import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import {
  GetAllDepartment,
  PositionByDepartment,
  YearList,
  MakeList,
  ModelList,
  AddVehicle,
  ViewVehicle,
  EditVehicle,
  GenerateVechicleId,
} from "../../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl as MuiFormControl,
  Grid,
  Typography,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  FormLabel,
  DialogContentText,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
  Link,
  TextField,
  Paper,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../../helper/helper";
import hasPermission from "../../../utils/hasPermission";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const state = [
  { value: "AL", key: "Alabama" },
  { value: "AK", key: "Alaska" },
  { value: "AS", key: "American Samoa" },
  { value: "AZ", key: "Arizona" },
  { value: "AR", key: "Arkansas" },
  { value: "AF", key: "Armed Forces Africa" },
  { value: "AA", key: "Armed Forces Americas" },
  { value: "AC", key: "Armed Forces Canada" },
  { value: "AE", key: "Armed Forces Europe" },
  { value: "AM", key: "Armed Forces Middle East" },
  { value: "AP", key: "Armed Forces Pacific" },
  { value: "CA", key: "California" },
  { value: "CO", key: "Colorado" },
  { value: "CT", key: "Connecticut" },
  { value: "DE", key: "Delaware" },
  { value: "DC", key: "District of Columbia" },
  { value: "FM", key: "Federated States Of Micronesia" },
  { value: "FL", key: "Florida" },
  { value: "GA", key: "Georgia" },
  { value: "GU", key: "Guam" },
  { value: "HI", key: "Hawaii" },
  { value: "ID", key: "Idaho" },
  { value: "IL", key: "Illinois" },
  { value: "IN", key: "Indiana" },
  { value: "IA", key: "Iowa" },
  { value: "KS", key: "Kansas" },
  { value: "KY", key: "Kentucky" },
  { value: "LA", key: "Louisiana" },
  { value: "ME", key: "Maine" },
  { value: "MH", key: "Marshall Islands" },
  { value: "MD", key: "Maryland" },
  { value: "MA", key: "Massachusetts" },
  { value: "MI", key: "Michigan" },
  { value: "MN", key: "Minnesota" },
  { value: "MS", key: "Mississippi" },
  { value: "MO", key: "Missouri" },
  { value: "MT", key: "Montana" },
  { value: "NE", key: "Nebraska" },
  { value: "NV", key: "Nevada" },
  { value: "NH", key: "New Hampshire" },
  { value: "NJ", key: "New Jersey" },
  { value: "NM", key: "New Mexico" },
  { value: "NY", key: "New York" },
  { value: "NC", key: "North Carolina" },
  { value: "ND", key: "North Dakota" },
  { value: "MP", key: "Northern Mariana Islands" },
  { value: "OH", key: "Ohio" },
  { value: "OK", key: "Oklahoma" },
  { value: "OR", key: "Oregon" },
  { value: "PW", key: "Palau" },
  { value: "PA", key: "Pennsylvania" },
  { value: "PR", key: "Puerto Rico" },
  { value: "RI", key: "Rhode Island" },
  { value: "SC", key: "South Carolina" },
  { value: "SD", key: "South Dakota" },
  { value: "TN", key: "Tennessee" },
  { value: "TX", key: "Texas" },
  { value: "UT", key: "Utah" },
  { value: "VT", key: "Vermont" },
  { value: "VI", key: "Virgin Islands" },
  { value: "VA", key: "Virginia" },
  { value: "WA", key: "Washington" },
  { value: "WV", key: "West Virginia" },
  { value: "WI", key: "Wisconsin" },
  { value: "WY", key: "Wyoming" },
];

const AddEditVehicle = ({
  addEditVehicleDialog,
  addEditVehicleDialogClose,
  id,
  vehicleList,
}) => {
  const [assignedSpace, setAssignedSpace] = useState("no");
  const [positionList, setPositionList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [viewVehicleData, setViewVehicleData] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAssignedSpace = (event) => {
    setAssignedSpace(event.target.value);
    console.log(event.target.value);
  };
  const [newId, setNewId] = useState("");
  const handleGenerateId = () => {
    GenerateVechicleId()
      .then((res) => {
        if (res.statusCode === 200) {
          setNewId(res.data);
        } else {
          setNewId("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDepartment = () => {
    GetAllDepartment()
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeDepartment = (value) => {
    PositionByDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [yearData, setYearData] = useState([]);
  //method for getting year dropdown
  const getYearList = () => {
    YearList()
      .then((res) => {
        if (res.statusCode == 200) {
          setYearData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [makeData, setMakeData] = useState([]);
  //Method for getting make from year dropdown
  const getMakeList = (v) => {
    let payload = {
      year: v,
    };
    MakeList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setMakeData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [modelData, setModelData] = useState([]);
  const getModelList = (v) => {
    let payload = {
      make: v,
    };
    ModelList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setModelData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewVehicle = () => {
    setLoading(true);
    ViewVehicle(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewVehicleData(res.data);
          handleChangeDepartment(res.data.department);
          setNewId(res.data.vehicle_id);
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  let today = new Date().toISOString().slice(0, 10);
  const {
    fname,
    lname,
    housing_location,
    email,
    vehicle_id,
    vehicle_year,
    vehicle_color,
    vehicle_make,
    vehicle_model,
    department,
    position,
    other_vehicle_id,
    vehicle_license_number,
    vehicle_state,
    vehicle_vin,
    parking_lot,
    section,
    space_number,
    vehicle_sticker_num,
    phone,
    assigned_space,
  } = viewVehicleData;
  useEffect(() => {
    handleDepartment();
    getYearList();
    if (id != undefined) {
      viewVehicle();
    }
  }, []);

  useEffect(() => {
    if (id != undefined) {
      getMakeList(vehicle_year);
      getModelList(vehicle_make);
    }
  }, [vehicle_year, vehicle_make]);

  let initialValues = {
    fname: fname ? fname : "",
    lname: lname ? lname : "",
    housing_location: housing_location ? housing_location : "",
    email: email ? email : "",
    phone: phone ? phone : "",
    department: department ? department : "",
    position: position ? position : "",
    vehicle_year: vehicle_year ? vehicle_year : "",
    vehicle_make: vehicle_make ? vehicle_make : "",
    vehicle_model: vehicle_model ? vehicle_model : "",
    // vehicle_rfid: vehicle_id ? vehicle_id : newId,
    access_id_card: other_vehicle_id ? other_vehicle_id : "",
    vehicle_color: vehicle_color ? vehicle_color : "",
    vehicle_license: vehicle_license_number ? vehicle_license_number : "",
    vehicle_vin: vehicle_vin ? vehicle_vin : "",
    vehicle_sticker_number: vehicle_sticker_num ? vehicle_sticker_num : "",
    vehicle_state: vehicle_state ? vehicle_state : "",
    assigned_space: assigned_space ? assigned_space : "",
    space_number: space_number ? space_number : "",
    section: section ? section : "",
    parking_lot: parking_lot ? parking_lot : "",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    fname: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    lname: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    housing_location: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
    vehicle_year: Yup.string().required("Please select year"),
    vehicle_make: Yup.string().required("Please select make"),
    vehicle_model: Yup.string().required("Please select model"),
    vehicle_state: Yup.string().required("Please select state"),
    email: Yup.string().nullable().email("Please enter valid email"),
    vehicle_color: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Please enter vehicle color"),
    vehicle_license: Yup.string()
      .matches(
        /^[a-zA-Z0-9]*$/,
        "Vehicle license should not contain special characters"
      )
      .required("Please enter vehicle license"),
    parking_lot: Yup.string()
      .nullable()
      .when(["assigned_space"], (same, schema) => {
        return same === "Yes"
          ? schema.required("Please enter details")
          : schema;
      }),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setLoading(true);
    try {
      let payload = {
        id: id,
        fname: values.fname,
        lname: values.lname,
        housing_location: values.housing_location,
        email: values.email,
        phone: values.phone,
        department: values.department,
        position: values.position,
        vehicle_id: newId,
        other_vehicle_id: values.access_id_card,
        vehicle_year: values.vehicle_year,
        vehicle_color: values.vehicle_color,
        vehicle_make: values.vehicle_make,
        vehicle_license_number: values.vehicle_license,
        vehicle_model: values.vehicle_model,
        vehicle_state: values.vehicle_state,
        vehicle_vin: values.vehicle_vin,
        assigned_space: values.assigned_space,
        vehicle_sticker_num: values.vehicle_sticker_number,
        parking_lot: values.parking_lot,
        section: values.section,
        space_number: values.space_number,
      };
      if (id != undefined) {
        EditVehicle(payload)
          .then((res) => {
            if (res.statusCode === 200) {
              setLoading(false);
              vehicleList();
              addEditVehicleDialogClose();
              dispatch(getSnackOpen());
              dispatch(getMsg("Vehicle has been updated successfully"));
              dispatch(getSnackAlert("success"));
            } else {
              dispatch(getMsg("Something went wrong!"));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
              setLoading(false);
            }
          })
          .catch((err) => {
            let errors = err.data.errors;
            let error = handleApiResonseErrors(errors);
            dispatch(getMsg(error));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setLoading(false);
          });
      } else {
        AddVehicle(payload)
          .then((res) => {
            if (res.statusCode === 200) {
              vehicleList();
              addEditVehicleDialogClose();
              dispatch(getSnackOpen());
              dispatch(getMsg("Vehicle has been submitted successfully"));
              dispatch(getSnackAlert("success"));
              setLoading(false);
            } else {
              dispatch(getMsg("Something went wrong!"));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            let errors = err.data.errors;
            let error = handleApiResonseErrors(errors);
            dispatch(getMsg(error));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setLoading(false);
          });
      }

      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-edit-vehicle-title"
        aria-describedby="add-edit-vehicle-desc"
        open={addEditVehicleDialog}
        onClose={addEditVehicleDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-edit-vehicle-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit Vehicle
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addEditVehicleDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="fname"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="First Name"
                        autoComplete="off"
                        placeholder="Mike"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="lname"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Last Name"
                        autoComplete="off"
                        placeholder="Smith"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="housing_location"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Employee Housing Location"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="email"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Email"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="phone"
                        fullWidth
                        inputProps={{ maxLength: 15 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Phone"
                        autoComplete="off"
                        placeholder="xxx-xxx-xxxx"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel shrink id="department">
                        Department
                      </InputLabel>
                      <Select
                        labelId="department"
                        id="department-select"
                        name="department"
                        label="Department"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.department}
                        onChange={(e) => {
                          setFieldValue("department", e.target.value);
                          handleChangeDepartment(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.department && errors.department)}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select department
                        </MenuItem>
                        {departmentList.map((data) => {
                          return (
                            <MenuItem value={data.id} key={data.id}>
                              {data.department_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error>
                        {touched.department && errors.department}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel shrink id="position">
                        Position{" "}
                      </InputLabel>
                      <Select
                        labelId="position"
                        id="position-select"
                        name="position"
                        label="position"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.position}
                        onChange={(e) => {
                          setFieldValue("position", e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.position && errors.position)}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select position
                        </MenuItem>
                        {positionList.map((data) => {
                          return (
                            <MenuItem value={data.id} key={data.id}>
                              {data.position_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.position && errors.position}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <TextField
                        name="vehicle_rfid"
                        value={newId}
                        onChange={(e) => setNewId(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Vehicle RFID"
                        autoComplete="off"
                        placeholder="xxx-xxx-xxxx"
                      />
                      <Button
                        disabled={!hasPermission("Logs", "generateVehicleId")}
                        onClick={handleGenerateId}
                      >
                        Generate New ID Code
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="access_id_card"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Access ID card"
                        autoComplete="off"
                        placeholder="Employee barcode ID, etc"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel shrink id="vehicle_year">
                        Vehicle Year*
                      </InputLabel>
                      <Select
                        labelId="vehicle_year"
                        id="vehicle_year-select"
                        name="vehicle_year"
                        label="Vehicle Year*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.vehicle_year}
                        onChange={(e) => {
                          setFieldValue("vehicle_year", e.target.value);
                          getMakeList(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.vehicle_year && errors.vehicle_year
                        )}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select year
                        </MenuItem>
                        {yearData.map((data) => {
                          return (
                            <MenuItem value={data.value} key={data.value}>
                              {data.key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error>
                        {touched.vehicle_year && errors.vehicle_year}
                      </FormHelperText>
                    </FormControl>
                    {/* <FormControl>
                      <CustomSelect
                        name="vehicle_year"
                        fieldLabel="Vehicle year*"
                        options={vehicleYear}
                        displayEmpty
                      />
                    </FormControl> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="vehicle_color"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Vehicle color*"
                        autoComplete="off"
                        placeholder="White"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel shrink id="vehicle_year">
                        Vehicle Make*
                      </InputLabel>
                      <Select
                        labelId="vehicle_make"
                        id="vehicle_make-select"
                        name="vehicle_make"
                        label="Vehicle Make*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.vehicle_make}
                        onChange={(e) => {
                          setFieldValue("vehicle_make", e.target.value);
                          getModelList(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.vehicle_make && errors.vehicle_make
                        )}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select Make
                        </MenuItem>
                        {makeData.map((data) => {
                          return (
                            <MenuItem value={data.value} key={data.value}>
                              {data.key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error>
                        {touched.vehicle_make && errors.vehicle_make}
                      </FormHelperText>
                    </FormControl>
                    {/* <FormControl>
                      <CustomSelect
                        name="vehicle_make"
                        fieldLabel="Vehicle make*"
                        options={vehicleMake}
                        displayEmpty
                      />
                    </FormControl> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="vehicle_license"
                        fullWidth
                        inputProps={{ maxLength: 15 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Vehicle license*"
                        autoComplete="off"
                        placeholder="D34H45H"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel shrink id="vehicle_year">
                        Vehicle Model*
                      </InputLabel>
                      <Select
                        labelId="vehicle_model"
                        id="vehicle_model-select"
                        name="vehicle_model"
                        label="Vehicle Make*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.vehicle_model}
                        onChange={(e) => {
                          setFieldValue("vehicle_model", e.target.value);
                          // getModelList(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.vehicle_model && errors.vehicle_model
                        )}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select Model
                        </MenuItem>
                        {modelData.map((data) => {
                          return (
                            <MenuItem value={data.value} key={data.value}>
                              {data.key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error>
                        {touched.vehicle_model && errors.vehicle_model}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        name="vehicle_state"
                        fieldLabel="Vehicle state*"
                        options={state}
                        displayEmpty
                      />
                    </FormControl>
                    <FormHelperText error>
                      {touched.vehicle_state && errors.vehicle_state}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="vehicle_vin"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Vehicle VIN"
                        autoComplete="off"
                        placeholder="Vehicle VIN"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Assigned space</FormLabel>
                      <RadioGroup
                        aria-labelledby="assigned-space"
                        name="assigned_space"
                        value={values.assigned_space}
                        onChange={(e) =>
                          setFieldValue("assigned_space", e.target.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="vehicle_sticker_number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Vehicle Sticker Number"
                        autoComplete="off"
                        placeholder="Number printed on vehicle sticker"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display:
                        values.assigned_space === "Yes" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="parking_lot"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Parking lot*"
                        autoComplete="off"
                        placeholder="Lot name or number"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display:
                        values.assigned_space === "Yes" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="section"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Section"
                        autoComplete="off"
                        placeholder="Lot section name or number"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display:
                        values.assigned_space === "Yes" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="space_number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Space number"
                        autoComplete="off"
                        placeholder="Space number"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddEditVehicle;

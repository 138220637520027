import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import ChoosePoiImage from "./ChoosePoiImage";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Grid,
  Typography,
  Button,
  Tab,
  Box,
  Avatar as MuiAvatar,
  Tooltip,
} from "@mui/material";

const Avatar = styled(MuiAvatar)`
  width: 90px;
  height: 90px;
  object-fit: cover;
`;

const BigAvatar = styled(Avatar)`
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin: 30px auto 0;
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  VerticalTab: {
    "& .MuiTabs-flexContainer": {
      display: "block",
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "3px",

      "& .MuiTab-root": {
        display: "block",
        width: "105px",
        height: "105px",
        marginLeft: "auto",
        marginBottom: "8px",
        border: "1px solid",
        borderRadius: "10px",
        borderColor:
          theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar-thumb": {
      backgroundColor: "#e0e0e0",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const AddPoiImage = ({
  frontFace,
  leftSideFace,
  rightSideFace,
  chest,
  back,
  leftHand,
  rightHand,
  leftArm,
  rightArm,
  leftLeg,
  rightLeg,
  other,
  setFrontFace,
  setLeftSideFace,
  setRightSideFace,
  setChest,
  setBack,
  setLeftHand,
  setRightHand,
  setLeftArm,
  setRightArm,
  setLeftLeg,
  setRightLeg,
  setOther,
}) => {
  const [tabValue, setTabValue] = useState("1");
  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const classes = useStyles();

  return (
    <>
      <TabContext value={tabValue}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            <Box>
              <TabPanel value="1">
                <ChoosePoiImage
                  className="poi-img"
                  title="Front Face (Autocrop)"
                  filePath="/static/img/poi/front-face.png"
                  uploadedImage={frontFace}
                  setUploadedImage={(file) => setFrontFace(file)}
                />
              </TabPanel>
              <TabPanel value="2">
                <ChoosePoiImage
                  title="Left Side Face"
                  filePath="/static/img/poi/left-face.png"
                  uploadedImage={leftSideFace}
                  setUploadedImage={(file) => setLeftSideFace(file)}
                />
              </TabPanel>
              <TabPanel value="3">
                <ChoosePoiImage
                  title="Right Side Face"
                  filePath="/static/img/poi/right-face.png"
                  uploadedImage={rightSideFace}
                  setUploadedImage={(file) => setRightSideFace(file)}
                />
              </TabPanel>
              <TabPanel value="4">
                <ChoosePoiImage
                  title="Chest"
                  filePath="/static/img/poi/chest.png"
                  uploadedImage={chest}
                  setUploadedImage={(file) => setChest(file)}
                />
              </TabPanel>
              <TabPanel value="5">
                <ChoosePoiImage
                  title="Back"
                  filePath="/static/img/poi/back.png"
                  uploadedImage={back}
                  setUploadedImage={(file) => setBack(file)}
                />
              </TabPanel>
              <TabPanel value="6">
                <ChoosePoiImage
                  title="Left Hand"
                  filePath="/static/img/poi/left-hand.png"
                  uploadedImage={leftHand}
                  setUploadedImage={(file) => setLeftHand(file)}
                />
              </TabPanel>
              <TabPanel value="7">
                <ChoosePoiImage
                  title="Right Hand"
                  filePath="/static/img/poi/right-hand.png"
                  uploadedImage={rightHand}
                  setUploadedImage={(file) => setRightHand(file)}
                />
              </TabPanel>
              <TabPanel value="8">
                <ChoosePoiImage
                  title="Left Arm"
                  filePath="/static/img/poi/left-arm.png"
                  uploadedImage={leftArm}
                  setUploadedImage={(file) => setLeftArm(file)}
                />
              </TabPanel>
              <TabPanel value="9">
                <ChoosePoiImage
                  title="Right Arm"
                  filePath="/static/img/poi/right-arm.png"
                  uploadedImage={rightArm}
                  setUploadedImage={(file) => setRightArm(file)}
                />
              </TabPanel>
              <TabPanel value="10">
                <ChoosePoiImage
                  title="Left Leg"
                  filePath="/static/img/poi/left-leg.png"
                  uploadedImage={leftLeg}
                  setUploadedImage={(file) => setLeftLeg(file)}
                />
              </TabPanel>
              <TabPanel value="11">
                <ChoosePoiImage
                  title="Right Leg"
                  filePath="/static/img/poi/right-leg.png"
                  uploadedImage={rightLeg}
                  setUploadedImage={(file) => setRightLeg(file)}
                />
              </TabPanel>
              <TabPanel value="12">
                <ChoosePoiImage
                  title="Other Image"
                  filePath="/static/img/poi/other-image.png"
                  uploadedImage={other}
                  setUploadedImage={(file) => setOther(file)}
                />
              </TabPanel>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <TabList
              className={classes.VerticalTab}
              onChange={tabChange}
              aria-label="lab API tabs example"
            >
              <Tooltip title="Choose clear face image" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          frontFace
                            ? frontFace
                            : "/static/img/poi/front-face.png"
                        }
                      />
                    </>
                  }
                  value="1"
                />
              </Tooltip>
              <Tooltip title="Left side face" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          leftSideFace
                            ? leftSideFace
                            : "/static/img/poi/left-face.png"
                        }
                      />
                    </>
                  }
                  value="2"
                />
              </Tooltip>
              <Tooltip title="Right side face" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          rightSideFace
                            ? rightSideFace
                            : "/static/img/poi/right-face.png"
                        }
                      />
                    </>
                  }
                  value="3"
                />
              </Tooltip>
              <Tooltip title="Chest" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={chest ? chest : "/static/img/poi/chest.png"}
                      />
                    </>
                  }
                  value="4"
                />
              </Tooltip>
              <Tooltip title="Back" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={back ? back : "/static/img/poi/back.png"}
                      />
                    </>
                  }
                  value="5"
                />
              </Tooltip>
              <Tooltip title="Left hand" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          leftHand ? leftHand : "/static/img/poi/left-hand.png"
                        }
                      />
                    </>
                  }
                  value="6"
                />
              </Tooltip>
              <Tooltip title="Right hand" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          rightHand
                            ? rightHand
                            : "/static/img/poi/right-hand.png"
                        }
                      />
                    </>
                  }
                  value="7"
                />
              </Tooltip>
              <Tooltip title="Left arm" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={leftArm ? leftArm : "/static/img/poi/left-arm.png"}
                      />
                    </>
                  }
                  value="8"
                />
              </Tooltip>
              <Tooltip title="Right arm" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          rightArm ? rightArm : "/static/img/poi/right-arm.png"
                        }
                      />
                    </>
                  }
                  value="9"
                />
              </Tooltip>
              <Tooltip title="Left leg" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={leftLeg ? leftLeg : "/static/img/poi/left-leg.png"}
                      />
                    </>
                  }
                  value="10"
                />
              </Tooltip>
              <Tooltip title="Right leg" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          rightLeg ? rightLeg : "/static/img/poi/right-leg.png"
                        }
                      />
                    </>
                  }
                  value="11"
                />
              </Tooltip>
              <Tooltip title="Other image" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={other ? other : "/static/img/poi/other-image.png"}
                      />
                    </>
                  }
                  value="12"
                />
              </Tooltip>
            </TabList>
          </Grid>
        </Grid>
      </TabContext>
    </>
  );
};

export default AddPoiImage;

import * as React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useState } from "react";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Box,
  location,
  TextField,
  InputLabel,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
  ImageListItem,
  Avatar,
} from "@mui/material";

import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { logout } from "../../redux/slices/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { Cancel as CancelIcon } from "@mui/icons-material";
// import userImage from "../../../public/static/user-image.jpg"
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Image = styled.img`
  max-width: 10px;
  cursor: pointer;
  margin-left: 6px;
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signOut } = useAuth();
  const userData = JSON.parse(localStorage.getItem("user"));

  const user = useSelector((state) => state.login.user);

  if (user == null || user == undefined) {
    navigate({
      pathname: "/auth/sign-in",
    });
  }

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    dispatch(logout());
    navigate({
      pathname: "/auth/sign-in",
    });
    // await axios.get(`logout/${user.token}`).then((response) => {
    //   dispatch(logout());
    //   navigate({
    //     pathname: "/auth/sign-in",
    //   });
    // });
  };

  const [bugReport, setBugReport] = useState(false);

  const handleClickOpen = () => {
    setBugReport(true);
  };

  const handleClose = () => {
    setBugReport(false);
  };
  const [reproducible, setReproducible] = useState("");

  const handleChange = (event) => {
    setReproducible(event.target.value);
  };

  const [about, setAbout] = useState(false);

  const handleAboutOpen = () => {
    setAbout(true);
  };

  const handleAboutClose = () => {
    setAbout(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <Avatar
          sx={{ cursor: "pointer", width: "32px" }}
          alt="Darin Redding"
          src={"../../../public/static/user-image.jpg"}
          // onClick={toggleMenu}
        />
      </Tooltip>
      <Typography sx={{ marginLeft: "4px" }} variant="body2">
        {userData?.username}
      </Typography>
      <Image
        src={`/static/img/brands/down-arrow.png`}
        sx={{ marginLeft: "8px", width: "12px" }}
        onClick={toggleMenu}
      />
      {/* <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip> */}
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        sx={{ marginTop: "20px" }}
      >
        <MenuItem onClick={handleAboutOpen}>About</MenuItem>
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
              },
            },
          }}
          open={about}
          onClose={handleAboutClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="visitor-modal"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              background: "#e8e8e8",
            }}
          >
            <Typography variant="h6">About</Typography>
            <DialogActions sx={{ padding: "0px" }}>
              <IconButton
                sx={{ marginRight: "-10px" }}
                onClick={handleAboutClose}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
          <DialogContent sx={{ marginTop: "20px" }}>
            <Typography>SOVA Systems ©2024</Typography>
            <Typography>
              <span>Build Date:</span> 2024-03-13 05:03:23
            </Typography>
            <Typography>
              <span>Git Revision:</span>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleAboutClose}
              variant="outlined"
              color="warning"
            >
              Cancel
            </Button>
            <Button onClick={handleAboutClose} variant="contained" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <MenuItem>Create Ticket</MenuItem>
        <MenuItem>Legal</MenuItem>
        <MenuItem component={NavLink} to={"/pages/profile"}>
          My Profile
        </MenuItem>
        <MenuItem>Open Source</MenuItem>
        <MenuItem>Privacy Policy</MenuItem>
        <MenuItem>Support Portal</MenuItem>
        <MenuItem onClick={handleClickOpen}>Submit Bug report </MenuItem>
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "650px",
              },
            },
          }}
          open={bugReport}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="visitor-modal"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              background: "#e8e8e8",
            }}
          >
            <Typography>Select Filters</Typography>
            <DialogActions sx={{ padding: "0px" }}>
              <IconButton sx={{ marginRight: "-10px" }} onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
          <DialogContent>
            <TextField
              sx={{ marginTop: "20px" }}
              fullWidth
              id="first-name"
              name="first_name"
              label="Name*"
              variant="outlined"
              // InputLabelProps={{
              //   shrink: true,
              // }}
              placeholder="Enter Your Name...."
            />
            <TextField
              sx={{ marginTop: "20px" }}
              fullWidth
              id="email"
              name="email"
              label="Email*"
              variant="outlined"
              placeholder="Enter Your Email...."
            />
            <TextField
              sx={{
                marginTop: "20px",
                "& .MuiInputBase-input": { height: "100px" },
              }}
              fullWidth
              id="Description"
              label="Description*"
              variant="outlined"
            />

            <TextField
              sx={{ marginTop: "20px" }}
              fullWidth
              id="company name"
              name="company name"
              label="Company Name*"
              variant="outlined"
              placeholder="Enter Your Company Name...."
            />
            <TextField
              sx={{
                marginTop: "20px",
                "& .MuiInputBase-input": { height: "100px" },
              }}
              fullWidth
              id="reproduce"
              label="Detailed steps to reproduce the bug*"
              variant="outlined"
            />
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <InputLabel id="demo-simple-select-label">--Select--</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reproducible}
                label="Is it reproducible"
                onChange={handleChange}
              >
                <MenuItem value={10}>--Select--</MenuItem>
                <MenuItem value={20}>Always</MenuItem>
                <MenuItem value={30}>Sometimes</MenuItem>
                <MenuItem value={30}>Rarely</MenuItem>
                <MenuItem value={30}>Unable</MenuItem>
                <MenuItem value={30}>Never tried</MenuItem>
                <MenuItem value={30}>Not applicable</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h5" sx={{ marginTop: "20px" }}>
              Device
            </Typography>
            <p sx={{ color: "gray" }}>
              Tell us about the device you are using to access SOVA. Examples:
              Asus laptop, MacBook Pro, iPad 2, Samsung Galaxy S8
            </p>
            <TextField
              sx={{ marginTop: "20px" }}
              fullWidth
              id=""
              // label=""
              // variant="outlined"
            />
            <Typography variant="h5" sx={{ marginTop: "20px" }}>
              Browser
            </Typography>
            <p sx={{ color: "gray" }}>
              What browser are you using to access SOVA when the issue occurs?
              SOVA works best with the latest versions of Chrome and Firefox
              with browser extensions disabled. You may be able to resolve the
              issue you’re experiencing right now by trying one of these two
              browsers, and clearing your cache and cookies.
            </p>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <InputLabel id="demo-simple-select-label">--Select--</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reproducible}
                label="Is it reproducible"
                onChange={handleChange}
              >
                <MenuItem value={10}>--Select--</MenuItem>
                <MenuItem value={20}>Always</MenuItem>
                <MenuItem value={30}>Sometimes</MenuItem>
                <MenuItem value={30}>Rarely</MenuItem>
                <MenuItem value={30}>Unable</MenuItem>
                <MenuItem value={30}>Never tried</MenuItem>
                <MenuItem value={30}>Not applicable</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h6" sx={{ marginTop: "20px" }}>
              Would you like to request a personal followup by email regarding
              this issue?
            </Typography>
            <FormControlLabel
              required
              control={<Checkbox />}
              label=" Yes Please"
            />
            <Typography variant="h6">Attachments</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="warning">
              Cancel
            </Button>
            <Button onClick={handleClose} variant="contained" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  List,
  ListItemText,
  ListItem,
  TableContainer,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
//Classes Styling
const useStyles = makeStyles((theme) => ({
  BulletList: {
    listStyle: "disc",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "disc",
    },
  },
}));
const GeneralAddressTips = ({
  generalAddressTips,
  generalAddressTipsClose,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={generalAddressTips}
        aria-labelledby="general-address-title"
        aria-describedby="general-address-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600",
            },
          },
        }}
      >
        <DialogTitle
          id="general-address-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            General Address Tips
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton onClick={generalAddressTipsClose}>
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent
          id="general-address-description"
          sx={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <DialogContentText sx={{ padding: "0 24px" }}>
            Getting your stuff returned is our goal. Providing an accurate
            shipping address avoids unnecessary delays in your shipments.
          </DialogContentText>
          <Box sx={{ padding: "0 24px" }}>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{ marginTop: "20px" }}
            >
              Tips for speedy shipments
            </Typography>
            <List className={classes.BulletList}>
              <ListItem>
                <ListItemText primary='Leave fields blank if they don&apos;t apply to your shipping address. Do not enter "N/A," "Not applicable," "None," "Same," etc.' />
              </ListItem>
              <ListItem>
                <ListItemText primary='Enter information in the appropriate fields. For example, do not enter city and state information in the "Address 2" field; use the provided city and state fields instead.' />
              </ListItem>
              <ListItem>
                <ListItemText primary="Avoid using accent marks or non-English characters. Our systems can't process Chinese or Cyrillic letters and entering these into any of the address fields could delay shipments of your packages." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Do not enter delivery instructions in any of the address fields." />
              </ListItem>
              <ListItem>
                <ListItemText primary="In order to ship your items in a timely fashion and provide the best pricing, we utilize a variety of carriers. We are unable to accommodate requests for specific shipping carriers." />
              </ListItem>
              <ListItem>
                <ListItemText primary='If carriers do not appear in step 3 when trying to ship to a company address, select NO for "Is this a business address?" but leave your company name in the company field. Sometimes businesses do not validate to their address, especially new businesses.' />
              </ListItem>
            </List>
          </Box>
          <DialogContentText
            id="general-address-description"
            sx={{ padding: "0 24px" }}
          >
            Still no carries showing in step 3? Try using the common U.S. Postal
            Service abbreviations that might help:
          </DialogContentText>
          <TableContainer sx={{ marginTop: "15px" }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Apartment: APT</TableCell>
                  <TableCell>Floor: FL</TableCell>
                  <TableCell>Parkway: PKWY</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Avenue: AVE</TableCell>
                  <TableCell>Fort: FT</TableCell>
                  <TableCell>Penthouse: PH</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Beach: BCH</TableCell>
                  <TableCell>Gardens: GDNS</TableCell>
                  <TableCell>Plaza: PLZ</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Boulevard: BLVD</TableCell>
                  <TableCell>Harbor: HBR</TableCell>
                  <TableCell>Point: PT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Building: BLDG</TableCell>
                  <TableCell>Heights: HTS</TableCell>
                  <TableCell>Post Office Box: P.O. Box</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Canyon: CYN</TableCell>
                  <TableCell>Highway: HWY</TableCell>
                  <TableCell>Road: RD</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Center: CTableRow</TableCell>
                  <TableCell>Hills: HLS</TableCell>
                  <TableCell>Room: RM</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Circle: CIR</TableCell>
                  <TableCell>Island: IS</TableCell>
                  <TableCell>Route: RTE</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Court: CT</TableCell>
                  <TableCell>Junction: JCT</TableCell>
                  <TableCell>Square: SQ</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Crescent: CRES</TableCell>
                  <TableCell>Lake: LK</TableCell>
                  <TableCell>Station: STA</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Crossing: XING</TableCell>
                  <TableCell>Landing: LDG</TableCell>
                  <TableCell>STableRoweet: ST</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Department: DEPT</TableCell>
                  <TableCell>Lane: LN</TableCell>
                  <TableCell>Suite: STE</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Drive: DR</TableCell>
                  <TableCell>Lodge: LDG</TableCell>
                  <TableCell>Terrace: TER</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Expressway: EXPY</TableCell>
                  <TableCell>Mount: MT</TableCell>
                  <TableCell>Turnpike: TPKE</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Falls: FLS</TableCell>
                  <TableCell>Mountain: MTN</TableCell>
                  <TableCell>Valley: VLY</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Field: FLD</TableCell>
                  <TableCell>Office: OFC</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={generalAddressTipsClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeneralAddressTips;

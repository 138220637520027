import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import ControlIdPopup from "../../pages/settings/property-user/inner-component/ControlIdPopup";

import {
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography,
  MenuList,
  MenuItem,
  Link,
} from "@mui/material";
import { Settings } from "react-feather";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
    max-height: 75%;
    border: 0;
    box-shadow: 0px 4px 13px 3px #0000001c;
    border-radius: 12px;
  }
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  SettingsMenu: {
    "& .MuiList-root": {
      padding: 0,
      "& .MuiMenuItem-root": {
        padding: 0,
        display: "block",
        border: 0,
        "& .MuiListItem-root": {
          borderBottom: "1px solid #0000001a",
        },
        "& .MuiBox-root": {
          textAlign: "left",
          cursor: "default",
          backgroundColor: "#376fd00a",

          padding: "8px 18px",
          "& .MuiTypography-subtitle1": {
            fontWeight: 500,
          },
        },
      },
    },
    "& .MuiPaper-root::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },
    "& .MuiPaper-root::-webkit-scrollbar-track": {
      background: "#bed2f5",
    },
    "& .MuiPaper-root::-webkit-scrollbar-thumb": {
      background: "#92a6c9",
    },
  },
}));

const SettingsMenuHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function SettingsListItem({ title }) {
  return (
    <ListItemText
      primary={title}
      primaryTypographyProps={{
        variant: "subtitle2",
        color: "textPrimary",
      }}
    />
  );
}

function SettingsMenu() {
  const settingsRef = useRef(null);
  const [settings, setingsOpen] = useState(false);
  const settingsMenuOpen = () => {
    setingsOpen(true);
  };
  const settingsMenuClose = () => {
    setingsOpen(false);
  };
  const classes = useStyles();

  const [conrolIdPopup, setConrolIdPopup] = React.useState(false);
  const conrolIdPopupOpen = () => {
    setConrolIdPopup(true);
  };
  const conrolIdPopupClose = () => {
    setConrolIdPopup(false);
  };
  return (
    <React.Fragment>
      <Tooltip title="Settings Menu">
        <IconButton
          color="inherit"
          ref={settingsRef}
          onClick={settingsMenuOpen}
          size="large"
        >
          <Settings />
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={settingsRef.current}
        onClose={settingsMenuClose}
        open={settings}
        className={classes.SettingsMenu}
      >
        <MenuList>
          <MenuItem>
            <SettingsMenuHeader p={2}>
              <Typography variant="subtitle1" color="textPrimary">
                Property User
              </Typography>
            </SettingsMenuHeader>
            <Link
              component={NavLink}
              to="/settings/property-user/communication"
              onClick={settingsMenuClose}
            >
              <List disablePadding>
                <ListItem>
                  <SettingsListItem title="Communication" />
                </ListItem>
              </List>
            </Link>
          </MenuItem>
          <MenuItem>
            <SettingsMenuHeader p={2}>
              <Typography variant="subtitle1" color="textPrimary">
                Corporate User
              </Typography>
            </SettingsMenuHeader>
            <List disablePadding>
              <Link
                component={NavLink}
                to="/settings/corporate-user/ir-numbering"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="IR Numbering" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/equipment/department-list"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="List Departments" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/ir-classifications"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="List IR Classifications" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/lost-and-found-privacy"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Lost & Found Privacy" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/trigger-words"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Trigger Words" />
                </ListItem>
              </Link>
            </List>
          </MenuItem>
          <MenuItem>
            <SettingsMenuHeader p={2}>
              <Typography variant="subtitle1" color="textPrimary">
                Property Admin
              </Typography>
            </SettingsMenuHeader>
            <List disablePadding>
              <Link
                component={NavLink}
                to="/settings/corporate-user/customize-report"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Customize Reports" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/ip-range"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="IP Range" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/lost-and-found-rule"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Lost & Found Rules" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/modify-nav-menu"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Modify Nav Menu" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/e-notify-templetes"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="E-Notify Templates" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/venues"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Venues" />
                </ListItem>
              </Link>
            </List>
          </MenuItem>
          <MenuItem>
            <SettingsMenuHeader p={2}>
              <Typography variant="subtitle1" color="textPrimary">
                Admin
              </Typography>
            </SettingsMenuHeader>
            <List disablePadding>
              <Link
                component={NavLink}
                to="/settings/corporate-user/add-question"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Chatbot Questions" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/list-device"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="List Devices" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/login-records"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Login Audit" />
                </ListItem>
              </Link>
              <ListItem onClick={conrolIdPopupOpen}>
                <SettingsListItem title="Lost & Found Search" />
              </ListItem>
              <Link
                component={NavLink}
                to="/settings/corporate-user/module-color"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Module Colors" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/notify-div"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Notify Divs" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/past-notify"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Past Notifies" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/permission"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Permissions" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/security-events"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Security Events" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/carrier-settings"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Shippo Carrier Settings" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/sms-report"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="SMS Report" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/switch-dummy-data"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Switch Dummy Data" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/user-search"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="User Search" />
                </ListItem>
              </Link>
              <Link
                component={NavLink}
                to="/settings/corporate-user/youtube-videos"
                onClick={settingsMenuClose}
              >
                <ListItem>
                  <SettingsListItem title="Youtube Videos" />
                </ListItem>
              </Link>
            </List>
          </MenuItem>
        </MenuList>
      </Popover>
      {conrolIdPopup && (
        <ControlIdPopup
          conrolIdPopup={conrolIdPopup}
          setConrolIdPopup={setConrolIdPopup}
          conrolIdPopupOpen={conrolIdPopupOpen}
          conrolIdPopupClose={conrolIdPopupClose}
        />
      )}
    </React.Fragment>
  );
}

export default SettingsMenu;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import AddEditExternalLocks from "./inner-component/AddEditExternalLocks";
import LockerInspection from "./inner-component/LockerInspection";
import LockAssignment from "./inner-component/LockAssignment";
import {
  LockList,
  LockerRoomlist,
  DeleteLock,
  RemoveAssigmentLock,
  ViewAssignmentLock,
} from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import LockListCol from "../table-columns-json/logs/LockList";
import { setLockList } from "../../redux/slices/logs/logTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import Toaster from "../tasks/Toaster";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Pagination,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  InsertDriveFile as InsertDriveFileIcon,
  DriveFolderUpload as DriveFolderUploadIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import EditLock from "./inner-component/EditLock";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "480px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(brand, serial, roomname, lockernumber, action) {
  return {
    brand,
    serial,
    roomname,
    lockernumber,
    action,
  };
}
const rows = [
  createData(
    "139",
    "Sandeep Kundu",
    "Director of Security",
    0,
    "No",
    "No",
    "illness",
    0,
    0,
    0
  ),
  createData(
    "140",
    "Sandeep mundu",
    "Director of Security",
    0,
    "No",
    "No",
    "Enjury",
    0,
    0,
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "brand",
    numeric: true,
    disablePadding: true,
    label: "Brand",
  },
  {
    id: "serial",
    numeric: true,
    disablePadding: false,
    label: "Serial",
  },
  {
    id: "roomname",
    numeric: true,
    disablePadding: false,
    label: "Room Name",
  },
  {
    id: "lockernumber",
    numeric: true,
    disablePadding: false,
    label: "Locker Number",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {LockListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    roomData,
    setDataToFilter,
    lockList,
    handleDeleteOpen,
    tableCalRedux,
  } = props;
  const [actions, setActions] = React.useState("");
  const [isFilter, setIsFilter] = useState(false);
  //Select
  const [roomName, setRoomName] = React.useState("");
  const [lockNumber, setLockNumber] = useState("");
  const selectRoomName = (event) => {
    setRoomName(event.target.value);
  };

  const [checked, setChecked] = React.useState(false);
  const [checkedValue, setCheckedValue] = useState("");
  const handleChecked = (event) => {
    setChecked(event.target.checked);
    setCheckedValue(event.target.value);
  };

  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.lockList
  );
  const dispatch = useDispatch();
  /**
   * It takes the values of the form fields and sets them to the dataToFilter state
   */
  const applySearch = () => {
    const payload = {
      locker_number: lockNumber,
      locker_room: roomName,
      assigned: checkedValue,
    };
    setDataToFilter(payload);
  };

  /**
   * It resets the state of the filter form to its default values
   */
  const resetFilter = () => {
    setLockNumber("");
    setRoomName("");
    setCheckedValue("");
    setChecked(false);
  };

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportData, setExportData] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    LockList(1, { limit: totalRecord })
      .then((res) => {
        if (res.statusCode === 200) {
          setExportData(res.data);
        } else {
          setExportData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);

  //const [visitortype, setVisitorType] = React.useState("");
  // const selectVisitorType = (event) => {
  //   setVisitorType(event.target.value);
  // };
  // const [visitorstatus, setVisitorStatus] = React.useState("");
  // const selectVisitorStatus = (event) => {
  //   setVisitorStatus(event.target.value);
  // };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  // Toggle Button Group
  const [toggleGroup, setToggleGroup] = React.useState("locks");
  const hangleToggleButton = (event, newAlignment) => {
    setToggleGroup(newAlignment);
  };
  const [addEditExternalLocksDialog, setAddEditExternalLocksDialog] =
    useState(false);
  const addEditExternalLocksDialogOpen = () => {
    setAddEditExternalLocksDialog(true);
  };
  const addEditExternalLocksDialogClose = () => {
    setAddEditExternalLocksDialog(false);
  };

  // Locker Inspection Dialog
  const [lockerInspectionDialog, setLockerInspectionDialog] = useState(false);
  const lockerInspectionDialogOpen = () => {
    setLockerInspectionDialog(true);
  };
  const lockerInspectionDialogClose = () => {
    setLockerInspectionDialog(false);
  };

  let headers = [
    { label: "Brand", key: "brand" },
    { label: "Serial", key: "serial" },
    { label: "Room Name", key: "locker_room" },
    { label: "Lock Number", key: "locker_number" },
  ];

  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord <= 1
              ? `${totalRecord} record found`
              : `${totalRecord} records found`}
          </Typography>
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                value={lockNumber}
                onChange={(e) => setLockNumber(e.target.value)}
                placeholder="Search by locker number or click arrow"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                    setIsFilter(true);
                  }
                }}
                inputProps={{}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form>
                            <FormControl mt={3}>
                              <InputLabel
                                shrink
                                required
                                id="room-name"
                                sx={{
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  marginLeft: "-4px",
                                }}
                              >
                                Room name
                              </InputLabel>
                              <Select
                                labelId="position"
                                id="room-name"
                                value={roomName}
                                onChange={selectRoomName}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">--Select room--</MenuItem>
                                {roomData.map((data) => {
                                  return (
                                    <MenuItem
                                      value={data.value}
                                      key={data.value}
                                    >
                                      {data.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={checked ? 0 : 1}
                                      checked={checked}
                                      onChange={handleChecked}
                                    />
                                  }
                                  label="Show assigned only"
                                />
                              </FormGroup>
                            </FormControl>
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={6}
                                onClick={() => {
                                  applySearch({});
                                  setIsFilter(true);
                                  handleCloseForm();
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  variant="contained"
                  color="primary"
                  mt={6}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                  }}
                >
                  Clear filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    applySearch({});
                    setIsFilter(true);
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={addEditExternalLocksDialogOpen}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Add Locks" pl={0} />
              </MenuItem>
              <MenuItem onClick={lockerInspectionDialogOpen}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary="Add Locker Inspection"
                  pl={0}
                />
              </MenuItem>
              <CSVLink
                data={exportData}
                headers={headers}
                onClick={handleClose}
                filename="SOVA-locks"
              >
                <MenuItem>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Export CSV" pl={0} />
                </MenuItem>
              </CSVLink>

              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setLockList(data));
                      }}
                      listing={LockListCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setLockList(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      <Box
        sx={{
          paddingLeft: "15px",
          paddingRight: "15px",
          marginBottom: "15px",
          marginTop: "15px",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={toggleGroup}
          exclusive
          onChange={hangleToggleButton}
        >
          <ToggleButton value="map" component={NavLink} to="/auth/404" disabled>
            Map
          </ToggleButton>
          <ToggleButton value="lockers" component={NavLink} to="/logs/lockers">
            Lockers
          </ToggleButton>
          <ToggleButton
            value="assignments"
            component={NavLink}
            to="/logs/locker-log"
          >
            Assignments
          </ToggleButton>
          <ToggleButton value="locks">Locks</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {addEditExternalLocksDialog && (
        <AddEditExternalLocks
          addEditExternalLocksDialog={addEditExternalLocksDialog}
          setAddEditExternalLocksDialog={setAddEditExternalLocksDialog}
          addEditExternalLocksDialogOpen={addEditExternalLocksDialogOpen}
          addEditExternalLocksDialogClose={addEditExternalLocksDialogClose}
          lockList={lockList}
        />
      )}
      {lockerInspectionDialog && (
        <LockerInspection
          lockerInspectionDialog={lockerInspectionDialog}
          setLockerInspectionDialog={setLockerInspectionDialog}
          lockerInspectionDialogOpen={lockerInspectionDialogOpen}
          lockerInspectionDialogClose={lockerInspectionDialogClose}
          roomData={roomData}
        />
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [lockData, setLockData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [roomData, setRoomData] = useState([]);
  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const dispatch = useDispatch();
  const tableCalRedux = useSelector((state) => state.logTableCol.lockList);
  const [lockerRoom, setLockerRoom] = useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lockData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lockAssignId, setLockAssignId] = useState(null);
  const [viewAssignData, setViewAssignData] = useState({});
  const handleActionClick = (event, id, room, assignId) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    setLockerRoom(room);
    setLockAssignId(assignId);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setViewAssignData({});
  };

  const [editLockDialog, setEditLockDialog] = useState(false);

  const editLockDialogOpen = () => {
    setEditLockDialog(true);
  };

  const editLockDialogClose = () => {
    setEditLockDialog(false);
  };
  //Date Time Picker
  // const [value, setValue] = React.useState(null);
  // const [startdate, setStartDate] = React.useState(null);
  // const [enddate, setEndDate] = React.useState(null);

  // const classes = useStyles();

  // Lock Assignment
  const [lockAssignmentDialog, setLockAssignmentDialog] = useState(false);
  const lockAssignmentDialogOpen = () => {
    setLockAssignmentDialog(true);
    // ViewAssignmentLock(lockAssignId)
    //   .then((res) => {
    //     if (res.statusCode === 200) {
    //       setViewAssignData(res.data);
    //     } else {
    //       console.log("something went wrong");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const lockAssignmentDialogClose = () => {
    setLockAssignmentDialog(false);
  };
  const [addEditExternalLocksDialog, setAddEditExternalLocksDialog] =
    useState(false);
  const addEditExternalLocksDialogOpen = () => {
    setAddEditExternalLocksDialog(true);
  };
  const addEditExternalLocksDialogClose = () => {
    setAddEditExternalLocksDialog(false);
  };

  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const lockList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      // sorting: {
      //   order: order,
      //   by: orderBy,
      // },
      ...dataToFilter,
    };
    LockList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLockData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setLockData([]);
          setLoading(false);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const roomList = () => {
    LockerRoomlist()
      .then((res) => {
        if (res.statusCode === 200) {
          setRoomData(res.data);
        } else {
          setRoomData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteLock = () => {
    let url = selected.length > 0 ? selected : id;
    DeleteLock(url)
      .then((res) => {
        if (res.statusCode === 200) {
          lockList();
          handleDeleteClose();
          dispatch(getMsg(`record(s) deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again later"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  const removeAssignmentLock = () => {
    RemoveAssigmentLock(lockAssignId)
      .then((res) => {
        if (res.statusCode === 200) {
          setAnchorEl(null);
          lockList();
        } else {
          console.log("something went wrong");
          setAnchorEl(null);
        }
      })
      .catch((err) => {
        console.log(err);
        setAnchorEl(null);
      });
  };
  useEffect(() => {
    lockList();
    roomList();
  }, [rowsPerPage, currentPage, dataToFilter]);

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  return (
    <div>
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {selected.length || id > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete lock?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={deleteLock} autoFocus variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          roomData={roomData}
          setDataToFilter={setDataToFilter}
          lockList={lockList}
          handleDeleteOpen={handleDeleteOpen}
          tableCalRedux={tableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={lockData.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(lockData, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.brand === true ? "" : "hide-cell"
                          }
                        >
                          {row.brand}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.serial === true ? "" : "hide-cell"
                          }
                        >
                          {row.serial}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.room_name === true ? "" : "hide-cell"
                          }
                        >
                          {row.locker_room}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.locker_number === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.locker_number}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) =>
                              handleActionClick(
                                e,
                                row.id,
                                row.locker_room,
                                row.lock_assignment_id
                              )
                            }
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              sx={{
                                display: lockerRoom != null ? "block" : "none",
                              }}
                              onClick={removeAssignmentLock}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary="Remove Assignment"
                                pl={1}
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                lockAssignmentDialogOpen();
                                handleActionClose();
                              }}
                              sx={{
                                display: lockerRoom != null ? "none" : "block",
                              }}
                            >
                              <ListItemIcon>
                                <DriveFolderUploadIcon />
                              </ListItemIcon>
                              <ListItemText primary="Assign" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                lockAssignmentDialogOpen();
                                handleActionClose();
                              }}
                              sx={{
                                display: lockerRoom != null ? "block" : "none",
                              }}
                            >
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText primary="View Assignment" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={editLockDialogOpen}>
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit Lock" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={handleDeleteOpen}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete Lock" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {lockData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {editLockDialog && (
        <EditLock
          editLockDialogOpen={editLockDialogOpen}
          editLockDialogClose={editLockDialogClose}
          editLockDialog={editLockDialog}
          lockList={lockList}
          id={id}
        />
      )}

      {lockAssignmentDialog && (
        <LockAssignment
          lockAssignmentDialog={lockAssignmentDialog}
          setLockAssignmentDialog={setLockAssignmentDialog}
          lockAssignmentDialogOpen={lockAssignmentDialogOpen}
          lockAssignmentDialogClose={lockAssignmentDialogClose}
          lockList={lockList}
          id={id}
          viewAssignData={viewAssignData}
          lockAssignId={lockAssignId}
        />
      )}
    </div>
  );
}

function Locks() {
  return (
    <React.Fragment>
      <Helmet title="Locks" />
      <Typography variant="h3" gutterBottom display="inline">
        Locks
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Locks;

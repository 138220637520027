import React, { useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { UpdateColor, SaveInlineData } from "../../../api";
import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  FormGroup,
  Button,
  ListItemIcon,
  Box,
  Snackbar,
  Alert,
  Tooltip,
  TextField,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  LockOpen as LockOpenIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";

const useStyles = makeStyles((theme) => ({
  colorPicker: {
    boxShadow: "none !important",
  },
}));
const StyledDropDownColorPicker = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenuItem-root": { padding: 0 },
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        padding: "0px 0 10px",
      },
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);
const Tablerow = ({
  isItemSelected,
  user,
  labelId,
  handleClick,
  webHandleClick,
  mobileHandleClick,
  mfaHandleClick,
  handleClickM,
  StyledMenu,
  anchorEl,
  handleClose,
  ListItemText,
  handleDeleteOpen,
  deleteId,
  NavLink,
  open,
  webToggle,
  mobileToggle,
  mfaToggle,
  clickpwdChangeOpen,
  setAnchorEl,
}) => {
  const dispatch = useDispatch();
  const [web, setWeb] = useState(webToggle);
  const [mobile, setMobile] = useState(mobileToggle);
  const [mfa, setMfa] = useState(user["2fa"] === 1); // Initialize based on 2fa key

  useEffect(() => {
    setMfa(user["2fa"] === 1);
  }, [user["2fa"]]);

  const handleToggle = (event) => {
    const isChecked = event.target.checked;
    setMfa(isChecked); // Update the state to the new value
    mfaHandleClick(isChecked, user.UserID); // Call the function to handle enabling/disabling 2FA
  };
  const [code, setCode] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [msg, setMsg] = useState("");
  // Color Picker Dropdown
  const [colorpicker, setColorPicker] = useState(null);
  const [color, setColor] = useState(user.color);
  const handleClickDropdown = (event, id) => {
    setColorPicker(event.currentTarget);
  };
  const closeColorPicker = () => {
    setColorPicker(null);
    setColor(user.color);
  };
  const handleSave = (id) => {
    setColorPicker(null);
    UpdateColor(id, { color_code: color ? color : user.color })
      .then((res) => {
        if (res.statusCode === 200) {
          setCode(res.statusCode);
          setMsg(`User color updated successfully for ${user.name}`);
          setOpenSnack(true);
          setColorPicker(null);
        } else {
          setMsg("Something went wrong");
          setOpenSnack(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setMsg("Something went wrong");
        setOpenSnack(true);
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const classes = useStyles();
  const checkedValue = useSelector((state) => state.user.checked);

  // Save edited user initial name column data
  const [editedInitial, setEditedInitial] = useState(user.user_initials);
  const handleInitialChange = (event) => {
    setEditedInitial(event.target.value);
  };

  const saveEditedData = (userId) => {
    if (editedInitial !== user.user_initials) {
      SaveInlineData(userId, {
        userInitials: editedInitial ? editedInitial : user.user_initials,
      })
        .then((res) => {
          if (res.statusCode == 200) {
            dispatch(getMsg("Data updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Somthing went wrong please try again!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {});
    }
  };
  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={code === 200 ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <TableRow hover selected={isItemSelected} key={user.id}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, user.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
          />
        </TableCell>
        {checkedValue.includes("first_name") && (
          <TableCell
            sx={{ whiteSpace: "nowrap" }}
            component="th"
            id={labelId}
            scope="row"
            padding="none"
          >
            {user.name}
          </TableCell>
        )}
        {checkedValue.includes("color") && (
          <TableCell>
            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              onClick={handleClickDropdown}
              aria-label="color-picker"
              size="large"
              aria-owns={anchorEl ? "color-picker" : undefined}
              aria-haspopup="true"
            >
              <Box
                sx={{
                  width: 30,
                  height: 20,
                  backgroundColor: code === 200 ? color : user.color,
                  cursor: "pointer",
                }}
              />
              <ArrowDropDownIcon sx={{ cursor: "pointer" }} />
            </Button>
            <StyledDropDownColorPicker
              id="color-picker"
              anchorEl={colorpicker}
              open={Boolean(colorpicker)}
              onClose={() => handleSave(user.id)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem>
                <Box>
                  <ChromePicker
                    color={color}
                    onChange={(updatedColor) => setColor(updatedColor.hex)}
                    className={classes.colorPicker}
                  />
                  <Button onClick={closeColorPicker}>Cancel</Button>
                  <Button
                    onClick={() => handleSave(user.id)}
                    color="primary"
                    variant="outlined"
                    autoFocus
                    sx={{ marginLeft: "5px" }}
                  >
                    Save
                  </Button>
                </Box>
              </MenuItem>
            </StyledDropDownColorPicker>
          </TableCell>
        )}
        {checkedValue.includes("email") && (
          <TableCell sx={{ whiteSpace: "nowrap" }}>{user.email}</TableCell>
        )}
        {checkedValue.includes("user_initials") && (
          <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            align="left"
            sx={{ whiteSpace: "nowrap" }}
          >
            {/* {user.user_initials} */}
            <Tooltip title={"Click to edit"}>
              <TextField
                sx={{
                  width: 230,
                  border: 0,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: 0,
                  },
                }}
                value={editedInitial}
                onChange={handleInitialChange}
                onBlur={() => saveEditedData(user.UserID)}
              />
            </Tooltip>
          </TableCell>
        )}
        {checkedValue.includes("type") && (
          <TableCell sx={{ whiteSpace: "nowrap" }}>{user.type}</TableCell>
        )}
        {checkedValue.includes("web") && (
          <TableCell>
            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                label=""
                checked={web}
                onClick={() => {
                  setWeb(!web);
                  webHandleClick(web, user.id);
                }}
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("mobile") && (
          <TableCell>
            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                label=""
                checked={mobile}
                onClick={() => {
                  setMobile(!mobile);
                  mobileHandleClick(mobile, user.id);
                }}
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("mfa") && (
          <TableCell>
            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                label=""
                checked={mfa}
                onChange={handleToggle}
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("created") && (
          <TableCell>{user.created}</TableCell>
        )}
        {checkedValue.includes("last_seen") && (
          <TableCell>{user.last_seen}</TableCell>
        )}
        {checkedValue.includes("phone") && <TableCell>{user.phone}</TableCell>}
        {checkedValue.includes("ru") && <TableCell>{user.ru}</TableCell>}
        {checkedValue.includes("sec_question") && (
          <TableCell sx={{ minWidth: "250px" }}>{user.sec_question}</TableCell>
        )}
        {checkedValue.includes("sec_answer") && (
          <TableCell sx={{ whiteSpace: "nowrap" }}>{user.sec_answer}</TableCell>
        )}
        {checkedValue.includes("country") && (
          <TableCell>{user.country}</TableCell>
        )}
        <TableCell>
          <IconButton
            id={`demo-positioned-button-${user.id}`}
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClickM(event, user.id)}
            value={user.id}
            color="primary"
          >
            <SettingsIcon />
          </IconButton>
          <StyledMenu
            id={`demo-positioned-menu-${user.id}`}
            component="div"
            sx={{
              padding: "4px !important",
              background: "transparent",
            }}
            aria-labelledby={`demo-positioned-button-${user.id}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={handleClose}
              aria-label="details"
              component={NavLink}
              to={`/pages/viewuser/${deleteId}`}
              size="large"
            >
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="View" pl={1} />
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              aria-label="details"
              component={NavLink}
              to={`/users/edituser/${deleteId}`}
              size="large"
            >
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" pl={1} />
            </MenuItem>
            <MenuItem onClick={clickpwdChangeOpen}>
              <ListItemIcon>
                <LockOpenIcon />
              </ListItemIcon>
              <ListItemText primary="Pwd Change" pl={1} />
            </MenuItem>
            <MenuItem onClick={(handleClose, handleDeleteOpen)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" pl={1} />
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Tablerow;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  irClassificationList: {
    classificationName: true,
    count: true,
    critical: true,
    nurseNotify: true,
    imgRequired: true,
    taskCount: true,
    activeStatus: true,
    action: true,
  },
};

export const defaultIrClassificationTableColSlice = createSlice({
  name: "irClassification",
  initialState,
  reducers: {},
});

export const {} = defaultIrClassificationTableColSlice.actions;

export default defaultIrClassificationTableColSlice.reducer;

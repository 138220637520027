import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import StorageLocation from "./Inner-components/StorageLocation";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import {
  UpdatePackage,
  ViewPackage,
  StorageLocationDropDown,
  GetPackageStorage,
  TenantDropdown,
  DepartmentList,
} from "../../api";
import { format } from "date-fns";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Grid,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  TextField,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  FormGroup,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  Box,
  Tooltip,
  CircularProgress,
  Paper,
  FormHelperText,
  CardActions,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Error as ErrorIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from "@mui/icons-material";
import { DatePicker, DateTimePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import GoogleAutoCompleteField from "../../components/form-components/GoogleAutoCompleteField";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import SignatureCanvas from "react-signature-canvas";
import CameraFeeding from "../../helper/camera-feeding";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Image = styled.img`
  max-width: fit-content;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  object-fit: contain;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;

const ImageWrapper = styled.div`
  height: 200px;
  overflow: hidden;
  &:before {
  }
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  VerticalTab: {
    "& .MuiTabs-flexContainer": {
      display: "block",
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "3px",

      "& .MuiTab-root": {
        display: "block",
        width: "105px",
        height: "105px",
        marginLeft: "auto",
        marginBottom: "8px",
        border: "1px solid",
        borderRadius: "10px",
        borderColor:
          theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar-thumb": {
      backgroundColor: "#e0e0e0",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const tenant = [
  { key: "General Incident Report", value: "general_incident_report" },
  { key: "Alleged Food Illness", value: "food_illness" },
  { key: "AED Deployment", value: "aed_deployment" },
];
const weight = [
  { key: "0-1 lb.", value: "0-1 lb." },
  { key: "2-5 lbs.", value: "2-5 lbs." },
  { key: "6-10 lbs.", value: "6-10 lbs." },
  { key: "11-20 lbs.", value: "11-20 lbs." },
  { key: "over 20 lbs.", value: "over 20 lbs." },
];
const currentStorageLocation = [
  { key: "In person", value: "In person" },
  { key: "Spoke", value: "Spoke" },
  { key: "Phone", value: "Phone" },
  { key: "Voicemail", value: "Voicemail" },
  { key: "Email", value: "Email" },
  { key: "Note to guestroom", value: "Note to guestroom" },
];
const departmentSelect = [
  { key: "General Incident Report", value: "general_incident_report" },
  { key: "Alleged Food Illness", value: "food_illness" },
  { key: "AED Deployment", value: "aed_deployment" },
];
const AddPackageForm = (props) => {
  const classes = useStyles();
  const [guestArrivalDate, setArrivalDate] = React.useState(null);
  const [packageReceivedDate, setPackageReceivedDate] = React.useState(null);
  const [deliveryDatetime, setDeliveryDatetime] = React.useState(null);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const [department, setDepartment] = useState("guest");
  const handleDepartment = (event) => {
    setDepartment(event.target.value);
    console.log(event.target.value);
  };
  const [showWebCam, setShowWebCam] = React.useState(false);
  const uploadedFileRef = useRef();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isImage, setIsImage] = useState(true);
  //Signature pad
  //Ref for signature pad
  const sigCanvas = useRef({});
  const [signImage, setSignImage] = useState(null);
  const clearPad = (e) => {
    e.preventDefault();
    setIsImage(false);
    sigCanvas.current.clear();
    setSignImage(null);
  };
  const savePad = (e) => {
    e.preventDefault();
    setSignImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    dispatch(getMsg("Signature saved successfully"));
    dispatch(getSnackAlert("success"));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };

  // useEffect(() => {
  //   setSignImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  // }, [signImage]);

  const [storageData, setStorageData] = useState([]);
  const storageList = () => {
    StorageLocationDropDown()
      .then((res) => {
        if (res.statusCode === 200) {
          setStorageData(res.data);
        } else {
          setStorageData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [currentGridLocaiton, setCurrentGridLocaiton] = useState([]);
  const getPackageStorage = (value) => {
    GetPackageStorage({ params: { parent_id: value } })
      .then((res) => {
        if (res.statusCode === 200) {
          setCurrentGridLocaiton(res.data);
        } else {
          setCurrentGridLocaiton([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [departmentList, setDepartmentList] = useState([]);
  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [viewData, setViewData] = useState({});
  const viewPackage = () => {
    ViewPackage(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewData(res.data);
          getPackageStorage(res.data.current_storage_location);
          setUploadedImage(res.data.receive_package_image);
        } else {
          setViewData({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [tenantData, setTenantData] = useState([]);
  const tenantDropdownList = () => {
    TenantDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setTenantData(res.data);
        } else {
          setTenantData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleShowWebCam = () => {
  //   let changeSetShowWebCam = !showWebCam;
  //   setShowWebCam(changeSetShowWebCam);
  // };
  // const handleFileChange = (evt) => {
  //   let file = evt.target.files[0];
  //   getFileBase64(file, (result) => {
  //     setUploadedImage(result);
  //   });
  // };

  // const clearSelectedImage = () => {
  //   setUploadedImage(null);
  //   uploadedFileRef.current.value = "";
  // };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };

  const handleFileChange = useCallback(
    (evt) => {
      let file = evt.target.files[0];
      getFileBase64(file, (result) => {
        setUploadedImage(result);
      });
    },
    [setUploadedImage]
  );

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };
  useEffect(() => {
    viewPackage();
    storageList();
    getDepartmentList();
    tenantDropdownList();
  }, []);

  function isUrl(str) {
    const pattern =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return pattern.test(str);
  }
  const {
    package_uuid,
    // guest_first_name = "",
    guest_last_name,
    guest_address_1,
    guest_address_2,
    guest_city,
    guest_state,
    guest_country,
    guest_zip,
    guest_phone,
    guest_arrival_date,
    guest_email,
    group_name,
    group_contact_person_name,
    group_contact_person_phone,
    group_contact_person_email,
    guest_registered,
    notify_by_mail,
    guest_room_no,
    arrival_date,
    departure_date,
    package_status,
    package_arrival_date,
    carrier_dropping,
    other_pack_drop,
    tracking_number,
    recipient_first_name,
    recipient_last_name,
    recipient_group_name,
    sender_name,
    package_size,
    pallet_id,
    package_weight,
    handling_instruction,
    description_note,
    current_storage_location,
    recipient_notified,
    how_contacted,
    how_contacted_date,
    recipient_notification,
    followup_required,
    followup_datetime,
    delivery_to_location,
    other_guestroom_number,
    ballroom_name,
    other_delivery_location,
    receiver_first_name,
    receiver_last_name,
    receiver_gender,
    receiver_id,
    delivery_notes,
    delivery_datetime,
    client_notification,
    webCamImage,
    signature_image,
    receive_package_image,
    tenant,
    current_grid_location,
  } = viewData;
  const validationSchema = Yup.object().shape({
    package_status: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Expected", "Received", "Delivered"]),
    package_received_date: Yup.string()
      .nullable()
      .required("Please select the package arrival date"),

    carrier_dropping: Yup.string()
      .required("Please select one of these options")
      .oneOf(["DHL", "Fed-Ex", "UPS", "USPS", "Amazon", "OnTrac", "Other"]),
    tracking_number: Yup.string().required("Please enter tracking number"),
    recipient_first_name: Yup.string().required(
      "Please enter recipient first name"
    ),
    recipient_last_name: Yup.string().required(
      "Please enter recipient last name"
    ),
    sender_name: Yup.string().required("Please enter sender name"),
    package_size: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Envelope", "Tube", "Box", "Brate", "Pallet"]),
    package_notify: Yup.string()
      .required("Please select one of these options")
      .oneOf(["emp", "guest"]),
    current_storage_location: Yup.string().required(
      "Please select current storage location"
    ),

    followup_datetime: Yup.string()
      .nullable()
      .when(["followup_required"], (same, schema) => {
        return same == true
          ? schema.required("Please enter date and time")
          : schema;
      }),
    delivery_to_location: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Guest", "Ballroom", "Returned to sender", "Other"]),

    fname_of_person_receiving_package: Yup.string().required(
      "Please enter recipient first name"
    ),
    lname_of_person_receiving_package: Yup.string().required(
      "Please enter recipient first name"
    ),
    delivery_datetime: Yup.string()
      .nullable()
      .required("Please select the delivery date"),
  });

  let initialValues = {
    r_first_name: viewData
      ? viewData.guest_first_name
        ? viewData.guest_first_name
        : ""
      : "",
    r_last_name: viewData
      ? viewData.guest_last_name
        ? viewData.guest_last_name
        : ""
      : "",
    addrss_1: viewData
      ? viewData.guest_address_1
        ? viewData.guest_address_1
        : ""
      : "",
    address_2: viewData
      ? viewData.guest_address_2
        ? viewData.guest_address_2
        : ""
      : "",
    city: viewData ? (viewData.guest_city ? viewData.guest_city : "") : "",
    state: viewData ? (viewData.guest_state ? viewData.guest_state : "") : "",
    zip: viewData ? (viewData.guest_zip ? viewData.guest_zip : "") : "",
    phone: viewData ? (viewData.guest_phone ? viewData.guest_phone : "") : "",
    guest_arrival_date: viewData
      ? viewData.guest_arrival_date
        ? new Date(viewData.guest_arrival_date)
        : new Date()
      : null,
    email: viewData ? (viewData.guest_email ? viewData.guest_email : "") : "",
    gname: viewData ? (viewData.group_name ? viewData.group_name : "") : "",
    contact_person_name: viewData
      ? viewData.group_contact_person_name
        ? viewData.group_contact_person_name
        : ""
      : "",
    contact_person_phone: viewData
      ? viewData.group_contact_person_phone
        ? viewData.group_contact_person_phone
        : ""
      : "",
    contact_person_email: viewData
      ? viewData.group_contact_person_email
        ? viewData.group_contact_person_email
        : ""
      : "",
    country: viewData
      ? viewData.guest_country
        ? viewData.guest_country
        : ""
      : "",
    tenant: viewData ? (viewData.tenant ? viewData.tenant : "") : "",
    guest_registered: viewData
      ? viewData.guest_registered
        ? viewData.guest_registered
        : ""
      : "",
    notify_recipient: viewData
      ? viewData.notify_by_mail == 1
        ? true
        : false
      : false,
    guestroom_number: viewData
      ? viewData.guest_room_no
        ? viewData.guest_room_no
        : ""
      : "",
    arrival_date: viewData
      ? viewData.arrival_date
        ? new Date(viewData.arrival_date)
        : null
      : null,
    departure_date: viewData
      ? viewData.departure_date
        ? new Date(viewData.departure_date)
        : null
      : null,
    //packages stated
    package_status: viewData
      ? viewData.package_status
        ? viewData.package_status
        : ""
      : "",
    package_received_date: viewData
      ? viewData.package_arrival_date
        ? new Date(viewData.package_arrival_date)
        : new Date()
      : new Date(),
    carrier_dropping: viewData
      ? viewData.carrier_dropping
        ? viewData.carrier_dropping
        : ""
      : "",
    other_carrier: viewData
      ? viewData.other_pack_drop
        ? viewData.other_pack_drop
        : ""
      : "",
    package_notify: viewData
      ? viewData.package_notify
        ? viewData.package_notify
        : ""
      : "",
    department: viewData
      ? viewData.department_id
        ? viewData.department_id
        : ""
      : "",
    tracking_number: viewData
      ? viewData.tracking_number
        ? viewData.tracking_number
        : ""
      : "",
    recipient_first_name: viewData
      ? viewData.recipient_first_name
        ? viewData.recipient_first_name
        : ""
      : "",
    recipient_last_name: viewData
      ? viewData.recipient_last_name
        ? viewData.recipient_last_name
        : ""
      : "",
    recipient_g_name: viewData
      ? viewData.recipient_group_name
        ? viewData.recipient_group_name
        : ""
      : "",
    sender_name: viewData
      ? viewData.sender_name
        ? viewData.sender_name
        : ""
      : "",
    package_size: viewData
      ? viewData.package_size
        ? viewData.package_size
        : ""
      : "",
    pallet_id: viewData ? (viewData.pallet_id ? viewData.pallet_id : "") : "",
    weight: viewData
      ? viewData.package_weight
        ? viewData.package_weight
        : ""
      : "",
    special_handeling_ins: viewData
      ? viewData.handling_instruction
        ? viewData.handling_instruction
        : ""
      : "",
    package_description: viewData
      ? viewData.description_note
        ? viewData.description_note
        : ""
      : "",
    current_storage_location: viewData
      ? viewData.current_storage_location
        ? viewData.current_storage_location
        : ""
      : "",
    current_grid_location: viewData
      ? viewData.current_grid_location
        ? viewData.current_grid_location
        : ""
      : "",
    recipient_notified: viewData
      ? viewData.recipient_notified == 1
        ? true
        : false
      : false,
    how_contacted: viewData
      ? viewData.how_contacted
        ? viewData.how_contacted
        : ""
      : "",
    date_and_time_contacted: viewData
      ? new Date(viewData.how_contacted_date)
        ? new Date(viewData.how_contacted_date)
        : null
      : null,
    recipient_notification: viewData
      ? viewData.recipient_notification == 1
        ? true
        : false
      : false,
    followup_required: viewData
      ? viewData.followup_required == 1
        ? true
        : false
      : false,
    followup_datetime: viewData
      ? new Date(viewData.followup_datetime)
        ? new Date(viewData.followup_datetime)
        : null
      : null,
    //Delivery started
    delivery_to_location: viewData
      ? viewData.delivery_to_location
        ? viewData.delivery_to_location
        : ""
      : "",
    other_guestroom_number: viewData
      ? viewData.other_guestroom_number
        ? viewData.other_guestroom_number
        : ""
      : "",
    ballroom_name: viewData
      ? viewData.ballroom_name
        ? viewData.ballroom_name
        : ""
      : "",
    other_delivery_location: viewData
      ? viewData.other_delivery_location
        ? viewData.other_delivery_location
        : ""
      : "",
    fname_of_person_receiving_package: viewData
      ? viewData.receiver_first_name
        ? viewData.receiver_first_name
        : ""
      : "",
    lname_of_person_receiving_package: viewData
      ? viewData.receiver_last_name
        ? viewData.receiver_last_name
        : ""
      : null,
    identification_verified: viewData
      ? viewData.receiver_id
        ? viewData.receiver_id
        : ""
      : "",
    delivery_datetime: viewData
      ? viewData.delivery_datetime
        ? new Date(viewData.delivery_datetime)
        : null
      : null,
    delivery_notes: viewData
      ? viewData.delivery_notes
        ? viewData.delivery_notes
        : ""
      : "",
    client_notification: viewData
      ? viewData.client_notification
        ? viewData.client_notification
        : false
      : false,
  };

  console.log({ initialValues });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        id: id,
        guest_first_name: values.r_first_name,
        guest_last_name: values.r_last_name,
        guest_address_1: values.addrss_1,
        guest_address_2: values.address_2,
        guest_city: values.city,
        guest_state: values.state,
        guest_zip: values.zip,
        guest_phone: values.phone,
        guest_arrival_date:
          values.guest_arrival_date !== null
            ? format(values.guest_arrival_date, "yyyy-MM-dd")
            : format(guest_arrival_date, "yyyy-MM-dd"),
        guest_email: values.email,
        group_name: values.gname,
        notify_by_mail: values.notify_recipient ? 1 : null,
        group_contact_person_name: values.contact_person_name,
        group_contact_person_phone: values.contact_person_phone,
        group_contact_person_email: values.contact_person_email,
        guest_country: values.country,
        guest_registered: values.guest_registered,
        arrival_date:
          values.arrival_date !== null
            ? format(values.arrival_date, "yyyy-MM-dd")
            : null,
        departure_date:
          values.departure_date !== null
            ? format(values.departure_date, "yyyy-MM-dd")
            : null,
        tenant: values.tenant,

        package_status: values.package_status,
        package_arrival_date:
          values.package_received_date !== null
            ? format(values.package_received_date, "yyyy-MM-dd HH:mm:ss")
            : format(package_arrival_date, "yyyy-MM-dd HH:mm:ss"),
        carrier_dropping: values.carrier_dropping,
        package_notify: values.package_notify,
        other_pack_drop: values.other_carrier,
        tracking_number: values.tracking_number,
        department_id: values.department,
        recipient_first_name: values.recipient_first_name,
        recipient_last_name: values.recipient_last_name,
        recipient_group_name: values.recipient_g_name,
        sender_name: values.sender_name,
        guest_room_no: values.guestroom_number,
        package_size: values.package_size,
        pallet_id: values.pallet_id,
        package_weight: values.weight,
        handling_instruction: values.special_handeling_ins,
        description_note: values.package_description,
        current_storage_location: storageData?.find(
          (it) => values.current_storage_location == it.value
        ).key,
        current_grid_location: values.current_grid_location,
        recipient_notified: values.recipient_notified ? 1 : null,
        how_contacted: values.how_contacted,
        how_contacted_date:
          values.date_and_time_contacted !== null
            ? format(values.date_and_time_contacted, "yyyy-MM-dd HH:mm:ss")
            : format(how_contacted_date, "yyyy-MM-dd HH:mm:ss"),
        recipient_notification: values.recipient_notification ? 1 : null,
        followup_required: values.followup_required ? 1 : null,
        followup_datetime:
          values.followup_datetime !== null
            ? format(values.followup_datetime, "yyyy-MM-dd HH:mm:ss")
            : format(followup_datetime, "yyyy-MM-dd HH:mm:ss"),
        receive_package_image: isUrl(uploadedImage) ? null : uploadedImage,
        delivery_to_location: values.delivery_to_location,
        other_delivery_location: values.other_delivery_location,
        other_guestroom_number: values.other_guestroom_number,
        ballroom_name: values.ballroom_name,
        receiver_first_name: values.fname_of_person_receiving_package,
        receiver_last_name: values.lname_of_person_receiving_package,
        // receiver_gender
        receiver_id: values.identification_verified,
        delivery_datetime:
          values.delivery_datetime !== null
            ? format(values.delivery_datetime, "yyyy-MM-dd HH:mm:ss")
            : format(delivery_datetime, "yyyy-MM-dd HH:mm:ss"),
        delivery_notes: values.delivery_notes,
        client_notification: values.client_notification ? 1 : null,
        signature_image: signImage ? signImage : null,
      };

      UpdatePackage(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record submitted successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
            navigate({
              pathname: "/packages/list-packages",
            });
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <Box className={classes.accordion}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="guest-information"
                          id="guest-information-header"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            Guest Information
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  name="r_first_name"
                                  label="Recipient first name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="John"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  name="r_last_name"
                                  label="Recipient last name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Smith"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormControl mt={3}>
                                  <GoogleAutoCompleteField
                                    name="addrss_1"
                                    label="Address1"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                    placeholder="3214 N University Ave"
                                    getApiResponse={(res) => {
                                      setFieldValue(
                                        "addrss_1",
                                        res.formatted_address
                                      );
                                      setFieldValue("city", res.locality);
                                      setFieldValue(
                                        "state",
                                        res.administrative_area_level_1
                                      );
                                      setFieldValue("zip", res.postal_code);
                                      setFieldValue("country", res.country);
                                    }}
                                  />
                                </FormControl>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="address_2"
                                  label="Address 2"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Apt 23"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="city"
                                  label="City"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Miami"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="state"
                                  label="State"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="FL"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="zip"
                                  label="ZIP"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="xxxxx"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="phone"
                                  label="Phone"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="xxx-xxx-xxxx"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <DatePicker
                                  name="guest_arrival_date"
                                  label="Guest arrival date"
                                  value={values.guest_arrival_date}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      "guest_arrival_date",
                                      newValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "mm/dd/yyyy",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="email"
                                  label="Email"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="j.smith@microsoft.com"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="gname"
                                  label="Group name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Microsoft"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="contact_person_name"
                                  label="Group contact person name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Stacy Taylor"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="contact_person_phone"
                                  label="Group contact person phone"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="xxx-xxx-xxxx"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="contact_person_email"
                                  label="Group contact person email"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="me@groupname.com"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="country"
                                  label="Country"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="USA"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="tenant"
                                  fieldLabel="Tenant"
                                  options={tenantData}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  displayEmpty
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Guest registered?</FormLabel>
                                <RadioGroup
                                  name="guest_registered"
                                  aria-labelledby="guest-registered"
                                  row
                                  value={values.guest_registered}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "guest_registered",
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="notify_recipient"
                                      checked={values.notify_recipient}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "notify_recipient",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label="Notify recipient via email when package arrives"
                                />
                              </FormControl>
                            </Grid>
                            {values.guest_registered == "Yes" && (
                              <Grid item xs={12} md={12}>
                                <Grid container spacing={6}>
                                  <Grid item xs={12} md={4}>
                                    <FormControl>
                                      <CustomTextField
                                        fullWidth
                                        name="guestroom_number"
                                        label="Guestroom number"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder=""
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormControl>
                                      <DatePicker
                                        name="arrival_date"
                                        label="Arrival date (from reservation)"
                                        ampm={false}
                                        value={values.arrival_date}
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            "arrival_date",
                                            newValue
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "mm/dd/yyyy",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormControl>
                                      <DatePicker
                                        name="departure_date"
                                        label="Departure date (from reservation)"
                                        value={values.departure_date}
                                        ampm={false}
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            "departure_date",
                                            newValue
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "mm/dd/yyyy",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion mt={3} defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="package-information"
                          id="package-information-header"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            Package Information
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Package status *</FormLabel>
                                <RadioGroup
                                  name="package_status"
                                  aria-labelledby="package-status"
                                  row
                                  value={values.package_status}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "package_status",
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Expected"
                                    value="Expected"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Received"
                                    value="Received"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Delivered"
                                    value="Delivered"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormHelperText error>
                                {touched.package_status &&
                                  errors.package_status}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <DateTimePicker
                                  name="package_received_date"
                                  label="Package received date *"
                                  value={values.package_received_date}
                                  ampm={false}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      "package_received_date",
                                      newValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...params}
                                      fullWidth
                                      name="package_received_date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "mm/dd/yyyy H:i:s",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>
                                  Carrier dropping off package *
                                </FormLabel>
                                <RadioGroup
                                  name="carrier_dropping"
                                  aria-labelledby="carrier-dropping"
                                  row
                                  value={values.carrier_dropping}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "carrier_dropping",
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="DHL"
                                    value="DHL"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Fed-Ex"
                                    value="Fed-Ex"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="UPS"
                                    value="UPS"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="USPS"
                                    value="USPS"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Amazon"
                                    value="Amazon"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="OnTrac"
                                    value="OnTrac"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Other"
                                    value="Other"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormHelperText error>
                                {touched.carrier_dropping &&
                                  errors.carrier_dropping}
                              </FormHelperText>
                              {values.carrier_dropping == "Other" && (
                                <FormControl>
                                  <CustomTextField
                                    fullWidth
                                    name="other_carrier"
                                    label="Other Carrier"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                    placeholder="other carrier"
                                  />
                                </FormControl>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="handle-dept"
                                  name="package_notify"
                                  value={values.package_notify}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "package_notify",
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    label="Guest"
                                    value="guest"
                                    control={<Radio />}
                                  />
                                  <FormControlLabel
                                    label="Employee"
                                    value="emp"
                                    control={<Radio />}
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormHelperText error>
                                {touched.package_notify &&
                                  errors.package_notify}
                              </FormHelperText>
                              {values.package_notify == "emp" && (
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <CustomSelect
                                      name="department"
                                      fieldLabel="Department"
                                      options={departmentList}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      displayEmpty
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                {uploadedImage ? (
                                  <BigAvatar
                                    alt="image name"
                                    src={uploadedImage}
                                  />
                                ) : (
                                  <BigAvatar
                                    alt="image name"
                                    src="/static/img/avatars/profile-upload.png"
                                  />
                                )}

                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    JustifyContent: "space-between",
                                    margin: "10px 0 15px",
                                    gap: "15px",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    component="span"
                                    onClick={(e) => clearSelectedImage(e)}
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="warning"
                                    component="span"
                                    onClick={() => handleShowWebCam()}
                                  >
                                    {showWebCam ? "Close WebCam" : "WebCam"}
                                  </Button>
                                </Box>
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id="raised-button-file"
                                  multiple
                                  type="file"
                                  ref={uploadedFileRef}
                                  onChange={(e) => handleFileChange(e)}
                                />
                                <label htmlFor="raised-button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                  >
                                    Choose image
                                  </Button>
                                </label>
                              </FormControl>
                              <FormHelperText>
                                Your image Add optional image of the package as
                                received
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="tracking_number"
                                  label="Tracking number *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Scan to enter package tracking barcode"
                                  InputProps={
                                    {
                                      // InputProps
                                    }
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="recipient_first_name"
                                  label="Guest first name *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="John"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="recipient_last_name"
                                  label="Guest last name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Smith"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="recipient_g_name"
                                  label="Recipient group name *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Microsoft"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="sender_name"
                                  label="Sender name *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="ACME product, Inc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Package size *</FormLabel>
                                <RadioGroup
                                  name="package_size"
                                  aria-labelledby="package-size"
                                  row
                                  value={values.package_size}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "package_size",
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Envelope"
                                    value="Envelope"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Tube"
                                    value="Tube"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Box"
                                    value="Box"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Crate"
                                    value="Crate"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Pallet"
                                    value="Pallet"
                                  />
                                </RadioGroup>
                              </FormControl>
                              {values.package_size == "Pallet" && (
                                <FormControl>
                                  <CustomTextField
                                    fullWidth
                                    name="pallet_id"
                                    label="Pallet ID"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                    placeholder="pallet id"
                                  />
                                </FormControl>
                              )}
                              <FormHelperText error>
                                {touched.package_size && errors.package_size}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="weight"
                                  fieldLabel="Weight"
                                  options={weight}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  displayEmpty
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="special_handeling_ins"
                                  label="Special handeling instructions"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Fragile, requires refrigeration, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  fullWidth
                                  name="package_description"
                                  label="Package description"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Small brown box"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="current_storage_location"
                                  fieldLabel="Current storage location *"
                                  options={storageData}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  displayEmpty
                                />
                              </FormControl>
                              <FormHelperText error>
                                {touched.current_storage_location &&
                                  errors.current_storage_location}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {/* <FormControl>
                                <CustomSelect
                                  name="current_grid_location"
                                  fieldLabel="Current Grid Location"
                                  options={currentGridLocaiton}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  displayEmpty
                                />
                              </FormControl> */}
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Current Grid Location
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="current_grid_location"
                                  value={values.current_grid_location}
                                  onChange={handleChange}
                                  label=" Current Grid Location"
                                  displayEmpty
                                  // InputLabelProps={{
                                  //   shrink: true,
                                  // }}
                                >
                                  {/* <MenuItem value="">Select</MenuItem> */}
                                  {currentGridLocaiton.map((item) => {
                                    return (
                                      <MenuItem value={item.id} key={item.id}>
                                        {item.description}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="recipient_notified"
                                      checked={values.recipient_notified}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "recipient_notified",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label="Recipient notified"
                                />
                              </FormControl>
                            </Grid>
                            {values.recipient_notified && (
                              <>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <CustomSelect
                                      name="how_contacted"
                                      fieldLabel="How Contacted"
                                      options={currentStorageLocation}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      displayEmpty
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <DateTimePicker
                                      name="date_and_time_contacted"
                                      label="Date and Time Contacted"
                                      value={values.date_and_time_contacted}
                                      ampm={false}
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          "date_and_time_contacted",
                                          newValue
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          name="date_and_time_contacted"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "mm/dd/yyyy H:i:s",
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="recipient_notification"
                                        checked={values.recipient_notification}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "recipient_notification",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label="Notify staff when package is delivered"
                                  />
                                  <Tooltip
                                    sx={{ marginTop: "8px" }}
                                    title="Checking this will send a notification by email and/or SMS to recipients listed in communication screen, Package Notifiy - Guest tab when the package is successfully delivered"
                                    placement="top"
                                    color="primary"
                                  >
                                    <ErrorIcon />
                                  </Tooltip>
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="followup_required"
                                        checked={values.followup_required}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "followup_required",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label="Notify staff after a certain date if package is not deliverd yet"
                                  />
                                  <Tooltip
                                    sx={{ marginTop: "8px" }}
                                    title="Checking this will allow you to set a date and time after which notifications will be sent to recipients listed in the communication screen, Undelivered Package tab if the package is not delivered by the date entered"
                                    placement="top"
                                    color="primary"
                                  >
                                    <ErrorIcon />
                                  </Tooltip>
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            {values.followup_required && (
                              <Grid item xs={12} md={6}>
                                <FormLabel>
                                  At the date and time chosen here, recipients
                                  listed in the Communication Screen,
                                  Undelivered Package tab, will be notified by
                                  email and/or text if the package status is
                                  undelivered
                                </FormLabel>
                                <FormControl>
                                  <DateTimePicker
                                    name="followup_datetime"
                                    // label=""
                                    value={values.followup_datetime}
                                    ampm={false}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        "followup_datetime",
                                        newValue
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <CustomTextField
                                        {...params}
                                        fullWidth
                                        name="followup_datetime"
                                        // InputLabelProps={{
                                        //   shrink: true,
                                        // }}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "mm/dd/yyyy H:i:s",
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion mt={3}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="delivery-information"
                          id="delivery-information-header"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            Delivery Information
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Delivery to location*</FormLabel>
                                <RadioGroup
                                  name="delivery_to_location"
                                  aria-labelledby="delivery-to-location"
                                  row
                                  value={values.delivery_to_location}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "delivery_to_location",
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Guest"
                                    value="Guest"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Ballroom"
                                    value="Ballroom"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Returned to sender"
                                    value="Returned to sender"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Other"
                                    value="Other"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormHelperText error>
                                {touched.delivery_to_location &&
                                  errors.delivery_to_location}
                              </FormHelperText>
                              {values.delivery_to_location == "Guest" && (
                                <FormControl mt={3}>
                                  <CustomTextField
                                    fullWidth
                                    name="other_guestroom_number"
                                    autoComplete="off"
                                    placeholder="Guestroom number, front dest etc "
                                  />
                                </FormControl>
                              )}
                              {values.delivery_to_location == "Ballroom" && (
                                <FormControl mt={3}>
                                  <CustomTextField
                                    fullWidth
                                    name="ballroom_name"
                                    autoComplete="off"
                                    placeholder="Ballroom name"
                                  />
                                </FormControl>
                              )}
                              {values.delivery_to_location == "Other" && (
                                <FormControl mt={3}>
                                  <CustomTextField
                                    fullWidth
                                    name="other_delivery_location"
                                    autoComplete="off"
                                    placeholder="Delivery location other than guestroom"
                                  />
                                </FormControl>
                              )}
                              <FormControl mt={3}>
                                <CustomTextField
                                  fullWidth
                                  name="fname_of_person_receiving_package"
                                  label="First name of person receiving package*"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Mark"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lname_of_person_receiving_package"
                                  label="Last name of person receiving package*"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Johnson"
                                />
                              </FormControl>
                              <FormControl mt={3}>
                                <FormLabel>Identification verified</FormLabel>
                                <RadioGroup
                                  name="identification_verified"
                                  aria-labelledby="identification-verified"
                                  row
                                  value={values.identification_verified}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "identification_verified",
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Driver license"
                                    value="Driver license"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Passport"
                                    value="Passport"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Military ID"
                                    value="Military ID"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormControl mt={3}>
                                <DateTimePicker
                                  name="delivery_datetime"
                                  label="Delivery datetime*"
                                  value={values.delivery_datetime}
                                  ampm={false}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      "delivery_datetime",
                                      newValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...params}
                                      fullWidth
                                      name="delivery_datetime"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "mm/dd/yyyy H:i:s",
                                      }}
                                    />
                                  )}
                                />
                                <FormControl mt={6}>
                                  <CustomTextField
                                    fullWidth
                                    name="delivery_notes"
                                    label="Delivery Notes"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                    placeholder="Given to (name), placed in vacant guestroom, etc."
                                  />
                                </FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="client_notification"
                                      checked={values.client_notification}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "client_notification",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label="Email delivery receipt to the guest"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <ImageWrapper>
                                <Image
                                  alt="SaaS Dashboard | SOVA"
                                  src="/static/img/lost-found/lost_and_found_pic.jpg"
                                />
                              </ImageWrapper>
                              <FormHelperText
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <span> Image of the package as delivered</span>{" "}
                                <Tooltip
                                  title="The image above is captured from the mobile app when delivering the package"
                                  placement="right-end"
                                  color="primary"
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControl>
                                <Typography>
                                  ACKNOWLEDGED RECEIPT OF GOODS
                                </Typography>
                                <FormLabel>
                                  I, ,acknowledge receipt and delivery of the
                                  goods described on this form. The undersigned
                                  further acknowledges that said goods have been
                                  inspected and are without visible signs of
                                  defect and final acceptance is confirmed.
                                </FormLabel>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Card
                                variant="outlined"
                                className="signatureCanvas"
                              >
                                {signature_image && isImage ? (
                                  <Image src={signature_image} />
                                ) : (
                                  <CardContent>
                                    <SignatureCanvas ref={sigCanvas} />
                                  </CardContent>
                                )}
                              </Card>
                              <Box style={{ display: "flex", marginTop: 12 }}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={(e) => savePad(e)}
                                >
                                  Save
                                </Button>
                                <Button
                                  style={{ marginLeft: 5 }}
                                  color="primary"
                                  variant="outlined"
                                  onClick={
                                    isImage
                                      ? () => setIsImage(false)
                                      : (e) => clearPad(e)
                                  }
                                >
                                  Clear
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} alignSelf="center">
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <KeyboardBackspaceIcon
                                  sx={{ marginRight: "15px" }}
                                />
                                <Typography
                                  variant="h6"
                                  component="div"
                                  gutterBottom
                                  sx={{ marginBottom: 0 }}
                                >
                                  Signature
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Update package
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {showWebCam && (
        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
    </>
  );
};
function EditPackage() {
  // Storage Location
  const [storageLocation, setStorageLocation] = React.useState(false);
  const storageLocationOpen = () => {
    setStorageLocation(true);
  };
  const storageLocationClose = () => {
    setStorageLocation(false);
  };
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Helmet title="Edit Package" />
          <Typography variant="h3" gutterBottom display="inline">
            Edit Package
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/packages/list-packages">
              List Packages
            </Link>
            <Typography>Edit Package</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item xs={12} md={6} textAlign="right">
          <Button
            onClick={storageLocationOpen}
            color="primary"
            variant="outlined"
            sx={{ marginRight: "10px" }}
          >
            Storage Location
          </Button>
          <Button color="primary" variant="outlined">
            Add/Edit Tenant
          </Button>
        </Grid> */}
      </Grid>
      {storageLocation && (
        <StorageLocation
          storageLocation={storageLocation}
          setStorageLocation={setStorageLocation}
          storageLocationOpen={storageLocationOpen}
          storageLocationClose={storageLocationClose}
        />
      )}

      <Divider my={6} />
      <AddPackageForm />
    </React.Fragment>
  );
}
export default EditPackage;

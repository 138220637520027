import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import AddEditTrainedResponder from "./trained-responders-inner-component/AddEditTrainedResponder";
import UpdateTraining from "./UpdateTraining";
import moment from "moment";

import {
  Checkbox,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  FormControl as MuiFormControl,
  Select,
  Pagination,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  CameraAlt as CameraAltIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { makeStyles } from "@mui/styles";
import {
  AedTrainedResponderListing,
  DeleteTrainedResponder,
  ViewTrainedResponderInfo,
} from "../../../api";
import ImageListingModal from "../../components/ImageGallery/ImageListingModal";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { handleApiResonseErrors } from "../../../helper/helper";
const Paper = styled(MuiPaper)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #0000000f",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  name,
  dept,
  trainingdate,
  coordinator,
  eri,
  cert,
  permits,
  action
) {
  return {
    name,
    dept,
    trainingdate,
    coordinator,
    eri,
    cert,
    permits,
    action,
  };
}
const rows = [
  createData(
    "Sandeep Kundu",
    "security test",
    "02/20/2021",
    "No",
    "No",
    "",
    "S",
    0
  ),
  createData(
    "Derin Redding",
    "security test",
    "02/20/2021",
    "No",
    "No",
    "",
    "S",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "name",
    numeric: true,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "dept",
    numeric: true,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "trainingdate",
    numeric: true,
    disablePadding: false,
    label: "Training Date",
  },
  {
    id: "coordinator",
    numeric: true,
    disablePadding: false,
    label: "Coordinator",
  },
  {
    id: "eri",
    numeric: true,
    disablePadding: false,
    label: "ERT",
  },
  {
    id: "cert",
    numeric: true,
    disablePadding: false,
    label: "Cert.",
  },
  {
    id: "permits",
    numeric: true,
    disablePadding: false,
    label: "Permits",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {/* {headCell.icon} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("des");
  const [orderBy, setOrderBy] = React.useState("created");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  //States related to Listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [trainedResponderList, setTrainedResponderList] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [clickedImg, setClickedImg] = useState(null);
  const [responderId, setResponderId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const dispatch = useDispatch();
  const [editData, setEditData] = useState({});

  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = trainedResponderList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setResponderId(rowId);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // Aed Edit Trained Responders
  const [addEditTresp, setAddEditTrespModal] = useState(false);
  const addEditTrespOpen = () => {
    setAddEditTrespModal(true);
  };
  const addEditTrespClose = () => {
    setAddEditTrespModal(false);
    setEditData({});
  };

  // Update Training Modal
  const [updateTrainingDialog, setUpdateTrainingDialog] = useState(false);
  const updateTrainingDialogOpen = () => {
    setUpdateTrainingDialog(true);
  };
  const updateTrainingDialogClose = () => {
    setUpdateTrainingDialog(false);
  };

  const viewImage = (link) => {
    setClickedImg(link);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  //Method for listing
  const getAedTrainedResponderList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      expiredP: props.expiredParam,
    };
    AedTrainedResponderListing(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setTrainedResponderList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setTrainedResponderList(res.data);
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        // dispatch(sendIrLogList([]));
      });
  };

  //Method for deleting record
  //handler for deleting the tour
  const deleteResponder = () => {
    handleDeleteClose();
    DeleteTrainedResponder(responderId)
      .then((res) => {
        if (res.statusCode == 200) {
          getAedTrainedResponderList();
          dispatch(getMsg("Trained responder has been deleted"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        // console.log("err");
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setResponderId(null);
  };

  //Method for getting trained responder info
  const getEditResponderData = () => {
    setPageLoad(true);
    ViewTrainedResponderInfo(responderId)
      .then((res) => {
        if (res.statusCode == 200) {
          setEditData(res.data);
        }
        setPageLoad(false);
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const updateTrainingDate = () => {
    getAedTrainedResponderList();
  };

  useEffect(() => {
    getAedTrainedResponderList();
  }, [rowsPerPage, currentPage]);
  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this responder ?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={(handleDeleteClose, deleteResponder)}
            autoFocus
            color="primary"
            sx={{ ml: "10px" }}
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Paper mt={3}>
        <Toolbar>
          <div>
            <IconButton onClick={addEditTrespOpen}>
              <AddIcon />
            </IconButton>
          </div>
        </Toolbar>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={trainedResponderList.length}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(trainedResponderList, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        key={row.id}
                        // onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell> */}
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.department_name}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.training_date}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.coordinator}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.ert}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.certificate_file ? (
                            <CameraAltIcon
                              color="primary"
                              key={index}
                              sx={{ cursor: "pointer" }}
                              onClick={() => viewImage(row.certificate_file)}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell
                          align="left"
                          color="success"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {row.guard_license_number &&
                            row.guard_issue_date &&
                            row.guard_training_interval && (
                              <span style={{ color: "green" }}>S,&nbsp;</span>
                            )}
                          {row.firearm_permit_number &&
                            row.firearm_expiry_date && (
                              <span style={{ color: "red" }}>F,&nbsp;</span>
                            )}
                          {row.baton_permit_number &&
                            row.baton_permit_number && (
                              <span style={{ color: "red" }}>B,&nbsp;</span>
                            )}
                          {row.tear_permit_number && row.tear_expiry_date && (
                            <span style={{ color: "yellow" }}>T,&nbsp;</span>
                          )}
                          {row.other_permit_number && row.other_expiry_date && (
                            <span style={{ color: "red" }}>O</span>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleActionClose();
                                getEditResponderData();
                                addEditTrespOpen();
                              }}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={(handleActionClose, handleDeleteOpen)}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {totalRecord === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                    <TableCell colSpan={9} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {selected.length > 0 && (
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: "15px" }}
              onClick={updateTrainingDialogOpen}
            >
              Update Training
            </Button>
          )}
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {addEditTresp && (
        <AddEditTrainedResponder
          addEditTresp={addEditTresp}
          setAddEditTrespModal={setAddEditTrespModal}
          addEditTrespOpen={addEditTrespOpen}
          addEditTrespClose={addEditTrespClose}
          setPageLoad={setPageLoad}
          editData={editData}
          getAedTrainedResponderList={getAedTrainedResponderList}
        />
      )}
      {updateTrainingDialog && (
        <UpdateTraining
          updateTrainingDate={updateTrainingDate}
          updateTrainingDialog={updateTrainingDialog}
          setUpdateTrainingDialog={setUpdateTrainingDialog}
          updateTrainingDialogOpen={updateTrainingDialogOpen}
          updateTrainingDialogClose={updateTrainingDialogClose}
          selected={selected}
          setPageLoad={setPageLoad}
        />
      )}
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {clickedImg && (
        <ImageListingModal
          clickedImg={clickedImg}
          handelRotationRight={false}
          setClickedImg={setClickedImg}
          handelRotationLeft={false}
        />
      )}
    </div>
  );
}

const TrainedResponders = ({ expiredParam }) => {
  return (
    <>
      <EnhancedTable expiredParam={expiredParam} />
    </>
  );
};

export default TrainedResponders;

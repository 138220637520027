import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { format, subDays } from "date-fns";
import { GetEquipmentUserLog } from "../../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  ListItemIcon,
  MenuItem,
  Menu,
  Card,
  Box,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  SettingsInputComposite as SettingsInputCompositeIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const ListItemText = styled(MuiListItemText)(spacing);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const EquipmentUserLog = (props) => {
  const { logModalClose, open, setOpen, id } = props;
  const [dense, setDense] = React.useState(true);
  const [startdate, setStartDate] = React.useState(null);
  const [enddate, setEndDate] = React.useState(null);
  const [equipUserLog, setEquipUserLog] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEquipmentUserLog = () => {
    let payload = {
      start_date: startdate ? format(startdate, "yyyy-MM-dd") : null,
      end_date: enddate ? format(enddate, "yyyy-MM-dd") : null,
    };
    setLoading(true);
    GetEquipmentUserLog(id, { params: payload })
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipUserLog(res.data);
          setLoading(false);
        } else {
          setEquipUserLog([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getEquipmentUserLog();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={open}
        onClose={logModalClose}
        aria-labelledby="equipment-log"
        aria-describedby="equipment-log"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            User Log
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText id="equipment-log" sx={{ padding: "30px 20px" }}>
            <Grid container spacing={4} mt={2}>
              <Grid item xs={12} md={4}>
                <DatePicker
                  id="start-date"
                  label="Start date"
                  value={startdate}
                  onChange={(newValueStartDate) => {
                    setStartDate(newValueStartDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Start date",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  id="end-date"
                  label="End date"
                  value={enddate}
                  onChange={(newValueEndDate) => {
                    setEndDate(newValueEndDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "End date",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ height: "100%" }}
                  onClick={getEquipmentUserLog}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
          <Paper>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Equipment Type</TableCell>
                    <TableCell>Equipment Name</TableCell>
                    <TableCell>Checkout Date</TableCell>
                    <TableCell>Check In Date</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                ) : (
                  <TableBody>
                    {equipUserLog.map((data) => {
                      return (
                        <TableRow>
                          <TableCell>{data.equipment_type}</TableCell>
                          <TableCell>{data.equipment_name}</TableCell>
                          <TableCell>{data.checked_out_time}</TableCell>
                          <TableCell>{data.checked_in_time}</TableCell>
                        </TableRow>
                      );
                    })}

                    {equipUserLog.length === 0 && (
                      <TableRow
                        style={{ height: (dense ? 7 : 0) * equipUserLog }}
                      >
                        <TableCell colspan={6} align="center">
                          No log records found for the selected user
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableWrapper>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
          <Button onClick={logModalClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EquipmentUserLog;

import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import {
  Typography,
  TableBody,
  TableHead,
  TableRow,
  Card,
  TableCell,
  Table,
  TableContainer,
  Switch,
} from "@mui/material";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import { useDispatch } from "react-redux";
import { CarrierServiceLevels, UpdateCarrierServiceStatus } from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";

function CarrierServiceLevel() {
  const dispatch = useDispatch();

  const { carrierName } = useParams();

  //carrier service level
  const [serviceLevels, setServiceLevels] = React.useState([]);

  React.useEffect(() => {
    let payload = {
      carrierName: carrierName,
    };
    CarrierServiceLevels(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setServiceLevels(res.data);
        } else {
          setServiceLevels([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  }, []);
  const handleSwitchToggle = (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
    let payload = {
      serviceId: id,
      status: newStatus,
    };
    UpdateCarrierServiceStatus(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setServiceLevels((prevData) =>
            prevData.map((serviceLevel) => ({
              ...serviceLevel,
              carrier_service_settings:
                serviceLevel.carrier_service_settings.map((setting) =>
                  setting.id === id
                    ? { ...setting, status: newStatus }
                    : setting
                ),
            }))
          );
          dispatch(getMsg("Carrier status updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h3" sx={{ marginBottom: "15px" }}>
            <AirportShuttleIcon
              sx={{ marginBottom: "-5px", marginRight: "6px" }}
            />
            {carrierName}
          </Typography>
          <Typography>
            Choose the carrier service level below to receive email or SMS
            notifications when shipping is paid for a lost and found item
          </Typography>
          <Card
            sx={{
              marginTop: "30px",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Service Name</TableCell>
                    <TableCell align="right">Notify When Paid</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceLevels.map((serviceLevel) => (
                    <TableRow key={serviceLevel.id}>
                      <TableCell align="left">
                        {serviceLevel.service_level}
                      </TableCell>
                      <TableCell align="right">
                        {serviceLevel.carrier_service_settings.length > 0 && (
                          <Switch
                            checked={
                              serviceLevel.carrier_service_settings[0]
                                .status === 1
                            }
                            onChange={() =>
                              handleSwitchToggle(
                                serviceLevel.carrier_service_settings[0].id,
                                serviceLevel.carrier_service_settings[0].status
                              )
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default CarrierServiceLevel;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visitorListing: {
    id: true,
    name: true,
    type: true,
    host: true,
    company: true,
    recent: true,
    issued: true,
    expiry: true,
    active: true,
    status: true,
    action: true,
  },
  visitorCompanyList: {
    CompanyName: true,
    CompanyAddr1: true,
    CompanyCity: true,
    CompanyState: true,
    CompanyZip: true,
    CompanyPhone: true,
    CompanyFax: true,
    CompanyAdminName: true,
    CompanyAdminPhone: true,
    note: true,
    status: true,
    action: true,
  },
  visitLogList: {
    visitor_name: true,
    company_name: true,
    check_in: true,
    check_out: true,
    host_name: true,
    purpose_visit: true,
    note: true,
  },
  visitorWatchList: {
    visitor_name: true,
    date_added: true,
    added_by: true,
    expiry_date: true,
    reason: true,
    status: true,
    action: true,
  },
};

export const visitorListCalSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setVisitorListing: (state, action) => {
      state.visitorListing = action.payload;
    },
    setVisitorCompanyList: (state, action) => {
      state.visitorCompanyList = action.payload;
    },
    setVisitLogList: (state, action) => {
      state.visitLogList = action.payload;
    },
    setVisitorWatchList: (state, action) => {
      state.visitorWatchList = action.payload;
    },
  },
});

export const {
  setVisitorListing,
  setVisitorCompanyList,
  setVisitLogList,
  setVisitorWatchList,
} = visitorListCalSlice.actions;

export default visitorListCalSlice.reducer;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import axios from "axios";
import {
  UpdateButtonCritical,
  RemoveButtonTime,
  SaveButtonInlineData,
} from "../../api";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Checkbox,
  Grid,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  Link,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
  max-width: 100px;
`;
const Image = styled.img`
  width: 100%;
`;

const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiSwitch-root": {
        position: "absolute",
        right: "-10px",
        top: "0px",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
    },
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const CheckpointRow = (props) => {
  const {
    data,
    isItemSelected,
    labelId,
    handleClick,
    anchorEl,
    handleActionClick,
    handleActionClose,
    handleDeleteOpen,
    tourId,
    scheduleCheckpointOpen,
    checkpointId,
    viewTourCheckpoint,
    getTourCheckpoints,
    tableCalRedux,
  } = props;
  const [critical, setCritical] = useState(
    data.critical === "critical" ? true : false
  );
  const handleCritical = (status, id) => {
    let value = status === true ? "non-critical" : "critical";
    UpdateButtonCritical(id, { status: value, tour_id: tourId })
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const remvoeButtonTime = () => {
    RemoveButtonTime(data.TagID)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("Button schedule removed"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          getTourCheckpoints();
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [editedTagDescription, setEditedTagDescription] = useState(data.button);
  const dispatch = useDispatch();

  const [editedLocationHint, setEditedLocationHint] = useState(
    data.location_hint
  );

  const handleTagDescriptionChange = (e) => {
    setEditedTagDescription(e.target.value);
  };

  const handleLocationHintChange = (e) => {
    setEditedLocationHint(e.target.value);
  };

  const saveEditedData = async () => {
    const updatedData = {
      tag_description: editedTagDescription,
      location_hint: editedLocationHint,
    };

    SaveButtonInlineData(data.TagID, updatedData)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Somthing went wrong please try again!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  // Scheduled tooltip title
  function generateTooltipTitle(data) {
    let msg1 = "";
    let msg2 = "";
    let msg3 = "";

    if (data.schedule_type === "Monthly") {
      const number = data.schedule_type_value;
      const ends = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"];
      const abbreviation =
        number % 100 >= 11 && number % 100 <= 13
          ? number + "th"
          : number + ends[number % 10];
      msg1 = "Every " + abbreviation + " day of month,";
    } else if (data.schedule_type === "Daily") {
      msg1 = "Everyday,";
    } else if (data.schedule_type === "Weekly") {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const selectedDays = data.weekly_value.map((day) => days[day - 1]);
      msg1 = "Every " + selectedDays.join(", ");
    }

    if (data.schedule_time_start > 0) {
      msg2 =
        "between " +
        data.schedule_time_start +
        " hours and " +
        data.schedule_time_end +
        " hours";
    } else {
      msg2 = "during";
    }
    if (data.shift_selected == "Shift-1") {
      msg3 = "Shift-1";
    } else if (data.shift_selected == "Shift-2") {
      msg3 = "Shift-2";
    } else if (data.shift_selected == "Shift-3") {
      msg3 = "Shift-3";
    } else {
      msg3 = "";
    }

    return msg1 + " " + msg2 + " " + msg3;
  }

  return (
    <>
      <TableRow hover key={data.TagID} selected={isItemSelected}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, data.TagID)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
          />
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          sx={{ lg: 'whiteSpace: "nowrap"' }}
          className={tableCalRedux.TagDescription === true ? "" : "hide-cell"}
        >
          {/* {data.button} */}
          <Tooltip title={"Click to edit"}>
            <TextField
              sx={{
                width: 230,
                // height: 120,

                border: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 0,
                },
              }}
              value={editedTagDescription}
              onChange={handleTagDescriptionChange}
              onBlur={saveEditedData}
              onClick={(e) => e.stopPropagation()}
            />
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.location_hint === true ? "" : "hide-cell"}
        >
          {/* {data.location_hint} */}
          <Tooltip title={"Click to edit"}>
            <TextField
              sx={{
                width: 330,
                border: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 0,
                },
              }}
              value={editedLocationHint}
              onChange={handleLocationHintChange}
              onBlur={saveEditedData}
              onClick={(e) => e.stopPropagation()}
            />
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.image === true ? "" : "hide-cell"}
        >
          {data.image ? (
            <ImageWrapper>
              <Image alt="" src={data.image} />
            </ImageWrapper>
          ) : (
            <ImageWrapper>
              <Image alt="" src={`/static/img/avatars/user.webp`} />
            </ImageWrapper>
          )}
        </TableCell>
        {/* <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
          {data.image}
        </TableCell> */}
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.critical === true ? "" : "hide-cell"}
        >
          {/* {data.critical} */}
          <FormControlLabel
            control={
              <Switch
                checked={critical}
                onClick={() => {
                  setCritical(!critical);
                  handleCritical(critical, data.TagID);
                }}
              />
            }
            label=""
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.tasks === true ? "" : "hide-cell"}
        >
          {data.task_count}
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.task_schedules === true ? "" : "hide-cell"}
        >
          {data.task_schedules}
        </TableCell>
        {/* <TableCell align="left">{data.timing}</TableCell> */}
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.timing === true ? "" : "hide-cell"}
        >
          {data.schedule_time_start || data.schedule_time_span == "1" ? (
            <>
              <Tooltip title="Click to delete schedule" placement="bottom-end">
                <ClearIcon
                  onClick={remvoeButtonTime}
                  sx={{ cursor: "pointer", marginRight: "10px" }}
                />
              </Tooltip>

              <Tooltip title={generateTooltipTitle(data)} placement="bottom">
                <AccessTimeIcon />
              </Tooltip>
            </>
          ) : null}
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.action === true ? "" : "hide-cell"}
        >
          <IconButton
            aria-label="Settings"
            size="large"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={(event) =>
              handleActionClick(event, data.TagID, data.button)
            }
            style={{ color: "#1374C5" }}
          >
            <SettingsIcon />
          </IconButton>
          <StyledMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="edit-button"
              component={NavLink}
              to={`/tours/edit-button/${tourId}/${checkpointId}`}
            >
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" pl={1} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                scheduleCheckpointOpen();
                viewTourCheckpoint();
                handleActionClose();
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Schedule" pl={1} />
            </MenuItem>
            <MenuItem onClick={handleDeleteOpen}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" pl={1} />
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CheckpointRow;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomSelect from "../../components/form-components/CustomSelect";
import CustomTextField from "../../components/form-components/CustomTextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  FormControl as MuiFormControl,
  Grid,
  FormHelperText,
} from "@mui/material";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AddEditPropertyAreaManager, PropertyAreaDropdown } from "../../api";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const AddPropertyAreaManager = ({
  addPropertyAreaManagerDialog,
  addPropertyAreaManagerDialogClose,
  getPropertyAreaManagerList,
  setPageLoad,
  editData,
}) => {
  const dispatch = useDispatch();
  let initialValues = {
    area_id: editData ? (editData.area_id ? editData.area_id : "") : "",
    name: editData ? (editData.name ? editData.name : "") : "",
    designation: editData
      ? editData.designation
        ? editData.designation
        : ""
      : "",
    //
  };

  const validationSchema = Yup.object().shape({
    area_id: Yup.string().required("Please enter details"),
    name: Yup.string().required("Please enter details"),
    designation: Yup.string().required("Please enter details"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formData = {
        id: editData ? (editData.id ? editData.id : null) : null,
        area_id: values.area_id,
        name: values.name,
        designation: values.designation,
      };
      AddEditPropertyAreaManager(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            getPropertyAreaManagerList();
            dispatch(
              getMsg(
                editData.id
                  ? "Area manager updated successfully"
                  : "Area manager added successfully"
              )
            );
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            addPropertyAreaManagerDialogClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } catch (err) {
      setPageLoad(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    }
  };

  //API call for area dropdown
  const [propertyArea, setPropertyArea] = useState([]);
  const propertyAreaDropdown = () => {
    setPageLoad(true);
    PropertyAreaDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setPropertyArea(res.data);
        } else {
          setPropertyArea([]);
        }
        setPageLoad(false);
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  useEffect(() => {
    propertyAreaDropdown();
  }, []);
  return (
    <>
      <Dialog
        aria-labelledby="add-property-area-title"
        aria-describedby="add-property-area-desc"
        open={addPropertyAreaManagerDialog}
        onClose={addPropertyAreaManagerDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-property-area-title"
        >
          {editData.id ? (
            <Typography variant="h4" component="div" gutterBottom>
              Edit Property Area Manager
            </Typography>
          ) : (
            <Typography variant="h4" component="div" gutterBottom>
              Add Property Area Manager
            </Typography>
          )}

          <DialogActions>
            <IconButton
              onClick={addPropertyAreaManagerDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="area_id"
                        fieldLabel="Property area *"
                        firstOptionTitle="--Select Area--"
                        displayEmpty
                        options={propertyArea}
                      />
                      <FormHelperText error>
                        {touched.area_id && errors.area_id}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Manager name *"
                        autoComplete="off"
                        placeholder="John Smith"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="designation"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Description *"
                        autoComplete="off"
                        placeholder="Add optional notes about the property area manager"
                        rows={5}
                        multiline={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={addPropertyAreaManagerDialogClose}
                    sx={{ marginRight: "5px" }}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPropertyAreaManager;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import ImageCropper from "../../components/form-components/ImageCropper";
import { AddTours } from "../../api";
import ChoosePoiImage from "../poi/inner-component/ChoosePoiImage";

import {
  decrement,
  increment,
  incrementByAmount,
  incrementAsync,
  incrementIfOdd,
  selectCount,
  hideLoader,
  showLoader,
} from "../../redux/slices/counter";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/tourSlice";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(MuiInput)`
  width: 42px;
`;

const BigAvatar = styled(Avatar)`
  width: 300px;
  height: 250px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
    "& textarea": {
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[100],
    },
    "& #tour-desc:focus-visible": {
      outlineColor: "#2f63be",
      outline: "2px solid #2f63be",
      border: 0,
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledInputSlider = styled((props) => <Paper {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiInput-root::before": {
        borderBottom: "0px",
      },
      "& .MuiInput-root": {
        "& .MuiInput-input": {
          paddingBottom: "0px",
        },
      },
    },
  })
);
const StyledSelectTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-12px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function ColumnLeft() {
  const count = useAppSelector((state) => state.counter.loader);
  const [image, setImage] = useState(null);
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // State for image cropper modal
  const [imageCropperDialog, setImageCropperDialog] = useState(false);
  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };
  const [imageData, setImageData] = useState(null);
  const childToParent = (childData) => {
    setImageData(childData);
  };

  const imageCropperClear = () => {
    setImageData(null);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [keycabinet, selectKey] = React.useState("");
  const selectkeycabinetlevel = (event) => {
    selectKey(event.target.value);
  };
  const [keysecurity, setKeySecurity] = React.useState("");
  const selectkeysecurity = (event) => {
    setKeySecurity(event.target.value);
  };
  const [keyring, setkeyringlevel] = React.useState("");
  const selectkeyringlevel = (event) => {
    setkeyringlevel(event.target.value);
  };
  const [keydetails, setKeyDetails] = React.useState("");
  const selectKeyDetails = (event) => {
    setKeyDetails(event.target.value);
  };
  // Faded Item
  const [checked, setChecked] = React.useState(false);
  const handleChangeOnChecked = () => {
    setChecked((prev) => !prev);
  };

  //Activity Slider
  const [activityvalue, setValue] = React.useState(0);

  const handleActivityPoints = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (activityvalue < 0) {
      setValue(0);
    } else if (activityvalue > 100) {
      setValue(100);
    }
  };

  const handleImage = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };

  const onLoad = (fileString) => {
    setImage(fileString);
    setImageData(fileString); // Ensure imageData is set
    console.log("profile_photo->", fileString);
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const initialValues = {
    tour_name: "",
    tour_description: "",
  };
  const validationSchema = Yup.object().shape({
    tour_name: Yup.string().required("Please enter tour name"),
    tour_description: Yup.string().required("Please enter tour description"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setIsDisabled(true);
    try {
      setLoading(true);
      let payload = {
        tour_name: values.tour_name,
        description: values.tour_description,
        activity_point: activityvalue ? activityvalue : 3,
        tag: values.tag,
        image: imageData,
      };
      AddTours(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Tour has been submitted successfully"));
            setLoading(false);
            navigate({
              pathname: "/tours/tour-listing",
            });
            setIsDisabled(false);
          } else {
            dispatch(getSnackClose());
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisabled(false);
          dispatch(getSnackClose());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const classes = useStyles();
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card mb={6}>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <TextField
                        id="tour-name"
                        label="Tour name*"
                        name="tour_name"
                        value={values.tour_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.tour_name && errors.tour_name)}
                        helperText={touched.tour_name && errors.tour_name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Public spaces"
                        // helperText="Choose a concise name to represent the tour. Please refrain from using excessive capitalization or special characters like (/-@) as it make reports difficult to read"
                      />
                      <Typography
                        component="p"
                        sx={{ fontSize: "10px", fontWeight: "300" }}
                        mt={2}
                        mx={3}
                      >
                        Choose a concise name to represent the tour. Please
                        refrain from using excessive capitalization or special
                        characters like (/-@) as it make reports difficult to
                        read
                      </Typography>
                    </FormControl>
                    {/* <FormControl mt={6}>
                      <TextField
                        id="tour-desc"
                        label="Description of tour*"
                        name="tour_description"
                        value={values.tour_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.tour_description && errors.tour_description
                        )}
                        helperText={
                          touched.tour_description && errors.tour_description
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Include both exterior and interior checkpoints at public spaces around the property. Employee areas are not inclded. Tour should be completed a minimum of 2 times per shift"
                      />
                      <Typography
                        component="p"
                        sx={{ fontSize: "10px", fontWeight: "300" }}
                        mt={2}
                        mx={3}
                      >
                        Enter a detailed description of this tour to assist your
                        officers in understanding the tour and your exceptions
                      </Typography>
                    </FormControl> */}
                    <TextAreaControl mt={3} className={classes.textAreaControl}>
                      <FormControl mt={1}>
                        <InputLabel id="v-status">
                          Description of tour*
                        </InputLabel>
                        <TextareaAutosize
                          id="tour-desc"
                          label="Description of tour*"
                          name="tour_description"
                          value={values.tour_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.tour_description && errors.tour_description
                          )}
                          helperText={
                            touched.tour_description && errors.tour_description
                          }
                          placeholder="Include both exterior and interior checkpoints at public spaces around the property. Employee areas are not inclded. Tour should be completed a minimum of 2 times per shift"
                        />
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.6964285714285714rem",
                            marginLeft: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {touched.tour_description && errors.tour_description}
                        </Typography>
                      </FormControl>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "300",
                          marginLeft: "14px",
                        }}
                        mt={2}
                        mx={3}
                      >
                        Enter a detailed description of this tour to assist your
                        officers in understanding the tour and your exceptions
                      </Typography>
                    </TextAreaControl>
                    <FormControl mt={6}>
                      <FormLabel>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          Activity points
                          {
                            <StyledInputSlider>
                              <Paper>
                                <Input
                                  value={activityvalue ? activityvalue : 3}
                                  sx={{ marginLeft: "5px" }}
                                  size="small"
                                  name="activity_point"
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                />
                              </Paper>
                            </StyledInputSlider>
                          }
                        </Box>
                      </FormLabel>
                      <Slider
                        size="large"
                        value={activityvalue ? activityvalue : 3}
                        onChange={handleActivityPoints}
                        aria-labelledby="input-slider"
                        sx={{ marginLeft: "6px", marginTop: "10px" }}
                        max={20}
                        min={1}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        id="tag"
                        label="Tag"
                        name="tag"
                        value={values.tag}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.tag && errors.tag)}
                        helperText={touched.tag && errors.tag}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={5}>
                      <FormLabel>Add tour image</FormLabel>

                      <FormHelperText>
                        Pro tip: Wait until you get checkpoints added then add a
                        screenshot from the "Build visual tour" feature from the
                        tour list to show a satellite map with marker at your
                        checkpoints.
                      </FormHelperText>
                      {/* <BigAvatar
                        variant="square"
                        alt="Remy Sharp"
                        src={imageData}
                      /> */}
                      <img
                        src={imageData}
                        style={{ maxHeight: "250px", maxWidth: "300px" }}
                      />

                      {/* <label htmlFor="raised-button-file">
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          type="file"
                          onChange={handleImage}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          mt={3}
                        >
                          Choose image
                        </Button>
                      </label> */}
                      <Grid item xs={12} md={12}>
                        <Button
                          onClick={imageCropperOpen}
                          variant="contained"
                          color="primary"
                          mt={3}
                        >
                          Choose Image
                        </Button>
                        {imageData && (
                          <Button
                            sx={{ marginLeft: "10px" }}
                            onClick={imageCropperClear}
                            variant="contained"
                            color="primary"
                            component="span"
                            mt={3}
                          >
                            Clear
                          </Button>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      mt={6}
                      sx={{ width: "175px", marginLeft: "5px" }}
                      disabled={isDisabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Card>
        )}
      </Formik>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {imageCropperDialog && (
        <ImageCropper
          imageCropperDialog={imageCropperDialog}
          setImageCropperDialog={setImageCropperDialog}
          imageCropperOpen={imageCropperOpen}
          imageCropperClose={imageCropperClose}
          childToParent={childToParent}
          imageData={imageData}
        />
      )}
    </>
  );
}

function AddTour() {
  return (
    <React.Fragment>
      <WithPermissionFallback controller="Tour" action="addTour">
        <Helmet title="Add New Tour" />
        <Typography variant="h3" gutterBottom display="inline">
          Add New Tour
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/tours/tour-listing">
            Tour List
          </Link>
          <Typography>Add New Tour</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <ColumnLeft />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default AddTour;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { UnblockUser } from "../../../../api";
const RemoveBlock = ({
  removeBlock,
  setRemoveBlock,
  removeBlockOpen,
  removeBlockClose,
  updatedRow,
  securityEventsList,
}) => {
  const dispatch = useDispatch();
  const unblockUser = () => {
    let payload = {
      email: updatedRow.email,
    };
    UnblockUser(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          removeBlockClose();
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
    securityEventsList();
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={removeBlockOpen}
        onClose={removeBlockClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle>
          <Typography variant="h5">Remove block: {updatedRow.email}</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setRemoveBlock(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            This action will remove the block that was applied to the selected
            users account following several failed login attempts. They can then
            try logging in again using their existing password. If the user does
            not remember their password, cancel this popup and change the users
            password instead.
          </Typography>
        </DialogContent>

        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            onClick={() => setRemoveBlock(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={unblockUser} variant="contained" color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveBlock;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
  Box,
  FormControl as MuiFormControl,
  InputAdornment,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  RemoveCircle as RemoveCircleIcon,
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DeleteShiftQuestion, SaveShiftQuestion } from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const ShiftDuties = ({
  shiftDutiesDialog,
  shiftDutiesDialogClose,
  shiftDuties,
  getShiftReportData,
}) => {
  const dispatch = useDispatch();
  // Shift Duties fields
  const [shiftDutiesFields, setShiftDutiesFields] = useState(
    shiftDuties.map((tool) => ({ ...tool }))
  );

  const addMoreShiftDutiesFields = (value) => {
    setShiftDutiesFields([
      ...shiftDutiesFields,
      { type: "shift", question: value },
    ]);
  };
  // Shift Duties fields
  const removeShiftDutiesFields = (index) => {
    const fields = [...shiftDutiesFields];
    const deleteQues = fields.splice(index, 1);
    if (deleteQues[0].hasOwnProperty("id")) {
      deleteQuestion(deleteQues[0].id);
    }
    setShiftDutiesFields(fields);
  };

  const handleFieldChange = (index, event) => {
    const values = [...shiftDutiesFields];
    values[index].question = event.target.value;
    setShiftDutiesFields(values);
  };

  const saveQuestion = () => {
    const payload = {
      questions: shiftDutiesFields,
    };
    SaveShiftQuestion(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getShiftReportData();
        } else {
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  const deleteQuestion = (id) => {
    const payload = {
      id: id,
      type: "shift",
    };
    DeleteShiftQuestion(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getShiftReportData();
        } else {
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  let initialValues = {
    add_field: "",
  };

  return (
    <>
      <Dialog
        aria-labelledby="shift-duties-title-modal"
        aria-describedby="shift-duties-modal"
        open={shiftDutiesDialog}
        onClose={shiftDutiesDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="shift-duties-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Shift Duties
          </Typography>
          <DialogActions>
            <IconButton
              onClick={shiftDutiesDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues} enableReinitialize>
            {({ values, handleChange, setFieldValue }) => (
              <form>
                {shiftDutiesFields.map((field, index) => (
                  <FormControl key={index} mt={3}>
                    <CustomTextField
                      name={`field_${index}`}
                      placeholder="Add new record here"
                      value={field.question}
                      onChange={(e) => {
                        handleFieldChange(index, e);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color="error"
                              onClick={() => removeShiftDutiesFields(index)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))}
                <FormControl mt={3}>
                  <CustomTextField
                    name="add_field"
                    id="add-field"
                    placeholder="Add new record here"
                    disabled={shiftDutiesFields.length < 16 ? false : true}
                    helperText={
                      shiftDutiesFields.length < 16
                        ? ""
                        : "A maximum of 16 questions have already been added to this section"
                    }
                    onChange={handleChange}
                    FormHelperTextProps={{ style: { color: "red" } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            disabled={
                              shiftDutiesFields.length < 16 ? false : true
                            }
                            onClick={() => {
                              if (values.add_field.trim() !== "") {
                                console.log(
                                  "add field value: ",
                                  values.add_field
                                );
                                addMoreShiftDutiesFields(values.add_field);
                                setFieldValue("add_field", "");
                              }
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions sx={{ pr: "28px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={shiftDutiesDialogClose}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ ml: "15px" }}
            onClick={saveQuestion}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShiftDuties;

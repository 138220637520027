import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  List,
  ListItemText,
  ListItem,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
//Classes Styling
const useStyles = makeStyles((theme) => ({
  BulletList: {
    listStyle: "disc",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "disc",
    },
  },
}));
const AboutShippingAddress = ({ aboutShipping, aboutShippingClose }) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={aboutShipping}
        aria-labelledby="aboutshipping-address-title"
        aria-describedby="aboutshipping-address-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600",
            },
          },
        }}
      >
        <DialogTitle
          id="aboutshipping-address-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            About Shipping to APO, FPO & DPO Addresses
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton onClick={aboutShippingClose}>
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent
          id="aboutshipping-address-description"
          sx={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <DialogContentText sx={{ padding: "0 24px" }}>
            If you are shipping your item to a military base or diplomatic
            location, you'll be using an <strong>APO</strong>,{" "}
            <strong>FPO</strong> or <strong>DPO address</strong>. There are some
            special considerations when shipping to APO, FPO, and DPO addresses.
          </DialogContentText>
          <Box sx={{ padding: "0 24px" }}>
            <Typography>
              Most item can ship to APO, FPO, and DPO addresses, except:
            </Typography>
            <List className={classes.BulletList}>
              <ListItem>
                <ListItemText primary="Cell phones" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Computers" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Standalone lithium batteries" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Lithium batteries packed separately with equipment (products that come with lithium batteries installed are permitted for shipping to APO and FPO addresses)" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Furniture" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Outdoor living items" />
              </ListItem>
            </List>
          </Box>
          <DialogContentText
            id="general-address-description"
            sx={{ padding: "0 24px" }}
          >
            Some apparel, baby, camera and photo, electronics, health and
            personal care, housewares, jewelry, software, sports, and tools and
            hardware items cannot be shipped to APO, FPO, or DPO addresses.
            Contact us at lost@sovasystems.com if you are unsure.
          </DialogContentText>
          <DialogContentText
            id="general-address-description"
            sx={{ padding: "0 24px" }}
          >
            Set the City to APO, FPO, or DPO, set the State or Region to AA, AE,
            or AP, and the Country to "United States" when shipping to an APO,
            FPO, or DPO address. Standard shipping deliveries may take up to 21
            business days for these addresses.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={aboutShippingClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AboutShippingAddress;

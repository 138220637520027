import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  DialogContentText,
  Box,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { ClonePackage as Clone } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
const ClonePackage = ({
  clonePackage,
  clonePackageClose,
  id,
  packagesList,
}) => {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackList, setTrackList] = useState([]);
  const [isClone, setIsClone] = useState(false);
  const dispatch = useDispatch();
  const handleRemove = (v) => {
    setTrackList(trackList.filter((d) => d !== v));
  };

  const handleSave = () => {
    let payload = {
      id: id,
      tracking_number: trackList,
    };
    Clone(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          clonePackageClose();
          packagesList();
        } else {
          dispatch(getMsg("Something went wrong!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };
  return (
    <>
      <Dialog
        open={clonePackage}
        onClose={clonePackageClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px",
            },
          },
        }}
        aria-labelledby="clone-package-title"
        aria-describedby="clone-package-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="clone-package-title"
        >
          <Typography variant="h4" component="div">
            Clone Package
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={clonePackageClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Scan a tracking barcode or manually enter a tracking number then hit
            your enter key to add additional packages. The record you selected
            when opening this popup will be cloned, but each cloned record will
            have the tracking numbers you enter here for each cloned record.
          </DialogContentText>
          <TextField
            placeholder="Tracking number"
            sx={{ marginTop: "10px" }}
            name="tracking_number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            fullWidth
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                let value = e.target.value;
                setTrackList([...trackList, value]);
                setTrackingNumber("");
                setIsClone(true);
              }
            }}
          />

          {trackList.map((d, i) => {
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  key={i}
                >
                  <TextField
                    sx={{ marginTop: "10px", marginRight: "20px" }}
                    value={d}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <CancelIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleRemove(d)}
                  />
                </Box>
              </>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={clonePackageClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: "10px" }}
            onClick={handleSave}
            disabled={isClone ? false : true}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClonePackage;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import CustomAlert from "../../components/form-components/CustomAlert";
import { updateShowAlert } from "../../redux/slices/mainSlice";
import PropTypes from "prop-types";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Paper as MuiPaper,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  AddBox as AddBoxIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";

// Import dialog components
import AddEmergencyTypeDialog from "./inner-component/AddEmergencyTypeDialog";
import EditEmergencyTypeDialog from "./inner-component/EditEmergencyTypeDialog";
import DeleteEmergencyTypeDialog from "./inner-component/DeleteEmergencyTypeDialog";
// Import the API calls
import { GetEmergencyTypes } from "../../api";
import EmergencyTab from "./inner-component/EmergencyProceduresTab/EmergencyTab";

const Divider = styled(MuiPaper)(spacing);
const Paper = styled(MuiPaper)(spacing);

// Classes Styling
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #0000000f",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    overflowX: "auto", // Enable horizontal scrolling
  },
  tabContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuContainer: {
    position: "absolute",
    right: "20px", // Fixed right position
    top: "10px", // Adjust to align with the tabs
  },
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function emergencyProceduresTabs(index) {
  return {
    id: `incident-tab-${index}`,
    "aria-controls": `incident-tabpanel-${index}`,
  };
}

function EmergencyProceduresContent(props) {
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabs, setTabs] = useState([]); // State to hold the tabs data

  const [anchorEl, setAnchorEl] = useState(null);
  const [actions, setActions] = useState("");

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const showAlertText = useSelector((state) => state.main.text);

  // Alert Message
  const [alertMessage, setAlertMessage] = useState(showAlertText);
  const [alertType, setAlertType] = useState("success");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    setActions(action);
    handleCloseMenu();
    if (action === "add") {
      setOpenAddDialog(true); // Open Add Emergency Type Dialog
    } else if (action === "edit") {
      setOpenEditDialog(true);
    } else if (action === "delete") {
      setOpenDeleteDialog(true);
    }
  };

  const getEmergencyTypes = () => {
    GetEmergencyTypes()
      .then((res) => {
        if (res.statusCode === 200) {
          setTabs(res.data); // Set the tabs from the API response
        }
      })
      .catch((err) => {
        console.log("Error fetching emergency types:", err);
      });
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getEmergencyTypes();

    setTimeout(() => {
      dispatch(updateShowAlert(false));
    }, 3000);
  }, []);

  return (
    <>
      <Paper mt={6} sx={{ position: "relative" }}>
        <Box className={classes.tabs}>
          <Box className={classes.tabContainer}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="emergency-procedures"
              variant="scrollable"
              scrollButtons="auto" // Allow scrolling
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.type}
                  {...emergencyProceduresTabs(index)}
                />
              ))}
            </Tabs>
          </Box>

          <Box className={classes.menuContainer}>
            <IconButton onClick={handleClickMenu}>
              <SettingsIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => handleMenuItemClick("add")}>
                <AddBoxIcon color="disabled" sx={{ marginRight: "9px" }} /> Add
                Heading
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("edit")}>
                <EditIcon color="disabled" sx={{ marginRight: "9px" }} /> Edit
                Heading
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("delete")}>
                <DeleteIcon color="disabled" sx={{ marginRight: "9px" }} />{" "}
                Delete Heading
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        {tabs.map((tab, index) => (
          <TabPanel value={tabValue} index={index} key={index}>
            <EmergencyTab
              item={{ type: tab.type }}
              setLoading={(status) => setLoading(status)}
            />
          </TabPanel>
        ))}
      </Paper>

      {/* Add Emergency Type Dialog */}
      <AddEmergencyTypeDialog
        openDialog={openAddDialog} // Pass the state to control visibility
        closeDialog={() => setOpenAddDialog(false)} // Pass function to close dialog
        getEmergencyTypes={getEmergencyTypes}
      />

      {/* Edit Emergency Type Dialog */}
      <EditEmergencyTypeDialog
        openDialog={openEditDialog}
        closeDialog={() => setOpenEditDialog(false)}
        getEmergencyTypes={getEmergencyTypes}
        tabs={tabs}
      />

      {/* Delete Emergency Type Dialog */}
      <DeleteEmergencyTypeDialog
        openDialog={openDeleteDialog}
        closeDialog={() => setOpenDeleteDialog(false)}
        getEmergencyTypes={getEmergencyTypes}
        tabs={tabs}
      />

      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
    </>
  );
}

function EmergencyProcedures() {
  return (
    <React.Fragment>
      <Helmet title="Emergency Procedures" />
      <Typography variant="h3" gutterBottom display="inline">
        Emergency Procedures
      </Typography>
      <EmergencyProceduresContent />
    </React.Fragment>
  );
}

export default EmergencyProcedures;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import KeyDetail from "./inner-component/KeyDetail";
import ViewKeyDetail from "./inner-component/ViewKeyDetail";
import EditKeyDetail from "./inner-component/EditKeyDetail";
import AddAuthorizationPerson from "./inner-component/AddAuthorizationPerson";
import { DateTimePicker } from "@mui/lab";
import {
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  FormControlLabel,
  Switch,
  FormHelperText,
  CircularProgress,
  Paper,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import {
  AddBox as AddBoxIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CustomSelection from "../../components/form-components/CustomSelection";
import {
  DepartmentList,
  AddKeyRequisitionRecord,
  SaveKeyRequest,
  InvolvedKeyListing,
  GetInvolvedKeyData,
  DeleteInvolvedKey,
  InvolvedAuthPerListing,
  GetInvolvedAuthPerData,
  DeleteInvolvedAuthPer,
} from "../../api";
import { handleApiResonseErrors } from "../../helper/helper";
import moment from "moment";
import Toaster from "../tasks/Toaster";
import AddAuthOverride from "./inner-component/AddAuthOverride";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
// Classes Styling
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #0000000f",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function aedTabs(index) {
  return {
    id: `incident-tab-${index}`,
    "aria-controls": `incident-tabpanel-${index}`,
  };
}

function AddKeyRequisitionForm(props) {
  const classes = useStyles();
  const [requestDate, setRequestDate] = React.useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [departmentList, setDepartmentList] = useState([]);
  const [tabStatus, setTabStatus] = useState("");
  const [keyReqId, setKeyReqId] = useState("");
  const [keyActionStatus, setKeyActionStatus] = useState(false);
  const [customValidation, setCustomValidation] = useState({
    requestDate: "",
    firstName: "",
    lastName: "",
    departmentId: "",
    positions: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const [keyDList, setKeyDList] = useState([]);
  const [keyInvolvedId, setKeyInvolvedId] = useState(null);
  const [keyDetailData, setKeyDetailData] = useState({});
  const [loading, setLoading] = useState(false);
  const [authPerList, setAuthPerList] = useState([]);
  const [authPerId, setAuthPerId] = useState(null);
  const [authActionStatus, setAuthActionStatus] = useState(false);
  const [authPerData, setAuthPerData] = useState({});
  const [pageLoad, setPageLoad] = useState(false);

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Key Details
  const [keyDetails, setKeyDetails] = React.useState(null);
  const handleKeyDetails = (event, keyId) => {
    setKeyInvolvedId(keyId);
    setKeyDetails(event.currentTarget);
  };
  const handleKeyDetailsClose = () => {
    setKeyDetails(null);
  };

  const [dense, setDense] = React.useState(true);
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  // Authorizations Details
  const [authorizationsDetails, setAuthorizationsDetails] =
    React.useState(null);
  const handleAuthorizations = (event, authId) => {
    setAuthPerId(authId);
    setAuthorizationsDetails(event.currentTarget);
  };
  const handleAuthorizationsDetailsClose = () => {
    setAuthorizationsDetails(null);
  };
  // Key detail dialog
  const [keyDetailDialog, setKeyDetailDialog] = useState(false);
  const keyDetailDialogOpen = () => {
    setKeyDetailDialog(true);
  };
  const keyDetailDialogClose = () => {
    setKeyDetailDialog(false);
    setKeyDetailData({});
  };
  //Person Authorization dialog
  const [viewKeyDetailDialog, setViewKeyDetailDialog] = useState(false);
  const viewKeyDetailDialogOpen = () => {
    setViewKeyDetailDialog(true);
  };
  const viewKeyDetailDialogClose = () => {
    setViewKeyDetailDialog(false);
  };

  // Edit key detail dialog
  const [editKeyDetailDialog, setEditKeyDetailDialog] = useState(false);
  const editKeyDetailDialogOpen = () => {
    setEditKeyDetailDialog(true);
  };
  const editKeyDetailDialogClose = () => {
    setEditKeyDetailDialog(false);
  };

  // Add Authorization Dialog
  const [addAuthorizationPersonDialog, setAddAuthorizationPersonDialog] =
    useState(false);
  const addAuthorizationPersonDialogOpen = () => {
    setAddAuthorizationPersonDialog(true);
  };
  const addAuthorizationPersonDialogClose = () => {
    setAddAuthorizationPersonDialog(false);
    setAuthPerData({});
  };

  //Add authorization overriden
  const [addAuthOverrideDialog, setAddAuthOverrideDialog] = useState(false);
  const addAuthOverrideDialogOpen = () => {
    setAddAuthOverrideDialog(true);
  };
  const addAuthOverrideDialogClose = () => {
    setAddAuthOverrideDialog(false);
  };
  //For Dropdown
  const getDepartmentList = () => {
    DepartmentList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const initialValues = {
    request_date: new Date(),
    first_name: "",
    last_name: "",
    department_id: "",
    position: "",
  };

  const validationSchema = Yup.object().shape({
    request_date: Yup.string().required("Please select date of request"),
    first_name: Yup.string().required("Please enter firstname"),
    last_name: Yup.string().required("Please enter lastname"),
    department_id: Yup.string().required("Please select department"),
    position: Yup.string().required("Please enter company name"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formatedReqDate = values.request_date
        ? moment(values.request_date).format("YYYY-MM-DD")
        : "";
      // setVehicleLoader(true);
      let formData = {
        id: keyReqId ? keyReqId : "",
        request_date: formatedReqDate,
        first_name: values.first_name,
        last_name: values.last_name,
        department_id: values.department_id,
        position: values.position,
      };
      AddKeyRequisitionRecord(formData)
        .then((res) => {
          // setVehicleLoader(false);
          if (res.statusCode === 200) {
            navigate({ pathname: "/logs/key-requisition" });
            dispatch(getMsg("Key Requisition saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
          setPageLoad(false);
        })
        .catch((err) => {
          // setVehicleLoader(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setPageLoad(false);
        });
      // await
      //   timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (err) {
      // setVehicleLoader(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setPageLoad(false);
    }
  };

  const saveKeyReq = (val) => {
    setPageLoad(true);
    let payload = {
      request_date: val.request_date
        ? moment(val.request_date).format("YYYY-MM-DD")
        : "",
      first_name: val.first_name,
      last_name: val.last_name,
      department_id: val.department_id,
      position: val.position,
    };
    SaveKeyRequest(payload)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode == 200) {
          setKeyReqId(res.data.id);
          dispatch(getMsg("Key request has been submitted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          if (tabStatus === "keyClick") {
            keyDetailDialogOpen();
          } else if (tabStatus === "authClick") {
            addAuthorizationPersonDialogOpen();
          }
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const saveNewKeyReq = (v) => {
    console.log(v);
    if (v.request_date === "") {
      console.log("Im in date");
      setCustomValidation((prevState) => ({
        ...prevState,
        requestDate: "Date of request required",
      }));
    } else if (v.first_name === "") {
      console.log("Im infname");
      setCustomValidation((prevState) => ({
        ...prevState,
        firstName: "First name required",
      }));
    } else if (v.last_name === "") {
      console.log("Im in lname");
      setCustomValidation((prevState) => ({
        ...prevState,
        lastName: "Last name required",
      }));
    } else if (v.department_id === "") {
      console.log("Im in depid");
      setCustomValidation((prevState) => ({
        ...prevState,
        departmentId: "Department required",
      }));
    } else if (v.position === "") {
      console.log("Im in postion");
      setCustomValidation((prevState) => ({
        ...prevState,
        positions: "Position Required",
      }));
    } else {
      saveKeyReq(v);
    }
  };

  //method for getting the involved key details listing
  const getInvolvedKeyDetailsListing = () => {
    if (keyReqId) {
      setLoading(true);
      InvolvedKeyListing(keyReqId)
        .then((res) => {
          if (res.statusCode == 200) {
            console.log("++++", res.data);
            setKeyDList(res.data);
          } else {
            setKeyDList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    }
  };

  //function for getting data for involved vehicle
  const getKeyInvolved = () => {
    setPageLoad(true);
    GetInvolvedKeyData(keyInvolvedId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setKeyDetailData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Function for deleteing involved vehicle
  const DeleteKeyInvolved = () => {
    setPageLoad(true);
    DeleteInvolvedKey(keyInvolvedId)
      .then((res) => {
        if (res.statusCode == 200) {
          setPageLoad(false);
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getInvolvedKeyDetailsListing();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          // handleDeleteClose();
        } else {
          setPageLoad(false);
          // handleDeleteClose();
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Handler for editing vehicle involved
  const editKeyD = () => {
    getKeyInvolved();
    handleKeyDetailsClose();
    keyDetailDialogOpen();
    setKeyActionStatus(false);
  };

  //Handler for view vehicle involved
  const viewKeyD = () => {
    getKeyInvolved();
    handleKeyDetailsClose();
    keyDetailDialogOpen();
    setKeyActionStatus(true);
  };

  //method for getting the involved key details listing
  const getInvolvedAuthPerListing = () => {
    if (keyReqId) {
      setLoading(true);
      InvolvedAuthPerListing(keyReqId)
        .then((res) => {
          if (res.statusCode == 200) {
            setAuthPerList(res.data);
          } else {
            setAuthPerList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    }
  };

  //function for getting data for involved vehicle
  const getAuthPerInvolved = () => {
    setPageLoad(true);
    GetInvolvedAuthPerData(authPerId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setAuthPerData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Function for deleteing involved vehicle
  const DeleteAuthPerInvolved = () => {
    setPageLoad(true);
    DeleteInvolvedAuthPer(authPerId)
      .then((res) => {
        if (res.statusCode == 200) {
          setPageLoad(false);
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getInvolvedAuthPerListing();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          // handleDeleteClose();
        } else {
          setPageLoad(false);
          // handleDeleteClose();
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Handler for editing vehicle involved
  const editAuthPer = () => {
    getAuthPerInvolved();
    handleAuthorizationsDetailsClose();
    addAuthorizationPersonDialogOpen();
    setAuthActionStatus(false);
  };

  //Handler for view vehicle involved
  const viewAuthPer = () => {
    getAuthPerInvolved();
    handleAuthorizationsDetailsClose();
    addAuthorizationPersonDialogOpen();
    setAuthActionStatus(true);
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            // enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={3}>
                      <DateTimePicker
                        id="date-range"
                        label="Date of request *"
                        value={values.request_date}
                        onChange={(newValue) => {
                          setFieldValue("request_date", newValue);
                        }}
                        inputFormat="MM/dd/yyyy HH:mm"
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="request_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />

                      {values.request_date === "" && (
                        <FormHelperText sx={{ color: "red" }}>
                          {customValidation.requestDate}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="first_name"
                        label="First name of requesting *"
                        autoComplete="off"
                        id="requesting-fname"
                        InputLabelProps={{ shrink: true }}
                      />
                      {values.first_name === "" && (
                        <FormHelperText sx={{ color: "red" }}>
                          {customValidation.firstName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="last_name"
                        label="Last name of requesting *"
                        autoComplete="off"
                        id="requesting-lname"
                        InputLabelProps={{ shrink: true }}
                      />
                      {values.last_name === "" && (
                        <FormHelperText sx={{ color: "red" }}>
                          {customValidation.lastName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomSelection
                        name="department_id"
                        label="Department *"
                        options={departmentList}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {values.department_id === "" && (
                        <FormHelperText sx={{ color: "red" }}>
                          {customValidation.departmentId}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="position"
                        label="Position *"
                        InputLabelProps={{ shrink: true }}
                        id="position"
                        autoComplete="off"
                      />
                      {values.position === "" && (
                        <FormHelperText sx={{ color: "red" }}>
                          {customValidation.positions}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider my={6} />
                    <Box className={classes.tabs}>
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="key-requisition-tabs"
                      >
                        <Tab label="Key Details" {...aedTabs(0)} />
                        <Tab label="Authorizations" {...aedTabs(1)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                      <IconButton
                        onClick={() => {
                          setTabStatus("keyClick");
                          setKeyActionStatus(false);
                          if (keyReqId === "") {
                            saveNewKeyReq(values);
                          } else {
                            keyDetailDialogOpen();
                          }
                        }}
                      >
                        <AddBoxIcon />
                      </IconButton>
                      <Card
                        variant="outlined"
                        sx={{ borderBottom: 0, mt: "20px" }}
                      >
                        <TableContainer className="tableContainer">
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            size={dense ? "small" : "medium"}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Equipment ID</TableCell>
                                <TableCell>Equipment Name</TableCell>
                                <TableCell>Key Group</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            {loading ? (
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan={4} align="center">
                                    <CircularProgress />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : (
                              <TableBody>
                                {keyDList.map((item) => {
                                  return (
                                    <TableRow hover key={item.id}>
                                      <TableCell>{item.equipment_id}</TableCell>
                                      <TableCell>
                                        {item.equipment_name}
                                      </TableCell>
                                      <TableCell>{item.key_group}</TableCell>
                                      <TableCell textAlign="left">
                                        <IconButton
                                          aria-label="Settings"
                                          size="large"
                                          aria-owns={
                                            keyDetails
                                              ? "key-details"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={(e) => {
                                            handleKeyDetails(e, item.id);
                                          }}
                                          style={{ color: "#1374C5" }}
                                        >
                                          <SettingsIcon />
                                        </IconButton>
                                        <StyledMenu
                                          id="key-details"
                                          anchorEl={keyDetails}
                                          open={Boolean(keyDetails)}
                                          onClose={handleKeyDetailsClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              viewKeyD();
                                              handleKeyDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <VisibilityIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="View"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              editKeyD();
                                              handleKeyDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <EditIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Edit"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              DeleteKeyInvolved();
                                              handleKeyDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Delete"
                                              pl={1}
                                            />
                                          </MenuItem>
                                        </StyledMenu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                                {keyDList.length == 0 && (
                                  <TableRow>
                                    <TableCell colSpan={4} align="center">
                                      No record added
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Card>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <IconButton
                        onClick={() => {
                          setTabStatus("authClick");
                          setAuthActionStatus(false);
                          if (keyReqId === "") {
                            saveNewKeyReq(values);
                          } else {
                            addAuthorizationPersonDialogOpen();
                          }
                        }}
                      >
                        <AddBoxIcon />
                      </IconButton>
                      <Card
                        variant="outlined"
                        sx={{ borderBottom: 0, mt: "20px" }}
                      >
                        <TableContainer className="tableContainer">
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            size={dense ? "small" : "medium"}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Email</TableCell>
                                {/* <TableCell>Date Approved</TableCell> */}
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            {loading ? (
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan={5} align="center">
                                    <CircularProgress />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : (
                              <TableBody>
                                {authPerList.map((item) => {
                                  return (
                                    <TableRow hover key={item.id}>
                                      <TableCell>{item.name}</TableCell>
                                      <TableCell>{item.title}</TableCell>
                                      <TableCell>{item.email}</TableCell>
                                      {/* <TableCell>
                                        {item.date_approved}
                                      </TableCell> */}
                                      <TableCell textAlign="left">
                                        <IconButton
                                          aria-label="Settings"
                                          size="large"
                                          aria-owns={
                                            authorizationsDetails
                                              ? "authorizations-details"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={(e) => {
                                            handleAuthorizations(e, item.id);
                                          }}
                                          style={{ color: "#1374C5" }}
                                        >
                                          <SettingsIcon />
                                        </IconButton>
                                        <StyledMenu
                                          id="authorizations-details"
                                          anchorEl={authorizationsDetails}
                                          open={Boolean(authorizationsDetails)}
                                          onClose={
                                            handleAuthorizationsDetailsClose
                                          }
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              viewAuthPer();
                                              handleAuthorizationsDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <VisibilityIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="View"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              editAuthPer();
                                              handleAuthorizationsDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <EditIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Edit"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              DeleteAuthPerInvolved();
                                              handleAuthorizationsDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Delete"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          {/* <MenuItem
                                            onClick={() => {
                                              addAuthOverrideDialogOpen();
                                              handleAuthorizationsDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <DoneOutlineIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Override"
                                              pl={1}
                                            />
                                          </MenuItem> */}
                                        </StyledMenu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                                {authPerList.length == 0 && (
                                  <TableRow>
                                    <TableCell colSpan={5} align="center">
                                      No record added
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Card>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                      gdg
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                      ghf
                    </TabPanel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {keyDetailDialog && (
        <KeyDetail
          keyDetailDialog={keyDetailDialog}
          setKeyDetailDialog={setKeyDetailDialog}
          keyDetailDialogOpen={keyDetailDialogOpen}
          keyDetailDialogClose={keyDetailDialogClose}
          keyReqId={keyReqId}
          getInvolvedKeyDetailsListing={getInvolvedKeyDetailsListing}
          keyDetailData={keyDetailData}
          keyActionStatus={keyActionStatus}
          setPageLoad={setPageLoad}
        />
      )}
      {addAuthOverrideDialog && (
        <AddAuthOverride
          addAuthOverrideDialog={addAuthOverrideDialog}
          setAddAuthOverrideDialog={setAddAuthOverrideDialog}
          addAuthOverrideDialogOpen={addAuthOverrideDialogOpen}
          addAuthOverrideDialogClose={addAuthOverrideDialogClose}
          authPerId={authPerId}
          setPageLoad={setPageLoad}
          getInvolvedAuthPerListing={getInvolvedAuthPerListing}
        />
      )}
      {viewKeyDetailDialog && (
        <ViewKeyDetail
          viewKeyDetailDialog={viewKeyDetailDialog}
          setViewKeyDetailDialog={setViewKeyDetailDialog}
          viewKeyDetailDialogOpen={viewKeyDetailDialogOpen}
          viewKeyDetailDialogClose={viewKeyDetailDialogClose}
        />
      )}
      {editKeyDetailDialog && (
        <EditKeyDetail
          editKeyDetailDialog={editKeyDetailDialog}
          setEditKeyDetailDialog={setEditKeyDetailDialog}
          editKeyDetailDialogOpen={editKeyDetailDialogOpen}
          editKeyDetailDialogClose={editKeyDetailDialogClose}
        />
      )}
      {addAuthorizationPersonDialog && (
        <AddAuthorizationPerson
          addAuthorizationPersonDialog={addAuthorizationPersonDialog}
          setAddAuthorizationPersonDialog={setAddAuthorizationPersonDialog}
          addAuthorizationPersonDialogOpen={addAuthorizationPersonDialogOpen}
          addAuthorizationPersonDialogClose={addAuthorizationPersonDialogClose}
          keyReqId={keyReqId}
          // authPerId={authPerId}
          getInvolvedAuthPerListing={getInvolvedAuthPerListing}
          authActionStatus={authActionStatus}
          authPerData={authPerData}
          setPageLoad={setPageLoad}
        />
      )}
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </>
  );
}

function AddKeyRequisition() {
  return (
    <React.Fragment>
      <Helmet title="Add Key Requisition" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Key Requisition
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/key-requisition">
          Key Requisition
        </Link>
        <Typography>Add Key Requisition</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddKeyRequisitionForm />
    </React.Fragment>
  );
}

export default AddKeyRequisition;

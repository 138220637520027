import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { updateShowAlert, updateText } from "../../redux/slices/mainSlice";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import CameraFeeding from "../../helper/camera-feeding";
import CustomSelect from "../../components/form-components/CustomSelect";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomAlert from "../../components/form-components/CustomAlert";
import CustomDateTimePicker from "../../components/form-components/CustomDateTimePicker";
import {
  AddFound,
  CategoryList,
  EstimatedList,
  StorageLocationList,
  DepartmentList,
} from "../../api";

import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormHelperText,
  Box,
  Menu,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  Radio,
  RadioGroup,
  Paper,
  CircularProgress,
  IconButton,
  MenuItem,
  ListItemText,
} from "@mui/material";
import {
  Error as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import ItemCategories from "./inner-component/ItemCategories";
import StorageLocationsDialog from "./inner-component/StorageLocationsDialog";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "235px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
    marginTop: "5px",
  })
);
const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

function AddLostAndFoundForm({
  storageLocation,
  getStorageLocationList,
  itemcatglist,
  getCategoryList,
}) {
  const [foundDate, setFundDate] = React.useState(null);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [takePhoto, setTakePhoto] = React.useState(false);
  const [followup, setFollowup] = React.useState(false);
  const [showWebCam, setShowWebCam] = React.useState(false);
  // Alert Message
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  // Estimated Value
  const [itemestlist, setItemEstList] = React.useState([]);

  // Department List
  const [departmentList, setDepartmentList] = React.useState([]);

  const classes = useStyles();
  const navigate = useNavigate();
  // State for loader
  const [loading, setLoading] = React.useState(false);

  // Redux
  const CurrentLocationInfo = useSelector((state) => state.login.Location);
  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();

  const search = useLocation().search;
  const parent_id = new URLSearchParams(search).get("parent_id");

  const uploadedFileRef = useRef();

  React.useEffect(() => {
    getCategoryList();
    getEstimatedList();
    getStorageLocationList();
    getDepartmentList();
  }, []);

  // Item Information Expansion
  const [iteminfoexpand, setItemInfo] = React.useState("expand-item-info");
  const handleItemExpansion = (panel) => (event, newExpanded) => {
    setItemInfo(newExpanded ? panel : false);
  };
  // Image Expansion
  const [imageexpand, setImageExpand] = React.useState("expand-image");
  const handleImgExpansion = (panel) => (event, newExpanded) => {
    setImageExpand(newExpanded ? panel : false);
  };
  // Lost Expansion
  const [lostexpand, setLostExpand] = React.useState("expand-lost");
  const handleLostExpansion = (panel) => (event, newExpanded) => {
    setLostExpand(newExpanded ? panel : false);
  };

  let initialValues = {
    parent_id: "",
    category: "",
    estimated_value: "",
    storage_location: "",
    followup_required: "",
    description: "",
    internal_notes: "",
    found_date: "",
    found_name: "",
    found_by: "",
    found_department: "",
    finder_wants_item: "",
    found_address: "",
    found_city: "",
    found_state: "",
    found_zip: "",
    found_country: "",
    found_phone: "",
    found_email: "",
    found_location: "",
    location_id: CurrentLocationInfo.LocationID,
    image: "",
  };

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Please select category."),
    estimated_value: Yup.string().required("Please select estimated value."),
    storage_location: Yup.string().required("Please select storage location ."),
    description: Yup.string().required(
      "Lost and found description should not be empty."
    ),
    found_date: Yup.string().required("Please select date."),
    found_location: Yup.string().required("Please select location."),
    found_zip: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    found_phone: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    found_email: Yup.string().nullable().email("Please enter a valid email."),
  });

  const getEstimatedList = () => {
    EstimatedList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemEstList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartmentList = () => {
    DepartmentList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const uploadImage = async (file) => {
    getFileBase64(file, (result) => {
      setUploadedImage(result);
      setShowWebCam(false);
    });
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };

  const takeImageToWebCam = () => {
    let changeTakePhoto = !takePhoto;
    setTakePhoto(changeTakePhoto);
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };

  const handleChecked = (e) => {
    let updateFollowup = !followup;
    setFollowup(updateFollowup);
  };

  const someThingWrongAlert = (msg) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType("error");
    dispatch(updateShowAlert(true));
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let found_date = foundDate ? moment(foundDate) : "";
      found_date = found_date ? found_date.format("YYYY-MM-DD HH:mm:ss") : "";

      let formData = {
        parent_id: parent_id,
        category: values.category,
        storage_location: values.storage_location,
        estimated_value: values.estimated_value,
        followup_required: followup === true ? "Y" : "N",
        description: values.description,
        internal_notes: values.internal_notes,
        found_date: values.found_date,
        found_name: values.found_name,
        found_by: values.found_by,
        found_department: values.found_department,
        finder_wants_item: values.finder_wants_item,
        found_address: values.found_address,
        found_city: values.found_city,
        found_state: values.found_state,
        found_zip: values.found_zip,
        found_country: values.found_country,
        found_phone: values.found_phone,
        found_email: values.found_email,
        found_location: values.found_location,
        location_id: values.location_id,
        image: uploadedImage,
      };
      AddFound(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(updateShowAlert(true));
            dispatch(updateText("Record has been submitted successfully"));
            navigate({ pathname: "/lostandfound/list-lost-found" });
          } else {
            someThingWrongAlert("Sorry! something wrong please try again");
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            someThingWrongAlert(error);
          }
        });
    } catch (error) {
      someThingWrongAlert("Sorry! something wrong please try again");
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Box className={classes.accordion}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} lg={6}>
                      <Accordion
                        mt={0}
                        expanded={iteminfoexpand === "expand-item-info"}
                        onChange={handleItemExpansion("expand-item-info")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="item-info"
                          id="item-infor"
                        >
                          <Typography>Item Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={8}>
                              <FormControl mt={3}>
                                <TextField
                                  fullWidth
                                  id="controlid"
                                  label="Control ID"
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="System generated"
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              lg={4}
                              alignSelf="self-end"
                              align="right"
                            >
                              <Button variant="outlined" color="warning">
                                Scan UPC
                              </Button>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="category"
                                  id="category"
                                  fieldLabel="Item category *"
                                  options={itemcatglist}
                                  displayEmpty
                                />
                                <FormHelperText>
                                  {errors.category}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="estimated_value"
                                  fieldLabel="Estimated value *"
                                  options={itemestlist}
                                  displayEmpty
                                />
                                <FormHelperText>
                                  {errors.estimated_value}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormHelperText>
                                Choose 'Laptop' above for all Tablets, iPads,
                                Kindles, Macbooks, etc.
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="storage_location"
                                  fieldLabel="Storage location *"
                                  options={storageLocation}
                                  displayEmpty
                                />
                                <FormHelperText>
                                  {errors.storage_location}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "15px",
                                }}
                              >
                                <FormLabel>Followup required</FormLabel>
                                <Tooltip title="Highlights row in list, also copies record to followup tab">
                                  <ErrorIcon color="primary" />
                                </Tooltip>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableRipple
                                      name="followup_required"
                                      value={values.followup_required}
                                      onChange={(e) => handleChecked(e)}
                                    />
                                  }
                                  label=""
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="description"
                                  label="Description *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Make model, color, material, size, etc."
                                  multiline={true}
                                  rows={4}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="internal_notes"
                                  label="Internal Notes "
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Notes about the item, owner, a phone call you had with them, etc."
                                  multiline={true}
                                  rows={4}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        mt={6}
                        expanded={imageexpand === "expand-image"}
                        onChange={handleImgExpansion("expand-image")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="image-from-group"
                          id="image-from-group"
                        >
                          <Typography>Image</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                {uploadedImage ? (
                                  <Image alt="image name" src={uploadedImage} />
                                ) : (
                                  <Image
                                    alt="image name"
                                    src="/static/img/lost-found/lost_and_found_pic.jpg"
                                  />
                                )}

                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    JustifyContent: "space-between",
                                    margin: "10px 0 15px",
                                    gap: "15px",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    component="span"
                                    onClick={(e) => clearSelectedImage(e)}
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="warning"
                                    component="span"
                                    onClick={() => handleShowWebCam()}
                                  >
                                    {showWebCam ? "Close WebCam" : "WebCam"}
                                  </Button>
                                </Box>
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id="raised-button-file"
                                  multiple
                                  type="file"
                                  ref={uploadedFileRef}
                                  onChange={(e) => handleFileChange(e)}
                                />
                                <label htmlFor="raised-button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                  >
                                    Choose image
                                  </Button>
                                </label>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Accordion
                        mt={0}
                        expanded={lostexpand === "expand-lost"}
                        onChange={handleLostExpansion("expand-lost")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="lost-form-group"
                          id="lost-form-group"
                        >
                          <Typography>Found</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomDateTimePicker
                                  name="found_date"
                                  label="Date *"
                                  inputFormat="yyyy-MM-dd HH:mm:ss"
                                  placeholder="yyyy-mm-dd hh:mm:ss"
                                  setValue={(value) => {
                                    let date = value ? moment(value) : "";
                                    let dateValue = date
                                      ? date.format("YYYY-MM-DD HH:mm:ss")
                                      : "";
                                    setFieldValue("found_date", dateValue);
                                  }}
                                />
                                <FormHelperText>
                                  {errors.found_date}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="found_name"
                                  label="Founder name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Smith"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel component="legend">
                                  Found by
                                </FormLabel>
                                <StyledRadio>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue=""
                                    name="found_by"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "found_by",
                                        e.currentTarget.value
                                      )
                                    }
                                  >
                                    <FormControlLabel
                                      value="Employee"
                                      control={<Radio />}
                                      label="Employee"
                                    />
                                    <FormControlLabel
                                      value="Guest/Other"
                                      control={<Radio />}
                                      label="Guest/Other"
                                    />
                                  </RadioGroup>
                                </StyledRadio>
                              </FormControl>
                            </Grid>
                            {/* For found by employee */}
                            {values.found_by === "Employee" && (
                              <React.Fragment>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomSelect
                                      name="found_department"
                                      fieldLabel="Department"
                                      options={departmentList}
                                      displayEmpty
                                    />
                                    <FormHelperText>
                                      {errors.found_department}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl mt={3}>
                                    <FormLabel component="legend">
                                      If not claimed, finder wants item:
                                    </FormLabel>
                                    <StyledRadio>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="finder_wants_item"
                                        onChange={(e) =>
                                          setFieldValue(
                                            "finder_wants_item",
                                            e.currentTarget.value
                                          )
                                        }
                                      >
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                        />
                                        <FormControlLabel
                                          value="Unknown"
                                          control={<Radio />}
                                          label="Unknown"
                                        />
                                      </RadioGroup>
                                    </StyledRadio>
                                  </FormControl>
                                </Grid>
                              </React.Fragment>
                            )}

                            {/* For found by Guest/Other */}
                            {values.found_by === "Guest/Other" && (
                              <React.Fragment>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      name="found_address"
                                      label="Address"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="1234 E 3rd St"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      name="found_city"
                                      label="City"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Miami"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                  <FormControl>
                                    <CustomTextField
                                      name="found_state"
                                      label="State"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="FL"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                  <FormControl>
                                    <CustomTextField
                                      name="found_zip"
                                      label="Zip"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="xxxxx"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      name="found_country"
                                      label="Country"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="USA, Canada etc."
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      name="found_phone"
                                      label="Phone"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="xxx-xxx-xxxx"
                                      inputProps={{ maxLength: 20 }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      name="found_email"
                                      label="Email"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="me@you.com"
                                    />
                                  </FormControl>
                                </Grid>
                              </React.Fragment>
                            )}
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="found_location"
                                  label="Found location *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="1256"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Button color="primary" variant="contained" type="submit">
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              {showAlert && (
                <CustomAlert
                  message={alertMessage}
                  severity={alertType}
                  closeAlert={(show) => dispatch(updateShowAlert(show))}
                  showAlert={showAlert}
                />
              )}
            </Card>
          </form>
        )}
      </Formik>
      {showWebCam && (
        // <CameraFeed
        //   sendFile={uploadImage}
        //   takePhoto={takePhoto}
        // />

        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </div>
  );
}

function AddLostAndFound() {
  // State for anchorEl
  const [anchorEl, setAnchorEl] = useState(null);
  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // storage locations
  const [storageLocation, setStorageLocation] = useState([]);
  const getStorageLocationList = () => {
    StorageLocationList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setStorageLocation(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [storageLocationsDialog, setStorageLocationsDialog] = useState(false);
  const storageLocationsDialogOpen = () => {
    setStorageLocationsDialog(true);
  };

  const storageLocationsDialogClose = () => {
    setStorageLocationsDialog(false);
  };

  // item categories
  const [itemcatglist, setItemCatgList] = React.useState([]);

  const getCategoryList = () => {
    CategoryList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemCatgList(
            res.data.map((item) => ({ key: item.key, value: item.key }))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [itemCategoriesDialog, setItemCategoriesDialog] = useState(false);
  const itemCategoriesDialogOpen = () => {
    setItemCategoriesDialog(true);
  };

  const itemCategoriesDialogClose = () => {
    setItemCategoriesDialog(false);
  };
  return (
    // <React.Fragment>
    //   <Helmet title="Add Lost and Found" />
    //   <Typography variant="h3" gutterBottom display="inline">
    //     Add Lost and Found
    //   </Typography>

    //   <Breadcrumbs aria-label="Breadcrumb" mt={2}>
    //     <Link component={NavLink} to="/lostandfound/list-lost-found">
    //       List Lost and Found
    //     </Link>
    //     <Typography>Add Lost and Found</Typography>
    //   </Breadcrumbs>

    //   <Divider my={6} />
    //   <AddLostAndFoundForm />
    // </React.Fragment>
    <React.Fragment>
      <WithPermissionFallback controller="LostAndFound" action="addFound">
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Helmet title="Add Lost and Found" />

            <Typography variant="h3" gutterBottom display="inline">
              Add Lost and Found
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/lostandfound/list-lost-found">
                List Lost and Found
              </Link>
              <Typography>Add Lost and Found</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} md={6} textAlign="right">
            <IconButton
              aria-label="Menu"
              size="large"
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={(e) => handleActionClick(e)}
              style={{ color: "#1374C5" }}
            >
              <MenuIcon />
            </IconButton>
            <StyledMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleActionClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  storageLocationsDialogOpen();
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary={"Storage Locations"} pl={1} />
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  itemCategoriesDialogOpen();
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary={"Item Categories"} pl={1} />
              </MenuItem>
            </StyledMenu>
          </Grid>
        </Grid>
        <Divider my={6} />
        <AddLostAndFoundForm
          storageLocation={storageLocation}
          getStorageLocationList={getStorageLocationList}
          itemcatglist={itemcatglist}
          getCategoryList={getCategoryList}
        />
        {storageLocationsDialog && (
          <StorageLocationsDialog
            storageLoctaionsDialog={storageLocationsDialog}
            storageLocationsDialogClose={storageLocationsDialogClose}
            storageLocation={storageLocation}
            getStorageLocationList={getStorageLocationList}
          />
        )}
        {itemCategoriesDialog && (
          <ItemCategories
            itemCategoriesDialog={itemCategoriesDialog}
            itemCategoriesDialogClose={itemCategoriesDialogClose}
            getCategoryList={getCategoryList}
          />
        )}
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default AddLostAndFound;

import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Calendar from "./calendar/Calendar";
import { useDispatch } from "react-redux";
import CloneWeeklySchedule from "./inner-component/CloneWeeklySchedule";
import moment from "moment";
import { ActiveLocationUsers } from "../../api";

import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";

import {
  Paper as MuiPaper,
  Box,
  Switch,
  Grid,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
  TextField,
  Divider as MuiDivider,
  FormLabel,
  Card,
  CardContent,
  ListItemButton,
  InputAdornment,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  Brightness1Outlined as Brightness1OutlinedIcon,
  FilterList as FilterListIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function ScheduleCalendarContent({ reloadEventsList, setReloadEventsList }) {
  const classes = useStyles();
  const [usersList, setUsersList] = useState([]);
  const [hideEvent, setHideEvent] = useState(false);
  const [hideUserEvent, setHideUserEvent] = useState(false);
  // const [reloadEventsList, setReloadEventsList] = useState(false);
  const [draggedUserEvent, setDraggedUserEvent] = useState(null);
  const [draggedNoteEvent, setDraggedNoteEvent] = useState(null);
  const [eventNote, setEventNote] = useState("");
  const [events, setEvents] = useState([]);

  const [loading, setLoading] = useState(false);
  const [dot, setDot] = useState("bg-primary");
  const selectDot = (event) => {
    setDot(event.target.value);
  };

  // Clone Weekly Schedule Dialog

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = () => {
    setLoading(true);
    ActiveLocationUsers()
      .then((res) => {
        if (res.statusCode == 200) {
          setUsersList(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  const AddEventQueue = () => {
    let event = {
      title: eventNote,
      event_class: dot,
      color_code: getEventColorCode(dot),
    };
    setEvents([...events, event]);
    setEventNote("");
    setDot("bg-primary");
  };

  const setNoteEvent = (evt, index) => {
    let noteEvent = {
      ...evt,
      index: index,
    };
    setDraggedNoteEvent(noteEvent);
  };

  const removeNoteEvent = (value) => {
    setEvents(events.filter((data, index) => index !== value));
  };

  const dispatch = useDispatch();

  const alertMessage = (msg, type) => {
    dispatch(getMsg(msg));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };

  const getEventColorCode = (value) => {
    switch (value) {
      case "bg-primary":
        return "#1582dc";
        break;
      case "bg-success":
        return "#15db81";
        break;
      case "bg-info":
        return "#d08426";
        break;
      case "bg-warning":
        return "#daac16";
        break;
      case "bg-danger":
        return "#da3e16";
        break;
      case "bg-color":
        return "#4f5061";
        break;
      default:
        return "#1582dc";
        break;
    }
  };
  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={12} lg={4}>
              <Box>
                <Typography variant="h3" component="div" gutterBottom>
                  {moment(new Date()).format("dddd")}
                </Typography>
                <Typography>
                  {moment(new Date()).format("MMM DD ")}
                  <strong>{moment(new Date()).format("yyyy")}</strong>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "10px 0 3px 0",
                }}
              >
                <FormLabel>Notes/Events</FormLabel>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={hideEvent}
                    onChange={() => setHideEvent(!hideEvent)}
                  />
                  <span className="slider round"></span>
                  <span className="label">{hideEvent ? "Hide" : ""}</span>
                  <span className="label">{!hideEvent ? "Show" : ""}</span>
                </label>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <TextField
                  placeholder="Add Note"
                  fullWidth
                  value={eventNote}
                  onChange={(e) => setEventNote(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: "auto !important",
                            marginRight: "-14px",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          },
                        }}
                      >
                        <Select
                          labelId="department"
                          id="dot"
                          value={dot}
                          onChange={selectDot}
                          fullWidth
                          displayEmpty
                          sx={{
                            width: "50px",
                            textOverflow: "unset",
                            "& .MuiSelect-iconOutlined": {
                              display: "none",
                            },
                            "& .MuiSelect-outlined": {
                              textOverflow: "unset !important",
                              height: "20px !important",
                            },
                            "& .MuiOutlinedInput-input": {
                              color: "transparent",
                            },
                          }}
                        >
                          <MenuItem value="bg-primary">
                            <Brightness1OutlinedIcon
                              color="primary"
                              sx={{ mr: "5px" }}
                            />
                            Visitor
                          </MenuItem>
                          <MenuItem value="bg-success">
                            <Brightness1OutlinedIcon
                              color="success"
                              sx={{ mr: "5px" }}
                            />
                            Call Off
                          </MenuItem>
                          <MenuItem value="bg-info">
                            <Brightness1OutlinedIcon
                              color="info"
                              sx={{ mr: "5px" }}
                            />
                            Training
                          </MenuItem>
                          <MenuItem value="bg-warning">
                            <Brightness1OutlinedIcon
                              color="warning"
                              sx={{ mr: "5px" }}
                            />
                            Vacation
                          </MenuItem>
                          <MenuItem value="bg-danger">
                            <Brightness1OutlinedIcon
                              color="error"
                              sx={{ mr: "5px" }}
                            />
                            Critical
                          </MenuItem>
                          <MenuItem value="bg-color">
                            <Brightness1OutlinedIcon
                              color="color"
                              sx={{ mr: "5px" }}
                            />
                            Note
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{ height: "51px" }}
                  onClick={() => {
                    if (eventNote.trim() != "") {
                      AddEventQueue();
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
              {events.length == 0 ? (
                ""
              ) : (
                <Card
                  variant="outlined"
                  sx={{
                    "& .MuiList-root": {
                      padding: 0,
                    },
                    marginTop: "15px",
                  }}
                >
                  <List className="dndOutsideSourceExample">
                    {events?.map((row, index) => {
                      return (
                        <ListItem
                          sx={{ backgroundColor: row.color_code }}
                          divider={true}
                          disablePadding={true}
                          draggable="true"
                          key={index}
                          onDragStart={() => setNoteEvent(row, index)}
                        >
                          <ListItemButton>
                            <ListItemText primary={row.title} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Card>
              )}

              <Divider my={6} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel>
                  Users
                  <IconButton color="primary" sx={{ ml: "5px" }}>
                    <Tooltip
                      title={"Change Colors by Editing the User Account"}
                      placement="top-start"
                    >
                      <ErrorIcon />
                    </Tooltip>
                  </IconButton>
                </FormLabel>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={hideUserEvent}
                    onChange={() => setHideUserEvent(!hideUserEvent)}
                  />
                  <span className="slider round"></span>
                  <span className="label">{hideUserEvent ? "Hide" : ""}</span>
                  <span className="label">{!hideUserEvent ? "Show" : ""}</span>
                </label>
              </Box>
              <Card
                variant="outlined"
                sx={{
                  "& .MuiList-root": {
                    padding: 0,
                  },
                }}
              >
                <List className="dndOutsideSourceExample">
                  {usersList.map((row, index) => {
                    return (
                      <ListItem
                        divider={true}
                        disablePadding={true}
                        draggable="true"
                        key={row.id}
                        onDragStart={() => setDraggedUserEvent(row)}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <Brightness1OutlinedIcon
                              sx={{ color: row.color_code }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={row.UserName} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <Calendar
                alertMessage={(msg, type) => alertMessage(msg, type)}
                setLoading={(status) => setLoading(status)}
                hideEvent={hideEvent}
                hideUserEvent={hideUserEvent}
                resetDraggedUserEvent={() => setDraggedUserEvent(null)}
                draggedUserEvent={draggedUserEvent}
                resetDraggedNoteEvent={() => setDraggedNoteEvent(null)}
                draggedNoteEvent={draggedNoteEvent}
                removeNoteEvent={(index) => removeNoteEvent(index)}
                reloadEventsList={reloadEventsList}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function ScheduleCalendar() {
  const [usersList, setUsersList] = useState([]);
  const [reloadEventsList, setReloadEventsList] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dot, setDot] = useState("bg-primary");
  const selectDot = (event) => {
    setDot(event.target.value);
  };
  const getUsersList = () => {
    setLoading(true);
    ActiveLocationUsers()
      .then((res) => {
        if (res.statusCode == 200) {
          setUsersList(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  const dispatch = useDispatch();

  const alertMessage = (msg, type) => {
    dispatch(getMsg(msg));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };
  const [cloneWeeklyScheduleDialog, setCloneWeeklyScheduleDialog] =
    useState(false);
  const cloneWeeklyScheduleDialogOpen = () => {
    setCloneWeeklyScheduleDialog(true);
  };
  const cloneWeeklyScheduleDialogClose = () => {
    setCloneWeeklyScheduleDialog(false);
  };
  return (
    <React.Fragment>
      <Helmet title="Calendar" />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <Typography variant="h3" gutterBottom display="inline">
            Calendar
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} align="right">
          <Button
            sx={{ marginLeft: "10px" }}
            color="primary"
            variant="contained"
            onClick={cloneWeeklyScheduleDialogOpen}
          >
            Clone Weekly Schedule
          </Button>
        </Grid>
      </Grid>
      {cloneWeeklyScheduleDialog && (
        <CloneWeeklySchedule
          cloneWeeklyScheduleDialog={cloneWeeklyScheduleDialog}
          setCloneWeeklyScheduleDialog={setCloneWeeklyScheduleDialog}
          cloneWeeklyScheduleDialogOpen={cloneWeeklyScheduleDialogOpen}
          cloneWeeklyScheduleDialogClose={cloneWeeklyScheduleDialogClose}
          alertMessage={(msg, type) => alertMessage(msg, type)}
          setLoading={(status) => setLoading(status)}
          reloadEventsList={(status) => setReloadEventsList((prev) => !prev)}
        />
      )}

      <ScheduleCalendarContent
        reloadEventsList={reloadEventsList}
        setReloadEventsList={setReloadEventsList}
      />
    </React.Fragment>
  );
}

export default ScheduleCalendar;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
  Button,
  Checkbox,
  Radio,
  FormControlLabel,
  TableCell,
  TableRow,
  Link,
  DialogActions,
  RadioGroup,
  TableContainer,
  Table,
  FormControl as MuiFormControl,
  FormLabel,
  TableBody,
  CircularProgress,
  Box,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import StripeCheckout from "react-stripe-checkout";
import { sova } from "../../../config";
import { SmsCreditPop, SaveSMSCreditPayment } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const PurchaseSmsCredits = (props) => {
  const {
    sovaCreditSystem,
    setSovaCreditSystem,
    sovaCreditSystemOpen,
    sovaCreditSystemClose,
    getSubcriptionDetail,
  } = props;

  const [loading, setLoading] = useState(false); //State for loader
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedObject, setSelectedObject] = useState({});
  const [plansList, setPlansList] = useState([]);
  const [reminderEmail, setReminderEmail] = useState(false);
  const dispatch = useDispatch();
  const handleReminderChange = (event) => {
    setReminderEmail(event.target.checked);
  };
  const CurrentLocationInfo = useSelector((state) => state.login.Location);

  //Method for paymnent confirmation
  const savePayment = (payload) => {
    SaveSMSCreditPayment(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackMsg(res.message));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          sovaCreditSystemClose();
          getSubcriptionDetail();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          sovaCreditSystemClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        sovaCreditSystemClose();
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        let errors = err.data.errors;
        console.log(errors);
      });
  };

  const onToken = (token) => {
    // console.log("+++++++++++++++++++++++", token);
    let payload = {
      location_id: CurrentLocationInfo.LocationID,
      stripeToken: token.id,
      stripeEmail: token.email,
      amount: selectedObject.value,
      sms_plan: selectedObject.sms_plan,
      mail_reminder: reminderEmail,
    };
    savePayment(payload);
  };

  const getCreditSMSPlan = () => {
    setLoading(true);
    SmsCreditPop()
      .then((res) => {
        if (res.statusCode == 200) {
          setPlansList(res.data);
        } else {
          setPlansList([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getCreditSMSPlan();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="credit-system-title"
        open={sovaCreditSystem}
        onClose={sovaCreditSystemClose}
        aria-describedby="credit-system-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="credit-system-title"
        >
          <Typography variant="h4">SOVA Credit System</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setSovaCreditSystem(false)}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Credits are used to send SMS messages through SOVA. If credits are
            allowed to expire, SMS balance goes negative but sending is not
            disabled. SMS sending may be diabled if balance is not brought
            positive. Choose a credit amount from the list then click the Buy
            button to pay with a credit card. Credits purchased will add to your
            balance.
          </DialogContentText>
          {/* <FormLabel>Quarter</FormLabel> */}
          <Table>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {plansList.map((item) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedPlan == item.value}
                              onChange={(e) => {
                                setSelectedPlan(e.target.value);
                                setSelectedObject(item);
                              }}
                              value={item.value}
                              name="quarter_q1"
                            />
                          }
                          label={`$ ${item.value} credit`}
                        />
                      </TableCell>
                      <TableCell align="right">{item.key}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>

          {/* <TableContainer>
            <Table>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    value="tenCredits"
                    control={<Radio />}
                    label="$10 Credit"
                  />
                </TableCell>
                <TableCell>approx 500 SMS</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    value="tenCredits"
                    control={<Radio />}
                    label="$10 Credit"
                  />
                </TableCell>
                <TableCell>approx 500 SMS</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    value="tenCredits"
                    control={<Radio />}
                    label="$10 Credit"
                  />
                </TableCell>
                <TableCell>approx 500 SMS</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    value="tenCredits"
                    control={<Radio />}
                    label="$10 Credit"
                  />
                </TableCell>
                <TableCell>approx 500 SMS</TableCell>
              </TableRow>
            </Table>
          </TableContainer> */}
          {/* <FormControlLabel
            value="end"
            control={<Checkbox />}
            label="Remind when account fails below 500 SMS threshold"
            labelPlacement="end"
          /> */}
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={reminderEmail}
                  onChange={handleReminderChange}
                />
              }
              label="Remind when account fails below 500 SMS threshold"
            />
          </Box>
          {/* <Typography>
            By clicking the buy button, you agree to our{" "}
            <Link>term of service</Link>
          </Typography> */}
        </DialogContent>
        <DialogActions>
          {/* <Button
            color="primary"
            variant="outlined"
            onClick={sovaCreditSystemClose}
            sx={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: "10px" }}
          >
            Buy
          </Button> */}
          <StripeCheckout
            token={(e) => onToken(e)}
            name="SOVA SMS Credit"
            // description={row.category + " (" + row.totalAmountToPay + ")"}
            currency="USD"
            amount={selectedObject.value * 100}
            locale="auto"
            image="/static/img/brands/sova-eye.png"
            stripeKey={sova.StripePublishableKey}
          >
            <Button
              // sx={{ marginTop: "30px" }}
              color="primary"
              variant="contained"
              disabled={selectedPlan == ""}
              sx={{ marginRight: "10px" }}
            >
              Pay with Card
            </Button>
          </StripeCheckout>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PurchaseSmsCredits;

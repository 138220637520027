import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Cancel as CancelIcon } from "@mui/icons-material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { NonTourUserList } from "../../../api";
import moment from "moment";
import CustomDateRangeDropdown from "../../../components/form-components/CustomDateRangeDropdown";
import CustomDateRange from "../../../components/form-components/CustomDateRange";
const Paper = styled(MuiPaper)(spacing);

const NTourTaskD1 = (props) => {
  const { nontourtask, setNonTourTask, nonTourTaskClose, taskNameDialog } =
    props;
  // const [startDate, setStartDate] = useState(null);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [nonTourUserList, setNonTourUserList] = useState([]);
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  const [isFilter, setIsFilter] = useState(false);

  const getNonTourTask = () => {
    // let formatedsDate = startDateToFilter; //moment(startDateToFilter).format("YYYY-MM-DD");
    // let formatedeDate = endDateToFilter; //moment(endDateToFilter).format("YYYY-MM-DD");
    let payload = {
      task_name: taskNameDialog,
    };
    setDialogLoader(true);
    NonTourUserList(payload)
      .then((res) => {
        setDialogLoader(false);
        setNonTourUserList(res.data);
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  const getNonTourTaskSearch = () => {
    let formatedsDate = moment(startDateToFilter).format("YYYY-MM-DD"); //startDateToFilter;
    let formatedeDate = moment(endDateToFilter).format("YYYY-MM-DD"); //endDateToFilter;
    let payload = {
      task_name: taskNameDialog,
      start_date: formatedsDate,
      end_date: formatedeDate,
    };
    setDialogLoader(true);
    NonTourUserList(payload)
      .then((res) => {
        setDialogLoader(false);
        setNonTourUserList(res.data);
        // if (res.statusCode === 200) {
        //   setDialogLoader(false);
        //   setNonTourUserList(res.data);
        // } else {
        //   setDialogLoader(false);
        // }
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
  };

  useEffect(() => {
    getNonTourTask();
  }, []);

  return (
    <>
      {/* Non Tour Task Modal */}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        open={nontourtask}
        onClose={nonTourTaskClose}
        aria-labelledby="non-tour-title"
        aria-describedby="non-tour-description"
      >
        <DialogTitle
          id="non-tour-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Non Tour Task 30 Days - {taskNameDialog}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setNonTourTask(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Grid container mt={2} sx={{ padding: "20px 24px 0" }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="dateRange"
                label="Date range"
                value={
                  startDateToFilter && endDateToFilter
                    ? moment(startDateToFilter).format("MM/DD/YYYY") +
                      " - " +
                      moment(endDateToFilter).format("MM/DD//YYYY")
                    : ""
                }
                onClick={() => setFadedBox((prev) => !prev)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getNonTourTaskSearch();
                    setIsFilter(true);
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Select date range"
              />
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                  }}
                  selectedStart={startDateToFilter}
                  selectedEnd={endDateToFilter}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              {isFilter ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    resetFilter();
                    getNonTourTask();
                    setIsFilter(false);
                    // setSearchInput("");
                  }}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginRight: { lg: "50px" },
                    marginLeft: "-3px",
                    width: "106px",
                  }}
                >
                  Clear Filter
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginRight: { lg: "50px" },
                    marginLeft: "-3px",
                    width: "106px",
                  }}
                  onClick={() => {
                    if (startDateToFilter !== "") {
                      getNonTourTaskSearch();
                      setIsFilter(true);
                    }
                  }}
                >
                  Search
                </Button>
              )}
            </Grid>
          </Grid>
          <Paper mt={3}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Datetime</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogLoader ? (
                    <TableCell
                      colSpan={9}
                      align="center"
                      sx={{
                        height: "50px",
                        paddingTop: "10px",
                        paddingBottom: "5px",
                        "& .MuiCircularProgress-root": {
                          width: "28px !important",
                          height: "28px !important",
                        },
                      }}
                    >
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <>
                      {nonTourUserList.map((item, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell>{item.scan_time}</TableCell>
                            <TableCell>{item.user_name}</TableCell>
                            <TableCell>{item.task_location}</TableCell>
                            <TableCell>{item.comment}</TableCell>
                          </TableRow>
                        );
                      })}
                      {nonTourUserList.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            No data for selected date range
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button color="primary" variant="outlined" onClick={nonTourTaskClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NTourTaskD1;

import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";

const ViewMatchingPair = ({ show, handleModal, data, handelNoMatch }) => {
  return (
    <Dialog
      open={show}
      aria-labelledby="activity-log-title"
      aria-describedby="activity-log-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "900px",
          },
        },
      }}
    >
      <DialogTitle
        id="activity-log-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Matched Item Value: {data.point}
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => handleModal(false)}
          >
            <CancelRoundedIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <DialogContentText sx={{ padding: "30px 24px" }}>
          SOVA uses 13 different criteria to match lost records with stored
          records. The matched value for this particular lost & found pair is
          shown in the header above. Values over 7 indicate a high likelihood of
          a match. Green rows in the table below indicate a positive hit or
          match on one of the rules, while red indicates a poor match on that
          particular rule. Click the view buttons below to review details for
          the record. Close this popup and choose merge if you feel there is a
          match based on the criteria shown below. Choosing 'no match' will
          prevent these two items from being matched next time. By choosing 'no
          match', your records are not deleted, they simply will no longer be
          paired together on this screen.
        </DialogContentText>
        <Paper mt={4}>
          <TableContainer className="tableContainer">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Rule</TableCell>
                  <TableCell>Lost Value</TableCell>
                  <TableCell>Stored Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.sets.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      style={{ background: row.color }}
                    >
                      <TableCell>{row.key}</TableCell>
                      <TableCell sx={{ minWidth: "200px" }}>
                        {row.value}
                      </TableCell>
                      <TableCell>{row.lost_value}</TableCell>
                      <TableCell>{row.found_value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Paper sx={{ padding: "30px 15px 0px" }}>
          <Typography variant="h4" component="div" gutterBottom>
            Other Details
          </Typography>
          <Typography variant="small" component="p" gutterBottom>
            Note: CTRL+click buttons below to open LF records in a background
            tab.
          </Typography>
        </Paper>
        <Paper mt={4}>
          <TableContainer className="tableContainer">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Element</TableCell>
                  <TableCell>
                    <Link
                      to={"/lostandfound/edit-lost-found/" + data.lost_id}
                      style={{ textDecoration: "none" }}
                    >
                      <Button color="primary" variant="outlined">
                        View Lost {data.lost_id}
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={"/lostandfound/edit-lost-found/" + data.found_id}
                      style={{ textDecoration: "none" }}
                    >
                      <Button color="primary" variant="outlined">
                        View Stored {data.found_id}
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover>
                  <TableCell>Category</TableCell>
                  <TableCell>{data.lost_cat}</TableCell>
                  <TableCell>{data.found_cat}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>Description</TableCell>
                  <TableCell>{data.lost_des}</TableCell>
                  <TableCell>{data.found_des}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>Location</TableCell>
                  <TableCell>{data.lost_location}</TableCell>
                  <TableCell>{data.found_location}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>Date</TableCell>
                  <TableCell>{data.lost_date}</TableCell>
                  <TableCell>{data.found_date}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          onClick={() => handelNoMatch(true)}
          color="primary"
          variant="outlined"
        >
          No Match
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewMatchingPair;

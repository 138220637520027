import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  DialogActions,
  Avatar as MuiAvatar,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const BigAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
`;
const PeekModal = ({ peekModalClose, peekModal, singleData }) => {
  return (
    <>
      <Dialog
        open={peekModal}
        onClose={peekModalClose}
        aria-labelledby="peak-dialog"
        aria-describedby="peak-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          id="peak-dialog"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">New Reference</Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={peekModalClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={9} lg={9} order={{ xs: 2, md: 1 }}>
              <Typography>
                <strong>Author: </strong>
                {singleData.user.UserName}
              </Typography>
              <Typography mt={1}>
                <strong>Classification: </strong>
                {singleData.Classification}
              </Typography>
              <Typography mt={1}>
                <strong>Datetime: </strong>
                {singleData.Datetime}
              </Typography>
              <Typography mt={1}>
                <strong>Involved Person: </strong>
                {singleData.involved_person
                  ? singleData.involved_person
                  : "None"}
              </Typography>
              <Typography mt={1}>
                <strong>Narrative: </strong>
                {singleData.Description}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} lg={3} order={{ xs: 1, md: 1 }}>
              <BigAvatar
                alt="Remy Sharp"
                src="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PeekModal;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  IconButton,
  Paper as MuiPaper,
  ListItemText as MuiListItemText,
  Typography,
  FormControl as MuiFormControl,
  List,
  ListItem,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { TourUserListMissedButtonU } from "../../../api";

// Classes Styling
const useStyles = makeStyles((theme) => ({
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);

const UsernameDialog2 = (props) => {
  const {
    buttonDetail,
    setButtonDetail,
    buttonDetailClose,
    tagIdD4,
    tagNameD4,
    userIdD3,
    userDialog,
    sDateMissedUser,
    eDateMissedUser,
  } = props;
  const classes = useStyles();
  const [dialogLoaderInner, setDialogLoaderInner] = useState(false);
  const [userMissedButtonU, setUserMissedButtonU] = useState([]); // state for list in get-user-list-missed-button-U (D4) inner dialog username

  //handler for (D4) getUserListMissedButton-U : inner dialog for username column
  const getUserListMissedButtonU = () => {
    let formatedsDate = moment(sDateMissedUser).format("YYYY-MM-DD");
    let formatedeDate = moment(eDateMissedUser).format("YYYY-MM-DD");
    let payload = {
      tagId: tagIdD4,
      userId: userIdD3, //"9935162353",
      start_date: formatedsDate, //"2016-08-03",
      end_date: formatedeDate, //"2022-06-01",
    };
    setDialogLoaderInner(true);
    TourUserListMissedButtonU(payload)
      .then((res) => {
        setUserMissedButtonU(res.data);
        setDialogLoaderInner(false);
        // if (res.statusCode == 200) {
        //   setDialogLoaderInner(false);
        //   setUserMissedButtonU(res.data);
        // } else {
        //   setDialogLoaderInner(false);
        // }
      })
      .catch((err) => {
        console.log(err);
        setDialogLoaderInner(false);
      });
  };

  useEffect(() => {
    getUserListMissedButtonU();
  }, []);

  return (
    <>
      {/* get-user-list-missed-button U : Inner Dialog for Username column */}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={buttonDetail}
        onClose={buttonDetailClose}
        aria-labelledby="btn-detail-title"
        aria-describedby="btn-description"
      >
        <DialogTitle
          id="btn-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div" gutterBottom>
            {tagNameD4}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setButtonDetail(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" component="div" gutterBottom>
              {userDialog}
            </Typography>
            <Typography variant="p" component="div" gutterBottom>
              List below shows the tour start times when this user started a
              tour but not scan this button
            </Typography>
          </DialogContentText>
          <List mt={4} className={classes.DateList}>
            {dialogLoaderInner ? (
              <ListItem
                align="center"
                sx={{
                  height: "40px",
                  paddingTop: "10px",
                  paddingBottom: "5px",
                  "& .MuiCircularProgress-root": {
                    width: "25px !important",
                    height: "25px !important",
                  },
                }}
              >
                <CircularProgress />
              </ListItem>
            ) : (
              <>
                {userMissedButtonU.map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText primary={item.tour_start_time} />
                    </ListItem>
                  );
                })}
                {userMissedButtonU.length === 0 ? (
                  <ListItem>
                    <ListItemText primary="No data for selected date range" />
                  </ListItem>
                ) : null}
              </>
            )}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UsernameDialog2;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
  Typography,
  Grid,
  FormControl as MuiFormControl,
  TextField,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  Autocomplete,
  Checkbox,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@mui/material";
import {
  Settings as SettingsIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
} from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  NotifyTemplateList,
  UserMailList,
  AddUpdateNotifyTemplates,
  ViewNotifyTemplates,
  DeleteNotifyTemplates,
} from "../../../api";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
const ListItemText = styled(MuiListItemText)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ENotifyiTempletes = () => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;

  const [notifyTemplateData, setNotifyTemplateData] = useState([]);
  const [usermailData, setUsermailData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMailUsers, setSelectedMailUsers] = useState([]);
  const [saveMainData, setSaveMainData] = useState({});
  const [updatedRow, setUpdatedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    notification_text: "",
  });
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteOpen = (id) => {
    setOpenDelete(true);
  };

  const handleChange = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleActionClick = (event, row) => {
    setUpdatedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (saveMainData.length > 0) {
      let result = [];
      saveMainData.forEach((obj) => {
        usermailData.forEach((result3) => {
          if (result3.value === obj) {
            result.push(result3);
          }
        });
      });
      setSelectedMailUsers(result);
    }
  }, [saveMainData]);
  const deleteNotifyTemplates = () => {
    DeleteNotifyTemplates(updatedRow.id)
      .then((res) => {
        if (res.statusCode === 200) {
          handleDeleteClose();
          notifyTemplateList();
          dispatch(getMsg("Data deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addUpdateNotifyTemplates = () => {
    let payload = {
      id: id ? id : null,
      title: formData.title,
      notification_text: formData.notification_text,
      recepients: selectedMailUsers.map((it) => it.value),
    };
    if (payload.title == "" || payload.notification_text == "") {
      dispatch(getMsg("Please Fill Both field Title & Text"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    } else {
      AddUpdateNotifyTemplates(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            notifyTemplateList();
            if (id) {
              navigate("/settings/corporate-user/e-notify-templetes");
              dispatch(getMsg("data updated successfully"));
            } else {
              dispatch(getMsg("data add successfully"));
            }
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setFormData({
              title: "",
              notification_text: "",
            });
            setSelectedMailUsers([]);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {});
    }
  };

  const viewNotifyTemplates = () => {
    ViewNotifyTemplates(id)
      .then((res) => {
        if (res.statusCode == 200) {
          setFormData(res.data);
          setSaveMainData(res.data.recepients);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const userMailList = () => {
    UserMailList()
      .then((res) => {
        if (res.statusCode == 200) {
          setUsermailData(res.data);
        } else {
          setUsermailData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const notifyTemplateList = () => {
    NotifyTemplateList()
      .then((res) => {
        if (res.statusCode == 200) {
          setNotifyTemplateData(res.data);
          console.log(res.data);
        } else {
          setNotifyTemplateData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    notifyTemplateList();
    userMailList();
    viewNotifyTemplates();
  }, [id]);

  return (
    <>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this template?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={deleteNotifyTemplates} autoFocus variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h3" gutterBottom>
        E-Notify Templates
      </Typography>
      <Typography variant="body2" gutterBottom mt={4}>
        The form below allows you to save canned messages to be sent for various
        types of emergencies via the 'E-Notify' function in Live Dispatch. You
        can learn more about this feature by going here. Use the form below to
        enter an easy to remember title for your notification, followed by the
        text that you wish to appear in the E-Notify screen. We recommend
        entering a notification of less than 160 characters in the editor below
        for better deliverability and so the message can be quickly read and
        acted upon.
      </Typography>

      <Grid container spacing={4}>
        <Grid item lg={6} md={6} mt={4}>
          <FormControl>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              value={selectedMailUsers}
              onChange={(e, v) => {
                setSelectedMailUsers(v);
              }}
              options={usermailData}
              disableCloseOnSelect
              getOptionLabel={(option) => option.key}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.key}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Recipients"
                  placeholder="Select Recipients"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    marginBottom: "30px",
                  }}
                />
              )}
            />
          </FormControl>
          {/* <FormControl> */}
          <TextField
            fullWidth
            id="outlined"
            label="Title"
            name="title"
            value={formData.title}
            onChange={(event) =>
              setFormData({
                ...formData,
                title: event.target.value,
              })
            }
            placeholder="Department fire alert"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* </FormControl> */}
        </Grid>
        <Grid item lg={6} md={6}></Grid>
        <Grid item lg={6} md={6} mt={3}>
          {/* <FormControl> */}
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label="Text"
            name="notification_text"
            value={formData.notification_text}
            placeholder="Short and concise. Try to limit to 160 characters for better deliverability. Hard limit is 400 characters"
            onChange={(event) =>
              setFormData({
                ...formData,
                notification_text: event.target.value,
              })
            }
            multiline
            rows={2}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* </FormControl> */}
          <Box mt={3}>
            {/* <Button variant="outlined">None Selected</Button> */}
            <Button onClick={addUpdateNotifyTemplates} variant="contained">
              Save
            </Button>
          </Box>
        </Grid>
        <Grid item lg={6} md={6}></Grid>
      </Grid>
      <Card
        sx={{
          marginTop: "20px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Notification Title</TableCell>
              <TableCell align="right">Notification Text</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifyTemplateData.map((row) => {
              return (
                <TableRow>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="right">{row.notification_text}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="Settings"
                      size="large"
                      aria-owns={anchorEl ? "icon-menu" : undefined}
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleActionClick(event, row);
                      }}
                      style={{ color: "#1374C5" }}
                    >
                      <SettingsIcon />
                    </IconButton>
                    <Menu
                      id="icon-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleActionClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      getContentAnchorEl={null}
                    >
                      <MenuItem
                        onClick={() => {
                          handleActionClose();
                          viewNotifyTemplates();
                        }}
                        component={NavLink}
                        to={`/settings/corporate-user/e-notify-templetes/${updatedRow.id}`}
                      >
                        <ListItemIcon>
                          <CreateIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edit" pl={1} />
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleActionClose();
                          handleDeleteOpen();
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delete" pl={1} />
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default ENotifyiTempletes;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import html2canvas from "html2canvas";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { makeStyles } from "@mui/styles";
import { handleApiResonseErrors } from "../../helper/helper";
import { useSelector, useDispatch } from "react-redux";
import { updateShowAlert, updateText } from "../../redux/slices/mainSlice";
import CustomAlert from "../../components/form-components/CustomAlert";
import { SaveEmergencyProcedures, EmergencyProcedures } from "../../api";

import {
  Box,
  CardContent,
  Paper,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";

// Styles for the Card and other components
const Card = styled(MuiCard)`
  ${spacing};
  overflow: visible;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  previewContainer: {
    marginTop: "20px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  page: {
    marginBottom: "10px",
    border: "1px solid #ccc",
    display: "inline-block",
  },
}));

function EditEmergencyProceduresContent(props) {
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const createSnapshotAndSubmit = async () => {
    try {
      setLoading(true);
      const editorElement = document.querySelector(".ql-editor");
      const canvas = await html2canvas(editorElement);
      const base64Image = canvas.toDataURL("image/png");
      const file = base64ToFile(base64Image, "canvas-image.png");

      let formData = new FormData();
      formData.append("type", params.emergency_type);
      formData.append("sub_type", params.sub_type);
      formData.append("description", description);
      formData.append("file", file);

      const response = await SaveEmergencyProcedures(formData);
      if (response.statusCode === 200) {
        dispatch(updateShowAlert(true));
        dispatch(updateText("Record has been updated successfully"));
        navigate({ pathname: "/logs/emergency-procedures" });
      } else {
        messageAlert("Sorry! Something went wrong. Please try again.", "error");
      }
    } catch (error) {
      const apiError = handleApiResonseErrors(error?.data?.errors);
      if (apiError) {
        messageAlert(apiError, "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    getEmergencyData();
  }, []);

  const getEmergencyData = () => {
    let payload = {
      type: params.emergency_type,
    };
    EmergencyProcedures(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const reqSubtype = res.data.find(
            (item) => item.sub_type === params.sub_type
          );
          if (reqSubtype) {
            setDescription(reqSubtype.description);
          }
        }
        setLoading(false);
      })
      .catch(() => {});
  };

  const messageAlert = (msg, type) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  return (
    <>
      <Card mt={6}>
        <CardContent>
          <Box mt={3}>
            <QuillWrapper>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                placeholder="Type something.."
              />
            </QuillWrapper>
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: "20px" }}
              onClick={createSnapshotAndSubmit}
              disabled={!description}
            >
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
    </>
  );
}

function UploadPdfEmergencyProcedures() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  useEffect(() => {
    fetchUploadedPdf();
  }, []);

  const fetchUploadedPdf = async () => {
    setLoading(true);
    try {
      const payload = {
        type: params.emergency_type,
      };
      const res = await EmergencyProcedures(payload);
      if (res.statusCode === 200) {
        const reqSubtype = res.data.find(
          (item) => item.sub_type === params.sub_type
        );
        if (reqSubtype?.url) {
          setUploadedFileUrl(reqSubtype.url);
          setFileUrl(reqSubtype.url); // Display uploaded file initially
        }
      }
    } catch (error) {
      console.error("Error fetching uploaded PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileUrl(URL.createObjectURL(file));
    }
  };

  const messageAlert = (msg, type) => {
    setUploading(false);
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  const handleUpload = () => {
    if (!selectedFile) return;

    let formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("type", params.emergency_type);
    formData.append("sub_type", params.sub_type);
    formData.append("description", null);

    setUploading(true);
    SaveEmergencyProcedures(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(updateShowAlert(true));
          dispatch(updateText("Record has been updated successfully"));
          navigate({ pathname: "/logs/emergency-procedures" });
        } else {
          messageAlert(
            "Sorry! Something went wrong. Please try again.",
            "error"
          );
        }
      })
      .catch((err) => {
        const error = handleApiResonseErrors(err?.data?.errors);
        if (error) {
          messageAlert(error, "error");
        }
      });
  };

  return (
    <>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      <Card mt={6}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Upload PDF
          </Typography>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
          />
          {fileUrl && (
            <Box className={classes.previewContainer}>
              <Typography variant="body1">
                <strong>
                  {fileUrl === uploadedFileUrl
                    ? "Uploaded File:"
                    : "Selected File:"}
                </strong>{" "}
                {fileUrl === uploadedFileUrl
                  ? "Previously Uploaded PDF"
                  : selectedFile?.name}
              </Typography>
              <Box className={classes.pdfPreview}>
                <iframe
                  src={fileUrl}
                  width="100%"
                  height="500px"
                  title="PDF Preview"
                />
              </Box>
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={!selectedFile || uploading || fileUrl === uploadedFileUrl}
            sx={{ mt: "20px" }}
          >
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </CardContent>
      </Card>
    </>
  );
}

function EditEmergencyProcedures() {
  const params = useParams();
  return (
    <React.Fragment>
      <Helmet title="Emergency Procedures" />
      <Typography variant="h3" gutterBottom display="inline">
        Emergency Procedures
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/emergency-procedures">
          Emergency Procedures
        </Link>
        <Typography>
          {`${params.emergency_type
            .charAt(0)
            .toUpperCase()}${params.emergency_type.slice(1)} / ${params.sub_type
            .charAt(0)
            .toUpperCase()}${params.sub_type.slice(1)}`}
        </Typography>
      </Breadcrumbs>

      <Divider my={6} />
      {params.type === "editor" && <EditEmergencyProceduresContent />}
      {params.type === "pdf" && <UploadPdfEmergencyProcedures />}
    </React.Fragment>
  );
}

export default EditEmergencyProcedures;

export default [
  {
    id: "disposition_status",
    label: "Sel",
  },
  {
    id: "id",
    label: "Control ID",
  },
  {
    id: "lost_date",
    label: "Lost Date",
  },
  {
    id: "found_date",
    label: "Found Date",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "found_name",
    label: "Found By",
  },
  {
    id: "found_location",
    label: "Found At",
  },
  {
    id: "storage_location",
    label: "Stored At",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import FindRecord from "./FindRecord";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import CustomDateRange from "../../../../components/form-components/CustomDateRange";
import StyledColumnOptions from "../../../../helper/StyledColumnOptions";
import { useDispatch } from "react-redux";
import { setVisitorListing } from "../../../../redux/slices/visitor/visitorListCalSlice";
import VisitorListingCol from "../../../table-columns-json/visitor/VisitorListing";
import DialogBox from "../../../../components/form-components/DialogBox";
import { DeleteVisitor } from "../../../../api";
import {
  IconButton,
  Paper as MuiPaper,
  InputAdornment,
  ListItemText as MuiListItemText,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as InsertDriveFileIcon,
  SettingsOverscan as SettingsOverscanIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

// Classes Styling
const useStyles = makeStyles((theme) => ({
  alphabets: {
    marginTop: "15px",
    "& .MuiList-root": {
      display: "flex",
      overflowX: "auto",
      background: "#7aa8ff0d",
      padding: "0px",
      "& .MuiListItemButton-root": {
        paddingLeft: "13px",
        paddingRight: "13px",
        textAlign: "center",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
}));

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function EnhancedTableToolbar({
  numSelected,
  companyList,
  visitorTypeList,
  filterRecord,
  showAlertModal,
  tableData,
  checkedInOrOut,
  setCheckedInOrOut,
  tableCalRedux,
  defalutTableCalRedux,
  removeRecords,
}) {
  const [actions, setActions] = useState("");
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [searchUserName, setSearchUserName] = useState("");
  const [trackingStatus, setTrackingStatus] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [visitorTypeId, setVisitorTypeId] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const [fadebox, setFadedBox] = useState(false);
  const classes = useStyles();

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };

  const dispatch = useDispatch();

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //Scan Visitor ID modal
  const [open, visitorModal] = useState(false);
  const scanVisitorModalOpen = () => {
    visitorModal(true);
  };

  let exportCSVheaders = [
    { label: "Id", key: "id" },
    { label: "Visitor Name", key: "name" },
    { label: "Visitor Type", key: "visitorTypeName" },
    { label: "Host Name", key: "hostName" },
    { label: "Company", key: "companyName" },
    { label: "Mobile Phone", key: "phone" },
    { label: "Arrival Date", key: "arrivalDate" },
    { label: "Expiry Date", key: "expiryDate" },
    { label: "Active", key: "active" },
    { label: "Disposition", key: "disposition" },
  ];

  const applySearch = () => {
    const payload = {
      from_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      to_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      name: searchUserName,
      visitor_company: companyId,
      visitor_type: visitorTypeId,
      status: trackingStatus,
    };
    filterRecord(payload);
  };

  const checkedInOrOutFilter = () => {
    handleClose();
    if (checkedInOrOut === false) {
      filterRecord({ status: "CHECKED-IN" });
    } else {
      filterRecord({ status: "" });
    }
    setCheckedInOrOut(!checkedInOrOut);
  };

  const clearFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    setSearchUserName("");
    setTrackingStatus("");
    setVisitorTypeId("");
    setCompanyId("");
    filterRecord({});
  };

  return (
    <React.Fragment>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem
                  value={"Delete"}
                  onClick={() => removeRecords("Delete")}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  value={"Active"}
                  onClick={() => removeRecords("Active")}
                >
                  Active
                </MenuItem>
                <MenuItem
                  value={"Inactive"}
                  onClick={() => removeRecords("Inactive")}
                >
                  Inactive
                </MenuItem>
                <MenuItem
                  value={"CHECKED-IN"}
                  onClick={() => removeRecords("CHECKED-IN")}
                >
                  CHECKED-IN
                </MenuItem>
                <MenuItem
                  value={"CHECKED-OUT"}
                  onClick={() => removeRecords("CHECKED-OUT")}
                >
                  CHECKED-OUT
                </MenuItem>
                <MenuItem
                  value={"print-v-pass"}
                  onClick={() => removeRecords("print-v-pass")}
                >
                  Print Vehicle Pass
                </MenuItem>
                <MenuItem
                  value={"print-visitor-badge"}
                  onClick={() => removeRecords("print-visitor-badge")}
                >
                  Print Visitor Badge
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {numSelected} records found
          </Typography>
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                className={classes.searchFilter}
                id="search"
                type="text"
                placeholder="Search by visitor name, or click down arrow"
                value={searchUserName}
                autoComplete="off"
                inputProps={{}}
                onChange={(e) => {
                  setSearchUserName(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                    setIsFilter(true);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="dateRange"
                                label="Date range"
                                value={
                                  startDateToFilter && endDateToFilter
                                    ? moment(startDateToFilter).format(
                                        "MM-DD-YYYY"
                                      ) +
                                      " - " +
                                      moment(endDateToFilter).format(
                                        "MM-DD-YYYY"
                                      )
                                    : ""
                                }
                                onClick={() => setFadedBox((prev) => !prev)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Select date range"
                              />
                              {fadebox && (
                                <CustomDateRange
                                  handleShow={fadebox}
                                  conformation={(status) =>
                                    setFadedBox((prev) => !prev)
                                  }
                                  setDates={(start, end) => {
                                    setStartDateToFilter(start);
                                    setEndDateToFilter(end);
                                  }}
                                  selectedStart={startDateToFilter}
                                  selectedEnd={endDateToFilter}
                                />
                              )}
                            </FormControl>
                            <FormControl>
                              <InputLabel
                                shrink
                                id="result"
                                sx={{
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  marginLeft: "-4px",
                                }}
                              >
                                Company
                              </InputLabel>
                              <Select
                                labelId="Company"
                                id="result"
                                name="result"
                                value={companyId}
                                onChange={(e) => {
                                  setCompanyId(e.target.value);
                                }}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Please select</MenuItem>
                                {companyList.map((row, index) => {
                                  return (
                                    <MenuItem value={row.key} key={index}>
                                      {row.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel
                                shrink
                                id="result"
                                sx={{
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  marginLeft: "-4px",
                                }}
                              >
                                Visitor Type
                              </InputLabel>
                              <Select
                                labelId="Company"
                                id="result"
                                name="result"
                                value={visitorTypeId}
                                onChange={(e) => {
                                  setVisitorTypeId(e.target.value);
                                }}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Please select</MenuItem>
                                {visitorTypeList.map((row, index) => {
                                  return (
                                    <MenuItem value={row.key} key={index}>
                                      {row.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>

                            <FormControl>
                              <InputLabel
                                shrink
                                id="result"
                                sx={{
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  marginLeft: "-4px",
                                }}
                              >
                                Tracking Status
                              </InputLabel>
                              <Select
                                labelId="Tracking Status"
                                id="result"
                                name="result"
                                value={trackingStatus}
                                onChange={(e) => {
                                  setTrackingStatus(e.target.value);
                                }}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Please select</MenuItem>
                                <MenuItem value="New Visitor">
                                  New Visitor
                                </MenuItem>
                                <MenuItem value="Pre-book">Pre-book</MenuItem>
                                <MenuItem value="CHECKED-IN">
                                  Checked In
                                </MenuItem>
                                <MenuItem value="CHECKED-OUT">
                                  Checked Out
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <Box className={classes.cardHeader}>
                              <Button
                                color="primary"
                                variant="contained"
                                ml={3}
                                onClick={(e) => {
                                  handleCloseForm();
                                  applySearch();
                                  setIsFilter(true);
                                }}
                              >
                                Filter
                              </Button>
                            </Box>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  title="Clear Search"
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    setIsFilter(false);
                    clearFilter();
                  }}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                    width: "100%",
                  }}
                >
                  Clear
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={(e) => {
                    if (searchUserName !== "") {
                      applySearch();
                      setIsFilter(true);
                    }
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                aria-label="add-visitor"
                component={NavLink}
                to="/visitors/add-visitor"
              >
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Add Visitor" pl={0} />
              </MenuItem>
              <MenuItem onClick={scanVisitorModalOpen}>
                <ListItemIcon>
                  <SettingsOverscanIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Scan Visitor ID" pl={0} />
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <CSVLink
                  data={tableData}
                  headers={exportCSVheaders}
                  onClick={handleClose}
                  filename={`Visitors-activity-${format(
                    new Date(),
                    "yyyy-MM-dd"
                  )}`}
                  style={{ textDecoration: "none", color: "#374151" }}
                >
                  <ListItemText size="large" primary="Export CSV" pl={0} />
                </CSVLink>
              </MenuItem>
              <MenuItem onClick={() => checkedInOrOutFilter()}>
                <ListItemIcon>
                  <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary={
                    checkedInOrOut === false
                      ? "Show Checked In Only"
                      : "Clear Filter"
                  }
                  pl={0}
                />
              </MenuItem>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setVisitorListing(data));
                      }}
                      listing={VisitorListingCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setVisitorListing(defalutTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
          {open && (
            <FindRecord
              show={open}
              handleModal={(show) => visitorModal(show)}
              showAlertTost={(type, msg) => showAlertModal(type, msg)}
            />
          )}
        </div>
      </Toolbar>
    </React.Fragment>
  );
}

export default EnhancedTableToolbar;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import ProcessSurveyRespondent from "./inner-component/ProcessSurveyRespondent";
import UnmaskNames from "./inner-component/UnmaskNames";
import { CovidSurveyListing } from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  Pagination,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import "moment-timezone";
import {
  FilterList as FilterListIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  SettingsOverscan as SettingsOverscanIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import Toaster from "../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { getSnackClose } from "../../redux/slices/mainSlice";
import { setCovid19SurveyList } from "../../redux/slices/logs/logTableColSlice";
import Covid19SurveyListCol from "../table-columns-json/logs/Covid19SurveyList";
import StyledColumnOptions from "../../helper/StyledColumnOptions";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Image = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 12px auto 0;
`;
//Classes Styling
const useStyles = makeStyles((theme) => ({
  decimalList: {
    listStyle: "decimal",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "decimal",
    },
  },
}));
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "465px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "180px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  reporteddatetime,
  temp,
  fname,
  lname,
  symptoms,
  tested,
  quarantined,
  exposed,
  travel,
  action
) {
  return {
    reporteddatetime,
    temp,
    fname,
    lname,
    symptoms,
    tested,
    quarantined,
    exposed,
    travel,
    action,
  };
}
const rows = [
  createData(
    "07/31/2020 04:59",
    "100.5",
    "*****",
    "*****",
    "Y",
    "N",
    "N",
    "N",
    "Y",
    0
  ),
  createData(
    "07/31/2020 04:59",
    "100.5",
    "******",
    "******",
    "Y",
    "N",
    "N",
    "N",
    "Y",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "reporteddatetime",
    numeric: true,
    disablePadding: true,
    label: "Reported Datetime",
  },
  {
    id: "temp",
    numeric: true,
    disablePadding: false,
    label: "Temp",
  },
  {
    id: "fname",
    numeric: true,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lname",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "symptoms",
    numeric: true,
    disablePadding: false,
    label: "Symptoms",
  },
  {
    id: "tested",
    numeric: true,
    disablePadding: false,
    label: "Tested",
  },
  {
    id: "quarantined",
    numeric: true,
    disablePadding: false,
    label: "Quarantined",
  },
  {
    id: "exposed",
    numeric: true,
    disablePadding: false,
    label: "Exposed",
  },
  {
    id: "travel",
    numeric: true,
    disablePadding: false,
    label: "Travel",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell> */}
        {Covid19SurveyListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            // padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {/* {headCell.icon} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    filterRecord,
    dataToFilter,
    setDataToFilter,
    setCurrentPage,
    tableCalRedux,
    getCovidSurveyList,
  } = props;
  const [actions, setActions] = React.useState("");
  const [searchNfcId, setSearchNfcId] = useState("");
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [searchf_name, setSearchf_name] = useState("");
  const [searchl_name, setSearchl_name] = useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const passcode = useSelector((state) => state.main.unmasked);
  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.covid19SurveyList
  );

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [dateRange, setDateRange] = React.useState(null);
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  // Process Survey Respondent Dialog
  const [processSurveyRespondent, setProcessSurveyRespondent] = useState(false);
  const processSurveyRespondentOpen = () => {
    setProcessSurveyRespondent(true);
  };
  const processSurveyRespondentClose = () => {
    setProcessSurveyRespondent(false);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    setSearchNfcId("");
    setSearchf_name("");
    setSearchl_name("");
    filterRecord({});
  };

  //Method for search filter
  const applySearch = () => {
    const payload = {
      // startTemp: startDateToFilter,
      // endTemp: endDateToFilter,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      first_name: searchf_name,
      last_name: searchl_name,
      nfc_id: searchNfcId,
      // user_id: officer,
      // activity_type: activityValue,
      // sentiment_icon: sentimentValue,
      // comments: remarkValue,
      // location: locationValue,
      // search_input: searchInput,
    };
    // console.log("Payload console+++++++++++++++", payload);
    filterRecord(payload);
  };
  return (
    <>
      <Toolbar>
        {/* <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value={"Delete"}>Delete</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div> */}
        {/* <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {numSelected} records found
          </Typography>
        </div> */}
        <div>
          {totalRecord > 0 ? (
            <Typography
              color="inherit"
              variant="subtitle1"
              mt={3}
              style={{ width: "max-content" }}
            >
              {totalRecord} records found
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              mt={3}
              id="tableTitle"
              style={{ width: "max-content" }}
            >
              {totalRecord} record found
            </Typography>
          )}
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                type="text"
                autoComplete="off"
                placeholder="Search by NFC ID, or click down arrow"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                    setIsFilter(true);
                    // handleFirstPage();
                    setCurrentPage(1);
                  }
                }}
                inputProps={{}}
                value={searchNfcId}
                onChange={(e) => {
                  setSearchNfcId(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="dateRange"
                                label="Date range"
                                autoComplete="off"
                                value={
                                  startDateToFilter && endDateToFilter
                                    ? moment(startDateToFilter).format(
                                        "MM/DD/YYYY"
                                      ) +
                                      " - " +
                                      moment(endDateToFilter).format(
                                        "MM/DD/YYYY"
                                      )
                                    : ""
                                }
                                onClick={() => setFadedBox((prev) => !prev)}
                                // onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Select date range"
                              />
                              {fadebox && (
                                <CustomDateRange
                                  handleShow={fadebox}
                                  conformation={(status) =>
                                    setFadedBox((prev) => !prev)
                                  }
                                  setDates={(start, end) => {
                                    setStartDateToFilter(start);
                                    setEndDateToFilter(end);
                                  }}
                                  selectedStart={startDateToFilter}
                                  selectedEnd={endDateToFilter}
                                />
                              )}
                            </FormControl>
                            <FormControl>
                              <TextField
                                fullWidth
                                id="nfc_id"
                                label="NFC ID"
                                value={searchNfcId}
                                onChange={(e) => {
                                  setSearchNfcId(e.target.value);
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Click here then scan or enter manually"
                              />
                            </FormControl>
                            {passcode && (
                              <>
                                <FormControl>
                                  <TextField
                                    fullWidth
                                    id="first_name"
                                    label="Firstname"
                                    value={searchf_name}
                                    onChange={(e) => {
                                      setSearchf_name(e.target.value);
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    // placeholder="Click here then scan or enter manually"
                                  />
                                </FormControl>
                                <FormControl>
                                  <TextField
                                    fullWidth
                                    id="last_name"
                                    label="Lastname"
                                    value={searchl_name}
                                    onChange={(e) => {
                                      setSearchl_name(e.target.value);
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    // placeholder="Click here then scan or enter manually"
                                  />
                                </FormControl>
                              </>
                            )}
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={6}
                                sx={{ marginLeft: "5px" }}
                                onClick={(e) => {
                                  applySearch({});
                                  setIsFilter(true);
                                  handleCloseForm();
                                  setCurrentPage(1);
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                    setSearchNfcId("");
                  }}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                >
                  Clear
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={(e) => {
                    if (searchNfcId !== "") {
                      applySearch();
                      setIsFilter(true);
                      // handleFirstPage();
                      setCurrentPage(1);
                    }
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={processSurveyRespondentOpen}>
                <ListItemIcon>
                  <SettingsOverscanIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Scan Respondent" pl={0} />
              </MenuItem>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setCovid19SurveyList(data));
                      }}
                      listing={Covid19SurveyListCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setCovid19SurveyList(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      {processSurveyRespondent && (
        <ProcessSurveyRespondent
          processSurveyRespondent={processSurveyRespondent}
          setProcessSurveyRespondent={setProcessSurveyRespondent}
          processSurveyRespondentOpen={processSurveyRespondentOpen}
          processSurveyRespondentClose={processSurveyRespondentClose}
          getCovidSurveyList={getCovidSurveyList}
        />
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);
  //States related to listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [covidSurveyList, setCovidSurveyList] = useState([]);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [colorCount, setColorCount] = useState(0);
  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const passcode = useSelector((state) => state.main.unmasked);
  const dispatch = useDispatch();

  const tableCalRedux = useSelector(
    (state) => state.logTableCol.covid19SurveyList
  );

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  // const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.fname);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, fname) => {
    const selectedIndex = selected.indexOf(fname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, fname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (fname) => selected.indexOf(fname) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id) => {
    setRecordId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  // Unmask names dialog
  const [unmaskNamesDialog, setUnmaskNamesDialog] = useState(false);
  const unmaskNamesDialogOpen = () => {
    setUnmaskNamesDialog(true);
  };
  const unmaskNamesDialogClose = () => {
    setUnmaskNamesDialog(false);
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  //Method for calling the listing API
  const getCovidSurveyList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      //  sorting: {
      //    order: order,
      //    by: orderBy,
      //  },
      ...dataToFilter,
    }; //this will be used for sending filtered data
    CovidSurveyListing(currentPage, payload)
      .then((res) => {
        console.log(res.data);
        if (res.statusCode == 200) {
          // setCovidSurveyList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          // Assuming res.data is an array of survey objects
          const updatedSurveyList = res.data.map((survey) => {
            let colorCount = 0;
            const symptoms = [
              survey.cough,
              survey.breath_difficulty,
              survey.fever,
              survey.chills,
              survey.repeated_chills,
              survey.muscle_pain,
              survey.headache,
              survey.sore_throat,
              survey.taste_smell_loss,
            ];

            // Count the number of symptoms present in the survey
            for (const symptom of symptoms) {
              if (symptom === 1) {
                colorCount++;
              }
            }

            return { ...survey, colorCount };
          });
          console.log("updatedSurveyList", updatedSurveyList);
          setCovidSurveyList(updatedSurveyList);
        } else {
          setCovidSurveyList(res.data);
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setCovidSurveyList([]);
        console.log(err);
        setTotalRecord(0);
      });
  };

  useEffect(() => {
    getCovidSurveyList();
  }, [rowsPerPage, currentPage, dataToFilter]);

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };
  const convertGMTToLocal = (gmtDate) => {
    const gmtTime = moment.tz(gmtDate, "GMT");
    return gmtTime.clone().tz(moment.tz.guess()).format("YYYY-MM-DD HH:mm");
  };

  return (
    <>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          filterRecord={(data) => filterRecord(data)}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          setCurrentPage={setCurrentPage}
          tableCalRedux={tableCalRedux}
          getCovidSurveyList={getCovidSurveyList}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(covidSurveyList, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.fname);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.fname)}
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        // selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.reporteddatetime === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {/* {row.report_date} */}
                          {convertGMTToLocal(row.report_date)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.temp === true ? "" : "hide-cell"
                          }
                        >
                          {row.temp}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.fname === true ? "" : "hide-cell"
                          }
                          onClick={unmaskNamesDialogOpen}
                        >
                          <TextField
                            type={passcode ? "text" : "password"}
                            disabled
                            value={row.first_name}
                            sx={{
                              "& fieldset": { border: "none" },
                              height: "auto",
                              "& . MuiInputBase-input": {
                                padding: 0,
                                opacity: "unset",
                              },
                              "& .Mui-disabled": {
                                color: "inherit",
                                WebkitTextFillColor: "inherit",
                                padding: 0,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.lname === true ? "" : "hide-cell"
                          }
                          onClick={unmaskNamesDialogOpen}
                        >
                          <TextField
                            type={passcode ? "text" : "password"}
                            disabled
                            value={row.last_name}
                            sx={{
                              "& fieldset": { border: "none" },
                              height: "auto",
                              "& . MuiInputBase-input": {
                                padding: 0,
                                opacity: "unset",
                              },
                              "& .Mui-disabled": {
                                color: "inherit",
                                WebkitTextFillColor: "inherit",
                                padding: 0,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            color:
                              row.colorCount === 1
                                ? "orange"
                                : row.colorCount >= 2
                                ? "red"
                                : "green",
                          }}
                          className={
                            tableCalRedux.symptoms === true ? "" : "hide-cell"
                          }
                        >
                          {row.colorCount > 0 ? "Y" : "N"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            color:
                              row.waiting_test_result === 1 ||
                              row.tested_positive === 1
                                ? "red"
                                : "green",
                          }}
                          className={
                            tableCalRedux.tested === true ? "" : "hide-cell"
                          }
                        >
                          {row.waiting_test_result === 1 ||
                          row.tested_positive === 1
                            ? "Y"
                            : "N"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            color: row.self_quarantined === 1 ? "red" : "green",
                          }}
                          className={
                            tableCalRedux.quarantined === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.self_quarantined === 1 ? "Y" : "N"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            color:
                              row.exposed_other === 1 ||
                              row.exposed_positive_tested === 1 ||
                              row.exposed_cdc === 1
                                ? "red"
                                : "green",
                          }}
                          className={
                            tableCalRedux.exposed === true ? "" : "hide-cell"
                          }
                        >
                          {row.exposed_other === 1 ||
                          row.exposed_positive_tested === 1 ||
                          row.exposed_cdc === 1
                            ? "Y"
                            : "N"}
                          {/* {row.exposed} */}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            color:
                              row.travelled_outside === 1 ? "red" : "green",
                          }}
                          className={
                            tableCalRedux.travel === true ? "" : "hide-cell"
                          }
                        >
                          {row.travelled_outside === 1 ? "Y" : "N"}
                          {/* {row.travel} */}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleActionClick(e, row.id);
                            }}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              aria-label="view-covid19-survey"
                              component={NavLink}
                              to={`/logs/view-covid19-survey/${recordId}`}
                            >
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText primary="View" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {rows.length === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * rows.length }}>
                    <TableCell colSpan={11} align="center">
                      <Image src={`/static/img/covid-survey.png`} />
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        sx={{ my: "15px" }}
                      >
                        Collect Covid-19 survey data for people visiting your
                        business.
                      </Typography>
                      <List className={classes.decimalList}>
                        <ListItem>
                          <ListItemText primary="Obtain an ID code to identify your property" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Employees and visitors go to a special webpage on their phone to complete their COVID-19 prescreening survey no more than 2 hours prior to their arrival" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Notifications are sent automatically by email or SMS if survey questions are answered indicating the person visiting may have symptoms consistent with Covid-19" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Employee and visitor identity is only known by persons entering a special access password" />
                        </ListItem>
                      </List>
                    </TableCell>
                  </TableRow>
                )}
                {totalRecord === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                    <TableCell colSpan={10} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {unmaskNamesDialog && !passcode && (
        <UnmaskNames
          unmaskNamesDialog={unmaskNamesDialog}
          setUnmaskNamesDialog={setUnmaskNamesDialog}
          unmaskNamesDialogOpen={unmaskNamesDialogOpen}
          unmaskNamesDialogClose={unmaskNamesDialogClose}
        />
      )}
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
    </>
  );
}

function Covid19Survey() {
  return (
    <React.Fragment>
      <Helmet title="Covid-19 Survey" />
      <Typography variant="h3" gutterBottom display="inline">
        Covid-19 Survey
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Covid19Survey;

import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { GetAddKeyRingList, DeletekeyRing, AddNewKeyRing } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import EditKeyRing from "./EditKeyRing";
import { useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  TextField,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import WithPermissionHide from "../../../utils/withPermissionHide";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const AddKeyRing = ({ addKeyRing, addKeyRingClose, childToParent }) => {
  // Action Dropdown
  const [addKeyRingAction, setAddKeyRingAction] = React.useState(null);
  // Datepicker
  const [expDate, setExpDate] = useState(null);
  const [keyRingList, setKeyRingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [ringId, setRingId] = useState(null);
  const [close, setClose] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [singleData, setSingleData] = useState({});
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  // Check for permission for the specific action
  const hasPermissionToAdd = permissions.some(
    (perm) =>
      perm.controller === "Equipment" &&
      perm.action_name === "addNewKeyRing" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  /**
   * It sets the state of the add key ring action button, the ring id, and the single data
   * @param event - The event that triggered the click.
   * @param id - The id of the keyring
   * @param data - This is the data that is passed to the modal.
   */
  const addKeyRingActionClick = (event, id, data) => {
    setAddKeyRingAction(event.currentTarget);
    setRingId(id);
    setSingleData(data);
  };
  /**
   * It closes the add keyring action.
   */
  const addKeyRingActionClose = () => {
    setAddKeyRingAction(null);
  };

  /**
   * It closes the dialog box.
   * @param e - The event object
   */
  const closeDialogHandle = (e) => {
    setClose(e.target.value);
  };

  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  /* The above code is creating a state variable called editKeyRing and setting it to false. It is also
  creating a function called editKeyRingOpen that sets the editKeyRing state variable to true. */
  const [editKeyRing, setEditKeyRing] = useState(false);
  const editKeyRingOpen = () => {
    setEditKeyRing(true);
  };

  /**
   * The function editKeyRingClose() is a function that sets the state of editKeyRing to false
   */
  const editKeyRingClose = () => {
    setEditKeyRing(false);
  };

  /**
   * This function is used to get the list of keyrings from the backend
   */
  const addKeyRingList = () => {
    setLoading(true);
    GetAddKeyRingList()
      .then((res) => {
        if (res.statusCode === 200) {
          setKeyRingList(res.data);
          childToParent(res.data, "key_ring");
          setLoading(false);
        } else {
          setKeyRingList([]);
          childToParent(res.data, "key_ring");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  /**
   * This function is used to delete the key ring
   */
  const deleteKeyRing = () => {
    DeletekeyRing(ringId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          addKeyRingList();
          handleDeleteClose();
        } else {
          dispatch(getSnackClose());
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setErrMsg(error);
        }
        dispatch(getSnackClose());
      });
  };

  /* Calling the addKeyRingList function when the component mounts. */
  useEffect(() => {
    addKeyRingList();
  }, []);

  /* The above code is using Yup to validate the form. */
  const validationSchema = Yup.object().shape({
    ring_id: Yup.string()
      .required("Please enter ring id")
      .matches(/^[a-z0-9]+$/i, "Ring scan ID is alphanumeric only ")
      .min(2, "Ring scan ID has minimum length of 2 characters"),
    ring_code: Yup.string().required("Please enter ring code"),
    ring_name: Yup.string().required("Please enter ring name"),
    ring_group: Yup.string().required("Please enter ring group"),
    expiry_date: Yup.date().typeError("Please enter a expiry date").required(),
  });

  /* Creating a variable called initialValues and assigning it an object with the properties ring_id,
 ring_code, ring_name, ring_group, and expiry_date. */
  const initialValues = {
    ring_id: "",
    ring_code: "",
    ring_name: "",
    ring_group: "",
    expiry_date: null,
  };

  /**
   * It takes in the values from the form, and then it sends a request to the API to add a new key ring
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        ring_scan_id: values.ring_id,
        ring_code: values.ring_code,
        ring_name: values.ring_name,
        ring_group: values.ring_group,
        ring_expiry: values.expiry_date,
      };
      AddNewKeyRing(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key ring has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            if (close === "close") {
              addKeyRingClose();
            }
            addKeyRingList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        open={addKeyRing}
        onClocse={addKeyRingClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="add-key-ring-title"
        aria-describedby="add-key-ring-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-ring-title"
        >
          <Typography variant="h4">Add Key Ring</Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={addKeyRingClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-key-ring-description">
            Key rings are the physical set of keys that you will scan out to
            your users. Key rings can have one or more keys assigned to them.
            Add a key ring by entering a barcode ID in the ring ID field below.
            Ring name and Ring group according to your key management system. If
            you do not use these categories, enter any value in these fields.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={8}>
                      <CustomTextField
                        name="ring_id"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Ring ID*"
                        autoComplete="off"
                        placeholder="Barcode ID"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={8}>
                      <CustomTextField
                        name="ring_code"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Ring code*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="ring_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Ring name*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="ring_group"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Ring group*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <DatePicker
                        id="exp-date"
                        label="Expiry date*"
                        name="expiry_date"
                        value={values.expiry_date}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.expiry_date && errors.expiry_date
                        )}
                        helperText={touched.expiry_date && errors.expiry_date}
                        onChange={(newValue) => {
                          setFieldValue("expiry_date", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Click to select",
                            }}
                          />
                        )}
                      />
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.expiry_date && errors.expiry_date}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      disabled={!hasPermissionToAdd}
                      color="primary"
                      variant="outlined"
                      type="submit"
                    >
                      Save & add more
                    </Button>
                    <Button
                      disabled={!hasPermissionToAdd}
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                      value={"close"}
                      onClick={closeDialogHandle}
                    >
                      Save & done
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Paper sx={{ marginTop: "30px" }}>
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete  key ring?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  Cancel
                </Button>
                <Button onClick={deleteKeyRing} variant="contained" autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <WithPermissionHide
              controller="Equipment"
              action="getAddKeyRingList"
            >
              <Typography variant="h4" component="div" gutterBottom>
                Existing Key Rings
              </Typography>
              <TableContainer className="tableContainer">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Group</TableCell>
                      <TableCell>Ring ID</TableCell>
                      <TableCell>Expiry Date</TableCell>
                      <TableCell>Security Level</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableCell colSpan={13} align="center">
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <TableBody>
                      {keyRingList.map((data) => {
                        return (
                          <TableRow key={data.id}>
                            <TableCell>{data.ring_name}</TableCell>
                            <TableCell>{data.ring_group}</TableCell>
                            <TableCell>{data.ring_scan_id}</TableCell>
                            <TableCell>{data.ring_expiry}</TableCell>
                            <TableCell>{data.ring_securitylvl}</TableCell>
                            <TableCell padding="none">
                              <IconButton
                                aria-label="Settings"
                                size="large"
                                aria-owns={
                                  addKeyRingAction
                                    ? "add-key-ring-action"
                                    : undefined
                                }
                                aria-haspopup="true"
                                onClick={(e) =>
                                  addKeyRingActionClick(e, data.id, data)
                                }
                                style={{ color: "#1374C5" }}
                              >
                                <SettingsIcon />
                              </IconButton>
                              <StyledMenu
                                id="add-key-ring-action"
                                anchorEl={addKeyRingAction}
                                open={Boolean(addKeyRingAction)}
                                onClose={addKeyRingActionClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <WithPermissionHide
                                  controller="Equipment"
                                  action="updateKeyRing"
                                >
                                  <MenuItem
                                    onClick={() => {
                                      addKeyRingActionClose();
                                      editKeyRingOpen();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" pl={1} />
                                  </MenuItem>
                                </WithPermissionHide>
                                <WithPermissionHide
                                  controller="Equipment"
                                  action="deleteKeyRing"
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleDeleteOpen();
                                      addKeyRingActionClose();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Delete" pl={1} />
                                  </MenuItem>
                                </WithPermissionHide>
                              </StyledMenu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {keyRingList.length === 0 && (
                        <TableRow>
                          <TableCell align="center" colSpan={10}>
                            No records found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </WithPermissionHide>
            {editKeyRing && (
              <EditKeyRing
                editKeyRing={editKeyRing}
                setEditKeyRing={setEditKeyRing}
                editKeyRingOpen={editKeyRingOpen}
                editKeyRingClose={editKeyRingClose}
                singleData={singleData}
                ringId={ringId}
                addKeyRingList={addKeyRingList}
              />
            )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={addKeyRingClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddKeyRing;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl as MuiFormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { SaveQuestion, FindModule } from "../../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";

const AddQuestionDialog = ({
  addQuestionDialog,
  setAddQuestionDialog,
  addQuestionDialogOpen,
  addQuestionDialogClose,
  updatedRow,
  questionList,
}) => {
  const dispatch = useDispatch();
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const [age, setAge] = React.useState("");
  const [formData, setFormData] = useState(updatedRow);
  const [moduleList, setModuleList] = useState([]);
  const [errors, setErrors] = useState({});
  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  console.log("formData", formData);
  const saveQuestion = (event) => {
    event.preventDefault();

    // Define the validation schema
    const validationSchema = Yup.object().shape({
      question: Yup.string().required("Question is required"),
      step: Yup.number().required("Please enter a numeric value"),
    });

    // Create the payload object
    let payload = {
      id: formData.id ? formData.id : null,
      answer: formData.answer,
      language: formData.language,
      step: formData.step,
      module_type: formData.module_type,
      question: formData.question,
    };

    // Validate the payload using the schema
    validationSchema
      .validate(payload, { abortEarly: false })
      .then(() => {
        // Validation passed, proceed with saving
        console.log("payload", payload);
        SaveQuestion(payload)
          .then((res) => {
            // Handle the response
            if (res.statusCode == 200) {
              addQuestionDialogClose();
              questionList();
              if (formData?.id) {
                dispatch(getMsg("Data updated successfully"));
              } else {
                dispatch(getMsg("Data add successfully"));
              }
              dispatch(getSnackAlert("success"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
            } else {
              dispatch(getMsg("Somthing went wrong please try again!"));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
            }
          })
          .catch((err) => {
            // Handle any saving errors
          });
      })
      .catch((validationErrors) => {
        // Handle validation errors
        console.log("Validation errors:", validationErrors);
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setErrors(errors);
      });
  };

  const findModule = () => {
    FindModule()
      .then((res) => {
        if (res.statusCode == 200) {
          setModuleList(res.data);
        } else {
          setModuleList([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    findModule();
  }, []);
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={addQuestionDialog}
        onClose={addQuestionDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {Object.keys(formData).length ? "Edit Question" : "Add Question"}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setAddQuestionDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <form onSubmit={saveQuestion}>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} mt={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Answer</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="answer"
                    value={formData?.answer ? formData.answer : ""}
                    label="Answer"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenu-list": {
                            boxShadow:
                              "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                            margin: "0px 3px 5px 3px",
                            padding: "0",
                          },
                        },
                      },
                    }}
                    onChange={handleChange}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} mt={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Select Language
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="language"
                    value={formData?.language ? formData.language : ""}
                    label="Select Language"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenu-list": {
                            boxShadow:
                              "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                            margin: "0px 3px 5px 3px",
                            padding: "0",
                          },
                        },
                      },
                    }}
                    onChange={handleChange}
                  >
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="Spenish">Spenish</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} mt={4}>
                <TextField
                  id="outlined"
                  label="Step"
                  name="step"
                  value={formData?.step ? formData.step : ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
                <FormHelperText error>{errors.step}</FormHelperText>
              </Grid>
              <Grid item lg={12} md={12} mt={2}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Select Module
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Module"
                    name="module_type"
                    value={formData.module_type ? formData.module_type : ""}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenu-list": {
                            boxShadow:
                              "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                            margin: "0px 3px 5px 3px",
                            padding: "0",
                          },
                        },
                      },
                    }}
                    onChange={handleChange}
                  >
                    {moduleList.map((item) => {
                      return (
                        <MenuItem value={item.value}> {item.key}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} mt={2} xl={12}>
                <TextField
                  id="outlined-multiline"
                  name="question"
                  value={formData?.question ? formData.question : ""}
                  label="Description"
                  placeholder="Comment...."
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={4}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                />
                <FormHelperText error>{errors.question}</FormHelperText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: "20px 24px" }}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddQuestionDialog;

import { useEffect, useState } from "react";
import Ably from "ably";

function AblyReceiver() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    console.log("AblyReceiver component mounted");

    const ably = new Ably.Realtime({
      key: "FiSX9g.xIo36w:LldqceEFINznqGLI5RnDcfq3isTKQYJoEX8SXbz-Qyg",
    });

    const channel = ably.channels.get("test-channel");

    console.log("Subscribed to channel:", channel.name);

    channel.subscribe("greeting", (msg) => {
      console.log("Received message:", msg.data);
      setMessage(msg.data);
    });

    return () => {
      console.log("AblyReceiver component unmounting");
      channel.unsubscribe();
      ably.close();
    };
  }, []);

  return (
    <div>
      <h1>Received Message: {message}</h1>
    </div>
  );
}

export default AblyReceiver;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DateTimePicker } from "@mui/lab";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import {
  ClassificationList,
  ViewPoiEncounter,
  UpdateEncounter,
} from "../../api";
import PoiEncountorLocation from "./inner-component/PoiEncountorLocation";
import AddIcon from "@mui/icons-material/Add";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Grid,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Card,
  CardContent,
  FormLabel,
  FormControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
  Box,
  Avatar,
  TextField,
  Paper,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  FormatBold as FormatBoldIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  LocalPrintshop as LocalPrintshopIcon,
  FullscreenExit as FullscreenExitIcon,
  Code as CodeIcon,
  Restore as RestoreIcon,
} from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { spacing } from "@mui/system";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import CameraFeeding from "../../helper/camera-feeding";
import EncounterClassification from "./inner-component/EncounterClassification";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

// const Avatar = styled(MuiAvatar)`
//   width: 90px;
//   height: 90px;
//   object-fit: cover;
// `;
// const BigAvatar = styled(Avatar)`
//   width: 180px;
//   height: 180px;
//   object-fit: cover;
//   margin: 30px auto 0;
// `;
const teethSelect = [
  { title: "None selected" },
  { title: "The Godfather" },
  { title: "The Godfather: Part II" },
  { title: "The Dark Knight" },
  { title: "12 Angry Men" },
  { title: "Schindler's List" },
  { title: "Pulp Fiction" },
];
const otherFacial = [
  { title: "None selected" },
  { title: "The Godfather" },
  { title: "The Godfather: Part II" },
  { title: "The Dark Knight" },
  { title: "12 Angry Men" },
  { title: "Schindler's List" },
  { title: "Pulp Fiction" },
];

function AddPersonOfInterestForm() {
  const [datevalue, setDateValue] = React.useState(null);
  const [narrativeData, setNarrativeData] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showWebCam, setShowWebCam] = React.useState(false);
  const uploadedFileRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  let navigate = useNavigate();
  // DatePicker
  const [type, setType] = useState([]);
  const [locationDialog, setLocationDialog] = useState(false);
  const locationDialogOpen = () => {
    setLocationDialog(true);
  };
  const locationDialogClose = () => {
    setLocationDialog(false);
  };

  const [classificationDialog, setClassificationDialog] = useState(false);
  const classificationDialogOpen = () => {
    setClassificationDialog(true);
  };
  const classificationDialogClose = () => {
    setClassificationDialog(false);
  };
  //const classes = useStyles();
  const [narrativebtn, setNarrativeBtn] = React.useState("undobtn");
  const handleNarrativeBtn = (event, newNarrativeBtn) => {
    setNarrativeBtn(newNarrativeBtn);
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };
  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };
  // const ir = [{ key: "The Shawshank Redemption", value: 1994 }];
  const [ir, setIr] = useState([]);
  const behavioralFactors = [
    { key: "Agitated", value: "Agitated" },
    { key: "Intoxicated", value: "Intoxicated" },
    { key: "Slurred speech", value: "Slurred speech" },
    { key: "Sleepy", value: "Sleepy" },
    { key: "Eyes darting", value: "Eyes darting" },
    { key: "Can't sit still", value: "Can't sit still" },
    { key: "Can't focus", value: "Can't focus" },
    { key: "Responsive", value: "Responsive" },
    { key: "Unresponsive", value: "Unresponsive" },
    { key: "Eyes closed", value: "Eyes closed" },
    { key: "Other", value: "Other" },
  ];
  const locationEncounter = [
    { key: "Outdoors", value: "Outdoors" },
    { key: "Indoors", value: "Indoors" },
    { key: "Stairwell", value: "Stairwell" },
    { key: "Rooptop", value: "Rooptop" },
    { key: "Landscaping", value: "Landscaping" },
    { key: "Other", value: "Other" },
  ];
  const alertLevel = [
    { key: "Lucid", value: "Lucid" },
    { key: "Not lucid", value: "Not lucid" },
    { key: "Other", value: "Other" },
  ];

  const resolutionData = [
    {
      key: "Left property without escort",
      value: "Left property without escort",
    },
    { key: "Left property with escort", value: "Left property with escort" },
    { key: "Taken by police", value: "Taken by police" },
    {
      key: "Citation in lieu of arrest",
      value: "Citation in lieu of arrest",
    },
    {
      key: "Documented, no action taken",
      value: "Documented, no action taken",
    },
    {
      key: "Taken by ambulance",
      value: "Taken by ambulance",
    },
    {
      key: "Other",
      value: "Other",
    },
  ];

  const tressPassType = [
    { key: "Written", value: "Written" },
    { key: "Verbal", value: "Verbal" },
  ];

  const childToParent = (value) => {
    setEncounterClassification(value);
  };

  const [encounterClassification, setEncounterClassification] = useState([]);
  const classificationList = () => {
    ClassificationList()
      .then((res) => {
        if (res.statusCode === 200) {
          setEncounterClassification(res.data);
        } else {
          setEncounterClassification([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const [coordinates, setCoordinates] = useState({});

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const getCoordinates = (value) => {
    setCoordinates(value);
  };
  const [encounterData, setEncounterData] = useState({});
  const viewEncounter = () => {
    ViewPoiEncounter(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setEncounterData(res.data);
          setNarrativeData(res.data.narrative);
          setCoordinates({
            lat: parseFloat(res.data.lat),
            lng: parseFloat(res.data.long),
          });
          setUploadedImage(res.data.image);
          let bfa = res.data.behavioral_factors.split(",");
          let data = [];
          behavioralFactors.forEach((obj) => {
            bfa.forEach((res) => {
              if (res == obj.key) {
                data.push(obj);
              }
            });
          });
          setType(data);
        } else {
          setEncounterData({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const {
    first_name,
    last_name,
    alias,
    property,
    poi_location,
    map_location,
    alert_level,
    behavioral_factors,
    police_contacted,
    trespass_issued,
    trespass,
    trespass_type,
    resolution,
    encounter_classification_id,
    narrative,
    image,
    encounter_date,
    ru,
    police_officer_name,
    other_resolution,
    alleged_crime_committed,
    crime_committed,
    police_report_number,
    poi_detained,
    recent_encounter,
    police_officer_badge_id,
  } = encounterData;
  useEffect(() => {
    classificationList();
    viewEncounter();
    setLoading(true);
  }, []);

  let initialValues = {
    most_recent_encounter: recent_encounter ? recent_encounter : "",
    first_name: first_name ? first_name : "",
    last_name: last_name ? last_name : "",
    alias: alias ? alias : "",
    date_time_encounter: encounter_date ? new Date(encounter_date) : null,
    property: property ? property : "",
    alert_level: alert_level ? alert_level : "",
    other_alert_level: "",
    location_encounter: poi_location ? poi_location : "",
    map_location: map_location == 1 ? true : false,
    other_poi_location: "",
    other_behavioral_factor: "",
    on_site: police_contacted ? police_contacted : "",
    officer_name: police_officer_name ? police_officer_name : "",
    officer_badge_id: police_officer_badge_id ? police_officer_badge_id : "",
    alleged_crime_committed: alleged_crime_committed
      ? alleged_crime_committed
      : "",
    crime_committed: crime_committed ? crime_committed : "",
    police_report_number: police_report_number ? police_report_number : "",
    poi_detained: poi_detained ? poi_detained : "",
    tres_pass: trespass_issued ? trespass_issued : "",
    tress_pass1: trespass ? trespass : "",
    tres_pass_type: trespass_type ? trespass_type : "",
    resolution: resolution ? resolution : "",
    other_resolution: other_resolution ? other_resolution : "",
    encounter_classicfication: encounter_classification_id
      ? encounter_classification_id
      : "",
  };

  const validationSchema = Yup.object().shape({
    // company_name: Yup.string().required("Please enter company name"),
    date_time_encounter: Yup.string()
      .nullable()
      .required("Please select the encounter date"),
    location_encounter: Yup.string().required("Please select location"),
    map_location: Yup.boolean().oneOf([true], "Checkbox selection is required"),
    on_site: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),

    officer_name: Yup.string().when(["on_site"], (same, schema) => {
      return same == "Yes"
        ? schema.required("Please enter officer name")
        : schema;
    }),

    officer_badge_id: Yup.string().when(["on_site"], (same, schema) => {
      return same == "Yes"
        ? schema.required("Please enter officer badge Id")
        : schema;
    }),

    poi_detained: Yup.string().when(["on_site"], (same, schema) => {
      return same == "Yes"
        ? schema
            .required("Please select one of these options")
            .oneOf(["Yes", "No"])
        : schema;
    }),

    alleged_crime_committed: Yup.string().when(["on_site"], (same, schema) => {
      return same == "Yes"
        ? schema
            .required("Please select one of these options")
            .oneOf(["Yes", "No"])
        : schema;
    }),

    crime_committed: Yup.string().when(
      ["alleged_crime_committed"],
      (same, schema) => {
        return same == "Yes"
          ? schema.required("Please enter crime committed")
          : schema;
      }
    ),

    police_report_number: Yup.string().when(
      ["alleged_crime_committed"],
      (same, schema) => {
        return same == "Yes"
          ? schema.required("Please enter report number")
          : schema;
      }
    ),

    resolution: Yup.string().required("Please select resolution"),
    other_resolution: Yup.string().when(["resolution"], (same, schema) => {
      return same == "Other"
        ? schema.required("Please enter other resolution")
        : schema;
    }),
  });

  function isUrl(str) {
    const pattern =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return pattern.test(str);
  }
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        lat: coordinates?.lat,
        long: coordinates?.lng,
        encounter_date:
          values.date_time_encounter != null
            ? format(values.date_time_encounter, "yyyy-MM-dd HH:mm:ss")
            : format(new Date(encounter_date), "yyyy-MM-dd HH:mm:ss"),
        poi_location: values.location_encounter,
        other_poi_location: values.other_poi_location,
        map_location: values.map_location ? 1 : 0,
        alert_level: values.alert_level,
        image: isUrl(uploadedImage) ? null : uploadedImage,
        other_alert_level: values.other_alert_level,
        behavioral_factors: type.map((d) => d.value).toString(),
        other_behavioral_factors: values.other_behavioral_factor,
        police_contacted: values.on_site,
        police_officer_badge_id: values.officer_badge_id,
        police_officer_name: values.officer_name,
        alleged_crime_committed: values.alleged_crime_committed,
        crime_committed: values.crime_committed,
        police_report_number: values.police_report_number,
        poi_detained: values.poi_detained,
        trespass_issued: values.tres_pass,
        trespass: values.tress_pass1,
        trespass_type: values.tres_pass_type,
        resolution: values.resolution,
        other_resolution: values.other_resolution,
        encounter_classification_id: values.encounter_classicfication,
        narrative: narrativeData,
      };
      UpdateEncounter(id, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record submitted successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
            navigate({
              pathname: "/poi/poi-encounters",
            });
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="most_recent_encounter"
                        label="Most Recent Encounter"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="none"
                        inputProps={{ readOnly: true }}
                        sx={{ cursor: "not-allowed" }}
                      />
                    </FormControl>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="first_name"
                        label="First name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                        inputProps={{ readOnly: true }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="last_name"
                        label="Last name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                        inputProps={{ readOnly: true }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="alias"
                        label="Alias"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                        inputProps={{ readOnly: true }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <DateTimePicker
                        id="date-time-encounter"
                        label="Datetime of this encounter"
                        name="date_time_encounter"
                        value={values.date_time_encounter}
                        onChange={(newValue) => {
                          setFieldValue("date_time_encounter", newValue);
                        }}
                        ampm={false}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="date_time_encounter"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="property"
                        label="Property or SNG*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                        inputProps={{ readOnly: true }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="location_encounter"
                        fieldLabel="Location"
                        options={locationEncounter}
                        displayEmpty
                      />
                    </FormControl>
                    <FormHelperText error>
                      {touched.location_encounter && errors.location_encounter}
                    </FormHelperText>
                    {values.location_encounter == "Other" && (
                      <FormControl mt={6}>
                        <CustomTextField
                          name="other_poi_location"
                          label="Other POI Location"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    )}
                    <FormControl mt={6}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="map_location"
                              checked={values.map_location}
                              onChange={(e) => {
                                setFieldValue("map_location", e.target.checked);
                                if (e.target.checked) {
                                  locationDialogOpen();
                                }
                              }}
                            />
                          }
                          label="Add POI encounter location on a map"
                        />
                        <Button
                          sx={{ marginLeft: "5px" }}
                          color="primary"
                          variant="contained"
                          onClick={locationDialogOpen}
                        >
                          View map
                        </Button>
                      </FormGroup>
                      <FormHelperText error>
                        {touched.map_location && errors.map_location}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="alert_level"
                        fieldLabel="Alert level"
                        options={alertLevel}
                        displayEmpty
                      />
                    </FormControl>
                    {values.alert_level == "Other" && (
                      <FormControl mt={6}>
                        <CustomTextField
                          name="other_alert_level"
                          label="Other Alert Level"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    )}
                    <FormControl mt={6}>
                      <Autocomplete
                        multiple
                        id="treatment-rendered"
                        fullWidth
                        options={behavioralFactors}
                        value={type}
                        onChange={(e, v) => {
                          setType(v);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.key}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.key}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Behavioral Factors"
                            variant="outlined"
                            placeholder="Behavioral Factors"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {type.map((d) => d.value) == "Other" && (
                      <FormControl mt={6}>
                        <CustomTextField
                          name="other_behavioral_factor"
                          label="Other Behavioral Factor*"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    )}
                    <FormControl mt={6}>
                      <FormLabel>Police On-Site</FormLabel>
                      <RadioGroup
                        aria-labelledby="police-on-site"
                        name="on_site"
                        row
                        value={values.on_site}
                        onChange={(e) =>
                          setFieldValue("on_site", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormHelperText error>
                      {touched.on_site && errors.on_site}
                    </FormHelperText>
                    {values.on_site == "Yes" && (
                      <Box>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="officer_name"
                            label="Officer Name*"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormControl>

                        <FormControl mt={6}>
                          <CustomTextField
                            name="officer_badge_id"
                            label="Officer Badge ID*"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel>Alleged Crime Committed*</FormLabel>
                          <RadioGroup
                            aria-labelledby="police-on-site"
                            name="alleged_crime_committed"
                            row
                            value={values.alleged_crime_committed}
                            onChange={(e) =>
                              setFieldValue(
                                "alleged_crime_committed",
                                e.target.value
                              )
                            }
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormHelperText error>
                          {touched.alleged_crime_committed &&
                            errors.alleged_crime_committed}
                        </FormHelperText>
                        {values.alleged_crime_committed == "Yes" && (
                          <Box>
                            <FormControl mt={6}>
                              <CustomTextField
                                name="crime_committed"
                                label="Crime Committed*"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>

                            <FormControl mt={6}>
                              <CustomTextField
                                name="police_report_number"
                                label="Police Report Number*"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                          </Box>
                        )}
                        <FormControl mt={6}>
                          <FormLabel>POI Detained*</FormLabel>
                          <RadioGroup
                            aria-labelledby="poi_detained"
                            name="poi_detained"
                            row
                            value={values.poi_detained}
                            onChange={(e) =>
                              setFieldValue("poi_detained", e.target.value)
                            }
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormHelperText error>
                          {touched.poi_detained && errors.poi_detained}
                        </FormHelperText>
                      </Box>
                    )}
                    <FormControl mt={6}>
                      <FormLabel>Trespass warning issued</FormLabel>
                      <RadioGroup
                        aria-labelledby="trespass"
                        name="tres_pass"
                        row
                        value={values.tres_pass}
                        onChange={(e) =>
                          setFieldValue("tres_pass", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                    </FormControl>

                    {values.tres_pass == "Yes" && (
                      <Box>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="tress_pass1"
                            label="Trespass"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormControl>

                        <FormControl mt={6}>
                          <CustomSelect
                            name="tres_pass_type"
                            fieldLabel="Trespass Type"
                            options={tressPassType}
                            displayEmpty
                          />
                        </FormControl>
                      </Box>
                    )}
                    <FormControl mt={6}>
                      <CustomSelect
                        name="resolution"
                        fieldLabel="Resolution"
                        options={resolutionData}
                        displayEmpty
                      />
                    </FormControl>
                    <FormHelperText error>
                      {touched.resolution && errors.resolution}
                    </FormHelperText>
                    {values.resolution == "Other" && (
                      <FormControl mt={6}>
                        <CustomTextField
                          name="other_resolution"
                          label="Other Resolution*"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    )}
                    <FormControl mt={6}>
                      <CustomSelect
                        name="encounter_classicfication"
                        fieldLabel="Encounter Classification"
                        options={encounterClassification}
                        displayEmpty
                      />
                      <AddIcon onClick={classificationDialogOpen} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl>
                      {uploadedImage ? (
                        <BigAvatar alt="image name" src={uploadedImage} />
                      ) : (
                        <BigAvatar
                          alt="image name"
                          src="/static/img/avatars/profile-upload.png"
                        />
                      )}

                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          JustifyContent: "space-between",
                          margin: "10px 0 15px",
                          gap: "15px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                          onClick={(e) => clearSelectedImage(e)}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="outlined"
                          color="warning"
                          component="span"
                          onClick={() => handleShowWebCam()}
                        >
                          {showWebCam ? "Close WebCam" : "WebCam"}
                        </Button>
                      </Box>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        ref={uploadedFileRef}
                        onChange={(e) => handleFileChange(e)}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Choose image
                        </Button>
                      </label>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormControl>
                      <FormLabel>Narrative</FormLabel>
                      <QuillWrapper>
                        <ReactQuill
                          theme="snow"
                          value={narrativeData}
                          onChange={setNarrativeData}
                          placeholder="Type something.."
                        />
                      </QuillWrapper>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      mt={6}
                      type="submit"
                    >
                      Save Encounter
                    </Button>
                    <Typography>
                      Management may receive alerts when this encounter is saved
                      if POI threat level is 5
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {locationDialog && (
        <PoiEncountorLocation
          locationDialog={locationDialog}
          setLocationDialog={setLocationDialog}
          locationDialogOpen={locationDialogOpen}
          locationDialogClose={locationDialogClose}
          getCoordinates={getCoordinates}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
      )}
      {classificationDialog && (
        <EncounterClassification
          classificationDialog={classificationDialog}
          // setLocationDialog={setLocationDialog}
          classificationDialogOpen={classificationDialogOpen}
          classificationDialogClose={classificationDialogClose}
          childToParent={childToParent}
        />
      )}
      {showWebCam && (
        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
    </>
  );
}
function EditPoiEncounter() {
  return (
    <React.Fragment>
      <Helmet title="Edit POI Encounter" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit POI Encounter
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/poi/poi-list">
          POI List
        </Link>
        <Link component={NavLink} to="/poi/poi-encounters">
          POI Encounters
        </Link>
        <Typography>Edit POI Encounter</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddPersonOfInterestForm />
    </React.Fragment>
  );
}
export default EditPoiEncounter;

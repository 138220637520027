import React, { useState, useEffect } from "react";
import moment from "moment";
import { LostfoundSubItems, LostfoundUnmerge } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import DialogBox from "../../../components/form-components/DialogBox";
import CustomAlert from "../../../components/form-components/CustomAlert";
import SubListingAction from "../../../pages/lostandfound/inner-component/SubListingAction";

import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  IconButton,
  DialogContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const SubItmes = ({ showSubItemModal, handleModal, id }) => {
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(true); //State for loader
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const removeRecords = () => {
    setShowDeleteModal(true);
  };
  const handelDeleteAction = (status) => {
    if (status === true) {
      LostfoundUnmerge(selectedId)
        .then((res) => {
          if (res.statusCode === 200) {
            setShowDeleteModal(false);
            setAlertMessage(res.message);
            setAlertType("success");
            setShowAlert(true);
            LostfoundSubItemsList();
          } else {
            setShowDeleteModal(false);
            setAlertMessage(res.message);
            setAlertType("error");
            setShowAlert(true);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setShowDeleteModal(false);
            setAlertMessage(error);
            setAlertType("error");
            setShowAlert(true);
          }
        });
    } else {
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    LostfoundSubItemsList();
  }, []);

  const LostfoundSubItemsList = () => {
    LostfoundSubItems(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setItemList(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog
      open={showSubItemModal}
      aria-labelledby="subitems-title"
      aria-describedby="subitems-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1200px",
          },
        },
      }}
    >
      <DialogTitle
        id="subitems-dialog-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Sub Items
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => handleModal(false)}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 0" }}>
        <DialogBox
          handleShow={showDeleteModal}
          message={"Are you sure you want to unmerge?"}
          conformation={(status) => handelDeleteAction(status)}
        />
        {showAlert && (
          <CustomAlert
            message={alertMessage}
            severity={alertType}
            closeAlert={(show) => setShowAlert(show)}
            showAlert={showAlert}
          />
        )}
        <TableContainer className="tableContainer">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Unique ID</TableCell>
                <TableCell>Lost Datetime</TableCell>
                <TableCell>Found Datetime</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Found By</TableCell>
                <TableCell>Claim By</TableCell>
                <TableCell>Found Location</TableCell>
                <TableCell>Storage Location</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={10} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {itemList.map((row, index) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>
                        {row.lost_date
                          ? moment(row.lost_date).format("YYYY-MM-DD HH:mm:ss")
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {row.found_date
                          ? moment(row.found_date).format("YYYY-MM-DD HH:mm:ss")
                          : "-"}
                      </TableCell>
                      <TableCell>{row.category ? row.category : "-"}</TableCell>
                      <TableCell>
                        {row.description ? row.description : "-"}
                      </TableCell>
                      <TableCell>{row.found_by ? row.found_by : "-"}</TableCell>
                      <TableCell>
                        {row.claim_name ? row.claim_name : "-"}
                      </TableCell>
                      <TableCell>
                        {row.found_location ? row.found_location : "-"}
                      </TableCell>
                      <TableCell>
                        {row.storage_location ? row.storage_location : "-"}
                      </TableCell>
                      <TableCell align="left">
                        <SubListingAction
                          data={row}
                          setRecordId={(value) => setSelectedId(value)}
                          removeRecords={() => removeRecords()}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {itemList.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          onClick={() => handleModal(false)}
          color="primary"
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubItmes;

import React, { useState } from "react";
import { PropertyStatus, SaveInlineData } from "../../../api"; // Import the SaveInlineData function

import {
  IconButton,
  ListItemText,
  TableCell,
  TableRow,
  FormGroup,
  ListItemIcon,
  MenuItem,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

function Tablerow({
  list,
  isItemSelected,
  labelId,
  handleClick,
  deleteId,
  handleActionClick,
  handleActionClose,
  anchorEl,
  StyledMenu,
  handleDeleteOpen,
  checked,
  showAlertModal,
}) {
  const [toggle, setToggle] = useState(checked);
  const checkedValue = useSelector((state) => state.property.checked);
  const [propertyName, setPropertyName] = useState(list.property_name);
  const [mobileId, setMobileId] = useState(list.mobile_id);
  //handle for changing the status
  const statusHandleClick = (status, id) => {
    let data = status === true ? "I" : "A";
    let payload = {
      status: data,
    };
    PropertyStatus(id, payload)
      .then((response) => {
        if (response.statusCode == 200) {
          showAlertModal("success", response.message);
        } else {
          showAlertModal("error", response.message);
        }
      })
      .catch((err) => {
        showAlertModal("error", "Somthing wrong please try again");
      });
  };

  const saveEditedPropertyName = (id) => {
    if (propertyName !== list.property_name) {
      SaveInlineData(id, {
        property_name: propertyName ? propertyName : list.property_name,
      })
        .then((res) => {
          if (res.statusCode === 200) {
            showAlertModal("success", "Property name updated successfully");
          } else {
            showAlertModal("error", "Something went wrong, please try again!");
          }
        })
        .catch((err) => {
          showAlertModal("error", "Something went wrong, please try again!");
        });
    }
  };

  // Handle inline editing and saving for mobile_id
  const saveEditedMobileId = (id) => {
    if (mobileId !== list.mobile_id) {
      SaveInlineData(id, {
        mobile_id: mobileId ? mobileId : list.mobile_id,
      })
        .then((res) => {
          if (res.statusCode === 200) {
            showAlertModal("success", "Mobile ID updated successfully");
          } else {
            showAlertModal("error", "Something went wrong, please try again!");
          }
        })
        .catch((err) => {
          showAlertModal("error", "Something went wrong, please try again!");
        });
    }
  };
  const handlePropertyNameChange = (e) => {
    setPropertyName(e.target.innerText);
  };

  // Handle contentEditable change for Mobile ID
  const handleMobileIdChange = (e) => {
    setMobileId(e.target.innerText);
  };

  return (
    <>
      <TableRow
        hover
        aria-checked={isItemSelected}
        selected={isItemSelected}
        tabIndex={-1}
        key={list.location_id}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, list.location_id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
          />
        </TableCell>
        {checkedValue.includes("property_name") && (
          <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              border: "none",
              outline: "none",
              borderBottom: "1px solid rgba(224,224,224,1)",
            }}
            contentEditable
            suppressContentEditableWarning={true}
            onInput={handlePropertyNameChange}
            onBlur={() => saveEditedPropertyName(list.location_id)}
          >
            {propertyName}
          </TableCell>
        )}
        {checkedValue.includes("corporate") && (
          <TableCell align="left">{list.corporate}</TableCell>
        )}
        {checkedValue.includes("mobile_id") && (
          <TableCell
            align="left"
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              border: "none",
              outline: "none",
              borderBottom: "1px solid rgba(224,224,224,1)",
            }}
            contentEditable
            suppressContentEditableWarning={true}
            onInput={handleMobileIdChange}
            onBlur={() => saveEditedMobileId(list.location_id)}
          >
            {mobileId}
          </TableCell>
        )}
        {checkedValue.includes("address") && (
          <TableCell align="left" sx={{ lg: 'whiteSpace: "nowrap"' }}>
            {list.address}
          </TableCell>
        )}
        {checkedValue.includes("status") && (
          <TableCell align="left">
            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                label=""
                checked={toggle}
                onClick={() => {
                  setToggle(!toggle);
                  statusHandleClick(toggle, list.location_id);
                }}
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("dyn") && (
          <TableCell align="left">{list.dyn}</TableCell>
        )}
        {checkedValue.includes("phone") && (
          <TableCell align="left">{list.phone}</TableCell>
        )}
        {checkedValue.includes("fax") && (
          <TableCell align="left">{list.fax}</TableCell>
        )}
        {checkedValue.includes("mobile_activation_id") && (
          <TableCell align="left">{list.mobile_activation_id}</TableCell>
        )}
        {checkedValue.includes("public_lost_id_found") && (
          <TableCell align="left">{list.public_lost_id_found}</TableCell>
        )}
        {checkedValue.includes("lostfound_account_id") && (
          <TableCell align="left">{list.lostfound_account_id}</TableCell>
        )}
        {checkedValue.includes("time_zone") && (
          <TableCell align="left">{list.time_zone}</TableCell>
        )}
        {checkedValue.includes("admin_name") && (
          <TableCell align="left">{list.admin_name}</TableCell>
        )}
        {checkedValue.includes("admin_email") && (
          <TableCell align="left">{list.admin_email}</TableCell>
        )}
        {checkedValue.includes("street") && (
          <TableCell align="left">{list.street}</TableCell>
        )}
        {checkedValue.includes("city") && (
          <TableCell align="left">{list.city}</TableCell>
        )}
        {checkedValue.includes("state") && (
          <TableCell align="left">{list.state}</TableCell>
        )}
        {checkedValue.includes("country") && (
          <TableCell align="left">{list.country}</TableCell>
        )}
        {checkedValue.includes("zip") && (
          <TableCell align="left">{list.zip}</TableCell>
        )}
        {checkedValue.includes("currency") && (
          <TableCell align="left">{list.currency}</TableCell>
        )}
        {checkedValue.includes("map_direction") && (
          <TableCell align="left">{list.map_direction}</TableCell>
        )}
        {checkedValue.includes("shipment_handling_fee") && (
          <TableCell align="left">{list.shipment_handling_fee}</TableCell>
        )}
        {checkedValue.includes("locker_combo_unmask_pin") && (
          <TableCell align="left">{list.locker_combo_unmask_pin}</TableCell>
        )}
        {checkedValue.includes("shift1_time_notif") && (
          <TableCell align="left">{list.shift1_time_notif}</TableCell>
        )}
        {checkedValue.includes("Shift1Start") && (
          <TableCell align="left">{list.Shift1Start}</TableCell>
        )}
        {checkedValue.includes("Shift1End") && (
          <TableCell align="left">{list.Shift1End}</TableCell>
        )}
        {checkedValue.includes("shift2_time_notif") && (
          <TableCell align="left">{list.shift2_time_notif}</TableCell>
        )}
        {checkedValue.includes("Shift2Start") && (
          <TableCell align="left">{list.Shift2Start}</TableCell>
        )}
        {checkedValue.includes("Shift2End") && (
          <TableCell align="left">{list.Shift2End}</TableCell>
        )}
        {checkedValue.includes("shift3_time_notif") && (
          <TableCell align="left">{list.shift3_time_notif}</TableCell>
        )}
        {checkedValue.includes("Shift3Start") && (
          <TableCell align="left">{list.Shift3Start}</TableCell>
        )}
        {checkedValue.includes("Shift3End") && (
          <TableCell align="left">{list.Shift3End}</TableCell>
        )}
        <TableCell align="left">
          <IconButton
            aria-label="Settings"
            size="large"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            id={`${list.location_id}`}
            onClick={(e) => handleActionClick(e, list.location_id)}
            style={{ color: "#1374C5" }}
            value={list.location_id}
          >
            <SettingsIcon />
          </IconButton>
          <StyledMenu
            id={`icon-menu-${list.location_id}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="edit-property"
              component={NavLink}
              to={`/accounts/edit-property/${deleteId}`}
            >
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" pl={1} />
            </MenuItem>
            <MenuItem
              aria-label="floor-plans"
              component={NavLink}
              to={`/accounts/floor-plans/${deleteId}`}
            >
              <ListItemIcon>
                <SwapVertIcon />
              </ListItemIcon>

              <ListItemText primary="Floors" pl={1} />
            </MenuItem>
            <MenuItem onClick={(handleActionClose, handleDeleteOpen)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" pl={1} />
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Tablerow;

import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import { GetStatusAudit, UpdateStatusReason } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Button,
  Box,
  IconButton,
  FormControlLabel,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card,
  TextField,
  Paper,
  FormHelperText,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionHide from "../../../utils/withPermissionHide";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const ChangeSubmitStatus = ({
  changeSubmitStatus,
  changeSubmitStatusClose,
  singleData,
}) => {
  const [auditList, setAuditList] = useState([]);
  const [status, setStatus] = useState("");
  const [value, setValue] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [singleStatus, setSingleStatus] = useState({});
  const [showReason, setShowReason] = useState(false);
  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToUpdateStatusReason = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "updateStatusReason" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  // console.log(singleData);
  // console.log(status);
  const dispatch = useDispatch();
  const getStatusAudit = () => {
    GetStatusAudit(singleData.IncidentID)
      .then((res) => {
        if (res.statusCode === 200) {
          setAuditList(res.data);
        } else {
          setAuditList([]);
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const updateStatusReason = () => {
    let payload = {
      IncidentID: singleData.IncidentID,
      status: status,
      IncidentStatus: singleData.status,
      check_ir_UOF_status: 0,
      rejectionReason: value ? value : null,
    };
    if ((value != "" && status == "rejected") || status == "accepted") {
      UpdateStatusReason(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            changeSubmitStatusClose();
            window.location.reload();
            dispatch(
              status == "accepted"
                ? getSnackMsg("IR now accepted")
                : getSnackMsg("IR Status Updated")
            );

            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
          }
        })
        .catch((err) => {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        });
    } else {
      setErrMsg(
        status == ""
          ? "Please select radio"
          : "Please provide the IR author a reason for returning the IR for edits"
      );
    }
  };

  useEffect(() => {
    getStatusAudit();
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="change-submit-status-title"
        aria-describedby="change-submit-status-description"
        open={changeSubmitStatus}
        // onClose={changeSubmitStatusClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="change-submit-status-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Change Submit Status
          </Typography>
          <DialogActions>
            <IconButton
              onClick={changeSubmitStatusClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <>
            <Box>
              <Typography variant="p" component="div" gutterBottom>
                <Typography variant="strong" component="span" gutterBottom>
                  Incident Class:
                </Typography>
                <Typography variant="body2" component="span" gutterBottom>
                  {singleData.Classification}
                </Typography>
              </Typography>
              <Typography variant="p" component="div" gutterBottom>
                <Typography variant="strong" component="span" gutterBottom>
                  Incident Datetime:
                </Typography>
                <Typography variant="body2" component="span" gutterBottom>
                  {singleData.Datetime}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <FormControl>
                <RadioGroup
                  aria-labelledby="change-status"
                  name="change_status"
                  defaultValue="Change status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Accept"
                    value="accepted"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Return"
                    value="rejected"
                  />
                </RadioGroup>
              </FormControl>
              {status == "rejected" && (
                <FormControl mt={4}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Reason for Return"
                    multiline
                    value={value}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              )}
            </Box>
            <FormHelperText error>{errMsg}</FormHelperText>
            <DialogActions sx={{ padding: "20px 0" }}>
              <Button
                color="primary"
                variant="outlined"
                onClick={changeSubmitStatusClose}
              >
                Cancel
              </Button>
              <Button
                disabled={!hasPermissionToUpdateStatusReason}
                color="primary"
                variant="contained"
                sx={{ marginLeft: "10px" }}
                onClick={updateStatusReason}
              >
                Update
              </Button>
            </DialogActions>
          </>
          <WithPermissionHide controller="Reports" action="getStatusAudit">
            <Paper mt={6}>
              <Typography variant="h6" component="div" gutterBottom>
                Submit status timeline
              </Typography>
              {auditList.length > 0 && (
                <Card
                  variant="outlined"
                  sx={{ margin: "15px 0", borderBottom: 0 }}
                >
                  <TableContainer className="tableContainer">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Username</TableCell>
                          <TableCell>Datetime</TableCell>
                          <TableCell>Status Prior</TableCell>
                          <TableCell>Status After</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {auditList.map((data) => {
                          return (
                            <TableRow>
                              <TableCell>{data.username}</TableCell>
                              <TableCell>{data.created}</TableCell>
                              <TableCell>{data.status_prior}</TableCell>
                              <TableCell>{data.status}</TableCell>
                              <TableCell>
                                {data.status == "rejected" && (
                                  <IconButton
                                    onClick={() => {
                                      setSingleStatus(data);
                                      setShowReason(true);
                                    }}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              )}
              {showReason && (
                <Typography variant="h6" component="div" gutterBottom>
                  Rejection reason: {singleStatus?.rejectionReason}
                </Typography>
              )}
            </Paper>
          </WithPermissionHide>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangeSubmitStatus;

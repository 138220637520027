import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { MoreVert } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { changeLocation } from "../../redux/slices/loginSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  GetHeaderLocation,
  UserMailList,
  NotifyTemplateList,
  SendEmergencyAlert,
  GetCommUser,
} from "../../api";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddIcon from "@mui/icons-material/Add";

import {
  Button,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  TextareaAutosize as MuiTextareaAutosize,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Tooltip,
  Typography,
  Box,
  Autocomplete,
  Icon,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
// Classes Styling
const useStyles = makeStyles((theme) => ({
  QuickLinksMenu: {
    "& .MuiPaper-root": {
      border: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "#c4c4c4" : theme.palette.grey[700],
    },
    "& .MuiList-root": {
      padding: 0,
      maxHeight: "500px",
    },
    "& .MuiPaper-root::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },
    "& .MuiPaper-root::-webkit-scrollbar-track": {
      background: "#bed2f5",
    },
    "& .MuiPaper-root::-webkit-scrollbar-thumb": {
      background: "#92a6c9",
    },
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LocationHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [locationsList, setLocationsList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    localStorage.getItem("location")
      ? JSON.parse(localStorage.getItem("location"))
      : {}
  );
  const [selectedLocationId, setSelectedLocationId] = useState(
    selectedLocation.LocationID ? selectedLocation.LocationID : ""
  );
  const [selectedLocationName, setSelectedLocationName] = useState(
    selectedLocation.LocationName ? selectedLocation.LocationName : ""
  );
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  // states
  const [openeDialog, setOpeneDialog] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [recipientsDialog, setRecipientsDialog] = React.useState(false);
  const [sendAutomatedCalls, setSendAutomatedCalls] = useState(true);
  const [useConferenceCall, setUseConfernceCall] = useState(false);
  const [errors, setErrors] = useState({ recipients: "", message: "" });

  const handleMenuItemClick = (event, index, opt) => {
    setSelectedIndex(index);
    setSelectedLocationId(opt.LocationID);
    setSelectedLocationName(opt.LocationName);
    dispatch(changeLocation(opt));
    setAnchorEl(null);

    const currentUrl = window.location.href;
    const isTargetPath = currentUrl.includes("logs/edit-shift-report");
    if (isTargetPath) {
      navigate("/logs/shift-report");
    }
    window.location.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  const getLocationsList = () => {
    GetHeaderLocation()
      .then((res) => {
        if (res.statusCode === 200) {
          setLocationsList(res.data);
        } else {
          setLocationsList([]);
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        console.log(errors);
      });
  };

  // handle user emails list
  const getUsersEmail = () => {
    const payload = {
      type: "emergency_alert",
    };
    GetCommUser(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setUsers(res.data);
          setSelectedUsers(res.data);
        } else {
          setUsers([]);
          setSelectedUsers([]);
        }
      })
      .catch((err) => {
        setUsers([]);
        console.log(err);
      });
  };

  const handleSelectionChange = (event, newValue) => {
    setSelectedUsers(newValue);
  };
  const isSelectedAll = Object.values(selectedUsers).every(Boolean);

  const handleSelectAllToggle = () => {
    setSelectAll(!selectAll);
    setSelectedUsers(!selectAll ? users.map((user) => user.email) : []);
  };

  //handle template
  const handleTemplateChange = (event) => {
    const selectedId = event.target.value;
    const foundTemplate = templates.find(
      (template) => template.id === selectedId
    );
    if (foundTemplate) {
      setSelectedTemplate(selectedId);
      setTemplateDescription(foundTemplate.notification_text);
    }
  };

  const handleCheckboxChange = (event) => {
    setSendAutomatedCalls(event.target.checked);
  };

  const handelConferenceCallCheckbox = (event) => {
    setUseConfernceCall(event.target.checked);
  };

  // emergency dialog
  const handleClickOpen = () => {
    setOpeneDialog(true);
  };

  const handleCloseDialog = () => {
    setOpeneDialog(false);
  };

  //handle recipients model
  const handleClickResDialog = () => {
    setRecipientsDialog(true);
  };

  const handleClosereciDialog = () => {
    setRecipientsDialog(false);
  };

  //send emergency notification alert
  const handleSubmit = () => {
    // Validation before API call
    if (
      !templateDescription.trim() ||
      Object.values(selectedUsers).every((val) => !val)
    ) {
      setErrors((prev) => ({
        ...prev,
        message: !templateDescription.trim() ? "A message is required." : "",
        recipients: Object.values(selectedUsers).every((val) => !val)
          ? "At least one recipient is required."
          : "",
      }));
      return; // Stop the function if validation fails
    }
    try {
      const payload = {
        templateId: selectedTemplate,
        msg: templateDescription,
        commIds: selectedUsers.map((user) => user.id),
        isCall: sendAutomatedCalls ? 1 : 0,
        conf_call: useConferenceCall ? 1 : 0,
      };

      SendEmergencyAlert(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            const { sms, email, call, callData } = res.data;

            // Create a message string with the data values
            const dataMessage = `SMS: ${sms}, Email: ${email}, Call: ${call}, Call Data: ${
              callData ? "Yes" : "No"
            }`;

            // Include dataMessage in the toast notification
            dispatch(
              getMsg(
                `Emergency alert notification sent successfully. Details - ${dataMessage}`
              )
            );

            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setOpeneDialog(false);
            setTemplateDescription("");
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setOpeneDialog(false);
            setTemplateDescription("");
          }
        })
        .catch((err) => {
          console.log(err);
          setOpeneDialog(false);
          dispatch(getSnackClose());
        });
    } catch (error) {
      console.log(error);
      setOpeneDialog(false);
      setTemplateDescription("");
    }
  };

  useEffect(() => {
    NotifyTemplateList().then((res) => {
      if (res.statusCode === 200) setTemplates(res.data);
    });
    getUsersEmail();
    getLocationsList();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          onClick={handleClickOpen}
          sx={{
            width: "32px",
            height: "34px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#376fd0 !important",
            borderRadius: "3px",
            marginRight: "10px",
          }}
        >
          <IconButton aria-label="add" sx={{ color: "white" }}>
            <AddIcon />
          </IconButton>
        </Box>

        {/* emergency model */}
        <Dialog
          open={openeDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ textAlign: "center", color: "red" }}
          >
            {"EMERGENCY NOTIFICATION SYSTEM"}
          </DialogTitle>
          <Button
            onClick={handleClickResDialog}
            color="warning"
            variant="outlined"
            sx={{
              width: "40%",
              borderRadius: "4px",
              display: "block",
              margin: "auto",
            }}
          >
            Recipients (
            {Object.values(selectedUsers).filter((val) => val).length})
          </Button>
          <Dialog
            open={recipientsDialog}
            onClose={handleClosereciDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ textAlign: "center", color: "red" }}
            >
              {"Select recipients for notification"}
            </DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={Object.values(selectedUsers).every((val) => val)}
                    checked={isSelectedAll}
                    onChange={(e) => handleSelectAllToggle(e.target.checked)}
                  />
                }
                label="Select all"
              />
              <Typography sx={{ paddingTop: "10px" }}>
                ({Object.values(selectedUsers).filter((val) => val).length})
                selected
              </Typography>
            </Box>
            <FormControl
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              <Autocomplete
                multiple
                options={users}
                value={selectedUsers}
                disableCloseOnSelect
                onChange={handleSelectionChange}
                getOptionLabel={(option) => option.email}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.email}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!errors.recipients}
                    helperText={errors.recipients}
                    name="receipients"
                    label="Receipients (select one or more)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </FormControl>
            <DialogActions sx={{ py: "10px" }}>
              <Button
                onClick={() => {
                  handleClosereciDialog();
                  setTemplateDescription("");
                }}
                color="warning"
                variant="outlined"
                sx={{ mx: "20px", borderRadius: "4px", mt: "10px" }}
              >
                Cancel
              </Button>
              <Button
                color="success"
                variant="outlined"
                sx={{ mx: "20px", borderRadius: "4px", mt: "10px" }}
                onClick={handleClosereciDialog}
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
          <FormControl sx={{ mx: "20px", mt: "15px" }}>
            <InputLabel
              sx={{
                background: "#fff",
              }}
            >
              Select template (optional)
            </InputLabel>
            <Select
              labelId="template-select-label"
              value={selectedTemplate}
              label="Select Template"
              onChange={handleTemplateChange}
            >
              {templates.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            sx={{ alignItem: "right", mx: "20px" }}
            control={<Checkbox checked={sendAutomatedCalls} />}
            onChange={handleCheckboxChange}
            label="send automated phone calls"
          />
          <FormControlLabel
            sx={{ alignItem: "right", mx: "20px" }}
            control={<Checkbox checked={useConferenceCall} />}
            onChange={handelConferenceCallCheckbox}
            label="Use Conference Call-In"
          />
          <TextField
            sx={{ mx: "20px" }}
            id="filled-multiline-static"
            required
            error={!!errors.message}
            helperText={errors.message}
            multiline
            rows={4}
            value={templateDescription}
            onChange={(e) => setTemplateDescription(e.target.value)}
            // onChange={handleTemplateDescriptionChange}
            label="Message"
            placeholder="Write emergency message here................"
            variant="filled"
          />
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              color="warning"
              variant="outlined"
              sx={{ mx: "20px", borderRadius: "4px", mt: "10px" }}
            >
              Cancel
            </Button>
            <Button
              color="success"
              variant="outlined"
              sx={{ mx: "20px", borderRadius: "4px", mt: "10px" }}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </DialogActions>
          <Typography sx={{ mx: "20px", my: "15px", textAlign: "justify" }}>
            This Emergency Notification System should not be used as a
            replacement for 2-way radio or other forms of reliable communication
            as it requires a computer network to send messages. Some delay in
            transmission can be expected.
          </Typography>
        </Dialog>

        <Typography
          sx={{
            marginRight: "5px",
            display: { xl: "block", lg: "block", xs: "none" },
          }}
        >
          <strong>{selectedLocationName}</strong>
        </Typography>

        <Tooltip title="Quick Links">
          <IconButton
            color="inherit"
            // ref={quickLinksRef}
            onClick={handleClickListItem}
            size="large"
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        className={classes.QuickLinksMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {locationsList.map((option, index) => (
          <MenuItem
            key={option.LocationID}
            selected={option.LocationID === selectedLocationId}
            onClick={(event) => handleMenuItemClick(event, index, option)}
          >
            {option.LocationName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LocationHeader;

import React, { useState, useEffect } from "react";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@mui/material/TextField";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormLabel,
  FormControl as MuiFormControl,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
function ShiftTimeDailog({ isOpen, onClose, onDataUpdate, apiData }) {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (apiData && apiData.data) {
      const parseTime = (timeString) => {
        if (timeString && timeString.length === 4) {
          const hour = parseInt(timeString.substring(0, 2));
          const minute = parseInt(timeString.substring(2, 4));
          return new Date(0, 0, 0, hour, minute);
        }
        return null;
      };

      const parsedData = {
        ...apiData.data,
        Shift1Start: parseTime(apiData.data.Shift1Start),
        Shift1End: parseTime(apiData.data.Shift1End),
        Shift2Start: parseTime(apiData.data.Shift2Start),
        Shift2End: parseTime(apiData.data.Shift2End),
        Shift3Start: parseTime(apiData.data.Shift3Start),
        Shift3End: parseTime(apiData.data.Shift3End),
      };

      setFormData(parsedData);
    }
  }, [apiData]);

  const handleTimeChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatTime = (date) => {
    const pad = (num) => (num < 10 ? "0" + num : num);
    return pad(date.getHours()) + pad(date.getMinutes());
  };

  const handleSave = () => {
    // Format Date objects into "HHmm" strings for saving
    const updatedData = {
      ...formData,
      Shift1Start: formatTime(formData.Shift1Start),
      Shift1End: formatTime(formData.Shift1End),
      Shift2Start: formatTime(formData.Shift2Start),
      Shift2End: formatTime(formData.Shift2End),
      Shift3Start: formatTime(formData.Shift3Start),
      Shift3End: formatTime(formData.Shift3End),
    };

    console.log(updatedData);
    onDataUpdate(updatedData);

    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>View/Edit Shift times</DialogTitle>
      <DialogContent>
        {formData && (
          <>
            <Grid container spacing={3} sx={{ paddingTop: "25px" }}>
              <Grid item xs={12} md={12}>
                <FormLabel>Shift 1:</FormLabel>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                <FormControl>
                  <TimePicker
                    name="Shift1Start"
                    value={formData.Shift1Start}
                    onChange={(date) => handleTimeChange("Shift1Start", date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                <FormControl>
                  <TimePicker
                    name="Shift1End"
                    value={formData.Shift1End}
                    onChange={(date) => handleTimeChange("Shift1End", date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormLabel>Shift 2:</FormLabel>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                <FormControl>
                  <TimePicker
                    name="Shift2Start"
                    value={formData.Shift2Start}
                    onChange={(date) => handleTimeChange("Shift2Start", date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                <FormControl>
                  <TimePicker
                    name="Shift2End"
                    value={formData.Shift2End}
                    onChange={(date) => handleTimeChange("Shift2End", date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormLabel>Shift 3:</FormLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <TimePicker
                    name="Shift3Start"
                    value={formData.Shift3Start}
                    onChange={(date) => handleTimeChange("Shift3Start", date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <TimePicker
                    name="Shift3End"
                    value={formData.Shift3End}
                    onChange={(date) => handleTimeChange("Shift3End", date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShiftTimeDailog;

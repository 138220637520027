import React, { useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";

import { ListItemText as MuiListItemText, ListItemButton } from "@mui/material";
import { spacing } from "@mui/system";

const MergeGroupColumn = ({ column, tasks }) => {
  const ListItemText = styled(MuiListItemText)(spacing);

  const useStyles = makeStyles((theme) => ({
    alphabets: {
      marginTop: "15px",
      "& .MuiList-root": {
        display: "flex",
        overflowX: "auto",
        background: "#7aa8ff0d",
        padding: "0px",
        "& .MuiListItemButton-root": {
          paddingLeft: "13px",
          paddingRight: "13px",
          textAlign: "center",
        },
      },
      "& .MuiList-root::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "& .MuiList-root::-webkit-scrollbar-track": {
        backgroundColor: "#bed2f561",
      },
      "& .MuiList-root::-webkit-scrollbar-thumb": {
        backgroundColor: "#92a6c96e",
      },
    },
    shipmentRequest: {
      "& .MuiFormControl-root": {
        minWidth: "auto",
        width: "100%",
      },
    },
    filterDataByTabs: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "15px",
      "& .MuiToggleButtonGroup-root": {
        justifyContent: "space-between",
        width: "100%",
        overflowX: "auto",
        "& .MuiButtonBase-root": {
          borderRadius: "0",
          width: "100%",
          whiteSpace: "nowrap",
        },
      },
      "& .MuiList-root::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "& .MuiList-root::-webkit-scrollbar-track": {
        backgroundColor: "#bed2f561",
      },
      "& .MuiList-root::-webkit-scrollbar-thumb": {
        backgroundColor: "#92a6c96e",
      },
    },
    btnDropdown: {
      maxWidth: "65px",
      width: "100%",
    },
    statusButton: {
      width: "20px",
      height: "20px",
      display: "block",
      lineHeight: "20px",
      textAlign: "center",
      borderRadius: "6px",
      color: "#fff",
      fontSize: "12px",
      fontWeight: "bolder",
    },
    cardHeader: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterByRadioControls: {
      position: "absolute",
      top: "100%",
      right: "0px",
      boxShadow: "0px 3px 5px 3px #00000014 !important",
      minWidth: "320px",
      zIndex: "1",
      padding: "15px",
    },
    itemInSingleRow: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
    },
    createMergedGroup: {
      border: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "#c4c4c4" : theme.palette.grey[300],
      padding: 0,
      marginTop: "15px",
    },
    CustomActionsButton: {
      borderColor:
        theme.palette.mode === "light"
          ? "rgb(223 223 223)"
          : theme.palette.grey[300],
      color:
        theme.palette.mode === "light" ? "#7f8797" : theme.palette.grey[300],
      padding: "13px 20px",
    },
  }));

  const classes = useStyles();

  useEffect(() => {}, []);

  return (
    <div>
      <div fontSize="17px" fontWeight={600} color="subtle-text">
        {column.title}
      </div>

      <Droppable droppableId={column.id}>
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {tasks.map((task, index) => (
              <Draggable key={task.id} draggableId={`${task.id}`} index={index}>
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <ListItemButton>
                      <ListItemText primary={task.content} />
                    </ListItemButton>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default MergeGroupColumn;

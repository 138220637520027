import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { CheckOutVisitorFromKioskMode } from "../../api";
import KioskModeSubmitPassword from "./KioskModeSubmitPassword";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Paper,
} from "@mui/material";
const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Keyframes = styled("div")({
  "@keyframes pulsate": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.5,
    },
  },
  animation: "pulsate 1s infinite ease",
});
const Image = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;
function ReturningVisitorList() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const responseParameter = location.state.response.data;
  const signOut = location?.state?.response?.signOut;

  const locationDetail = localStorage.getItem("location");
  const name = JSON.parse(locationDetail);

  const [kioskModeSubmitPasswordModal, setKioskModeSubmitPasswordModal] =
    useState(false);
  const kioskModeSubmitPasswordModalClose = () => {
    setKioskModeSubmitPasswordModal(false);
  };
  const [loading, setLoading] = useState(false); //State for loader

  const signOutVisitor = (id) => {
    setLoading(true);

    let payload = {
      status: "CHECKED-OUT",
    };
    CheckOutVisitorFromKioskMode(id, payload).then((response) => {
      if (response.statusCode == 200) {
        navigate("/visitors/kiosk-sign-out");
      }
    });
  };

  // handle fullscreen
  const launchFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };
  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setKioskModeSubmitPasswordModal(true);
    }
  }

  const updateParentState = () => {
    kioskModeSubmitPasswordModalClose();
  };

  return (
    <>
      <Box
        sx={{
          p: 5,
          my: "auto",
          minHeight: "100vh",
          backgroundColor: "#f7f9fc",
          margin: "-8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={classes.startOverCard}
      >
        <Card sx={{ maxWidth: "1140px", mx: "auto", width: "100%" }}>
          <CardHeader
            title={
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    {name.LocationName}
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    Returning Visitor
                  </Typography>
                </Box>
              </>
            }
          />
          <CardContent>
            <Paper mt={3}>
              <TableContainer
                className="tableContainer"
                // component={Paper}
                sx={{
                  border: "1px solid #f1f1f1",
                  margin: "15px auto auto",
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#bed2f5",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#92a6c9",
                  },
                }}
              >
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Photo</TableCell>
                      <TableCell>Visitor Name</TableCell>
                      <TableCell>Host Name</TableCell>
                      <TableCell>Company</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "5px 16px",
                      },
                    }}
                  >
                    {signOut == 1
                      ? responseParameter.map((item, index) => (
                          <TableRow hover key={index}>
                            <TableCell>
                              <Image src={`/static/img/avatars/avatar-1.jpg`} />
                            </TableCell>
                            <TableCell onClick={() => signOutVisitor(item.id)}>
                              {item.name}
                            </TableCell>
                            <TableCell>{item.visitor_host?.name}</TableCell>
                            <TableCell>
                              {item.visitor_company.CompanyName}
                            </TableCell>
                          </TableRow>
                        ))
                      : responseParameter.map((item, index) => (
                          <TableRow hover key={index}>
                            <TableCell>
                              <Image src={`/static/img/avatars/avatar-1.jpg`} />
                            </TableCell>
                            <TableCell
                              component={NavLink}
                              to={`/visitors/visitor-detail/${item.id}`}
                            >
                              {item.name}
                            </TableCell>
                            <TableCell>{item.visitor_host?.name}</TableCell>
                            <TableCell>
                              {item.visitor_company.CompanyName}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </CardContent>
          <CardActions sx={{ justifyContent: "space-between" }}>
            <Keyframes>
              <Typography
                color="error"
                sx={{ fontSize: "16px !important", fontWeight: 500 }}
              >
                Tap name to select
              </Typography>
            </Keyframes>
            <Button component={NavLink} to="/visitors/kiosk-mode">
              Start over
            </Button>
          </CardActions>
        </Card>
      </Box>
      {kioskModeSubmitPasswordModal && (
        <KioskModeSubmitPassword
          kioskModeSubmitPasswordModal={kioskModeSubmitPasswordModal}
          setKioskModeSubmitPasswordModal={setKioskModeSubmitPasswordModal}
          kioskModeSubmitPasswordModalClose={kioskModeSubmitPasswordModalClose}
          launchFullScreen={launchFullScreen}
          onDataFromChild={updateParentState}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function ReturningVisitors() {
  return (
    <React.Fragment>
      <Helmet title="Returning Visitors" />
      <ReturningVisitorList />
    </React.Fragment>
  );
}
export default ReturningVisitors;

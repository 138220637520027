import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import TaskList from "../task-list/TaskList";
import { IrTaskList } from "../../../api";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  getSnackClose,
  getSnackOpen,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Chip,
  CircularProgress,
  Paper as MuiPaper,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Paper = styled(MuiPaper)(spacing);

const IrTask = ({ irTasks, irTasksClose }) => {
  // Action Dropdown
  const [irTaskList, setIrTaskList] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  // Task List Modal
  const [taskList, setTaskList] = useState(false);
  const taskListOpen = () => {
    setTaskList(true);
  };
  const taskListClose = () => {
    setTaskList(false);
  };

  // single record
  const [singleRecord, setSingleRecord] = useState({});

  const showAlertTost = (msg, type) => {
    dispatch(getSnackMsg(msg));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };

  const irTask = () => {
    IrTaskList()
      .then((res) => {
        if (res.statusCode === 200) {
          setIrTaskList(res.data);
        } else {
          console.log("something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    irTask();
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="ir-task-title"
        aria-describedby="ir-task-description"
        open={irTasks}
        onClose={irTasksClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
              overflowY: "unset",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-task-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Incidents with unfinished tasks
          </Typography>
          <DialogActions>
            <IconButton onClick={irTasksClose} sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="ir-task-description">
            The table below shows a count of pending and standard tasks that
            have been assigned to incident reports. Tasks highlighted in
            <Chip
              color="error"
              label="red"
              sx={{
                color: "#fff",
                margin: "0 5px",
              }}
            />
            are due and should be completed promptly. Click the open button
            below to view these tasks in a kanban panel.
          </DialogContentText>
          <Card variant="outlined" sx={{ borderBottom: 0, marginTop: "15px" }}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>IR Number</TableCell>
                    <TableCell>Classification</TableCell>
                    <TableCell>Task Added By</TableCell>
                    <TableCell>Pending</TableCell>
                    <TableCell>Started</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {irTaskList.map((data) => {
                    let isRed = false;
                    for (let i = 0; i < data.mouduleTask.length; i++) {
                      if (
                        data.mouduleTask[i].due_date != null &&
                        !data.mouduleTask[i].completed_date
                      ) {
                        isRed = true;
                        break;
                      }
                    }
                    return (
                      <TableRow hover>
                        <TableCell>{data.IncidentReportName}</TableCell>
                        <TableCell>
                          {`${data.IncidentTypeName} ${
                            data.IncidentTypeOther ? data.IncidentTypeOther : ""
                          }`}
                        </TableCell>
                        <TableCell>{data.UserName}</TableCell>
                        <TableCell align="center">
                          {isRed ? (
                            <Chip
                              color="error"
                              label={data.not_started}
                              sx={{
                                color: "#fff",
                                margin: "0 5px",
                              }}
                            />
                          ) : (
                            data.not_started
                          )}
                        </TableCell>
                        <TableCell>{data.started}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="View"
                            size="large"
                            aria-haspopup="true"
                            onClick={() => {
                              setTaskList(true);
                              setSingleRecord(data);
                            }}
                            style={{ color: "#1374C5" }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {/* Kanban Panel */}
                          {taskList && (
                            <TaskList
                              taskList={taskList}
                              setTaskList={setTaskList}
                              taskListOpen={taskListOpen}
                              taskListClose={taskListClose}
                              showAlertTost={(msg, type) =>
                                showAlertTost(msg, type)
                              }
                              setLoader={(status) => setLoader(status)}
                              module_id={singleRecord.IncidentID}
                              taskTitle={`${singleRecord.IncidentTypeName} ${singleRecord.Datetime}`}
                              moduleType="IR"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {irTaskList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={irTasksClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {loader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default IrTask;

import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

const CheckboxWrapper = ({
  name,
  label,
  legend,
  returnOnChange,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (evt) => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
    if (returnOnChange) {
      returnOnChange(evt);
    }
  };

  const configCheckbox = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  };

  // const configFormControl = {};
  // if (meta && meta.touched && meta.error) {
  //   configFormControl.error = true;
  // }

  return (
    <FormControl>
      <FormLabel component="legend">{legend}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...configCheckbox} />}
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxWrapper;

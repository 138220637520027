import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Card,
} from "@mui/material";
import { SmsReports } from "../../../api";

const SmsReport = () => {
  const [smsReportList, setSmsReportList] = useState([]);

  useEffect(() => {
    SmsReports()
      .then((res) => {
        if (res.statusCode == 200) {
          setSmsReportList(res.data);
        } else {
          setSmsReportList(res.data);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <Typography variant="h3" gutterBottom>
        SMS Report
      </Typography>
      <Card
        sx={{
          marginTop: "20px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Location Name</TableCell>
              <TableCell align="right">Total messages sent</TableCell>
              <TableCell align="right">Period</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {smsReportList.map((report) => (
              <TableRow key={report.id}>
                <TableCell align="center">{report.location_name}</TableCell>
                <TableCell align="center">
                  {report.total_messages_sent}
                </TableCell>
                <TableCell align="center">{report.period}</TableCell>
              </TableRow>
            ))}
            {smsReportList.length < 1 && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default SmsReport;

export default [
  {
    id: "date",
    numeric: true,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "supervisor",
    numeric: true,
    disablePadding: false,
    label: "Supervisor",
  },
  {
    id: "shift",
    numeric: true,
    disablePadding: false,
    label: "Shift",
  },
  {
    id: "training",
    numeric: true,
    disablePadding: false,
    label: "Training",
  },
  {
    id: "irs",
    numeric: true,
    disablePadding: false,
    label: "IRs",
  },
  {
    id: "groupissues",
    numeric: true,
    disablePadding: false,
    label: "Group Issues",
  },
  {
    id: "ets",
    numeric: true,
    disablePadding: false,
    label: "ETS",
  },
  {
    id: "sd",
    numeric: true,
    disablePadding: false,
    label: "SD",
  },
  {
    id: "efs",
    numeric: true,
    disablePadding: false,
    label: "EFS",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "updatedutc",
    numeric: true,
    disablePadding: false,
    label: "Updated (UTC)",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { VehicleIdExist } from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import AddEditVehicle from "./AddEditVehicle";
const ScanVehicleId = ({
  scanVehicleIdDialog,
  scanVehicleIdDialogClose,
  vehicleList,
}) => {
  const [vehicleId, setVehicleId] = useState(null);
  const [vehicleData, setVehicleData] = useState({});
  const [isFind, setIsFind] = useState(false);
  const dispatch = useDispatch();

  // Add/Edit Vehicle Management
  const [addEditVehicleDialog, setAddEditVehicleDialog] = useState(false);
  const addEditVehicleDialogOpen = () => {
    setAddEditVehicleDialog(true);
  };
  const addEditVehicleDialogClose = () => {
    setAddEditVehicleDialog(false);
    scanVehicleIdDialogClose();
  };

  const handleVehicleIdExist = () => {
    VehicleIdExist({ vehicle_id: vehicleId })
      .then((res) => {
        if (res.statusCode === 200) {
          setVehicleData(res.data);
          setIsFind(true);
        } else {
          setVehicleData({});
        }
      })
      .catch((err) => {
        dispatch(getMsg("Vehicle not found!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        setVehicleData({});
        setIsFind(false);

        console.log(err);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="scan-vehicle-title"
        aria-describedby="scan-vehicle-desc"
        open={scanVehicleIdDialog}
        onClose={scanVehicleIdDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="scan-vehicle-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Find Vehicle
          </Typography>
          <DialogActions>
            <IconButton
              onClick={scanVehicleIdDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              autoFocus
              sx={{ marginTop: "15px" }}
              name="vehicle_id"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label="Vehicle Id"
              autoComplete="off"
              placeholder="Scan barcode or enter ID"
              value={vehicleId}
              onChange={(e) => setVehicleId(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleVehicleIdExist();
                }
              }}
            />

            <Button
              color="primary"
              variant="contained"
              sx={{ marginLeft: "5px", height: "52px" }}
              onClick={handleVehicleIdExist}
            >
              Search
            </Button>
          </Box>
          {isFind && (
            <Box sx={{ marginTop: "15px" }}>
              <Typography>
                <strong>Name: </strong>
                {vehicleData.fname + " " + vehicleData.lname}
              </Typography>
              <Typography>
                <strong>Vehicle License Number: </strong>
                {vehicleData.vehicle_license_number}
              </Typography>
              <Typography>
                <strong>Make:</strong> {vehicleData.vehicle_make}
              </Typography>
              <Typography>
                <strong>Model:</strong> {vehicleData.vehicle_model}
              </Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {isFind && (
            <Button
              color="primary"
              variant="contained"
              sx={{ marginRight: "5px" }}
              onClick={() => {
                addEditVehicleDialogOpen();
              }}
            >
              Edit vehicle detail
            </Button>
          )}

          <Button
            color="primary"
            variant="outlined"
            onClick={scanVehicleIdDialogClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {addEditVehicleDialog && (
        <AddEditVehicle
          addEditVehicleDialog={addEditVehicleDialog}
          setAddEditVehicleDialog={setAddEditVehicleDialog}
          addEditVehicleDialogOpen={addEditVehicleDialogOpen}
          addEditVehicleDialogClose={addEditVehicleDialogClose}
          id={vehicleData?.id}
          vehicleList={vehicleList}
        />
      )}
    </>
  );
};

export default ScanVehicleId;

export const getFileBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export const handleApiResonseErrors = (errors) => {
  if (errors) {
    for (var key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key][0];
      }
    }
  }
};

export const focusOnRequiredField = (error) => {
  let firstError = Object.keys(error)[0];
  if (firstError !== undefined) {
    const node = document.getElementById(firstError);
    if (node) {
      node.focus();
    }
  }
};

export const getMediaId = (mimeType) => {
  if (
    mimeType == "image/jpeg" ||
    mimeType == "image/png" ||
    mimeType == "image/gif" ||
    mimeType == "image/jpg"
  ) {
    return 1;
  } else if (
    mimeType == "video/mp4" ||
    mimeType == "video/x-matroska" ||
    mimeType == '"video/quicktime' ||
    mimeType == "video/x-msvideo" ||
    mimeType == "video/mpeg" ||
    mimeType == '"video/webm' ||
    mimeType == "video/mp2t"
  ) {
    return 2;
  }
  if (mimeType == "audio/mpeg") {
    return 3;
  } else if (mimeType == "application/pdf") {
    return 4;
  } else if (
    mimeType ==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    mimeType == "application/msword"
  ) {
    return 5;
  }
};

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CheckPin, AddEditCombo } from "../../../api";
import { makeStyles } from "@mui/styles";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const AddEditInternalLog = ({
  addEditInternalLogDialog,
  addEditInternalLogDialogClose,
  id,
}) => {
  const [pin, setPin] = useState(null);
  const [isTrue, setIsTrue] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const checkPin = () => {
    setLoading(true);
    let payload = {
      locker_id: id,
      pin: pin,
    };
    CheckPin(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setPin(res.data.lock_combination);
          setIsTrue(true);
        } else {
          dispatch(getSnackOpen());
          dispatch(
            getMsg(
              " Please enter the correct pin number or contact your SOVA admin for assistance"
            )
          );
          dispatch(getSnackAlert("error"));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleCombo = () => {
    setLoading(true);
    let payload = {
      locker_id: id,
      lock_combination: pin,
    };
    AddEditCombo(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          addEditInternalLogDialogClose();
          dispatch(getSnackOpen());
          dispatch(getMsg("Locker updated successfully"));
          dispatch(getSnackAlert("success"));
        } else {
          console.log("err");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      <Dialog
        aria-labelledby="locker-log-title"
        aria-describedby="locker-log-desc"
        open={addEditInternalLogDialog}
        onClose={addEditInternalLogDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="locker-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Locker Log
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addEditInternalLogDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your supervisor pin to unlock the lock combo below. Use this
            form to add or update the combination for an integral lock, i.e. a
            lock that is attached permanently to the locker. If this locker has
            a separate, external lock, click the cancel button and assign a lock
            to this locker from the locks tab instead.
          </DialogContentText>
          <TextField
            fullWidth
            placeholder="Enter pin"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            id="enter-pin"
            name="enter_pin"
            sx={{ my: "15px" }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={checkPin}
            sx={{ display: isTrue ? "none" : "block" }}
          >
            Unlock
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={addEditInternalLogDialogClose}
            sx={{ marginRight: "5px" }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isTrue ? false : true}
            onClick={handleCombo}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddEditInternalLog;

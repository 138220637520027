import React, { useState } from "react";
import { Switch } from "@mui/material";
import { ToggleMenuStatus } from "../../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";

const MenuStatusToggle = (props) => {
  const dispatch = useDispatch();
  const { value, id } = props;
  console.log("value", value);
  const [active, setActive] = useState(value === 1 ? true : false);
  const handleActive = (status, id) => {
    let payload = {
      action: status === true ? 0 : 1,
      id: id,
    };
    ToggleMenuStatus(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Status has been changed successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Switch
        checked={active}
        onClick={() => {
          setActive(!active);
          handleActive(active, id);
        }}
      />
    </>
  );
};

export default MenuStatusToggle;

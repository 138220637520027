import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  TextField,
  Button,
  Typography,
  FormControl as MuiFormControl,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { UnmaskPassword } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
  getUnmasked,
} from "../../../redux/slices/mainSlice";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const UnmaskNames = ({ unmaskNamesDialog, unmaskNamesDialogClose }) => {
  const dispatch = useDispatch();

  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Please enter comment"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let payload = {
      password: values.password,
    };
    UnmaskPassword(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("Unmasked successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          unmaskNamesDialogClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          dispatch(getUnmasked());
        } else {
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          // darInquiryClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        // darInquiryClose();
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="unmask-dialog-title"
        aria-describedby="unmask-dialog-desc"
        open={unmaskNamesDialog}
        onClose={unmaskNamesDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="attendance-title-modal"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Unmask Names
          </Typography>
          <DialogActions>
            <IconButton
              onClick={unmaskNamesDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A special access password is required to unmask the names of the
            persons who have submitted Covid-19 prescreening surveys. This
            password is generally known by your HR department, occupational
            nurse or other designated person responsible for health and safety
            at your business.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl mt={3}>
                  <TextField
                    name="password"
                    label="Enter Password"
                    placeholder="*****"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.password && errors.password)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    helperText={touched.password && errors.password}
                    sx={{ marginTop: "20px" }}
                    // multiline={true}
                    // rows={6}
                  />
                </FormControl>
                <DialogActions sx={{ padding: "20px 24px" }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={unmaskNamesDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" variant="contained">
                    Send
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UnmaskNames;

import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import * as Yup from "yup";
import { AddLock, CheckPinLock, EditLockCombo } from "../../../api";
import { makeStyles } from "@mui/styles";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem,
  FormLabel,
  Card,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing, width } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const EditLock = ({
  editLockDialogOpen,
  editLockDialogClose,
  editLockDialog,
  lockList,
  id,
}) => {
  const dispatch = useDispatch();
  const [combo, setCombo] = useState("");
  const [isUnlock, setIsUnlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const handleUnlockPin = () => {
    setLoading(true);
    CheckPinLock({ pin: combo, locker_id: id })
      .then((res) => {
        if (res.statusCode === 200) {
          setCombo(res.data?.combo);
          setIsUnlock(true);
          setLoading(false);
        } else {
          console.log("err");
          setLoading(false);
          dispatch(getSnackOpen());
          dispatch(
            getMsg(
              "Please enter the correct pin number or contact your SOVA admin for assistance"
            )
          );
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleCombo = () => {
    setLoading(true);
    let payload = {
      id: id,
      combo: combo,
    };
    EditLockCombo(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          editLockDialogClose();
          dispatch(getSnackOpen());
          dispatch(getMsg("Lock updated successfully"));
          dispatch(getSnackAlert("success"));
        } else {
          console.log("err");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-edit-locks-title"
        aria-describedby="add-edit-locks-desc"
        open={editLockDialog}
        onClose={editLockDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-edit-locks-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit External Lock
          </Typography>
          <DialogActions>
            <IconButton
              onClick={editLockDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              SUPERVISORS ONLY. When adding new locks, enter brand serial and
              combo of the external lock. For Master locks with numbers, enter
              combo like '06-24-36'. If there are single digits in the combo,
              make sure to include the preceeding zero as shown in the example.
              If your locks have letters, enter those with no dashes like 'ABC'.
            </Typography>
            <Typography>
              If editing existing combo locks, only the combo field will be
              visible below. To see the hidden lock combo, enter your supervisor
              pin in the Combo field and click the 'Unlock' button. Then you can
              change the combo if needed. Contact your HR department if you need
              the supervisor pin number.
            </Typography>
          </DialogContentText>
          <Grid container spacing={6}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl mt={6}>
                <TextField
                  name="combo"
                  label="Combo*"
                  id="combo"
                  value={combo}
                  onChange={(e) => setCombo(e.target.value)}
                  placeholder={isUnlock ? "06-24-36" : "supervisor pin"}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <Button
                clor="primary"
                variant="contained"
                sx={{ marginLeft: "10px" }}
                onClick={handleUnlockPin}
                sx={{ display: isUnlock ? "none" : "block" }}
              >
                Unlock
              </Button>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              clor="primary"
              variant="outlined"
              onClick={editLockDialogClose}
            >
              Cancel
            </Button>
            <Button
              clor="primary"
              variant="contained"
              sx={{ marginLeft: "10px" }}
              disabled={isUnlock ? false : true}
              onClick={handleCombo}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default EditLock;

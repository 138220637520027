import React, { usState } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  FormControl as MuiFormControl,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { UpdateAedTriningDate } from "../../../api";

import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const UpdateTraining = ({
  updateTrainingDialog,
  updateTrainingDialogClose,
  updateTrainingDate,
  selected,
  setPageLoad,
}) => {
  const dispatch = useDispatch();

  const [trainingDate, setTrainingDate] = React.useState(null);
  const initialValues = {
    training_date: null,
  };
  const validationSchema = Yup.object().shape({
    training_date: Yup.string()
      .nullable()
      .required("Training date is required"),
  });
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setPageLoad(true);
    let formatTrainingDate = values.training_date
      ? moment(values.training_date).format("YYYY-MM-DD")
      : "";
    let payload = {
      id: selected.toString(),
      training_date: formatTrainingDate,
    };
    UpdateAedTriningDate(payload)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          dispatch(getMsg("Training date updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          updateTrainingDialogClose();
          updateTrainingDate();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        // let errors = err.data.errors;
        // let error = handleApiResonseErrors(errors);
        dispatch(getMsg("Incorrect date format, please select from dropdown"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };
  return (
    <>
      <Dialog
        aria-labelledby="update-training-modal"
        aria-describedby="update-training-modal"
        open={updateTrainingDialog}
        onClose={updateTrainingDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="vcomm-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Please select date of the training session
          </Typography>
          <DialogActions>
            <IconButton
              onClick={updateTrainingDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          {/* <FormControl mt={6}>
            <DatePicker
              name="training_date"
              label="Training date*"
              value={trainingDate}
              onChange={(newValue) => {
                setTrainingDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "Click to select date",
                  }}
                />
              )}
            />
          </FormControl> */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} md={12}>
                  <FormControl mt={3}>
                    <DatePicker
                      // name="pad_expiry"
                      label="Training Date"
                      value={values.training_date}
                      onChange={(newValue) => {
                        setFieldValue("training_date", newValue);
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          name="training_date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            // placeholder: "09/13/2022",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <DialogActions sx={{ padding: "20px 24px" }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={updateTrainingDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" variant="contained">
                    Update
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
        {/* <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={updateTrainingDialogClose}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ ml: "10px" }}
            onClick={updateTrainingDateaaaa}
          >
            Update
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default UpdateTraining;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { handleApiResonseErrors } from "../../../helper/helper";
import { useDispatch } from "react-redux";
import { UpdateMediaFile } from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Card,
  LinearProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  NotInterested as NotInterestedIcon,
  CloudUpload as CloudUploadIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useDropzone } from "react-dropzone";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  height: 100px;
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Input = styled("input")({
  display: "none",
});
//Classes Styling
const useStyles = makeStyles((theme) => ({
  BulletList: {
    listStyle: "disc",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "disc",
    },
  },
  decimalList: {
    listStyle: "decimal",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "decimal",
    },
  },
}));
const EditMediaUpload = ({
  editMedia,
  setEditMedia,
  selectedItem,
  getMediaListing,
  setPageLoad,
}) => {
  const classes = useStyles();

  const [uploadeMedia, setUploadeMedia] = useState([]);

  const [mediaName, setMediaName] = useState(selectedItem.MediaName);
  const [mediaDescription, setMediaDescription] = useState(
    selectedItem.MediaDescription
  );

  const changeName = (event) => {
    setMediaName(event.target.value);
  };

  const changeDesc = (event) => {
    setMediaDescription(event.target.value);
  };

  const saveMedia = () => {
    if (!mediaName || !mediaDescription) {
      alertMessage("Please provide media name and description", "error");
      return;
    }
    setPageLoad(true);
    let formData = {
      id: selectedItem.MediaID,
      media_name: mediaName,
      media_description: mediaDescription,
    };
    UpdateMediaFile(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          alertMessage(res.message, "success");
          getMediaListing();
        } else {
          alertMessage("Sorry! something wrong please try again", "error");
        }
        setPageLoad(false);
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setPageLoad(false);
          alertMessage(error);
        }
      });
  };

  const dispatch = useDispatch();
  const alertMessage = (msg, type) => {
    dispatch(getMsg(msg));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };

  let src = "";
  switch (selectedItem?.MediaTypeID) {
    case 1:
      src = selectedItem?.link;
      break;
    case 2:
      src = "/static/img/reports/File-Video-icon.png";
      break;
    case 3:
      src = "/static/img/reports/File-Audio-icon.png";
      break;
    case 4:
      src = "/static/img/reports/Adobe-PDF-Document-icon.png";
      break;
    case 5:
      src = "/static/img/reports/Document-Microsoft-Word-icon.png";
      break;
    default:
      src = "";
      break;
  }

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        aria-labelledby="media-upload-title"
        aria-describedby="media-upload-description"
        open={editMedia}
        onClose={() => setEditMedia(false)}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="media-upload-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Media Upload
          </Typography>
          <DialogActions>
            <IconButton
              onClick={() => setEditMedia(false)}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell align="left">Media Name</TableCell>
              <TableCell align="left">Media Description</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableRow>
            <TableCell>
              <ThumbnailTableWrapper>
                <Image
                  sx={{ width: "100%" }}
                  component="img"
                  alt="Guage Chart"
                  src={src}
                />
              </ThumbnailTableWrapper>
            </TableCell>
            <TableCell sx={{ minWidth: "200px" }}>
              <FormControl>
                <TextField
                  fullWidth
                  value={mediaName}
                  onChange={(evt) => changeName(evt)}
                  placeholder="Media Name"
                />
              </FormControl>
            </TableCell>
            <TableCell sx={{ minWidth: "200px" }}>
              <FormControl>
                <TextField
                  fullWidth
                  value={mediaDescription}
                  onChange={(evt) => changeDesc(evt)}
                  placeholder="Media Description"
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <Box sx={{ display: "flex" }}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ marginRight: "10px" }}
                  onClick={() => saveMedia()}
                >
                  Update
                </Button>
              </Box>
            </TableCell>
          </TableRow>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditMediaUpload;

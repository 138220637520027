export default [
  {
    id: "senderid",
    label: "ID",
  },
  {
    id: "senderaddr",
    label: "Sender Address",
  },
  {
    id: "receiverid",
    label: "ID",
  },
  {
    id: "recipientname",
    label: "Recipient Name",
  },
  {
    id: "recipientaddr",
    label: "Recipient Address",
  },
  {
    id: "shipdetailby",
    label: "Ship Detail By",
  },
  {
    id: "residential",
    label: "Residential",
  },
  {
    id: "recipientphone",
    label: "Recipient Phone",
  },
  {
    id: "recipientemail",
    label: "Recipient Email",
  },
  {
    id: "trackingid",
    label: "Tracking ID",
  },
  {
    id: "carrier",
    label: "Carrier",
  },
  {
    id: "servicelevel",
    label: "Service Level",
  },
  {
    id: "length",
    label: "Length",
  },
  {
    id: "width",
    label: "Width",
  },
  {
    id: "height",
    label: "Height",
  },
  {
    id: "weight",
    label: "Weight",
  },
  {
    id: "amountpaid",
    label: "Amount Paid",
  },
  {
    id: "freightcost",
    label: "Freight Cost",
  },
  {
    id: "processingcost",
    label: "Processing Cost",
  },
  {
    id: "salestax",
    label: "Sales Tax",
  },
  {
    id: "insurance",
    label: "Insurance",
  },
  {
    id: "trackingstatus",
    label: "Tracking Status",
  },
  {
    id: "finaltrackingmessage",
    label: "Final Tracking Message",
  },
  {
    id: "created",
    label: "Created",
  },
  {
    id: "modified",
    label: "Modified",
  },
];

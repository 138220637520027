import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  FormControl as MuiFormControl,
  Alert,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { SaveKeyDetails, KeyGroupListDrop } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const keyGroup = [
  { key: "Executive", value: "executive" },
  { key: "Test updated", value: "testupdated" },
  { key: "Other", value: "other" },
];
const KeyDetail = ({
  keyDetailDialog,
  keyDetailDialogClose,
  keyReqId,
  getInvolvedKeyDetailsListing,
  keyDetailData,
  keyActionStatus,
  setPageLoad,
}) => {
  const dispatch = useDispatch();
  let initialValues = {
    key_number: keyDetailData
      ? keyDetailData.key_number
        ? keyDetailData.key_number
        : ""
      : "",
    description: keyDetailData
      ? keyDetailData.description
        ? keyDetailData.description
        : ""
      : "",
    type: keyDetailData ? (keyDetailData.type ? keyDetailData.type : "") : "",
    other_type: keyDetailData
      ? keyDetailData.other_type
        ? keyDetailData.other_type
        : ""
      : "",
  };

  const validationSchema = Yup.object().shape({
    key_number: Yup.string().required("Please enter equipment id"),
    description: Yup.string().required("Please enter equipment name"),
    type: Yup.string().required("Please enter lastname"),
    other_type: Yup.string().when(["type"], (same, schema) => {
      return same === "0" ? schema.required("Please enter other type") : schema;
    }),

    // position: Yup.string().required("Please enter company name"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formData = {
        id: keyDetailData ? (keyDetailData.id ? keyDetailData.id : null) : null,
        key_request_id: keyReqId ? keyReqId : "",
        name: values.key_number,
        description: values.description,
        type: values.type,
        other_type: values.other_type,
      };
      SaveKeyDetails(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            getInvolvedKeyDetailsListing();
            dispatch(getMsg("Key details saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            keyDetailDialogClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
      // await
      //   timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (err) {
      setPageLoad(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    }
  };

  const [keyGroupList, setKeyGroupList] = useState([]);
  const getKeyGroupList = () => {
    setPageLoad(true);
    KeyGroupListDrop()
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode == 200) {
          //let data = res.data.map((item) => item.incident_id).toString();
          setKeyGroupList(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  useEffect(() => {
    getKeyGroupList();
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="keyDetail-title-modal"
        aria-describedby="keyDetail-desc-modal"
        open={keyDetailDialog}
        onClose={keyDetailDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="attendance-title-modal"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Key Detail
          </Typography>
          <DialogActions>
            <IconButton
              onClick={keyDetailDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          {keyDetailData.id ? null : (
            <Alert severity="info">
              {/* <AlertTitle>Info</AlertTitle> */}
              Details of the key(s) the user is requesting access to
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={3}>
                      <CustomTextField
                        label="Equipment ID *"
                        autoComplete="off"
                        name="key_number"
                        id="equipment-id"
                        placeholder="Key or Ring ID"
                        InputLabelProps={{ shrink: true }}
                        disabled={keyActionStatus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        label="Equipment name *"
                        autoComplete="off"
                        name="description"
                        id="equipment-name"
                        placeholder="Key Set 01"
                        InputLabelProps={{ shrink: true }}
                        disabled={keyActionStatus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <InputLabel
                        shrink
                        id="department"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Key group *
                      </InputLabel>
                      <Select
                        labelId="driver"
                        id="driver-select"
                        name="type"
                        label="Key group *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.type}
                        onChange={(e) => {
                          setFieldValue("type", e.target.value);
                          setFieldValue("other_type", "");
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                        fullWidth
                        displayEmpty
                        disabled={keyActionStatus}
                      >
                        <MenuItem disabled value="">
                          Select key group
                        </MenuItem>
                        {keyGroupList.map((data) => {
                          return (
                            <MenuItem value={data.value} key={data.key}>
                              {data.key}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value="0">Other</MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.type && errors.type}
                      </FormHelperText>
                      {/* <CustomSelect
                        name="type"
                        fieldLabel="Key group"
                        options={keyGroup}
                        displayEmpty
                      /> */}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: values.type === "0" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        label="Other key group"
                        autoComplete="off"
                        name="other_type"
                        id="other-type"
                        InputLabelProps={{ shrink: true }}
                        disabled={keyActionStatus}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    disabled={keyActionStatus}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default KeyDetail;

export default [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "activity",
    label: "Activity",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "UserName",
    label: "User",
  },
  {
    id: "created",
    label: "Datetime",
  },
];

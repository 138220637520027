import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import * as Yup from "yup";
import { AddLock } from "../../../api";
import { makeStyles } from "@mui/styles";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem,
  FormLabel,
  Card,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const AddEditExternalLocks = ({
  addEditExternalLocksDialog,
  addEditExternalLocksDialogClose,
  lockList,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    brand: "",
    serial: "",
    combo: "",
  };
  const validationSchema = Yup.object().shape({
    brand: Yup.string().required("Please enter brand"),
    serial: Yup.string().required("Please enter serial"),
    combo: Yup.string().required("Please enter combo"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setLoading(true);
    try {
      let payload = {
        brand: values.brand,
        serial: values.serial,
        combo: values.combo,
      };
      AddLock(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            addEditExternalLocksDialogClose();
            dispatch(getSnackOpen());
            dispatch(getMsg("Lock submitted successfully"));
            dispatch(getSnackAlert("success"));
            lockList();
            setLoading(false);
          } else {
            dispatch(getSnackOpen());
            dispatch(getMsg("something went worng!"));
            dispatch(getSnackAlert("error"));
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getSnackClose());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-edit-locks-title"
        aria-describedby="add-edit-locks-desc"
        open={addEditExternalLocksDialog}
        onClose={addEditExternalLocksDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-edit-locks-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit External Lock
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addEditExternalLocksDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              SUPERVISORS ONLY. When adding new locks, enter brand serial and
              combo of the external lock. For Master locks with numbers, enter
              combo like '06-24-36'. If there are single digits in the combo,
              make sure to include the preceeding zero as shown in the example.
              If your locks have letters, enter those with no dashes like 'ABC'.
            </Typography>
            <Typography>
              If editing existing combo locks, only the combo field will be
              visible below. To see the hidden lock combo, enter your supervisor
              pin in the Combo field and click the 'Unlock' button. Then you can
              change the combo if needed. Contact your HR department if you need
              the supervisor pin number.
            </Typography>
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="brand"
                        label="Brand*"
                        id="brand"
                        placeholder="Master"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="serial"
                        label="Serial*"
                        id="serial"
                        placeholder="C49893"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="combo"
                        label="Combo*"
                        id="combo"
                        placeholder="06-24-36"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    clor="primary"
                    variant="outlined"
                    onClick={addEditExternalLocksDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    clor="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddEditExternalLocks;

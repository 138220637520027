import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { Menu, MenuItem, Button, Box, Grid } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  colorPicker: {
    boxShadow: "none !important",
  },
  dateRangeStyling: {},
}));
const StyledDropdownDateRange = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      maxWidth: "560px",
      width: "100%",
      "& .MuiGrid-root": {
        width: "auto",
      },
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenuItem-root": { padding: 0 },
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        padding: "0px 0 10px",
      },
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);
const CustomDateRangeDropdown = ({
  handleShow,
  conformation,
  setDates,
  selectedStart,
  selectedEnd,
}) => {
  const [colorpicker, setColorPicker] = useState(null);
  const [startDate, setStartDate] = React.useState(
    selectedStart ? selectedStart : new Date()
  );
  const [endDate, setEndDate] = React.useState(
    selectedEnd ? selectedEnd : new Date()
  );
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleDateRangeSelect = (ranges) => {
    let start = ranges.selection.startDate;
    let end = ranges.selection.endDate;
    setStartDate(start);
    setEndDate(end);
  };

  const closeColorPicker = () => {
    setColorPicker(null);
    //   setColor(user.color);
  };
  const classes = useStyles();

  return (
    <>
      <StyledDropdownDateRange
        id="color-picker"
        anchorEl={colorpicker}
        open={Boolean(handleShow)}
        // onClose={() => handleSave(user.UserID)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DateRangePicker
                  className={classes.dateRangeStyling}
                  ranges={[selectionRange]}
                  onChange={(e) => handleDateRangeSelect(e)}
                />
              </Grid>
              <Grid item xs={12} align="right">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    conformation(true);
                    closeColorPicker();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ marginLeft: "10px", marginRight: "10px" }}
                  onClick={() => {
                    setDates(startDate, endDate);
                    conformation(true);
                  }}
                >
                  Done
                </Button>
              </Grid>
            </Grid>
          </Box>
        </MenuItem>
      </StyledDropdownDateRange>
    </>
  );
};

export default CustomDateRangeDropdown;

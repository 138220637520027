export default [
  {
    id: "date",
    label: "Date",
  },

  {
    id: "time",
    label: "Time",
  },
  {
    id: "code_called",
    label: "Code Called",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "summary_description",
    label: "Summmary Description",
  },
  {
    id: "action",
    label: "Action",
  },
];

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checked: [],
  snackStatus: false,
  tourRow: {},
  msg: "",
};

export const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    getChecked: (state, action) => {
      state.checked = action.payload;
    },
    getSnackOpen: (state) => {
      state.snackStatus = true;
    },
    getSnackClose: (state) => {
      state.snackStatus = false;
    },
    getTourRow: (state, action) => {
      state.tourRow = action.payload;
    },
    getMsg: (state, action) => {
      state.msg = action.payload;
    },
  },
});

export const { getChecked, getSnackOpen, getSnackClose, getTourRow, getMsg } =
  tourSlice.actions;
export default tourSlice.reducer;

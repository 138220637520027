import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TextField,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Select,
  Box,
} from "@mui/material";
import {
  ReporterUserList,
  UpdateReporterStatus,
  UpdateReporterUser,
  AddNotes,
} from "../../api";
import {
  Settings as SettingsIcon,
  PhotoCamera as PhotoCameraIcon,
  MyLocation as MyLocationIcon,
} from "@mui/icons-material";
import Toaster from "../users/components/Toaster";

const ReportingUser = () => {
  const [reporters, setReporters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updatingStatusId, setUpdatingStatusId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedReporterId, setSelectedReporterId] = useState(null);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const handleOpenMapDialog = (lat, lng) => {
    setLocation({ lat, lng }); // Set location for map
    setIsMapDialogOpen(true); // Open map dialog
  };

  const handleCloseMapDialog = () => {
    setIsMapDialogOpen(false); // Close map dialog
    setLocation({ lat: null, lng: null }); // Clear location data
  };

  const handleCloseSnack = () => setOpenSnack(false);

  const handleOpenCameraDialog = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the image URL
    setIsLoading(true); // Start loader
    setIsCameraDialogOpen(true); // Open dialog
  };

  const handleCloseCameraDialog = () => {
    setIsCameraDialogOpen(false); // Close dialog
    setSelectedImage(null); // Clear the selected image
    setIsLoading(false); // Reset loader
  };

  useEffect(() => {
    const fetchReporters = async () => {
      try {
        const response = await ReporterUserList();
        if (response.data) {
          // Set default status as "Acknowledged" if status is null or undefined
          const updatedReporters = response.data.map((reporter) => ({
            ...reporter,
            status: reporter.status || "Acknowledged",
          }));
          setReporters(updatedReporters);
        } else {
          setError("No data received from the API");
        }
      } catch (err) {
        setError("Failed to fetch reporter user list");
      } finally {
        setLoading(false);
      }
    };

    fetchReporters();
  }, []);

  const handleStatusUpdate = async (id, status) => {
    setUpdatingStatusId(id);
    try {
      const response = await UpdateReporterStatus({ id, status });
      if (response.StatusCode === 200) {
        setReporters((prevReporters) =>
          prevReporters.map((reporter) =>
            reporter.id === id ? { ...reporter, status } : reporter
          )
        );
        setSeverity("success");
        setMsg(response.message || "Status updated successfully");
      } else {
        throw new Error(response.message || "Failed to update status");
      }
    } catch (error) {
      setSeverity("error");
      setMsg(error.message || "Something went wrong, please try again");
    } finally {
      setUpdatingStatusId(null);
      setOpenSnack(true);
    }
  };

  const handleSettingsClick = (event, reporterId) => {
    setAnchorEl(event.currentTarget);
    setSelectedReporterId(reporterId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddNote = () => {
    setNoteDialogOpen(true);
    handleMenuClose();
  };

  const handleNoteDialogClose = () => {
    setNoteDialogOpen(false);
    setNoteText("");
  };

  const handleNoteSubmit = async () => {
    try {
      const response = await AddNotes({
        id: selectedReporterId,
        notes: noteText,
      });
      if (response.StatusCode === 200) {
        setSeverity("success");
        setMsg(response.message || "Note added successfully");
      } else {
        throw new Error(response.message || "Failed to add note");
      }
    } catch (error) {
      setSeverity("error");
      setMsg(error.message || "Something went wrong, please try again");
    } finally {
      setOpenSnack(true);
      handleNoteDialogClose();
    }
  };

  const handleSendUpdate = async () => {
    try {
      const response = await UpdateReporterUser({
        id: selectedReporterId,
        message: message,
      });
      if (response.StatusCode === 200) {
        setSeverity("success");
        setMsg(response.message || "SMS sent successfully");
        handleClose();
      } else {
        throw new Error(response.message || "Failed to update user");
      }
    } catch (error) {
      setSeverity("error");
      setMsg(error.message || "Something went wrong, please try again");
    } finally {
      setOpenSnack(true);
      handleMenuClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
    handleMenuClose();
  };

  const handleClose = () => {
    setOpen(false);
    setMessage("");
  };

  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        msg={msg}
        severity={severity}
      />

      <Typography variant="h2">Anonymous Intel Sharing</Typography>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <CircularProgress />
        </div>
      ) : (
        <TableContainer sx={{ marginTop: "30px", backgroundColor: "white" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Reporter Type</TableCell>
                <TableCell>Reporter Name</TableCell>
                <TableCell>Reporter Phone</TableCell>
                <TableCell>Indoor/Outdoor</TableCell>
                <TableCell>Person/Things</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reporters.length > 0 ? (
                reporters.map((reporter) => (
                  <TableRow key={reporter.id}>
                    <TableCell>{reporter.type}</TableCell>
                    <TableCell>{reporter.name}</TableCell>
                    <TableCell>{reporter.phone}</TableCell>
                    <TableCell>{reporter.reporting_location}</TableCell>
                    <TableCell>{reporter.reporting_about}</TableCell>
                    <TableCell align="center">
                      {reporter.thing_image &&
                      reporter.thing_image !== "link not found" ? (
                        <PhotoCameraIcon
                          onClick={() =>
                            handleOpenCameraDialog(reporter.thing_image)
                          }
                          style={{ cursor: "pointer" }}
                          color="primary"
                        />
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell>
                      {updatingStatusId === reporter.id ? (
                        <CircularProgress size={12} />
                      ) : (
                        <Select
                          value={reporter.status}
                          onChange={(e) =>
                            handleStatusUpdate(reporter.id, e.target.value)
                          }
                          displayEmpty
                          sx={{ minWidth: 120 }}
                        >
                          <MenuItem value="Acknowledged">Acknowledged</MenuItem>
                          <MenuItem value="Escalated">Escalated</MenuItem>
                          <MenuItem value="On-hold">On-hold</MenuItem>
                          <MenuItem value="Deferred">Deferred</MenuItem>
                          <MenuItem value="Started">Started</MenuItem>
                          <MenuItem value="Resolved">Resolved</MenuItem>
                        </Select>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {reporter.reporting_user_lat &&
                      reporter.reporting_user_long ? (
                        <MyLocationIcon
                          sx={{ cursor: "pointer" }}
                          color="primary"
                          onClick={() =>
                            handleOpenMapDialog(
                              reporter.reporting_user_lat,
                              reporter.reporting_user_long
                            )
                          }
                        />
                      ) : (
                        <></> // No icon displayed if lat/long are missing
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <SettingsIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => handleSettingsClick(e, reporter.id)}
                      />
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        sx={{
                          borderRadius: "6px",
                          boxShadow:
                            "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        }}
                      >
                        <MenuItem onClick={handleAddNote}>Add Note</MenuItem>
                        <MenuItem onClick={handleOpen}>
                          Send Update to User
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No reporters found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
        open={noteDialogOpen}
        onClose={handleNoteDialogClose}
      >
        <DialogTitle>Add Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Note"
            fullWidth
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoteDialogClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleNoteSubmit} variant="contained">
            Save Note
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send Update</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message"
            fullWidth
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSendUpdate} variant="contained">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
        open={isCameraDialogOpen}
        onClose={handleCloseCameraDialog}
      >
        <DialogTitle>Thing Image</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            {isLoading && <CircularProgress />}
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Reporter Uploaded"
                style={{
                  display: isLoading ? "none" : "block",
                  width: "100%",
                  borderRadius: "8px",
                }}
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)} // hide loader if loading fails
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCameraDialog}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={isMapDialogOpen}
        onClose={handleCloseMapDialog}
      >
        <DialogTitle>Location</DialogTitle>
        <DialogContent>
          <Box
            component="iframe"
            width="100%"
            height="400px"
            src={`https://www.google.com/maps?q=${location.lat},${location.lng}&hl=es;z=14&output=embed`}
            frameBorder="0"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMapDialog} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportingUser;

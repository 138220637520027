import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { EquipmentLog } from "../../api";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import moment from "moment";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import EquipmentLogListCol from "../table-columns-json/equipment/equipmentLogList";
import { setEquipmentLogList } from "../../redux/slices/equipment/equipmentTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import WithPermissionHide from "../../utils/withPermissionHide";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& a": {
        color:
          theme.palette.mode === "light" ? "#374151" : theme.palette.grey[300],
        textDecoration: "none",
      },
    },
  })
);
const StyledSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiSwitch-root": {
        position: "absolute",
        right: "-10px",
        top: "0px",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(name, equiptype, equipname, checkout, checkin, action) {
  return {
    name,
    equiptype,
    equipname,
    checkout,
    checkin,
    action,
  };
}
const rows = [
  createData(
    "",
    "Key",
    "GK026",
    "GK026",
    "Grand club keys",
    "GK026",
    "Security office keybox",
    0,
    "02/25/2016",
    10,
    0,
    0
  ),
  createData(
    "",
    "Key",
    "GK027",
    "GK026",
    "Grand club keys",
    "GK026",
    "Security office keybox",
    0,
    "02/25/2016",
    8,
    0,
    0
  ),
  createData(
    "",
    "Key",
    "GK028",
    "GK026",
    "Grand club keys",
    "GK026",
    "Security office keybox",
    0,
    "02/25/2016",
    5,
    0,
    0
  ),
  createData(
    "",
    "Key",
    "GK029",
    "GK026",
    "Grand club keys",
    "GK026",
    "Security office keybox",
    0,
    "02/25/2016",
    1,
    0,
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
const headCells = [
  {
    id: "fname",
    numeric: true,
    disablePadding: false,
    label: "User Name",
  },
  {
    id: "equiptype",
    numeric: true,
    disablePadding: false,
    label: "Equipment Type",
  },
  {
    id: "equipname",
    numeric: true,
    disablePadding: false,
    label: "Equipment Name",
  },
  {
    id: "checkout",
    numeric: true,
    disablePadding: false,
    label: "Checkout Date",
  },
  {
    id: "checkin",
    numeric: true,
    disablePadding: false,
    label: "Check In Date",
  },
  // { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {EquipmentLogListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={headCell.id === "fname" ? orderBy === headCell.id : false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id === "fname" ? createSortHandler(headCell.id) : null
              }
              hideSortIcon={headCell.id === "fname" ? false : true}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    setDataToFilter,
    totalRecord,
    equipmentLogs,
    generatePdf,
    tableCalRedux,
    defalutTableCalRedux,
  } = props;
  const [actions, setActions] = React.useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState(null);
  const [endDateToFilter, setEndDateToFilter] = useState(null);
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [equipmentType, setEquipmentType] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const dispatch = useDispatch();

  const applySearch = () => {
    const payload = {
      start_date:
        startDateToFilter !== null
          ? format(startDateToFilter, "yyyy-MM-dd")
          : null,
      end_date:
        endDateToFilter !== null ? format(endDateToFilter, "yyyy-MM-dd") : null,
      userid: userId,
      name: name,
      equipment_type: equipmentType,
    };
    setDataToFilter(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setDataToFilter(null);
    setEndDateToFilter(null);
    setUserId("");
    setName("");
    setEquipmentType("");
  };

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };

  // show checked only switch toggle
  const [state, setState] = React.useState({
    switchone: true,
  });
  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { switchone } = state;
  const [value, setValue] = React.useState(null);
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  let headers = [
    { label: "User Name", key: "fname" },
    { label: "Equipment Type", key: "equipment_type" },
    { label: "Equipment Name", key: "equipment_name" },
    { label: "Checkout Date", key: "checked_out_time" },
    { label: "Checkin Date", key: "checked_in_time" },
  ];

  return (
    <Toolbar>
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Search by name or click down arrow"
              inputProps={{}}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch({});
                  setIsFilter(true);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Date range"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM-DD-YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM-DD-YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="user-id"
                              label="User ID"
                              value={userId}
                              onChange={(e) => setUserId(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Barcode ID"
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="name"
                              label="Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="John Smith"
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="equip-type"
                              label="Equipment type"
                              value={equipmentType}
                              onChange={(e) => setEquipmentType(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Radios"
                            />
                          </FormControl>
                          <FormControl>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              sx={{ width: "175px", marginRight: "5px" }}
                              onClick={() => {
                                applySearch({});
                                handleCloseForm();
                                setIsFilter(true);
                              }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  if (name !== "") {
                    applySearch({});
                    setIsFilter(true);
                  }
                }}
              >
                {/* <SearchIcon /> */}
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <WithPermissionHide
              controller="Equipment"
              action="downloadEquipmentlog"
            >
              <MenuItem onClick={generatePdf}>
                <ListItemIcon>
                  <PictureAsPdfIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Download PDF" pl={0} />
              </MenuItem>
            </WithPermissionHide>
            <WithPermissionHide
              controller="Equipment"
              action="downloadEquipmentlog"
            >
              <CSVLink
                data={equipmentLogs}
                headers={headers}
                onClick={handleClose}
                filename={`SOVA-equipment-logs-${format(
                  new Date(),
                  "MM-dd-yyyy"
                )}`}
              >
                <MenuItem>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Download CSV" pl={0} />
                </MenuItem>
              </CSVLink>
            </WithPermissionHide>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setEquipmentLogList(data));
                    }}
                    listing={EquipmentLogListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setEquipmentLogList(defalutTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [equipmentLogs, setEquipmentLogs] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [length, setLength] = useState(null);

  const tableCalRedux = useSelector(
    (state) => state.equipmentTableCol.equipmentLogList
  );
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultEquipmentTableCol.equipmentLogList
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = equipmentLogs.map((n) => n.visitor_detail_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  // Log Action Dropdown
  const [logDropdown, setLogDropdown] = React.useState(null);
  const actionLogOpen = (event) => {
    setLogDropdown(event.currentTarget);
  };
  const actionLogClose = () => {
    setLogDropdown(null);
  };
  //Equipment log modal
  const [open, setOpen] = React.useState(false);
  const clickLogModalOpen = () => {
    setOpen(true);
  };
  const logModalClose = () => {
    setOpen(false);
  };
  //Date Time Picker
  const [value, setValue] = React.useState(null);
  const [startdate, setStartDate] = React.useState(null);
  const [enddate, setEndDate] = React.useState(null);
  //
  const [state, setState] = React.useState({
    switchtwo: true,
  });

  const handleSwitching = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { switchtwo } = state;

  //method for process equipment logs
  const processEquipmentLogs = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      ...dataToFilter,
    };
    EquipmentLog(currentPage, { params: payload })
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipmentLogs(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setLoading(false);
          setEquipmentLogs(res.data);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setTotalRecord(0);
        setEquipmentLogs([]);
      });
  };

  //method to generate pdf
  const generatePdf = () => {
    const doc = new jsPDF();
    var img = new Image();
    img.src = "/static/img/brands/logo.png";
    doc.addImage(img, "png", 10, 10, 45, 20);

    doc.setFontSize(20);
    doc.setFont("helvetica", "bold");
    doc.text("Equipment Logs", 80, 35);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Marigold Hotel", 88, 50);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Reported By:", 140, 70);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Darin", 163, 70);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Generated:", 140, 80);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(format(new Date(), "MM/dd/yyyy HH:mm:ss"), 160, 80);

    const expPdfData = equipmentLogs.map((data) => {
      return [
        data.fname,
        data.equipment_type,
        data.equipment_name,
        data.checked_out_time,
        data.checked_in_time,
      ];
    });
    autoTable(doc, {
      margin: { top: 90 },
      head: [
        [
          "User Name",
          "Equipment Type",
          "Equipment Name",
          "Checkout Date",
          "Checkin Date",
        ],
      ],
      body: expPdfData,
      startY: 90,
      showFoot: "everyPage",
      // showHead: "firstPage",
      // Footer
      didDrawPage: function (data) {
        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 110, pageHeight - 3);
        doc.setLineWidth(0.1);
        doc.line(10, pageHeight - 8, 200, pageHeight - 8, "F");
      },
    });
    let fileName = `SOVA-Equipment-Logs-${format(new Date(), "MM-dd-yyyy")}`;
    doc.save(fileName);
  };
  //useeffect method
  useEffect(() => {
    processEquipmentLogs();
  }, [rowsPerPage, currentPage, dataToFilter]);

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setDataToFilter={setDataToFilter}
          totalRecord={totalRecord}
          equipmentLogs={equipmentLogs}
          generatePdf={generatePdf}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={equipmentLogs.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(equipmentLogs, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.visitor_detail_id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={index} selected={isItemSelected}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          // padding="none"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.user_name === true ? "" : "hide-cell"
                          }
                        >
                          {row.fname}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.equipment_type === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.equipment_type}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.equipment_name === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.equipment_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.checkout_date === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.checked_out_time}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.checkin_date === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.checked_in_time}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {equipmentLogs.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * equipmentLogs.length,
                    }}
                  >
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={
              Math.ceil(totalRecord / rowsPerPage) > 0
                ? Math.ceil(totalRecord / rowsPerPage)
                : 1
            }
            page={currentPage}
            boundaryCount={2}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
    </div>
  );
}

function EquipmentLogs() {
  return (
    <React.Fragment>
      <Helmet title="Equipment Logs" />
      <Typography variant="h3" gutterBottom display="inline">
        Equipment Logs
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EquipmentLogs;

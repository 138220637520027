import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Grid,
  Card,
  Box,
  Paper,
  Stack,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Avatar as MuiAvatar,
  CardActions,
  CardContent,
  Chip,
} from "@mui/material";
import { padding } from "polished";

import { Cancel as CancelIcon } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material/TableCell";
const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 120px;
  width: 120px;
`;
const ImageWrapper = styled.div``;
const Image = styled.img`
  width: 100%;
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    font-size: 14px;
    font-weight: 600;
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#17212f" : "rgb(229,246,253)"};

    color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#fff" : "#646464"};
    position: sticky;
    top: 0;
    padding: 14px 15px;
  }
  &.${tableCellClasses.body} {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 15px;
    color: ${(props) => props.theme.palette.white};
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#00000045" : "#fafafa"};
  }
`;
const LiveModuleModal = ({
  liveModal,
  setLiveModal,
  liveModalOpen,
  liveModalClose,
}) => {
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1100px",
            },
          },
        }}
        open={liveModal}
        onClose={liveModalClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            React User Activity: Web Developer
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setLiveModal(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={5} lg={5}>
              <Card
                variant="outlined"
                sx={{
                  padding: "10px",
                }}
              >
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={4} md={3}>
                    <Avatar
                      alt="Lucy Lavender"
                      src="/static/img/avatars/avatar-1.jpg"
                    />
                  </Grid>
                  <Grid item xs={9} md={9}>
                    <Typography component="p" variant="body2" ml={5}>
                      Phone: +1 (619) 895-6561
                    </Typography>
                    <Typography component="p" variant="body2" ml={5}>
                      Email: darin@sovasystems.com
                    </Typography>
                  </Grid>
                  <Grid xs={12} align="right">
                    <Button
                      variant="outlined"
                      mt={5}
                      sx={{
                        borderRadius: "30px",
                        padding: "8px 15px",
                        minWidth: "120px",
                        marginLeft: "auto",
                        display: "block",
                      }}
                    >
                      Logout
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={7} lg={7}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={3} xl={3}>
                  <ImageWrapper>
                    <Image
                      sx={{ width: "100%" }}
                      component="img"
                      alt="Guage Chart"
                      src="/static/img/unsplash/guage-1.jpg"
                    />
                  </ImageWrapper>
                </Grid>
                <Grid item xs={12} sm={3} xl={3}>
                  <ImageWrapper>
                    <Image
                      sx={{ width: "100%" }}
                      component="img"
                      alt="Guage Chart"
                      src="/static/img/unsplash/guage-2.jpg"
                    />
                  </ImageWrapper>
                </Grid>
                <Grid item xs={12} sm={3} xl={3}>
                  <ImageWrapper>
                    <Image
                      sx={{ width: "100%" }}
                      component="img"
                      alt="Guage Chart"
                      src="/static/img/unsplash/guage-3.jpg"
                    />
                  </ImageWrapper>
                </Grid>
                <Grid item xs={12} sm={3} xl={3}>
                  <ImageWrapper>
                    <Image
                      sx={{ width: "100%" }}
                      component="img"
                      alt="Guage Chart"
                      src="/static/img/unsplash/guage-4.jpg"
                    />
                  </ImageWrapper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Paper>
                <Typography
                  variant="h5"
                  component="div"
                  color="primary"
                  gutterBottom
                >
                  Activity Log Today's date: 06-14-2022
                </Typography>

                <Stack mt={3}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderBottom: 0,
                    }}
                  >
                    <TableContainer
                      className="tableContainer"
                      sx={{
                        height: "500px",
                        "&::-webkit-scrollbar": {
                          width: 5,
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "#F7F9FC",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#f3f3f3",
                          borderRadius: 2,
                        },
                      }}
                    >
                      <Table
                        sx={{ height: "max-content" }}
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <CustomTableCell component="th">
                              Activity Time
                            </CustomTableCell>
                            <CustomTableCell component="th">
                              Activity Type
                            </CustomTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              03/10/2022 09:24:46
                            </CustomTableCell>
                            <CustomTableCell>
                              IR Writing Started Guest illness-100498847
                            </CustomTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={7}>
              <Paper>
                <Typography variant="h5" color="primary">
                  Incident Reports
                </Typography>

                <Stack mt={3}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderBottom: 0,
                    }}
                  >
                    <TableContainer
                      className="tableContainer"
                      sx={{
                        height: "200px",
                        "&::-webkit-scrollbar": {
                          width: 5,
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "#F7F9FC",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#f3f3f3",
                          borderRadius: 2,
                        },
                      }}
                    >
                      <Table
                        sx={{ height: "max-content" }}
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <CustomTableCell component="th">
                              Incident Type
                            </CustomTableCell>
                            <CustomTableCell component="th">
                              Incident Date
                            </CustomTableCell>
                            <CustomTableCell component="th">
                              Incident Time
                            </CustomTableCell>
                            <CustomTableCell component="th">
                              Incident Status
                            </CustomTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>14:45</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>06:50</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>12/29/2021</CustomTableCell>
                            <CustomTableCell>20:34</CustomTableCell>
                            <CustomTableCell>Returned</CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>14:45</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>06:50</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>12/29/2021</CustomTableCell>
                            <CustomTableCell>20:34</CustomTableCell>
                            <CustomTableCell>Returned</CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>14:45</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>06:50</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>12/29/2021</CustomTableCell>
                            <CustomTableCell>20:34</CustomTableCell>
                            <CustomTableCell>Returned</CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>14:45</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>06:50</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>12/29/2021</CustomTableCell>
                            <CustomTableCell>20:34</CustomTableCell>
                            <CustomTableCell>Returned</CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>14:45</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>03/04/2022</CustomTableCell>
                            <CustomTableCell>06:50</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Vehicle Accident
                            </CustomTableCell>
                            <CustomTableCell>12/29/2021</CustomTableCell>
                            <CustomTableCell>20:34</CustomTableCell>
                            <CustomTableCell>Returned</CustomTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Stack>
              </Paper>

              <Paper mt={6}>
                <Typography variant="h5" color="primary" mt={3}>
                  Tour Activity
                </Typography>

                <Stack mt={3}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderBottom: 0,
                    }}
                  >
                    <TableContainer
                      className="tableContainer"
                      sx={{
                        height: "200px",
                        "&::-webkit-scrollbar": {
                          width: 5,
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "#F7F9FC",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#f3f3f3",
                          borderRadius: 2,
                        },
                      }}
                    >
                      <Table
                        sx={{ height: "max-content" }}
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <CustomTableCell component="th">
                              Tour Name
                            </CustomTableCell>
                            <CustomTableCell component="th">
                              Start Time
                            </CustomTableCell>
                            <CustomTableCell component="th">
                              Result
                            </CustomTableCell>
                            <CustomTableCell component="th">
                              End Time
                            </CustomTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 17:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="3/3"
                                completed={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              11/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              01/04/2022 17:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="2/3"
                                processing={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              20/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 12:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="1/3"
                                processing={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              05/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 12:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="2/3"
                                processing={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              05/20/2021 11:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 17:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="3/3"
                                completed={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              11/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 17:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="3/3"
                                completed={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              11/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 17:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="3/3"
                                completed={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              11/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              01/04/2022 17:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="2/3"
                                processing={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              20/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 12:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="1/3"
                                processing={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              05/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 12:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="2/3"
                                processing={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              05/20/2021 11:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 17:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="3/3"
                                completed={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              11/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                          <TableRow>
                            <CustomTableCell component="td" scope="row">
                              Perimeter Tour
                            </CustomTableCell>
                            <CustomTableCell>
                              03/04/2022 17:08:41
                            </CustomTableCell>
                            <CustomTableCell>
                              <Chip
                                size="small"
                                label="3/3"
                                completed={+true}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              11/20/2021 17:07:40
                            </CustomTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LiveModuleModal;

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  Typography,
  Card,
  CardContent,
  Box,
  CardMedia,
  CircularProgress,
  Paper,
} from "@mui/material";
import Barcode from "react-barcode";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { display } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const PrintVehiclePass = ({
  printVehicalPassDialog,
  printVehicalPassClose,
  //   barCodeId,
  visitorVehicleBadges,
  addVisitorKey,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const authEndDate = new Date(
    visitorVehicleBadges?.visitor_auth_end_date
  ).toLocaleDateString();
  const redirectUrl = "/visitors/list-visitors";

  const [hideButton, setHideButtons] = useState(false);
  const [loading, setLoading] = useState(false); //State for loader

  const checkButton = () => {
    setLoading(true);
    setHideButtons(true);
    setTimeout(() => {
      setLoading(false);
      window.print();
      setHideButtons(false);
    }, 2000);
  };
  return (
    <>
      <Dialog
        aria-labelledby="print-badge"
        aria-describedby="print-badge-desc"
        open={printVehicalPassDialog}
        onClose={printVehicalPassClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="print-badge"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Print Vehicle Pass
          </Typography>
          {hideButton == false && (
            <DialogActions>
              <IconButton
                onClick={() => {
                  if (addVisitorKey) {
                    navigate(redirectUrl);
                  } else {
                    printVehicalPassClose();
                  }
                }}
                sx={{ marginRight: "-10px" }}
              >
                <CancelRoundedIcon />
              </IconButton>
            </DialogActions>
          )}
        </DialogTitle>
        <DialogContent>
          {visitorVehicleBadges.map((item) => {
            return (
              <>
                <div>
                  <Card sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="h2" variant="subtitle1">
                          Parking Pass
                        </Typography>
                        <Typography component="h2" variant="subtitle1">
                          {item?.propertyData.LocationName}
                        </Typography>

                        <Typography variant="h3" component="div" gutterBottom>
                          {item?.name}
                        </Typography>
                        <Typography>
                          {item?.visitor_type?.visitor_types}
                        </Typography>
                        <Typography>
                          {item?.visitor_company?.CompanyName}
                        </Typography>
                        <Box
                          sx={{
                            width: 80,
                            mx: "auto",
                            "& svg": {
                              width: "80px",
                              height: "auto",
                            },
                          }}
                        >
                          <Barcode
                            value={item.id}
                            // lineColor="#FF0000"
                            background="#FFFFFF"
                          />
                        </Box>
                        <Box sx={{ display: "flex", gap: "20px" }}>
                          <Typography>Issued:</Typography>
                          <Typography>
                            {item?.vehicle_make} {item?.vehicle_model}
                          </Typography>
                          <Typography>Expires:</Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "20px" }}>
                          <Typography>
                            {new Date(
                              item?.visitor_auth_start_date
                            ).toLocaleDateString()}
                          </Typography>
                          <Typography>
                            {item?.vehicle_license_number}
                          </Typography>
                          <Typography>
                            {new Date(
                              item?.visitor_auth_end_date
                            ).toLocaleDateString()}
                          </Typography>
                        </Box>
                        <Typography>
                          Observe all posted signs. Improperly parked vehicles
                          may be towed.
                        </Typography>
                      </CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          pl: 1,
                          pb: 1,
                        }}
                      ></Box>
                    </Box>
                  </Card>

                  <DialogContentText sx={{ textAlign: "center", py: 3 }}>
                    {item.visitor_escort == "Yes" ? "Escort Required" : null}
                  </DialogContentText>
                </div>
              </>
            );
          })}
        </DialogContent>
        {hideButton == false && (
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={printVehicalPassClose}
              sx={{ mx: 3 }}
            >
              Close
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => checkButton()}
            >
              Send to Printer
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default PrintVehiclePass;

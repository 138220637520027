export default [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "arrival_date",
    label: "Arrival Date",
  },
  {
    id: "departure_date",
    label: "Departure Date",
  },
  {
    id: "visitor_type",
    label: "Type",
  },
  {
    id: "room_area",
    label: "Room/Area",
  },
  {
    id: "caretaker",
    label: "Caretaker",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "special_instructions",
    label: "Special Instruction",
  },
  {
    id: "action",
    label: "Action",
  },
];

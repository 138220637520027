import React, { useEffect, useState } from "react";
import moment from "moment";
import { LostfoundSubItems, LostfoundUnmerge } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import CustomAlert from "../../../components/form-components/CustomAlert";
import DialogBox from "../../../components/form-components/DialogBox";
import SubListingAction from "../inner-component/SubListingAction";

import {
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Menu,
  CircularProgress,
} from "@mui/material";

const SubItems = ({ id, setSubItemCount }) => {
  const [itemList, setItemList] = React.useState([]);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [selectedId, setSelectedId] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  // Alert Message
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const [showAlert, setShowAlert] = React.useState(false);

  const removeRecords = () => {
    setShowDeleteModal(true);
  };
  const handelDeleteAction = (status) => {
    if (status === true) {
      LostfoundUnmerge(selectedId)
        .then((res) => {
          if (res.statusCode == 200) {
            setShowDeleteModal(false);
            setAlertMessage(res.message);
            setAlertType("success");
            setShowAlert(true);
            LostfoundSubItemsList();
          } else {
            setShowDeleteModal(false);
            setAlertMessage(res.message);
            setAlertType("error");
            setShowAlert(true);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setShowDeleteModal(false);
            setAlertMessage(error);
            setAlertType("error");
            setShowAlert(true);
          }
        });
    } else {
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    LostfoundSubItemsList();
  }, []);

  const LostfoundSubItemsList = () => {
    setLoading(true);
    LostfoundSubItems(id)
      .then((res) => {
        if (res.statusCode == 200) {
          setItemList(res.data);
          setSubItemCount(res.data.length);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Paper>
        <DialogBox
          handleShow={showDeleteModal}
          message={"Are you sure you want to unmerge?"}
          conformation={(status) => handelDeleteAction(status)}
        />
        {showAlert && (
          <CustomAlert
            message={alertMessage}
            severity={alertType}
            closeAlert={(show) => setShowAlert(show)}
            showAlert={showAlert}
          />
        )}
        <TableContainer
          className="tableContainer"
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Control ID</TableCell>
                <TableCell>Lost Datetime</TableCell>
                <TableCell>Found Datetime</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Found By</TableCell>
                <TableCell>Claim By</TableCell>
                <TableCell>Found Location</TableCell>
                <TableCell>Storage Location</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={10} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {itemList.map((row, index) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>
                        {row.lost_date
                          ? moment(row.lost_date).format("YYYY-MM-DD HH:mm:ss")
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {row.found_date
                          ? moment(row.found_date).format("YYYY-MM-DD HH:mm:ss")
                          : "-"}
                      </TableCell>
                      <TableCell>{row.category ? row.category : "-"}</TableCell>
                      <TableCell>
                        {row.description ? row.description : "-"}
                      </TableCell>
                      <TableCell>{row.found_by ? row.found_by : "-"}</TableCell>
                      <TableCell>
                        {row.claim_name ? row.claim_name : "-"}
                      </TableCell>
                      <TableCell>
                        {row.found_location ? row.found_location : "-"}
                      </TableCell>
                      <TableCell>
                        {row.storage_location ? row.storage_location : "-"}
                      </TableCell>
                      <TableCell align="left">
                        <SubListingAction
                          data={row}
                          setRecordId={(value) => setSelectedId(value)}
                          removeRecords={() => removeRecords()}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {itemList.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default SubItems;

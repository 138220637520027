import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packageAuditList: {
    auditor: true,
    date: true,
    storage_location: true,
    package_count: true,
    unaccounted: true,
    action: true,
  },
  packagesList: {
    id: true,
    carrier: true,
    sender_name: true,
    recipient: true,
    group: true,
    tracking_number: true,
    package_status: true,
    storage_location: true,
    package_arrival_date: true,
    delivery_datetime: true,
    action: true,
  },
  tenantList: {
    company_name: true,
    admin_name: true,
    admin_email: true,
    tenant_phone: true,
    status: true,
    action: true,
  },
};

export const packagesTableColSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    setPackageAuditList: (state, action) => {
      state.packageAuditList = action.payload;
    },
    setPackagesList: (state, action) => {
      state.packagesList = action.payload;
    },
    setTenantList: (state, action) => {
      state.tenantList = action.payload;
    },
  },
});

export const { setPackageAuditList, setPackagesList, setTenantList } =
  packagesTableColSlice.actions;

export default packagesTableColSlice.reducer;

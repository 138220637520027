import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import AddEditAed from "./Aeds-inner-component/AddEditAed";
import AedLocations from "./Aeds-inner-component/AedLocationsNew";
import UpdatePadsBatteryExpired from "./UpdatePadsBatteryExpired";
import {
  Checkbox,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Pagination,
  Select,
  Typography,
  FormControl as MuiFormControl,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { makeStyles } from "@mui/styles";
import {
  AedInventoryListing,
  ViewAedsInventoryInfo,
  DeleteAedInventory,
} from "../../../api";
import Toaster from "../../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { handleApiResonseErrors } from "../../../helper/helper";
const Paper = styled(MuiPaper)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #0000000f",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  uid,
  location,
  make,
  model,
  serial,
  padexpiry,
  batteryexpiry,
  action
) {
  return {
    uid,
    location,
    make,
    model,
    serial,
    padexpiry,
    batteryexpiry,
    action,
  };
}
const rows = [
  createData(
    "34678555",
    "Tr",
    "Defibtech",
    "Lifeline & Reviewer",
    "32323",
    "06/17/2022",
    "06/23/2022",
    0
  ),
  createData(
    "34678556",
    "Tr",
    "Defibtech",
    "Lifeline & Reviewer",
    "32323",
    "06/17/2022",
    "06/23/2022"
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "uid",
    numeric: true,
    disablePadding: true,
    label: "UID",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "make",
    numeric: true,
    disablePadding: false,
    label: "Make",
  },
  {
    id: "model",
    numeric: true,
    disablePadding: false,
    label: "Model",
  },
  {
    id: "serial",
    numeric: true,
    disablePadding: false,
    label: "Serial",
  },
  {
    id: "padexpiry",
    numeric: true,
    disablePadding: false,
    label: "Pad Expiry",
  },
  {
    id: "batteryexpiry",
    numeric: true,
    disablePadding: false,
    label: "Battery Expiry",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {/* {headCell.icon} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const classes = useStyles();
  //States related to Listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [aedInventoryRecords, setAedInventoryRecords] = useState([]);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [pageLoad, setPageLoad] = useState(false);
  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const [aedsInventId, setAedsInventId] = useState(null);
  // const [editAedData, setEditAedData] = useState({});
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const [isEdit, setIsEdit] = useState(false);
  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = aedInventoryRecords.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, uid) => {
    const selectedIndex = selected.indexOf(uid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (uid) => selected.indexOf(uid) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, aedId) => {
    setAnchorEl(event.currentTarget);
    setAedsInventId(aedId);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // Add Edit Aed Modal
  const [addEditAedManagement, setAddEditAedManagement] = useState(false);
  const addEditAedManagementOpen = () => {
    setAddEditAedManagement(true);
    setIsEdit(false);
  };
  const addEditAedManagementClose = () => {
    setAddEditAedManagement(false);
    // setEditAedData({});
  };
  // Aed Locations
  const [aedLocationsModal, setAedLocationsModal] = useState(false);
  const aedLocationModalOpen = () => {
    setAedLocationsModal(true);
  };
  const aedLocationModalClose = () => {
    setAedLocationsModal(false);
  };

  // Update Pads And Battery Expired
  const [updatePadsBatteryExpiredDialog, setupdatePadsBatteryExpiredDialog] =
    useState(false);
  const updatePadsBatteryExpiredDialogOpen = () => {
    setupdatePadsBatteryExpiredDialog(true);
  };
  const updatePadsBatteryExpiredDialogClose = () => {
    setupdatePadsBatteryExpiredDialog(false);
  };

  const getAedsInventoryList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
    };
    AedInventoryListing(currentPage, { params: payload })
      .then((res) => {
        if (res.statusCode == 200) {
          setAedInventoryRecords(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setAedInventoryRecords(res.data);
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setTotalRecord(0);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  //Method for getting data for involved person
  const getAedInventoryRecord = () => {
    setPageLoad(true);
    ViewAedsInventoryInfo(aedsInventId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          // setEditAedData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //Method for deleting record
  const deleteAedInventory = () => {
    handleDeleteClose();
    DeleteAedInventory(aedsInventId)
      .then((res) => {
        if (res.statusCode == 200) {
          getAedsInventoryList();
          dispatch(getMsg("AED has been deleted"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        // console.log("err");
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setAedsInventId(null);
  };

  useEffect(() => {
    getAedsInventoryList();
  }, [rowsPerPage, currentPage]);

  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this AED ?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={(handleDeleteClose, deleteAedInventory)}
            autoFocus
            color="primary"
            sx={{ ml: "10px" }}
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Paper mt={3}>
        <Toolbar>
          <div>
            <IconButton color="primary" onClick={addEditAedManagementOpen}>
              <AddIcon />
            </IconButton>
          </div>
          <Spacer />
          <div>
            {totalRecord > 0 && (
              <Button
                color="primary"
                variant="contained"
                sx={{ whiteSpace: "nowrap" }}
                onClick={aedLocationModalOpen}
              >
                AED Maps
              </Button>
            )}
          </div>
        </Toolbar>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={aedInventoryRecords.length}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(aedInventoryRecords, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                        >
                          {row.uid}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.location}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.make}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.model}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.serial}
                        </TableCell>
                        <TableCell
                          align="left"
                          color="error"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {row.pad_expiry}
                        </TableCell>
                        <TableCell
                          align="left"
                          color="error"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {row.battery_expiry}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleActionClick(e, row.id);
                            }}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                addEditAedManagementOpen();
                                // getAedInventoryRecord();
                                setIsEdit(true);
                                handleActionClose();
                              }}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={(handleActionClose, handleDeleteOpen)}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {totalRecord === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                    <TableCell colSpan={9} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {selected.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "15px",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={updatePadsBatteryExpiredDialogOpen}
            >
              Bulk Edit
            </Button>
          </Box>
        )}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {updatePadsBatteryExpiredDialog && (
        <UpdatePadsBatteryExpired
          updatePadsBatteryExpiredDialog={updatePadsBatteryExpiredDialog}
          setupdatePadsBatteryExpiredDialog={setupdatePadsBatteryExpiredDialog}
          updatePadsBatteryExpiredDialogOpen={
            updatePadsBatteryExpiredDialogOpen
          }
          updatePadsBatteryExpiredDialogClose={
            updatePadsBatteryExpiredDialogClose
          }
          selected={selected}
          setPageLoad={setPageLoad}
        />
      )}
      {addEditAedManagement && (
        <AddEditAed
          addEditAedManagement={addEditAedManagement}
          setAddEditAedManagement={setAddEditAedManagement}
          addEditAedManagementOpen={addEditAedManagementOpen}
          addEditAedManagementClose={addEditAedManagementClose}
          setPageLoad={setPageLoad}
          getAedsInventoryList={getAedsInventoryList}
          // editAedData={editAedData}
          aedsInventId={aedsInventId}
          isEdit={isEdit}
        />
      )}
      {aedLocationsModal && (
        <AedLocations
          title="AED Locations"
          aedLocationsModal={aedLocationsModal}
          setAedLocationsModal={setAedLocationsModal}
          aedLocationModalOpen={aedLocationModalOpen}
          aedLocationModalClose={aedLocationModalClose}
          data={aedInventoryRecords}
        />
      )}
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
    </div>
  );
}

const Aeds = () => {
  return (
    <>
      <EnhancedTable />
    </>
  );
};

export default Aeds;

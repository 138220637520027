export default [
  {
    id: "equipment_id",
    label: "Equipment ID",
  },
  {
    id: "equipment_type",
    label: "Equipment Type",
  },
  {
    id: "equipment_name",
    label: "Equipment Name",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React from "react";
import styled, { css } from "styled-components/macro";
import { rgba } from "polished";
import Gauge from "./Gauge";
import SovaScore from "../../../components/dashboard/sovaScore";
import CustomGauge from "../../../components/blocks/dashboard/customGauge";
import { buildStyles } from "react-circular-progressbar";
import { NavLink } from "react-router-dom";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== "dark" &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 10px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const Infocards = ({
  title,
  chip,
  illustration,
  revScore,
  progressPercent,
  percentagecolor,
  linkTo,
  sx,
}) => {
  const strokeSize = 9;

  return (
    <Card illustration={illustration} sx={sx ? sx : ""}>
      <CardContent component={NavLink} to={linkTo}>
        <Typography variant="div" mb={4}>
          {title}
        </Typography>
        {/* <SovaScore /> */}
        <CustomGauge
          progressPercent={progressPercent}
          strokeSize={strokeSize}
          centerLabel={revScore}
          circularProgressStyles={buildStyles({
            rotation: 0.67,
            strokeLinecap: "round",
            trailColor: "#0B1D26",
            pathColor: percentagecolor,
            pathTransitionDuration: 2,
          })}
        />
        {/* {!illustration && <Chip label={chip} />} */}
      </CardContent>
    </Card>
  );
};

export default Infocards;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { spacing } from "@mui/system";
import {
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl as MuiFormControl,
  Pagination,
} from "@mui/material";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const CustomPagination = ({
  handleChangeLimit,
  handleChangePagination,
  rowsPerPage,
  currentPage,
  totalRecord,
}) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "8px",
        }}
      >
        <Pagination
          count={
            Math.ceil(totalRecord / rowsPerPage) > 0
              ? Math.ceil(totalRecord / rowsPerPage)
              : 1
          }
          page={currentPage}
          boundaryCount={2}
          onChange={handleChangePagination}
          color="primary"
        />

        <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ marginRight: "8px" }}>
              Records per page:
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rowsPerPage}
              onChange={handleChangeLimit}
            >
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </Box>
        </FormControl>
      </Box>
    </React.Fragment>
  );
};

export default CustomPagination;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import moment from "moment";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import {
  ListEmployee,
  DeleteEmployee,
  DepartmentList,
  PositionByDepartment,
} from "../../api";
import { handleApiResonseErrors } from "../../helper/helper";
import CustomAlert from "../../components/form-components/CustomAlert";
import { useSelector, useDispatch } from "react-redux";
import { updateShowAlert } from "../../redux/slices/mainSlice";
import DialogBox from "../../components/form-components/DialogBox";
import EmployeeListingCol from "../table-columns-json/logs/employees/EmployeeListing";
import { setEmployeeListing } from "../../redux/slices/logs/logTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";

import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  InsertDriveFile as InsertDriveFileIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionHide from "../../utils/withPermissionHide";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 12px auto 0;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "465px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(barcode, fname, lname, department, position, action) {
  return {
    barcode,
    fname,
    lname,
    department,
    position,
    action,
  };
}

const headCells = [
  {
    id: "barcode",
    numeric: true,
    disablePadding: true,
    label: "Bardcode ID",
  },
  {
    id: "fname",
    numeric: true,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lname",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "department",
    numeric: true,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    tableCalRedux,
    numSelected,
    rowCount,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {EmployeeListingCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "action" ? null : createSortHandler(headCell.id)
              }
              hideSortIcon={headCell.id == "action" ? true : false}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    tableCalRedux,
    filterRecord,
    totalRecordinDb,
    removeRecords,
  } = props;
  const [actions, setActions] = useState("");

  const [fadebox, setFadedBox] = useState(false);

  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [searchUserName, setSearchUserName] = useState("");
  const [name, setName] = useState("");
  const [employeeId, setEmployeeId] = useState("");

  const [isFilter, setIsFilter] = useState(false);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  // Check for permission for specific actions
  const hasPermissionToDeleteEmployee = permissions.some(
    (perm) =>
      perm.controller === "Logs" &&
      perm.action_name === "deleteEmployee" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.employeeListing
  );
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };
  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [dateRange, setDateRange] = useState(null);

  const [anchorColumn, setAnchorColumn] = useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  const [department, setDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const selectDepartment = (event) => {
    setDepartment(event.target.value);
  };
  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const [position, setPosition] = React.useState("");
  const [positionList, setPositionList] = useState([]);
  const selectPosition = (event) => {
    setPosition(event.target.value);
  };

  const getPositionList = (value) => {
    // setLoading(true);
    PositionByDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    setSearchUserName("");
    setName("");
    setEmployeeId("");
    setDepartment("");
    setPosition("");
    filterRecord({});
  };

  const applySearch = () => {
    const payload = {
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      search: searchUserName,
      name: name,
      employee_id: employeeId,
      department: department,
      position: position,
    };
    filterRecord(payload);
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem
                  disabled={!hasPermissionToDeleteEmployee}
                  value={"Delete"}
                  onClick={removeRecords}
                >
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecordinDb <= 1
              ? `${totalRecordinDb} record found`
              : `${totalRecordinDb} records found`}
          </Typography>
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                type="text"
                placeholder="Search by name or click down arrow"
                value={searchUserName}
                autoComplete="off"
                inputProps={{}}
                onChange={(e) => {
                  setSearchUserName(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                    setIsFilter(true);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="employeeId"
                                label="Employee ID"
                                value={employeeId}
                                onChange={(e) => {
                                  setEmployeeId(e.target.value);
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Corporate ID"
                              />
                            </FormControl>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="name"
                                label="Name"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Mike Smith"
                              />
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="department">
                                Department
                              </InputLabel>
                              <Select
                                labelId="department"
                                id="department"
                                value={department}
                                label="Department"
                                onChange={selectDepartment}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select Department</MenuItem>
                                {departmentList.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item.key}
                                      key={item.value}
                                      onClick={() =>
                                        getPositionList(item.value)
                                      }
                                    >
                                      {item.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="position">
                                Position
                              </InputLabel>
                              <Select
                                labelId="position"
                                id="position"
                                value={position}
                                label="Position"
                                onChange={selectPosition}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">
                                  {" "}
                                  {department != ""
                                    ? "Select Position"
                                    : "Select Department First"}
                                </MenuItem>
                                {positionList.map((item) => {
                                  return (
                                    <MenuItem value={item.key} key={item.value}>
                                      {item.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="dateRange"
                                label="Date range"
                                value={
                                  startDateToFilter && endDateToFilter
                                    ? moment(startDateToFilter).format(
                                        "MM-DD-YYYY"
                                      ) +
                                      " - " +
                                      moment(endDateToFilter).format(
                                        "MM-DD-YYYY"
                                      )
                                    : ""
                                }
                                onClick={() => setFadedBox((prev) => !prev)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Click to select date range"
                              />
                              {fadebox && (
                                <CustomDateRange
                                  handleShow={fadebox}
                                  conformation={(status) =>
                                    setFadedBox((prev) => !prev)
                                  }
                                  setDates={(start, end) => {
                                    setStartDateToFilter(start);
                                    setEndDateToFilter(end);
                                  }}
                                  selectedStart={startDateToFilter}
                                  selectedEnd={endDateToFilter}
                                />
                              )}
                            </FormControl>
                            <Box>
                              <Button
                                color="primary"
                                variant="contained"
                                ml={3}
                                onClick={(e) => {
                                  handleCloseForm();
                                  applySearch();
                                  setIsFilter(true);
                                }}
                              >
                                Filter
                              </Button>
                            </Box>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    setIsFilter(false);
                    clearFilter();
                  }}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                    width: "100%",
                  }}
                >
                  Clear
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={(e) => {
                    if (searchUserName !== "") {
                      applySearch();
                      setIsFilter(true);
                    }
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <WithPermissionHide controller="Logs" action="addEmployee">
                <MenuItem component={NavLink} to="/logs/add-employee">
                  <ListItemIcon>
                    <AddBoxIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Add Employee" pl={0} />
                </MenuItem>
              </WithPermissionHide>
              {/* <MenuItem>
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Download CSV" pl={0} />
              </MenuItem> */}
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setEmployeeListing(data));
                      }}
                      listing={EmployeeListingCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setEmployeeListing(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
    </>
  );
};
function EnhancedTable(props) {
  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const showAlertText = useSelector((state) => state.main.text);
  const tableCalRedux = useSelector(
    (state) => state.logTableCol.employeeListing
  );
  const dispatch = useDispatch();

  const [dataToFilter, setDataToFilter] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  // for listing
  const [lists, setLists] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecordinDb, setTotalRecordinDb] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("color");
  const [loading, setLoading] = useState(false);
  // Alert Message
  const [alertMessage, setAlertMessage] = useState(showAlertText);
  const [alertType, setAlertType] = useState("success");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, barcode) => {
    const selectedIndex = selected.indexOf(barcode);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, barcode);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lists.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (barcode) => selected.indexOf(barcode) !== -1;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    // setSelectedId("");
  };

  const showAlertModal = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  useEffect(() => {
    getEmployeeList();
    setTimeout(() => {
      dispatch(updateShowAlert(false));
    }, 3000);
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter]);

  const getEmployeeList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    ListEmployee(currentPage + 1, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLists(res.data);
          setTotalRecordinDb(res.meta.total);
        } else {
          setLists([]);
          setTotalRecordinDb(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          showAlertModal("error", error);
        }
      });
  };

  const removeRecords = () => {
    if (selected.length > 0 || selectedId) {
      setModalMessage("Are you sure you want to delete?");
    } else {
      setModalMessage("You haven't selected any item!");
    }
    setShowDeleteModal(true);
    handleActionClose();
  };

  const handelDeleteAction = (status) => {
    if (status === true) {
      let url = selected.length > 0 ? selected : selectedId;

      setLoading(true);
      DeleteEmployee(url)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            getEmployeeList();
          }
          showAlertModal("success", "Record removed successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setShowDeleteModal(false);
    }
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          tableCalRedux={tableCalRedux}
          filterRecord={(data) => filterRecord(data)}
          totalRecordinDb={totalRecordinDb}
          removeRecords={removeRecords}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={lists.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={10} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {lists.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ lg: 'whiteSpace: "nowrap"' }}
                        className={
                          tableCalRedux.employee_id === true ? "" : "hide-cell"
                        }
                      >
                        {row.employee_id}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.fname === true ? "" : "hide-cell"
                        }
                      >
                        {row.fname}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.lname === true ? "" : "hide-cell"
                        }
                      >
                        {row.lname}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.department === true ? "" : "hide-cell"
                        }
                      >
                        {row.department}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.position === true ? "" : "hide-cell"
                        }
                      >
                        {row.position}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.action === true ? "" : "hide-cell"
                        }
                      >
                        <IconButton
                          aria-label="Settings"
                          size="large"
                          aria-owns={anchorEl ? "icon-menu" : undefined}
                          aria-haspopup="true"
                          onClick={(evt) => handleActionClick(evt, row.id)}
                          style={{ color: "#1374C5" }}
                        >
                          <SettingsIcon />
                        </IconButton>
                        <StyledMenu
                          id="icon-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleActionClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <WithPermissionHide
                            controller="Logs"
                            action="editEmployee"
                          >
                            <MenuItem
                              component={NavLink}
                              to={"/logs/edit-employee/" + selectedId}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                          </WithPermissionHide>
                          <WithPermissionHide
                            controller="Logs"
                            action="deleteEmployee"
                          >
                            <MenuItem onClick={() => removeRecords()}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </WithPermissionHide>
                        </StyledMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}

                {lists.length < 1 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * lists.length }}>
                    <TableCell colSpan={10} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={lists.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      <DialogBox
        handleShow={showDeleteModal}
        message={modalMessage}
        conformation={(status) => handelDeleteAction(status)}
      />
    </div>
  );
}

function Employees() {
  return (
    <React.Fragment>
      <Helmet title="Employees" />
      <WithPermissionFallback controller="Logs" action="employeeList">
        <Typography variant="h3" gutterBottom display="inline">
          Employees
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default Employees;

import React from "react";
import { Snackbar, Alert } from "@mui/material";

const CustomAlert = ({ message, severity, showAlert, closeAlert }) => {
  const handleErrorSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeAlert(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={handleErrorSnackClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleErrorSnackClose}
          severity={severity}
          variant="filled"
          sx={{ maxWidth: 600 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default CustomAlert;

import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import SettingsMenu from "./SettingsMenu";
import QuickLinks from "./QuickLinks";
import LocationHeader from "./LocationHeader";
import Toaster from "../../pages/tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { getSnackClose } from "../../redux/slices/mainSlice";
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  border: 1px solid #9e9e9e;
  border-radius: 4px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  z-index: 1; /* Ensure it's on top */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  padding-left: 50px; /* Add padding to ensure the input doesn't overlap with the search icon */

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    width: 250px;
  }
`;
const Navbar = ({ onDrawerToggle }) => {
  const { t } = useTranslation();
  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const dispatch = useDispatch();

  //handle header search input
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchInput.trim() === "") {
      // setErrorMessage("Please enter a text to search.");
    } else {
      // Navigate to SearchPage.js with searchInput as URL parameter
      navigate(`/pages/search-page/${encodeURIComponent(searchInput)}`);
      setSearchInput("");
    }
  };

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
    setErrorMessage("");
  };

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Box sx={{ display: "flex" }}>
                {!showSearch && (
                  <IconButton onClick={() => setShowSearch(true)}>
                    <SearchIcon />
                  </IconButton>
                )}
                {showSearch && (
                  <Box>
                    <Search>
                      <SearchIconWrapper
                        sx={{ width: "280px" }}
                        onClick={handleSearch}
                      >
                        <SearchIcon />
                      </SearchIconWrapper>
                      <Input
                        placeholder={t("Search")}
                        value={searchInput}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                      />
                    </Search>
                    {errorMessage && (
                      <Typography variant="caption" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs />
            {/* <Grid item>
              <Temp />
            </Grid> */}
            <Grid item>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocationHeader />
                {/* <NavbarMessagesDropdown /> */}
                <NavbarNotificationsDropdown />
                <NavbarLanguagesDropdown />
                <SettingsMenu />
                <NavbarUserDropdown />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
    </React.Fragment>
  );
};

export default withTheme(Navbar);

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { sova } from "../../../../config";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Card,
  Tooltip,
  CardContent,
  CardMedia,
} from "@mui/material";

import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
// Image
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
`;

const mapStyles = {
  height: "100vh",
  width: "100%",
};

const AedLocations = ({
  title,
  aedLocationsModal,
  aedLocationModalClose,
  data,
}) => {
  const [selected, setSelected] = useState({});
  const [mapData, setMapData] = useState([]);
  const [defaultCenter, setDefaultcenter] = useState({
    lat: data.length > 0 ? data[0].location_lat : null,
    lng: data.length > 0 ? data[0].location_lon : null,
  });

  const onSelect = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    setMapData(data);
  }, [data]);
  return (
    <>
      <Dialog
        aria-labelledby="aed-locations-title"
        open={aedLocationsModal}
        onClose={aedLocationModalClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="aed-locations-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            {/* AED Locations */}
            {title}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={aedLocationModalClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <LoadScript googleMapsApiKey={sova.GoogleApiKey}>
            {mapData.length > 0 ? (
              <Card variant="outlined" sx={{ height: "450px" }}>
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={13}
                  center={defaultCenter}
                >
                  {data.map((item) => {
                    return (
                      <Marker
                        key={item.id}
                        position={{
                          lat: item.location_lat,
                          lng: item.location_lon,
                        }}
                        icon={`/static/img/heart.svg`}
                        onMouseOver={() => onSelect(item)}
                      />
                    );
                  })}
                  {selected.location_lat && (
                    <InfoWindow
                      position={{
                        lat: selected.location_lat,
                        lng: selected.location_lon,
                      }}
                      clickable={true}
                      onCloseClick={() => setSelected({})}
                    >
                      <Card sx={{ display: "flex" }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <ul style={{ paddingLeft: 10 }}>
                            <li>Location: {selected.location}</li>
                            <li>
                              Put in service date:{" "}
                              {selected.put_in_dervice_date}
                            </li>
                            <li>
                              Last Inspection: {selected.last_inspection_date}
                            </li>
                            <li>Pad Expiry: {selected.pad_expiry}</li>
                            <li>Battery Expiry: {selected.battery_expiry}</li>
                          </ul>
                        </CardContent>
                        <CardMedia
                          component="img"
                          sx={{ width: 151, height: 150 }}
                          image={selected.aed_image}
                          alt="No Image found"
                        />
                      </Card>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </Card>
            ) : (
              <Typography
                sx={{
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                No records found
              </Typography>
            )}
          </LoadScript>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AedLocations;

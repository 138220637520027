import React, { useState } from "react";
import styled from "styled-components/macro";
import CustomTextField from "../../components/form-components/CustomTextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  TextField,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import { handleApiResonseErrors } from "../../helper/helper";
import { UpdatePreventativeActionPlan } from "../../api";
import moment from "moment";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const UpdatePreventive = ({
  updatePreventiveDialog,
  setUpdatePreventiveDialog,
  editPrevData,
  setLoader,
  getPreventativeListData,
  id,
  updatePrevDialogClose,
}) => {
  const dispatch = useDispatch();
  let initialValues = {
    root_cause: editPrevData
      ? editPrevData.root_cause
        ? editPrevData.root_cause
        : ""
      : "",
    assigned_to: editPrevData
      ? editPrevData.assigned_to
        ? editPrevData.assigned_to
        : ""
      : "",
    date_assigned: editPrevData
      ? editPrevData.date_assigned
        ? editPrevData.date_assigned
        : null
      : null,
    preventive_action: editPrevData
      ? editPrevData.preventive_action
        ? editPrevData.preventive_action
        : ""
      : "",
    target_date: editPrevData
      ? editPrevData.target_date
        ? editPrevData.target_date
        : null
      : null,
    date_completed: editPrevData
      ? editPrevData.date_completed
        ? editPrevData.date_completed
        : null
      : null,
  };
  const validationSchema = Yup.object().shape({});
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoader(true);
      let formatedAssignedDate = values.date_assigned
        ? moment(values.date_assigned).format("YYYY-MM-DD")
        : "";
      let formatedTargetDate = values.target_date
        ? moment(values.target_date).format("YYYY-MM-DD")
        : "";
      let formatedCompletedDate = values.date_completed
        ? moment(values.date_completed).format("YYYY-MM-DD")
        : "";
      let formData = {
        id: editPrevData ? (editPrevData.id ? editPrevData.id : null) : null,
        root_cause: values.root_cause,
        assigned_to: values.assigned_to,
        date_assigned: formatedAssignedDate,
        preventive_action: values.preventive_action,
        target_date: formatedTargetDate,
        date_completed: formatedCompletedDate,
      };
      UpdatePreventativeActionPlan(formData)
        .then((res) => {
          setLoader(false);
          if (res.statusCode === 200) {
            getPreventativeListData();
            dispatch(getMsg(res.message));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            updatePrevDialogClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } catch (err) {
      console.log(err);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      setLoader(false);
    }
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        open={updatePreventiveDialog}
        onClose={() => updatePrevDialogClose()}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Update Preventive Action Plan</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => updatePrevDialogClose()}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            status,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl mt={3}>
                        <CustomTextField
                          label="Root Causes"
                          name="root_cause"
                          placeholder=""
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="assigned_to"
                          label="Assigned to"
                          placeholder=""
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl mt={3}>
                        <DatePicker
                          id="date-assigned"
                          label="Date assigned"
                          value={values.date_assigned}
                          onChange={(newValue) => {
                            setFieldValue("date_assigned", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="date_assigned"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm/dd/yy",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl>
                        <CustomTextField
                          name="preventive_action"
                          label="Corrective Action"
                          placeholder=""
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl>
                        <DatePicker
                          id="target-date"
                          label="Target Date"
                          value={values.target_date}
                          onChange={(newValue) => {
                            setFieldValue("target_date", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="target_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm/dd/yy",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl>
                        <DatePicker
                          id="date-completed"
                          label="Date Completed"
                          value={values.date_completed}
                          onChange={(newValue) => {
                            setFieldValue("date_completed", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="date_completed"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm/dd/yy",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button type="submit" color="primary" variant="contained">
                    Update
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default UpdatePreventive;

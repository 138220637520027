import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { ViewVisitor } from "../../api";
import KioskModeSubmitPassword from "./KioskModeSubmitPassword";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
}));
const Image = styled.img`
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  width: 44px;
  height: 44px;
`;
function VisitorDetailCard() {
  const classes = useStyles();
  const { visitorId } = useParams();
  const navigate = useNavigate();
  const locationDetail = localStorage.getItem("location");
  const name = JSON.parse(locationDetail);

  const [openDialog, setOpenDialog] = useState(false);
  const [visitorDetail, setVisitorDetail] = useState({});
  const [visitorHost, setVisitorHost] = useState("");

  const [kioskModeSubmitPasswordModal, setKioskModeSubmitPasswordModal] =
    useState(false);
  const kioskModeSubmitPasswordModalClose = () => {
    setKioskModeSubmitPasswordModal(false);
  };

  const viewVisitorDetail = () => {
    let payload = {
      visitor_id: visitorId,
    };
    ViewVisitor(payload).then((response) => {
      console.log("response", response);
      if (response.statusCode == 200) {
        setVisitorDetail(response.data);
      }
      console.log("something went wrong!");
    });
  };

  const selectVisitorHost = (name) => {
    setVisitorHost(name);
    setOpenDialog(false);
  };
  useEffect(() => {
    viewVisitorDetail();
  }, [visitorId]);

  const launchFullScreen = () => {
    if (!document.fullscreenElement) {
      console.log("iffff");
      document.documentElement.requestFullscreen();
      localStorage.setItem("fullscreen", "true");
    }
  };
  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setKioskModeSubmitPasswordModal(true);
    }
  }

  const updateParentState = () => {
    kioskModeSubmitPasswordModalClose();
  };

  return (
    <>
      <Box sx={{ p: 5, my: "auto" }} className={classes.startOverCard}>
        <Card sx={{ maxWidth: "1140px", mx: "auto" }}>
          <CardHeader
            title={
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    {name.LocationName}
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    Returning Visitor
                  </Typography>
                </Box>
              </>
            }
          />
          <CardContent>
            <Box sx={{ maxWidth: 800, ml: "auto", mr: "auto" }}>
              <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
                  <Typography gutterBottom>Welcome Back!</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={5} textAlign="center">
                  <Image
                    src={`/static/img/avatars/avatar-1.jpg`}
                    style={{ width: 200, height: 200 }}
                  />
                  <Box sx={{ mt: 4 }}>
                    <Button color="primary" variant="outlined">
                      Take Photo
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "16px !important",
                    },
                  }}
                >
                  <Typography variant="body2" gutterBottom>
                    <strong>Name:</strong> {visitorDetail.name}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Company Name:</strong> {visitorDetail.company_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      "& .MuiButtonBase-root:hover": {
                        background: "transparent",
                      },
                    }}
                  >
                    <strong>Visiting:</strong>{" "}
                    {visitorHost ? visitorHost : visitorDetail.host_name}
                    <Button
                      onClick={() => setOpenDialog(true)}
                      color="primary"
                      variant="text"
                      sx={{ p: 0, ml: 3 }}
                    >
                      Tap to choose host
                    </Button>
                  </Typography>
                  <Typography color="primary">
                    <small style={{ lineHeight: 1.2 }}>
                      Automatic check in not available. Please contact you host
                      or stop by security office. Thank You!
                    </small>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "space-between" }}>
            <IconButton
              disableRipple={true}
              color="primary"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosIcon fontSize="medium" />
            </IconButton>
            <Button component={NavLink} to="/visitors/kiosk-mode">
              Start over
            </Button>
            <IconButton
              disableRipple={true}
              color="primary"
              component={NavLink}
              to="/visitors/confirm-returning-visitors"
              state={{
                visitorDetails: visitorDetail,
                visitorHost: visitorHost,
              }}
            >
              <ArrowForwardIosIcon fontSize="medium" />
            </IconButton>
          </CardActions>
        </Card>
      </Box>

      {/* Dialog starts here */}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800",
            },
          },
        }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Tap to select host from list below</Typography>
          <Button
            color="warning"
            variant="outlined"
            onClick={() => setOpenDialog(false)}
          >
            Skip
          </Button>
        </DialogTitle>
        <DialogContent>
          <TableContainer
            className="tableContainer"
            // component={Paper}
            sx={{
              border: "1px solid #f1f1f1",
              margin: "15px auto auto",
              "&::-webkit-scrollbar": {
                width: 7,
                height: 7,
              },
              "&::-webkit-scrollbar-track": {
                background: "#bed2f5",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#92a6c9",
              },
            }}
          >
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Image</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "5px 16px",
                  },
                }}
              >
                {visitorDetail?.visitorHosts?.map((item, index) => (
                  <TableRow
                    hover
                    key={index}
                    onClick={() => {
                      selectVisitorHost(item.name);
                    }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.department?.department_name}</TableCell>
                    <TableCell>
                      <Image src={`/static/img/avatars/avatar-1.jpg`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ paddingRight: 5 }}>
          <Button autoFocus onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog ends here */}

      {kioskModeSubmitPasswordModal && (
        <KioskModeSubmitPassword
          kioskModeSubmitPasswordModal={kioskModeSubmitPasswordModal}
          setKioskModeSubmitPasswordModal={setKioskModeSubmitPasswordModal}
          kioskModeSubmitPasswordModalClose={kioskModeSubmitPasswordModalClose}
          launchFullScreen={launchFullScreen}
          onDataFromChild={updateParentState}
        />
      )}
    </>
  );
}
function VisitorDetail() {
  return (
    <React.Fragment>
      <Helmet title="Returning Visitors" />
      <VisitorDetailCard />
    </React.Fragment>
  );
}
export default VisitorDetail;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tourPatrolData: [],
  nonTourTaskData: [],
  dailyActivityData: [],
  userPerformanceData: [],
  passdownData: [],
  buttonHistoryData: [],
  tourSummaryData: [],
  tourMissedData: [],
  smsUsageData: [],
  deviceReportData: [],
  sendIrLog: [],
  incidentsData: [],
  snackStatus: false,
  snackMsg: "",
  snackAlert: "",
  tourPatrolChecked: [],
  nonTourTaskChecked: [],
  dailyActivityChecked: [],
  performanceChecked: [],
  passdownChecked: [],
  buttonHistoryChecked: [],
  tourSummaryChecked: [],
  missedButtonChecked: [],
  smsReportChecked: [],
  deviceReportChecked: [],
  incidentDisabled: false,
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    tourPatrolList: (state, action) => {
      state.tourPatrolData = action.payload;
    },
    getSnackOpen: (state) => {
      state.snackStatus = true;
    },
    getSnackClose: (state) => {
      state.snackStatus = false;
    },
    getSnackMsg: (state, action) => {
      state.snackMsg = action.payload;
    },
    getSnackAlert: (state, action) => {
      state.snackAlert = action.payload;
    },
    nonTourTaskList: (state, action) => {
      state.nonTourTaskData = action.payload;
    },
    dailyActivityList: (state, action) => {
      state.dailyActivityData = action.payload;
    },
    getTourPatrolChecked: (state, action) => {
      state.tourPatrolChecked = action.payload;
    },
    getNonTourTaskChecked: (state, action) => {
      state.nonTourTaskChecked = action.payload;
    },
    getDailyActivityChecked: (state, action) => {
      state.dailyActivityChecked = action.payload;
    },
    userPerformanceList: (state, action) => {
      state.userPerformanceData = action.payload;
    },
    getPerformanceChecked: (state, action) => {
      state.performanceChecked = action.payload;
    },
    passdownList: (state, action) => {
      state.passdownData = action.payload;
    },
    getPassdownChecked: (state, action) => {
      state.passdownChecked = action.payload;
    },
    buttonHistoryList: (state, action) => {
      state.buttonHistoryData = action.payload;
    },
    getButtonHistoryChecked: (state, action) => {
      state.buttonHistoryChecked = action.payload;
    },
    tourSummaryList: (state, action) => {
      state.tourSummaryData = action.payload;
    },
    getTourSummaryChecked: (state, action) => {
      state.tourSummaryChecked = action.payload;
    },
    tourMissedList: (state, action) => {
      state.tourMissedData = action.payload;
    },
    getMissedButtonChecked: (state, action) => {
      state.missedButtonChecked = action.payload;
    },
    smsUsageList: (state, action) => {
      state.smsUsageData = action.payload;
    },
    getSmsReportChecked: (state, action) => {
      state.smsReportChecked = action.payload;
    },
    deviceReportList: (state, action) => {
      state.deviceReportData = action.payload;
    },
    getDeviceReportChecked: (state, action) => {
      state.deviceReportChecked = action.payload;
    },
    sendIrLogList: (state, action) => {
      state.sendIrLog = action.payload;
    },
    incidentsList: (state, action) => {
      state.incidentsData = action.payload;
    },
    changeDisabledStatus: (state, action) => {
      state.incidentDisabled = action.payload;
    },
  },
});

export const {
  tourPatrolList,
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
  nonTourTaskList,
  dailyActivityList,
  passdownList,
  getTourPatrolChecked,
  getNonTourTaskChecked,
  getDailyActivityChecked,
  userPerformanceList,
  getPerformanceChecked,
  getPassdownChecked,
  buttonHistoryList,
  getButtonHistoryChecked,
  tourSummaryList,
  getTourSummaryChecked,
  tourMissedList,
  getMissedButtonChecked,
  smsUsageList,
  getSmsReportChecked,
  deviceReportList,
  getDeviceReportChecked,
  sendIrLogList,
  incidentsList,
  changeDisabledStatus,
} = reportSlice.actions;

export default reportSlice.reducer;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Box,
  List,
  ListItem,
  Chip,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Table,
  Switch,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ModifyNavMenu as ModifyNavList, UpdateMenuOrder } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import MenuStatusToggle from "./inner-component/MenuStatusToggle";
const label = { inputProps: { "aria-label": "Switch demo" } };

const ModifyNavMenu = () => {
  const [navList, setNavList] = useState([]);
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const modifyNavList = () => {
    ModifyNavList()
      .then((res) => {
        if (res.statusCode === 200) {
          setNavList(res.data);
        } else {
          setNavList([]);
          console.log("Something went wrong");
        }
      })
      .catch((err) => {});
  };
  const updateMenuOrder = (items) => {
    let payload = {
      menu: items.map((item) => item.id),
    };
    console.log("item", payload.menu);
    UpdateMenuOrder(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Menu order has been updated"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    modifyNavList();
  }, []);
  console.log("befor", navList);
  const handleDragEnd = (result) => {
    console.log("after", navList);
    if (!result.destination) {
      return;
    }

    const items = Array.from(navList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateMenuOrder(items);
    setNavList(items);
  };
  console.log("last", navList);
  return (
    <>
      <Helmet title="Modify Nav Menu" />
      <Typography variant="h3" gutterBottom>
        Modify Nav Menu
      </Typography>
      <Box
        sx={{
          border: "1px solid rgb(225, 217, 217)",
          display: "flex",
          padding: "2px",
          marginTop: "20px",
          gap: "5",
        }}
      >
        <Chip
          onClick={() => {
            setMenu(false);
          }}
          label="Order"
          variant={menu ? "contained" : "outlined"}
          sx={{
            marginRight: "5px",
          }}
        />
        <Chip
          onClick={() => {
            setMenu(true);
          }}
          label="Visiblity"
          variant={menu ? "outlined" : "contained"}
          sx={{
            marginLeft: "5px",
          }}
        />
      </Box>
      {menu != true && (
        <Box
          sx={{
            border: "1px solid rgb(225, 217, 217)",
            padding: "10px",
          }}
        >
          <Typography variant="body2" gutterBottom>
            Using your mouse, drag and drop the menu items below to change the
            order. Refresh the screen to see the changes. Changes made here will
            affect all users.
          </Typography>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="menuList">
              {(provided) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ listStyleType: "none", padding: 0 }}
                >
                  {navList.map((row, index) => {
                    if (!(row.visible === 0)) {
                      return (
                        <Box
                          sx={{
                            margin: "5px",
                            background: "#00000014",
                            "&:hover": {
                              background: "#f7ecd7",
                            },
                          }}
                        >
                          <Draggable
                            key={row.id}
                            draggableId={row.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ListItem>{row.menu}</ListItem>
                              </li>
                            )}
                          </Draggable>
                        </Box>
                      );
                    }
                    return null;
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}
      {menu && (
        <Box
          sx={{
            border: "1px solid rgb(225, 217, 217)",
            padding: "10px",
          }}
        >
          <Typography variant="body2" gutterBottom>
            Toggle visibility of select menu items by changing the toggles
            below. Certain menus are required to be visible and do not have a
            toggle. Refresh the screen to see the changes. Changes made here
            will affect all users.
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Menu </TableCell>
                  <TableCell>Visible</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {navList.map((row, index) => (
                  <TableRow>
                    <TableCell>{row.menu}</TableCell>
                    <TableCell>
                      {!(
                        row.menu === "Dashboard" ||
                        row.menu === "Live Dispatch" ||
                        row.menu === "Reports" ||
                        row.menu === "Business Intel" ||
                        row.menu === "Settings"
                      ) && <MenuStatusToggle value={row.visible} id={row.id} />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default ModifyNavMenu;

import React from "react";
import Webcam from "react-webcam";
import { Cancel as CancelIcon } from "@mui/icons-material";
import styled from "styled-components/macro";
import "./camera-feed";
import {
  IconButton,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";

const CameraFeeding = ({
  closeWabCam,
  showWebCam,
  setUploadedImage,
  uploadedImage,
}) => {
  // const [uploadedImage, setUploadedImage] = React.useState(null);

  const videoConstraints = {
    width: 250,
    height: 200,
    facingMode: "user",
  };

  const Image = styled.img`
    max-width: initial;
    margin: 20px auto 0;
    width: 100%;
  `;

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "300px",
          },
        },
      }}
      open={showWebCam}
      onClose={closeWabCam}
      aria-labelledby="equipment-log"
      aria-describedby="equipment-log"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Get photo
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => closeWabCam()}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent className="camera-container">
        <Webcam
          audio={false}
          height={200}
          screenshotFormat="image/jpeg"
          width={250}
          videoConstraints={videoConstraints}
        >
          {({ getScreenshot }) => (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                const imageSrc = getScreenshot();
                console.log(imageSrc);
                setUploadedImage(imageSrc);
                closeWabCam();
              }}
            >
              Capture
            </Button>
          )}
        </Webcam>
        {/* {uploadedImage ? (
          <Image alt="image name" src={uploadedImage} />
        ) : (
          <Image
            alt="image name"
            src="/static/img/lost-found/lost_and_found_pic.jpg"
          />
        )} */}
      </DialogContent>
    </Dialog>
  );
};

export default CameraFeeding;

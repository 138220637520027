import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  equipmentList: {
    sel: true,
    type: true,
    equipment_name: true,
    equipment_id: true,
    key_group_id: true,
    key_ring_id: true,
    current_location: true,
    active: true,
    last_processed: true,
    equipment_sl: true,
    disposition: true,
    action: true,
  },
  equipmentUserList: {
    barcode_id: true,
    fname: true,
    last_name: true,
    department: true,
    position: true,
    sl: true,
    updated: true,
    last_seen: true,
    active: true,
    out: true,
    pcount: true,
    action: true,
  },
  equipmentLogList: {
    user_name: true,
    equipment_type: true,
    equipment_name: true,
    checkout_date: true,
    checkin_date: true,
  },
  equipmentDetailList: {
    equipment_id: true,
    equipment_type: true,
    equipment_name: true,
    action: true,
  },
  positionList: {
    id: true,
    department_name: true,
    position_name: true,
    security_level: true,
    equipment_permission_count: true,
    action: true,
  },
  departmentList: {
    id: true,
    department_name: true,
    position_count: true,
    action: true,
  },
};

export const equipmentTableColSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {
    setEquipmentList: (state, action) => {
      state.equipmentList = action.payload;
    },
    setEquipmentUserList: (state, action) => {
      state.equipmentUserList = action.payload;
    },
    setEquipmentLogList: (state, action) => {
      state.equipmentLogList = action.payload;
    },
    setEquipmentDetailList: (state, action) => {
      state.equipmentDetailList = action.payload;
    },
    setPositionList: (state, action) => {
      state.positionList = action.payload;
    },
    setDepartmentList: (state, action) => {
      state.departmentList = action.payload;
    },
  },
});

export const {
  setEquipmentList,
  setEquipmentUserList,
  setEquipmentLogList,
  setEquipmentDetailList,
  setPositionList,
  setDepartmentList,
} = equipmentTableColSlice.actions;

export default equipmentTableColSlice.reducer;

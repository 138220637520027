import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { useParams, useNavigate } from "react-router-dom";

import { DetailsForShipment } from "../../api";

import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  Paper,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import { Print as PrintIcon } from "@mui/icons-material";
const Image = styled.img`
  max-width: 100%;
  width: 100%;
`;
const SubHeaderImage = styled.img`
  max-width: 100px;
  width: 100%;
`;

const PaymentDoneContent = () => {
  const [perLoading, setPerLoading] = useState(true); //State for loader
  const [recordData, setRecordData] = useState(null);

  const params = useParams();
  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    DetailsForShipment(params.key)
      .then((res) => {
        if (res.statusCode === 200) {
          if (
            res.data.Shipment &&
            res.data.Shipment.payment_status === "Paid"
          ) {
            setRecordData(res.data);
          } else {
            navigate({
              pathname: "/lostandfound/process-to-shipment/" + params.key,
            });
          }
        }
        setPerLoading(false);
      })
      .catch((err) => {});
  };

  const print = () => {
    window.print();
  };

  return (
    <>
      {perLoading ? (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      ) : (
        <>
          {recordData ? (
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <Card variant="outlined">
                  <CardHeader title="We're shipping this:" />
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item md={8}>
                        <Typography variant="h5" component="div" gutterBottom>
                          <strong>Item Category: </strong> {recordData.category}
                        </Typography>
                        <Typography variant="h5" component="div" gutterBottom>
                          <strong>Description: </strong>{" "}
                          {recordData.description}
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Image
                          src={
                            recordData
                              ? recordData.image
                              : "/static/img/lost-found/lost_and_found_pic.jpg"
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card variant="outlined" sx={{ marginTop: "20px" }}>
                  <CardHeader title="It's going here:" />
                  {recordData.recipient_address_object &&
                  recordData.recipient_address_object.is_residential ? (
                    <CardContent>
                      <Typography>
                        {recordData.lost_first_name +
                          " " +
                          recordData.lost_last_name}
                      </Typography>
                      <Typography>
                        {recordData.lost_company_name +
                          " " +
                          recordData.lost_street1 +
                          " " +
                          recordData.lost_street2}
                      </Typography>
                      <Typography>
                        {recordData.lost_city +
                          " " +
                          recordData.lost_state +
                          " " +
                          recordData.lost_country +
                          " " +
                          recordData.lost_zip}
                      </Typography>
                      <Typography>{recordData.lost_phone}</Typography>
                      <Typography>
                        Now sit tight while we prepare your package for
                        shipment. You will receive a confirmation email once
                        your item is shipped, along with a tracking number.
                        Thank you for using SOVA as your shipping partner. If
                        you have questions, please send us an email at
                        lost@sovasystems.com
                      </Typography>
                    </CardContent>
                  ) : (
                    <CardContent>
                      <Typography>
                        {recordData.lost_first_name +
                          " " +
                          recordData.lost_last_name}
                      </Typography>
                      <Typography>
                        {recordData.lost_company_name +
                          " " +
                          recordData.b_lost_street1 +
                          " " +
                          recordData.b_lost_street2}
                      </Typography>
                      <Typography>
                        {recordData.b_lost_city +
                          " " +
                          recordData.b_lost_state +
                          " " +
                          recordData.b_lost_country +
                          " " +
                          recordData.b_lost_zip}
                      </Typography>
                      <Typography>{recordData.b_lost_phone}</Typography>
                      <Typography>
                        Now sit tight while we prepare your package for
                        shipment. You will receive a confirmation email once
                        your item is shipped, along with a tracking number.
                        Thank you for using SOVA as your shipping partner. If
                        you have questions, please send us an email at
                        lost@sovasystems.com
                      </Typography>
                    </CardContent>
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ height: "100%" }}>
                  <CardHeader
                    title="You shipped via:"
                    action={
                      <Tooltip
                        title="Print Receipt"
                        placement="top"
                        onClick={() => print()}
                      >
                        <IconButton aria-label="settings" color="primary">
                          <PrintIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    subheader={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {recordData.selected_shipment &&
                          recordData.selected_shipment.provider_image_75 && (
                            <SubHeaderImage
                              src={
                                recordData.selected_shipment.provider_image_75
                              }
                            />
                          )}
                        {recordData.selected_shipment &&
                          recordData.selected_shipment.duration_terms && (
                            <Typography sx={{ marginLeft: "5px" }}>
                              {recordData.selected_shipment.duration_terms}
                            </Typography>
                          )}
                      </Box>
                    }
                  />
                  <CardContent sx={{ height: "100%" }}>
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography>
                        Freight: $
                        {recordData.Shipment
                          ? recordData.Shipment.freight_cost
                          : 0}
                      </Typography>
                      <Typography>
                        Processing: $
                        {recordData.Shipment
                          ? recordData.Shipment.processing_cost
                          : 0}
                      </Typography>
                      <Typography>
                        Insurance: $
                        {recordData.Shipment
                          ? recordData.Shipment.insurance_value
                          : 0}
                      </Typography>
                      <Typography variant="h4" component="div" gutterBottom>
                        Total Cost: $
                        {recordData.Shipment
                          ? recordData.Shipment.amount_paid
                          : 0}
                      </Typography>
                      <Typography variant="h4" component="div" gutterBottom>
                        Thank You!
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                Sorry! record not found.
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
function PaymentDone() {
  return (
    <React.Fragment>
      <Paper sx={{ maxWidth: "1200px", margin: "40px auto 0", width: "100%" }}>
        <PaymentDoneContent />
      </Paper>
    </React.Fragment>
  );
}

export default PaymentDone;

import React, { useEffect, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import styled from "styled-components/macro";
import { Form, Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { useParams, useNavigate } from "react-router-dom";
import { handleApiResonseErrors } from "../../helper/helper";
import CustomCheckbox from "../../components/form-components/CustomCheckbox";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomAlert from "../../components/form-components/CustomAlert";
import GoogleAutoCompleteField from "../../components/form-components/GoogleAutoCompleteField";
import StripeCheckout from "react-stripe-checkout";
import TermsAndConditions from "../../components/modals/lostandfound/TermsAndConditions";
import GeneralAddressTips from "../../components/modals/lostandfound/GeneralAddressTips";
import AboutShippingAddress from "../../components/modals/lostandfound/AboutShippingAddress";
import { sova } from "../../config";
import * as Yup from "yup";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Modal from "@mui/material/Modal";
import {
  DetailsForShipment,
  GetShipments,
  SaveLostfoundPayment,
} from "../../api";
import Divider from "@mui/material/Divider";
import { NavLink } from "react-router-dom";
import {
  Paper,
  CircularProgress,
  Typography,
  Grid,
  Box,
  Accordion as MuiAccordion,
  FormControl as MuiFormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  TextField,
  InputLabel,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  Checkbox,
  Link,
  FormGroup,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  MonetizationOn as MonetizationOnIcon,
} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { spacing } from "@mui/system";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 100%;
  display: block;
`;
const Image = styled.img`
  max-width: 100px;
  margin: 20px auto 0;
  width: 100%;
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
const ProcessToShipmentSteps = (props) => {
  const { showAlertTost } = props;
  const [loading, setLoading] = useState(false); //State for loader
  const [perLoading, setPerLoading] = useState(true); //State for loader
  const [recordData, setRecordData] = useState(null);
  const [shipmentRates, setShipmentRates] = useState({});
  const [expanded, setExpanded] = useState("panel1");
  const [showPanel2, setShowPanel2] = useState(true);
  const [showPanel3, setShowPanel3] = useState(true);

  const [declaredValueError, setDeclaredValueError] = useState("");
  const [insuranceValue, setInsuranceValue] = useState(0);

  const [handleTour, setHandleTour] = useState(true);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
  }));
  const Image = styled.img`
    max-width: initial;
    margin: 20px auto 0;
    width: 100%;
  `;
  const tourConfig = [
    {
      selector: '[data-tut="reactour__control_id"]',
      content: `This is the ID for your item. Refer to this number in any correspondence.`,
    },
    {
      selector: '[data-tut="reactour__item_handle"]',
      content: `We assume you want to ship your item. If not, please select one of these other options.`,
    },
    {
      selector: '[data-tut="reactour__declared_values"]',
      content: `Now, click this field and enter a value for your item, then click next.`,
    },
    {
      selector: '[data-tut="reactour__want_insurance"]',
      content: `Carriers insure packages up to certain limits automatically, but you can opt for additional insurance through our third party carrier, ShipSurance. Some items cannot be insured through ShipSurance, like cell phones, tablets, and documents. Make your selection, then click "Next".`,
    },
    {
      selector: '[data-tut="reactour__check_all_apply"]',
      content: `If your item is perishable, urgent, or you would like Saturday delivery, enter your choices here. Then click "Next".`,
    },
    {
      selector: '[data-tut="reactour__process_to_step_2"]',
      content: `Looking good! Now, click this button to proceed to step 2.`,
    },
  ];

  const validationSchema = Yup.object().shape({
    lost_first_name: Yup.string().required("Please enter first name."),
    lost_last_name: Yup.string().required("Please enter last name."),
    lost_street1: Yup.string().required("Please enter Street address."),
    lost_city: Yup.string().required("Please enter City."),
    lost_state: Yup.string().required("Please enter state."),
    lost_zip: Yup.string().required("Please enter zip."),
    lost_country: Yup.string().required("Please enter country."),
    address_verify: Yup.string().required("This field is required."),
    lost_zip: Yup.number()
      .required("Please enter zip.")
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    lost_phone: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    lost_email: Yup.string().nullable().email("Please enter a valid email."),
    lost_company_name: Yup.string()
      .nullable()
      .when(["business_add"], (businessAdd, schema) => {
        return businessAdd === "No"
          ? schema
          : schema.required("Please enter business name");
      }),
  });

  const classes = useStyles();
  let initialValuesStep1 = {
    declared_value: recordData
      ? recordData.declared_value
        ? recordData.declared_value
        : ""
      : "",
    lost_perishable: recordData
      ? recordData.lost_perishable
        ? recordData.lost_perishable
        : ""
      : "",
    lost_urgent: recordData
      ? recordData.lost_urgent
        ? recordData.lost_urgent
        : ""
      : "",
    lost_first_name: recordData
      ? recordData.lost_first_name
        ? recordData.lost_first_name
        : ""
      : "",
    lost_last_name: recordData
      ? recordData.lost_last_name
        ? recordData.lost_last_name
        : ""
      : "",
    lost_street1: recordData
      ? recordData.lost_street1
        ? recordData.lost_street1
        : ""
      : "",
    lost_street2: recordData
      ? recordData.lost_street2
        ? recordData.lost_street2
        : ""
      : "",
    lost_city: recordData
      ? recordData.lost_city
        ? recordData.lost_city
        : ""
      : "",
    lost_state: recordData
      ? recordData.lost_state
        ? recordData.lost_state
        : ""
      : "",
    lost_zip: recordData
      ? recordData.lost_zip
        ? recordData.lost_zip
        : ""
      : "",
    lost_country: recordData
      ? recordData.lost_country
        ? recordData.lost_country
        : ""
      : "",
    lost_phone: recordData
      ? recordData.lost_phone
        ? recordData.lost_phone
        : ""
      : "",
    lost_email: recordData
      ? recordData.lost_email
        ? recordData.lost_email
        : ""
      : "",
    lost_company_name: recordData
      ? recordData.lost_company_name
        ? recordData.lost_company_name
        : ""
      : "",
    address_verify: "",
    signature_confirmation: "",
    business_add: "No",
    saturday_delivery: "",
    want_insurance: "Yes",
    shipping_options: "ShipItem",
  };
  let initialValuesStep2 = {};

  const params = useParams();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    DetailsForShipment(params.key)
      .then((res) => {
        if (res.statusCode === 200) {
          if (
            res.data.Shipment &&
            res.data.Shipment.payment_status === "Paid"
          ) {
            navigate({
              pathname: "/lostandfound/payment-done/" + params.key,
            });
          } else {
            setRecordData(res.data);
            calcuInsurance(res.data.declared_value, "Yes");
          }
        } else {
          showAlertTost(res.message, "error");
        }
        setPerLoading(false);
      })
      .catch((err) => {
        setPerLoading(false);
        setPerLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          showAlertTost(error, "error");
        }
      });
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        shipment_uuid: params.key,
        declared_value: values.declared_value,
        lost_perishable: values.lost_perishable ? 1 : 0,
        lost_urgent: values.lost_urgent ? 1 : 0,
        lost_first_name: values.lost_first_name,
        lost_last_name: values.lost_last_name,
        lost_street1: values.lost_street1,
        lost_street2: values.lost_street2,
        lost_city: values.lost_city,
        lost_state: values.lost_state,
        lost_zip: values.lost_zip,
        lost_country: values.lost_country,
        lost_phone: values.lost_phone,
        lost_email: values.lost_email,
        lost_company_name: values.lost_company_name,
        address_verify: values.address_verify ? 1 : 0,
        signature_confirmation: values.signature_confirmation,
        business_add: values.business_add,
        saturday_delivery: values.saturday_delivery,
        want_insurance: values.want_insurance,
        shipping_options: values.shipping_options,
        insurance_value: insuranceValue,
      };

      GetShipments(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            setShipmentRates(res.data);
            setExpanded("panel3");
            setShowPanel3(false);
          } else {
            showAlertTost("Sorry! something wrong please try again", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            showAlertTost(error, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      showAlertTost("Sorry! something wrong please try again", "error");
    }
  };

  const savePayment = (payload) => {
    setLoading(true);
    SaveLostfoundPayment(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          navigate({
            pathname: "/lostandfound/payment-done/" + params.key,
          });
          showAlertTost("payment done", "success");
        } else {
          showAlertTost(res.message, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          showAlertTost(error, "error");
        }
      });
  };

  const onToken = (token, selectedShipmentID) => {
    let payload = {
      shipment_uuid: params.key,
      shipmentID: selectedShipmentID,
      stripeToken: token.id,
      stripeEmail: token.email,
    };
    savePayment(payload);
  };

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const proceedToStep2 = (declared_value) => {
    if (declared_value) {
      setDeclaredValueError("");
      setExpanded("panel2");
      setShowPanel2(false);
    } else {
      setDeclaredValueError("Please enter declared value");
    }
  };

  const calcuInsurance = (value, wantInsurance) => {
    if (wantInsurance === "No") {
      setInsuranceValue(0);
    } else {
      var dms = 0.45;
      var roundVal = Math.ceil(value / 100);
      var roundVal = roundVal * dms;
      var roundVal = Math.ceil(roundVal);
      setInsuranceValue(roundVal);
    }
  };

  // Term and conditions modal
  const [termAndConditions, setTermAndConditions] = React.useState(false);
  const termAndConditionOpen = () => {
    setTermAndConditions(true);
  };
  const termAndConditionClose = () => {
    setTermAndConditions(false);
  };
  // General Tips
  const [generalAddressTips, setGeneralAddressTips] = React.useState(false);
  const generalAddressTipsOpen = () => {
    setGeneralAddressTips(true);
  };
  const generalAddressTipsClose = () => {
    setGeneralAddressTips(false);
  };
  // About Shipping Address
  const [aboutShipping, setAboutShipping] = React.useState(false);
  const aboutShippingOpen = () => {
    setAboutShipping(true);
  };
  const aboutShippingClose = () => {
    setAboutShipping(false);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // datepicker
  const [selectedDate, setSelectedDate] = React.useState("");

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    // You can handle the selected date here, for example, send it to the state or process it further
  };

  return (
    <>
      <Paper sx={{ maxWidth: "900px", margin: "0 auto", padding: "50px 0" }}>
        {perLoading ? (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        ) : (
          <>
            {recordData ? (
              <Box className={classes.accordion}>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValuesStep1}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    setFieldValue,
                    values,
                    status,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleExpanded("panel1")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="step1-content"
                          id="step1-header"
                        >
                          <Typography
                            sx={{
                              color: "#376fd0",
                            }}
                          >
                            <strong>Step 1:</strong> Declare item value and
                            answer questions
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={12}>
                              <Typography
                                component="div"
                                variant="h4"
                                gutterBottom
                                sx={{ color: "#376fd0" }}
                              >
                                Item Description
                              </Typography>
                              <Box>
                                <Grid spacing={6} container>
                                  <Grid item xs={12} md={9}>
                                    <Typography
                                      component="div"
                                      variant="body1"
                                      gutterBottom
                                      data-tut="reactour__control_id"
                                    >
                                      <strong>Control ID</strong>:{" "}
                                      {recordData.id}
                                    </Typography>
                                    <Typography
                                      component="div"
                                      variant="body1"
                                      gutterBottom
                                    >
                                      <strong>Item Category:</strong>{" "}
                                      {recordData.category}
                                    </Typography>
                                    <Typography
                                      component="div"
                                      variant="body1"
                                      gutterBottom
                                    >
                                      <strong>Description:</strong>{" "}
                                      {recordData.description}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <Image
                                      src={
                                        recordData
                                          ? recordData.image
                                          : "/static/img/lost-found/lost_and_found_pic.jpg"
                                      }
                                    />
                                  </Grid>
                                </Grid>

                                <Typography
                                  component="div"
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginTop: "15px" }}
                                >
                                  Note: The description here may not accurately
                                  describe your item. A picture of your item may
                                  appear if available. If you have questions,
                                  please contact the business where you lost
                                  your item.
                                </Typography>
                              </Box>

                              <FormControl
                                mt={6}
                                data-tut="reactour__item_handle"
                              >
                                <FormLabel component="legend">
                                  How should we handle this item?
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="shipping_options"
                                  name="shipping_options"
                                  value={values.shipping_options}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "shipping_options",
                                      e.currentTarget.value
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Ship item"
                                    value="ShipItem"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Discard item"
                                    value="Discard"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="I will pick up the item"
                                    value="PickUp"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormControl
                                mt={6}
                                data-tut="reactour__declared_values"
                              >
                                <InputLabel htmlFor="outlined-adornment-amount">
                                  Declared Value (max $5000 USD) *
                                </InputLabel>
                                <OutlinedInput
                                  fullWidth
                                  id="outlined-adornment-amount"
                                  name="declared_value"
                                  value={values.declared_value}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "declared_value",
                                      e.currentTarget.value
                                    );
                                    calcuInsurance(
                                      e.currentTarget.value,
                                      values.want_insurance
                                    );
                                  }}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <MonetizationOnIcon />
                                    </InputAdornment>
                                  }
                                  label="Name of supervisor at time of injury"
                                />
                                <FormHelperText style={{ color: "red" }}>
                                  {declaredValueError}
                                </FormHelperText>
                              </FormControl>
                              <FormControl mt={6}>
                                <Paper data-tut="reactour__want_insurance">
                                  <FormLabel component="legend">
                                    Want insurance?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="want_insurance"
                                    name="want_insurance"
                                    value={values.want_insurance}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "want_insurance",
                                        e.currentTarget.value
                                      );
                                      calcuInsurance(
                                        values.declared_value,
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </Paper>
                                <FormHelperText sx={{ marginLeft: 0 }}>
                                  Insurance cost is ${insuranceValue}. Read the{" "}
                                  <Link onClick={termAndConditionOpen}>
                                    terms and conditions
                                  </Link>
                                </FormHelperText>
                              </FormControl>
                              <FormControl
                                mt={6}
                                data-tut="reactour__check_all_apply"
                              >
                                <FormLabel>Check all that apply:</FormLabel>
                                <FormGroup row>
                                  <CustomCheckbox
                                    name="lost_perishable"
                                    checked={
                                      values.lost_perishable ? true : false
                                    }
                                    label="Perishable"
                                  />
                                  <CustomCheckbox
                                    name="lost_urgent"
                                    checked={values.lost_urgent ? true : false}
                                    label="Urgent"
                                  />
                                  <CustomCheckbox
                                    name="saturday_delivery"
                                    checked={
                                      values.saturday_delivery ? true : false
                                    }
                                    label="Saturday Delivery"
                                  />
                                </FormGroup>
                              </FormControl>
                              <Button
                                sx={{ marginTop: "30px" }}
                                style={{ width: "100%" }}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  proceedToStep2(values.declared_value);
                                }}
                                data-tut="reactour__process_to_step_2"
                              >
                                Proceed to step 2
                              </Button>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        mt={4}
                        expanded={expanded === "panel2"}
                        onChange={handleExpanded("panel2")}
                        disabled={showPanel2}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="step2-content"
                          id="step2-header"
                        >
                          <Typography
                            sx={{
                              color: "#376fd0",
                            }}
                          >
                            <strong>Step 2:</strong> Verify Shipping Information
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={12}>
                              <Typography
                                component="div"
                                variant="h4"
                                gutterBottom
                                sx={{
                                  color: "#376fd0",
                                }}
                              >
                                Shipping Address
                              </Typography>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  label="First name *"
                                  name="lost_first_name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lost_last_name"
                                  label="Last name *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <FormLabel component="legend">
                                  Business address
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="business_add"
                                  name="business_add"
                                  value={values.business_add}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "business_add",
                                      e.currentTarget.value
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                              {values.business_add === "Yes" && (
                                <FormControl mt={6}>
                                  <CustomTextField
                                    fullWidth
                                    name="lost_company_name"
                                    label="Business Name *"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                    placeholder="Business Name (leave blank if residential and choose NO above)"
                                  />
                                </FormControl>
                              )}
                              <FormControl mt={6}>
                                <GoogleAutoCompleteField
                                  name="lost_street1"
                                  label="Street (line 1) *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  getApiResponse={(res) => {
                                    setFieldValue("lost_street1", res.address);
                                    setFieldValue("lost_city", res.locality);
                                    setFieldValue(
                                      "lost_state",
                                      res.administrative_area_level_1
                                    );
                                    setFieldValue("lost_zip", res.postal_code);
                                    setFieldValue("lost_country", res.country);
                                  }}
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lost_street2"
                                  label="Street (line 2)"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lost_city"
                                  label="City *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lost_state"
                                  label="State *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lost_zip"
                                  label="Zip code *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lost_country"
                                  label="Country *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                />
                                <FormHelperText sx={{ marginLeft: 0 }}>
                                  Double check your address for typos? Click for{" "}
                                  <Link onClick={generalAddressTipsOpen}>
                                    General Address Tips
                                  </Link>{" "}
                                  or{" "}
                                  <Link onClick={aboutShippingOpen}>
                                    APO/FPO Address Tips
                                  </Link>
                                </FormHelperText>
                                <CustomCheckbox
                                  name="signature_confirmation"
                                  checked={
                                    values.signature_confirmation ? true : false
                                  }
                                  label="Add signature confirmation?"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lost_phone"
                                  label="Phone"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Required for FedEx and international shipments"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  fullWidth
                                  name="lost_email"
                                  label="Email"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="me@you.com"
                                />
                                <CustomCheckbox
                                  name="address_verify"
                                  checked={values.address_verify ? true : false}
                                  label="Address verified correct? *"
                                />
                              </FormControl>
                              <Button
                                sx={{ marginTop: "30px" }}
                                style={{ width: "100%" }}
                                color="primary"
                                variant="contained"
                                type={
                                  values.address_verify ? "submit" : "button"
                                }
                                disabled={values.address_verify ? false : true}
                              >
                                Proceed to step 3
                              </Button>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        mt={4}
                        expanded={expanded === "panel3"}
                        onChange={handleExpanded("panel3")}
                        disabled={showPanel3}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="step3-content"
                          id="step3-header"
                        >
                          <Typography
                            sx={{
                              color: "#376fd0",
                            }}
                          >
                            <strong>Step 3:</strong> Choose Shipping Career
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={12}>
                              <Typography
                                component="div"
                                variant="h4"
                                gutterBottom
                                sx={{
                                  color: "#376fd0",
                                }}
                              >
                                Shipping Choices
                              </Typography>
                              <Typography
                                component="div"
                                variant="body1"
                                gutterBottom
                                sx={{ color: "#d32f2f" }}
                              >
                                Shipping to:{" "}
                                {shipmentRates.toAddress
                                  ? shipmentRates.toAddress.street1 +
                                    " " +
                                    shipmentRates.toAddress.city +
                                    " " +
                                    shipmentRates.toAddress.state +
                                    " " +
                                    shipmentRates.toAddress.zip
                                  : ""}
                              </Typography>

                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>#</TableCell>
                                      <TableCell>Cost</TableCell>
                                      <TableCell>Carrier</TableCell>
                                      <TableCell>Service</TableCell>
                                      <TableCell>Duration</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>1</TableCell>
                                      <TableCell>
                                        <Button
                                          onClick={handleOpen}
                                          variant="contained"
                                          sx={{ marginRight: "10px" }}
                                        >
                                          Pay
                                        </Button>
                                        70.68
                                      </TableCell>
                                      <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                      >
                                        <Box sx={style}>
                                          <CancelIcon
                                            onClick={handleClose}
                                            sx={{
                                              float: "right",
                                              color: "gray",
                                            }}
                                          />
                                          <Typography
                                            sx={{
                                              textAlign: "center",
                                              marginTop: "30px",
                                              color: "#376fd0",
                                            }}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                          >
                                            Shipping & Handling
                                          </Typography>
                                          <Typography
                                            sx={{
                                              textAlign: "center",
                                              marginTop: "5px",
                                              fontWeight: "bold",
                                              color: "gray",
                                            }}
                                          >
                                            Cash($70.68)
                                          </Typography>
                                          <hr></hr>
                                          <Form
                                            sx={{
                                              paddingRight: "10px",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            <TextField
                                              sx={{
                                                width: "100%",
                                                marginTop: "15px",
                                              }}
                                              id="outlined-basic"
                                              label="Email Id"
                                              variant="outlined"
                                              placeholder="Enter Your Email....."
                                            />
                                            <TextField
                                              sx={{
                                                width: "100%",
                                                marginTop: "15px",
                                              }}
                                              id="outlined-basic"
                                              label="Card Number"
                                              variant="outlined"
                                              placeholder="Enter Your Card No....."
                                            />
                                            {/* <TextField
                                              sx={{
                                                width: "45%",
                                                marginTop: "20px",
                                                marginRight: "10px",
                                              }}
                                              id="date"
                                              label="Date of Birth"
                                              type="date"
                                              defaultValue="2000-01-01"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              value={selectedDate}
                                              onChange={handleDateChange}
                                            /> */}
                                            <TextField
                                              sx={{
                                                width: "47%",
                                                marginTop: "20px",
                                                marginRight: "10px",
                                                color: "black",
                                              }}
                                              label="Select Month and Year"
                                              type="month"
                                              defaultValue="MM/YY"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              onChange={handleDateChange}
                                              variant="outlined"
                                            />
                                            <TextField
                                              sx={{
                                                width: "47%",
                                                marginTop: "20px",
                                                marginLeft: "10px",
                                              }}
                                              id="outlined-basic"
                                              label="CVC"
                                              variant="outlined"
                                              placeholder="....."
                                            />
                                            <NavLink to="/lostandfound/user-receipt">
                                              <Button
                                                // onClick={handleOpen}
                                                variant="contained"
                                                sx={{
                                                  marginRight: "10px",
                                                  width: "100%",
                                                  marginTop: "20px",
                                                }}
                                              >
                                                Pay $70.68
                                              </Button>
                                            </NavLink>
                                          </Form>
                                        </Box>
                                      </Modal>
                                      <TableCell>usps</TableCell>
                                      <TableCell>Service</TableCell>
                                      <TableCell>
                                        Delivery in 3 to 10 buisiness days.
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              {/* <TableContainer
                                className="tableContainer"
                                component="div"
                                sx={{ marginTop: "20px" }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>#</TableCell>
                                      <TableCell>Cost</TableCell>
                                      <TableCell>Carrier</TableCell>
                                      <TableCell>Service</TableCell>
                                      <TableCell>Duration</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {shipmentRates.shipmentRates ? (
                                    <TableBody>
                                      {shipmentRates.shipmentRates.map(
                                        (row, index) => {
                                          return (
                                            <TableRow hover key={index}>
                                              <TableCell>{index + 1}</TableCell>
                                              <TableCell>
                                                <StripeCheckout
                                                  token={(e) =>
                                                    onToken(e, row.object_id)
                                                  }
                                                  name="Shipping & Handling"
                                                  description={
                                                    row.category +
                                                    " (" +
                                                    row.totalAmountToPay +
                                                    ")"
                                                  }
                                                  currency="USD"
                                                  amount={
                                                    row.totalAmountToPay * 100
                                                  }
                                                  locale="auto"
                                                  image="/static/img/brands/sova-eye.png"
                                                  stripeKey={
                                                    sova.StripePublishableKey
                                                  }
                                                >
                                                  <Button
                                                    sx={{ marginTop: "30px" }}
                                                    color="primary"
                                                    variant="contained"
                                                  >
                                                    PAY
                                                  </Button>
                                                </StripeCheckout>
                                              </TableCell>
                                              <TableCell>
                                                {row.totalAmountToPay}
                                              </TableCell>
                                              <TableCell>
                                                <Image
                                                  src={row.provider_image_75}
                                                />
                                                {row.provider}
                                              </TableCell>
                                              <TableCell>
                                                {row.servicelevel_name}
                                              </TableCell>
                                              <TableCell>
                                                {row.duration_terms}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )}
                                    </TableBody>
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={4} align="center">
                                        No records found
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Table>
                              </TableContainer> */}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  )}
                </Formik>

                <Tour
                  onRequestClose={() => setHandleTour(false)}
                  steps={tourConfig}
                  isOpen={handleTour}
                  maskClassName="mask"
                  className="helper"
                  rounded={5}
                  accentColor="#5cb7b7"
                  onAfterOpen={disableBody}
                  onBeforeClose={enableBody}
                />
              </Box>
            ) : (
              <Box>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    Sorry! record not found.
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        )}
        {loading === true && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
        {termAndConditions && (
          <TermsAndConditions
            termAndConditions={termAndConditions}
            setTermAndConditions={setTermAndConditions}
            termAndConditionClose={termAndConditionClose}
            termAndConditionOpen={termAndConditionOpen}
          />
        )}
        {generalAddressTips && (
          <GeneralAddressTips
            generalAddressTips={generalAddressTips}
            setGeneralAddressTips={setGeneralAddressTips}
            generalAddressTipsClose={generalAddressTipsClose}
            generalAddressTipsOpen={generalAddressTipsOpen}
          />
        )}
        {aboutShipping && (
          <AboutShippingAddress
            aboutShipping={aboutShipping}
            setAboutShipping={setAboutShipping}
            aboutShippingClose={aboutShippingClose}
            aboutShippingOpen={aboutShippingOpen}
          />
        )}
      </Paper>
    </>
  );
};
function ProcessToShipment() {
  // Alert Message
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const showAlertTost = (msg, type) => {
    setAlertMessage(msg);
    setAlertType(type);
    setShowAlert(true);
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ProcessToShipmentSteps
            showAlertTost={(msg, type) => showAlertTost(msg, type)}
          />
        </Grid>
        {showAlert && (
          <CustomAlert
            message={alertMessage}
            severity={alertType}
            closeAlert={(show) => setShowAlert(show)}
            showAlert={showAlert}
          />
        )}
      </Grid>
    </React.Fragment>
  );
}

export default ProcessToShipment;

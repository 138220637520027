import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checked: [],
  snackStatus: false,
  msg: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getChecked: (state, action) => {
      state.checked = action.payload;
    },
    getSnackOpen: (state) => {
      state.snackStatus = true;
    },
    getSnackClose: (state) => {
      state.snackStatus = false;
    },
    getMsg: (state, action) => {
      state.msg = action.payload;
    },
  },
});

export const { getChecked, getSnackOpen, getSnackClose, getMsg } =
  userSlice.actions;
export default userSlice.reducer;

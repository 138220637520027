import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import AddQuestionDialog from "./inner-component/AddQuestionDialog";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  IconButton,
  Card,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  MenuItem,
  Menu,
  DialogTitle,
  DialogActions,
  ListItemText,
  ListItemIcon,
  Dialog,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { QuestionList, DeleteQuestion } from "../../../api";
import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const AddQuestion = () => {
  const [addQuestionDialog, setAddQuestionDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [listQuestion, setListQuestion] = useState([]);
  const [updatedRow, setUpdatedRow] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const addQuestionDialogOpen = () => {
    setAddQuestionDialog(true);
  };
  const addQuestionDialogClose = () => {
    setAddQuestionDialog(false);
  };
  const handleActionClick = (event, row) => {
    setUpdatedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  const deleteQuestion = () => {
    DeleteQuestion(updatedRow.id)
      .then((res) => {
        if (res.statusCode === 200) {
          handleDeleteClose();
          questionList();
          dispatch(getMsg("Data deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const questionList = () => {
    QuestionList()
      .then((res) => {
        if (res.statusCode == 200) {
          setListQuestion(res.data);
        } else {
          setListQuestion([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };
  useEffect(() => {
    questionList();
  }, []);
  return (
    <>
      <Helmet title="Chat Bot Questions | Sova" />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this question?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={deleteQuestion} autoFocus variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h3" gutterBottom>
        Add Questions
      </Typography>
      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "space-between",
        }}
      >
        <Button
          component={NavLink}
          to="/property-inspection"
          variant="contained"
        >
          Search
        </Button>
        <IconButton
          onClick={() => {
            addQuestionDialogOpen();
            setUpdatedRow({});
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </Box>
      <Card
        sx={{
          marginTop: "20px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Response</TableCell>
              <TableCell align="left">Question</TableCell>
              <TableCell align="left">Language</TableCell>
              <TableCell align="left">Step</TableCell>
              <TableCell align="left">Module Type</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listQuestion.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.answer}</TableCell>
                <TableCell align="left">{row.question}</TableCell>
                <TableCell align="left">{row.language}</TableCell>
                <TableCell align="left">{row.step}</TableCell>
                <TableCell align="left">{row.module_type}</TableCell>
                <TableCell align="left">
                  <IconButton
                    aria-label="Settings"
                    size="medium"
                    aria-owns={anchorEl ? "icon-menu" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => handleActionClick(event, row)}
                  >
                    <SettingsIcon color="primary" />
                  </IconButton>
                  <StyledMenu
                    id="icon-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleActionClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        addQuestionDialogOpen();
                        handleActionClose();
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Edit" pl={1} />
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleDeleteOpen();
                        handleActionClose();
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText primary="Delete" pl={1} />
                    </MenuItem>
                  </StyledMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      {addQuestionDialog && (
        <AddQuestionDialog
          addQuestionDialog={addQuestionDialog}
          setAddQuestionDialog={setAddQuestionDialog}
          addQuestionDialogOpen={addQuestionDialogOpen}
          addQuestionDialogClose={addQuestionDialogClose}
          updatedRow={updatedRow}
          questionList={questionList}
        />
      )}
    </>
  );
};

export default AddQuestion;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import moment from "moment";

import {
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { TourUserListMissedButton } from "../../../api";

const Paper = styled(MuiPaper)(spacing);

const TournameDialog2 = (props) => {
  const {
    bluetoothButton,
    setBluetoothButton,
    bluetoothButtonClose,
    tagIdD2,
    tagDescriptionD2,
    tourIdD1,
    sDateMissedButton,
    eDateMissedButton,
  } = props;
  const [dialogLoaderInner, setDialogLoaderInner] = useState(false);
  const [userMissedButton, setUserMissedButton] = useState([]); //state for list in get-user-list-missed-button (D2) inner dialog tourname
  //handler for (D2) get-user-list-missed-button: Inner dialog for tourname
  const getUserListMissedButton = () => {
    let formatedsDate = moment(sDateMissedButton).format("YYYY-MM-DD");
    let formatedeDate = moment(eDateMissedButton).format("YYYY-MM-DD");
    let payload = {
      tagId: tagIdD2, //4052695550,
      tourId: tourIdD1, //5806315235,
      start_date: formatedsDate, // "2009-01-01",
      end_date: formatedeDate, //"2022-06-01",
    };
    setDialogLoaderInner(true);
    TourUserListMissedButton(payload)
      .then((res) => {
        setUserMissedButton(res.data);
        setDialogLoaderInner(false);
        // if (res.statusCode == 200) {
        //   setDialogLoaderInner(false);
        //   setUserMissedButton(res.data);
        // } else {
        //   setUserMissedButton(res.data);
        // }
      })
      .catch((err) => {
        console.log(err);
        setDialogLoaderInner(false);
      });
  };

  useEffect(() => {
    getUserListMissedButton();
  }, []);

  return (
    <>
      {/*get user list missed button: Inner dialog for tourname column */}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={bluetoothButton}
        onClose={bluetoothButtonClose}
        aria-labelledby="bluetooth-btn-title"
        aria-describedby="bluetooth-btn-description"
      >
        <DialogTitle
          id="bluetooth-btn-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            {tagDescriptionD2}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setBluetoothButton(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Paper mt={3}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogLoaderInner ? (
                    <TableCell
                      colSpan={9}
                      align="center"
                      sx={{
                        height: "50px",
                        paddingTop: "10px",
                        paddingBottom: "5px",
                        "& .MuiCircularProgress-root": {
                          width: "28px !important",
                          height: "28px !important",
                        },
                      }}
                    >
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <>
                      {userMissedButton.map((item, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell>{item.user_name}</TableCell>
                            <TableCell>{item.missed_count}</TableCell>
                          </TableRow>
                        );
                      })}
                      {userMissedButton.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={3}>
                            No data for selected date range
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={bluetoothButtonClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TournameDialog2;

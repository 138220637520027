import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

function Gauge(props) {
  return (
    <ReactSpeedometer
      value={40}
      minValue={0}
      maxValue={100}
      needleColor="black"
      needleTransitionDuration={4000}
      needleHeightRatio={0.4}
      needleTransition="easeElastic"
      currentValueText="${value}"
      width={195}
      height={125}
      textColor="#AAA"
      ringWidth={25}
    />
  );
}

export default Gauge;

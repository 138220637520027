import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  poiList: {
    threat_level: true,
    id: true,
    gender: true,
    first_name: true,
    last_name: true,
    alias: true,
    race: true,
    age: true,
    height: true,
    weight: true,
    image: true,
    watch: true,
    enc: true,
    last_updated: true,
    action: true,
  },
  poiEncounterList: {
    threat_level: true,
    poi_id: true,
    reporting_location: true,
    gender: true,
    alias: true,
    count: true,
    image: true,
    narrative: true,
    date_time: true,
    action: true,
  },
};

export const poiTableColSlice = createSlice({
  name: "poi",
  initialState,
  reducers: {
    setPoiList: (state, action) => {
      state.poiList = action.payload;
    },
    setPoiEncounterList: (state, action) => {
      state.poiEncounterList = action.payload;
    },
  },
});

export const { setPoiList, setPoiEncounterList } = poiTableColSlice.actions;

export default poiTableColSlice.reducer;

import React from "react";
import { useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { DateTimePicker } from "@mui/lab";
import { SketchPicker } from "react-color";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
  Typography,
  Select,
  TextField,
  Grid,
  FormControl as MuiFormControl,
  MenuItem,
  InputLabel,
  Button,
  Box,
} from "@mui/material";

const NewNotify = () => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  // default narrative
  const [defaultNarrative, setDefaultNarrative] = useState("");
  const setNarrative = () => {
    setEditorTextNarrative(defaultNarrative);
  };
  //Functions for text editors
  const [editorTextNarrative, setEditorTextNarrative] = useState("");
  const onEditorNarrativeChange = (e) => {
    setEditorTextNarrative(e.getCurrentContent().getPlainText());
  };

  const QuillWrapper = styled.div`
    .ql-editor {
      min-height: 200px;
    }
  `;
  const [description, setDescription] = useState("");

  return (
    <>
      <Typography variant="h3">New Notification Div</Typography>
      <Grid container spacing={4}>
        <Grid item lg={3} md={3} mt={10}>
          <FormControl>
            <InputLabel id="demo-select-small">Select Corporate</InputLabel>
            <Select
              labelId="department"
              id="demo-select-small"
              label="Select Corporate"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value="disables value">None Selected</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item lg={9} md={9}></Grid> */}

        <Grid item lg={3} md={3} sx={{ marginTop: "40px" }}>
          <FormControl
            sx={{
              maxWidth: "450px",
            }}
          >
            <InputLabel id="demo-select-small">Select Location</InputLabel>
            <Select
              labelId="department"
              id="demo-select-small"
              label="Select Location"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value="disables value">None Selected</MenuItem>
              <MenuItem value={10}>America</MenuItem>
              <MenuItem value={20}>Florida</MenuItem>
              <MenuItem value={30}>London</MenuItem>
              <MenuItem value={10}>America</MenuItem>
              <MenuItem value={20}>Florida</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={9} md={9}></Grid>
        <Grid item lg={12} md={12} mt={3}>
          <FormControl>
            <QuillWrapper>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                placeholder="Type something.."
              />
            </QuillWrapper>
            {/* <TextField
              id="outlined-multiline"
              multiline
              rows={3}
              label="Notify Text"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} mt={3}>
          <FormControl>
            {/* <TextField
              id="outlined"
              label="Start Date"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <DateTimePicker
              id="start date"
              name="Start Date"
              label="Start Date"
              // value={lostdate}
              // onChange={(event) => {
              //   setLostDate(event);
              // }}
              inputFormat="yyyy-MM-dd HH:mm:ss"
              //  defaultDate={values.lost_date}
              // maxDate={departuredate ? departuredate : new Date()}
              // minDate={arrivaldate ? arrivaldate : new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "yyyy-mm-dd hh:mm:ss",
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} mt={3}>
          <FormControl>
            {/* <TextField
              id="outlined"
              label="End Date"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <DateTimePicker
              id="start date"
              name="Start Date"
              label="Start Date"
              // value={lostdate}
              // onChange={(event) => {
              //   setLostDate(event);
              // }}
              inputFormat="yyyy-MM-dd HH:mm:ss"
              //  defaultDate={values.lost_date}
              // maxDate={departuredate ? departuredate : new Date()}
              // minDate={arrivaldate ? arrivaldate : new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "yyyy-mm-dd hh:mm:ss",
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} mt={3}>
          <FormControl>
            <TextField
              sx={{ width: "100%" }}
              // name="map_pin_color"
              // onBlur={handleBlur}
              // onChange={handleChange}
              // value={values.map_pin_color}
              label="Div Background Color"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              type="color"
            />
            {/* <TextField
              id="outlined"
              label="DIV background color"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            {/* <SketchPicker /> */}
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button variant="contained">Save</Button>
      </Box>
    </>
  );
};

export default NewNotify;

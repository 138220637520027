import React, { useState, useEffect, useReducer } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import MuiPhoneNumber from "material-ui-phone-number";
import { ChromePicker } from "react-color";
import Toaster from "../tasks/Toaster";
import PasswordStrength from "../users/components/PasswordStrength";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import ImageCropper from "../../components/form-components/ImageCropper";
import {
  GetUserView,
  UpdateUser,
  UpdateUserPassword,
  UserCorporate,
  UserGroupList,
  UserPropertyList,
} from "../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/userSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputAdornment,
  IconButton,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  FormControlLabel,
  Autocomplete,
  Checkbox,
  Chip,
  Switch,
  Paper,
  CircularProgress,
  Radio,
  Box,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
const Image = styled.img`
  width: 100%;
`;
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function EditUserForm() {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");
  const [preLoader, setPreLoader] = useState(true);
  const classes = useStyles();

  // const [image, setImage] = useState(null);
  const [corporates, setCorporates] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [color, setColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [user, setUser] = useState({});
  //State for Image Crop
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);

  const [locationId, setLocationId] = useState(null);
  const dispatch = useDispatch();
  //Image crop functionality
  // const selectImage = (file) => {
  //   setSrc(URL.createObjectURL(file));
  //   console.log(file);
  //   // console.log(src);
  // };
  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    //Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
  };

  const handleColor = () => {
    setShowColorPicker((showColorPicker) => !showColorPicker);
  };

  //useReducer start
  const initialState = { selectedOptions: [] };
  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };

      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.LocationID !== action.payload.LocationID
          ),
        };
      default:
        throw new Error();
    }
  }
  const [state, dispatch1] = useReducer(reducer, initialState);

  const handleChangeAuto = (event, values) => {
    dispatch1({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const removeOption = (id) => {
    dispatch1({ type: "REMOVE_OPTION", payload: { LocationID: id } });
  };
  let locationIds = state.selectedOptions.map((data) => data.LocationID);
  const {
    email,
    first_name,
    last_name,
    user_group_id,
    corporate_id,
    dummy_first_name,
    dummy_last_name,
    ru,
    reporting_location_id,
    sec_question,
    sec_answer,
    user_initials,
    color_code,
    country,
    location_id,
    phone,
    attendance_log,
    user_site,
    profile_image,
  } = user;

  // State for image cropper modal
  const [imageCropperDialog, setImageCropperDialog] = useState(false);
  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };
  const [imageData, setImageData] = useState(null);
  const childToParent = (childData) => {
    setImageData(childData);
  };

  const imageCropperClear = () => {
    setImageData(null);
  };

  const initialValues = {
    user_level: user_group_id ? user_group_id : "",
    select_corporate: corporate_id ? corporate_id : "",
    location_id: location_id ? location_id : "",
    email: email ? email : "",
    incognito_first_name: dummy_first_name ? dummy_first_name : "",
    incognito_last_name: dummy_last_name ? dummy_last_name : "",
    report_location_name: ru ? ru : "",
    report_locaton_id: reporting_location_id ? reporting_location_id : "",
    challenge_question: sec_question ? sec_question : "",
    answer: sec_answer ? sec_answer : "",
    first_name: first_name ? first_name : "",
    last_name: last_name ? last_name : "",
    user_intial: user_initials ? user_initials : "",
    phoneNumber: phone ? phone : "",
    code: "",
    country: country ? country : "",
    map_pin_color: color_code ? color_code : "",
    profile_name: "",
    attendance_log: attendance_log ? attendance_log : "",
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string().required("Please enter last name"),
    email: Yup.string()
      .email("Email must be valid email")
      .required("Please enter email address"),

    challenge_question: Yup.string().required(
      "Please enter chanllenge question"
    ),
    answer: Yup.string().required("Please enter answer"),
    user_level: Yup.string().required("Please select user level"),
    select_corporate: Yup.string().required("Please select coporate"),
    location_id: Yup.string().required("Please select property"),
  });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setPreLoader(true);
      let payload = {
        UserID: id,
        user_group_id: values.user_level,
        location_id: locationId,
        corporate_id: values.select_corporate,
        email: values.email,
        dummy_first_name: values.incognito_first_name,
        dummy_last_name: values.incognito_last_name,
        sec_question: values.challenge_question,
        sec_answer: values.answer,
        first_name: values.first_name,
        last_name: values.last_name,
        user_initials: values.user_intial,
        phone: values.phoneNumber,
        ru: values.report_location_name,
        reporting_location_id: values.report_locaton_id,
        color_code: values.map_pin_color,
        attendance_log: values.attendance_log,
        profile_image: imageData ? imageData : null,
        user_site: locationIds,
      };
      UpdateUser(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("User has been updated successfully"));
            navigate({
              pathname: "/users/user",
            });
            setPreLoader(false);
          } else {
            dispatch(getSnackClose());
            setPreLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setPreLoader(false);
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const corporate = () => {
    UserCorporate()
      .then((res) => {
        if (res.statusCode === 200) {
          setCorporates(res.data);
        } else {
          setCorporates([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userGroups = () => {
    UserGroupList()
      .then((res) => {
        if (res.statusCode === 200) {
          setUserGroup(res.data);
        } else {
          setUserGroup([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const siteLocationId = () => {
    var siteResult = [];
    if (user_site && propertyList !== undefined) {
      user_site.forEach((obj) => {
        propertyList.forEach((r) => {
          if (r.LocationID === obj) {
            siteResult.push(r);
          }
        });
      });
    }
    dispatch1({
      type: "SET_SELECTED_OPTIONS",
      payload: {
        options: siteResult,
      },
    });
  };

  const property = () => {
    UserPropertyList()
      .then((res) => {
        if (res.statusCode === 200) {
          setPropertyList(res.data);
        } else {
          setPropertyList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get user details
  const { id } = useParams(); //get the userId
  const getUser = async () => {
    GetUserView(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setUser(res.data);
          setPreLoader(false);
        } else {
          setUser({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setPreLoader(true);
    getUser();
    corporate();
    userGroups();
    property();
  }, []);

  useEffect(() => {
    siteLocationId();
  }, [user_site, propertyList]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Card mb={6}>
          {preLoader && (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          )}
          {imageCropperDialog && (
            <ImageCropper
              imageCropperDialog={imageCropperDialog}
              setImageCropperDialog={setImageCropperDialog}
              imageCropperOpen={imageCropperOpen}
              imageCropperClose={imageCropperClose}
              childToParent={childToParent}
              imageData={imageData}
              image={image}
              // parentToChild={parentToChild}
            />
          )}
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <TextField
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="user_level"
                      select
                      value={values.user_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.user_level && errors.user_level)}
                      helperText={touched.user_level && errors.user_level}
                      label="User level*"
                      fullWidth
                      displayEmpty
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="">--Click to select--</MenuItem>
                      {userGroup.map((user) => {
                        return (
                          <MenuItem value={user.id} key={user.id}>
                            {user.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      labelId="demo-simple-select-label-two"
                      id="demo-simple-select"
                      name="select_corporate"
                      value={values.select_corporate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(
                        touched.select_corporate && errors.select_corporate
                      )}
                      helperText={
                        touched.select_corporate && errors.select_corporate
                      }
                      label="Select corporate*"
                      fullWidth
                      displayEmpty
                      select
                    >
                      <MenuItem value="">--Click to select--</MenuItem>
                      {corporates.map((corporate) => {
                        return (
                          <MenuItem
                            value={corporate.id}
                            key={corporate.id}
                            onClick={() => property(corporate.id)}
                          >
                            {corporate.corporate_name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                  <FormControl mt={6}>
                    <Autocomplete
                      multiple
                      name="location_id"
                      options={propertyList}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.LocationName}
                      value={state.selectedOptions}
                      renderTags={(values) =>
                        values.map((value) => (
                          <Chip
                            key={value.LocationID}
                            label={value.LocationName}
                            onDelete={() => {
                              removeOption(value.LocationID);
                            }}
                          />
                        ))
                      }
                      getOptionSelected={(option, value) =>
                        option.LocationID === value.LocationID
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.LocationID}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.LocationName}
                        </li>
                      )}
                      style={{ width: "100%" }}
                      onChange={handleChangeAuto}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Assigned property"
                          placeholder="Search"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      autoComplete="off"
                      required
                      fullWidth
                      id="email"
                      name="email"
                      type="email"
                      onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onChange={handleChange}
                      value={values.email}
                      label="Email"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="me@you.com"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="incognito-fname"
                      name="incognito_first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.incognito_first_name}
                      label="Incognito first name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="False name visible during demos"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="incognito-lname"
                      name="incognito_last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.incognito_last_name}
                      label="Incognito last name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="False name visible during demos"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="Rpt-lname"
                      name="report_location_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.report_location_name}
                      label="Reporting location name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Name of your company"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      label="Reporting location ID"
                      id="outlined-adornment-text"
                      name="report_locaton_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.report_locaton_id && errors.report_locaton_id
                      )}
                      helperText={
                        touched.report_locaton_id && errors.report_locaton_id
                      }
                      value={values.report_locaton_id}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 3 }}
                      placeholder="ABC"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledTooltip
                              placement="top-end"
                              title={
                                <Typography>
                                  Issued by SOVA, used to add POI records by
                                  email
                                </Typography>
                              }
                            >
                              <ErrorIcon color="primary" />
                            </StyledTooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <Typography
                    component="p"
                    sx={{ fontSize: "12px", fontWeight: "300" }}
                    mt={4}
                  >
                    Write a challenge question below and provide an answer. This
                    will be used to help protect your account from unauthorized
                    access.The challenge question and answer you provide should
                    only be known by this user. Note: answers are case
                    sensitive, and multiple word answers are encouraged.
                  </Typography>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="challenge-ques"
                      name="challenge_question"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.challenge_question}
                      error={Boolean(
                        touched.challenge_question && errors.challenge_question
                      )}
                      helperText={
                        touched.challenge_question && errors.challenge_question
                      }
                      label="Challenge question*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Provide a question and answer only this user would know"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="answer"
                      name="answer"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.answer}
                      error={Boolean(touched.answer && errors.answer)}
                      helperText={touched.answer && errors.answer}
                      label="Answer*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="cAsE sEnSiTiVe"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <TextField
                      fullWidth
                      id="first-name"
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      value={values.first_name}
                      label="First name*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Mike"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="last-name"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      value={values.last_name}
                      label="Last name*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Smith"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="cell-sign"
                      name="user_intial"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.user_intial}
                      label="User initials or Call sign"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="MS, 14, etc"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <MuiPhoneNumber
                      name="phoneNumber"
                      id="contactPhoneNumber"
                      label="Phone"
                      value={values.phoneNumber}
                      onChange={handleChange("phoneNumber")}
                      onBlur={handleBlur}
                      defaultCountry={"us"}
                      style={{ width: "100%" }}
                      variant="outlined"
                      disableAreaCodes={true}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </FormControl>
                  {/* <FormControl mt={6}>
                    <TextField
                      autoComplete="off"
                      id="map-pin"
                      name="map_pin_color"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onClick={handleColor}
                      value={color ? color : values.map_pin_color}
                      error={Boolean(
                        touched.map_pin_color && errors.map_pin_color
                      )}
                      helperText={touched.map_pin_color && errors.map_pin_color}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Click to select color for BackTrack map"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <ErrorIcon
                                color="primary"
                                onClick={() => setOpen(true)}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="Map pin color"
                    />
                    {showColorPicker && (
                      <ChromePicker
                        color={color}
                        onChange={(updatedColor) => setColor(updatedColor.hex)}
                      />
                    )}
                    <Dialog
                      open={open}
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      onClose={() => setOpen(false)}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogTitle
                        id="form-dialog-title"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h4">Map Pin Color</Typography>
                        <DialogActions>
                          <IconButton onClick={() => setOpen(false)}>
                            <CancelIcon />
                          </IconButton>
                        </DialogActions>
                      </DialogTitle>
                      <DialogContent>
                        <ImageWrapper>
                          <Image
                            alt="demo-image"
                            src={`/static/img/unsplash/map-pin-color-popup.png`}
                          />
                        </ImageWrapper>
                      </DialogContent>
                    </Dialog>
                  </FormControl> */}

                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      name="map_pin_color"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.map_pin_color}
                      label="Map Pin Color"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="color"
                    />
                  </FormControl>

                  <FormControl mt={6}>
                    <Typography>Has access to Attendance Log?</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          name="attendance_log"
                          value={values.attendance_log}
                          checked={values.attendance_log === "Y"}
                          onChange={(event, checked) =>
                            setFieldValue("attendance_log", checked ? "Y" : "N")
                          }
                          onBlur={handleBlur}
                        />
                      }
                      label=""
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <Typography
                      component="p"
                      sx={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Add profile image (or add via mobile app)
                    </Typography>
                    <BigAvatar
                      alt="Remy Sharp"
                      src={imageData ? imageData : profile_image}
                    />
                    {/* <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      onBlur={handleBlur}
                      onChange={handleImage}
                      // onChange={(e) => {
                      //   selectImage(e.target.files[0]);
                      // }}
                      type="file"
                      name="profile_name"
                      value={values.profile_name}
                    /> */}
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={imageCropperOpen}
                        mt={3}
                      >
                        Choose image
                      </Button>

                      {imageData && (
                        <Button
                          sx={{ marginLeft: "10px" }}
                          onClick={imageCropperClear}
                          variant="contained"
                          color="primary"
                          component="span"
                          mt={3}
                        >
                          Clear
                        </Button>
                      )}
                    </label>
                    {/* {src && (
                      <Dialog open={true}>
                        <DialogContent>
                          <ReactCrop
                            src={src}
                            onImageLoaded={setImage}
                            crop={crop}
                            onChange={setCrop}
                          />
                          <button onClick={cropImageNow}>Crop</button>
                        </DialogContent>
                      </Dialog>
                    )} */}
                  </FormControl>
                  <Button
                    onClick={handleClickOpen}
                    sx={{
                      marginleft: "35px",
                      width: "200px",
                      marginTop: "20px",
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={6}
                  >
                    Select Baseline Tour Image
                  </Button>
                  <Dialog
                    sx={{
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "600px",
                        },
                      },
                    }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Select Baseline Tour Image"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <FormControl mt={3}>
                          <TextField
                            // placeholder="select Tour"
                            label="Select Tour"
                            id="demo-simple-select"
                            name="user_level"
                            select
                            // value={values.user_level}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.user_level && errors.user_level
                            )}
                            helperText={touched.user_level && errors.user_level}
                            // label="User level*"
                            fullWidth
                            displayEmpty
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem>
                              <Radio />
                              Tour1
                            </MenuItem>
                            <MenuItem>
                              <Radio />
                              Tour2
                            </MenuItem>
                            <MenuItem>
                              <Radio />
                              Tour3
                            </MenuItem>
                          </TextField>
                        </FormControl>
                        <Box sx={{ display: "flex" }}>
                          <Image src={`/static/img/brands/logo.svg`} />
                          <Image src={`/static/img/brands/logo.svg`} />
                          <Image src={`/static/img/brands/logo.svg`} />
                        </Box>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        autoFocus
                        color="primary"
                        variant="outlined"
                      >
                        Next
                      </Button>
                      <Button
                        onClick={handleClose}
                        color="warning"
                        variant="outlined"
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={6}
                    sx={{ width: "175px" }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function EditUser() {
  const [openpwdChange, setpwdChange] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [setAnchorEl] = React.useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const { id } = useParams();
  console.log("id in edit user", id);
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const clickpwdChangeOpen = () => {
    setpwdChange(true);
  };
  const pwdChangeClose = () => {
    setpwdChange(false);
    setAnchorEl(null);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const initialValues = {
    password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter your password")
      .matches(/^\S*$/, "Whitespace is not allowed")
      .test(
        "regex",
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
        (val) => {
          let regExp = new RegExp(
            "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
          );
          // console.log(regExp.test(val), regExp, val);
          return regExp.test(val);
        }
      ),

    confirm_password: Yup.string()
      .required("Please enter the same password")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });

  //Check String contain lowercase and uppercase
  const isUpperCase = (string) => /[a-z]/.test(string) && /[A-Z]/.test(string);
  //Check String contain digit or not
  const hasNumber = (string) => /\d/.test(string);
  //check string contain special character or not
  const schar = (string) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(string);

  //check whitespace not allowed
  const whiteSpaces = (string) => /^\S*$/.test(string);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let payload = {
        password: values.password,
        cpassword: values.confirm_password,
      };
      UpdateUserPassword(id, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            console.log("password changes");
            setOpenSnack(true);
            setStatusCode(res.statusCode);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setpwdChange(false);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Edit User" />

      <Grid container spacing={4}>
        <Grid item xs={12} md={7} lg={9}>
          <Typography variant="h3" gutterBottom display="inline">
            Edit User
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/tables/Users">
              User
            </Link>
            <Typography>Edit User</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={5} lg={3} align="right" alignSelf="self-end">
          <Button
            variant="contained"
            color="primary"
            onClick={clickpwdChangeOpen}
          >
            Change password
          </Button>
        </Grid>
      </Grid>
      <Divider my={6} />
      <EditUserForm />
      <Toaster
        openSnack={openSnack}
        severity={statusCode === 200 ? "success" : "error"}
        handleCloseSnack={handleCloseSnack}
        message={
          statusCode === 200
            ? "Password has been successfully changed"
            : "Something went wrong"
        }
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        open={openpwdChange}
        onClose={pwdChangeClose}
        aria-labelledby="pwd-change"
        aria-describedby="pwd-change"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Change Password
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setpwdChange(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText id="pwd-change" sx={{ padding: "30px 20px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                      <FormControl mt={6} sx={{ width: "100%" }}>
                        <TextField
                          Autocomplete="off"
                          label="Password"
                          id="outlined-adornment-password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Must be over 30% complexity"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <PasswordStrength password={values.password} />
                        <FormHelperText>
                          {values.password.length >= 8 ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#71BF45",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <CancelIcon
                              sx={{
                                color: "#D65454",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          )}
                          Length of at least 8 characters
                        </FormHelperText>
                        <FormHelperText>
                          {isUpperCase(values.password) ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#71BF45",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <CancelIcon
                              sx={{
                                color: "#D65454",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          )}
                          Contains uppercase and lowercase letters
                        </FormHelperText>
                        <FormHelperText>
                          {hasNumber(values.password) ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#71BF45",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <CancelIcon
                              sx={{
                                color: "#D65454",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          )}
                          Contains number
                        </FormHelperText>
                        <FormHelperText>
                          {schar(values.password) ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#71BF45",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <CancelIcon
                              sx={{
                                color: "#D65454",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          )}
                          Contains special characters
                        </FormHelperText>
                        <FormHelperText>
                          {whiteSpaces(values.password) ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#71BF45",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <CancelIcon
                              sx={{
                                color: "#D65454",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          )}
                          Doesn’t contain spaces
                        </FormHelperText>
                        <FormHelperText>
                          <CheckCircleIcon
                            sx={{
                              color: "#71BF45",
                              fontSize: "15px",
                              verticalAlign: "sub",
                              marginRight: "10px",
                            }}
                          />
                          Doesn’t contain common password words
                        </FormHelperText>
                        <FormHelperText>
                          {values.password === values.confirm_password ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#71BF45",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <CancelIcon
                              sx={{
                                color: "#D65454",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                          )}
                          Passwords match
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl mt={2} sx={{ width: "100%" }}>
                        <TextField
                          label="Confirm password"
                          id="confirm-password"
                          name="confirm_password"
                          type={showPassword ? "text" : "password"}
                          value={values.confirm_password}
                          error={Boolean(
                            touched.confirm_password && errors.confirm_password
                          )}
                          helperText={
                            touched.confirm_password && errors.confirm_password
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Enter same value as above"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions
                    sx={{ paddingBottom: "30px", paddingRight: "20px" }}
                  >
                    <Button
                      onClick={pwdChangeClose}
                      color="primary"
                      variant="outlined"
                    >
                      Close
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                      Save
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default EditUser;

import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { DateTimePicker } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import React, { useState, useEffect } from "react";
import CustomSelect from "../../../components/form-components/CustomSelect";
import CustomTextField from "../../../components/form-components/CustomTextField";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import {
  PositionByDepartment,
  DepartmentList,
  SaveLockerLog,
  LockerRoomlist,
  FindLocker,
  ViewAssignment,
  EditAssignment,
  SendCombo,
  ViewLockerDetail,
} from "../../../api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  FormControl as MuiFormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Paper,
  CircularProgress,
  FormLabel,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Https as HttpsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const AddLockerAssignment = ({
  addLockerAssignmentDialog,
  addLockerAssignmentDialogClose,
  id,
  isEdit,
  lockerListData,
  singleRow,
  isAssign,
  isEditAssign,
  lockersList,
  logId,
}) => {
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [lockerList, setLockerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState({});
  const [smsLockComboCheckBox, setSmsLockComboCheckBox] = useState(false);
  const [lockerID, setLockerID] = useState("");
  const [lockerDetail, setLockerDetail] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const viewAssignment = () => {
    ViewAssignment(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewData(res.data);
          setLockerID(res.data.locker_id);
        } else {
          setViewData({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const {
    fname,
    lname,
    department_id,
    position,
    room_name,
    locker_number,
    inspection_date,
    interval_days,
    locker_id,
    sms_lock_combo,
  } = viewData;

  let initialValues = {
    fname: fname ? fname : "",
    lname: lname ? lname : "",
    room_name: room_name ? room_name : isAssign ? singleRow?.locker_room : "",
    locker_number: locker_number
      ? locker_number
      : isAssign
      ? singleRow?.locker_number
      : "",
    locker_number_t: locker_number
      ? locker_number
      : isAssign
      ? singleRow?.locker_number
      : "",
    department_id: department_id ? department_id : "",
    inspection_date: inspection_date ? inspection_date : null,
    interval_days: interval_days ? interval_days : "",
    position: position ? position : "",
    locker_id: locker_id ? locker_id : isAssign ? singleRow.id : "",
    sms_lock_combo: sms_lock_combo ? sms_lock_combo : "",
  };

  const validationSchema = Yup.object().shape({
    fname: Yup.string().required("Please enter first name."),
    lname: Yup.string().required("Please enter last name."),
    department_id: Yup.number().required("Please select department."),
    position: Yup.number().required("Please select position."),
    room_name: Yup.string().required("Please select room."),
    locker_number_t: Yup.number().required("Please select locker."),
    locker_number: Yup.number().required("Please select locker number."),
  });

  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getLockerRoomlist = () => {
    LockerRoomlist()
      .then((res) => {
        setRoomList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getPositionList = (value) => {
    setLoading(true);
    PositionByDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLockerLockList = (value) => {
    setLoading(true);
    FindLocker({ room_name: value })
      .then((res) => {
        if (res.statusCode === 200) {
          setLockerList(res.data);
        } else {
          setLockerList([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDepartmentList();
    getLockerRoomlist();
    if (isAssign) {
      getLockerLockList(singleRow?.locker_room);
    }
    if (isEdit) {
      viewAssignment();
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      PositionByDepartment(department_id)
        .then((res) => {
          if (res.statusCode === 200) {
            setPositionList(res.data);
          } else {
            setPositionList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
      FindLocker({ room_name: room_name })
        .then((res) => {
          if (res.statusCode === 200) {
            setLockerList(res.data);
          } else {
            setLockerList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [department_id]);

  useEffect(() => {
    if (lockerID) {
    }
    ViewLockerDetail(lockerID)
      .then((response) => {
        if (response.statusCode == 200) {
          setLockerDetail(response.data);
        } else {
          console.log("somehing went wrong");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [lockerID]);

  const checkCombo = (e) => {
    if (
      lockerDetail.lock_combination == "" ||
      lockerDetail.lock_combination == null
    ) {
      dispatch(
        getMsg(
          "Cannot send SMS. Please either assign a lock to this locker, or if the locks are an integral part of the locker, provide a lock combination in the field below."
        )
      );
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } else {
      setSmsLockComboCheckBox(e.target.checked);
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let date = values.inspection_date
        ? moment(values.inspection_date).format("YYYY-MM-DD")
        : "";

      let formData = {
        id: viewData?.id,
        fname: values.fname,
        lname: values.lname,
        room_name: values.room_name,
        locker_number: values.locker_number_t,
        department_id: values.department_id,
        inspection_date: date,
        interval_days: values.interval_days,
        position: values.position,
        locker_id: values.locker_id
          ? values.locker_id
          : lockerList.find((i) => i.key == singleRow?.locker_number).id,
      };
      isEdit
        ? EditAssignment(formData)
            .then((res) => {
              if (res.statusCode === 200) {
                setLoading(false);
                addLockerAssignmentDialogClose();
                if (isAssign) {
                  lockersList();
                }
                dispatch(getMsg("Record udpated successfully"));
                dispatch(getSnackAlert("success"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                lockerListData();
              } else {
                dispatch(getMsg("Something went wrong!"));
                dispatch(getSnackAlert("error"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                setLoading(false);
              }
            })
            .catch((err) => {
              // let errors = err.data.errors;
              // let error = handleApiResonseErrors(errors);
              // dispatch(getMsg(error));
              // dispatch(getSnackAlert("error"));
              // dispatch(getSnackOpen());

              setLoading(false);
            })
        : SaveLockerLog(formData)
            .then((res) => {
              if (res.statusCode === 200) {
                setLoading(false);
                if (isAssign) {
                  lockersList();
                }
                addLockerAssignmentDialogClose();
                dispatch(getMsg("Record submitted successfully"));
                dispatch(getSnackAlert("success"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                lockerListData();
              } else {
                dispatch(getMsg(res.message));
                dispatch(getSnackAlert("error"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                setLoading(false);
              }
            })
            .catch((err) => {
              // let errors = err.data.errors;
              // let error = handleApiResonseErrors(errors);
              // dispatch(getMsg(error));
              // dispatch(getSnackAlert("error"));
              // dispatch(getSnackOpen());

              setLoading(false);
            });
    } catch (error) {
      // messageAlert("Sorry! something wrong please try again", "error");
      // setLoading(false);
    }
  };
  const sendCombo = (values) => {
    let payload = {
      mobileNo: values.sms_lock_combo,
      room_name: values.room_name,
      locker_number: values.locker_number,
      locker_id: values.locker_id,
      lockerCombo: lockerDetail.lock_combination,
    };
    SendCombo(payload).then((response) => {
      if (response.statusCode == 200) {
        dispatch(getMsg("Locker combo send successfully"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      } else {
        dispatch(getMsg("Something went wrong"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      }
    });
  };

  return (
    <>
      <Dialog
        aria-labelledby="locker-assignement-title"
        aria-describedby="locker-assignement-desc"
        open={addLockerAssignmentDialog}
        onClose={addLockerAssignmentDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="locker-assignement-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Locker Assignment
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addLockerAssignmentDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, values, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        label="First Name*"
                        autoComplete="off"
                        name="fname"
                        id="fname"
                        InputLabelProps={{ shrink: true }}
                        placeholder="John"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        label="Last Name*"
                        autoComplete="off"
                        name="lname"
                        id="lname"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Smith"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        name="department_id"
                        fieldLabel="Department*"
                        options={departmentList}
                        displayEmpty
                        customCallbackMethod={(evt) => {
                          if (evt.target.value) {
                            setFieldValue("position", "");
                            getPositionList(evt.target.value);
                          }
                        }}
                        firstOptionTitle="Select Department"
                      />
                      <FormHelperText>{errors.department_id}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        name="position"
                        fieldLabel="Position*"
                        options={positionList}
                        displayEmpty
                        firstOptionTitle="Select Position"
                      />
                      <FormHelperText>{errors.position}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        name="room_name"
                        fieldLabel="Room name *"
                        options={roomList}
                        displayEmpty
                        disabled={isAssign ? true : false}
                        customCallbackMethod={(evt) => {
                          if (evt.target.value) {
                            setFieldValue("locker_number_t", "");
                            setFieldValue("locker_number", "");
                            getLockerLockList(evt.target.value);
                            setSmsLockComboCheckBox(false);
                          }
                        }}
                      />
                      <FormHelperText>{errors.room_name}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        name="locker_number_t"
                        fieldLabel="Locker number (select room first)*"
                        options={lockerList}
                        displayEmpty
                        disabled={isAssign ? true : false}
                        customCallbackMethod={(evt) => {
                          if (evt.target.value) {
                            let value = evt.target.value;
                            var lockerObj = lockerList.find(function (obj) {
                              return obj.value == value;
                            });
                            if (lockerObj) {
                              setFieldValue("locker_number_t", lockerObj.value);
                              setFieldValue("locker_number", lockerObj.key);
                              setFieldValue("locker_id", lockerObj.id);
                              setLockerID(lockerObj.id);
                              setSmsLockComboCheckBox(false);
                            }
                          }
                        }}
                      />
                      <FormHelperText>{errors.locker_number}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <DateTimePicker
                        id="start-date"
                        label="Prior locker inspection date & time"
                        value={values.inspection_date}
                        onChange={(newValue) => {
                          setFieldValue("inspection_date", newValue);
                        }}
                        ampm={false}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="inspection_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        label="Inspection Interval Days"
                        autoComplete="off"
                        name="interval_days"
                        InputLabelProps={{ shrink: true }}
                        placeholder="List days only, like 180"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={smsLockComboCheckBox}
                            onChange={checkCombo}
                          />
                        }
                        label="Send lock combo by SMS to assignee?"
                      />
                    </FormControl>
                  </Grid>
                  {smsLockComboCheckBox == true && (
                    <Grid item xs={12} md={6}>
                      <FormLabel sx={{ marginBottom: 5 }}>
                        Enter assignee mobile number
                      </FormLabel>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={8}>
                          <FormControl>
                            <MuiPhoneNumber
                              name="sms_lock_combo"
                              id="sms_lock_combo"
                              value={values.sms_lock_combo}
                              onChange={(newPhoneNumber) => {
                                var cleanedPhoneNumber = newPhoneNumber.replace(
                                  /[\s\(\)-]/g,
                                  ""
                                );
                                setFieldValue(
                                  "sms_lock_combo",
                                  cleanedPhoneNumber
                                );
                              }}
                              defaultCountry={"us"}
                              style={{ width: "100%" }}
                              variant="outlined"
                              disableAreaCodes={true}
                              countryCodeEditable={false}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} alignSelf="center">
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{ fontSize: 13 }}
                            onClick={() => {
                              sendCombo(values);
                            }}
                            disabled={
                              values.sms_lock_combo.length > 9 ? false : true
                            }
                          >
                            Send Combo
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} md={12}>
                    <Typography>
                      <HttpsIcon
                        color="primary"
                        sx={{ marginBottom: "-5px" }}
                      />{" "}
                      <span>
                        {singleRow
                          ? singleRow.internal_lock === 2
                            ? "External Lock"
                            : singleRow.internal_lock === 1
                            ? "Internal lock"
                            : "Lock not set"
                          : lockerDetail
                          ? lockerDetail.internal_lock === 2
                            ? "External Lock"
                            : lockerDetail.internal_lock === 1
                            ? "Internal lock"
                            : "Lock not set"
                          : "No data available"}
                      </span>
                      {/*Note: Internal lock text shows when you open assign user from lockers pages */}
                      {/* <span>Internal Lock</span> */}
                    </Typography>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    onClick={addLockerAssignmentDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" variant="contained">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
        {loading && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </Dialog>
    </>
  );
};

export default AddLockerAssignment;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  NavLink,
  useParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { UpdateCustomButtonText } from "../../api";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  Switch,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  Tabs,
  Tab,
  Menu,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ChangeHistory as ChangeHistoryIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { event } from "jquery";
import Toaster from "./Toaster";
import { isValid } from "date-fns";
const Button = styled(MuiButton)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LavelTable = styled((props) => <TableContainer {...props} />)(
  ({ theme }) => ({
    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiFormLabel-root": {
        whiteSpace: "nowrap",
        paddingRight: "10px",
        minWidth: "60px",
      },
    },
    "& .MuiTableCell-root": {
      padding: "5px 10px 5px 0px",
      border: "0px",
    },
  })
);
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const StyledBox = styled((props) => <Paper {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .Mui-selected": {
      backgroundColor: "#7aa8ff0d",
    },
    "& .MuiList-root::-webkit-scrollbar": {
      /* display: "none", */
    },
  },
}));
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
//Auto Select
const customNotification = [
  { title: "Not selected" },
  { title: "Option one" },
  { title: "Option two" },
  { title: "Option three" },
  { title: "Option four" },
  { title: "Option five" },
];
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(MuiInput)`
  width: 42px;
`;

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const StyledInputSlider = styled((props) => <Paper {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiInput-root::before": {
        borderBottom: "0px",
      },
      "& .MuiInput-root": {
        "& .MuiInput-input": {
          paddingBottom: "0px",
        },
      },
    },
  })
);
const StyledContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#7aa8ff0d",
    padding: "15px",
    marginTop: "10px",
    maxHeight: "542px",
    overflowY: "auto",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      borderBottom: "0px",
      marginBottom: "12px",
      "& .MuiTypography-subtitle2": {
        marginRight: "5px",
      },
      "& .MuiSvgIcon-root": {
        margin: "0px 6px",
        width: "12px",
        height: "12px",
        verticalAlign: "middle",
      },
    },
  },
}));
// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
//For on or off
const CustomSwitchOffOn = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"ON"',
      left: 15,
    },
    "&:after": {
      content: '"OFF"',
      right: "-2px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  })
);

const CustomButton = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    custombuttontext,
    customButtonClose,
    setCustomButtonText,
    customoptions,
    customOptionsLevel,
    taskView,
    task,
  } = props;

  const {
    task_id,
    ntt_options_type,
    sub_task_1,
    sub_task_2,
    sub_task_3,
    sub_task_4,
    sub_task_5,
    sub_task_6,
  } = task;
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const initialValues = {
    options: ntt_options_type ? ntt_options_type : "",
    task_one: sub_task_1 ? sub_task_1 : "",
    task_two: sub_task_2 ? sub_task_2 : "",
    task_three: sub_task_3 ? sub_task_3 : "",
    task_four: sub_task_4 ? sub_task_4 : "",
    task_five: sub_task_5 ? sub_task_5 : "",
    task_six: sub_task_6 ? sub_task_6 : "",
  };

  const validationSchema = Yup.object().shape({});

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting, isSubmitting, isValid }
  ) => {
    try {
      console.log(values);
      setIsDisabled(true);
      let payload = {
        ntt_options_type: values.options,
        sub_task_1: values.task_one,
        sub_task_2: values.task_two,
        sub_task_3: values.task_three,
        sub_task_4: values.task_four,
        sub_task_5: values.task_five,
        sub_task_6: values.task_six,
      };
      UpdateCustomButtonText(task_id, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setOpenSnack(true);
            setIsDisabled(false);
            taskView();
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisabled(false);
        });

      setStatus({ sent: true });
      setSubmitting(false);
      isSubmitting(true);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message="Task button has been successfully updated "
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={custombuttontext}
        onClose={customButtonClose}
        aria-labelledby="custom-button-model"
        aria-describedby="custom-button-model"
      >
        <DialogTitle
          id="btn-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Custom Button Text For Task Buttons
          </Typography>

          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setCustomButtonText(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText
            id="general-address-description"
            sx={{ padding: "0 24px", margin: "5px" }}
          >
            Change the buttons your officers see when scanning a task button.
            Task buttons are added via the mobile app. Default options for the 3
            buttons your officer will see on their mobile device are Item
            Checked, Door Unlocked/Locked, and Start. You can replace the
            default labels and use custom labels instead, or you can add up to 6
            sub-tasks for your officers to do after scanning the task button.
            For example, if you added a task button to a wheelchair, you could
            task your officers to do a quick 6-point safety inspection before
            issuing the wheelchair to a visitor.
          </DialogContentText>
          <DialogContentText sx={{ padding: "10px 25px 25px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} lg={6}>
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="options"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Options
                        </InputLabel>
                        <Select
                          labelId="options"
                          id="options"
                          name="options"
                          // value={customoptions}
                          value={values.options}
                          label="Options"
                          // onChange={customOptionsLevel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="fixed">
                            Default (item checked, door lock/unlock, start)
                          </MenuItem>
                          <MenuItem value="custom">
                            Custom Labels (input 3 labels below)
                          </MenuItem>
                          <MenuItem value="list">
                            Checklist (input 6 sub-tasks below)
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <Box
                        style={{
                          display:
                            values.options === "fixed" ? "none" : "block",
                        }}
                      >
                        <FormControl mt={6}>
                          <TextField
                            fullWidth
                            id="task-1"
                            label="Task 1"
                            name="task_one"
                            value={values.task_one}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="0"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <TextField
                            fullWidth
                            id="task-2"
                            label="Task 2"
                            name="task_two"
                            value={values.task_two}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <TextField
                            fullWidth
                            id="task-3"
                            label="Task 3"
                            name="task_three"
                            value={values.task_three}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Box>
                      <Box
                        style={{
                          display: values.options === "list" ? "block" : "none",
                        }}
                      >
                        <FormControl mt={6}>
                          <TextField
                            fullWidth
                            id="task-1"
                            label="Task 4"
                            name="task_four"
                            value={values.task_four}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="0"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <TextField
                            fullWidth
                            id="task-2"
                            label="Task 5"
                            name="task_five"
                            value={values.task_five}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <TextField
                            fullWidth
                            id="task-3"
                            label="Task 6"
                            name="task_six"
                            value={values.task_six}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl
                        mt={5}
                        style={{
                          display:
                            values.options === "custom" ? "block" : "none",
                        }}
                      >
                        <FormLabel>
                          Task button will appear in the mobile app with the
                          text that you add for button 1-3 below. A value must
                          be added for all 3 buttons below. An additional button
                          will show in the mobile app for starting the task.
                          Button should be short and no more than 15 characters.
                        </FormLabel>
                      </FormControl>
                      <FormControl
                        mt={5}
                        style={{
                          display: values.options === "list" ? "block" : "none",
                        }}
                      >
                        <FormLabel>
                          A checklist of tasks will appear in the mobile app for
                          the user to review. When they complete the task, they
                          can indicate which tasks are completed. Tasks are not
                          required to be checked. Unchecked tasks appear in the
                          tour report.
                        </FormLabel>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Button
                        onClick={customButtonClose}
                        color="primary"
                        variant="contained"
                        mt={6}
                        type="submit"
                        disabled={isDisabled}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomButton;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import {
  ViewPackagePrice,
  UpdatePackageSystem,
  UpdatePackagePrice,
} from "../../../api";

const PackagePricing = ({ packagePricing, packagePricingClose }) => {
  const [pricingType, setPricingType] = useState("size");
  const [loading, setLoading] = useState(false);
  const [priceFields, setPriceFields] = useState([]);

  const handleChange = (event) => {
    setPricingType(event.target.value);
  };

  const getPackagePrice = () => {
    setLoading(true);
    const payload = {
      package_type: pricingType,
    };
    UpdatePackageSystem(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setPriceFields(res.data);
          setLoading(false);
        } else {
          setPriceFields([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updatePackagePrice = (packagePrice, packageId) => {
    const payload = {
      id: packageId,
      price: packagePrice,
    };
    UpdatePackagePrice(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          alert("Saved Successfully");
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePriceChange = (newPrice, id) => {
    setPriceFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id
          ? { ...field, price: newPrice >= 0 ? newPrice : 0 }
          : field
      )
    );
  };

  useEffect(() => {
    getPackagePrice();
  }, [pricingType]);

  return (
    <>
      <Dialog
        open={packagePricing}
        onClose={packagePricingClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px",
            },
          },
        }}
        aria-labelledby="package-pricing-title"
        aria-describedby="package-pricing-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="package-pricing-title"
        >
          <Typography variant="h4" component={"div"}>
            Package Pricing
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={packagePricingClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="subtitle2">
              Choose pricing in USD for the different package types. Leave
              pricing at zero if you do not wish to collect payment from package
              recipients. Select if you want to apply pricing to packages by
              weight or by size.
            </Typography>
          </Box>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} md={12}>
              <FormControl>
                <RadioGroup
                  name={"package-pricing-radio-buttons"}
                  row
                  value={pricingType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={"size"}
                    control={<Radio />}
                    label={"Size"}
                  />
                  <FormControlLabel
                    value={"weight"}
                    control={<Radio />}
                    label={"Weight"}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Package Type
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Price (in USD)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/* pricing table according to pricingType */}
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              priceFields.map((field) => (
                <>
                  <Grid
                    container
                    item
                    spacing={0}
                    alignItems="center"
                    key={field.id}
                  >
                    <Grid item xs={6}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {field.package_name.charAt(0).toUpperCase() +
                          field.package_name.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        value={field.price}
                        margin="dense"
                        size="small"
                        fullWidth
                        onChange={(e) =>
                          handlePriceChange(e.target.value, field.id)
                        }
                        onBlur={(e) =>
                          updatePackagePrice(e.target.value, field.id)
                        }
                        inputProps={{ min: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              ))
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              packagePricingClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PackagePricing;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Formik } from "formik";
import * as Yup from "yup";
import { AddTenant as Add, UpdateTenant, ViewTenant } from "../../../api";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Grid,
  FormControl as MuiFormControl,
  Divider as MuiDivider,
  InputLabel as MuiInputLabel,
  Paper,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const AddTenant = ({ addTenant, addTenantClose, id, tenantList, isEdit }) => {
  // Storage Location
  const [packageStorage, setPackageStorage] = React.useState("");
  const selectPackageStorage = (event) => {
    setPackageStorage(event.target.value);
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [viewData, setViewData] = useState({});
  const viewTenant = () => {
    ViewTenant(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewData(res.data);
        } else {
          setViewData({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    company_name,
    admin_name,
    tenant_email,
    tenant_phone,
    pickup_hours,
    package_pickup,
  } = viewData;
  useEffect(() => {
    if (isEdit) {
      viewTenant();
    }
  }, []);

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Please enter company name"),
    admin_name: Yup.string().required("Please enter admin name"),
    tenant_email: Yup.string()
      .email("Tenant email must be a valid email")
      .required("Please enter tenant email"),
    tenant_phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Please enter tenant phone")
      .min(10, "Tenant phone must be at least 10 characters")
      .max(15, "Tenant phone must be at most 15 characters"),
    package_pickup_location: Yup.string().required(
      "Please enter package pickup location"
    ),
    package_pickup_hours: Yup.string().required(
      "Please enter package pickup hours"
    ),
  });

  let initialValues = {
    company_name: company_name ? company_name : "",
    admin_name: admin_name ? admin_name : "",
    tenant_email: tenant_email ? tenant_email : "",
    tenant_phone: tenant_phone ? tenant_phone : "",
    package_pickup_location: package_pickup ? package_pickup : "",
    package_pickup_hours: pickup_hours ? pickup_hours : "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        id: isEdit ? id : null,
        company_name: values.company_name,
        admin_name: values.admin_name,
        tenant_email: values.tenant_email,
        tenant_phone: values.tenant_phone,
        pickup_hours: values.package_pickup_hours,
        package_pickup: values.package_pickup_location,
      };
      isEdit
        ? UpdateTenant(payload)
            .then((res) => {
              if (res.statusCode === 200) {
                tenantList();
                dispatch(getMsg("Record Updated successfully"));
                dispatch(getSnackAlert("success"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                setLoading(false);
                addTenantClose();
              } else {
                dispatch(getMsg("Something went wrong!"));
                dispatch(getSnackAlert("error"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                setLoading(false);
              }
            })
            .catch((err) => {
              let errors = err.data.errors;
              let error = handleApiResonseErrors(errors);
              dispatch(getMsg(error));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setLoading(false);
            })
        : Add(payload)
            .then((res) => {
              if (res.statusCode === 200) {
                dispatch(getMsg("Record submitted successfully"));
                dispatch(getSnackAlert("success"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                setLoading(false);
                addTenantClose();
              } else {
                dispatch(getMsg("Something went wrong!"));
                dispatch(getSnackAlert("error"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                setLoading(false);
              }
            })
            .catch((err) => {
              let errors = err.data.errors;
              let error = handleApiResonseErrors(errors);
              dispatch(getMsg(error));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setLoading(false);
            });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={addTenant}
        onClose={addTenantClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        aria-labelledby="add-Tenant-title"
        aria-describedby="add-audit-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-audit-title"
        >
          <Typography variant="h4" component="div">
            Add/Edit Tenant
          </Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={addTenantClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Use this form to identify tenant companies that exist within your
            property or building for which you will receive packages. By listing
            their contact details, they can be notified when their packages
            arrive. Details you add in the package pickup location and pickup
            hours fields below will be included in the email the tenant
            receives.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6} mt={1}>
                  <Grid item xs={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="company_name"
                        label="Company Name*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Acme Building Products"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="admin_name"
                        label="Admin Name*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="John Smith"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="tenant_email"
                        label="Tenant Email*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="jsmith@acme.com"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="tenant_phone"
                        label="Tenant Phone*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="xxx-xxx-xxxx"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="package_pickup_location"
                        label="Package Pickup Location*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Package Room, Security, etc."
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="package_pickup_hours"
                        label="Package Pickup Hours*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="9-5 M-F"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogContentText>
                  Pro Tip: You can also use the form above to identify persons
                  or departments at your own company so that they receive
                  notifications when their employees packages arrive.
                </DialogContentText>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={addTenantClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddTenant;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  TextField,
  Button,
  Typography,
  FormHelperText,
  Alert,
  AlertTitle,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { ScanRespondentCode, SaveCovidTemp } from "../../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleApiResonseErrors } from "../../../helper/helper";
import { useNavigate } from "react-router-dom";
const ProcessSurveyRespondent = ({
  processSurveyRespondent,
  processSurveyRespondentClose,
  getCovidSurveyList,
}) => {
  const [barCodeId, setBarCodeId] = useState("");
  const [temp, setTemp] = useState("");
  const [surveyStatus, setSurveyStatus] = useState(null);
  const [surveyDelay, setSurveyDelay] = useState(null);
  const [tempStatus, setTempStatus] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getSurveyDetails = () => {
    setSurveyStatus(null);
    setSurveyDelay(null);
    setTempStatus(false);
    let payload = {
      barcode: barCodeId,
    };
    // setSurveyLoader(true);
    ScanRespondentCode(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setSurveyStatus(res.data.msg);
          setTempStatus(true);
        }
        if (res.statusCode == 404) {
          setSurveyDelay(res.message);
        }
        if (res.statusCode == 422) {
          setSurveyDelay(res.message);
        }
        // setSurveyLoader(false);
      })
      .catch((err) => {
        // setSurveyLoader(false);
        console.log(err);
      });
  };

  const handleSubmit = () => {
    // setSurveyLoader(true);

    if (barCodeId !== "" && temp !== "") {
      let payload = {
        barcode_id: barCodeId,
        Temperature: temp,
      };
      SaveCovidTemp(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Record updated successfully"));
            dispatch(getSnackAlert("success"));
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            getCovidSurveyList();
            processSurveyRespondentClose();
          }
          // setSurveyLoader(false);
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          // setSurveyLoader(false);
          dispatch(getSnackOpen());
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          // console.log(error);
        });
    } else {
      dispatch(getSnackOpen());
      dispatch(getMsg("Please provide the details"));
      dispatch(getSnackAlert("error"));
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="process-survey-respondent-title"
        aria-describedby="process-survey-respondent-desc"
        open={processSurveyRespondent}
        onClose={processSurveyRespondentClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="attendance-title-modal"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Process Survey Respondent
          </Typography>
          <DialogActions>
            <IconButton
              onClick={processSurveyRespondentClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Scan barcode from respondents mobile device or enter barcode value
            manually
          </DialogContentText>
          <TextField
            autoComplete="off"
            placeholder="Barcode ID"
            fullWidth
            sx={{ marginTop: "15px" }}
            value={barCodeId}
            onChange={(e) => {
              setBarCodeId(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                getSurveyDetails();
              }
            }}
          />
          {surveyDelay !== null && (
            <FormHelperText error>{surveyDelay}</FormHelperText>
          )}

          {surveyStatus == "GO" ? (
            <Alert
              severity="success"
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                "& .MuiAlert-icon": {
                  display: "none",
                },
                "& .MuiTypography-root": {
                  fontSize: "25px",
                  margin: "0",
                },
              }}
            >
              <AlertTitle>GO</AlertTitle>
            </Alert>
          ) : surveyStatus == "WAIT" ? (
            <Alert
              severity="warning"
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                "& .MuiAlert-icon": {
                  display: "none",
                },
                "& .MuiTypography-root": {
                  fontSize: "25px",
                  margin: "0",
                },
              }}
            >
              <AlertTitle>WAIT</AlertTitle>
            </Alert>
          ) : surveyStatus == "STOP" ? (
            <Alert
              severity="error"
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                "& .MuiAlert-icon": {
                  display: "none",
                },
                "& .MuiTypography-root": {
                  fontSize: "25px",
                  margin: "0",
                },
              }}
            >
              <AlertTitle>STOP</AlertTitle>
            </Alert>
          ) : null}
          {tempStatus && (
            <TextField
              autoComplete="off"
              placeholder="Values in degrees, like 98.6"
              label="Temprature Reading"
              fullWidth
              sx={{ marginTop: "15px" }}
              value={temp}
              onChange={(e) => {
                setTemp(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProcessSurveyRespondent;

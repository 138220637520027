import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checked: [],
  snackStatus: false,
  msg: "",
  snackAlert: "",
};

export const equipmentSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {
    getChecked: (state, action) => {
      state.checked = action.payload;
    },
    getSnackOpen: (state) => {
      state.snackStatus = true;
    },
    getSnackClose: (state) => {
      state.snackStatus = false;
    },
    getMsg: (state, action) => {
      state.msg = action.payload;
    },
    getSnackAlert: (state, action) => {
      state.snackAlert = action.payload;
    },
  },
});

export const {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} = equipmentSlice.actions;
export default equipmentSlice.reducer;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { handleApiResonseErrors } from "../../../../../helper/helper";
import { SaveEmergencyProcedures } from "../../../../../api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateShowAlert,
  updateText,
} from "../../../../../redux/slices/mainSlice";
import {
  Typography,
  Paper as MuiPaper,
  Grid,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { display, spacing } from "@mui/system";
import { AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon } from "@mui/icons-material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SelectionDialog from "./SelectionDialog";

const Paper = styled(MuiPaper)(spacing);
const Image = styled.img`
  width: 100%;
`;

function SubTypeCard({ data, getProcedure }) {
  const navigate = useNavigate();
  const [selectionDialog, setSelectionDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false); // State for delete dialog
  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();

  const selectionDialogClose = () => {
    setSelectionDialog(false);
  };

  const handleButtonClick = () => {
    if (data.url) {
      data.url_file_type === "image"
        ? navigate(
            `/logs/edit-emergency-procedures/editor/${data.type}/${data.sub_type}`
          )
        : navigate(
            `/logs/edit-emergency-procedures/pdf/${data.type}/${data.sub_type}`
          );
    } else {
      setSelectionDialog(true);
    }
  };

  const messageAlert = (msg, type) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  const handleDelete = () => {
    const payload = {
      type: data.type,
      sub_type: data.sub_type,
      file: null,
      description: null,
      del: 1,
    };
    SaveEmergencyProcedures(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          getProcedure();
          dispatch(updateText("Record has been updated successfully"));
          dispatch(updateShowAlert(true));
          setOpenDelete(false); // Close the dialog after deletion
        } else {
          messageAlert(
            "Sorry! Something went wrong. Please try again.",
            "error"
          );
        }
      })
      .catch((err) => {
        const error = handleApiResonseErrors(err?.data?.errors);
        if (error) {
          messageAlert(error, "error");
        }
      });
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const renderFile = () => {
    if (data.url) {
      if (data["url_file_type"] === "image") {
        return <Image src={data.url} alt="Emergency Image" />;
      }
      if (data["url_file_type"] === "pdf") {
        return (
          <iframe
            src={data.url}
            width="100%"
            height="200px"
            title="PDF Preview"
          />
        );
      }
    }

    return <Image src={`/static/img/lost-found/lost_and_found_pic.jpg`} />;
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          sx={{ mb: "30px", mt: "15px" }}
        >
          {data?.sub_type}
        </Typography>
        <Paper className="addEmergency-product" style={{ minHeight: 250 }}>
          {renderFile()}
          <Box className="overlay-link">
            {data.url ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <RemoveRedEyeOutlinedIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "white" }}
                  titleAccess="View"
                  onClick={() => {
                    handleButtonClick();
                  }}
                />
                <DeleteOutlineOutlinedIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "white" }}
                  titleAccess="Delete"
                  onClick={() => {
                    setOpenDelete(true); // Open the delete confirmation dialog
                  }}
                />
              </Box>
            ) : (
              <>
                <AddCircleOutlineOutlinedIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "white" }}
                  titleAccess="Add"
                  onClick={() => {
                    setSelectionDialog(true);
                  }}
                />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  Click to add
                </Typography>
              </>
            )}
          </Box>
        </Paper>
      </Grid>

      {/* Selection dialog */}
      {selectionDialog && (
        <SelectionDialog
          data={data}
          selectionDialog={selectionDialog}
          selectionDialogClose={selectionDialogClose}
        />
      )}

      {/* Delete confirmation dialog */}
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this procedure?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SubTypeCard;

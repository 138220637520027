import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import EditKeyGroup from "./EditKeyGroup";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import {
  GetAddKeyLockNumberList,
  DeleteKeyLockNumber,
  AddNewKeyLockNumber,
  AddEquipmentGroup,
  GetAddKeyGroupList,
  DeleteKeyGroup,
  KeyGroupTree,
} from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Checkbox,
  FormGroup,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  FormControlLabel,
  Grid,
  FormHelperText,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import CustomCheckboxTree from "../../../components/form-components/CustomCheckboxTree";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const AddKeyGroup = ({
  addKeyGroup,
  addKeyGroupClose,
  getEquipmentKeyGroupTreeList,
}) => {
  // Edit Key Group Modal
  const [editKeyGroup, setEditKeyGroup] = useState(false);
  const [keyGroupId, setKeyGroupId] = useState(null);
  const [close, setClose] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const editKeyGroupOpen = () => {
    setEditKeyGroup(true);
  };
  const editKeyGroupClose = () => {
    setEditKeyGroup(false);
  };
  const [hideActionButtons, setHideActionButtons] = useState(false);
  const toggleActionButtons = () => {
    setHideActionButtons((toggleAction) => !toggleAction);
  };

  /**
   * It closes the dialog box.
   * @param e - The event object
   */
  const closeDialogHandle = (e) => {
    setClose(e.target.value);
  };

  const [keyGroup, setKeyGroup] = useState([]);
  const getKeyGroup = () => {
    GetAddKeyGroupList()
      .then((res) => {
        if (res.statusCode === 200) {
          setKeyGroup(res.data);
        } else {
          setKeyGroup([]);
        }
        console.log(res.data);
      })
      .then((err) => {
        console.log(err);
      });
  };

  // Key Group Checkbox Tree
  const [equipKeyGroupTree, setEquipKeyGroupTree] = useState([]);
  const [isCheckedKeyGroupValue, setIsCheckedKeyGroupValue] = useState(0);

  const handleCheckedKeyGroupTree = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIsCheckedKeyGroupValue(value);
      setHideActionButtons(true);
      let d = equipKeyGroupTree.find((d) => d.id == value);
      setSingleData(d);
    } else {
      setIsCheckedKeyGroupValue(0);
    }
  };
  const getEquipmentKeyGroupTree = () => {
    KeyGroupTree()
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipKeyGroupTree(res.data);
        } else {
          setEquipKeyGroupTree([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteKeyGroup = () => {
    handleDeleteClose();
    DeleteKeyGroup(isCheckedKeyGroupValue)
      .then((res) => {
        if (res.statusCode === 200) {
          getEquipmentKeyGroupTree();
          getEquipmentKeyGroupTreeList();
          dispatch(getSnackOpen());
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          getKeyGroup();
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg("Something went wrong"));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setErrMsg(error);
        }
        dispatch(getSnackClose());
      });
  };

  const handleKeyGroup = (id, data) => {
    setKeyGroupId(id);
    setHideActionButtons(true);
    setSingleData(data);
  };
  useEffect(() => {
    getKeyGroup();
    getEquipmentKeyGroupTree();
  }, []);

  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    key_group_name: Yup.string().required("Please enter key group name"),
  });

  /* Setting the initial values for the form. */
  let initialValues = {
    key_group_name: "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        parent_id: isCheckedKeyGroupValue ? isCheckedKeyGroupValue : 0,
        name: values.key_group_name,
      };
      AddEquipmentGroup(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Add key group has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            getEquipmentKeyGroupTree();
            getEquipmentKeyGroupTreeList();
            if (close === "close") {
              addKeyGroupClose();
            }
            getKeyGroup();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={addKeyGroup}
        onClose={addKeyGroupClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="add-key-group-title"
        aria-describedby="add-key-group-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Add Key Group</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={addKeyGroupClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Key groups are logical groupings of keys that are used in a
            particular area of your property. Add a new top level key group by
            entering a name in the field below and saving. Add a child key group
            by first selecting the parent group from the list below. To edit or
            delete a key group select the key group from the list then click the
            edit or delete button at the bottom.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <Dialog
                      open={openDelete}
                      onClose={handleDeleteClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Are you sure you want to delete add key group?"}
                      </DialogTitle>
                      <DialogActions>
                        <Button onClick={handleDeleteClose}>Cancel</Button>
                        <Button onClick={deleteKeyGroup} autoFocus>
                          OK
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <FormControl mt={3}>
                      <FormGroup>
                        <CustomCheckboxTree
                          handleChecked={(evt) =>
                            handleCheckedKeyGroupTree(evt)
                          }
                          equipType={equipKeyGroupTree}
                          isChecked={isCheckedKeyGroupValue}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="key_group_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key group name"
                        autoComplete="off"
                        placeholder="No special characters"
                      />
                    </FormControl>
                    <FormHelperText
                      sx={{ color: "#f44336", marginLeft: "14px" }}
                    >
                      {errMsg}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="outlined" type="submit">
                      Save & add more
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                      value={"close"}
                      onClick={closeDialogHandle}
                    >
                      Save & done
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        {hideActionButtons && (
          <DialogActions sx={{ padding: "0 24px 20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={editKeyGroupOpen}
            >
              Edit
            </Button>
            <Button
              color="primary"
              variant="outlined"
              sx={{ marginLeft: "10px" }}
              onClick={handleDeleteOpen}
            >
              Delete
            </Button>
          </DialogActions>
        )}
        {editKeyGroup && (
          <EditKeyGroup
            setEditKeyGroup={setEditKeyGroup}
            editKeyGroup={editKeyGroup}
            editKeyGroupOpen={editKeyGroupOpen}
            editKeyGroupClose={editKeyGroupClose}
            getKeyGroup={getKeyGroup}
            isCheckedKeyGroupValue={isCheckedKeyGroupValue}
            getEquipmentKeyGroupTree={getEquipmentKeyGroupTree}
          />
        )}
      </Dialog>
    </>
  );
};

export default AddKeyGroup;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Button,
  TextField,
  DialogContent,
  FormHelperText,
} from "@mui/material";
import { ValidateKioskModePassword } from "../../api";

const KioskModeSubmitPassword = ({
  kioskModeSubmitPasswordModal,
  kioskModeSubmitPasswordModalClose,
  launchFullScreen,
  onDataFromChild,
}) => {
  const [userPassword, setUserPassword] = useState("");
  const handleInputChange = (event) => {
    setUserPassword(event.target.value);
  };
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = {
      password: userPassword,
    };
    ValidateKioskModePassword(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          onDataFromChild();
          setIncorrectPassword(false);
          localStorage.removeItem("fullscreen");
        } else {
          setIncorrectPassword(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="kiosk-mode-submit-password"
        aria-describedby="kiosk-mode-submit-password-desc"
        open={kioskModeSubmitPasswordModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "650px",
              padding: "20px",
              borderRadius: "12px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          id="vcomm-log-title"
        ></DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              error={incorrectPassword == true ? true : false}
              fullWidth
              sx={{ marginTop: "15px" }}
              label="Password required"
              InputLabelProps={{ shrink: true }}
              id="password-required"
              name="password"
              type="password"
              value={userPassword}
              onChange={handleInputChange}
              placeholder="Enter a valid password of a SOVA admin at your location to exit full screen mode"
            />
            {incorrectPassword == true && (
              <FormHelperText error>Incorrect password</FormHelperText>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <Button
                color="primary"
                variant="outlined"
                sx={{ mr: 3, mt: 4, mb: 4 }}
                onClick={() => {
                  launchFullScreen();
                  kioskModeSubmitPasswordModalClose();
                }}
              >
                Return to kiosk mode
              </Button>
              <Button
                sx={{ mt: 4, mb: 4 }}
                color="primary"
                variant="contained"
                type="submit"
              >
                Submit password
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default KioskModeSubmitPassword;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { handleApiResonseErrors } from "../../../helper/helper";
import { SingleRecord } from "../../../api";
import moment from "moment";
import PropTypes from "prop-types";
import { Formik } from "formik";
import CustomTextField from "../../form-components/CustomTextField";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  IconButton,
  FormControl as MuiFormControl,
  Box,
  CircularProgress,
  Paper,
  Grid,
  DialogContentText,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const FindRecord = ({ show, handleModal, showAlertTost }) => {
  const [loading, setLoading] = useState(false); //State for loader
  const [recordData, setRecordData] = useState(null);

  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
    borderedBox: {
      border: "1px solid",
      borderBottom: 0,
      borderColor:
        theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
    },
  }));

  const classes = useStyles();

  let initialValues = {
    controlId: "",
  };
  let disposedStatus = ["Lost", "Stored", "Claimed", "Disposed", "Pending"];

  const handleSubmit = async (values) => {
    try {
      if (values.controlId) {
        if (isNaN(values.controlId)) {
          showAlertTost("Please enter a valid number", "error");
        } else {
          setLoading(true);
          setRecordData(null);
          SingleRecord(values.controlId)
            .then((res) => {
              setLoading(false);
              if (res.statusCode === 200) {
                setRecordData(res.data);
              } else {
                showAlertTost(
                  "Nothing found with that control ID. Try again",
                  "error"
                );
              }
            })
            .catch((err) => {
              setLoading(false);
              let errors = err.data.errors;
              let error = handleApiResonseErrors(errors);
              if (error) {
                showAlertTost(error, "error");
              }
            });
        }
      } else {
        showAlertTost("Please enter control Id", "error");
      }
    } catch (error) {
      setLoading(false);
      showAlertTost("Sorry! something wrong please try again", "error");
    }
  };

  function Item(props) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          ...sx,
        }}
        {...other}
      />
    );
  }
  Item.propTypes = {
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
      ),
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  return (
    <>
      <Dialog
        open={show}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="add-key-building-title"
        aria-describedby="add-key-building-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-building-title"
        >
          <Typography variant="h4" component="div">
            Find Lost and Found Record
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => handleModal(false)}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <DialogContentText id="search-modal">
                  Scan or type a Control ID in the box below then press search
                </DialogContentText>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    marginTop: "20px",
                  }}
                >
                  <Item
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                      "& .MuiFormControl-root": {
                        width: "100%",
                      },
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="controlId"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Control ID"
                        autoComplete="off"
                        placeholder="24xxxxxx"
                      />
                    </FormControl>
                  </Item>
                  <Item>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        height: "100%",
                        marginLeft: "-3px",
                      }}
                      type="submit"
                    >
                      <SearchIcon />
                    </Button>
                  </Item>
                </Box>
              </form>
            )}
          </Formik>
          {recordData && (
            <div>
              <Grid container mt={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    style={{ textAlign: "center" }}
                    variant="h4"
                    component="div"
                  >
                    Match Found!
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6} mt={3}>
                  {recordData.image ? (
                    <img src={recordData.image} width="80%" />
                  ) : (
                    <img
                      src="/static/img/lost-found/lost_and_found_pic.jpg"
                      width="80%"
                    />
                  )}
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p>
                    <strong>Record Added: </strong>{" "}
                    {recordData.created
                      ? moment(recordData.created).format("YYYY-MM-DD HH:mm:ss")
                      : ""}
                  </p>
                  <p>
                    <strong>Category: </strong> {recordData.category}
                  </p>
                  <p>
                    <strong>Description: </strong> {recordData.description}
                  </p>
                  <p>
                    <strong>Status: </strong>
                    {disposedStatus[recordData.disposition_status]}
                  </p>
                  <p>
                    <strong>Storage Location: </strong>
                    {recordData.storage_location}
                  </p>
                </Grid>
              </Grid>
              <DialogActions>
                <Link
                  to={"/lostandfound/edit-lost-found/" + recordData.id}
                  style={{ textDecoration: "none" }}
                >
                  <Button color="primary" variant="contained">
                    LF edit
                  </Button>
                </Link>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => handleModal(false)}
                  sx={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </div>
          )}
        </DialogContent>
        {loading && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </Dialog>
    </>
  );
};

export default FindRecord;

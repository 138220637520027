import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";

const Confirmation = ({
  confirmationModal,
  confirmationModalClose,
  updateCommRecord,
}) => {
  const sendVerificationClick = () => {
    updateCommRecord("Yes");
    confirmationModalClose();
  };
  const handleClick = () => {
    updateCommRecord("No");
    confirmationModalClose();
  };
  return (
    <>
      <Dialog
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-desc-modal"
        open={confirmationModal}
        onClose={confirmationModalClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="vcomm-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Confirmation
          </Typography>
          <DialogActions>
            <IconButton
              onClick={confirmationModalClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-desc-modal">
            Records have been edited on this screen, and some of these
            communication methods are unverified for one or more of the
            recipients shown in this list. Send verification emails/text
            messages now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClick}>
            Not now
          </Button>
          <Button
            onClick={sendVerificationClick}
            color="primary"
            variant="contained"
            sx={{ marginLeft: "15px" }}
          >
            Send verification
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Confirmation;

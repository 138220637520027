import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import * as Yup from "yup";
import {
  Button,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  DropDeviceLocation,
  DropDeviceDetail,
  DropMakeListInspection,
  DropModelListInspection,
  DropMadeListInspection,
  AddAedInspectionRecord,
} from "../../../api";
import EquipmentImg from "../../../helper/eqipments";
import moment from "moment";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddVisitorForm(props) {
  const classes = useStyles();
  const [aedLoader, setAedLoader] = useState(false);
  const [aedImage, setAedImage] = useState(null);

  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    inspection_type: "",
    inspection_date: new Date(),
    device_location: "",
    inspected_by: LoggedInUserInfo.alias_name
      ? LoggedInUserInfo.alias_name
      : "",
    device_manufacturer: "",
    device_model: "",
    device_serial_number: "",
    unit_visible_accessible: "",
    reason_unit_visible_accessible: "",
    clean_and_free: "",
    reason_clean_and_free: "",
    electrode_lead_wire: "",
    reason_electrode_lead_wire: "",
    pads_expired: "",
    pads_expiry_date: null,
    battery_expired: "",
    battery_expiry_date: null,
    cabinet_alarm_working: "",
    reason_cabinet_alarm_working: "",
    electrode_connected_packed: "",
    reason_cabinet_electrode_connected_packed: "",
    // Initial for battery power
    battery_status_red: "",
    reason_battery_status_red: "",
    battery_status_green: "",
    reason_battery_status_green: "",
    voice_prompt_speaker: "",
    reason_voice_prompt_speaker: "",
    aed_work_red: "",
    reason_aed_work_red: "",
    close_aed_work_green: "",
    reason_close_aed_work_green: "",
    green_checkmark: "",
    reason_green_checkmark: "",
    // end battery
    extra_pads: "",
    // reason_extra_pads: ""
    accessories_pads_expired: "",
    accessories_pad_expiry_date: null,
    extra_battery: "",
    // reason_extra_battery: "",
    accessories_battery_expired: "",
    accessories_battery_expiry_date: null,
    child_pad_key: "",
    // reason_child_pad_key: "",
    examination_gloves: "",
    // reason_examination_gloves: "",
    gauze_towel: "",
    // reason_gauze_towel: "",
    pocket_mask: "",
    // reason_pocket_mask: "",
    razor: "",
    // reason_razor: "",
  };

  const validationSchema = Yup.object().shape({
    inspection_type: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Monthly", "Yearly"]),
    inspection_date: Yup.string().required("Please select inspection date"),
    device_location: Yup.string().required("Please select device location"),
    inspected_by: Yup.string().required("Please enter details"),
    device_manufacturer: Yup.string().required("Please enter details"),
    device_model: Yup.string().required("Please enter details"),
    device_serial_number: Yup.string().required("Please enter details"),
    unit_visible_accessible: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    reason_unit_visible_accessible: Yup.string().when(
      ["unit_visible_accessible"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    clean_and_free: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    reason_clean_and_free: Yup.string().when(
      ["clean_and_free"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    electrode_lead_wire: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    reason_electrode_lead_wire: Yup.string().when(
      ["electrode_lead_wire"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    pads_expired: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    pads_expiry_date: Yup.string()
      .nullable()
      .when(["pads_expired"], (same, schema) => {
        return same === "Yes"
          ? schema.required("Please enter details")
          : schema;
      }),
    battery_expired: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    battery_expiry_date: Yup.string()
      .nullable()
      .when(["battery_expired"], (same, schema) => {
        return same === "Yes"
          ? schema.required("Please enter details")
          : schema;
      }),
    cabinet_alarm_working: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    reason_cabinet_alarm_working: Yup.string().when(
      ["cabinet_alarm_working"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    electrode_connected_packed: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    reason_cabinet_electrode_connected_packed: Yup.string().when(
      ["electrode_connected_packed"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    battery_status_red: Yup.string().when(["device_model"], (same, schema) => {
      return same === "Powerheart G3 Plus"
        ? schema
            .required("Please select one of these options")
            .oneOf(["Yes", "No"])
        : schema;
    }),
    reason_battery_status_red: Yup.string().when(
      ["battery_status_red"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    battery_status_green: Yup.string().when(
      ["device_model"],
      (same, schema) => {
        return same === "Powerheart G3 Plus"
          ? schema
              .required("Please select one of these options")
              .oneOf(["Yes", "No"])
          : schema;
      }
    ),
    reason_battery_status_green: Yup.string().when(
      ["battery_status_green"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    voice_prompt_speaker: Yup.string().when(
      ["device_model"],
      (same, schema) => {
        return same === "Powerheart G3 Plus"
          ? schema
              .required("Please select one of these options")
              .oneOf(["Yes", "No"])
          : schema;
      }
    ),
    reason_voice_prompt_speaker: Yup.string().when(
      ["voice_prompt_speaker"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    aed_work_red: Yup.string().when(["device_model"], (same, schema) => {
      return same === "Powerheart G3 Plus"
        ? schema
            .required("Please select one of these options")
            .oneOf(["Yes", "No"])
        : schema;
    }),
    reason_aed_work_red: Yup.string().when(["aed_work_red"], (same, schema) => {
      return same === "No" ? schema.required("Please enter details") : schema;
    }),
    close_aed_work_green: Yup.string().when(
      ["device_model"],
      (same, schema) => {
        return same === "Powerheart G3 Plus"
          ? schema
              .required("Please select one of these options")
              .oneOf(["Yes", "No"])
          : schema;
      }
    ),
    reason_close_aed_work_green: Yup.string().when(
      ["close_aed_work_green"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    green_checkmark: Yup.string().when(["device_model"], (same, schema) => {
      return same !== "Powerheart G3 Plus" && same !== ""
        ? schema
            .required("Please select one of these options")
            .oneOf(["Yes", "No"])
        : schema;
    }),
    reason_green_checkmark: Yup.string().when(
      ["green_checkmark"],
      (same, schema) => {
        return same === "No" ? schema.required("Please enter details") : schema;
      }
    ),
    extra_pads: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    accessories_pads_expired: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    accessories_pad_expiry_date: Yup.string()
      .nullable()
      .when(["accessories_pads_expired"], (same, schema) => {
        return same === "Yes"
          ? schema.required("Please enter details")
          : schema;
      }),
    extra_battery: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    accessories_battery_expired: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    accessories_battery_expiry_date: Yup.string()
      .nullable()
      .when(["accessories_battery_expired"], (same, schema) => {
        return same === "Yes"
          ? schema.required("Please enter details")
          : schema;
      }),
    child_pad_key: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    examination_gloves: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    gauze_towel: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    pocket_mask: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    razor: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setAedLoader(true);
      let formatInspectionDate = values.inspection_date
        ? moment(values.inspection_date).format("YYYY-MM-DD")
        : "";
      let formatPadExpDate = values.pads_expiry_date
        ? moment(values.pads_expiry_date).format("YYYY-MM-DD")
        : "";
      let formatBatteryExpDate = values.battery_expiry_date
        ? moment(values.battery_expiry_date).format("YYYY-MM-DD")
        : "";
      let formatAccPadExpDate = values.accessories_pad_expiry_date
        ? moment(values.accessories_pad_expiry_date).format("YYYY-MM-DD")
        : "";
      let formatAccBatteryExpDate = values.accessories_battery_expiry_date
        ? moment(values.accessories_battery_expiry_date).format("YYYY-MM-DD")
        : "";
      let formData = {
        inspection_type: values.inspection_type,
        inspection_date: formatInspectionDate,
        device_location: values.device_location,
        inspected_by: values.inspected_by,
        device_manufacturer: values.device_manufacturer,
        device_model: values.device_model,
        device_serial_number: values.device_serial_number,
        unit_visible_accessible: values.unit_visible_accessible,
        reason_unit_visible_accessible: values.reason_unit_visible_accessible,
        clean_and_free: values.clean_and_free,
        reason_clean_and_free: values.reason_clean_and_free,
        electrode_lead_wire: values.electrode_lead_wire,
        reason_electrode_lead_wire: values.reason_electrode_lead_wire,
        pads_expired: values.pads_expired,
        pads_expiry_date: formatPadExpDate,
        battery_expired: values.battery_expired,
        battery_expiry_date: formatBatteryExpDate,
        cabinet_alarm_working: values.cabinet_alarm_working,
        reason_cabinet_alarm_working: values.reason_cabinet_alarm_working,
        electrode_connected_packed: values.electrode_connected_packed,
        reason_cabinet_electrode_connected_packed:
          values.reason_cabinet_electrode_connected_packed,
        battery_status_red: values.battery_status_red,
        reason_battery_status_red: values.reason_battery_status_red,
        battery_status_green: values.battery_status_green,
        reason_battery_status_green: values.reason_battery_status_green,
        voice_prompt_speaker: values.voice_prompt_speaker,
        reason_voice_prompt_speaker: values.reason_voice_prompt_speaker,
        aed_work_red: values.aed_work_red,
        reason_aed_work_red: values.reason_aed_work_red,
        close_aed_work_green: values.close_aed_work_green,
        reason_close_aed_work_green: values.reason_close_aed_work_green,
        green_checkmark: "Yes", //values.green_checkmark,
        reason_green_checkmark: values.reason_green_checkmark,
        extra_pads: values.extra_pads,
        accessories_pads_expired: values.accessories_pads_expired,
        accessories_pad_expiry_date: formatAccPadExpDate,
        extra_battery: values.extra_battery,
        accessories_battery_expired: values.accessories_battery_expired,
        accessories_battery_expiry_date: formatAccBatteryExpDate,
        child_pad_key: values.child_pad_key,
        examination_gloves: values.examination_gloves,
        gauze_towel: values.gauze_towel,
        pocket_mask: values.pocket_mask,
        razor: values.razor,
      };
      AddAedInspectionRecord(formData)
        .then((res) => {
          setAedLoader(false);
          if (res.statusCode === 200) {
            navigate({ pathname: "/logs/aed-management" });
            dispatch(getMsg("Aed inspection saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setAedLoader(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          //setPageLoad(false);
        });
    } catch (err) {
      setAedLoader(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      // setPageLoad(false);
    }
  };

  const [locationData, setLocationData] = useState([]);
  //method for getting year dropdown
  const getDeviceLocationList = () => {
    setAedLoader(true);
    DropDeviceLocation()
      .then((res) => {
        setAedLoader(false);
        if (res.statusCode == 200) {
          setLocationData(res.data);
        }
      })
      .catch((err) => {
        setAedLoader(false);
        console.log(err);
      });
  };

  const [manufacturerDetail, setManufacturerDetail] = useState([]);
  const [modelDetail, setModelDetail] = useState([]);
  const [seriesDetail, setSeriesDetail] = useState({});
  //Method for getting make from year dropdown
  // const getDeviceDetails = (v) => {
  //   console.log(locationData);
  //   let LocationId = locationData.find((data) => data.value == v);
  //   console.log(locationData);

  //   // // setVehicleLoader(true);
  //   let payload = {
  //     id: LocationId.key,
  //   };
  //   DropMakeListInspection(payload)
  //     .then((res) => {
  //       // setVehicleLoader(false);
  //       if (res.statusCode == 200) {
  //         setManufacturerDetail(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       // setVehicleLoader(false);
  //       setManufacturerDetail([]);
  //       console.log(err);
  //     });
  //   DropModelListInspection(payload)
  //     .then((res) => {
  //       // setVehicleLoader(false);
  //       if (res.statusCode == 200) {
  //         setModelDetail(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       // setVehicleLoader(false);
  //       console.log(err);
  //     });
  //   DropMadeListInspection(payload)
  //     .then((res) => {
  //       // setVehicleLoader(false);
  //       if (res.statusCode == 200) {
  //         setSeriesDetail(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       // setVehicleLoader(false);
  //       console.log(err);
  //     });
  // };

  const [modelImgData, setModelImgData] = useState(
    "/static/img/lost-found/lost_and_found_pic.jpg"
  );
  const getMImgList = (v) => {
    let imgs = EquipmentImg.find((data) => data.id == v);
    setModelImgData(imgs.path);
  };

  useEffect(() => {
    getDeviceLocationList();
  }, []);

  return (
    <Card mb={6}>
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          // enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setFieldValue,
          }) => {
            if (isSubmitting) {
              const firstErrorKey = Object.keys(errors)[0];
              if (firstErrorKey) {
                const firstErrorField = document.querySelector(
                  `[name="${firstErrorKey}"]`
                );
                if (firstErrorField) {
                  firstErrorField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }
                firstErrorField.focus();
              }
            }
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <Typography>
                      Complete the form below to add an inspection to one of
                      your AED's. The device manufacturer, model, serial number
                      and image automatically populate based on the selection
                      you make in the 'Device Location' dropdown below. If after
                      selecting location for the AED, the data or image shown is
                      not correct, cancel this inspection and correct the AED
                      first, then start a new inspection.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Inspection type *</FormLabel>
                      <RadioGroup
                        name="inspection_type"
                        aria-labelledby="inspetion-type"
                        value={values.inspection_type.toString()}
                        onChange={(e) =>
                          setFieldValue(
                            "inspection_type",
                            e.currentTarget.value
                          )
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Monthly"
                          value="Monthly"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Yearly"
                          value="Yearly"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.inspection_type && errors.inspection_type}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <DatePicker
                        name="inspection_date"
                        label="Inspection date"
                        value={values.inspection_date}
                        onChange={(newValue) => {
                          setFieldValue("inspection_date", newValue);
                        }}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="inspection_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              // placeholder: "09/13/2022",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel shrink id="make-select">
                        Device location *
                      </InputLabel>
                      <Select
                        labelId="make"
                        id="make-select"
                        name="device_location"
                        // label="Device location *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.device_location}
                        onChange={(e) => {
                          setFieldValue("device_location", e.target.value);
                          // getDeviceDetails(e.target.value);
                          let LocationId = locationData.find(
                            (data) => data.value == e.target.value
                          );
                          let payload = {
                            id: LocationId?.key,
                          };
                          setAedLoader(true);
                          DropMakeListInspection(payload)
                            .then((res) => {
                              setAedLoader(false);
                              if (res.statusCode == 200) {
                                setManufacturerDetail(res.data);
                                let manId = res.data.map((i) => i.value);
                                setFieldValue(
                                  "device_manufacturer",
                                  manId.toString()
                                );
                              }
                            })
                            .catch((err) => {
                              setAedLoader(false);
                              console.log(err);
                            });
                          DropModelListInspection(payload)
                            .then((res) => {
                              setAedLoader(false);
                              if (res.statusCode == 200) {
                                let devId = res.data.map((i) => i.value);
                                setModelDetail(res.data);
                                setFieldValue("device_model", devId.toString());
                                getMImgList(devId);
                              }
                            })
                            .catch((err) => {
                              setAedLoader(false);
                              console.log(err);
                            });
                          DropMadeListInspection(payload)
                            .then((res) => {
                              setAedLoader(false);
                              if (res.statusCode == 200) {
                                setSeriesDetail(res.data);
                                setAedImage(res?.data?.aed_image);
                                setFieldValue(
                                  "device_serial_number",
                                  res.data.device_serial_number
                                );
                              }
                            })
                            .catch((err) => {
                              setAedLoader(false);
                              console.log(err);
                            });
                          // setFieldValue("model", "");
                          // setModelData([]);
                          // setModelImgData(null);
                          // getModelList(e.target.value);
                          // console.log(e);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.device_location && errors.device_location
                        )}
                        // helperText={
                        //   touched.device_location && errors.device_location
                        // }
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          --Click to Select--
                        </MenuItem>
                        {locationData.map((data) => {
                          return (
                            <MenuItem value={data.value} key={data.key}>
                              {data.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.device_location && errors.device_location}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="inspected_by"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Inspected by"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="device_manufacturer"
                        fieldLabel="Device manufacturer"
                        options={manufacturerDetail}
                        displayEmpty
                        disabled
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="device_model"
                        fieldLabel="Device model"
                        options={modelDetail}
                        displayEmpty
                        disabled
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="device_serial_number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Device serial number"
                        autoComplete="off"
                        placeholder=""
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      variant="outlined"
                      sx={{
                        mb: "15px",
                      }}
                    >
                      <CardContent sx={{ height: "180px" }}>
                        <Image
                          sx={{
                            width: "100%",
                          }}
                          component="img"
                          alt=""
                          src={aedImage ? aedImage : modelImgData}
                          // src={`/static/img/lost-found/lost_and_found_pic.jpg`}
                        />
                      </CardContent>
                    </Card>
                    <Typography>(Image above may vary)</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" component="div" gutterBottom>
                      AED INSPECTION CRITERIA
                    </Typography>
                    <Typography>
                      Inspection the AED for obvious signs of damage:
                    </Typography>
                    <Divider my={6} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Unit visible and accessible *</FormLabel>
                      <RadioGroup
                        aria-labelledby="unit_visible_accessible"
                        name="unit_visible_accessible"
                        value={values.unit_visible_accessible.toString()}
                        onChange={(e) => {
                          setFieldValue(
                            "unit_visible_accessible",
                            e.currentTarget.value
                          );
                          setFieldValue("reason_unit_visible_accessible", "");
                        }}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.unit_visible_accessible &&
                          errors.unit_visible_accessible}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display:
                        values.unit_visible_accessible === "No"
                          ? "block"
                          : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="reason_unit_visible_accessible"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // label="Company or other info"
                        autoComplete="off"
                        placeholder="Provide details"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Unit clean and free of damage *</FormLabel>
                      <RadioGroup
                        aria-labelledby="damaged-unit-status"
                        name="clean_and_free"
                        value={values.clean_and_free.toString()}
                        onChange={(e) => {
                          setFieldValue(
                            "clean_and_free",
                            e.currentTarget.value
                          );
                          setFieldValue("reason_clean_and_free", "");
                        }}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.clean_and_free && errors.clean_and_free}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display:
                        values.clean_and_free === "No" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="reason_clean_and_free"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // label="Company or other info"
                        autoComplete="off"
                        placeholder="Provide details"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>
                        Electrode lead wires are free of damage *
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="electrode-lead-status"
                        name="electrode_lead_wire"
                        value={values.electrode_lead_wire.toString()}
                        onChange={(e) => {
                          setFieldValue(
                            "electrode_lead_wire",
                            e.currentTarget.value
                          );
                          setFieldValue("reason_electrode_lead_wire", "");
                        }}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.electrode_lead_wire &&
                          errors.electrode_lead_wire}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display:
                        values.electrode_lead_wire === "No" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="reason_electrode_lead_wire"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // label="Company or other info"
                        autoComplete="off"
                        placeholder="Provide details"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Pads are expired *</FormLabel>
                      <RadioGroup
                        aria-labelledby="pads-expired-status"
                        name="pads_expired"
                        value={values.pads_expired.toString()}
                        onChange={(e) => {
                          setFieldValue("pads_expired", e.currentTarget.value);
                          setFieldValue("pads_expiry_date", null);
                        }}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.pads_expired && errors.pads_expired}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: values.pads_expired === "Yes" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <DatePicker
                        label="Enter expiration date on pads"
                        value={values.pads_expiry_date}
                        onChange={(newValue) => {
                          setFieldValue("pads_expiry_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="pads_expiry_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              // placeholder: "09/13/2022",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Battery is expired *</FormLabel>
                      <RadioGroup
                        aria-labelledby="battery-expired-status"
                        name="battery_expired"
                        value={values.battery_expired.toString()}
                        onChange={(e) => {
                          setFieldValue(
                            "battery_expired",
                            e.currentTarget.value
                          );
                          setFieldValue("battery_expiry_date", null);
                        }}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.battery_expired && errors.battery_expired}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display:
                        values.battery_expired === "Yes" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <DatePicker
                        label="Enter expiration date on battery"
                        value={values.battery_expiry_date}
                        onChange={(newValue) => {
                          setFieldValue("battery_expiry_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="battery_expiry_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              // placeholder: "09/13/2022",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Cabinet alarm functioning *</FormLabel>
                      <RadioGroup
                        aria-labelledby="cabinet-functioning"
                        name="cabinet_alarm_working"
                        value={values.cabinet_alarm_working.toString()}
                        onChange={(e) => {
                          setFieldValue(
                            "cabinet_alarm_working",
                            e.currentTarget.value
                          );
                          setFieldValue("reason_cabinet_alarm_working", "");
                        }}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.cabinet_alarm_working &&
                          errors.cabinet_alarm_working}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display:
                        values.cabinet_alarm_working === "No"
                          ? "block"
                          : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="reason_cabinet_alarm_working"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // label="Company or other info"
                        autoComplete="off"
                        placeholder="Provide details"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>
                        Electrodes are connected and sealed in package *
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="electrode_connected_packed"
                        name="electrode_connected_packed"
                        value={values.electrode_connected_packed.toString()}
                        onChange={(e) => {
                          setFieldValue(
                            "electrode_connected_packed",
                            e.currentTarget.value
                          );
                          setFieldValue(
                            "reason_cabinet_electrode_connected_packed",
                            ""
                          );
                        }}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.electrode_connected_packed &&
                          errors.electrode_connected_packed}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display:
                        values.electrode_connected_packed === "No"
                          ? "block"
                          : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="reason_cabinet_electrode_connected_packed"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // label="Company or other info"
                        autoComplete="off"
                        placeholder="Provide details"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" component="div" gutterBottom>
                      VERIFY BATTERY POWER
                    </Typography>
                    <Typography>
                      Power up the AED (if required) and observe the following:
                    </Typography>
                    <Divider my={6} />
                  </Grid>
                  {/* Verify battery power 1st condition start */}
                  {values.device_model == "Powerheart G3 Plus" &&
                    values.device_model !== "" && (
                      <Grid item xs={12}>
                        <Box>
                          <Grid container spacing={6}>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              // sx={{
                              //   display:
                              //     values.inspection_type === "Monthly" ? "block" : "none",
                              // }}
                            >
                              <FormControl>
                                <FormLabel>
                                  Open AED lid, observe status indicator change
                                  to RED *
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="aed-lid"
                                  name="battery_status_red"
                                  value={values.battery_status_red.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "battery_status_red",
                                      e.currentTarget.value
                                    );
                                    setFieldValue(
                                      "reason_battery_status_red",
                                      ""
                                    );
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                                <FormHelperText
                                  error={true}
                                  sx={{ marginLeft: 0 }}
                                >
                                  {touched.battery_status_red &&
                                    errors.battery_status_red}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.battery_status_red === "No"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="reason_battery_status_red"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Provide details"
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              // sx={{
                              //   display:
                              //     values.battery_status_red === "No" ? "block" : "none",
                              // }}
                            >
                              <FormControl>
                                <FormLabel>
                                  After approximately 5 seconds, verify that
                                  status indicator returns to GREEN *
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="approx-time"
                                  name="battery_status_green"
                                  value={values.battery_status_green.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "battery_status_green",
                                      e.currentTarget.value
                                    );
                                    setFieldValue(
                                      "reason_battery_status_green",
                                      ""
                                    );
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                                <FormHelperText
                                  error={true}
                                  sx={{ marginLeft: 0 }}
                                >
                                  {touched.battery_status_green &&
                                    errors.battery_status_green}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.battery_status_green === "No"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="reason_battery_status_green"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Provide details"
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              // sx={{
                              //   display: inspectionType === "monthly" ? "block" : "none",
                              // }}
                            >
                              <FormControl>
                                <FormLabel>
                                  Voice prompts are audible and speaker is
                                  working properly *
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="voice-prompts"
                                  name="voice_prompt_speaker"
                                  value={values.voice_prompt_speaker.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "voice_prompt_speaker",
                                      e.currentTarget.value
                                    );
                                    setFieldValue(
                                      "reason_voice_prompt_speaker",
                                      ""
                                    );
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                                <FormHelperText
                                  error={true}
                                  sx={{ marginLeft: 0 }}
                                >
                                  {touched.voice_prompt_speaker &&
                                    errors.voice_prompt_speaker}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.voice_prompt_speaker === "No"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="reason_voice_prompt_speaker"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Provide details"
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              // sx={{
                              //   display: inspectionType === "monthly" ? "block" : "none",
                              // }}
                            >
                              <FormControl>
                                <FormLabel>
                                  Close AED lid, observe status indicator change
                                  to RED *
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="close-aed"
                                  name="aed_work_red"
                                  value={values.aed_work_red.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "aed_work_red",
                                      e.currentTarget.value
                                    );
                                    setFieldValue("reason_aed_work_red", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                                <FormHelperText
                                  error={true}
                                  sx={{ marginLeft: 0 }}
                                >
                                  {touched.aed_work_red && errors.aed_work_red}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.aed_work_red === "No"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="reason_aed_work_red"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Provide details"
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              // sx={{
                              //   display: inspectionType === "monthly" ? "block" : "none",
                              // }}
                            >
                              <FormControl>
                                <FormLabel>
                                  After approximately 5 seconds, verify that
                                  status indicator returns to GREEN *
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="status-indicator"
                                  name="close_aed_work_green"
                                  value={values.close_aed_work_green.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "close_aed_work_green",
                                      e.currentTarget.value
                                    );
                                    setFieldValue(
                                      "reason_close_aed_work_green",
                                      ""
                                    );
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                                <FormHelperText
                                  error={true}
                                  sx={{ marginLeft: 0 }}
                                >
                                  {touched.close_aed_work_green &&
                                    errors.close_aed_work_green}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.close_aed_work_green === "No"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="reason_close_aed_work_green"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Provide details"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  {/* Verify battery power 1st condition end */}

                  {/* Verify battery power 2st condition start*/}
                  {values.device_model !== "Powerheart G3 Plus" &&
                    values.device_model !== "" && (
                      <Grid item xs={12}>
                        <Box>
                          <Grid container spacing={6}>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              // sx={{
                              //   display: inspectionType === "monthly" ? "block" : "none",
                              // }}
                            >
                              <FormControl>
                                <FormLabel>
                                  Verify that green checkmark, hourglass or
                                  green light is illuminated *
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="status-indicator"
                                  name="green_checkmark"
                                  value={values.green_checkmark.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "green_checkmark",
                                      e.currentTarget.value
                                    );
                                    setFieldValue("reason_green_checkmark", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                                <FormHelperText
                                  error={true}
                                  sx={{ marginLeft: 0 }}
                                >
                                  {touched.green_checkmark &&
                                    errors.green_checkmark}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.green_checkmark === "No"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="reason_green_checkmark"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Provide details"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  {/* Verify battery power 2st condition end */}
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" component="div" gutterBottom>
                      ACCESSORIES
                    </Typography>
                    <Typography>
                      Open up the AED (or cabinet) and verify the following
                      items and present:
                    </Typography>
                    <Divider my={6} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Extra set of pads *</FormLabel>
                      <RadioGroup
                        aria-labelledby="set-of-pads"
                        name="extra_pads"
                        value={values.extra_pads.toString()}
                        onChange={(e) =>
                          setFieldValue("extra_pads", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.extra_pads && errors.extra_pads}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <FormLabel>Pads are expired *</FormLabel>
                          <RadioGroup
                            aria-labelledby="pads-expired"
                            name="accessories_pads_expired"
                            value={values.accessories_pads_expired.toString()}
                            onChange={(e) =>
                              setFieldValue(
                                "accessories_pads_expired",
                                e.currentTarget.value
                              )
                            }
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.accessories_pads_expired &&
                              errors.accessories_pads_expired}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          display:
                            values.accessories_pads_expired === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <FormControl>
                          <DatePicker
                            name="accessories_pad_expiry_date"
                            label="Enter expiration date on pads"
                            value={values.accessories_pad_expiry_date}
                            onChange={(newValue) => {
                              setFieldValue(
                                "accessories_pad_expiry_date",
                                newValue
                              );
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="accessories_pad_expiry_date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  // placeholder: "09/13/2022",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Extra battery *</FormLabel>
                      <RadioGroup
                        aria-labelledby="extra-battery"
                        name="extra_battery"
                        value={values.extra_battery.toString()}
                        onChange={(e) =>
                          setFieldValue("extra_battery", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.extra_battery && errors.extra_battery}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <FormLabel>Battery is expired *</FormLabel>
                          <RadioGroup
                            aria-labelledby="battery-expired"
                            name="accessories_battery_expired"
                            value={values.accessories_battery_expired.toString()}
                            onChange={(e) =>
                              setFieldValue(
                                "accessories_battery_expired",
                                e.currentTarget.value
                              )
                            }
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.accessories_battery_expired &&
                              errors.accessories_battery_expired}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          display:
                            values.accessories_battery_expired === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <FormControl>
                          <DatePicker
                            name="accessories_battery_expiry_date"
                            label="Enter expiration date on battery"
                            value={values.accessories_battery_expiry_date}
                            onChange={(newValue) => {
                              setFieldValue(
                                "accessories_battery_expiry_date",
                                newValue
                              );
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="accessories_battery_expiry_date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  // placeholder: "09/13/2022",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Child pads OR child key *</FormLabel>
                      <RadioGroup
                        aria-labelledby="child-pads"
                        name="child_pad_key"
                        value={values.child_pad_key.toString()}
                        onChange={(e) =>
                          setFieldValue("child_pad_key", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.child_pad_key && errors.child_pad_key}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Examination gloves *</FormLabel>
                      <RadioGroup
                        aria-labelledby="examination-gloves"
                        name="examination_gloves"
                        value={values.examination_gloves.toString()}
                        onChange={(e) =>
                          setFieldValue(
                            "examination_gloves",
                            e.currentTarget.value
                          )
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.examination_gloves &&
                          errors.examination_gloves}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Absorbent guaze/towels *</FormLabel>
                      <RadioGroup
                        aria-labelledby="absorbent-guaze-towels"
                        name="gauze_towel"
                        value={values.gauze_towel.toString()}
                        onChange={(e) =>
                          setFieldValue("gauze_towel", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.gauze_towel && errors.gauze_towel}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Pocket mask with one-way valve *</FormLabel>
                      <RadioGroup
                        aria-labelledby="pocket-mask"
                        name="pocket_mask"
                        value={values.pocket_mask.toString()}
                        onChange={(e) =>
                          setFieldValue("pocket_mask", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.pocket_mask && errors.pocket_mask}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Razor *</FormLabel>
                      <RadioGroup
                        aria-labelledby="razor"
                        name="razor"
                        value={values.razor.toString()}
                        onChange={(e) =>
                          setFieldValue("razor", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                      <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                        {touched.razor && errors.razor}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Submit AED inspection
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </CardContent>
      {aedLoader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </Card>
  );
}

function AddAedInspection() {
  return (
    <React.Fragment>
      <Helmet title="Add AED Inspection" />
      <Typography variant="h3" gutterBottom display="inline">
        Add AED Inspection
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/aed-management">
          AED Dashboard
        </Link>
        <Typography>Add AED Inspection</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddVisitorForm />
    </React.Fragment>
  );
}

export default AddAedInspection;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { useParams } from "react-router-dom";
import {
  Grid,
  IconButton,
  /* Link, */
  /* Breadcrumbs as MuiBreadcrumbs, */
  /* Divider as MuiDivider, */
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Button,
  /* Tooltip, */
  Typography,
  FormGroup,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  MenuItem,
  FormControl,
  InputLabel as MuiInputLabel,
  FormControlLabel,
  Select,
  Switch,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  FormLabel,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import {
  /* Add as AddIcon,
  Visibility as VisibilityIcon, */
  Settings as SettingsIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { AddFloorList, UpdateFloor } from "../../../api";
import { makeStyles } from "@mui/styles";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/propertySlice";
import { useDispatch } from "react-redux";
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const AddEditFloor = ({
  addFloorDialog,
  setAddFloorDialog,
  addFloorOpen,
  addFloorClose,
  showFloorList,
  editFloorData,
  editButtonStatus,
  floorId,
  pageLoader,
  setPageLoader,
}) => {
  const [uploadMedia, setUploadMedia] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      //   onLoad(reader.result);
      setUploadMedia(reader.result);
    };
  };
  const uploadMediaFiles = (e) => {
    if (e.target.files) {
      console.log(e.target.files);
      const files = e.target.files;
      const file = files[0];
      getBase64(file);
      // setUploadMedia(URL.createObjectURL(e.target.files[0]));
    }
  };
  let number = [];
  for (let i = 0; i <= 119; i++) {
    // console.log(i);
    number.push(i);
  }
  //Initial values Formik
  const initialValues = {
    floor: editFloorData
      ? editFloorData.floor_no
        ? editFloorData.floor_no
        : 0
      : 0,
    floor_name: editFloorData
      ? editFloorData.floor_name
        ? editFloorData.floor_name
        : ""
      : "",
    region_name: editFloorData
      ? editFloorData.region_name
        ? editFloorData.region_name
        : ""
      : "",
    floor_bounds: editFloorData
      ? editFloorData.floor_bounds
        ? editFloorData.floor_bounds
        : ""
      : "",
  };
  const validationSchema = Yup.object().shape({
    floor_name: Yup.string().required("Please enter floor name"),
  });
  //Handler for form submission
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setIsDisabled(true);
    setPageLoader(true);
    try {
      let formData = {
        location_id: id,
        floor: values.floor,
        floor_name: values.floor_name,
        region_name: values.region_name,
        floor_image: uploadMedia,
        floor_bounds: values.floor_bounds,
      };
      let updatedData = {
        id: floorId,
        location_id: id,
        floor: values.floor,
        floor_name: values.floor_name,
        region_name: values.region_name,
        floor_image: uploadMedia,
        floor_bounds: values.floor_bounds,
      };
      // const url =
      //   editButtonStatus === true ? `update-floor/${id}` : `add-floor`;
      editButtonStatus === true
        ? UpdateFloor(updatedData)
            .then((res) => {
              setPageLoader(false);
              setIsDisabled(false);
              if (res.statusCode === 200) {
                showFloorList();
                addFloorClose();
                dispatch(getSnackMsg("Floor has been updated successfully"));
                dispatch(getSnackAlert("success"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
              } else {
                dispatch(getSnackMsg("Something went wrong, please try again"));
                dispatch(getSnackAlert("error"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
              }
            })
            .catch((err) => {
              console.log(err);
              setPageLoader(false);
              setIsDisabled(false);
              dispatch(getSnackMsg("Something went wrong, please try again"));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
            })
        : AddFloorList(formData)
            .then((res) => {
              setPageLoader(false);
              setIsDisabled(false);
              if (res.statusCode === 200) {
                showFloorList();
                addFloorClose();
                dispatch(getSnackMsg("Floor has been added successfully"));
                dispatch(getSnackAlert("success"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
              } else {
                dispatch(getSnackMsg("Something went wrong, please try again"));
                dispatch(getSnackAlert("error"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
              }
            })
            .catch((err) => {
              console.log(err);
              setPageLoader(false);
              setIsDisabled(false);
              dispatch(getSnackMsg("Something went wrong, please try again"));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
            });
    } catch (error) {
      //   setStatus({ sent: false });
      //   setErrors({ submit: error.message });
      //   setSubmitting(false);
      console.log(error);
      setPageLoader(false);
      setIsDisabled(false);
    }
  };

  return (
    <>
      <Dialog
        open={addFloorDialog}
        onClose={addFloorClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4">Add/Edit Floor</Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton sx={{ marginRight: "-10px" }} onClick={addFloorClose}>
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent
          repositionOnUpdate={false}
          style={{ paddingTop: "20px" }}
        >
          <DialogContentText>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
                setFieldValue,
              }) => (
                <Card mb={6}>
                  <CardContent>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              fullWidth
                              shrink
                              id="floor-number-label"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Floor
                            </InputLabel>
                            <Select
                              name="floor"
                              labelId="floor-number-label"
                              label="Floor"
                              id="floor-number"
                              value={values.floor}
                              displayEmpty
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                            >
                              {number.map((n, index) => (
                                <MenuItem key={index} value={n}>
                                  {n}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <CustomTextField
                              name="floor_name"
                              label="Floor name *"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter Floor Name"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth sx={{ mt: "10px" }}>
                            <CustomTextField
                              name="floor_image"
                              label="Floor image"
                              type="file"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={uploadMediaFiles}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth sx={{ mt: "10px" }}>
                            <CustomTextField
                              id="region-name"
                              name="region_name"
                              label="Region name"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter Region Name"
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sx={{
                            display:
                              uploadMedia || editFloorData.floor_image
                                ? "block"
                                : "none",
                            height: "100%",
                          }}
                        >
                          <Card variant="outlined" sx={{ height: "150px" }}>
                            <Image
                              sx={{
                                width: "100%",
                              }}
                              //   src={uploadMedia}
                              src={
                                uploadMedia
                                  ? uploadMedia
                                  : editFloorData.floor_image
                              }
                            />
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl fullWidth sx={{ marginTop: "25px" }}>
                            <CustomTextField
                              name="floor_bounds"
                              label="Floor bounds (include brackets)"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="[4747.3444.2.3343444444.233.22222]"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <DialogActions
                        sx={{ paddingRight: "0px", paddingTop: "20px" }}
                      >
                        <Button
                          onClick={() => setAddFloorDialog(false)}
                          variant="outlined"
                        >
                          Close
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          autoFocus
                          disabled={isDisabled}
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </form>
                  </CardContent>
                </Card>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {pageLoader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddEditFloor;

import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Box,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { AddVenue } from "../../../../api";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { id } from "date-fns/locale";

const VenuesEdit = ({
  venuesEditDialog,
  setVenuesEditDialog,
  venuesEditDialogOpen,
  venuesEditDialogClose,
  updatedRow,
  venueList,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(updatedRow);
  const [errMsg, setErrMsg] = useState("");
  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  console.log("data", updatedRow);
  const addVenue = () => {
    let payload = {
      id: formData.id ? formData.id : null,
      venue_name: formData.venue_name,
    };
    console.log("payload", payload);
    if (payload.venue_name == undefined || payload.venue_name === "") {
      setErrMsg("Venue Name Cannot Blank");
    } else {
      AddVenue(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            venuesEditDialogClose();
            venueList();
            if (formData?.id) {
              dispatch(getMsg("Data updated successfully"));
            } else {
              dispatch(getMsg("Data add successfully"));
            }
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {});
    }
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              marginBottom: "100px",
              marginTop: "100px",
            },
          },
        }}
        open={venuesEditDialog}
        onClose={venuesEditDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "10px",
            background: "#d3d3d369",
          }}
        >
          <Typography variant="h5">
            {" "}
            {formData?.id ? "Edit Venue" : "Add Venue"}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setVenuesEditDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ marginTop: "20px" }}>
          <TextField
            fullWidth
            id="outlined"
            label="Venue Name"
            name="venue_name"
            value={formData ? formData.venue_name : ""}
            onChange={handleChange}
            placeholder="Edit Venu Name......."
            // InputLabelProps={{
            //   shrink: true,
            // }}
            sx={{ mt: "10px" }}
          />
          <TextField
            fullWidth
            id="outlined"
            label="Street"
            name="Street"
            placeholder="124 First Street......."
            sx={{ mt: "10px" }}
          />
          <TextField
            fullWidth
            id="outlined"
            label="City"
            name="City"
            placeholder="Dallas......"
            sx={{ mt: "10px", width: "170px" }}
          />
          <TextField
            fullWidth
            id="outlined"
            label="State"
            name="State"
            placeholder="TX......"
            // sx={{width={md:"170px",xs:"200px"}}}
            sx={{ mt: "10px", width: "170px", mx: "12px" }}
          />
          <TextField
            fullWidth
            id="outlined"
            label="Zip"
            name="Zip"
            placeholder="00000......"
            sx={{ mt: "10px", width: "170px" }}
          />
          <TextField
            fullWidth
            id="outlined"
            label="Region"
            name="Region"
            placeholder="Northwest......"
            sx={{ mt: "10px" }}
          />
          <TextField
            fullWidth
            id="outlined"
            label="Supervisor Name"
            name="Supervisor Name"
            placeholder="Bob Smith......"
            sx={{ mt: "10px" }}
          />
          <TextField
            fullWidth
            id="outlined"
            label="Client Supervisor Name"
            name="Client Supervisor Name"
            placeholder="Mary Washington......"
            sx={{ mt: "10px" }}
          />
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="is Default?"
          />

          <FormHelperText error>{errMsg}</FormHelperText>
        </DialogContent>

        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="warning"
            onClick={venuesEditDialogClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              addVenue();
            }}
            variant="contained"
            color="primary"
          >
            Save Venue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VenuesEdit;

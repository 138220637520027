import React from "react";
import { UpdateRequireReview } from "../../../api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  DialogActions,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
const RequireReview = ({ requireReview, requireReviewClose, singleData }) => {
  const [checked, setChecked] = React.useState(
    singleData.review_request == "Y" ? true : false
  );
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setChecked(event.target.checked);
    let data = event.target.checked === true ? "Y" : "N";
    UpdateRequireReview(singleData.IncidentID, { review_request: data })
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(
            getSnackMsg(
              `${
                checked
                  ? "Incident unmarked as review required"
                  : "Incident marked as review required"
              }`
            )
          );
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="requireReviewClose-title"
        aria-describedby="requireReviewClose-description"
        open={requireReview}
        onClose={requireReviewClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="requireReviewClose-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Require Review
          </Typography>
          <DialogActions>
            <IconButton
              onClick={requireReviewClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Checking the box below will require your users to review this IR
            when logging into the website. Unchecking the box will remove this
            requirement.
          </DialogContentText>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="Require review"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={requireReviewClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequireReview;

import React from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { ResetPassword } from "../../api";
import { Alert, Button, TextField, Typography, Paper } from "@mui/material";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const ConfirmPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const ident = urlParams.get("ident");
  const activate = urlParams.get("activate");

  return (
    <>
      <React.Fragment>
        <Brand />
        <Wrapper>
          <Helmet title="Confirm Password" />
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await ResetPassword({
                  password: values.password,
                  confirmPassword: values.confirmPassword,
                  ident,
                  activate,
                });
                // Handle successful password reset (e.g., redirect or show a success message)
              } catch (error) {
                // Handle errors (e.g., set error messages)
                setErrors({ submit: error.message });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, handleChange, values }) => (
              <form onSubmit={handleSubmit} style={{ marginTop: "30px" }}>
                <TextField
                  type="password"
                  name="password"
                  label="Enter Password"
                  fullWidth
                  onChange={handleChange}
                  value={values.password}
                  my={3}
                />
                <TextField
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  fullWidth
                  onChange={handleChange}
                  value={values.confirmPassword}
                  my={3}
                  sx={{ marginTop: "20px" }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: "20px" }}
                >
                  Reset password
                </Button>
              </form>
            )}
          </Formik>
        </Wrapper>
      </React.Fragment>
    </>
  );
};

export default ConfirmPassword;

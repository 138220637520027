import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { DatePicker } from "@mui/lab";
import styled from "styled-components/macro";
import { handleApiResonseErrors } from "../../../helper/helper";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { SaveManualData, DeleteEvent } from "../../../api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  DialogActions,
  Button,
  TextField,
  FormControl as MuiFormControl,
  FormHelperText,
  Avatar,
} from "@mui/material";
import { TimePicker } from "@mui/lab";
import { spacing } from "@mui/system";

import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const EditEventModal = ({
  editEventPopUp,
  setEditEventPopUP,
  editEventPopUPOpen,
  editEventPopUPClose,
  editEventInfo,
  alertMessage,
  eventData,
  setLoading,
  selectDestinationDatesDialogOpen,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    start_date: Yup.date().nullable().required("Please select date."),
    end_date: Yup.date().nullable().required("Please select date."),
    start_time: Yup.date().nullable().required("Required"),
    end_time: Yup.date()
      .nullable()
      .when(
        ["start_time", "start_date", "end_date"],
        (start_time, start_date, end_date, schema) => {
          if (
            start_date &&
            end_date &&
            start_date.getTime() === end_date.getTime()
          ) {
            const startTime = new Date(start_time);
            return schema.min(
              startTime,
              "End Time should be after Start Time on the same date."
            );
          }
          return schema;
        }
      )
      .required("Required"),
  });

  useEffect(() => {
    console.log(editEventInfo);
  }, []);

  let initialValues = {
    name: editEventInfo ? editEventInfo.title : "",
    end_date: editEventInfo
      ? editEventInfo.end_date
        ? moment(editEventInfo.end_date).format("YYYY-MM-DD")
        : null
      : null,
    start_date: editEventInfo
      ? editEventInfo.start_date
        ? moment(editEventInfo.start_date).format("YYYY-MM-DD")
        : null
      : null,
    start_time: editEventInfo
      ? editEventInfo.start_time
        ? editEventInfo.start_date
        : null
      : null,
    end_time: editEventInfo
      ? editEventInfo.end_time != "00:00:00"
        ? editEventInfo.end_date
        : null
      : null,
    notes: editEventInfo ? editEventInfo.notes : "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        id: editEventInfo.id,
        name: values.name,
        end_date: values.end_date,
        start_date: values.start_date,
        start_time: moment(values.start_time).format("HH:mm:ss"),
        end_time: moment(values.end_time).format("HH:mm:ss"),
        notes: values.notes,
      };
      SaveManualData(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            alertMessage(res.message, "success");
            eventData();
            editEventPopUPClose();
          } else {
            alertMessage("Sorry! something wrong please try again", "error");
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            alertMessage(error, "error");
          }
        });
    } catch (error) {}
  };

  const deleteEvent = () => {
    setLoading(true);
    DeleteEvent(editEventInfo.id)
      .then((res) => {
        if (res.statusCode === 200) {
          alertMessage(res.message, "success");
          eventData();
          editEventPopUPClose();
        } else {
          alertMessage("Sorry! something wrong please try again", "error");
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          alertMessage(error, "error");
        }
      });
  };

  return (
    <>
      <Dialog
        open={editEventPopUp}
        onClose={editEventPopUPClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          id="peak-dialog"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <Avatar
              alt={`${editEventInfo ? editEventInfo.title : ""} Profile Image`}
              src={editEventInfo?.profile_image}
            />
            <Typography variant="h4" component="div">
              {editEventInfo ? editEventInfo.title : ""}
            </Typography>
            {editEventInfo && editEventInfo.type == "User" && (
              <Typography variant="subtitle2" component="div">
                {`Total hours assigned this week: ${
                  editEventInfo
                    ? moment
                        .duration(
                          moment(editEventInfo.end_date).diff(
                            moment(editEventInfo.start_date)
                          )
                        )
                        .asHours()
                        .toFixed(2)
                    : ""
                }`}
              </Typography>
            )}
          </div>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={editEventPopUPClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={6} mt={4}>
                    <FormControl>
                      <CustomTextField
                        name="name"
                        label="Title: *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Please enter title"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={6}></Grid>
                  <Grid item xs={6} md={6} mt={3}>
                    <FormControl>
                      <DatePicker
                        label="Start Date:"
                        value={values.start_date ?? ""}
                        name="start_date"
                        onChange={(value) => {
                          let date = value ? moment(value) : "";
                          let dateValue = date ? date.format("YYYY-MM-DD") : "";
                          setFieldValue("start_date", dateValue);
                        }}
                        // minDate={
                        //   values.start_date
                        //     ? new Date(values.start_date)
                        //     : new Date()
                        // }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "yyyy-mm-dd",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={6} mt={3}>
                    <FormControl>
                      <DatePicker
                        label="End Date:"
                        value={values.end_date ?? ""}
                        name="end_date"
                        onChange={(value) => {
                          let date = value ? moment(value) : "";
                          let dateValue = date ? date.format("YYYY-MM-DD") : "";
                          setFieldValue("end_date", dateValue);
                        }}
                        minDate={
                          values.start_date
                            ? new Date(values.start_date)
                            : new Date()
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "yyyy-mm-dd",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} md={6} mt={3}>
                    <FormControl>
                      <TimePicker
                        label="Start Time:"
                        value={values.start_time}
                        name="start_time"
                        onChange={(newValue) => {
                          setFieldValue("start_time", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                      <FormHelperText error>{errors.start_time}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={6} mt={3}>
                    <FormControl>
                      <TimePicker
                        label="End Time:"
                        value={values.end_time}
                        name="end_time"
                        minTime={
                          values.start_date == values.end_date
                            ? new Date(values.start_time)
                            : ""
                        }
                        onChange={(newValue) => {
                          setFieldValue("end_time", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                      <FormHelperText error>{errors.end_time}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} mt={3}>
                    <FormControl>
                      <CustomTextField
                        name="notes"
                        label="Description:"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Please enter notes"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => editEventPopUPClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    editEventPopUPClose();
                    selectDestinationDatesDialogOpen();
                  }}
                >
                  Clone Daily
                </Button>
                <Button variant="outlined" onClick={() => deleteEvent()}>
                  Remove
                </Button>
                <Button variant="contained" type="submit">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default EditEventModal;

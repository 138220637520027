import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import MediaUploadFiles from "./MediaUploadFiles";
import {
  getFileBase64,
  handleApiResonseErrors,
  getMediaId,
} from "../../../helper/helper";

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Card,
  LinearProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  NotInterested as NotInterestedIcon,
  CloudUpload as CloudUploadIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  height: 100px;
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Input = styled("input")({
  display: "none",
});
//Classes Styling
const useStyles = makeStyles((theme) => ({
  BulletList: {
    listStyle: "disc",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "disc",
    },
  },
  decimalList: {
    listStyle: "decimal",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "decimal",
    },
  },
}));
function LinearProgressWithLabel(props) {
  return (
    <Box>
      <Box>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}KB`}</Typography>
      </Box>
      <Box sx={{ minWidth: "150px" }}>
        <LinearProgress
          variant="determinate"
          sx={{ height: "5px" }}
          {...props}
        />
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};
const MediaUpload = ({
  mediaUpload,
  mediaUploadClose,
  incidentId,
  getMediaListing,
}) => {
  const classes = useStyles();

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToUploadFiles = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "uploadFiles" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop: (file) => {
      addNewMedia(file[0], file[0].type);
    },
  });

  const [uploadeMedia, setUploadeMedia] = useState([]);

  const uploadMediaFiles = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let array = Object.entries(event.target.files).map((res) => {
        return res[1];
      });
      addNewMedia(array[0], array[0].type);
    }
  };

  const addNewMedia = (file, type) => {
    getFileBase64(file, (result) => {
      let newFile = {
        file: result,
        media_id: getMediaId(type),
        media_name: "",
        media_description: "",
        isUploaded: false,
      };
      setUploadeMedia([...uploadeMedia, newFile]);
    });
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        aria-labelledby="media-upload-title"
        aria-describedby="media-upload-description"
        open={mediaUpload}
        onClose={mediaUploadClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="media-upload-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Media Upload
          </Typography>
          <DialogActions>
            <IconButton
              onClick={mediaUploadClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
              <Grid container spacing={6}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  textAlign="right"
                  order={{ md: 1, lg: 2 }}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={uploadMediaFiles}
                    />
                    <Button
                      disabled={!hasPermissionToUploadFiles}
                      variant="contained"
                      component="span"
                    >
                      <AddIcon sx={{ marginRight: "5px" }} /> Add files...
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} md={9} order={{ md: 2, lg: 1 }}>
                  <Typography variant="h5" component="div" gutterBottom>
                    INSTRUCTION
                  </Typography>
                  <List className={classes.decimalList}>
                    <ListItem>
                      <ListItemText primary='Click "Add Files" button to right, navigate to and select the file to include with this IR' />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="You may also drag and drop images onto this window (if browser allows)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="File is placed in queue below. Provide a name and description for the media file" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Click edit button to crop, rotate and annotate image with arrows and text" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary='Click the blue "Upload" button and for the upload to complete' />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Repeat for additional media files that you want to include with the IR" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="When finished close the popup to return to the IR" />
                    </ListItem>
                  </List>
                  <Typography variant="h6" component="div" gutterBottom>
                    Acceptable formats include:
                  </Typography>
                  <List className={classes.BulletList}>
                    <ListItem>
                      <ListItemText primary="Image - (JPG, PNG, GIF)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Video - (MP4*, AVI, MOV, MPEG, MPG, MKV)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Audio - (MP3, WAV)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Documents - (DOC, PDF)" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Typography>
                * Note: Encoding video as MP4 is preferred as it allows the
                videos to play directly in a web browser
              </Typography>
              <MediaUploadFiles
                uploadeMedia={uploadeMedia}
                setUploadeMedia={(data) => setUploadeMedia(data)}
                incidentId={incidentId}
                getMediaListing={() => getMediaListing()}
              />
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MediaUpload;

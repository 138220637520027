export default [
  {
    id: "TourName",
    label: "Tour Name",
  },
  {
    id: "tour_description",
    label: "Tour Description",
    title: "Details about this tour",
  },
  {
    id: "sched",
    label: "Sched",
    title: "Scheduled tours only appear at set times",
  },
  {
    id: "buttons",
    label: "Buttons",
  },
  {
    id: "active",
    label: "Active",
  },
  {
    id: "last_completed",
    label: "Last Completed",
    title: "Datetime tour was last completed",
  },
  {
    id: "image",
    label: "Image",
    title: "Map image of tour",
  },
  {
    id: "action",
    label: "Action",
  },
];

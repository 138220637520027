import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { GetKanbanData, MoveKanbanItem } from "../../../api";
import TaskCard from "./TaskCard";
import { Typography, Box } from "@mui/material";

const Container = styled.div`
  display: flex;
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  margin: 15px;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 280px;
  border-radius: 5px;
  margin-right: 45px;
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Title = styled.span`
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 10px 10px;
  border-radius: 5px;
  align-self: flex-start;
  width: 100%;
`;

const Kanban = ({
  module_id,
  showAlertTost,
  setLoader,
  module,
  reloadTaskList,
}) => {
  //   const [columns, setColumns] = useState(columnsFromBackend);
  const [columns, setColumns] = useState([]);
  const [notStartedTask, setNotStartedTask] = useState([]);
  //   const [notStartedTask, setNotStartedTask] = useState([]);

  const onDragEnd = (result, columns, setColumns) => {
    let statusToChange = parseInt(result.destination.droppableId) + 1;
    let taskId = result.draggableId;
    console.log(taskId);

    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      if (result.source.droppableId > result.destination.droppableId) {
        return;
      }
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      UpdateTaskStatus(taskId, statusToChange);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  useEffect(() => {
    GetTaskData();
  }, [reloadTaskList]);

  const GetTaskData = () => {
    setLoader(true);
    let payload = {
      module_id: module_id,
      module: module,
    };
    GetKanbanData(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          let data = [
            {
              title: "Not Started (" + res.data.pending.length + ")",
              items: res.data.pending,
            },
            {
              title: "In Progress (" + res.data.progress.length + ")",
              items: res.data.progress,
            },
            {
              title: "Completed (" + res.data.completed.length + ")",
              items: res.data.completed,
            },
          ];
          setColumns(data);
          setNotStartedTask(res.data);
        }
        setLoader(false);
      })
      .catch((err) => {});
  };
  const UpdateTaskStatus = (taskId, status) => {
    setLoader(true);
    let payload = {
      id: taskId,
      status: status,
    };
    MoveKanbanItem(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          showAlertTost(res.message, "success");
          GetTaskData();
        } else {
          showAlertTost("Sorry! something wrong please try agin", "error");
        }
        setLoader(false);
      })
      .catch((err) => {
        showAlertTost("Sorry! something wrong please try agin", "error");
      });
  };
  //Task list dialog

  const Image = styled.img`
    width: 100%;
  `;

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      <Container>
        <TaskColumnStyles>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <TaskList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Title>{column.title}</Title>
                    {column.items.length > 0 ? (
                      <>
                        {column.items.map((item, index) => (
                          <TaskCard
                            key={item.id}
                            item={item}
                            index={index}
                            module={module}
                          />
                        ))}
                        {provided.placeholder}
                      </>
                    ) : (
                      <Box
                        sx={{
                          margin: "15px",
                        }}
                      >
                        <Image src={`/static/img/reports/kanban-empty.svg`} />
                        <Typography variant="body2" gutterBottom mt={4}>
                          Drag an exiting task and drop it here to update it's
                          status.Click the plus icon below to add a new task.
                        </Typography>
                      </Box>
                    )}
                  </TaskList>
                )}
              </Droppable>
            );
          })}
        </TaskColumnStyles>
      </Container>
    </DragDropContext>
  );
};

export default Kanban;

export const sova = {
  SOVA: "/",
  LOCATION_ID: localStorage.getItem("location")
    ? JSON.parse(localStorage.getItem("location")).LocationID
    : "",

  //SOVA_API: "http://127.0.0.1:8000/api/v2/", //local

  SOVA_API: "https://sova-api.demoserver.in/api/v2/", //live

  TOKEN: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).token
    : "",

  StripePublishableKey:
    "pk_test_51Jnm3oJaAqi1g4RFbiZ5nnQSwDKaGi19WfDFrwRzhZfHJpAvRVFgNeeQywl7vHLXyKZkmCt9CnMOROu0E46NH4FA00cOr1GsJd",

  GoogleApiKey: "AIzaSyBZ5R4X8XrNL3bo_Bxbs-VKJE-o7oF_SMc",
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

import React, { useState, useEffect } from "react";
import { ViewVisitorLogs } from "../../../../api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button,
  IconButton,
  Typography,
  Card,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const ViewLog = ({ show, handleModel, data }) => {
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState([]);

  useEffect(() => {
    LogList();
  }, []);

  const LogList = () => {
    setLoading(true);
    ViewVisitorLogs(data.id)
      .then((res) => {
        if (res.statusCode == 200) {
          setLists(res.data);
        } else {
          setLists([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="view-log"
        aria-describedby="view-log-desc"
        open={show}
        onClose={() => handleModel(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="vcomm-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Recent Visit for {data.name}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={() => handleModel(false)}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Card variant="outlined" sx={{ borderBottom: 0 }}>
            <TableContainer className="tableContainer">
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Check In (UTC)</TableCell>
                    <TableCell>Checkout (UTC)</TableCell>
                    <TableCell>Host Name</TableCell>
                    <TableCell>Purpose of Visit</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress />
                      </TableCell>
                      <TableCell colSpan={4} />
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {lists.map((row, index) => {
                      return (
                        <TableRow key={row}>
                          <TableCell>{row.checked_in_time}</TableCell>
                          <TableCell>{row.checked_out_time}</TableCell>
                          <TableCell>{row.host_name}</TableCell>
                          <TableCell>{row.purpose_of_visit}</TableCell>
                        </TableRow>
                      );
                    })}
                    {lists.length < 1 && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Card>
        </DialogContent>
        <DialogActions sx={{ paddingRight: "24px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleModel(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewLog;

import axios from "axios";

// const axiosInstance = axios.create({
//   baseURL: "http://sova-v2.demoserver.in/api/v2",
//   headers: {
//     "Content-type": "application/json",
//     Authorization: "Bearer 7|vBbLmX3uT1q4vttxzL8df2cvqdjzvOdVrmW9pGe7",
//   },
// });

const axiosInstance = axios.create({
  baseURL: "http://sova-v2.demoserver.in/api/v2",
});
axiosInstance.defaults.headers.common["Authorization"] =
  "Bearer 7|vBbLmX3uT1q4vttxzL8df2cvqdjzvOdVrmW9pGe7";
// axiosInstance.interceptors.response.use((response) => {
//   console.log(response);
//   return response;
// });
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );

export default axiosInstance;

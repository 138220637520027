import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { AddRoom, LockerRoomlist } from "../../../api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
  Chip,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const AddEditLockerRoom = ({
  addEditLockerRoomDialog,
  addEditLockerRoomDialogClose,
}) => {
  const [text, setText] = useState("");
  const [roomData, setRoomData] = useState([]);
  const roomList = () => {
    LockerRoomlist()
      .then((res) => {
        if (res.statusCode === 200) {
          setRoomData(res.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };

  const handleAdd = (e) => {
    setText("");
    let d = roomData.map((d) => d.key).toString();
    let payload = {
      locker_room: e.target.value + "," + d,
    };
    AddRoom(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
          roomList();
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (chipToDelete) => () => {
    setRoomData((chips) => chips.filter((chip) => chip.key !== chipToDelete));
    let payload = roomData
      .filter((chip) => chip.key !== chipToDelete)
      .map((d) => d.key)
      .toString();
    AddRoom({ locker_room: payload })
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
          roomList();
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    roomList();
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="add-edit-locker-room-title"
        aria-describedby="add-edit-locker-room-desc"
        open={addEditLockerRoomDialog}
        onClose={addEditLockerRoomDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-edit-locker-room-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Locker Rooms
          </Typography>
          <IconButton onClick={addEditLockerRoomDialogClose}>
            <CancelRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-edit-locker-room-desc">
            Click into box below and provide locker room name. Hit the enter key
            on your keyboard to save. Repeat to add additional locker rooms. If
            you make a mistake, delete room and add again.
          </DialogContentText>
          <Card variant="outlined" sx={{ marginTop: "15px" }}>
            <CardContent>
              <Paper
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {roomData.map((data) => {
                  let icon;
                  return (
                    <ListItem key={data.key}>
                      <Chip
                        icon={icon}
                        label={data.key}
                        onDelete={
                          data.label === "" ? undefined : handleDelete(data.key)
                        }
                      />
                    </ListItem>
                  );
                })}
              </Paper>
              <TextField
                placeholder="type to add"
                sx={{ marginTop: "15px" }}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleAdd(e);
                  }
                }}
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={addEditLockerRoomDialogClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditLockerRoom;

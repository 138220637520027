import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import React, { useState, useEffect } from "react";
import DialogBox from "../../components/form-components/DialogBox";
import CustomAlert from "../../components/form-components/CustomAlert";
import AddLockerAssignment from "./inner-component/AddLockerAssignment";
import {
  LockerLogList,
  DeletelockerLog,
  DepartmentList,
  PositionByDepartment,
  LockerRoomlist,
} from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import AssignmentListCol from "../table-columns-json/logs/AssignmentList";
import { setAssignmentList } from "../../redux/slices/logs/logTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  InsertDriveFile as InsertDriveFileIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 120px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Image = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 15px auto 15px;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "465px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  fname,
  lname,
  department,
  position,
  roomname,
  lockernum,
  inspection,
  interval,
  action
) {
  return {
    fname,
    lname,
    department,
    position,
    roomname,
    lockernum,
    inspection,
    interval,
    action,
  };
}
const rows = [
  createData(
    "Darin",
    "Redding",
    "Security test",
    "Director of security",
    "Womens locker",
    "996",
    "04/29/2020",
    "60",
    0
  ),
  createData(
    "Darinnn",
    "Redding",
    "Security test",
    "Director of security",
    "Womens locker",
    "996",
    "04/29/2020",
    "60",
    0
  ),
  createData(
    "aSaas",
    "Housekeeping1",
    "",
    "Lost",
    "14/01/2021 11:41",
    "test",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "fname",
    numeric: true,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "lname",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "department",
    numeric: true,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "roomname",
    numeric: true,
    disablePadding: false,
    label: "Room Name",
  },
  {
    id: "lockernum",
    numeric: true,
    disablePadding: false,
    label: "Locker Num",
  },
  {
    id: "inspection",
    numeric: true,
    disablePadding: false,
    label: "Inspection",
  },
  {
    id: "interval",
    numeric: true,
    disablePadding: false,
    label: "Interval",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {AssignmentListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    tableCalRedux,
    lockerListData,
    removeRecords,
    setDataToFilter,
  } = props;
  const [actions, setActions] = React.useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [pendingInspection, setPendingInspection] = useState(null);
  const selectDepartment = (event) => {
    setDepartment(event.target.value);
  };
  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const [position, setPosition] = React.useState("");
  const [positionList, setPositionList] = useState([]);
  const selectPosition = (event) => {
    setPosition(event.target.value);
  };

  const getPositionList = (value) => {
    // setLoading(true);
    PositionByDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [roomName, setRoomName] = React.useState("");
  const [roomList, setRoomList] = useState([]);
  const selectRoomName = (event) => {
    setRoomName(event.target.value);
  };
  const getLockerRoomlist = () => {
    LockerRoomlist()
      .then((res) => {
        setRoomList(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };
  /**
   * It takes the values of the form fields and sets them to the dataToFilter state
   */
  const applySearch = (value) => {
    const payload = {
      search_value: search,
      fname: fName,
      lname: lName,
      department: department,
      position: position,
      room_name: roomName,
      filter: value,
    };
    setDataToFilter(payload);
  };

  /**
   * It resets the state of the filter form to its default values
   */
  const resetFilter = () => {
    setSearch("");
    setFName("");
    setLName("");
    setDepartment("");
    setPosition("");
    setRoomName("");
    setPendingInspection(null);
  };

  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.assignmentList
  );
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportData, setExportData] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    LockerLogList(1, { limit: totalRecord })
      .then((res) => {
        if (res.statusCode === 200) {
          setExportData(res.data);
        } else {
          setExportData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };
  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [dateRange, setDateRange] = React.useState(null);

  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  // Add Locker Assignments
  const [addLockerAssignmentDialog, setAddLockerAssignmentDialog] =
    useState(false);
  const addLockerAssignmentDialogOpen = () => {
    setAddLockerAssignmentDialog(true);
  };
  const addLockerAssignmentDialogClose = () => {
    setAddLockerAssignmentDialog(false);
  };

  // Toggle Button Group
  const [toggleGroup, setToggleGroup] = React.useState("assignments");
  const hangleToggleButton = (event, newAlignment) => {
    setToggleGroup(newAlignment);
  };

  // Select room
  const [selectRoom, setSelectRoom] = React.useState("");
  const handleSelectRoom = (event) => {
    setSelectRoom(event.target.value);
  };

  // Select status
  const [selectStatus, setSelectStatus] = React.useState("");
  const handleSelectStatus = (event) => {
    setSelectStatus(event.target.value);
  };

  let headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Department", key: "Department" },
    { label: "Position", key: "Position" },
    { label: "Room Name", key: "room_name" },
    { label: "Locker Number", key: "locker_number" },
    { label: "Room Name", key: "room_name" },
    { label: "Locker Number", key: "locker_number" },
    { label: "Inspection", key: "inspection_date" },
    { label: "Interval", key: "interval_days" },
  ];

  useEffect(() => {
    getDepartmentList();
    getLockerRoomlist();
  }, []);

  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value={"Delete"} onClick={removeRecords}>
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: toggleGroup === "assignments" ? "flex" : "none",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                placeholder="Search by name, dept, room, or click arrow"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch({});
                    setIsFilter(true);
                  }
                }}
                inputProps={{}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form>
                            <FormControl mt={3}>
                              <TextField
                                name="fname"
                                fullWidth
                                id="first-name"
                                InputLabelProps={{ shrink: true }}
                                label="First name"
                                placeholder="Mike"
                                value={fName}
                                onChange={(e) => setFName(e.target.value)}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                name="lname"
                                fullWidth
                                id="last-name"
                                InputLabelProps={{ shrink: true }}
                                label="Last name"
                                placeholder="Smith"
                                value={lName}
                                onChange={(e) => setLName(e.target.value)}
                              />
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="department">
                                Department
                              </InputLabel>
                              <Select
                                labelId="department"
                                id="department"
                                value={department}
                                label="Department"
                                onChange={selectDepartment}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select Department</MenuItem>
                                {departmentList.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item.key}
                                      key={item.value}
                                      onClick={() =>
                                        getPositionList(item.value)
                                      }
                                    >
                                      {item.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="position">
                                Position
                              </InputLabel>
                              <Select
                                labelId="position"
                                id="position"
                                value={position}
                                label="Position"
                                onChange={selectPosition}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select Position</MenuItem>
                                {positionList.map((item) => {
                                  return (
                                    <MenuItem value={item.key} key={item.value}>
                                      {item.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="position">
                                Room Name
                              </InputLabel>
                              <Select
                                labelId="position"
                                id="room-name"
                                value={roomName}
                                label="Room Name"
                                onChange={selectRoomName}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select Room</MenuItem>
                                {roomList.map((item) => {
                                  return (
                                    <MenuItem value={item.key} key={item.value}>
                                      {item.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={6}
                                onClick={() => {
                                  applySearch({});
                                  setIsFilter(true);
                                  handleCloseForm();
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  variant="contained"
                  color="primary"
                  mt={6}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                  }}
                >
                  Clear filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    applySearch({});
                    setIsFilter(true);
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
          <Box
            mt={3}
            sx={{
              display: toggleGroup === "map" ? "block" : "none",
              flexWrap: "nowrap",
            }}
          >
            <FormControl>
              <Select
                labelId="department"
                id="select-room"
                value={selectRoom}
                onChange={handleSelectRoom}
                fullWidth
                displayEmpty
              >
                <MenuItem value="">Select room</MenuItem>
                <MenuItem value={10}>Mens locker</MenuItem>
                <MenuItem value={20}>Womens locker</MenuItem>
                <MenuItem value={30}>Vendors locker</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            mt={3}
            sx={{
              display: toggleGroup === "lockers" ? "flex" : "none",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <Box sx={{ display: { xs: "flex" } }}>
                <Item
                  sx={{
                    maxWidth: { xs: "50%" },
                    flex: { xs: "0 0 50%" },
                    width: { xs: "100%" },
                    "& .MuiOutlinedInput-root": {
                      borderTopRightRadius: { xs: 0 },
                      borderBottomRightRadius: { xs: 0 },
                      borderRight: { xs: 0 },
                    },
                  }}
                >
                  <FormControl>
                    <Select
                      labelId="department"
                      id="select-room"
                      value={selectStatus}
                      onChange={handleSelectStatus}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">Select room</MenuItem>
                      <MenuItem value={10}>Mens locker</MenuItem>
                      <MenuItem value={20}>Womens locker</MenuItem>
                      <MenuItem value={30}>Vendors locker</MenuItem>
                    </Select>
                  </FormControl>
                </Item>
                <Item
                  sx={{
                    maxWidth: { xs: "50%" },
                    flex: { xs: "0 0 50%" },
                    width: { xs: "100%" },
                    "& .MuiOutlinedInput-root": {
                      borderTopLeftRadius: { xs: 0 },
                      borderBottomLeftRadius: { xs: 0 },
                    },
                  }}
                >
                  <FormControl>
                    <Select
                      labelId="department"
                      id="select-room"
                      value={selectRoom}
                      onChange={handleSelectRoom}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">Select room</MenuItem>
                      <MenuItem value={10}>Mens locker</MenuItem>
                      <MenuItem value={20}>Womens locker</MenuItem>
                      <MenuItem value={30}>Vendors locker</MenuItem>
                    </Select>
                  </FormControl>
                </Item>
              </Box>
            </Item>
            <Item>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
              >
                <SearchIcon />
              </Button>
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3} sx={{}}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={addLockerAssignmentDialogOpen}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary="Add Locker Assignment"
                  pl={0}
                />
              </MenuItem>
              <MenuItem
                value={pendingInspection}
                onClick={() => {
                  handleClose();
                  // setPendingInspection(1);
                  applySearch("1");
                  setIsFilter(true);
                }}
              >
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary="View Pending Inspection"
                  pl={0}
                />
              </MenuItem>
              <CSVLink
                data={exportData}
                headers={headers}
                onClick={handleClose}
                filename="SOVA-locks"
                style={{ textDecoration: "none", color: "#374151" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Export CSV" pl={0} />
                </MenuItem>
              </CSVLink>

              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setAssignmentList(data));
                      }}
                      listing={AssignmentListCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setAssignmentList(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      <Box
        sx={{
          paddingLeft: "15px",
          paddingRight: "15px",
          marginBottom: "15px",
          marginTop: "15px",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={toggleGroup}
          exclusive
          onChange={hangleToggleButton}
        >
          <ToggleButton value="map" component={NavLink} to="/auth/404" disabled>
            Map
          </ToggleButton>
          <ToggleButton value="lockers" component={NavLink} to="/logs/lockers">
            Lockers
          </ToggleButton>
          <ToggleButton value="assignments">Assignments</ToggleButton>
          <ToggleButton value="locks" component={NavLink} to="/logs/locks">
            Locks
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {addLockerAssignmentDialog && (
        <AddLockerAssignment
          addLockerAssignmentDialog={addLockerAssignmentDialog}
          setAddLockerAssignmentDialog={setAddLockerAssignmentDialog}
          addLockerAssignmentDialogOpen={addLockerAssignmentDialogOpen}
          addLockerAssignmentDialogClose={addLockerAssignmentDialogClose}
          lockerListData={lockerListData}
        />
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [selectedId, setSelectedId] = useState("");

  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const tableCalRedux = useSelector(
    (state) => state.logTableCol.assignmentList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, fname) => {
    const selectedIndex = selected.indexOf(fname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, fname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (fname) => selected.indexOf(fname) !== -1;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setIsEdit(false);
  };

  // Add Locker Assignments
  const [addLockerAssignmentDialog, setAddLockerAssignmentDialog] =
    useState(false);
  const addLockerAssignmentDialogOpen = () => {
    setAddLockerAssignmentDialog(true);
  };
  const addLockerAssignmentDialogClose = () => {
    setAddLockerAssignmentDialog(false);
  };

  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    lockerList();
  }, [rowsPerPage, currentPage, dataToFilter]);

  const lockerList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      ...dataToFilter,
    };
    LockerLogList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setList([]);
          setTotalRecord(0);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const showAlertModal = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const removeRecords = () => {
    if (selectedId || selected.length > 0) {
      setModalMessage("Are you sure you want to delete?");
    } else {
      setModalMessage("You haven't selected any item!");
    }
    handleActionClose();
    setShowDeleteModal(true);
  };

  const handelDeleteAction = (status) => {
    let url = selected.length > 0 ? selected : selectedId;
    if ((status === true && selectedId) || selected.length > 0) {
      setLoading(true);
      DeletelockerLog(url)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            lockerList();
          }
          showAlertModal("success", `Record(s) deleted successfully`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setShowDeleteModal(false);
    }
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          tableCalRedux={tableCalRedux}
          lockerListData={lockerList}
          removeRecords={removeRecords}
          setDataToFilter={setDataToFilter}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={9} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {list.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${row.id}`;

                  return (
                    <TableRow hover key={row.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          onClick={(event) => handleClick(event, row.id)}
                          selected={isItemSelected}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ lg: 'whiteSpace: "nowrap"' }}
                        className={
                          tableCalRedux.first_name === true ? "" : "hide-cell"
                        }
                      >
                        {row.first_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.last_name === true ? "" : "hide-cell"
                        }
                      >
                        {row.last_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.department === true ? "" : "hide-cell"
                        }
                      >
                        {row.Department}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.position === true ? "" : "hide-cell"
                        }
                      >
                        {row.Position}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.room_name === true ? "" : "hide-cell"
                        }
                      >
                        {row.room_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.locker_num === true ? "" : "hide-cell"
                        }
                      >
                        {row.locker_number}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.inspection === true ? "" : "hide-cell"
                        }
                      >
                        {row.inspection_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.interval === true ? "" : "hide-cell"
                        }
                      >
                        {row.interval_days}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.action === true ? "" : "hide-cell"
                        }
                      >
                        <IconButton
                          aria-label="Settings"
                          size="large"
                          aria-owns={anchorEl ? "icon-menu" : undefined}
                          aria-haspopup="true"
                          onClick={(evt) => handleActionClick(evt, row.id)}
                          style={{ color: "#1374C5" }}
                        >
                          <SettingsIcon />
                        </IconButton>
                        <StyledMenu
                          id="icon-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleActionClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              addLockerAssignmentDialogOpen();
                              setIsEdit(true);
                            }}
                          >
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit" pl={1} />
                          </MenuItem>
                          <MenuItem onClick={() => removeRecords()}>
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delete" pl={1} />
                          </MenuItem>
                        </StyledMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {rows.length === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * rows.length }}>
                    <TableCell colSpan={10} align="center">
                      <Image
                        src={`/static/img/map-building.png`}
                        alt=""
                        sx={{ margin: "20px 0" }}
                      />
                      <Typography variant="h4" component="div" gutterBottom>
                        We're Buiding Cool Things.
                      </Typography>
                      <Typography>
                        This screen will eventually show an intractive floor
                        plan allowing you to see current status of lockers
                        visually and make assignments. At your place of business
                        maybe two co-workers want to be located near each other.
                        Or you prefer allowing space so people are distributed
                        evenly throughout the room. This tool will help you
                        achieve that. Keep your eyes on this space!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {list.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {addLockerAssignmentDialog && (
        <AddLockerAssignment
          addLockerAssignmentDialog={addLockerAssignmentDialog}
          setAddLockerAssignmentDialog={setAddLockerAssignmentDialog}
          addLockerAssignmentDialogOpen={addLockerAssignmentDialogOpen}
          addLockerAssignmentDialogClose={addLockerAssignmentDialogClose}
          id={selectedId}
          isEdit={isEdit}
          lockerListData={lockerList}
        />
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}
      <DialogBox
        handleShow={showDeleteModal}
        message={modalMessage}
        conformation={(status) => handelDeleteAction(status)}
      />
    </div>
  );
}

function LockerLog() {
  return (
    <React.Fragment>
      <Helmet title="Locker Assignments" />
      <Typography variant="h3" gutterBottom display="inline">
        Locker Assignments
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LockerLog;

import React, { useState } from "react";
import PropTypes from "prop-types";
import InspectionArea from "./property-inspection-inner-component/InspectionArea";
import PropertyArea from "./property-inspection-inner-component/PropertyArea";
import PropertyAreaManager from "./property-inspection-inner-component/PropertyAreaManager";
import InspectionCategory from "./property-inspection-inner-component/InspectionCategory";
import InspectionQuestion from "./property-inspection-inner-component/InspectionQuestion";
import InspectionResults from "./property-inspection-inner-component/InspectionResult";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Paper as MuiPaper,
  Divider as MuiDivider,
} from "@mui/material";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function aedTabs(index) {
  return {
    id: `incident-tab-${index}`,
    "aria-controls": `incident-tabpanel-${index}`,
  };
}
function PropertyInspection() {
  /* Tabs */
  const [tabValue, setTabValue] = React.useState(2);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <MuiPaper>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Aed-management-tabs"
            variant="scrollable"
          >
            <Tab label="Property Area" {...aedTabs(0)} />
            <Tab label="Property Area Manager" {...aedTabs(1)} />
            <Tab label="Inspection Area" {...aedTabs(2)} />
            <Tab label="Inspection Category" {...aedTabs(3)} />
            <Tab label="Inspection Questions" {...aedTabs(4)} />
            <Tab label="Inspection Results" {...aedTabs(5)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <PropertyArea />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <PropertyAreaManager />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <InspectionArea />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <InspectionCategory />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <InspectionQuestion />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <InspectionResults />
        </TabPanel>
      </MuiPaper>
    </>
  );
}

export default PropertyInspection;

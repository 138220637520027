import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import moment from "moment";
import CustomDateRange from "../../../components/form-components/CustomDateRange";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import { DownloadShipmentsCsv } from "../../../api";

import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  CircularProgress,
  Paper,
  FormControl as MuiFormControl,
  TextField,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const DownloadShipmentCSV = ({ show, handleModal, showAlertPopup }) => {
  const [exportCSVLists, setExportCSVLists] = useState("");
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [fadebox, setFadedBox] = useState(false);
  const [loading, setLoading] = useState(false); //State for loader

  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
  }));

  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    min-width: 148px;
  `;

  const classes = useStyles();

  let exportCSVheaders = [
    { label: "ID", key: "id" },
    { label: "Sender Address", key: "sender_address" },
    { label: "Lostfound ID", key: "lostfound_id" },
    { label: "Recipient Name", key: "recipient_name" },
    { label: "Recipient Address", key: "recipient_address" },
    { label: "Ship Detail By", key: "hotel_ship_details" },
    { label: "Residential", key: "residential_check" },
    { label: "Recipient Phone", key: "recipient_phone" },
    { label: "Recipient Email", key: "recipient_email" },
    { label: "Tracking ID", key: "tracking_id" },
    { label: "Carrier", key: "carrier_chosen" },
    { label: "Service Level", key: "service_level" },
    { label: "Length", key: "length" },
    { label: "Width", key: "width" },
    { label: "Height", key: "height" },
    { label: "Weight", key: "weight" },
    { label: "Amount Paid", key: "amount_paid" },
    { label: "Freight Cost", key: "freight_cost" },
    { label: "Processing Cost", key: "processing_cost" },
    { label: "Sales Tax", key: "sales_tax" },
    { label: "Insurance", key: "insurance_value" },
    { label: "Tracking Status", key: "tracking_status" },
    { label: "Final Tracking Message", key: "final_tracking_message" },
    { label: "Created", key: "created" },
    { label: "Modified", key: "modified" },
  ];

  const getDataToDownloadCSV = (fromDate, toDate) => {
    setLoading(true);
    let payload = {
      from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      forAdmin: true,
    };
    DownloadShipmentsCsv(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setExportCSVLists(res.data);
        } else {
          setExportCSVLists("");
          let msg = "Sorry! record not found on selected date";
          showAlertPopup(msg, "error");
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <Dialog
      open={show}
      aria-labelledby="subitems-title"
      aria-describedby="subitems-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "600px",
          },
        },
      }}
    >
      <DialogTitle
        id="subitems-dialog-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Download CSV
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => handleModal(false)}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>

      <DialogContent>
        <FormControl mt={8}>
          <TextField
            fullWidth
            id="dateRange"
            label="Select data range to download csv"
            value={
              startDateToFilter && endDateToFilter
                ? moment(startDateToFilter).format("MM-DD-YYYY") +
                  " - " +
                  moment(endDateToFilter).format("MM-DD-YYYY")
                : ""
            }
            onClick={() => setFadedBox((prev) => !prev)}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Select data range"
          />
          {fadebox && (
            <CustomDateRange
              handleShow={fadebox}
              conformation={(status) => setFadedBox((prev) => !prev)}
              setDates={(start, end) => {
                getDataToDownloadCSV(start, end);
                setStartDateToFilter(start);
                setEndDateToFilter(end);
              }}
              selectedStart={startDateToFilter}
              selectedEnd={endDateToFilter}
            />
          )}
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          onClick={() => handleModal(false)}
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        {exportCSVLists ? (
          <CSVLink
            data={exportCSVLists}
            headers={exportCSVheaders}
            onClick={() => handleModal(false)}
            filename={`SOVA-shipping-${format(new Date(), "yyyy-MM-dd")}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              variant="contained"
              sx={{ marginLeft: "10px" }}
            >
              Download
            </Button>
          </CSVLink>
        ) : (
          <Button color="primary" variant="contained" disabled={true}>
            Download
          </Button>
        )}
        {loading && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DownloadShipmentCSV;

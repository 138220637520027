import React, { useState, useEffect } from "react";
import Reissueid from "../inner-component/Reissueid";
import CustomAlert from "../../../components/form-components/CustomAlert";

import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import { ReissueList } from "../../../api";
import hasPermission from "../../../utils/hasPermission";

const ReIssueLogs = ({ id }) => {
  const [showReissueIdDialog, setShowReissueIdDialog] = useState(false);
  const [list, setListing] = useState([]);
  // Alert Message
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  useEffect(() => {
    GetIssueLogsList();
  }, []);

  const GetIssueLogsList = () => {
    ReissueList(id)
      .then((res) => {
        setListing(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const messageAlert = (msg, type) => {
    setShowAlert(true);
    setAlertMessage(msg);
    setAlertType(type);
  };

  return (
    <>
      <Typography variant="h4" component="div" gutterBottom sx={{ my: "20px" }}>
        Reissue Logs
      </Typography>
      <Card>
        <CardContent>
          <Card variant="outlined" sx={{ borderBottom: 0 }}>
            <Grid container spacing={4} mt={6}>
              <Grid item xs={12} lg={12} align="right">
                <Button
                  disabled={!hasPermission("Logs", "addReissueId")}
                  color="primary"
                  variant="contained"
                  sx={{ marginRight: "15px" }}
                  onClick={() => setShowReissueIdDialog(true)}
                >
                  Reissue ID
                </Button>
              </Grid>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Reissue Date</TableCell>
                    <TableCell>By</TableCell>
                    <TableCell>Reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list?.map((row, index) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row.reissue_date}</TableCell>
                        <TableCell>{row.By}</TableCell>
                        <TableCell>{row.reason}</TableCell>
                      </TableRow>
                    );
                  })}
                  {list?.length < 1 && (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </CardContent>
      </Card>
      {showReissueIdDialog && (
        <Reissueid
          id={id}
          show={showReissueIdDialog}
          handleModal={(status) => setShowReissueIdDialog(status)}
          GetIssueLogsList={() => GetIssueLogsList()}
          messageAlert={(msg, type) => messageAlert(msg, type)}
        />
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}
    </>
  );
};

export default ReIssueLogs;

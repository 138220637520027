import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import CustomTextField from "../../components/form-components/CustomTextField";
import KioskModeSubmitPassword from "./KioskModeSubmitPassword";

import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  FormControlLabel,
  CircularProgress,
  Paper,
} from "@mui/material";
import { spacing } from "@mui/system";
import { SavePackageDetails } from "../../api";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function PackagesDelivery() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [otherOptions, setOtherOptions] = useState("dhl");
  const [kioskModeSubmitPasswordModal, setKioskModeSubmitPasswordModal] =
    useState(false);
  const kioskModeSubmitPasswordModalClose = () => {
    setKioskModeSubmitPasswordModal(false);
  };
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false); //State for loader

  let initialValues = {
    recipient_first_name: "",
    recipient_last_name: "",
    sender_name: "",
    current_storage_location: "",
    carrier_dropping: "DHL",
  };
  const validationSchema = Yup.object().shape({
    recipient_first_name: Yup.string().required(
      "Please enter recipient first name"
    ),
    recipient_last_name: Yup.string().required(
      "Please enter recipient last name"
    ),
    sender_name: Yup.string().required("Please enter sender name"),
    current_storage_location: Yup.string().required(
      "Please enter storage location"
    ),
    carrier_dropping: Yup.string().required("Please select an option"),
  });
  const handleSubmit = (values) => {
    setLoading(true);
    SavePackageDetails(values)
      .then((response) => {
        setLoading(false);
        if (response.statusCode == 200) {
          setSuccessMessage(true);
          setTimeout(() => {
            setSuccessMessage(false);
            navigate({
              pathname: "/visitors/kiosk-mode",
            });
          }, 3000);
        }
      })
      .catch((err) => {
        setSuccessMessage(false);
        console.log("error", err);
      });
  };
  const launchFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };
  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setKioskModeSubmitPasswordModal(true);
    }
  }

  const updateParentState = () => {
    kioskModeSubmitPasswordModalClose();
  };
  return (
    <>
      <Box sx={{ p: 5, my: "auto" }} className={classes.startOverCard}>
        <Card sx={{ maxWidth: "1140px", mx: "auto" }}>
          <CardHeader
            title={
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    Marigold Hotel
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    Package Drop Off
                  </Typography>
                </Box>
              </>
            }
          />
          <CardContent>
            {successMessage === false ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, handleSubmit, values, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <CustomTextField
                            name="recipient_first_name"
                            id="recipient-first-name"
                            placeholder="Mark"
                            label="Recipient first name"
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <CustomTextField
                            name="recipient_last_name"
                            id="recipient-last-name"
                            placeholder="Smith"
                            label="Recipient last name"
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <CustomTextField
                            name="sender_name"
                            id="sender-name"
                            placeholder="Name or company"
                            label="Sender name"
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <CustomTextField
                            name="current_storage_location"
                            id="delivered-to"
                            placeholder="Package drop off spot"
                            label="Delivered to"
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="carrier-dropping"
                            name="carrier_dropping"
                            value={values.carrier_dropping}
                            onChange={(e) =>
                              setFieldValue("carrier_dropping", e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="DHL"
                              control={<Radio />}
                              label="DHL"
                            />
                            <FormControlLabel
                              value="Fed-Ex"
                              control={<Radio />}
                              label="Fed-Ex"
                            />
                            <FormControlLabel
                              value="UPS"
                              control={<Radio />}
                              label="UPS"
                            />
                            <FormControlLabel
                              value="USPS"
                              control={<Radio />}
                              label="USPS"
                            />
                            <FormControlLabel
                              value="Amazon"
                              control={<Radio />}
                              label="Amazon"
                            />
                            <FormControlLabel
                              value="OnTrac"
                              control={<Radio />}
                              label="OnTrac"
                            />
                            <FormControlLabel
                              value="Other"
                              control={<Radio />}
                              label="Other"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          display: otherOptions === "other" ? "block" : "none",
                        }}
                      >
                        <FormControl>
                          <CustomTextField
                            label="Carrier name"
                            placeholder="Other shipping carrier"
                            name="other_pack_drop"
                            id="other-pack-name"
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Done
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            ) : (
              <>
                <Typography sx={{ textAlign: "center" }}>
                  Package accepted. Thank you for using our self-service kiosk.
                </Typography>

                <Typography sx={{ textAlign: "center" }}>
                  Returning to main page in few seconds...
                </Typography>
              </>
            )}
          </CardContent>
          {successMessage === false && (
            <CardActions sx={{ justifyContent: "flex-end", mt: "15px" }}>
              <Button component={NavLink} to="/visitors/kiosk-mode/">
                Start over
              </Button>
            </CardActions>
          )}
        </Card>
      </Box>
      {kioskModeSubmitPasswordModal && (
        <KioskModeSubmitPassword
          kioskModeSubmitPasswordModal={kioskModeSubmitPasswordModal}
          setKioskModeSubmitPasswordModal={setKioskModeSubmitPasswordModal}
          kioskModeSubmitPasswordModalClose={kioskModeSubmitPasswordModalClose}
          launchFullScreen={launchFullScreen}
          onDataFromChild={updateParentState}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

export default PackagesDelivery;

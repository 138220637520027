import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import AddKeyGroup from "./innerform/AddKeyGroup";
import AddKeyRing from "./innerform/AddKeyRing";
import AddKeyCabinet from "./innerform/AddKeyCabinet";
import AddKeyDoor from "./innerform/AddKeyDoor";
import AddKeyLockNumber from "./innerform/AddKeyLockNumber";
import AddKeyBuilding from "./innerform/AddKeyBuilding";
import AddKeyType from "./innerform/AddKeyType";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../tasks/Toaster";
import CustomCheckboxTree from "../../components/form-components/CustomCheckboxTree";

import {
  GetAddKeyRingList,
  GetAddKeyCabinetList,
  GetAddKeyDoorList,
  GetAddKeyLockNumberList,
  GetAddKeyBuildingList,
  GetAddKeyTypeList,
  AddEquip,
  EquipmentTypeTree,
  KeyGroupTree,
} from "../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/equipmentSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Paper,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import AddEquipmentType from "./innerform/AddEquipmentType";
import { useState } from "react";
import { handleApiResonseErrors } from "../../helper/helper";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledSelectTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-12px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },

  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function ColumnLeft() {
  let navigate = useNavigate();
  const [openaddequip, setOpenAddEquip] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const addEquipOpen = () => {
    setOpenAddEquip(true);
  };

  const addEquipClose = () => {
    setOpenAddEquip(false);
  };

  // let sl = [];
  // for (let i = 1; i <= 10; i++) {
  //   sl.push(i);
  // }
  const sl = Array.from({ length: 10 }, (_, i) => i + 1);

  const addToList = (setList, getList) => {
    getList()
      .then((res) => {
        if (res.statusCode === 200) {
          setList(res.data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [keyRingList, setKeyRingList] = useState([]);
  const addKeyRingList = () => addToList(setKeyRingList, GetAddKeyRingList);

  const [keyCabinetList, setKeyCabinetList] = useState([]);
  const addKeyCabinetList = () =>
    addToList(setKeyCabinetList, GetAddKeyCabinetList);

  const [keyDoorList, setKeyDoorList] = useState([]);
  const addKeyDoorList = () => addToList(setKeyDoorList, GetAddKeyDoorList);

  const [keyLockNumberList, setKeyLockNumberList] = useState([]);
  const addKeyLockNumberList = () =>
    addToList(setKeyLockNumberList, GetAddKeyLockNumberList);

  const [keyBuildingList, setKeyBuildingList] = useState([]);
  const addKeyBuildingList = () =>
    addToList(setKeyBuildingList, GetAddKeyBuildingList);

  const [keyTypeList, setKeyTypeList] = useState([]);
  const getAddKeyTypeList = () => addToList(setKeyTypeList, GetAddKeyTypeList);

  const childToParent = (val, type) => {
    switch (type) {
      case "key_ring":
        setKeyRingList(val);
        break;
      case "lock_number":
        setKeyLockNumberList(val);
        break;
      case "key_cabinet":
        setKeyCabinetList(val);
        break;
      case "key_door":
        setKeyDoorList(val);
        break;
      case "key_building":
        setKeyBuildingList(val);
        break;
      case "key_type":
        setKeyTypeList(val);
      default:
        break;
    }
  };

  useEffect(() => {
    getEquipmentTypeTree();
    getEquipmentKeyGroupTree();
  }, []);

  // Checkbox Tree
  const [equipTypeTree, setEquipTypeTree] = useState([]);
  const [isCheckedValue, setIsCheckedValue] = useState(0);

  const handleCheckedTree = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIsCheckedValue(value);
      if (value === "key_id") {
        addKeyRingList();
        addKeyCabinetList();
        addKeyDoorList();
        addKeyLockNumberList();
        addKeyBuildingList();
        getAddKeyTypeList();
      }
    } else {
      setIsCheckedValue(0);
    }
  };
  const getEquipmentTypeTree = () => {
    EquipmentTypeTree()
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipTypeTree(res.data);
        } else {
          setEquipTypeTree([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Key Group Checkbox Tree
  const [equipKeyGroupTree, setEquipKeyGroupTree] = useState([]);
  const [isCheckedKeyGroupValue, setIsCheckedKeyGroupValue] = useState(0);

  const handleCheckedKeyGroupTree = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIsCheckedKeyGroupValue(value);
    } else {
      setIsCheckedKeyGroupValue(0);
    }
  };
  const getEquipmentKeyGroupTree = () => {
    KeyGroupTree()
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipKeyGroupTree(res.data);
        } else {
          setEquipKeyGroupTree([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialValues = {
    equipment_id: "",
    equipment_name: "",
    key_ring: "",
    key_cabinet: "",
    security_level: "",
    key_door: "",
    key_lock_number: "",
    key_building: "",
    key_type: "",
    key_notes: "",
    equipment_disposition: "in_service",
  };
  const validationSchema = Yup.object().shape({
    equipment_id: Yup.string().required("Please enter equipment id"),
    equipment_name: Yup.string().required("Please enter equipment name"),
  });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        equipment_id: values.equipment_id,
        equipment_name: values.equipment_name,
        equipment_type_id: isCheckedValue,
        key_group_id: isCheckedKeyGroupValue,
        key_ring_id: values.key_ring,
        key_door_id: values.key_door,
        key_building_id: values.key_building,
        key_lock_number_id: values.key_lock_number,
        key_type_id: values.key_type,
        key_cabinet_id: values.key_cabinet,
        key_notes: values.key_notes,
        disposition: values.equipment_disposition,
        equipment_sl: values.security_level ? values.security_level : 1,
      };
      AddEquip(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            setLoading(false);
            dispatch(getMsg("Equipment has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            navigate({
              pathname: "/equipment/equipment-list",
            });
            getAddKeyTypeList();
          } else {
            dispatch(getSnackOpen());
            setLoading(false);
            dispatch(getMsg(res.message));
            dispatch(getSnackAlert("error"));
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  // Modal Add Key Group
  const [addKeyGroup, setAddKeyGroup] = useState(false);
  const addKeyGroupOpen = () => {
    setAddKeyGroup(true);
  };
  const addKeyGroupClose = () => {
    setAddKeyGroup(false);
  };
  // Modal Add Key Ring
  const [addKeyRing, setAddKeyRing] = useState(false);
  const addKeyRingOpen = () => {
    setAddKeyRing(true);
  };
  const addKeyRingClose = () => {
    setAddKeyRing(false);
  };
  // Modal Add Key Cabinet
  const [addKeyCabinet, setAddKeyCabinet] = useState(false);
  const addKeyCabinetOpen = () => {
    setAddKeyCabinet(true);
  };
  const addKeyCabinetClose = () => {
    setAddKeyCabinet(false);
  };
  // Modal Add Key Door
  const [addKeyDoor, setAddKeyDoor] = useState(false);
  const addKeyDoorOpen = () => {
    setAddKeyDoor(true);
  };
  const addKeyDoorClose = () => {
    setAddKeyDoor(false);
  };
  // Modal Add Key Lock Number
  const [addKeyLockNumber, setAddKeyLockNumber] = useState(false);
  const addKeyLockNumberOpen = () => {
    setAddKeyLockNumber(true);
  };
  const addKeyLockNumberClose = () => {
    setAddKeyLockNumber(false);
  };
  // Modal Add Key Building
  const [addKeyBuilding, setAddKeyBuilding] = useState(false);
  const addKeyBuildingOpen = () => {
    setAddKeyBuilding(true);
  };
  const addKeyBuildingClose = () => {
    setAddKeyBuilding(false);
  };
  // Modal Add Key Type
  const [addKeyType, setAddKeyType] = useState(false);
  const addKeyTypeOpen = () => {
    setAddKeyType(true);
  };
  const addKeyTypeClose = () => {
    setAddKeyType(false);
  };

  // const modals = [
  //   { state: "addKeyGroup", setState: setAddKeyGroup },
  //   { state: "addKeyRing", setState: setAddKeyRing },
  //   { state: "addKeyCabinet", setState: setAddKeyCabinet },
  //   { state: "addKeyDoor", setState: setAddKeyDoor },
  //   { state: "addKeyLockNumber", setState: setAddKeyLockNumber },
  //   { state: "addKeyBuilding", setState: setAddKeyBuilding },
  //   { state: "addKeyType", setState: setAddKeyType },
  // ];

  // const [states, setStates] = useState(
  //   modals.reduce((acc, { state }) => ({ ...acc, [state]: false }), {})
  // );

  // const handleModalOpen = (modal) => {
  //   setStates({ ...states, [modal]: true });
  // };

  // const handleModalClose = (modal) => {
  //   setStates({ ...states, [modal]: false });
  // };

  const dispatch = useDispatch();
  const snackbarStatus = useSelector((state) => state.equipment.snackStatus);
  const snackbarMsg = useSelector((state) => state.equipment.msg);
  const snackbarAlert = useSelector((state) => state.equipment.snackAlert);

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  let equipTypeTree1 = [
    { id: "key_id", name: "Keys", parent_id: 0, children_recursive: [] },
    ...equipTypeTree,
  ];
  const classes = useStyles();
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card mb={6}>
            {snackbarStatus && (
              <Toaster
                openSnack={snackbarStatus}
                handleCloseSnack={handleCloseSnack}
                severity={snackbarAlert}
                message={snackbarMsg}
              />
            )}
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <TextField
                        id="equipment-id"
                        label="Equipment ID"
                        name="equipment_id"
                        value={values.equipment_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.equipment_id && errors.equipment_id
                        )}
                        helperText={touched.equipment_id && errors.equipment_id}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Barcode for equipment. For keys, enter barcodes in key ring instead"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <StyledTooltip
                                placement="top-end"
                                title={
                                  <Typography>
                                    A minimum of 2 alphanumeric characters are
                                    required
                                  </Typography>
                                }
                              >
                                <ErrorIcon color="primary" />
                              </StyledTooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>

                    <FormControl mt={6}>
                      <TextField
                        id="equipment-name"
                        label="Equipment name"
                        name="equipment_name"
                        value={values.equipment_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.equipment_name && errors.equipment_name
                        )}
                        helperText={
                          touched.equipment_name && errors.equipment_name
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Key 001, Radio-A, etc."
                      />
                    </FormControl>
                    <FormControl mt={3}>
                      {openaddequip && (
                        <AddEquipmentType
                          addEquipOpen={addEquipOpen}
                          addEquipClose={addEquipClose}
                          openaddequip={openaddequip}
                          setOpenAddEquip={setOpenAddEquip}
                          getEquipmentTypeList={getEquipmentTypeTree}
                        />
                      )}
                      <StyledFormControl>
                        <FormLabel component="legend">
                          Equipment type
                          {
                            <sup
                              style={{
                                verticalAlign: "top",
                              }}
                            >
                              *
                            </sup>
                          }
                          <Tooltip
                            title="Click to add or edit equipment types"
                            placement="right-start"
                          >
                            <IconButton color="warning" onClick={addEquipOpen}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </FormLabel>
                      </StyledFormControl>
                      <CustomCheckboxTree
                        handleChecked={(evt) => handleCheckedTree(evt)}
                        equipType={equipTypeTree1}
                        isChecked={isCheckedValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={2}>
                      <FormLabel component="legend">
                        Equipment Disposition
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="equipment-dispotion"
                        name="equipment_disposition"
                        value={values.equipment_disposition}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <FormControlLabel
                          value="in_service"
                          control={<Radio />}
                          label="In service"
                        />
                        <FormControlLabel
                          value="lost"
                          control={<Radio />}
                          label="Lost"
                        />
                        <FormControlLabel
                          value="expired"
                          control={<Radio />}
                          label="Expired"
                        />
                        <FormControlLabel
                          value="out_for_repair"
                          control={<Radio />}
                          label="Out for repair"
                        />
                      </RadioGroup>
                    </FormControl>

                    <Fade in="true">
                      <Grid
                        container
                        spacing={6}
                        style={{
                          display:
                            isCheckedValue === "key_id" ? "block" : "none",
                        }}
                      >
                        <Grid item xs={12} md={12}>
                          <FormControl mt={3}>
                            <StyledFormControl>
                              <FormLabel component="legend">
                                Key Group
                                {
                                  <sup
                                    style={{
                                      verticalAlign: "top",
                                    }}
                                  >
                                    *
                                  </sup>
                                }
                                <Tooltip
                                  title="Click to add or edit key groups"
                                  placement="right-start"
                                >
                                  <IconButton
                                    color="warning"
                                    onClick={addKeyGroupOpen}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </FormLabel>
                              <FormGroup>
                                <CustomCheckboxTree
                                  handleChecked={(evt) =>
                                    handleCheckedKeyGroupTree(evt)
                                  }
                                  equipType={equipKeyGroupTree}
                                  isChecked={isCheckedKeyGroupValue}
                                />
                              </FormGroup>
                            </StyledFormControl>
                          </FormControl>
                          {addKeyGroup && (
                            <AddKeyGroup
                              addKeyGroup={addKeyGroup}
                              addKeyGroupClose={addKeyGroupClose}
                              addKeyGroupOpen={addKeyGroupOpen}
                              setAddKeyGroup={setAddKeyGroup}
                              getEquipmentKeyGroupTreeList={
                                getEquipmentKeyGroupTree
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  alignItems: "center",
                                }}
                              >
                                <Item sx={{ flexGrow: 1, width: "100%" }}>
                                  <FormControl>
                                    <InputLabel
                                      shrink
                                      required
                                      id="key-ring"
                                      sx={{
                                        background: "#fff",
                                        paddingLeft: "6px",
                                        paddingRight: "6px",
                                        marginLeft: "-4px",
                                      }}
                                    >
                                      Key ring
                                    </InputLabel>
                                    <Select
                                      labelId="key-ring"
                                      id="key-ring"
                                      name="key_ring"
                                      value={values.key_ring}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      fullWidth
                                      displayEmpty
                                    >
                                      <MenuItem value="">
                                        Select key ring
                                      </MenuItem>
                                      {keyRingList.map((data) => {
                                        return (
                                          <MenuItem
                                            value={data.id}
                                            key={data.id}
                                          >
                                            {data.ring_name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Item>
                                <Item>
                                  <Tooltip
                                    title="Click to add or edit key rings"
                                    placement="right-start"
                                  >
                                    <IconButton
                                      color="warning"
                                      onClick={addKeyRingOpen}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {addKeyRing && (
                                    <AddKeyRing
                                      addKeyRing={addKeyRing}
                                      setAddKeyRing={setAddKeyRing}
                                      addKeyRingOpen={addKeyRingOpen}
                                      addKeyRingClose={addKeyRingClose}
                                      childToParent={childToParent}
                                    />
                                  )}
                                </Item>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  alignItems: "center",
                                }}
                              >
                                <Item sx={{ flexGrow: 1, width: "100%" }}>
                                  <FormControl>
                                    <InputLabel
                                      shrink
                                      required
                                      id="key-cabinet"
                                      sx={{
                                        background: "#fff",
                                        paddingLeft: "6px",
                                        paddingRight: "6px",
                                        marginLeft: "-4px",
                                      }}
                                    >
                                      Key cabinet
                                    </InputLabel>
                                    <Select
                                      labelId="key-cabinet"
                                      id="key-cabinet"
                                      name="key_cabinet"
                                      value={values.key_cabinet}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      fullWidth
                                      displayEmpty
                                    >
                                      <MenuItem value="">
                                        Select key cabinet
                                      </MenuItem>
                                      {keyCabinetList.map((data) => {
                                        return (
                                          <MenuItem
                                            value={data.id}
                                            key={data.id}
                                          >
                                            {data.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Item>
                                <Item>
                                  <Tooltip
                                    title="Click to add or edit key cabinets"
                                    placement="right-start"
                                  >
                                    <IconButton
                                      color="warning"
                                      onClick={addKeyCabinetOpen}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {addKeyCabinet && (
                                    <AddKeyCabinet
                                      addKeyCabinet={addKeyCabinet}
                                      setAddKeyCabinet={setAddKeyCabinet}
                                      addKeyCabinetOpen={addKeyCabinetOpen}
                                      addKeyCabinetClose={addKeyCabinetClose}
                                      childToParent={childToParent}
                                    />
                                  )}
                                </Item>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="security-level"
                              sx={{
                                background: "#fff",
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Key security level
                            </InputLabel>
                            <Select
                              labelId="security-level"
                              id="security-level"
                              name="security_level"
                              value={values.security_level}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              endAdornment={
                                <InputAdornment position="end">
                                  <StyledSelectTooltip title="A higher security level (SL) equals more restrictive access. Equipment users must have an SL equals to or higher than this equipment SL to be allowed to checkout this equipment">
                                    <IconButton
                                      color="primary"
                                      sx={{
                                        padding: "0px",
                                        marginRight: "-2.5px",
                                      }}
                                    >
                                      <ErrorIcon />
                                    </IconButton>
                                  </StyledSelectTooltip>
                                </InputAdornment>
                              }
                              displayEmpty
                            >
                              <MenuItem value="">
                                Select security level
                              </MenuItem>
                              {sl.map((data) => {
                                return (
                                  <MenuItem value={data} key={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <Box className={classes.accordion}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="option-key-details"
                                id="option-key-det"
                              >
                                <Typography>Optional Key Details</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={6}>
                                  <Grid item xs={12} md={6}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Item sx={{ flexGrow: 1, width: "100%" }}>
                                        <FormControl>
                                          <InputLabel
                                            shrink
                                            required
                                            id="key-door"
                                            sx={{
                                              background: "#fff",
                                              paddingLeft: "6px",
                                              paddingRight: "6px",
                                              marginLeft: "-4px",
                                            }}
                                          >
                                            Key door
                                          </InputLabel>
                                          <Select
                                            labelId="disposition"
                                            id="key-door"
                                            name="key_door"
                                            value={values.key_door}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            displayEmpty
                                          >
                                            <MenuItem value="">
                                              Select key door
                                            </MenuItem>
                                            {keyDoorList.map((data) => {
                                              return (
                                                <MenuItem
                                                  value={data.id}
                                                  key={data.id}
                                                >
                                                  {data.name}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Item>
                                      <Item>
                                        <Tooltip
                                          title="Click to add or edit key doors"
                                          placement="right-start"
                                        >
                                          <IconButton
                                            color="warning"
                                            onClick={addKeyDoorOpen}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        {addKeyDoor && (
                                          <AddKeyDoor
                                            addKeyDoor={addKeyDoor}
                                            setAddKeyDoor={setAddKeyDoor}
                                            addKeyDoorOpen={addKeyDoorOpen}
                                            addKeyDoorClose={addKeyDoorClose}
                                            childToParent={childToParent}
                                          />
                                        )}
                                      </Item>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Item sx={{ flexGrow: 1, width: "100%" }}>
                                        <FormControl>
                                          <InputLabel
                                            shrink
                                            required
                                            id="key-lock"
                                            sx={{
                                              background: "#fff",
                                              paddingLeft: "6px",
                                              paddingRight: "6px",
                                              marginLeft: "-4px",
                                            }}
                                          >
                                            Key lock number
                                          </InputLabel>
                                          <Select
                                            labelId="disposition"
                                            id="key-lock"
                                            name="key_lock_number"
                                            value={values.key_lock_number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            displayEmpty
                                          >
                                            <MenuItem value="">
                                              Select key lock no.
                                            </MenuItem>

                                            {keyLockNumberList.map((data) => {
                                              return (
                                                <MenuItem
                                                  value={data.id}
                                                  key={data.id}
                                                >
                                                  {data.name}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Item>
                                      <Item>
                                        <Tooltip
                                          title="Click to add or edit key doors"
                                          placement="right-start"
                                        >
                                          <IconButton
                                            color="warning"
                                            onClick={addKeyLockNumberOpen}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        {addKeyLockNumber && (
                                          <AddKeyLockNumber
                                            addKeyLockNumber={addKeyLockNumber}
                                            addKeyLockNumberClose={
                                              addKeyLockNumberClose
                                            }
                                            setAddKeyLockNumber={
                                              setAddKeyLockNumber
                                            }
                                            addKeyLockNumberOpen={
                                              addKeyLockNumberOpen
                                            }
                                            childToParent={childToParent}
                                          />
                                        )}
                                      </Item>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Item sx={{ flexGrow: 1, width: "100%" }}>
                                        <FormControl>
                                          <InputLabel
                                            shrink
                                            required
                                            id="key-building"
                                            sx={{
                                              background: "#fff",
                                              paddingLeft: "6px",
                                              paddingRight: "6px",
                                              marginLeft: "-4px",
                                            }}
                                          >
                                            Key building
                                          </InputLabel>
                                          <Select
                                            labelId="disposition"
                                            id="key-building"
                                            name="key_building"
                                            value={values.key_building}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            displayEmpty
                                          >
                                            <MenuItem value="">
                                              Select key building
                                            </MenuItem>
                                            {keyBuildingList.map((data) => {
                                              return (
                                                <MenuItem
                                                  value={data.id}
                                                  key={data.id}
                                                >
                                                  {data.name}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Item>
                                      <Item>
                                        <Tooltip
                                          title="Click to add or edit key doors"
                                          placement="right-start"
                                        >
                                          <IconButton
                                            color="warning"
                                            onClick={addKeyBuildingOpen}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        {addKeyBuilding && (
                                          <AddKeyBuilding
                                            addKeyBuilding={addKeyBuilding}
                                            setAddKeyBuilding={
                                              setAddKeyBuilding
                                            }
                                            addKeyBuildingClose={
                                              addKeyBuildingClose
                                            }
                                            addKeyBuildingOpen={
                                              addKeyBuildingOpen
                                            }
                                            childToParent={childToParent}
                                          />
                                        )}
                                      </Item>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Item sx={{ flexGrow: 1, width: "100%" }}>
                                        <FormControl>
                                          <InputLabel
                                            shrink
                                            required
                                            id="key-type"
                                            sx={{
                                              background: "#fff",
                                              paddingLeft: "6px",
                                              paddingRight: "6px",
                                              marginLeft: "-4px",
                                            }}
                                          >
                                            Key type
                                          </InputLabel>
                                          <Select
                                            labelId="disposition"
                                            id="key-type"
                                            name="key_type"
                                            value={values.key_type}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            displayEmpty
                                          >
                                            <MenuItem value="">
                                              Select key type
                                            </MenuItem>
                                            {keyTypeList.map((data) => {
                                              return (
                                                <MenuItem
                                                  value={data.id}
                                                  key={data.id}
                                                >
                                                  {data.type_name}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Item>
                                      <Item>
                                        <Tooltip
                                          title="Click to add or edit key doors"
                                          placement="right-start"
                                        >
                                          <IconButton
                                            color="warning"
                                            onClick={addKeyTypeOpen}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        {addKeyType && (
                                          <AddKeyType
                                            addKeyType={addKeyType}
                                            addKeyTypeClose={addKeyTypeClose}
                                            addKeyTypeOpen={addKeyTypeOpen}
                                            setAddKeyType={setAddKeyType}
                                            childToParent={childToParent}
                                          />
                                        )}
                                      </Item>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl>
                            <TextField
                              id="key-notes"
                              label="Key notes"
                              name="key_notes"
                              value={values.key_notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Fade>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Link component={NavLink} to="/equipment/equipment-list">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        mt={6}
                        sx={{ width: "175px" }}
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      mt={6}
                      sx={{ width: "175px", marginLeft: "5px" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Card>
        )}
      </Formik>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function AddEquipment() {
  return (
    <React.Fragment>
      <WithPermissionFallback controller="Equipment" action="addEquipment">
        <Helmet title="Add Equipment" />
        <Typography variant="h3" gutterBottom display="inline">
          Add Equipment
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/equipment/equipment-list">
            Equipment
          </Link>
          <Typography>Add Equipment</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <ColumnLeft />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default AddEquipment;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const ClassificationDoc = ({ show, handleModal, data, files }) => {
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="classification-title"
        aria-describedby="classification-description"
        open={show}
        onClose={() => handleModal(false)}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="classification-title"
        >
          <Typography variant="h4" component="div">
            {data.Classification} {data.Datetime}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={() => handleModal(false)}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="classification-description">
            Select a document from the list below. The selected file either be
            viewable in your browser or will download depending on your settings
            and the file type selected.
          </DialogContentText>
          <Paper sx={{ marginTop: "15px" }}>
            <Typography variant="h5" component="div">
              Document attached to this Incident Report:
            </Typography>

            {files.map((row, index) => {
              return (
                <Box mt={4} sx={{ border: 1, borderColor: "grey.500" }}>
                  <List disablePadding={true}>
                    <ListItem>
                      <a href={row.link} target="_blank">
                        <ListItemText primary={row.MediaName} />
                      </a>
                    </ListItem>
                  </List>
                </Box>
              );
            })}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleModal(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClassificationDoc;

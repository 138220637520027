import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { format, subDays } from "date-fns";
import { GetTaskLog } from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  ListItemIcon,
  MenuItem,
  Menu,
  Card,
  Box,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  SettingsInputComposite as SettingsInputCompositeIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const ListItemText = styled(MuiListItemText)(spacing);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const TaskLog = (props) => {
  const { open, logModalClose, setOpen, id, recordObj } = props;
  const [dense, setDense] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [logData, setLogData] = useState([]);
  // Log Action Dropdown
  const [logDropdown, setLogDropdown] = React.useState(null);
  const actionLogOpen = (event) => {
    setLogDropdown(event.currentTarget);
  };
  const actionLogClose = () => {
    setLogDropdown(null);
  };

  const getEquipmentListLog = () => {
    setLoading(true);
    GetTaskLog(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setLogData(res.data);
          console.log(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          setLogData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getEquipmentListLog();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={open}
        onClose={logModalClose}
        aria-labelledby="equipment-log"
        aria-describedby="equipment-log"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            <Typography variant="h4" component="div">
              Log:{" "}
              {recordObj &&
                `${recordObj.name} - ${recordObj.report_type}, ${recordObj.injury_datetime}`}
            </Typography>
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <Paper>
            <TableWrapper>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "15px",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Task Title</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Added By</TableCell>
                    <TableCell>Datetime</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                ) : (
                  <TableBody>
                    {logData.map((data) => {
                      return (
                        <TableRow hover key={data.id}>
                          <TableCell>{data?.module_task?.title}</TableCell>
                          <TableCell>{data?.log_type}</TableCell>
                          <TableCell>
                            {data?.status == 1
                              ? "Not started"
                              : data?.status == 2
                              ? "Progress"
                              : "Completed"}
                          </TableCell>
                          <TableCell>{data?.user.UserName}</TableCell>
                          <TableCell>
                            {data?.module_task?.created
                              ? new Date(
                                  data.module_task.created
                                ).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })
                              : ""}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableWrapper>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
          <Button onClick={logModalClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskLog;

import * as Yup from "yup";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import { handleApiResonseErrors } from "../../../helper/helper";
import { AddReissueId } from "../../../api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  TextField,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const reason = [
  { key: "Damaged", value: "Damaged" },
  { key: "Lost", value: "Lost" },
];
const Reissueid = ({
  id,
  show,
  handleModal,
  GetIssueLogsList,
  messageAlert,
}) => {
  const [reIssueDate, setReIssueDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required("Please select reason."),
    note: Yup.string().required("Please enter notes."),
  });

  let initialValues = {
    visitor_id: "",
    reason: "",
    note: "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let date = reIssueDate ? moment(reIssueDate) : "";
      date = date ? date.format("YYYY-MM-DD") : "";

      let formData = {
        reissue_date: date,
        visitor_id: id,
        reason: values.reason,
        note: values.note,
      };
      AddReissueId(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            GetIssueLogsList();
            messageAlert("Record has been submitted successfully", "success");
            handleModal(false);
          } else {
            messageAlert("Sorry! something wrong please try again", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            messageAlert(error, "error");
          }
        });
    } catch (error) {
      messageAlert("Sorry! something wrong please try again", "error");
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="reissueid-title"
        aria-describedby="reissueid-desc"
        open={show}
        onClose={() => handleModal(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="reissueid-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Reissue ID
          </Typography>
          <DialogActions>
            <IconButton
              onClick={() => handleModal(false)}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormControl mt={6}>
                  <DatePicker
                    name="date_reissued"
                    label="Date reissued*"
                    value={reIssueDate}
                    onChange={(newValue) => {
                      setReIssueDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "",
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl mt={6}>
                  <CustomSelect
                    name="reason"
                    fieldLabel="Reason*"
                    options={reason}
                    displayEmpty
                  />
                  <FormHelperText>{errors.reason}</FormHelperText>
                </FormControl>
                <FormControl mt={6}>
                  <CustomTextField
                    name="note"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Notes*"
                    autoComplete="off"
                    placeholder=""
                    rows={5}
                    multiline={true}
                  />
                </FormControl>

                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleModal(false)}
                    sx={{ marginRight: "5px" }}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="primary" variant="contained">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Reissueid;

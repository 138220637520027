import React from "react";
import moment from "moment";
import { SingleLostfoundActivityLog } from "../../../api";

import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  IconButton,
  DialogContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const SingleActivityLogs = ({ activitylogtable, handleActivityModal, id }) => {
  const [itemLogsList, setItemLogsList] = React.useState([]);
  const [loading, setLoading] = React.useState(true); //State for loader

  React.useEffect(() => {
    ActivityLogList();
  }, []);

  const ActivityLogList = () => {
    SingleLostfoundActivityLog(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setItemLogsList(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog
      open={activitylogtable}
      aria-labelledby="activity-log-title"
      aria-describedby="activity-log-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "900px",
          },
        },
      }}
    >
      <DialogTitle
        id="activity-log-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Activity Log
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => handleActivityModal(false)}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 0" }}>
        <TableContainer className="tableContainer">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Activity</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Datetime</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={3} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {itemLogsList.map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.activity}</TableCell>
                      <TableCell>
                        {row.user ? row.user.UserName : "-"}
                      </TableCell>
                      <TableCell>
                        {moment(row.created).format("YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {itemLogsList.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          onClick={() => handleActivityModal(false)}
          color="primary"
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SingleActivityLogs;

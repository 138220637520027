export default [
  {
    id: "brand",
    label: "Brand",
  },
  {
    id: "serial",
    label: "Serial",
  },
  {
    id: "room_name",
    label: "Room Name",
  },

  {
    id: "locker_number",
    label: "Locker Number",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { ShipmentsForAdmin } from "../../api";
import moment from "moment";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import DownloadShipmentCSV from "../../components/modals/lostandfound/DownloadShipmentCSV";
import CustomAlert from "../../components/form-components/CustomAlert";
import AdminShipmentsCol from "../table-columns-json/lostfound/AdminShipments";
import { useSelector, useDispatch } from "react-redux";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import { setAdminShipments } from "../../redux/slices/lostfound/lostfoundTableCalSlice";

import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Settings as SettingsIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const headCells = [
  {
    id: "senderid",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "senderaddr",
    numeric: true,
    disablePadding: false,
    label: "Sender Address",
  },
  {
    id: "receiverid",
    numeric: true,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "recipientname",
    numeric: true,
    disablePadding: false,
    label: "Recipient Name",
  },
  {
    id: "recipientaddr",
    numeric: true,
    disablePadding: false,
    label: "Recipient Address",
  },
  {
    id: "shipdetailby",
    numeric: true,
    disablePadding: false,
    label: "Ship Detail By",
  },
  {
    id: "residential",
    numeric: true,
    disablePadding: false,
    label: "Residential",
  },
  {
    id: "recipientphone",
    numeric: true,
    disablePadding: false,
    label: "Recipient Phone",
  },
  {
    id: "recipientemail",
    numeric: true,
    disablePadding: false,
    label: "Recipient Email",
  },
  {
    id: "trackingid",
    numeric: true,
    disablePadding: false,
    label: "Tracking ID",
  },
  {
    id: "carrier",
    numeric: true,
    disablePadding: false,
    label: "Carrier",
  },
  {
    id: "servicelevel",
    numeric: true,
    disablePadding: false,
    label: "Service Level",
  },
  {
    id: "length",
    numeric: true,
    disablePadding: false,
    label: "Length",
  },
  {
    id: "width",
    numeric: true,
    disablePadding: false,
    label: "Width",
  },
  {
    id: "height",
    numeric: true,
    disablePadding: false,
    label: "Height",
  },
  {
    id: "weight",
    numeric: true,
    disablePadding: false,
    label: "Weight",
  },
  {
    id: "amountpaid",
    numeric: true,
    disablePadding: false,
    label: "Amount Paid",
  },
  {
    id: "freightcost",
    numeric: true,
    disablePadding: false,
    label: "Freight Cost",
  },
  {
    id: "processingcost",
    numeric: true,
    disablePadding: false,
    label: "Processing Cost",
  },
  {
    id: "salestax",
    numeric: true,
    disablePadding: false,
    label: "Sales Tax",
  },
  {
    id: "insurance",
    numeric: true,
    disablePadding: false,
    label: "Insurance",
  },
  {
    id: "trackingstatus",
    numeric: true,
    disablePadding: false,
    label: "Tracking Status",
  },
  {
    id: "finaltrackingmessage",
    numeric: true,
    disablePadding: false,
    label: "Final Tracking Message",
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "modified",
    numeric: true,
    disablePadding: false,
    label: "Modified",
  },
];
const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, tableCalRedux } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {AdminShipmentsCol.map((headCell) => (
          <TableCell
            sx={{
              whiteSpace: "nowrap",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sortDirection={orderBy === headCell.senderid ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.senderid}
              direction={orderBy === headCell.senderid ? order : "asc"}
              onClick={createSortHandler(headCell.senderid)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    filterRecord,
    dataToFilter,
    showAlertPopup,
    tableCalRedux,
  } = props;

  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [showDownloadCSV, setShowDownloadCSV] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLostfoundTableCal.adminShipments
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  const [fadebox, setFadedBox] = useState(false);

  const applySearch = () => {
    const payload = {
      from_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      to_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
    };
    filterRecord(payload);
  };

  const clearFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    filterRecord({});
  };

  return (
    <Toolbar>
      <div>
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content", marginLeft: "15px" }}
        >
          {numSelected} records found
        </Typography>
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <FormControl>
              <TextField
                fullWidth
                id="dateRange"
                // label="Date range"
                value={
                  startDateToFilter && endDateToFilter
                    ? moment(startDateToFilter).format("MM-DD-YYYY") +
                      " - " +
                      moment(endDateToFilter).format("MM-DD-YYYY")
                    : ""
                }
                onClick={() => setFadedBox((prev) => !prev)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                    setIsFilter(true);
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Find with two date"
              />
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                  }}
                  selectedStart={startDateToFilter}
                  selectedEnd={endDateToFilter}
                />
              )}
            </FormControl>
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  clearFilter();
                  setIsFilter(false);
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
              >
                Clear Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  applySearch({});
                  setIsFilter(true);
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <WithPermissionHide
              controller="LostAndFound"
              action="downloadShipmentsCsv"
            >
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={() => setShowDownloadCSV(true)}
              >
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText primary="Download CSV" pl={0} />
              </MenuItem>
            </WithPermissionHide>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setAdminShipments(data));
                    }}
                    listing={AdminShipmentsCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setAdminShipments(defaultTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
      {showDownloadCSV && (
        <DownloadShipmentCSV
          show={showDownloadCSV}
          handleModal={(status) => setShowDownloadCSV(status)}
          showAlertPopup={(msg, type) => showAlertPopup(msg, type)}
        />
      )}
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("color");
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataToFilter, setDataToFilter] = useState({});
  const [lists, setLists] = useState([]);
  const [totalRecordinDb, setTotalRecordinDb] = useState(0);
  const [loading, setLoading] = useState(false); //State for loader
  const [currentPage, setCurrentPage] = useState(0);

  // Alert Message
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const [showAlert, setShowAlert] = React.useState(false);

  const tableCalRedux = useSelector(
    (state) => state.lostfoundTableCal.adminShipments
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  useEffect(() => {
    getList();
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter]);

  const getList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      forAdmin: true,
      ...dataToFilter,
    };
    ShipmentsForAdmin(currentPage + 1, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLists(res.data);
          setTotalRecordinDb(res.meta.total);
        } else {
          setLists([]);
          setTotalRecordinDb(0);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  const showAlertPopup = (msg, type) => {
    setAlertMessage(msg);
    setAlertType(type);
    setShowAlert(true);
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={totalRecordinDb}
          dataToFilter={dataToFilter}
          filterRecord={(data) => filterRecord(data)}
          showAlertPopup={(msg, type) => showAlertPopup(msg, type)}
          tableCalRedux={tableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={lists.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={lists.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ paddingTop: "15px", paddingBottom: "15px" }}
                    colSpan={8}
                    align="center"
                  >
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell
                    sx={{ paddingTop: "15px", paddingBottom: "15px" }}
                    colSpan={8}
                  />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {lists.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell
                        className={
                          tableCalRedux.senderid === true ? "" : "hide-cell"
                        }
                        sx={{
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          whiteSpace: "nowrap",
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.senderaddr === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.sender_address_object
                          ? row.sender_address_object.company
                          : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.receiverid === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.lostfound_id}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.recipientname === true
                            ? ""
                            : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.recipient_address_object
                          ? row.recipient_address_object.name
                          : ""}
                      </TableCell>

                      <TableCell
                        className={
                          tableCalRedux.recipientaddr === true
                            ? ""
                            : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.recipient_address_object ? (
                          <>
                            {row.recipient_address_object.street1}
                            {row.recipient_address_object.city}
                            {row.recipient_address_object.state}
                            {row.recipient_address_object.zip}
                            {row.recipient_address_object.country}
                          </>
                        ) : (
                          ""
                        )}
                      </TableCell>

                      <TableCell
                        className={
                          tableCalRedux.shipdetailby === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.hotel_ship_details ? row.hotel_ship_details : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.residential === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.residential_check ? row.residential_check : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.recipientphone === true
                            ? ""
                            : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.recipient_address_object
                          ? row.recipient_address_object.phone
                          : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.recipientemail === true
                            ? ""
                            : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.recipient_address_object
                          ? row.recipient_address_object.email
                          : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.trackingid === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.tracking_id}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.carrier === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.carrier_chosen}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.servicelevel === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.service_level}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.length === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.parcel_object ? row.parcel_object.length : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.width === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.parcel_object ? row.parcel_object.width : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.height === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.parcel_object ? row.parcel_object.height : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.weight === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.parcel_object ? row.parcel_object.weight : ""}
                        {row.parcel_object ? row.parcel_object.mass_unit : ""}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.amountpaid === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.amount_paid}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.freightcost === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.freight_cost}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.processingcost === true
                            ? ""
                            : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.processing_cost}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.salestax === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.sales_tax}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.insurance === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.insurance_value}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.trackingstatus === true
                            ? ""
                            : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.tracking_status}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.finaltrackingmessage === true
                            ? ""
                            : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.final_tracking_message}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.created === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.created}
                      </TableCell>
                      <TableCell
                        className={
                          tableCalRedux.modified === true ? "" : "hide-cell"
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        align="left"
                      >
                        {row.modified}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {lists.length < 1 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * lists.length }}>
                    <TableCell
                      sx={{ paddingTop: "15px", paddingBottom: "15px" }}
                      colSpan={8}
                      align="center"
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40, 50]}
          component="div"
          count={totalRecordinDb}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}
    </div>
  );
}

function ShipmentsAdmin() {
  return (
    <React.Fragment>
      <Helmet title="Accounting Export" />
      <WithPermissionFallback
        controller="LostAndFound"
        action="shipmentsForAdmin"
      >
        <Typography variant="h3" gutterBottom display="inline">
          Accounting Export
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ShipmentsAdmin;

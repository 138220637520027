export default [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "department_name",
    label: "Department Name",
  },

  {
    id: "position_count",
    label: "Position Count",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  Paper,
  CircularProgress,
  DialogContent,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const StructuredDialog = ({
  open,
  onClose,
  title,
  description,
  onSave,
  currentValue,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(currentValue || "");

  const handleSave = async () => {
    setLoading(true);
    try {
      onSave(inputValue);
      setInputValue("");
      onClose();
    } catch (error) {
      console.error("Error while saving structured data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setInputValue(currentValue || "");
    }
  }, [open, currentValue]);

  return (
    <>
      <Dialog
        aria-labelledby="structured-dialog-title"
        aria-describedby="structured-dialog-description"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#E8E8E8",
          }}
          id="structured-dialog-title"
          style={{ paddingBottom: 0, paddingRight: 24, paddingLeft: 24 }}
        >
          <Typography variant="h4" component="div" gutterBottom>
            {title}
          </Typography>

          <DialogActions>
            <IconButton
              onClick={onClose}
              sx={{ marginRight: "-10px" }}
              disabled={loading}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ p: "10px" }}>{description}</Typography>
          <TextField
            id="structured-input"
            label="Enter structured text"
            variant="outlined"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            disabled={loading}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions sx={{ pr: "28px", paddingBottom: "20px" }}>
          <Button
            color="warning"
            variant="outlined"
            onClick={onClose}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="outlined"
            disabled={loading}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default StructuredDialog;

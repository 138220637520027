import React from "react";

import { Checkbox, Box, FormGroup, FormControlLabel } from "@mui/material";

const StyledColumnOptions = ({ calStatus, updateCalStatus, listing }) => {
  const handleChecked = (e) => {
    const { checked, name } = e.target;
    let newUpdate = checked ? true : false;
    let newCalStatus = {};
    Object.keys(calStatus).map((key) => {
      if (key === name) {
        newCalStatus[key] = newUpdate;
      } else {
        newCalStatus[key] = calStatus[key];
      }
    });
    updateCalStatus(newCalStatus);
  };

  return (
    <React.Fragment>
      <Box>
        <FormGroup>
          {listing.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    disableRipple
                    checked={calStatus[item.id]}
                    name={item.id}
                    onChange={(e) => handleChecked(e)}
                  />
                }
                label={item.label}
              />
            );
          })}
        </FormGroup>
      </Box>
    </React.Fragment>
  );
};

export default StyledColumnOptions;

import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import SendEar from "./SendEar";

export default function SendEarForm(props) {
  const {
    setSendEarFormOpen,
    sendEarFormOpen,
    handleOpenForm,
    handleSendCloseForm,
    recordId,
  } = props;

  const [sendModal, setSendModal] = useState(false);
  const sendModalOpen = () => {
    setSendModal(true);
  };
  const sendModalClose = () => {
    setSendModal(false);
  };

  return (
    <React.Fragment>
      <div>
        <Button variant="outlined" onClick={handleOpenForm}>
          Open alert dialog
        </Button>
        <Dialog
          open={sendEarFormOpen}
          onClose={handleSendCloseForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogContent
            sx={{ paddingTop: 8, paddingLeft: 10, paddingRight: 10 }}
          >
            <Typography variant="div" component="h3" sx={{ opacity: 0.8 }}>
              Forms are not complete. Proceed?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", paddingBottom: 8 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSendCloseForm}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={sendModalOpen}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {sendModal && (
        <SendEar
          sendModal={sendModal}
          setSendModal={setSendModal}
          sendModalOpen={sendModalOpen}
          sendModalClose={sendModalClose}
          recordId={recordId}
          handleSendCloseForm={handleSendCloseForm}
        />
      )}
    </React.Fragment>
  );
}

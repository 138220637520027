import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import axios from "axios";
import Toaster from "../tasks/Toaster";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import GoogleMapReact from "google-map-react";
import { BuildVisualTour } from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  Cached as CachedIcon,
  CheckBox as CheckBoxIcon,
  LocationOn as LocationOnIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  GpsFixed as GpsFixedIcon,
  CalendarToday as CalendarTodayIcon,
  FileCopy as FileCopyIcon,
  Public as PublicIcon,
  Schedule,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import TourRow from "./TourRow";
import CopyTour from "./CopyTour";
import ScheduleTour from "./ScheduleTour";
import { useDispatch, useSelector } from "react-redux"; //Imports related to redux
import {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getTourRow,
} from "../../redux/slices/tourSlice"; //import related to redux store.
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      "& a": {
        color:
          theme.palette.mode === "light" ? "#374151" : theme.palette.grey[300],
        textDecoration: "none",
      },
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const BuildVisual = (props) => {
  const { logModalClose, setOpen, open } = props;

  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const [buildVisual, setBuildVisual] = useState([]);
  const [isTrue, setIsTrue] = useState(false);
  const buildVisualTour = () => {
    BuildVisualTour()
      .then((res) => {
        if (res.statusCode === 200) {
          setBuildVisual(res.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const markerHandle = (key, lat, lng, e) => {
    // console.log(key, lat, lng, "hi");
    console.log(e);
  };
  useEffect(() => {
    buildVisualTour();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1100px",
            },
          },
        }}
        open={open}
        onClose={logModalClose}
        aria-labelledby="equipment-log"
        aria-describedby="equipment-log"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Build Visual Tour
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText id="equipment-log" sx={{ padding: "30px 20px" }}>
            <Grid container spacing={4} mt={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  id="tour-name"
                  fullWidth
                  label="Tour name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder=""
                />
                <FormHelperText>
                  Using the map, click one or more pins to select from the
                  existing checkpoints to build a new tour. Click again to
                  de-select a checkpoint. When you are finished selecting
                  checkpoints, add a tour name and save. You can then view your
                  new tour from the tour list and add tasks, schedule etc.
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", gap: "10px", justifyContent: "end" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ height: "100%" }}
                  >
                    Save new tour
                  </Button>
                  <Button
                    onClick={logModalClose}
                    color="primary"
                    variant="outlined"
                  >
                    Close
                  </Button>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ height: "100vh", width: "100%" }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBZ5R4X8XrNL3bo_Bxbs-VKJE-o7oF_SMc",
                    language: "en",
                    region: "US",
                  }}
                  defaultCenter={{
                    lat: 39.23868851374065,
                    lng: -119.94484119660757,
                  }}
                  defaultZoom={18}
                  options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                  onChildClick={(key, lat, lng, e) => {
                    markerHandle(key, lat, lng, e);
                  }}
                  onClick={(e) => console.log(e)}
                >
                  {buildVisual.map((data) => {
                    return (
                      <AnyReactComponent
                        lat={data.gpsLatitude}
                        lng={data.gpsLongitude}
                        text={
                          <Tooltip
                            title={data.TagDescription}
                            placement="top"
                            arrow
                          >
                            <LocationOnIcon
                              sx={{ color: "red" }}
                              fontSize="large"
                            />
                          </Tooltip>
                        }
                        key={data.TagId}
                      />
                    );
                  })}
                </GoogleMapReact>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BuildVisual;

export default [
  {
    id: "user_name",
    label: "User Name",
  },
  {
    id: "equipment_type",
    label: "Equipment Type",
  },
  {
    id: "equipment_name",
    label: "Equipment Name",
  },
  {
    id: "checkout_date",
    label: "Checkout Date",
  },
  {
    id: "checkin_date",
    label: "Check In Date",
  },
];

import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomTextEditor from "../../components/form-components/CustomTextEditor";
import { format } from "date-fns";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { makeStyles } from "@mui/styles";
import { AddActivationLog } from "../../api";
import { useDispatch } from "react-redux";
import { DateTimePicker } from "@mui/lab";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Link,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  Paper,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker, TimePicker } from "@mui/lab";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function AddAlarmActivationForm(props) {
  const [alarmActivationDate, setAlarmActivationDate] = React.useState(null);
  const [maxTime, setMaxTime] = useState(null);
  const [alarmActivated, setAlarmActivated] = React.useState(null);
  const [timeAllPageSent, setTimeAllPageSent] = React.useState(null);
  const [timeAllClearGiven, setTimeAllClearGiven] = React.useState(null);
  const [timeAllClearPage, setTimeAllClearPage] = React.useState(null);
  const [description, setDescription] = useState("");
  const [visitorComplaints, setVisitorComplaints] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let initialValues = {
    alarm_activation_date: null,
    time_alarm_activated: null,
    emergency_code_number: "",
    who_called_in_code: "",
    exact_caller_location: "",
    all_page_used: "",
    time_all_page_sent: null,
    was_911_called: "",
    response_time: "",
    exact_location: "",
    summary_description: "",
    desc_of_emergency: "",
    time_all_clear: null,
    who_gave_all_clear: "",
    all_clear_page_sent: "",
    all_clear_page_time: null,
    visitor_complaints: "",
    additional_info: "",
  };

  const validationSchema = Yup.object().shape({
    alarm_activation_date: Yup.date()
      .max(new Date(), "Date shouldn't be a future date")
      .nullable()
      .required("Please enter alarm activation date"),
    emergency_code_number: Yup.string().required(
      "Please enter emergency code number"
    ),
    time_alarm_activated: Yup.date()
      .nullable()
      .when("alarm_activation_date", (alarmActivationDate, schema) => {
        return alarmActivationDate &&
          alarmActivationDate.toDateString() === new Date().toDateString()
          ? schema.max(
              new Date(),
              "Time alarm activated should not be greater than current time"
            )
          : schema;
      })
      .required("Please enter time alarm activated"),
    time_all_clear: Yup.date()
      .nullable()
      .when("time_alarm_activated", (timeAlarmActivated, schema) => {
        const alarmActivationTime = new Date(timeAlarmActivated);
        return schema.min(
          alarmActivationTime,
          "Time All Clear should be after Alarm Activated Time"
        );
      }),
    response_time: Yup.number().typeError("Please Enter Numeric Values Only"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      console.log(values);
      let payload = {
        date:
          values.alarm_activation_date !== null
            ? format(values.alarm_activation_date, "yyyy-MM-dd")
            : null,
        alert_time:
          values.time_alarm_activated !== null
            ? format(values.time_alarm_activated, "HHmm")
            : null,
        code_number: values.emergency_code_number,
        who_called: values.who_called_in_code,
        caller_location: values.exact_caller_location,
        all_page_used: values.all_page_used,
        all_page_time:
          values.time_all_page_sent !== null
            ? format(values.time_all_page_sent, "HHmm")
            : null,
        res_called: values.was_911_called,
        res_time: values.response_time,
        location_emergency: values.exact_location,
        summary_description: values.summary_description,
        emergency_description: description,
        all_clear_time:
          values.time_all_clear !== null
            ? format(values.time_all_clear, "HHmm")
            : null,
        all_clear_given_by: values.who_gave_all_clear,
        all_clear_page_sent: values.all_clear_page_sent,
        all_clear_time_page:
          values.all_clear_page_time !== null
            ? format(values.all_clear_page_time, "HHmm")
            : null,
        guest_complaint: visitorComplaints,
        additional_information: additionalInfo,
      };
      AddActivationLog(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            navigate({
              pathname: "/logs/alarm-activation-log",
            });
            setLoading(false);
          } else {
            setLoading(false);
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          dispatch(getMsg("Something went wrong!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Card mt={6}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => {
              if (isSubmitting) {
                console.log(Object.keys(errors));

                const firstErrorKey = Object.keys(errors)[0];
                if (firstErrorKey) {
                  const firstErrorField = document.querySelector(
                    `[name="${firstErrorKey}"]`
                  );
                  if (firstErrorField) {
                    firstErrorField.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                  // firstErrorField.focus();
                }
              }
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl mt={3}>
                        <DateTimePicker
                          name="alarm_activation_date"
                          label="Alarm activation date *"
                          value={values.alarm_activation_date}
                          onChange={(newValue) => {
                            const presentDate = new Date();
                            if (
                              new Date(newValue).toDateString() ===
                              presentDate.toDateString()
                            ) {
                              setMaxTime(presentDate);
                            } else {
                              setMaxTime(null);
                            }
                            setFieldValue("alarm_activation_date", newValue);
                          }}
                          inputFormat="MM/dd/yyyy HH:mm"
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="alarm_activation_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm/dd/yyyy H:i:s",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl mt={3}>
                        <TimePicker
                          name="time_alarm_activated"
                          label="Time alarm activated *"
                          autoComplete="off"
                          value={values.time_alarm_activated}
                          maxTime={maxTime}
                          onChange={(newValue) => {
                            setFieldValue("time_alarm_activated", newValue);
                          }}
                          ampm={false}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="time_alarm_activated"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="emergency_code_number"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Emergency code number *"
                          autoComplete="off"
                          placeholder=""
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl>
                        <CustomTextField
                          name="who_called_in_code"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Who called in the code?"
                          autoComplete="off"
                          placeholder=""
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl>
                        <CustomTextField
                          name="exact_caller_location"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Exact location of caller"
                          autoComplete="off"
                          placeholder=""
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl>
                        <FormLabel>Was an emergency all page used?</FormLabel>
                        <RadioGroup
                          aria-labelledby="all-page-used"
                          name="all_page_used"
                          row
                          value={values.all_page_used}
                          onChange={(e) =>
                            setFieldValue("all_page_used", e.target.value)
                          }
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl>
                        <TimePicker
                          name="time_all_page_sent"
                          label="Time all page was sent (if used)"
                          autoComplete="off"
                          value={values.time_all_page_sent}
                          onChange={(newValue) => {
                            setTimeAllPageSent(newValue);
                            setFieldValue("time_all_page_sent", newValue);
                          }}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="time_all_page_sent"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl>
                        <FormLabel>Was 911 called?</FormLabel>
                        <RadioGroup
                          aria-labelledby="was-911-called"
                          name="was_911_called"
                          row
                          value={values.was_911_called}
                          onChange={(e) =>
                            setFieldValue("was_911_called", e.target.value)
                          }
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl>
                        <CustomTextField
                          name="response_time"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Response time for 911 (in minutes)"
                          autoComplete="off"
                          placeholder=""
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <CustomTextField
                          name="exact_location"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Exact location of emergency"
                          autoComplete="off"
                          placeholder=""
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={12}>
                      <FormControl>
                        <CustomTextField
                          name="summary_description"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Summary description"
                          autoComplete="off"
                          placeholder=""
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                      <FormControl>
                        <FormLabel>Description of emergency</FormLabel>
                        {/* <CustomTextEditor name="desc_of_emergency" /> */}
                        <QuillWrapper>
                          <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={setDescription}
                            placeholder="Type something.."
                          />
                        </QuillWrapper>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <TimePicker
                          name="time_all_clear"
                          label="Time All Clear was given"
                          autoComplete="off"
                          value={values.time_all_clear}
                          minTime={values.time_alarm_activated}
                          onChange={(newValue) => {
                            // setTimeAllClearGiven(newValue);
                            setFieldValue("time_all_clear", newValue);
                          }}
                          ampm={false}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="time_all_clear"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <CustomTextField
                          name="who_gave_all_clear"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Who gave All Clear?"
                          autoComplete="off"
                          placeholder=""
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <FormLabel>Was an "All Clear" page sent?</FormLabel>
                        <RadioGroup
                          aria-labelledby="all-clear-page-sent"
                          name="all_clear_page_sent"
                          row
                          value={values.all_clear_page_sent}
                          onChange={(e) => {
                            setFieldValue(
                              "all_clear_page_sent",
                              e.target.value
                            );
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <TimePicker
                          name="all_clear_page_time"
                          label="Time of All Clear page"
                          autoComplete="off"
                          value={values.all_clear_page_time}
                          onChange={(newValue) => {
                            setFieldValue("all_clear_page_time", newValue);
                          }}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="all_clear_page_time"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={12}>
                      <FormControl>
                        <FormLabel>
                          Any guest or visitor complaints? Please explain
                        </FormLabel>
                        {/* <CustomTextEditor name="visitor_complaints" /> */}
                        <QuillWrapper>
                          <ReactQuill
                            theme="snow"
                            value={visitorComplaints}
                            onChange={setVisitorComplaints}
                            placeholder="Type something.."
                          />
                        </QuillWrapper>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={12}>
                      <FormControl>
                        <FormLabel>Any additional information?</FormLabel>
                        {/* <CustomTextEditor name="additional_info" /> */}
                        <QuillWrapper>
                          <ReactQuill
                            theme="snow"
                            value={additionalInfo}
                            onChange={setAdditionalInfo}
                            placeholder="Type something.."
                          />
                        </QuillWrapper>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={12}>
                      <Button color="primary" variant="contained" type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function AddAlarmActivationLog() {
  return (
    <React.Fragment>
      <Helmet title="Add Alarm Activation Log" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Alarm Activation Log
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/alarm-activation-log">
          Alarm Activation Log
        </Link>
        <Typography>Add Alarm Activation Log</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddAlarmActivationForm />
    </React.Fragment>
  );
}

export default AddAlarmActivationLog;

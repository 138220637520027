import React, { useState } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { TimePicker } from "@mui/lab";
import axios from "axios";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  IconButton,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import Toaster from "../../tasks/Toaster";
// import { event } from "jquery";
const Button = styled(MuiButton)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const ShiftSchedule = (props) => {
  const {
    shiftoneopen,
    shiftOneClose,
    tovalue,
    setToValue,
    fromvalue,
    setFromValue,
    shiftName,
    startTimer,
    endTimer,
    validateTime,
    setValidateTime,
  } = props;
  const { id } = useParams();
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const initialValues = {
    start_time: startTimer ? startTimer : null,
    end_time: endTimer ? endTimer : null,
  };

  const validationSchema = Yup.object().shape({});
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formatstart = moment(fromvalue).format("HH:mm");
      let formatend = moment(tovalue).format("HH:mm");

      axios
        .post(`update-shift-schedule/${id}`, {
          shift: shiftName,
          from_time: formatstart,
          to_time: formatend,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            shiftOneClose();
            setOpenSnack(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message="Shift Time has been successfully updated"
      />
      <Dialog
        open={shiftoneopen}
        onClose={shiftOneClose}
        aria-labelledby="shift-one-title"
        aria-describedby="shift-one-description"
      >
        <DialogTitle
          id="alert-shiftone-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Set alert for failed mobile logins
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton sx={{ marginRight: "-10px" }} onClick={shiftOneClose}>
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: 15 }}>
          <DialogContentText id="alert-shiftone-description">
            Using the fields below, set as time range of 15 minutes when you
            expect a login to occur. If SOVA does not detect any mobile logins
            during this time, an email and/or SMS alert will be sent to
            recipients in the communication list "No Login". This can be useful
            in receiving timeply alerts if officers fail to report duty on time.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={6}>
                      <TimePicker
                        name="start_time"
                        label="Time from"
                        value={fromvalue ? fromvalue : values.start_time}
                        onChange={(newValue) => {
                          //   let formatedDate = moment(newValue).format("HH:mm");
                          //   setFieldValue("start_time", formatedDate);
                          setFromValue(newValue);
                          setValidateTime(true);
                        }}
                        inputProps={{
                          placeholder: "",
                        }}
                        // onChange={handleChange}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            placeholder={""}
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={6}>
                      <TimePicker
                        name="end_time"
                        label="Time to"
                        value={tovalue ? tovalue : values.end_time}
                        placeholder=""
                        onChange={(newValue) => {
                          //   let formatedDate = moment(newValue).format("HH:mm");
                          //   setFieldValue("end_time", formatedDate);
                          setToValue(newValue);
                          setValidateTime(true);
                        }}
                        inputProps={{
                          placeholder: "",
                        }}
                        // onChange={handleChange}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            placeholder={""}
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} xl={12}>
                    {validateTime === false && (
                      <Typography sx={{ color: "red" }}>
                        Time From must be before Time To
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <DialogActions
                  sx={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingTop: "15px",
                  }}
                >
                  <Button
                    onClick={shiftOneClose}
                    color="primary"
                    variant="outlined"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => {
                      var beginningTime = moment(fromvalue);
                      var endingTime = moment(tovalue);
                      console.log(
                        "Begin is comparing to end",
                        beginningTime.isBefore(endingTime)
                      );
                      setValidateTime(beginningTime.isBefore(endingTime));
                    }}
                    type="submit"
                    disabled={!validateTime}
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "15px" }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShiftSchedule;

export default [
  {
    id: "threat_level",
    label: "TL",
  },
  {
    id: "poi_id",
    label: "POI ID",
  },
  {
    id: "reporting_location",
    label: "Reporting Location",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "alias",
    label: "Alias",
  },
  {
    id: "count",
    label: "Count",
  },
  {
    id: "narrative",
    label: "Narrative",
  },
  {
    id: "image",
    label: "Image",
  },
  {
    id: "date_time",
    label: "DateTime",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import KioskModeSubmitPassword from "./KioskModeSubmitPassword";
import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
}));
function CheckIn() {
  const classes = useStyles();
  const location = useLocation();
  const btnColor = location.state.buttonColor;

  const locationDetail = localStorage.getItem("location");
  const name = JSON.parse(locationDetail);

  const [kioskModeSubmitPasswordModal, setKioskModeSubmitPasswordModal] =
    useState(false);
  const kioskModeSubmitPasswordModalClose = () => {
    setKioskModeSubmitPasswordModal(false);
  };

  const launchFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };
  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setKioskModeSubmitPasswordModal(true);
    }
  }

  const updateParentState = () => {
    kioskModeSubmitPasswordModalClose();
  };
  return (
    <>
      <Box sx={{ p: 5, my: "auto" }} className={classes.startOverCard}>
        <Card sx={{ maxWidth: "1140px", mx: "auto" }}>
          <CardHeader title={name.LocationName} />
          <CardContent>
            <Grid
              container
              spacing={10}
              sx={{
                "& .MuiButton-containedPrimary:hover": {
                  opacity: 0.7,
                  backgroundColor: btnColor ? btnColor : "#000000",
                },
              }}
            >
              {/* <Grid item xs={12} md={8} sx={{ mx: "auto" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.startOverButton}
                  component={NavLink}
                  to="/visitors/kiosk-mode-check-in"
                >
                  First time visitor, no scheduled meeting
                </Button>
              </Grid> */}
              <Grid item xs={12} md={8} sx={{ mx: "auto" }}>
                <Button
                  sx={{
                    backgroundColor: btnColor ? btnColor : "success",
                  }}
                  variant="contained"
                  className={classes.startOverButton}
                  component={NavLink}
                  to="/visitors/returning-visitor-info"
                  state={{ searchParam: "Returning" }}
                >
                  I am a returning visitor
                </Button>
              </Grid>
              <Grid item xs={12} md={8} sx={{ mx: "auto" }}>
                <Button
                  sx={{
                    backgroundColor: btnColor ? btnColor : "success",
                  }}
                  variant="contained"
                  className={classes.startOverButton}
                  component={NavLink}
                  to="/visitors/returning-visitor-info"
                  state={{ searchParam: "Pre-book" }}
                >
                  Pre-booked visitor with a scheduled meeting
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end", mt: "15px" }}>
            <Button component={NavLink} to="/visitors/kiosk-mode">
              Start over
            </Button>
          </CardActions>
        </Card>
      </Box>
      {kioskModeSubmitPasswordModal && (
        <KioskModeSubmitPassword
          kioskModeSubmitPasswordModal={kioskModeSubmitPasswordModal}
          setKioskModeSubmitPasswordModal={setKioskModeSubmitPasswordModal}
          kioskModeSubmitPasswordModalClose={kioskModeSubmitPasswordModalClose}
          launchFullScreen={launchFullScreen}
          onDataFromChild={updateParentState}
        />
      )}
    </>
  );
}

export default CheckIn;

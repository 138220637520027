import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  FormLabel,
} from "@mui/material";
const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
}));
function ScanQrCode() {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ p: 5, my: "auto" }} className={classes.startOverCard}>
        <Card sx={{ maxWidth: "1140px", mx: "auto" }}>
          <CardHeader
            title={
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    Marigold Hotel
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    Returning Visitor
                  </Typography>
                </Box>
              </>
            }
          />
          <CardContent>
            <Grid container spacing={10} justifyContent="space-between">
              <Grid item xs={12} md={12} sx={{ mx: "auto" }}>
                <FormLabel>
                  Scan the QR code on your Mobile Visitor Pass by placing it
                  face down on the flat scanner.
                </FormLabel>
                <TextField fullWidth name="ext_scan_id" id="scan-qr-code" />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  component={NavLink}
                  to="/visitors/returning-visitor-info"
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end", mt: "15px" }}>
            <Button component={NavLink} to="/visitors/kiosk-mode">
              Start over
            </Button>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}

export default ScanQrCode;

import { Data } from "@react-google-maps/api";
import { id } from "date-fns/locale";
import { ajaxPrefilter } from "jquery";
import api from "./api";
// Users
const Login = (data) => api.post("login", data);
const ForgetPassword = (data) => api.post("forgotPassword", data);
const ResetPassword = (data) => api.post("resetPassword", data);

const GetUserList = (page, data = null) =>
  api.post("get-user-list?page=" + page, data);
const UpdateColor = (id, data = null) => api.post("update-color/" + id, data);
const MakeActiveInactive = (id, data = null) =>
  api.post("alpha/users/make-active-inactive/" + id, data);
const UpdateStatus = (id, data = null) =>
  api.post("alpha/users/update-status/" + id, data);
const VisitorMobile = (data = null) =>
  api.post("visitor/send_mobile_visitor_pass ", data);
const GenerateQr = (data = null) => api.post("enable-2fa", data);
const LoginVerify = (data = null) => api.post("generate-QR", data);

const Disable2FA = (data = null) => api.post("disable-2fa", data);
const VerifyCode = (data = null) => api.post("verify-2fa", data);
const CheckRecoveryCode = (data = null) => api.post("checkRecoveryCode", data);
const ExpireRecoveryCode = (data = null) =>
  api.post("expireRecoveryCode", data);
const SaveRecoveryCode = (data = null) => api.post("saveRecoveryCode", data);
const Reset2FA = (data = null) => api.post("reset-2fa", data);
const UpdateLoginAuth = (id, data = null) =>
  api.post("alpha/users/update-login-auth/" + id, data);
const DeleteUser = (id) => api.post("delete-multiple-user/" + id);
const GetUserView = (id) => api.get("get-user-view/" + id);
const UserCorporate = () => api.get("corporate-list");
const UserGroupList = () => api.get("user-group-list");
const UserProperty = (id) => api.get("property-list/" + id);
const AddUser = (data) => api.post("add-user", data);
const UpdateUser = (data = null) => api.post("user-update", data);
const UpdateUserPassword = (id, data = null) =>
  api.post("update-password/" + id, data);
const UserColumnList = () => api.get("user-column-list");
const ModuleList = () => api.get("module-list");
const MyProfile = () => api.post("my-profile");
const EditProfile = (data) => api.post("edit-profile", data);
const UnMasked = (data) => api.post("unmasked", data);

//user end
// lost and found
const AddLost = (data) => api.post("/lost-and-found/add-lost", data);
const AddFound = (data) => api.post("/lost-and-found/add-found", data);
const CategoryList = (data) => api.get("/lost-and-found/category-list", data);
const EstimatedList = (data) =>
  api.post("/lost-and-found/estimated-list", data);
const LostAndfFound = (page, data = null) =>
  api.post("/lost-and-found/list?page=" + page, data);
const StorageLocationList = (data) =>
  api.post("/lost-and-found/storage-location-list", data);
const AddStorageLocation = (data) =>
  api.post("/lost-and-found/add-storage", data);
const DepartmentList = (data) =>
  api.post("/lost-and-found/department-list", data);
const LostFoundActivityLogs = (page, data = null) =>
  api.post("/lost-and-found/activity-logs?page=" + page, data);
const RemoveActivityLogs = (data) =>
  api.post("/lost-and-found/remove-activity-logs", data);
const SingleLostfoundActivityLog = (id) =>
  api.get("/lost-and-found/activity-log/" + id);
const LostfoundSubItems = (id) => api.get("/lost-and-found/child-list/" + id);
const CloneLostFound = (id) => api.post("/lost-and-found/clone-record/" + id);
const LostfoundUnmerge = (id) => api.get("/lost-and-found/unmerge/" + id);
const UpdateOneColumn = (data) =>
  api.post("/lost-and-found/update-one-column", data);
const UpdateDispose = (data) =>
  api.post("/lost-and-found/update-dispose", data);
const RewriteAi = (data) =>
  api.post("Ir_Classification/rewite-ir-with-ai", data);
const SingleRecord = (id) => api.get("/lost-and-found/single-record/" + id);
const CountryList = (data) => api.post("/lost-and-found/country-list", data);
const EditLostFound = (data) =>
  api.post("/lost-and-found/edit-lost-found", data);
const SaveLockedStatus = (data) =>
  api.post("/lost-and-found/save-locked-status", data);
const CreateMergedGroup = (data) =>
  api.post("/lost-and-found/create-merged-group", data);
const MergedGroup = (data) => api.post("/lost-and-found/merged-group", data);
const GetDetails = (id) => api.get("/lost-and-found/get-details/" + id);
const GetBagTag = (id) => api.get("/lost-and-found/get-bag-tag/" + id);
const GetListByIds = (data) =>
  api.post("/lost-and-found/get-list-by-ids", data);
const SendShipmentRequest = (data) =>
  api.post("/lost-and-found/send-shipment-request", data);
const GetRevisions = (id) => api.get("/lost-and-found/get-revisions/" + id);
const CommunicationLog = (data = null) =>
  api.post("/lost-and-found/communication-log", data);
const SaveCommunicationLog = (data) =>
  api.post("/lost-and-found/save-communication-log", data);
const TransactionsLabel = (data) =>
  api.post("/lost-and-found/transactions-label", data);
const TransactionPdf = (id) => api.get("/lost-and-found/transaction-pdf/" + id);
const MatchLostFoundList = (data = null) =>
  api.post("/lost-and-found/match-lost-found", data);
const NoMatch = (data) => api.post("/lost-and-found/no-match", data);
const ImageSearch = (data) => api.post("/lost-and-found/image-search", data);
const MergeDuplicates = (data) =>
  api.post("/lost-and-found/merge-duplicates", data);
const ActiveCarrier = (data) =>
  api.post("/lost-and-found/active-carrier", data);
const ShipmentList = (page, data = null) =>
  api.post("/lost-and-found/shipment-list?page=" + page, data);
const ShipmentsForAdmin = (page, data = null) =>
  api.post("/lost-and-found/shipments-for-admin?page=" + page, data);
const DownloadShipmentsCsv = (data) =>
  api.post("/lost-and-found/download-shipments-csv", data);
const DetailsForShipment = (id) =>
  api.get("/lost-and-found/details-for-shipment/" + id);
const GetShipments = (data) => api.post("/lost-and-found/get-shipments", data);
const SaveLostfoundPayment = (data) =>
  api.post("/payment/save-lostfound-payment", data);
const GetLfByControlId = (id) =>
  api.get("/lost-and-found/get-lf-by-control-id/" + id);
const AddItemCategoryList = (data = null) =>
  api.post("/lost-and-found/add-category-list", data);

// Reports Module APIs:
const TourPatrolList = (page, data) =>
  api.post("/get-tour-petrol-list?page=" + page, data);
const DeleteTour = (id) => api.post(`delete-tour-petrol/${id}`);
const UserAutocompleteList = (data) => api.get("user-list", data); //for filter section autocomplete
const TourAutocompleteList = (data) => api.get("tour-list", data); //for filter section autocomplete
const TourMissedButtonList = (data) => api.post("tour/get-button-missed", data); //for outer dialog tourname
const TourButtonMissedUser = (data) =>
  api.post("tour/get-button-missed-user", data); //for outer dialog username
const TourUserListMissedButton = (data) =>
  api.post("get-user-list-missed-button", data); //for inner dialog in tourname
const TourUserListMissedButtonU = (data) =>
  api.post("get-user-list-missed-button-u", data); //for inner dialog in username
const TourResultTooltipList = (data) => api.post("get-tooltip-list", data); //Api calling function for Result tooltip in tour report
const ViewTourReport = (data) => api.post("view-tour-report", data); //API calling function for View tour report
const TourPatrolColumns = () => api.get("tour-patrol-column-list"); //API for columns section on Tour Patrol
const TourMapLatLong = (data) => api.post("get-tour-map-latlong", data); //API for view map section.
const BarChartData = (data) => api.post("get-chart-data", data); //API for getting bar chart data.
//Reports -> Non Tour Task
const NonTourTaskList = (page, data) =>
  api.post("get-non-tour-task?page=" + page, data); //API calling func for Non-tour=-task listing.
const NonTourUserList = (data) => api.post("non-tour-user-list", data); //Api call for dialog on non tour task Dialog for user list.
const NonTourUserAverageTime = (data) =>
  api.post("non-tour-user-avg-time", data); //API call for dialog in average time column
const NonTourTaskColumns = () => api.get("non-tour-column-list"); //API for Columns section in Non tour task.
//Reports -> Daily Activity
const DailyActivityReport = (page, data) =>
  api.post("daily-activity-reports-list?page=" + page, data); //API call for Daily activity listing
const DailyActivityData = (data) => api.post("get-activity-data", data); //API call for daily activity data dialog.
const DailyActivityRecentData = (id) => api.get("get-activity-user/" + id); //API for recent activity dialog
const RecentUserActivity = (id) => api.get("get-user-activity-details/" + id); // API for Recent User Activity
const DailyActivityChangeLog = (data) =>
  api.post("get-activity-change-log", data); //API for change log dialog.
const DailyActivityColumns = () => api.get("daily-activity-column-list"); //API for columns in daily activity
const DARInquiryDetails = (id) => api.get("get-detail-for-dar/" + id); //API for DAR inquiry dialog Email section
const SendDAREmailList = () => api.get("get-user-email-details"); //API for getting the list of emails in Send dar dialog
const DARInquiryEmail = (data) => api.post("send-mail-for-dar", data); //API for sending email in DAR inquiry
const DARMultiEmail = (data) => api.post("send-multiple-user-mail", data); //API for Send DAR multiple email
const DAREditComment = (data, id) =>
  api.post("edit-daily-activity/" + id, data); //API for editing comment and location in dar.
const ActivityAlertList = () => api.get("get-activity-alert"); //API for getting the activity alert listing
const AddActivityAlert = (data) => api.post("add-activity-alert", data); //API for adding activity alert
const DeleteActivityAlert = (id) => api.get("delete-activity-alert/" + id); //Api for deleting Activity alert
//Reports -> User Performance
const UserPerformanceList = (page, data) =>
  api.post("get-performance-list?page=" + page, data);
const UserActivityLog = (data) => api.post("shift-user-activity", data); //API call for Dialog in user activity
const UserActivityMissedButton = (data) =>
  api.post("tour/get-button-missed-user", data); //API call for missed button columns in performance list
const UserActivityShiftTour = (data) => api.post("shift-tour-data", data); //API for tour hour column dialog
const UserActivityShiftReport = (data) => api.post("shift-report-list", data); //API for NTT count column dialog
const UserPerformanceColumns = () => api.get("performance-report-column-list"); //API call for Columns feature
//Reports -> Passdown Section
const PassdownItemList = (page, data) =>
  api.post("get-passdown-data?page=" + page, data); //API for listing On Passdown.
const PassdownItemColumns = () => api.get("passdown-report-column-list"); //API call for columns features
//Reports -> Button History
const ButtonHistoryList = (page, data) =>
  api.post("get-button-history-list?page=" + page, data);
const GetButtonName = (id) => api.get("get-button-history/" + id); //API for dropdown for button name
const RecentScansData = (data) => api.post("get-tag-description", data);
const ButtonHistoryColumns = () => api.get("button-history-report-column-list");
//Reports -> Tour Summary
const TourSummaryList = (page, data) =>
  api.post("get-tour-summary-list?page" + page, data);
const TourSummaryColumns = () => api.get("tour-summary-report-column-list");
// Reports -> Missed Tour Buttons
const MissedTourButtonList = (page, data) =>
  api.post("get-missed-tour-list?page=" + page, data);
const MissedTourButtonColumns = () => api.get("tour-missed-report-column-list");
//Reports -> SMS Reports
const SmsReportList = (page, data) =>
  api.post("get-sms-reports-list?page=" + page, data);
const SmsReportColumns = () => api.get("sms-report-column-list");
const SmsCreditPop = () => api.post("report/get-sms-credit-popup");
const SaveSMSCreditPayment = (data) =>
  api.post("payment/save-sms-credit-payment", data);
const GetMessageSubcriptionDetail = (id) => api.get("get-edit-property/" + id);
const SmsReports = () => api.post("sms-report");

//Reports -> Device Report
const DeviceReportList = (page, data) =>
  api.post("get-device-report-list?page=" + page, data);
const DeviceReportColumns = () => api.get("device-report-column-list");
//Reports -> Send IR Log
const IrLogList = (page, data) => api.get("send-ir-log?page=" + page, data);
//Report -> Incidents section
const IncidentsReportList = (page, data = null) =>
  api.post("report/incident-list?page=" + page, data);
const UpdateRequireReview = (id, data = null) =>
  api.post("report/update-require-review/" + id, data);
const DeleteIncident = (id) => api.post("report/delete-incident/" + id);
const ReportUpdateStatus = (id, data = null) =>
  api.post("report/update-status/" + id, data);
const AppendIrDeatails = (data = null) =>
  api.post("report/append-ir-details", data);
const GetStatusAudit = (id) => api.get("report/get-status-audit/" + id);
const UpdateStatusReason = (data = null) =>
  api.post("report/update-status-reason", data);
const SendIncidnetOfficier = (data = null) =>
  api.post("report/send-incident-to-officer", data);
const SendEmailToReportingOfficier = (data = null) =>
  api.post("report/send-email-to-reporting-offcr", data);
const IncidentTypeListDropdown = () => api.post("report/incident-type-list"); //API for dropdown in incident type at add incident form
const SaveIncidentReport = (data) => api.post("report/save-incident", data);
const SearchInvolvedPerson = (data) =>
  api.post("/report/search-involved-person", data);
const SavePersonInvolved = (data) =>
  api.post("report/save-involved-person", data);
const InvolvedPersonListing = (id) =>
  api.get("report/involved-person-list/" + id);
const NorEmailListing = (id) => api.get("report/send-nor-list/" + id);
const SendNorMail = (data) => api.post("report/send-nor", data);
const SendNorPdf = (data) => api.post("report/send-nor-pdf", data);
const IrTaskList = () => api.get("report/ir-task-list");
const LiveDispatchList = () => api.post("lives/live-dispatch-ajax");
const VehicleInventoryList = () => api.post("get-vehicle-inventories");
const VehicleInspectionList = () => api.post("get-vehicle-inspection");
const AddVehicleInventory = (data) => api.post("save-vehicle-inventory", data);
const AddVehiclesInspection = (data) =>
  api.post("save-vehicle-inspection", data);
const AddNewTask = (data) => api.post("lives/save-dispatch-activity", data);
const DeleteInventory = (data) => api.post("delete-vehicle-inventory", data);
const ChangeTaskStatus = (data) => api.post("lives/update-scanTime", data);
const ChangeCompleteStatus = (data) =>
  api.post("lives/fetch-non-tour-data", data);
const CompletedTask = (data) =>
  api.post("lives/save-dispatch-with-FinishTime", data);

const DeleteInspection = (data) => api.post("delete-vehicle-inspection", data);
const DeleteInvolvedPerson = (id) =>
  api.get("report/delete-incident-involved-person/" + id);
const GetInvolvedPersonData = (id) =>
  api.get("report/view-involved-person/" + id);
const AddIncident = (data) => api.post("report/add-incident-beta", data);
const UploadMediaFile = (data) => api.post("report/upload-file", data);
const YearList = () => api.get("report/vehicle-year-list");
const MakeList = (data) => api.post("report/vehicle-make-from-year", data);
const ModelList = (data) => api.post("report/vehicle-model-from-make", data);
const DriversList = (id) => api.get("report/vehicle-involved-drivers/" + id);
const SearchPlateNumber = (data) =>
  api.get("report/search-plate-number/" + data);
const SaveInvolvedVehicle = (data) =>
  api.post("report/save-vehicle-report", data);
const InvolvedVehicleListing = (id) =>
  api.get("report/incident-vehicle-list/" + id);
const GetInvolvedVehicleData = (id) =>
  api.get("report/incident-vehicle-view/" + id);
const DeleteInvolvedVehicle = (id) =>
  api.get("report/delete-incident-vehicle/" + id);
const SaveInvolvedWitness = (data) =>
  api.post("report/save-witness-statement", data);
const InvolvedWitnessListing = (id) =>
  api.get("report/witness-statement-list/" + id);
const GetInvolvedWitnessData = (id) =>
  api.get("report/view-witness-statement/" + id);
const DeleteInvolvedWitness = (id) =>
  api.get("report/delete-Witness-statement/" + id);
const GetMediaList = (data = null) =>
  api.post("report/get-incident-files", data);
const UpdateMediaFile = (data) => api.post("report/update-media-file", data);
const DeleteMediaFile = (id) => api.get("report/delete-media-file/" + id);
const SaveReference = (data) => api.post("report/save-reference", data);
const ReferenceListing = (id) => api.get("report/reference-list/" + id);
const DeleteReference = (id) => api.get("report/delete-reference/" + id);
const ViewReference = (id) => api.get("report/view-reference/" + id);
const GetIncidentFiles = (data) => api.post("report/get-incident-files", data);
const SaveFinancialInfo = (data) => api.post("report/save-ir-finance", data);
const GetIncidentReportData = (id) => api.get("report/view-incident/" + id);
const FinancialListing = (id) => api.get("report/list-ir-financial-loss/" + id);
const GetInvolvedFinanceData = (id) =>
  api.get("report/view-ir-finanical-loss/" + id);
const DeleteInvolvedFinance = (id) =>
  api.post("report/delete-financial-loss/" + id);
const DeleteInvolvedFinanceImage = (id) =>
  api.post("report/delete-financial-loss-image/" + id);
const getLocationLatLong = (id) => api.get("report/location-lat-long/" + id);
const GetDefaultNarrative = (id) =>
  api.get("/report/get-default-narrative/" + id);
const GetInterviewQuestionList = () =>
  api.get("/report/ir-force-interview-questions");
const ViewInterviewAns = (id) =>
  api.get("/report/view-Interview-questions-answer/" + id);
const SaveUseOfForce = (data) =>
  api.post("/report/save-answers-questions", data);
const UpdateForce = (data) =>
  api.post("/report/update-answers-questions", data);
const DownloadReportIncident = (data) =>
  api.post("report/download-incident-report", data);
const IncidentUserList = () => api.get("user-list");
const IncidentIccStatus = (data) =>
  api.post("report/save-incident-icc-status", data);
const IncidentIccStatusList = (id) =>
  api.get("report/incident-icc-status-list/" + id);
// incident investigation
const saveIncidentInvestigation = (data) =>
  api.post("report/save-incident-investigation", data);
//Report -> Incidents section
//Account Section APIs
//Property section API
const GetPropertyList = (page, data = null) =>
  api.get("get-properties?page=" + page, data);
const DeleteProperty = (id) => api.get("delete-properties/" + id);
const PropertyListColumns = () => api.get("property-column-list");
const PropertyStatus = (id, data) =>
  api.post("update-properties-status/" + id, data);
const AddProperties = (data) => api.post("add-properties", data);
const CorporateList = () => api.get("corporate-list");
const TimeZoneList = () => api.get("timezone-list");
const Modulelist = () => api.get("module-list");
const UpdateProperty = (id, data) => api.post("update-properties/" + id, data);
const ToggleButtonStatus = (id, data) =>
  api.post("update-dispatch-status/" + id, data);
const UserPropertyList = () => api.get("user-property-list");
const ModuleListData = () => api.get("module-list");
const ModulePermission = (id) => api.get("module-permission-list/" + id);
const GetEditPropertyInfo = (id) => api.get("get-edit-property/" + id);
//Corporate List section
const CorporateListing = (page, data = null) =>
  api.get("get-corporate?page=" + page, data);
const CorporateColumns = () => api.get("corporate-column-list");
const DeleteCorporate = (id) => api.post("delete-corporate/" + id);
const ViewCorporate = (id) => api.get("view-corporate/" + id);
const AddCorporateUser = (data) => api.post("add-corporate", data);
const UpdateCorporate = (id, data) => api.post("update-corporate/" + id, data);
const GetFloorList = (id, page, data = null) =>
  api.get(`get-floors/${id}?page=` + page, data);
const DeleteFloor = (id) => api.post("delete-floor/" + id);
const AddFloorList = (data) => api.post("add-floor", data);
const ViewFloor = (id) => api.get("view-floor/" + id);
const UpdateFloor = (data) => api.post("update-floor", data);
const GetFloorColumnList = () => api.get("floor-column-list");

//API for header location
const GetHeaderLocation = () => api.post("properties-list");
//Tour API Start
const GetTourList = (page, data = null) =>
  api.post("get-tour-list?page=" + page, data);
const UpdateTourStatus = (id, data = null) =>
  api.post("update-tour-status/" + id, data);
const UpdateTour = (id, data = null) => api.post("update-tour/" + id, data);
const ViewTour = (id) => api.get("view-tour/" + id);
const GetTourBuilding = () => api.get("get-tour-building-list");
const Copytour = (id, data = null) => api.post("copy-tour/" + id, data);
const GetTourCheckpointList = (id, page, data = null) =>
  api.post(`get-tour-checkpoint-list/${id}?page=` + page, data);
const UpdateButtonCritical = (id, data = null) =>
  api.post("update-button-critical/" + id, data);
const UpdateCheckpointStatus = (id, data = null) =>
  api.post("update-checkpoint-status/" + id, data);
const GetTourButton = (id) => api.get("get-tour-button/" + id);
const GetAllTour = (data) => api.get(`get-all-tour`, data);
const GetCheckPointList = (id) => api.post("get-tour-checkpoint-list/" + id);
const ViewTourCheckpoint = (data) => api.post("view-tour-checkpoint", data);
const GetTaskButtonList = (data) => api.get("get-task-button-list", data);
const GetTaskButton = (id) => api.get("get-task-button/" + id);
const GetAllTask = (id) => api.get("get-all-task/" + id);
const UpdateTourCheckpoint = (data = null) =>
  api.post("update-tour-checkpoint", data);
const GetTourBuildingList = () => api.get("get-tour-building-list");
const DeleteTourBuilding = (id, data = null) =>
  api.post("delete-tour-building/" + id, data);
const AddTourBuildings = (data = null) => api.post("add-tour-building", data);
const UpdateTourBuilding = (id, data = null) =>
  api.post("update-tour-building/" + id, data);
const AddTours = (data = null) => api.post("add-tour", data);
// const ExportCheckpoints = () => api.get("export-checkpoints");
const ExportCheckpoints = (params) => api.get("export-checkpoints", { params });
const UpdateCheckpointSchedule = (id, data = null) =>
  api.post("update-checkpoint-schedule/" + id, data);
const UpdateTourSchedule = (id, data = null) =>
  api.post("update-tour-schedule/" + id, data);
const RemoveTourTime = (id) => api.post("remove-tour-time/" + id);
const RemoveButtonTime = (id) => api.post("remove-button-time/" + id);
const LoadChildList = () => api.get("load-child-list");
const BuildVisualTour = () => api.post("tours/build-visual-tour");
const UpdateLatLong = (data = null) => api.post("tours/update-lat-long", data);
const SaveButtonInlineData = (id, data = null) =>
  api.post("save-button-inline-data/" + id, data);
const GetTourShiftTime = () => api.get("get-tour-shift-time");
const UpdateTourShiftTime = (data = null) =>
  api.post("update-tour-shift-time", data);
const ReorderButton = (data = null) => api.post("reorder-button", data);
//Tour API End

// Task API Start
const GetTaskList = (page, data = null) =>
  api.post("get-tasks-list?page=" + page, data);
const GetNonTourDataList = () => api.post("lives/get-non-tour-data");
const TaskActive = (id, data = null) => api.post("task-active/" + id, data);
const UpdateTaskStatus = (id, data = null) =>
  api.post("update-task-status/" + id, data);
const GetTaskAssignment = (id) => api.get("get-task-assignment/" + id);
const DeleteTaskAssignment = (id) => api.get("delete-task-assignment/" + id);
const DelelteTaskButton = (id) => api.get("delete-task-button/" + id);
const ViewTask = (id) => api.get("view-task/" + id);
const ViewEditSchedule = (id) => api.get("view-edit-schedule/" + id);
const GetScheduleTask = (id) => api.get("get-schedule-task/" + id);
const ViewTaskButton = (id) => api.get("view-task-button/" + id);
const ViewScheduleTask = (id) => api.get("view-schedule-task/" + id);
const UpdateTask = (id, data = null) => api.post("update-task/" + id, data);
const AddScheduleTask = (data = null) => api.post("add-schedule-task", data);
const UpdateCustomButtonText = (id, data = null) =>
  api.post("update-custom-button-text/" + id, data);
const TaskColumnList = () => api.get("task-column-list");
const AddTasks = (data = null) => api.post("add-task", data);
const UpdateScheduleTask = (id, data = null) =>
  api.post("update-schedule-task/" + id, data);
const UpdateTaskButton = (id, data = null) =>
  api.post("update-task-button/" + id, data);
const UpdateScheduleTime = (data = null) =>
  api.post("update-schedule-time", data);
const CopyTask = (data = null) => api.post("copy-task", data);
// Task API End
//Equipment Api
const GetEquipmentList = (page, data = null) =>
  api.post("get-equipment-list?page=" + page, data);
const EquipmentActiveInactive = (id, data) =>
  api.post("equipment-active-inactive/" + id, data);
const DeleteEquipment = (id) => api.get("delete-equipment/" + id);
const GetEquipmentUserList = (page, data = null) =>
  api.post("equipment-user-list?page=" + page, data);
const AddEquipUser = (data) => api.post("add-equipment-user", data);
const EquipmentLog = (page, data = null) =>
  api.get("equipment-log?page=" + page, data);
const EquipmentUserActive = (id, data) =>
  api.post("equipment-user-active-inactive/" + id, data);
const DeleteEquipmentUser = (id) => api.post("delete-equipment-user/" + id);
const GetAllDepartment = () => api.get("get-all-department");
const PositionByDepartment = (id) => api.get("position-by-department/" + id);
const GetSecurityLevel = (id) => api.get("get-security-level/" + id);
const ViewEquipmentUser = (id) => api.get("view-equipment-user/" + id);
const UpdateEquipmentUser = (data = null) =>
  api.post("update-equipment-user", data);
const GetEquipmentListLog = (id, data = null) =>
  api.get("get-equipment-log-list/" + id, data);
const GetEquipmentUserLog = (id, data = null) =>
  api.get("get-equipment-user-log/" + id, data);
const GetPositionList = (page, data = null) =>
  api.post("get-position-list?page=" + page, data);
const DeletePosition = (id) => api.post("delete-position/" + id);
const GetPositionSL = (id) => api.get("get-position-sl/" + id);
const AddPosition = (data) => api.post("add-position", data);
const ViewPosition = (id) => api.get("view-position/" + id);
const UpdatePosition = (id, data = null) =>
  api.post("update-position/" + id, data);
const GetDepartmentList = (page, data = null) =>
  api.post("get-department-list?page=" + page, data);
const DeleteDepartment = (id) => api.post("delete-department/" + id);
const AddEquipDepartment = (data) => api.post("add-department", data);
const UpdateDepartment = (id, data = null) =>
  api.post("update-department/" + id, data);
const GetEquipmentDetail = (page, data = null) =>
  api.post("get-equipment-detail?page=" + page, data);
const ViewEquipDetial = (id) => api.get("view-equipment-detail/" + id);
const AddUpdateEquipmentDetail = (data) =>
  api.post("add-update-equipment-detail", data);
const GetAddEquipmentType = () => api.get("get-add-equipment-type");
const AddEquipType = (data) => api.post("add-equipment-type", data);
const DeleteEquipmentType = (id) => api.post("delete-equipment-type/" + id);
const UpdateEquipmentType = (id, data = null) =>
  api.post("update-equipment-type/" + id, data);
const GetAddKeyRingList = () => api.get("get-add-key-ring-list");
const DeletekeyRing = (id) => api.post("delete-key-ring/" + id);
const AddNewKeyRing = (data) => api.post("add-new-key-ring", data);
const UpdateKeyRing = (data) => api.post("update-key-ring", data);
const GetAddKeyCabinetList = () => api.get("get-add-key-cabinet-list");
const DeleteKeyCabinet = (id) => api.post("delete-key-cabinet/" + id);
const AddNewKeyCabinet = (data) => api.post("add-new-key-cabinet", data);
const UpdateKeyCabinet = (data) => api.post("update-key-cabinet", data);
const GetAddKeyDoorList = () => api.get("get-add-key-door-list");
const DeleteKeyDoor = (id) => api.post("delete-key-door/" + id);
const AddNewKeyDoor = (data) => api.post("add-new-key-door", data);
const UpdateKeyDoor = (data) => api.post("update-key-door", data);
const GetAddKeyLockNumberList = () => api.get("get-add-key-lock-number-list");
const DeleteKeyLockNumber = (id) => api.post("delete-key-lock-number/" + id);
const AddNewKeyLockNumber = (data) => api.post("add-new-lock-number", data);
const UpdateKeyLockNumber = (data) => api.post("update-new-lock-number", data);
const GetAddKeyBuildingList = () => api.get("get-add-key-building");
const DeleteKeyBuilding = (id) => api.post("delete-key-building/" + id);
const AddNewKeyBuilding = (data) => api.post("add-new-key-building", data);
const UpdateKeyBuilding = (data) => api.post("update-key-building", data);
const GetAddKeyTypeList = () => api.get("get-add-key-type-list");
const DeleteKeyType = (id) => api.post("delete-key-type/" + id);
const AddNewKeyType = (data) => api.post("add-new-key-type", data);
const UpdateKeyType = (data) => api.post("update-key-type", data);
const AddEquip = (data) => api.post("add-equipment", data);
const ViewEquipment = (id) => api.get("veiw-equipment/" + id);
const UpdateEquipment = (data) => api.post("update-equipment", data);
const ViewEquipDepartmentList = (data) =>
  api.post("view-equip-department-list", data);
const GetAddKeyGroupList = () => api.get("get-add-key-group-list");
const AddEquipmentGroup = (data) => api.post("add-equipment-group", data);
const DeleteKeyGroup = (id) => api.post("delete-key-group/" + id);
const UpdateEquipmentGroup = (data) => api.post("update-equipment-group", data);
const GetEditEquipUser = (data) => api.get("get-edit-equipment-user", data);
const ViewProcessEquipment = (id) => api.get("view-process-equipment/" + id);
const CheckEquipments = (data) => api.post("check-equipments", data);
const SearchEquipmentUser = () => api.post("search-equipment-user");
const DeleteAssignedEquipemnt = (id) =>
  api.post("delete-assigned-equipment/" + id);
const ViewAssignEquipment = (id) => api.post("view-assgin-equipment/" + id);
const UpdateEquipUser = (data) => api.post("update-equip-user", data);
const EquipmentCheckin = (data) => api.post("equip-detail", data);
const BulkCheckIn = (id) => api.post("bulk-check/" + id);
const EquipmentTypeTree = (id) => api.post("equipment-type-tree");
const ViewEquipmentType = (id) => api.get("view-equipment-type/" + id);
const ViewEquipmentKeyGroup = (id) => api.get("view-equipment-key-group/" + id);
const KeyGroupTree = (id) => api.post("key-group-tree");
const ViewEquipmentUsers = (data = null) =>
  api.post("view-equipment-users", data);
const UpdatePositionUsers = (data = null) =>
  api.post("update-Position-users", data);
const UpdateUserImage = (data) => api.post("update-user-image", data);
// Start Logs module:- Vehicle Management
const AddVehicle = (data = null) => api.post("logs/vehicle/add-vehicle", data);
const VehicleManagementList = (page, data = null) =>
  api.post("logs/vehicle/vehicle-management-list?page=" + page, data);
const ViewVehicle = (id) => api.get("logs/vehicle/view-vehicle/" + id);
const EditVehicle = (data = null) =>
  api.post("logs/vehicle/edit-vehicle", data);
const DeleteVehicle = (id) => api.post("logs/vehicle/delete-vehicle/" + id);
const VehicleViolationList = (id, page, data = null) =>
  api.post(`logs/vehicle/vehicle-violation-list/${id}?page=` + page, data);
const AddVehicleViolation = (id, data = null) =>
  api.post("logs/vehicle/add-vehicle-violations/" + id, data);
const ViewVehicleViolation = (id) =>
  api.get("logs/vehicle/view-vehicle-violation/" + id);
const EditVehicleViolation = (data = null) =>
  api.post("logs/vehicle/edit-vehicle-violations", data);
const DeleteVehicleViolation = (id) =>
  api.post("logs/vehicle/delete-vehicle-violation/" + id);
const GenerateVechicleId = () => api.get("logs/vehicle/generate-vehicle-id");
const VehicleIdExist = (data = null) =>
  api.post("logs/vehicle/vehicle-id-exist", data);
const VehicleByYear = (data = null) =>
  api.post("logs/vehicle/vehicle-make-year", data);
const EquipmentListPdf = (data) => api.post("download-equipment", data);
const EquipmentUserPdf = (data) => api.post("download-equipment-users", data);
const ImportEquipmentUser = (data) => api.post("import-equipment-user", data);
// End Logs module:- Vehicle Management

// Start Logs module:- Employee Management
const ListEmployee = (page, data = null) =>
  api.post("/logs/emplyees/employee-list?page=" + page, data);
const SaveEmployee = (data) => api.post("logs/emplyees/add-employee", data);
const ViewEmployee = (id) => api.get("logs/emplyees/view-employee/" + id);
const DeleteEmployee = (id) => api.post("logs/emplyees/delete-employee/" + id);
const UpdateEmployee = (data) => api.post("logs/emplyees/edit-employee", data);
const ReissueList = (id) => api.get("logs/emplyees/reissue-list/" + id);
const AddReissueId = (data) => api.post("logs/emplyees/add-reissue-id", data);
// End Logs module:- Employee Management

// Start Logs module:- Special visitors
const SpecialVisitorList = (page, data = null) =>
  api.post("/logs/visitor/special-visitor-list?page=" + page, data);
const DeleteSpecialVisitor = (id) =>
  api.post("logs/visitor/delete-special-visitor/" + id);
const SaveSpecialVisitor = (data) =>
  api.post("logs/visitor/add-special-visitor", data);
const ViewSpecialVisitor = (id) =>
  api.get("logs/visitor/view-special-visitor/" + id);
const EditSpecialVisitor = (data) =>
  api.post("logs/visitor/edit-special-visitor", data);
const PrintSpecialVisitor = () =>
  api.post("logs/visitor/print-special-visitor");
// End Logs module:- Special visitors

// Start Logs module:- overdue key report
const OverdueKeyReportList = (page, data = null) =>
  api.get("logs/overdue-key-report/overdue-key-report-list?page=" + page, data);
const DeleteOverdueKeyReport = (id) =>
  api.get("logs/overdue-key-report/delete-overdue-key-report/" + id);
const AddOverdueKeyReport = (data) =>
  api.post("logs/overdue-key-report/add-overdue-key-report", data);
const ViewOverdueKeyReport = (id) =>
  api.get("logs/overdue-key-report/view-overdue-key-report/" + id);
const EditOverdueKeyReport = (data) =>
  api.post("logs/overdue-key-report/edit-overdue-key-report", data);
const LostBadgeAppendDetail = (data) =>
  api.post("logs/overdue-key-report/lost_badge_append_detail", data);
const OverdueReportPdf = (data = null) =>
  api.post("logs/overdue-key-report/overdue-Report-pdf");
// End Logs module:- overdue key report

// Start Logs module:- shift report
const ShiftReportLists = (page, data = null) =>
  api.post("logs/shiftReport/shift-report-list?page=" + page, data);

const DeleteShiftReport = (id) =>
  api.get("logs/shiftReport/delete-shift-report/" + id);
const ViewShiftReport = (id) =>
  api.get("/logs/shiftReport/view-shift-report/" + id);
const AddShiftReports = (data = null) =>
  api.post("logs/shiftReport/add-shift-report", data);
const EditShiftReports = (data) =>
  api.post("logs/shiftReport/edit-shift-report", data);

const ShiftReportDetails = (data = null) =>
  api.post("logs/shiftReport/shift-report-detail", data);
const FetchTours = (data = null) =>
  api.post("logs/shiftReport/fetch-tour ", data);
const FetchTasks = (data = null) =>
  api.post("logs/shiftReport/fetch-task", data);
const FetchAttendance = (data = null) =>
  api.post("logs/shiftReport/fetch-attendance", data);
const FetchVisitors = (data = null) =>
  api.post("logs/shiftReport/fetch-visitor", data);
const FetchIncidents = (data = null) =>
  api.post("logs/shiftReport/fetch-incident", data);
const FetchPoiEncounters = (data = null) =>
  api.post("logs/shiftReport/fetch-poi-encounter", data);
const FetchPassdown = (data = null) =>
  api.post("logs/shiftReport/fetch-passdown", data);
const FetchButtonScanned = (data = null) =>
  api.post("logs/shiftReport/fetch-button-scanned", data);
const GetModuleArray = () => api.get("logs/shiftReport/get-module");
const ShiftReportData = () => api.get("/logs/shiftReport/shift-report-data");
const SaveShiftQuestion = (data) =>
  api.post("/logs/shiftReport/save-shift-question", data);
const AddElevator = (data) => api.post("/logs/shiftReport/add-elevator", data);
const GetElevator = () => api.get("/logs/shiftReport/get-elevator");
const ShiftReportPdf = (data) =>
  api.post("/logs/shiftReport/shift-report-pdf", data);
const DownloadShiftPdf = (id) =>
  api.post("/logs/shiftReport/download-shift-pdf/" + id);
const DeleteShiftQuestion = (data) =>
  api.post("/logs/shiftReport/delete-shift-question", data);
const SendShiftReport = (id, data) =>
  api.post("/logs/shiftReport/send-sr-pdf/" + id, data);
// End Logs module:- shift report

//Start Logs Module:- Locker log->Lockers
const LockerList = (page, data = null) =>
  api.post("logs/locker-log/locker-list?page=" + page, data);
const GetLockerLogHistory = (page, data = null) =>
  api.post("logs/locker-log/get-locker-log-history?page=" + page, data);
const DeleteLocker = (id) => api.post("logs/locker-log/delete-locker/" + id);
const LockerRoomlist = () => api.post("logs/locker-log/room-list");
const FindLockerLock = (id) =>
  api.get("logs/locker-log/find-locker-lock/" + id);
const LockerLogList = (page, data = null) =>
  api.post("logs/locker-log/locker-log-list?page=" + page, data);
const DeletelockerLog = (id) =>
  api.post("logs/locker-log/delete-locker-log/" + id);
const SaveLockerLog = (data) =>
  api.post("logs/locker-log/save-locker-log", data);
const FindLocker = (data) => api.post("logs/locker-log/find-locker", data);
const AddLockerLog = (data) => api.post("logs/locker-log/add-locker", data);
const AddEditCombo = (data) => api.post("logs/locker-log/add-edit-combo", data);
const CheckPin = (data) => api.post("logs/locker-log/check-pin", data);
const AddRoom = (data) => api.post("logs/locker-log/add-room", data);
const CheckPinLock = (data) => api.post("logs/locker-log/check-pin-lock", data);
const EditLockCombo = (data) => api.post("logs/locker-log/edit-lock", data);
const SendCombo = (data) => api.post("logs/locker-log/send-combo", data);
const ViewLockerDetail = (id) =>
  api.get("logs/locker-log/view-locker-detail/" + id);
//End Logs Module:- Locker log->Lockers

//Start Logs Module:- Locker log->Locks
const LockList = (page, data = null) =>
  api.post("logs/locker-log/lock-list?page=" + page, data);
const AddLock = (data) => api.post("logs/locker-log/add-lock", data);
const DeleteLock = (id) => api.post("logs/locker-log/delete-lock/" + id);
const AssignLock = (data = null) =>
  api.post("logs/locker-log/save-locker-lock", data);
const RemoveAssigmentLock = (id) =>
  api.post("logs/locker-log/delete-locker-lock/" + id);
const ViewAssignmentLock = (id) =>
  api.get("logs/locker-log/find-locker-lock/" + id);
const ViewAssignment = (id) => api.get("logs/locker-log/view-locker-log/" + id);
const EditAssignment = (data = null) =>
  api.post("logs/locker-log/edit-locker-log", data);
const LockerInspectionPdf = (data = null) =>
  api.post("logs/locker-log/locker-inspection", data);
//Start Logs Module:- Locker log->Locks

//Start Logs Module:-Alarm Activation Log
const AlarmActivationLogList = (page, data = null) =>
  api.post(
    "/logs/alarm-activation-log/emergency-call-log-list?page=" + page,
    data
  );
const AddActivationLog = (data) =>
  api.post("/logs/alarm-activation-log/add-emergency-call-log", data);
const DeleteAlarmActivationLog = (id) =>
  api.post("/logs/alarm-activation-log/delete-emergency-call-log/" + id);
const ViewAlarmActivationLog = (id) =>
  api.get("/logs/alarm-activation-log/view-emergency-call-log/" + id);
const UpdateAlarmActivationLog = (data) =>
  api.post("/logs/alarm-activation-log/edit-emergency-call-log", data);
const PrintEmergencyReport = (data = null) =>
  api.post("logs/alarm-activation-log/print-emergency-report", data);
//End Logs Module:-Alarm Activation Log

//Start Logs Module:-Attendance Log
const AttendanceLogList = (page, data = null) =>
  api.post("/logs/attendance-log/attendance-log-list?page=" + page, data);
const DeleteAttendanceLog = (id) =>
  api.post("/logs/attendance-log/delete-log-type-call-off/" + id);
const GetEmployeeById = (data = null) =>
  api.post("/logs/attendance-log/get-employee-id", data);
const AddAttendance = (data = null) =>
  api.post("/logs/attendance-log/save-log-type-call-of", data);
const AddAttendanceNotify = (data = null) =>
  api.post("/logs/attendance-log/save-log-type-call-off-notify", data);
const ViewAttendanceLog = (id) =>
  api.get("/logs/attendance-log/view-log-type-call-off/" + id);
const UpdateAttendance = (data = null) =>
  api.post("/logs/attendance-log/edit-log-type-call-off", data);
const AttendanceLogUserList = (id) => api.get("/logs/attendance-log/user-list");
const AttendanceLogPdf = (data = null) =>
  api.post("logs/attendance-log/call-off-pdf", data);
const GetDepartmentManager = (data = null) =>
  api.post("logs/attendance-log/get-department-manager", data);
//End Logs Module:-Attendance Log
//Start Logs Module:- Covid-19 Survey
const CovidSurveyListing = (page, data = null) =>
  api.post("logs/covid_survey/covid-survey-list?page=" + page, data);
const ViewCovidSurveyInfo = (id) =>
  api.get("logs/covid_survey/view-covid-survey/" + id);
const ScanRespondentCode = (data) =>
  api.post("/logs/covid_survey/result-barcode", data);
const SaveCovidTemp = (data) =>
  api.post("/logs/covid_survey/save-covid-temp", data);
const UnmaskPassword = (data) =>
  api.post("/logs/covid_survey/validate-unmasked-password", data);
const Covid19ScreeningSurvey = (data) =>
  api.post("/covid19-screening-survey", data);

const Covid19SurveyLocation = (locationName) =>
  api.get("/covid19-location/" + locationName);
//End Logs Module:- Covid-19 Survey

//Start Logs Module:- Key Requisition
const KeyRequisitionListing = (page, data = null) =>
  api.get("/logs/key_requisition/key-requisition-list?page=" + page, data);
const DeleteKeyRequisition = (id) =>
  api.post("/logs/key_requisition/delete_key_requisition/" + id);
const AddKeyRequisitionRecord = (data) =>
  api.post("/logs/key_requisition/add_key_requisition", data);
const EditKeyRequisitionRecord = (data) =>
  api.post("/logs/key_requisition/edit_key_requisition", data);
const SaveKeyRequest = (data) =>
  api.post("/logs/key_requisition/save_key_request", data);
const SaveKeyDetails = (data) =>
  api.post("/logs/key_requisition/save-key-detail", data);
const KeyGroupListDrop = () => api.get("/logs/key_requisition/key-group-list");
const InvolvedKeyListing = (id) =>
  api.get("/logs/key_requisition/key-detail-list/" + id);
const GetInvolvedKeyData = (id) =>
  api.get("/logs/key_requisition/view-key-detail/" + id);
const DeleteInvolvedKey = (id) =>
  api.post("/logs/key_requisition/delete_key_detail/" + id);
const SaveAuthPersonDetails = (data) =>
  api.post("/logs/key_requisition/save-authorization-detail", data);
const InvolvedAuthPerListing = (id) =>
  api.get("/logs/key_requisition/authorization-detail-list/" + id);
const GetInvolvedAuthPerData = (id) =>
  api.get("/logs/key_requisition/view-authorization-detail/" + id);
const DeleteInvolvedAuthPer = (id) =>
  api.post("/logs/key_requisition/delete_authorization_detail/" + id);
const OverrideAuthorization = (data) =>
  api.post("/logs/key_requisition/override_authorization", data);
const ViewKeyRequisitionInfo = (id) =>
  api.get("/logs/key_requisition/view_key_requisition/" + id);
//End Logs Module:- Key Requisition
//Start Logs Module - AED Mngmt
const AedInventoryListing = (page, data = null) =>
  api.get("/logs/aed-inspection/aed-list?page=" + page, data);
const AddAedInventoryRecord = (data) =>
  api.post("/logs/aed-inspection/save-aed", data);
const DeleteAedInventory = (id) =>
  api.get("/logs/aed-inspection/delete-aed/" + id);
const DropMakeList = () => api.get("/logs/aed-inspection/get-make-list");
const DropModelList = (data) =>
  api.get("/logs/aed-inspection/get-model-list", data);
const GetModelImageList = (data) =>
  api.get("/logs/aed-inspection/get-model-image-list", data);
const ViewAedsInventoryInfo = (id) =>
  api.get("/logs/aed-inspection/view-aed/" + id);
const AedBulkEditDate = (data) =>
  api.post("/logs/aed-inspection/update_aed_date", data);
const DropDeviceLocation = () =>
  api.get("/logs/aed-inspection/get-location-list");
const DropDeviceDetail = (data) =>
  api.post("/logs/aed-inspection/made-list", data);
const DropMakeListInspection = (data) =>
  api.post("/logs/aed-inspection/make-list", data);
const DropModelListInspection = (data) =>
  api.post("/logs/aed-inspection/device-model-list", data);
const DropMadeListInspection = (data) =>
  api.post("/logs/aed-inspection/made-list", data);
const AedInspectionsListing = (page, data = null) =>
  api.post("/logs/aed-inspection/aed-inspection-list?page=" + page, data);
const AddAedInspectionRecord = (data) =>
  api.post("/logs/aed-inspection/add-aed-inspection", data);
const DeleteAedInspection = (id) =>
  api.post("/logs/aed-inspection/delete-aed-inspection/" + id);
const ViewAedInspectionInfo = (id) =>
  api.get("/logs/aed-inspection/view-aed-inspection/" + id);
const AddTrainedresponder = (data) =>
  api.post("/logs/aed-inspection/add-aed-trained", data);
const AedTrainedResponderListing = (page, data = null) =>
  api.post("/logs/aed-inspection/aed-trained-list?page=" + page, data);
const DeleteTrainedResponder = (id) =>
  api.post("/logs/aed-inspection/delete-aed-trained/" + id);
const ViewTrainedResponderInfo = (id) =>
  api.get("/logs/aed-inspection/view-aed-trained/" + id);

const AedDashBoard = (data) =>
  api.get("logs/aed-inspection/aed_dashboard" + data);
const ViewAedMedicalOfficier = () =>
  api.get("logs/aed-inspection/view-aed-medical-officar");
const AddEditAedMedicalOfficier = (data = null) =>
  api.post("logs/aed-inspection/aed-medical-officar", data);
const ViewAedPlanOfficier = () =>
  api.get("logs/aed-inspection/view-aed-plan-detail");
const AddEditAedPlanOfficier = (data = null) =>
  api.post("logs/aed-inspection/aed-plan-detail", data);
const DownloadWrittenPlan = () =>
  api.get("/logs/aed-inspection/download-written-plan");
const DownloadMedicalDirector = () =>
  api.get("/logs/aed-inspection/download-medical-director");
const AedCsv = () => api.get("/logs/aed-inspection/export-aed-csv");
const UpdateAedTriningDate = (data = null) =>
  api.post("logs/aed-inspection/update_training_date", data);

//End Logs Module - AED Mngmt

//Start Logs Module:- emergency procedures
const SaveEmergencyProcedures = (data) =>
  api.post("/logs/emergency_procedures/emergency-procedures-texteditor", data);
const EmergencyProcedures = (data) =>
  api.post("/logs/emergency_procedures/emergency-procedures", data);
const GetEmergencyTypes = () =>
  api.post("/logs/emergency_procedures/get-emergency-types");
const AddEmergencyType = (data) =>
  api.post("/logs/emergency_procedures/add-emergency-type", data);
const EditEmergencyType = (data) =>
  api.post("logs/emergency_procedures/edit-emergency-type", data);
const DeleteEmergencyType = (data) =>
  api.post("logs/emergency_procedures/delete-emergency-type", data);
//End Logs Module:- emergency procedures

//Start Visitor
const VisitorTypeList = (page, data = null) =>
  api.post("visitor/list-visitor-types?page=" + page, data);
const VisitorTypeListDropdown = () => api.get("visitor/visitor-type-list");
const VisitorCompanyListDropdown = () =>
  api.get("visitor/visitor-Company-list");
const CheckInVisitor = (id) => api.post("visitor/change-status-check-in", id);
const CheckOutVisitor = (id) => api.post("visitor/change-status-check-out", id);
const UpdateGroupVisitoreList = (data) =>
  api.post("visitor/update-group-visitor-list", data);
const SaveVisitorDetails = (data) =>
  api.post("visitor/save-visitor-detail", data);
const KioskModeData = () => api.post("/visitor/kiosk-mode");
const ViewKioskSettingDetails = () => api.post("/visitor/view-kiosk-setting");
const SendPhoneOtp = (data) => api.post("/send-otp", data);
const VerifyPhoneOtp = (data) => api.post("/verify-otp", data);
const SaveKioskSetting = (data) => api.post("visitor/kiosk-setting", data);
const ValidateKioskModePassword = (data) =>
  api.post("visitor/validate-password", data);
const SavePackageDetails = (data) =>
  api.post("visitor/save-package-detail", data);
const SearchVisitor = (data) => api.post("visitor/search-visitor", data);
const CheckOutVisitorFromKioskMode = (id, data) =>
  api.post("visitor/check-out-visitor/" + id, data);
const CheckInVisitorFromKioskMode = (id, data) =>
  api.post("visitor/confirm-visitor/" + id, data);
const AddVisitorNewCompany = (data = null) =>
  api.post("visitor/add-visitor-new-company", data);
const VisitorDepartmentList = () => api.get("visitor/visitor-department-list");
const AddVisitorData = (data = null) => api.post("visitor/add-visitor", data);
const AddVisitorType = (data = null) =>
  api.post("/visitor/add-visitor-types", data);
const ActiveInactiveVisitor = (id, data = null) =>
  api.post("visitor/active-inactive-visitor-types/" + id, data);
const TriggerEmailVisit = (data = null) =>
  api.post("visitor/trigger-email-visits", data);
const ViewVisitor = (id) => api.post("visitor/visitor-id-exists", id);
const EditVisitors = (id) => api.get("visitor/view-visitor/" + id);
const InlineUpdateVisitor = (data = null) =>
  api.post("visitor/inline-update-visitor", data);
const ScanBarcode = (data = null) => api.post("visitor/scan-barcode", data);
const UpdateVisitor = (data = null) => api.post("visitor/edit-visitor", data);
const VisitorCompanyList = (page, data = null) =>
  api.post("visitor/get-visitor-company-list?page=" + page, data);
const ActiveVisitorCompany = (data = null) =>
  api.post("visitor/active-inactive-company", data);
const AddUpdateVisitorCompany = (data = null) =>
  api.post("visitor/add-update-visitor-company", data);
const ViewVisitorCompany = (id) =>
  api.get("visitor/view-visitor-company/" + id);
const DeleteVisitorCompany = (id) =>
  api.get("visitor/delete-visitor-company/" + id);

const GetVisitorWatchList = (page, data = null) =>
  api.post("visitor/get-visitor-watch-list?page=" + page, data);
const AddUpdateVisitorWatch = (data = null) =>
  api.post("visitor/add-update-visitor-watch", data);
const DeleteVisitorWatch = (id) =>
  api.get("visitor/delete-visitor-watch/" + id);
const ViewVisitorWatch = (id) => api.get("visitor/view-visitor-watch/" + id);
const UpdateVisitorWatchListStatus = (data = null) =>
  api.post("visitor/update-visitor-watch-list-status", data);
const PrintVehiclePass = (data = null) =>
  api.post("visitor/print-vehicle-pass", data);
const PrintVisitorBadge = (data = null) =>
  api.post("visitor/print-visitor-badge", data);

const VisitorEquipment = (data = null) => api.post("position-id-sl", data);
const CheckVisitorUser = (data = null) => api.post("visitor/user-check", data);
const EquipmentPermission = (data = null) =>
  api.post("visitor/equipment-type-tree-sl", data);
const AutoCompleteHostName = (data, config) =>
  api.post("visitor/auto-complete-host-name", data, config);
//End Visitor

//Start Property Inspection -> Inspection Area
const GetInspectionAreaList = (page, data = null) =>
  api.post(
    "/property-inspection/inspection-area/get-inspection-area-list?page=" +
      page,
    data
  );
const AddUpdatePropertyInspectionArea = (data = null) =>
  api.post(
    "property-inspection/inspection-area/add-update-property-inspection-area",
    data
  );
const DeleteInspectionArea = (id) =>
  api.post(
    "property-inspection/inspection-area/delete-propery-inspection-area/" + id
  );
const PropertyAreaDropdown = () =>
  api.get("property-inspection/property-area/property-area-dropdown");
const InspectionAreaDropdown = () =>
  api.get("property-inspection/inspection-area/inspection-area-dropdown");
const InspectionCategoryDropdown = () =>
  api.get(
    "property-inspection/inspection-category/inspection-category-dropdown"
  );

//End Property Inspection -> Inspection Area

//Start Property Inspection -> Inspection Question
const GetInspectionQuestionList = (page, data = null) =>
  api.post(
    "/property-inspection/inspection-question/get-inspection-question-list?page=" +
      page,
    data
  );
const DeleteInspectionQuestion = (id) =>
  api.post(
    "property-inspection/inspection-question/delete-inspection-question/" + id
  );
const AddUpdateInspectionQuestion = (data = null) =>
  api.post(
    "property-inspection/inspection-question/add-update-inspection-question",
    data
  );

const FetchSelectedQuestions = (data = null) =>
  api.post(
    "/property-inspection/inspection-question/fetch-selected-questions",
    data
  );

const QuestionReorder = (data = null) =>
  api.post("/property-inspection/inspection-question/question-reorder", data);
//End Property Inspection -> Inspection Question
//Start Property Inspection -> Inspection Results
const GetInspectionResultList = (page, data = null) =>
  api.post(
    "/property-inspection/inspection-result/get-inspection-result-list?page=" +
      page,
    data
  );
const FindResultsPdf = (data) =>
  api.post("/property-inspection/inspection-result/find_results_pdf", data);

const FindResultPdf = (data = null) =>
  api.post("/property-inspection/inspection-result/find_results_pdf", data);
//End Property Inspection -> Inspection Results
// Start Visitors Module
const VisitorDashboardCount = () => api.get("/visitor/dashboard");
const DeleteVisitor = (id) => api.post("/visitor/delete-visitor/" + id);
const GetVisitorList = (page, data = null) =>
  api.post("/visitor/get-visitor-list?page=" + page, data);
const VisitorStatusUpdate = (id, data) =>
  api.post("/visitor/active-inactive-visitor/" + id, data);
const CompanyList = () => api.get("/visitor/visitor-Company-list");
const VisitorsList = () => api.get("/visitor/visitor-type-list");
const ViewVisitorLogs = (id) => api.get("/visitor/view-log/" + id);
const CloneVisitor = (data) => api.post("/visitor/clone-visitor", data);
const VisitLogListing = (page, data = null) =>
  api.post("/visitor/visitor-log-list?page=" + page, data);
const GenerateEmergencyListPDF = () => api.get("/visitor/emergency-list-pdf");
// End Visitors Module

//--Start Property Inspection Section--
const AddEditPropertyArea = (data) =>
  api.post("/property-inspection/property-area/add-update-property-area", data);
const PropertyAreaList = (page, data = null) =>
  api.post(
    "/property-inspection/property-area/get-property-area-list?page=" + page,
    data
  );
const DeletePropertyArea = (id) =>
  api.post("/property-inspection/property-area/delete-propery-area/" + id);
const AddEditPropertyAreaManager = (data) =>
  api.post(
    "property-inspection/property-area-manager/add-update-property-area-magager",
    data
  );
const PropertyAreaManagerList = (page, data = null) =>
  api.post(
    "property-inspection/property-area-manager/get-property-area-manager-list?page=" +
      page,
    data
  );
const DeletePropertyAreaManager = (id) =>
  api.post(
    "property-inspection/property-area-manager/delete-propery-area-manager/" +
      id
  );
const AddEditInspectionCategory = (data) =>
  api.post(
    "property-inspection/inspection-category/add-update-inspection-category",
    data
  );
const InspectionCategoryList = (page, data = null) =>
  api.post(
    "property-inspection/inspection-category/get-inspection-category-list?page=" +
      page,
    data
  );
const DeleteInspectionCategory = (id) =>
  api.post(
    "property-inspection/inspection-category/delete-inspection-category/" + id
  );

//POI Module start
const GetPoiEncounterList = (page, data = null) =>
  api.post("poi/get-encounter-list?page=" + page, data);
const ViewPoiEncounter = (id) => api.post("poi/view-encounter/" + id);
const ClassificationList = () => api.get("poi/classification-list");
const ViewPoi = (id) => api.get("poi/view-poi-detail/" + id);
const AddEncounter = (id, data = null) =>
  api.post("poi/add-encounter/" + id, data);
const UpdateEncounter = (id, data = null) =>
  api.post("poi/edit-encounter/" + id, data);
const DeleteClassifcition = (data = null) =>
  api.post("poi/delete-classification", data);
const AddClassifcition = (data = null) =>
  api.post("poi/add-classification", data);
const EncounterRequireReview = (data = null) =>
  api.post("poi/encounter-require-review", data);
const PoiImageSearch = (data = null) => api.post("poi/image-search", data);
const MergeSelectedPoi = (data) => api.post("poi/merge-selected-poi", data);
const DeletePoi = (id) => api.post("poi/delete-poi/" + id);
const PoiPrintReport = (id) => api.get("poi/print-report/" + id);
const LocationLatLong = () => api.get("poi/location-lat-long");
const PrintEncounterReport = (data) =>
  api.post("poi/print-encounter-report", data);
const PoiEncounterMap = (data = null) =>
  api.post("poi/poi-encounter-locations", data);
const DeletePoiAttachment = (id) => api.post("poi/delete-poi-attachment/" + id);
const PoiEncList = (id) => api.get("poi/poi-encounter-list/" + id);
const PoiWatchlist = (id, data) => api.post("poi/api-watch-list/" + id, data);
//POI Module end
//--End Property Inspection Section--

//---Start POI (Person of intrest)---
const PersonOfIntrestList = (page, data = null) =>
  api.post("/poi/get-poi-list?page=" + page, data);
const SavePOI = (data) => api.post("/poi/add-poi", data);
const UpdateReporterStatus = (id, data) =>
  api.post("/s4/update-status", id, data);
const UpdateReporterUser = (id, data) =>
  api.post("/s4/send-update-to-reporting-user", id, data);
const AddNotes = (id, data) => api.post("/s4/add-notes", id, data);
const SaveKeyForm = (data) =>
  api.post("/s4/save-anonymous-intel-sharing", data);
const ReporterUserList = (data) =>
  api.post("/s4/get-anonymous-intel-sharing", data);
const TaskActivityList = (data) =>
  api.post("/lives/get-activity-list-data", data);
const TaskUserList = (data) => api.post("/lives/get-user-list-data", data);
const DynamicKey = (data) => api.post("/s4/check-s4-key", data);
const UpdatePOI = (data) => api.post("/poi/edit-poi", data);
const ShareLocationsList = () => api.get("/poi/location-list");
const UpdatePoiRequireReview = (data) =>
  api.post("poi/update-require-review", data);
const UpdateInlinePoiData = (data) =>
  api.post("poi/update-inline-poi-data", data);
const PoiEncounterList = (id) => api.get("poi/poi-encounter-list/" + id);
//---End POI (Person of intrest)---

//Packages Module Start
const PackagesList = (page, data = null) =>
  api.post("/package/list?page=" + page, data);
const AddPackage = (data = null) => api.post("/package/add-package", data);
const ViewPackage = (id) => api.get("/package/view-package/" + id);
const UpdatePackage = (data = null) => api.post("/package/edit-package", data);
const DeletePackage = (id) => api.post("/package/delete-package/" + id);
const TrackingSearch = (id) => api.get("/package/tracking-number-exist/" + id);
const ClonePackage = (data = null) =>
  api.post("/package/add-duplicate-package", data);
const StorageLocationDropDown = () => api.get("/package/parent-storage-list");
const PackageDetail = (data = null) =>
  api.post("/package/get-package-detail", data);
const BulkDelivery = (data = null) => api.post("/package/bulk-delivery", data);
const AddBulkPackage = (data = null) =>
  api.post("/package/add-bulk-package", data);
const GetPackageStorage = (data = null) =>
  api.get("/package/get-package-storage", data);
const AddStorage = (data = null) => api.post("/package/add-storage", data);
const AddParentNode = (data = null) =>
  api.post("/package/add-parent-node", data);
const DeletePackageStorage = (id) =>
  api.post("/package/delete-package-storage/" + id);
const TenantList = (page, data = null) =>
  api.post("/tenant/tenant-list?page=" + page, data);
const UpdateTenantStatus = (data = null) =>
  api.post("/tenant/update-status", data);
const AddTenant = (data = null) => api.post("/tenant/add-tenant", data);
const UpdateTenant = (data = null) => api.post("/tenant/edit-tenant", data);
const ViewTenant = (id) => api.get("/tenant/view-tenant/" + id);
const PackageAuditList = (page, data = null) =>
  api.post("/package/audit-list?page=" + page, data);
const UpdatePackageAudit = (data = null) =>
  api.post("/package/edit-audit", data);
const DeletePackageAudit = (id) => api.post("/package/delete-audit/" + id);
const PrintReceivingRecord = (data = null) =>
  api.post("package/print-receiving-record", data);
const RecipientList = () => api.get("package/recipient-list");
const TenantDropdown = () => api.get("package/tenant-dropdown-list");
const RecivedPackage = (data = null) =>
  api.post("package/recived-package", data);
const AddPackageAudit = (data = null) => api.post("package/add-audit", data);
const ViewPackagePrice = (data = null) =>
  api.get("package/view-package-price", data);
const UpdatePackageSystem = (data = null) =>
  api.post("package/update_package_system", data);
const UpdatePackagePrice = (data = null) =>
  api.post("package/update-package-price", data);
//Packages Module End
//---Start EAR (Employee Accident)---
const AddEARReport = (data) => api.post("/ear/add-ear", data);
const DeleteEmployeeAccident = (id) => api.get("/ear/delete-emp/" + id);
const EmployeeAccidentList = (page, data = null) =>
  api.post("/ear/ear-report-list?page=" + page, data);
const ViewEmployeeAccidentReport = (id) =>
  api.get("/ear/view-ear-report/" + id);
const EditEmpAccident = (data) => api.post("/ear/edit-ear-emp", data);
const AddManagerAccident = (data) => api.post("/ear/add-ear-mgr", data);
const EditManagerAccident = (data) => api.post("ear/edit-ear-mgr", data);
const PreventativeActionPlanListing = (data) =>
  api.post("/ear/preventive-list", data);
const ViewPreventativeActionPlan = (id) =>
  api.get("/ear/view-preventive/" + id);
const UpdatePreventativeActionPlan = (data) =>
  api.post("/ear/edit-preventive", data);
const InvestigationTeamList = (data) =>
  api.post("/ear/investigation-list", data);
const DeleteInvestigation = (data) =>
  api.post("/ear/delete-investigation", data);
const WitnessList = (data) => api.post("/ear/witness-list", data);
const DeleteWitness = (data) => api.post("/ear/delete-witness", data);
const SendEarEmailList = (data) => api.post("/ear/get-comm-user", data);
const SendEarReport = (data) => api.post("/ear/send-ear", data);
const SendEmailManager = (data) => api.post("/ear/send-manager-email", data);
const PrintEarPdf = (id) => api.get("ear/print-ear/" + id);
const EarDropdownPosition = () => api.get("ear/dropdown-position-list");
const EarTaskList = () => api.get("ear/get-task-list");
const GetTaskLog = (id) => api.get("ear/get-task-log/" + id);
//---End EAR (Employee Accident)---

// Start Dashboard
const GetLocations = (data = null) =>
  api.post("/dashboard/get-locations", data);
const ShowMessageBoard = () => api.get("/show-message-board");
const BoardDeleteMessage = (id) => api.post("/delete-message/" + id);
const AddGuestBook = (data) => api.post("/add-guest-book", data);
const GetUserMessage = (id) => api.post("/get-user-message/" + id);
const ToggleWidget = () => api.post("/dashboard/toggle-widget");
const GraphData = () => api.post("/dashboard/graph-data");
const MarkAsReadMessage = (id, data) =>
  api.post("/mark-as-read-message/" + id, data);
const GetHighChartData = (chartName) =>
  api.get("/dashboard/get-highchart/" + chartName);
const SaveHighChart = (data) => api.post("/dashboard/save-highchart", data);
const getCalendarJson = (data) =>
  api.post(
    `/dashboard/get-calendar-json?year=${data.year}&month=${data.month}`
  );
// End Dashboard

// Start Calender
const AddEvent = (data) => api.post("/calender/add-event", data);
const SaveManualData = (data) => api.post("/calender/save-manual-data", data);
const UpdateEvent = (data) => api.post("/calender/update-event", data);
const GetEventData = () => api.get("/calender/get-event-data");
const DeleteEvent = (id) => api.get("/calender/delete-event/" + id);
const ActiveLocationUsers = () => api.get("/calender/user-list");
const CloneDaily = (data) => api.post("/calender/clone-daily", data);
const CloneLastWeekEvents = (data) =>
  api.post("/calender/clone-last-week-events", data);

// End Calender

// Start Task Module
const GetKanbanData = (data) => api.post("/module-task/get-kanban-data", data);
const MoveKanbanItem = (data) =>
  api.post("/module-task/move-kanban-item", data);
const SaveModuleTask = (data) =>
  api.post("/module-task/save-module-task", data);
const TaskCommentList = (data) =>
  api.post("/module-task/task-comment-list", data);
const SaveModuleTaskComment = (data) =>
  api.post("/module-task/save-module-task-comment", data);
// End Task Module

//Communication Module Start
const CommunicationList = (data) =>
  api.post("/communication/communication-list", data);
const RemoveVerification = (data) =>
  api.post("/communication/remove-verification", data);
const verifyStatus = (data) => api.post("/communication/verify-status", data);
const UpdateCommRecord = (data) =>
  api.post("/communication/update-comm-record", data);
const DeleteCommnication = (id) =>
  api.post("/communication/delete-assignment/" + id);
const AddCommunication = (data) =>
  api.post("/communication/add-communication", data);
const IncidentTypeList = () => api.get("/communication/incident-type-list");
const GetAssignments = (data) =>
  api.post("/communication/get-assignments", data);
const SaveAssignment = (data) =>
  api.post("/communication/save-assignments", data);
const SendVerification = (data) =>
  api.post("/communication/send-verification", data);
const GetCommUser = (data) => api.post("/communication/get-comm-user", data);
const SendTestComm = (data) => api.post("/communication/test", data);
const GetTestCommReport = (data) =>
  api.post("/communication/delivery-reports", data);
// Setting Module
// corporate User
//Ir Number
const SaveIrNumbering = (data) =>
  api.post("/Ir_number/save-ir-numbering", data);
const GetIrNumbering = () => api.get("/Ir_number/get-ir-numbering");
// IR Classification
const ListIrType = () => api.get("/Ir_Classification/list-ir-type");
const AddIr = (data) => api.post("/Ir_Classification/add-ir", data);
const ViewIr = (id) => api.get("/Ir_Classification/view-ir/" + id);
const EditIr = (data) => api.post("/Ir_Classification/edit-ir", data);
const IrNameChange = (data) =>
  api.post("Ir_Classification/confirm-name-change", data);
const TaskLibsList = (id) => api.get("/Ir_Classification/task-libs-list/" + id);
const DeleteTaskLibs = (id) =>
  api.post("/Ir_Classification/delete-task-libs/" + id);
const TaskLibsView = (id) => api.get("/Ir_Classification/task-libs-view/" + id);
const EditTaskLibs = (data) =>
  api.post("/Ir_Classification/edit-task-libs", data);
const ToggleImageRequired = (data = null) =>
  api.post("/Ir_Classification/toggle-image-required", data);
const IrActiveToggle = (data = null) =>
  api.post("/Ir_Classification/make-active-inactive", data);
const IrNurseNotifyToggle = (data = null) =>
  api.post("/Ir_Classification/change-ohm", data);
const IrCriticalTogggle = (data = null) =>
  api.post("/Ir_Classification/change-classification", data);
const DeleteIr = (id) => api.post("/Ir_Classification/delete-ir/" + id);
const CopyIRTypes = (data = null) =>
  api.post("/Ir_Classification/copy-ir-types", data);
//Lost & Found Privacy
const ShipmentsReports = (data) =>
  api.post("/lost-and-found-privacy/shipment-reports", data);
const UpdateGdpr = (id) =>
  api.post("/lost-and-found-privacy/update-gdpr/" + id);
//Property Admin
//Customize Report
const CustomInputs = (data) =>
  api.post("/customize-report/custom-inputs", data);
const ToggleFieldsStatus = (data) =>
  api.post("/customize-report/toggle-fields-status", data);
const IpRange = () => api.get("/ip-range/ip-range");
const AddRange = (data) => api.post("/ip-range/add-range", data);
const DeleteRange = (id) => api.post("/ip-range/delete-range/" + id);

//Carrier Settings
const ShippoList = () => api.post("/carrier-settings/shippo-list");
const EditCarrierStatus = (data) =>
  api.post("/carrier-settings/edit-flag-status", data);
const SetupCSL = (data) => api.post("/carrier-settings/setup-csl", data);
const CarrierServiceLevels = (data) =>
  api.post("/carrier-settings/carrier-service-level", data);
const UpdateCarrierServiceStatus = (data) =>
  api.post("/carrier-settings/update-carrier-service-status", data);

//lost and found rules
const ListRules = () => api.get("/lost-and-found-rule/list-rules");
const ListItem = () => api.get("/lost-and-found-rule/item-list");
const SaveRules = (data) => api.post("/lost-and-found-rule/save-rules", data);
const ViewRules = (id) => api.get("/lost-and-found-rule/view-rules/" + id);
const DeleteRules = (id) => api.get("/lost-and-found-rule/delete-rules/" + id);
// vanues
const VenueList = (data) => api.post("/venues/venue-list", data);
const ChangeVenueStatus = (data) =>
  api.post("/venues/change-venue-status", data);
const AddVenue = (data) => api.post("/venues/add-venue", data);
const ViewVenue = (id) => api.get("/venues/view-venue/" + id);
const DeleteVenue = (id) => api.get("/venues/delete-venue/" + id);

//chatBot question
const QuestionList = () => api.get("/question/question-list");
const SaveQuestion = (data) => api.post("/question/save-question", data);
const FindModule = () => api.get("/question/find-module");
const DeleteQuestion = (id) => api.get("/question/delete-question/" + id);
//List Device
const DeviceList = (data) => api.post("/devices/device-list", data);
const EditDevice = (data) => api.post("/devices/edit-device", data);
const DuplicatesDeviceList = (data) =>
  api.post("/devices/duplicates-device-list", data);
const ChangeHideStatus = (data) =>
  api.post("/devices/change-hide-status", data);
const SendFcmMessage = (data) => api.post("/devices/change-hide-status", data);
const BulkUpdate = (data) => api.post("/devices/bulk-update", data);
//login audit
const AuditUserList = (data) => api.post("/login-audit/audit-user-list", data);
const ContinentList = () => api.get("/login-audit/continent-list");
const CountryDropDownList = (data) =>
  api.post("/login-audit/country-list", data);
const StateList = (data) => api.post("/login-audit/state-list", data);
const EditAuditUser = (data) => api.post("/login-audit/edit-audit-user", data);
const AuditUserPdf = (data) => api.post("/login-audit/audit-user-pdf", data);
const SaveAuditUser = (data) => api.post("/login-audit/save-audit-user", data);
const LogoutAllSessions = (id) =>
  api.post("/login-audit/logout-all-sessions/" + id);
//Trigger Word
const ListTriggerWord = () => api.get("/Trigger-word/list-trigger-word");
const AddTriggerWord = (data) =>
  api.post("/Trigger-word/add-trigger-word", data);
const DeleteTriggerWord = (id) =>
  api.post("/Trigger-word/delete-trigger-word/" + id);
//E-Notify Templates
const NotifyTemplateList = () =>
  api.get("/e-notify-templates/notify-templates-list");
const UserMailList = () => api.get("/e-notify-templates/user-email-list");
const AddUpdateNotifyTemplates = (data) =>
  api.post("/e-notify-templates/add-notify-templates", data);
const ViewNotifyTemplates = (id) =>
  api.get("/e-notify-templates/view-notify-templates/" + id);
const DeleteNotifyTemplates = (id) =>
  api.post("/e-notify-templates/delete-notify-templates/" + id);
//Security Events
const SecurityEventsList = (page, data) =>
  api.post("/security-events/security-events-list?page=" + page, data);
const UnblockUser = (data) => api.post("/security-events/unblock-user", data);
const LocateCityFromIp = (data) =>
  api.post("/security-events/locate-city-from-ip", data);
//User Search
const LocationList = (data) => api.post("/user-search/location-list", data);
const UserSearch = (data) => api.post("/user-search/user-search", data);
const SaveInlineData = (id, data) =>
  api.post("/user-search/save-inline-data/" + id, data);
const ChangeUserLocation = (data) =>
  api.post("/user-search/change-user-location", data);
//modify nav menu
const ModifyNavMenu = () => api.get("/menu/modify-nav-menu");
const UpdateMenuOrder = (data) => api.post("/menu/update-menu-order", data);
const ToggleMenuStatus = (data) => api.post("/menu/toggle-menu-status", data);
//youTube
const VideoList = () => api.get("/youtube-video/video-list");
const AddVideo = (data) => api.post("/youtube-video/add-video", data);
const UserEmailVerification = (data) =>
  api.get("/user-email-verification", data);
const UserPhoneVerification = (data) =>
  api.get("/user-phone-verification", data);
const getControllerList = (data) => api.get("/get-controller-list", data);
const userGroupList = (data) => api.get("/user-group-list", data);
const getControllerActionList = (data) =>
  api.post("/get-controller-action-list", data);
const updateGroupPermission = (data) =>
  api.post("/update-group-permission", data);
// Notify
const AddNotification = (data) => api.post("/user/notification", data);
const ViewNotification = () => api.post("/user/view-notification");
const EditNotification = (id, data) =>
  api.post("/user/edit-notification/" + id, data);
const DeleteNotification = (data) =>
  api.post("/user/delete-notification", data);
const GetNotificationView = (id) => api.get("/user/view-notification/" + id);

//switch dummy
const UpdateSwitchDummyData = (id, data) =>
  api.post("/devices/change-setting/switch-dummy-data/" + id, data);
const ViewSwitchDummyData = () =>
  api.get("/devices/change-setting/view-switch-dummy-data/");
//emergency notification
const SendEmergencyAlert = (data) =>
  api.post("/lives/send-emergency-alert", data);
const GetSelectedTemplate = (data) =>
  api.post("/lives/get-selected-template", data);
const HeaderSearch = (data) => api.post("/search", data);
export {
  Login,
  ForgetPassword,
  ResetPassword,
  //user api start
  GetUserList,
  UpdateColor,
  MakeActiveInactive,
  UpdateStatus,
  UpdateLoginAuth,
  DeleteUser,
  GetUserView,
  UserCorporate,
  UserGroupList,
  UserProperty,
  AddUser,
  UpdateUser,
  UpdateUserPassword,
  UserColumnList,
  ModuleList,
  MyProfile,
  EditProfile,
  UnMasked,
  //user api end
  //Tour API Start
  GetTourList,
  UpdateTourStatus,
  ViewTour,
  UpdateTour,
  GetTourBuilding,
  Copytour,
  GetTourCheckpointList,
  UpdateButtonCritical,
  UpdateCheckpointStatus,
  GetTourButton,
  GetAllTask,
  UpdateTourCheckpoint,
  GetTourBuildingList,
  DeleteTourBuilding,
  AddTourBuildings,
  UpdateTourBuilding,
  AddTours,
  ExportCheckpoints,
  UpdateCheckpointSchedule,
  UpdateTourSchedule,
  UpdateCustomButtonText,
  RemoveTourTime,
  RemoveButtonTime,
  LoadChildList,
  BuildVisualTour,
  UpdateLatLong,
  SaveButtonInlineData,
  GetTourShiftTime,
  UpdateTourShiftTime,
  ReorderButton,
  //Tour API End
  // Task API Start
  GetTaskList,
  GetNonTourDataList,
  TaskActive,
  UpdateTaskStatus,
  GetTaskAssignment,
  DeleteTaskAssignment,
  ViewTask,
  ViewEditSchedule,
  GetScheduleTask,
  ViewTaskButton,
  ViewScheduleTask,
  UpdateTask,
  DelelteTaskButton,
  AddScheduleTask,
  TaskColumnList,
  AddTasks,
  UpdateScheduleTask,
  UpdateTaskButton,
  UpdateScheduleTime,
  CopyTask,
  VisitorMobile,
  GenerateQr,
  Disable2FA,
  VerifyCode,
  LoginVerify,
  CheckRecoveryCode,
  ExpireRecoveryCode,
  Reset2FA,
  SaveRecoveryCode,
  // Task API End
  AddLost,
  CategoryList,
  EstimatedList,
  LostAndfFound,
  StorageLocationList,
  AddStorageLocation,
  AddFound,
  DepartmentList,
  TourPatrolList,
  LostFoundActivityLogs,
  RemoveActivityLogs,
  SingleLostfoundActivityLog,
  LostfoundSubItems,
  CloneLostFound,
  DeleteTour,
  UserAutocompleteList,
  TourAutocompleteList,
  TourMissedButtonList,
  GetAllTour,
  GetTaskButtonList,
  GetTaskButton,
  GetCheckPointList,
  ViewTourCheckpoint,
  TourButtonMissedUser,
  LostfoundUnmerge,
  TourUserListMissedButton,
  TourUserListMissedButtonU,
  TourResultTooltipList,
  ViewTourReport,
  GetEquipmentList,
  UpdateOneColumn,
  UpdateDispose,
  SingleRecord,
  CountryList,
  EditLostFound,
  NonTourTaskList,
  EquipmentActiveInactive,
  DeleteEquipment,
  GetEquipmentUserList,
  AddEquipUser,
  EquipmentCheckin,
  BulkCheckIn,
  EquipmentTypeTree,
  KeyGroupTree,
  NonTourUserList,
  NonTourUserAverageTime,
  EquipmentLog,
  DailyActivityReport,
  UpdateRequireReview,
  DailyActivityData,
  DailyActivityRecentData,
  RecentUserActivity,
  EquipmentUserActive,
  DeleteEquipmentUser,
  GetAllDepartment,
  PositionByDepartment,
  SaveLockedStatus,
  CreateMergedGroup,
  MergedGroup,
  GetDetails,
  GetBagTag,
  DailyActivityChangeLog,
  GetSecurityLevel,
  TourPatrolColumns,
  NonTourTaskColumns,
  DailyActivityColumns,
  ViewEquipmentUser,
  UpdateEquipmentUser,
  DARInquiryDetails,
  SendDAREmailList,
  DARInquiryEmail,
  DARMultiEmail,
  GetEquipmentListLog,
  GetEquipmentUserLog,
  GetListByIds,
  DAREditComment,
  TourMapLatLong,
  SendShipmentRequest,
  GetPositionList,
  DeletePosition,
  GetPositionSL,
  AddPosition,
  ViewPosition,
  UpdatePosition,
  GetDepartmentList,
  DeleteDepartment,
  AddEquipDepartment,
  DeleteKeyGroup,
  UpdateEquipmentGroup,
  GetEditEquipUser,
  ViewProcessEquipment,
  CheckEquipments,
  SearchEquipmentUser,
  DeleteAssignedEquipemnt,
  GetRevisions,
  ActivityAlertList,
  AddActivityAlert,
  DeleteActivityAlert,
  BarChartData,
  CommunicationLog,
  SaveCommunicationLog,
  UserPerformanceList,
  UpdateDepartment,
  GetEquipmentDetail,
  ViewEquipDetial,
  EquipmentListPdf,
  EquipmentUserPdf,
  ImportEquipmentUser,
  AddUpdateEquipmentDetail,
  TransactionsLabel,
  TransactionPdf,
  UserActivityLog,
  UserActivityMissedButton,
  UserActivityShiftTour,
  UserActivityShiftReport,
  UserPerformanceColumns,
  PassdownItemList,
  PassdownItemColumns,
  ButtonHistoryList,
  GetButtonName,
  RecentScansData,
  ButtonHistoryColumns,
  TourSummaryList,
  GetAddEquipmentType,
  AddEquipType,
  MatchLostFoundList,
  DeleteEquipmentType,
  UpdateEquipmentType,
  GetAddKeyRingList,
  DeletekeyRing,
  AddNewKeyRing,
  UpdateKeyRing,
  GetAddKeyCabinetList,
  DeleteKeyCabinet,
  AddNewKeyCabinet,
  UpdateKeyCabinet,
  GetAddKeyDoorList,
  DeleteKeyDoor,
  AddNewKeyDoor,
  UpdateKeyDoor,
  GetAddKeyLockNumberList,
  DeleteKeyLockNumber,
  AddNewKeyLockNumber,
  UpdateKeyLockNumber,
  GetAddKeyBuildingList,
  DeleteKeyBuilding,
  AddNewKeyBuilding,
  UpdateKeyBuilding,
  GetAddKeyTypeList,
  DeleteKeyType,
  AddNewKeyType,
  UpdateKeyType,
  ViewEquipmentType,
  ViewEquipmentKeyGroup,
  ViewEquipmentUsers,
  UpdatePositionUsers,
  UpdateUserImage,
  AddEquip,
  ViewEquipment,
  UpdateEquipment,
  ViewEquipDepartmentList,
  GetAddKeyGroupList,
  AddEquipmentGroup,
  ViewAssignEquipment,
  UpdateEquipUser,
  NoMatch,
  ImageSearch,
  MergeDuplicates,
  TourSummaryColumns,
  ActiveCarrier,
  ShipmentList,
  MissedTourButtonList,
  MissedTourButtonColumns,
  SmsReportList,
  SmsCreditPop,
  SaveSMSCreditPayment,
  GetHeaderLocation,
  GetMessageSubcriptionDetail,
  SmsReports,
  DeviceReportList,
  DeviceReportColumns,
  IrLogList,
  IncidentsReportList,
  IncidentUserList,
  IncidentIccStatus,
  IncidentIccStatusList,
  IncidentTypeListDropdown,
  SaveIncidentReport,
  SearchInvolvedPerson,
  SavePersonInvolved,
  InvolvedPersonListing,
  NorEmailListing,
  SendNorMail,
  SendNorPdf,
  AddIncident,
  DeleteInvolvedPerson,
  UploadMediaFile,
  YearList,
  MakeList,
  ModelList,
  SearchPlateNumber,
  DriversList,
  SaveInvolvedVehicle,
  InvolvedVehicleListing,
  GetInvolvedVehicleData,
  DeleteInvolvedVehicle,
  SaveInvolvedWitness,
  InvolvedWitnessListing,
  GetInvolvedWitnessData,
  DeleteInvolvedWitness,
  SaveReference,
  ReferenceListing,
  DeleteReference,
  ViewReference,
  GetIncidentFiles,
  SaveFinancialInfo,
  GetIncidentReportData,
  FinancialListing,
  GetInvolvedFinanceData,
  DeleteInvolvedFinance,
  DeleteInvolvedFinanceImage,
  getLocationLatLong,
  GetDefaultNarrative,
  GetInterviewQuestionList,
  ViewInterviewAns,
  SaveUseOfForce,
  UpdateForce,
  GetInvolvedPersonData,
  GetPropertyList,
  DeleteProperty,
  PropertyListColumns,
  PropertyStatus,
  AddProperties,
  CorporateList,
  TimeZoneList,
  Modulelist,
  CorporateListing,
  CorporateColumns,
  DeleteCorporate,
  ViewCorporate,
  AddCorporateUser,
  UpdateCorporate,
  GetFloorList,
  DeleteFloor,
  AddFloorList,
  ViewFloor,
  UpdateFloor,
  GetFloorColumnList,
  UpdateProperty,
  ToggleButtonStatus,
  UserPropertyList,
  ModuleListData,
  ModulePermission,
  GetEditPropertyInfo,
  ShipmentsForAdmin,
  DownloadShipmentsCsv,
  DetailsForShipment,
  GetShipments,
  SmsReportColumns,
  SaveLostfoundPayment,
  DeleteIncident,
  ReportUpdateStatus,
  DownloadReportIncident,
  saveIncidentInvestigation,
  GetLfByControlId,
  AddItemCategoryList,
  UpdateReporterStatus,
  UpdateReporterUser,
  AddNotes,
  // Start Logs module:- Employee Management
  ListEmployee,
  SaveEmployee,
  ViewEmployee,
  UpdateEmployee,
  DeleteEmployee,
  ReissueList,
  AddReissueId,
  // End Logs module:- Employee Management
  AppendIrDeatails,
  GetStatusAudit,
  UpdateStatusReason,
  SendIncidnetOfficier,
  SendEmailToReportingOfficier,
  IrTaskList,
  LiveDispatchList,
  RewriteAi,
  VehicleInventoryList,
  VehicleInspectionList,
  GetMediaList,
  DeleteInventory,
  ChangeTaskStatus,
  ChangeCompleteStatus,
  CompletedTask,
  DeleteInspection,
  AddVehicleInventory,
  AddVehiclesInspection,
  AddNewTask,
  UpdateMediaFile,
  DeleteMediaFile,
  // Start Logs module:- Vehicle Management
  AddVehicle,
  VehicleManagementList,
  ViewVehicle,
  EditVehicle,
  DeleteVehicle,
  VehicleViolationList,
  AddVehicleViolation,
  ViewVehicleViolation,
  EditVehicleViolation,
  DeleteVehicleViolation,
  GenerateVechicleId,
  VehicleIdExist,
  VehicleByYear,
  // End Logs module:- Vehicle Management
  // Start Logs module:- Special visitors
  SpecialVisitorList,
  DeleteSpecialVisitor,
  SaveSpecialVisitor,
  ViewSpecialVisitor,
  EditSpecialVisitor,
  PrintSpecialVisitor,
  // End Logs module:- Special visitors

  // Start Logs module:- overdue key report
  OverdueKeyReportList,
  DeleteOverdueKeyReport,
  AddOverdueKeyReport,
  ViewOverdueKeyReport,
  EditOverdueKeyReport,
  LostBadgeAppendDetail,
  OverdueReportPdf,
  // End Logs module:- overdue key report

  // Start Logs module:- shift report
  ShiftReportLists,
  DeleteShiftReport,
  ViewShiftReport,
  AddShiftReports,
  EditShiftReports,
  ShiftReportDetails,
  FetchTours,
  FetchTasks,
  FetchAttendance,
  FetchVisitors,
  FetchIncidents,
  FetchPoiEncounters,
  FetchPassdown,
  FetchButtonScanned,
  GetModuleArray,
  ShiftReportData,
  SaveShiftQuestion,
  AddElevator,
  GetElevator,
  ShiftReportPdf,
  DownloadShiftPdf,
  DeleteShiftQuestion,
  SendShiftReport,
  // End Logs module:- shift report

  //Start Logs Module:- Lokcer Log -> Lockers
  LockerList,
  GetLockerLogHistory,
  DeleteLocker,
  LockerRoomlist,
  FindLockerLock,
  LockerLogList,
  DeletelockerLog,
  SaveLockerLog,
  FindLocker,
  AddLockerLog,
  LockList,
  AddLock,
  DeleteLock,
  AssignLock,
  RemoveAssigmentLock,
  ViewAssignmentLock,
  ViewAssignment,
  EditAssignment,
  AddEditCombo,
  CheckPin,
  CheckPinLock,
  EditLockCombo,
  SendCombo,
  AddRoom,
  LockerInspectionPdf,
  ViewLockerDetail,
  //Start Logs Module:- Lokcer Log -> Lockers

  //Start Log Module:- Alarm Activation Log
  AlarmActivationLogList,
  AddActivationLog,
  DeleteAlarmActivationLog,
  ViewAlarmActivationLog,
  UpdateAlarmActivationLog,
  PrintEmergencyReport,
  //End Log Module:- Alarm Activation Log

  //Start Log Module:- Attendance Log
  AttendanceLogList,
  DeleteAttendanceLog,
  GetEmployeeById,
  AddAttendance,
  ViewAttendanceLog,
  UpdateAttendance,
  AttendanceLogUserList,
  AddAttendanceNotify,
  AttendanceLogPdf,
  GetDepartmentManager,
  //Start Log Module:- Attendance Log
  //Start Logs Module:- Covid-19 Survey
  CovidSurveyListing,
  ViewCovidSurveyInfo,
  ScanRespondentCode,
  SaveCovidTemp,
  UnmaskPassword,
  Covid19ScreeningSurvey,
  Covid19SurveyLocation,
  //End Logs Module:- Covid-19 Survey
  //Start Logs Module - Key Requisition
  KeyRequisitionListing,
  DeleteKeyRequisition,
  AddKeyRequisitionRecord,
  SaveKeyRequest,
  SaveKeyDetails,
  KeyGroupListDrop,
  InvolvedKeyListing,
  GetInvolvedKeyData,
  DeleteInvolvedKey,
  SaveAuthPersonDetails,
  InvolvedAuthPerListing,
  GetInvolvedAuthPerData,
  DeleteInvolvedAuthPer,
  OverrideAuthorization,
  ViewKeyRequisitionInfo,
  EditKeyRequisitionRecord,
  //End Logs Module - Key Requisition
  //Start Logs Module - AED Mngmt
  AedInventoryListing,
  DropMakeList,
  DropModelList,
  AddAedInventoryRecord,
  DeleteAedInventory,
  GetModelImageList,
  ViewAedsInventoryInfo,
  AedBulkEditDate,
  DropDeviceLocation,
  DropDeviceDetail,
  DropMakeListInspection,
  DropModelListInspection,
  DropMadeListInspection,
  AedInspectionsListing,
  AddAedInspectionRecord,
  DeleteAedInspection,
  ViewAedInspectionInfo,
  AddTrainedresponder,
  AedTrainedResponderListing,
  DeleteTrainedResponder,
  ViewTrainedResponderInfo,
  AedDashBoard,
  ViewAedMedicalOfficier,
  AddEditAedMedicalOfficier,
  AddEditAedPlanOfficier,
  ViewAedPlanOfficier,
  DownloadWrittenPlan,
  DownloadMedicalDirector,
  AedCsv,
  UpdateAedTriningDate,
  //End Logs Module - AED Mngmt

  //Start Logs Module - Emergency Procedures
  SaveEmergencyProcedures,
  EmergencyProcedures,
  GetEmergencyTypes,
  AddEmergencyType,
  EditEmergencyType,
  DeleteEmergencyType,
  //End Logs Module - Emergency Procedures

  //Start Visitor
  VisitorTypeList,
  VisitorTypeListDropdown,
  VisitorCompanyListDropdown,
  CheckInVisitor,
  CheckOutVisitor,
  UpdateGroupVisitoreList,
  SaveVisitorDetails,
  ViewKioskSettingDetails,
  SendPhoneOtp,
  VerifyPhoneOtp,
  SaveKioskSetting,
  ValidateKioskModePassword,
  SavePackageDetails,
  SearchVisitor,
  CheckOutVisitorFromKioskMode,
  CheckInVisitorFromKioskMode,
  KioskModeData,
  AddVisitorNewCompany,
  VisitorDepartmentList,
  AddVisitorData,
  AddVisitorType,
  ActiveInactiveVisitor,
  TriggerEmailVisit,
  ViewVisitor,
  EditVisitors,
  InlineUpdateVisitor,
  ScanBarcode,
  UpdateVisitor,
  VisitorCompanyList,
  ActiveVisitorCompany,
  AddUpdateVisitorCompany,
  ViewVisitorCompany,
  DeleteVisitorCompany,
  GetVisitorWatchList,
  AddUpdateVisitorWatch,
  DeleteVisitorWatch,
  ViewVisitorWatch,
  VisitorEquipment,
  CheckVisitorUser,
  EquipmentPermission,
  GenerateEmergencyListPDF,
  AutoCompleteHostName,
  UpdateVisitorWatchListStatus,
  PrintVehiclePass,
  PrintVisitorBadge,
  //End Visitor
  //Start Property Inspection -> Inspection Area
  GetInspectionAreaList,
  AddUpdatePropertyInspectionArea,
  PropertyAreaDropdown,
  DeleteInspectionArea,
  //End Property Inspection -> Inspection Area
  //Start Property Inspection -> Inspection Question
  GetInspectionQuestionList,
  DeleteInspectionQuestion,
  InspectionAreaDropdown,
  InspectionCategoryDropdown,
  AddUpdateInspectionQuestion,
  GetInspectionResultList,
  FindResultsPdf,
  FetchSelectedQuestions,
  QuestionReorder,
  FindResultPdf,
  //End Property Inspection -> Inspection Question
  // Start Visitors Module
  VisitorDashboardCount,
  GetVisitorList,
  VisitorStatusUpdate,
  DeleteVisitor,
  CompanyList,
  VisitorsList,
  ViewVisitorLogs,
  CloneVisitor,
  VisitLogListing,
  // End Visitors Module
  //--Start Property Inspection section--
  AddEditPropertyArea,
  PropertyAreaList,
  DeletePropertyArea,
  AddEditPropertyAreaManager,
  PropertyAreaManagerList,
  DeletePropertyAreaManager,
  AddEditInspectionCategory,
  InspectionCategoryList,
  DeleteInspectionCategory,
  //--End Property Inspection section--
  //---Start POI (Person of intrest)---
  PersonOfIntrestList,
  SavePOI,
  UpdatePOI,
  SaveKeyForm,
  ReporterUserList,
  TaskActivityList,
  TaskUserList,
  DynamicKey,
  ShareLocationsList,
  UpdatePoiRequireReview,
  UpdateInlinePoiData,
  PoiEncounterList,
  //---End POI (Person of intrest)---
  //---Start EAR (Employee Acc)---
  AddEARReport,
  DeleteEmployeeAccident,
  EmployeeAccidentList,
  ViewEmployeeAccidentReport,
  EditEmpAccident,
  AddManagerAccident,
  EditManagerAccident,
  PreventativeActionPlanListing,
  ViewPreventativeActionPlan,
  UpdatePreventativeActionPlan,
  InvestigationTeamList,
  DeleteInvestigation,
  WitnessList,
  DeleteWitness,
  SendEarEmailList,
  SendEarReport,
  SendEmailManager,
  PrintEarPdf,
  EarDropdownPosition,
  EarTaskList,
  GetTaskLog,
  //---End EAR (Employee Acc)---
  //POI Module start
  GetPoiEncounterList,
  ViewPoiEncounter,
  ClassificationList,
  ViewPoi,
  AddEncounter,
  UpdateEncounter,
  AddClassifcition,
  DeleteClassifcition,
  EncounterRequireReview,
  PoiImageSearch,
  MergeSelectedPoi,
  DeletePoi,
  PoiPrintReport,
  LocationLatLong,
  PrintEncounterReport,
  PoiEncounterMap,
  DeletePoiAttachment,
  PoiEncList,
  PoiWatchlist,
  //POI Module end

  //Package Modele Start
  PackagesList,
  AddPackage,
  ViewPackage,
  UpdatePackage,
  DeletePackage,
  TrackingSearch,
  ClonePackage,
  StorageLocationDropDown,
  PackageDetail,
  BulkDelivery,
  AddBulkPackage,
  GetPackageStorage,
  AddStorage,
  AddParentNode,
  DeletePackageStorage,
  TenantList,
  UpdateTenantStatus,
  AddTenant,
  UpdateTenant,
  ViewTenant,
  PackageAuditList,
  UpdatePackageAudit,
  DeletePackageAudit,
  PrintReceivingRecord,
  RecipientList,
  TenantDropdown,
  RecivedPackage,
  AddPackageAudit,
  ViewPackagePrice,
  UpdatePackageSystem,
  UpdatePackagePrice,
  //Package Module End

  // Start Dashboard
  GetLocations,
  ShowMessageBoard,
  BoardDeleteMessage,
  AddGuestBook,
  GetUserMessage,
  ToggleWidget,
  GraphData,
  MarkAsReadMessage,
  GetHighChartData,
  SaveHighChart,
  getCalendarJson,
  // End Dashboard

  // Start Calender
  AddEvent,
  SaveManualData,
  UpdateEvent,
  GetEventData,
  DeleteEvent,
  ActiveLocationUsers,
  CloneDaily,
  CloneLastWeekEvents,
  // End Calender

  // Start Task Module
  GetKanbanData,
  MoveKanbanItem,
  SaveModuleTask,
  TaskCommentList,
  SaveModuleTaskComment,
  // End Task Module

  //Communication Module
  CommunicationList,
  RemoveVerification,
  verifyStatus,
  UpdateCommRecord,
  DeleteCommnication,
  AddCommunication,
  IncidentTypeList,
  GetAssignments,
  SaveAssignment,
  SendVerification,
  GetCommUser,
  SendTestComm,
  GetTestCommReport,
  //End Communication

  //Settings

  // Ir Number
  SaveIrNumbering,
  GetIrNumbering,
  //end Ir number

  //Start IR Classification
  ListIrType,
  AddIr,
  ViewIr,
  EditIr,
  IrNameChange,
  TaskLibsList,
  DeleteTaskLibs,
  TaskLibsView,
  EditTaskLibs,
  ToggleImageRequired,
  IrActiveToggle,
  IrNurseNotifyToggle,
  IrCriticalTogggle,
  DeleteIr,
  CopyIRTypes,
  // End Ir Classifcation

  // Lost & Found Privacy
  ShipmentsReports,
  UpdateGdpr,
  //End Lost & Found Privacy

  //Customize Report
  CustomInputs,
  ToggleFieldsStatus,
  //end Customize Report

  //IPRange
  IpRange,
  AddRange,
  DeleteRange,
  //Carrier Settings
  ShippoList,
  EditCarrierStatus,
  SetupCSL,
  CarrierServiceLevels,
  UpdateCarrierServiceStatus,
  //Lost and found rules
  ListRules,
  ListItem,
  SaveRules,
  ViewRules,
  DeleteRules,
  //end lost and found rules
  VenueList,
  ChangeVenueStatus,
  AddVenue,
  ViewVenue,
  DeleteVenue,
  //Chatbot question
  QuestionList,
  SaveQuestion,
  DeleteQuestion,
  FindModule,
  //List Device
  DeviceList,
  EditDevice,
  DuplicatesDeviceList,
  ChangeHideStatus,
  SendFcmMessage,
  BulkUpdate,
  //login audit
  AuditUserList,
  ContinentList,
  CountryDropDownList,
  StateList,
  EditAuditUser,
  AuditUserPdf,
  SaveAuditUser,
  LogoutAllSessions,
  //Trigger Word
  ListTriggerWord,
  AddTriggerWord,
  DeleteTriggerWord,
  //security events
  SecurityEventsList,
  UnblockUser,
  LocateCityFromIp,
  //E notify Template
  NotifyTemplateList,
  UserMailList,
  AddUpdateNotifyTemplates,
  // search user
  LocationList,
  UserSearch,
  SaveInlineData,
  ChangeUserLocation,
  ViewNotifyTemplates,
  DeleteNotifyTemplates,
  // modify nav menu
  ModifyNavMenu,
  UpdateMenuOrder,
  ToggleMenuStatus,
  //Youtube
  VideoList,
  AddVideo,
  UserEmailVerification,
  UserPhoneVerification,
  getControllerList,
  userGroupList,
  getControllerActionList,
  updateGroupPermission,
  //Notify
  AddNotification,
  ViewNotification,
  EditNotification,
  DeleteNotification,
  GetNotificationView,
  //switch dummy
  UpdateSwitchDummyData,
  ViewSwitchDummyData,
  //emergency notification
  SendEmergencyAlert,
  GetSelectedTemplate,
  HeaderSearch,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Box,
  Grid,
  FormControl as MuiFormControl,
  Divider as MuiDivider,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Toolbar,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
  Card,
  TableSortLabel,
} from "@mui/material";
import {
  StorageLocationDropDown,
  RecivedPackage,
  AddPackageAudit,
} from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(id, receiveddate, trackingid, description) {
  return {
    id,
    receiveddate,
    trackingid,
    description,
  };
}
const rows = [
  createData(
    "20296",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
  createData(
    "20297",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
  createData(
    "20298",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
  createData(
    "20299",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
  createData(
    "20291",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "receiveddate",
    numeric: true,
    disablePadding: false,
    label: "Received Date",
  },
  {
    id: "trackingid",
    numeric: true,
    disablePadding: false,
    label: "Tracking ID",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          /> */}
          Stored
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, recivedPackageList } = props;
  return (
    <Toolbar>
      <div>
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content" }}
        >
          {recivedPackageList?.length} records found
        </Typography>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const { recivedPackageList, childToParent } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    childToParent(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  // console.log(selected);
  // console.log(
  //   recivedPackageList.map((it) => it.id).filter((x) => !selected.includes(x))
  // );
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          recivedPackageList={recivedPackageList}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(recivedPackageList, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ lg: 'whiteSpace: "nowrap"' }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {row.package_arrival_date}
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {row.tracking_number}
                      </TableCell>
                      <TableCell align="left">{row.description_note}</TableCell>
                    </TableRow>
                  );
                })}
              {recivedPackageList.length == 0 && (
                <TableRow style={{ height: (dense ? 6 : 0) * emptyRows }}>
                  <TableCell colSpan={12}>No record found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

const AddAudit = ({ addAudit, addAuditClose, packagesAuditList }) => {
  // Storage Location
  const [recivedPackageList, setRecivedPackageList] = useState([]);
  const [packageStorageList, setPackageStorageList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [packageStorage, setPackageStorage] = useState("");
  const dispatch = useDispatch();
  const selectPackageStorage = (event) => {
    setPackageStorage(event.target.value);
    recivedPackage(event.target.value);
  };

  const packStorage = () => {
    StorageLocationDropDown()
      .then((res) => {
        if (res.statusCode === 200) {
          setPackageStorageList(res.data);
        } else {
          setPackageStorageList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const recivedPackage = (val) => {
    RecivedPackage({ selectedLocation: val })
      .then((res) => {
        if (res.statusCode === 200) {
          setRecivedPackageList(res.data);
        } else {
          setRecivedPackageList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const childToParent = (val) => {
    setSelected(val);
  };

  const handleSubmit = () => {
    const payload = {
      storageLocation: packageStorage,
      checked: selected,
      notCheckedVal: recivedPackageList
        .map((it) => it.id)
        .filter((x) => !selected.includes(x)),
    };
    AddPackageAudit(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Record submitted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          packagesAuditList();
          addAuditClose();
        } else {
          dispatch(getMsg("Something went wrong!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };
  useEffect(() => {
    packStorage();
  }, []);
  return (
    <>
      <Dialog
        open={addAudit}
        onClose={addAuditClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        aria-labelledby="add-audit-title"
        aria-describedby="add-audit-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-audit-title"
        >
          <Typography variant="h4" component="div">
            Add Audit
          </Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={addAuditClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a package storage location from the dropdown below. You will
            see a list of packages populate below. Next, verify that each
            package is accounted for by checking the box. After you save, you
            will see a new package audit record in the listview.
          </DialogContentText>
          <FormControl mt={6}>
            <InputLabel
              shrink
              id="department"
              sx={{
                paddingLeft: "6px",
                paddingRight: "6px",
                marginLeft: "-4px",
              }}
            >
              Select package storage
            </InputLabel>
            <Select
              labelId="department"
              id="storage-location"
              value={packageStorage}
              onChange={selectPackageStorage}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Select</MenuItem>
              {packageStorageList.map((it) => {
                return (
                  <MenuItem value={it.key} key={it.value}>
                    {it.key}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Card variant="outlined" sx={{ marginTop: "30px" }}>
            <EnhancedTable
              recivedPackageList={recivedPackageList}
              childToParent={childToParent}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={addAuditClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: "10px" }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAudit;

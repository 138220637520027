import React from "react";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  FormControl as MuiFormControl,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const keyGroup = [
  { key: "Executive", value: "executive" },
  { key: "Test updated", value: "testupdated" },
  { key: "Other", value: "other" },
];
const ViewKeyDetail = ({ viewKeyDetailDialog, viewKeyDetailDialogClose }) => {
  let initialValues = {};
  return (
    <>
      <Dialog
        aria-labelledby="viewKeyDetail-title-modal"
        aria-describedby="viewKeyDetail-desc-modal"
        open={viewKeyDetailDialog}
        onClose={viewKeyDetailDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="attendance-title-modal"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Key Detail
          </Typography>
          <DialogActions>
            <IconButton
              onClick={viewKeyDetailDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues}>
            <form>
              <Grid container spacing={6}>
                <Grid item xs={12} md={12}>
                  <FormControl mt={3}>
                    <CustomTextField
                      disabled
                      label="Equipment ID *"
                      autoComplete="off"
                      name="equipment_id"
                      id="equipment-id"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <CustomTextField
                      disabled
                      label="Equipment name *"
                      autoComplete="off"
                      name="equipment_name"
                      id="equipment-name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <CustomSelect
                      disabled
                      name="key_group"
                      fieldLabel="Key group *"
                      options={keyGroup}
                      displayEmpty
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewKeyDetail;

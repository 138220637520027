import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
  Button,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { UserPropertyList, CopyIRTypes } from "../../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const CopyIrClasses = ({ copyIrClassesDialog, copyIrClassesDialogClose }) => {
  const [listLocation, setListLocation] = useState([]);
  const [location, setlocation] = useState("");
  const dispatch = useDispatch();
  const selectLocation = (event) => {
    console.log("event", event.target.value);
    setlocation(event.target.value);
  };

  const userPropertyList = () => {
    UserPropertyList()
      .then((res) => {
        if (res.statusCode == 200) {
          console.log("resData", res.data);
          setListLocation(res.data);
        } else {
          setListLocation([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const copyIRTypes = () => {
    let payload = {
      location: location,
    };
    CopyIRTypes(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          const locationName = listLocation.find(
            (item) => item.LocationID === location
          );
          dispatch(
            getMsg(
              `IR Types successfully copied to ${locationName.LocationName}`
            )
          );
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    userPropertyList();
  }, []);
  return (
    <>
      <Dialog
        aria-labelledby="copyrr-classes"
        aria-describedby="copyrr-classes-desc"
        open={copyIrClassesDialog}
        onClose={copyIrClassesDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="vcomm-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Copy IR Classes
          </Typography>
          <DialogActions>
            <IconButton
              onClick={copyIrClassesDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the site from the list below to copy IR classes to. Please
            note, all active IR types for the current location ID will be copied
            to the new location ID.
          </DialogContentText>
          <FormControl mt={6}>
            <Select
              labelId="department"
              id="location"
              value={location}
              onChange={selectLocation}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Select location</MenuItem>
              {listLocation.map((item) => {
                return (
                  <MenuItem value={item.LocationID}>
                    {" "}
                    {item.LocationName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ paddingRight: "24px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={copyIrClassesDialogClose}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: "15px" }}
            onClick={copyIRTypes}
          >
            Copy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CopyIrClasses;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import moment from "moment";
import Rating from "@mui/material/Rating";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import CustomDateTimePicker from "../../../components/form-components/CustomDateTimePicker";
import { handleApiResonseErrors } from "../../../helper/helper";
import { SaveCommunicationLog, CommunicationLog } from "../../../api";
import * as Yup from "yup";
import { getFileBase64 } from "../../../helper/helper";
import {
  TextField,
  Box,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  IconButton,
  DialogContent,
  Typography,
  CircularProgress,
  FormControl as MuiFormControl,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Paper,
  Tooltip,
} from "@mui/material";

import { Cancel as CancelIcon, Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useParams } from "react-router-dom";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Commlog = ({
  CommlogDialog,
  setCommlogDialog,
  CommlogDialogClose,
  showAlertTost,
}) => {
  const [loading, setLoading] = useState(false); //State for loader
  const [uploadedImage, setUploadedImage] = useState(null);
  const { id } = useParams();
  const log_type = "lostfound";

  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
    borderedBox: {
      border: "1px solid",
      borderBottom: 0,
      borderColor:
        theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
    },
  }));

  const classes = useStyles();

  const callLength = [
    { key: "Unanswered", value: "Unanswered" },
    { key: "< 2 Minutes", value: "<2Minutes" },
    { key: "2-5 Minutes", value: "2-5 Minutes" },
    { key: "5-10 Minutes", value: "5-10 Minutes" },
    { key: "10-20 Minutes", value: "10-20 Minutes" },
    { key: "> 20 Minutes", value: ">20Minutes" },
  ];
  let initialValues = {
    image: "",
    lost_found_id: id,
    log_type: log_type,
    com_datetime: null,
    origin: "Incoming",
    type: "Phone",
    caller_sentiment: 0,
    caller_name: "",
    caller_number: "",
    length_of_call: "",
    purpose_of_call: "",
    follow_up: "",
    status: "Open",
  };

  const validationSchema = Yup.object().shape({
    com_datetime: Yup.date().nullable().required("Please select date."),
    caller_name: Yup.string().required("Please enter value."),
    follow_up: Yup.string().required("Please enter value."),
    caller_sentiment: Yup.number()
      .required("Please select caller sentiment.")
      .positive("Please select caller sentiment.")
      .typeError("Please select caller sentiment."),
    caller_number: Yup.number()
      .nullable()
      .when(["origin"], (originValue, schema) => {
        return originValue === "Incoming" || originValue === "Outgoing"
          ? schema
              .positive("Please enter a valid number.")
              .typeError("Please enter a valid number.")
              .required("Please enter a value.")
          : schema;
      }),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        lost_found_id: values.lost_found_id,
        log_type: values.log_type,
        com_datetime: values.com_datetime,
        origin: values.origin,
        type: values.type,
        caller_sentiment: values.caller_sentiment,
        caller_name: values.caller_name,
        follow_up: values.follow_up,
        status: values.status,
        caller_number:
          values.origin === "Notes Only" ? "" : values.caller_number,
        length_of_call:
          values.origin === "Notes Only" ? "" : values.length_of_call,
        purpose_of_call:
          values.origin === "Notes Only" ? "" : values.purpose_of_call,
      };

      SaveCommunicationLog(formData)
        .then((res) => {
          setLoading(true);
          if (res.statusCode === 200) {
            setLoading(false);
            showAlertTost(res.message, "success");
            CommlogDialogClose();
          } else {
            showAlertTost("Sorry! something wrong please try again", "error");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            showAlertTost(error, "error");
          }
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      showAlertTost("Sorry! something wrong please try again", "error");
    }
  };

  const handleOnPaste = (pasteEvent) => {
    var item = pasteEvent.clipboardData.items[0];
    if (item.type.indexOf("image") === 0) {
      var file = item.getAsFile();
      getFileBase64(file, (result) => {
        setUploadedImage(result);
      });
    }
  };
  const [list, setList] = React.useState([]);
  React.useEffect(() => {
    getListing();
    return () => {
      setList([]);
    };
  }, []);

  const getListing = () => {
    setLoading(true);
    let payload = {
      id: id,
      log_type: log_type,
    };
    CommunicationLog(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setList(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog
        open={CommlogDialog}
        aria-labelledby="comm-log-title"
        aria-describedby="comm-log-description"
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogTitle
          id="comm-log-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Communication Log: ID #{id}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => CommlogDialogClose(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px " }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl mt={3}>
                      <CustomDateTimePicker
                        name="com_datetime"
                        label="Date and Time"
                        inputFormat="yyyy-MM-dd HH:mm:ss"
                        placeholder="yyyy-mm-dd H:i:s"
                        setValue={(value) => {
                          let date = value ? moment(value) : "";
                          let dateValue = date
                            ? date.format("YYYY-MM-DD HH:mm:ss")
                            : "";
                          setFieldValue("com_datetime", dateValue);
                        }}
                      />
                      <FormHelperText>{errors.com_datetime}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl mt={3}>
                      <FormLabel id="origin">Origin</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="origin"
                        name="origin"
                        value={values.origin}
                        onChange={(e) =>
                          setFieldValue("origin", e.currentTarget.value)
                        }
                      >
                        <FormControlLabel
                          value="Incoming"
                          control={<Radio />}
                          label="Incoming"
                        />
                        <FormControlLabel
                          value="Outgoing"
                          control={<Radio />}
                          label="Outgoing"
                        />
                        <FormControlLabel
                          value="Notes Only"
                          control={<Radio />}
                          label="Notes Only"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl mt={3}>
                      <FormLabel id="type">Type</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="type"
                        name="type"
                        value={values.type}
                        onChange={(e) =>
                          setFieldValue("type", e.currentTarget.value)
                        }
                      >
                        <FormControlLabel
                          value="Phone"
                          control={<Radio />}
                          label="Phone"
                        />
                        <FormControlLabel
                          value="Email"
                          control={<Radio />}
                          label="Email"
                        />
                        <FormControlLabel
                          value="SMS"
                          control={<Radio />}
                          label="SMS"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl>
                      <CustomTextField
                        name="caller_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={
                          values.origin === "Incoming"
                            ? "Person receiving message"
                            : values.origin === "Outgoing"
                            ? "Person Called"
                            : "Note Added By"
                        }
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  {(values.origin === "Outgoing" ||
                    values.origin === "Incoming") && (
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl>
                        <CustomTextField
                          name="caller_number"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Phone/SMS"
                          autoComplete="off"
                          placeholder=""
                          inputProps={{ maxLength: 20 }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {(values.origin === "Outgoing" ||
                    values.origin === "Incoming") && (
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl>
                        <CustomSelect
                          name="length_of_call"
                          fieldLabel="Call Length"
                          options={callLength}
                          displayEmpty
                        />
                        <FormHelperText>{errors.length_of_call}</FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  {(values.origin === "Outgoing" ||
                    values.origin === "Incoming") && (
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl>
                        <CustomTextField
                          name="purpose_of_call"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Purpose"
                          autoComplete="off"
                          rows={3}
                          multiline={true}
                          placeholder=""
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl>
                      <CustomTextField
                        name="follow_up"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Followup"
                        autoComplete="off"
                        rows={3}
                        multiline={true}
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl>
                      <FormLabel>Caller sentiment</FormLabel>
                      <FormHelperText sx={{ marginLeft: 0 }}>
                        Select how the call interaction was. Higher stars
                        represent a more positive experience.
                      </FormHelperText>
                      <Rating
                        name="caller_sentiment"
                        value={
                          values.caller_sentiment
                            ? parseInt(values.caller_sentiment)
                            : 0
                        }
                        onChange={(event, newValue) => {
                          setFieldValue("caller_sentiment", newValue);
                        }}
                      />
                      <FormHelperText>{errors.caller_sentiment}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <FormLabel id="status">Status</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="status"
                        name="status"
                        value={values.status}
                        onChange={(e) =>
                          setFieldValue("status", e.currentTarget.value)
                        }
                      >
                        <FormControlLabel
                          value="Open"
                          control={<Radio />}
                          label="Open"
                        />
                        <FormControlLabel
                          value="Closed"
                          control={<Radio />}
                          label="Closed"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {values.type === "Email" && (
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FormLabel id="ss">Paste screenshot</FormLabel>
                          {uploadedImage && (
                            <Tooltip
                              title="Remove image"
                              placement="top-start"
                              sx={{ marginLeft: "5px" }}
                              onClick={() => setUploadedImage(null)}
                            >
                              <IconButton>
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                        {uploadedImage === null ? (
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{ marginTop: "10px" }}
                            rows={3}
                            multiline={true}
                            placeholder="Press ctrl+v to share your screenshot"
                            onPasteCapture={(e) => handleOnPaste(e)}
                          />
                        ) : (
                          <Box
                            className={classes.borderedBox}
                            sx={{
                              minHeight: "100px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: 1,
                              marginTop: "10px",
                            }}
                          >
                            {uploadedImage && (
                              <img
                                alt="image name"
                                src={uploadedImage}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={CommlogDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <TableContainer className="tableContainer" sx={{ marginTop: "20px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Datetime</TableCell>
                  <TableCell>Origin</TableCell>
                  <TableCell>Caller Name</TableCell>
                  <TableCell>Caller Number</TableCell>
                  <TableCell>Call Length</TableCell>
                  <TableCell>Purpose</TableCell>
                  <TableCell>Follow Up</TableCell>
                  <TableCell>Caller Sentiment</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Staff</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        {moment(row.created).format("YYYY/MM/DD HH:mm:ss")}
                      </TableCell>
                      <TableCell>{row.origin ? row.origin : "-"}</TableCell>
                      <TableCell>
                        {row.caller_name ? row.caller_name : "-"}
                      </TableCell>
                      <TableCell>
                        {row.caller_number ? row.caller_number : "-"}
                      </TableCell>
                      <TableCell>
                        {row.length_of_call ? row.length_of_call : "-"}
                      </TableCell>
                      <TableCell>
                        {row.purpose_of_call ? row.purpose_of_call : "-"}
                      </TableCell>
                      <TableCell>
                        {row.follow_up ? row.follow_up : "-"}
                      </TableCell>
                      <TableCell>
                        <Rating
                          name="read-only"
                          value={
                            row.caller_sentiment
                              ? parseInt(row.caller_sentiment)
                              : ""
                          }
                          readOnly
                        />
                      </TableCell>
                      <TableCell>{row.status ? row.status : "-"}</TableCell>
                      <TableCell>
                        {row.user ? row.user.UserName : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {list.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default Commlog;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button,
  Paper,
  IconButton,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import CustomDateRange from "../../../components/form-components/CustomDateRange";
import moment from "moment";
import { subMonths, format } from "date-fns";
import { DailyActivityChangeLog } from "../../../api";

const ChangeLogDialog = ({ changeLog, changeLogClose }) => {
  const [startDateToFilter, setStartDateToFilter] = useState(new Date());
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const [fadebox, setFadedBox] = React.useState(false);
  const [changeLogList, setChangeLogList] = useState([]);
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader

  // Method for daily activity log dialog listing
  const getChangeLogList = () => {
    let formatedsDate = moment(startDateToFilter).format("YYYY-MM-DD"); //startDateToFilter;
    let formatedeDate = moment(endDateToFilter).format("YYYY-MM-DD"); //endDateToFilter;

    let payload = {
      start_date: formatedsDate, //"2020-06-01",
      end_date: formatedeDate, // "2022-06-24",
    };
    setDialogLoader(true);
    DailyActivityChangeLog(payload)
      .then((res) => {
        setDialogLoader(false);
        setChangeLogList(res.data);
        // if (res.statusCode == 200) {
        //   setMissedTourButtonList(res.data);
        //   setTotalTourCount(res.tour_run_stats_count);
        // } else {
        //   setMissedTourButtonList(res.data);
        // }
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getChangeLogList();
  }, []);

  return (
    <>
      <Dialog
        open={changeLog}
        onClose={changeLogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="activity-title"
        >
          <Typography variant="h4">Change Log</Typography>
          <DialogActions>
            <IconButton onClick={changeLogClose} sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid container spacing={4} mt={2} sx={{ padding: "0 24px" }}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="dateRange"
                label="Date range"
                value={
                  startDateToFilter && endDateToFilter
                    ? moment(startDateToFilter).format("MM-DD-YYYY") +
                      " - " +
                      moment(endDateToFilter).format("MM-DD-YYYY")
                    : ""
                }
                onClick={() => setFadedBox((prev) => !prev)}
                // onChange={handleChange}

                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Select date range"
              />
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                  }}
                  selectedStart={startDateToFilter}
                  selectedEnd={endDateToFilter}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="primary"
                variant="contained"
                sx={{ height: "100%" }}
                onClick={getChangeLogList}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
          <Paper mt={3}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Old Comment</TableCell>
                    <TableCell>New Comment</TableCell>
                    <TableCell>Old Location</TableCell>
                    <TableCell>New Location</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Datetime</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogLoader ? (
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <>
                      {changeLogList.map((item) => {
                        return (
                          <>
                            <TableRow hover key={item.user}>
                              <TableCell>{item.old_comment}</TableCell>
                              <TableCell>{item.new_comment}</TableCell>
                              <TableCell>{item.old_location}</TableCell>
                              <TableCell>{item.new_location}</TableCell>
                              <TableCell>{item.user_activity_id}</TableCell>
                              <TableCell>{item.created}</TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                      {changeLogList.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={6}>
                            No data for selected date range
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}
                  {/* <TableRow>
                    <TableCell sx={{ minWidth: "300px" }}>blah blah</TableCell>
                    <TableCell sx={{ minWidth: "300px" }}>blah blah</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Noida</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Noida</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      Lorem Ipsum
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      21/3/22 02:40
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={changeLogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeLogDialog;

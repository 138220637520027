import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import {
  LockerRoomlist,
  FindLocker,
  AssignLock,
  ViewAssignmentLock,
} from "../../../api";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem,
  FormLabel,
  Card,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const lockerRoom = [
  { key: "Unanswered", value: "Unanswered" },
  { key: "< 2 Minutes", value: "<2Minutes" },
  { key: "2-5 Minutes", value: "2-5 Minutes" },
  { key: "5-10 Minutes", value: "5-10 Minutes" },
  { key: "10-20 Minutes", value: "10-20 Minutes" },
  { key: "> 20 Minutes", value: ">20Minutes" },
];
const locker = [
  { key: "Unanswered", value: "Unanswered" },
  { key: "< 2 Minutes", value: "<2Minutes" },
  { key: "2-5 Minutes", value: "2-5 Minutes" },
  { key: "5-10 Minutes", value: "5-10 Minutes" },
  { key: "10-20 Minutes", value: "10-20 Minutes" },
  { key: "> 20 Minutes", value: ">20Minutes" },
];
const LockAssignment = ({
  lockAssignmentDialog,
  lockAssignmentDialogClose,
  lockList,
  id,
  lockAssignId,
}) => {
  const [roomList, setRoomList] = useState([]);
  const [lockerList, setLockerList] = useState([]);
  const [viewAssignData, setViewAssignData] = useState({});
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const viewLockAssign = (cb) => {
    setLoading(true);
    ViewAssignmentLock(lockAssignId)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewAssignData(res.data);
          cb(res.data.locker_room);
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getLockerRoomlist = () => {
    LockerRoomlist()
      .then((res) => {
        setRoomList(res.data);
        //  setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        //  setLoading(false);
      });
  };

  const getLockerLockList = (value) => {
    // setLoading(true);
    FindLocker({ room_name: value })
      .then((res) => {
        if (res.statusCode === 200) {
          setLockerList(res.data);
        } else {
          setLockerList([]);
        }
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLockerRoomlist();
    viewLockAssign(getLockerLockList);
  }, []);

  const { locker_id, locker_room, locker_number } = viewAssignData;
  const initialValues = {
    locker_room: locker_room ? locker_room : "",
    locker: locker_number ? locker_number : "",
    locker_id: "",
  };
  const validationSchema = Yup.object().shape({
    locker_room: Yup.string().required("Please enter tour name"),
    locker: Yup.string().required("Please enter tour description"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setLoading(true);
    try {
      let payload = {
        room_name: values.locker_room,
        lock_number: values.locker,
        lock_id: id,
        locker_id: values.locker_id ? values.locker_id : locker_id,
      };
      AssignLock(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            lockList();
            lockAssignmentDialogClose();
            setLoading(false);
            // dispatch(getSnackOpen());
            // dispatch(getMsg("Lock updated successfully"));
            // dispatch(getSnackAlert("success"));
          } else {
            setLoading(false);
            // dispatch(getSnackOpen());
            // dispatch(getMsg("Something went wrong!"));
            // dispatch(getSnackAlert("error"));
          }
        })
        .catch((err) => {
          console.log(err);
          // dispatch(getSnackClose());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="lock-assignment-title"
        aria-describedby="lock-assignment-desc"
        open={lockAssignmentDialog}
        onClose={lockAssignmentDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "650px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="lock-assignment-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Lock Assignment
          </Typography>
          <DialogActions>
            <IconButton
              onClick={lockAssignmentDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a locker room and locker to assign this external combo lock
            to a locker.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="locker_room"
                        fieldLabel="Locker room"
                        options={roomList}
                        displayEmpty
                        InputLabelProps={{ shrink: true }}
                        customCallbackMethod={(evt) => {
                          if (evt.target.value) {
                            setFieldValue("locker", "");
                            getLockerLockList(evt.target.value);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="locker"
                        fieldLabel="Locker"
                        options={lockerList}
                        displayEmpty
                        InputLabelProps={{ shrink: true }}
                        customCallbackMethod={(evt) => {
                          if (evt.target.value) {
                            let value = evt.target.value;
                            var lockerObj = lockerList.find(function (obj) {
                              return obj.value == value;
                            });
                            if (lockerObj) {
                              setFieldValue("locker_id", lockerObj.id);
                            }
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    clor="primary"
                    variant="outlined"
                    onClick={lockAssignmentDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    clor="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default LockAssignment;

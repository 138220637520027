export default [
  {
    id: "visitor_name",
    label: "Visitor Name",
  },
  {
    id: "company_name",
    label: "Company Name",
  },
  {
    id: "check_in",
    label: "Check In (UTC)",
  },
  {
    id: "check_out",
    label: "Check Out (UTC)",
  },
  {
    id: "host_name",
    label: "Host Name",
  },
  {
    id: "purpose_visit",
    label: "Purpose of visit",
  },
  {
    id: "note",
    label: "Note",
  },
];

import React, { useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import {
  Typography,
  Grid,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Card,
  Table,
  IconButton,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormHelperText,
  FormControl as MuiFormControl,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { AddVideo } from "../../../../api";

const NewTrainingVideo = ({
  newTrainingVideoDialog,
  setNewTrainingVideoDialog,
  newTrainingVideoDialogOpen,
  newTrainingVideoDialogClose,
  updatedRow,
  videoList,
}) => {
  const dispatch = useDispatch();
  // const [formData, setFormData] = useState(updatedRow);

  // handle formData based on updatedRow value
  const [formData, setFormData] = useState(
    updatedRow || {
      title: "",
      youtube_url: "",
      summary: "",
      page_url: "",
    }
  );
  const [errors, setErrors] = useState("");
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const addVideo = () => {
    const youtubeUrlRegExp =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/embed\/|youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const validationSchema = Yup.object().shape({
      title: Yup.string().required("title cannot blank"),
      youtube_url: Yup.string()
        .nullable()
        .matches(youtubeUrlRegExp, "YouTube URL is not valid"),
    });
    let payload = {
      id: formData.id ? formData.id : null,
      title: formData.title,
      summary: formData.summary,
      youtube_url: formData.youtube_url,
      page_url: formData.page_url,
    };
    validationSchema
      .validate(payload, { abortEarly: false })
      .then(() => {
        // Validation passed, proceed with saving
        // console.log("payload", payload);
        AddVideo(payload)
          .then((res) => {
            if (res.statusCode == 200) {
              videoList();
              newTrainingVideoDialogClose();
              if (formData?.id) {
                dispatch(getMsg("Data updated successfully"));
              } else {
                dispatch(getMsg("Data add successfully"));
              }
              dispatch(getSnackAlert("success"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
            } else {
              dispatch(getMsg(res.message));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
            }
          })
          .catch((err) => {});
      })
      .catch((validationErrors) => {
        // Handle validation errors
        console.log("Validation errors:", validationErrors);
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setErrors(errors);
      });
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={newTrainingVideoDialog}
        onClose={newTrainingVideoDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            ADD Youtube URL
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setNewTrainingVideoDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item md={6} mt={5}>
              <TextField
                fullWidth
                id="outlined"
                label="Title"
                name="title"
                value={formData ? formData.title : ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: "true",
                }}
              />
              <FormHelperText error>{errors.title}</FormHelperText>
            </Grid>

            <Grid item md={6} mt={5}>
              <TextField
                fullWidth
                id="outlined"
                label="Page URL"
                name="page_url"
                value={formData ? formData.page_url : ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: "true",
                }}
              />
            </Grid>
            <Grid item md={12} mt={2}>
              <TextField
                fullWidth
                id="outlined"
                label="Summary"
                name="summary"
                value={formData ? formData.summary : ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: "true",
                }}
              />
            </Grid>
            <Typography variant="body2" gutterBottom mt={3} ml={5}>
              URL format(change <strong>link</strong> to youtube ID)
            </Typography>

            <Typography variant="body2" gutterBottom mt={3} ml={5}>
              <Typography variant="body2" gutterBottom mt={3} ml={5}>
                <span
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.5)",
                    display: "inline-block",
                  }}
                >
                  https://youtube.com/embed/<strong>link</strong>
                  ?rel=0;&autoplay=0&mute=0&vq=hd1080&loop=1
                </span>
              </Typography>
            </Typography>
            <Grid item md={12} mt={2}>
              <TextField
                fullWidth
                id="outlined"
                label="YouTube URL"
                name="youtube_url"
                value={formData ? formData.youtube_url : ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: "true",
                }}
              />
              <FormHelperText error>{errors.youtube_url}</FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={newTrainingVideoDialogClose}
          >
            Close
          </Button>
          <Button onClick={addVideo} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewTrainingVideo;

import React from "react";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import { useField } from "formik";

const CustomDateTimePicker = ({ setValue, name, handler, ...otherProps }) => {
  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return (
    <DateTimePicker
      {...configTextfield}
      onChange={(event) => {
        setValue(event);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          onClick={handler}
        />
      )}
    />
  );
};

export default CustomDateTimePicker;

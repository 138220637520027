import React from "react";
import { makeStyles } from "@mui/styles";

import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  ListItems: {
    "& .MuiListItem-root": {
      border: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "#c1c1c18c" : theme.palette.grey[500],
      marginBottom: "15px",
      marginTop: "15px",
    },
  },
}));
const RuleSet = ({ show, handleModal }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={show}
      aria-labelledby="activity-log-title"
      aria-describedby="activity-log-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "650px",
          },
        },
      }}
    >
      <DialogTitle
        id="activity-log-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Rule set
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => handleModal(false)}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <Paper mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <List className={classes.ListItems}>
                <ListItem>
                  <ListItemText primary="A: Lost category equal to found category" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="B: Lost description & found description partial match" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="C: Lost description & found description NO match" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="D: Lost location & found location partial match" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="E: Lost location & found location exact match" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="F: Lost location & found location NO match" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="G: Lost date & found date within 1 day" />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <List className={classes.ListItems}>
                <ListItem>
                  <ListItemText primary="H: Lost date & found date less than 3 days apart" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="I: Lost date & found date MORE than 7 days apart" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="J: Lost by room number & found location are exact match" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="K: Lost by room number & found location are partial match" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="L: Found date is between lost arrival date & lost departure date" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="M: Found date is NOT between lost arrival date & lost departure date" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          onClick={() => handleModal(false)}
          color="primary"
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RuleSet;

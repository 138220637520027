import React, { useState, useEffect } from "react";
import moment from "moment";
import CustomDateRange from "../../../components/form-components/CustomDateRange";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { PoiEncounterMap } from "../../../api";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import { sova } from "../../../config";
const EncounterMap = ({ encounterMap, encounterMapClose }) => {
  const [locations, setLocations] = useState([
    // { lat: 37.7749, lng: -122.4194, title: "San Francisco" },
    // { lat: 40.7128, lng: -74.006, title: "New York" },
    // { lat: 51.5074, lng: -0.1278, title: "London" },
  ]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [fadebox, setFadedBox] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("01/01/2023");
  const [endDateToFilter, setEndDateToFilter] = useState("02/07/2023");

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter("01/01/2023");
    setEndDateToFilter("07/02/2023");
  };

  const mapData = () => {
    let payload = {
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
    };
    PoiEncounterMap(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
          setLocations(res.data);
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    mapData();
  }, []);

  useEffect(() => {
    mapData();
  }, [isFilter]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
  });

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps...";

  return (
    <>
      <Dialog
        open={encounterMap}
        onClose={encounterMapClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          },
        }}
        aria-labelledby="poiWatch-title"
        aria-describedby="poiWatch-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
          id="poiWatch-title"
        >
          <Typography variant="h6" component="div">
            Encounter Locations
          </Typography>
          <div className="searchTool">
            <Box
              mt={3}
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <TextField
                sx={{
                  width: {
                    sm: "80%",
                  },
                }}
                id="dateRange"
                label="Date range"
                autoComplete="off"
                value={
                  startDateToFilter && endDateToFilter
                    ? moment(startDateToFilter).format("MM/DD/YYYY") +
                      " - " +
                      moment(endDateToFilter).format("MM/DD/YYYY")
                    : ""
                }
                onClick={() => setFadedBox((prev) => !prev)}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Select date range"
              />
              <div>
                {isFilter ? (
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      width: "100px",
                      marginRight: { lg: "50px" },
                      marginLeft: "-3px",
                    }}
                    onClick={() => {
                      resetFilter();
                      setIsFilter(false);
                    }}
                  >
                    Clear Filter
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      width: "100px",
                      marginRight: { lg: "50px" },
                      marginLeft: "-3px",
                    }}
                    onClick={() => {
                      setIsFilter(true);
                    }}
                  >
                    Search
                  </Button>
                )}
              </div>
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                  }}
                  selectedStart={
                    startDateToFilter ? new Date(startDateToFilter) : null
                  }
                  selectedEnd={
                    endDateToFilter ? new Date(endDateToFilter) : null
                  }
                />
              )}
            </Box>
          </div>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={encounterMapClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <GoogleMap
            id="map"
            mapContainerStyle={{
              height: "400px",
              width: "100%",
            }}
            zoom={16}
            center={{ lat: locations[0]?.lat, lng: locations[0]?.lng }}
            options={{ mapTypeId: "satellite" }}
          >
            {locations.map((location, index) => (
              <Marker
                key={index}
                position={location}
                icon={{
                  url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                  scaledSize: new window.google.maps.Size(30, 30),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                }}
                title={location.title}
                onClick={() => {
                  setSelectedLocation(location);
                }}
              />
            ))}
            {selectedLocation && (
              <InfoWindow
                position={selectedLocation}
                onCloseClick={() => {
                  setSelectedLocation(null);
                }}
              >
                <div
                  style={{
                    minWidth: "320px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ paddingRight: "12px" }}>
                    <p>
                      Name:{selectedLocation.first_name}{" "}
                      {selectedLocation.last_name}
                    </p>
                    <p>Alias: {selectedLocation.poi_alias}</p>
                    <p>Location: {selectedLocation.property}</p>
                    <p>Date: {selectedLocation.encounter_date}</p>
                    <p>Resolution:{selectedLocation.eResolution}</p>
                  </div>
                  <div>
                    <img
                      src={selectedLocation.front_face}
                      alt="Encounter Image"
                    />
                  </div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EncounterMap;

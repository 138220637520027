import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import {
  GoogleMap,
  Autocomplete,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { sova } from "../../config";
import { useSelector } from "react-redux";
import {
  GetAllTour,
  GetCheckPointList,
  ViewTourCheckpoint,
  GetTaskButtonList,
  GetTaskButton,
  LoadChildList,
  getLocationLatLong,
  UpdateLatLong,
} from "../../api";
import { handleApiResonseErrors } from "../../helper/helper";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Box,
  Fade,
  TextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  Tabs,
  Tab,
  Stack,
  ListItem as MuiListItem,
  ListItemText,
  ListItemButton,
  List as MuiList,
} from "@mui/material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(TextField)`
  width: 100%;
`;

const ListItem = styled(ListItemButton)`
  padding: 0px;
`;

const List = styled(MuiList)`
  margin-top: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: #7aa8ff0d;
  border-radius: 5px;
`;
const useStyles = makeStyles((theme) => ({
  filterList: {
    maxHeight: "150px",
    overflowY: "auto",
  },
}));
const Image = styled.img`
  width: 100%;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledBox = styled((props) => <Paper {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .Mui-selected": {
      backgroundColor: "#7aa8ff0d",
    },
    "& .MuiList-root::-webkit-scrollbar": {
      /* display: "none", */
    },
  },
}));
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function GeoLocateFunction() {
  const [TourLists, setTourLists] = useState([]);
  const [getTaskButton, setGetTaskButton] = useState([]);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [mapInput, setMapInput] = useState("");
  const [selectedPlace, setSelectedPlace] = useState({
    lat: 37.773972,
    lng: -122.431297,
  });

  const location = useSelector((state) => state.login.Location);

  const getAllTour = () => {
    GetAllTour()
      .then((response) => {
        setTourLists(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    GetTaskButtonList()
      .then((response) => {
        console.log("button list", response.data);
        setGetTaskButton(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [checked, setChecked] = useState(false);
  const [childList, setChildList] = useState([]);
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      LoadChildList()
        .then((res) => {
          if (res.statusCode === 200) {
            setChildList(res.data);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [searchInput, setSearchInput] = useState("");
  const [searchCheckpoint, setSearchCheckpoint] = useState("");
  const [searchTask, setSearchTask] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkpointList, setCheckpointList] = useState([]);

  const handleList = (id) => {
    GetCheckPointList(id)
      .then((response) => {
        setCheckpointList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tagId, setTagId] = useState("");
  const [viewCheckpoint, setViewCheckpoint] = useState({});
  const handleListCheckpoint = (id, tourId) => {
    let payload = {
      tour_id: tourId,
      tag_id: id,
    };
    setLoading(true);
    ViewTourCheckpoint(payload)
      .then((response) => {
        if (response.data.lat && response.data.long) {
          setLat(parseFloat(response.data.lat));
          setLng(parseFloat(response.data.long));
        } else {
          setLat(parseFloat(locationData.lat));
          setLng(parseFloat(locationData.long));
        }
        setViewCheckpoint(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleListTaskCheckpoint = (tagId, tourId, lat, long) => {
    console.log("done");
    setLoading(true);
    setLat(parseFloat(lat));
    setLng(parseFloat(long));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const [taskButton, setTaskButton] = useState([]);
  const handleListTask = (id) => {
    GetTaskButton(id)
      .then((response) => {
        setTaskButton(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onMarkerDragEnd = (e) => {
    console.log(e.latLng.lat(), e.latLng.lng());
    let payload = {
      tag_id: tagId,
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    UpdateLatLong(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("success");
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLocationLatLng();
    getAllTour();
  }, []);

  const getLocationLatLng = () => {
    let id = location ? location.LocationID : "";
    getLocationLatLong(id).then((res) => {
      if (res.statusCode == 200) {
        setLat(res.data.lat);
        setLng(res.data.long);
        setLocationData(res.data);
      }
    });
  };

  const classes = useStyles();

  // google maps
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 37.773972,
    lng: -122.431297,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
    libraries: ["places"],
  });

  const [autocomplete, setAutocomplete] = useState(null);

  const onAutocompleteLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.geometry ? place.geometry.location : null;
      if (location) {
        setSelectedPlace({
          lat: location.lat(),
          lng: location.lng(),
        });
      }
      setMapInput(place.formatted_address || place.name);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <StyledBox>
              <Paper>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Tours" {...a11yProps(0)} />
                    <Tab label="Task Buttons" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <Stack mt={6}>
                    <FormControl>
                      <Input
                        onChange={(event) => setSearchInput(event.target.value)}
                        placeholder="Search tours"
                      />
                    </FormControl>
                    <FormControl mt={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={handleChangeCheck}
                            />
                          }
                          label="Show Login/Logout buttons"
                        />
                      </FormGroup>
                    </FormControl>
                  </Stack>
                  <List
                    className={classes.filterList}
                    style={{ display: checked ? "none" : "block" }}
                  >
                    {TourLists.filter((val) => {
                      if (searchInput == "") {
                        return val;
                      } else if (
                        val.TourName.toLowerCase().includes(
                          searchInput.toLowerCase()
                        )
                      ) {
                        return val;
                      }
                    }).map((data) => {
                      return (
                        <ListItem>
                          <ListItemButton
                            onClick={() => handleList(data.TourID)}
                          >
                            <ListItemText
                              primary={data.TourName}
                              value={data.TourID}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Stack mt={6}>
                    <FormControl>
                      <TextField
                        onChange={(event) => setSearchTask(event.target.value)}
                        placeholder="Search tasks"
                      />
                    </FormControl>
                  </Stack>
                  <List className={classes.filterList}>
                    {getTaskButton
                      .filter((val) => {
                        if (searchTask == "") {
                          return val;
                        } else if (
                          val.TaskDescription.toLowerCase().includes(
                            searchTask.toLowerCase()
                          )
                        ) {
                          return val;
                        }
                      })
                      .map((data) => {
                        if (data.TaskDescription == "") {
                          return;
                        } else {
                          return (
                            <ListItem>
                              <ListItemButton
                                onClick={() => handleListTask(data.TaskID)}
                              >
                                <ListItemText
                                  primary={data.TaskDescription}
                                  value={data.TaskID}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        }
                      })}
                  </List>
                </TabPanel>
              </Paper>
            </StyledBox>
          </CardContent>
        </Card>
        <Card mt={3}>
          <CardContent>
            <StyledBox>
              <Paper>
                {value === 0 ? (
                  <Stack>
                    <Typography variant="span" component="h4">
                      Checkpoints
                    </Typography>
                    <FormControl mt={3}>
                      <TextField
                        onChange={(event) =>
                          setSearchCheckpoint(event.target.value)
                        }
                        placeholder="Search checkpoints"
                      />
                    </FormControl>
                    {checked ? (
                      <List className={classes.filterList}>
                        {childList
                          .filter((val) => {
                            if (searchCheckpoint == "") {
                              return val;
                            } else if (
                              val.TagDescription.toLowerCase().includes(
                                searchCheckpoint.toLowerCase()
                              )
                            ) {
                              return val;
                            }
                          })
                          .map((data) => {
                            return (
                              <ListItem>
                                <ListItemButton
                                  onClick={() =>
                                    handleListTaskCheckpoint(
                                      data.tag_id,
                                      data.tour_id,
                                      data.gpsLatitude,
                                      data.gpsLongitude
                                    )
                                  }
                                >
                                  <ListItemText
                                    primary={data.TagDescription}
                                    value={data.tag_id}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>
                    ) : (
                      <List className={classes.filterList}>
                        {checkpointList
                          .filter((val) => {
                            if (searchCheckpoint == "") {
                              return val;
                            } else if (
                              val.button
                                .toLowerCase()
                                .includes(searchCheckpoint.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .map((data) => {
                            return (
                              <ListItem>
                                <ListItemButton
                                  onClick={() =>
                                    handleListCheckpoint(
                                      data.TagID,
                                      data.tour_id
                                    )
                                  }
                                >
                                  <ListItemText
                                    primary={data.button}
                                    value={data.TagID}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>
                    )}
                  </Stack>
                ) : (
                  <Stack>
                    <Typography variant="span" component="h4">
                      Checkpoints
                    </Typography>
                    <FormControl mt={3}>
                      <TextField
                        onChange={(event) =>
                          setSearchCheckpoint(event.target.value)
                        }
                        placeholder="Search task checkpoints"
                      />
                    </FormControl>
                    <List className={classes.filterList}>
                      {taskButton
                        .filter((val) => {
                          if (searchCheckpoint == "") {
                            return val;
                          } else if (
                            val.tag_description
                              .toLowerCase()
                              .includes(searchCheckpoint.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .map((data) => {
                          if (data.tag_description == "") {
                            return;
                          } else {
                            return (
                              <ListItem>
                                <ListItemButton
                                  onClick={() =>
                                    handleListTaskCheckpoint(
                                      data.tag_id,
                                      data.tour_id,
                                      data.lat,
                                      data.long
                                    )
                                  }
                                >
                                  <ListItemText
                                    primary={data.tag_description}
                                    value={data.tag_id}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          }
                        })}
                    </List>
                  </Stack>
                )}
              </Paper>
            </StyledBox>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={8}>
        <Card>
          <CardContent>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Item sx={{ width: "100%" }}>
                {isLoaded && (
                  <FormControl>
                    <Autocomplete
                      onLoad={onAutocompleteLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <Input
                        id="search-location"
                        value={mapInput}
                        onChange={(e) => setMapInput(e.target.value)}
                        placeholder="Wrong Map ? Enter address"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Autocomplete>
                  </FormControl>
                )}
              </Item>
              <Item>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ whiteSpace: "nowrap", height: "100%" }}
                  onClick={() => setMapInput("")}
                >
                  Clear input
                </Button>
              </Item>
            </Box>
          </CardContent>
          <CardContent style={{ height: "100vh", width: "100%" }}>
            {isLoaded ? (
              <GoogleMap
                id="map"
                mapContainerStyle={{ height: "100%", width: "100%" }}
                zoom={16}
                center={selectedPlace}
                options={{ mapTypeId: "satellite" }}
              >
                <Marker
                  position={selectedPlace}
                  onDragEnd={onMarkerDragEnd}
                  draggable
                />
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  {locations.length <= 0 && (
                    <Typography
                      sx={{
                        position: "absolute",
                        padding: "10px",
                        bottom: "200px",
                        color: "#ed6c02",
                        // zIndex: "9999",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: "0",
                        right: "0",
                        textAlign: "center",
                        background: "white",
                        opacity: "0.6",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Select a tour name in the top box, then a tour checkpoint
                      in the lower box. Next, drag a map pin to the location of
                      the checkpoint and release. GPS data is updated
                      automatically. Repeat for all checkpoints.
                    </Typography>
                  )}
                </Box>
              </GoogleMap>
            ) : (
              <div>Loading...</div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function GeoLocate() {
  return (
    <React.Fragment>
      <Helmet title="Geolocate" />
      <Typography variant="h3" gutterBottom display="inline">
        Geolocate
      </Typography>
      <Divider my={6} />
      <GeoLocateFunction />
    </React.Fragment>
  );
}

export default GeoLocate;

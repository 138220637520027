import { store } from "../redux/store";

// Function to check if the user has permission
const hasPermission = (controller, action) => {
  const state = store.getState();

  const currentUser = state.login.user;
  const permissions = state.permissions.permissions;

  if (currentUser?.user_group_id === 1) {
    return true; // Return true directly if the user is a super admin
  }

  return permissions.some(
    (perm) =>
      perm.controller === controller &&
      perm.action_name === action &&
      perm.user_group.includes(currentUser?.user_group_id)
  );
};

export default hasPermission;

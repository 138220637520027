export default [
  {
    id: "CR Plus & Express",
    path: "/static/img/logs-module/CR-Plus-Express.jpg",
  },
  { id: "Heartsine", path: "/static/img/logs-module/Heartsine.jpg" },
  {
    id: "Lifeline & Reviver",
    path: "/static/img/logs-module/Lifeline-Reviver.jpg",
  },
  { id: "LIFEPAK 500", path: "/static/img/logs-module/LIFEPAK-500.jpg" },
  { id: "LIFEPAK 1000", path: "/static/img/logs-module/LIFEPAK-1000.jpg" },
  { id: "Philips FRX", path: "/static/img/logs-module/Philips-FRX.jpg" },
  { id: "Philips Onsite", path: "/static/img/logs-module/Philips-Onsite.jpg" },
  {
    id: "Powerheart G3 Plus",
    path: "/static/img/logs-module/Powerheart-G3-Plus.jpg",
  },
  { id: "ZOLL AED Plus", path: "/static/img/logs-module/ZOLL-AED-Plus.jpg" },
];

import { format as dateFnsFormat } from "date-fns";

export function formatCurrency(value, option = {}) {
  return new Intl.NumberFormat("en-US", {
    ...{
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    },
    ...option,
  }).format(value);
}

/**
 *
 * @param {string} dateString
 * @returns Formatted date string
 */
export function formatDate(dateString, format = "MMM.dd.yyyy") {
  const date = new Date(dateString);

  return dateFnsFormat(date, format);
}

/**
 *
 * @param {string} amount
 * @returns Formatted percentage value
 */
export function formatPercent(amount) {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(parseFloat(amount));
}

export function calcualateAverage(arr = []) {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
}

export function getCoordsOnCircle(
  angleDeg,
  radiusPx,
  centerVector,
  strokeOffsetPx
) {
  const RADIAN = Math.PI / 180;
  const noOffset = 0;

  const tempX = Math.cos(-RADIAN * angleDeg);
  const tempY = Math.sin(-RADIAN * angleDeg);
  /**
   * This is to compensate for the stroke thickness, and fact that the origin of an
   * element is top-left and we are using left and top to position element.
   */
  const strokeOffsetX = Math.sign(tempX) > 0 ? noOffset : -strokeOffsetPx;
  const strokeOffsetY = Math.sign(tempY) > 0 ? noOffset : -strokeOffsetPx;

  const y = centerVector.y + strokeOffsetY + tempY * radiusPx;
  const x = centerVector.x + strokeOffsetX + tempX * radiusPx;

  return { x, y }; // Position Vector
}

import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { ActiveVisitorCompany } from "../../../api";
const ActiveCompanyToggle = (props) => {
  const { id, value } = props;
  const [active, setActive] = useState(value === "A" ? true : false);
  const handleActive = (status, id) => {
    let payload = {
      id: id,
      status: status === true ? "D" : "A",
    };
    ActiveVisitorCompany(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("success");
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={active}
            onClick={() => {
              setActive(!active);
              handleActive(active, id);
            }}
          />
        }
        label=""
      />
    </>
  );
};

export default ActiveCompanyToggle;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Card,
  Button,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { sova } from "../../../config";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { getLocationLatLong } from "../../../api";

const AddLocationOnMap = ({
  addLocationOnMap,
  addLocationOnMapClose,
  lat,
  setLat,
  lng,
  setLng,
}) => {
  // const [lat, setLat] = useState(null);
  // const [L, setLng] = useState(null);
  const location = useSelector((state) => state.login.Location);

  const onMarkerDragEnd = (e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
  };
  const getLocationLatLng = () => {
    let id = location ? location.LocationID : "";
    if (lat == null && lng == null) {
      getLocationLatLong(id).then((res) => {
        if (res.statusCode == 200) {
          setLat(res.data.lat);
          setLng(res.data.long);
        }
      });
    }
  };
  useEffect(() => {
    getLocationLatLng();
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="add-location-title"
        aria-describedby="add-location-description"
        open={addLocationOnMap}
        onClose={addLocationOnMapClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-location-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add location on map
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addLocationOnMapClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Card sx={{ height: "400px" }} variant="outlined">
            <LoadScript googleMapsApiKey={sova.GoogleApiKey}>
              <GoogleMap
                mapContainerStyle={{
                  height: "100vh",
                  width: "100%",
                }}
                zoom={18}
                center={{
                  lat: lat,
                  lng: lng,
                }}
                options={{ mapTypeId: "satellite" }}
              >
                <Marker
                  key={"123"}
                  position={{
                    lat: lat,
                    lng: lng,
                  }}
                  onDragEnd={(e) => onMarkerDragEnd(e)}
                  draggable={true}
                />
              </GoogleMap>
            </LoadScript>
          </Card>
        </DialogContent>
        <DialogActions sx={{ paddingHorizontal: "24px" }}>
          <Typography>
            Click and drag the map pin to the location of the incident.
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            sx={{ marginLeft: "auto" }}
            onClick={addLocationOnMapClose}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddLocationOnMap;

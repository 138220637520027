import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import AddEditAedPlanDetails from "./AddEditAedPlanDetails";
import AddEditMedicalDirector from "./AddEditMedicalDirector";
import { NavLink } from "react-router-dom";

import {
  AedDashBoard,
  DownloadWrittenPlan,
  DownloadMedicalDirector,
} from "../../../api";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Box,
  Button,
  Grid,
  IconButton,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Paper,
  Link,
} from "@mui/material";
import {
  MonitorHeart as MonitorHeartIcon,
  BusinessCenter as BusinessCenterIcon,
  Groups as GroupsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);

function Item(props) {
  const { sx, ...other } = props;
  return <Box sx={{}} {...other} />;
}
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Dashboard = ({ onExpiredChange, expiredParamVal }) => {
  // Add Edit AED Plan Dialog
  const [addEditAedPlanDetailsDialog, setAddEditAedPlanDetailsDialog] =
    useState(false);
  const addEditAedPlanDetailsDialogOpen = () => {
    setAddEditAedPlanDetailsDialog(true);
  };
  const addEditAedPlanDetailsDialogClose = () => {
    setAddEditAedPlanDetailsDialog(false);
  };
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  // Add Edit Medical Direcotr Dialog
  const [addEditMedicalDirectorDialog, setAddEditMedicalDirectorDialog] =
    useState(false);
  const addEditMedicalDirectorDialogOpen = () => {
    setAddEditMedicalDirectorDialog(true);
  };
  const addEditMedicalDirectorDialogClose = () => {
    setAddEditMedicalDirectorDialog(false);
  };
  const [expiredParam, setExpiredParam] = useState("");
  const [aedEffectiveDate, setAedEffectiveDate] = useState("");

  const [aedData, setAedData] = useState({});
  const aedDashboard = () => {
    AedDashBoard(expiredParam)
      .then((res) => {
        if (res.statusCode === 200) {
          setAedData(res.data);
          setAedEffectiveDate(res.data.locationData.aed_effective_date);
        } else {
          setAedData({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (expiredParamVal) {
      setExpiredParam(expiredParamVal);
    }
  }, [expiredParamVal]);

  useEffect(() => {
    aedDashboard();
  }, [expiredParam]);

  const onButtonClick = () => {
    setLoading(true);
    DownloadWrittenPlan()
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "AED_Written_Plan.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onButtonClick1 = () => {
    setLoading(true);
    DownloadMedicalDirector()
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "AED_Medical_Director.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const calculateAedStatus = (aedEffectiveDate) => {
    const dStart = new Date(aedEffectiveDate);
    const dEnd = new Date();
    const dDiff = Math.floor((dEnd - dStart) / (1000 * 60 * 60 * 24)); // Calculate difference in days
    let aedColor, aedText;

    if (dDiff > 365) {
      aedColor = "#d80202";
      aedText = "Expired";
    } else if (dDiff >= 270 && dDiff <= 365) {
      aedColor = "#ef8b00";
      aedText = "Expiring Soon";
    } else {
      aedColor = "#13870d";
      aedText = "Good";
    }

    return { aedColor, aedText };
  };
  const { aedColor, aedText } = calculateAedStatus(aedEffectiveDate);

  const handleCellClick = (val) => {
    onExpiredChange(val);
    setExpiredParam(val);
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={9} alignSelf="center">
          <Typography variant="h4" component="div" gutterBottom>
            Written Plan Status:
            <span style={{ color: aedColor }}>{aedText}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} alignSelf="center">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Item sx={{ width: "100%", maxWidth: "180px" }}>
              <Typography>Written Plan -</Typography>
            </Item>
            <Item>
              <Button
                color="primary"
                sx={{ minWidth: "33px", padding: 0 }}
                onClick={addEditAedPlanDetailsDialogOpen}
              >
                Edit
              </Button>
              <Button
                color="primary"
                sx={{ minWidth: "33px", padding: 0 }}
                onClick={onButtonClick}
              >
                View
              </Button>
            </Item>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Item sx={{ width: "100%", maxWidth: "180px" }}>
              <Typography>Medical Director -</Typography>
            </Item>
            <Item>
              <Button
                color="primary"
                sx={{ minWidth: "33px", padding: 0 }}
                onClick={addEditMedicalDirectorDialogOpen}
              >
                Edit
              </Button>
              <Button
                color="primary"
                sx={{ minWidth: "33px", padding: 0 }}
                onClick={onButtonClick1}
              >
                View
              </Button>
            </Item>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} md={4} sx={{ display: "flex" }}>
            <Card variant="outlined" sx={{ flex: 1 }}>
              <CardHeader
                title="AED's"
                action={
                  <IconButton color="primary" sx={{ cursor: "default" }}>
                    <MonitorHeartIcon />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ p: 0 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Total AED's:</TableCell>
                      <TableCell>{aedData.aed}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>AED's with expired battery or pads:</TableCell>
                      <TableCell
                        style={{
                          color: aedData.expiredAED > 0 ? "#d80202" : "#000000",
                        }}
                      >
                        {aedData.expiredAED > 0 ? aedData.aed : 0}
                        {/* Made cahnge as per v1 */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Batteries or pads expiring in the next 90 days:
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            aedData.expiredThreeAED > 0 ? "#ef8b00" : "#000000",
                        }}
                      >
                        {aedData.expiredThreeAED}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>AED's in good condition:</TableCell>
                      <TableCell>{aedData.goodStandAED}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: "flex" }}>
            <Card variant="outlined" sx={{ flex: 1 }}>
              <CardHeader
                title="Maintenance"
                action={
                  <IconButton color="primary" sx={{ cursor: "default" }}>
                    <BusinessCenterIcon />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ p: 0 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Inspections completed within the last 30 days:
                      </TableCell>
                      <TableCell>{aedData.pastMonthInspection}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        AED's visually inspected within the last 24 hours:
                      </TableCell>
                      <TableCell>{aedData.pastDayInspection}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>AED's due for monthly inspection:</TableCell>
                      <TableCell
                        style={{
                          color:
                            aedData.comingMonthInspection > 0
                              ? "#d80202"
                              : "#000000",
                        }}
                      >
                        {aedData.comingMonthInspection}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: "flex" }}>
            <Card variant="outlined" sx={{ flex: 1 }}>
              <CardHeader
                title="Trained Responders"
                action={
                  <IconButton color="primary" sx={{ cursor: "default" }}>
                    <GroupsIcon />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ p: 0 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Total Responders:</TableCell>
                      <TableCell
                        onClick={() => handleCellClick("")}
                        sx={{ cursor: "pointer" }}
                      >
                        {aedData.responderCount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Responders with expired training:</TableCell>
                      <TableCell
                        style={{
                          color:
                            aedData.expiredResponderCount > 0
                              ? "#d80202"
                              : "#000000",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCellClick("?expired=today")}
                      >
                        {aedData.expiredResponderCount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Training expiring within 90 days:</TableCell>
                      <TableCell
                        style={{
                          color: aedData.expiringResponderCount
                            ? "#ef8b00"
                            : "#000000",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCellClick("?expired=90_days")}
                      >
                        {aedData.expiringResponderCount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Responders in good condition:</TableCell>
                      <TableCell
                        onClick={() =>
                          handleCellClick("?expired=3_months_later")
                        }
                        sx={{ cursor: "pointer" }}
                      >
                        {aedData.goodResponder}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {addEditAedPlanDetailsDialog && (
        <AddEditAedPlanDetails
          addEditAedPlanDetailsDialog={addEditAedPlanDetailsDialog}
          setAddEditAedPlanDetailsDialog={setAddEditAedPlanDetailsDialog}
          addEditAedPlanDetailsDialogOpen={addEditAedPlanDetailsDialogOpen}
          addEditAedPlanDetailsDialogClose={addEditAedPlanDetailsDialogClose}
        />
      )}
      {addEditMedicalDirectorDialog && (
        <AddEditMedicalDirector
          addEditMedicalDirectorDialog={addEditMedicalDirectorDialog}
          setAddEditAedPlanDetailsDialog={setAddEditAedPlanDetailsDialog}
          addEditMedicalDirectorDialogOpen={addEditMedicalDirectorDialogOpen}
          addEditMedicalDirectorDialogClose={addEditMedicalDirectorDialogClose}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default Dashboard;

import React from "react";
import { Snackbar, Alert } from "@mui/material";
const Toaster = ({ openSnack, handleCloseSnack, msg }) => {
  return (
    <Snackbar
      open={openSnack}
      autoHideDuration={3000}
      onClose={handleCloseSnack}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        onClose={handleCloseSnack}
        severity="success"
        variant="filled"
        sx={{ width: "100%" }}
      >
        {msg}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;

import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { useField } from "formik";

const CustomTextField = ({ name, startAdornment = null, ...otherProps }) => {
  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    InputProps: {},
  };

  if (startAdornment) {
    configTextfield.InputProps.startAdornment = (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    );
  }

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return <TextField {...configTextfield} />;
};

export default CustomTextField;

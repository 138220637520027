import React from "react";
import RecipientsDialog from "./RecipientsDialog";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl as MuiFormControl,
  Box,
  FormControlLabel,
  Checkbox,
  Card,
  TextField,
  Grid,
  formLabelClasses,
} from "@mui/material";
import { spacing } from "@mui/system";

import { Cancel as CancelIcon } from "@mui/icons-material";

const LivePlusDialog = ({
  livePlusDilaog,
  setLivePlusDilaog,
  livePlusDilaogOpen,
  livePlusDilaogClose,
}) => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [recipientsDilaog, SetrecipientsDilaog] = React.useState(false);
  const recipientsDilaogOpen = () => {
    SetrecipientsDilaog(true);
  };
  const recipientsDilaogClose = () => {
    SetrecipientsDilaog(false);
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
        open={livePlusDilaog}
        onClose={livePlusDilaogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h6" ml={4}>
            EMERGENCY NOTIFICATION SYSTEM
          </Typography>

          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setLivePlusDilaog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              textAlign: "center",
              marginTop: "8px",
            }}
          >
            <Button variant="contained" onClick={recipientsDilaogOpen}>
              Recipients(4)
            </Button>
          </Box>
          <FormControl mt={3}>
            <InputLabel id="demo-select-small">Select template</InputLabel>
            <Select
              labelId="department"
              id="demo-select-small"
              label="Select template"
              value={age}
              onChange={handleChange}
              placeholder="Select template(optional)"
            >
              <MenuItem value="disables value">
                Select template(optional)
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            sx={{ float: "right" }}
            control={<Checkbox defaultChecked />}
            label="Send automated phone calls"
          />
          <Card
            variant="outlined"
            sx={{
              padding: "16px",
            }}
          >
            <TextField
              sx={{
                width: "280px",
                marginLeft: "20px",
              }}
              id="outlined-multiline-static"
              label="Write emergency message here..."
              multiline
              rows={4}
            />
          </Card>
          <Grid container spacing={4}>
            <Grid
              item
              xs={6}
              mt={5}
              sx={{
                textAlign: "center",
              }}
            >
              <Button variant="outlined" ml={5}>
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              mt={5}
              sx={{
                textAlign: "center",
              }}
            >
              <Button variant="contained">Send</Button>
            </Grid>
            <Typography
              variant="caption"
              sx={{
                textAlign: "center",

                marginTop: "10px",
              }}
            >
              This emergency notification should not be used as replacement for
              2-way radio or others form of relateable communication as it
              requires a computer to send messages.Some delay in transmission
              can be excepted.
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={livePlusDilaogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {recipientsDilaog && (
        <RecipientsDialog
          recipientsDilaog={recipientsDilaog}
          SetrecipientsDilaog={SetrecipientsDilaog}
          recipientsDilaogOpen={recipientsDilaogOpen}
          recipientsDilaogClose={recipientsDilaogClose}
        />
      )}
    </>
  );
};

export default LivePlusDialog;

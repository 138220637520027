import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import VehicleImage from "./VehicleImage";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Grid,
  Typography,
  Button,
  Tab,
  Box,
  Avatar as MuiAvatar,
  Tooltip,
} from "@mui/material";
const Avatar = styled(MuiAvatar)`
  width: 90px;
  height: 90px;
  object-fit: cover;
`;

const BigAvatar = styled(Avatar)`
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin: 30px auto 0;
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  VerticalTab: {
    "& .MuiTabs-flexContainer": {
      display: "block",
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "3px",

      "& .MuiTab-root": {
        display: "block",
        width: "105px",
        height: "105px",
        marginLeft: "auto",
        marginBottom: "8px",
        border: "1px solid",
        borderRadius: "10px",
        borderColor:
          theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar-thumb": {
      backgroundColor: "#e0e0e0",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const AddVehicleImage = ({
  frontVehicle,
  leftSideVehicle,
  rightSideVehicle,
  rear,
  interior,
  setLeftSideVehicle,
  setFrontVehicle,
  setRightSideVehicle,
  setRear,
  setInterior,
}) => {
  const [tabValue, setTabValue] = useState("1");
  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const classes = useStyles();
  return (
    <>
      <TabContext value={tabValue}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            <Box>
              <TabPanel value="1">
                <VehicleImage
                  className="poi-img"
                  title="Front"
                  filePath="/static/img/poi/front.webp"
                  uploadedImage={frontVehicle}
                  setUploadedImage={(file) => setFrontVehicle(file)}
                />
              </TabPanel>
              <TabPanel value="2">
                <VehicleImage
                  title="Left Side "
                  filePath="/static/img/poi/left-side.webp"
                  uploadedImage={leftSideVehicle}
                  setUploadedImage={(file) => setLeftSideVehicle(file)}
                />
              </TabPanel>
              <TabPanel value="3">
                <VehicleImage
                  title="Right Side"
                  filePath="/static/img/poi/right-side.png"
                  uploadedImage={rightSideVehicle}
                  setUploadedImage={(file) => setRightSideVehicle(file)}
                />
              </TabPanel>
              <TabPanel value="4">
                <VehicleImage
                  title="Rear"
                  filePath="/static/img/poi/rear.png"
                  uploadedImage={rear}
                  setUploadedImage={(file) => setRear(file)}
                />
              </TabPanel>
              <TabPanel value="5">
                <VehicleImage
                  title="Interior"
                  filePath="/static/img/poi/images.jpeg"
                  uploadedImage={interior}
                  setUploadedImage={(file) => setInterior(file)}
                />
              </TabPanel>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <TabList
              className={classes.VerticalTab}
              onChange={tabChange}
              aria-label="lab API tabs example"
            >
              <Tooltip
                title="Choose clear vehice
               image"
                placement="left"
              >
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          frontVehicle
                            ? frontVehicle
                            : "/static/img/poi/front.webp"
                        }
                      />
                    </>
                  }
                  value="1"
                />
              </Tooltip>
              <Tooltip title="Left side" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          leftSideVehicle
                            ? leftSideVehicle
                            : "/static/img/poi/left-side.webp"
                        }
                      />
                    </>
                  }
                  value="2"
                />
              </Tooltip>
              <Tooltip title="Right side" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          rightSideVehicle
                            ? rightSideVehicle
                            : "/static/img/poi/right-side.png"
                        }
                      />
                    </>
                  }
                  value="3"
                />
              </Tooltip>
              <Tooltip title="Rear" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={rear ? rear : "/static/img/poi/rear.png"}
                      />
                    </>
                  }
                  value="4"
                />
              </Tooltip>
              <Tooltip title="Interior" placement="left">
                <Tab
                  label={
                    <>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          interior ? interior : "/static/img/poi/images.jpeg"
                        }
                      />
                    </>
                  }
                  value="5"
                />
              </Tooltip>
            </TabList>
          </Grid>
        </Grid>
      </TabContext>
    </>
  );
};

export default AddVehicleImage;

import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelection from "../../../components/form-components/CustomSelection";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Accordion as MuiAccordion,
  FormLabel,
  FormControlLabel,
  Tooltip,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AccordionDetails,
  AccordionSummary,
  InputLabel as MuiInputLabel,
  FormHelperText,
  TextareaAutosize as MuiTextareaAutosize,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  ExpandMore as ExpandMoreIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  FormatColorFill as FormatColorFillIcon,
  AddBox as AddBoxIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import states from "../../../helper/states.json";
import {
  YearList,
  MakeList,
  ModelList,
  SearchPlateNumber,
  SaveInvolvedVehicle,
  DriversList,
} from "../../../api";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import CanvasDraw from "react-canvas-draw";
import { DateTimePicker, DatePicker } from "@mui/lab";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Image = styled.img`
  max-width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  object-fit: cover;
`;
const ImageWrapper = styled.div`
  height: 100%;
  &:before {
  }
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const VehicleInvolved = ({
  vehicleInvolved,
  vehicleInvolvedClose,
  incidentId,
  getInvolvedVehicleListing,
  vehicleData,
  vehicleActionStatus,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [vehicleLoader, setVehicleLoader] = useState(false);
  const [updateImgStatus, setUpdateImgStatus] = useState(false);
  const [buttonChange, setButtonChange] = useState(false);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToSaveVehicalReport = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "saveVehicleReport" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  let initialValues = {
    year: vehicleData ? (vehicleData.year ? vehicleData.year : "") : "",
    make: vehicleData ? (vehicleData.make ? vehicleData.make : "") : "",
    model: vehicleData ? (vehicleData.model ? vehicleData.model : "") : "",
    vin_number: vehicleData
      ? vehicleData.vin_number
        ? vehicleData.vin_number
        : ""
      : "",
    color: vehicleData ? (vehicleData.color ? vehicleData.color : "") : "",
    plate: vehicleData ? (vehicleData.plate ? vehicleData.plate : "") : "",
    prior_damage: vehicleData
      ? vehicleData.prior_damage
        ? vehicleData.prior_damage
        : ""
      : "",
    attended: vehicleData
      ? vehicleData.attended
        ? vehicleData.attended
        : ""
      : "",
    state: vehicleData ? (vehicleData.state ? vehicleData.state : "") : "",
    driver_name: vehicleData
      ? vehicleData.driver_name
        ? vehicleData.driver_name
        : ""
      : "",
    disposition: vehicleData
      ? vehicleData.deposition
        ? vehicleData.deposition
        : ""
      : "",
    reason: vehicleData ? (vehicleData.reason ? vehicleData.reason : "") : "",
    damaged: vehicleData
      ? vehicleData.damaged
        ? vehicleData.damaged
        : ""
      : "",
    citation_ticket: vehicleData
      ? vehicleData.citation_ticket
        ? vehicleData.citation_ticket
        : ""
      : "",
    driver_owner: vehicleData
      ? vehicleData.driver_owner
        ? vehicleData.driver_owner
        : ""
      : "",
    got_emergency_doctor: vehicleData
      ? vehicleData.got_emergency_doctor
        ? vehicleData.got_emergency_doctor
        : ""
      : "",
    emergency_doctor_detail: vehicleData
      ? vehicleData.emergency_doctor_detail
        ? vehicleData.emergency_doctor_detail
        : ""
      : "",
    property_damaged: vehicleData
      ? vehicleData.property_damaged
        ? vehicleData.property_damaged
        : ""
      : "",
    property_damaged_detail: vehicleData
      ? vehicleData.property_damaged_detail
        ? vehicleData.property_damaged_detail
        : ""
      : "",
    driven_able: vehicleData
      ? vehicleData.driven_able
        ? vehicleData.driven_able
        : ""
      : "",
    driven_able_detail: vehicleData
      ? vehicleData.driven_able_detail
        ? vehicleData.driven_able_detail
        : ""
      : "",
    airbag_deploy: vehicleData
      ? vehicleData.airbag_deploy
        ? vehicleData.airbag_deploy
        : ""
      : "",
    airbag_deploy_detail: vehicleData
      ? vehicleData.airbag_deploy_detail
        ? vehicleData.airbag_deploy_detail
        : ""
      : "",
    car_seats_installed: vehicleData
      ? vehicleData.car_seats_installed
        ? vehicleData.car_seats_installed
        : ""
      : "",
    driver_address: vehicleData
      ? vehicleData.driver_address
        ? vehicleData.driver_address
        : ""
      : "",
    driver_city: vehicleData
      ? vehicleData.driver_city
        ? vehicleData.driver_city
        : ""
      : "",
    driver_state: vehicleData
      ? vehicleData.driver_state
        ? vehicleData.driver_state
        : ""
      : "",
    driver_zip: vehicleData
      ? vehicleData.driver_zip
        ? vehicleData.driver_zip
        : ""
      : "",
    driver_phone: vehicleData
      ? vehicleData.driver_phone
        ? vehicleData.driver_phone
        : ""
      : "",
    driver_relation: vehicleData
      ? vehicleData.driver_relation
        ? vehicleData.driver_relation
        : "Self"
      : "Self",
    vehicle_claimant: vehicleData
      ? vehicleData.vehicle_claimant
        ? vehicleData.vehicle_claimant
        : ""
      : "",
    passengers: vehicleData
      ? vehicleData.passengers
        ? vehicleData.passengers
        : ""
      : "",
    passengers_data: vehicleData
      ? vehicleData.passenger
        ? vehicleData.passenger
        : [
            {
              first_name: "",
              last_name: "",
              age: "",
            },
          ]
      : [
          {
            first_name: "",
            last_name: "",
            age: "",
          },
        ],
    // p_first_name: "",
    // p_last_name: "",
    // p_age: "",
    insurance_company: vehicleData
      ? vehicleData.insurance_company
        ? vehicleData.insurance_company
        : ""
      : "",
    policy_number: vehicleData
      ? vehicleData.policy_number
        ? vehicleData.policy_number
        : ""
      : "",
    claim_number: vehicleData
      ? vehicleData.claim_number
        ? vehicleData.claim_number
        : ""
      : "",
    notes: vehicleData ? (vehicleData.notes ? vehicleData.notes : "") : "",
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    year: Yup.string().required("Please select year"),
    make: Yup.string().required("Please select make"),
    model: Yup.string().required("Please select model"),
    color: Yup.string().required("Please enter color"),
    plate: Yup.string().required("Please enter licence plate"),
    state: Yup.string().required("Please select state"),
    driver_name: Yup.string().required("Please select driver name"),
    reason: Yup.string().required("Please select reason of involvement"),
    damaged: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    citation_ticket: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    driver_owner: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    got_emergency_doctor: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    property_damaged: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    driven_able: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No", "Unknown"]),
    airbag_deploy: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    car_seats_installed: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    passengers: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    passengers_data: Yup.array().when(["passengers"], (same, schema) => {
      return same === "No"
        ? schema
        : schema
            .of(
              Yup.object().shape({
                first_name: Yup.string().required("Please enter first name"),
                last_name: Yup.string().required("Please enter last name"),
                age: Yup.string().required("Please enter age"),
              })
            )
            .min(1, "Please select one passengers");
    }),
    driver_phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      // .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setVehicleLoader(true);
      let formData = {
        id: vehicleData ? (vehicleData.id ? vehicleData.id : null) : null,
        incident_id: incidentId,
        year: values.year,
        make: values.make,
        model: values.model,
        vin_number: values.vin_number,
        color: values.color,
        plate: values.plate,
        prior_damage: values.prior_damage,
        attended: values.attended,
        state: values.state,
        driver_name: values.driver_name,
        deposition: values.disposition,
        reason: values.reason,
        damaged: values.damaged,
        v_image: canvasImg,
        citation_ticket: values.citation_ticket,
        driver_owner: values.driver_owner,
        got_emergency_doctor: values.got_emergency_doctor,
        emergency_doctor_detail: values.emergency_doctor_detail,
        property_damaged: values.property_damaged,
        property_damaged_detail: values.property_damaged_detail,
        driven_able: values.driven_able,
        driven_able_detail: values.driven_able_detail,
        airbag_deploy: values.airbag_deploy,
        airbag_deploy_detail: values.airbag_deploy_detail,
        car_seats_installed: values.car_seats_installed,
        driver_address: values.driver_address,
        driver_city: values.driver_city,
        driver_state: values.driver_state,
        driver_zip: values.driver_zip,
        driver_phone: values.driver_phone,
        driver_relation: values.driver_relation,
        vehicle_claimant: values.vehicle_claimant,
        passengers: values.passengers,
        passengers_data: values.passengers_data,
        insurance_company: values.insurance_company,
        policy_number: values.policy_number,
        claim_number: values.claim_number,
        notes: values.notes,
      };
      SaveInvolvedVehicle(formData)
        .then((res) => {
          setVehicleLoader(false);
          if (res.statusCode === 200) {
            getInvolvedVehicleListing();
            dispatch(getSnackMsg(res.message));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            vehicleInvolvedClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setVehicleLoader(false);
          console.log(err);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
      // await
      //   timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      setVehicleLoader(false);
      console.log(error);
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  const attended = [
    { key: "Person(s) outside vehicle", value: "outside" },
    { key: "Person(s) inside vehicle", value: "inside" },
    { key: "No person(s) present near vehicle", value: "no" },
  ];

  const dispositionOptions = [
    { key: "Vehicle tires marked", value: "Vehicle tires marked" },
    { key: "Warning placed on vehicle", value: "Warning placed on vehicle" },
    {
      key: "Final warning before towing",
      value: "Final warning before towing",
    },
    {
      key: "Vehicle moved by order of mgmt",
      value: "Vehicle moved by order of mgmt",
    },
    { key: "Vehicle moved by owner", value: "Vehicle moved by owner" },
    { key: "Vehicle towed/impounded", value: "Vehicle towed/impounded" },
    {
      key: "Vehicle towed by order of mgmt",
      value: "Vehicle towed by order of mgmt",
    },
  ];
  const involvementReason = [
    { key: "Alleged Damage", value: "Alleged Damage" },
    { key: "Assigned parking space", value: "Assigned parking space" },
    {
      key: "Excessive use of guest parking",
      value: "Excessive use of guest parking",
    },
    { key: "Expired permit", value: "Expired permit" },
    {
      key: "Fire lane - no parking any time",
      value: "Fire lane - no parking any time",
    },
    {
      key: "Handicapped parking only",
      value: "Handicapped parking only",
    },
    {
      key: "Loading zone only",
      value: "Loading zone only",
    },
    {
      key: "More than 12` from curb",
      value: "More than 12` from curb",
    },
    {
      key: "No parking any time",
      value: "No parking any time",
    },
    {
      key: "No visible parking permit",
      value: "No visible parking permit",
    },
    {
      key: "Parked for over 72 hours",
      value: "Parked for over 72 hours",
    },
    {
      key: "Parking permit not visible",
      value: "Parking permit not visible",
    },
    {
      key: "Prior warning - Vehicle towed",
      value: "Prior warning - Vehicle towed",
    },
    {
      key: "Vehicle occupies two spaces",
      value: "Vehicle occupies two spaces",
    },
    {
      key: "Visitor parking only",
      value: "Visitor parking only",
    },
    {
      key: "Warning #1 - please comply",
      value: "Warning #1 - please comply",
    },
    {
      key: " Warning #2 - please comply",
      value: " Warning #2 - please comply",
    },
    {
      key: "Warning #3 - please comply",
      value: "Warning #3 - please comply",
    },
    {
      key: "Warning #4 - please comply",
      value: "Warning #4 - please comply",
    },
  ];
  const driverRelationshipToOwner = [
    { key: "Self", value: "Self" },
    { key: "Rental", value: "Rental" },
    { key: "Family Member", value: "Family Member" },
    { key: "Coworker", value: "Coworker" },
    { key: "Other", value: "Other" },
  ];

  //Add passengers
  const [morepassengers, setMorePassengers] = React.useState(0);
  const addMorePassengers = () => {
    let copy = {
      first_name: "",
      last_name: "",
      age: "",
    };
    setPassengerInfo([...passengerInfo, copy]);
    // setMorePassengers(morepassengers + 1);
    // console.log(morepassengers);
  };
  //Remove passengers
  const removePassengers = (index) => {
    // let newArr = passengerInfo.filter((i) => )
    console.log("++++", index);

    let newArr = [];

    for (let i = 0; i < passengerInfo.length; i++) {
      if (i !== index) {
        newArr.push(passengerInfo[i]);
      }
    }
    console.log(newArr, "newArr");
    setPassengerInfo(newArr);
    // setMorePassengers(morepassengers - 1);
    // console.log(morepassengers);
  };

  const handleChangePassengerf_name = (i, event) => {
    let newp = passengerInfo;
    newp[i].first_name = event.target.value;
    console.log("+++", newp);
    setPassengerInfo(newp);
  };
  const handleChangePassengerl_name = (i, event) => {
    let newp = passengerInfo;
    newp[i].last_name = event.target.value;
    console.log("+++", newp);
    setPassengerInfo(newp);
  };
  const handleChangePassengerage = (i, event) => {
    let newp = passengerInfo;
    newp[i].age = event.target.value;
    console.log("+++", newp);
    setPassengerInfo(newp);
  };
  //State for passenger section
  const [passengerInfo, setPassengerInfo] = useState([
    {
      first_name: "",
      last_name: "",
      age: "",
    },
  ]);

  const [yearData, setYearData] = useState([]);
  //method for getting year dropdown
  const getYearList = () => {
    setVehicleLoader(true);
    YearList()
      .then((res) => {
        setVehicleLoader(false);
        if (res.statusCode == 200) {
          setYearData(res.data);
        }
      })
      .catch((err) => {
        setVehicleLoader(false);
        console.log(err);
      });
  };

  const [makeData, setMakeData] = useState([]);
  //Method for getting make from year dropdown
  const getMakeList = (v) => {
    setVehicleLoader(true);
    let payload = {
      year: v,
    };
    MakeList(payload)
      .then((res) => {
        setVehicleLoader(false);
        if (res.statusCode == 200) {
          setMakeData(res.data);
        }
      })
      .catch((err) => {
        setVehicleLoader(false);
        console.log(err);
      });
  };

  const [modelData, setModelData] = useState([]);
  const getModelList = (v) => {
    setVehicleLoader(true);
    let payload = {
      make: v,
    };
    ModelList(payload)
      .then((res) => {
        setVehicleLoader(false);
        if (res.statusCode == 200) {
          setModelData(res.data);
        }
      })
      .catch((err) => {
        setVehicleLoader(false);
        console.log(err);
      });
  };

  const [plateData, setPlateData] = useState("");
  //Method For plate number search
  const getPlateDetail = (pNo) => {
    setVehicleLoader(true);
    SearchPlateNumber(pNo)
      .then((res) => {
        setVehicleLoader(false);
        if (res.statusCode == 200) {
          let data = res.data.map((item) => item.incident_id).toString();
          setPlateData(data);
        }
      })
      .catch((err) => {
        setVehicleLoader(false);
        console.log(err);
      });
  };

  const [driverNamesList, setDriverNamesList] = useState([]);
  const getDriverDetail = () => {
    setVehicleLoader(true);
    DriversList(incidentId)
      .then((res) => {
        setVehicleLoader(false);
        if (res.statusCode == 200) {
          //let data = res.data.map((item) => item.incident_id).toString();
          setDriverNamesList(res.data);
        }
      })
      .catch((err) => {
        setVehicleLoader(false);
        console.log(err);
      });
  };

  //Ref for canvas
  const canvasRef = useRef(null);
  const [canvasImg, setCanvasImg] = useState(null);

  const saveDamagedImage = () => {
    const width = canvasRef.current.props.canvasWidth;
    const height = canvasRef.current.props.canvasHeight;
    const background = canvasRef.current.canvasContainer.children[0];
    const drawing = canvasRef.current.canvasContainer.children[1];

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    // composite now
    canvas.getContext("2d").drawImage(background, 0, 0);
    canvas.getContext("2d").globalAlpha = 1.0;
    canvas.getContext("2d").drawImage(drawing, 0, 0);

    const dataUri = canvas.toDataURL("image/jpeg", 1.0);
    const data = dataUri.split(",")[1];
    const mimeType = dataUri.split(";")[0].slice(5);

    const bytes = window.atob(data);
    const buf = new ArrayBuffer(bytes.length);
    const arr = new Uint8Array(buf);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: mimeType });
    console.log(dataUri);
    setCanvasImg(dataUri);
    // return { blob: blob, dataUri: dataUri };
  };
  useEffect(() => {
    getYearList();
    getDriverDetail();
  }, []);

  useEffect(() => {
    if (vehicleData && vehicleData.year && vehicleData.make) {
      getMakeList(vehicleData.year);
      getModelList(vehicleData.make);
    }
  }, [vehicleData]);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        aria-labelledby="vehicle-involved-title"
        aria-describedby="vehicle-involved-description"
        open={vehicleInvolved}
        onClose={vehicleInvolvedClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="vehicle-involved-title"
        >
          <Typography variant="h4">Vehicle Involved</Typography>
          <DialogActions>
            <IconButton
              onClick={vehicleInvolvedClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            status,
          }) => (
            <>
              <DialogContent>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                      <FormControl mt={3}>
                        <CustomSelection
                          name="year"
                          label="Year *"
                          options={yearData}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          returnOnChange={(evt) => {
                            if (evt.target.value !== "") {
                              getMakeList(evt.target.value);
                              setFieldValue("make", "");
                              setFieldValue("model", "");
                              setModelData([]);
                            }
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl mt={3}>
                        <CustomSelection
                          name="make"
                          label="Make *"
                          options={makeData}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          returnOnChange={(evt) => {
                            if (evt.target.value !== "") {
                              getModelList(evt.target.value);
                              setFieldValue("model", "");
                            }
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl mt={3}>
                        <CustomSelection
                          name="model"
                          label="Model *"
                          options={modelData}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomTextField
                          name="vin_number"
                          label="VIN number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomTextField
                          name="color"
                          label="Color *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder="Blue"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomTextField
                          name="plate"
                          label="License plate *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          inputProps={{ maxLength: 20 }}
                          autoComplete="off"
                          placeholder=""
                          onBlur={(e) => {
                            setPlateData("");
                            if (e.target.value !== "") {
                              getPlateDetail(e.target.value);
                            }
                          }}
                        />
                        {plateData !== "" ? (
                          <FormHelperText
                            sx={{ marginLeft: 0, wordBreak: "break-word" }}
                          >
                            Matching plate found. Contact SOVA to request a copy
                            of these reports. Provide SOVA with the following
                            ID(s): {plateData}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomTextField
                          name="prior_damage"
                          label="Prior damage"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder="Describe if known"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomSelection
                          name="attended"
                          label="Attended"
                          options={attended}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomSelection
                          name="state"
                          label="License state *"
                          options={states}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <InputLabel
                          shrink
                          id="department"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Driver name *
                        </InputLabel>
                        <Select
                          labelId="driver"
                          id="driver-select"
                          name="driver_name"
                          label="Driver name *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.driver_name}
                          onChange={(e) => {
                            setFieldValue("driver_name", e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.driver_name && errors.driver_name
                          )}
                          helperText={touched.driver_name && errors.driver_name}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            Select driver from IP
                          </MenuItem>
                          {driverNamesList.map((data) => {
                            return (
                              <MenuItem value={data.value} key={data.key}>
                                {data.key}
                              </MenuItem>
                            );
                          })}
                          <MenuItem value="unknown">Unknown</MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.driver_name && errors.driver_name}
                        </FormHelperText>
                        {/* <CustomSelection
                          name="driver_name"
                          label="Driver name *"
                          options={driverNamesList}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                        /> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomSelection
                          name="disposition"
                          label="Disposition"
                          options={dispositionOptions}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomSelection
                          name="reason"
                          label="Reason for involvement *"
                          options={involvementReason}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel>Damaged *</FormLabel>
                        <RadioGroup
                          aria-labelledby="damaged"
                          name="damaged"
                          value={values.damaged.toString()}
                          onChange={(e) => {
                            setFieldValue("damaged", e.currentTarget.value);
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.damaged && errors.damaged}
                        </FormHelperText>
                        <FormHelperText
                          error={true}
                          sx={{
                            display: values.damaged === "No" ? "block" : "none",
                          }}
                        >
                          If damaged, choose yes
                        </FormHelperText>
                        {values.damaged === "Yes" ? (
                          <Box>
                            {/* Updated Canvas */}
                            {vehicleData.v_image &&
                            updateImgStatus === false ? (
                              <Box>
                                <Box>
                                  <Image
                                    sx={{
                                      width: "100%",
                                    }}
                                    src={vehicleData.v_image}
                                  />
                                </Box>
                              </Box>
                            ) : (
                              <Box
                              // sx={{
                              //   display:
                              //     values.damaged === "Yes" ? "block" : "none",
                              // }}
                              >
                                <IconButton
                                  onClick={saveDamagedImage}
                                  sx={{ marginBottom: "15px" }}
                                >
                                  <SaveIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    canvasRef.current.undo();
                                  }}
                                  sx={{ marginBottom: "15px" }}
                                >
                                  <UndoIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    canvasRef.current.clear();
                                  }}
                                  sx={{ marginBottom: "15px" }}
                                >
                                  <ClearIcon />
                                </IconButton>
                                <CanvasDraw
                                  imgSrc="/static/img/reports/vehicle.png"
                                  // imgSrc="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
                                  brushColor="rgba(255, 0, 0)"
                                  brushRadius={1}
                                  canvasWidth={350}
                                  canvasHeight={500}
                                  hideGrid={true}
                                  disabled={vehicleActionStatus}
                                  // getSaveData={()=>console.log()}
                                  ref={canvasRef}
                                />
                              </Box>
                            )}
                          </Box>
                        ) : null}
                        {vehicleData.v_image ? (
                          <Box sx={{ marginTop: "15px" }}>
                            {buttonChange == false ? (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setUpdateImgStatus(true);
                                  setButtonChange(true);
                                }}
                              >
                                Update
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="outlined"
                                sx={{ marginLeft: "5px" }}
                                onClick={() => {
                                  setUpdateImgStatus(false);
                                  setButtonChange(false);
                                  setCanvasImg(null);
                                }}
                              >
                                Cancel
                              </Button>
                            )}
                          </Box>
                        ) : null}
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>
                          Citation or ticket issued to injured driver? *
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="ticket-issued"
                          name="citation_ticket"
                          value={values.citation_ticket.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "citation_ticket",
                              e.currentTarget.value
                            );
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.citation_ticket && errors.citation_ticket}
                        </FormHelperText>
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>
                          Was driver the owner of the vehicle? *
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="vehicle-owner"
                          name="driver_owner"
                          value={values.driver_owner.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "driver_owner",
                              e.currentTarget.value
                            );
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.driver_owner && errors.driver_owner}
                        </FormHelperText>
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>
                          Vehicle occupant(s) seek medical treatment? *
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="vehicle-occupants"
                          name="got_emergency_doctor"
                          value={values.got_emergency_doctor.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "got_emergency_doctor",
                              e.currentTarget.value
                            );
                            setFieldValue("emergency_doctor_detail", "");
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.got_emergency_doctor &&
                            errors.got_emergency_doctor}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        sx={{
                          display:
                            values.got_emergency_doctor === "Yes"
                              ? "block"
                              : "none",
                        }}
                        mt={3}
                      >
                        <CustomTextField
                          name="emergency_doctor_detail"
                          autoComplete="off"
                          disabled={vehicleActionStatus}
                          placeholder="Name of doctor, clinic, or hospital"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel>
                          Any property damage caused by this vehicle? *
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="property-damage"
                          name="property_damaged"
                          value={values.property_damaged.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "property_damaged",
                              e.currentTarget.value
                            );
                            setFieldValue("property_damaged_detail", "");
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.property_damaged && errors.property_damaged}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        sx={{
                          display:
                            values.property_damaged === "Yes"
                              ? "block"
                              : "none",
                        }}
                        mt={3}
                      >
                        <CustomTextField
                          name="property_damaged_detail"
                          //   label="Descrbe property damage"
                          //   InputLabelProps={{
                          //     shrink: true,
                          //   }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder="Descrbe property damage"
                        />
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>Vehicle driven off-scene? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="vehicle-driven"
                          name="driven_able"
                          value={values.driven_able.toString()}
                          onChange={(e) => {
                            setFieldValue("driven_able", e.currentTarget.value);
                            setFieldValue("driven_able_detail", "");
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Unknown"
                            value="Unknown"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.driven_able && errors.driven_able}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        sx={{
                          display:
                            values.driven_able === "No" ? "block" : "none",
                        }}
                        mt={3}
                      >
                        <CustomTextField
                          name="driven_able_detail"
                          //   label="Descrbe property damage"
                          //   InputLabelProps={{
                          //     shrink: true,
                          //   }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder="List damage preventing vehicle from being driven"
                        />
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>Did airbags deploy? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="airbags-deployed"
                          name="airbag_deploy"
                          value={values.airbag_deploy.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "airbag_deploy",
                              e.currentTarget.value
                            );
                            setFieldValue("airbag_deploy_detail", "");
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.airbag_deploy && errors.airbag_deploy}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        sx={{
                          display:
                            values.airbag_deploy === "Yes" ? "block" : "none",
                        }}
                        mt={3}
                      >
                        <CustomTextField
                          name="airbag_deploy_detail"
                          //   label="Descrbe property damage"
                          //   InputLabelProps={{
                          //     shrink: true,
                          //   }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder="Which airbags deployed?"
                        />
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>Car seats installed? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="seats-installed"
                          name="car_seats_installed"
                          value={values.car_seats_installed.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "car_seats_installed",
                              e.currentTarget.value
                            );
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.car_seats_installed &&
                            errors.car_seats_installed}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="driver-details-title"
                          id="driver-details-information"
                        >
                          <Typography>Driver Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomTextField
                                  name="driver_address"
                                  label="Driver address"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={vehicleActionStatus}
                                  autoComplete="off"
                                  placeholder="1234 first Ave."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomTextField
                                  name="driver_city"
                                  label="Driver city"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={vehicleActionStatus}
                                  autoComplete="off"
                                  placeholder="Miami"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomTextField
                                  name="driver_state"
                                  label="Driver state"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={vehicleActionStatus}
                                  autoComplete="off"
                                  placeholder="FL"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomTextField
                                  name="driver_zip"
                                  label="Driver zip"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={vehicleActionStatus}
                                  inputProps={{ maxLength: 7 }}
                                  autoComplete="off"
                                  placeholder="xxxxx"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomTextField
                                  name="driver_phone"
                                  label="Driver phone"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={vehicleActionStatus}
                                  inputProps={{ maxLength: 15 }}
                                  autoComplete="off"
                                  placeholder="xxx-xxx-xxxx"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomSelection
                                  name="driver_relation"
                                  label="Driver relationship to owner"
                                  options={driverRelationshipToOwner}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={vehicleActionStatus}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <CustomTextField
                          name="vehicle_claimant"
                          label="Where can claimant's damaged vehicle be seen?"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder="If towed, list tow yard name"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <FormLabel>Passengers? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="passengers"
                          name="passengers"
                          value={values.passengers.toString()}
                          onChange={(e) => {
                            setFieldValue("passengers", e.currentTarget.value);
                            setFieldValue("guest_room_no", "");
                          }}
                          disabled={vehicleActionStatus}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={vehicleActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={vehicleActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.passengers && errors.passengers}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: values.passengers === "Yes" ? "block" : "none",
                      }}
                    >
                      {values.passengers_data.map((item, index) => {
                        return (
                          <Box sx={{ marginBottom: "20px" }}>
                            <Grid container spacing={6}>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                lg={3}
                                order={{ lg: 1, xs: 2 }}
                              >
                                <FormControl>
                                  <TextField
                                    label="First name"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={item.first_name}
                                    fullWidth
                                    onChange={(e) => {
                                      let newp = values.passengers_data;
                                      newp[index].first_name = e.target.value;
                                      setFieldValue("passengers_data", newp);
                                      console.log("+++Error+++", errors);
                                      // handleChangePassengerf_name(index, e);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.passengers_data &&
                                        touched.passengers_data[index]
                                          ?.first_name &&
                                        errors?.passengers_data &&
                                        errors?.passengers_data[index]
                                          ?.first_name
                                    )}
                                    helperText={
                                      touched.passengers_data &&
                                      touched.passengers_data[index]
                                        ?.first_name &&
                                      errors?.passengers_data &&
                                      errors?.passengers_data[index]?.first_name
                                    }
                                    // my={2}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                lg={3}
                                order={{ lg: 2, xs: 3 }}
                              >
                                <FormControl>
                                  <TextField
                                    label="Last name"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={item.last_name}
                                    fullWidth
                                    onChange={(e) => {
                                      let newp = values.passengers_data;
                                      newp[index].last_name = e.target.value;
                                      setFieldValue("passengers_data", newp);
                                      // handleChangePassengerl_name(index, e);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.passengers_data &&
                                        touched.passengers_data[index]
                                          ?.last_name &&
                                        errors?.passengers_data &&
                                        errors?.passengers_data[index]
                                          ?.last_name
                                    )}
                                    helperText={
                                      touched.passengers_data &&
                                      touched.passengers_data[index]
                                        ?.last_name &&
                                      errors?.passengers_data &&
                                      errors?.passengers_data[index]?.last_name
                                    }
                                    // my={2}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                lg={5}
                                order={{ lg: 3, xs: 4 }}
                              >
                                <FormControl>
                                  <TextField
                                    label="Age(approx.)"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={item.age}
                                    fullWidth
                                    onChange={(e) => {
                                      let newp = values.passengers_data;
                                      newp[index].age = e.target.value;
                                      setFieldValue("passengers_data", newp);
                                      // handleChangePassengerage(index, e);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.passengers_data &&
                                        touched.passengers_data[index]?.age &&
                                        errors?.passengers_data &&
                                        errors?.passengers_data[index]?.age
                                    )}
                                    helperText={
                                      touched.passengers_data &&
                                      touched.passengers_data[index]?.age &&
                                      errors?.passengers_data &&
                                      errors?.passengers_data[index]?.age
                                    }
                                    // my={2}
                                  />
                                </FormControl>
                              </Grid>
                              {index === 0 ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={1}
                                  textAlign="right"
                                  alignSelf="center"
                                  order={{ lg: 4, xs: 1 }}
                                >
                                  <Tooltip title="Add" placement="top">
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        let copy = {
                                          first_name: "",
                                          last_name: "",
                                          age: "",
                                        };
                                        setFieldValue("passengers_data", [
                                          ...values.passengers_data,
                                          copy,
                                        ]);
                                      }}
                                    >
                                      <AddBoxIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={1}
                                  textAlign="right"
                                  alignSelf="center"
                                  order={{ lg: 4, xs: 1 }}
                                >
                                  <Tooltip title="Delete" placement="top">
                                    <IconButton
                                      color="error"
                                      onClick={() => {
                                        // removePassengers(index);
                                        let newArr = [];
                                        for (
                                          let i = 0;
                                          i < values.passengers_data.length;
                                          i++
                                        ) {
                                          if (i !== index) {
                                            newArr.push(
                                              values.passengers_data[i]
                                            );
                                          }
                                        }
                                        setFieldValue(
                                          "passengers_data",
                                          newArr
                                        );
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        );
                      })}
                      {/* <Box sx={{ marginBottom: "20px" }}>
                        <Grid container spacing={6}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            order={{ lg: 1, xs: 2 }}
                          >
                            <FormControl>
                              <CustomTextField
                                name="first_name"
                                label="First name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            order={{ lg: 2, xs: 3 }}
                          >
                            <FormControl>
                              <CustomTextField
                                name="last_name"
                                label="Last name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            lg={5}
                            order={{ lg: 3, xs: 4 }}
                          >
                            <FormControl>
                              <CustomTextField
                                name="age_approx"
                                label="Age(approx.)"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={1}
                            textAlign="right"
                            alignSelf="center"
                            order={{ lg: 4, xs: 1 }}
                          >
                            <Tooltip title="Add" placement="top">
                              <IconButton
                                color="primary"
                                onClick={addMorePassengers}
                              >
                                <AddBoxIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box> */}
                      {/* {Array.from(Array(morepassengers)).map((c, index) => {
                        return (
                          <Grid container spacing={6}>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              lg={3}
                              order={{ lg: 1, xs: 2 }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="first_name[]"
                                  label="First name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder=""
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              lg={3}
                              order={{ lg: 2, xs: 3 }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="last_name"
                                  label="Last name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder=""
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              lg={5}
                              order={{ lg: 3, xs: 4 }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="age_approx"
                                  label="Age(approx.)"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder=""
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={1}
                              textAlign="right"
                              alignSelf="center"
                              order={{ lg: 4, xs: 1 }}
                            >
                              <Tooltip title="Delete" placement="top">
                                <IconButton
                                  color="error"
                                  onClick={removePassengers}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        );
                      })} */}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomTextField
                          name="insurance_company"
                          label="Insurance company name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder="Gelco"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomTextField
                          name="policy_number"
                          label="Policy number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <CustomTextField
                          name="claim_number"
                          label="Claim number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={vehicleActionStatus}
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <TextAreaControl
                          className={classes.textAreaControl}
                          mt={4}
                        >
                          <FormControl>
                            <InputLabel id="v-status">Notes</InputLabel>
                            <TextareaAutosize
                              name="notes"
                              value={values.notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={vehicleActionStatus}
                              placeholder="Include any additional notes about the vehicle that may be useful for investigation"
                            />
                          </FormControl>
                        </TextAreaControl>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions sx={{ paddingRight: "24px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={vehicleInvolvedClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={
                        vehicleActionStatus || !hasPermissionToSaveVehicalReport
                      }
                      sx={{ marginLeft: "10px" }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </>
          )}
        </Formik>
      </Dialog>
      {vehicleLoader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default VehicleInvolved;

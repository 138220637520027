import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardHeader, CardContent, Typography } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
}));
function KioskSignOut() {
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/visitors/kiosk-mode");
    }, 3000);
    // Clear the timeout when the component unmounts (optional)
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <>
      <Box sx={{ p: 5, my: "auto" }} className={classes.startOverCard}>
        <Card
          sx={{
            maxWidth: "700px",
            mx: "auto",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <CardHeader
            sx={{ textAlign: "center" }}
            title={
              <Typography color="primary">
                You have successfully signed out
              </Typography>
            }
          />
          <CardContent>
            <Typography sx={{ textAlign: "center" }}>
              Returning to main page in few seconds...
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default KioskSignOut;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  Location: localStorage.getItem("location")
    ? JSON.parse(localStorage.getItem("location"))
    : null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = JSON.parse(localStorage.getItem("user"));

      // let locationObject = {
      //   locationId: action.payload.LocationID,
      //   locationName: "A Seattle collection",
      // };
      localStorage.setItem("location", JSON.stringify(action.payload.location));
      state.Location = JSON.parse(localStorage.getItem("location"));
      localStorage.setItem("unmasked", false);
    },
    logout: (state) => {
      state.user = null;
      state.Location = null;
      localStorage.removeItem("user");
      localStorage.removeItem("location");
      localStorage.removeItem("unmasked");
    },
    changeLocation: (state, action) => {
      localStorage.setItem("location", JSON.stringify(action.payload));
      state.Location = JSON.parse(localStorage.getItem("location"));
    },
  },
});

export const { login, logout, changeLocation } = loginSlice.actions;
export default loginSlice.reducer;

import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";

import { getFileBase64 } from "../../../helper/helper";

import {
  Grid,
  Typography,
  Button,
  Box,
  Avatar as MuiAvatar,
} from "@mui/material";
import CameraFeeding from "../../../helper/camera-feeding";
import ImageCropper from "../../../components/form-components/ImageCropper";

const Avatar = styled(MuiAvatar)`
  width: 90px;
  height: 90px;
  object-fit: cover;
`;

const BigAvatar = styled(Avatar)`
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin: 30px auto 0;
`;

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  VerticalTab: {
    "& .MuiTabs-flexContainer": {
      display: "block",
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "3px",
      "& .MuiTab-root": {
        display: "block",
        width: "105px",
        height: "105px",
        marginLeft: "auto",
        marginBottom: "8px",
        border: "1px solid",
        borderRadius: "10px",
        borderColor:
          theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar-thumb": {
      backgroundColor: "#e0e0e0",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const AddPoiImage = ({ title, filePath, uploadedImage, setUploadedImage }) => {
  const uploadedFileRef = useRef();
  const [showWebCam, setShowWebCam] = useState(false);
  const [imageCropperDialog, setImageCropperDialog] = useState(false);

  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };

  const childToParent = (childData) => {
    setUploadedImage(childData);
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
      imageCropperOpen();
    });
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <Box>
            <Typography
              variant="h4"
              component="div"
              textAlign="center"
              gutterBottom
            >
              {title}
            </Typography>
            <BigAvatar
              alt="Selected Image"
              src={uploadedImage ? uploadedImage : filePath}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center", // Center the buttons
              margin: "10px 0 15px",
              gap: "15px",
            }}
          >
            {uploadedImage && (
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onClick={clearSelectedImage}
              >
                Clear
              </Button>
            )}
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              ref={uploadedFileRef}
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className="customChooseImage"
              >
                Choose Image
              </Button>
            </label>
          </Box>
        </Grid>
      </Grid>

      {showWebCam && (
        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}

      {title === "Front Face (Autocrop)" &&
        uploadedImage &&
        imageCropperDialog && (
          <ImageCropper
            imageCropperDialog={imageCropperDialog}
            setImageCropperDialog={setImageCropperDialog}
            imageCropperOpen={imageCropperOpen}
            imageCropperClose={imageCropperClose}
            childToParent={childToParent}
            imageData={uploadedImage}
            dialogHead="Crop Face"
          />
        )}

      {uploadedImage && imageCropperDialog && (
        <ImageCropper
          imageCropperDialog={imageCropperDialog}
          setImageCropperDialog={setImageCropperDialog}
          imageCropperOpen={imageCropperOpen}
          imageCropperClose={imageCropperClose}
          childToParent={childToParent}
          imageData={uploadedImage}
        />
      )}
    </>
  );
};

export default AddPoiImage;

import React, { useEffect, useState, useReducer } from "react";
import styled from "styled-components/macro";
import {
  NavLink,
  useParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { TimePicker } from "@mui/lab";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import StructuredDialog from "../dashboards/Task/StructuredDialog";
import { useSelector } from "react-redux";
import {
  ViewTask,
  GetTaskButton,
  ViewEditSchedule,
  GetScheduleTask,
  ViewTaskButton,
  ViewScheduleTask,
  UpdateTaskStatus,
  UpdateTask,
  DelelteTaskButton,
  DeleteTaskAssignment,
} from "../../api";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  Switch,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  Tabs,
  Tab,
  Menu,
  Chip,
  CircularProgress,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ChangeHistory as ChangeHistoryIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { escapeSelector, event } from "jquery";
import TaskButton from "./TaskButton";
import Schedule from "./Schedule";
import CustomButton from "./CustomButton";
import AddSchedule from "./AddSchedule";
import Toaster from "./Toaster";
import {
  getChecked,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/mainSlice";
import TourSchedule from "./TourSchedule";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LavelTable = styled((props) => <TableContainer {...props} />)(
  ({ theme }) => ({
    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiFormLabel-root": {
        whiteSpace: "nowrap",
        paddingRight: "10px",
        minWidth: "60px",
      },
    },
    "& .MuiTableCell-root": {
      padding: "5px 10px 5px 0px",
      border: "0px",
    },
  })
);
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const StyledBox = styled((props) => <Paper {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .Mui-selected": {
      backgroundColor: "#7aa8ff0d",
    },
    "& .MuiList-root::-webkit-scrollbar": {
      /* display: "none", */
    },
  },
}));
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
//Auto Select
const customNotification = [
  { title: "O1", value: 1 },
  { title: "O2", value: 2 },
  { title: "O3", value: 3 },
  { title: "O4", value: 4 },
  { title: "O5", value: 5 },
];
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(MuiInput)`
  width: 42px;
`;

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const StyledInputSlider = styled((props) => <Paper {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiInput-root::before": {
        borderBottom: "0px",
      },
      "& .MuiInput-root": {
        "& .MuiInput-input": {
          paddingBottom: "0px",
        },
      },
    },
  })
);
const StyledContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#7aa8ff0d",
    padding: "15px",
    marginTop: "10px",
    maxHeight: "542px",
    overflowY: "auto",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      borderBottom: "0px",
      marginBottom: "12px",
      "& .MuiTypography-subtitle2": {
        marginRight: "5px",
      },
      "& .MuiSvgIcon-root": {
        margin: "0px 6px",
        width: "12px",
        height: "12px",
        verticalAlign: "middle",
      },
    },
  },
}));
// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
//For on or off
const CustomSwitchOffOn = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"ON"',
      left: 15,
    },
    "&:after": {
      content: '"OFF"',
      right: "-2px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  })
);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function ColumnLeft() {
  //Activity Points
  const [activityvalue, setValue] = React.useState(0);
  const [task, setTask] = useState({});
  const [hideLevel, setHideLevel] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [dense, setDense] = useState(false);
  const [taskButtonList, setTaskButtonList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [Id, setId] = useState(null);
  const [value, setPanel] = useState(0);
  const [viewTask, setViewTask] = useState({});
  const [singleScheduleTask, setsingleScheduleTask] = useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const count = useAppSelector((state) => state.counter.loader);
  const [modulearray, setmodulearray] = useState([]);
  const [message, setMessage] = useState("");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  // Check for permission for specific actions
  const hasPermissionToDeleteTaskAssignment = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "deleteTaskAssignment" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const hasPermissionToViewScheduleTask = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "viewScheduleTask" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const hasPermissionToViewEditSchedule = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "viewEditSchedule" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const hasPermissionToViewTaskButton = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "viewTaskButton" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );
  const [structuredCommentsOpen, setStructuredCommentsOpen] = useState(false);
  const [structuredLocationOpen, setStructuredLocationOpen] = useState(false);

  const handleStructuredCommentsOpen = () => setStructuredCommentsOpen(true);
  const handleStructuredCommentsClose = () => setStructuredCommentsOpen(false);

  const handleStructuredLocationOpen = () => setStructuredLocationOpen(true);
  const handleStructuredLocationClose = () => setStructuredLocationOpen(false);

  const handlePanel = (event, newTab) => {
    setPanel(newTab);
  };
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  //Hide level 3 method
  const handleHideLevel = (event) => {
    setHideLevel(event.target.checked);
  };
  const handleActivityPoints = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };
  const handleBlur = () => {
    if (activityvalue < 0) {
      setValue(0);
    } else if (activityvalue > 100) {
      setValue(100);
    }
  };
  //Sentiment Score
  const [sentimentscore, setscoreValue] = React.useState(0);
  const handleScores = (event, newValue) => {
    setscoreValue(newValue);
  };
  const handleScoreChange = (event) => {
    setscoreValue(event.target.value === "" ? "" : Number(event.target.value));
  };
  const handleScoreBlur = () => {
    if (sentimentscore < 0) {
      setscoreValue(0);
    } else if (sentimentscore > 100) {
      setscoreValue(100);
    }
  };
  let navigate = useNavigate();
  const { id } = useParams(); //get the task_id
  const [viewSchedule, setViewSchedule] = useState([]);
  const viewEditSchedule = () => {
    ViewEditSchedule(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewSchedule(res.data);
        } else {
          setViewSchedule([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let result = {};
  result = viewSchedule.find((obj) => {
    return obj.type === "level1";
  });
  //get single task
  const taskView = () => {
    ViewTask(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setTask(res.data);
          setLoading(false);

          //get task button list
          GetTaskButton(res.data.task_id)
            .then((res) => {
              if (res.statusCode === 200) {
                setTaskButtonList(res.data);
              } else {
                setTaskButtonList([]);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setTask({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  //  get Schedules List
  const scheduleList = () => {
    GetScheduleTask(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setSchedule(res.data);
        } else {
          setSchedule([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get task button list
  const button = () => {
    GetTaskButton(task.task_id)
      .then((res) => {
        if (res.statusCode === 200) {
          setTaskButtonList(res.data);
        } else {
          setTaskButtonList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  //delete task Schedule
  const deleteSchedule = () => {
    value === 0
      ? DeleteTaskAssignment(Id)
          .then((res) => {
            {
              value === 0 ? scheduleList() : button();
            }
            setOpenDelete(false);
            if (res.statusCode === 200) {
              setOpenSnack(true);
            }
          })
          .catch((err) => {
            console.log(err);
            setOpenDelete(false);
          })
      : DelelteTaskButton(Id)
          .then((res) => {
            {
              value === 0 ? scheduleList() : button();
            }
            setOpenDelete(false);
            if (res.statusCode === 200) {
              setOpenSnack(true);
            }
          })
          .catch((err) => {
            console.log(err);
            setOpenDelete(false);
          });
  };
  //single rcord of task button
  const viewTaskButton = () => {
    ViewTaskButton(Id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewTask(res.data);
        } else {
          setViewTask({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewScheduleTask = () => {
    ViewScheduleTask(Id)
      .then((res) => {
        if (res.statusCode === 200) {
          setsingleScheduleTask(res.data);
          setmodulearray(res.data.weekly_value);
          setMessage(res.data.message);
        } else {
          setsingleScheduleTask({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAuto = (event) => {
    // console.log(event.target.value);
  };
  //useReducer start
  const initialState = { selectedOptions: [] };
  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };

      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.title !== action.payload.title
          ),
        };
      default:
        throw new Error();
    }
  }
  const [state, dispatch1] = useReducer(reducer, initialState);

  const handleChangeAuto = (event, values) => {
    dispatch1({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const removeOption = (id) => {
    dispatch1({ type: "REMOVE_OPTION", payload: { title: id } });
  };
  let addCustomNotify = state.selectedOptions.map((data) => data.value);

  useEffect(() => {
    viewEditSchedule();
    taskView();
    scheduleList();
    // button();
    setLoading(true);
  }, []);

  const {
    TaskDescription,
    Assignable,
    critical_task,
    contact_ohm,
    comment_required,
    notify_email,
    web_notify,
    send_shift_report,
    sentiment_assign,
    task_comment,
    sentimentIcon,
    sentimentScore,
    activity_point,
    ir_required,
    passdown_req,
    unavailable_started,
    lvl1_label,
    lvl2_label,
    lvl3_label,
    Comment_required_lvl,
    comment_required_lvl,
    Notify_required_lvl,
    Notify_lvl1_email,
    Notify_lvl2_email,
    Notify_lvl3_email,
    sub_task_1,
    custom_notify,
    tag,
    structured_comment,
    structured_location,
  } = task;

  const addNotification = () => {
    var siteResult = [];
    if (custom_notify && customNotification !== undefined) {
      custom_notify.forEach((obj) => {
        customNotification.forEach((r) => {
          if (r.value === obj) {
            siteResult.push(r);
          }
        });
      });
    }
    dispatch1({
      type: "SET_SELECTED_OPTIONS",
      payload: {
        options: siteResult,
      },
    });
  };
  useEffect(() => {
    addNotification();
  }, [custom_notify, customNotification]);

  const [checkedComments, setCheckedComments] = useState([]);

  useEffect(() => {
    // Ensure this runs when comment_required_lvl changes
    if (comment_required_lvl) {
      setCheckedComments(comment_required_lvl.split(",").map(Number));
    }
  }, [comment_required_lvl]);

  const handleCheckboxChange = (value) => {
    const currentIndex = checkedComments.indexOf(value);
    const newChecked = [...checkedComments];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedComments(newChecked);
  };

  const initialValues = {
    task_name: TaskDescription ? TaskDescription : "",
    task_type: Assignable ? Assignable : "",
    critical: critical_task ? critical_task : "",
    cohm: contact_ohm ? contact_ohm : "",
    comment: comment_required ? comment_required : "",
    team: notify_email ? notify_email : "",
    send_shift_report: send_shift_report ? send_shift_report : "",
    web_notify: web_notify ? web_notify : "",
    ir_required: ir_required ? ir_required : "",
    collect_sentiemnt: sentiment_assign ? sentiment_assign : "",
    passdown_req: passdown_req ? passdown_req : "",
    unavailable_started: unavailable_started ? unavailable_started : "",
    task_comment: task_comment ? task_comment : "",
    task_sentiments: sentimentIcon ? sentimentIcon : "",
    sentiment_scores: sentimentScore ? sentimentScore : "",
    tag: tag ? tag : "",
    activity_point: activity_point ? activity_point : "",
    level_one: lvl1_label ? lvl1_label : "",
    level_two: lvl2_label ? lvl2_label : "",
    level_three: lvl3_label ? lvl3_label : "",
    level_field_one: Notify_lvl1_email ? Notify_lvl1_email : "",
    level_field_two: Notify_lvl2_email ? Notify_lvl2_email : "",
    level_field_three: Notify_lvl3_email ? Notify_lvl3_email : "",
    hide_level: sub_task_1 ? sub_task_1 : "",
    // comment_level: checkedComments ? checkedComments : "",
    structured_comment: structured_comment ? structured_comment : "",
    structured_location: structured_location ? structured_location : "",
  };
  const validationSchema = Yup.object().shape({
    task_name: Yup.string().required("Please enter task name"),
    level_field_one: Yup.string().email("Invalid email format"),
    level_field_two: Yup.string().email("Invalid email format"),
    level_field_three: Yup.string().email("Invalid email format"),
  });

  const handleToggle = (key, value) => {
    let payload = {
      type: key,
      status: value,
    };
    setLoading(true);
    UpdateTaskStatus(task.task_id, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          dispatch(getMsg("Task Updated Successfully!"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          setLoading(false);
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(getMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      const commentValues = checkedComments ? checkedComments.join(",") : "";

      let payload = {
        TaskDescription: values.task_name,
        assignable: values.task_type,
        task_comment: values.task_comment,
        activity_point: activityvalue === 0 ? activity_point : activityvalue,
        sentimentIcon: values.task_sentiments,
        sentimentScore: sentimentscore === 0 ? sentimentScore : sentimentscore,
        lvl1_label: values.level_one,
        lvl2_label: values.level_two,
        lvl3_label: values.level_three,
        Notify_lvl1_email: values.level_field_one,
        Notify_lvl2_email: values.level_field_two,
        Notify_lvl3_email: values.level_field_three,
        sub_task_1: values.hide_level,
        custom_notify: addCustomNotify,
        passdown_req: values.passdown_req,
        unavailable_started: values.unavailable_started,
        tag: values.tag,
        comment_required: values.comment_required,
        Comment_required_lvl: commentValues,
        structured_comment: values.structured_comment,
        structured_location: values.structured_location,
      };
      UpdateTask(id, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setLoading(false);
            dispatch(getMsg("Task Updated Successfully!"));
            navigate({
              pathname: "/tasks/task-list",
            });
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            setLoading(false);
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  //
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  //Edit task modal
  const [edittask, setEditTask] = React.useState(false);
  const taskModalOpen = () => {
    setEditTask(true);
  };
  const taskModalClose = () => {
    setEditTask(false);
  };
  //add task Schedule
  const [addSchedule, setAddSchedule] = useState(false);
  const AddScheduleOpen = () => {
    setAddSchedule(true);
  };
  const AddScheduleClose = () => {
    setAddSchedule(false);
  };
  //Custom Button Text Modal
  const [custombuttontext, setCustomButtonText] = React.useState(false);
  const customButtonOpen = () => {
    setCustomButtonText(true);
  };
  const customButtonClose = () => {
    setCustomButtonText(false);
  };
  // Custom options dropdown
  const [customoptions, setCustomOptions] = React.useState("");
  const customOptionsLevel = (event) => {
    setCustomOptions(event.target.value);
  };
  //Edit task button modal
  const [taskbutton, setTaskButton] = React.useState(false);
  const taskButtonOpen = () => {
    setTaskButton(true);
  };
  const taskButtonClose = () => {
    setTaskButton(false);
  };
  const classes = useStyles();
  const [timevalue, setTimeValue] = React.useState(null);

  const [month, setMonth] = React.useState("");

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  // Faded Item
  const [dvalues, setRadio] = useState(singleScheduleTask.schedule_type);
  const handleRadioChange = (event) => {
    setRadio(event.target.value);
  };
  // Add Schedule
  const [addschedule, addScheduleModal] = useState(false);
  const [levelType, setLevelType] = useState("");

  const addScheduleOpen = () => {
    addScheduleModal(true);
  };
  const addScheduleClose = () => {
    addScheduleModal(false);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card mb={6}>
            {loading && (
              <Paper className={classes.fixedLoader}>
                <CircularProgress />
              </Paper>
            )}
            <Toaster
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              message="A total of 1 record(s) have been deleted"
            />
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <TextField
                        id="task-name"
                        name="task_name"
                        label="Task name*"
                        value={values.task_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.task_name && errors.task_name)}
                        helperText={touched.task_name && errors.task_name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Short concise name, no special characters"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">Task type</FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby="task-type"
                          // defaultValue={values.task_type}
                          name="task_type"
                          value={values.task_type.toString()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Tour task"
                            disabled
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="Non-tour task"
                            disabled
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                    <FormControl
                      mt={3}
                      style={{
                        display: values.task_type === "Yes" ? "block" : "none",
                      }}
                    >
                      <FormLabel component="legend">
                        Set custom levels
                      </FormLabel>
                      <Grid container spacing={3} sx={{ marginTop: "0" }}>
                        <Grid item xs={12} lg={4} xl={3}>
                          <TextField
                            id="level-one"
                            name="level_one"
                            label="Level 1"
                            value={values.level_one}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.level_one && errors.level_one
                            )}
                            helperText={touched.level_one && errors.level_one}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Yes"
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} xl={3}>
                          <TextField
                            id="level-two"
                            name="level_two"
                            label="Level 2"
                            value={values.level_two}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.level_two && errors.level_two
                            )}
                            helperText={touched.level_two && errors.level_two}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="No"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={4}
                          xl={3}
                          style={{
                            visibility:
                              values.hide_level === "1" ? "hidden" : "inherit",
                          }}
                        >
                          <TextField
                            id="level-three"
                            name="level_three"
                            label="Level 3"
                            value={values.level_three}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.level_three && errors.level_three
                            )}
                            helperText={
                              touched.level_three && errors.level_three
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="N/A"
                          />
                        </Grid>
                        <Grid item xs={12} lg={12} xl={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="hide_level"
                                value={values.hide_level}
                                checked={values.hide_level == 1}
                                // onChange={handleHideLevel}
                                onChange={(event, checked) =>
                                  setFieldValue(
                                    "hide_level",
                                    checked ? "1" : "0"
                                  )
                                }
                              />
                            }
                            label="Hide level 3"
                          />
                        </Grid>
                        <Grid item xs={12} lg={12} xl={12}>
                          <LavelTable>
                            <TableContainer className="tableContainer">
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      Send email notifications for levels
                                    </TableCell>
                                    <TableCell>Comments</TableCell>
                                    <TableCell>Schedule</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Box>
                                        <Item>
                                          <FormLabel>Level 1</FormLabel>
                                        </Item>
                                        <Item>
                                          <TextField
                                            id="lavel-field"
                                            name="level_field_one"
                                            value={values.level_field_one}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(
                                              touched.level_field_one &&
                                                errors.level_field_one
                                            )}
                                            helperText={
                                              touched.level_field_one &&
                                              errors.level_field_one
                                            }
                                            placeholder="me@you.com"
                                          />
                                        </Item>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="comment_level1"
                                            value="1"
                                            // onChange={handleChange}
                                            onChange={() =>
                                              handleCheckboxChange(1)
                                            }
                                            checked={checkedComments.includes(
                                              1
                                            )}
                                          />
                                        }
                                        label=""
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        cursor: !hasPermissionToViewEditSchedule
                                          ? "not-allowed"
                                          : "pointer",
                                        color: !hasPermissionToViewEditSchedule
                                          ? "#B0B0B0"
                                          : "#0077B5",
                                        pointerEvents:
                                          !hasPermissionToViewEditSchedule
                                            ? "none"
                                            : "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {isValidEmail(values.level_field_one) && (
                                        <span>
                                          {" "}
                                          {/* span is used to wrap the IconButton when it's disabled */}
                                          <IconButton
                                            onClick={() => {
                                              addScheduleOpen();
                                              viewEditSchedule();
                                              setLevelType("level1");
                                            }}
                                            disabled={
                                              !hasPermissionToViewEditSchedule
                                            }
                                          >
                                            <AccessTimeIcon />
                                          </IconButton>
                                        </span>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Box>
                                        <Item>
                                          <FormLabel>Level 2</FormLabel>
                                        </Item>
                                        <Item>
                                          <TextField
                                            id="lavel-field"
                                            name="level_field_two"
                                            value={values.level_field_two}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(
                                              touched.level_field_two &&
                                                errors.level_field_two
                                            )}
                                            helperText={
                                              touched.level_field_two &&
                                              errors.level_field_two
                                            }
                                            placeholder="me@you.com"
                                          />
                                        </Item>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="comment_level2"
                                            value="2"
                                            checked={checkedComments.includes(
                                              2
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(2)
                                            }
                                            // onChange={handleChange}
                                          />
                                        }
                                        label=""
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {isValidEmail(values.level_field_two) && (
                                        <IconButton
                                          onClick={() => {
                                            addScheduleOpen();
                                            viewEditSchedule();
                                            setLevelType("level2");
                                          }}
                                        >
                                          <AccessTimeIcon />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Box>
                                        <Item>
                                          <FormLabel>Level 3</FormLabel>
                                        </Item>
                                        <Item>
                                          <TextField
                                            id="lavel-field"
                                            name="level_field_three"
                                            value={values.level_field_three}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(
                                              touched.level_field_three &&
                                                errors.level_field_three
                                            )}
                                            helperText={
                                              touched.level_field_three &&
                                              errors.level_field_three
                                            }
                                            placeholder="me@you.com"
                                          />
                                        </Item>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="comment_level3"
                                            value="3"
                                            checked={checkedComments.includes(
                                              3
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(3)
                                            }
                                            // onChange={handleChange}
                                          />
                                        }
                                        label=""
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {isValidEmail(
                                        values.level_field_three
                                      ) && (
                                        <IconButton
                                          onClick={() => {
                                            addScheduleOpen();
                                            addScheduleOpen();
                                            viewEditSchedule();
                                            setLevelType("level3");
                                          }}
                                        >
                                          <AccessTimeIcon />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </LavelTable>
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl
                      mt={3}
                      style={{
                        display: values.task_type === "No" ? "block" : "none",
                      }}
                    >
                      <CustomSwitchControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <CustomSwitchYesNo
                                name="critical"
                                value={values.critical}
                                checked={values.critical === "critical"}
                                onChange={(event, checked) => {
                                  setFieldValue(
                                    "critical",
                                    checked ? "critical" : "non-critical"
                                  );
                                  handleToggle(
                                    "critical_task",
                                    checked ? "critical" : "non-critical"
                                  );
                                }}
                              />
                            }
                            label="Critical"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchYesNo
                                name="cohm"
                                value={values.cohm}
                                checked={values.cohm === 1}
                                onChange={(event, checked) => {
                                  setFieldValue("cohm", checked ? 1 : 0);
                                  handleToggle("contact_ohm", checked ? 1 : 0);
                                }}
                              />
                            }
                            label="Contact occupational health manager"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchYesNo
                                name="comment"
                                value={values.comment}
                                checked={values.comment === 1}
                                onChange={(event, checked) => {
                                  setFieldValue("comment", checked ? 1 : 0);
                                  handleToggle(
                                    "comment_required",
                                    checked ? 1 : 0
                                  );
                                }}
                              />
                            }
                            label="Comments required"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchOffOn
                                name="team"
                                value={values.team}
                                checked={values.team === 1}
                                onChange={(event, checked) => {
                                  setFieldValue("team", checked ? 1 : 0);
                                  handleToggle("notify_email", checked ? 1 : 0);
                                }}
                              />
                            }
                            label="Team notify"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchYesNo
                                name="send_shift_report"
                                value={values.send_shift_report}
                                checked={values.send_shift_report === 1}
                                onChange={(event, checked) => {
                                  setFieldValue(
                                    "send_shift_report",
                                    checked ? 1 : 0
                                  );
                                  handleToggle(
                                    "send_shift_report",
                                    checked ? 1 : 0
                                  );
                                }}
                              />
                            }
                            label="Send shift report"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchOffOn
                                name="web_notify"
                                value={values.web_notify}
                                checked={values.web_notify === 1}
                                onChange={(event, checked) => {
                                  setFieldValue("web_notify", checked ? 1 : 0);
                                  handleToggle("web_notify", checked ? 1 : 0);
                                }}
                              />
                            }
                            label="Web notify"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchYesNo
                                name="ir_required"
                                value={values.ir_required}
                                checked={values.ir_required === 1}
                                onChange={(event, checked) => {
                                  setFieldValue("ir_required", checked ? 1 : 0);
                                  handleToggle("ir_required", checked ? 1 : 0);
                                }}
                              />
                            }
                            label="IR required"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchYesNo
                                name="collect_sentiemnt"
                                value={values.collect_sentiemnt}
                                checked={values.collect_sentiemnt === "Y"}
                                onChange={(event, checked) => {
                                  setFieldValue(
                                    "collect_sentiemnt",
                                    checked ? "Y" : "N"
                                  );
                                  handleToggle(
                                    "sentiment_assign",
                                    checked ? "Y" : "N"
                                  );
                                }}
                              />
                            }
                            label="Collect sentiment"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchYesNo
                                name="passdown_req"
                                value={values.passdown_req}
                                checked={values.passdown_req === "Y"}
                                onChange={(event, checked) => {
                                  setFieldValue(
                                    "passdown_req",
                                    checked ? "Y" : "N"
                                  );
                                  handleToggle(
                                    "passdown_req",
                                    checked ? "Y" : "N"
                                  );
                                }}
                              />
                            }
                            label="Passdown required"
                          />
                          <FormControlLabel
                            control={
                              <CustomSwitchYesNo
                                name="unavailable_started"
                                value={values.unavailable_started}
                                checked={values.unavailable_started === "Y"}
                                onChange={(event, checked) => {
                                  setFieldValue(
                                    "unavailable_started",
                                    checked ? "Y" : "N"
                                  );
                                  handleToggle(
                                    "unavailable_started",
                                    checked ? "Y" : "N"
                                  );
                                }}
                              />
                            }
                            label="Unavailable when started"
                          />
                        </FormGroup>
                      </CustomSwitchControl>
                      <FormControl mt={6}>
                        <TextField
                          id="task-comment"
                          label="Task comment"
                          name="task_comment"
                          value={values.task_comment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Standard comment to populate via dispatch"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <FormLabel>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                          >
                            Activity points
                            {
                              <StyledInputSlider>
                                <Paper>
                                  <Input
                                    name="activity_point"
                                    value={
                                      activityvalue === 0
                                        ? values.activity_point
                                        : activityvalue
                                    }
                                    sx={{ marginLeft: "5px" }}
                                    size="small"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                  />
                                </Paper>
                              </StyledInputSlider>
                            }
                          </Box>
                        </FormLabel>
                        <Slider
                          size="medium"
                          name="activity_point"
                          value={
                            activityvalue === 0
                              ? values.activity_point
                              : activityvalue
                          }
                          onChange={handleActivityPoints}
                          aria-labelledby="input-slider"
                          sx={{ marginLeft: "6px", marginTop: "10px" }}
                          min={0}
                          max={10}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <Autocomplete
                          multiple
                          name="location_id"
                          options={customNotification}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          value={state.selectedOptions}
                          renderTags={(values) =>
                            values.map((value) => (
                              <Chip
                                key={value.title}
                                label={value.title}
                                onDelete={() => {
                                  removeOption(value.title);
                                }}
                              />
                            ))
                          }
                          getOptionSelected={(option, value) =>
                            option.title === value.title
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.title}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.title}
                            </li>
                          )}
                          style={{ width: "100%" }}
                          onChange={handleChangeAuto}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              label="Add custom notification"
                              placeholder="Search"
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <FormLabel component="legend">Task sentiment</FormLabel>
                        <StyledRadio>
                          <RadioGroup
                            aria-labelledby="task-sentiment"
                            name="task_sentiments"
                            defaultValue={values.task_sentiments}
                            value={values.task_sentiments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Positive"
                              value="1"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Warning"
                              value="2"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Caution"
                              value="3"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Danger"
                              value="4"
                            />
                          </RadioGroup>
                        </StyledRadio>
                      </FormControl>
                      <FormControl mt={6}>
                        <FormLabel>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                          >
                            Sentiment score
                            {
                              <StyledInputSlider>
                                <Paper>
                                  <Input
                                    name="sentiment_scores"
                                    value={
                                      sentimentscore === 0
                                        ? values.sentiment_scores
                                        : sentimentscore
                                    }
                                    sx={{ marginLeft: "5px" }}
                                    size="small"
                                    onChange={handleScoreChange}
                                    onBlur={handleScoreBlur}
                                    inputProps={{
                                      step: 1,
                                      min: 0,
                                      max: 100,
                                      type: "number",
                                      "aria-labelledby": "input-slider",
                                    }}
                                  />
                                </Paper>
                              </StyledInputSlider>
                            }
                          </Box>
                        </FormLabel>
                        <Slider
                          name="sentiment_scores"
                          size="medium"
                          value={
                            sentimentscore === 0
                              ? values.sentiment_scores
                              : sentimentscore
                          }
                          onChange={handleScores}
                          onBlur={handleBlur}
                          aria-labelledby="input-slider"
                          sx={{ marginLeft: "6px", marginTop: "10px" }}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <TextField
                          id="tag"
                          label="Tag:"
                          name="tag"
                          value={values.tag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                      <Dialog
                        open={openDelete}
                        onClose={handleDeleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Are you sure you want to delete task button?"}
                        </DialogTitle>
                        <DialogActions>
                          <Button onClick={handleDeleteClose}>Cancel</Button>
                          <Button onClick={deleteSchedule} autoFocus>
                            OK
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <StyledBox sx={{ marginTop: "15px" }}>
                        <Paper>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={value}
                              onChange={handlePanel}
                              aria-label="basic tabs example"
                            >
                              <Tab label="Schedules" {...a11yProps(0)} />
                              <Tab label="Task Buttons" {...a11yProps(1)} />
                            </Tabs>
                          </Box>
                          <TabPanel value={value} index={0}>
                            <WithPermissionHide
                              controller="Task"
                              action="addScheduleTask"
                            >
                              <IconButton
                                sx={{ marginTop: "15px", marginBottom: "15px" }}
                              >
                                <AddIcon onClick={AddScheduleOpen} />
                              </IconButton>
                            </WithPermissionHide>
                            <Paper>
                              <TableContainer className="tableContainer">
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Existing Schedules</TableCell>
                                      <TableCell align="right">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {schedule.map((data) => {
                                      return (
                                        <TableRow key={data.assign_id}>
                                          <TableCell>{data.message}</TableCell>
                                          <TableCell align="right">
                                            <IconButton
                                              aria-label="Settings"
                                              size="large"
                                              aria-owns={
                                                anchorEl
                                                  ? "icon-menu"
                                                  : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={(event) =>
                                                handleActionClick(
                                                  event,
                                                  data.assign_id
                                                )
                                              }
                                              style={{ color: "#1374C5" }}
                                            >
                                              <SettingsIcon />
                                            </IconButton>
                                            <StyledMenu
                                              id="icon-menu"
                                              anchorEl={anchorEl}
                                              open={Boolean(anchorEl)}
                                              onClose={handleActionClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                            >
                                              <MenuItem
                                                onClick={() => {
                                                  taskModalOpen();
                                                  viewScheduleTask();
                                                  handleActionClose();
                                                }}
                                                disabled={
                                                  !hasPermissionToViewScheduleTask
                                                }
                                              >
                                                <ListItemIcon>
                                                  <CreateIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                  primary="Edit"
                                                  pl={1}
                                                />
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleDeleteOpen();
                                                  handleActionClose();
                                                }}
                                                disabled={
                                                  !hasPermissionToDeleteTaskAssignment
                                                }
                                              >
                                                <ListItemIcon>
                                                  <DeleteIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                  primary="Delete"
                                                  pl={1}
                                                />
                                              </MenuItem>
                                            </StyledMenu>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                    {schedule.length === 0 && (
                                      <TableRow
                                        style={{
                                          height:
                                            (dense ? 6 : 0) * schedule.length,
                                        }}
                                      >
                                        <TableCell colSpan={2} align="center">
                                          No records found
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <Paper>
                              <WithPermissionHide
                                controller="Task"
                                action="updateCustomButtonText"
                              >
                                <Button
                                  color="warning"
                                  component="div"
                                  mt={3}
                                  onClick={customButtonOpen}
                                >
                                  Customize button text
                                </Button>
                              </WithPermissionHide>
                              <TableContainer className="tableContainer">
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        Existing Task Buttons
                                      </TableCell>
                                      <TableCell align="right">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {taskButtonList.map((data) => {
                                      return (
                                        <TableRow key={data.tag_id}>
                                          <TableCell>
                                            {data.tag_description}
                                          </TableCell>
                                          <TableCell align="right">
                                            <IconButton
                                              aria-label="Settings"
                                              size="large"
                                              aria-owns={
                                                anchorEl
                                                  ? "icon-menu"
                                                  : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={(event) =>
                                                handleActionClick(
                                                  event,
                                                  data.tag_id
                                                )
                                              }
                                              style={{ color: "#1374C5" }}
                                            >
                                              <SettingsIcon />
                                            </IconButton>
                                            <StyledMenu
                                              id="icon-menu"
                                              anchorEl={anchorEl}
                                              open={Boolean(anchorEl)}
                                              onClose={handleActionClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                            >
                                              <MenuItem
                                                onClick={() => {
                                                  viewTaskButton();
                                                  taskButtonOpen();
                                                  handleActionClose();
                                                }}
                                                disabled={
                                                  !hasPermissionToViewTaskButton
                                                }
                                              >
                                                <ListItemIcon>
                                                  <CreateIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                  primary="Edit"
                                                  pl={1}
                                                />
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleDeleteOpen();
                                                  handleActionClose();
                                                }}
                                              >
                                                <ListItemIcon>
                                                  <DeleteIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                  primary="Delete"
                                                  pl={1}
                                                />
                                              </MenuItem>
                                            </StyledMenu>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                    {taskButtonList.length === 0 && (
                                      <TableRow
                                        style={{
                                          height:
                                            (dense ? 6 : 0) *
                                            taskButtonList.length,
                                        }}
                                      >
                                        <TableCell colSpan={2} align="center">
                                          No records found
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                          </TabPanel>
                        </Paper>
                      </StyledBox>
                    </FormControl>
                    {values.task_type === "No" && (
                      <Paper
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "30px",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color={"primary"}
                          sx={{ cursor: "pointer" }}
                          onClick={handleStructuredCommentsOpen}
                        >
                          Add Structured Comments
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color={"primary"}
                          sx={{ cursor: "pointer" }}
                          onClick={handleStructuredLocationOpen}
                        >
                          Add Structured Location
                        </Typography>
                      </Paper>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={2}>
                      <FormLabel>Information</FormLabel>
                      <StyledContainer>
                        <Box
                          style={{
                            display:
                              values.task_type === "Yes" ? "block" : "none",
                          }}
                        >
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Custom Button Levels:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              By default, mobile users will see options Yes, No,
                              and N/A as buttons when completing this task. For
                              example, let's assume the task is to check the
                              temperature of a server room. You could add
                              captions like 'Normal', 'Above Normal' or 'Too
                              Hot' then add email addresses to notify others of
                              selections made, and a schedule when these notices
                              should be sent.
                            </Typography>
                          </Paper>
                        </Box>
                        <Box
                          style={{
                            display:
                              values.task_type === "No" ? "block" : "none",
                          }}
                        >
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Critical:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Setting this toggle to YES can potentially sent
                              notification alerts by email or SMS when the
                              critical task is recorded by your team. Visit
                              Settings
                              <ArrowForwardIosIcon />
                              Communication to learn how to set this up.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Contact Occupational Health Manager:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              When set to ON, triggers emails and SMS messages
                              to be sent to your onsite nurse or health care
                              provider when these tasks are recorded. Visit
                              Settings
                              <ArrowForwardIosIcon />
                              Communication to learn how to set this up.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Comments Required:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Setting this toggle to YES requires your team to
                              enter a comment prior to completing the task.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Team Notify:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Setting toggle to ON allows your team to send
                              notifications to a group of recipients that you
                              configure. You will find an option for "Team
                              Notify" in the drop-down menu at Settings
                              <ArrowForwardIosIcon />
                              Communication. Add your recipients there to
                              receive email or text messages when this task type
                              is recorded.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Send Shift Report:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Setting toggle to YES will cause this task to roll
                              up in totals that are shown in the shift report.
                              Most tasks should be marked YES. An example of a
                              task that you may not wish to have counted on the
                              shift report is a task like "Meal Break", unless
                              it's important to know how many people took meal
                              breaks in a given shift.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Web Notify:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Setting toggle to ON will show a notification to
                              all users who are currently logged into SOVA from
                              a desktop console. These notifications remain, and
                              must be clicked to close.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              IR Required:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Setting toggle to YES will prompt mobile users to
                              optionally create a new incident report when
                              completing the task.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Collect Sentiment:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Setting toggle to YES will show a popup to mobile
                              users when they are completing the task. The popup
                              allows them to choose whether the task interaction
                              was a positive or negative one. You would usually
                              set this to YES for tasks where the security
                              officer will encounter and interact with your
                              customers.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Passdown Required:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Setting toggle to YES saves task to the passdown
                              report automatically.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Task Comment:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Adding a canned text comment will allow it to be
                              selected from live dispatch to help improve data
                              consistency between dispatchers. Consider adding
                              text like &#123; add name here &#125; to focus
                              users to change certain parts of the comment while
                              leaving the rest unchanged.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Activity Points:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Set an activity point value for this task using
                              the slider. Tasks are given 3 activity points by
                              default. A good rule of thumb is to assign 1
                              activity point for each 5 minutes of activity. So
                              if a task takes 10 minutes on average to complete,
                              you would assign 2 activity points.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Task Sentiment:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Select the general sentiment for this task. Your
                              selection here will dictate the color of the icon
                              that appears in the DAR report so admins can
                              quickly scan that report to focus only on the
                              items that would generally be considered negative.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Sentiment Score:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Select a number using the slider that represents
                              the 'Sentiment Score' for this task. Very positive
                              tasks should be ranked 80-100, very negative tasks
                              0-20. Neutral tasks should be ranked at 50. The
                              average sentiment score for all tasks completed in
                              a given day is displayed in the dashboard calendar
                              providing admins a quick overview into whether the
                              day was positive or negative.
                            </Typography>
                          </Paper>
                          <Paper variant="p" component="div">
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="span"
                            >
                              Tag:
                            </Typography>
                            <Typography variant="span" gutterBottom>
                              Aggregate tasks that are used at certain sites.
                              This is an optional field. Possible values could
                              be the name of your clients business, or an ID
                              number that represents one of your clients.
                            </Typography>
                          </Paper>
                        </Box>
                      </StyledContainer>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={6}
                      sx={{ width: "175px" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
            {/* Add Schedule Dialog */}
            <TourSchedule
              addschedule={addschedule}
              addScheduleClose={addScheduleClose}
              addScheduleModal={addScheduleModal}
              TimePicker={TimePicker}
              viewSchedule={viewSchedule}
              result={result}
              levelType={levelType}
            />
            {/* Custom Button Text */}
            <CustomButton
              custombuttontext={custombuttontext}
              customButtonClose={customButtonClose}
              setCustomButtonText={setCustomButtonText}
              customoptions={customoptions}
              customOptionsLevel={customOptionsLevel}
              task={task}
              taskView={taskView}
            />
            <AddSchedule
              addSchedule={addSchedule}
              setAddSchedule={setAddSchedule}
              AddScheduleClose={AddScheduleClose}
              AddScheduleOpen={AddScheduleOpen}
              TimePicker={TimePicker}
              id={id}
              scheduleList={scheduleList}
              TaskDescription={TaskDescription}
            />
            <StructuredDialog
              open={structuredCommentsOpen}
              onClose={handleStructuredCommentsClose}
              title="Structured Comments"
              description={`Provide a basic sentence containing relevant data that should appear in the activity reports. Enclose portions that you want your users to change in {{double curly brackets}}. Here’s an example:

            Fire panel {{Indicate if it was checked::was checked, was not checked}} and the system readings were {{Provide fire panel readings::normal, trouble, bypass}}

            Inside the brackets, the portion at the left of the double :: colon will be the caption your user will see, and the portion to the right will be the hint that will appear in the field where they will provide their response. Here you could provide an example of what information they should provide.`}
              onSave={(structuredComment) => {
                setFieldValue("structured_comment", structuredComment);
                handleStructuredCommentsClose();
              }}
              currentValue={values.structured_comment}
            />

            {/* Structured Location Dialog */}
            <StructuredDialog
              open={structuredLocationOpen}
              onClose={handleStructuredLocationClose}
              title="Structured Location"
              description="Add captions and hints below to help your team provide necessary information in the location field for this NTT."
              onSave={(structuredLocation) => {
                setFieldValue("structured_location", structuredLocation);
                handleStructuredLocationClose();
              }}
              currentValue={values.structured_location}
            />
          </Card>
        )}
      </Formik>
      <Schedule
        edittask={edittask}
        taskModalClose={taskModalClose}
        setEditTask={setEditTask}
        handleRadioChange={handleRadioChange}
        dvalues={dvalues}
        month={month}
        handleMonthChange={handleMonthChange}
        TimePicker={TimePicker}
        timevalue={timevalue}
        setTimeValue={setTimeValue}
        singleScheduleTask={singleScheduleTask}
        viewScheduleTask={viewScheduleTask}
        scheduleList={scheduleList}
        modulearray={modulearray}
        setmodulearray={setmodulearray}
        message={message}
        setMessage={setMessage}
      />
      <TaskButton
        taskbutton={taskbutton}
        taskButtonClose={taskButtonClose}
        viewTask={viewTask}
        TextAreaControl={TextAreaControl}
        classes={classes}
        button={button}
      />
    </>
  );
}

function EditTask() {
  return (
    <React.Fragment>
      <Helmet title="Edit Task" />
      <WithPermissionFallback controller="Task" action="updateTask">
        <Typography variant="h3" gutterBottom display="inline">
          Edit Task
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/tasks/task-list">
            Tasks
          </Link>
          <Typography>Edit Task</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <ColumnLeft />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default EditTask;

import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  DialogActions,
  IconButton,
  FormControl as MuiFormControl,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Paper,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { SaveReference } from "../../../api";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import CustomSelection from "../../../components/form-components/CustomSelection";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import { useDispatch } from "react-redux";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const NewReference = ({
  newReferenceOpen,
  newReferenceClose,
  newReference,
  setNewReference,
  incidentId,
  getInvolvedReferenceListing,
  editRefData,
  refLoader,
  setRefLoader,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  //Initial values Formik
  const initialValues = {
    reference_type: editRefData
      ? editRefData.reference_type
        ? editRefData.reference_type
        : ""
      : "",
    reference_id: editRefData
      ? editRefData.reference_id
        ? editRefData.reference_id
        : ""
      : "",
  };
  const validationSchema = Yup.object().shape({
    reference_type: Yup.string().required("Please select reference"),
    reference_id: Yup.string().required("Please enter ID"),
  });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setRefLoader(true);
      let formData = {
        id: editRefData ? (editRefData.id ? editRefData.id : null) : null,
        incident_id: incidentId,
        reference_type: values.reference_type,
        reference_id: values.reference_id,
      };
      SaveReference(formData)
        .then((res) => {
          console.log("++", res);
          setRefLoader(false);
          if (res.statusCode === 200) {
            getInvolvedReferenceListing();
            dispatch(
              getSnackMsg(
                editRefData
                  ? editRefData.id
                    ? "Record has been updated successfully"
                    : "Record has been added successfully"
                  : "Record has been submitted successfully"
              )
            );
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            newReferenceClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setRefLoader(false);
          console.log(err);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
      // await timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      setRefLoader(false);
      console.log(error);
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };
  const references = [
    { key: "Lost and Found", value: "Lost and Found" },
    { key: "Person of Interest", value: "Person of Interest" },
    { key: "Other Incident Report", value: "Other Incident Report" },
  ];
  return (
    <>
      <Dialog
        open={newReference}
        onClose={newReferenceClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">New Reference</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={newReferenceClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomSelection
                        name="reference_type"
                        fullWidth
                        label="Select Reference *"
                        fieldLabel="Select reference"
                        options={references}
                        displayEmpty
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="reference_id"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Enter ID *"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={newReferenceClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {refLoader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default NewReference;

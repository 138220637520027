import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import AddEditVehicle from "./inner-component/AddEditVehicle";
import ScanVehicleId from "./inner-component/ScanVehicleId";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
  addVehicle,
} from "../../redux/slices/mainSlice";
import Toaster from "../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import VehicleListCol from "../table-columns-json/logs/VehicleList";
import { setVehicleList } from "../../redux/slices/logs/logTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";

import {
  VehicleManagementList,
  GetAllDepartment,
  YearList,
  MakeList,
  ModelList,
  DeleteVehicle,
  VehicleByYear,
} from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Pagination,
  CircularProgress,
  Autocomplete,
  FormLabel,
  RadioGroup,
  Radio,
  DialogActions,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  InsertDriveFile as InsertDriveFileIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  SettingsOverscan as SettingsOverscanIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
import hasPermission from "../../utils/hasPermission";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 12px auto 0;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "465px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

const make = [
  { title: "The Shawshank Redemption" },
  { title: "The Godfather" },
  { title: "The Godfather: Part II" },
  { title: "The Dark Knight" },
];
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "fname",
    numeric: true,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "lname",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "department",
    numeric: true,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "sticker",
    numeric: true,
    disablePadding: false,
    label: "Sticker",
  },
  {
    id: "color",
    numeric: true,
    disablePadding: false,
    label: "Color",
  },
  {
    id: "make",
    numeric: true,
    disablePadding: false,
    label: "Make",
  },
  {
    id: "model",
    numeric: true,
    disablePadding: false,
    label: "Model",
  },
  {
    id: "year",
    numeric: true,
    disablePadding: false,
    label: "Year",
  },
  {
    id: "lplate",
    numeric: true,
    disablePadding: false,
    label: "L Plate",
  },
  {
    id: "vio",
    numeric: true,
    disablePadding: false,
    label: "Vio",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {VehicleListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
            title={headCell.title || ""}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "action" || headCell.id == "vio"
                  ? null
                  : createSortHandler(headCell.id)
              }
              hideSortIcon={
                headCell.id == "action" || headCell.id == "vio" ? true : false
              }
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setDataToFilter,
    handleDeleteOpen,
    tableCalRedux,
    id,
    vehicleList,
  } = props;
  const [actions, setActions] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.vehicleList
  );
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportData, setExportData] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    VehicleManagementList(1, { limit: totalRecord })
      .then((res) => {
        if (res.statusCode === 200) {
          setExportData(res.data);
        } else {
          setExportData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
    handleDepartment();
    // getYearList();
  };
  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [dateRange, setDateRange] = React.useState(null);

  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  // Add/Edit Vehicle Management
  const [addEditVehicleDialog, setAddEditVehicleDialog] = useState(false);
  const addEditVehicleDialogOpen = () => {
    setAddEditVehicleDialog(true);
  };
  const addEditVehicleDialogClose = () => {
    setAddEditVehicleDialog(false);
  };
  // Scan Vehicle ID Dialog
  const [scanVehicleIdDialog, setScanVehicleIdDialog] = useState(false);
  const scanVehicleIdDialogOpen = () => {
    setScanVehicleIdDialog(true);
  };
  const scanVehicleIdDialogClose = () => {
    setScanVehicleIdDialog(false);
  };

  // Year
  const [year, setYear] = useState("");
  const [makeData, setMakeData] = useState([]);
  const [makeValue, setMakeValue] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [model, setModel] = useState("");
  const [isVoilation, setIsVoilation] = useState(null);
  const [search, setSearch] = useState("");
  const selectYear = (event) => {
    setYear(event.target.value);
    VehicleByYear({ year: event.target.value })
      .then((res) => {
        if (res.statusCode == 200) {
          setMakeData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDepartment = () => {
    GetAllDepartment()
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const lastFiveYear = () => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 5;
    const years = [];
    for (let i = lastYear; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  };

  const lastFiveToTenYear = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const endYear = currentYear - 5;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const lastTenYear = (value) => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - value;
    const years = [];
    for (let year = startYear; year < currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const lastFive = lastFiveYear();
  const lastFiveToTen = lastFiveToTenYear();
  const lastTen = lastTenYear(10);
  const allYear = lastTenYear(30);
  const [yearData, setYearData] = useState([
    { key: "Within the last 5 years", value: lastFive },
    { key: "5 to 10 years old", value: lastFiveToTen },
    { key: "More than 10 years old", value: lastTen },
    { key: "Show all years", value: allYear },
  ]);
  //method for getting year dropdown
  const getYearList = () => {
    YearList()
      .then((res) => {
        if (res.statusCode == 200) {
          setYearData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applySearch = () => {
    const payload = {
      search_value: search,
      fname: fname,
      lname: lname,
      department: department,
      year: year,
      make: makeValue.map((d) => d.value),
      model: model,
      voilation: isVoilation,
    };
    // console.log(payload);
    setDataToFilter(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setSearch("");
    setFname("");
    setLname("");
    setDepartment("");
    setYear("");
    setMakeValue([]);
    setModel("");
    setIsVoilation(null);
  };
  let headers = [
    { label: "First Name", key: "first" },
    { label: "Last Name", key: "last" },
    { label: "Department", key: "department" },
    { label: "Position", key: "Position" },
    { label: "ID", key: "vehicle_id" },
    { label: "Sticker", key: "sticker" },
    { label: "Color", key: "color" },
    { label: "Make", key: "make" },
    { label: "Model", key: "model" },
    { label: "Year", key: "year" },
    { label: "Lplate", key: "Lplate" },
    { label: "Vio", key: "vio" },
  ];
  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem
                  disabled={!hasPermission("Logs", "deleteVehicle")}
                  value={"Delete"}
                  onClick={handleDeleteOpen}
                >
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord <= 1
              ? `${totalRecord} record found`
              : `${totalRecord} records found`}
          </Typography>
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch({});
                    setIsFilter(true);
                  }
                }}
                placeholder="Search by name, ID, Sticker # or license plate"
                inputProps={{}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="fname"
                                label="First name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Mike"
                                value={fname}
                                onChange={(e) => setFname(e.target.value)}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                fullWidth
                                id="lname"
                                label="Last name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Smith"
                                value={lname}
                                onChange={(e) => setLname(e.target.value)}
                              />
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="department">
                                Department
                              </InputLabel>
                              <Select
                                labelId="department"
                                id="department"
                                value={department}
                                label="Department"
                                onChange={(e) => setDepartment(e.target.value)}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select Department</MenuItem>
                                {departmentList.map((data) => {
                                  return (
                                    <MenuItem
                                      value={data.department_name}
                                      key={data.id}
                                    >
                                      {data.department_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="year">
                                Year
                              </InputLabel>
                              <Select
                                labelId="department"
                                id="year"
                                value={year}
                                label="Year"
                                onChange={selectYear}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select Year</MenuItem>
                                {yearData.map((data) => {
                                  return (
                                    <MenuItem
                                      value={data.value}
                                      key={data.value}
                                    >
                                      {data.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <Autocomplete
                                multiple
                                id="make"
                                options={makeData}
                                value={makeValue}
                                onChange={(e, v) => {
                                  setMakeValue(v);
                                  ModelList({ make: v.map((d) => d.value) })
                                    .then((res) => {
                                      if (res.statusCode == 200) {
                                        setModelData(res.data);
                                      }
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.key}
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.key}
                                  </li>
                                )}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Make"
                                    placeholder="Select year first"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="model">
                                Model
                              </InputLabel>
                              <Select
                                labelId="model"
                                id="model"
                                value={model}
                                label="Model"
                                onChange={(e) => setModel(e.target.value)}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select make first</MenuItem>
                                {modelData.map((data) => {
                                  return (
                                    <MenuItem
                                      value={data.value}
                                      key={data.value}
                                    >
                                      {data.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <FormLabel component="legend">
                                Vehicle with violations
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="vehicle-with-violations"
                                name="vehicle-with-violations-group"
                                value={isVoilation}
                                onChange={(e) => setIsVoilation(e.target.value)}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={6}
                                onClick={() => {
                                  applySearch({});
                                  handleCloseForm();
                                  setIsFilter(true);
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  variant="contained"
                  color="primary"
                  mt={6}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                    setSearch("");
                  }}
                >
                  Clear filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    if (search !== "") {
                      applySearch({});
                      setIsFilter(true);
                    }
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <WithPermissionHide controller="Logs" action="addVehicle">
                <MenuItem
                  onClick={() => {
                    addEditVehicleDialogOpen();
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <AddBoxIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Add Vehicle" pl={0} />
                </MenuItem>
              </WithPermissionHide>
              <CSVLink
                data={exportData}
                headers={headers}
                onClick={handleClose}
                filename="SOVA-vehicle-log"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Export CSV" pl={0} />
                </MenuItem>
              </CSVLink>
              <WithPermissionHide controller="Logs" action="vehicleIdExist">
                <MenuItem onClick={scanVehicleIdDialogOpen}>
                  <ListItemIcon>
                    <SettingsOverscanIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Scan Vehicle ID" pl={0} />
                </MenuItem>
              </WithPermissionHide>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setVehicleList(data));
                      }}
                      listing={VehicleListCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setVehicleList(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      {addEditVehicleDialog && (
        <AddEditVehicle
          addEditVehicleDialog={addEditVehicleDialog}
          setAddEditVehicleDialog={setAddEditVehicleDialog}
          addEditVehicleDialogOpen={addEditVehicleDialogOpen}
          addEditVehicleDialogClose={addEditVehicleDialogClose}
          vehicleList={vehicleList}
        />
      )}
      {scanVehicleIdDialog && (
        <ScanVehicleId
          scanVehicleIdDialog={scanVehicleIdDialog}
          setScanVehicleIdDialog={setScanVehicleIdDialog}
          scanVehicleIdDialogOpen={scanVehicleIdDialogOpen}
          scanVehicleIdDialogClose={scanVehicleIdDialogClose}
          vehicleList={vehicleList}
        />
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("color");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [vehicleManagementList, setVehicleManagementList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [vehicleId, setVehicleId] = useState("");

  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const dispatch = useDispatch();
  const tableCalRedux = useSelector((state) => state.logTableCol.vehicleList);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vehicleManagementList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id, vehicleId, data) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    setVehicleId(vehicleId);
    dispatch(addVehicle(data));
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  // Add/Edit Vehicle Management
  const [addEditVehicleDialog, setAddEditVehicleDialog] = useState(false);
  const addEditVehicleDialogOpen = () => {
    setAddEditVehicleDialog(true);
  };
  const addEditVehicleDialogClose = () => {
    setAddEditVehicleDialog(false);
  };

  const vehicleList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    VehicleManagementList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setVehicleManagementList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setVehicleManagementList([]);
          setLoading(false);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        dispatch(getMsg("Something went wrong, please try again later"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const deleteVehicle = () => {
    DeleteVehicle(selected.length > 0 ? selected : id)
      .then((res) => {
        if (res.statusCode === 200) {
          vehicleList();
          handleDeleteClose();
          dispatch(getMsg(`Record(s) has been deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setSelected([]);
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    vehicleList();
  }, [rowsPerPage, currentPage, dataToFilter, order, orderBy]);

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  return (
    <div>
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {selected.length || id > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete vehicle?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={deleteVehicle} variant="contained" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" variant="contained">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setDataToFilter={setDataToFilter}
          handleDeleteOpen={handleDeleteOpen}
          tableCalRedux={tableCalRedux}
          id={id}
          vehicleList={vehicleList}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={vehicleManagementList.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(
                  vehicleManagementList,
                  getComparator(order, orderBy)
                )
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id} selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.fname === true ? "" : "hide-cell"
                          }
                          style={{ paddingLeft: "15px" }}
                        >
                          {row.first}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.lname === true ? "" : "hide-cell"
                          }
                        >
                          {row.last}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.department === true ? "" : "hide-cell"
                          }
                        >
                          {row.department}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.position === true ? "" : "hide-cell"
                          }
                        >
                          {row.Position}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.vehicle_id === true ? "" : "hide-cell"
                          }
                        >
                          {row.vehicle_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.sticker === true ? "" : "hide-cell"
                          }
                        >
                          {row.sticker}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.color === true ? "" : "hide-cell"
                          }
                        >
                          {row.color}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.make === true ? "" : "hide-cell"
                          }
                        >
                          {row.make}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.model === true ? "" : "hide-cell"
                          }
                        >
                          {row.model}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.year === true ? "" : "hide-cell"
                          }
                        >
                          {row.year}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.l_plate === true ? "" : "hide-cell"
                          }
                        >
                          {row.Lplate}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.v_state === true ? "" : "hide-cell"
                          }
                        >
                          {row.v_state}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.vio === true ? "" : "hide-cell"
                          }
                        >
                          {row.vio}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) =>
                              handleActionClick(e, row.id, row.vehicle_id, row)
                            }
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <WithPermissionHide
                              controller="Logs"
                              action="editVehicle"
                            >
                              <MenuItem onClick={addEditVehicleDialogOpen}>
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                            </WithPermissionHide>
                            <MenuItem
                              component={NavLink}
                              to={`/logs/violations/${id}`}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Violations" pl={1} />
                            </MenuItem>
                            <WithPermissionHide
                              controller="Logs"
                              action="deleteVehicle"
                            >
                              <MenuItem onClick={handleDeleteOpen}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </WithPermissionHide>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {vehicleManagementList.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * vehicleManagementList.length,
                    }}
                  >
                    <TableCell colSpan={14} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {addEditVehicleDialog && (
        <AddEditVehicle
          addEditVehicleDialog={addEditVehicleDialog}
          setAddEditVehicleDialog={setAddEditVehicleDialog}
          addEditVehicleDialogOpen={addEditVehicleDialogOpen}
          addEditVehicleDialogClose={addEditVehicleDialogClose}
          id={id}
          vehicleList={() => vehicleList}
        />
      )}
    </div>
  );
}

function VehicleManagement() {
  return (
    <React.Fragment>
      <Helmet title="Vehicle Management" />
      <WithPermissionFallback controller="Logs" action="VehicleManagementList">
        <Typography variant="h3" gutterBottom display="inline">
          Vehicle Management
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default VehicleManagement;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { GetListByIds, MergeDuplicates } from "../../../api";

import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Paper,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const MergeDuplicate = ({
  showModal,
  handleModal,
  selectedRecords,
  showAlertTost,
  reloadList,
  handleActionsClose,
  resetSelected,
}) => {
  const [recordList, setRecordList] = useState([]);
  const [loading, setLoading] = useState(true); //State for loader
  const [parentId, setParentId] = useState("");

  const useStyles = makeStyles((theme) => ({
    alphabets: {
      marginTop: "15px",
      "& .MuiList-root": {
        display: "flex",
        overflowX: "auto",
        background: "#7aa8ff0d",
        padding: "0px",
        "& .MuiListItemButton-root": {
          paddingLeft: "13px",
          paddingRight: "13px",
          textAlign: "center",
        },
      },
      "& .MuiList-root::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "& .MuiList-root::-webkit-scrollbar-track": {
        backgroundColor: "#bed2f561",
      },
      "& .MuiList-root::-webkit-scrollbar-thumb": {
        backgroundColor: "#92a6c96e",
      },
    },
    shipmentRequest: {
      "& .MuiFormControl-root": {
        minWidth: "auto",
        width: "100%",
      },
    },
    filterDataByTabs: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "15px",
      "& .MuiToggleButtonGroup-root": {
        justifyContent: "space-between",
        width: "100%",
        overflowX: "auto",
        "& .MuiButtonBase-root": {
          borderRadius: "0",
          width: "100%",
          whiteSpace: "nowrap",
        },
      },
      "& .MuiList-root::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "& .MuiList-root::-webkit-scrollbar-track": {
        backgroundColor: "#bed2f561",
      },
      "& .MuiList-root::-webkit-scrollbar-thumb": {
        backgroundColor: "#92a6c96e",
      },
    },
    btnDropdown: {
      maxWidth: "65px",
      width: "100%",
    },
    statusButton: {
      width: "20px",
      height: "20px",
      display: "block",
      lineHeight: "20px",
      textAlign: "center",
      borderRadius: "6px",
      color: "#fff",
      fontSize: "12px",
      fontWeight: "bolder",
    },
    cardHeader: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterByRadioControls: {
      position: "absolute",
      top: "100%",
      right: "0px",
      boxShadow: "0px 3px 5px 3px #00000014 !important",
      minWidth: "320px",
      zIndex: "1",
      padding: "15px",
    },
    itemInSingleRow: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
    },
    createMergedGroup: {
      border: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "#c4c4c4" : theme.palette.grey[300],
      padding: 0,
      marginTop: "15px",
    },
    CustomActionsButton: {
      borderColor:
        theme.palette.mode === "light"
          ? "rgb(223 223 223)"
          : theme.palette.grey[300],
      color:
        theme.palette.mode === "light" ? "#7f8797" : theme.palette.grey[300],
      padding: "13px 20px",
    },
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    GetGetListByIds();
  }, []);

  const GetGetListByIds = () => {
    GetListByIds({ ids: selectedRecords })
      .then((res) => {
        if (res.statusCode === 200) {
          setRecordList(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mergedDuplicates = () => {
    if (parentId === "") {
      showAlertTost("Please choose a master record", "error");
    } else {
      let parentIndex = selectedRecords.indexOf(parseInt(parentId));
      let childId = parentIndex === 1 ? selectedRecords[0] : selectedRecords[1];

      let payload = {
        child_id: childId,
        parent_id: parentId,
      };
      setLoading(true);
      MergeDuplicates(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            handleModal(false);
            showAlertTost(res.message, "success");
            handleActionsClose();
            reloadList();
            resetSelected();
          } else {
            showAlertTost("Sorry! something wrong please try agin", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          showAlertTost("Sorry! something wrong please try agin", "error");
        });
    }
  };

  return (
    <Dialog
      open={showModal}
      aria-labelledby="subitems-title"
      aria-describedby="subitems-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "600px",
          },
        },
      }}
    >
      <DialogTitle
        id="subitems-dialog-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Merge Duplicates
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => handleModal(false)}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>

      {loading ? (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      ) : (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to merge ID: {recordList[0].id} (
            {recordList[0].DS + " " + recordList[0].category}) with ID:{" "}
            {recordList[1].id} (
            {recordList[1].DS + " " + recordList[1].category}
            )? If yes, data from both records will be merged into a single
            master record. To continue choose the master record below and click
            merge.
          </DialogContentText>
          <form>
            <FormControl>
              <RadioGroup
                aria-labelledby="merge-duplicates"
                name="merge-duplicates"
                value={parentId}
                onChange={(e) => setParentId(e.currentTarget.value)}
              >
                {recordList.map((row, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={<Radio />}
                      label={row.category + ":" + row.id}
                      value={row.id}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </form>
        </DialogContent>
      )}
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          onClick={() => handleModal(false)}
          color="primary"
          variant="outlined"
        >
          Close
        </Button>
        <Button
          onClick={() => mergedDuplicates()}
          color="primary"
          variant="contained"
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MergeDuplicate;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const LiveModuleDilaog = ({
  liveModuleDilaog,
  setLiveModuleDilaog,
  liveModuleDilaogOpen,
  liveModuleDilaogClose,
}) => {
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        open={liveModuleDilaog}
        onClose={liveModuleDilaogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h5">Inactive</Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setLiveModuleDilaog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" mt={4}>
            Location:
          </Typography>
          <Typography variant="body2">
            Remarks:User Adil siddiqui is inactive
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={liveModuleDilaogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LiveModuleDilaog;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
  Box,
  FormLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AddVehiclesInspection } from "../../../api";
import Toaster from "../../users/components/Toaster";

const AddVehicleInspection = ({
  openVehicleInspection,
  handleCloseInspection,
  vehicles,
  refreshTable,
}) => {
  const [uid, setUid] = useState("");
  const [value, setValue] = useState(dayjs());
  const [date, setDate] = useState(dayjs());

  const [insuranceInformation, setInsuranceInformation] = useState("");
  const [inspectedBy, setInspectedBy] = useState("");
  const [vehiclePlate, setVehiclePlate] = useState("");
  const [vehicleDescription, setVehicleDescription] = useState("");
  const [mileage, setMileage] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [brakeLightsComment, setBreakLightsComment] = useState("");
  const [headLightsComment, setHeadLightsComment] = useState("");
  const [tyreWearComments, setTyreWearComments] = useState("");
  const [signalsComments, setSignalsComments] = useState("");

  const [wiperComments, setWiperComments] = useState("");
  const [doorComments, setDoorComments] = useState("");
  const [mirrorsComments, setMirrorsComments] = useState("");
  const [windowsComments, setWindowsComments] = useState("");
  const [heatingComments, setHeatingComments] = useState("");
  const [seatBeltsComments, setSeatBeltsComments] = useState("");
  const [cleanlinessComments, setCleanlinessComments] = useState("");
  const [breakLights, setBreakLights] = useState(false);
  const [headLights, setHeadLights] = useState(false);
  const [tyreWear, setTyreWear] = useState(false);
  const [signals, setSignals] = useState(false);
  const [wiperBlades, setWiperBlades] = useState(false);
  const [doors, setDoors] = useState(false);
  const [mirrors, setMirrors] = useState(false);
  const [windows, setWindows] = useState(false);
  const [heating, setHeating] = useState(false);
  const [seatBelts, setSeatBelts] = useState(false);
  const [cleanliness, setCleanliness] = useState(false);
  const [fire, setFire] = useState(false);
  const [firstAid, setFirstAid] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [roadHazard, setRroadHazard] = useState(false);
  const [engineOil, setEngineOil] = useState(false);
  const [flashlight, setFlashlight] = useState(false);
  const [blankets, setBlankets] = useState(false);
  const [transmission, setTransmission] = useState(false);
  const [radiator, setRadiator] = useState(false);
  const [brake, setBrake] = useState(false);
  const [powerSteering, setPowerSteering] = useState(false);
  const [windshield, setWindshield] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [issues, setIssues] = useState(""); // New state for issues

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));

    if (userData && userData.username) {
      setInspectedBy(userData.username); // Set the username as inspected_by by default
    }
    if (vehicles && vehicles.id) {
      setUid(vehicles.vehicle_uid || "");
      setValue(dayjs(vehicles.insurance_expiry || ""));
      setDate(dayjs(vehicles.inspection_datetime || ""));
      setInsuranceInformation(vehicles.vehicle_insurance_carrier || "");
      setInspectedBy(vehicles.inspected_by || userData.username); // Set from vehicle data or default to user's name
      setVehiclePlate(vehicles.vehicle_plate || "");
      setVehicleDescription(vehicles.vehicle_description || "");
      setMileage(vehicles.mileage || "");
      setSelectedValue(vehicles.fuel_level || "");
      setIssues(vehicles.issues || "");

      setBreakLights(vehicles.brake_lights === "Y");
      setBreakLightsComment(vehicles.brake_lights_comment || "");

      setHeadLights(vehicles.head_lights === "Y");
      setHeadLightsComment(vehicles.head_lights_comment || "");

      setTyreWear(vehicles.tyre_wear === "Y");
      setTyreWearComments(vehicles.tyre_wear_comment || "");

      setSignals(vehicles.turn_signals === "Y");
      setSignalsComments(vehicles.turn_signals_comment || "");

      setWiperBlades(vehicles.wiper_blades === "Y");
      setWiperComments(vehicles.wiper_blades_comment || "");

      setDoors(vehicles.doors === "Y");
      setDoorComments(vehicles.doors_comment || "");

      setMirrors(vehicles.mirrors === "Y");
      setMirrorsComments(vehicles.mirrors_comment || "");

      setWindows(vehicles.windows === "Y");
      setWindowsComments(vehicles.windows_comment || "");

      setHeating(vehicles.heating_cooling_system === "Y");
      setHeatingComments(vehicles.heating_cooling_system_comment || "");

      setSeatBelts(vehicles.seat_belts === "Y");
      setSeatBeltsComments(vehicles.seat_belts_comment || "");

      setCleanliness(vehicles.cleaneliness === "Y");
      setCleanlinessComments(vehicles.cleaneliness_comment || "");

      setFire(vehicles.fire_extinguisher_present === "Y");
      setFirstAid(vehicles.first_aid_kit_present === "Y");
      setCommunication(vehicles.communication_device_present === "Y");
      setRroadHazard(vehicles.road_hazard_kit_present === "Y");
      setEngineOil(vehicles.engine_oil === "Y");
      setFlashlight(vehicles.flashlight_present === "Y");
      setBlankets(vehicles.blankets_present === "Y");
      setTransmission(vehicles.transmission_fluid === "Y");
      setRadiator(vehicles.radiator_coolant === "Y");
      setBrake(vehicles.brake_fluid === "Y");
      setPowerSteering(vehicles.power_steering_fluid === "Y");
      setWindshield(vehicles.windshield_washer_fluid === "Y");
    }
  }, [vehicles]);
  useEffect(() => {
    if (!openVehicleInspection) {
      // Reset state variables here
      setUid("");
      setInsuranceInformation("");
      setValue(dayjs());
      setDate(dayjs());
      setVehiclePlate("");
      setVehicleDescription("");
      setMileage("");
      setSelectedValue("");
      setIssues("");
      setBreakLights(false);
      setBreakLightsComment("");
      setHeadLights(false);
      setHeadLightsComment("");
      setTyreWear(false);
      setTyreWearComments("");
      setSignals(false);
      setSignalsComments("");
      setWiperBlades(false);
      setWiperComments("");
      setDoors(false);
      setDoorComments("");
      setMirrors(false);
      setMirrorsComments("");
      setWindows(false);
      setWindowsComments("");
      setHeating(false);
      setHeatingComments("");
      setSeatBelts(false);
      setSeatBeltsComments("");
      setCleanliness(false);
      setCleanlinessComments("");
      setFire(false);
      setFirstAid(false);
      setCommunication(false);
      setEngineOil(false);
      setTransmission(false);
      setRadiator(false);
      setBrake(false);
      setPowerSteering(false);
      setWindshield(false);
      setErrors({});
      setLoading(false);
      setOpenSnack(false);
      setToastMessage("");
    }
  }, [openVehicleInspection]);

  const handleWindshield = (event) => {
    setWindshield(event.target.checked);
  };
  const handlePowerSteering = (event) => {
    setPowerSteering(event.target.checked);
  };
  const handleBrake = (event) => {
    setBrake(event.target.checked);
  };
  const handleRadiator = (event) => {
    setRadiator(event.target.checked);
  };
  const handleTransmission = (event) => {
    setTransmission(event.target.checked);
  };
  const handleBlankets = (event) => {
    setBlankets(event.target.checked);
  };
  const handleFlashlight = (event) => {
    setFlashlight(event.target.checked);
  };
  const handleEngineOil = (event) => {
    setEngineOil(event.target.checked);
  };
  const handleRoadHazard = (event) => {
    setRroadHazard(event.target.checked);
  };
  const handleCommunication = (event) => {
    setCommunication(event.target.checked);
  };

  const handleFirstAid = (event) => {
    setFirstAid(event.target.checked);
  };
  const handleFire = (event) => {
    setFire(event.target.checked);
  };

  const handleBreakLights = (event) => {
    setBreakLights(event.target.checked);
  };
  const handleHeadLights = (event) => {
    setHeadLights(event.target.checked);
  };
  const handleTyreWear = (event) => {
    setTyreWear(event.target.checked);
  };
  const handleSignals = (event) => {
    setSignals(event.target.checked);
  };
  const handleWiperBlades = (event) => {
    setWiperBlades(event.target.checked);
  };
  const handleDoors = (event) => {
    setDoors(event.target.checked);
  };
  const handleMirrors = (event) => {
    setMirrors(event.target.checked);
  };
  const handleWindows = (event) => {
    setWindows(event.target.checked);
  };
  const handleHeating = (event) => {
    setHeating(event.target.checked);
  };
  const handleSeatBelts = (event) => {
    setSeatBelts(event.target.checked);
  };
  const handleCleanliness = (event) => {
    setCleanliness(event.target.checked);
  };
  const handleCleanlinessComments = (event) => {
    setCleanlinessComments(event.target.value);
  };
  const handleSeatBeltsComments = (event) => {
    setSeatBeltsComments(event.target.value);
  };
  const handleHeatingComments = (event) => {
    setHeatingComments(event.target.value);
  };
  const handleWindowsComments = (event) => {
    setWindowsComments(event.target.value);
  };
  const handleMirrorsComments = (event) => {
    setMirrorsComments(event.target.value);
  };
  const handleDoorComments = (event) => {
    setDoorComments(event.target.value);
  };
  const handleWiperComments = (event) => {
    setWiperComments(event.target.value);
  };
  const handleTyreWearComments = (event) => {
    setTyreWearComments(event.target.value);
  };
  const handleSignalsComments = (event) => {
    setSignalsComments(event.target.value);
  };
  const handleHeadComments = (event) => {
    setHeadLightsComment(event.target.value);
  };
  const handleBreakComments = (event) => {
    setBreakLightsComment(event.target.value);
  };
  const handleFuleLevel = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleMilegae = (event) => {
    setMileage(event.target.value);
  };
  const handleVehicleDescription = (event) => {
    setVehicleDescription(event.target.value);
  };
  const handleVehiclePlate = (event) => {
    setVehiclePlate(event.target.value);
  };
  const handleInspectedBy = (event) => {
    setInspectedBy(event.target.value);
  };
  const handleUidChange = (event) => {
    setUid(event.target.value);
  };
  const handleInsuranceExpiry = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD"); // backend format
    setValue(formattedDate); // Store in state for payload
  };

  const handleInspectionDate = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD"); // backend format
    setDate(formattedDate); // Store in state for payload
  };

  const handleInsuranceInformation = (event) => {
    setInsuranceInformation(event.target.value);
  };

  const isFormValid = () => {
    const newErrors = {};
    const generalConditions = [
      { switch: breakLights, comment: brakeLightsComment },
      { switch: headLights, comment: headLightsComment },
      { switch: tyreWear, comment: tyreWearComments },
      { switch: signals, comment: signalsComments },
      { switch: wiperBlades, comment: wiperComments },
      { switch: doors, comment: doorComments },
      { switch: mirrors, comment: mirrorsComments },
      { switch: windows, comment: windowsComments },
      { switch: heating, comment: heatingComments },
      { switch: seatBelts, comment: seatBeltsComments },
      { switch: cleanliness, comment: cleanlinessComments },
    ];

    const hasAtLeastOneGeneralConditionFilled = generalConditions.some(
      (condition) => condition.switch || condition.comment
    );

    if (!uid) newErrors.uid = "Vehicle UID is required";
    if (!insuranceInformation)
      newErrors.insuranceInformation = "Insurance information is required";
    if (!value) newErrors.value = "Insurance expiry date is required";
    if (!date) newErrors.value = "Insurance date is required";
    if (!inspectedBy) newErrors.inspectedBy = "Inspector's name is required";
    if (!vehiclePlate) newErrors.vehiclePlate = "Vehicle plate is required";
    if (!vehicleDescription)
      newErrors.vehicleDescription = "Vehicle description is required";
    if (!mileage) newErrors.mileage = "Mileage is required";
    if (!selectedValue) newErrors.selectedValue = "Fuel level is required";
    if (!hasAtLeastOneGeneralConditionFilled)
      newErrors.generalConditions =
        "At least one general condition must be filled";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleSave = async (event) => {
    event.preventDefault(); // Prevents page reload on form submission

    if (!isFormValid()) {
      return;
    }

    setLoading(true); // Set loading state to true when the save process starts

    const formData = {
      vehicle_uid: uid,
      vehicle_insurance_carrier: insuranceInformation,
      insurance_expiry: dayjs(value).format("YYYY-MM-DD"),
      inspection_datetime: dayjs(date).format("YYYY-MM-DD"),
      mileage,
      issues,
      fuel_level: selectedValue,
      brake_lights: breakLights ? "Y" : "N",
      brake_lights_comment: brakeLightsComment,
      head_lights: headLights ? "Y" : "N",
      head_lights_comment: headLightsComment,
      turn_signals: signals ? "Y" : "N",
      turn_signals_comment: signalsComments,
      tyre_wear: tyreWear ? "Y" : "N",
      tyre_wear_comment: tyreWearComments,
      wiper_blades: wiperBlades ? "Y" : "N",
      wiper_blades_comment: wiperComments,
      doors: doors ? "Y" : "N",
      doors_comment: doorComments,
      mirrors: mirrors ? "Y" : "N",
      mirrors_comment: mirrorsComments,
      windows: windows ? "Y" : "N",
      windows_comment: windowsComments,
      heating_cooling_system: heating ? "Y" : "N",
      heating_cooling_system_comment: heatingComments,
      seat_belts: seatBelts ? "Y" : "N",
      seat_belts_comment: seatBeltsComments,
      cleaneliness: cleanliness ? "Y" : "N",
      cleaneliness_comment: cleanlinessComments,
      fire_extinguisher_present: fire ? "Y" : "N",
      first_aid_kit_present: firstAid ? "Y" : "N",
      road_hazard_kit_present: roadHazard ? "Y" : "N",
      flashlight_present: flashlight ? "Y" : "N",
      communication_device_present: communication ? "Y" : "N",
      blankets_present: blankets ? "Y" : "N",
      engine_oil: engineOil ? "Y" : "N",
      transmission_fluid: transmission ? "Y" : "N",
      radiator_coolant: radiator ? "Y" : "N",
      brake_fluid: brake ? "Y" : "N",
      power_steering_fluid: powerSteering ? "Y" : "N",
      windshield_washer_fluid: windshield ? "Y" : "N",
      vehicle_plate: vehiclePlate,
      vehicle_description: vehicleDescription,
      inspected_by: inspectedBy,
    };

    if (vehicles && vehicles.id) {
      formData.id = vehicles.id;
    }

    try {
      const response = await AddVehiclesInspection(formData);

      if (response) {
        setToastMessage(
          vehicles && vehicles.id
            ? "Record has been edited successfully"
            : "Record has been added successfully"
        );
        setOpenSnack(true); // Open the toaster
        handleCloseInspection(); // Close dialog after saving
        refreshTable(); // Refresh the table to show updated data
      } else {
        // Handle unsuccessful save (optional)
      }
    } catch (error) {
      // Handle the error if needed
    } finally {
      setLoading(false); // Ensure loading is set to false after operation completes
    }
  };

  const handleCloseSnack = () => {
    setOpenSnack(false); // Close the snackbar
  };

  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        msg={toastMessage}
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={openVehicleInspection}
        onClose={handleCloseInspection}
      >
        <DialogTitle>
          {vehicles && vehicles.id
            ? "Edit Vehicle Inspection"
            : "Add Vehicle Inspection"}
        </DialogTitle>
        <DialogContent>
          <form>
            <FormControl
              sx={{ width: "100%", marginTop: "20px" }}
              error={!!errors.uid}
            >
              <TextField
                id="uid"
                label="UID *"
                InputLabelProps={{
                  shrink: true,
                }}
                value={uid}
                onChange={handleUidChange}
                placeholder="Vehicle uid"
                variant="outlined"
                helperText={errors.uid}
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "20px" }}
              error={!!errors.insuranceInformation}
            >
              <TextField
                label="Insurance information *"
                InputLabelProps={{
                  shrink: true,
                }}
                value={insuranceInformation}
                onChange={handleInsuranceInformation}
                placeholder="Insurance carrier"
                variant="outlined"
                helperText={errors.insuranceInformation}
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl
                sx={{ width: "100%", marginTop: "20px" }}
                error={!!errors.value}
              >
                <DesktopDatePicker
                  label="Insurance Expiry"
                  inputFormat="MM/DD/YYYY" // Display format in UI
                  value={dayjs(value).format("MM/DD/YYYY")} // Format the value for display
                  onChange={handleInsuranceExpiry}
                  renderInput={(params) => (
                    <TextField {...params} helperText={errors.value} />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl
                sx={{ width: "100%", marginTop: "20px" }}
                error={!!errors.value}
              >
                <DesktopDatePicker
                  label="Inspection date"
                  inputFormat="MM/DD/YYYY" // Display format in UI
                  value={dayjs(date).format("MM/DD/YYYY")} // Format the value for display
                  onChange={handleInspectionDate}
                  renderInput={(params) => (
                    <TextField {...params} helperText={errors.value} />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
            <FormControl sx={{ width: "100%", marginTop: "20px" }}>
              <TextField
                label="Inspected by *"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inspectedBy} // This will now be dynamic based on the logged-in user
                onChange={(e) => setInspectedBy(e.target.value)} // Allows manual change if needed
                placeholder="Inspected by"
                variant="outlined"
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "20px" }}
              error={!!errors.vehiclePlate}
            >
              <TextField
                label="Vehicle plate *"
                InputLabelProps={{
                  shrink: true,
                }}
                value={vehiclePlate}
                onChange={handleVehiclePlate}
                placeholder="Vehicle plate"
                variant="outlined"
                helperText={errors.vehiclePlate}
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "20px" }}
              error={!!errors.vehicleDescription}
            >
              <TextField
                label="Vehicle description *"
                InputLabelProps={{
                  shrink: true,
                }}
                value={vehicleDescription}
                onChange={handleVehicleDescription}
                placeholder="Vehicle description"
                variant="outlined"
                helperText={errors.vehicleDescription}
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "20px" }}
              error={!!errors.mileage}
            >
              <TextField
                label="Mileage *"
                InputLabelProps={{
                  shrink: true,
                }}
                value={mileage}
                onChange={handleMilegae}
                placeholder="Mileage"
                variant="outlined"
                helperText={errors.mileage}
              />
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "20px" }}>
              <TextField
                label="Issues"
                InputLabelProps={{
                  shrink: true,
                }}
                value={issues}
                onChange={(e) => setIssues(e.target.value)}
                placeholder="Enter any issues"
                variant="outlined"
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "20px" }}
              error={!!errors.selectedValue}
            >
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{ color: "black" }}
              >
                Fuel level
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedValue}
                onChange={handleFuleLevel}
              >
                <FormControlLabel value="1/4" control={<Radio />} label="1/4" />
                <FormControlLabel value="1/2" control={<Radio />} label="1/2" />
                <FormControlLabel value="3/4" control={<Radio />} label="3/4" />
                <FormControlLabel value="1/1" control={<Radio />} label="1/1" />
              </RadioGroup>
              <Typography variant="caption" color="error">
                {errors.selectedValue}
              </Typography>
            </FormControl>
            <Typography variant="h5" mt={6}>
              General Conditions
            </Typography>
            {errors.generalConditions && (
              <Typography variant="caption" color="error">
                {errors.generalConditions}
              </Typography>
            )}
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.brakeLightsComment}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Break Lights</Typography>
                <Switch
                  checked={breakLights}
                  onChange={handleBreakLights}
                  value={breakLights}
                />
              </Box>
              {!breakLights && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  value={brakeLightsComment}
                  onChange={handleBreakComments}
                  sx={{ marginTop: "10px" }}
                  helperText={errors.brakeLightsComment}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.headLightsComment}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Head Lights</Typography>
                <Switch
                  checked={headLights}
                  onChange={handleHeadLights}
                  value={headLights}
                />
              </Box>
              {!headLights && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  value={headLightsComment}
                  onChange={handleHeadComments}
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                  helperText={errors.headLightsComment}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.tyreWearComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Tyre wear</Typography>
                <Switch
                  checked={tyreWear}
                  onChange={handleTyreWear}
                  value={tyreWear}
                />
              </Box>
              {!tyreWear && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  value={tyreWearComments}
                  onChange={handleTyreWearComments}
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                  helperText={errors.tyreWearComments}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.signalsComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Turn Signals</Typography>
                <Switch
                  checked={signals}
                  onChange={handleSignals}
                  value={signals}
                />
              </Box>
              {!signals && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  value={signalsComments}
                  onChange={handleSignalsComments}
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                  helperText={errors.signalsComments}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.wiperComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  Wiper blades (front and rear if available)
                </Typography>
                <Switch
                  checked={wiperBlades}
                  onChange={handleWiperBlades}
                  value={wiperBlades}
                />
              </Box>
              {!wiperBlades && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  value={wiperComments}
                  onChange={handleWiperComments}
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                  helperText={errors.wiperComments}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.doorComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  Doors (open/close/lock/unlock)
                </Typography>
                <Switch checked={doors} onChange={handleDoors} value={doors} />
              </Box>
              {!doors && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  value={doorComments}
                  onChange={handleDoorComments}
                  sx={{ marginTop: "10px" }}
                  helperText={errors.doorComments}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.mirrorsComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  Mirrors (rearview and slide)
                </Typography>
                <Switch
                  checked={mirrors}
                  onChange={handleMirrors}
                  value={mirrors}
                />
              </Box>
              {!mirrors && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  value={mirrorsComments}
                  onChange={handleMirrorsComments}
                  sx={{ marginTop: "10px" }}
                  helperText={errors.mirrorsComments}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.windowsComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  Windows (roll up and down)
                </Typography>
                <Switch
                  checked={windows}
                  onChange={handleWindows}
                  value={windows}
                />
              </Box>
              {!windows && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  value={windowsComments}
                  onChange={handleWindowsComments}
                  sx={{ marginTop: "10px" }}
                  helperText={errors.windowsComments}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.heatingComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Heating/Cooling system</Typography>
                <Switch
                  checked={heating}
                  onChange={handleHeating}
                  value={heating}
                />
              </Box>
              {!heating && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  value={heatingComments}
                  onChange={handleHeatingComments}
                  sx={{ marginTop: "10px" }}
                  helperText={errors.heatingComments}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.seatBeltsComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Seat Belts</Typography>
                <Switch
                  checked={seatBelts}
                  onChange={handleSeatBelts}
                  value={seatBelts}
                />
              </Box>
              {!seatBelts && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  value={seatBeltsComments}
                  onChange={handleSeatBeltsComments}
                  sx={{ marginTop: "10px" }}
                  helperText={errors.seatBeltsComments}
                />
              )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              error={!!errors.cleanlinessComments}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  Cleanliness (inside/outside)
                </Typography>
                <Switch
                  checked={cleanliness}
                  onChange={handleCleanliness}
                  value={cleanliness}
                />
              </Box>
              {!cleanliness && (
                <TextField
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  value={cleanlinessComments}
                  onChange={handleCleanlinessComments}
                  sx={{ marginTop: "10px" }}
                  helperText={errors.cleanlinessComments}
                />
              )}
            </FormControl>
            <Typography variant="h5" mt={6}>
              Health and Saftey
            </Typography>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  Fire extinguisher present
                </Typography>
                <Switch value={fire} checked={fire} onChange={handleFire} />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">First aid kit present</Typography>
                <Switch
                  checked={firstAid}
                  value={firstAid}
                  onChange={handleFirstAid}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Road hazard kit present</Typography>
                <Switch
                  checked={roadHazard}
                  value={roadHazard}
                  onChange={handleRoadHazard}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  Communication device Present
                </Typography>
                <Switch
                  value={communication}
                  checked={communication}
                  onChange={handleCommunication}
                />
              </Box>
            </FormControl>
            <Typography variant="h5" mt={6}>
              Mechanical Condition (Optional)
            </Typography>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Engine oil</Typography>
                <Switch
                  value={engineOil}
                  checked={engineOil}
                  onChange={handleEngineOil}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Transmission fluid</Typography>
                <Switch
                  value={transmission}
                  checked={transmission}
                  onChange={handleTransmission}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Raditor coolant</Typography>
                <Switch
                  checked={radiator}
                  value={radiator}
                  onChange={handleRadiator}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Break fluid</Typography>
                <Switch checked={brake} value={brake} onChange={handleBrake} />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Power steering fluid</Typography>
                <Switch
                  checked={powerSteering}
                  value={powerSteering}
                  onChange={handlePowerSteering}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Windshield washer fluid</Typography>
                <Switch
                  checked={windshield}
                  value={windshield}
                  onChange={handleWindshield}
                />
              </Box>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
          <Button
            onClick={handleCloseInspection}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            disabled={loading}
            color="primary"
            sx={{ marginLeft: "10px" }}
            variant="contained"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddVehicleInspection;

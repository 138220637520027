import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  ListItemText as MuiListItemText,
  Typography,
  FormControl as MuiFormControl,
  Button,
  List,
  ListItem,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { TourResultTooltipList } from "../../../api";
const useStyles = makeStyles((theme) => ({
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);

const ResultDialog = (props) => {
  const {
    resultmissedbtns,
    setResultMissedBtns,
    rMissedButtonsClose,
    userDialog,
    tourDialog,
    tourIdD5,
    timeStart,
    timeFinish,
  } = props;
  const classes = useStyles();
  const [dialogLoaderInner, setDialogLoaderInner] = useState(false);
  const [resultTooltipList, setResultTooltipList] = useState([]); //state for list in get-result-tooltip (D5) dialog for result chip

  const getResultTooltipList = () => {
    let formatedsTime = moment(timeStart).format("YYYY-MM-DD, HH:mm:ss");
    let formatedfTime = moment(timeFinish).format("YYYY-MM-DD, HH:mm:ss");
    let payload = {
      tourId: tourIdD5,
      start_time: formatedsTime,
      finish_time: formatedfTime,
    };
    setDialogLoaderInner(true);
    TourResultTooltipList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setDialogLoaderInner(false);
          setResultTooltipList(res.data);
        } else {
          setDialogLoaderInner(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDialogLoaderInner(false);
      });
  };

  useEffect(() => {
    getResultTooltipList();
  }, []);

  return (
    <>
      {/* get tooltip data: Dialog for Result Chip column*/}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={resultmissedbtns}
        onClose={rMissedButtonsClose}
        aria-labelledby="result-title"
        aria-describedby="result-description"
      >
        <DialogTitle
          id="result-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div" gutterBottom>
            Buttons Missed: This tour
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setResultMissedBtns(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography>Tour Name: {tourDialog}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              User Name: {userDialog}
            </Grid>
          </Grid>
          <List mt={4} className={classes.DateList}>
            {dialogLoaderInner ? (
              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                sx={{
                  height: "50px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                  "& .MuiCircularProgress-root": {
                    width: "30px !important",
                    height: "30px !important",
                  },
                }}
              >
                <CircularProgress />
              </ListItem>
            ) : (
              <>
                {resultTooltipList.map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText primary={item.TagDescription} />
                    </ListItem>
                  );
                })}
                {resultTooltipList.length === 0 ? (
                  <ListItem>
                    <ListItemText primary="No missed tour checkpoints" />
                  </ListItem>
                ) : null}
              </>
            )}
          </List>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={rMissedButtonsClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResultDialog;

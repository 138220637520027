import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  FormControl as MuiFormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  FormLabel,
  TextField,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const EditSpecialVisitor = ({
  editSpecialVisitorDialog,
  editSpecialVisitorDialogClose,
}) => {
  let initialValues = {
    name: "",
    arrival_date: "",
    type: "",
    roomarea: "",
    has_caretaker: "",
    phone: "",
    special_instruction: "",
  };
  const [arrivalDate, setArrivalDate] = React.useState(null);
  const [departureDate, setDepartureDate] = React.useState(null);
  const type = [
    { key: "Impaired hearing", value: "hearing" },
    { key: "Impaired mobility", value: "mobility" },
    { key: "Impaired vision", value: "vision" },
    { key: "VIP", value: "vip" },
    { key: "Other", value: "other" },
  ];
  return (
    <>
      <Dialog
        aria-labelledby="special-visitors-title"
        aria-describedby="special-visitors-desc"
        open={editSpecialVisitorDialog}
        onClose={editSpecialVisitorDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="special-visitors-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Special Visitor
          </Typography>
          <DialogActions>
            <IconButton
              onClick={editSpecialVisitorDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues}>
            <form>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="name"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Name*"
                      autoComplete="off"
                      placeholder="Name"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl mt={6}>
                    <DatePicker
                      label="Arrival date*"
                      name="arrival_date"
                      value={arrivalDate}
                      onChange={(newValue) => {
                        setArrivalDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSelect
                      name="type"
                      fieldLabel="Type*"
                      options={type}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <DatePicker
                      label="Departure date*"
                      value={departureDate}
                      onChange={(newValue) => {
                        setDepartureDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomTextField
                      name="roomarea"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Room/Area*"
                      autoComplete="off"
                      placeholder=""
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel>Has caretaker?*</FormLabel>
                    <RadioGroup
                      aria-labelledby="has-caretaker"
                      name="has_caretaker"
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Yes"
                        value="yes"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="No"
                        value="no"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomTextField
                      name="phone"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Phone*"
                      autoComplete="off"
                      placeholder="xxx-xxx-xxxx"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomTextField
                      name="special_instruction"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Special instructions*"
                      autoComplete="off"
                      placeholder="Add information useful in an evacuation such as special needs"
                      rows={5}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Formik>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={editSpecialVisitorDialogClose}
            sx={{ marginRight: "5px" }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditSpecialVisitor;

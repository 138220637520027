import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import {
  DepartmentList,
  ViewKeyRequisitionInfo,
  InvolvedKeyListing,
  GetInvolvedKeyData,
  DeleteInvolvedKey,
  InvolvedAuthPerListing,
  GetInvolvedAuthPerData,
  DeleteInvolvedAuthPer,
  EditKeyRequisitionRecord,
} from "../../api";
import {
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  FormControlLabel,
  Switch,
  CircularProgress,
  Paper,
} from "@mui/material";
import * as Yup from "yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import {
  AddBox as AddBoxIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { handleApiResonseErrors } from "../../helper/helper";
import KeyDetail from "./inner-component/KeyDetail";
import Toaster from "../tasks/Toaster";
import AddAuthOverride from "./inner-component/AddAuthOverride";
import AddAuthorizationPerson from "./inner-component/AddAuthorizationPerson";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CustomSelection from "../../components/form-components/CustomSelection";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
// Classes Styling
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #0000000f",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function aedTabs(index) {
  return {
    id: `incident-tab-${index}`,
    "aria-controls": `incident-tabpanel-${index}`,
  };
}
const department = [
  { key: "Security test", value: "securitytest" },
  { key: "Test", value: "test" },
];
function EditKeyRequisitionForm(props) {
  const { id } = useParams(); //Getting the edit user Id
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [tabStatus, setTabStatus] = useState("");
  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const [pageLoad, setPageLoad] = useState(false);
  const [editData, setEditData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [keyDList, setKeyDList] = useState([]);
  const [keyInvolvedId, setKeyInvolvedId] = useState(null);
  const [keyDetailData, setKeyDetailData] = useState({});
  const [keyActionStatus, setKeyActionStatus] = useState(false);
  const [authPerList, setAuthPerList] = useState([]);
  const [authPerId, setAuthPerId] = useState(null);
  const [authActionStatus, setAuthActionStatus] = useState(false);
  const [authPerData, setAuthPerData] = useState({});
  const [departmentList, setDepartmentList] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  // Key Details
  const [keyDetails, setKeyDetails] = React.useState(null);
  const handleKeyDetails = (event, keyId) => {
    setKeyInvolvedId(keyId);
    setKeyDetails(event.currentTarget);
  };
  const handleKeyDetailsClose = () => {
    setKeyDetails(null);
  };

  // Key detail dialog
  const [keyDetailDialog, setKeyDetailDialog] = useState(false);
  const keyDetailDialogOpen = () => {
    setKeyDetailDialog(true);
  };
  const keyDetailDialogClose = () => {
    setKeyDetailDialog(false);
    setKeyDetailData({});
  };

  const [dense, setDense] = React.useState(true);
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  // Authorizations Details
  const [authorizationsDetails, setAuthorizationsDetails] =
    React.useState(null);
  const handleAuthorizations = (event, authId) => {
    setAuthPerId(authId);
    setAuthorizationsDetails(event.currentTarget);
  };
  const handleAuthorizationsDetailsClose = () => {
    setAuthorizationsDetails(null);
  };

  // Add Authorization Dialog
  const [addAuthorizationPersonDialog, setAddAuthorizationPersonDialog] =
    useState(false);
  const addAuthorizationPersonDialogOpen = () => {
    setAddAuthorizationPersonDialog(true);
  };
  const addAuthorizationPersonDialogClose = () => {
    setAddAuthorizationPersonDialog(false);
    setAuthPerData({});
  };

  //Add authorization overriden
  const [addAuthOverrideDialog, setAddAuthOverrideDialog] = useState(false);
  const addAuthOverrideDialogOpen = () => {
    setAddAuthOverrideDialog(true);
  };
  const addAuthOverrideDialogClose = () => {
    setAddAuthOverrideDialog(false);
  };

  const initialValues = {
    request_date: editData
      ? editData.request_date
        ? editData.request_date
        : new Date()
      : new Date(),
    first_name: editData
      ? editData.first_name
        ? editData.first_name
        : ""
      : "",
    last_name: editData ? (editData.last_name ? editData.last_name : "") : "",
    department_id: editData
      ? editData.department_id
        ? editData.department_id
        : ""
      : "",
    position: editData ? (editData.position ? editData.position : "") : "",
    approved_date: editData
      ? editData.approved_date
        ? editData.approved_date
        : null
      : null,
    approved_by: editData
      ? editData.approved_by
        ? editData.approved_by
        : ""
      : "",
  };

  const validationSchema = Yup.object().shape({
    request_date: Yup.string().required("Please select date of request"),
    first_name: Yup.string().required("Please enter firstname"),
    last_name: Yup.string().required("Please enter lastname"),
    department_id: Yup.string().required("Please select department"),
    position: Yup.string().required("Please enter company name"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formattedReqDate = values.request_date
        ? moment(values.request_date).format("YYYY-MM-DD")
        : null;
      let formattedAppDate = values.approved_date
        ? moment(values.approved_date).format("YYYY-MM-DD")
        : null;
      // setVehicleLoader(true);
      let formData = {
        id: id,
        request_date: formattedReqDate,
        first_name: values.first_name,
        last_name: values.last_name,
        department_id: values.department_id,
        position: values.position,
        approved_date: formattedAppDate,
        approved_by: values.approved_by,
      };
      EditKeyRequisitionRecord(formData)
        .then((res) => {
          // setVehicleLoader(false);
          if (res.statusCode === 200) {
            navigate({ pathname: "/logs/key-requisition" });
            dispatch(getMsg("Key Requisition updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
          setPageLoad(false);
        })
        .catch((err) => {
          // setVehicleLoader(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setPageLoad(false);
        });
      // await
      //   timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (err) {
      // setVehicleLoader(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setPageLoad(false);
    }
  };

  //method for getting the involved key details listing
  const getInvolvedKeyDetailsListing = () => {
    if (id) {
      setLoading(true);
      InvolvedKeyListing(id)
        .then((res) => {
          if (res.statusCode == 200) {
            console.log("++++", res.data);
            setKeyDList(res.data);
          } else {
            setKeyDList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    }
  };

  //function for getting data for involved vehicle
  const getKeyInvolved = () => {
    setPageLoad(true);
    GetInvolvedKeyData(keyInvolvedId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setKeyDetailData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Function for deleteing involved vehicle
  const DeleteKeyInvolved = () => {
    setPageLoad(true);
    DeleteInvolvedKey(keyInvolvedId)
      .then((res) => {
        if (res.statusCode == 200) {
          setPageLoad(false);
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getInvolvedKeyDetailsListing();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          // handleDeleteClose();
        } else {
          setPageLoad(false);
          // handleDeleteClose();
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Handler for editing vehicle involved
  const editKeyD = () => {
    getKeyInvolved();
    handleKeyDetailsClose();
    keyDetailDialogOpen();
    setKeyActionStatus(false);
  };

  //Handler for view vehicle involved
  const viewKeyD = () => {
    getKeyInvolved();
    handleKeyDetailsClose();
    keyDetailDialogOpen();
    setKeyActionStatus(true);
  };

  //method for getting the involved key details listing
  const getInvolvedAuthPerListing = () => {
    if (id) {
      setLoading(true);
      InvolvedAuthPerListing(id)
        .then((res) => {
          if (res.statusCode == 200) {
            setAuthPerList(res.data);
          } else {
            setAuthPerList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    }
  };

  //function for getting data for involved vehicle
  const getAuthPerInvolved = () => {
    setPageLoad(true);
    GetInvolvedAuthPerData(authPerId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setAuthPerData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Function for deleteing involved vehicle
  const DeleteAuthPerInvolved = () => {
    setPageLoad(true);
    DeleteInvolvedAuthPer(authPerId)
      .then((res) => {
        if (res.statusCode == 200) {
          setPageLoad(false);
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getInvolvedAuthPerListing();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          // handleDeleteClose();
        } else {
          setPageLoad(false);
          // handleDeleteClose();
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Handler for editing vehicle involved
  const editAuthPer = () => {
    getAuthPerInvolved();
    handleAuthorizationsDetailsClose();
    addAuthorizationPersonDialogOpen();
    setAuthActionStatus(false);
  };

  //Handler for view vehicle involved
  const viewAuthPer = () => {
    getAuthPerInvolved();
    handleAuthorizationsDetailsClose();
    addAuthorizationPersonDialogOpen();
    setAuthActionStatus(true);
  };

  //For Dropdown
  const getDepartmentList = () => {
    DepartmentList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const getEditKeyReqData = () => {
    setPageLoad(true);
    ViewKeyRequisitionInfo(id)
      .then((res) => {
        if (res.statusCode == 200) {
          setEditData(res.data);
        }
        setPageLoad(false);
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  useEffect(() => {
    getEditKeyReqData();
    getDepartmentList();
    getInvolvedKeyDetailsListing();
    getInvolvedAuthPerListing();
  }, []);

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={3}>
                      <DatePicker
                        id="request-date"
                        label="Date of request *"
                        value={values.request_date}
                        onChange={(newValue) => {
                          setFieldValue("request_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="request_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="first_name"
                        label="First name of requesting *"
                        autoComplete="off"
                        id="requesting-fname"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="last_name"
                        label="Last name of requesting *"
                        autoComplete="off"
                        id="requesting-lname"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomSelection
                        name="department_id"
                        label="Department *"
                        options={departmentList}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="position"
                        label="Position *"
                        InputLabelProps={{ shrink: true }}
                        id="position"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider my={6} />
                    <Box className={classes.tabs}>
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="key-requisition-tabs"
                      >
                        <Tab label="Key Details" {...aedTabs(0)} />
                        <Tab label="Authorizations" {...aedTabs(1)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                      <IconButton
                        onClick={() => {
                          setTabStatus("keyClick");
                          setKeyActionStatus(false);
                          keyDetailDialogOpen();
                        }}
                      >
                        <AddBoxIcon />
                      </IconButton>
                      <Card
                        variant="outlined"
                        sx={{ borderBottom: 0, mt: "20px" }}
                      >
                        <TableContainer className="tableContainer">
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            size={dense ? "small" : "medium"}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Equipment ID</TableCell>
                                <TableCell>Equipment Name</TableCell>
                                <TableCell>Key Group</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            {loading ? (
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan={4} align="center">
                                    <CircularProgress />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : (
                              <TableBody>
                                {keyDList.map((item) => {
                                  return (
                                    <TableRow hover key={item.id}>
                                      <TableCell>{item.equipment_id}</TableCell>
                                      <TableCell>
                                        {item.equipment_name}
                                      </TableCell>
                                      <TableCell>{item.key_group}</TableCell>
                                      <TableCell textAlign="left">
                                        <IconButton
                                          aria-label="Settings"
                                          size="large"
                                          aria-owns={
                                            keyDetails
                                              ? "key-details"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={(e) => {
                                            handleKeyDetails(e, item.id);
                                          }}
                                          style={{ color: "#1374C5" }}
                                        >
                                          <SettingsIcon />
                                        </IconButton>
                                        <StyledMenu
                                          id="key-details"
                                          anchorEl={keyDetails}
                                          open={Boolean(keyDetails)}
                                          onClose={handleKeyDetailsClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              viewKeyD();
                                              handleKeyDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <VisibilityIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="View"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              editKeyD();
                                              handleKeyDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <EditIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Edit"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              DeleteKeyInvolved();
                                              handleKeyDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Delete"
                                              pl={1}
                                            />
                                          </MenuItem>
                                        </StyledMenu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                                {keyDList.length == 0 && (
                                  <TableRow>
                                    <TableCell colSpan={4} align="center">
                                      No record added
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Card>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <IconButton
                        onClick={() => {
                          setTabStatus("authClick");
                          setAuthActionStatus(false);
                          addAuthorizationPersonDialogOpen();
                        }}
                      >
                        <AddBoxIcon />
                      </IconButton>
                      <Card
                        variant="outlined"
                        sx={{ borderBottom: 0, mt: "20px" }}
                      >
                        <TableContainer className="tableContainer">
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            size={dense ? "small" : "medium"}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Date Approved</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            {loading ? (
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan={5} align="center">
                                    <CircularProgress />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : (
                              <TableBody>
                                {authPerList.map((item) => {
                                  return (
                                    <TableRow hover key={item.id}>
                                      <TableCell>{item.name}</TableCell>
                                      <TableCell>{item.title}</TableCell>
                                      <TableCell>{item.email}</TableCell>
                                      <TableCell>
                                        {item.date_approved}
                                      </TableCell>
                                      <TableCell textAlign="left">
                                        <IconButton
                                          aria-label="Settings"
                                          size="large"
                                          aria-owns={
                                            authorizationsDetails
                                              ? "authorizations-details"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={(e) => {
                                            handleAuthorizations(e, item.id);
                                          }}
                                          style={{ color: "#1374C5" }}
                                        >
                                          <SettingsIcon />
                                        </IconButton>
                                        <StyledMenu
                                          id="authorizations-details"
                                          anchorEl={authorizationsDetails}
                                          open={Boolean(authorizationsDetails)}
                                          onClose={
                                            handleAuthorizationsDetailsClose
                                          }
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              viewAuthPer();
                                              handleAuthorizationsDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <VisibilityIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="View"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              editAuthPer();
                                              handleAuthorizationsDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <EditIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Edit"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              DeleteAuthPerInvolved();
                                              handleAuthorizationsDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Delete"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              addAuthOverrideDialogOpen();
                                              handleAuthorizationsDetailsClose();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <DoneOutlineIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Override"
                                              pl={1}
                                            />
                                          </MenuItem>
                                        </StyledMenu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                                {authPerList.length == 0 && (
                                  <TableRow>
                                    <TableCell colSpan={5} align="center">
                                      No record added
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Card>
                    </TabPanel>
                    {/* <TabPanel value={tabValue} index={2}>
                      gdg
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                      ghf
                    </TabPanel> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <DatePicker
                        id="approved-date"
                        label="Date approved"
                        value={values.approved_date}
                        onChange={(newValue) => {
                          setFieldValue("approved_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="approved_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Click to select",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        label="Approved by"
                        autoComplete="off"
                        name="approved_by"
                        id="approved-by"
                        placeholder="Your name here"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {keyDetailDialog && (
        <KeyDetail
          keyDetailDialog={keyDetailDialog}
          setKeyDetailDialog={setKeyDetailDialog}
          keyDetailDialogOpen={keyDetailDialogOpen}
          keyDetailDialogClose={keyDetailDialogClose}
          keyReqId={id}
          getInvolvedKeyDetailsListing={getInvolvedKeyDetailsListing}
          keyDetailData={keyDetailData}
          keyActionStatus={keyActionStatus}
          setPageLoad={setPageLoad}
        />
      )}
      {addAuthorizationPersonDialog && (
        <AddAuthorizationPerson
          addAuthorizationPersonDialog={addAuthorizationPersonDialog}
          setAddAuthorizationPersonDialog={setAddAuthorizationPersonDialog}
          addAuthorizationPersonDialogOpen={addAuthorizationPersonDialogOpen}
          addAuthorizationPersonDialogClose={addAuthorizationPersonDialogClose}
          keyReqId={id}
          // authPerId={authPerId}
          getInvolvedAuthPerListing={getInvolvedAuthPerListing}
          authActionStatus={authActionStatus}
          authPerData={authPerData}
          setPageLoad={setPageLoad}
        />
      )}
      {addAuthOverrideDialog && (
        <AddAuthOverride
          addAuthOverrideDialog={addAuthOverrideDialog}
          setAddAuthOverrideDialog={setAddAuthOverrideDialog}
          addAuthOverrideDialogOpen={addAuthOverrideDialogOpen}
          addAuthOverrideDialogClose={addAuthOverrideDialogClose}
          authPerId={authPerId}
          setPageLoad={setPageLoad}
          getInvolvedAuthPerListing={getInvolvedAuthPerListing}
        />
      )}
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function EditKeyRequisition() {
  return (
    <React.Fragment>
      <Helmet title="Edit Key Requisition" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Key Requisition
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/key-requisition">
          Key Requisition
        </Link>
        <Typography>Edit Key Requisition</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <EditKeyRequisitionForm />
    </React.Fragment>
  );
}

export default EditKeyRequisition;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { Typography, Paper as MuiPaper, Link, Grid } from "@mui/material";
import { spacing } from "@mui/system";
import { AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon } from "@mui/icons-material";
import { EmergencyProcedures } from "../../../../api";
import SubTypeCard from "./innerComponents/SubTypeCard";

const Paper = styled(MuiPaper)(spacing);
const Image = styled.img`
  width: 100%;
`;

function EmergencyTab({ item, setLoading }) {
  const [respond, setRespond] = useState(null);
  const [contact, setContact] = useState(null);
  const [emergencyProcedureData, setEmergencyProcedureData] = useState([]);

  const getProcedure = () => {
    let payload = {
      type: item?.type,
    };
    setLoading(true);
    EmergencyProcedures(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setEmergencyProcedureData(res.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProcedure();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        {emergencyProcedureData.map((item) => {
          return <SubTypeCard data={item} getProcedure={getProcedure} />;
        })}
      </Grid>
    </React.Fragment>
  );
}

export default EmergencyTab;

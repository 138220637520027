import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  NavLink,
  useParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { UpdateTaskButton } from "../../api";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  Switch,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  Tabs,
  Tab,
  Menu,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ChangeHistory as ChangeHistoryIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { event } from "jquery";
import { ToastConsumer } from "react-toast-notifications";
import { useSelector } from "react-redux";
import Toaster from "./Toaster";
const Button = styled(MuiButton)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LavelTable = styled((props) => <TableContainer {...props} />)(
  ({ theme }) => ({
    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiFormLabel-root": {
        whiteSpace: "nowrap",
        paddingRight: "10px",
        minWidth: "60px",
      },
    },
    "& .MuiTableCell-root": {
      padding: "5px 10px 5px 0px",
      border: "0px",
    },
  })
);
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const StyledBox = styled((props) => <Paper {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .Mui-selected": {
      backgroundColor: "#7aa8ff0d",
    },
    "& .MuiList-root::-webkit-scrollbar": {
      /* display: "none", */
    },
  },
}));
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
//Auto Select
const customNotification = [
  { title: "Not selected" },
  { title: "Option one" },
  { title: "Option two" },
  { title: "Option three" },
  { title: "Option four" },
  { title: "Option five" },
];
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(MuiInput)`
  width: 42px;
`;

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const StyledInputSlider = styled((props) => <Paper {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiInput-root::before": {
        borderBottom: "0px",
      },
      "& .MuiInput-root": {
        "& .MuiInput-input": {
          paddingBottom: "0px",
        },
      },
    },
  })
);
const StyledContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#7aa8ff0d",
    padding: "15px",
    marginTop: "10px",
    maxHeight: "542px",
    overflowY: "auto",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      borderBottom: "0px",
      marginBottom: "12px",
      "& .MuiTypography-subtitle2": {
        marginRight: "5px",
      },
      "& .MuiSvgIcon-root": {
        margin: "0px 6px",
        width: "12px",
        height: "12px",
        verticalAlign: "middle",
      },
    },
  },
}));
// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
//For on or off
const CustomSwitchOffOn = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"ON"',
      left: 15,
    },
    "&:after": {
      content: '"OFF"',
      right: "-2px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  })
);
const TaskButton = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const {
    taskbutton,
    taskButtonClose,
    setTaskButton,
    viewTask,
    TextAreaControl,
    classes,
    button,
  } = props;

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  // Check for permission for specific actions
  const hasPermissionToUpdateTaskButton = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "updateTaskButton" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const { tag_id, tag_description, location_hint_1 } = viewTask;
  const initialValues = {
    id: tag_id ? tag_id : "",
    btn_name: tag_description ? tag_description : "",
    location_hint: location_hint_1 ? location_hint_1 : "",
  };

  const validationSchema = Yup.object().shape({});

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let payload = {
        tag_description: values.btn_name,
        location_hint_1: values.location_hint,
      };
      UpdateTaskButton(tag_id, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            button();
            setOpenSnack(true);
            setTimeout(() => {
              setOpenSnack(false);
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message="Task button has been successfully updated "
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={taskbutton}
        // onClick={taskButtonClose}
        aria-labelledby="equipment-log"
        aria-describedby="equipment-log"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Edit Task Button
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton sx={{ marginRight: "-10px" }} onClick={taskButtonClose}>
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText sx={{ padding: "10px 25px 25px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={6}>
                      <FormControl mt={6}>
                        <TextField
                          id="id"
                          name="id"
                          label="ID"
                          value={values.id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="2760132329"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <TextField
                          id="btn-name"
                          name="btn_name"
                          label="Button name"
                          value={values.btn_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Men's locker room"
                        />
                      </FormControl>
                      <FormControl mt={3}>
                        <TextAreaControl
                          mt={3}
                          className={classes.textAreaControl}
                        >
                          <FormControl mt={1}>
                            <InputLabel id="v-status">Location hint</InputLabel>
                            <TextareaAutosize
                              style={{ color: "#000000", fontWeight: 400 }}
                              placeholder="Near lockers"
                              name="location_hint"
                              value={values.location_hint}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormControl>
                        </TextAreaControl>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl mt={5}>
                        <FormLabel>Location hint image</FormLabel>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          type="file"
                        />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} lg={4}>
                            <label htmlFor="raised-button-file">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={3}
                              >
                                Choose image
                              </Button>
                            </label>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={4}>
                            <label>
                              <Button
                                variant="contained"
                                color="warning"
                                component="span"
                                mt={3}
                              >
                                Place Arrows
                              </Button>
                            </label>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions
                    sx={{
                      paddingTop: "30px",
                      paddingBottom: "30px",
                      paddingRight: "20px",
                    }}
                  >
                    <Button
                      onClick={taskButtonClose}
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!hasPermissionToUpdateTaskButton}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TaskButton;

import React, { useState } from "react";
import { Switch } from "@mui/material";
import { ToggleImageRequired } from "../../../../api";

const ImageReqToggle = (props) => {
  const { value, id } = props;
  const [active, setActive] = useState(value === "yes" ? true : false);
  const handleActive = (status, id) => {
    let payload = {
      status: status === true ? "no" : "yes",
      IncidentTypeID: id,
    };
    ToggleImageRequired(payload)
      .then((res) => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Switch
        checked={active}
        onClick={() => {
          setActive(!active);
          handleActive(active, id);
        }}
      />
    </>
  );
};

export default ImageReqToggle;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";

const PoiMergeDialog = ({
  mergeDilaog,
  setMergeDilaog,
  mergeDilaogOpen,
  mergeDilaogClose,
  mergeRecord,
}) => {
  return (
    <div>
      {" "}
      <Dialog
        open={mergeDilaog}
        onClose={mergeDilaogClose}
        aria-labelledby="peak-dialog"
        aria-describedby="peak-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          id="poi-dialog"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">SovaSystem.com says</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={mergeDilaogClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Merging POI records create a new record with data from both merged
            records.After the merge, the 2 exiting POI records will be
            deleted.All Encounters for these 2 Poi record will be merged into
            one.Wolud you like to proceed?
          </Typography>
          <DialogActions>
            <Button variant="contained" onClick={() => mergeRecord(true)}>
              OK
            </Button>
            <Button variant="outlined" onClick={() => mergeRecord(false)}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PoiMergeDialog;

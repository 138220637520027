import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  Pagination,
  CircularProgress,
  DialogTitle,
  DialogActions,
  Dialog,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  AddBox as AddBoxIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { KeyRequisitionListing, DeleteKeyRequisition } from "../../api";
import Toaster from "../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import { handleApiResonseErrors } from "../../helper/helper";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Image = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 12px auto 0;
`;
//Classes Styling
const useStyles = makeStyles((theme) => ({
  decimalList: {
    listStyle: "decimal",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "decimal",
    },
  },
}));
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "180px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  nameRequesting,
  department,
  dateRequested,
  sigRemaining,
  dateApproved,
  action
) {
  return {
    nameRequesting,
    department,
    dateRequested,
    sigRemaining,
    dateApproved,
    action,
  };
}
const rows = [
  createData("dgdf dfhfghf", "", "10/25/2022", "0", "", 0),
  createData("dgdf dfhgfghf", "", "10/25/2022", "0", "", 0),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "nameRequesting",
    numeric: true,
    disablePadding: true,
    label: "Name Requesting",
  },
  {
    id: "department",
    numeric: true,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "dateRequested",
    numeric: true,
    disablePadding: false,
    label: "Date Requested",
  },
  {
    id: "sigRemaining",
    numeric: true,
    disablePadding: false,
    label: "Sig Remaining",
  },
  {
    id: "dateApproved",
    numeric: true,
    disablePadding: false,
    label: "Date Approved",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            // padding={headCell.disablePadding ? "none" : "normal"}'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {/* {headCell.icon} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, totalRecord } = props;
  const [actions, setActions] = React.useState("");

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [dateRange, setDateRange] = React.useState(null);
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  return (
    <>
      <Toolbar>
        {/* <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {DeleteKeyRequisition
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value={"Delete"}>Delete</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div> */}
        {/* <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {numSelected} records found
          </Typography>
        </div> */}
        <>
          {totalRecord > 0 ? (
            <Typography
              color="inherit"
              variant="subtitle1"
              mt={3}
              style={{ whiteSpace: "nowrap" }}
              // style={{ width: "max-content", marginLeft: "10px" }}
            >
              {totalRecord} records found
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              id="tableTitle"
              mt={3}
              style={{ whiteSpace: "nowrap" }}
              // style={{ width: "max-content", marginLeft: "10px" }}
            >
              {totalRecord} record found
            </Typography>
          )}
        </>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem component={NavLink} to="/logs/add-key-requisition">
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary="Add Key Requisition"
                  pl={0}
                />
              </MenuItem>
              {/* <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem> */}
            </StyledFilterMenu>
            {/* <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      6 out of 6 visible
                    </Typography>
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked disableRipple />}
                          label="Name Requesting"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Department"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Date Requested"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Sig Remaining"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Date Approved"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Action"
                        />
                      </FormGroup>
                    </Box>
                    <Toolbar>
                      <Button color="primary" variant="contained">
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown> */}
          </Paper>
        </div>
      </Toolbar>
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [requisitionList, setRequisitionList] = useState([]);
  const [keyReqId, setKeyReqId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const [isDate, setIsDate] = useState(null);
  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const dispatch = useDispatch();

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.nameRequesting);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, nameRequesting) => {
    const selectedIndex = selected.indexOf(nameRequesting);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nameRequesting);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (nameRequesting) =>
    selected.indexOf(nameRequesting) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, reqId, date) => {
    setKeyReqId(reqId);
    setAnchorEl(event.currentTarget);
    setIsDate(date);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  //handler for deleting the tour
  const deleteKeyReq = () => {
    handleDeleteClose();
    DeleteKeyRequisition(keyReqId)
      .then((res) => {
        if (res.statusCode == 200) {
          getKeyRequisitionList();
          dispatch(getMsg("Key requisition deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        // console.log("err");
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setKeyReqId(null);
  };

  // Methods for calling the APIs
  const getKeyRequisitionList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
    }; //this will be used for sending filtered data
    KeyRequisitionListing(currentPage, { params: payload })
      .then((res) => {
        if (res.statusCode == 200) {
          setRequisitionList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setRequisitionList(res.data);
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        // dispatch(sendIrLogList([]));
      });
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  useEffect(() => {
    getKeyRequisitionList();
  }, [rowsPerPage, currentPage]);

  return (
    <>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this key req ?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={(handleDeleteClose, deleteKeyReq)}
            autoFocus
            color="primary"
            sx={{ ml: "10px" }}
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  {/* <TableCell colSpan={8} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <>
                <TableBody>
                  {stableSort(requisitionList, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.nameRequesting);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) =>
                          //   handleClick(event, row.nameRequesting)
                          // }
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                            align="left"
                            sx={{
                              whiteSpace: "nowrap",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {row.name_requesting}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              whiteSpace: "nowrap",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {row.department}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              whiteSpace: "nowrap",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {row.request_date}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              whiteSpace: "nowrap",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {row.sig_remaining}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              whiteSpace: "nowrap",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {row.approved_date}
                          </TableCell>
                          <TableCell align="left">
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={anchorEl ? "icon-menu" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleActionClick(e, row.id, row.approved_date);
                              }}
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="icon-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                aria-label="view-covid19-survey"
                                component={NavLink}
                                to={`/logs/edit-key-requisition/${keyReqId}`}
                                sx={{
                                  display: isDate == null ? "flex" : "none",
                                }}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>

                              <MenuItem
                                onClick={(handleActionClose, handleDeleteOpen)}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={10} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
    </>
  );
}

function KeyRequisition() {
  return (
    <React.Fragment>
      <Helmet title="Key Requisition" />
      <Typography variant="h3" gutterBottom display="inline">
        Key Requisition
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default KeyRequisition;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  FormLabel,
  Alert as MuiAlert,
  Paper,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import Textfield from "./components/Textfield";
import { makeStyles } from "@mui/styles";
//import { getSnackOpen, getSnackClose } from "../../redux/slices/corporateSlice";
import { useDispatch } from "react-redux";
import { ViewCorporate, UpdateCorporate } from "../../api";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../redux/slices/corporateSlice";
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function EditForm() {
  const classes = useStyles();
  const [editList, setEditList] = useState({});
  const [corpLocation, setCorpLocation] = useState([]);
  const { id } = useParams(); //Getting the edit user Id
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);

  const { corporate_name, first_name, last_name, title, email, phone, fax } =
    editList;
  //Initial values Formik
  const initialValues = {
    corporateName: corporate_name ? corporate_name : "",
    firstName: first_name ? first_name : "",
    lastName: last_name ? last_name : "",
    title: title ? title : "",
    email: email ? email : "",
    phone: phone ? phone : "",
    fax: fax ? fax : "",
  };

  //Yup validation schema
  const validationSchema = Yup.object().shape({
    corporateName: Yup.string().required("Please enter corporate name."),
    firstName: Yup.string().required("Please enter first name."),
    lastName: Yup.string().required("Please enter last name."),
    title: Yup.string().required("Please enter title."),
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Please enter email address."),
    phone: Yup.string().matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})?[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      "Please enter a valid phone number."
    ),
    fax: Yup.string().matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})?[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      "Please enter a valid fax number."
    ),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setIsDisabled(true);
    setPageLoad(true);
    try {
      let formData = {
        corporate_name: values.corporateName,
        first_name: values.firstName,
        last_name: values.lastName,
        title: values.title,
        email: values.email,
        phone: values.phone,
        fax: values.fax,
      };
      await // timeOut(500);
      UpdateCorporate(id, formData)
        .then((res) => {
          if (res.statusCode === 200) {
            setIsDisabled(false);
            // dispatch(getSnackOpen());
            navigate({
              pathname: "/accounts/list-corporate",
            });
            dispatch(getSnackMsg("Corporate has been updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            // dispatch(getSnackClose());
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
          setPageLoad(false);
        })
        .catch((err) => {
          console.log(err);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setIsDisabled(false);
          setPageLoad(false);
        });
      console.log(values);
      // resetForm();
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      setIsDisabled(false);
      setPageLoad(false);
    }
  };
  const getEditList = () => {
    ViewCorporate(id)
      // axios
      //   .get(`view-corporate/${id}`)
      .then((response) => {
        setEditList(response.data);
        setCorpLocation(response.data.location);
        setPageLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setPageLoad(false);
      });
  };

  useEffect(() => {
    setPageLoad(true);
    getEditList();
  }, [id]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
          setFieldValue,
        }) => (
          <Card mb={6}>
            <CardContent>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  Your data has been submitted successfully!
                </Alert>
              )}

              {
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                      <FormControl mt={3}>
                        <Textfield
                          name="corporateName"
                          label="Corporate name"
                          required
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                      <Typography mt={4}>
                        {corpLocation.length > 0
                          ? "Property:"
                          : "No property selected"}
                      </Typography>
                      <ul style={{ margin: 0 }}>
                        {corpLocation.map((locName) => (
                          <li>{locName}</li>
                        ))}
                      </ul>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <FormLabel component="label">
                          Contact information:
                        </FormLabel>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <Textfield
                          name="firstName"
                          label="First name"
                          required
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormControl>
                          <Textfield
                            name="lastName"
                            label="Last name"
                            required
                            m={2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormControl>
                          <Textfield
                            name="title"
                            label="Title"
                            required
                            m={2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <Textfield
                          name="email"
                          label="Email"
                          required
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <MuiPhoneNumber
                          name="phone"
                          id="phone"
                          label="Phone"
                          value={values.phone}
                          onChange={(value) => setFieldValue("phone", value)}
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultCountry={"us"}
                          variant="outlined"
                          disableAreaCodes={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <Textfield
                          name="fax"
                          label="Fax"
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        mt={6}
                        sx={{ width: "175px" }}
                        disabled={isDisabled}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              }
            </CardContent>
          </Card>
        )}
      </Formik>
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function EditCorporate() {
  return (
    <React.Fragment>
      <Helmet title="Add Corporate" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Corporate
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/accounts/list-corporate">
          Accounts
        </Link>
        <Typography>Edit Corporate</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <EditForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EditCorporate;

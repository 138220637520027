import React from "react";
import styled from "styled-components/macro";
import CustomTextField from "../../form-components/CustomTextField";
import { Formik } from "formik";
import { handleApiResonseErrors } from "../../../helper/helper";
import { SaveLockedStatus } from "../../../api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  FormControl as MuiFormControl,
  Typography,
  IconButton,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const LockItem = ({
  show,
  handleModal,
  fromData,
  setLoading,
  alertTost,
  GetSingleRecord,
  setPreLoader,
}) => {
  let initialValues = {
    locked_item_reason: fromData
      ? fromData.locked_item_reason
        ? fromData.locked_item_reason
        : ""
      : "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        id: fromData.id,
        locked_item_reason: values.locked_item_reason,
      };

      SaveLockedStatus(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            handleModal(false);
            setPreLoader(true);
            GetSingleRecord();
            alertTost("Record has been updated successfully", "success");
          } else {
            setLoading(false);
            alertTost("Sorry! something wrong please try again", "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            alertTost(error, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      alertTost("Sorry! something wrong please try again", "error");
    }
  };
  return (
    <>
      <Dialog
        open={show}
        aria-labelledby="lockitem-title"
        aria-describedby="lockitem-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="div">
            Lock Item: {fromData.description}
          </Typography>
          <DialogActions onClick={() => handleModal(false)}>
            <IconButton sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="lockitem-description">
            You have chosen to lock this item. Locked items cannot be modified
            in any way. You cannot discard a locked item. You can clear a lock
            applied to an item by removing the checkbox and saving the record.
            Please add a reason for the lock below. If another user attempts to
            modify this locked record, they will be shown the reason for the
            lock that your enter here.
          </DialogContentText>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormControl mt={6}>
                  <CustomTextField
                    name="locked_item_reason"
                    label="Reason for lock"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                    placeholder="Add a reason for applying this lock. Other users will be able to see this reason if they try editing the item. Be sure to remove this lock when you are ready to claim or dispose of the item."
                    multiline={true}
                    rows={6}
                  />
                </FormControl>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LockItem;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { UpdateKeyBuilding } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import EditKeyLockNumber from "./EditKeyLockNumber";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const EditKeyBuilding = ({
  addKeyBuildingClose,
  addKeyBuildingList,
  editKeyBuilding,
  editKeyBuildingOpen,
  editKeyBuildingClose,
  singleData,
  buildingId,
}) => {
  // Action Dropdown
  const [errMsg, setErrMsg] = useState("");

  const dispatch = useDispatch();

  const {
    address1,
    address2,
    admin_email,
    admin_fax,
    admin_fname,
    admin_lname,
    admin_phone,
    city,
    name,
    state,
    zip,
  } = singleData;
  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    bulding_name: Yup.string().required("Please enter key building name"),
    building_addr_1: Yup.string().required(
      "Please enter key building address1"
    ),
    building_addr_2: Yup.string().required(
      "Please enter key building address1"
    ),
    building_city: Yup.string().required("Please enter key building city"),
    building_state: Yup.string().required("Please enter key building state"),
    building_zip: Yup.string().required("Please enter key building zip"),
    admin_first_name: Yup.string().required(
      "Please enter key building admin first name"
    ),
    admin_last_name: Yup.string().required(
      "Please enter key building admin last name"
    ),
    admin_email: Yup.string().required("Please enter key building admin email"),
    admin_phone: Yup.string().required(
      "Please enter key building admin admin phone"
    ),
    admin_fax: Yup.string().required("Please enter key building admin fax"),
  });

  /* Setting the initial values for the form. */
  let initialValues = {
    bulding_name: name ? name : "",
    building_addr_1: address1 ? address1 : "",
    building_addr_2: address2 ? address2 : "",
    building_city: city ? city : "",
    building_state: state ? state : "",
    building_zip: zip ? zip : "",
    admin_first_name: admin_fname ? admin_fname : "",
    admin_last_name: admin_lname ? admin_lname : "",
    admin_email: admin_email ? admin_email : "",
    admin_phone: admin_phone ? admin_phone : "",
    admin_fax: admin_fax ? admin_fax : "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        id: buildingId,
        name: values.bulding_name,
        address1: values.building_addr_1,
        address2: values.building_addr_2,
        city: values.building_city,
        state: values.building_state,
        zip: values.building_zip,
        admin_fname: values.admin_first_name,
        admin_lname: values.admin_last_name,
        admin_email: values.admin_email,
        admin_phone: values.admin_phone,
        admin_fax: values.admin_fax,
      };
      UpdateKeyBuilding(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key building has been updated successfully"));
            dispatch(getSnackAlert("success"));
            editKeyBuildingClose();
            addKeyBuildingList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={editKeyBuilding}
        onClocse={editKeyBuildingClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="add-key-building-title"
        aria-describedby="add-key-building-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-building-title"
        >
          <Typography variant="h4">Add Key building</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={editKeyBuildingClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="bulding_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_addr_1"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building address1"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_addr_2"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building address2"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_city"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building city"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_state"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building state"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_zip"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building zip"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="admin_first_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin first name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="admin_last_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin last name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="admin_email"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin email"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="admin_phone"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin phone"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="admin_fax"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin fax"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="outlined" type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={addKeyBuildingClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditKeyBuilding;

export default [
  {
    id: "barcode_id",
    label: "Barcode ID",
    tooltipMsg: "",
  },
  {
    id: "fname",
    label: "First Name",
    tooltipMsg: "",
  },
  {
    id: "last_name",
    label: "Last Name",
    tooltipMsg: "",
  },
  {
    id: "department",
    label: "Department",
    tooltipMsg: "",
  },
  {
    id: "position",
    label: "Position",
    tooltipMsg: "",
  },
  {
    id: "sl",
    label: " SL",
    tooltipMsg: "The Security Level of Equipment User",
  },
  {
    id: "updated",
    label: "Updated",
    tooltipMsg: "",
  },
  {
    id: "last_seen",
    label: "Last Seen",
    tooltipMsg: "",
  },
  {
    id: "active",
    label: "Active",
    tooltipMsg: "",
  },
  {
    id: "out",
    label: "Out",
    tooltipMsg: "Count of items currently checked out to the user",
  },
  {
    id: "pcount",
    label: "PCount",
    tooltipMsg: "Count of equipment items this user can check out",
  },
  {
    id: "action",
    label: "Action",
    tooltipMsg: "",
  },
];

import React, { createContext, useContext, useEffect } from "react";
import * as Ably from "ably";
import { useToasts } from "react-toast-notifications";

const AblyContext = createContext();

const ably = new Ably.Realtime({
  key: "FiSX9g.xIo36w:LldqceEFINznqGLI5RnDcfq3isTKQYJoEX8SXbz-Qyg",
});

export const useAbly = () => useContext(AblyContext);

export const AblyProvider = ({ children, channels }) => {
  const { addToast } = useToasts();

  useEffect(() => {
    const subscriptions = channels.map(({ channelName }) => {
      const channel = ably.channels.get(channelName);
      const handler = (message) => {
        // Check if the message data is an object with a 'message' key
        if (typeof message.data === "object" && message.data.message) {
          addToast(`${message.data.message}`, {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 10000,
          });
        } else {
          addToast(`${message.data}`, {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 10000,
          });
        }
      };
      channel.subscribe(handler);
      return () => channel.unsubscribe(handler);
    });

    // Cleanup subscriptions on component unmount
    return () => subscriptions.forEach((unsubscribe) => unsubscribe());
  }, [channels, addToast]);

  return <AblyContext.Provider value={{}}>{children}</AblyContext.Provider>;
};

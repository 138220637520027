import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { AddRange } from "../../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";

const IpRangeDialog = ({
  ipRangeDialog,
  setIpRangeDialog,
  ipRangeDialogOpen,
  ipRangeDialogClose,
  updatedRow,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(updatedRow);
  console.log("formdata", formData);
  const handleChange = (event) => {
    const fieldValue = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: fieldValue,
    }));
  };

  console.log("changeData", formData);
  const addRange = () => {
    let payload = {
      id: formData.id ? formData.id : null,
      start: formData.start,
      end: formData.end,
    };
    AddRange(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          ipRangeDialogClose();
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          onUpdate(res.data);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  return (
    <div>
      {" "}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={ipRangeDialog}
        onClose={ipRangeDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h3" gutterBottom>
            Add IP Range
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setIpRangeDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={6} mt={4}>
              <TextField
                mt={4}
                id="outlined"
                label="IP Start Address"
                name="start"
                value={formData ? formData.start : ""}
                placeholder="valid IP4 starting range"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6} mt={4}>
              <TextField
                id="outlined"
                label="IP End Address"
                name="end"
                value={formData ? formData.end : ""}
                placeholder="valid IP4 starting range"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={ipRangeDialogClose}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              addRange();
            }}
            variant="contained"
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IpRangeDialog;

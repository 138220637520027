import React, { useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogActionsIconButton,
  DialogContent,
  DialogContentText,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  DialogActions,
  IconButton,
  InputLabel,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { TextField } from "formik-mui";
import Toaster from "../tasks/Toaster";
import { Copytour } from "../../api";
const CopyTour = (props) => {
  const {
    copyTourOpen,
    copyTour,
    copyTourClose,
    setCopyTour,
    propertyList,
    tourId,
    getTourList,
  } = props;
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  //Select Location
  const [location, setLocation] = useState("");
  const selectLocation = (event) => {
    setLocation(event.target.value);
    console.log(event.target.value);
  };
  const handleCopyTour = () => {
    Copytour(tourId, { location_id: location })
      .then((res) => {
        if (res.statusCode === 200) {
          getTourList();
          setOpenSnack(true);
        } else {
          setOpenSnack(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message="Copy tour has been successfully submitted "
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={copyTour}
        onClose={copyTourClose}
        aria-labelledby="visitor-modal"
        aria-describedby="visitor-modal"
      >
        <DialogTitle
          id="visitor-modal"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Copy Tour
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setCopyTour(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText id="visitor-modal" sx={{ padding: "20px 20px" }}>
            <FormLabel>
              Select the site from the list below to copy this tour to. You will
              repeat this process for each tour you wish to copy to another
              site.
            </FormLabel>
            <FormControl
              mt={6}
              sx={{
                width: "100%",
                marginTop: "15px",
              }}
            >
              <InputLabel id="copy-tour">Copy tour</InputLabel>
              <Select
                fullWidth
                id="location"
                name="location"
                label="Copy tour"
                onChange={selectLocation}
                value={location}
                variant="outlined"
                labelId="copy-tour"
                displayEmpty
              >
                <MenuItem value="">Select Location</MenuItem>
                {propertyList.map((data) => {
                  return (
                    <MenuItem value={data.LocationID} key={data.LocationID}>
                      {data.LocationName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
          <Button onClick={copyTourClose} color="primary" variant="outlined">
            Close
          </Button>
          <Button
            color="primary"
            sx={{ marginLeft: "10px" }}
            variant="contained"
            onClick={() => {
              handleCopyTour();
              copyTourClose();
            }}
          >
            Copy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CopyTour;

import React from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  FormControl as MuiFormControl,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { SendEmailManager } from "../../api";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";

const useStyles = makeStyles((theme) => ({
  FormInquiry: {
    "& .MuiFormControl-root": {
      width: "100%",
      minWidth: "auto",
    },
  },
}));

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const EmailDialog = (props) => {
  const classes = useStyles();
  const {
    emailModal,
    setEmailModal,
    emailModalOpen,
    emailModalClose,
    recordId,
    recordObj,
  } = props;
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const dispatch = useDispatch();
  const initialValues = {
    inputEmail: LoggedInUserInfo.Email ? LoggedInUserInfo.Email : "",
    inputComment: "",
  };

  const validationSchema = Yup.object().shape({
    inputEmail: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter email address"),
    inputComment: Yup.string().required("Please enter comment"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let payload = {
      id: recordId,
      email: values.inputEmail,
      comments: values.inputComment,
    };
    SendEmailManager(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("Email sent to the manager"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          emailModalClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          emailModalClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        emailModalClose();
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={emailModal}
        onClose={emailModalClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <DialogTitle
            id="viewmap-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="h4" component="div" gutterBottom>
              Send email to manager for {recordObj.name}
            </Typography>
            <DialogActions>
              <IconButton
                sx={{ marginLeft: "160px" }}
                onClick={() => setEmailModal(false)}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
        </Grid>
        <DialogContent>
          <DialogContentText>
            Send a notification to the manager responsible to EAR by adding
            theri email and any optional comments in the fields below.The
            manager will receive an email link to this EAR so they can provide
            their details regarding the accidents.The link expires after 72
            hours.If the manager needs access to the EAR after that time,simply
            send them another link.
          </DialogContentText>
          {/* <Typography variant="body2" gutterBottom mt={4}>
            Manager email
          </Typography>
          <Grid item xl={12}>
            <TextField
              variant="outlined"
              id="outlined-basic"
              placeholder="dscgdgc@gmail.com"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Typography variant="body2" gutterBottom mt={4}>
            Comments for the manager
          </Typography>
          <Grid item xl={12}>
            <TextField
              variant="outlined"
              id="outlined-basic"
              placeholder="Comments regarding the employee accidents"
              multiline
              rows={3}
              sx={{
                width: "100%",
              }}
            />
          </Grid> */}
          <Box className={classes.FormInquiry}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl mt={3}>
                    <TextField
                      name="inputEmail"
                      value={values.inputEmail}
                      error={Boolean(touched.inputEmail && errors.inputEmail)}
                      fullWidth
                      helperText={touched.inputEmail && errors.inputEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      autoComplete="off"
                      id="reply-to"
                      label="Manager Email"
                      sx={{ input: { color: "red" } }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="me@you.com"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      name="inputComment"
                      label="Comment *"
                      placeholder="Comment relating to the activity"
                      value={values.inputComment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.inputComment && errors.inputComment
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      helperText={touched.inputComment && errors.inputComment}
                      multiline={true}
                      rows={6}
                    />
                  </FormControl>
                  <DialogActions sx={{ padding: "20px 24px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={emailModalClose}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained">
                      Send Email
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailDialog;

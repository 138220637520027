import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  FormControl as MuiFormControl,
  IconButton,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const SelectionDialog = ({ data, selectionDialog, selectionDialogClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (values) => {
    values.selectionType === "editor"
      ? navigate(
          `/logs/edit-emergency-procedures/editor/${data.type}/${data.sub_type}`
        )
      : navigate(
          `/logs/edit-emergency-procedures/pdf/${data.type}/${data.sub_type}`
        );
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
          },
        },
      }}
      aria-labelledby="comment-title"
      aria-describedby="comment-description"
      open={selectionDialog}
      onClose={selectionDialogClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        id="comment-title"
      >
        <Typography variant="h4">Select Type</Typography>
        <DialogActions>
          <IconButton
            onClick={selectionDialogClose}
            sx={{ marginRight: "-10px" }}
          >
            <CancelRoundedIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ selectionType: "editor" }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <FormControl>
                <FormLabel component="legend">Method of procedures</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="select-type"
                  value={values.selectionType}
                  onChange={(event) => {
                    setFieldValue("selectionType", event.target.value);
                  }}
                  name="selectionType"
                >
                  <FormControlLabel
                    value="editor"
                    control={<Radio />}
                    label="Editor"
                  />
                  <FormControlLabel
                    value="pdf"
                    control={<Radio />}
                    label="Upload Pdf"
                  />
                </RadioGroup>
              </FormControl>
              <DialogActions sx={{ padding: "20px 0" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={selectionDialogClose}
                >
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  Continue
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SelectionDialog;

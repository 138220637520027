import React, { useState, useEffect } from "react";
import NewTrainingVideo from "./inner-component/NewTrainingVideo";
import {
  Typography,
  Grid,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Card,
  Table,
  IconButton,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { BorderColor as BorderColorIcon } from "@mui/icons-material";
import { VideoList } from "../../../api";

const YoutubeVideos = () => {
  const [listVideo, setListVideo] = useState([]);
  const [updatedRow, setUpdatedRow] = useState({});
  const [loading, setLoading] = useState(false);
  const [newTrainingVideoDialog, setNewTrainingVideoDialog] =
    React.useState(false);
  const newTrainingVideoDialogOpen = () => {
    setNewTrainingVideoDialog(true);
  };
  const handleClick = (event, row) => {
    setUpdatedRow(row);
  };
  const newTrainingVideoDialogClose = () => {
    setNewTrainingVideoDialog(false);
  };
  const videoList = () => {
    setLoading(true);
    VideoList()
      .then((res) => {
        if (res.statusCode == 200) {
          setLoading(false);
          setListVideo(res.data);
        } else {
          setLoading(false);
          setListVideo([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    videoList();
  }, []);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4}>
          <Typography variant="h3" gutterBottom>
            Training Video Link
          </Typography>
        </Grid>
        <Grid item lg={5} md={5}></Grid>
        <Grid item lg={3} md={3}>
          <Button
            onClick={() => {
              setUpdatedRow(null);
              newTrainingVideoDialogOpen();
            }}
            variant="outlined"
          >
            New training video
          </Button>
        </Grid>
      </Grid>
      <Card
        sx={{
          marginTop: "25px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="left">Page URL</TableCell>
              <TableCell align="left">Summary</TableCell>
              <TableCell align="left">Modify Date</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableCell colSpan={13} align="center">
              <CircularProgress />
            </TableCell>
          ) : (
            <TableBody>
              {listVideo.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="left">{row.page_url}</TableCell>
                  <TableCell align="left">{row.summary}</TableCell>
                  <TableCell align="left">{row.modified}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={(event) => {
                        setUpdatedRow(row);
                        handleClick(event, row);
                      }}
                    >
                      <BorderColorIcon
                        fontSize="small"
                        onClick={(event) => {
                          setUpdatedRow(row);
                          newTrainingVideoDialogOpen();
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {listVideo.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </Card>
      {newTrainingVideoDialog && (
        <NewTrainingVideo
          newTrainingVideoDialog={newTrainingVideoDialog}
          setNewTrainingVideoDialog={setNewTrainingVideoDialog}
          newTrainingVideoDialogOpen={newTrainingVideoDialogOpen}
          newTrainingVideoDialogClose={newTrainingVideoDialogClose}
          updatedRow={updatedRow}
          videoList={videoList}
        />
      )}
    </>
  );
};

export default YoutubeVideos;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lostFoundListing: {
    id: true,
    action: true,
    category: true,
    lost_date: true,
    found_name: true,
    found_date: true,
    description: true,
    found_location: true,
    storage_location: true,
    disposition_status: true,
  },
  shipments: {
    lostfound_id: true,
    amount_paid: true,
    carrier: true,
    service: true,
    created: true,
    tracking_status: true,
    tracking_id: true,
    recipient: true,
  },
  adminShipments: {
    senderid: true,
    senderaddr: true,
    receiverid: true,
    recipientname: true,
    recipientaddr: true,
    shipdetailby: true,
    residential: true,
    recipientphone: true,
    recipientemail: true,
    trackingid: true,
    carrier: true,
    servicelevel: true,
    length: true,
    width: true,
    height: true,
    weight: true,
    amountpaid: true,
    freightcost: true,
    processingcost: true,
    salestax: true,
    insurance: true,
    trackingstatus: true,
    finaltrackingmessage: true,
    created: true,
    modified: true,
  },
  activityLogs: {
    id: true,
    activity: true,
    category: true,
    description: true,
    UserName: true,
    created: true,
  },
};

export const defaultLostfoundTableCalSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
});

export const {} = defaultLostfoundTableCalSlice.actions;

export default defaultLostfoundTableCalSlice.reducer;

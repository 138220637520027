import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import { ActiveCarrier, ShipmentList } from "../../api";
import ShipmentsCol from "../table-columns-json/lostfound/Shipments";
import { useSelector, useDispatch } from "react-redux";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import { setShipments } from "../../redux/slices/lostfound/lostfoundTableCalSlice";

import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../utils/withPermissionFallback";

// Classes Styling
const useStyles = makeStyles((theme) => ({
  alphabets: {
    marginTop: "15px",
    "& .MuiList-root": {
      display: "flex",
      overflowX: "auto",
      background: "#7aa8ff0d",
      padding: "0px",
      "& .MuiListItemButton-root": {
        paddingLeft: "13px",
        paddingRight: "13px",
        textAlign: "center",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Image = styled.img``;
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  carrier,
  service,
  rate,
  recipient,
  created,
  id,
  tstatus,
  tnumber
) {
  return {
    carrier,
    service,
    rate,
    recipient,
    created,
    id,
    tstatus,
    tnumber,
  };
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, tableCalRedux } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {ShipmentsCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, filterRecord, tableCalRedux } = props;

  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [searchUserName, setSearchUserName] = useState("");
  const [activeCarrierList, setActiveCarrierList] = useState([]);
  const [trackingStatus, setTrackingStatus] = useState("");
  const [carrierChosen, setCarrierChosen] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLostfoundTableCal.shipments
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  const [fadebox, setFadedBox] = useState(false);
  const classes = useStyles();

  const applySearch = () => {
    const payload = {
      from_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      to_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      name: searchUserName,
      tracking_status: trackingStatus,
      carrier_chosen: carrierChosen,
    };
    filterRecord(payload);
  };

  const clearFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    setSearchUserName("");
    setTrackingStatus("");
    setCarrierChosen("");
    filterRecord({});
  };

  useEffect(() => {
    getActiveCarrier();
  }, []);

  const getActiveCarrier = () => {
    ActiveCarrier()
      .then((res) => {
        if (res.statusCode == 200) {
          setActiveCarrierList(res.data);
        } else {
          setActiveCarrierList([]);
        }
      })
      .catch((err) => {});
  };

  return (
    <Toolbar>
      <div>
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content", marginLeft: "15px" }}
        >
          {numSelected} records found
        </Typography>
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              className={classes.searchFilter}
              id="search"
              type="text"
              placeholder="Search by recipient name, or click down arrow"
              value={searchUserName}
              autoComplete="off"
              inputProps={{}}
              onChange={(e) => {
                setSearchUserName(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Date range"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM-DD-YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM-DD-YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="result"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Carrier
                            </InputLabel>
                            <Select
                              labelId="Carrier"
                              id="result"
                              name="result"
                              value={carrierChosen}
                              onChange={(e) => {
                                setCarrierChosen(e.target.value);
                              }}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Please select</MenuItem>
                              {activeCarrierList.map((row, index) => {
                                return (
                                  <MenuItem value={row.value} key={index}>
                                    {row.key}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>

                          <FormControl>
                            <InputLabel
                              shrink
                              id="result"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Tracking Status
                            </InputLabel>
                            <Select
                              labelId="Tracking Status"
                              id="result"
                              name="result"
                              value={trackingStatus}
                              onChange={(e) => {
                                setTrackingStatus(e.target.value);
                              }}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Please select</MenuItem>
                              <MenuItem value="Delivered">Delivered</MenuItem>
                              <MenuItem value="Transit">Transit</MenuItem>
                              <MenuItem value="Unknown">Unknown</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className={classes.cardHeader}>
                            <Button
                              color="primary"
                              variant="contained"
                              ml={3}
                              onClick={(e) => {
                                handleCloseForm();
                                applySearch();
                                setIsFilter(true);
                              }}
                            >
                              Filter
                            </Button>
                          </Box>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  setIsFilter(false);
                  clearFilter();
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                  width: "100%",
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchUserName !== "") {
                    applySearch();
                    setIsFilter(true);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setShipments(data));
                    }}
                    listing={ShipmentsCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setShipments(defaultTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataToFilter, setDataToFilter] = useState({});
  const [lists, setLists] = useState([]);
  const [totalRecordinDb, setTotalRecordinDb] = useState(0);
  const [loading, setLoading] = useState(false); //State for loader
  const [currentPage, setCurrentPage] = useState(0);

  const tableCalRedux = useSelector(
    (state) => state.lostfoundTableCal.shipments
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    getList();
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter]);

  const getList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    ShipmentList(currentPage + 1, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLists(res.data);
          setTotalRecordinDb(res.meta.total);
        } else {
          setLists([]);
          setTotalRecordinDb(0);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={totalRecordinDb}
          dataToFilter={dataToFilter}
          filterRecord={(data) => filterRecord(data)}
          tableCalRedux={tableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={lists.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={8} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {lists.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ lg: 'whiteSpace: "nowrap"' }}
                        className={
                          tableCalRedux.carrier === true ? "" : "hide-cell"
                        }
                      >
                        <Image src={row.img_src} alt={row.carrier_choosed} />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.service === true ? "" : "hide-cell"
                        }
                      >
                        {row.service}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.amount_paid === true ? "" : "hide-cell"
                        }
                      >
                        {row.amountPaid}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.recipient === true ? "" : "hide-cell"
                        }
                      >
                        {row.recipient_addr}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.created === true ? "" : "hide-cell"
                        }
                      >
                        {moment(row.created).format("YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.lostfound_id === true ? "" : "hide-cell"
                        }
                      >
                        {row.lostfound_id}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.tracking_status === true
                            ? ""
                            : "hide-cell"
                        }
                      >
                        {row.tracking_status}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.tracking_id === true ? "" : "hide-cell"
                        }
                      >
                        {row.tracking_id}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {lists.length < 1 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * lists.length }}>
                    <TableCell colSpan={8} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40, 50]}
          component="div"
          count={totalRecordinDb}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function Shipments() {
  return (
    <React.Fragment>
      <Helmet title="Lost and Found Shipments" />
      <WithPermissionFallback controller="LostAndFound" action="shipmentList">
        <Typography variant="h3" gutterBottom display="inline">
          Lost and Found Shipments
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default Shipments;

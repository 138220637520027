import React from "react";

import { RadioGroup, Radio, FormControlLabel } from "@mui/material";

const MergePoiRadioOne = ({
  firstRecord,
  selectedRecord,
  handleChecked,
  objKey,
  lableText,
}) => {
  return (
    <>
      <RadioGroup>
        <FormControlLabel
          checked={firstRecord[objKey] === selectedRecord[objKey]}
          value={firstRecord[objKey]}
          onChange={() => handleChecked(objKey, firstRecord[objKey])}
          control={<Radio required={true} name={objKey} />}
          label={lableText}
        />
      </RadioGroup>
    </>
  );
};

export default MergePoiRadioOne;

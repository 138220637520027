export default [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "host",
    label: "Host",
  },
  {
    id: "company",
    label: "Company",
  },
  {
    id: "recent",
    label: "Recent",
  },
  {
    id: "issued",
    label: "Issued",
  },
  {
    id: "expiry",
    label: "Expiry",
  },
  {
    id: "active",
    label: "Active",
  },
  {
    id: "status",
    label: "Disposition",
  },
  {
    id: "action",
    label: "Action",
  },
];

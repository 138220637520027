import { Formik } from "formik";
import styled from "styled-components/macro";
import React, { useState, useEffect } from "react";
import MergePoiRadioOne from "./inner-component/MergePoiRadioOne";
import MergePoiRadioTwo from "./inner-component/MergePoiRadioTwo";
import PoiMergeDialog from "./PoiMergeDialog";
import { makeStyles } from "@mui/styles";
import { ViewPoi, MergeSelectedPoi } from "../../api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Table,
  TableRow,
  TableCell,
  Box,
  TableContainer,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";

import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
//poi merge dialog

const Image = styled.img`
  height: 100px;
  margin-bottom: 20px;
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const PoiMergeModal = ({
  poiModal,
  setPoiModal,
  poiModalOpen,
  poiModalClose,
  selected,
  showAlertTost,
  getPoiList,
  resetSelectedId,
}) => {
  const [mergeDilaog, setMergeDilaog] = useState(false);
  const [preLoader, setPreLoader] = useState(true);
  const [loader, setLoader] = useState(false);

  const [firstRecord, setFirstRecord] = useState({});
  const [secondRecord, setSecondRecord] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});

  const initialValues = {};

  const classes = useStyles();

  const mergeDilaogOpen = () => {
    setMergeDilaog(true);
  };
  const mergeDilaogClose = () => {
    setMergeDilaog(false);
  };

  useEffect(() => {
    getPoiData();
  }, []);

  const getPoiData = () => {
    let firstId = selected[0];
    let secondId = selected[1];
    ViewPoi(firstId)
      .then((res) => {
        if (res.statusCode === 200) {
          setFirstRecord(res.data);
        }
        ViewPoi(secondId)
          .then((res) => {
            if (res.statusCode === 200) {
              setSecondRecord(res.data);
            }
            setPreLoader(false);
          })
          .catch((err) => {
            setPreLoader(false);
          });
      })
      .catch((err) => {
        setPreLoader(false);
      });
  };

  const isSameValue = (value1, value2) => {
    if (value1 === value2) {
      return false;
    } else {
      return true;
    }
  };
  const handleChecked = (key, value) => {
    let obj = {
      ...selectedRecord,
      [key]: value,
    };
    setSelectedRecord(obj);
  };

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      mergeDilaogOpen();
    } catch (err) {}
  };

  const mergeRecord = (status) => {
    mergeDilaogClose();
    if (status) {
      setLoader(true);
      let recordToSave = {
        ...firstRecord,
        ...selectedRecord,
        second_id: selected[1],
      };
      MergeSelectedPoi(recordToSave)
        .then((res) => {
          if (res.statusCode === 200) {
            getPoiList();
            resetSelectedId();
            showAlertTost(res.message, "success");
          } else {
            showAlertTost("Sorry! something wrong please try again", "error");
          }
          poiModalClose();
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          poiModalClose();
          showAlertTost("Sorry! something wrong please try again", "error");
        });
    }
  };

  return (
    <div>
      <Dialog
        open={poiModal}
        onClose={poiModalClose}
        aria-labelledby="peak-dialog"
        aria-describedby="peak-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          id="poi-dialog"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="div">
            POI Merge
          </Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={poiModalClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          {/* <Image src={`/static/img/lost-found/nothing-here.jpg`} /> */}
          <Typography variant="body2">
            <strong>Instructions:</strong> The two records shown will be
            merged.Carefully review data for each record and click to select the
            data you wish to keep.At the conclusion of this merge opertaion,but
            of these records will be discard,and the new POI record will be
            created with your selections.Encounters for the both POI's will be
            included in the new merge record.
          </Typography>
          <Box mt={2}>
            {loader && (
              <Paper className={classes.fixedLoader}>
                <CircularProgress />
              </Paper>
            )}
            {preLoader ? (
              <Paper className={classes.fixedLoader}>
                <CircularProgress />
              </Paper>
            ) : (
              <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TableContainer>
                      <Table>
                        <TableRow>
                          <TableCell>
                            <strong>Threat level</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.threat_level,
                              secondRecord.threat_level
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="threat_level"
                                lableText={firstRecord.threat_level}
                              />
                            ) : (
                              <Paper>{firstRecord.threat_level}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.threat_level,
                              secondRecord.threat_level
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="threat_level"
                                lableText={secondRecord.threat_level}
                              />
                            ) : (
                              <Paper>{secondRecord.threat_level}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Gender</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.gender,
                              secondRecord.gender
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="gender"
                                lableText={
                                  firstRecord.gender == "M"
                                    ? "Male"
                                    : firstRecord.gender == "F"
                                    ? "Female"
                                    : "Undetermined"
                                }
                              />
                            ) : (
                              <Paper>
                                {firstRecord.gender == "M"
                                  ? "Male"
                                  : firstRecord.gender == "F"
                                  ? "Female"
                                  : "Undetermined"}
                              </Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.gender,
                              secondRecord.gender
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="gender"
                                lableText={
                                  secondRecord.gender == "M"
                                    ? "Male"
                                    : secondRecord.gender == "F"
                                    ? "Female"
                                    : "Undetermined"
                                }
                              />
                            ) : (
                              <Paper>
                                {secondRecord.gender == "M"
                                  ? "Male"
                                  : secondRecord.gender == "F"
                                  ? "Female"
                                  : "Undetermined"}
                              </Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Race</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.race,
                              secondRecord.race
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="race"
                                lableText={firstRecord.race}
                              />
                            ) : (
                              <Paper>{firstRecord.race}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.race,
                              secondRecord.race
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="race"
                                lableText={secondRecord.race}
                              />
                            ) : (
                              <Paper>{secondRecord.race}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Age</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(firstRecord.age, secondRecord.age) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="age"
                                lableText={firstRecord.age}
                              />
                            ) : (
                              <Paper>{firstRecord.age}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(firstRecord.age, secondRecord.age) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="age"
                                lableText={secondRecord.age}
                              />
                            ) : (
                              <Paper>{secondRecord.age}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Height</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.height,
                              secondRecord.height
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="height"
                                lableText={firstRecord.height}
                              />
                            ) : (
                              <Paper>{firstRecord.height}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.height,
                              secondRecord.height
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="height"
                                lableText={secondRecord.height}
                              />
                            ) : (
                              <Paper>{secondRecord.height}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Weight</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.weight,
                              secondRecord.weight
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="weight"
                                lableText={firstRecord.weight}
                              />
                            ) : (
                              <Paper>{firstRecord.weight}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.weight,
                              secondRecord.weight
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="weight"
                                lableText={secondRecord.weight}
                              />
                            ) : (
                              <Paper>{secondRecord.weight}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Poi contact details</strong>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>First Name</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.first_name,
                              secondRecord.first_name
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="first_name"
                                lableText={firstRecord.first_name}
                              />
                            ) : (
                              <Paper>{firstRecord.first_name}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.first_name,
                              secondRecord.first_name
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="first_name"
                                lableText={secondRecord.first_name}
                              />
                            ) : (
                              <Paper>{secondRecord.first_name}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Last Name</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.last_name,
                              secondRecord.last_name
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="last_name"
                                lableText={firstRecord.last_name}
                              />
                            ) : (
                              <Paper>{firstRecord.last_name}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.last_name,
                              secondRecord.last_name
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="last_name"
                                lableText={secondRecord.last_name}
                              />
                            ) : (
                              <Paper>{secondRecord.last_name}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Address 1</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.address_1,
                              secondRecord.address_1
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="address_1"
                                lableText={firstRecord.address_1}
                              />
                            ) : (
                              <Paper>{firstRecord.address_1}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.address_1,
                              secondRecord.address_1
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="address_1"
                                lableText={secondRecord.address_1}
                              />
                            ) : (
                              <Paper>{secondRecord.address_1}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Address 2</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.address_2,
                              secondRecord.address_2
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="address_2"
                                lableText={firstRecord.address_2}
                              />
                            ) : (
                              <Paper>{firstRecord.address_2}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.address_2,
                              secondRecord.address_2
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="address_2"
                                lableText={secondRecord.address_2}
                              />
                            ) : (
                              <Paper>{secondRecord.address_2}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>City</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.city,
                              secondRecord.city
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="city"
                                lableText={firstRecord.city}
                              />
                            ) : (
                              <Paper>{firstRecord.city}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.city,
                              secondRecord.city
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="city"
                                lableText={secondRecord.city}
                              />
                            ) : (
                              <Paper>{secondRecord.city}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>State</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.state,
                              secondRecord.state
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="state"
                                lableText={firstRecord.state}
                              />
                            ) : (
                              <Paper>{firstRecord.state}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.state,
                              secondRecord.state
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="state"
                                lableText={secondRecord.state}
                              />
                            ) : (
                              <Paper>{secondRecord.state}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Home phone</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.home_phone,
                              secondRecord.home_phone
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="home_phone"
                                lableText={firstRecord.home_phone}
                              />
                            ) : (
                              <Paper>{firstRecord.home_phone}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.home_phone,
                              secondRecord.home_phone
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="home_phone"
                                lableText={secondRecord.home_phone}
                              />
                            ) : (
                              <Paper>{secondRecord.home_phone}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Mobile phone</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.mobile_phone,
                              secondRecord.mobile_phone
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="mobile_phone"
                                lableText={firstRecord.mobile_phone}
                              />
                            ) : (
                              <Paper>{firstRecord.mobile_phone}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.mobile_phone,
                              secondRecord.mobile_phone
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="mobile_phone"
                                lableText={secondRecord.mobile_phone}
                              />
                            ) : (
                              <Paper>{secondRecord.mobile_phone}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Zip</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(firstRecord.zip, secondRecord.zip) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="zip"
                                lableText={firstRecord.zip}
                              />
                            ) : (
                              <Paper>{firstRecord.zip}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(firstRecord.zip, secondRecord.zip) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="zip"
                                lableText={secondRecord.zip}
                              />
                            ) : (
                              <Paper>{secondRecord.zip}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Alias</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.alias,
                              secondRecord.alias
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="alias"
                                lableText={firstRecord.alias}
                              />
                            ) : (
                              <Paper>{firstRecord.alias}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.alias,
                              secondRecord.alias
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="alias"
                                lableText={secondRecord.alias}
                              />
                            ) : (
                              <Paper>{secondRecord.alias}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Other Physical characteristics</strong>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Language & Accent</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.lang_accent,
                              secondRecord.lang_accent
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="lang_accent"
                                lableText={firstRecord.lang_accent}
                              />
                            ) : (
                              <Paper>{firstRecord.lang_accent}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.lang_accent,
                              secondRecord.lang_accent
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="lang_accent"
                                lableText={secondRecord.lang_accent}
                              />
                            ) : (
                              <Paper>{secondRecord.lang_accent}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Eye color</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.eye_color,
                              secondRecord.eye_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="eye_color"
                                lableText={firstRecord.eye_color}
                              />
                            ) : (
                              <Paper>{firstRecord.eye_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.eye_color,
                              secondRecord.eye_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="eye_color"
                                lableText={secondRecord.eye_color}
                              />
                            ) : (
                              <Paper>{secondRecord.eye_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Teeth</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.teeth,
                              secondRecord.teeth
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="teeth"
                                lableText={firstRecord.teeth}
                              />
                            ) : (
                              <Paper>{firstRecord.teeth}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.teeth,
                              secondRecord.teeth
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="teeth"
                                lableText={secondRecord.teeth}
                              />
                            ) : (
                              <Paper>{secondRecord.teeth}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Facial hair</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.facial_hair,
                              secondRecord.facial_hair
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="facial_hair"
                                lableText={firstRecord.facial_hair}
                              />
                            ) : (
                              <Paper>{firstRecord.facial_hair}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.facial_hair,
                              secondRecord.facial_hair
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="facial_hair"
                                lableText={secondRecord.facial_hair}
                              />
                            ) : (
                              <Paper>{secondRecord.facial_hair}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Other Facial hair</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.facial_hair_other,
                              secondRecord.facial_hair_other
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="facial_hair_other"
                                lableText={firstRecord.facial_hair_other}
                              />
                            ) : (
                              <Paper>{firstRecord.facial_hair_other}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.facial_hair_other,
                              secondRecord.facial_hair_other
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="facial_hair_other"
                                lableText={secondRecord.facial_hair_other}
                              />
                            ) : (
                              <Paper>{secondRecord.facial_hair_other}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Other Facial</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_facial,
                              secondRecord.other_facial
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_facial"
                                lableText={firstRecord.other_facial}
                              />
                            ) : (
                              <Paper>{firstRecord.other_facial}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_facial,
                              secondRecord.other_facial
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_facial"
                                lableText={secondRecord.other_facial}
                              />
                            ) : (
                              <Paper>{secondRecord.other_facial}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Other Facial</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_facial_desc,
                              secondRecord.other_facial_desc
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_facial_desc"
                                lableText={firstRecord.other_facial_desc}
                              />
                            ) : (
                              <Paper>{firstRecord.other_facial_desc}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_facial_desc,
                              secondRecord.other_facial_desc
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_facial_desc"
                                lableText={secondRecord.other_facial_desc}
                              />
                            ) : (
                              <Paper>{secondRecord.other_facial_desc}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Hair color</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.hair_color,
                              secondRecord.hair_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hair_color"
                                lableText={firstRecord.hair_color}
                              />
                            ) : (
                              <Paper>{firstRecord.hair_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.hair_color,
                              secondRecord.hair_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hair_color"
                                lableText={secondRecord.hair_color}
                              />
                            ) : (
                              <Paper>{secondRecord.hair_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Other Hair color</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_hair_color,
                              secondRecord.other_hair_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_hair_color"
                                lableText={firstRecord.other_hair_color}
                              />
                            ) : (
                              <Paper>{firstRecord.other_hair_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_hair_color,
                              secondRecord.other_hair_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_hair_color"
                                lableText={secondRecord.other_hair_color}
                              />
                            ) : (
                              <Paper>{secondRecord.other_hair_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Hair Style</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.hair_style,
                              secondRecord.hair_style
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hair_style"
                                lableText={firstRecord.hair_style}
                              />
                            ) : (
                              <Paper>{firstRecord.hair_style}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.hair_style,
                              secondRecord.hair_style
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hair_style"
                                lableText={secondRecord.hair_style}
                              />
                            ) : (
                              <Paper>{secondRecord.hair_style}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Hair length</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.hair_length,
                              secondRecord.hair_length
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hair_length"
                                lableText={firstRecord.hair_length}
                              />
                            ) : (
                              <Paper>{firstRecord.hair_length}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.hair_length,
                              secondRecord.hair_length
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hair_length"
                                lableText={secondRecord.hair_length}
                              />
                            ) : (
                              <Paper>{secondRecord.hair_length}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Odor</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.odor,
                              secondRecord.odor
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="odor"
                                lableText={firstRecord.odor}
                              />
                            ) : (
                              <Paper>{firstRecord.odor}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.odor,
                              secondRecord.odor
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="odor"
                                lableText={secondRecord.odor}
                              />
                            ) : (
                              <Paper>{secondRecord.odor}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Cleanliness</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.cleanliness,
                              secondRecord.cleanliness
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="cleanliness"
                                lableText={firstRecord.cleanliness}
                              />
                            ) : (
                              <Paper>{firstRecord.cleanliness}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.cleanliness,
                              secondRecord.cleanliness
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="cleanliness"
                                lableText={secondRecord.cleanliness}
                              />
                            ) : (
                              <Paper>{secondRecord.cleanliness}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Scars</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.scars,
                              secondRecord.scars
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="scars"
                                lableText={firstRecord.scars}
                              />
                            ) : (
                              <Paper>{firstRecord.scars}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.scars,
                              secondRecord.scars
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="scars"
                                lableText={secondRecord.scars}
                              />
                            ) : (
                              <Paper>{secondRecord.scars}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>scars Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.scar_description,
                              secondRecord.scar_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="scar_description"
                                lableText={firstRecord.scar_description}
                              />
                            ) : (
                              <Paper>{firstRecord.scar_description}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.scar_description,
                              secondRecord.scar_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="scar_description"
                                lableText={secondRecord.scar_description}
                              />
                            ) : (
                              <Paper>{secondRecord.scar_description}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Piercings</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.piercings,
                              secondRecord.piercings
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="piercings"
                                lableText={firstRecord.piercings}
                              />
                            ) : (
                              <Paper>{firstRecord.piercings}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.piercings,
                              secondRecord.piercings
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="piercings"
                                lableText={secondRecord.piercings}
                              />
                            ) : (
                              <Paper>{secondRecord.piercings}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Piercings on</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.piercings_on,
                              secondRecord.piercings_on
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="piercings_on"
                                lableText={firstRecord.piercings_on}
                              />
                            ) : (
                              <Paper>{firstRecord.piercings_on}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.piercings_on,
                              secondRecord.piercings_on
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="piercings_on"
                                lableText={secondRecord.piercings_on}
                              />
                            ) : (
                              <Paper>{secondRecord.piercings_on}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Piercings Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.piercings_description,
                              secondRecord.piercings_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="piercings_description"
                                lableText={firstRecord.piercings_description}
                              />
                            ) : (
                              <Paper>{firstRecord.piercings_description}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.piercings_description,
                              secondRecord.piercings_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="piercings_description"
                                lableText={secondRecord.piercings_description}
                              />
                            ) : (
                              <Paper>
                                {secondRecord.piercings_description}
                              </Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Tattoos</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.tattoos,
                              secondRecord.tattoos
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="tattoos"
                                lableText={firstRecord.tattoos}
                              />
                            ) : (
                              <Paper>{firstRecord.tattoos}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.tattoos,
                              secondRecord.tattoos
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="tattoos"
                                lableText={secondRecord.tattoos}
                              />
                            ) : (
                              <Paper>{secondRecord.tattoos}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Tattoos on</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.tattoo_on,
                              secondRecord.tattoo_on
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="tattoo_on"
                                lableText={firstRecord.tattoo_on}
                              />
                            ) : (
                              <Paper>{firstRecord.tattoo_on}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.tattoo_on,
                              secondRecord.tattoo_on
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="tattoo_on"
                                lableText={secondRecord.tattoo_on}
                              />
                            ) : (
                              <Paper>{secondRecord.tattoo_on}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Clothing worn</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.glasses,
                              secondRecord.glasses
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="glasses"
                                lableText={firstRecord.glasses}
                              />
                            ) : (
                              <Paper>{firstRecord.glasses}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.glasses,
                              secondRecord.glasses
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="glasses"
                                lableText={secondRecord.glasses}
                              />
                            ) : (
                              <Paper>{secondRecord.glasses}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Glasses</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(firstRecord.bag, secondRecord.bag) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="bag"
                                lableText={firstRecord.bag}
                              />
                            ) : (
                              <Paper>{firstRecord.bag}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(firstRecord.bag, secondRecord.bag) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="bag"
                                lableText={secondRecord.bag}
                              />
                            ) : (
                              <Paper>{secondRecord.bag}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Bag</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(firstRecord.hat, secondRecord.hat) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hat"
                                lableText={firstRecord.hat}
                              />
                            ) : (
                              <Paper>{firstRecord.hat}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(firstRecord.hat, secondRecord.hat) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hat"
                                lableText={secondRecord.hat}
                              />
                            ) : (
                              <Paper>{secondRecord.hat}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Hat</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.hat_color,
                              secondRecord.hat_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hat_color"
                                lableText={firstRecord.hat_color}
                              />
                            ) : (
                              <Paper>{firstRecord.hat_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.hat_color,
                              secondRecord.hat_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="hat_color"
                                lableText={secondRecord.hat_color}
                              />
                            ) : (
                              <Paper>{secondRecord.hat_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Hat color</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.coat_description,
                              secondRecord.coat_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="coat_description"
                                lableText={firstRecord.coat_description}
                              />
                            ) : (
                              <Paper>{firstRecord.coat_description}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.coat_description,
                              secondRecord.coat_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="coat_description"
                                lableText={secondRecord.coat_description}
                              />
                            ) : (
                              <Paper>{secondRecord.coat_description}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Coat Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.coat_color,
                              secondRecord.coat_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="coat_color"
                                lableText={firstRecord.coat_color}
                              />
                            ) : (
                              <Paper>{firstRecord.coat_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.coat_color,
                              secondRecord.coat_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="coat_color"
                                lableText={secondRecord.coat_color}
                              />
                            ) : (
                              <Paper>{secondRecord.coat_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Coat color</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_coat_description,
                              secondRecord.other_coat_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_coat_description"
                                lableText={firstRecord.other_coat_description}
                              />
                            ) : (
                              <Paper>
                                {firstRecord.other_coat_description}
                              </Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_coat_description,
                              secondRecord.other_coat_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_coat_description"
                                lableText={secondRecord.other_coat_description}
                              />
                            ) : (
                              <Paper>
                                {secondRecord.other_coat_description}
                              </Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Other Coat Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.shirt_description,
                              secondRecord.shirt_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="shirt_description"
                                lableText={firstRecord.shirt_description}
                              />
                            ) : (
                              <Paper>{firstRecord.shirt_description}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.shirt_description,
                              secondRecord.shirt_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="shirt_description"
                                lableText={secondRecord.shirt_description}
                              />
                            ) : (
                              <Paper>{secondRecord.shirt_description}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Shirt Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.shirt_color,
                              secondRecord.shirt_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="shirt_color"
                                lableText={firstRecord.shirt_color}
                              />
                            ) : (
                              <Paper>{firstRecord.shirt_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.shirt_color,
                              secondRecord.shirt_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="shirt_color"
                                lableText={secondRecord.shirt_color}
                              />
                            ) : (
                              <Paper>{secondRecord.shirt_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Shirt color</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_shirt_description,
                              secondRecord.other_shirt_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_shirt_description"
                                lableText={firstRecord.other_shirt_description}
                              />
                            ) : (
                              <Paper>
                                {firstRecord.other_shirt_description}
                              </Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_shirt_description,
                              secondRecord.other_shirt_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_shirt_description"
                                lableText={secondRecord.other_shirt_description}
                              />
                            ) : (
                              <Paper>
                                {secondRecord.other_shirt_description}
                              </Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Coat Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.pants_description,
                              secondRecord.pants_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="pants_description"
                                lableText={firstRecord.pants_description}
                              />
                            ) : (
                              <Paper>{firstRecord.pants_description}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.pants_description,
                              secondRecord.pants_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="pants_description"
                                lableText={secondRecord.pants_description}
                              />
                            ) : (
                              <Paper>{secondRecord.pants_description}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Other Shirt Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.pant_color,
                              secondRecord.pant_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="pant_color"
                                lableText={firstRecord.pant_color}
                              />
                            ) : (
                              <Paper>{firstRecord.pant_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.pant_color,
                              secondRecord.pant_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="pant_color"
                                lableText={secondRecord.pant_color}
                              />
                            ) : (
                              <Paper>{secondRecord.pant_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Pants Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_pant_description,
                              secondRecord.other_pant_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_pant_description"
                                lableText={firstRecord.other_pant_description}
                              />
                            ) : (
                              <Paper>
                                {firstRecord.other_pant_description}
                              </Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_pant_description,
                              secondRecord.other_pant_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_pant_description"
                                lableText={secondRecord.other_pant_description}
                              />
                            ) : (
                              <Paper>
                                {secondRecord.other_pant_description}
                              </Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Other Pants Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.shoe_description,
                              secondRecord.shoe_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="shoe_description"
                                lableText={firstRecord.shoe_description}
                              />
                            ) : (
                              <Paper>{firstRecord.shoe_description}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.shoe_description,
                              secondRecord.shoe_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="shoe_description"
                                lableText={secondRecord.shoe_description}
                              />
                            ) : (
                              <Paper>{secondRecord.shoe_description}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Shoe Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.shoe_color,
                              secondRecord.shoe_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="shoe_color"
                                lableText={firstRecord.shoe_color}
                              />
                            ) : (
                              <Paper>{firstRecord.shoe_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.shoe_color,
                              secondRecord.shoe_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="shoe_color"
                                lableText={secondRecord.shoe_color}
                              />
                            ) : (
                              <Paper>{secondRecord.shoe_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Shoe color</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_shoe_description,
                              secondRecord.other_shoe_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_shoe_description"
                                lableText={firstRecord.other_shoe_description}
                              />
                            ) : (
                              <Paper>
                                {firstRecord.other_shoe_description}
                              </Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.other_shoe_description,
                              secondRecord.other_shoe_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="other_shoe_description"
                                lableText={secondRecord.other_shoe_description}
                              />
                            ) : (
                              <Paper>
                                {secondRecord.other_shoe_description}
                              </Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Vechile Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_year,
                              secondRecord.vehicle_year
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_year"
                                lableText={firstRecord.vehicle_year}
                              />
                            ) : (
                              <Paper>{firstRecord.vehicle_year}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_year,
                              secondRecord.vehicle_year
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_year"
                                lableText={secondRecord.vehicle_year}
                              />
                            ) : (
                              <Paper>{secondRecord.vehicle_year}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Year</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_make,
                              secondRecord.vehicle_make
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_make"
                                lableText={firstRecord.vehicle_make}
                              />
                            ) : (
                              <Paper>{firstRecord.vehicle_make}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_make,
                              secondRecord.vehicle_make
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_make"
                                lableText={secondRecord.vehicle_make}
                              />
                            ) : (
                              <Paper>{secondRecord.vehicle_make}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Make</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_model,
                              secondRecord.vehicle_model
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_model"
                                lableText={firstRecord.vehicle_model}
                              />
                            ) : (
                              <Paper>{firstRecord.vehicle_model}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_model,
                              secondRecord.vehicle_model
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_model"
                                lableText={secondRecord.vehicle_model}
                              />
                            ) : (
                              <Paper>{secondRecord.vehicle_model}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Model</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_color,
                              secondRecord.vehicle_color
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_color"
                                lableText={firstRecord.vehicle_color}
                              />
                            ) : (
                              <Paper>{firstRecord.vehicle_color}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_color,
                              secondRecord.vehicle_color
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_color"
                                lableText={secondRecord.vehicle_color}
                              />
                            ) : (
                              <Paper>{secondRecord.vehicle_color}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Color</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_state,
                              secondRecord.vehicle_state
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_state"
                                lableText={firstRecord.vehicle_state}
                              />
                            ) : (
                              <Paper>{firstRecord.vehicle_state}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_state,
                              secondRecord.vehicle_state
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_state"
                                lableText={secondRecord.vehicle_state}
                              />
                            ) : (
                              <Paper>{secondRecord.vehicle_state}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>State</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_plate,
                              secondRecord.vehicle_plate
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_plate"
                                lableText={firstRecord.vehicle_plate}
                              />
                            ) : (
                              <Paper>{firstRecord.vehicle_plate}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.vehicle_plate,
                              secondRecord.vehicle_plate
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="vehicle_plate"
                                lableText={secondRecord.vehicle_plate}
                              />
                            ) : (
                              <Paper>{secondRecord.vehicle_plate}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>License Plate Number</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.gang_affiliation,
                              secondRecord.gang_affiliation
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="gang_affiliation"
                                lableText={firstRecord.gang_affiliation}
                              />
                            ) : (
                              <Paper>{firstRecord.gang_affiliation}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.gang_affiliation,
                              secondRecord.gang_affiliation
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="gang_affiliation"
                                lableText={secondRecord.gang_affiliation}
                              />
                            ) : (
                              <Paper>{secondRecord.gang_affiliation}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Gang Affliation</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.gang_description,
                              secondRecord.gang_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="gang_description"
                                lableText={firstRecord.gang_description}
                              />
                            ) : (
                              <Paper>{firstRecord.gang_description}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.gang_description,
                              secondRecord.gang_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="gang_description"
                                lableText={secondRecord.gang_description}
                              />
                            ) : (
                              <Paper>{secondRecord.gang_description}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Gang Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.criminal_history,
                              secondRecord.criminal_history
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="criminal_history"
                                lableText={firstRecord.criminal_history}
                              />
                            ) : (
                              <Paper>{firstRecord.criminal_history}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.criminal_history,
                              secondRecord.criminal_history
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="criminal_history"
                                lableText={secondRecord.criminal_history}
                              />
                            ) : (
                              <Paper>{secondRecord.criminal_history}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Criminal History Description</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.criminal_history_description,
                              secondRecord.criminal_history_description
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="criminal_history_description"
                                lableText={
                                  firstRecord.criminal_history_description
                                }
                              />
                            ) : (
                              <Paper>
                                {firstRecord.criminal_history_description}
                              </Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.criminal_history_description,
                              secondRecord.criminal_history_description
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="criminal_history_description"
                                lableText={
                                  secondRecord.criminal_history_description
                                }
                              />
                            ) : (
                              <Paper>
                                {secondRecord.criminal_history_description}
                              </Paper>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Notes</strong>
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.notes,
                              secondRecord.notes
                            ) ? (
                              <MergePoiRadioOne
                                firstRecord={firstRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="notes"
                                lableText={firstRecord.notes}
                              />
                            ) : (
                              <Paper>{firstRecord.notes}</Paper>
                            )}
                          </TableCell>
                          <TableCell>
                            {isSameValue(
                              firstRecord.notes,
                              secondRecord.notes
                            ) ? (
                              <MergePoiRadioTwo
                                secondRecord={secondRecord}
                                selectedRecord={selectedRecord}
                                handleChecked={(key, value) =>
                                  handleChecked(key, value)
                                }
                                objKey="notes"
                                lableText={secondRecord.notes}
                              />
                            ) : (
                              <Paper>{secondRecord.notes}</Paper>
                            )}
                          </TableCell>
                        </TableRow>
                      </Table>
                      <Typography variant="body2">
                        <strong>Instructions:</strong> Click to highlight images
                        you wish to keep from the choices below.highlight images
                        will bs included in highlight in the merged record and
                        non-images will be discarded.A maximum of 10 images can
                        be selected. Drag images to change their order.The image
                        positioned at the top left when you merge will be the
                        main image.
                      </Typography>
                      <Box mt={3}>
                        {/* <Image
                          src={`/static/img/lost-found/nothing-here.jpg`}
                        /> */}
                      </Box>
                    </TableContainer>
                    <DialogActions>
                      <Button variant="outlined" onClick={poiModalClose}>
                        Cancle
                      </Button>
                      <Button variant="contained" type="submit">
                        Merge
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      {mergeDilaog && (
        <PoiMergeDialog
          mergeDilaog={mergeDilaog}
          setMergeDilaog={setMergeDilaog}
          mergeDilaogOpen={mergeDilaogOpen}
          mergeDilaogClose={mergeDilaogClose}
          mergeRecord={(status) => mergeRecord(status)}
        />
      )}
    </div>
  );
};

export default PoiMergeModal;

import React, { useEffect } from "react";
import { render } from "react-dom";
import HighchartsReact from "highcharts-react-official";
import { Typography, Grid } from "@mui/material";
import Highcharts from "highcharts";
import SearchIcon from "@mui/icons-material/Search";

function BusinessChart() {
  const options = {
    title: {
      text: "Tour Route by User",
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
    },
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  };

  useEffect(() => {
    Highcharts.chart("line_crat_image", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
            ],
          },
        },
      },
      title: {
        text: "Time Spent on Non-Tour Tasks March, 2022",
        align: "left",
      },
      tooltip: {
        pointFormat: "{series.name}: <p>{point.percentage:.1f}%</p>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: [
            {
              name: "Chrome",
              y: 74.77,
              sliced: true,
              selected: true,
            },
            {
              name: "Edge",
              y: 12.82,
            },
            {
              name: "Firefox",
              y: 4.63,
            },
            {
              name: "Safari",
              y: 2.44,
            },
            {
              name: "Internet Explorer",
              y: 2.02,
            },
            {
              name: "Other",
              y: 3.28,
            },
          ],
        },
      ],
    });
  }, []);
  // bar image
  useEffect(() => {
    Highcharts.chart("bar_chart_image", {
      chart: {
        type: "column",
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
            ],
          },
        },
      },
      title: {
        text: "Time Spent on Tours",
        align: "left",
      },
      subtitle: {
        text:
          'Source: <a target="_blank" ' +
          'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
        align: "left",
      },
      xAxis: {
        categories: ["USA", "China", "Brazil", "EU", "India", "Russia"],
        crosshair: true,
        accessibility: {
          description: "Countries",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "1000 metric tons (MT)",
        },
      },
      tooltip: {
        valueSuffix: " (1000 MT)",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Corn",
          data: [406292, 260000, 107000, 68300, 27500, 14500],
        },
        {
          name: "Wheat",
          data: [51086, 136000, 5500, 141000, 107180, 77000],
        },
      ],
    });
  }, []);

  // total buttons chart
  useEffect(() => {
    Highcharts.chart("button_bar_chart_image", {
      chart: {
        type: "column",
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
            ],
          },
        },
      },
      title: {
        text: "Total Buttons Scanned",
        align: "left",
      },
      subtitle: {
        text:
          'Source: <a target="_blank" ' +
          'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
        align: "left",
      },
      xAxis: {
        categories: ["USA", "China", "Brazil", "EU", "India", "Russia"],
        crosshair: true,
        accessibility: {
          description: "Countries",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "1000 metric tons (MT)",
        },
      },
      tooltip: {
        valueSuffix: " (1000 MT)",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Corn",
          data: [406292, 260000, 107000, 68300, 27500, 14500],
        },
        {
          name: "Wheat",
          data: [51086, 136000, 5500, 141000, 107180, 77000],
        },
      ],
    });
  }, []);
  return (
    <>
      <div>
        <Typography variant="h5" sx={{ marginBottom: "20px" }}>
          {" "}
          <SearchIcon sx={{ marginBottom: "-5px", marginRight: "5px" }} />
          Business Intelligence
        </Typography>

        <HighchartsReact
          sx={{ maxWidth: "100%", padding: "20px" }}
          highcharts={Highcharts}
          options={options}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} sx={{ marginTop: "42px" }}>
          <div id="bar_chart_image" sx={{ padding: "20px", Width: "100%" }} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} sx={{ marginTop: "20px" }}>
          <div
            id="line_crat_image"
            style={{
              Width: "100%",
              height: "auto",
              padding: "20px",
            }}
          ></div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} sx={{ marginTop: "42px" }}>
          <div
            id="button_bar_chart_image"
            sx={{ padding: "20px", Width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} sx={{ marginTop: "20px" }}>
          {/* <div
            id="container"
            style={{
              maxWidth: "100%",
              height: "auto",
              padding: "20px",
            }}
          ></div> */}
        </Grid>
      </Grid>
    </>
  );
}

export default BusinessChart;

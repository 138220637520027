import React, { useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import MUIRichTextEditor from "mui-rte";
import { LostBadgeAppendDetail } from "../../../api";
import { format } from "date-fns";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  FormControl as MuiFormControl,
  Grid,
  Box,
  FormHelperText,
  CircularProgress,
  Paper,
} from "@mui/material";

import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DateTimePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  textEditor: {
    position: "relative",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    borderRadius: "4px",
    "& #mui-rte-container": {
      marginTop: 0,
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      position: "absolute",
      padding: "15px",
      display: "block",
    },
    "& #mui-rte-editor": {
      height: "180px",
      overflowY: "auto",
    },
    "& #mui-rte-editor-container": {
      height: "170px",
      overflowY: "auto",
      borderTop: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    },
    "& #mui-rte-toolbar": {
      padding: "5px 15px",
    },
    "& .DraftEditor-editorContainer": {
      padding: "15px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const LostBadgeReportAppend = ({
  lostBadgeReportAppendDialog,
  lostBadgeReportAppendDialogClose,
  singleData,
}) => {
  const [dateValue, setDateValue] = useState(null);
  const [editorText, setEditorText] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [editorErr, setEditorErr] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onEditorChange = (e) => {
    setEditorText(e.getCurrentContent().getPlainText());
  };
  const lostBadgeAppend = () => {
    let payload = {
      overdue_key_report_id: singleData.id,
      append_time: dateValue ? format(dateValue, "yyyy-MM-dd HH:mm:ss") : null,
      description: editorText,
    };
    if (dateValue != null && editorText != "") {
      setLoading(true);
      LostBadgeAppendDetail(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setLoading(false);
            lostBadgeReportAppendDialogClose();
            dispatch(getMsg("Record udpated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
    } else {
      if (dateValue == null) {
        setDateErr("Date and time should not be empty");
      } else {
        setEditorErr("Description should not be empty");
      }
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="lost-badge-title"
        aria-describedby="lost-badge-desc"
        open={lostBadgeReportAppendDialog}
        onClose={lostBadgeReportAppendDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="lost-badge-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Lost Badge Report Append
          </Typography>
          <DialogActions>
            <IconButton
              onClick={lostBadgeReportAppendDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography>Name : {singleData.checked_to}</Typography>
              <Typography>Badge Number :{singleData.key_number}</Typography>
            </Grid>
            <Grid item xs={12} md={6} textAlign="right">
              <Typography>
                Datetime missing : {singleData.missing_datetime}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl mt={6}>
                <DateTimePicker
                  id="date"
                  label="Date"
                  ampm={false}
                  value={dateValue}
                  onChange={(newValue) => {
                    setDateValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "mm/dd/yyyy",
                      }}
                    />
                  )}
                />
              </FormControl>
              {dateValue == null && (
                <FormHelperText error>{dateErr}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl>
                <Box className={classes.textEditor}>
                  <MUIRichTextEditor
                    label={
                      <Typography sx={{ padding: "15px" }}>
                        Type something here...
                      </Typography>
                    }
                    onChange={onEditorChange}
                  />
                </Box>
              </FormControl>
              {editorText == "" && (
                <FormHelperText error>{editorErr}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={lostBadgeReportAppendDialogClose}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: "10px" }}
            onClick={lostBadgeAppend}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default LostBadgeReportAppend;

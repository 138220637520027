import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPermissions } from "../redux/slices/permissionSlice";

const WithPermissionHide = ({ controller, action, children }) => {
  const dispatch = useDispatch();

  // Fetch current user's role id
  const currentUser = useSelector((state) => state.login.user);

  // Fetch permissions from the store
  const { permissions, loading, error } = useSelector(
    (state) => state.permissions
  );

  // Fetch permissions if they are not loaded yet
  useEffect(() => {
    if (Object.keys(permissions).length === 0) {
      dispatch(fetchPermissions());
    }
  }, [dispatch, permissions]);

  // Show loading state while fetching permissions
  if (loading || !currentUser) {
    return null; // Hide while loading
  }

  // Handle any error that occurred during permission fetch
  if (error) {
    return null; // Hide component on error
  }

  if (currentUser?.user_group_id === 1) {
    return children; // Render the children directly if the user is a super admin
  }

  // Check if permissions exist for the specified action within the controller
  const actionPermissions =
    permissions.length &&
    permissions.find(
      (perm) => perm.controller === controller && perm.action_name === action
    );

  // If no permission object is found or the user group array is empty, hide the component
  if (!actionPermissions || actionPermissions.user_group.length === 0) {
    return null;
  }

  // Check if the user's group is included in the allowed user groups
  if (!actionPermissions.user_group.includes(currentUser?.user_group_id)) {
    return null; // User does not have permission, hide the component
  }

  // If all checks pass, render the children (the component that requires permission)
  return children;
};

export default WithPermissionHide;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import CustomPagination from "../../../components/table-components/CustomPagination";
import RemoveBlock from "./inner-component/RemoveBlock";
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Menu,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { format } from "date-fns";
import { SecurityEventsList, LocateCityFromIp } from "../../../api";
import AedLocations from "../../logs/inner-component/Aeds-inner-component/AedLocationsNew";
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const SecurityEvents = () => {
  const [value, setValue] = React.useState(null);
  const [eventData, setEventData] = useState([]);
  const [position, setPosition] = React.useState();
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [location, setLocation] = useState("");
  const [dataToFilter, setDataToFilter] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [updatedRow, setUpdatedRow] = useState({});
  const selectPosition = (event) => {
    setPosition(event.target.value);
  };
  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    // setCurrentPage(1);
  };
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [removeBlock, setRemoveBlock] = React.useState(false);
  const removeBlockOpen = () => {
    setRemoveBlock(true);
  };
  const removeBlockClose = () => {
    setRemoveBlock(false);
  };

  const applySearch = () => {
    const payload = {
      search_value: search,
      locationName: location,
      action: position,
      start_date: dateFrom ? format(dateFrom, "yyyy-MM-dd") : null,
      end_date: dateTo ? format(dateTo, "yyyy-MM-dd") : null,
    };

    setDataToFilter(payload);
  };
  const resetFilter = () => {
    setSearch("");
    setLocation("");
    setDateFrom(null);
    setDateTo(null);
  };
  const securityEventsList = () => {
    console.log("value", currentPage);
    let payload = {
      limit: rowsPerPage,
      ...dataToFilter,
    };
    setLoading(true);
    SecurityEventsList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setEventData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setLoading(false);
          setEventData([]);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    securityEventsList();
  }, [dataToFilter, rowsPerPage, currentPage]);

  // Aed Locations to open Google map
  const [aedLocationsModal, setAedLocationsModal] = useState(false);
  const [latLongData, setLatLongData] = useState({});

  const fetchCoordinates = async (userIp) => {
    try {
      LocateCityFromIp({ ip: userIp })
        .then((response) => {
          const { lat, long } = response.data;
          // Ensure both location_lat and location_lon are included
          const locationData = [{ location_lat: lat, location_lon: long }];
          setLatLongData(locationData);
          setAedLocationsModal(true);
        })
        .catch((error) => {
          setAedLocationsModal(false);
          console.error("Error fetching city coordinates:", error);
        });
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const aedLocationModalOpen = () => {
    setAedLocationsModal(true);
  };
  const aedLocationModalClose = () => {
    setAedLocationsModal(false);
  };

  //function to change field background color
  function getBackgroundColor(action) {
    switch (action) {
      case "password changed":
        return "lightgreen";
      case "account blocked":
        return "lightcoral";
      case "unknown computer":
        return "lightyellow";
      default:
        return "inherit";
    }
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4}>
          <Typography variant="h3" gutterBottom mt={5}>
            Security Events
          </Typography>
        </Grid>
        <Grid item lg={8} md={8}>
          <div className="searchTool">
            <Box
              mt={3}
              sx={{
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <Item sx={{ flexGrow: 1, width: "100%" }}>
                <TextField
                  id="search"
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      applySearch();
                      setIsFilter(true);
                    }
                  }}
                  placeholder="Search by name, or click down arrow"
                  inputProps={{}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-owns={anchorForm ? "search-form" : undefined}
                          aria-haspopup="true"
                          onClick={handleClickDropdown}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                        <StyledForm
                          id="search-form"
                          anchorEl={anchorForm}
                          open={Boolean(anchorForm)}
                          onClose={handleCloseForm}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem
                            sx={{ maxHeight: "380px", overflowY: "auto" }}
                          >
                            <form>
                              <FormControl>
                                <DatePicker
                                  id="date-from"
                                  label="Date from"
                                  value={dateFrom}
                                  onChange={(newValue) => {
                                    setDateFrom(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "Date from",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <FormControl>
                                <DatePicker
                                  id="date-to"
                                  label="Date to"
                                  value={dateTo}
                                  onChange={(newValue) => {
                                    setDateTo(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "Date to",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <FormControl>
                                <InputLabel
                                  required
                                  id="position"
                                  sx={{
                                    paddingLeft: "6px",
                                    paddingRight: "6px",
                                    marginLeft: "-4px",
                                  }}
                                >
                                  Action
                                </InputLabel>
                                <Select
                                  labelId="position"
                                  id="position"
                                  value={position}
                                  onChange={selectPosition}
                                  fullWidth
                                  displayEmpty
                                >
                                  <MenuItem value="">
                                    --Select action--
                                  </MenuItem>
                                  <MenuItem value="password changed">
                                    password changed
                                  </MenuItem>
                                  <MenuItem value="account blocked">
                                    account blocked
                                  </MenuItem>
                                  <MenuItem value="account blocked">
                                    unknown computer
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl mt={3}>
                                <TextField
                                  fullWidth
                                  id="outlined"
                                  label="Location name"
                                  value={location}
                                  onChange={(e) => setLocation(e.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>

                              <FormControl>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  mt={6}
                                  onClick={() => {
                                    applySearch({});
                                    setIsFilter(true);
                                    handleCloseForm();
                                  }}
                                >
                                  Filter
                                </Button>
                              </FormControl>
                            </form>
                          </MenuItem>
                        </StyledForm>
                      </InputAdornment>
                    ),
                  }}
                />
              </Item>
              <Item>
                {isFilter ? (
                  <Button
                    variant="contained"
                    color="primary"
                    mt={6}
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      marginLeft: "-3px",
                    }}
                    onClick={() => {
                      resetFilter();
                      setDataToFilter(null);
                      setIsFilter(false);
                    }}
                  >
                    Clear filter
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      marginLeft: "-3px",
                    }}
                    onClick={() => {
                      applySearch({});
                      setIsFilter(true);
                    }}
                  >
                    Search
                  </Button>
                )}
              </Item>
            </Box>
          </div>
        </Grid>
      </Grid>
      <Card
        sx={{
          marginTop: "30px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Location Name</TableCell>
              <TableCell align="left">User IP</TableCell>
              <TableCell align="left">City</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableCell colSpan={13} align="center">
              <CircularProgress />
            </TableCell>
          ) : (
            <TableBody>
              {eventData.map((row, indexData) => {
                const otherRecordsExist = eventData.some(
                  (otherRow) =>
                    otherRow.email === row.email &&
                    otherRow.action !== "account blocked"
                );

                return (
                  <TableRow key={indexData}>
                    <TableCell
                      style={{
                        backgroundColor: getBackgroundColor(row.action),
                      }}
                    >
                      {row.action}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.location}</TableCell>
                    <TableCell align="left">{row.user_ip}</TableCell>
                    {/* <TableCell align="left">{row.city}</TableCell> */}
                    <TableCell align="left">
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => fetchCoordinates(row.user_ip)}
                      >
                        {row.city}
                      </TableCell>
                    </TableCell>
                    <TableCell align="left">{row.created}</TableCell>

                    <TableCell align="left">
                      {row.action === "account blocked" && !otherRecordsExist && (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            removeBlockOpen();
                            setUpdatedRow(row);
                          }}
                        >
                          Unblock
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {eventData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecord}
        />
      </Card>
      {removeBlock && (
        <RemoveBlock
          removeBlock={removeBlock}
          setRemoveBlock={setRemoveBlock}
          removeBlockOpen={removeBlockOpen}
          removeBlockClose={removeBlockClose}
          updatedRow={updatedRow}
          securityEventsList={securityEventsList}
        />
      )}
      {/* AedLocations Modal for google map */}
      {aedLocationsModal && (
        <AedLocations
          title="Security Events"
          aedLocationsModal={aedLocationsModal}
          setAedLocationsModal={setAedLocationsModal}
          aedLocationModalOpen={aedLocationModalOpen}
          aedLocationModalClose={aedLocationModalClose}
          data={latLongData}
        />
      )}
    </>
  );
};

export default SecurityEvents;

import React, { useEffect, useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";

const CustomCheckboxTree = ({ handleChecked, equipType, isChecked }) => {
  const RecursiveComponent = ({ data }) => {
    const hasChildren = data.children_recursive
      ? data.children_recursive.length
      : 0;

    return (
      <>
        {hasChildren > 0 ? (
          <TreeItem
            key={data.id}
            nodeId={String(data.id)}
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked == data.id}
                    value={data.id}
                    onChange={(evt) => handleChecked(evt)}
                  />
                }
                label={data.name}
              />
            }
          >
            {data.children_recursive.map((item) => (
              <RecursiveComponent data={item} />
            ))}
          </TreeItem>
        ) : (
          <TreeItem
            key={data.id}
            nodeId={String(data.id)}
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked == data.id}
                    value={data.id}
                    onChange={(evt) => handleChecked(evt)}
                  />
                }
                label={data.name}
              />
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      {equipType.length > 0 && (
        <TreeView
          aria-label="multi-select"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          multiSelect
          sx={{ height: 216, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
        >
          {equipType.map((row, index) => {
            return (
              <TreeItem
                key={row.id}
                nodeId={String(row.id)}
                label={
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked == row.id}
                        value={row.id}
                        onChange={(evt) => handleChecked(evt)}
                      />
                    }
                    label={row.name}
                  />
                }
              >
                {row.children_recursive.length > 0 &&
                  row.children_recursive.map((childRow, index) => {
                    return <RecursiveComponent data={childRow} />;
                  })}
              </TreeItem>
            );
          })}
        </TreeView>
      )}
    </>
  );
};

export default CustomCheckboxTree;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import axios from "axios";
import Toaster from "../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { getTourRow } from "../../redux/slices/tourSlice"; //import related to redux store.
import { UpdateTour, UpdateTourStatus } from "../../api";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import { RemoveTourTime } from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  Tooltip,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import { DateTimePicker, DatePicker } from "@mui/lab";

import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  Cached as CachedIcon,
  CheckBox as CheckBoxIcon,
  LocationOn as LocationOnIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  GpsFixed as GpsFixedIcon,
  CalendarToday as CalendarTodayIcon,
  FileCopy as FileCopyIcon,
  Public as PublicIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import WithPermissionHide from "../../utils/withPermissionHide";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
const Image = styled.img`
  height: 30px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const TourRow = (props) => {
  const {
    tour,
    isItemSelected,
    labelId,
    handleClick,
    handleChecked,
    anchorEl,
    handleActionClick,
    handleActionClose,
    handleDeleteOpen,
    clickScheduleModalOpen,
    copyTourOpen,
    property,
    tourId,
    ids,
    singleTour,
    viewTour,
    getTourList,
    tableCalRedux,
  } = props;
  const [active, setActive] = useState(tour.status === "A" ? true : false);
  const dispatch = useDispatch();
  const handleActive = (status, id) => {
    let value = status === true ? "D" : "A";
    UpdateTourStatus(id, { status: value })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeTourTime = () => {
    RemoveTourTime(tour.id)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("Tour schedule removed ");
          dispatch(getMsg("Tour schedule removed"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          getTourList();
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [editedTourName, setEditedTourName] = useState(tour.tour_name);
  const [editedDescription, setEditedDescription] = useState(
    tour.tour_description
  );

  const handleTourNameChange = (event) => {
    setEditedTourName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

  const saveEditedData = async () => {
    const dataToUpdate = {};

    if (editedTourName !== tour.tour_name) {
      dataToUpdate.tour_name = editedTourName;
    }

    if (editedDescription !== tour.tour_description) {
      dataToUpdate.description = editedDescription;
    }

    UpdateTour(tour.id, dataToUpdate)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Somthing went wrong please try again!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };

  // Scheduled tooltip title
  function generateTooltipTitle(tour) {
    let msg1 = "";
    let msg2 = "";
    let msg3 = "";

    if (tour.schedule_type === "Monthly") {
      const number = tour.schedule_type_value;
      const ends = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"];
      const abbreviation =
        number % 100 >= 11 && number % 100 <= 13
          ? number + "th"
          : number + ends[number % 10];
      msg1 = "Every " + abbreviation + " day of month,";
    } else if (tour.schedule_type === "Daily") {
      msg1 = "Everyday,";
    } else if (tour.schedule_type === "Weekly") {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const selectedDays = tour.weekly_value.map((day) => days[day - 1]);
      msg1 = "Every " + selectedDays.join(", ");
    }

    if (tour.schedule_time_start > 0) {
      msg2 =
        "between " +
        tour.schedule_time_start +
        " hours and " +
        tour.schedule_time_end +
        " hours";
    } else {
      msg2 = "during";
    }
    if (tour.shift_selected == "Shift-1") {
      msg3 = "Shift-1";
    } else if (tour.shift_selected == "Shift-2") {
      msg3 = "Shift-2";
    } else if (tour.shift_selected == "Shift-3") {
      msg3 = "Shift-3";
    } else {
      msg3 = "";
    }

    return msg1 + " " + msg2 + " " + msg3;
  }

  return (
    <>
      <TableRow hover key={tour.id} selected={isItemSelected}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, tour.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
          />
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.TourName === true ? "" : "hide-cell"}
        >
          {/* {tour.tour_name} */}
          <Tooltip title={"Click to edit"}>
            <TextField
              sx={{
                width: 230,
                border: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 0,
                },
              }}
              value={editedTourName}
              onChange={handleTourNameChange}
              onBlur={saveEditedData}
            />
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          sx={{ minWidth: "400px" }}
          className={tableCalRedux.tour_description === true ? "" : "hide-cell"}
        >
          {/* {tour.tour_description} */}
          <Tooltip title={"Click to edit"}>
            <TextField
              sx={{
                width: 330,
                // height: 120,
                border: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 0,
                },
              }}
              value={editedDescription}
              onChange={handleDescriptionChange}
              onBlur={saveEditedData}
            />
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.sched === true ? "" : "hide-cell"}
        >
          {tour.schedule_time_start || tour.schedule_time_span == 1 ? (
            <>
              <Tooltip title="Click to delete schedule" placement="bottom-end">
                <ClearIcon
                  onClick={removeTourTime}
                  sx={{ cursor: "pointer", marginRight: "10px" }}
                />
              </Tooltip>
              <Tooltip title={generateTooltipTitle(tour)} placement="bottom">
                <AccessTimeIcon />
              </Tooltip>
            </>
          ) : null}
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.buttons === true ? "" : "hide-cell"}
        >
          {tour.button}
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.active === true ? "" : "hide-cell"}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onClick={() => {
                    setActive(!active);
                    handleActive(active, tour.id);
                  }}
                />
              }
              label=""
            />
          </FormGroup>
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.last_completed === true ? "" : "hide-cell"}
        >
          {tour.last_completed}
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.image === true ? "" : "hide-cell"}
        >
          <ImageWrapper>
            <Image alt="" src={tour.image} />
          </ImageWrapper>
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.action === true ? "" : "hide-cell"}
        >
          <IconButton
            aria-label="Settings"
            size="large"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={(event) => handleActionClick(event, tour.id, tour.tour_id)}
            style={{ color: "#1374C5" }}
          >
            <SettingsIcon />
          </IconButton>
          <StyledMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <WithPermissionHide controller="Tour" action="updateTour">
              <MenuItem
                aria-label="details"
                component={NavLink}
                to={`/tours/edit-tour/${tourId}`}
                size="large"
              >
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
                <ListItemText primary="Edit" pl={1} />
              </MenuItem>
            </WithPermissionHide>
            <MenuItem onClick={handleDeleteOpen}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" pl={1} />
            </MenuItem>
            <WithPermissionHide
              controller="Tour"
              action="getTourCheckpointList"
            >
              <MenuItem
                aria-label="checkpoints"
                component={NavLink}
                to={`/tours/checkpoints/${ids}`}
                onClick={() => dispatch(getTourRow(viewTour))}
              >
                <ListItemIcon>
                  <GpsFixedIcon />
                </ListItemIcon>
                <ListItemText primary="Checkpoints" pl={1} />
              </MenuItem>
            </WithPermissionHide>
            <MenuItem
              onClick={() => {
                clickScheduleModalOpen();
                singleTour();
                handleActionClose();
              }}
            >
              <ListItemIcon>
                <CalendarTodayIcon />
              </ListItemIcon>
              <ListItemText primary="Schedule tour" pl={1} />
            </MenuItem>
            <WithPermissionHide controller="Tour" action="copyTour">
              <MenuItem
                onClick={() => {
                  copyTourOpen();
                  property();
                  handleActionClose();
                }}
              >
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText primary="Copy tour" pl={1} />
              </MenuItem>
            </WithPermissionHide>
          </StyledMenu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TourRow;

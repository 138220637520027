import "./ImageGallery.css";
const VideoPlayModal = ({ videoLink, setVideoLink }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setVideoLink(null);
    }
  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <video src={videoLink} width="750" height="500" controls></video>
        <span className="dismiss" onClick={handleClick}>
          X
        </span>
      </div>
    </>
  );
};

export default VideoPlayModal;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { ForgetPassword } from "../../api"; // Import the ForgetPassword API function
import { spacing } from "@mui/system";
import Toaster from "../../pages/users/components/Toaster";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const [toast, setToast] = useState({ open: false, message: "" });

  const handleCloseSnack = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <>
      <Toaster
        openSnack={toast.open}
        handleCloseSnack={handleCloseSnack}
        msg={toast.message}
      />

      <Formik
        initialValues={{
          email: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const response = await ForgetPassword({ email: values.email });

            // Check the response message to determine what to do
            if (
              response.message ===
              "An email has been sent with a link to reset your password."
            ) {
              setToast({
                open: true,
                message:
                  "An email has been sent with a link to reset your password.",
              });
            } else if (
              response.message === "Please check your email for instructions."
            ) {
              setErrors({ email: "Please check your email." });
            }

            setStatus({ success: true });
            setSubmitting(false);
          } catch (error) {
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Enter your registered email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Forget password
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ResetPassword;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import styled from "styled-components/macro";
import { handleApiResonseErrors } from "../../../helper/helper";
import { CloneDaily } from "../../../api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  Grid,
  FormControl as MuiFormControl,
  FormHelperText,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const SelectDestinationDates = ({
  selectDestinationDatesDialog,
  selectDestinationDatesDialogClose,
  editEventInfo,
  alertMessage,
  eventData,
  setLoading,
}) => {
  const [selectedDates, setSelectedDates] = useState([]);

  const handleDateChange = (date) => {
    if (selectedDates.find((selectedDate) => selectedDate === date)) {
      setSelectedDates(
        selectedDates.filter((selectedDate) => selectedDate !== date)
      );
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const validationSchema = Yup.object().shape({
    date: Yup.date().nullable().required("Please select date."),
  });

  let initialValues = {
    date: null,
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        id: editEventInfo.id,
        date: selectedDates.join(","),
      };
      CloneDaily(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            alertMessage(res.message, "success");
            eventData();
            selectDestinationDatesDialogClose();
          } else {
            alertMessage("Sorry! something wrong please try again", "error");
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            alertMessage(error, "error");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <Dialog
        aria-labelledby="select-destination-dates-title"
        aria-describedby="select-destination-dates-desc"
        open={selectDestinationDatesDialog}
        onClose={selectDestinationDatesDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 0,
          }}
          id="select-destination-dates-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Select Destination Dates
          </Typography>
          <DialogActions>
            <IconButton
              onClick={selectDestinationDatesDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent sx={{ paddingTop: 0 }}>
                <DialogContentText>
                  <strong>INSTRUCTIONS:</strong> Click field below to open a
                  calendar. Click to select one or more destination dates. When
                  you click save, the schedule you selected will be cloned to
                  the destination dates.
                </DialogContentText>
                <Grid item xs={12} md={12}>
                  <FormControl mt={6}>
                    <DatePicker
                      label="Destination dates:"
                      value={values.date ?? ""}
                      name="date"
                      onChange={(value) => {
                        let date = value ? moment(value) : "";
                        let dateValue = date ? date.format("YYYY-MM-DD") : "";
                        setFieldValue("date", dateValue);
                        handleDateChange(dateValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Please select dates",
                            value: selectedDates.join(", "),
                          }}
                        />
                      )}
                    />
                    <FormHelperText error>{errors.date}</FormHelperText>
                  </FormControl>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default SelectDestinationDates;

import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { UpdateKeyCabinet } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import EditKeyRing from "./EditKeyRing";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const EditKeyCabinet = ({
  editKeyCabinet,
  editKeyCabinetClose,
  singleData,
  addKeyCabinetList,
  cabinetId,
}) => {
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    cabinet_name: Yup.string().required("Please enter ring name"),
  });

  const { name, trays, rows, columns, notes } = singleData;
  /* Setting the initial values for the form. */
  const initialValues = {
    cabinet_name: name ? name : "",
    cabinet_trays: trays ? trays : "",
    cabinet_columns: columns ? columns : "",
    cabinet_rows: rows ? rows : "",
    cabinet_notes: notes ? notes : "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    editKeyCabinetClose();
    try {
      let formData = {
        id: cabinetId,
        name: values.cabinet_name,
        trays: values.cabinet_trays,
        columns: values.cabinet_columns,
        rows: values.cabinet_rows,
        notes: values.cabinet_notes,
      };
      UpdateKeyCabinet(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key cabinet has been updated successfully"));
            dispatch(getSnackAlert("success"));
            addKeyCabinetList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={editKeyCabinet}
        onClocse={editKeyCabinetClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="add-key-cabinet-title"
        aria-describedby="edit-key-cabinet-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="edit-key-cabinet-title"
        >
          <Typography variant="h4">Edit Key Cabinet</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={editKeyCabinetClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={8}>
                      <CustomTextField
                        name="cabinet_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={8}>
                      <CustomTextField
                        name="cabinet_trays"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet trays"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="cabinet_columns"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet columns"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="cabinet_rows"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet rows"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="cabinet_notes"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet notes"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={editKeyCabinetClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditKeyCabinet;

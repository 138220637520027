import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { handleApiResonseErrors } from "../../../helper/helper";
import { AddEvent } from "../../../api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Typography,
  TextField,
  FormLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

import {
  CancelRounded as CancelRoundedIcon,
  Brightness1Outlined as Brightness1OutlinedIcon,
} from "@mui/icons-material";

const AddTaskOrEvent = ({
  addTaskEventDialog,
  addTaskEventDialogClose,
  newSlotInfo,
  alertMessage,
  eventData,
  setLoading,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dot, setDot] = useState("bg-primary");
  const selectDot = (event) => {
    setDot(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

  let initialValues = {
    name: "",
  };

  useEffect(() => {
    setStartDate(moment(newSlotInfo.start).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(newSlotInfo.start).format("YYYY-MM-DD HH:mm:ss"));
  }, []);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        start_date: startDate,
        end_date: endDate,
        type: "Event",
        event_class: dot,
        name: values.name,
      };
      AddEvent(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            alertMessage(res.message, "success");
            eventData();
            addTaskEventDialogClose();
          } else {
            alertMessage("Sorry! something wrong please try again", "error");
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            alertMessage(error, "error");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <Dialog
        aria-labelledby="add-task-event-title"
        aria-describedby="add-task-event-desc"
        open={addTaskEventDialog}
        onClose={addTaskEventDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 0,
          }}
          id="add-task-event-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add Task or Event
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addTaskEventDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent sx={{ paddingTop: 0 }}>
                <DialogContentText sx={{ marginBottom: "15px" }}>
                  Instructions: Add a short description of the text or event in
                  the field below. Select an optional identifier by clicking the
                  circle, then select the task or event type from the dropdown.
                  When finished, click save to add your task or event to the
                  calendar. After it's added you can adjust the date, or change
                  the date to a range to indicate a multi-dask or event.
                </DialogContentText>
                <FormLabel>Notes/events</FormLabel>
                <TextField
                  placeholder="Add Note"
                  fullWidth
                  name="name"
                  onChange={(e) => {
                    setFieldValue("name", e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: "auto !important",
                            marginRight: "-14px",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          },
                        }}
                      >
                        <Select
                          id="dot"
                          value={dot}
                          onChange={selectDot}
                          fullWidth
                          displayEmpty
                          sx={{
                            width: "50px",
                            textOverflow: "unset",
                            "& .MuiSelect-iconOutlined": {
                              display: "none",
                            },
                            "& .MuiSelect-outlined": {
                              textOverflow: "unset !important",
                              height: "20px !important",
                              color: "transparent",
                            },
                          }}
                        >
                          <MenuItem value="bg-primary">
                            <Brightness1OutlinedIcon
                              color="warning"
                              sx={{ mr: "5px" }}
                            />
                            Visitor
                          </MenuItem>
                          <MenuItem value="bg-success">
                            <Brightness1OutlinedIcon
                              color="primary"
                              sx={{ mr: "5px" }}
                            />
                            Call Off
                          </MenuItem>
                          <MenuItem value="bg-info">
                            <Brightness1OutlinedIcon
                              color="success"
                              sx={{ mr: "5px" }}
                            />
                            Training
                          </MenuItem>
                          <MenuItem value="bg-warning">
                            <Brightness1OutlinedIcon
                              color="error"
                              sx={{ mr: "5px" }}
                            />
                            Vacation
                          </MenuItem>
                          <MenuItem value="bg-danger">
                            <Brightness1OutlinedIcon
                              color="error"
                              sx={{ mr: "5px" }}
                            />
                            Critical
                          </MenuItem>
                          <MenuItem value="bg-color">
                            <Brightness1OutlinedIcon
                              color="primary"
                              sx={{ mr: "5px" }}
                            />
                            Note
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText error>{errors.name}</FormHelperText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={addTaskEventDialogClose}
                  sx={{ marginRight: "5px" }}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" variant="contained">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddTaskOrEvent;

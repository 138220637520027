import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tourList: {
    TourName: true,
    tour_description: true,
    sched: true,
    buttons: true,
    active: true,
    last_completed: true,
    image: true,
    action: true,
  },
  checkpointList: {
    TagDescription: true,
    location_hint: true,
    image: true,
    critical: true,
    tasks: true,
    task_schedules: true,
    timing: true,
    action: true,
  },
};

export const defaultTourTableColSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {},
});

export const {} = defaultTourTableColSlice.actions;

export default defaultTourTableColSlice.reducer;

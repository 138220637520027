import React, { useEffect, useState } from "react";
import CustomProgressBar from "../../../components/form-components/CustomProgressBar";
import {
  Dialog,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  Box,
  DialogActions,
  Avatar as MuiAvatar,
  Button,
  LinearProgress,
  FormLabel,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
  Menu,
  FormGroup,
  TextField,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import {
  GetInterviewQuestionList,
  ViewInterviewAns,
  SaveUseOfForce,
} from "../../../api";
import Questionnaire from "./Questionnaire";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const AddUseOfForce = ({
  addUseOfForce,
  setAddUseOfForce,
  addUseOfForceOpen,
  addUseOfForceClose,
  incidentId,
  setPageLoad,
}) => {
  const [interviewQuestionList, setInterviewQuestionList] = useState([]);
  const [interviewAnswerList, setInterviewAnswerList] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [currentQues, setCurrentQues] = useState({});
  const [currentAns, setCurrentAns] = useState();
  const [inputVal, setinputVal] = useState("");
  const [progressCount, setProgressCount] = useState(0); // State for progress bar
  const [isCompleted, setIsCompleted] = useState(false); // State to check is all force interview questions completed

  const handleChangeInput = (event) => {
    setinputVal(event.target.value);
  };
  const dispatch = useDispatch();
  const getquestionnaireList = () => {
    GetInterviewQuestionList()
      .then((res) => {
        if (res.statusCode == 200) {
          // console.log(res.data);
          setInterviewQuestionList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckedTree = (val) => {
    console.log(val);
    // if (checked) {
    let singleData = interviewQuestionList[val];
    setCurrentQues(singleData);
    console.log(singleData);
    // setName(d.name);
    // setSL(d.security_level);
    // } else {
    // setIsCheckedValue(0);
    // }
  };

  // Method to set currentAns
  const handleCurrentAnswer = () => {
    if (interviewQuestionList.length > 0 && interviewAnswerList.length > 0) {
      const CurrentQues = interviewQuestionList[questionCount];
      const answer = interviewAnswerList.find(
        (answer) => answer.question_id === CurrentQues.id
      );
      setCurrentAns(answer);
    }
  };

  //Method for view interview question
  const getInterviewQuestionAns = () => {
    setPageLoad(true);
    ViewInterviewAns(incidentId)
      .then((res) => {
        if (res.statusCode == 200) {
          setPageLoad(false);
          setInterviewAnswerList(res.data);
          // dispatch(getSnackMsg("Record has been deleted successfully"));
          // dispatch(getSnackAlert("success"));
          // dispatch(getSnackOpen());
          // getInvolvedWitnessListing();
          // handleDeleteClose();
        } else {
          setPageLoad(false);
          // handleDeleteClose();
          // dispatch(getSnackMsg("Something went wrong, please try again"));
          // dispatch(getSnackAlert("error"));
          // dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  //Method for view interview question
  const saveAnsQuestions = () => {
    setPageLoad(true);
    let payload = {
      ques_type: currentQues?.ques_type,
      incident_id: incidentId,
      answer: inputVal,
      question_id: currentQues?.id,
    };

    SaveUseOfForce(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setPageLoad(false);
          setinputVal("");
          dispatch(getMsg("Record has been saved successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          // getInvolvedWitnessListing();
          // handleDeleteClose();
        } else {
          setPageLoad(false);
          // handleDeleteClose();
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  //Method for accepting props from child to parent
  const childToParent = (childData) => {
    console.log(childData);
    // setImageData(childData);
  };

  // Progress Counter for Progress Bar
  const ProgressCounter = (val) => {
    function percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    }
    let perVal = percentage(questionCount, 20);
    setProgressCount(perVal);
  };

  useEffect(() => {
    getquestionnaireList();
    getInterviewQuestionAns();
  }, []);

  useEffect(() => {
    handleCurrentAnswer();
  }, [interviewQuestionList, interviewAnswerList]);

  useEffect(() => {
    if (interviewQuestionList.length > 0) {
      handleCheckedTree(questionCount);
      // getInterviewQuestionAns();
    }
  }, [questionCount, interviewQuestionList]);

  useEffect(() => {
    ProgressCounter();
    handleCurrentAnswer();
  }, [questionCount]);

  return (
    <>
      <Dialog
        open={addUseOfForce}
        // onClose={addUseOfForceClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          id="peak-dialog"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Use of Force Questions</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={addUseOfForceClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <CustomProgressBar value={progressCount} />
        {isCompleted ? (
          <DialogContent>
            {" "}
            <Typography
              variant="h3"
              align="center"
              style={{ margin: "20px 0" }}
            >
              Thank You
            </Typography>
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              {/* <Box>
            <LinearProgress
              sx={{
                height: "15px",
                borderRadius: "10px",
              }}
            />
          </Box> */}
              {/* <Questionnaire
            interviewQuestionList={interviewQuestionList}
            currentQues={currentQues}
            childToParent={childToParent}
          /> */}
              <form>
                <Grid container spacing={6}>
                  <Grid item xs={12} lg={12}>
                    <FormControl>
                      <FormLabel>
                        Question: {currentQues?.id} - {currentQues?.question}{" "}
                      </FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    {currentQues.ques_type == "radio" ? (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="select-radio"
                          value={currentAns?.option_id || inputVal}
                          onChange={handleChangeInput}
                          row
                          // onChange={(e) => setinputVal(e.target.value)}
                        >
                          {currentQues?.ir_question_option?.map((item) => (
                            <FormControlLabel
                              value={item.id}
                              control={<Radio />}
                              label={item.options}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    ) : currentQues.ques_type == "text" ? (
                      <FormControl>
                        <TextField
                          value={currentAns?.answer || inputVal}
                          onChange={handleChangeInput}
                        />
                      </FormControl>
                    ) : currentQues.ques_type == "list" ? (
                      <FormControl>
                        <Select
                          labelId="department"
                          id="department"
                          value={currentAns?.answer || inputVal}
                          onChange={handleChangeInput}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">
                            Please select person name
                          </MenuItem>
                          <MenuItem value="Unknown">Unknown</MenuItem>
                        </Select>
                      </FormControl>
                    ) : currentQues.ques_type == "multitext" ? (
                      <Grid container spacing={6} sx={{ marginTop: "8px" }}>
                        <Grid item xs={12} lg={11}>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={6} lg={3}>
                              <FormControl>
                                <TextField
                                  value={currentAns?.answer || inputVal}
                                  onChange={handleChangeInput}
                                  placeholder=""
                                  label="Injury Type"
                                  InputLabelProps={{ shrink: true }}
                                />
                              </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} md={6} lg={3}>
                          <FormControl>
                            <TextField
                              value={inputVal}
                              onChange={handleChangeInput}
                              placeholder=""
                              label="Name of person injured"
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid> */}
                            {/* <Grid item xs={12} md={6} lg={3}>
                          <FormControl>
                            <TextField
                              value={inputVal}
                              onChange={handleChangeInput}
                              placeholder=""
                              label="Hospital or treatment facility"
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid> */}
                            {/* <Grid item xs={12} md={6} lg={3}>
                          <FormControl>
                            <TextField
                              value={inputVal}
                              onChange={handleChangeInput}
                              placeholder=""
                              label="Physicians name"
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid> */}
                          </Grid>
                        </Grid>
                        {/* <Grid item xs={12} lg={1}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Grid> */}
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                disabled={questionCount < 1 ? true : false}
                onClick={() => {
                  setQuestionCount(questionCount - 1);
                }}
              >
                PREVIOUS
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (questionCount < interviewQuestionList.length - 1) {
                    setQuestionCount(questionCount + 1);
                    saveAnsQuestions();
                  } else {
                    setIsCompleted(true);
                    setProgressCount(100);
                  }
                }}
              >
                {questionCount < interviewQuestionList.length - 1
                  ? "NEXT"
                  : "FINISH INTERVIEW"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default AddUseOfForce;

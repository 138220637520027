import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import LoginEditDialog from "./inner-component/LoginEditDialog";
import CustomDateRange from "../../../components/form-components/CustomDateRange";
import moment from "moment";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";
import CustomPagination from "../../../components/table-components/CustomPagination";
import { sova } from "../../../config";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  Typography,
  Grid,
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  TextField,
  RadioGroup,
  MenuItem,
  Box,
  InputAdornment,
  IconButton,
  Select,
  InputLabel,
  CircularProgress,
  Paper,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import {
  FilterList as FilterListIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  AuditUserList,
  ContinentList,
  CountryDropDownList,
  StateList,
  AuditUserPdf,
  LogoutAllSessions,
} from "../../../api";
import { DatePicker } from "@mui/lab";
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "140px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },

      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
// const Paper = styled(MuiPaper)(spacing);

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const LoginRecords = () => {
  const [loginEditDialog, setLoginEditDialog] = React.useState(false);
  const [listAuditUser, setListAuditUser] = useState([]);
  const [continent, setContinent] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [listcontinent, setListContinent] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [listState, setListState] = useState([]);
  const [fadebox, setFadedBox] = React.useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [search, setSearch] = useState("");
  const [ipSearch, setIpSearch] = useState("");
  const [friendlyName, setFriendlyName] = useState("");
  const [dataToFilter, setDataToFilter] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [city, setCity] = useState("");
  const [updatedRow, setUpdatedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectContinent = (event) => {
    setContinent(event.target.value);
  };
  const selectCountry = (event) => {
    setCountry(event.target.value);
  };
  const selectState = (event) => {
    setState(event.target.value);
  };
  const loginEditDialogOpen = () => {
    setLoginEditDialog(true);
  };
  const loginEditDialogClose = () => {
    setLoginEditDialog(false);
  };
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };

  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleActionClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setUpdatedRow(row);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const [loginMap, setLoginMap] = React.useState(null);
  const start = Boolean(loginMap);

  const handleMapClick = (event) => {
    setLoginMap(event.currentTarget);
  };
  const handleMapClose = () => {
    setLoginMap(null);
  };
  const applySearch = (value) => {
    const payload = {
      search_value: search,
      ip_search: ipSearch,
      friendly_name: friendlyName,
      continent_name: continent,
      country: country,
      state: state,
      city: city,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
    };
    setDataToFilter(payload);
  };
  const resetFilter = () => {
    setSearch("");
    setContinent("");
    setCountry("");
    setState("");
    setStartDateToFilter(null);
    setEndDateToFilter(null);
    setIpSearch("");
    setFriendlyName("");
  };
  const logoutAllSessions = () => {
    LogoutAllSessions(updatedRow.user_id)
      .then((res) => {
        if (res.statusCode == 200) {
          loginEditDialogClose();
          auditUserList();
          dispatch(
            getMsg(
              "This username has been logged out from all active web sessions"
            )
          );
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  const onButtonClick = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      ...dataToFilter,
    };
    AuditUserPdf(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "audit_user.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const continentList = () => {
    ContinentList()
      .then((res) => {
        if (res.statusCode == 200) {
          setListContinent(res.data);
        } else {
          setListContinent([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  const countryDropDownList = (value) => {
    let payload = {
      continent: value,
    };
    CountryDropDownList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setListCountry(res.data);
        } else {
          setListCountry([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  const stateList = (value) => {
    let payload = {
      country: value,
    };
    StateList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setListState(res.data);
        } else {
          setListState([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  const auditUserList = () => {
    let payload = {
      limit: rowsPerPage,
      ...dataToFilter,
    };
    setLoader(true);
    AuditUserList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setListAuditUser(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoader(false);
        } else {
          setLoader(false);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    auditUserList();
    continentList();
  }, [dataToFilter, rowsPerPage, currentPage]);

  const [openmap, setOpenmap] = useState(false);

  const handleClickOpen = () => {
    setOpenmap(true);
  };

  const handleClose = () => {
    setOpenmap(false);
  };

  // Google Maps State
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 37.773972,
    lng: -122.431297,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
  });

  return (
    <>
      <Grid container spacing={5}>
        <Grid item lg={4} md={4}>
          <Typography variant="h3" gutterBottom mt={6}>
            Login Records
          </Typography>
        </Grid>
        <Grid item lg={7} md={7}>
          <div className="searchTool">
            <Box
              mt={3}
              sx={{
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <Item sx={{ flexGrow: 1, width: "100%" }}>
                <TextField
                  id="search"
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search by name, or click down arrow"
                  inputProps={{}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-owns={anchorForm ? "search-form" : undefined}
                          aria-haspopup="true"
                          onClick={handleClickDropdown}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                        <StyledForm
                          id="search-form"
                          anchorEl={anchorForm}
                          open={Boolean(anchorForm)}
                          onClose={handleCloseForm}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem sx={{ maxHeight: "380px" }}>
                            <form>
                              <FormControl mt={3}>
                                <TextField
                                  fullWidth
                                  id="dateRange"
                                  label="Date range"
                                  value={
                                    startDateToFilter && endDateToFilter
                                      ? moment(startDateToFilter).format(
                                          "MM/DD/YYYY"
                                        ) +
                                        " - " +
                                        moment(endDateToFilter).format(
                                          "MM/DD/YYYY"
                                        )
                                      : ""
                                  }
                                  onClick={() => setFadedBox((prev) => !prev)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Select date range"
                                />
                                {fadebox && (
                                  <CustomDateRange
                                    handleShow={fadebox}
                                    conformation={(status) =>
                                      setFadedBox((prev) => !prev)
                                    }
                                    setDates={(start, end) => {
                                      setStartDateToFilter(start);
                                      setEndDateToFilter(end);
                                    }}
                                    selectedStart={startDateToFilter}
                                    selectedEnd={endDateToFilter}
                                  />
                                )}
                              </FormControl>
                              <FormControl mt={3}>
                                <TextField
                                  fullWidth
                                  id="employee"
                                  label="IP Search"
                                  value={ipSearch}
                                  onChange={(e) => setIpSearch(e.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="IP Search"
                                />
                              </FormControl>
                              <FormControl mt={3}>
                                <TextField
                                  fullWidth
                                  id="Friendly_Name"
                                  label="Friendly Name"
                                  value={friendlyName}
                                  onChange={(e) =>
                                    setFriendlyName(e.target.value)
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="NickName given"
                                />
                              </FormControl>
                              <FormControl>
                                <InputLabel
                                  shrink
                                  id="Continent"
                                  sx={{
                                    paddingLeft: "6px",
                                    paddingRight: "6px",
                                    marginLeft: "-4px",
                                  }}
                                ></InputLabel>
                                <Select
                                  labelId="Continent"
                                  id="Continent"
                                  value={continent}
                                  onChange={selectContinent}
                                  fullWidth
                                  displayEmpty
                                >
                                  <MenuItem value="">
                                    --Select continent--
                                  </MenuItem>

                                  {listcontinent.map((item) => {
                                    return (
                                      <MenuItem
                                        value={item.key}
                                        key={item.value}
                                        onClick={() =>
                                          countryDropDownList(item.value)
                                        }
                                      >
                                        {item.key}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormControl>
                                <InputLabel
                                  shrink
                                  id="Country"
                                  sx={{
                                    paddingLeft: "6px",
                                    paddingRight: "6px",
                                    marginLeft: "-4px",
                                  }}
                                ></InputLabel>
                                <Select
                                  labelId="Country"
                                  id="Country"
                                  value={country}
                                  onChange={selectCountry}
                                  fullWidth
                                  displayEmpty
                                >
                                  <MenuItem value="">
                                    --Select Country--
                                  </MenuItem>

                                  {listCountry.map((item) => {
                                    return (
                                      <MenuItem
                                        value={item.key}
                                        key={item.value}
                                        onClick={() => stateList(item.value)}
                                      >
                                        {item.key}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormControl>
                                <InputLabel
                                  shrink
                                  id="State/Region"
                                  sx={{
                                    paddingLeft: "6px",
                                    paddingRight: "6px",
                                    marginLeft: "-4px",
                                  }}
                                ></InputLabel>
                                <Select
                                  labelId="State/Region"
                                  id="State/Region"
                                  value={state}
                                  onChange={selectState}
                                  fullWidth
                                  displayEmpty
                                >
                                  <MenuItem value="">--Select State--</MenuItem>
                                  {listState.map((item) => {
                                    return (
                                      <MenuItem
                                        value={item.key}
                                        key={item.value}
                                      >
                                        {item.key}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormControl mt={3}>
                                <TextField
                                  fullWidth
                                  id="City/Locality"
                                  label="City/Locality"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Dallas"
                                />
                              </FormControl>
                              <FormControl>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  mt={6}
                                  onClick={() => {
                                    applySearch({});
                                    setIsFilter(true);
                                    handleCloseForm();
                                  }}
                                >
                                  Filter
                                </Button>
                              </FormControl>
                            </form>
                          </MenuItem>
                        </StyledForm>
                      </InputAdornment>
                    ),
                  }}
                />
              </Item>
              <Item>
                {isFilter ? (
                  <Button
                    variant="contained"
                    color="primary"
                    mt={6}
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      marginLeft: "-3px",
                    }}
                    onClick={() => {
                      resetFilter();
                      setDataToFilter(null);
                      setIsFilter(false);
                    }}
                  >
                    Clear filter
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      marginLeft: "-3px",
                    }}
                    onClick={() => {
                      applySearch({});
                      setIsFilter(true);
                    }}
                  >
                    Search
                  </Button>
                )}
              </Item>
            </Box>
          </div>
        </Grid>
        <Grid item lg={1} md={1}>
          <Box mt={6}>
            <IconButton
              aria-label="Settings"
              size="medium"
              aria-owns={loginMap ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleMapClick}
            >
              <FilterListIcon color="primary" />
            </IconButton>
            <StyledMenu
              id="icon-menu"
              anchorEl={loginMap}
              open={Boolean(loginMap)}
              onClose={handleMapClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleClickOpen}>Login Map</MenuItem>
              <Dialog
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "650px",
                    },
                  },
                }}
                open={openmap}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Use Google's location service?"}
                </DialogTitle>
                <DialogContent>
                  <GoogleMap
                    id="map"
                    mapContainerStyle={{
                      height: "500px",
                      width: "100%",
                      position: "relative",
                    }}
                    zoom={16}
                    center={mapCenter}
                    options={{ mapTypeId: "satellite" }}
                  >
                    {locations.map((location, index) => (
                      <Marker
                        key={index}
                        position={location}
                        icon={{
                          url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                          scaledSize: new window.google.maps.Size(30, 30),
                          origin: new window.google.maps.Point(0, 0),
                          anchor: new window.google.maps.Point(15, 15),
                        }}
                        title={location.title}
                        onClick={() => {
                          setSelectedLocation(location);
                        }}
                      />
                    ))}
                    {selectedLocation && (
                      <InfoWindow
                        position={selectedLocation}
                        onCloseClick={() => {
                          setSelectedLocation(null);
                        }}
                      >
                        <div
                          style={{
                            minWidth: "320px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ paddingRight: "12px" }}>
                            <p>Property: {selectedLocation.property}</p>
                            <p>Alias: {selectedLocation.alias}</p>
                            <p>Threat Level: {selectedLocation.threat_level}</p>
                            <p>
                              Encounter Time: {selectedLocation.encounter_time}
                            </p>
                            <p>Group: {selectedLocation.group}</p>
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      {/* {locations.length <= 0 && (
                        <Typography
                          sx={{
                            position: "absolute",
                            padding: "10px",
                            bottom: "200px",
                            color: "#ed6c02",
                            // zIndex: "9999",
                            marginLeft: "auto",
                            marginRight: "auto",
                            left: "0",
                            right: "0",
                            textAlign: "center",
                            background: "white",
                            opacity: "0.6",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          No encounters with known POI's have been recorded in
                          the last 30 days. Please adjust the date range or
                          check again later
                        </Typography>
                      )} */}
                    </Box>
                  </GoogleMap>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    color="warning"
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleClose} variant="contained" autoFocus>
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
              <MenuItem onClick={onButtonClick}>Download PDF</MenuItem>
            </StyledMenu>
          </Box>
        </Grid>
      </Grid>
      <Card
        sx={{
          marginTop: "30px",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">IP Address</TableCell>
                <TableCell align="right">Country</TableCell>
                <TableCell align="right">State/Region</TableCell>
                <TableCell align="right">City/Locality</TableCell>
                <TableCell align="right">Friendly</TableCell>
                <TableCell align="right">Login(UTC)</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            {loader ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {listAuditUser.map((row, index) => (
                  <TableRow>
                    <TableCell>{row.user_name}</TableCell>
                    <TableCell align="right">{row.user_email}</TableCell>
                    <TableCell align="right">{row.ip_address}</TableCell>
                    <TableCell align="right">{row.country}</TableCell>
                    <TableCell align="right">{row.state}</TableCell>
                    <TableCell align="right">{row.city}</TableCell>
                    <TableCell align="right">{row.friendly_name}</TableCell>
                    <TableCell align="right">{row.login_datetime}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="Settings"
                        size="medium"
                        aria-owns={anchorEl ? "icon-menu" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleActionClick(event, row);
                        }}
                      >
                        <SettingsIcon color="primary" />
                      </IconButton>
                      <StyledMenu
                        id="icon-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleActionClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            loginEditDialogOpen();
                            handleActionClose();
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            logoutAllSessions();
                            handleActionClose();
                          }}
                        >
                          Kick-user
                        </MenuItem>
                      </StyledMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecord}
        />
      </Card>
      {loginEditDialog && (
        <LoginEditDialog
          loginEditDialog={loginEditDialog}
          setLoginEditDialog={setLoginEditDialog}
          loginEditDialogOpen={loginEditDialogOpen}
          loginEditDialogClose={loginEditDialogClose}
          updatedRow={updatedRow}
          auditUserList={auditUserList}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default LoginRecords;

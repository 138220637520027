import React from "react";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  FormControl as MuiFormControl,
  Alert,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import * as Yup from "yup";
import { handleApiResonseErrors } from "../../../helper/helper";
import { OverrideAuthorization } from "../../../api";
import { DatePicker } from "@mui/lab";
import moment from "moment";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const AddAuthOverride = ({
  addAuthOverrideDialog,
  setAddAuthOverrideDialog,
  addAuthOverrideDialogOpen,
  addAuthOverrideDialogClose,
  authPerId,
  setPageLoad,
  getInvolvedAuthPerListing,
}) => {
  const dispatch = useDispatch();
  let initialValues = {
    verify_date: null,
    override_name: "",
    reason: "",
  };

  const validationSchema = Yup.object().shape({
    verify_date: Yup.string().nullable().required("Please enter date"),
    override_name: Yup.string().required("Please enter your name"),
    reason: Yup.string().required("Please enter reason"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formattedDate = values.verify_date
        ? moment(values.verify_date).format("YYYY-MM-DD")
        : "";
      let formData = {
        id: authPerId ? authPerId : null,
        verify_date: formattedDate,
        override_name: values.override_name,
        reason: values.reason,
      };
      OverrideAuthorization(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            getInvolvedAuthPerListing();
            dispatch(getMsg("Authorization overridden"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            addAuthOverrideDialogClose();
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } catch (err) {
      setPageLoad(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="viewKeyDetail-title-modal"
        aria-describedby="viewKeyDetail-desc-modal"
        open={addAuthOverrideDialog}
        onClose={addAuthOverrideDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="attendance-title-modal"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Override Authorization
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addAuthOverrideDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          {/* {authPerData.id ? null : ( */}
          <Alert severity="info">
            Please add the approval date and the name of the person providing
            this authorization override, along with the reason for the override
          </Alert>
          {/*  )} */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={3}>
                      <DatePicker
                        id="start-date"
                        label="Date *"
                        value={values.verify_date}
                        onChange={(newValue) => {
                          setFieldValue("verify_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="verify_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        label="Your Name *"
                        autoComplete="off"
                        name="override_name"
                        id="title"
                        InputLabelProps={{ shrink: true }}
                        placeholder="John Smith"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        label="Reason *"
                        autoComplete="off"
                        name="reason"
                        id="reason"
                        InputLabelProps={{ shrink: true }}
                        placeholder="AP away on leave, AP provided verbal authorization, etc."
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button color="primary" variant="contained" type="submit">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAuthOverride;

import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Checkbox,
  Grid,
  IconButton,
  /* Link, */
  /* Breadcrumbs as MuiBreadcrumbs, */
  /* Divider as MuiDivider, */
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  LockOpen as LockOpenIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;

function createData(
  name,
  color,
  email,
  type,
  web,
  mobile,
  mfa,
  created,
  lastseen,
  action
) {
  return {
    name,
    color,
    email,
    type,
    web,
    mobile,
    mfa,
    created,
    lastseen,
    action,
  };
}

const rows = [
  createData(
    "Darin RedAdmin",
    "blue",
    "darin@sovasystems.com",
    "Property Admin",
    0,
    0,
    0,
    "02/25/2016",
    "01/208/2022",
    0
  ),
  createData(
    "Detectify Vendor",
    "blue",
    "Darin@sova.cc",
    "Property Admin",
    1,
    1,
    1,
    "08/18/2018",
    "03/25/2021",
    1
  ),
  createData(
    "Kiosk User",
    "blue",
    "Kiosk@sova.cc",
    "Non-Security User",
    2,
    2,
    2,
    "08/07/2019",
    "07/01/2020",
    2
  ),
  createData(
    "My test Property user",
    "blue",
    "test987@sova.cc",
    "Property User",
    3,
    3,
    3,
    "02/17/2020",
    "01/20/2020",
    3
  ),
  createData(
    "NonSec User",
    "blue",
    "me@sovasystems.com",
    "Non-Security User",
    4,
    4,
    4,
    "07/27/2020",
    "10/13/2020",
    4
  ),
  createData(
    "Prince Dwivedi",
    "blue",
    "Prince.dwivedi@webnyxa.com",
    "Non-Security User",
    5,
    5,
    5,
    "01/28/2022",
    "01/28/2022",
    5
  ),
  createData(
    "Sandeep Kundu",
    "blue",
    "sandeepkundu4344@gmail.com",
    "Property User",
    7,
    7,
    7,
    "01/28/2022",
    "01/28/2022",
    7
  ),
  createData(
    "Sanjeev Kundu",
    "blue",
    "sanjeevkundu4344@gmail.com",
    "Property User",
    8,
    8,
    8,
    "11/29/2019",
    "03/02/2020",
    8
  ),
  createData(
    "Steve Wright",
    "red",
    "spwrig@amazon.com",
    "Property User",
    9,
    9,
    9,
    "11/29/2019",
    "11/23/2020",
    9
  ),
  createData(
    "Web Developer",
    "red",
    "test231@sova.cc",
    "Property User",
    10,
    10,
    10,
    "11/13/2018",
    "10/13/2020",
    10
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  { id: "color", numeric: true, disablePadding: false, label: "Color" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  { id: "type", numeric: true, disablePadding: false, label: "Type" },
  { id: "web", numeric: true, disablePadding: false, label: "Web" },
  { id: "mobile", numeric: true, disablePadding: false, label: "Mobile" },
  { id: "mfa", numeric: true, disablePadding: false, label: "MFA" },
  { id: "created", numeric: true, disablePadding: false, label: "Created" },
  {
    id: "lastseen",
    numeric: true,
    disablePadding: false,
    label: "Last Seen",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected } = props;
  const [actions, setActions] = React.useState("");

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              onChange={handleChange}
            >
              <MenuItem value={"Delete"}>Delete</MenuItem>
              <MenuItem value={"Web"}>Web</MenuItem>
              <MenuItem value={"Mobile"}>Mobile</MenuItem>
              <MenuItem value={"MFA"}>MFA</MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div>
        {numSelected > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {numSelected} records found
          </Typography>
        ) : (
          <Typography variant="h6" mt={3} id="tableTitle">
            {/* Nutrition */}
          </Typography>
        )}
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <Menu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
          >
            <MenuItem>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText
                aria-label="adduser"
                component={NavLink}
                to="/forms/adduser"
                size="large"
                primary="Add User"
                pl={0}
              />
            </MenuItem>
          </Menu>
        </Paper>
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const [state, setState] = React.useState({
    web: true,
    mobile: false,
    mfa: false,
  });

  const handleToggleWeb = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { web, mobile, mfa } = state;
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
          className="tableContainer"
          sx={{
            maxWidth: { md: "900px", lg: "992px", xl: "100%" },
            overflowX: "scroll",
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
            bodyStyle={{ overflow: "visible" }}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ lg: 'whiteSpace: "nowrap"' }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.color}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ lg: 'whiteSpace: "nowrap"' }}
                      >
                        {row.type}
                      </TableCell>
                      <TableCell align="left">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={web}
                              onChange={handleToggleWeb}
                              name="web"
                            />
                          }
                          label=""
                        />
                      </TableCell>
                      <TableCell align="left">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={mobile}
                              onChange={handleToggleWeb}
                              name="mobile"
                            />
                          }
                          label=""
                        />
                      </TableCell>
                      <TableCell align="left">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={mfa}
                              onChange={handleToggleWeb}
                              name="mfa"
                            />
                          }
                          label=""
                        />
                      </TableCell>
                      <TableCell align="left">{row.created}</TableCell>
                      <TableCell align="left">{row.lastseen}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          aria-label="Settings"
                          size="large"
                          aria-owns={anchorEl ? "icon-menu" : undefined}
                          aria-haspopup="true"
                          onClick={handleActionClick}
                          style={{ color: "#1374C5" }}
                        >
                          <SettingsIcon />
                        </IconButton>
                        <Menu
                          id="icon-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleActionClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          getContentAnchorEl={null}
                        >
                          <MenuItem>
                            <ListItemIcon>
                              <VisibilityIcon />
                            </ListItemIcon>
                            <ListItemText primary="View" pl={1} />
                          </MenuItem>
                          <MenuItem>
                            <ListItemIcon>
                              <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit" pl={1} />
                          </MenuItem>
                          <MenuItem>
                            <ListItemIcon>
                              <LockOpenIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pwd Change" pl={1} />
                          </MenuItem>
                          <MenuItem>
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delete" pl={1} />
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={11} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function AdvancedTable() {
  return (
    <React.Fragment>
      <Helmet title="Users" />
      <Typography variant="h3" gutterBottom display="inline">
        Users
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdvancedTable;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  IconButton,
  ListItemText,
  TableCell,
  TableRow,
  Button,
  Typography,
  ListItemIcon,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  DialogActions,
  Dialog,
  DialogTitle,
  CircularProgress,
  DialogContent,
  DialogContentText,
  Card,
  CardContent,
  Box,
  FormLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import styled from "styled-components/macro";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Floorrow = ({
  floorList,
  isItemSelected,
  labelId,
  handleClick,
  floorId,
  handleActionClick,
  handleActionClose,
  handleDeleteOpen,
  anchorEl,
  StyledMenu,
  setMsg,
  showFloorList,
  editFloor,
  setEditButtonStatus,
}) => {
  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const [snackopen, setSnackopen] = useState(false);
  const CloudUpload = styled(MuiCloudUpload)(spacing);

  const handleSnackClick = () => {
    setSnackopen(true);
  };
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };
  //Loop for showing floor no
  let number = [];
  for (let i = 0; i <= 119; i++) {
    // console.log(i);
    number.push(i);
  }

  const checkedValue = useSelector((state) => state.floorPlan.checked);
  return (
    <>
      <Snackbar
        open={snackopen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          variant="filled"
          sx={{ maxWidth: 600 }}
        >
          Floor has been successfully updated.
        </Alert>
      </Snackbar>
      <TableRow
        hover
        aria-checked={isItemSelected}
        selected={isItemSelected}
        tabIndex={-1}
        key={floorList.id}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, floorList.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
          />
        </TableCell>
        {checkedValue.includes("floor") && (
          <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            sx={{ lg: 'whiteSpace: "nowrap"' }}
          >
            {floorList.floor_no}
          </TableCell>
        )}
        {checkedValue.includes("floor_name") && (
          <TableCell align="left">{floorList.floor_name}</TableCell>
        )}
        {checkedValue.includes("region_name") && (
          <TableCell align="left">{floorList.region_name}</TableCell>
        )}
        {checkedValue.includes("floor_image") && (
          <TableCell align="left">{floorList.image_name}</TableCell>
        )}
        <TableCell align="left">
          <IconButton
            aria-label="Settings"
            size="large"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              handleActionClick(e, floorList.id);
              setMsg("Floor has been successfully updated.");
              // getEditFloor();
            }}
            style={{ color: "#1374C5" }}
          >
            <SettingsIcon />
          </IconButton>
          <StyledMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                editFloor();
                handleActionClose();
                setEditButtonStatus(true);
              }}
            >
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" pl={1} />
            </MenuItem>
            <MenuItem>
              <ListItemIcon>{<EditLocationIcon />}</ListItemIcon>
              <ListItemText primary="Edit Geolocation" pl={1} />
            </MenuItem>
            <MenuItem onClick={(handleActionClose, handleDeleteOpen)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" pl={1} />
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Floorrow;

import moment from "moment";
import React, { useState, useEffect } from "react";
import AddTaskOrEvent from "../inner-component/AddTaskOrEvent";
import EditEventModal from "../inner-component/EditEventModal";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import SelectDestinationDates from "../inner-component/SelectDestinationDates";
import { handleApiResonseErrors } from "../../../helper/helper";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css"; // for using DnD

import { GetEventData, UpdateEvent, AddEvent } from "../../../api";

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

function CalendarComponent({
  alertMessage,
  setLoading,
  hideEvent,
  hideUserEvent,
  resetDraggedUserEvent,
  draggedUserEvent,
  resetDraggedNoteEvent,
  draggedNoteEvent,
  removeNoteEvent,
  reloadEventsList,
}) {
  const [newSlotInfo, setNewSlotInfo] = useState({});
  const [editEventInfo, setEditEventInfo] = useState({});
  const [eventListing, setEventListing] = useState([]);
  const [userEventListing, setUserEventListing] = useState([]);
  const [allEventListing, setAllEventListing] = useState([]);
  const [eventsToShow, setEventsToShow] = useState([]);

  //  Add Task or Event Dialog
  const [addTaskEventDialog, setAddTaskEventDialog] = useState(false);
  const addTaskEventDialogOpen = (slotInfo) => {
    setNewSlotInfo(slotInfo);
    setAddTaskEventDialog(true);
  };
  const addTaskEventDialogClose = () => {
    setAddTaskEventDialog(false);
  };
  //Edit Event Pop up
  const [editEventPopUp, setEditEventPopUP] = useState(false);
  const editEventPopUPOpen = (event) => {
    setEditEventInfo(event);
    setEditEventPopUP(true);
  };
  const editEventPopUPClose = () => {
    setEditEventPopUP(false);
  };
  // Select Destination
  const [selectDestinationDatesDialog, setDestinationDatesDialog] =
    useState(false);
  const selectDestinationDatesDialogOpen = () => {
    setDestinationDatesDialog(true);
  };
  const selectDestinationDatesDialogClose = () => {
    setDestinationDatesDialog(false);
  };

  const moveEvent = ({ event, start, end }) => {
    setLoading(true);
    let formData = {
      id: event.id,
      start_date: moment(start).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(end).format("YYYY-MM-DD HH:mm:ss"),
    };
    UpdateEvent(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          alertMessage(res.message, "success");
          eventData();
        } else {
          alertMessage("Sorry! something wrong please try again", "error");
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          alertMessage(error, "error");
        }
      });
  };

  useEffect(() => {
    eventData();
  }, [reloadEventsList]);

  const eventData = () => {
    setLoading(true);
    GetEventData()
      .then((res) => {
        if (res.statusCode == 200) {
          getEvents(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };
  const getEvents = (data) => {
    const allEventList = data.map((t) => {
      return {
        id: t.id,
        title: t.name,
        start: t.start_date != "" ? new Date(t.start_date) : "",
        end: t.end_date != "" ? new Date(t.end_date) : "",
        start_date: t.start_date,
        end_date: t.end_date,
        start_time: t.start_time,
        end_time: t.end_time,
        notes: t.notes,
        colorEvento: t.color_code ? t.color_code : "blue",
        color: "white",
        type: t.type,
        profile_image: t.profile_image,
      };
    });
    setAllEventListing(allEventList);
    var eventList = data.reduce((eventList, t) => {
      if (t.type != "User") {
        eventList.push({
          id: t.id,
          title: t.name,
          start: t.start_date != "" ? new Date(t.start_date) : "",
          end: t.end_date != "" ? new Date(t.end_date) : "",
          start_date: t.start_date,
          end_date: t.end_date,
          start_time: t.start_time,
          end_time: t.end_time,
          notes: t.notes,
          colorEvento: t.color_code ? t.color_code : "blue",
          color: "white",
        });
      }
      return eventList;
    }, []);

    setEventListing(eventList);

    var userEventList = data.reduce((userEventList, t) => {
      if (t.type === "User") {
        userEventList.push({
          id: t.id,
          title: t.name,
          start: t.start_date != "" ? new Date(t.start_date) : "",
          end: t.end_date != "" ? new Date(t.end_date) : "",
          start_date: t.start_date,
          end_date: t.end_date,
          start_time: t.start_time,
          end_time: t.end_time,
          notes: t.notes,
          colorEvento: t.color_code ? t.color_code : "blue",
          color: "white",
        });
      }
      return userEventList;
    }, []);
    setUserEventListing(userEventList);
  };

  const onDropFromOutside = (event) => {
    if (draggedUserEvent || draggedNoteEvent) {
      setLoading(true);
      let formData = {
        start_date: moment(event.start).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(event.start).format("YYYY-MM-DD HH:mm:ss"),
        type: draggedUserEvent ? "User" : "Event",
        event_class: draggedNoteEvent
          ? draggedNoteEvent.event_class
          : "bg-primary",
        name: draggedUserEvent
          ? draggedUserEvent.UserName
          : draggedNoteEvent.title,
        user_id: draggedUserEvent ? draggedUserEvent.UserID : null,
      };
      AddEvent(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            alertMessage(res.message, "success");
            eventData();
            if (draggedNoteEvent) {
              removeNoteEvent(draggedNoteEvent.index);
            }
          } else {
            alertMessage("Sorry! something wrong please try again", "error");
          }
          resetDraggedUserEvent();
          resetDraggedNoteEvent();
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            alertMessage(error, "error");
          }
        });
    } else {
      alertMessage("Sorry! something wrong please try again", "error");
    }
  };

  useEffect(() => {
    eventToShow();
  }, [
    hideEvent,
    hideUserEvent,
    eventListing,
    userEventListing,
    allEventListing,
  ]);

  const eventToShow = () => {
    if (hideEvent === true && hideUserEvent === true) {
      setEventsToShow([]);
    } else if (hideEvent === false && hideUserEvent === false) {
      setEventsToShow(allEventListing);
    } else if (hideEvent === true && hideUserEvent === false) {
      setEventsToShow(userEventListing);
    } else if (hideEvent === false && hideUserEvent === true) {
      setEventsToShow(eventListing);
    }
  };

  return (
    <React.Fragment>
      <DragAndDropCalendar
        selectable
        views={["month", "week", "day"]}
        localizer={localizer}
        events={eventsToShow}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={(event) => editEventPopUPOpen(event)}
        onSelectSlot={(slotInfo) => {
          addTaskEventDialogOpen(slotInfo);
        }}
        onEventDrop={(event, start, end) => moveEvent(event, start, end)}
        onDropFromOutside={(event) => onDropFromOutside(event)}
        onEventResize={(event, start, end) => moveEvent(event, start, end)}
        eventPropGetter={(event) => {
          const backgroundColor = event.colorEvento
            ? event.colorEvento
            : "blue";
          const color = event.color ? event.color : "white";
          return { style: { backgroundColor, color } };
        }}
      />
      {addTaskEventDialog && (
        <AddTaskOrEvent
          addTaskEventDialog={addTaskEventDialog}
          setAddTaskEventDialog={setAddTaskEventDialog}
          addTaskEventDialogOpen={addTaskEventDialogOpen}
          addTaskEventDialogClose={addTaskEventDialogClose}
          newSlotInfo={newSlotInfo}
          alertMessage={(msg, type) => alertMessage(msg, type)}
          eventData={() => eventData()}
          setLoading={(status) => setLoading(status)}
        />
      )}
      {editEventPopUp && (
        <EditEventModal
          editEventPopUp={editEventPopUp}
          setEditEventPopUP={setEditEventPopUP}
          editEventPopUPOpen={editEventPopUPOpen}
          editEventPopUPClose={editEventPopUPClose}
          editEventInfo={editEventInfo}
          alertMessage={(msg, type) => alertMessage(msg, type)}
          eventData={() => eventData()}
          setLoading={(status) => setLoading(status)}
          selectDestinationDatesDialogOpen={selectDestinationDatesDialogOpen}
        />
      )}
      {selectDestinationDatesDialog && (
        <SelectDestinationDates
          selectDestinationDatesDialog={selectDestinationDatesDialog}
          setDestinationDatesDialog={setDestinationDatesDialog}
          selectDestinationDatesDialogOpen={selectDestinationDatesDialogOpen}
          selectDestinationDatesDialogClose={selectDestinationDatesDialogClose}
          editEventInfo={editEventInfo}
          alertMessage={(msg, type) => alertMessage(msg, type)}
          eventData={() => eventData()}
          setLoading={(status) => setLoading(status)}
        />
      )}
    </React.Fragment>
  );
}

export default CalendarComponent;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton,
  Card,
  CardContent,
  TextField,
  FormHelperText,
  Button,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  Grid,
  Paper,
  Chip,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  StorageLocationDropDown,
  GetPackageStorage,
  AddStorage,
  DeletePackageStorage,
  AddParentNode,
} from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StorageLocation = ({
  storageLocation,
  storageLocationClose,
  childToParent,
}) => {
  const dispatch = useDispatch();
  const [storage, setStorage] = useState([]);
  const [text, setText] = useState("");
  const [addLocationText, setAddLocationText] = useState("");

  // Storage Location
  const [storageLocationValue, setStorageLocationValue] = useState("");
  const selectStorageLocation = (event) => {
    setStorageLocationValue(event.target.value);
    getPackageStorage(event.target.value);
  };

  const handleAdd = (e) => {
    if (storageLocationValue != "") {
      AddStorage({
        description: e.target.value,
        parent_id: storageLocationValue,
      })
        .then((res) => {
          if (res.statusCode === 200) {
            getPackageStorage(storageLocationValue);
            // console.log("success");
          } else {
            console.log("err");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(getMsg("Please select parent storage location first."));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
  };

  const handleDelete = (chipToDelete) => () => {
    DeletePackageStorage(chipToDelete)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("success");
          getPackageStorage(storageLocationValue);
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [storageData, setStorageData] = useState([]);
  const storageList = () => {
    StorageLocationDropDown()
      .then((res) => {
        if (res.statusCode === 200) {
          setStorageData(res.data);
          childToParent(res.data);
        } else {
          setStorageData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPackageStorage = (value) => {
    GetPackageStorage({ params: { parent_id: value } })
      .then((res) => {
        if (res.statusCode === 200) {
          setStorage(res.data);
        } else {
          setStorage([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddLocation = () => {
    AddParentNode({ parent_node: addLocationText })
      .then((res) => {
        if (res.statusCode === 200) {
          setAddLocationText("");
          storageList();
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    storageList();
  }, []);
  return (
    <>
      <Dialog
        open={storageLocation}
        onClose={storageLocationClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "750px",
            },
          },
        }}
        aria-labelledby="storage-location-title"
        aria-describedby="storage-location-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="storage-location-title"
        >
          <Typography variant="h4" component="div">
            Storage Location
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={storageLocationClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item lg={6}>
              <FormControl mt={3}>
                <InputLabel
                  shrink={true}
                  id="department"
                  sx={{
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    marginLeft: "-4px",
                  }}
                >
                  Storage location
                </InputLabel>
                <Select
                  labelId="department"
                  id="storage-location"
                  name="current_storage_location"
                  value={storageLocationValue}
                  label="Storage location"
                  fullWidth
                  displayEmpty
                  onChange={selectStorageLocation}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {storageData.map((item) => {
                    return (
                      <MenuItem
                        divider={true}
                        value={item.value}
                        key={item.value}
                      >
                        {item.key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6}>
              <Box
                style={{
                  display: "flex",
                  marginBottom: 10,
                  alignItems: "center",
                }}
              >
                <FormControl mt={3} ml={3}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Location"
                    value={addLocationText}
                    onChange={(e) => setAddLocationText(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <Button
                  sx={{ marginLeft: 10, alignSelf: "flex-end" }}
                  variant="contained"
                  onClick={handleAddLocation}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Card
            variant="outlined"
            sx={{ marginBottom: "15px", minHeight: "200px" }}
          >
            <CardContent>
              <Paper
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {storage.map((data) => {
                  let icon;
                  return (
                    <ListItem key={data.description}>
                      <Chip
                        icon={icon}
                        label={data.description}
                        onDelete={
                          data.label === "" ? undefined : handleDelete(data.id)
                        }
                      />
                    </ListItem>
                  );
                })}
              </Paper>
              <TextField
                placeholder="type to add"
                sx={{ marginTop: "15px" }}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleAdd(e);
                    setText("");
                  }
                }}
              />
            </CardContent>
          </Card>
          <FormHelperText error={true}>
            *HINT: Use enter key on your keyboard to add multiple storage
            locations to this screen
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={storageLocationClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StorageLocation;

import React from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Paper,
  Select,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  RadioGroup,
  Radio,
  DialogActions,
  FormLabel,
  FormControlLabel,
  Grid,
  TextareaAutosize as MuiTextareaAutosize,
  Rating,
  FormHelperText,
  Typography,
  MenuItem,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DateTimePicker } from "@mui/lab";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const EditCommunicationLog = ({
  editCommunicationLog,
  editCommunicationLogClose,
}) => {
  const [dateTime, setDateTime] = React.useState(null);
  // Select Length Of Call
  const [lengthOfCall, setLengthOfCall] = React.useState("");
  const selectLengthOfCall = (event) => {
    setLengthOfCall(event.target.value);
  };
  const [ratings, setRatings] = React.useState(1);
  let initialValues = {
    datetime: "",
  };
  return (
    <>
      <Dialog
        aria-labelledby="comm-log-title"
        aria-describedby="comm-log-description"
        open={editCommunicationLog}
        onClose={editCommunicationLogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1140px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="comm-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Edit Communication Log
          </Typography>
          <DialogActions>
            <IconButton
              onClick={editCommunicationLogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues}>
            <form>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <FormControl mt={6}>
                    <DateTimePicker
                      id="date-range"
                      label="Datetime"
                      value={dateTime}
                      onChange={(newValue) => {
                        setDateTime(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "mm/dd/yyyy H:i:s",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <FormLabel>Origin</FormLabel>
                  <RadioGroup
                    aria-labelledby="origin"
                    name="origin"
                    defaultValue="incoming"
                    row
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Incoming"
                      value="1"
                      disabled
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Outgoing"
                      value="2"
                      disabled
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Notes Only"
                      value="3"
                      disabled
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <FormLabel>Type</FormLabel>
                  <RadioGroup
                    aria-labelledby="type"
                    name="type"
                    defaultValue="phone"
                    row
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Phone"
                      value="1"
                      disabled
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Email"
                      value="2"
                      disabled
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="SMS"
                      value="3"
                      disabled
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <FormControl>
                    <TextField
                      fullWidth
                      required
                      id="caller-name"
                      label="Caller name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <FormControl>
                    <TextField
                      fullWidth
                      required
                      id="caller-number"
                      label="Caller number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <FormControl>
                    <InputLabel
                      shrink
                      id="length-of-call"
                      sx={{
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        marginLeft: "-4px",
                      }}
                    >
                      Length of call
                    </InputLabel>
                    <Select
                      labelId="department"
                      id="length-of-call"
                      value={lengthOfCall}
                      label="Length of call"
                      onChange={selectLengthOfCall}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <FormControl>
                    <FormLabel>Purpose</FormLabel>
                    <TextareaAutosize placeholder="" />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <FormControl>
                    <FormLabel>Followup</FormLabel>
                    <TextareaAutosize placeholder="" />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <FormControl>
                    <FormLabel>Caller sentiment</FormLabel>
                    <FormHelperText sx={{ marginLeft: 0 }}>
                      Select how the call interaction was. Higher stars
                      represent a more positive experience.
                    </FormHelperText>
                    <Rating
                      name="ratings-controlled"
                      value={ratings}
                      onChange={(event, newValue) => {
                        setRatings(newValue);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <FormLabel>Status</FormLabel>
                  <RadioGroup
                    aria-labelledby="status"
                    name="status"
                    defaultValue="opened"
                    row
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Open"
                      value="1"
                      disabled
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Closed"
                      value="2"
                      disabled
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <DialogActions sx={{ padding: "20px 0" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={editCommunicationLogClose}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ marginLeft: "10px" }}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditCommunicationLog;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  TextField,
  FormControl as MuiFormControl,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Select,
  InputLabel as MuiInputLabel,
  Typography,
  Box,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const MaintenanceCycle = ({ maintenanceCycle, maintenanceCycleClose }) => {
  let initialValues = {};
  // Select occur
  const [occur, setOccur] = React.useState("");
  const selectOccur = (event) => {
    setOccur(event.target.value);
  };
  const [value, setDateValue] = React.useState(null);
  // Select every
  const [every, setEvery] = React.useState("");
  const selectEvery = (event) => {
    setEvery(event.target.value);
  };
  return (
    <>
      <Dialog
        open={maintenanceCycle}
        onClocse={maintenanceCycleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="maintenance-title"
        aria-describedby="maintenance-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="maintenance-title"
        >
          <Typography variant="h4">Maintenance Schedule</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={maintenanceCycleClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FormControl mt={3}>
                  <TextField
                    name="maintenance_name"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Maintenance name"
                    autoComplete="off"
                    placeholder=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl mt={3}>
                  <TextField
                    name="assigned_to"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Assigned to"
                    autoComplete="off"
                    placeholder=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <InputLabel
                    shrink
                    id="occur"
                    sx={{
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      marginLeft: "-4px",
                    }}
                  >
                    Occur
                  </InputLabel>
                  <Select
                    labelId="position"
                    id="occur"
                    value={occur}
                    label="Occur"
                    onChange={selectOccur}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">Select key ring</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <Typography variant="h4" component="div" gutterBottom>
                    Weekly
                  </Typography>
                  <Grid container spacing={6}>
                    <Grid item={12} md={6}>
                      <FormControl mt={4}>
                        <TextField
                          name="main_start"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Main start"
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item={12} md={6}>
                      <FormControl mt={4}>
                        <InputLabel
                          shrink
                          id="every"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Occur
                        </InputLabel>
                        <Select
                          labelId="position"
                          id="every"
                          value={every}
                          label="Every"
                          onChange={selectEvery}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">1</MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={<Checkbox defaultChecked disableRipple />}
                            label="Sun"
                          />
                          <FormControlLabel
                            control={<Checkbox disableRipple />}
                            label="Mon"
                          />
                          <FormControlLabel
                            control={<Checkbox disableRipple />}
                            label="Tue"
                          />
                          <FormControlLabel
                            control={<Checkbox disableRipple />}
                            label="Wed"
                          />
                          <FormControlLabel
                            control={<Checkbox disableRipple />}
                            label="Thu"
                          />
                          <FormControlLabel
                            control={<Checkbox disableRipple />}
                            label="Fri"
                          />
                          <FormControlLabel
                            control={<Checkbox disableRipple />}
                            label="Sat"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <DatePicker
                          id="date-range"
                          label="Next date"
                          value={value}
                          onChange={(newValue) => {
                            setDateValue(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Select range",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <CustomTextField
                          name="intro"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Introduction"
                          autoComplete="off"
                          placeholder=""
                          row={3}
                          multiline={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button color="primary" variant="outlined">
                  Save & add more
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ marginLeft: "10px" }}
                >
                  Save & done
                </Button>
              </Grid>
            </Grid>
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={maintenanceCycleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MaintenanceCycle;

export default [
  {
    id: "user_name",
    label: "User Name",
  },
  {
    id: "department",
    label: "Department",
  },
  {
    id: "item_id",
    label: "Item ID",
  },
  {
    id: "result",
    label: "Result",
  },
  {
    id: "date_time",
    label: "Date/Time",
  },
  {
    id: "comments",
    label: "Comments",
  },
  {
    id: "action",
    label: "Action",
  },
];

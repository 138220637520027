import React, { useEffect, useState } from "react";
import moment from "moment";
import PoiDialog from "./PoiDialog";
import { sova } from "../../config";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import styled from "styled-components/macro";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  Typography,
  Grid,
  Paper,
  List,
  ListItemButton,
  CardContent,
  Card,
  IconButton,
  Box,
  Button,
  Avatar,
  Stack,
  Tooltip,
  ListItem,
} from "@mui/material";
import {
  Circle as CirclelIcon,
  FilterAlt as FilterAltIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { PersonOfIntrestList } from "../../api";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import UserImage from "./inner-component/UserImage";

function PoiDashboard() {
  const [poiModal, setPoiModal] = useState(false);
  const poiModalOpen = () => {
    setPoiModal(true);
  };
  const poiModalClose = () => {
    setPoiImage(null);
    setPoiModal(false);
  };

  // states for filter
  const [startDateToFilter, setStartDateToFilter] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [endDateToFilter, setEndDateToFilter] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [propertyToFilter, setPropertyToFilter] = useState("");
  const [alias, setAlias] = useState("");
  const [threat, setThreat] = useState("");
  const [poiId, setpoiId] = useState();
  const [poiImage, setPoiImage] = useState(null);

  const [dataToFilter, setDataToFilter] = useState(null);

  const applySearch = () => {
    const filter = {
      encounter_start_date: moment(startDateToFilter).format("YYYY-MM-DD"),
      encounter_end_date: moment(endDateToFilter).format("YYYY-MM-DD"),
      alias: alias,
      property: propertyToFilter,
      threat: threat,
      id: poiId,
    };
    setDataToFilter(filter);
  };
  const resetFilter = () => {
    setStartDateToFilter(moment().subtract(30, "days").format("YYYY-MM-DD"));
    setEndDateToFilter(moment().format("YYYY-MM-DD"));
    setAlias("");
    setPropertyToFilter("");
    setThreat("");
    setpoiId();
    setDataToFilter(null);
  };

  const [poiData, setPoiData] = useState([]);

  // Google Maps State
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 37.773972,
    lng: -122.431297,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
  });

  // function to calculate center according to locations (for estimation center nearby all locations)
  const calculateCenter = (locations) => {
    if (locations.length === 0) {
      return { lat: 0, lng: 0 };
    }
    const centerLat = locations[0].lat;
    const centerLng = locations[0].lng;

    setMapCenter({ lat: centerLat, lng: centerLng });
  };

  const getData = () => {
    let payload = {
      encounter_start_date: moment(startDateToFilter).format("YYYY-MM-DD"),
      encounter_end_date: moment(endDateToFilter).format("YYYY-MM-DD"),
    };
    PersonOfIntrestList(null, dataToFilter ? dataToFilter : payload).then(
      (res) => {
        if (res.statusCode === 200) {
          console.log("Response: ", res);
          setPoiData(res.data);
        } else {
          setPoiData([]);
          console.log("Error: ", res.message);
        }
      }
    );
  };

  useEffect(() => {
    getData();
  }, [dataToFilter]);
  useEffect(applySearch, [poiId]);

  useEffect(() => {
    const newLocations = poiData.reduce((acc, poi) => {
      const encounters = poi.poi_encounters.map((encounter) => ({
        lat: Number(encounter.lat),
        lng: Number(encounter.lon),
        property: encounter?.property,
        alias: poi?.alias,
        threat_level: poi?.threat_level,
        encounter_time: encounter?.encounter_date,
        group: encounter?.property,
      }));
      return [...acc, ...encounters];
    }, []);

    setLocations([...newLocations]);
    calculateCenter(newLocations);
  }, [poiData]);

  const [userImageDialog, setUserImageDialog] = useState(false);
  const userImageDialogOpen = () => {
    setUserImageDialog(true);
  };

  const userImageDialogClose = () => {
    setUserImageDialog(false);
  };

  return (
    <>
      <Helmet title="POI Dashboard" />
      <Grid container sx={{ display: "flex", flexWrap: "wrap" }}>
        <Grid
          className="map_filter_card"
          item
          sm={6}
          md={4}
          xs={12}
          sx={{ height: "calc(100vh - 227px)", overflowY: "auto" }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              px: "10px",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: "12px",
                marginTop: "10px",
                fontWeight: "500",
                whiteSpace: "pre",
              }}
            >
              POI Encounters
            </Typography>
            <Box>
              {dataToFilter && (
                <Tooltip title="Clear Filter">
                  <IconButton
                    sx={{
                      padding: "8px 8px",
                    }}
                    onClick={resetFilter}
                  >
                    <HighlightOffOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Filter">
                <IconButton
                  sx={{
                    padding: "8px 8px",
                  }}
                  onClick={poiModalOpen}
                >
                  <FilterAltIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>

          <List>
            {poiData.length > 0 ? (
              poiData.map((poi) => {
                return poi.poi_encounters.map((encounter) => {
                  return (
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <ListItemButton
                        sx={{
                          paddingLeft: { xs: 0, md: 2 },
                          paddingRight: { xs: 5, md: 2 },
                          borderRadius: "10px",
                          cursor: "auto",
                        }}
                      >
                        <Box
                          className="view-button"
                          sx={{
                            padding: "20px",
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            borderRadius: "10px",
                            // marginLeft: "6px",
                            width: "100%",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Grid item xs={8} md={8} sx={{ padding: "0px" }}>
                              <Typography
                                sx={{ cursor: "pointer" }}
                                variant="h5"
                                gutterBottom
                                color="primary"
                                onClick={() => {
                                  setpoiId(poi?.id);
                                }}
                              >
                                {poi?.first || poi?.last
                                  ? `${poi?.first} ${poi?.last}`
                                  : "Unknown"}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                Property: {encounter?.property}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                Alias: {poi?.alias}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                Threat Level: {poi?.threat_level}
                                <IconButton>
                                  <CirclelIcon
                                    color="success"
                                    sx={{
                                      fontSize: 10,
                                    }}
                                  />
                                </IconButton>
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                Encounter Time: {encounter?.encounter_date}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                Group: {""}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              md={4}
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignContent: "space-between",
                                paddingLeft: "15px",
                                height: 180,
                              }}
                            >
                              <Stack
                                xs={{
                                  paddingLeft: "25px",
                                }}
                              >
                                <Avatar
                                  sx={{
                                    height: "60px",
                                    width: "60px",
                                    paddingLeft: "1px",
                                    cursor: "pointer",
                                  }}
                                  alt="Poi Image"
                                  src={poi?.image}
                                  onClick={() => {
                                    setPoiImage(poi?.image);
                                    userImageDialogOpen();
                                  }}
                                />
                              </Stack>

                              <IconButton
                                className="view-button-inner"
                                component={NavLink}
                                sx={{
                                  marginLeft: "5px",
                                  marginBottom: "5px",
                                  // background: "rgba(0,0,0,0.04)",
                                  marginLeft: "6px",
                                }}
                                to={`/poi/view-poi-encounter/${encounter.id}`}
                              >
                                <OpenInNewIcon />
                                <Typography
                                  sx={{
                                    color: "#666262",
                                  }}
                                >
                                  View
                                </Typography>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                });
              })
            ) : (
              <ListItem>
                <ListItemButton>
                  <Typography variant="body1">
                    No Encounters Found.Try Adusting Some Filters.
                  </Typography>
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Grid>

        <Grid
          item
          sm={6}
          md={8}
          sx={{ height: "calc(100vh - 227px)" }}
          // sx={{ maxHeight: "550px", overflowY: "scroll" }}
          xs={12}
        >
          {loadError ? (
            "Error loading maps"
          ) : !isLoaded ? (
            "Loading maps..."
          ) : (
            <GoogleMap
              id="map"
              mapContainerStyle={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
              zoom={16}
              center={mapCenter}
              options={{ mapTypeId: "satellite" }}
            >
              {locations.map((location, index) => (
                <Marker
                  key={index}
                  position={location}
                  icon={{
                    url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    scaledSize: new window.google.maps.Size(30, 30),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                  }}
                  title={location.title}
                  onClick={() => {
                    setSelectedLocation(location);
                  }}
                />
              ))}
              {selectedLocation && (
                <InfoWindow
                  position={selectedLocation}
                  onCloseClick={() => {
                    setSelectedLocation(null);
                  }}
                >
                  <div
                    style={{
                      minWidth: "320px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ paddingRight: "12px" }}>
                      <p>Property: {selectedLocation.property}</p>
                      <p>Alias: {selectedLocation.alias}</p>
                      <p>Threat Level: {selectedLocation.threat_level}</p>
                      <p>Encounter Time: {selectedLocation.encounter_time}</p>
                      <p>Group: {selectedLocation.group}</p>
                    </div>
                  </div>
                </InfoWindow>
              )}
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {locations.length <= 0 && (
                  <Typography
                    sx={{
                      position: "absolute",
                      padding: "10px",
                      bottom: "200px",
                      color: "#ed6c02",
                      // zIndex: "9999",
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: "0",
                      right: "0",
                      textAlign: "center",
                      background: "white",
                      opacity: "0.6",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    No encounters with known POI's have been recorded in the
                    last 30 days. Please adjust the date range or check again
                    later
                  </Typography>
                )}
              </Box>
            </GoogleMap>
          )}
        </Grid>
      </Grid>

      {poiModal && (
        <PoiDialog
          poiModal={poiModal}
          setPoiModal={setPoiModal}
          poiModalClose={poiModalClose}
          poiModalOpen={poiModalOpen}
          startDateToFilter={startDateToFilter}
          setStartDateToFilter={setStartDateToFilter}
          endDateToFilter={endDateToFilter}
          setEndDateToFilter={setEndDateToFilter}
          propertyToFilter={setPropertyToFilter}
          setPropertyToFilter={setPropertyToFilter}
          alias={alias}
          setAlias={setAlias}
          threat={threat}
          setThreat={setThreat}
          applySearch={applySearch}
        />
      )}
      {userImageDialog && (
        <UserImage
          userImageDialog={userImageDialog}
          userImageDialogClose={userImageDialogClose}
          poiImage={poiImage}
        />
      )}
    </>
  );
}

export default PoiDashboard;

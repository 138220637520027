import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DailyActivityData } from "../../../api";
import { subMonths, format } from "date-fns";
import moment from "moment";
import CustomDateRange from "../../../components/form-components/CustomDateRange";

const Paper = styled(MuiPaper)(spacing);

const DailyActivityD1 = (props) => {
  const {
    nttcompleted,
    setNttCompleted,
    nttCompletedClose,
    taskNameDialog,
    activityNameDialog,
  } = props;

  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  const [startDateToFilter, setStartDateToFilter] = useState(
    subMonths(new Date(), 1)
  );
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const [dailyActivityLog, setDailyActivityLog] = useState([]);
  const [fadebox, setFadedBox] = React.useState(false);

  // Method for daily activity log dialog listing
  const getDailyActivityLog = () => {
    let formatedsDate = moment(startDateToFilter).format("YYYY-MM-DD"); //startDateToFilter;
    let formatedeDate = moment(endDateToFilter).format("YYYY-MM-DD"); //endDateToFilter;

    let payload = {
      activity: activityNameDialog,
      task_name: taskNameDialog,
      start_date: formatedsDate, //"2018-12-04",
      end_date: formatedeDate, // "2022-06-01",
    };
    setDialogLoader(true);
    DailyActivityData(payload)
      .then((res) => {
        setDialogLoader(false);
        setDailyActivityLog(res.data);
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getDailyActivityLog();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "750px",
            },
          },
        }}
        open={nttcompleted}
        onClose={nttCompletedClose}
        aria-labelledby="ntt-title"
        aria-describedby="ntt-description"
      >
        <DialogTitle
          id="ntt-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            {activityNameDialog} - {taskNameDialog}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setNttCompleted(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Grid container spacing={4} mt={2} sx={{ padding: "0 24px" }}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="dateRange"
                label="Date range"
                value={
                  startDateToFilter && endDateToFilter
                    ? moment(startDateToFilter).format("MM-DD-YYYY") +
                      " - " +
                      moment(endDateToFilter).format("MM-DD-YYYY")
                    : ""
                }
                onClick={() => setFadedBox((prev) => !prev)}
                // onChange={handleChange}

                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Select date range"
              />
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                  }}
                  selectedStart={startDateToFilter}
                  selectedEnd={endDateToFilter}
                />
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                color="primary"
                variant="contained"
                sx={{ height: "100%" }}
                onClick={getDailyActivityLog}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Paper mt={3}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Tour</TableCell>
                    <TableCell>Officer</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Remarks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogLoader ? (
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <>
                      {dailyActivityLog.map((item, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell>{item.activity_time}</TableCell>
                            <TableCell>{item.user_name}</TableCell>
                            <TableCell>{item.location}</TableCell>
                            <TableCell>{item.comments}</TableCell>
                          </TableRow>
                        );
                      })}
                      {dailyActivityLog.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={5}>
                            No data for selected date range
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={nttCompletedClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DailyActivityD1;

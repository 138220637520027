import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { SendFcmMessage } from "../../../../api";

const ListSendNotice = ({
  sendNoticeDialog,
  setSendNoticeDialog,
  sendNoticeDialogOpen,
  sendNoticeDialogClose,
  updatedRow,
}) => {
  const dispatch = useDispatch();
  const sendFcmMessage = () => {
    let payload = {
      id: updatedRow.id,
    };
    SendFcmMessage(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          sendNoticeDialogClose();
          dispatch(getMsg("Notification Sent Successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something Went Wrong, Please try Again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={sendNoticeDialog}
        onClose={sendNoticeDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" gutterBottom mt={1}>
            Send Notification
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setSendNoticeDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom mt={3}>
            Click the button below to send a notification to the selected device
            with the Bluetooth name as shown in the selected record. Make sure
            the device is turned on and connected to the Internet.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button onClick={sendFcmMessage} variant="contained" color="primary">
            Send Notification
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={sendNoticeDialogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListSendNotice;

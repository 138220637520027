import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import {
  GetAddEquipmentType,
  AddEquipType,
  UpdateEquipmentType,
  ViewEquipmentType,
} from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Button,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel as MuiInputLabel,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const EditEquipmentType = (props) => {
  const { editClose, open, isCheckedValue, getEquipmentTypeTree } = props;
  //get all task checkbox
  const [modulearray, setmodulearray] = useState([]);
  const [viewEquipType, setViewEquipType] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [close, setClose] = useState("");
  const dispatch = useDispatch();

  let sLevel = [];
  for (let i = 1; i <= 10; i++) {
    sLevel.push(i);
  }
  const getViewEquipmentType = () => {
    ViewEquipmentType(isCheckedValue)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewEquipType(res.data);
        } else {
          setViewEquipType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { name, security_level, parent_id } = viewEquipType;
  useEffect(() => {
    getViewEquipmentType();
  }, []);
  const validationSchema = Yup.object().shape({
    equip_type_name: Yup.string().required("Please enter equipment type name"),
    security_level: Yup.string().required("Please select security level"),
  });
  const initialValues = {
    security_level: security_level ? security_level : "",
    equip_type_name: name ? name : "",
  };
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        parent_id: parent_id,
        name: values.equip_type_name,
        security_level: values.security_level,
      };
      UpdateEquipmentType(isCheckedValue, formData)
        .then((res) => {
          if (res.statusCode === 200) {
            getEquipmentTypeTree();
            dispatch(getSnackOpen());
            dispatch(getMsg("Equipment type has been updated successfully"));
            dispatch(getSnackAlert("success"));
            // getEquipmentType();
            editClose();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <div>
      <Dialog
        aria-labelledby="add-equipment-title"
        aria-describedby="add-equipment-description"
        open={open}
        onClose={editClose}
        sx={{
          "& .MuiFormControl-root": {
            display: "block",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle id="add-equipment-title">Edit Equipment Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  values,
                  status,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={12} mt={6}>
                      <FormControl>
                        <CustomTextField
                          name="equip_type_name"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Equipment type name*"
                          autoComplete="off"
                          placeholder="No special characters"
                        />
                        <FormHelperText
                          sx={{ color: "#d32f2f", marginLeft: "14px" }}
                        >
                          {errMsg}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} mt={6}>
                      <FormControl>
                        <InputLabel
                          shrink
                          id="security_level"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Security level*
                        </InputLabel>
                        <Select
                          labelId="security_level"
                          id="security_level"
                          name="security_level"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.security_level}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.security_level && errors.security_level
                          )}
                          helperText={
                            touched.security_level && errors.security_level
                          }
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            Select security level
                          </MenuItem>
                          {sLevel.map((data) => {
                            return (
                              <MenuItem value={data} key={data}>
                                {data}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText
                          sx={{ color: "#d32f2f", marginLeft: "14px" }}
                        >
                          {touched.security_level && errors.security_level}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} mt={6}>
                      <Button color="primary" variant="contained" type="submit">
                        Save
                      </Button>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditEquipmentType;

export default [
  {
    id: "threat_level",
    label: "TL",
  },
  {
    id: "id",
    label: "ID",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "first_name",
    label: "First Name",
  },
  {
    id: "last_name",
    label: "Last Name",
  },
  {
    id: "alias",
    label: "Alias",
  },
  {
    id: "race",
    label: "Race",
  },
  {
    id: "age",
    label: "Age",
  },
  {
    id: "height",
    label: "Ht.",
  },
  {
    id: "weight",
    label: "Wt.",
  },

  {
    id: "image",
    label: "Image",
  },
  {
    id: "watch",
    label: "Watch",
  },
  {
    id: "enc",
    label: "Enc",
  },
  {
    id: "last_updated",
    label: "Last Updated",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { setEmployeeAccidentList } from "../redux/slices/report/reportTableColSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Pagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  TrendingDown as TrendingDownIcon,
  Print as PrintIcon,
  Send as SendIcon,
  Mail as MailIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../helper/helper";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  EmployeeAccidentList,
  DeleteEmployeeAccident,
  PrintEarPdf,
  EarDropdownPosition,
} from "../api";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PeekDialog from "./ear-component/PeekDialog";
import SendEar from "./ear-component/SendEar";
import EmailDialog from "./ear-component/EmailDialog";
import StyledColumnOptions from "../helper/StyledColumnOptions";
import TaskList from "./reports/task-list/TaskList";
import EarDialog from "./EarDialog";
import TaskLog from "./ear-component/TaskLog";
import SendEarForm from "./ear-component/SendEarForm";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
// const useStyles = makeStyles((theme) => ({
//   alphabets: {
//     marginTop: "15px",
//     "& .MuiList-root": {
//       display: "flex",
//       overflowX: "auto",
//       background: "#7aa8ff0d",
//       padding: "0px",
//       "& .MuiListItemButton-root": {
//         paddingLeft: "13px",
//         paddingRight: "13px",
//         textAlign: "center",
//       },
//     },
//     "& .MuiList-root::-webkit-scrollbar": {
//       width: "5px",
//       height: "5px",
//     },
//     "& .MuiList-root::-webkit-scrollbar-track": {
//       backgroundColor: "#bed2f561",
//     },
//     "& .MuiList-root::-webkit-scrollbar-thumb": {
//       backgroundColor: "#92a6c96e",
//     },
//   },
// }));

// const TableWrapper = styled.div`
//   overflow-y: auto;
//   max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
// `;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  id,
  name,
  position,
  reportdate,
  doctorvisit,
  emergencyroom,
  enjurytype,
  emp,
  mgr,
  action
) {
  return {
    id,
    name,
    position,
    reportdate,
    doctorvisit,
    emergencyroom,
    enjurytype,
    emp,
    mgr,
    action,
  };
}
const rows = [
  createData(
    "139",
    "Sandeep Kundu",
    "Director of Security",
    0,
    "No",
    "No",
    "illness",
    0,
    0,
    0
  ),
  createData(
    "140",
    "Sandeep mundu",
    "Director of Security",
    0,
    "No",
    "No",
    "Enjury",
    0,
    0,
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Employee",
  },
  {
    id: "position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "reportdate",
    numeric: true,
    disablePadding: false,
    label: "Date of Report",
  },
  {
    id: "doctorvisit",
    numeric: true,
    disablePadding: false,
    label: "Doctor Visit",
  },
  {
    id: "emergencyroom",
    numeric: true,
    disablePadding: false,
    label: "Emergency Room",
  },
  {
    id: "enjurytype",
    numeric: true,
    disablePadding: false,
    label: "Injury Type",
  },
  {
    id: "emp",
    numeric: true,
    disablePadding: false,
    label: "Emp",
  },
  {
    id: "mgr",
    numeric: true,
    disablePadding: false,
    label: "Mgr",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            // padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {/* {headCell.icon} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setCurrentPage,
    filterRecord,
    setDataToFilter,
    tableCalRedux,
    defalutTableCalRedux,
  } = props;
  const [actions, setActions] = React.useState("");
  const [searchName, setSearchName] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [searchDate, setSearchDate] = useState(null);
  const [searchInjuryType, setSearchInjuryType] = useState("");
  const [searchPosition, setSearchPosition] = useState("");
  const [positionList, setPositionList] = useState([]);
  const selectPosition = (event) => {
    setSearchPosition(event.target.value);
  };
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  //Method for search filter
  const applySearch = () => {
    const payload = {
      name: searchName,
      emp_position: searchPosition,
      injury_datetime: searchDate
        ? moment(searchDate).format("YYYY-MM-DD")
        : "",
      report_type: searchInjuryType,
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setSearchName("");
    setSearchPosition("");
    setSearchDate(null);
    setSearchInjuryType("");
    filterRecord({});
  };

  const positonDropdown = () => {
    EarDropdownPosition()
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    positonDropdown();
  }, []);
  const [earDialog, setEarDialog] = useState(false);
  const earDialogOpen = () => {
    setEarDialog(true);
  };
  const earDialogClose = () => {
    setEarDialog(false);
  };

  return (
    <Toolbar>
      <>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ whiteSpace: "nowrap" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            mt={3}
            id="tableTitle"
            style={{ whiteSpace: "nowrap" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              type="text"
              placeholder="Search by name, or click down arrow"
              autoComplete="off"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                  setCurrentPage(1);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="employee"
                              label="Employee"
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={searchName}
                              onChange={(e) => {
                                setSearchName(e.target.value);
                              }}
                              placeholder="Name"
                            />
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="position"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Position
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="position"
                              value={searchPosition}
                              onChange={selectPosition}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">--Select position--</MenuItem>
                              {positionList.map((item) => {
                                return (
                                  <MenuItem value={item.key} key={item.value}>
                                    {item.key}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <DatePicker
                              id="date-range"
                              label="Approx date of accident"
                              value={searchDate}
                              onChange={(newValue) => {
                                setSearchDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Select range",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel component="legend">
                              Injury type
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="deleted-users"
                              name="radio-buttons-group"
                              value={searchInjuryType}
                              onChange={(e) => {
                                setSearchInjuryType(e.target.value);
                              }}
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Injury"
                                value="injury"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Illness"
                                value="illness"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Near miss"
                                value="near_miss"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              onClick={(e) => {
                                applySearch();
                                setIsFilter(true);
                                handleCloseForm();
                                setCurrentPage(1);
                              }}
                              sx={{ width: "175px", marginRight: "5px" }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setSearchName("");
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100px",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                }}
              >
                Clear Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100px",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchName !== "") {
                    applySearch({});
                    setIsFilter(true);
                    setCurrentPage(1);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-ear-reports"
              component={NavLink}
              to="/add-ear-reports"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add New EAR" pl={0} />
            </MenuItem>
            <MenuItem onClick={earDialogOpen}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Task List" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setEmployeeAccidentList(data));
                    }}
                    listing={headCells}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setEmployeeAccidentList(defalutTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
      {earDialog && (
        <EarDialog
          earDialog={earDialog}
          earDialogOpen={earDialogOpen}
          earDialogClose={earDialogClose}
          setEarDialog={setEarDialog}
        />
      )}
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  //sgtates related to Listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [earList, setEarList] = useState([]);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [recordObj, setRecordObj] = useState(null);
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const [emailModal, setEmailModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);
  const classes = useStyles();
  const tableCalRedux = useSelector(
    (state) => state.reportTableCol.employeeAccidentList
  );
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultReportTableCol.employeeAccidentList
  );
  const emailModalOpen = () => {
    setEmailModal(true);
  };
  const emailModalClose = () => {
    setEmailModal(false);
  };

  const [sendEarFormOpen, setSendEarFormOpen] = useState(false);
  const handleOpenForm = () => {
    setSendEarFormOpen(true);
  };

  const handleSendCloseForm = () => {
    setSendEarFormOpen(false);
  };

  const [sendModal, setSendModal] = useState(false);
  const sendModalOpen = () => {
    setSendModal(true);
  };
  const sendModalClose = () => {
    setSendModal(false);
  };

  const [peekModal, setPeekModal] = useState(false);

  const peekModalOpen = () => {
    setPeekModal(true);
  };
  const peekModalClose = () => {
    setPeekModal(false);
  };

  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, Id, recobj) => {
    console.log(recobj);
    setAnchorEl(event.currentTarget);
    setRecordId(Id);
    setRecordObj(recobj);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setRecordId(null);
    setRecordObj(null);
  };

  const onButtonClick = () => {
    setPdfLoader(true);
    PrintEarPdf(recordId)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-EAR.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setPdfLoader(false);
        } else {
          console.log("something went wrong");
          setPdfLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPdfLoader(false);
      });
  };
  //handler for deleting the tour
  const DeleteEmployeeAccidentRecord = () => {
    handleDeleteClose();
    DeleteEmployeeAccident(recordId)
      .then((res) => {
        if (res.statusCode == 200) {
          getEmployeeAccidentList();
          dispatch(getMsg("EAR report successfully deleted"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        // console.log("err");
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setRecordId(null);
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  // Method for getting listing data
  const getEmployeeAccidentList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      //  sorting: {
      //    order: order,
      //    by: orderBy,
      //  },
      ...dataToFilter,
    }; //this will be used for sending filtered data
    EmployeeAccidentList(currentPage, payload)
      .then((res) => {
        console.log(res.data);
        if (res.statusCode == 200) {
          setEarList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setEarList(res.data);
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setEarList([]);
        setTotalRecord(0);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  useEffect(() => {
    getEmployeeAccidentList();
  }, [rowsPerPage, currentPage, dataToFilter]);

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  // Task List Modal
  const [taskList, setTaskList] = useState(false);
  const taskListOpen = () => {
    setTaskList(true);
  };
  const taskListClose = () => {
    setTaskList(false);
  };

  const showAlertTost = (msg, type) => {
    dispatch(getMsg(msg));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };

  //Equipment log modal
  const [open, setOpen] = React.useState(false);
  const clickLogModalOpen = () => {
    setOpen(true);
    setAnchorEl(null);
  };
  const logModalClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this Employee Accident Record ?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={(handleDeleteClose, DeleteEmployeeAccidentRecord)}
            autoFocus
            color="primary"
            sx={{ ml: "10px" }}
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setCurrentPage={setCurrentPage}
          filterRecord={(data) => filterRecord(data)}
          setDataToFilter={setDataToFilter}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(earList, getComparator(order, orderBy))
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.id === true ? "" : "hide-cell"
                          }
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.name === true ? "" : "hide-cell"
                          }
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.position === true ? "" : "hide-cell"
                          }
                        >
                          {row.emp_position}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.reportdate === true ? "" : "hide-cell"
                          }
                        >
                          {row.injury_datetime}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.doctorvisit === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.doctor_called == "Y" ? "Yes" : "No"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.doctorvisit === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.emergencyroom == "Y" ? "Yes" : "No"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                            color:
                              row.report_type == "injury"
                                ? "red"
                                : row.report_type == "illness"
                                ? "#6fbdff"
                                : "#daac16",
                          }}
                          className={
                            tableCalRedux.enjurytype === true ? "" : "hide-cell"
                          }
                        >
                          {row.report_type}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.emp === true ? "" : "hide-cell"
                          }
                        >
                          <Tooltip
                            title={
                              row.signature_image !== null
                                ? `Completed on : ${row.modified}`
                                : `Incomplete. Last Updated : ${row.modified}`
                            }
                          >
                            <IconButton
                              sx={{
                                color:
                                  row.signature_image !== null
                                    ? "#00d600"
                                    : "red",
                              }}
                              component={NavLink}
                              to={`/edit-employee-accidents/${row.id}`}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          {/* <IconButton>
                          <AddCircleIcon />
                        </IconButton> */}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                          className={
                            tableCalRedux.mgr === true ? "" : "hide-cell"
                          }
                        >
                          {row.mgr == "Y" ? (
                            <Tooltip
                              title={
                                row.manager_sign_image !== null
                                  ? `Completed on : ${row.modified}`
                                  : `Incomplete. Last Updated : ${row.modified}`
                              }
                            >
                              <IconButton
                                // color="success"
                                sx={{
                                  color:
                                    row.manager_sign_image !== null
                                      ? "#00d600"
                                      : "red",
                                }}
                                component={NavLink}
                                to={`/edit-ear/${row.id}`}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title={`Updated : ${row.modified}`}>
                              <IconButton
                                component={NavLink}
                                // to={`/edit-ear/${row.id}`}
                                to={`/add-ear/${row.id}`}
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id, row)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              aria-label="view-employee-accidents"
                              component={NavLink}
                              to={`/view-employee-accidents/${recordId}`}
                            >
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText primary="View" pl={1} />
                            </MenuItem>
                            {/* <MenuItem
                              aria-label="edit-ear-reports"
                              component={NavLink}
                              to="/edit-ear-reports"
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem> */}
                            <MenuItem onClick={peekModalOpen}>
                              <ListItemIcon>
                                <TrendingDownIcon />
                              </ListItemIcon>
                              <ListItemText primary="Peek" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={(handleActionClose, handleDeleteOpen)}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={onButtonClick}>
                              <ListItemIcon>
                                <PrintIcon />
                              </ListItemIcon>
                              <ListItemText primary="Print EAR" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={
                                recordObj?.emp == "Y" && recordObj?.mgr == "Y"
                                  ? sendModalOpen
                                  : handleOpenForm
                              }
                            >
                              <ListItemIcon>
                                <SendIcon />
                              </ListItemIcon>
                              <ListItemText primary="Send EAR" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={emailModalOpen}>
                              <ListItemIcon>
                                <MailIcon />
                              </ListItemIcon>
                              <ListItemText primary="Email Manager" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={taskListOpen}>
                              <ListItemIcon>
                                <ListAltIcon />
                              </ListItemIcon>
                              <ListItemText primary="Task List" pl={1} />
                            </MenuItem>
                            {recordObj?.ModuleTaskLog.length > 0 && (
                              <MenuItem onClick={clickLogModalOpen}>
                                <ListItemIcon>
                                  <ListAltIcon />
                                </ListItemIcon>
                                <ListItemText primary="Task Log" pl={1} />
                              </MenuItem>
                            )}
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {totalRecord === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                    <TableCell colSpan={10} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {peekModal && (
        <PeekDialog
          peekModal={peekModal}
          setPeekModal={setPeekModal}
          peekModalOpen={peekModalOpen}
          peekModalClose={peekModalClose}
          recordObj={recordObj}
        />
      )}
      {sendEarFormOpen && (
        <SendEarForm
          sendEarFormOpen={sendEarFormOpen}
          setSendEarFormOpen={setSendEarFormOpen}
          handleOpenForm={handleOpenForm}
          handleSendCloseForm={handleSendCloseForm}
          recordId={recordId}
        />
      )}

      {sendModal && recordObj?.emp == "Y" && recordObj?.mgr == "Y" && (
        <SendEar
          sendModal={sendModal}
          setSendModal={setSendModal}
          sendModalOpen={sendModalOpen}
          sendModalClose={sendModalClose}
          recordId={recordId}
          handleSendCloseForm={handleSendCloseForm}
        />
      )}
      {emailModal && (
        <EmailDialog
          emailModal={emailModal}
          setEmailModal={setEmailModal}
          emailModalOpen={emailModalOpen}
          emailModalClose={emailModalClose}
          recordId={recordId}
          recordObj={recordObj}
        />
      )}

      {taskList && (
        <TaskList
          taskList={taskList}
          setTaskList={setTaskList}
          taskListOpen={taskListOpen}
          taskListClose={taskListClose}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          setLoader={(status) => setLoader(status)}
          module_id={recordObj.id}
          taskTitle={
            recordObj.name +
            "," +
            recordObj.report_type +
            " " +
            recordObj.injury_datetime
          }
          moduleType="EAR"
        />
      )}

      {/* Equipment log popup */}
      {open && (
        <TaskLog
          setOpen={setOpen}
          open={open}
          logModalClose={logModalClose}
          id={recordId}
          recordObj={recordObj}
        />
      )}
      {pdfLoader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </div>
  );
}

function EmployeeAccidents() {
  return (
    <React.Fragment>
      <Helmet title="Employee Accident Reports" />
      <Typography variant="h3" gutterBottom display="inline">
        Employee Accident Reports
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EmployeeAccidents;

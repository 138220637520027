import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Dashboard from "./inner-component/Dashboard";
import Aeds from "./inner-component/Aeds";
import UpdatePassword from "./inner-component/UpdatePassword";
import AedInspections from "./inner-component/AedInspections";
import TrainedResponders from "./inner-component/TrainedResponders";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Paper as MuiPaper,
  Divider as MuiDivider,
} from "@mui/material";

import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
// Classes Styling
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #0000000f",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function aedTabs(index) {
  return {
    id: `incident-tab-${index}`,
    "aria-controls": `incident-tabpanel-${index}`,
  };
}
function AedManagementContent(props) {
  const classes = useStyles();
  /* Tabs */
  const [tabValue, setTabValue] = React.useState(0);
  const [expiredParam, setExpiredParam] = useState("");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Update Password
  const [updatePassword, setUpdatePassword] = useState(false);
  const updatePasswordOpen = () => {
    setUpdatePassword(true);
  };
  const updatePasswordClose = () => {
    setUpdatePassword(false);
  };
  const handleExpiredChange = (val) => {
    setExpiredParam(val);
  };
  return (
    <>
      <Paper mt={6}>
        <Box className={classes.tabs}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Aed-management-tabs"
            variant="scrollable"
          >
            <Tab label="Dashboard" {...aedTabs(0)} />
            <Tab label="AED's" {...aedTabs(1)} />
            <Tab label="AED Inspections" {...aedTabs(2)} />
            <Tab label="Trained Responders" {...aedTabs(3)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Dashboard
            onExpiredChange={handleExpiredChange}
            expiredParamVal={expiredParam}
          />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={1}
          sx={{ "& .MuiBox-root": { padding: 0 } }}
        >
          <Aeds />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <AedInspections />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <TrainedResponders expiredParam={expiredParam} />
        </TabPanel>
      </Paper>
      {updatePassword && (
        <UpdatePassword
          updatePassword={updatePassword}
          setUpdatePassword={setUpdatePassword}
          updatePasswordOpen={updatePasswordOpen}
          updatePasswordClose={updatePasswordClose}
        />
      )}
    </>
  );
}

function AedManagement() {
  return (
    <React.Fragment>
      <Helmet title="AED Management" />
      <Typography variant="h3" gutterBottom display="inline">
        AED Monthly Maintenance and Inspection
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/employee-accidents">
          Employee Accidents
        </Link>
        <Typography>Add EAR Reports</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}
      <AedManagementContent />
    </React.Fragment>
  );
}

export default AedManagement;

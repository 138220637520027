import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import AddLockerAssignment from "./inner-component/AddLockerAssignment";
import LockerLogDialog from "./inner-component/LockerLogDialog";
import AddEditInternalLog from "./inner-component/AddEditInternalLog";
import AddLocker from "./inner-component/AddLocker";
import {
  LockerList,
  DeleteLocker,
  LockerRoomlist,
  DeletelockerLog,
} from "../../api";
import { CSVLink } from "react-csv";
import LockersListCol from "../table-columns-json/logs/LockersList";
import { setLockersList } from "../../redux/slices/logs/logTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import { useDispatch, useSelector } from "react-redux";
import AddEditLockerRoom from "./inner-component/AddEditLockerRoom";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import Toaster from "../tasks/Toaster";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  InsertDriveFile as InsertDriveFileIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
  Https as HttpsIcon,
  Person as PersonIcon,
  DeviceHub as DeviceHubIcon,
  ExitToApp as ExitToAppIcon,
  DoDisturbOn as DoDisturbOnIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 120px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "465px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  lockernumber,
  roomname,
  status,
  assignment,
  locktype,
  action
) {
  return {
    lockernumber,
    roomname,
    status,
    assignment,
    locktype,
    action,
  };
}
const rows = [
  createData("3451", "Mens Locker", "Unassigned", "Director of security", 0, 0),
  createData("3455", "Mens Locker", "Unassigned", "Director of security", 0, 0),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "lockernumber",
    numeric: true,
    disablePadding: true,
    label: "Locker Number",
  },
  {
    id: "roomname",
    numeric: true,
    disablePadding: false,
    label: "Room Name",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "assignment",
    numeric: true,
    disablePadding: false,
    label: "Assignment",
  },
  {
    id: "locktype",
    numeric: true,
    disablePadding: false,
    label: "Lock Type",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {LockersListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setDataToFilter,
    tableCalRedux,
    lockerList,
    handleDeleteOpen,
  } = props;
  const [actions, setActions] = React.useState("");
  const [isFilter, setIsFilter] = useState(false);
  const handleChange = (event) => {
    setActions(event.target.value);
  };
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.lockersList
  );
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportData, setExportData] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    LockerList(1, { limit: totalRecord })
      .then((res) => {
        if (res.statusCode === 200) {
          setExportData(res.data);
        } else {
          setExportData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };
  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [dateRange, setDateRange] = React.useState(null);

  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  const [department, setDepartment] = React.useState("");
  const selectDepartment = (event) => {
    setDepartment(event.target.value);
  };

  // Add Locker Assignments
  const [addLockerAssignmentDialog, setAddLockerAssignmentDialog] =
    useState(false);
  const addLockerAssignmentDialogOpen = () => {
    setAddLockerAssignmentDialog(true);
  };
  const addLockerAssignmentDialogClose = () => {
    setAddLockerAssignmentDialog(false);
  };

  // Toggle Button Group
  const [toggleGroup, setToggleGroup] = React.useState("lockers");
  const hangleToggleButton = (event, newAlignment) => {
    setToggleGroup(newAlignment);
  };

  // Select room
  const [selectRoom, setSelectRoom] = React.useState("");
  const handleSelectRoom = (event) => {
    setSelectRoom(event.target.value);
  };

  // Select status
  const [selectStatus, setSelectStatus] = React.useState("");
  const handleSelectStatus = (event) => {
    setSelectStatus(event.target.value);
  };
  // Add Locker dialog
  const [addLockerDialog, setAddLockerDialog] = useState(false);
  const addLockerDialogOpen = () => {
    setAddLockerDialog(true);
  };
  const addLockerDialogClose = () => {
    setAddLockerDialog(false);
  };

  // Add Edit Locker Room Dialog
  const [addEditLockerRoomDialog, setAddEditLockerRoomDialog] = useState(false);
  const addEditLockerRoomDialogOpen = () => {
    setAddEditLockerRoomDialog(true);
  };
  const addEditLockerRoomDialogClose = () => {
    setAddEditLockerRoomDialog(false);
  };

  /**
   * It takes the values of the form fields and sets them to the dataToFilter state
   */
  const applySearch = () => {
    const payload = {
      locker_status: selectStatus,
      locker_room: selectRoom,
    };
    setDataToFilter(payload);
  };

  /**
   * It resets the state of the filter form to its default values
   */
  const resetFilter = () => {
    setSelectRoom("");
    setSelectStatus("");
  };

  const status = [
    {
      key: "Assigned",
      value: "assigned",
    },
    {
      key: "Unassigned",
      value: "unassigned",
    },
    {
      key: "On-Hold",
      value: "on-hold",
    },
    {
      key: "Damaged",
      value: "damaged",
    },
    {
      key: "Unavailable",
      value: "Unavailable",
    },
  ];

  let headers = [
    { label: "Lock Number", key: "locker_number" },
    { label: "Room Name", key: "locker_room" },
    { label: "Status", key: "locker_status" },
    { label: "Assignment", key: "assignment" },
    { label: "Type", key: "internal_lock" },
  ];

  const [roomData, setRoomData] = useState([]);
  const roomList = () => {
    LockerRoomlist()
      .then((res) => {
        if (res.statusCode === 200) {
          setRoomData(res.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };

  useEffect(() => {
    roomList();
  }, []);

  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: toggleGroup === "lockers" ? "flex" : "none",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <Box sx={{ display: { xs: "flex" } }}>
                <Item
                  sx={{
                    maxWidth: { xs: "50%" },
                    flex: { xs: "0 0 50%" },
                    width: { xs: "100%" },
                    "& .MuiOutlinedInput-root": {
                      borderTopRightRadius: { xs: 0 },
                      borderBottomRightRadius: { xs: 0 },
                      borderRight: { xs: 0 },
                    },
                  }}
                >
                  <FormControl>
                    <Select
                      labelId="department"
                      id="select-room"
                      value={selectStatus}
                      onChange={handleSelectStatus}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">Select status</MenuItem>
                      {status.map((data) => {
                        return (
                          <MenuItem value={data.value} key={data.value}>
                            {data.key}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Item>
                <Item
                  sx={{
                    maxWidth: { xs: "50%" },
                    flex: { xs: "0 0 50%" },
                    width: { xs: "100%" },
                    "& .MuiOutlinedInput-root": {
                      borderTopLeftRadius: { xs: 0 },
                      borderBottomLeftRadius: { xs: 0 },
                    },
                  }}
                >
                  <FormControl>
                    <Select
                      labelId="department"
                      id="select-room"
                      value={selectRoom}
                      onChange={handleSelectRoom}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">Select room</MenuItem>
                      {roomData.map((data) => {
                        return (
                          <MenuItem value={data.value} key={data.key}>
                            {data.key}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Item>
              </Box>
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  variant="contained"
                  color="primary"
                  mt={6}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                  }}
                >
                  Clear filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    applySearch({});
                    setIsFilter(true);
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3} sx={{}}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={addLockerDialogOpen}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Add Locker" pl={0} />
              </MenuItem>
              <MenuItem onClick={addEditLockerRoomDialogOpen}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary="Add/Edit Locker Room"
                  pl={0}
                />
              </MenuItem>
              <CSVLink
                data={exportData}
                headers={headers}
                onClick={handleClose}
                filename="SOVA-vehicle-log"
                style={{ textDecoration: "none", color: "#374151" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Export CSV" pl={0} />
                </MenuItem>
              </CSVLink>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setLockersList(data));
                      }}
                      listing={LockersListCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setLockersList(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      <Box
        sx={{
          paddingLeft: "15px",
          paddingRight: "15px",
          marginBottom: "15px",
          marginTop: "15px",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={toggleGroup}
          exclusive
          onChange={hangleToggleButton}
        >
          <ToggleButton value="map" component={NavLink} to="/auth/404" disabled>
            Map
          </ToggleButton>
          <ToggleButton value="lockers">Lockers</ToggleButton>
          <ToggleButton
            value="assignments"
            component={NavLink}
            to="/logs/locker-log"
          >
            Assignments
          </ToggleButton>
          <ToggleButton value="locks" component={NavLink} to="/logs/locks">
            Locks
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {addLockerAssignmentDialog && (
        <AddLockerAssignment
          addLockerAssignmentDialog={addLockerAssignmentDialog}
          setAddLockerAssignmentDialog={setAddLockerAssignmentDialog}
          addLockerAssignmentDialogOpen={addLockerAssignmentDialogOpen}
          addLockerAssignmentDialogClose={addLockerAssignmentDialogClose}
        />
      )}
      {addLockerDialog && (
        <AddLocker
          addLockerDialog={addLockerDialog}
          setAddLockerDialog={setAddLockerDialog}
          addLockerDialogOpen={addLockerDialogOpen}
          addLockerDialogClose={addLockerDialogClose}
          lockerList={lockerList}
        />
      )}
      {addEditLockerRoomDialog && (
        <AddEditLockerRoom
          addEditLockerRoomDialog={addEditLockerRoomDialog}
          setAddEditLockerRoomDialog={setAddEditLockerRoomDialog}
          addEditLockerRoomDialogOpen={addEditLockerRoomDialogOpen}
          addEditLockerRoomDialogClose={addEditLockerRoomDialogClose}
          roomData={roomData}
          roomList={roomList}
        />
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [lockerData, setLockerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);
  const dispatch = useDispatch();
  const tableCalRedux = useSelector((state) => state.logTableCol.lockersList);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lockerData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, lockernumber) => {
    const selectedIndex = selected.indexOf(lockernumber);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, lockernumber);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (lockernumber) => selected.indexOf(lockernumber) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [singleData, setSingleData] = useState(null);
  const [logId, setLogId] = useState(null);
  const [singleRow, setSingleRow] = useState({});
  const [isAssign, setIsAssign] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleActionClick = (event, id, data, locker_log_id, row) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    setSingleData(data);
    setLogId(locker_log_id);
    setSingleRow(row);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setId("");
    setIsEdit(false);
  };

  // Add Locker Assignments
  const [addLockerAssignmentDialog, setAddLockerAssignmentDialog] =
    useState(false);
  const addLockerAssignmentDialogOpen = () => {
    setAddLockerAssignmentDialog(true);
  };
  const addLockerAssignmentDialogClose = () => {
    setAddLockerAssignmentDialog(false);
  };
  // Locker Log Modal
  const [lockerLogModal, setLockerLogModal] = useState(false);
  const lockerLogModalOpen = () => {
    setLockerLogModal(true);
  };
  const lockerLogModalClose = () => {
    setLockerLogModal(false);
  };
  // AddEditInternal Dialog
  // Locker Log Modal
  const [addEditInternalLogDialog, setAddEditInternalLogDialog] =
    useState(false);
  const addEditInternalLogDialogOpen = () => {
    setAddEditInternalLogDialog(true);
  };
  const addEditInternalLogDialogClose = () => {
    setAddEditInternalLogDialog(false);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId("");
  };
  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const lockerList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      // sorting: {
      //   order: order,
      //   by: orderBy,
      // },
      ...dataToFilter,
    };
    LockerList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLockerData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setLockerData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteLocker = () => {
    let url = selected.length > 0 ? selected : id;
    DeleteLocker(url)
      .then((res) => {
        if (res.statusCode === 200) {
          lockerList();
          handleDeleteClose();
          setSelected([]);
          dispatch(getMsg(`Record(s) deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeAssignment = () => {
    DeletelockerLog(logId)
      .then((res) => {
        if (res.statusCode === 200) {
          lockerList();
          handleDeleteClose();
          dispatch(getMsg(`record deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  useEffect(() => {
    lockerList();
  }, [rowsPerPage, currentPage, dataToFilter]);

  return (
    <div>
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {selected.length || id > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete the locker(s)?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={deleteLocker} autoFocus variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setDataToFilter={setDataToFilter}
          tableCalRedux={tableCalRedux}
          lockerList={lockerList}
          handleDeleteOpen={handleDeleteOpen}
        />

        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={lockerData.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(lockerData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${id}`;

                    return (
                      <TableRow hover key={row.id} tabIndex={-1}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            selected={isItemSelected}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.locker_number === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.locker_number}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.room_name === true ? "" : "hide-cell"
                          }
                        >
                          {row.locker_room}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.status === true ? "" : "hide-cell"
                          }
                        >
                          {row.locker_status}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.assignment === true ? "" : "hide-cell"
                          }
                        >
                          {row.assignment}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.lock_type === true ? "" : "hide-cell"
                          }
                          title={
                            row.internal_lock == 2
                              ? "External Lock"
                              : row.internal_lock == 1
                              ? "Integral Lock"
                              : ""
                          }
                        >
                          {row.internal_lock == 2 ? (
                            <HttpsIcon
                              sx={{ display: "block" }}
                              color="primary"
                            />
                          ) : row.internal_lock == 1 ? (
                            <ExitToAppIcon
                              sx={{ display: "block" }}
                              color="warning"
                            />
                          ) : (
                            <span sx={{ display: "block" }}>Not set</span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) =>
                              handleActionClick(
                                e,
                                row.id,
                                row.assignment,
                                row.locker_log_id,
                                row
                              )
                            }
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              sx={{
                                display: singleData != null ? "block" : "none",
                              }}
                              onClick={() => {
                                addLockerAssignmentDialogOpen();
                                setIsEdit(true);
                              }}
                            >
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary="View/Edit Assignment"
                                pl={1}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: singleData != null ? "block" : "none",
                              }}
                              onClick={removeAssignment}
                            >
                              <ListItemIcon>
                                <DoDisturbOnIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary="Remove Assignment"
                                pl={1}
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                addLockerAssignmentDialogOpen();
                                setIsAssign(true);
                              }}
                              sx={{
                                display: singleData != null ? "none" : "block",
                              }}
                            >
                              <ListItemIcon>
                                <PersonIcon />
                              </ListItemIcon>
                              <ListItemText primary="Assign User" pl={1} />
                            </MenuItem>

                            <MenuItem onClick={lockerLogModalOpen}>
                              <ListItemIcon>
                                <HttpsIcon />
                              </ListItemIcon>
                              <ListItemText primary="Locker Log" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={addEditInternalLogDialogOpen}>
                              <ListItemIcon>
                                <DeviceHubIcon />
                              </ListItemIcon>
                              <ListItemText primary="Add/Edit Combo" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={handleDeleteOpen}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {lockerData.length === 0 && (
                  <TableRow
                    style={{ height: (dense ? 6 : 0) * lockerData.length }}
                  >
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {addLockerAssignmentDialog && (
        <AddLockerAssignment
          addLockerAssignmentDialog={addLockerAssignmentDialog}
          setAddLockerAssignmentDialog={setAddLockerAssignmentDialog}
          addLockerAssignmentDialogOpen={addLockerAssignmentDialogOpen}
          addLockerAssignmentDialogClose={addLockerAssignmentDialogClose}
          singleRow={singleRow}
          isAssign={isAssign}
          isEdit={isEdit}
          lockersList={lockerList}
          id={logId}
        />
      )}
      {lockerLogModal && (
        <LockerLogDialog
          lockerLogModal={lockerLogModal}
          setLockerLogModal={setLockerLogModal}
          lockerLogModalOpen={lockerLogModalOpen}
          lockerLogModalClose={lockerLogModalClose}
          id={id}
        />
      )}
      {addEditInternalLogDialog && (
        <AddEditInternalLog
          addEditInternalLogDialog={addEditInternalLogDialog}
          setAddEditInternalLogDialog={setAddEditInternalLogDialog}
          addEditInternalLogDialogOpen={addEditInternalLogDialogOpen}
          addEditInternalLogDialogClose={addEditInternalLogDialogClose}
          id={id}
        />
      )}
    </div>
  );
}

function Lockers() {
  return (
    <React.Fragment>
      <Helmet title="Lockers" />
      <Typography variant="h3" gutterBottom display="inline">
        Lockers
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Lockers;

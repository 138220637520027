import React, { useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  FormControl as MuiFormControl,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { EditAuditUser } from "../../../../api";

const LoginEditDialog = ({
  loginEditDialog,
  setLoginEditDialog,
  loginEditDialogOpen,
  loginEditDialogClose,
  updatedRow,
  auditUserList,
}) => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const [formData, setFormData] = useState(updatedRow);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const editAuditUser = () => {
    let payload = {
      id: formData.id ? formData.id : null,
      user_email: formData.user_email,
      friendly_name: formData.friendly_name,
      country: formData.country,
      country_code: formData.country_code,
      state: formData.state,
      city: formData.city,
      lat: formData.lat,
      lon: formData.lon,
      org: formData.org,
    };
    EditAuditUser(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          loginEditDialogClose();
          auditUserList();
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={loginEditDialog}
        onClose={loginEditDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Login Adult
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setLoginEditDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} mt={7}>
              {/* <FormControl> */}
              <TextField
                fullWidth
                id="outlined"
                name="user_email"
                value={formData?.user_email ? formData.user_email : null}
                onChange={handleChange}
                label="Device Nickname"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* </FormControl> */}
            </Grid>
            <Grid item lg={6} md={6} mt={7}>
              {/* <FormControl> */}
              <TextField
                fullWidth
                id="outlined"
                label="Country name"
                name="country"
                value={formData?.country}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* </FormControl> */}
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              {/* <FormControl> */}
              <TextField
                fullWidth
                id="outlined"
                label="Friendly name"
                name="friendly_name"
                value={formData?.friendly_name}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* </FormControl> */}
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              {/* <FormControl> */}
              <TextField
                fullWidth
                id="outlined"
                label="Country code"
                name="country_code"
                value={formData?.country_code}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* </FormControl> */}
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  value={formData?.ip_address}
                  label="IP Address"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  value={formData?.state}
                  label="State"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  label="ISP"
                  value={formData?.ISP}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  label="City"
                  value={formData?.city}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  label="Host name"
                  value={formData?.host_name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="Latitude"
                  label="Latitude"
                  value={formData?.lat}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  label="Sessional Key"
                  value={formData?.session_key}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  label="Longitude"
                  value={formData?.lon}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  label="Org"
                  value={formData?.org}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button onClick={editAuditUser} variant="outlined" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoginEditDialog;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import CustomSelect from "../../components/form-components/CustomSelect";
import { DateTimePicker } from "@mui/lab";
import moment from "moment";
import * as Yup from "yup";
import {
  ViewShiftReport,
  EditShiftReports,
  AddShiftReports,
  ShiftReportDetails,
  FetchTours,
  FetchTasks,
  FetchAttendance,
  FetchVisitors,
  FetchIncidents,
  FetchPoiEncounters,
  FetchPassdown,
  FetchButtonScanned,
  GetModuleArray,
  ShiftReportData,
  GetElevator,
} from "../../api";
import { format } from "date-fns";

import {
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Autocomplete,
  Checkbox,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper as MuiPaper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  AccessTime as AccessTimeIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Attribution as AttributionIcon,
  ListAlt as ListAltIcon,
  TripOrigin as TripOriginIcon,
  Flip as FlipIcon,
  Groups as GroupsIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  GetApp as GetAppIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { TimePicker } from "@mui/lab";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import hasPermission from "../../utils/hasPermission";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Paper = styled(MuiPaper)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const allSystemNormal = [
  { title: "E-locks" },
  { title: "Video surveillance" },
  { title: "Keywatcher" },
];
const selectIssue = [
  { key: "Bypass", value: "bypass" },
  { key: "Supervisory", value: "supervisory" },
  { key: "Troubles active", value: "troublesactive" },
  { key: "Alarms active", value: "alarmsactive" },
  { key: "Tech on site", value: "techonsite" },
];
const trainingTopics = [
  { key: "Corporate standard", value: "corporatestandard" },
  { key: "Department standard", value: "departmentstandard" },
  { key: "Monthly training", value: "monthlytraining" },
  { key: "Refresher training", value: "refreshertraining" },
  { key: "QA from staff", value: "qafromstaff" },
  { key: "Other", value: "other" },
];
function EditShiftReportForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState(new Date());
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const [fadebox, setFadedBox] = useState(false);

  const validationSchema = Yup.object().shape({
    current_shift: Yup.string().required("Please Select a Shift"),
    supervisor: Yup.string().required("Please Select Supervisor"),
    pre_shift_by: Yup.string().required("Please Select Pre-Shift Conducted by"),
  });

  const [shiftReportData, setShiftReportData] = useState({});

  const viewShiftReport = () => {
    ViewShiftReport(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setShiftReportData(res.data);
          setOfficerOnDutySelected(
            res.data.duty_officers?.map((officer) => ({
              key: officer,
              value: officer,
            }))
          );
          setListGroup(res.data.group_name !== "" ? res.data.group_name : []);
          setGroupContactPerson(
            res.data.group_contact_person !== ""
              ? res.data.group_contact_person
              : []
          );
          setElevatorSelected(
            res.data.lift_list !== "" ? res.data.lift_list : []
          );
          setSecurityFailureSystemSelected(
            res.data.security_system_list.map((item) => ({
              title: item,
            }))
          );
        } else {
          setShiftReportData({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    current_shift,
    // start_time,
    // end_time,
    supervisor,
    pre_shift_by,
    training_conducted,
    training_topic,
    training_summary,
    guest_arrivals,
    guest_departures,
    occupancy,
    guest_list,
    list_group,
    contact_person,
    group_list,
    ccve_working,
    ccve_working_other,
    supporting_tools,
    supporting_tools_other,
    e_lock_keywatcher,
    e_lock_keywatcher_other,
    equip_tools,
    equip_tools_other,
    system_equip,
    system_equip_other,
    shift_custom_field_1,
    shift_custom_field_1_other,
    shift_custom_field_2,
    shift_custom_field_2_other,
    shift_custom_field_3,
    shift_custom_field_3_other,
    shift_custom_field_4,
    shift_custom_field_4_other,
    shift_custom_field_5,
    shift_custom_field_5_other,
    shift_custom_field_6,
    shift_custom_field_6_other,
    shift_custom_field_7,
    shift_custom_field_7_other,
    shift_custom_field_8,
    shift_custom_field_8_other,
    shift_custom_field_9,
    shift_custom_field_9_other,
    shift_custom_field_10,
    shift_custom_field_10_other,
    shift_custom_field_11,
    shift_custom_field_11_other,
    shift_custom_field_12,
    shift_custom_field_12_other,
    shift_custom_field_13,
    shift_custom_field_13_other,
    shift_custom_field_14,
    shift_custom_field_14_other,
    shift_custom_field_15,
    shift_custom_field_15_other,
    shift_custom_field_16,
    shift_custom_field_16_other,
    meetings,
    meetings_other,
    fire_panel_check,
    fire_panel_check_other,
    dar_entry,
    dar_entry_other,
    report_reviewed,
    report_reviewed_other,
    key_report_reviewed,
    key_report_reviewed_other,
    equipment_checked,
    equipment_checked_other,
    keyset_accounted,
    keyset_accounted_other,
    email_sent,
    email_sent_other,
    passdown_info,
    passdown_info_other,
    security_offices,
    security_offices_other,
    equip_custom_field_1,
    equip_custom_field_1_other,
    equip_custom_field_2,
    equip_custom_field_2_other,
    equip_custom_field_3,
    equip_custom_field_3_other,
    equip_custom_field_4,
    equip_custom_field_4_other,
    equip_custom_field_5,
    equip_custom_field_5_other,
    equip_custom_field_6,
    equip_custom_field_6_other,
    equip_custom_field_7,
    equip_custom_field_7_other,
    equip_custom_field_8,
    equip_custom_field_8_other,
    equip_custom_field_9,
    equip_custom_field_9_other,
    equip_custom_field_10,
    equip_custom_field_10_other,
    equip_custom_field_11,
    equip_custom_field_11_other,
    equip_custom_field_12,
    equip_custom_field_12_other,
    equip_custom_field_13,
    equip_custom_field_13_other,
    equip_custom_field_14,
    equip_custom_field_14_other,
    equip_custom_field_15,
    equip_custom_field_15_other,
    equip_custom_field_16,
    equip_custom_field_16_other,
    all_lift_functional,
    other_all_lift_functional,
    all_systems_normal,
    fire_system_list,
    other_fire_system_list,
    security_systems_normal,
    other_security_systems_normal,
    sys_normal_training_summary,
    notable_events_summary,
  } = shiftReportData;

  let initialValues = {
    current_shift: current_shift ? current_shift : "",
    // start_time: start_time ? new Date(start_time) : "",/
    // end_time: end_time ? end_time : "",
    supervisor: supervisor ? supervisor : "",
    pre_shift_by: pre_shift_by ? pre_shift_by : "",
    training: training_conducted ? training_conducted : "",
    training_topics: training_topic ? training_topic : "",
    training_summary: training_summary ? training_summary : "",
    arrivals: guest_arrivals ? guest_arrivals : "",
    departures: guest_departures ? guest_departures : "",
    occupancy: occupancy ? occupancy : "",
    guest_issues: guest_list ? guest_list : "",
    list_group: list_group ? list_group : "",
    contact_person: contact_person ? contact_person : "",
    group_issues: group_list ? group_list : "",
    ccve_working: ccve_working ? ccve_working : "",
    ccve_working_other: ccve_working_other ? ccve_working_other : "",
    supporting_tools: supporting_tools ? supporting_tools : "",
    supporting_tools_other: supporting_tools_other
      ? supporting_tools_other
      : "",
    e_lock_keywatcher: e_lock_keywatcher ? e_lock_keywatcher : "",
    e_lock_keywatcher_other: e_lock_keywatcher_other
      ? e_lock_keywatcher_other
      : "",
    equip_tools: equip_tools ? equip_tools : "",
    equip_tools_other: equip_tools_other ? equip_tools_other : "",
    system_equip: system_equip ? system_equip : "",
    system_equip_other: system_equip_other ? system_equip_other : "",
    shift_custom_field_1: shift_custom_field_1 ? shift_custom_field_1 : "",
    shift_custom_field_1_other: shift_custom_field_1_other
      ? shift_custom_field_1_other
      : "",
    shift_custom_field_2: shift_custom_field_2 ? shift_custom_field_2 : "",
    shift_custom_field_2_other: shift_custom_field_2_other
      ? shift_custom_field_2_other
      : "",
    shift_custom_field_3: shift_custom_field_3 ? shift_custom_field_3 : "",
    shift_custom_field_3_other: shift_custom_field_3_other
      ? shift_custom_field_3_other
      : "",
    shift_custom_field_4: shift_custom_field_4 ? shift_custom_field_4 : "",
    shift_custom_field_4_other: shift_custom_field_4_other
      ? shift_custom_field_4_other
      : "",
    shift_custom_field_5: shift_custom_field_5 ? shift_custom_field_5 : "",
    shift_custom_field_5_other: shift_custom_field_5_other
      ? shift_custom_field_5_other
      : "",
    shift_custom_field_6: shift_custom_field_6 ? shift_custom_field_6 : "",
    shift_custom_field_6_other: shift_custom_field_6_other
      ? shift_custom_field_6_other
      : "",
    shift_custom_field_7: shift_custom_field_7 ? shift_custom_field_7 : "",
    shift_custom_field_7_other: shift_custom_field_7_other
      ? shift_custom_field_7_other
      : "",
    shift_custom_field_8: shift_custom_field_8 ? shift_custom_field_8 : "",
    shift_custom_field_8_other: shift_custom_field_8_other
      ? shift_custom_field_8_other
      : "",
    shift_custom_field_9: shift_custom_field_9 ? shift_custom_field_9 : "",
    shift_custom_field_9_other: shift_custom_field_9_other
      ? shift_custom_field_9_other
      : "",
    shift_custom_field_10: shift_custom_field_10 ? shift_custom_field_10 : "",
    shift_custom_field_10_other: shift_custom_field_10_other
      ? shift_custom_field_10_other
      : "",
    shift_custom_field_11: shift_custom_field_11 ? shift_custom_field_11 : "",
    shift_custom_field_11_other: shift_custom_field_11_other
      ? shift_custom_field_11_other
      : "",
    shift_custom_field_12: shift_custom_field_12 ? shift_custom_field_12 : "",
    shift_custom_field_12_other: shift_custom_field_12_other
      ? shift_custom_field_12_other
      : "",
    shift_custom_field_13: shift_custom_field_13 ? shift_custom_field_13 : "",
    shift_custom_field_13_other: shift_custom_field_13_other
      ? shift_custom_field_13_other
      : "",
    shift_custom_field_14: shift_custom_field_14 ? shift_custom_field_14 : "",
    shift_custom_field_14_other: shift_custom_field_14_other
      ? shift_custom_field_14_other
      : "",
    shift_custom_field_15: shift_custom_field_15 ? shift_custom_field_15 : "",
    shift_custom_field_15_other: shift_custom_field_15_other
      ? shift_custom_field_15_other
      : "",
    shift_custom_field_16: shift_custom_field_16 ? shift_custom_field_16 : "",
    shift_custom_field_16: shift_custom_field_16 ? shift_custom_field_16 : "",
    shift_custom_field_16_other: shift_custom_field_16_other
      ? shift_custom_field_16_other
      : "",
    meetings: meetings ? meetings : "",
    meetings_other: meetings_other ? meetings_other : "",
    fire_panel_check: fire_panel_check ? fire_panel_check : "",
    fire_panel_check_other: fire_panel_check_other
      ? fire_panel_check_other
      : "",
    dar_entry: dar_entry ? dar_entry : "",
    dar_entry_other: dar_entry_other ? dar_entry_other : "",
    report_reviewed: report_reviewed ? report_reviewed : "",
    report_reviewed_other: report_reviewed_other ? report_reviewed_other : "",
    key_report_reviewed: key_report_reviewed ? key_report_reviewed : "",
    key_report_reviewed_other: key_report_reviewed_other
      ? key_report_reviewed_other
      : "",
    equipment_checked: equipment_checked ? equipment_checked : "",
    equipment_checked_other: equipment_checked_other
      ? equipment_checked_other
      : "",
    keyset_accounted: keyset_accounted ? keyset_accounted : "",
    keyset_accounted_other: keyset_accounted_other
      ? keyset_accounted_other
      : "",
    email_sent: email_sent ? email_sent : "",
    email_sent_other: email_sent_other ? email_sent_other : "",
    passdown_info: passdown_info ? passdown_info : "",
    passdown_info_other: passdown_info_other ? passdown_info_other : "",
    security_offices: security_offices ? security_offices : "",
    security_offices_other: security_offices_other
      ? security_offices_other
      : "",
    equip_custom_field_1: equip_custom_field_1 ? equip_custom_field_1 : "",
    equip_custom_field_1_other: equip_custom_field_1_other
      ? equip_custom_field_1_other
      : "",
    equip_custom_field_2: equip_custom_field_2 ? equip_custom_field_2 : "",
    equip_custom_field_2_other: equip_custom_field_2_other
      ? equip_custom_field_2_other
      : "",
    equip_custom_field_3: equip_custom_field_3 ? equip_custom_field_3 : "",
    equip_custom_field_3_other: equip_custom_field_3_other
      ? equip_custom_field_3_other
      : "",
    equip_custom_field_4: equip_custom_field_4 ? equip_custom_field_4 : "",
    equip_custom_field_4_other: equip_custom_field_4_other
      ? equip_custom_field_4_other
      : "",
    equip_custom_field_5: equip_custom_field_5 ? equip_custom_field_5 : "",
    equip_custom_field_5_other: equip_custom_field_5_other
      ? equip_custom_field_5_other
      : "",
    equip_custom_field_6: equip_custom_field_6 ? equip_custom_field_6 : "",
    equip_custom_field_6_other: equip_custom_field_6_other
      ? equip_custom_field_6_other
      : "",
    equip_custom_field_7: equip_custom_field_7 ? equip_custom_field_7 : "",
    equip_custom_field_7_other: equip_custom_field_7_other
      ? equip_custom_field_7_other
      : "",
    equip_custom_field_8: equip_custom_field_8 ? equip_custom_field_8 : "",
    equip_custom_field_8_other: equip_custom_field_8_other
      ? equip_custom_field_8_other
      : "",
    equip_custom_field_9: equip_custom_field_9 ? equip_custom_field_9 : "",
    equip_custom_field_9_other: equip_custom_field_9_other
      ? equip_custom_field_9_other
      : "",
    equip_custom_field_10: equip_custom_field_10 ? equip_custom_field_10 : "",
    equip_custom_field_10_other: equip_custom_field_10_other
      ? equip_custom_field_10_other
      : "",
    equip_custom_field_11: equip_custom_field_11 ? equip_custom_field_11 : "",
    equip_custom_field_11_other: equip_custom_field_11_other
      ? equip_custom_field_11_other
      : "",
    equip_custom_field_12: equip_custom_field_12 ? equip_custom_field_12 : "",
    equip_custom_field_12_other: equip_custom_field_12_other
      ? equip_custom_field_12_other
      : "",
    equip_custom_field_13: equip_custom_field_13 ? equip_custom_field_13 : "",
    equip_custom_field_13_other: equip_custom_field_13_other
      ? equip_custom_field_13_other
      : "",
    equip_custom_field_14: equip_custom_field_14 ? equip_custom_field_14 : "",
    equip_custom_field_14_other: equip_custom_field_14_other
      ? equip_custom_field_14_other
      : "",
    equip_custom_field_15: equip_custom_field_15 ? equip_custom_field_15 : "",
    equip_custom_field_15_other: equip_custom_field_15_other
      ? equip_custom_field_15_other
      : "",
    equip_custom_field_16: equip_custom_field_16 ? equip_custom_field_16 : "",
    equip_custom_field_16_other: equip_custom_field_16_other
      ? equip_custom_field_16_other
      : "",
    all_lifts_functional: all_lift_functional ? all_lift_functional : "",
    elevator_comment: other_all_lift_functional
      ? other_all_lift_functional
      : "",
    all_systems_normal: all_systems_normal ? all_systems_normal : "",
    select_issue: fire_system_list ? fire_system_list : "",
    issue_comment: other_fire_system_list ? other_fire_system_list : "",
    security_failures_system_normal: security_systems_normal
      ? security_systems_normal
      : "",
    security_failure_comment: other_security_systems_normal
      ? other_security_systems_normal
      : "",
    sys_normal_training_summary: sys_normal_training_summary
      ? sys_normal_training_summary
      : "",
    notable_events_summary: notable_events_summary
      ? notable_events_summary
      : "",
  };

  const [shiftDuties, setShiftDuties] = useState([
    {
      column_name: "meetings",
      question:
        "Someone met with MODs, EODs and Valet operations at least once during shift (if no Supervisor on duty)",
      type: "shift",
    },
    {
      column_name: "fire_panel_check",
      question: "Fire panels were checked at least once per person",
      type: "shift",
    },
    {
      column_name: "dar_entry",
      question: "DAR entries from the prior shift was reviewed with staff",
      type: "shift",
    },
    {
      column_name: "report_reviewed",
      question:
        "Incident Reports written during the prior shift were reviewed with staff",
      type: "shift",
    },
    {
      column_name: "key_report_reviewed",
      question:
        "Key report from the prior shift was reviewed and overdue keys noted in the log",
      type: "shift",
    },
    {
      column_name: "equipment_checked",
      question: "Equipment checked out during the prior shift is accounted for",
      type: "shift",
    },
    {
      column_name: "keyset_accounted",
      question: "All keysets in security cabinets/lockbox are accounted for",
      type: "shift",
    },
    {
      column_name: "email_sent",
      question:
        "All required email was sent (i.e. call-off, overdue keys, DAR, reports)",
      type: "shift",
    },
    {
      column_name: "passdown_info",
      question: "Passdown information was communicated to oncoming shift",
      type: "shift",
    },
    {
      column_name: "security_offices",
      question:
        "Security offices were left in good order, no trash, or personal items left behind",
      type: "shift",
    },
  ]);
  const [equipmentTools, setEquipmentTools] = useState([
    {
      column_name: "ccve_working",
      question: "All CCVE was in working order (i.e. cameras, monitors, DVR's)",
      type: "equip",
    },
    {
      column_name: "supporting_tools",
      question:
        "All supporting tools are accounted for (i.e. safe tool, Lock interrogator, digital camera, special keys, etc.)",
      type: "equip",
    },
    {
      column_name: "e_lock_keywatcher",
      question:
        "All E-Locks and KeyWatcher functionality is normal, or any problems have been reported",
      type: "equip",
    },
    {
      column_name: "equip_tools",
      question:
        "Security equipment and tools were present and in order (i.e. radios, earpieces, keys, etc)",
      type: "equip",
    },
    {
      column_name: "system_equip",
      question:
        "Business systems and general equipment was in order (i.e. network, phone, doors, HVAC, water, electrical, no areas out of service)",
      type: "equip",
    },
  ]);

  // Elevateor List
  const [elevatorList, setElevatorList] = useState([]);
  const [elevatorSelected, setElevatorSelected] = useState([]);

  const getElevator = () => {
    GetElevator().then((res) => {
      if (res.statusCode === 200) {
        setElevatorList(res.data.split(","));
      }
    });
  };

  // Security Failures
  const [securityFailureSelected, setSecurityFailureSystemSelected] = useState(
    []
  );

  //Add More List Group
  const [listGroup, setListGroup] = useState([]);
  const addMoreListGroup = (value) => {
    setListGroup([value, ...listGroup]);
  };
  //Remove List Group Fields
  const removeListGroup = (index) => {
    const list = [...listGroup];
    list.splice(index, 1);
    setListGroup([...list]);
  };

  //Add More Group Contact Person
  const [groupContactPerson, setGroupContactPerson] = useState([]);
  const addMoreGroupContactPerson = (value) => {
    setGroupContactPerson([value, ...groupContactPerson]);
  };
  //Remove Group Contact Person
  const removeGroupContactPerson = (index) => {
    const list = [...groupContactPerson];
    list.splice(index, 1);
    setGroupContactPerson(list);
  };

  // Shift Report Details

  const [shiftReportDetailLoc, setShiftReportDetailLoc] = useState([]);
  const [officerOnDutyOptions, setOfficerOnDutyOptions] = useState([]);
  const [officerOnDutySelected, setOfficerOnDutySelected] = useState([]);

  const getShiftReportDetails = () => {
    setLoading(true);
    ShiftReportDetails()
      .then((res) => {
        if (res.statusCode == 200) {
          setShiftReportDetailLoc(res.data.location);
          setOfficerOnDutyOptions(res.data.supervisor);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const shiftOptions = [
    {
      key: `${shiftReportDetailLoc.Shift1Start} - ${shiftReportDetailLoc.Shift1End}`,
      value: `${shiftReportDetailLoc.Shift1Start} - ${shiftReportDetailLoc.Shift1End}`,
    },
    {
      key: `${shiftReportDetailLoc.Shift2Start} - ${shiftReportDetailLoc.Shift2End}`,
      value: `${shiftReportDetailLoc.Shift2Start} - ${shiftReportDetailLoc.Shift2End}`,
    },
    {
      key: `${shiftReportDetailLoc.Shift3Start} - ${shiftReportDetailLoc.Shift3End}`,
      value: `${shiftReportDetailLoc.Shift3Start} - ${shiftReportDetailLoc.Shift3End}`,
    },
    {
      key: "All-Shift",
      value: "all_shift",
    },
  ];

  // Shift Report Data

  const getShiftReportData = () => {
    ShiftReportData()
      .then((res) => {
        if (res.statusCode === 200) {
          const shiftFields = res.data.filter(
            (record) => record.type === "shift"
          );
          const equipmentFields = res.data.filter(
            (record) => record.type === "equip"
          );
          if (shiftFields.length !== 0) {
            setShiftDuties(shiftFields);
          }
          if (equipmentFields.length !== 0) {
            setEquipmentTools(equipmentFields);
          }
        } else {
          console.log("Something went Wrong: ", res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Attendance Records
  const [attendanceRecords, setAttendanceRecords] = useState(false);

  const getAttendance = (values) => {
    setLoading(true);
    let payload = {
      shift_date: [startDateToFilter, endDateToFilter],
      shift: values.current_shift,
      // sTime: format(values.start_time, "HHmm"),
      // eTime: format(values.end_time, "HHmm"),
    };
    FetchAttendance(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setAttendanceRecords(res.data);
        } else {
          setLoading(false);
          setAttendanceRecords([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Incident Reports
  const [incidentRecords, setIncidentRecords] = useState(false);

  const getIncident = (values) => {
    setLoading(true);
    let payload = {
      shift_date: [startDateToFilter, endDateToFilter],
      shift: values.current_shift,
      // sTime: format(values.start_time, "HHmm"),
      // eTime: format(values.end_time, "HHmm"),
    };
    FetchIncidents(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setIncidentRecords(res.data);
        } else {
          setLoading(false);
          setIncidentRecords([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //  Poi Encounter
  const [poiEncounters, setPoiEncounters] = useState(false);

  const getPoiEncounter = (values) => {
    setLoading(true);
    let payload = {
      shift_date: [startDateToFilter, endDateToFilter],
      shift: values.current_shift,
      // sTime: format(values.start_time, "HHmm"),
      // eTime: format(values.end_time, "HHmm"),
    };
    FetchPoiEncounters(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setPoiEncounters(res.data);
        } else {
          setLoading(false);
          setPoiEncounters([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Scanned Buttons
  const [buttonScanned, setButtonScanned] = useState(false);

  const getButtonScanned = (values) => {
    setLoading(true);
    let payload = {
      shift_date: [startDateToFilter, endDateToFilter],
      shift: values.current_shift,
      // sTime: format(values.start_time, "HHmm"),
      // eTime: format(values.end_time, "HHmm"),
    };
    FetchButtonScanned(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setButtonScanned(res.data);
        } else {
          setLoading(false);
          setButtonScanned([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Visitors
  const [visitorsRecorded, setVisitorsRecorded] = useState(false);

  const getVisitor = (values) => {
    setLoading(true);
    let payload = {
      shift_date: [startDateToFilter, endDateToFilter],
      shift: values.current_shift,
      // sTime: format(values.start_time, "HHmm"),
      // eTime: format(values.end_time, "HHmm"),
    };
    FetchVisitors(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
          setLoading(false);
          setVisitorsRecorded(res.data);
        } else {
          setLoading(false);
          setVisitorsRecorded([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Tours
  const [toursRecorded, setToursRecorded] = useState(false);

  const getTours = (values) => {
    setLoading(true);
    let payload = {
      shift_date: [startDateToFilter, endDateToFilter],
      shift: values.current_shift,
      // sTime: format(values.start_time, "HHmm"),
      // eTime: format(values.end_time, "HHmm"),
    };
    FetchTours(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setToursRecorded(res.data);
        } else {
          setLoading(false);
          setToursRecorded([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Tasks
  const [taskRecorded, setTaskRecorded] = useState(false);

  const getTasks = (values) => {
    setLoading(true);
    let payload = {
      shift_date: [startDateToFilter, endDateToFilter],
      shift: values.current_shift,
      // sTime: format(values.start_time, "HHmm"),
      // eTime: format(values.end_time, "HHmm"),
    };
    FetchTasks(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setTaskRecorded(res.data);
        } else {
          setLoading(false);
          setTaskRecorded([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Passdown
  const [passdown, setPassdown] = useState(false);

  const getPassdown = (values) => {
    setLoading(true);
    let payload = {
      shift_date: [startDateToFilter, endDateToFilter],
      shift: values.current_shift,
      // sTime: format(values.start_time, "HHmm"),
      // eTime: format(values.end_time, "HHmm"),
    };
    FetchPassdown(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setPassdown(res.data);
        } else {
          setLoading(false);
          setPassdown([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const transformButtonScannedData = (data = []) =>
    Array.isArray(data)
      ? data
          .map((record) => {
            const tourName = record.TourName || "";
            const tagDescription = record.TagDescription || "";
            const tagCount = record.TagCount || "";
            const officers = record.officers || "";
            return `${tourName}|${tagDescription}|${tagCount}|${officers}`;
          })
          .join(";")
      : "";

  const transformTask = (data = []) =>
    Array.isArray(data)
      ? data.map((record) => `${record.TaskName}`).join("|")
      : "";

  const transformTaskCount = (data = []) =>
    Array.isArray(data)
      ? data.map((record) => `${record.count}`).join("|")
      : "";

  const transformTours = (data = []) =>
    Array.isArray(data)
      ? data.map((record) => `${record.TourName}`).join("|")
      : "";

  const transformTourCount = (data = []) =>
    Array.isArray(data)
      ? data.map((record) => `${record.count}`).join("|")
      : "";

  const transformPoiEncounter = (data = []) =>
    Array.isArray(data)
      ? data.map((record) => `${record.poi.id}`).join(",")
      : "";

  const transformIncident = (data = []) =>
    Array.isArray(data)
      ? data.map((record) => `${record.IncidentID}`).join(",")
      : "";

  const transformPassdown = (data = []) =>
    Array.isArray(data)
      ? data.map((record) => `${record.nontourdata.id}`).join("|")
      : "";

  const transformVisitor = (data = []) =>
    Array.isArray(data)
      ? data.map((record) => `${record.visitor_id}`).join(",")
      : "";

  const button_scanned = transformButtonScannedData(buttonScanned);
  const tours = transformTours(toursRecorded);
  const tour_count = transformTourCount(toursRecorded);
  const task = transformTask(taskRecorded);
  const task_count = transformTaskCount(taskRecorded);
  const visitor_id = transformVisitor(visitorsRecorded);
  const incident_id = transformIncident(incidentRecords);
  const poi_id = transformPoiEncounter(poiEncounters);
  const nttRec_id = transformPassdown(passdown);

  // handle submit
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);

      let payload = {
        id: id,
        date: format(startDateToFilter, "yyyy-MM-dd"),
        end_date: format(endDateToFilter, "yyyy-MM-dd"),
        current_shift: values.current_shift,
        // start_time: format(values.start_time, "HHmmss"),
        // end_time: format(values.end_time, "HHmmss"),
        supervisor: values.supervisor,
        pre_shift_by: values.pre_shift_by,
        duty_officers:
          officerOnDutySelected.length > 0
            ? officerOnDutySelected.map((item) => item.value)
            : "",
        training_conducted: values.training,
        training_topic: values.training_topics,
        training_summary: values.training_summary,
        guest_arrivals: values.arrivals,
        guest_departures: values.departures,
        occupancy: values.occupancy,
        group_name: listGroup.length > 0 ? listGroup : "",
        guest_list: values.guest_issues ? values.guest_issues : "",
        group_contact_person:
          groupContactPerson.length > 0 ? groupContactPerson : "",
        group_list: values.group_issues,
        ccve_working: values.ccve_working,
        ccve_working_other: values.ccve_working_other,
        supporting_tools: values.supporting_tools,
        supporting_tools_other: values.supporting_tools_other,
        e_lock_keywatcher: values.e_lock_keywatcher,
        e_lock_keywatcher_other: values.e_lock_keywatcher_other,
        equip_tools: values.equip_tools,
        equip_tools_other: values.equip_tools_other,
        system_equip: values.system_equip,
        system_equip_other: values.system_equip_other,
        shift_custom_field_1: values.shift_custom_field_1,
        shift_custom_field_1_other: values.shift_custom_field_1_other,
        shift_custom_field_2: values.shift_custom_field_2,
        shift_custom_field_2_other: values.shift_custom_field_2_other,
        shift_custom_field_3: values.shift_custom_field_3,
        shift_custom_field_3_other: values.shift_custom_field_3_other,
        shift_custom_field_4: values.shift_custom_field_4,
        shift_custom_field_4_other: values.shift_custom_field_4_other,
        shift_custom_field_5: values.shift_custom_field_5,
        shift_custom_field_5_other: values.shift_custom_field_5_other,
        shift_custom_field_6: values.shift_custom_field_6,
        shift_custom_field_6_other: values.shift_custom_field_6_other,
        shift_custom_field_7: values.shift_custom_field_7,
        shift_custom_field_7_other: values.shift_custom_field_7_other,
        shift_custom_field_8: values.shift_custom_field_8,
        shift_custom_field_8_other: values.shift_custom_field_8_other,
        shift_custom_field_9: values.shift_custom_field_9,
        shift_custom_field_9_other: values.shift_custom_field_9_other,
        shift_custom_field_10: values.shift_custom_field_10,
        shift_custom_field_10_other: values.shift_custom_field_10_other,
        shift_custom_field_11: values.shift_custom_field_11,
        shift_custom_field_11_other: values.shift_custom_field_11_other,
        shift_custom_field_12: values.shift_custom_field_12,
        shift_custom_field_12_other: values.shift_custom_field_12_other,
        shift_custom_field_13: values.shift_custom_field_13,
        shift_custom_field_13_other: values.shift_custom_field_13_other,
        shift_custom_field_14: values.shift_custom_field_14,
        shift_custom_field_14_other: values.shift_custom_field_14_other,
        shift_custom_field_15: values.shift_custom_field_15,
        shift_custom_field_15_other: values.shift_custom_field_15_other,
        shift_custom_field_16: values.shift_custom_field_16,
        shift_custom_field_16_other: values.shift_custom_field_16_other,
        meetings: values.meetings,
        meetings_other: values.meetings_other,
        fire_panel_check: values.fire_panel_check,
        fire_panel_check_other: values.fire_panel_check_other,
        dar_entry: values.dar_entry,
        dar_entry_other: values.dar_entry_other,
        report_reviewed: values.report_reviewed,
        key_report_reviewed: values.key_report_reviewed,
        // working_tools: values.working_tools,
        // working_tool_comment: values.working_tool_comment,
        equipment_checked: values.equipment_checked,
        equipment_checked_other: values.equipment_checked_other,
        keyset_accounted: values.keyset_accounted,
        keyset_accounted_other: values.keyset_accounted_other,
        email_sent: values.email_sent,
        email_sent_other: values.email_sent_other,
        passdown_info: values.passdown_info,
        passdown_info_other: values.passdown_info_other,
        security_offices: values.security_offices,
        security_offices_other: values.security_offices_other,
        equip_custom_field_1: values.equip_custom_field_1,
        equip_custom_field_1_other: values.equip_custom_field_1_other,
        equip_custom_field_2: values.equip_custom_field_2,
        equip_custom_field_2_other: values.equip_custom_field_2_other,
        equip_custom_field_3: values.equip_custom_field_3,
        equip_custom_field_3_other: values.equip_custom_field_3_other,
        equip_custom_field_4: values.equip_custom_field_4,
        equip_custom_field_4_other: values.equip_custom_field_4_other,
        equip_custom_field_5: values.equip_custom_field_5,
        equip_custom_field_5_other: values.equip_custom_field_5_other,
        equip_custom_field_6: values.equip_custom_field_6,
        equip_custom_field_6_other: values.equip_custom_field_6_other,
        equip_custom_field_7: values.equip_custom_field_7,
        equip_custom_field_7_other: values.equip_custom_field_7_other,
        equip_custom_field_8: values.equip_custom_field_8,
        equip_custom_field_8_other: values.equip_custom_field_8_other,
        equip_custom_field_9: values.equip_custom_field_9,
        equip_custom_field_9_other: values.equip_custom_field_9_other,
        equip_custom_field_10: values.equip_custom_field_10,
        equip_custom_field_10_other: values.equip_custom_field_10_other,
        equip_custom_field_11: values.equip_custom_field_11,
        equip_custom_field_11_other: values.equip_custom_field_11_other,
        equip_custom_field_12: values.equip_custom_field_12,
        equip_custom_field_12_other: values.equip_custom_field_12_other,
        equip_custom_field_13: values.equip_custom_field_13,
        equip_custom_field_13_other: values.equip_custom_field_13_other,
        equip_custom_field_14: values.equip_custom_field_14,
        equip_custom_field_14_other: values.equip_custom_field_14_other,
        equip_custom_field_15: values.equip_custom_field_15,
        equip_custom_field_15_other: values.equip_custom_field_15_other,
        equip_custom_field_16: values.equip_custom_field_16,
        equip_custom_field_16_other: values.equip_custom_field_16_other,
        all_lift_functional: values.all_lifts_functional,
        lift_list: elevatorSelected.length > 0 ? elevatorSelected : "",
        other_all_lift_functional: values.elevator_comment,
        all_systems_normal: values.all_systems_normal,
        fire_system_list: values.select_issue,
        other_fire_system_list: values.issue_comment,
        security_systems_normal: values.security_failures_system_normal,
        security_system_list:
          securityFailureSelected.length > 0
            ? securityFailureSelected.map((item) => item.title)
            : "",
        other_security_systems_normal: values.security_failure_comment,
        sys_normal_training_summary: values.sys_normal_training_summary,
        notable_events_summary: values.notable_events_summary,
        // table data
        tours: tours,
        tour_count: tour_count,
        tasks: task,
        task_count: task_count,
        visitor_id: visitor_id,
        button_scanned: button_scanned,
        incident_id: incident_id,
        poi_id: poi_id,
        nttRec_id: nttRec_id,
        // group_name: values.group_name,
        // guest_list: values.guest_list ? values.guest_list : "",
      };

      EditShiftReports(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record submitted successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
            navigate({
              pathname: "/logs/shift-report",
            });
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          dispatch(getMsg(err));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    viewShiftReport();
    getShiftReportDetails();
    getShiftReportData();
    getElevator();
  }, []);

  return (
    <>
      <Card mt={6}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DateTimePicker
                        id="date-range"
                        label="date-range"
                        value={values.injury_datetime}
                        onClick={() => setFadedBox((prev) => !prev)}
                        inputFormat="MM/dd/yyyy HH:mm"
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="injury_datetime"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />

                      {/* <TextField
                        fullWidth
                        id="dateRange"
                        label="Date range *"
                        value={
                          startDateToFilter && endDateToFilter
                            ? moment(startDateToFilter).format("MM/DD/YYYY") +
                              " - " +
                              moment(endDateToFilter).format("MM/DD/YYYY")
                            : ""
                        }
                        onClick={() => setFadedBox((prev) => !prev)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Select date range"
                      /> */}
                      {fadebox && (
                        <CustomDateRange
                          handleShow={fadebox}
                          conformation={(status) =>
                            setFadedBox((prev) => !prev)
                          }
                          setDates={(start, end) => {
                            setStartDateToFilter(start);
                            setEndDateToFilter(end);
                          }}
                        />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomSelect
                        name="current_shift"
                        fieldLabel="Current Shift *"
                        options={shiftOptions}
                        displayEmpty
                        disabled
                      />
                      <FormHelperText error>
                        {touched.current_shift && errors.current_shift}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <FormControl>
                      <TimePicker
                        id="start-time"
                        label="Start time"
                        name="start_time"
                        value={values.start_time}
                        onChange={(newValue) => {
                          setFieldValue("start_time", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <TimePicker
                        id="end-time"
                        label="End time"
                        name="end_time"
                        value={values.end_time}
                        onChange={(newValue) => {
                          setFieldValue("end_time", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomSelect
                        name="supervisor"
                        fieldLabel="Supervisor *"
                        options={officerOnDutyOptions}
                        displayEmpty
                        disabled
                      />
                      <FormHelperText error>
                        {touched.supervisor && errors.supervisor}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomSelect
                        name="pre_shift_by"
                        fieldLabel="Pre-shift Conducted by *"
                        options={officerOnDutyOptions}
                        displayEmpty
                        disabled
                      />
                      <FormHelperText error>
                        {touched.pre_shift_by && errors.pre_shift_by}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="officers-on-duty"
                        options={officerOnDutyOptions}
                        value={officerOnDutySelected}
                        onChange={(event, value) => {
                          setOfficerOnDutySelected(value);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.value}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Officers on duty"
                            placeholder={
                              officerOnDutySelected?.length < 0
                                ? "Select"
                                : null
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>
                        <Typography variant="h4" component="div">
                          Training
                        </Typography>
                      </FormLabel>
                      <FormHelperText sx={{ ml: 0 }}>
                        Was training conducted during this shift?
                      </FormHelperText>
                      <RadioGroup
                        aria-labelledby="training"
                        name="training"
                        row
                        value={values.training}
                        onChange={(event) => {
                          setFieldValue("training", event.target.value);
                        }}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                          disabled
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {values.training == "Yes" && (
                    <>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <CustomSelect
                            name="training_topics"
                            fieldLabel="Training topics"
                            options={trainingTopics}
                            displayEmpty
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <FormLabel>
                            Summary of training classes conducted during this
                            shift
                          </FormLabel>
                          <QuillWrapper>
                            <ReactQuill
                              theme="snow"
                              name="training_summary"
                              value={values.training_summary}
                              onChange={(newValue) => {
                                setFieldValue("training_summary", newValue);
                              }}
                              placeholder="Type something.."
                            />
                          </QuillWrapper>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Attendance Log{" "}
                        <Tooltip
                          placement="top"
                          title="Retrieve Attendance Records"
                        >
                          <IconButton
                            disabled={!hasPermission("Logs", "fetchAttendance")}
                            color="primary"
                            onClick={() => {
                              if (values.current_shift) {
                                getAttendance(values);
                              } else {
                                window.alert("Please select Shift");
                              }
                            }}
                          >
                            <AccessTimeIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "20px", borderBottom: 0 }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>User Name</TableCell>
                                <TableCell>Department Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Datetime</TableCell>
                                <TableCell>Time Called</TableCell>
                                <TableCell>Shift Time</TableCell>
                                <TableCell>Arrival Time</TableCell>
                                <TableCell>Phone Number</TableCell>
                              </TableRow>
                            </TableHead>
                            {attendanceRecords ? (
                              <TableBody>
                                {attendanceRecords.length > 0 ? (
                                  attendanceRecords.map((record, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.UserName}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.department_name}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.type}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {new Date(
                                          record.created
                                        ).toLocaleString()}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.time_called}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.call_shift_time}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.LogTypeCallOff.type ===
                                        "Call Off"
                                          ? ""
                                          : record.arrival_time}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.number_called}{" "}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={8} align="center">
                                      No Call Offs
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Card>
                    </Paper>
                    <Paper mt={6}>
                      <Typography variant="h4" component="div" gutterBottom>
                        Incident{" "}
                        <Tooltip
                          title="Retrieve Incident Reports"
                          placement="top"
                        >
                          <IconButton
                            disabled={!hasPermission("Logs", "fetchIncident")}
                            color="primary"
                            onClick={() => {
                              if (values.current_shift) {
                                getIncident(values);
                              } else {
                                window.alert("Please select Shift");
                              }
                            }}
                          >
                            <PictureAsPdfIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "20px", borderBottom: 0 }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>IR#</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell sx={{ minWidth: "220px" }}>
                                  Summary
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {incidentRecords ? (
                              <TableBody>
                                {incidentRecords.length > 0 ? (
                                  incidentRecords.map((record, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.IncidentReportName
                                          ? `${record.IncidentReportName} (${record.ReportVersion})`
                                          : `(${record.ReportVersion})`}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {
                                          record.ir_incident_type
                                            .IncidentDescription
                                        }{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.start_time}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.IncidentLocation}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.SummaryDescription}{" "}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={8} align="center">
                                      No incident reports
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Card>
                    </Paper>
                    <Paper mt={6}>
                      <Typography variant="h4" component="div" gutterBottom>
                        Person Of Interest{" "}
                        <Tooltip
                          title="Retrieve POI Encounters"
                          placement="top"
                        >
                          <IconButton
                            disabled={
                              !hasPermission("Logs", "fetchPoiEncounter")
                            }
                            color="primary"
                            onClick={() => {
                              if (values.current_shift) {
                                getPoiEncounter(values);
                              } else {
                                window.alert("Please select Shift");
                              }
                            }}
                          >
                            <AttributionIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "20px", borderBottom: 0 }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Time Reported</TableCell>
                                <TableCell>Threat Level</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Race</TableCell>
                                <TableCell>Age</TableCell>
                                <TableCell>Police Contacted</TableCell>
                                <TableCell>Trespass Issued</TableCell>
                                <TableCell>Resolution</TableCell>
                                <TableCell>Image</TableCell>
                              </TableRow>
                            </TableHead>
                            {poiEncounters ? (
                              <TableBody>
                                {poiEncounters.length > 0 ? (
                                  poiEncounters.map((record, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.poi.id}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {new Date(
                                          record.created
                                        ).toLocaleString("en-us")}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.poi.threat_level}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.poi.gender}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.poi.race}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.poi.age}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.police_contacted}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.trespass_issued}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.resolution}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.image}{" "}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={10} align="center">
                                      No poi reports
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Card>
                    </Paper>
                    <Paper mt={6}>
                      <Typography variant="h4" component="div" gutterBottom>
                        Task Recorded{" "}
                        <Tooltip title="Retrieve Task Recorded" placement="top">
                          <IconButton
                            disabled={!hasPermission("Logs", "fetchTask")}
                            color="primary"
                            onClick={() => {
                              if (values.current_shift) {
                                getTasks(values);
                              } else {
                                window.alert("Please select Shift");
                              }
                            }}
                          >
                            <ListAltIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "20px", borderBottom: 0 }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Task Name</TableCell>
                                <TableCell>Count</TableCell>
                              </TableRow>
                            </TableHead>
                            {taskRecorded ? (
                              <TableBody>
                                {taskRecorded.length > 0 ? (
                                  taskRecorded.map((record, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.TaskName}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.count}{" "}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={2} align="center">
                                      No tasks recorded
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Card>
                    </Paper>
                    <Paper mt={6}>
                      <Typography variant="h4" component="div" gutterBottom>
                        Tours Recorded{" "}
                        <Tooltip
                          title="Retrieve Tours Recorded"
                          placement="top"
                        >
                          <IconButton
                            disabled={!hasPermission("Logs", "fetchTour")}
                            color="primary"
                            onClick={() => {
                              if (values.current_shift) {
                                getTours(values);
                              } else {
                                window.alert("Please select Shift");
                              }
                            }}
                          >
                            <TripOriginIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "20px", borderBottom: 0 }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Tour Name</TableCell>
                                <TableCell>Count</TableCell>
                              </TableRow>
                            </TableHead>
                            {toursRecorded ? (
                              <TableBody>
                                {toursRecorded.length > 0 ? (
                                  toursRecorded.map((record, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.TourName}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.count}{" "}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={2} align="center">
                                      No tours recorded
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Card>
                    </Paper>
                    <Paper mt={6}>
                      <Typography variant="h4" component="div" gutterBottom>
                        Buttons Scanned{" "}
                        <Tooltip
                          title="Retrieve Button Scanned Count"
                          placement="top"
                        >
                          <IconButton
                            disabled={
                              !hasPermission("Logs", "fetchButtonScanned")
                            }
                            color="primary"
                            onClick={() => {
                              if (values.current_shift) {
                                getButtonScanned(values);
                              } else {
                                window.alert("Please select Shift");
                              }
                            }}
                          >
                            <FlipIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "20px", borderBottom: 0 }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Tour Name</TableCell>
                                <TableCell>Button Name</TableCell>
                                <TableCell>Scan Count</TableCell>
                                <TableCell>Officer Scans</TableCell>
                              </TableRow>
                            </TableHead>
                            {buttonScanned ? (
                              <TableBody>
                                {buttonScanned.length > 0 ? (
                                  buttonScanned.map((record, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.TourName}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.TagDescription}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.TagCount}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.officers}{" "}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={2} align="center">
                                      No button scanned
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Card>
                    </Paper>
                    <Paper mt={6}>
                      <Typography variant="h4" component="div" gutterBottom>
                        Visitors Recorded{" "}
                        <Tooltip
                          title="Retrieve Visitors Recorded"
                          placement="top"
                        >
                          <IconButton
                            disabled={!hasPermission("Logs", "fetchVisitor")}
                            color="primary"
                            onClick={() => {
                              if (values.current_shift) {
                                getVisitor(values);
                              } else {
                                window.alert("Please select Shift");
                              }
                            }}
                          >
                            <GroupsIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "20px", borderBottom: 0 }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Visitor Name</TableCell>
                                <TableCell>Visitor Type</TableCell>
                                <TableCell>Host Name</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Mobile Phone</TableCell>
                                <TableCell>Check-in Time</TableCell>
                              </TableRow>
                            </TableHead>
                            {visitorsRecorded ? (
                              <TableBody>
                                {visitorsRecorded.length > 0 ? (
                                  visitorsRecorded.map((record, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.name}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.user_type}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.host_name}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.CompanyName}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.phone}{" "}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.checked_in_time}{" "}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={6} align="center">
                                      No visitors
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Occupancy / Guest Details (hotels)
                      </Typography>
                    </Paper>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={4}>
                        <FormControl mt={6}>
                          <CustomTextField
                            label="Arrivals"
                            name="arrivals"
                            id="arrivals"
                            placeholder="From reservations system"
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl mt={6}>
                          <CustomTextField
                            label="Departures"
                            name="departures"
                            id="departures"
                            placeholder="From reservations system"
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl mt={6}>
                          <CustomTextField
                            label="Occupancy(%)"
                            name="occupancy"
                            id="occupancy"
                            placeholder="From reservations system"
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>
                        List any important guest issues that need to ne followed
                        up on by managemnt
                      </FormLabel>
                      <QuillWrapper>
                        <ReactQuill
                          theme="snow"
                          name="guest_issues"
                          value={values.guest_issues}
                          onChange={(newValue) => {
                            setFieldValue("guest_issues", newValue);
                          }}
                          placeholder="Type something.."
                        />
                      </QuillWrapper>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Group Details
                      </Typography>
                    </Paper>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          mt={6}
                        >
                          <FormControl>
                            <CustomTextField
                              label="List group names that are in-house"
                              name="list_group"
                              id="list-group"
                              placeholder="Microsoft"
                              InputLabelProps={{ shrink: true }}
                              disabled
                            />
                          </FormControl>
                          <IconButton
                            color="primary"
                            sx={{ marginLeft: "5px" }}
                            onClick={() => {
                              addMoreListGroup(values.list_group);
                              setFieldValue("list_group", "");
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                        {listGroup?.map((item, index) => {
                          return (
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                              mt={3}
                              key={index}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="list_group"
                                  id="list-group"
                                  placeholder="Microsoft"
                                  value={item}
                                />
                              </FormControl>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  removeListGroup(index);
                                }}
                                sx={{ marginLeft: "5px" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          mt={6}
                        >
                          <FormControl>
                            <CustomTextField
                              label="Group contact person"
                              name="contact_person"
                              id="contact-person"
                              placeholder="Bill Gates"
                              InputLabelProps={{ shrink: true }}
                              disabled
                            />
                          </FormControl>
                          <IconButton
                            color="primary"
                            sx={{ marginLeft: "5px" }}
                            onClick={() => {
                              addMoreGroupContactPerson(values.contact_person);
                              setFieldValue("contact_person", "");
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                        {groupContactPerson?.map((item, index) => {
                          return (
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                              mt={3}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="contact_person"
                                  id="contact-person"
                                  placeholder="Microsoft"
                                  value={item}
                                />
                              </FormControl>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  removeGroupContactPerson(index);
                                }}
                                sx={{ marginLeft: "5px" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>
                        List any group issues encountered that would be helpful
                        to pass on to the next shift
                      </FormLabel>
                      <QuillWrapper>
                        <ReactQuill
                          theme="snow"
                          name="group_issues"
                          value={values.group_issues}
                          onChange={(newValue) => {
                            setFieldValue("group_issues", newValue);
                          }}
                          placeholder="Type something.."
                        />
                      </QuillWrapper>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Equipment, Tools and Supplies{" "}
                      </Typography>
                    </Paper>
                  </Grid>
                  {equipmentTools?.map((field) => {
                    return (
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <FormLabel>{field.question}</FormLabel>
                          <RadioGroup
                            aria-labelledby={field.column_name}
                            value={values[field.column_name]}
                            name={field.column_name}
                            onChange={(e) =>
                              setFieldValue(
                                field.column_name,
                                e.currentTarget.value
                              )
                            }
                            row
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                              disabled
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                              disabled
                            />
                            <FormControlLabel
                              value="Other"
                              control={<Radio />}
                              label="Other"
                              disabled
                            />
                          </RadioGroup>
                        </FormControl>
                        <Grid
                          container
                          spacing={6}
                          sx={{
                            display:
                              values[field.column_name] === "" ||
                              values[field.column_name] === "Yes"
                                ? "none"
                                : "block",
                          }}
                        >
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <CustomTextField
                                placeholder="Add comments here"
                                name={`${field.column_name}_other`}
                                id={`${field.column_name}_other`}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Shift Duties{" "}
                      </Typography>
                    </Paper>
                  </Grid>
                  {shiftDuties?.map((field) => {
                    return (
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <FormLabel>{field.question}</FormLabel>
                          <RadioGroup
                            aria-labelledby={field.column_name}
                            value={values[field.column_name]}
                            name={field.column_name}
                            onChange={(e) =>
                              setFieldValue(
                                field.column_name,
                                e.currentTarget.value
                              )
                            }
                            row
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                              disabled
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                              disabled
                            />
                            <FormControlLabel
                              value="Other"
                              control={<Radio />}
                              label="Other"
                              disabled
                            />
                          </RadioGroup>
                        </FormControl>
                        <Grid
                          container
                          spacing={6}
                          sx={{
                            display:
                              values[field.column_name] === "" ||
                              values[field.column_name] === "Yes"
                                ? "none"
                                : "block",
                          }}
                        >
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <CustomTextField
                                placeholder="Add comments here"
                                name={`${field.column_name}_other`}
                                id={`${field.column_name}_other`}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Elevator/Lift Out of Service or Failures{" "}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>All lifts functional</FormLabel>
                      <RadioGroup
                        aria-labelledby="lifts-functional"
                        name="all_lifts_functional"
                        value={values.all_lifts_functional}
                        onChange={(event) => {
                          setFieldValue(
                            "all_lifts_functional",
                            event.target.value
                          );
                        }}
                        row
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          disabled
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box
                      sx={{
                        display:
                          values.all_lifts_functional === "No"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                          <FormControl>
                            <Autocomplete
                              multiple
                              limitTags={2}
                              id="elevator-selected"
                              options={elevatorList}
                              value={elevatorSelected}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option}
                              onChange={(event, value) => {
                                setElevatorSelected(value);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={
                                    elevatorSelected?.length == 0
                                      ? "Select"
                                      : null
                                  }
                                  disabled
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl>
                            <CustomTextField
                              placeholder="Add comments here"
                              name="elevator_comment"
                              id="elevator-comment"
                              disabled
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Fire panel status
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>All systems normal</FormLabel>
                      <RadioGroup
                        aria-labelledby="system-normal"
                        name="all_systems_normal"
                        value={values.all_systems_normal}
                        onChange={(event) => {
                          setFieldValue(
                            "all_systems_normal",
                            event.target.value
                          );
                        }}
                        row
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          disabled
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box
                      sx={{
                        display:
                          values.all_systems_normal === "No" ? "block" : "none",
                      }}
                    >
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                          <FormControl>
                            <CustomSelect
                              name="select_issue"
                              options={selectIssue}
                              displayEmpty
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl>
                            <CustomTextField
                              name="issue_comment"
                              placeholder="Enter your comment"
                              id="issue-comment"
                              disabled
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Security Failures
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>All systems normal</FormLabel>
                      <RadioGroup
                        aria-labelledby="security-falilures-system-normal"
                        name="security_failures_system_normal"
                        value={values.security_failures_system_normal}
                        onChange={(event) => {
                          setFieldValue(
                            "security_failures_system_normal",
                            event.target.value
                          );
                        }}
                        row
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          disabled
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box
                      sx={{
                        display:
                          values.security_failures_system_normal === "No"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                          <FormControl>
                            <Autocomplete
                              multiple
                              limitTags={2}
                              id="security-failures"
                              options={allSystemNormal}
                              value={securityFailureSelected}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.title}
                              onChange={(event, value) => {
                                setSecurityFailureSystemSelected(value);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.title}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select" />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl>
                            <CustomTextField
                              name="security_failure_comment"
                              placeholder="Enter your comment"
                              id="security-failure-comment"
                              disabled
                            />
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                          <FormControl>
                            <QuillWrapper>
                              <ReactQuill
                                theme="snow"
                                name="sys_normal_training_summary"
                                value={values.sys_normal_training_summary}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    "sys_normal_training_summary",
                                    newValue
                                  );
                                }}
                                placeholder="Type something.."
                              />
                            </QuillWrapper>
                          </FormControl>
                        </Grid> */}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Typography variant="h4" component="div" gutterBottom>
                        Passdown{" "}
                        <Tooltip placement="top" title="Passdowns Record">
                          <IconButton
                            disabled={!hasPermission("Logs", "fetchPassdown")}
                            color="primary"
                            onClick={() => {
                              if (values.current_shift) {
                                getPassdown(values);
                              } else {
                                window.alert("Please select Shift");
                              }
                            }}
                          >
                            <GetAppIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "20px", borderBottom: 0 }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Task</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Detail</TableCell>
                                <TableCell>Reported</TableCell>
                                <TableCell>Datetime</TableCell>
                                <TableCell>Follow up</TableCell>
                              </TableRow>
                            </TableHead>
                            {passdown ? (
                              <TableBody>
                                {passdown.length > 0 ? (
                                  passdown.map((record, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.nontourdata.details}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.nontourdata.task_location}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.nontourdata.remark || ""}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.u.ReportedBy}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {new Date(
                                          record.nontourdata.PassdownTime
                                        ).toLocaleString("en-US")}
                                      </TableCell>
                                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {record.nontourdata.followup === "Y" ? (
                                          <span
                                            style={{
                                              color: "red",
                                              textAlign: "center",
                                            }}
                                          >
                                            Yes
                                          </span>
                                        ) : record.nontourdata.followup ===
                                          "N" ? (
                                          <span style={{ textAlign: "center" }}>
                                            No
                                          </span>
                                        ) : null}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={6} align="center">
                                      No Passdowns
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel>
                      Summary of any notable events, police/fire dept activity,
                      or special concern rooms, VIP arrivals or special
                      requests, group concerns, overdue keys, other observations
                    </FormLabel>
                    <FormControl>
                      <QuillWrapper>
                        <ReactQuill
                          theme="snow"
                          name="notable_events_summary"
                          value={values.notable_events_summary}
                          onChange={(newValue) => {
                            setFieldValue("notable_events_summary", newValue);
                          }}
                          placeholder="Type something.."
                        />
                      </QuillWrapper>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>

      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function EditShiftReport() {
  return (
    <React.Fragment>
      <Helmet title="View Shift Report" />
      <WithPermissionFallback controller="Logs" action="viewShiftReport">
        <Typography variant="h3" gutterBottom display="inline">
          View Shift Report
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/logs/shift-report">
            View Report
          </Link>
          <Typography>View Shift Report</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <EditShiftReportForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default EditShiftReport;

import CustomGauge from "../blocks/dashboard/customGauge";
import { buildStyles } from "react-circular-progressbar";
import { GAUGE_COLORS } from "../../constants";

const mockMarketData = [
  {
    marketName: "Total Completed",
    revScore: "33.0",
  },
  // {
  //   marketName: "Incident Reports",
  //   revScore: "4.0",
  // },
  // {
  //   marketName: "Time spent on tours 3",
  //   revScore: "0.0",
  // },
  // {
  //   marketName: "Time spent 4",
  //   revScore: "0.0",
  // },
];

const strokeSize = 9;

const gaugeColors = [
  GAUGE_COLORS.GAUGE_1,
  GAUGE_COLORS.GAUGE_2,
  GAUGE_COLORS.GAUGE_3,
  GAUGE_COLORS.GAUGE_4,
];

export default function sovaScore() {
  const chartData = mockMarketData;

  // return (
  //   <div className="mt-[1.625rem] grid gap-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
  //     {chartData.map((data, index) => (
  //       <div key={index}>
  //         <p className="text-center text-[1.0625rem]">{data.marketName}</p>
  //         <div className="mt-[1.69rem] flex justify-center">
  //           <CustomGauge
  //             progressPercent={data.revScore}
  //             strokeSize={strokeSize}
  //             centerLabel={data.revScore}
  //             circularProgressStyles={buildStyles({
  //               rotation: 0.67,
  //               strokeLinecap: "round",
  //               trailColor: "#0B1D26",
  //               pathColor: gaugeColors[index % gaugeColors.length],
  //               pathTransitionDuration: 2,
  //             })}
  //           />
  //         </div>
  //       </div>
  //     ))}
  //   </div>
  // );

  return (
    <div className="mt-[1.625rem] grid gap-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
      {chartData.map((data, index) => (
        <div key={index}>
          <div className="mt-[1.69rem] flex justify-center">
            <CustomGauge
              progressPercent={data.revScore}
              strokeSize={strokeSize}
              centerLabel={data.revScore}
              circularProgressStyles={buildStyles({
                rotation: 0.67,
                strokeLinecap: "round",
                trailColor: "#0B1D26",
                pathColor: gaugeColors[index % gaugeColors.length],
                pathTransitionDuration: 2,
              })}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import { LockerInspectionPdf } from "../../../api";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem,
  FormLabel,
  Card,
  Box,
  FormHelperText,
  Paper,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { makeStyles } from "@mui/styles";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
// // Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const LockerInspection = ({
  lockerInspectionDialog,
  lockerInspectionDialogClose,
  roomData,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  let initialValues = { select_room: "", lockers_count: "" };
  const validationSchema = Yup.object().shape({
    select_room: Yup.string().required("Please select room"),
    lockers_count: Yup.string().required("Please enter locker count"),
  });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      LockerInspectionPdf({
        room_name: values.select_room,
        lockcnt: values.lockers_count,
      })
        .then((res) => {
          if (res.statusCode === 200) {
            const linkSource = `data:application/pdf;base64,${res.data}`;
            const downloadLink = document.createElement("a");
            const fileName = "SOVA-LOCKER_INSPECTION.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            setLoading(false);
          } else {
            console.log("something went wrong");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-edit-locks-title"
        aria-describedby="add-edit-locks-desc"
        open={lockerInspectionDialog}
        onClose={lockerInspectionDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-edit-locks-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Locker Inspection
          </Typography>
          <DialogActions>
            <IconButton
              onClick={lockerInspectionDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            INSTRUCTIONS: Begin building a list of lockers to inspect by
            selecting a room from the dropdown, then indicate how many lockers
            to inspect. When you click the "Generate PDF" button below, SOVA
            will randomly select lockers to add to this inspection. Completing
            these steps regularly should result in all lockers being inspected
            over time
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="select_room"
                        fieldLabel="Select room"
                        options={roomData}
                        displayEmpty
                        InputLabelProps={{ shrink: true }}
                      />
                      <FormHelperText error>
                        {errors.select_room}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="lockers_count"
                        label="Count of Lockers to Inspect"
                        id="lockers-count"
                        type="number"
                        placeholder="Numbers Only"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    clor="primary"
                    variant="outlined"
                    onClick={lockerInspectionDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    clor="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Generate PDF
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default LockerInspection;

import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { EditEmergencyType } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  FormControl as MuiFormControl,
  IconButton,
  DialogContent,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const EditEmergencyTypeDialog = ({
  openDialog,
  closeDialog,
  getEmergencyTypes,
  tabs,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    oldType: Yup.string().required("This field is required"),
    newType: Yup.string().required("This field is required"),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    const payload = { oldType: values.oldType, newType: values.newType };
    EditEmergencyType(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          closeDialog();
          getEmergencyTypes();
          dispatch(getMsg("Emergency Type edited successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={openDialog} onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Edit Emergency Type</Typography>
        <IconButton onClick={closeDialog} sx={{ marginRight: "-10px" }}>
          <CancelRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ oldType: "", newType: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values }) => (
            <Form>
              <FormControl mt={5}>
                <InputLabel>Select Emergency Type</InputLabel>
                <Field
                  as={Select}
                  name="oldType"
                  value={values.oldType}
                  fullWidth
                  label="Select Emergency Type"
                >
                  {tabs.map((type, index) => (
                    <MenuItem key={index} value={type.type}>
                      {type.type}
                    </MenuItem>
                  ))}
                </Field>
                <ErrorMessage
                  name="oldType"
                  component="div"
                  style={{ color: "red", fontSize: "0.875em" }}
                />
              </FormControl>

              <FormControl mt={5}>
                <Field
                  as={TextField}
                  name="newType"
                  label="New Emergency Type"
                  fullWidth
                  value={values.newType}
                  autoComplete="off"
                  placeholder="Enter new emergency type"
                />
                <ErrorMessage
                  name="newType"
                  component="div"
                  style={{ color: "red", fontSize: "0.875em" }}
                />
              </FormControl>

              <DialogActions sx={{ padding: "20px 0" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? "Saving..." : "Save"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditEmergencyTypeDialog;

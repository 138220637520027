import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
  Button,
} from "@mui/material";

import {
  CancelRounded as CancelRoundedIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";

const UpdatePassword = ({ updatePassword, updatePasswordClose }) => {
  return (
    <>
      <Dialog
        aria-labelledby="update-password-title"
        aria-describedby="update-password-desc"
        open={updatePassword}
        onClose={updatePasswordClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="update-password-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Confirmation
          </Typography>
          <DialogActions>
            <IconButton
              onClick={updatePasswordClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <AccessTimeIcon
            fontSize="large"
            color="primary"
            sx={{ marginBottom: "15px" }}
          />
          <DialogContentText id="update-password-desc">
            Your password will expire in 13 day(s). Please help us in
            maintaining the security of your user account by resetting your
            password regularly. You must choose a unique password each time you
            update. Click the button below to reset your password, or choose
            later to be reminded the next time you login.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={updatePasswordClose}
          >
            Later
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: "15px" }}
          >
            Reset password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdatePassword;

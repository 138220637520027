import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  TextField,
  Card,
  FormControl as MuiFormControl,
  Button,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const ImageCropper = ({
  imageCropperDialog,
  imageCropperClose,
  childToParent,
  imageData,
  dialogHead,
}) => {
  const [image, setImage] = useState(null);
  //   const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      childToParent(cropper.getCroppedCanvas().toDataURL());
      //   setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  useEffect(() => {
    if (imageData) {
      setImage(imageData);
    } else {
      setImage(null);
    }
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="property-logo-title"
        aria-describedby="property-logo-description"
        open={imageCropperDialog}
        onClose={imageCropperClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-location-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            {dialogHead}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={imageCropperClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Card
            variant="outlined"
            sx={{ height: "auto" }}
            style={{ textAlign: "center" }}
          >
            {image ? (
              <Cropper
                style={{ height: 300, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            ) : (
              <img src="/static/img/location_hint.png" width="50%" />
            )}
          </Card>
          <FormControl mt={6}>
            <input type="file" onChange={onChange} />
            {/* <TextField label="" type="file" /> */}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getCropData();
              imageCropperClose();
            }}
          >
            Crop
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageCropper;

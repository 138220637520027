import React from "react";
import { Snackbar, Alert } from "@mui/material";
const Toaster = (props) => {
  const { openSnack, handleCloseSnack, message, severity } = props;
  return (
    <Snackbar
      open={openSnack}
      autoHideDuration={3000}
      onClose={handleCloseSnack}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        onClose={handleCloseSnack}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;

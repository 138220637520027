import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { EquipmentActiveInactive } from "../../api";
import { useSelector } from "react-redux";
const EquipmentListToggle = (props) => {
  const { value, id } = props;
  const [active, setActive] = useState(value === "A" ? true : false);
  const handleActive = (status, id) => {
    console.log(status, id);
    let payload = {
      status: status === true ? "I" : "A",
    };
    EquipmentActiveInactive(id, payload)
      .then((res) => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  // Check for permission for the specific action
  const hasPermission = permissions.some(
    (perm) =>
      perm.controller === "Equipment" &&
      perm.action_name === "activeInactive" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            disabled={!hasPermission}
            checked={active}
            onClick={() => {
              setActive(!active);
              handleActive(active, id);
            }}
          />
        }
        label=""
      />
    </>
  );
};

export default EquipmentListToggle;

import React from "react";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  FormControl as MuiFormControl,
  Alert,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import { SaveAuthPersonDetails } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const keyGroup = [
  { key: "Executive", value: "executive" },
  { key: "Test updated", value: "testupdated" },
  { key: "Other", value: "other" },
];
const AddAuthorizationPerson = ({
  addAuthorizationPersonDialog,
  addAuthorizationPersonDialogClose,
  keyReqId,
  getInvolvedAuthPerListing,
  authActionStatus,
  authPerData,
  setPageLoad,
}) => {
  const dispatch = useDispatch();
  let initialValues = {
    name: authPerData ? (authPerData.name ? authPerData.name : "") : "",
    title: authPerData ? (authPerData.title ? authPerData.title : "") : "",
    email: authPerData ? (authPerData.email ? authPerData.email : "") : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    title: Yup.string().required("Please enter title"),
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Please enter email address."),
    // position: Yup.string().required("Please enter company name"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formData = {
        id: authPerData ? (authPerData.id ? authPerData.id : null) : null,
        key_request_id: keyReqId ? keyReqId : "",
        name: values.name,
        title: values.title,
        email: values.email,
      };
      SaveAuthPersonDetails(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            getInvolvedAuthPerListing();
            dispatch(getMsg("Authorization person details saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            addAuthorizationPersonDialogClose();
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
      // await
      //   timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (err) {
      setPageLoad(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-authorization-title"
        aria-describedby="add-authorization-desc"
        open={addAuthorizationPersonDialog}
        onClose={addAuthorizationPersonDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-authorization-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Authorization Person
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addAuthorizationPersonDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          {authPerData.id ? null : (
            <Alert severity="info">
              Details of the person who will be sent an email to authorize this
              request{" "}
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={3}>
                      <CustomTextField
                        label="Name *"
                        autoComplete="off"
                        name="name"
                        id="name"
                        InputLabelProps={{ shrink: true }}
                        placeholder="John Smith"
                        disabled={authActionStatus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        label="Title *"
                        autoComplete="off"
                        name="title"
                        id="title"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Director of operations"
                        disabled={authActionStatus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        label="Email *"
                        autoComplete="off"
                        name="email"
                        id="email"
                        InputLabelProps={{ shrink: true }}
                        placeholder="me@you.com"
                        disabled={authActionStatus}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    disabled={authActionStatus}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAuthorizationPerson;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Box,
  Paper,
  Card,
  Avatar,
  CircularProgress,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { TaskCommentList, SaveModuleTaskComment } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";

import {
  Cancel as CancelIcon,
  ReplayCircleFilled as ReplayCircleFilledIcon,
} from "@mui/icons-material";

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const TaskListDialog = ({
  taskListDilaog,
  setTaskListDilaog,
  taskListDilaogOpen,
  taskListDilaogClose,
  itemData,
  module,
}) => {
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    getCommentist();
  }, []);

  const getCommentist = () => {
    setLoader(true);
    let payload = {
      module: module,
      module_task_id: itemData.id,
    };
    TaskCommentList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setCommentList(res.data);
        } else {
          setCommentList([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SaveCommant = () => {
    if (comment) {
      setLoader(true);
      let payload = {
        module_task_id: itemData.id,
        comment: comment,
      };
      SaveModuleTaskComment(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            showAlertTost(res.message, "success");
            setComment("");
            getCommentist();
          } else {
            showAlertTost("Sorry! something wrong please try agin", "error");
          }
          setLoader(false);
        })
        .catch((err) => {
          showAlertTost("Sorry! something wrong please try agin", "error");
        });
    } else {
      showAlertTost("Please enter comment", "error");
    }
  };

  const showAlertTost = (msg, type) => {
    dispatch(getSnackMsg(msg));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={taskListDilaog}
        onClose={taskListDilaogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4">{itemData.title}</Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setTaskListDilaog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom mt={5}>
            <strong>Start Date:</strong> {itemData.start_date}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Due Date:</strong> {itemData.due_date}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Description:</strong> {itemData.description}
          </Typography>
          <Typography variant="body2" gutterBottom m={5}>
            Comments
          </Typography>
          <Grid conatiner spacing={0}>
            <Grid item md={12} m={5}>
              <TextField
                fullWidth
                value={comment}
                onChange={(evt) => setComment(evt.target.value)}
                id="outlined-basic"
                label="Write a commnet.."
                variant="outlined"
                sx={{
                  marginTop: "20px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <ReplayCircleFilledIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Box
              mt={4}
              sx={{
                textAlign: "right",
              }}
            >
              <Button variant="contained" onClick={() => SaveCommant()}>
                Post
              </Button>
            </Box>
            {commentList.map((row, index) => {
              return (
                <Grid sx={{ display: "flex" }}>
                  <Grid item md={6} m={5}>
                    <Paper>
                      <Card>
                        <Avatar
                          alt="Remy Sharp"
                          src={
                            row.user
                              ? row.user.profile_image
                              : "/static/images/avatar/1.jpg"
                          }
                        />
                        <Typography variant="body2">
                          {row.user ? row.user.UserName : ""}
                        </Typography>
                        <Typography variant="body2">{row.comment}</Typography>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item md={6} m={15}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 21,
                        ml: 8,
                      }}
                    >
                      <Typography variant="body2" gutterBottom>
                        {row.created}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={taskListDilaogClose}
          >
            Close
          </Button>
        </DialogActions>

        {loader && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </Dialog>
    </>
  );
};

export default TaskListDialog;

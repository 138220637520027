import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@mui/styles";
import {
  GetInspectionQuestionList,
  DeleteInspectionQuestion,
  QuestionReorder,
  InspectionAreaDropdown,
  InspectionCategoryDropdown,
  FetchSelectedQuestions,
} from "../../api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  FormControl as MuiFormControl,
  Grid,
  DialogActions,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
  Menu,
  Button,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";

import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const ChangeOrder = ({
  changeOrderDialog,
  changeOrderDialogClose,
  inspectionQuestionList,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  // Inspection area
  const [inspectionArea, setInspectionArea] = React.useState("");
  // Category Select
  const [category, setCategory] = React.useState("");

  const [schema, setSchema] = useState([]);
  const [isTrue, setIsTrue] = useState(false);
  const fetchSelectedQuestions = (categoryValue, areaValue) => {
    let payload = {
      inspection_area: areaValue,
      inspection_category: categoryValue,
    };
    if (areaValue != "" && categoryValue != "") {
      setLoading(true);
      FetchSelectedQuestions(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setSchema(res.data);
            setIsTrue(true);
            setLoading(false);
          } else {
            setSchema([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const selectCategory = (event) => {
    setCategory(event.target.value);
    fetchSelectedQuestions(event.target.value, inspectionArea);
  };
  const selectInspectionArea = (event) => {
    setInspectionArea(event.target.value);
    fetchSelectedQuestions(category, event.target.value);
  };
  const [inspectionAreas, setInspectionAreas] = useState([]);
  const inspectionAreaDropdown = () => {
    InspectionAreaDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setInspectionAreas(res.data);
        } else {
          setInspectionAreas([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [inspectionCategorie, setInspectionCategorie] = useState([]);
  const inspectionCategoryDropdown = () => {
    InspectionCategoryDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setInspectionCategorie(res.data);
        } else {
          setInspectionCategorie([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    inspectionAreaDropdown();
    inspectionCategoryDropdown();
  }, []);

  // const [schema, setSchema] = React.useState([
  //   {
  //     id: "123",
  //     type: "a",
  //     text: "123-text",
  //   },
  //   {
  //     id: "345",
  //     type: "b",
  //     text: "345-text",
  //   },
  //   {
  //     id: "567",
  //     type: "a",
  //     text: "567-text",
  //   },
  //   {
  //     id: "789",
  //     type: "b",
  //     text: "789-text",
  //   },
  // ]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // reorder using index of source and destination.
    const schemaCopy = schema.slice();
    const [removed] = schemaCopy.splice(result.source.index, 1);
    // put the removed one into destination.
    schemaCopy.splice(result.destination.index, 0, removed);

    console.log(result);

    setSchema(schemaCopy);
    console.log(schema);
  };
  let Ids = schema.map((d) => d.id);
  const handleReorder = () => {
    setLoading(true);
    let payload = { data: Ids };
    QuestionReorder(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          inspectionQuestionList();
          changeOrderDialogClose();
          setLoading(false);
        } else {
          console.log("err");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="add-question-title"
        aria-describedby="add-question-desc"
        open={changeOrderDialog}
        onClose={changeOrderDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "650px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-question-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Change Question Order
          </Typography>
          <DialogActions>
            <IconButton
              onClick={changeOrderDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FormControl mt={6}>
                  <InputLabel shrink id="department">
                    Inspection area*
                  </InputLabel>
                  <Select
                    labelId="department"
                    id="inspection-area"
                    value={inspectionArea}
                    label="Inspection area*"
                    onChange={selectInspectionArea}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">Select</MenuItem>
                    {inspectionAreas.map((data) => {
                      return (
                        <MenuItem value={data.key} key={data.value}>
                          {data.key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl mt={6}>
                  <InputLabel shrink id="category-level">
                    Category*
                  </InputLabel>
                  <Select
                    labelId="department"
                    id="category"
                    value={category}
                    label="Category*"
                    onChange={selectCategory}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">Select</MenuItem>
                    {inspectionCategorie.map((data) => {
                      return (
                        <MenuItem value={data.key} key={data.value}>
                          {data.key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <Box sx={{ marginTop: "15px" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="column1">
                {(provided, snap) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {schema.map((it, i) => (
                      <Draggable
                        key={it.id + ""}
                        draggableId={it.id + ""}
                        index={i}
                      >
                        {(provided, snap) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="list-item"
                            style={{
                              backgroundColor: snap.isDragging
                                ? "#376fd0"
                                : "#eee",
                              padding: "12px 15px",
                              marginBottom: "10px",
                              borderRadius: 5,

                              ...provided.draggableProps.style,
                            }}
                          >
                            {it.question}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <DialogActions>
            {isTrue && (
              <Button
                color="primary"
                variant="contained"
                onClick={handleReorder}
              >
                Save
              </Button>
            )}
          </DialogActions>
          {loading && (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangeOrder;

import React, { useState, useEffect, useCallback } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelection from "../../../components/form-components/CustomSelection";
import CheckboxWrapper from "../../accounts/components/Checkbox";
import { makeStyles } from "@mui/styles";
import _debounce from "lodash.debounce";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Accordion as MuiAccordion,
  FormLabel,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Autocomplete,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  FormHelperText,
  MenuItem,
  ListItemText,
  Menu,
  ListItemIcon,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  ExpandMore as ExpandMoreIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
} from "@mui/icons-material";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import {
  DepartmentList,
  SavePersonInvolved,
  SearchInvolvedPerson,
} from "../../../api";
import states from "../../../helper/states.json";
import { DateTimePicker, DatePicker } from "@mui/lab";
import moment from "moment";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleApiResonseErrors } from "../../../helper/helper";
import GoogleAutoCompleteField from "../../../components/form-components/GoogleAutoCompleteField";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  alphabets: {
    marginTop: "15px",
    "& .MuiList-root": {
      display: "flex",
      overflowX: "auto",
      background: "#7aa8ff0d",
      padding: "0px",
      "& .MuiListItemButton-root": {
        paddingLeft: "13px",
        paddingRight: "13px",
        textAlign: "center",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  accordion: {
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  shipmentRequest: {
    "& .MuiFormControl-root": {
      minWidth: "auto",
      width: "100%",
    },
  },
  filterDataByTabs: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    "& .MuiToggleButtonGroup-root": {
      justifyContent: "space-between",
      width: "100%",
      overflowX: "auto",
      "& .MuiButtonBase-root": {
        borderRadius: "0",
        width: "100%",
        whiteSpace: "nowrap",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  btnDropdown: {
    maxWidth: "65px",
    width: "100%",
  },
  statusButton: {
    width: "20px",
    height: "20px",
    display: "block",
    lineHeight: "20px",
    textAlign: "center",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "12px",
    fontWeight: "bolder",
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterByRadioControls: {
    position: "absolute",
    top: "100%",
    right: "0px",
    boxShadow: "0px 3px 5px 3px #00000014 !important",
    minWidth: "320px",
    zIndex: "1",
    padding: "15px",
  },
  itemInSingleRow: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  createMergedGroup: {
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "#c4c4c4" : theme.palette.grey[300],
    padding: 0,
    marginTop: "15px",
  },
  CustomActionsButton: {
    borderColor:
      theme.palette.mode === "light" ? "#c4c4c4" : theme.palette.grey[300],
    color: theme.palette.mode === "light" ? "#7f8797" : theme.palette.grey[300],
    padding: "13px 6px 13px 12px",
    border: "1px solid",
    justifyContent: "space-between",
    fontSize: "11px",
    fontWeight: "400",
    marginTop: "15px",
  },
}));
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const SubMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginTop: "0px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: 0,
    },
    "& .MuiListItemIcon-root": {
      justifyContent: "flex-end",
    },
  },
}));
const AddPersonInvolved = ({
  personInvolved,
  setPersonInvolved,
  personInvolvedOpen,
  personInvolvedClose,
  incidentId,
  getInvolvedPersonListing,
  pageLoad,
  setPageLoad,
  editPIData,
  personActionStatus,
  involvedPerId,
}) => {
  const [departmentList, setDepartmentList] = useState([]);
  const dispatchR = useDispatch();
  const LoggedInUserLocationInfo = useSelector((state) => state.login.Location);
  const [ipInvolvement, setIpInvolvement] = useState("");
  const [nameInputValue, setNameInputValue] = useState("");
  const [nameSuggestion, setNameSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToSaveInvolvedPerson = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "saveInvolvedPerson" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const initialValues = {
    name: editPIData ? (editPIData.name ? editPIData.name : "") : "",
    ip_involvement: editPIData
      ? editPIData.involvement_type
        ? editPIData.involvement_type
        : ""
      : "",
    gender: editPIData
      ? editPIData.gender
        ? editPIData.gender
        : "Male"
      : "Male",
    guest_registered: editPIData
      ? editPIData.guest_registered
        ? editPIData.guest_registered
        : ""
      : "",
    guest_room_no: editPIData
      ? editPIData.guest_room_no
        ? editPIData.guest_room_no
        : ""
      : "",
    guest_arrival: editPIData
      ? editPIData.guest_arrival
        ? editPIData.guest_arrival
        : null
      : null,
    guest_departure: editPIData
      ? editPIData.guest_departure
        ? editPIData.guest_departure
        : null
      : null,
    is_employee: editPIData
      ? editPIData.is_employee
        ? editPIData.is_employee
        : ""
      : "",
    employee_department: editPIData
      ? editPIData.employee_department
        ? editPIData.employee_department
        : ""
      : "",
    employee_supervisor: editPIData
      ? editPIData.employee_supervisor
        ? editPIData.employee_supervisor
        : ""
      : "",
    dob: editPIData ? (editPIData.dob ? editPIData.dob : null) : null,
    age: editPIData ? (editPIData.age ? editPIData.age : "") : "",
    marital_status: editPIData
      ? editPIData.marital_status
        ? editPIData.marital_status
        : ""
      : "",
    hair_color: editPIData
      ? editPIData.hair_color
        ? editPIData.hair_color
        : ""
      : "",
    weight: editPIData ? (editPIData.weight ? editPIData.weight : "") : "",
    height: editPIData ? (editPIData.height ? editPIData.height : "") : "",
    address: editPIData ? (editPIData.address ? editPIData.address : "") : "",
    city: editPIData ? (editPIData.city ? editPIData.city : "") : "",
    state: editPIData ? (editPIData.state ? editPIData.state : "") : "",
    zip: editPIData ? (editPIData.zip ? editPIData.zip : "") : "",
    country: editPIData ? (editPIData.country ? editPIData.country : "") : "",
    home_phone: editPIData
      ? editPIData.home_phone
        ? editPIData.home_phone
        : ""
      : "",
    mobile_phone: editPIData
      ? editPIData.mobile_phone
        ? editPIData.mobile_phone
        : ""
      : "",
    email: editPIData ? (editPIData.email ? editPIData.email : "") : "",
    employee_name: editPIData
      ? editPIData.employee_name
        ? editPIData.employee_name
        : ""
      : "",
    employee_state: editPIData
      ? editPIData.employee_state
        ? editPIData.employee_state
        : ""
      : "",
    employee_address: editPIData
      ? editPIData.employee_address
        ? editPIData.employee_address
        : ""
      : "",
    employee_zip: editPIData
      ? editPIData.employee_zip
        ? editPIData.employee_zip
        : ""
      : "",
    employee_city: editPIData
      ? editPIData.employee_city
        ? editPIData.employee_city
        : ""
      : "",
    employee_phone: editPIData
      ? editPIData.employee_phone
        ? editPIData.employee_phone
        : ""
      : "",
    driver_license_number: editPIData
      ? editPIData.driver_license_number
        ? editPIData.driver_license_number
        : ""
      : "",
    driver_license_state: editPIData
      ? editPIData.driver_license_state
        ? editPIData.driver_license_state
        : ""
      : "",
    driver_license_expiry: editPIData
      ? editPIData.driver_license_expiry
        ? editPIData.driver_license_expiry
        : null
      : null,
    interviewed: editPIData
      ? editPIData.interviewed
        ? editPIData.interviewed
        : ""
      : "",
    injured: editPIData ? (editPIData.injured ? editPIData.injured : "") : "",
    injury_class: editPIData
      ? editPIData.injury_class
        ? editPIData.injury_class
        : ""
      : "",
    body_part_impacted: [],
    injury_caused_from: editPIData
      ? editPIData.injury_caused_from
        ? editPIData.injury_caused_from
        : ""
      : "",
    lose_concious: editPIData
      ? editPIData.lose_concious
        ? editPIData.lose_concious
        : ""
      : "",
    treatment_rendered: [],
    injury_related_conditions: editPIData
      ? editPIData.injury_related_conditions
        ? editPIData.injury_related_conditions
        : ""
      : "",
    first_aid_administered: editPIData
      ? editPIData.first_aid_administered
        ? editPIData.first_aid_administered
        : ""
      : "",
    hospitalised: editPIData
      ? editPIData.hospitalised
        ? editPIData.hospitalised
        : ""
      : "",
    followup_required: editPIData
      ? editPIData.followup_required
        ? editPIData.followup_required
        : ""
      : "",
    followup_email_datetime: editPIData
      ? editPIData.followup_email_datetime
        ? editPIData.followup_email_datetime
        : null
      : null,
    comm_preferred_method: editPIData
      ? editPIData.comm_preferred_method
        ? editPIData.comm_preferred_method
        : "in_person"
      : "in_person",
    add_poi: editPIData ? (editPIData.add_poi == "1" ? true : false) : false,
    threat_level: editPIData
      ? editPIData.threat_level
        ? editPIData.threat_level
        : ""
      : "",
    poi_alias: editPIData
      ? editPIData.poi_alias
        ? editPIData.poi_alias
        : ""
      : "",
    note: editPIData ? (editPIData.note ? editPIData.note : "") : "",
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    guest_registered: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    guest_room_no: Yup.string().when(["guest_registered"], (same, schema) => {
      return same === "No"
        ? schema
        : schema.required("Please enter the room no.");
    }),
    guest_arrival: Yup.string()
      .nullable()
      .when(["guest_registered"], (same, schema) => {
        return same === "No"
          ? schema
          : schema.required("Please select arrival date");
      }),
    guest_departure: Yup.string()
      .nullable()
      .when(["guest_registered"], (same, schema) => {
        return same === "No"
          ? schema
          : schema.required("Please select departure date");
      }),
    is_employee: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    interviewed: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    injured: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    injury_class: Yup.string().when(["injured"], (same, schema) => {
      return same === "No"
        ? schema
        : schema.required("Please select the officer name");
    }),
    body_part_impacted: Yup.array().when(["injured"], (same, schema) => {
      return same === "No"
        ? schema
        : schema
            .of(
              Yup.object().shape({
                id: Yup.string(),
                title: Yup.string(),
                value: Yup.string(),
              })
            )
            .min(1, "Please select body part impacted");
    }),
    injury_caused_from: Yup.string().when(["injured"], (same, schema) => {
      return same === "No"
        ? schema
        : schema.required("Please select the injury caused from");
    }),
    lose_concious: Yup.string().when(["injured"], (same, schema) => {
      return same === "No"
        ? schema
        : schema
            .required("Please select one of these options")
            .oneOf(["Yes", "No"]);
    }),
    treatment_rendered: Yup.array().when(["injured"], (same, schema) => {
      return same === "No"
        ? schema
        : schema
            .of(
              Yup.object().shape({
                id: Yup.string(),
                title: Yup.string(),
                value: Yup.string(),
              })
            )
            .min(1, "Please select treatment rendered");
    }),
    injury_related_conditions: Yup.string().when(
      ["injured"],
      (same, schema) => {
        return same === "No"
          ? schema
          : schema
              .required("Please select one of these options")
              .oneOf(["Yes", "No"]);
      }
    ),
    first_aid_administered: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    hospitalised: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    followup_required: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    followup_email_datetime: Yup.string()
      .nullable()
      .when(["followup_required"], (same, schema) => {
        return same === "No"
          ? schema
          : schema.required("Please select reminder email date/time");
      }),
    comm_preferred_method: Yup.string().when(
      ["followup_required"],
      (same, schema) => {
        return same === "No"
          ? schema
          : schema.required("Please select the injury caused from");
      }
    ),
    threat_level: Yup.string().when(["add_poi"], (same, schema) => {
      return same === false
        ? schema
        : schema.required("Please select the threat level");
    }),
    poi_alias: Yup.string().when(["add_poi"], (same, schema) => {
      return same === false
        ? schema
        : schema.required("Please enter the POI alias");
    }),

    home_phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      // .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),

    mobile_phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      // .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
    email: Yup.string().nullable().email("Please enter valid email"),
    employee_phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      // .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
  });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setPageLoad(true);
      console.log("++++++MY FORM VALUES +++++", values);
      let formatedGArrDate = values.guest_arrival
        ? moment(values.guest_arrival).format("YYYY-MM-DD")
        : "";
      let formatedGDepDate = values.guest_departure
        ? moment(values.guest_departure).format("YYYY-MM-DD")
        : "";
      let formatedDOBDate = values.dob
        ? moment(values.dob).format("YYYY-MM-DD")
        : "";
      let formatedfollowupDatetime = values.followup_email_datetime
        ? moment(values.followup_email_datetime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedlicenseexpiryDate = values.driver_license_expiry
        ? moment(values.driver_license_expiry).format("YYYY-MM-DD")
        : "";
      let formData = {
        id: involvedPerId ? involvedPerId : null,
        incident_id: incidentId,
        name: values.name,
        involvement_type: ipInvolvement, //For multi level dropdown
        gender: values.gender,
        guest_registered: values.guest_registered,
        guest_room_no: values.guest_room_no,
        guest_arrival: formatedGArrDate,
        guest_departure: formatedGDepDate,
        is_employee: values.is_employee,
        employee_department: values.employee_department,
        employee_supervisor: values.employee_supervisor,
        dob: formatedDOBDate,
        age: values.age,
        marital_status: values.marital_status,
        hair_color: values.hair_color,
        // other_hair_color: "",
        weight: values.weight,
        height: values.height,
        address: values.address,
        city: values.city,
        state: values.state,
        email: values.email,
        zip: values.zip,
        country: values.country,
        home_phone: values.home_phone,
        mobile_phone: values.mobile_phone,
        employee_name: values.employee_name,
        employee_state: values.employee_state,
        employee_address: values.employee_address,
        employee_zip: values.employee_zip,
        employee_city: values.employee_city,
        employee_phone: values.employee_phone,
        driver_license_number: values.driver_license_number,
        driver_license_state: values.driver_license_state,
        driver_license_expiry: formatedlicenseexpiryDate,
        interviewed: values.interviewed,
        injured: values.injured,
        injury_class: values.injury_class,
        body_part_impacted: values.body_part_impacted
          ? values.body_part_impacted.map((data) => data.value)
          : [],
        injury_caused_from: values.injury_caused_from,
        lose_concious: values.lose_concious,
        treatment_rendered: values.treatment_rendered
          ? values.treatment_rendered.map((data) => data.value)
          : [],
        injury_related_conditions: values.injury_related_conditions,
        first_aid_administered: values.first_aid_administered,
        hospitalised: values.hospitalised,
        followup_required: values.followup_required,
        followup_email_datetime: formatedfollowupDatetime,
        comm_preferred_method: values.comm_preferred_method,
        add_poi: values.add_poi,
        threat_level: values.threat_level,
        poi_alias: values.poi_alias,
        note: values.note,
      };
      SavePersonInvolved(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            getInvolvedPersonListing();
            dispatchR(getSnackMsg(res.message));
            dispatchR(getSnackAlert("success"));
            dispatchR(getSnackOpen());
            personInvolvedClose();
            setTimeout(() => {
              dispatchR(getSnackClose());
            }, 3000);
          } else {
            dispatchR(getSnackMsg("Something went wrong, please try again"));
            dispatchR(getSnackAlert("error"));
            dispatchR(getSnackOpen());
            // personInvolvedClose();
            setTimeout(() => {
              dispatchR(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          console.log(err);
          dispatchR(getSnackMsg("Something went wrong, please try again"));
          dispatchR(getSnackAlert("error"));
          dispatchR(getSnackOpen());
          // personInvolvedClose();
          setTimeout(() => {
            dispatchR(getSnackClose());
          }, 3000);
          // let errors = err.data.errors;
          // let error = handleApiResonseErrors(errors);
          // if (error) {
          //   dispatch(getSnackMsg(error));
          //   dispatch(getSnackAlert("error"));
          //   dispatch(getSnackOpen());
          //   setTimeout(() => {
          //     dispatch(getSnackClose());
          //   }, 3000);
          // }
        });
    } catch (error) {
      console.log(error);
      setPageLoad(false);
    }
  };

  const selectIpInvolvement = [
    { key: "Option one", value: "1" },
    { key: "Option two", value: "2" },
    { key: "Option three", value: "3" },
    { key: "Option four", value: "4" },
    { key: "Option five", value: "5" },
  ];
  const department = [
    { key: "Option one", value: "1" },
    { key: "Option two", value: "2" },
    { key: "Option three", value: "3" },
    { key: "Option four", value: "4" },
    { key: "Option five", value: "5" },
  ];
  const selectAge = [
    { key: "Under 18", value: "Under 18" },
    { key: "18-25", value: "18-25" },
    { key: "26-30", value: "26-30" },
    { key: "31-35", value: "31-35" },
    { key: "36-40", value: "36-40" },
    { key: "41-45", value: "41-45" },
    { key: "46-50", value: "46-50" },
    { key: "51-60", value: "51-60" },
    { key: "Over 60", value: "Over 60" },
  ];
  const meritalStatus = [
    {
      key: "Married/Domestic Partnership",
      value: "Married/Domestic Partnership",
    },
    { key: "Single", value: "Single" },
    { key: "Widowed", value: "Widowed" },
    { key: "Divorced", value: "Divorced" },
    { key: "Separated", value: "Separated" },
  ];
  const hairColor = [
    { key: "Blonde", value: "Blonde" },
    { key: "Brown", value: "Brown" },
    { key: "Red", value: "Red" },
    { key: "Grey", value: "Grey" },
    { key: "Black", value: "Black" },
    { key: "Other", value: "Other" },
  ];
  const selectWeight = [
    { key: "Under 120 lbs.", value: "Under 120 lbs." },
    { key: "120 - 135 lbs.", value: "120 - 135 lbs." },
    { key: "136 - 150 lbs.", value: "136 - 150 lbs." },
    { key: "151 - 170 lbs.", value: "151 - 170 lbs." },
    { key: "171 - 185 lbs.", value: "171 - 185 lbs." },
    { key: "186 - 200 lbs.", value: "186 - 200 lbs." },
    { key: "201 - 225 lbs.", value: "201 - 225 lbs." },
    { key: "Over 225 lbs", value: "Over 225 lbs" },
  ];
  const selectHeight = [
    { key: "Under 5 foot", value: "Under 5 foot" },
    { key: "5'-1' to 5'-6'", value: "5'-1' to 5'-6'" },
    { key: "5'-7' to 6'-0'", value: "5'-7' to 6'-0'" },
    { key: "Over 6 foot", value: "Over 6 foot" },
  ];

  const bodyPartImpacted = [
    { id: 1, title: "Head", value: "head" },
    { id: 2, title: "Neck", value: "neck" },
    { id: 3, title: "Right shoulder", value: "right_shoulder" },
    { id: 4, title: "Left shoulder", value: "left_shoulder" },
    { id: 5, title: "Upper back", value: "upper_back" },
    { id: 6, title: "Right arm", value: "right_arm" },
    { id: 7, title: "Right hand", value: "right_hand" },
    { id: 8, title: "Left arm", value: "left_arm" },
    { id: 9, title: "Left hand", value: "left_hand" },
    { id: 10, title: "Torso", value: "torso" },
    { id: 11, title: "Lower back", value: "lower_back" },
    { id: 12, title: "Hip", value: "hip" },
    { id: 13, title: "Buttocks", value: "buttocks" },
    { id: 14, title: "Right leg", value: "right_leg" },
    { id: 15, title: "Left leg", value: "left_leg" },
    { id: 16, title: "Right foot", value: "right_foot" },
    { id: 17, title: "Left foot", value: "left_foot" },
  ];
  const treatmentRendered = [
    { id: 1, title: "Abdominal thrusts", value: "abdominal_thrusts" },
    { id: 2, title: "Antiseptic", value: "antiseptic" },
    { id: 3, title: "Apply pressure", value: "apply_pressure" },
    { id: 4, title: "Blanket offered", value: "blanket_offered" },
    { id: 5, title: "Burn gel", value: "burn_gel" },
    { id: 6, title: "Cold pack given", value: "cold_pack_given" },
    { id: 7, title: "CPR", value: "CPR" },
    { id: 8, title: "Elevate body part", value: "elevate_body_part" },
    { id: 9, title: "Fluids given", value: "fluids_given" },
    { id: 10, title: "Immobilize body part", value: "immobilize_body_part" },
    { id: 11, title: "Oxygen", value: "Oxygen" },
    { id: 12, title: "Personal meds taken", value: "personal_meds_taken" },
    { id: 13, title: "Tourniquet applied", value: "tourniquet_applied" },
    { id: 14, title: "Wound dressed", value: "wound_dressed" },
  ];
  const selectInjuryClassification = [
    { key: "Abrasion", value: "abrasion" },
    { key: "Animal bite", value: "animal_bite" },
    { key: "Broken skin", value: "broken_skin" },
    { key: "Burn", value: "burn" },
    { key: "Cardiac event possible", value: "cardiac_event_possible" },
    { key: "Choking", value: "choking" },
    { key: "Contusion", value: "contusion" },
    { key: "Dizziness", value: "dizziness" },
    { key: "Electrical injury", value: "electrical_injury" },
    { key: "Fracture possible", value: "fracture_possible" },
    { key: "Heat related", value: "heat_related" },
    { key: "Nosebleed", value: "nosebleed" },
    { key: "Rash", value: "rash" },
    { key: "Shortness of breath", value: "shortness_breath" },
    { key: "Sprain/strain possible", value: "strain_possible" },
  ];
  const communicationMethod = [
    { key: "In person", value: "in_person" },
    { key: "Personal Phone", value: "mobile_phone" },
    { key: "Call guestroom", value: "call_guestroom" },
    { key: "Email", value: "email" },
  ];
  const threatLevel = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "6", value: "6" },
    { key: "7", value: "7" },
    { key: "8", value: "8" },
    { key: "9", value: "9" },
    { key: "10", value: "10" },
  ];
  const injuryCausedFrom = [
    { key: "Animal related", value: "animal_related" },
    { key: "Bodily exposure", value: "bodily_exposure" },
    { key: "Fall from ground", value: "fall_from_ground" },
    { key: "Fall from height", value: "fall_from_height" },
    { key: "Food related", value: "food_related" },
    { key: "Struck by object", value: "struck_object" },
  ];

  const getDepartmentList = () => {
    DepartmentList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  //states for Multilevel dropdown
  const [multiLevelDropdown, setMultiLevelDropdown] = React.useState(null);
  const handleMultilevelDropdownOpen = (event) => {
    setMultiLevelDropdown(event.currentTarget);
  };

  const handleMultilevelDropdownClose = () => {
    setMultiLevelDropdown(null);
  };
  // Level one
  const [levelOne, setLevelOne] = useState(null);
  const handleLevelOneOpen = (event) => {
    setLevelOne(event.currentTarget);
  };
  const handleLevelOneClose = () => {
    setLevelOne(null);
  };
  // Level two
  const [siblingDrop, setSiblingDrop] = useState(null);
  const handleSiblingOpen = (event) => {
    setSiblingDrop(event.currentTarget);
  };
  const handleSiblingClose = () => {
    setSiblingDrop(null);
  };

  const [siblingBroDrop, setSiblingBroDrop] = useState(null);
  const handleSiblingBroOpen = (event) => {
    setSiblingBroDrop(event.currentTarget);
  };
  const handleSiblingBroClose = () => {
    setSiblingBroDrop(null);
  };

  const [siblingSisDrop, setSiblingSisDrop] = useState(null);
  const handleSiblingSisOpen = (event) => {
    setSiblingSisDrop(event.currentTarget);
  };
  const handleSiblingSisClose = () => {
    setSiblingSisDrop(null);
  };

  //Parent
  const [parentDrop, setParentDrop] = useState(null);
  const handleParentOpen = (event) => {
    setParentDrop(event.currentTarget);
  };
  const handleParentClose = () => {
    setParentDrop(null);
  };

  const [parentMomDrop, setParentMomDrop] = useState(null);
  const handleParentMomOpen = (event) => {
    setParentMomDrop(event.currentTarget);
  };
  const handleParentMomClose = () => {
    setParentMomDrop(null);
  };

  const [parentDadDrop, setParentDadDrop] = useState(null);
  const handleParentDadOpen = (event) => {
    setParentDadDrop(event.currentTarget);
  };
  const handleParentDadClose = () => {
    setParentDadDrop(null);
  };

  // GrandParent
  const [grandParentDrop, setGrandParentDrop] = useState(null);
  const handleGrandParentOpen = (event) => {
    setGrandParentDrop(event.currentTarget);
  };
  const handleGrandParentClose = () => {
    setGrandParentDrop(null);
  };

  // Child
  const [childDrop, setChildDrop] = useState(null);
  const handleChildOpen = (event) => {
    setChildDrop(event.currentTarget);
  };
  const handleChildClose = () => {
    setChildDrop(null);
  };

  //Spouse
  const [spouseDrop, setSpouseDrop] = useState(null);
  const handleSpouseOpen = (event) => {
    setSpouseDrop(event.currentTarget);
  };
  const handleSpouseClose = () => {
    setSpouseDrop(null);
  };
  //Domestic partner
  const [domesticDrop, setDomesticDrop] = useState(null);
  const handleDomesticOpen = (event) => {
    setDomesticDrop(event.currentTarget);
  };
  const handleDomesticClose = () => {
    setDomesticDrop(null);
  };

  //Aunt
  const [auntDrop, setAuntDrop] = useState(null);
  const handleAuntOpen = (event) => {
    setAuntDrop(event.currentTarget);
  };
  const handleAuntClose = () => {
    setAuntDrop(null);
  };

  //Uncle
  const [uncleDrop, setUncleDrop] = useState(null);
  const handleUncleOpen = (event) => {
    setUncleDrop(event.currentTarget);
  };
  const handleUncleClose = () => {
    setUncleDrop(null);
  };

  //Niece
  const [nieceDrop, setNieceDrop] = useState(null);
  const handleNieceOpen = (event) => {
    setNieceDrop(event.currentTarget);
  };
  const handleNieceClose = () => {
    setNieceDrop(null);
  };

  //Nephew
  const [nephewDrop, setNephewDrop] = useState(null);
  const handleNephewOpen = (event) => {
    setNephewDrop(event.currentTarget);
  };
  const handleNephewClose = () => {
    setNephewDrop(null);
  };

  //Cousin
  const [cousinDrop, setCousinDrop] = useState(null);
  const handleCousinOpen = (event) => {
    setCousinDrop(event.currentTarget);
  };
  const handleCousinClose = () => {
    setCousinDrop(null);
  };
  const classes = useStyles();
  const handleAutocompleteChange = async (newValue) => {
    if (newValue && newValue.length >= 3) {
      setLoading(true);
      try {
        const response = await SearchInvolvedPerson({ name: newValue });
        setNameSuggestions(response.data);
      } catch (error) {
        console.error("Error fetching involved name suggestions:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const debouncedAutocompleteChange = useCallback(
    _debounce((input) => handleAutocompleteChange(input), 300),
    []
  );

  const handleInputChange = (event, newInputValue) => {
    if (event) {
      setNameInputValue(newInputValue);
      debouncedAutocompleteChange(newInputValue);
    }
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1100px",
            },
          },
        }}
        aria-labelledby="addPerson-title"
        aria-describedby="addPerson-description"
        open={personInvolved}
        onClose={personInvolvedClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="addPerson-title"
        >
          <Typography variant="h4">
            {editPIData.name
              ? `${personActionStatus ? "View" : "Edit"} Person Involved - ${
                  editPIData.name
                } : ${editPIData.involvement_type}`
              : "Add Person Involved"}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={personInvolvedClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <Card mb={2}>
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl mt={3}>
                          <Autocomplete
                            freeSolo
                            value={values.name}
                            onChange={(event, newValue) => {
                              // If the new value is an object (from suggestions), use its label; otherwise, use the input directly
                              // const selectedValue =
                              //   typeof newValue === "string"
                              //     ? newValue
                              //     : newValue?.label || "";
                              // setFieldValue("name", selectedValue);
                              if (newValue && typeof newValue === "string") {
                                setNameInputValue(newValue || "");
                                setFieldValue("name", newValue || "");
                              } else {
                                setNameInputValue(newValue.name || "");
                                setFieldValue("name", newValue.name || "");
                                setFieldValue(
                                  "address",
                                  newValue.address || ""
                                );
                                setFieldValue("city", newValue.city || "");
                                setFieldValue("state", newValue.state || "");
                                setFieldValue("zip", newValue.zip || "");
                                setFieldValue("email", newValue.email || "");
                                setFieldValue(
                                  "mobile_phone",
                                  newValue.mobile_phone || ""
                                );
                              }
                            }}
                            inputValue={nameInputValue}
                            onInputChange={handleInputChange}
                            options={nameSuggestion}
                            getOptionLabel={(option) => option.name || ""}
                            loading={loading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Name"
                                variant="outlined"
                                fullWidth
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                onBlur={handleBlur}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      {/* Multi-level-dropdown */}
                      <Grid item xs={12} md={6} lg={6}>
                        <FormLabel sx={{ display: "block" }}>
                          Involvement *
                        </FormLabel>
                        <Button
                          aria-label="Settings"
                          size="large"
                          color="primary"
                          variant="outlined"
                          aria-owns={
                            multiLevelDropdown ? "multilevel-menu" : undefined
                          }
                          aria-haspopup="true"
                          onClick={handleMultilevelDropdownOpen}
                          disabled={personActionStatus}
                        >
                          {ipInvolvement == ""
                            ? editPIData
                              ? editPIData.involvement_type
                                ? editPIData.involvement_type
                                : "Select IP Involvement"
                              : "Select IP Involvement"
                            : ipInvolvement}
                          <ArrowDropDownIcon />
                        </Button>
                        <FormControl mt={3}>
                          <StyledMenu
                            id="multilevel-menu"
                            anchorEl={multiLevelDropdown}
                            open={Boolean(multiLevelDropdown)}
                            onClose={handleMultilevelDropdownClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Complainant");
                              }}
                            >
                              <ListItemText primary="Complainant" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Complainant");
                              }}
                            >
                              <ListItemText primary="Complainant" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Victim");
                              }}
                            >
                              <ListItemText primary="Victim" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={handleLevelOneOpen}>
                              <ListItemText primary="Relative" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Friend of Victim");
                              }}
                            >
                              <ListItemText primary="Friend of Victim" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Witness");
                              }}
                            >
                              <ListItemText primary="Witness" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Suspect");
                              }}
                            >
                              <ListItemText primary="Suspect" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Passenger");
                              }}
                            >
                              <ListItemText primary="Passenger" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Police Officer");
                              }}
                            >
                              <ListItemText primary="Police Officer" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Fire Personnel");
                              }}
                            >
                              <ListItemText primary="Fire Personnel" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("EMS");
                              }}
                            >
                              <ListItemText primary="EMS" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Mentioned");
                              }}
                            >
                              <ListItemText primary="Mentioned" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                          <SubMenu
                            id="level-one"
                            anchorEl={levelOne}
                            open={Boolean(levelOne)}
                            onClose={handleLevelOneClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem onClick={handleSiblingOpen}>
                              <ListItemText primary="Sibling" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleParentOpen}>
                              <ListItemText primary="Parent" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleGrandParentOpen}>
                              <ListItemText primary="Grandparent" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleChildOpen}>
                              <ListItemText primary="Child" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleSpouseOpen}>
                              <ListItemText primary="Spouse" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleDomesticOpen}>
                              <ListItemText primary="Domestic Partner" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleAuntOpen}>
                              <ListItemText primary="Aunt" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleUncleOpen}>
                              <ListItemText primary="Uncle" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleNieceOpen}>
                              <ListItemText primary="Niece" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleNephewOpen}>
                              <ListItemText primary="Nephew" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleCousinOpen}>
                              <ListItemText primary="Cousin" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                          </SubMenu>
                          {/* Siblings menues */}
                          <SubMenu
                            id="level-one"
                            anchorEl={siblingDrop}
                            open={Boolean(siblingDrop)}
                            onClose={handleSiblingClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem onClick={handleSiblingBroOpen}>
                              <ListItemText primary="Brother" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleSiblingSisOpen}>
                              <ListItemText primary="Sister" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                          </SubMenu>
                          <SubMenu
                            id="level-one"
                            anchorEl={siblingBroDrop}
                            open={Boolean(siblingBroDrop)}
                            onClose={handleSiblingBroClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleSiblingBroClose();
                                handleSiblingClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Brother of");
                              }}
                            >
                              <ListItemText primary={`Brother of `} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          <SubMenu
                            id="level-one"
                            anchorEl={siblingSisDrop}
                            open={Boolean(siblingSisDrop)}
                            onClose={handleSiblingSisClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleSiblingSisClose();
                                handleSiblingClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Sister of");
                              }}
                            >
                              <ListItemText primary={`Sister of `} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          {/* Parents menues */}
                          <SubMenu
                            id="level-one"
                            anchorEl={parentDrop}
                            open={Boolean(parentDrop)}
                            onClose={handleParentClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem onClick={handleParentMomOpen}>
                              <ListItemText primary="Mother" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleParentDadOpen}>
                              <ListItemText primary="Father" pl={1} />
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                            </MenuItem>
                          </SubMenu>
                          <SubMenu
                            id="level-one"
                            anchorEl={parentMomDrop}
                            open={Boolean(parentMomDrop)}
                            onClose={handleParentMomClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleParentMomClose();
                                handleParentClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Mother of");
                              }}
                            >
                              <ListItemText primary={`Mother of `} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          <SubMenu
                            id="level-one"
                            anchorEl={parentDadDrop}
                            open={Boolean(parentDadDrop)}
                            onClose={handleParentDadClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleParentDadClose();
                                handleParentClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Father of");
                              }}
                            >
                              <ListItemText primary={`Father of `} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          {/* Grandparent menues */}
                          <SubMenu
                            id="level-one"
                            anchorEl={grandParentDrop}
                            open={Boolean(grandParentDrop)}
                            onClose={handleGrandParentClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleGrandParentClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Grandparent of");
                              }}
                            >
                              <ListItemText primary={`Grandparent of`} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          <SubMenu
                            id="level-one"
                            anchorEl={childDrop}
                            open={Boolean(childDrop)}
                            onClose={handleChildClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleChildClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Child of");
                              }}
                            >
                              <ListItemText primary={`Child of`} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          {/* Spouse of */}
                          <SubMenu
                            id="level-one"
                            anchorEl={spouseDrop}
                            open={Boolean(spouseDrop)}
                            onClose={handleSpouseClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleSpouseClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Spouse of");
                              }}
                            >
                              <ListItemText primary={`Spouse of`} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          {/* Domestic partner of */}
                          <SubMenu
                            id="level-one"
                            anchorEl={domesticDrop}
                            open={Boolean(domesticDrop)}
                            onClose={handleDomesticClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleDomesticClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Domestic Partner of");
                              }}
                            >
                              <ListItemText
                                primary={`Domestic Partner of`}
                                pl={1}
                              />
                            </MenuItem>
                          </SubMenu>
                          {/* Aunt */}
                          <SubMenu
                            id="level-one"
                            anchorEl={auntDrop}
                            open={Boolean(auntDrop)}
                            onClose={handleAuntClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleAuntClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Aunt of");
                              }}
                            >
                              <ListItemText primary={`Aunt of`} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          {/* Uncle */}
                          <SubMenu
                            id="level-one"
                            anchorEl={uncleDrop}
                            open={Boolean(uncleDrop)}
                            onClose={handleUncleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleUncleClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Uncle of");
                              }}
                            >
                              <ListItemText primary={`Uncle of`} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          {/* Niece */}
                          <SubMenu
                            id="level-one"
                            anchorEl={nieceDrop}
                            open={Boolean(nieceDrop)}
                            onClose={handleNieceClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleNieceClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Niece of");
                              }}
                            >
                              <ListItemText primary={`Niece of`} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          {/* Nephew */}
                          <SubMenu
                            id="level-one"
                            anchorEl={nephewDrop}
                            open={Boolean(nephewDrop)}
                            onClose={handleNephewClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleNephewClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Nephew of");
                              }}
                            >
                              <ListItemText primary={`Nephew of`} pl={1} />
                            </MenuItem>
                          </SubMenu>
                          {/* Cousin */}
                          <SubMenu
                            id="level-one"
                            anchorEl={cousinDrop}
                            open={Boolean(cousinDrop)}
                            onClose={handleCousinClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleCousinClose();
                                handleLevelOneClose();
                                handleMultilevelDropdownClose();
                                setIpInvolvement("Cousin of");
                              }}
                            >
                              <ListItemText primary={`Cousin of`} pl={1} />
                            </MenuItem>
                          </SubMenu>
                        </FormControl>
                      </Grid>
                      {/* Multi-level-dropdown */}
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl>
                          <FormLabel>Gender</FormLabel>
                          <RadioGroup
                            aria-labelledby="gender"
                            name="gender"
                            value={values.gender.toString()}
                            onChange={(e) =>
                              setFieldValue("gender", e.currentTarget.value)
                            }
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Male"
                              value="Male"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Female"
                              value="Female"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Undetermined"
                              value="Undetermined"
                              disabled={personActionStatus}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl>
                          <FormLabel>Registered guest? *</FormLabel>
                          <RadioGroup
                            aria-labelledby="guest_registered"
                            name="guest_registered"
                            value={values.guest_registered.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "guest_registered",
                                e.currentTarget.value
                              );
                              setFieldValue("guest_room_no", "");
                              setFieldValue("guest_arrival", null);
                              setFieldValue("guest_departure", null);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                              disabled={personActionStatus}
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.guest_registered &&
                              errors.guest_registered}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{
                          display:
                            values.guest_registered === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <Grid container spacing={6}>
                          <Grid item xs={12} md={4}>
                            <FormControl>
                              <CustomTextField
                                name="guest_room_no"
                                label="Room no. *"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={personActionStatus}
                                inputProps={{ maxLength: 10 }}
                                autoComplete="off"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl>
                              <DatePicker
                                id="arrival"
                                label="Arrival *"
                                value={values.guest_arrival}
                                onChange={(newValue) => {
                                  setFieldValue("guest_arrival", newValue);
                                }}
                                disabled={personActionStatus}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="guest_arrival"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Click to select",
                                    }}
                                    disabled={personActionStatus}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl>
                              <DatePicker
                                id="departure"
                                label="Departure *"
                                value={values.guest_departure}
                                onChange={(newValue) => {
                                  setFieldValue("guest_departure", newValue);
                                }}
                                minDate={values.guest_arrival}
                                disabled={personActionStatus}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="guest_departure"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Click to select",
                                    }}
                                    disabled={personActionStatus}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl>
                          <FormLabel>
                            IP is an employee of{" "}
                            {LoggedInUserLocationInfo.LocationName}? *
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="employee-status"
                            name="is_employee"
                            value={values.is_employee.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "is_employee",
                                e.currentTarget.value
                              );
                              setFieldValue("employee_department", "");
                              setFieldValue("employee_supervisor", "");
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                              disabled={personActionStatus}
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.is_employee && errors.is_employee}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{
                          display:
                            values.is_employee === "Yes" ? "block" : "none",
                        }}
                      >
                        <Grid container spacing={6}>
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <CustomSelection
                                name="employee_department"
                                label="Department *"
                                options={departmentList}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={personActionStatus}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <CustomTextField
                                name="employee_supervisor"
                                label="Supervisor name *"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={personActionStatus}
                                autoComplete="off"
                                placeholder="First name last name"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box className={classes.accordion}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="personal-details-title"
                              id="personal-details-information"
                            >
                              <Typography>Personal Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={6}>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <DatePicker
                                      id="dob"
                                      label="Date of birth"
                                      value={values.dob}
                                      onChange={(newValue) => {
                                        setFieldValue("dob", newValue);
                                      }}
                                      disabled={personActionStatus}
                                      renderInput={(params) => (
                                        <CustomTextField
                                          {...params}
                                          name="dob"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "",
                                          }}
                                          disabled={personActionStatus}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <CustomSelection
                                      name="age"
                                      label="Age"
                                      options={selectAge}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <CustomSelection
                                      name="marital_status"
                                      label="Marital status"
                                      options={meritalStatus}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <CustomSelection
                                      name="hair_color"
                                      label="Hair color"
                                      options={hairColor}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <CustomSelection
                                      name="weight"
                                      label="Weight"
                                      options={selectWeight}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <CustomSelection
                                      name="height"
                                      label="Height"
                                      options={selectHeight}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion mt={4}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="contact-info-title"
                              id="contact-information"
                            >
                              <Typography>Contact Information</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={6}>
                                <Grid item xs={12} md={6} lg={6}>
                                  <FormControl>
                                    <GoogleAutoCompleteField
                                      name="address"
                                      label="Address"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder="Enter a location"
                                      getApiResponse={(res) => {
                                        setFieldValue(
                                          "address",
                                          res.formatted_address
                                        );
                                        setFieldValue("city", res.locality);
                                        // setFieldValue(
                                        //   "lost_state",
                                        //   res.administrative_area_level_1
                                        // );
                                        setFieldValue("zip", res.postal_code);
                                        setFieldValue("country", res.country);
                                      }}
                                    />
                                  </FormControl>
                                  <FormControl mt={6}>
                                    <CustomTextField
                                      name="city"
                                      label="City"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder=""
                                    />
                                  </FormControl>
                                  <FormControl mt={6}>
                                    <CustomSelection
                                      name="state"
                                      label="Select state *"
                                      options={states}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                    />
                                  </FormControl>
                                  <FormControl mt={6}>
                                    <CustomTextField
                                      name="zip"
                                      label="Zip"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder="xxxxx"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      name="home_phone"
                                      label="Work phone"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder="xxx-xxx-xxxx"
                                    />
                                  </FormControl>
                                  <FormControl mt={6}>
                                    <CustomTextField
                                      name="mobile_phone"
                                      label="Personal phone"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder="xxx-xxx-xxxx"
                                    />
                                  </FormControl>
                                  <FormControl mt={6}>
                                    <CustomTextField
                                      name="email"
                                      label="Email"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder="me@you.com"
                                    />
                                  </FormControl>
                                  <FormControl mt={6}>
                                    <CustomTextField
                                      name="country"
                                      label="Country name"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder=""
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            mt={4}
                            sx={{
                              display:
                                values.is_employee === "No" ? "block" : "none",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="employer-information"
                              id="employer-information"
                            >
                              <Typography>Employer Information</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={6}>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="employee_name"
                                      label="Employer name"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder=""
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <CustomSelection
                                      name="employee_state"
                                      label="Employer State"
                                      options={states}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="employee_address"
                                      label="Employer address"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder=""
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="employee_zip"
                                      label="Employer zip"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder="xxxxx"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="employee_city"
                                      label="Employer city"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder=""
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="employee_phone"
                                      label="Employer phone"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      autoComplete="off"
                                      placeholder="xxx-xxx-xxxx"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion mt={4}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="driver-info-title"
                              id="driver-information"
                            >
                              <Typography>Driver Information</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={6}>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="driver_license_number"
                                      label="Driver license no."
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                      inputProps={{ maxLength: 20 }}
                                      autoComplete="off"
                                      placeholder=""
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <CustomSelection
                                      name="driver_license_state"
                                      label="Driver license state *"
                                      options={states}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={personActionStatus}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                  <FormControl>
                                    <DatePicker
                                      id="license-expiry"
                                      label="Driver license expiry"
                                      value={values.driver_license_expiry}
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          "driver_license_expiry",
                                          newValue
                                        );
                                      }}
                                      disabled={personActionStatus}
                                      renderInput={(params) => (
                                        <CustomTextField
                                          {...params}
                                          name="driver_license_expiry"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          disabled={personActionStatus}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "",
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl>
                          <FormLabel>Interviewed? *</FormLabel>
                          <RadioGroup
                            aria-labelledby="interviewed"
                            name="interviewed"
                            value={values.interviewed.toString()}
                            onChange={(e) =>
                              setFieldValue(
                                "interviewed",
                                e.currentTarget.value
                              )
                            }
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                              disabled={personActionStatus}
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.interviewed && errors.interviewed}
                          </FormHelperText>
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel>Injured? *</FormLabel>
                          <RadioGroup
                            aria-labelledby="injured"
                            name="injured"
                            value={values.injured.toString()}
                            onChange={(e) => {
                              setFieldValue("injured", e.currentTarget.value);
                              setFieldValue("injury_class", "");
                              setFieldValue("injury_caused_from", "");
                              setFieldValue("lose_concious", "");
                              setFieldValue("injury_related_conditions", "");
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                              disabled={personActionStatus}
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.injured && errors.injured}
                          </FormHelperText>
                        </FormControl>
                        <Box
                          sx={{
                            display:
                              values.injured === "Yes" ? "block" : "none",
                          }}
                        >
                          <FormControl mt={6}>
                            <CustomSelection
                              name="injury_class"
                              label="Injury classification *"
                              options={selectInjuryClassification}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={personActionStatus}
                            />
                          </FormControl>
                          <FormControl mt={6}>
                            <Autocomplete
                              multiple
                              id="bodypart-impacted"
                              fullWidth
                              options={bodyPartImpacted}
                              // value={bodyImpartedArray}
                              value={values.body_part_impacted}
                              onChange={(e, v) => {
                                // setBodyImpartedArray(v);
                                setFieldValue("body_part_impacted", v);
                              }}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.title}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.title}
                                </li>
                              )}
                              renderInput={(params) => (
                                <CustomTextField
                                  {...params}
                                  name="body_part_impacted"
                                  label="Body part impacted *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl mt={6}>
                            <CustomSelection
                              name="injury_caused_from"
                              label="Injury caused from *"
                              options={injuryCausedFrom}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={personActionStatus}
                            />
                          </FormControl>
                          <FormControl mt={6}>
                            <FormLabel>
                              Did person lose consciousness? *
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="consciousness"
                              name="lose_concious"
                              value={values.lose_concious.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "lose_concious",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Yes"
                                disabled={personActionStatus}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="No"
                                disabled={personActionStatus}
                              />
                            </RadioGroup>
                            <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                              {touched.lose_concious && errors.lose_concious}
                            </FormHelperText>
                          </FormControl>
                          <FormControl mt={6}>
                            <Autocomplete
                              multiple
                              id="treatment-rendered"
                              fullWidth
                              options={treatmentRendered}
                              // value={treatmentRenderArray}
                              value={values.treatment_rendered}
                              onChange={(e, v) => {
                                // setTreatmentRenderArray(v);
                                setFieldValue("treatment_rendered", v);
                              }}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.title}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.title}
                                </li>
                              )}
                              renderInput={(params) => (
                                <CustomTextField
                                  {...params}
                                  name="treatment_rendered"
                                  label="Treatment Rendered *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl mt={6}>
                            <FormLabel>
                              Did involved person or witness mention any
                              existing or co-morbid conditions related to this
                              injury? *
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="co-morbid"
                              name="injury_related_conditions"
                              value={values.injury_related_conditions.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "injury_related_conditions",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Yes"
                                disabled={personActionStatus}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="No"
                                disabled={personActionStatus}
                              />
                            </RadioGroup>
                            <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                              {touched.injury_related_conditions &&
                                errors.injury_related_conditions}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                        <FormControl mt={6}>
                          <FormLabel>First aid administered? *</FormLabel>
                          <RadioGroup
                            aria-labelledby="first-aid-administered"
                            name="first_aid_administered"
                            value={values.first_aid_administered.toString()}
                            onChange={(e) =>
                              setFieldValue(
                                "first_aid_administered",
                                e.currentTarget.value
                              )
                            }
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                              disabled={personActionStatus}
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.first_aid_administered &&
                              errors.first_aid_administered}
                          </FormHelperText>
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel>Hospitalised? *</FormLabel>
                          <RadioGroup
                            aria-labelledby="hospitalised"
                            name="hospitalised"
                            value={values.hospitalised.toString()}
                            onChange={(e) =>
                              setFieldValue(
                                "hospitalised",
                                e.currentTarget.value
                              )
                            }
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                              disabled={personActionStatus}
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.hospitalised && errors.hospitalised}
                          </FormHelperText>
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel>Follow-up required? *</FormLabel>
                          <RadioGroup
                            aria-labelledby="follow-up-required"
                            name="followup_required"
                            value={values.followup_required.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "followup_required",
                                e.currentTarget.value
                              );
                              setFieldValue("followup_email_datetime", null);
                              setFieldValue("comm_preferred_method", "");
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                              disabled={personActionStatus}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                              disabled={personActionStatus}
                            />
                          </RadioGroup>
                          <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                            {touched.followup_required &&
                              errors.followup_required}
                          </FormHelperText>
                        </FormControl>
                        <Box
                          sx={{
                            display:
                              values.followup_required === "Yes"
                                ? "block"
                                : "none",
                          }}
                        >
                          <FormControl mt={6}>
                            <FormLabel>
                              *Persons on the distribution list to receive email
                              reminders for incidents with be auto selected by
                              SOVA. Provide a date and time when the reminder
                              email should be sent.
                            </FormLabel>
                            <DateTimePicker
                              id="reminderDate"
                              value={values.followup_email_datetime}
                              onChange={(newValue) => {
                                setFieldValue(
                                  "followup_email_datetime",
                                  newValue
                                );
                              }}
                              disabled={personActionStatus}
                              inputFormat="MM/dd/yyyy HH:mm"
                              renderInput={(params) => (
                                <CustomTextField
                                  {...params}
                                  name="followup_email_datetime"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "",
                                  }}
                                  disabled={personActionStatus}
                                  autoComplete="off"
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl mt={6}>
                            <CustomSelection
                              name="comm_preferred_method"
                              label="Preferred method of communication *"
                              options={communicationMethod}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={personActionStatus}
                            />
                          </FormControl>
                        </Box>
                        <FormControl mt={6}>
                          <CheckboxWrapper
                            name="add_poi"
                            checked={values.add_poi}
                            disableRipple
                            label="Add IP as a new Person of Interest"
                            returnOnChange={(evt) => {
                              if (evt.target.checked) {
                                setFieldValue("threat_level", "");
                                setFieldValue("poi_alias", "");
                              }
                            }}
                            disabled={personActionStatus}
                          />
                        </FormControl>
                        <Box
                          sx={{
                            display: values.add_poi === true ? "block" : "none",
                          }}
                        >
                          <FormControl mt={6}>
                            <CustomSelection
                              name="threat_level"
                              label="Threat level *"
                              options={threatLevel}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={personActionStatus}
                            />
                          </FormControl>
                          <FormControl mt={6}>
                            <CustomTextField
                              name="poi_alias"
                              label="POI alias *"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={personActionStatus}
                              autoComplete="off"
                              placeholder=""
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="note"
                            fieldLabel="Notes"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={personActionStatus}
                            autoComplete="off"
                            placeholder="Add notes about this phone here"
                            multiline={true}
                            rows={8}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={
                            personActionStatus ||
                            !hasPermissionToSaveInvolvedPerson
                          }
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={personInvolvedClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPersonInvolved;

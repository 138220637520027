import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAlert: false,
  text: "",
  checked: [],
  snackStatus: false,
  msg: "",
  snackAlert: "",
  fieldStatus: false,
  unmasked: localStorage.getItem("unmasked")
    ? localStorage.getItem("unmasked") == "true"
      ? true
      : false
    : false,
  vehicle: {},
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    updateShowAlert: (state, action) => {
      state.showAlert = action.payload;
    },
    updateText: (state, action) => {
      state.text = action.payload;
    },
    getChecked: (state, action) => {
      state.checked = action.payload;
    },
    getSnackOpen: (state) => {
      state.snackStatus = true;
    },
    getSnackClose: (state) => {
      state.snackStatus = false;
    },
    getMsg: (state, action) => {
      state.msg = action.payload;
    },
    getSnackAlert: (state, action) => {
      state.snackAlert = action.payload;
    },
    getFieldEnabled: (state) => {
      state.fieldStatus = false;
    },
    getFieldDisabled: (state) => {
      state.fieldStatus = true;
    },
    getUnmasked: (state) => {
      localStorage.setItem("unmasked", true);
      state.unmasked = true;
    },
    getMasked: (state) => {
      state.unmasked = false;
    },
    addVehicle: (state, action) => {
      state.vehicle = action.payload;
    },
  },
});

export const {
  updateShowAlert,
  updateText,
  getChecked,
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
  getFieldEnabled,
  getFieldDisabled,
  getUnmasked,
  getMasked,
  addVehicle,
} = mainSlice.actions;
export default mainSlice.reducer;

// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const GAUGE_COLORS = {
  GAUGE_1: "#91B539",
  GAUGE_2: "#5A8B20",
  GAUGE_3: "#F6A923",
  GAUGE_4: "#4AD9E2",
  GAUGE_1_GRADIENT: (rotationDeg) =>
    `linear-gradient(${rotationDeg}, rgba(73,91,29,1) 0%, rgba(75,94,30,1) 13%, rgba(84,105,33,1) 21%, rgba(145,181,57,1) 100%)`,
  GAUGE_2_GRADIENT: (rotationDeg) =>
    `linear-gradient(${rotationDeg}, rgba(45,70,16,1) 0%, rgba(90,139,32,1) 100%)`,
  GAUGE_3_GRADIENT: (rotationDeg) =>
    `linear-gradient(${rotationDeg}, rgba(123,85,18,1) 0%, rgba(246,169,35,1) 100%)`,
  GAUGE_4_GRADIENT: (rotationDeg) =>
    `linear-gradient(${rotationDeg}, rgba(37,109,113,1) 0%, rgba(74,217,226,1) 100%)`,
};

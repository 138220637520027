import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { format } from "date-fns";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl as MuiFormControl,
  CircularProgress,
  Paper,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { AddEditAedPlanOfficier, ViewAedPlanOfficier } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { getFileBase64, handleApiResonseErrors } from "../../../helper/helper";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import CustomTextField from "../../../components/form-components/CustomTextField";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const AddEditAedPlanDetails = ({
  addEditAedPlanDetailsDialog,
  addEditAedPlanDetailsDialogClose,
}) => {
  const [aedEffectiveDate, setAedEffectiveDate] = useState(null);
  const [approvalDate, setApprovalDate] = useState(null);

  const [viewPlanData, setViewPlanData] = useState({});
  const [uploadedImage, setUploadedImage] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const uploadedFileRef = useRef();
  const viewAedPlanOfficier = () => {
    setLoading(true);
    ViewAedPlanOfficier()
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setViewPlanData(res.data);
        } else {
          setViewPlanData({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const { aed_effective_date, approval_date } = viewPlanData;
  useEffect(() => {
    viewAedPlanOfficier();
  }, []);

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  let initialValues = {
    aed_effective_date: Date(aed_effective_date)
      ? new Date(aed_effective_date)
      : null,
    approval_date: new Date(approval_date) ? new Date(approval_date) : null,
    building_img: null,
  };
  const validationSchema = Yup.object().shape({
    aed_effective_date: Yup.string()
      .nullable()
      .required("Please select the aed effective date"),
    approval_date: Yup.string()
      .nullable()
      .required("Please select the approval date"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        aed_effective_date:
          values.aed_effective_date !== null
            ? format(values.aed_effective_date, "yyyy-MM-dd")
            : format(new Date(aed_effective_date), "yyyy-MM-dd"),
        approval_date: values.approval_date
          ? format(values.approval_date, "yyyy-MM-dd")
          : format(new Date(approval_date), "yyyy-MM-dd"),
        exterior_image: uploadedImage ? uploadedImage : null,
      };
      AddEditAedPlanOfficier(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setLoading(false);
            addEditAedPlanDetailsDialogClose();
            dispatch(getMsg("Record saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            setLoading(false);
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-edit-aed-plan-details"
        aria-describedby="add-edit-aed-plan-details-desc"
        open={addEditAedPlanDetailsDialog}
        onClose={addEditAedPlanDetailsDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-edit-aed-plan-details"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit AED Plan Details
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addEditAedPlanDetailsDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={3}>
                      <DatePicker
                        id="aed-effective-date"
                        label="AED effective date"
                        name="aed_effective_date"
                        value={values.aed_effective_date}
                        onChange={(newValue) => {
                          setFieldValue("aed_effective_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="aed_effective_date"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <DatePicker
                        id="approval-date"
                        label="Approval date"
                        name="approval_date"
                        value={values.approval_date}
                        onChange={(newValue) => {
                          setFieldValue("approval_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            fullWidth
                            name="approval_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        label="Building image"
                        id="building-image"
                        name="building_img"
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        ref={uploadedFileRef}
                        onChange={(e) => handleFileChange(e)}
                      />
                    </FormControl>
                  </Grid>
                  <DialogActions sx={{ padding: "24px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={addEditAedPlanDetailsDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "15px" }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddEditAedPlanDetails;

import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Box,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  RadioGroup,
  Radio,
  LinearProgress,
} from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DateTimePicker, TimePicker } from "@mui/lab";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);

const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
    marginTop: "5px",
  })
);

const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const bodyPartList = [
  { title: "Ankle right" },
  { title: "Ankle left" },
  { title: "Right hand" },
  { title: "Left hand" },
  { title: "Shoulder" },
  { title: "Head" },
];
const natureInjury = [
  { title: "Bruise" },
  { title: "Sprain" },
  { title: "Abraison" },
  { title: "Concusion" },
  { title: "Shoulder" },
  { title: "Internal trauma" },
];
function EditEarReportsForm(props) {
  const [datevalue, setDateValue] = React.useState(null);
  const [datesigned, setDateSigned] = React.useState(null);
  // Visitor department
  const [department, setDepartment] = React.useState("");
  const selectDepartment = (event) => {
    setDepartment(event.target.value);
  };
  // Visitor position
  const [position, setPosition] = React.useState("");
  const selectPosition = (event) => {
    setPosition(event.target.value);
  };
  // Supervisor position
  const [sposition, setSpervisorPosition] = React.useState("");
  const selectSpervisorPosition = (event) => {
    setSpervisorPosition(event.target.value);
  };
  // Length of service of employee
  const [servicelength, setServiceLength] = React.useState("");
  const selectServiceLength = (event) => {
    setServiceLength(event.target.value);
  };
  const classes = useStyles();

  // Linear Progressbar
  const [progress, setProgress] = React.useState(0);
  //const [value, setTimeValue] = React.useState(null);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Card mb={6}>
      <CardContent>
        <form>
          <Box className={classes.accordion}>
            <Typography variant="h4" component="div" mb={4} gutterBottom>
              Employee
            </Typography>
            <Box mt={4}>
              <LinearProgress
                variant="determinate"
                value={progress}
                color="success"
                sx={{ height: "6px" }}
              />
            </Box>
            <Accordion mt={5}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step1-employee"
                id="step1-employee"
              >
                <Typography>Step 1: Details of Employee Involved</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel component="legend">
                        I am reporting a work related*
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby="workreport"
                          name="workreport"
                          defaultValue="Injury"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Injury"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Illness"
                            value="2"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Near miss"
                            value="3"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        required
                        id="emp-name"
                        label="Employee full name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="First name last name"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="department"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Department
                      </InputLabel>
                      <Select
                        labelId="department"
                        id="department"
                        value={department}
                        label="Department"
                        onChange={selectDepartment}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        required
                        id="supervisor-name"
                        label="Name of supervisor at time of injury"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Direct manager on day of accident"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        required
                        id="witness-name"
                        label="Name of Witnesses (seperate by commas):"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="John Smith, Marry Johnson, Alex Jones"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Have you notified your supervisor about this injury/near
                        miss?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby="notify-supervisor"
                          name="notifysupervisor"
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Currently on medicare?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby="medicarestatus"
                          name="medicareStatus"
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DateTimePicker
                        id="date-range"
                        label="Date & time of injury"
                        value={datevalue}
                        onChange={(newValue) => {
                          setDateValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="position"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Employee position
                      </InputLabel>
                      <Select
                        labelId="position"
                        id="position"
                        value={position}
                        label="Employee position:"
                        onChange={selectPosition}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">Select department first</MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="sposition"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Supervisor position
                      </InputLabel>
                      <Select
                        labelId="sposition"
                        id="sposition"
                        value={sposition}
                        label="Employee position:"
                        onChange={selectSpervisorPosition}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">Select department first</MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion mt={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step2-employee"
                id="step2-employee"
              >
                <Typography>Step 2: Details of the Incident</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <TextField
                        fullWidth
                        required
                        id=""
                        label="Where exactly did  the injury/near miss out?"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Be specific: name the department, floor, room or hallway"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        required
                        id=""
                        label="If you were injurded what were you doing at the moment?"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Describe the activity you were doing"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextAreaControl
                        mt={3}
                        className={classes.textAreaControl}
                      >
                        <FormControl mt={1}>
                          <InputLabel id="v-status">
                            Describe step by step how the injury/near miss
                            occured:
                          </InputLabel>
                          <TextareaAutosize placeholder="Describe surroundings and your activities leading up too the accident" />
                        </FormControl>
                      </TextAreaControl>
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Where you were wearing Shoes or Crews? (or similar)
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                    <FormControl mt={6}>
                      <TextAreaControl
                        mt={3}
                        className={classes.textAreaControl}
                      >
                        <FormControl mt={1}>
                          <InputLabel id="v-status">
                            What could have been done to prevent this
                            injury/near miss?
                          </InputLabel>
                          <TextareaAutosize placeholder="Better training, improve work training, better equipment etc." />
                        </FormControl>
                      </TextAreaControl>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button color="warning" variant="contained">
                      Mark body part
                    </Button>
                    <Image src={`/static/img/unsplash/body-part.jpg`} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion mt={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step3-employee"
                id="step3-employee"
              >
                <Typography>Step 3: Treatment for Injury</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel component="legend">
                        Did you see about this point Injury/Illness?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Has this part of your body been injured before?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion mt={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step4-employee"
                id="step4-employee"
              >
                <Typography>Step 4: Signature</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DateTimePicker
                        id="date-signed"
                        label="Date signed"
                        value={datesigned}
                        onChange={(newValue) => {
                          setDateSigned(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">Sign here</FormLabel>
                      <Box
                        mt={3}
                        sx={{ border: 1, height: 150, borderRadius: "5px" }}
                      ></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <FormLabel component="legend">
                        Did you have help translating this form?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="warning" variant="contained">
                      Clear signature
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Typography variant="h4" component="div" mt={4} mb={4} gutterBottom>
              Manager
            </Typography>
            <Box mt={4}>
              <LinearProgress
                variant="determinate"
                value={progress}
                color="success"
                sx={{ height: "6px" }}
              />
            </Box>
            <Accordion mt={5}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step1-manager"
                id="step1-manager"
              >
                <Typography>Step 1: Employee Data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} lg={8}>
                    <Grid container spacing={4} alignItems="flex-end">
                      <Grid item xs={12} md={4}>
                        <FormControl>
                          <TextField
                            fullWidth
                            required
                            id="emp-name"
                            label="Employee name"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Sandeep Kundu"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button color="warning" variant="contained">
                          Populate from EE
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl>
                          <InputLabel
                            shrink
                            id="department"
                            sx={{
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Department
                          </InputLabel>
                          <Select
                            labelId="department"
                            id="department"
                            value={department}
                            label="Department"
                            onChange={selectDepartment}
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="">Security</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <FormControl mt={3}>
                          <TextField
                            fullWidth
                            required
                            id="emp-address"
                            label="Employee address"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="165 Broadway"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl mt={3}>
                          <TextField
                            fullWidth
                            required
                            id="city"
                            label="City/locality"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="New York"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <FormControl mt={0}>
                          <DateTimePicker
                            id="dob"
                            label="Date of birth"
                            value={datevalue}
                            onChange={(newValue) => {
                              setDateValue(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm/dd/yyyy H:i:s",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl mt={0}>
                          <FormLabel component="legend">Gender:</FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby="gender"
                              name="selectgender"
                              defaultValue="M"
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="M"
                                value="1"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="F"
                                value="2"
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <FormControl mt={0}>
                          <FormLabel component="legend">Work status:</FormLabel>
                          <RadioGroup
                            aria-labelledby="workstatus"
                            name="WorkStatus"
                            defaultValue="Regular full time"
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Regular full time"
                              value="1"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Regular part time"
                              value="2"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="On call"
                              value="3"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5} alignSelf="start">
                        <FormControl mt={2}>
                          <DateTimePicker
                            id="mostRecentHireDate"
                            label="Date of most recent hire"
                            value={datevalue}
                            onChange={(newValue) => {
                              setDateValue(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm/dd/yyyy H:i:s",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <InputLabel
                            shrink
                            id="position"
                            sx={{
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Employee position
                          </InputLabel>
                          <Select
                            labelId="position"
                            id="position"
                            value={position}
                            label="Employee position:"
                            onChange={selectPosition}
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="">Director of security</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <FormControl mt={3}>
                          <TextField
                            fullWidth
                            required
                            id=""
                            label="State/administrative area"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="New York"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl mt={3}>
                          <TextField
                            fullWidth
                            required
                            id=""
                            label="Postal code"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="1006"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl mt={3}>
                          <TextField
                            fullWidth
                            required
                            id=""
                            label="Home phone number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="95602601"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl mt={3}>
                          <InputLabel
                            shrink
                            id="serviceLength"
                            sx={{
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Length of service of employee
                          </InputLabel>
                          <Select
                            labelId="serviceLength"
                            id="serviceLength"
                            value={servicelength}
                            label="Employee position:"
                            onChange={selectServiceLength}
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="">Less than 1 year</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion mt={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step2-manager"
                id="step2-manager"
              >
                <Typography>Step 2: Injury/Medical Data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <Autocomplete
                        multiple
                        id="body-part-list"
                        options={bodyPartList}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.title}
                          </li>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Body part injured (select all)"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <Autocomplete
                        multiple
                        id="nature-injury"
                        options={natureInjury}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.title}
                          </li>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nature of injury (select one or more)"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <DateTimePicker
                        id="reportedDate"
                        label="Date & time reported"
                        value={datevalue}
                        onChange={(newValue) => {
                          setDateValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={0}>
                      <FormLabel component="legend">Part of workday:</FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby="workdayPart"
                          name="PartofWorkday"
                          defaultValue="Doing normal work activities"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Entering or living work"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Doing normal work activities"
                            value="2"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="During meal period"
                            value="3"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="During break"
                            value="4"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Working overtime"
                            value="5"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Other"
                            value="6"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControl mt={3}>
                      <TextField
                        fullWidth
                        required
                        id=""
                        label="Name of employee's immediate supervisor at time of incident"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="test"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TimePicker
                      label="Time shift started"
                      name="schedule_time"
                      // onChange={(newValue) => {
                      //   setTimeValue(newValue);
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="08:40"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion mt={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step3-manager"
                id="step3-manager"
              >
                <Typography>
                  Step 3: Why did the accident/incident happen?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel component="legend">
                        Did you see about this point Injury/Illness?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Has this part of your body been injured before?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion mt={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step4-manager"
                id="step4-manager"
              >
                <Typography>
                  Step 4: How can future accident/incidents be improved?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DateTimePicker
                        id="date-signed"
                        label="Date signed"
                        value={datesigned}
                        onChange={(newValue) => {
                          setDateSigned(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">Sign here</FormLabel>
                      <Box
                        mt={3}
                        sx={{ border: 1, height: 150, borderRadius: "5px" }}
                      ></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <FormLabel component="legend">
                        Did you have help translating this form?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="warning" variant="contained">
                      Clear signature
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion mt={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step5-manager"
                id="step5-manager"
              >
                <Typography>Step 5: Preventative Action Plan</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DateTimePicker
                        id="date-signed"
                        label="Date signed"
                        value={datesigned}
                        onChange={(newValue) => {
                          setDateSigned(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">Sign here</FormLabel>
                      <Box
                        mt={3}
                        sx={{ border: 1, height: 150, borderRadius: "5px" }}
                      ></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <FormLabel component="legend">
                        Did you have help translating this form?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="warning" variant="contained">
                      Clear signature
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion mt={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="step6-manager"
                id="step6-manager"
              >
                <Typography>Step 6: Sign &amp; Review</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DateTimePicker
                        id="date-signed"
                        label="Date signed"
                        value={datesigned}
                        onChange={(newValue) => {
                          setDateSigned(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">Sign here</FormLabel>
                      <Box
                        mt={3}
                        sx={{ border: 1, height: 150, borderRadius: "5px" }}
                      ></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <FormLabel component="legend">
                        Did you have help translating this form?
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby=""
                          name=""
                          defaultValue="Yes"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="warning" variant="contained">
                      Clear signature
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}

function EditEarReports() {
  return (
    <React.Fragment>
      <Helmet title="Edit EAR Reports" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit EAR Reports
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/employee-accidents">
          Employee Accidents
        </Link>
        <Typography>Edit EAR Reports</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <EditEarReportsForm />
    </React.Fragment>
  );
}

export default EditEarReports;

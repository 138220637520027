import "./ImageGallery.css";
const AudioPlayModal = ({ audioLink, setAudioLink }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setAudioLink(null);
    }
  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <audio src={audioLink} width="750" height="500" controls></audio>
        <span className="dismiss" onClick={handleClick}>
          X
        </span>
      </div>
    </>
  );
};

export default AudioPlayModal;

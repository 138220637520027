import React, { useEffect, useState } from "react";
import axios from "axios";
import { UserEmailVerification, UserPhoneVerification } from "../../../api";
import styled from "styled-components/macro";
import { Link as MuiLink } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Typography,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const Image = styled.img`
  max-width: 100px;
  margin: 0 auto;
  width: 100%;
`;
const Link = styled(MuiLink)`
  text-decoration: none;
  color: #ffffff;
`;
function Verification() {
  const [responseMsg, setResponseMsg] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const type = urlParams.get("type");

    if (type == "email") {
      UserEmailVerification({ params: { token: token } })
        .then((res) => {
          const messageFromResponse = res.message; // Assuming the message is in the 'message' field of the response object
          setResponseMsg(messageFromResponse);
        })
        .catch((err) => {});
    }
    if (type == "phone") {
      UserPhoneVerification({ params: { token: token } })
        .then((res) => {
          const messageFromResponse = res.message; // Assuming the message is in the 'message' field of the response object
          setResponseMsg(messageFromResponse);
        })
        .catch((err) => {});
    }
  }, []);

  return (
    <>
      <Card style={{ backgroundColor: "#364a68" }}>
        <CardHeader
          sx={{ textAlign: "center", pt: 10 }}
          title={<Image src={`/static/img/brands/logo.svg`} />}
        />
        <CardContent sx={{ textAlign: "center" }}>
          <Typography
            component="p"
            variant="body2"
            sx={{ color: "#ffffff", textAlign: "center" }}
          >
            {responseMsg}
          </Typography>

          <Button
            sx={{ mt: 10 }}
            color="primary"
            variant="contained"
            component={Link}
            to="/"
          >
            Go back to Home Page
          </Button>
          <Typography
            variant="body2"
            component="div"
            gutterBottom
            mt={7}
            align="center"
            color="white"
          >
            Copyright &copy; {new Date().getFullYear()}, SOVA Systems |{" "}
            <a
              href="https://support.sovasystems.com/portal/en/newticket"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              className="verification-links"
            >
              Open a ticket
            </a>
          </Typography>
          <Box sx={{ textAlign: "center" }} mt={1}>
            <a
              href="https://support.sovasystems.com/portal/kb/articles/terms-for-user-sites"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              className="verification-links"
            >
              Use Terms
            </a>
            {<span style={{ color: "white" }}> | </span>}
            <a
              href="https://support.sovasystems.com/portal/kb/articles/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              className="verification-links"
            >
              Privacy Policy
            </a>
            {<span style={{ color: "white" }}> | </span>}
            <a
              href="https://support.sovasystems.com/portal/kb/articles/master-agreement"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              className="verification-links"
            >
              Services Agreement
            </a>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
function UserVerification() {
  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Verification />
    </React.Fragment>
  );
}
export default UserVerification;

export default [
  {
    id: "sel",
    label: "Sel",
    tooltipMsg: "Check one or more for bulk edits",
  },
  {
    id: "type",
    label: "Type",
    tooltipMsg: "",
  },
  {
    id: "equipment_name",
    label: "Name",
    tooltipMsg: "",
  },
  {
    id: "equipment_id",
    label: "Equip ID",
    tooltipMsg: "The barcode Id, except for keys",
  },
  {
    id: "key_group_id",
    label: "Key Group",
    tooltipMsg: "The name of key group (blank if no keys)",
  },
  {
    id: "key_ring_id",
    label: "Ring ID",
    tooltipMsg: "The Barcode Id for Keys",
  },
  {
    id: "current_location",
    label: "Current Location",
    tooltipMsg: "Person who has equipment",
  },
  {
    id: "active",
    label: "Active",
    tooltipMsg: "",
  },
  {
    id: "last_processed",
    label: "Last Processed",
    tooltipMsg: "Date the equipment was last checked out",
  },
  {
    id: "equipment_sl",
    label: "SL",
    tooltipMsg: "The Security Level (SL) of the equipment item",
  },
  {
    id: "disposition",
    label: "Disposition",
    tooltipMsg: "",
  },
  {
    id: "action",
    label: "Action",
    tooltipMsg: "",
  },
];

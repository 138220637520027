import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import AddSpecialVisitor from "./inner-component/AddSpecialVisitor";
import EditSpecialVisitor from "./inner-component/EditSpecialVisitor";
import {
  SpecialVisitorList,
  DeleteSpecialVisitor,
  PrintSpecialVisitor,
} from "../../api";
import { handleApiResonseErrors } from "../../helper/helper";
import CustomAlert from "../../components/form-components/CustomAlert";
import { updateShowAlert } from "../../redux/slices/mainSlice";
import { useSelector, useDispatch } from "react-redux";
import DialogBox from "../../components/form-components/DialogBox";
import SpecialVisitorListCol from "../table-columns-json/logs/SpecialVisitorList";
import { setSpecialVisitorList } from "../../redux/slices/logs/logTableColSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  Card,
  CardContent,
  TableContainer,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Pagination,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  PictureAsPdf as PictureAsPdfIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
import hasPermission from "../../utils/hasPermission";
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 12px auto 0;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);

const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  name,
  arrivaldate,
  departuredate,
  type,
  roomarea,
  caretaker,
  phone,
  specialinstruction,
  action
) {
  return {
    name,
    arrivaldate,
    departuredate,
    type,
    roomarea,
    caretaker,
    phone,
    specialinstruction,
    action,
  };
}
const rows = [
  createData(
    "Derin Redding",
    "11/09/2022",
    "11/10/2022",
    "Impaired Hearing",
    "1234",
    "No",
    "619-892-6561",
    "Some special instruction here",
    0
  ),
  createData(
    "Derin Reddy",
    "11/09/2022",
    "11/10/2022",
    "Impaired Hearing",
    "1234",
    "No",
    "619-892-6561",
    "Some special instruction here",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "name",
    numeric: true,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "arrivaldate",
    numeric: true,
    disablePadding: false,
    label: "Arrival Date",
  },
  {
    id: "departuredate",
    numeric: true,
    disablePadding: false,
    label: "Departure Date",
  },
  {
    id: "type",
    numeric: true,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "roomarea",
    numeric: true,
    disablePadding: false,
    label: "Room/Area",
  },
  {
    id: "caretaker",
    numeric: true,
    disablePadding: false,
    label: "Caretaker",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "specialinstruction",
    numeric: true,
    disablePadding: false,
    label: "Special Instruction",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox"></TableCell> */}
        {SpecialVisitorListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "action" ? null : createSortHandler(headCell.id)
              }
              hideSortIcon={headCell.id == "action" ? true : false}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, showAlertModal, getList, tableCalRedux } = props;
  const [actions, setActions] = React.useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.specialVisitorList
  );
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  // Add Special Visitor Dialog
  const [addSpecialVisitorDialog, setAddSpecialVisitorDialog] = useState(false);
  const addSpecialVisitorDialogOpen = () => {
    setAddSpecialVisitorDialog(true);
  };
  const addSpecialVisitorDialogClose = () => {
    setAddSpecialVisitorDialog(false);
  };

  /**
   * It calls the PrintEmergencyReport() function, which returns a promise. If the promise is resolved,
   * it creates a link element, sets the href attribute to the base64 encoded pdf, sets the download
   * attribute to the name of the file, and clicks the link
   */
  const onButtonClick = () => {
    setLoading(true);
    PrintSpecialVisitor()
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-SPECIAL-VISITORS.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          dispatch(getMsg("No special visitors to report"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      <Toolbar>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {numSelected} records found
          </Typography>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <WithPermissionHide controller="Logs" action="addSpecialVisitor">
                <MenuItem onClick={addSpecialVisitorDialogOpen}>
                  <ListItemIcon>
                    <AddBoxIcon />
                  </ListItemIcon>
                  <ListItemText
                    size="large"
                    primary="Add Special Visitor"
                    pl={0}
                  />
                </MenuItem>
              </WithPermissionHide>
              <WithPermissionHide
                controller="Logs"
                action="printSpecialVisitor"
              >
                <MenuItem onClick={onButtonClick}>
                  <ListItemIcon>
                    <PictureAsPdfIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Download PDF" pl={0} />
                </MenuItem>
              </WithPermissionHide>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setSpecialVisitorList(data));
                      }}
                      listing={SpecialVisitorListCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setSpecialVisitorList(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      {addSpecialVisitorDialog && (
        <AddSpecialVisitor
          addSpecialVisitorDialog={addSpecialVisitorDialog}
          setAddSpecialVisitorDialog={setAddSpecialVisitorDialog}
          addSpecialVisitorDialogOpen={addSpecialVisitorDialogOpen}
          addSpecialVisitorDialogClose={addSpecialVisitorDialogClose}
          showAlertModal={(type, msg) => showAlertModal(type, msg)}
          getList={() => getList()}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};
function EnhancedTable(props) {
  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const showAlertText = useSelector((state) => state.main.text);
  // const tableCalRedux = useSelector(
  //   (state) => state.employeeTableCal.employeeListing
  // );
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const [selectedId, setSelectedId] = useState("");

  // for listing
  const [lists, setLists] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordinDb, setTotalRecordinDb] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("color");
  const [loading, setLoading] = useState(false);
  // Alert Message
  const [alertMessage, setAlertMessage] = useState(showAlertText);
  const [alertType, setAlertType] = useState("success");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const tableCalRedux = useSelector(
    (state) => state.logTableCol.specialVisitorList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // Edit Special Visitor Dialog
  const [editSpecialVisitorDialog, setEditSpecialVisitorDialog] =
    useState(false);
  const editSpecialVisitorDialogOpen = () => {
    setEditSpecialVisitorDialog(true);
    handleActionClose();
  };
  const editSpecialVisitorDialogClose = () => {
    setEditSpecialVisitorDialog(false);
  };

  const showAlertModal = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    getList();
    setTimeout(() => {
      dispatch(updateShowAlert(false));
    }, 3000);
  }, [rowsPerPage, currentPage, order, orderBy]);

  const getList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
    };
    SpecialVisitorList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLists(res.data);
          setTotalRecordinDb(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setLists([]);
          setTotalRecordinDb(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          showAlertModal("error", error);
        }
      });
  };

  const removeRecords = () => {
    if (selectedId) {
      setModalMessage("Are you sure you want to delete?");
    } else {
      setModalMessage("You haven't selected any item!");
    }
    handleActionClose();
    setShowDeleteModal(true);
  };

  const handelDeleteAction = (status) => {
    if (status === true && selectedId) {
      setLoading(true);
      DeleteSpecialVisitor(selectedId)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            getList();
          }
          showAlertModal("success", res.message);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setShowDeleteModal(false);
    }
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={totalRecordinDb}
          showAlertModal={(type, msg) => showAlertModal(type, msg)}
          getList={() => getList()}
          tableCalRedux={tableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={10} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {lists.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox"></TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.name === true ? "" : "hide-cell"
                        }
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.arrival_date === true ? "" : "hide-cell"
                        }
                      >
                        {row.arrival_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.departure_date === true
                            ? ""
                            : "hide-cell"
                        }
                      >
                        {row.departure_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.visitor_type === true ? "" : "hide-cell"
                        }
                      >
                        {row.visitor_type}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.room_area === true ? "" : "hide-cell"
                        }
                      >
                        {row.room_area}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.caretaker === true ? "" : "hide-cell"
                        }
                      >
                        {row.caretaker}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.phone === true ? "" : "hide-cell"
                        }
                      >
                        {row.phone}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.special_instructions === true
                            ? ""
                            : "hide-cell"
                        }
                      >
                        {row.special_instructions}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.action === true ? "" : "hide-cell"
                        }
                      >
                        <IconButton
                          aria-label="Settings"
                          size="large"
                          aria-owns={anchorEl ? "icon-menu" : undefined}
                          aria-haspopup="true"
                          onClick={(evt) => handleActionClick(evt, row.id)}
                          style={{ color: "#1374C5" }}
                        >
                          <SettingsIcon />
                        </IconButton>
                        <StyledMenu
                          id="icon-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleActionClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <WithPermissionHide
                            controller="Logs"
                            action="editSpecialVisitor"
                          >
                            <MenuItem onClick={editSpecialVisitorDialogOpen}>
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                          </WithPermissionHide>
                          <MenuItem
                            disabled={
                              !hasPermission("Logs", "deleteSpecialVisitor")
                            }
                            onClick={() => removeRecords()}
                          >
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delete" pl={1} />
                          </MenuItem>
                        </StyledMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {lists.length === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * lists.length }}>
                    <TableCell colSpan={10} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecordinDb / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={lists.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* {editSpecialVisitorDialog && (
        <EditSpecialVisitor
          editSpecialVisitorDialog={editSpecialVisitorDialog}
          setEditSpecialVisitorDialog={setEditSpecialVisitorDialog}
          editSpecialVisitorDialogOpen={editSpecialVisitorDialogOpen}
          editSpecialVisitorDialogClose={editSpecialVisitorDialogClose}
        />
      )} */}
      {editSpecialVisitorDialog && (
        <AddSpecialVisitor
          addSpecialVisitorDialog={editSpecialVisitorDialog}
          setAddSpecialVisitorDialog={setEditSpecialVisitorDialog}
          addSpecialVisitorDialogOpen={editSpecialVisitorDialogOpen}
          addSpecialVisitorDialogClose={editSpecialVisitorDialogClose}
          showAlertModal={(type, msg) => showAlertModal(type, msg)}
          getList={() => getList()}
          id={selectedId}
        />
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      <DialogBox
        handleShow={showDeleteModal}
        message={modalMessage}
        conformation={(status) => handelDeleteAction(status)}
      />
    </div>
  );
}

function SpecialVisitors() {
  return (
    <React.Fragment>
      <Helmet title="Special Visitors" />
      <WithPermissionFallback controller="Logs" action="specialVisitorList">
        <Typography variant="h3" gutterBottom display="inline">
          Special Visitors
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default SpecialVisitors;

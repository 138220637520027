import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { sova } from "../../../config";
import { LocationLatLong } from "../../../api";
const PoiEncountorLocation = ({
  locationDialog,
  locationDialogClose,
  getCoordinates,
  setCoordinates,
  coordinates,
}) => {
  // const locationLatLong = () => {
  //   LocationLatLong()
  //     .then((res) => {
  //       if (res.statusCode === 200) {
  //         if (coordinates == {}) {
  //           setCoordinates({
  //             lat: parseFloat(res.data.lat),
  //             lng: parseFloat(res.data.long),
  //           });
  //         }
  //       } else {
  //         console.log("err");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   locationLatLong();
  // }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
  });

  const handleMarkerDragEnd = ({ latLng }) => {
    setCoordinates({ lat: latLng.lat(), lng: latLng.lng() });
    getCoordinates({ lat: latLng.lat(), lng: latLng.lng() });
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps...";
  return (
    <>
      <Dialog
        open={locationDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="locationDialog-title"
        aria-describedby="locationDialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="locationDialog-title"
        >
          <Typography variant="h4" component="div">
            Location of POI Encounter
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={locationDialogClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <GoogleMap
            id="map"
            mapContainerStyle={{
              height: "400px",
              width: "100%",
            }}
            zoom={25}
            center={coordinates}
            options={{ mapTypeId: "satellite" }}
          >
            <Marker
              position={coordinates}
              draggable
              onDragEnd={handleMarkerDragEnd}
            />
          </GoogleMap>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PoiEncountorLocation;

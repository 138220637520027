import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Typography,
  Button,
  IconButton,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Checkbox,
  Box,
  FormControl,
  TableSortLabel,
  Toolbar,
  FormLabel,
  Grid,
  Card,
  FormGroup,
  FormControlLabel,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { height } from "@mui/system";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { SendEarEmailList, SendEarReport } from "../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
const Spacer = styled.div`
  flex: 1 1 100%;
`;
function createData(name, email) {
  return { name, email };
}

const rows = [
  createData("Nidi sharma", "me@you.com"),
  createData("Nidi sharma 1", "me@you.com"),
  createData("Nidi sharma2", "me@you.com"),
  createData("Nidi sharma3", "me@you.com"),
  createData("Nidi sharma4", "me@you.com"),
  createData("Nidi sharma5", "me@you.com"),
  createData("Nidi sharma6", "me@you.com"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected } = props;

  return (
    <Toolbar>
      <div>
        <Typography
          color="inherit"
          variant="subtitle1"
          sx={{ whiteSpace: "noWrap" }}
        >
          {numSelected} selected
        </Typography>
      </div>
      <Spacer />
    </Toolbar>
  );
};

function EnhancedTable(props) {
  const { selected, setSelected } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  const [emailList, setEmailList] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = emailList.map((n) => n.comm_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  console.log("Selecteds", selected);

  // Method for emails list
  const getUsersEmail = () => {
    let payload = {
      type: "send_ear",
    };
    setDialogLoader(true);
    SendEarEmailList(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setEmailList(res.data);
        } else {
          setEmailList([]);
        }
        setDialogLoader(false);
      })
      .catch((err) => {
        setDialogLoader(false);
        setEmailList([]);
        console.log(err);
      });
  };
  useEffect(() => {
    getUsersEmail();
  }, []);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
          clasName="tableContainer"
          sx={{
            maxHeight: "250px",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#F7F9FC",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#f3f3f3",
              borderRadius: 2,
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={emailList.length}
            />
            {dialogLoader ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(emailList, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.comm_id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.comm_id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.comm_id)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell id={labelId} scope="row" padding="none">
                          {row.full_name}
                        </TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                      </TableRow>
                    );
                  })}
                {emailList.length === 0 && (
                  <TableRow style={{ height: dense ? 6 : 0 }}>
                    <TableCell colSpan={10} align="center">
                      No communication records found! Add in Settings -
                      Communication
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
const SendEar = (props) => {
  const [selected, setSelected] = React.useState([]);
  const {
    sendModal,
    setSendModal,
    sendModalOpen,
    sendModalClose,
    recordId,
    handleSendCloseForm,
  } = props;
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const dispatch = useDispatch();
  // Add location on map
  const [addLocationOnMap, setAddLocationOnMap] = useState(false);
  const addLocationOnMapOpen = (event) => {
    setAddLocationOnMap(event.target.checked);
  };

  const initialValues = {
    inputEmail: LoggedInUserInfo.Email ? LoggedInUserInfo.Email : "",
  };

  const validationSchema = Yup.object().shape({
    inputEmail: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter email address"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    handleSendCloseForm();
    console.log(values);
    let payload = {
      sr_id: recordId,
      comm_id: selected.toString(),
      sendPdf: addLocationOnMap == true ? "1" : "0",
      reply_to: values.inputEmail,
    };
    SendEarReport(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(
            getMsg("EAR report has been sent to the selected recipients")
          );
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          sendModalClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          sendModalClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        sendModalClose();
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  return (
    <>
      {" "}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={sendModal}
        onClose={sendModalClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <DialogTitle
            id="viewmap-title"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h3" component="div" gutterBottom>
                Send EAR Report
              </Typography>
              <Typography>
                Please select one or more names from the list below to send the
                report(s) to
              </Typography>
            </Grid>
            <DialogActions>
              <IconButton
                sx={{
                  marginLeft: "25px",
                }}
                onClick={() => {
                  setSendModal(false);
                  handleSendCloseForm();
                }}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
        </Grid>
        <DialogContent>
          <Card variant="outlined" sx={{ borderBottom: 0 }}>
            <EnhancedTable selected={selected} setSelected={setSelected} />
          </Card>
          <Paper sx={{ marginTop: "15px", marginBottom: "15px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      sx={{ marginTop: "15px", marginBottom: "15px" }}
                      name="inputEmail"
                      value={values.inputEmail}
                      error={Boolean(touched.inputEmail && errors.inputEmail)}
                      fullWidth
                      helperText={touched.inputEmail && errors.inputEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      autoComplete="off"
                      id="reply-to"
                      label="Reply to *"
                      // sx={{ input: { color: "red" } }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="me@you.com"
                    />
                  </Grid>

                  <FormControlLabel
                    control={
                      <Checkbox
                        disableRipple
                        checked={addLocationOnMap}
                        onChange={addLocationOnMapOpen}
                      />
                    }
                    label="Send PDF"
                  />
                  <Box sx={{ display: "flex" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      // onClick={() => setSendDar(false)}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={selected.length < 1}
                      sx={{ marginLeft: "10px" }}
                    >
                      Send EAR
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Paper>
          {/* <Typography variant="body2" gutterBottom mt={4}>
            Reply to
          </Typography>
          <Grid item xl={12}>
            <TextField
              variant="outlined"
              id="outlined-basic"
              placeholder="dscgdgc@gmail.com"
              sx={{
                width: "100%",
              }}
            />
          </Grid> */}
        </DialogContent>

        {/* <DialogActions
          sx={{
            padding: "20px 24px",
          }}
        >
          <FormGroup
            sx={{
              marginRight: "auto",
            }}
          >
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Send pdf"
            />
          </FormGroup>

          <Button variant="outlined" color="primary" onClick={sendModalClose}>
            Close
          </Button>
          <Button variant="contained">Send Ear</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default SendEar;

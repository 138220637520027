export default [
  {
    id: "lost_id",
    label: "Lost ID",
  },
  {
    id: "found_id",
    label: "Stored ID",
  },
  {
    id: "point",
    label: "Points",
  },
  {
    id: "match",
    label: "Matched Rule",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextareaAutosize as MuiTextareaAutosize,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { spacing } from "@mui/system";
import {
  SendPhoneOtp,
  VerifyPhoneOtp,
  SaveKeyForm,
  DynamicKey,
} from "../../api";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Formik } from "formik";
import { useParams } from "react-router-dom"; // Import useParams

const Image = styled.img``;

const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 80px !important;
  background: white;
  padding: 6px;
  font-size: 15px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
  width: 98%;
`;

const KeyForm = () => {
  const { dynamicKey } = useParams(); // Get dynamic key from URL
  const navigate = useNavigate();
  const [keyMatched, setKeyMatched] = useState(null);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [department, setDepartment] = useState("");
  const [unitNo, setUnitNo] = useState("");
  const [reportingAdd, setReportingAdd] = useState("");
  const [appearance, setAppearance] = useState("");
  const [personDescription, setPersonDescription] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [otherDesposition, setOtherDesposition] = React.useState("");
  const [facialHair, setFacialHair] = useState("");
  const [vehicleDescription, setVehicleDescription] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [stateDigit, setStateDigit] = useState("");
  const [describeHazard, setDescribeHazard] = useState("");
  const [reportingDetails, setReportingDetails] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [hazardLocation, setHazardLocation] = useState("");
  const [thingImage, setThingImage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [verified, setVerified] = useState(false);
  const [imageName, setImageName] = useState("No file chosen");
  const [desposition, setDesposition] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputsRef = useRef([]);
  const [type, setType] = useState("");
  const [reportingLoc, setReportingLoc] = useState("");
  const [describeThing, setDescribeThing] = useState("");
  const [reportingAbout, setReportingAbout] = useState("");
  const [shift, setShift] = useState("");
  const [currentloc, setCurrentloc] = useState("");
  const [secure, setSecure] = useState("");
  const [involved, setInvolved] = useState("");
  const [knowPerson, setKnowPerson] = useState("");
  const [age, setAge] = useState("");
  const [open, setOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [selectedValue, setSelectedValue] = useState("No");
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value === "Yes") {
      setLocationDialogOpen(true);
    }
  };
  const handleLocationPermission = () => {
    // Close dialog
    setLocationDialogOpen(false);

    // Request user’s location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location", error);
          alert("Unable to retrieve location. Please try again.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const [errors, setErrors] = useState({
    type: "",
    name: "",
    reportingLoc: "",
    reportingAbout: "",
    phoneNo: "",
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setThingImage(file); // Set the image file
      setImageName(file.name); // Set the image name to display
    }
  };

  const handleClickOpen = async () => {
    const formattedPhoneNo = phoneNo.startsWith("+91")
      ? phoneNo
      : `+91${phoneNo}`;
    const payload = { phone: formattedPhoneNo };

    try {
      await SendPhoneOtp(payload); // Call SendPhoneOtp with the payload object
      setOpen(true); // Open the dialog
      setOtpSent(true); // Mark OTP as sent
      setOtpError(""); // Clear any previous error
    } catch (error) {
      setOtpError("Failed to send OTP. Please try again.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVerifyOtp = async () => {
    const enteredOtp = otp.join(""); // Join OTP digits into a single string
    const formattedPhoneNo = phoneNo.startsWith("+91")
      ? phoneNo
      : `+91${phoneNo}`;
    const payload = { phone: formattedPhoneNo, otp: enteredOtp };

    try {
      const response = await VerifyPhoneOtp(payload); // Call VerifyPhoneOtp API with the payload

      // Check the 'statusCode' or 'status' instead of 'success'
      if (response.statusCode === 200 && response.status === "success") {
        setVerified(true); // Mark the phone number as verified
        setOpen(false); // Close the dialog
      } else {
        setOtpError("Incorrect OTP. Please try again.");
      }
    } catch (error) {
      setOtpError("Error verifying OTP. Please try again.");
    }
  };
  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    // if (!verified) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     phoneNo: "Please verify your phone number",
    //   }));
    //   return; // Stop submission if phone number is not verified
    // }

    const formattedPhoneNo = phoneNo.startsWith("+91")
      ? phoneNo
      : `+91${phoneNo}`;

    // Create FormData to handle the file upload
    const formData = new FormData();
    formData.append("type", type || "Visitor");
    formData.append("name", name);
    formData.append("address", unitNo || "");
    formData.append("located_at_home", currentloc);
    formData.append("is_property_secure", secure);
    formData.append("company_name", companyName);
    formData.append("department", department);
    formData.append("shift", shift);
    formData.append("phone", formattedPhoneNo);
    formData.append("reporting_location", reportingLoc);
    formData.append("reporting_address", reportingAdd);
    formData.append("reporting_about", reportingAbout);
    formData.append("person_involved_in", involved);
    formData.append("know_person_involved", knowPerson);
    formData.append("thing_description", describeThing || "No things involved");
    if (selectedValue === "Yes") {
      formData.append("reporting_user_lat", latitude);
      formData.append("reporting_user_long", longitude);
    }

    // If an image file was selected, append it to the form data
    if (thingImage) {
      formData.append("thing_image", thingImage);
    }

    try {
      const response = await SaveKeyForm(formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        },
      });

      if (response && response.statusCode === 200) {
        setFormSubmitted(true); // Mark form as submitted successfully
      } else {
        console.error("Form submission did not return a successful status.");
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };
  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/\d/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input if the current input is filled
      if (index < 5 && value) {
        inputsRef.current[index + 1].focus();
      }
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    }
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      type: "",
      name: "",
      reportingLoc: "",
      reportingAbout: "",
      phoneNo: "",
    };

    if (!type) {
      newErrors.type = "Type is required";
      isValid = false;
    }

    if (!name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!reportingLoc) {
      newErrors.reportingLoc = "Please select indoor or outdoor";
      isValid = false;
    }

    if (!reportingAbout) {
      newErrors.reportingAbout =
        "Please select if reporting about person or things";
      isValid = false;
    }

    if (!phoneNo) {
      newErrors.phoneNo = "Phone number is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  useEffect(() => {
    // Call DynamicKey API to check if the key matches
    const validateKey = async () => {
      try {
        const payload = { key: dynamicKey };
        const response = await DynamicKey(payload);

        if (response.StatusCode === 200 && response.status === "success") {
          setKeyMatched(true); // Show the form if key is matched
        } else {
          setKeyMatched(false); // Show error message if key is not matched
        }
      } catch (error) {
        console.error("Error validating key:", error);
        setKeyMatched(false);
      }
    };

    if (dynamicKey) {
      validateKey();
    }
  }, [dynamicKey]);

  return (
    <>
      <div
        style={{
          backgroundImage: "url('/static/img/brands/back.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          overflowY: "auto",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Image
          src={`/static/img/brands/logo.svg`}
          style={{
            height: 50,
            marginLeft: 0,
            marginBottom: "30px",
            marginTop: "30px",
          }}
        />
        {keyMatched === null ? (
          <CircularProgress />
        ) : keyMatched === true ? (
          formSubmitted ? ( // Show thank-you message if form is submitted
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                borderRadius: "8px",
                maxWidth: "600px",
                padding: "20px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="h6"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                Thank you for your submission. If you provided your mobile
                number, you will receive text message updates. For urgent
                matters, please call 9970959570 for immediate assistance.
              </Typography>
            </Box>
          ) : (
            <Formik>
              <Box
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  borderRadius: "8px",
                  maxWidth: "600px",
                  padding: "20px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  marginBottom: "20px",
                }}
              >
                <Typography variant="body1" className="f-16">
                  What is your connection to the area where you're making this
                  report?
                </Typography>
                <Select
                  fullWidth
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  sx={{ marginTop: "5px", backgroundColor: "white" }}
                >
                  <MenuItem value="">Choose</MenuItem>
                  <MenuItem value="Visitor">I'm a visitor</MenuItem>
                  <MenuItem value="Employee">I work there</MenuItem>
                  <MenuItem value="Resident">I live there</MenuItem>
                </Select>
                {errors.type && (
                  <Typography color="error">{errors.type}</Typography>
                )}

                {/* Work-related Fields */}
                {type === "Employee" && (
                  <Box sx={{ paddingLeft: "30px" }}>
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Company Name:
                    </Typography>
                    <TextField
                      placeholder="Enter company name"
                      variant="outlined"
                      fullWidth
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    />
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Department:
                    </Typography>
                    <TextField
                      placeholder="Enter department"
                      variant="outlined"
                      fullWidth
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    />
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Typical Work Shift:
                    </Typography>
                    <Select
                      fullWidth
                      value={shift}
                      onChange={(e) => setShift(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    >
                      <MenuItem value="">Choose</MenuItem>
                      <MenuItem value="1">Morning</MenuItem>
                      <MenuItem value="2">Afternoon</MenuItem>
                      <MenuItem value="3">Evening</MenuItem>
                    </Select>
                  </Box>
                )}

                {/* Resident-related Fields */}
                {type === "Resident" && (
                  <Box sx={{ paddingLeft: "30px" }}>
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Unit Number or Street Address:
                    </Typography>
                    <TextField
                      placeholder="Enter unit number or street address"
                      variant="outlined"
                      fullWidth
                      value={unitNo}
                      onChange={(e) => setUnitNo(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    />
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Are you currently located in your home?
                    </Typography>
                    <Select
                      fullWidth
                      value={currentloc}
                      onChange={(e) => setCurrentloc(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    >
                      <MenuItem value="">Choose</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Is your property secure?
                    </Typography>
                    <Select
                      fullWidth
                      value={secure}
                      onChange={(e) => setSecure(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    >
                      <MenuItem value="">Choose</MenuItem>
                      <MenuItem value="Yes">Yes my property is secure</MenuItem>
                      <MenuItem value="No">No, please send an officer</MenuItem>
                    </Select>
                  </Box>
                )}
                <Typography
                  variant="body1"
                  className="f-16"
                  sx={{ marginTop: "10px" }}
                >
                  Name:
                </Typography>
                <TextField
                  placeholder="Enter name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ marginTop: "5px", backgroundColor: "white" }}
                />
                {errors.name && (
                  <Typography color="error">{errors.name}</Typography>
                )}

                {/* General Form */}
                <Typography
                  variant="body1"
                  className="f-16"
                  sx={{ marginTop: "10px" }}
                >
                  Are you reporting something that occurred indoors or outdoors?
                </Typography>
                <Select
                  required
                  fullWidth
                  value={reportingLoc}
                  onChange={(e) => setReportingLoc(e.target.value)}
                  sx={{ marginTop: "5px", backgroundColor: "white" }}
                >
                  <MenuItem value="">Choose</MenuItem>
                  <MenuItem value="Outdoor">Outdoors</MenuItem>
                  <MenuItem value="Indoor">Indoors</MenuItem>
                </Select>
                {errors.reportingLoc && (
                  <Typography color="error">{errors.reportingLoc}</Typography>
                )}
                {reportingLoc === "Indoor" && (
                  <Box sx={{ paddingLeft: "30px" }}>
                    <TextField
                      sx={{ marginTop: "10px", backgroundColor: "white" }}
                      placeholder="Address, detailed location"
                      variant="outlined"
                      fullWidth
                      value={reportingAdd}
                      onChange={(e) => setReportingAdd(e.target.value)}
                    />
                  </Box>
                )}

                <Typography
                  variant="body1"
                  className="f-16"
                  sx={{ marginTop: "10px" }}
                >
                  Does your reporting involve persons or things?
                </Typography>
                <Select
                  fullWidth
                  value={reportingAbout}
                  onChange={(e) => setReportingAbout(e.target.value)}
                  sx={{ marginTop: "5px", backgroundColor: "white" }}
                >
                  <MenuItem value="">Choose</MenuItem>
                  <MenuItem value="Person">Person</MenuItem>
                  <MenuItem value="Thing">Thing</MenuItem>{" "}
                  {/* Ensure the correct value is used */}
                </Select>
                {errors.reportingAbout && (
                  <Typography color="error">{errors.reportingAbout}</Typography>
                )}

                {/* Reporting Person-related Fields */}
                {reportingAbout === "Person" && (
                  <Box sx={{ paddingLeft: "30px" }}>
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      What was the person involved in?
                    </Typography>
                    <Select
                      fullWidth
                      value={involved}
                      onChange={(e) => setInvolved(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    >
                      <MenuItem value="">Choose</MenuItem>
                      <MenuItem value="drugs">Active drug use</MenuItem>
                      <MenuItem value="transient">Loitering/transient</MenuItem>
                      <MenuItem value="noise">
                        Noise (music/TV, equipment, voices)
                      </MenuItem>
                      <MenuItem value="fighting">
                        Physical abuse on a person, fighting, etc.
                      </MenuItem>
                      <MenuItem value="activity">Suspicious activity</MenuItem>
                    </Select>
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Do you know any of the persons involved?
                    </Typography>
                    <Select
                      fullWidth
                      value={knowPerson}
                      onChange={(e) => setKnowPerson(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    >
                      <MenuItem value="">Choose</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>{" "}
                      {/* Ensure this value is "Yes" or "No" */}
                    </Select>
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Physical description of person(s)
                    </Typography>
                    <TextareaAutosize
                      value={personDescription}
                      onChange={(e) => setPersonDescription(e.target.value)}
                      fullWidth
                      placeholder="White male, tall, thin, etc."
                    />
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Hair color/length
                    </Typography>
                    <TextField
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                      placeholder="Short, brown, curly, etc."
                      variant="outlined"
                      fullWidth
                      value={hairColor}
                      onChange={(e) => setHairColor(e.target.value)}
                    />
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Age
                    </Typography>
                    <Select
                      fullWidth
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    >
                      <MenuItem value="">Choose</MenuItem>
                      <MenuItem value="Minor">Minor</MenuItem>
                      <MenuItem value="20s">20s</MenuItem>
                      <MenuItem value="30s">30s</MenuItem>
                      <MenuItem value="40s">40s</MenuItem>
                      <MenuItem value="Over 50">Over 50</MenuItem>
                    </Select>
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Facial hair
                    </Typography>
                    <TextField
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                      placeholder="None, beard, chin hair, etc."
                      variant="outlined"
                      fullWidth
                      value={facialHair}
                      onChange={(e) => setFacialHair(e.target.value)}
                    />
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Clothing/hat
                    </Typography>
                    <TextField
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                      placeholder="Black hoodie, jeans, sneakers, etc."
                      variant="outlined"
                      fullWidth
                      value={appearance}
                      onChange={(e) => setAppearance(e.target.value)}
                    />
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Disposition
                    </Typography>
                    <Select
                      fullWidth
                      value={desposition}
                      onChange={(e) => setDesposition(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    >
                      <MenuItem value="">Choose</MenuItem>
                      <MenuItem value="aggressive">Aggressive</MenuItem>
                      <MenuItem value="alert">Alert</MenuItem>
                      <MenuItem value="intoxicated">Intoxicated</MenuItem>
                      <MenuItem value="sleeping">Sleeping</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {/* Other Disposition */}
                    {desposition === "other" && (
                      <Box>
                        <Typography variant="body1" sx={{ marginTop: "10px" }}>
                          Other Disposition
                        </Typography>
                        <TextField
                          sx={{ marginTop: "5px", backgroundColor: "white" }}
                          placeholder="Provide details"
                          variant="outlined"
                          fullWidth
                          value={otherDesposition}
                          onChange={(e) => setOtherDesposition(e.target.value)}
                        />
                      </Box>
                    )}
                  </Box>
                )}

                {/* Reporting Things-related Fields */}
                {reportingAbout === "Thing" && (
                  <Box>
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Describe the thing:
                    </Typography>
                    <Select
                      fullWidth
                      value={describeThing}
                      onChange={(e) => setDescribeThing(e.target.value)}
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                    >
                      <MenuItem value="">Choose</MenuItem>
                      <MenuItem value="car">Abandoned Car</MenuItem>
                      <MenuItem value="hazard">Safety Hazard</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {/* Car Details */}
                    {describeThing === "car" && (
                      <Box sx={{ paddingLeft: "30px" }}>
                        <TextField
                          sx={{ marginTop: "10px", backgroundColor: "white" }}
                          placeholder="Vehicle Description"
                          variant="outlined"
                          fullWidth
                          value={vehicleDescription}
                          onChange={(e) =>
                            setVehicleDescription(e.target.value)
                          }
                        />
                        <TextField
                          sx={{ marginTop: "10px", backgroundColor: "white" }}
                          placeholder="License Plate"
                          variant="outlined"
                          fullWidth
                          value={licensePlate}
                          onChange={(e) => setLicensePlate(e.target.value)}
                        />
                        <TextField
                          sx={{ marginTop: "10px", backgroundColor: "white" }}
                          placeholder="State (2 digit)"
                          variant="outlined"
                          fullWidth
                          value={stateDigit}
                          onChange={(e) => setStateDigit(e.target.value)}
                        />
                      </Box>
                    )}
                    {/* Hazard Details */}
                    {describeThing === "hazard" && (
                      <Box sx={{ paddingLeft: "30px" }}>
                        <TextField
                          sx={{ marginTop: "10px", backgroundColor: "white" }}
                          placeholder="Describe hazard"
                          variant="outlined"
                          fullWidth
                          value={describeHazard}
                          onChange={(e) => setDescribeHazard(e.target.value)}
                        />
                        <TextField
                          sx={{ marginTop: "10px", backgroundColor: "white" }}
                          placeholder="Hazard location"
                          variant="outlined"
                          fullWidth
                          value={hazardLocation}
                          onChange={(e) => setHazardLocation(e.target.value)}
                        />
                      </Box>
                    )}
                    {/* Other Description */}
                    {describeThing === "other" && (
                      <Box sx={{ paddingLeft: "30px" }}>
                        <TextField
                          sx={{ marginTop: "10px", backgroundColor: "white" }}
                          placeholder="Provide details"
                          variant="outlined"
                          fullWidth
                          value={reportingDetails}
                          onChange={(e) => setReportingDetails(e.target.value)}
                        />
                      </Box>
                    )}
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Tap to open camera or choose saved image
                    </Typography>
                    <Box
                      sx={{
                        padding: "10px",
                        border: "1px solid #3498db",
                        borderRadius: "4px",
                        marginTop: "5px",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange} // Handle file selection
                      />
                      <Typography>{imageName}</Typography>
                    </Box>
                  </Box>
                )}

                {/* SMS and Location Capture */}
                <Typography
                  variant="body1"
                  className="f-16"
                  sx={{ marginTop: "10px" }}
                >
                  Receive SMS updates on this report by providing your mobile
                  number:
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <TextField
                    placeholder="xxx-xxx-xxxx"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "5px", backgroundColor: "white" }}
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    disabled={verified}
                  />
                  <div>
                    {!verified && (
                      <Button
                        type="button"
                        variant="contained"
                        sx={{ padding: "6px 9px", whiteSpace: "nowrap" }}
                        onClick={handleClickOpen}
                        disabled={otpSent}
                      >
                        Send otp
                      </Button>
                    )}
                    {verified && (
                      <Typography variant="body1" sx={{ color: "green" }}>
                        Verified
                      </Typography>
                    )}
                  </div>
                </Box>
                {errors.phoneNo && (
                  <Typography color="error">{errors.phoneNo}</Typography>
                )}

                <Typography
                  variant="body1"
                  className="f-16"
                  sx={{ marginTop: "10px" }}
                >
                  May we capture your device's location to get a more accurate
                  location where your reported issue occurred? No other device
                  data is collected.
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="No"
                    name="radio-buttons-group"
                    sx={{ gap: "10px", flexDirection: "row" }}
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>

                  {selectedValue === "Yes" && (
                    <div style={{ marginTop: "10px", width: "100%" }}>
                      <Typography variant="body1">Longitude</Typography>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Longitude"
                        fullWidth
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                        sx={{ marginTop: "5px", backgroundColor: "white" }}
                      />
                      <Typography variant="body1" sx={{ marginTop: "10px" }}>
                        Latitude
                      </Typography>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Latitude"
                        fullWidth
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                        sx={{ marginTop: "5px", backgroundColor: "white" }}
                      />
                    </div>
                  )}
                </FormControl>

                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button variant="contained" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Formik>
          )
        ) : (
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "8px",
              maxWidth: "600px",
              padding: "20px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              marginBottom: "20px",
            }}
          >
            <Typography color="error" variant="h6">
              Key not matched. It is case-sensitive.
            </Typography>
          </Box>
        )}
      </div>

      {/* OTP Verification Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please enter otp to verify your number
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            mt={3}
          >
            {otp.map((digit, index) => (
              <TextField
                key={index}
                inputRef={(el) => (inputsRef.current[index] = el)}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: "bold",
                  },
                }}
                variant="outlined"
                sx={{
                  width: "50px",
                  height: "60px",
                }}
              />
            ))}
          </Box>
          <Typography variant="body2" sx={{ marginTop: "10px", color: "red" }}>
            {otpError}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" type="button">
            Close
          </Button>
          <Button type="button" variant="contained" onClick={handleVerifyOtp}>
            Verify
          </Button>
        </DialogActions>
      </Dialog>
      {/* Live location Dialog */}
      <Dialog
        open={locationDialogOpen}
        onClose={() => setLocationDialogOpen(false)}
      >
        <DialogTitle>Location Access</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Please allow your browser to access your current location.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setLocationDialogOpen(false)}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleLocationPermission}
            color="primary"
            autoFocus
            variant="contained"
          >
            Turn On
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KeyForm;

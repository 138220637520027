import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checked: [],
  snackStatus: false,
  msg: "",
};

// export const fetchCorporate = createAsyncThunk(
//   "corporate/fetchCorporate",
//   () => {
//     return axios
//       .get("corporate-column-list")
//       .then((res) => res.data.data.default_fields);
//   }
// );

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    getChecked: (state, action) => {
      state.checked = action.payload;
    },
    getSnackOpen: (state) => {
      state.snackStatus = true;
    },
    getSnackClose: (state) => {
      state.snackStatus = false;
    },
    getMsg: (state, action) => {
      state.msg = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(fetchCorporate.fulfilled, (state, action) => {
  //     state.checked = action.payload;
  //   });
  //   builder.addCase(fetchCorporate.rejected, (state, action) => {
  //     state.checked = [];
  //   });
  // },
});

export const { getChecked, getSnackOpen, getSnackClose, getMsg } =
  taskSlice.actions;
export default taskSlice.reducer;

import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
function UserReceipt() {
  return (
    <>
      <Grid container spacing={4} mt={5}>
        <Grid item lg={6}>
          <Box sx={{ border: "1px solid gray", padding: "20px" }}>
            <Typography
              variant="h3"
              sx={{
                color: "#376fd0",
              }}
            >
              We're Shipping this:
            </Typography>
            <Typography>
              <strong>Item category:</strong> Cash
            </Typography>
            <Typography>
              <strong>Description:</strong> Testing test
            </Typography>
            <Box sx={{ float: "right", marginBottom: "40px" }}>
              <img src="" alt="img" />
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid gray",
              padding: "20px",
              marginTop: "40px",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#376fd0",
              }}
            >
              It's going here:
            </Typography>
            <Typography sx={{ marginTop: "20px" }}>Test dev</Typography>
            <Typography sx={{ marginTop: "20px" }}>
              Test dev test areea
            </Typography>
            <Typography>Noida, Uttar Pradesh india 201301</Typography>
            <Typography>0987654321</Typography>

            <Typography
              sx={{
                textAlign: "justify",
                marginTop: "30px",
                marginbottom: "30px",
              }}
            >
              Now sit tight while we prepare your package for shipment. You will
              receive a confirmation email once your item has shipped,along with
              a tracking number.Thank you for using SOVA as your shipping
              partner. If you have questions, please send us an email at
              lost@sovasystems.com
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box
            sx={{
              border: "1px solid gray",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: "#376fd0",
                }}
              >
                We're Shipping this:
                <Typography
                  sx={{
                    color: "#000",
                  }}
                >
                  USPS Delivery in 6 to 10 business days.
                </Typography>
              </Typography>

              <Button
                className="print-btn"
                sx={{
                  background: "blue",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "20px",
                  padding: "8px 30px",
                }}
              >
                Print Receipt
              </Button>
            </Box>
            <Box sx={{ textAlign: "center", padding: "100px 30px" }}>
              <p>Freight:$ 60.41</p>
              <p>processing:$ 9.77</p>
              <p>Insurance:$ 0</p>
              <Typography variant="h1"> Total cost: $ 70.18</Typography>
              <Typography variant="h1">Thank You!</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default UserReceipt;

export default [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "department_name",
    label: "Department Name",
  },
  {
    id: "position_name",
    label: "Position Name",
  },
  {
    id: "security_level",
    label: " Security Level",
  },
  {
    id: "equipment_permission_count",
    label: "Equipment Permission Count",
  },
  {
    id: "action",
    label: "Action",
  },
];

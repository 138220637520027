import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import format from "date-fns/format";
import useAppDispatch from "../../hooks/useAppDispatch";
import ImageCropper from "../../components/form-components/ImageCropper";
import {
  ViewTourCheckpoint,
  GetAllTour,
  GetTourButton,
  GetAllTask,
  UpdateTourCheckpoint,
} from "../../api";
import {
  getChecked,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/tourSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Paper,
  CircularProgress,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  Box,
  Menu,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  ListItemButton,
  ListItem,
  List,
  ListItemText,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Cancel as CancelIcon, Error as ErrorIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import ChoosePoiImage from "../poi/inner-component/ChoosePoiImage";
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
const Image = styled.img`
  width: 100%;
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
    "& textarea": {
      color: theme.palette.mode === "light" ? "#000" : theme.palette.grey[100],
    },
    "& #tour-desc:focus-visible": {
      outlineColor: "#2f63be",
      outline: "2px solid #2f63be",
      border: 0,
    },
  },
  checkSetSchedule: {
    display: "flex",
    justifyContent: "space-between",
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;selectTourButtons
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "235px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "460px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        padding: "10px",
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const selectSites = [
  { title: "None selected" },
  { title: "One" },
  { title: "Two" },
  { title: "Three" },
  { title: "Fouor" },
  { title: "Five" },
  { title: "Six" },
];

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function EditButtonForm(props) {
  const dispatch = useAppDispatch();
  const [images, setImages] = useState(null);
  let navigate = useNavigate();
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  // Button belogs to this tour
  const [tourbuttonsbelongs, setTourButtonsBelongs] = React.useState("");
  const selectTourButtonsBelongs = (event) => {
    setTourButtonsBelongs(event.target.value);
  };
  // Vehicle Make
  const [vehiclemake, setVehicleMake] = React.useState("");
  const selectVehicleMake = (event) => {
    setVehicleMake(event.target.value);
  };
  // Vehicle Model
  const [vehiclemodel, setVehicleModel] = React.useState("");
  const selectVehicleModel = (event) => {
    setVehicleModel(event.target.value);
  };
  // Visitor Type
  const [visitortype, setVisitorType] = React.useState("");
  const selectVisitorType = (event) => {
    setVisitorType(event.target.value);
  };
  // Visitor Company
  const [visitorcompany, setVisitorCompany] = React.useState("");
  const selectVisitorCompany = (event) => {
    setVisitorCompany(event.target.value);
  };
  // Visitor security level
  const [vsecuritylevel, setVSecurityLevel] = React.useState("");
  const selectVSecurityLevel = (event) => {
    setVSecurityLevel(event.target.value);
  };
  // Visitor escort
  const [escort, setEscort] = React.useState("");
  const selectEscort = (event) => {
    setEscort(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const [otherinfo, setOtherInfo] = React.useState("");
  const selectOtherInfo = (event) => {
    setOtherInfo(event.target.value);
  };
  // Site Dropdown
  const [siteDropdown, setSiteDropdown] = React.useState(null);
  const siteDropdownOpen = (event) => {
    setSiteDropdown(event.currentTarget);
  };
  const siteDropdownClose = () => {
    setSiteDropdown(null);
  };
  const classes = useStyles();
  //Add Company Select Control
  const [control, setControl] = React.useState(0);
  const addMoreVisitorCompany = () => {
    setControl(control + 1);
    console.log(control);
  };
  //Remove Company Select Control
  const removeVisitorCompany = () => {
    setControl(control - 1);
    console.log(control);
  };
  const { tourId, tagId } = useParams(); //get tagId from  the url
  // Select tour buttons
  const [tourbuttons, setTourButtons] = useState(tagId);
  const selectTourButtons = (event) => {
    setTourButtons(event.target.value);
    viewTourCheckpoint(tourId, event.target.value);
  };

  // State for image cropper modal
  const [imageCropperDialog, setImageCropperDialog] = useState(false);
  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };
  const [imageData, setImageData] = useState(null);
  const childToParent = (childData) => {
    setImageData(childData);
  };

  const imageCropperClear = () => {
    setImageData(null);
  };

  //get all tour
  const [allTour, setAllTour] = useState([]);
  const getAllTour = () => {
    GetAllTour()
      .then((res) => {
        if (res.statusCode === 200) {
          setAllTour(res.data);
        } else {
          setAllTour([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get tour button
  const [tourButton, setTourButton] = useState([]);
  const getTourButton = () => {
    GetTourButton(tourId)
      .then((res) => {
        if (res.statusCode === 200) {
          setTourButton(res.data);
        } else {
          setTourButton([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //veiw tour checkpoint
  const [viewCheckpoint, setViewCheckpoint] = useState({});
  const viewTourCheckpoint = (tourId, tagId) => {
    ViewTourCheckpoint({
      tour_id: tourId,
      tag_id: tagId,
    })
      .then((res) => {
        if (res.statusCode === 200) {
          setViewCheckpoint(res.data);
        } else {
          setViewCheckpoint({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get all task
  const [allTask, setAllTask] = useState([]);
  const getAllTask = () => {
    GetAllTask(tagId)
      .then((res) => {
        if (res.statusCode === 200) {
          setAllTask(res.data);
        } else {
          setAllTask([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //useeffect method
  useEffect(() => {
    getAllTour();
    getTourButton();
    viewTourCheckpoint(tourId, tourbuttons);
    getAllTask();
  }, []);
  //destrucure the viewCheckpoint
  const {
    tag_uid,
    tag_other_id,
    button,
    tour_id,
    TagID,
    location_hint,
    audio_private,
    audio_expiry_date,
    image,
    task_ids,
  } = viewCheckpoint;

  //get all task checkbox
  const [modulearray, setmodulearray] = useState([]);
  const [removeArray, setremoveArray] = useState([]);
  const [localChecked, setLocalChecked] = useState([]);

  useEffect(() => {
    if (task_ids) {
      const initialChecked = {};
      task_ids.forEach((taskId) => {
        initialChecked[taskId] = true;
      });
      setLocalChecked(initialChecked);
    }
  }, [task_ids]);

  const handleChecked = (e, taskDescription) => {
    const { value, checked } = e.target;

    if (checked) {
      setmodulearray([...modulearray, value]);
      setremoveArray(removeArray.filter((e) => e !== value));
    } else {
      setmodulearray(modulearray.filter((e) => e !== value));
      setremoveArray([...removeArray, value]);
    }

    setLocalChecked((localChecked) => ({
      ...localChecked,
      [value]: checked,
    }));
  };
  // DatePicker
  const [expdate, setExpDate] = useState();
  const [loading, setLoading] = useState(false);
  const handleImage = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };

  const onLoad = (fileString) => {
    setImages(fileString);
    console.log("profile_photo->", fileString);
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  const initialValues = {
    all_tour: tour_id ? tour_id : "",
    // tour_button: TagID ? TagID : "",
    button_id: tag_uid ? tag_uid : "",
    button_name: button ? button : "",
    secondary_name: tag_other_id ? tag_other_id : "",
    location_hint: location_hint ? location_hint : "",
    audio_private: audio_private ? audio_private : "",
  };

  const validationSchema = Yup.object().shape({});
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        tag_id: tourbuttons,
        tag_uid: values.button_id,
        tag_description: values.button_name,
        tag_other_id: values.secondary_name,
        tour_id: values.all_tour,
        location_hint: values.location_hint,
        audio_private: values.audio_private,
        audio_expiry_date: expdate,
        task_id: modulearray,
        remove_tasks: removeArray,
        image: imageData ? imageData : null,
      };
      UpdateTourCheckpoint(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            setLoading(false);
            navigate({
              pathname: `/tours/checkpoints/${tourId}`,
            });
          } else {
            dispatch(getSnackClose());
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getSnackClose());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl mt={4}>
                    <InputLabel
                      shrink
                      id="tour-btns"
                      sx={{
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        marginLeft: "-4px",
                      }}
                    >
                      Select other buttons for this tour
                    </InputLabel>
                    <Select
                      labelId="tour-btns"
                      id="tour-btns"
                      name="tour_button"
                      value={tourbuttons}
                      label="Select other buttons for this tour"
                      onChange={selectTourButtons}
                      fullWidth
                      displayEmpty
                    >
                      {tourButton.map((data) => {
                        return (
                          <MenuItem value={data.tag_id} key={data.tag_id}>
                            {data.tag_description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="btn-id"
                      label="Button ID (can be changed via mobile app)"
                      name="button_id"
                      value={values.button_id}
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="GBA3746467"
                      style={{ cursor: "not-allowed" }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="btn-name"
                      name="button_name"
                      value={values.button_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Button name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="front-desk"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="sec-name"
                      label="Secondary name"
                      name="secondary_name"
                      value={values.secondary_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Optional checkpoint code, etc"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <InputLabel
                      shrink
                      id="tour-btn"
                      sx={{
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        marginLeft: "-4px",
                      }}
                    >
                      Button belogs to this tour
                    </InputLabel>
                    <Select
                      labelId="tour-btn"
                      id="tour-btn"
                      name="all_tour"
                      value={values.all_tour}
                      label="Button belogs to this tour"
                      onChange={handleChange}
                      fullWidth
                      displayEmpty
                    >
                      {allTour.map((data) => {
                        return (
                          <MenuItem value={data.TourID} key={data.TourID}>
                            {data.TourName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextAreaControl mt={6} className={classes.textAreaControl}>
                    <FormControl mt={1}>
                      <InputLabel id="loc-hint">Location hint</InputLabel>
                      <TextareaAutosize
                        name="location_hint"
                        onChange={handleChange}
                        value={values.location_hint}
                        onBlur={handleBlur}
                        placeholder="This is located under the desk of main reception"
                      />
                    </FormControl>
                  </TextAreaControl>
                  <FormControl mt={6}>
                    <FormLabel>
                      Audio recording
                      {
                        <StyledTooltip
                          sx={{
                            verticalAlign: "middle",
                            marginLeft: "6px",
                            marginRight: "6px",
                          }}
                          placement="top-start"
                          title={"tooltip text goes here"}
                        >
                          <ErrorIcon color="primary" />
                        </StyledTooltip>
                      }
                    </FormLabel>
                    <Box mt={3} sx={{ display: "flex", gap: "8px" }}>
                      <Button color="primary" variant="outlined">
                        Retry
                      </Button>
                      <Button color="warning" variant="contained">
                        Stop
                      </Button>
                      <Button color="primary" variant="contained">
                        Record
                      </Button>
                    </Box>
                    <FormControlLabel
                      mt={3}
                      control={
                        <Checkbox
                          name="audio_private"
                          value={values.audio_private}
                          checked={values.audio_private === "YES"}
                          onChange={(event, checked) =>
                            setFieldValue(
                              "audio_private",
                              checked ? "YES" : "NO"
                            )
                          }
                          onBlur={handleBlur}
                        />
                      }
                      label="Check here to mark audio recording as private"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <DatePicker
                      id="expiry-date"
                      label="Audio expiry date"
                      value={expdate}
                      onChange={(newValue) => {
                        setExpDate(newValue);
                        setExpDate(format(newValue, "yyyy-MM-dd"));
                      }}
                      minDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Click to select",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <StyledFormControl>
                      <FormLabel component="legend">
                        Task (click task name to set schedule)
                      </FormLabel>
                      <FormGroup>
                        {allTask.map((mod, index) => {
                          const isChecked = localChecked[mod.TaskID] || false;
                          return (
                            <FormControlLabel
                              key={mod.TaskID}
                              control={
                                <Checkbox
                                  disableRipple
                                  name={mod.TaskDescription}
                                  value={mod.TaskID}
                                  // checked={modulearray.includes(mod.TaskID)}
                                  checked={isChecked}
                                  onChange={(e) => {
                                    handleChecked(e, mod.TaskDescription);
                                  }}
                                />
                              }
                              label={mod.TaskDescription}
                            />
                          );
                        })}
                      </FormGroup>
                    </StyledFormControl>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ChoosePoiImage />
                  {/* <FormControl mt={2}>
                    <FormLabel>Location hint image</FormLabel>
                    <ImageWrapper>
                      <Image
                        alt="demo-image"
                        src={imageData ? imageData : image}
                      />
                    </ImageWrapper>
                    <Box mt={4} sx={{ display: "flex", gap: "10px" }}>
                      <Item>
                      
                        <label htmlFor="raised-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={imageCropperOpen}
                          >
                            Choose image
                          </Button>
                        </label>
                      </Item>
                      <Item>
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                        >
                          Place arrows
                        </Button>
                      </Item>
                    </Box>
                  </FormControl> */}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    mt={6}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CardContent>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {imageCropperDialog && (
        <ImageCropper
          imageCropperDialog={imageCropperDialog}
          setImageCropperDialog={setImageCropperDialog}
          imageCropperOpen={imageCropperOpen}
          imageCropperClose={imageCropperClose}
          childToParent={childToParent}
          imageData={imageData ? imageData : image}
          // parentToChild={parentToChild}
        />
      )}
    </Card>
  );
}

function EditButton() {
  const { tagId, tourId } = useParams(); //get tagId from  the url
  return (
    <React.Fragment>
      <Helmet title="Edit Button" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Button
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/tours/tour-listing">
          Tour List
        </Link>
        <Link component={NavLink} to={`/tours/checkpoints/${tourId}`}>
          Button List
        </Link>
        <Typography>Edit Button</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <EditButtonForm />
    </React.Fragment>
  );
}

export default EditButton;

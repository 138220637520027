import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import {
  ViewAedMedicalOfficier,
  AddEditAedMedicalOfficier,
} from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl as MuiFormControl,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const AddEditMedicalDirector = ({
  addEditMedicalDirectorDialog,
  addEditMedicalDirectorDialogClose,
}) => {
  const [viewMedicalData, setViewMedicalData] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const viewAedMedicalOfficier = () => {
    setLoading(true);
    ViewAedMedicalOfficier()
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setViewMedicalData(res.data);
        } else {
          setViewMedicalData({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const {
    director_name,
    license_number,
    company_name,
    street,
    city,
    state,
    zip,
    office_phone,
    mobile,
    email,
    web_site,
  } = viewMedicalData;
  useEffect(() => {
    viewAedMedicalOfficier();
  }, []);

  let initialValues = {
    medical_director_name: director_name ? director_name : "",
    physician_license_number: license_number ? license_number : "",
    company_name: company_name ? company_name : "",
    street_address: street ? street : "",
    city: city ? city : "",
    state: state ? state : "",
    zip: zip ? zip : "",
    office_phone: office_phone ? office_phone : "",
    mobile_phone: mobile ? mobile : "",
    email: email ? email : "",
    website: web_site ? web_site : "",
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    medical_director_name: Yup.string().required("Please enter  director name"),
    physician_license_number: Yup.string().required(
      "Please enter physician license number"
    ),
    company_name: Yup.string().required("Please enter physician company name"),
    street_address: Yup.string().required("Please enter  street address"),
    city: Yup.string().required("Please enter city"),
    state: Yup.string().required("Please enter state"),
    zip: Yup.string().required("Please enter zip"),
    // office_phone: Yup.string().required("Please enter office phone"),
    // mobile_phone: Yup.string().required("Please enter mobile phone"),
    office_phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
    mobile_phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
    email: Yup.string()
      .required("Please enter email")
      .email("Please enter valid email"),
    website: Yup.string().required("Please enter website"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        director_name: values.medical_director_name,
        license_number: values.physician_license_number,
        company_name: values.company_name,
        street: values.street_address,
        city: values.city,
        state: values.state,
        zip: values.zip,
        office_phone: values.office_phone,
        mobile: values.mobile_phone,
        email: values.email,
        web_site: values.website,
      };
      AddEditAedMedicalOfficier(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setLoading(false);
            addEditMedicalDirectorDialogClose();
            dispatch(getMsg("Record saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            setLoading(false);
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-edit-medical-director"
        aria-describedby="add-edit-medical-director-desc"
        open={addEditMedicalDirectorDialog}
        onClose={addEditMedicalDirectorDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-edit-medical-director"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit Medical Director
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addEditMedicalDirectorDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="medical_director_name"
                        id="medical-director-name"
                        label="Medical director name"
                        defaultValue="Stan Smith"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="physician_license_number"
                        id="physician-license-number"
                        label="Physicians license number"
                        defaultValue="C34GHFGYH-Q1"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="company_name"
                        id="company-name"
                        label="Company name"
                        defaultValue="Allied Medical"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="street_address"
                        id="street-address"
                        label="Street address"
                        defaultValue="1234 Fourth Ave"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="city"
                        id="city"
                        label="City"
                        defaultValue="San Die"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="state"
                        id="state"
                        label="State"
                        defaultValue="CA"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="zip"
                        id="zip"
                        label="Zip"
                        defaultValue="92120"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="office_phone"
                        id="office-phone"
                        label="Office phone"
                        defaultValue="618-444-4032"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="mobile_phone"
                        id="mobile-phone"
                        label="Mobile phone"
                        defaultValue="619-345-8327"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="email"
                        id="email"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        defaultValue="derinredding@gmail.com"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="website"
                        id="website"
                        label="Web site"
                        defaultValue="ASDSD"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <DialogActions sx={{ padding: "24px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={addEditMedicalDirectorDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "15px" }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddEditMedicalDirector;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { ViewPoi, PoiEncList, PoiPrintReport } from "../../../api";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import PoiEncounterDialog from "./PoiEncounterDialog";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Button,
  IconButton,
  Typography,
  Card,
  Grid,
  Avatar as MuiAvatar,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Paper,
} from "@mui/material";

import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 200px;
  width: 200px;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const ImageSearchDialog = ({
  imageSearchDialog,
  imageSearchDialogClose,
  id,
}) => {
  const [viewPoiData, setViewPoiData] = useState({});
  const [preLoading, setPreLoading] = useState(false);
  const classes = useStyles();
  const viewPoi = () => {
    ViewPoi(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewPoiData(res.data);
          setPreLoading(false);
        } else {
          setViewPoiData({});
          setPreLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPreLoading(false);
      });
  };

  const [poi, setPoi] = useState([]);
  const poiEncList = () => {
    PoiEncList(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setPoi(res.data);
        } else {
          setPoi([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onButtonClick = () => {
    setPreLoading(true);
    PoiPrintReport(id)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-ATTENDANCE_LOG.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setPreLoading(false);
        } else {
          console.log("something went wrong");
          setPreLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPreLoading(false);
      });
  };

  useEffect(() => {
    viewPoi();
    poiEncList();
    setPreLoading(true);
  }, []);

  const [poiEncounterDialog, setPoiEncounterDialog] = React.useState(false);
  const poiEncounterDialogOpen = () => {
    setPoiEncounterDialog(true);
  };
  const poiEncounterDialogClose = () => {
    setPoiEncounterDialog(false);
  };

  return (
    <>
      <Dialog
        open={imageSearchDialog}
        onClose={imageSearchDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        aria-labelledby="poiWatch-title"
        aria-describedby="poiWatch-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="poiWatch-title"
        >
          <Typography variant="h4" component="div">
            POI#{viewPoiData?.id}
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={imageSearchDialogClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Card>
            <Grid container spacing={4}>
              <Grid item md={4}>
                <Avatar
                  alt="Lucy Lavender"
                  // src="/static/img/avatars/avatar-1.jpg"
                  src={viewPoiData?.front_face}
                />
              </Grid>
              <Grid item md={5}>
                <Typography variant="h6" gutterBottom>
                  POI Characteristics:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Added By: {viewPoiData?.location}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Threat Level: {viewPoiData?.threat_level}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Name: {viewPoiData?.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Alias: {viewPoiData?.alias}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Gender: {viewPoiData?.gender}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Age: {viewPoiData?.age}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Height: {viewPoiData?.height}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Weight: {viewPoiData?.weight}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Scars:{viewPoiData?.scars}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Hair Color: {viewPoiData?.hair_color}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Tattoos:{viewPoiData?.tattoos}
                </Typography>
              </Grid>

              <Grid item md={3}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    component={NavLink}
                    to={`/poi/edit-poi/${id}`}
                  >
                    Edit
                  </Button>
                  <Button variant="outlined" onClick={onButtonClick}>
                    Print
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
          <Card
            sx={{
              marginTop: "10px",
            }}
          >
            <Grid container spacing={6}>
              <Grid item md={9} mt={2}>
                <Typography variant="h6">Encounters:</Typography>
              </Grid>
              <Grid item md={3}>
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    component={NavLink}
                    to={`/poi/add-poi-encounter/${id}`}
                  >
                    Add Encounter
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableCell>DateTime</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Police Contacted</TableCell>
                <TableCell align="right">Trespass Issued</TableCell>
                <TableCell align="right">Resolution</TableCell>
              </TableHead>
              {poi.map((data) => {
                return (
                  <TableBody>
                    <TableCell onClick={poiEncounterDialogOpen}>
                      {data.encounter_date}
                    </TableCell>
                    <TableCell align="right">{data.Location}</TableCell>
                    <TableCell align="right">{data.police_contacted}</TableCell>
                    <TableCell align="right">{data.trespass_issued}</TableCell>
                    <TableCell align="right">{data.resolution}</TableCell>
                  </TableBody>
                );
              })}
            </Table>
          </Card>
          <Card
            sx={{
              marginTop: "10px",
            }}
          >
            <Typography variant="h6" gutterBottom mt={3}>
              For additional details contact:
            </Typography>
            <Typography variant="body2" gutterBottom mt={5}>
              Name: {viewPoiData?.UserName}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Phone:{viewPoiData?.Phone}
            </Typography>
            <Typography variant="body2">Email: {viewPoiData?.Email}</Typography>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={imageSearchDialogClose}
            sx={{ marginRight: "10px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {preLoading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {poiEncounterDialog && (
        <PoiEncounterDialog
          poiEncounterDialog={poiEncounterDialog}
          setPoiEncounterDialog={setPoiEncounterDialog}
          poiEncounterDialogOpen={poiEncounterDialogOpen}
          poiEncounterDialogClose={poiEncounterDialogClose}
        />
      )}
    </>
  );
};

export default ImageSearchDialog;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { CheckInVisitorFromKioskMode } from "../../api";
import KioskModeSubmitPassword from "./KioskModeSubmitPassword";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Paper,
} from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Image = styled.img`
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  width: 44px;
  height: 44px;
`;

function ConfirmReturningVisitor() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const locationDetail = localStorage.getItem("location");
  const name = JSON.parse(locationDetail);

  const confirmVisitorDetail = location?.state?.visitorDetails;
  const visitorHost = location?.state?.visitorHost;
  const [kioskModeSubmitPasswordModal, setKioskModeSubmitPasswordModal] =
    useState(false);
  const kioskModeSubmitPasswordModalClose = () => {
    setKioskModeSubmitPasswordModal(false);
  };
  const [loading, setLoading] = useState(false); //State for loader

  const confirmCheckInVisitor = (id) => {
    setLoading(true);
    let payload = {
      status: "CHECKED-IN",
    };
    CheckInVisitorFromKioskMode(id, payload).then((response) => {
      setLoading(false);
      if (response.statusCode == 200) {
        navigate({
          pathname: "/visitors/kiosk-mode",
        });
      } else {
        console.log("something went wrong");
      }
    });
  };

  const launchFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };
  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setKioskModeSubmitPasswordModal(true);
    }
  }

  const updateParentState = () => {
    kioskModeSubmitPasswordModalClose();
  };

  return (
    <>
      <Box sx={{ p: 5, my: "auto" }} className={classes.startOverCard}>
        <Card sx={{ maxWidth: "1140px", mx: "auto" }}>
          <CardHeader
            title={
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    {name.LocationName}
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    Returning Visitor
                  </Typography>
                </Box>
              </>
            }
          />
          <CardContent>
            <Box sx={{ maxWidth: 800, ml: "auto", mr: "auto" }}>
              <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
                  <Typography gutterBottom>Confirm and Finish</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={5} textAlign="center">
                  <Image
                    src={`/static/img/avatars/avatar-1.jpg`}
                    style={{ width: 200, height: 200 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "16px !important",
                    },
                  }}
                >
                  <Typography variant="body2" gutterBottom>
                    <strong>Name:</strong> {confirmVisitorDetail.name}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Company Name:</strong>{" "}
                    {confirmVisitorDetail.company_name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Visiting:</strong>{" "}
                    {visitorHost ? visitorHost : confirmVisitorDetail.host_name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "space-between" }}>
            <IconButton
              disableRipple={true}
              color="primary"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosIcon fontSize="medium" />
            </IconButton>
            <Button component={NavLink} to="/visitors/kiosk-mode">
              Start over
            </Button>
            <IconButton
              disableRipple={true}
              onClick={() => {
                confirmCheckInVisitor(confirmVisitorDetail.id);
              }}
            >
              <CheckIcon fontSize="large" color="success" />
            </IconButton>
          </CardActions>
        </Card>
      </Box>
      {kioskModeSubmitPasswordModal && (
        <KioskModeSubmitPassword
          kioskModeSubmitPasswordModal={kioskModeSubmitPasswordModal}
          setKioskModeSubmitPasswordModal={setKioskModeSubmitPasswordModal}
          kioskModeSubmitPasswordModalClose={kioskModeSubmitPasswordModalClose}
          launchFullScreen={launchFullScreen}
          onDataFromChild={updateParentState}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function ConfirmReturningVisitors() {
  return (
    <React.Fragment>
      <Helmet title="Confirm Returning Visitors" />
      <ConfirmReturningVisitor />
    </React.Fragment>
  );
}
export default ConfirmReturningVisitors;

import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import { SendEmailToReportingOfficier } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  FormControl as MuiFormControl,
  IconButton,
  DialogContent,
  TextField,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const EmailComment = ({ emailComment, emailCommentClose, singleData }) => {
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToSendEmail = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "sendEmailToReportingOffcr" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const handleSubmit = () => {
    let payload = {
      incident_id: singleData.IncidentID,
      email: singleData.user.email,
      offcrNmae: singleData.user.UserName,
      comment: comment,
    };
    SendEmailToReportingOfficier(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          emailCommentClose();
          dispatch(getSnackMsg("Email sent successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="comment-title"
        aria-describedby="comment-description"
        open={emailComment}
        onClose={emailCommentClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="comment-title"
        >
          <Typography variant="h4">
            Email comment to {singleData.user.UserName}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={emailCommentClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <FormControl mt={3}>
            <TextField
              name="comment"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label="Comment"
              autoComplete="off"
              onChange={(e) => setComment(e.target.value)}
              multiline={true}
              rows={4}
              placeholder="Comment added here should be related to this incident. Officer will receive an email after you click send button."
            />
          </FormControl>
          <DialogActions sx={{ padding: "20px 0" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={emailCommentClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!hasPermissionToSendEmail}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Send
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailComment;

import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Dialog,
  OutlinedInput,
  MenuItem,
  Checkbox,
  formControl,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { Bell, Home, UserPlus, Server } from "react-feather";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, description, Icon }) {
  return (
    <ListItem divider component={Link} to="#">
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [filterDialog, setFilterDialog] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={7}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader
          p={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            sx={{ marginTop: "5px" }}
          >
            7 New Notifications
          </Typography>
          <IconButton onClick={() => setFilterDialog(true)}>
            <FilterAltIcon color="Primary" />
          </IconButton>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            <Notification
              title="Update complete"
              description="Restart server to complete update."
              Icon={Server}
            />
            <Notification
              title="New connection"
              description="Anna accepted your request."
              Icon={UserPlus}
            />
            <Notification
              title="Lorem ipsum"
              description="Aliquam ex eros, imperdiet vulputate hendrerit et"
              Icon={Bell}
            />
            <Notification
              title="New login"
              description="Login from 192.186.1.1."
              Icon={Home}
            />
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="#">
              Show all notifications
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
      {filterDialog && (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",
              },
            },
          }}
          open={filterDialog}
          onClose={() => setFilterDialog(false)}
          aria-labelledby="visitor-modal"
          aria-describedby="visitor-modal"
        >
          <DialogTitle
            id="visitor-modal"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              background: "#e8e8e8",
            }}
          >
            <Typography>Select Filters</Typography>
            <DialogActions sx={{ padding: "0px" }}>
              <IconButton
                sx={{ marginRight: "-10px" }}
                onClick={() => setFilterDialog(false)}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
          <DialogContent sx={{ padding: "0px" }}>
            <DialogContentText sx={{ padding: "0px 20px 20px" }}>
              <FormControl sx={{ m: 1, width: "100%", marginTop: "10px" }}>
                <Autocomplete
                  multiple
                  id="emergency_alert_departments"
                  limitTags={3}
                  fullWidth
                  // value={}
                  options={names}
                  // onChange={(e, v) => {
                  //   setENotifyDepartments(v);
                  //   // handeleChangeDepartment(
                  //   //   v,
                  //   //   propRow,
                  //   //   data.value
                  //   // );
                  // }}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="emergency_alert_departments"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={"None Selected"}
                    />
                  )}
                />

                {/* <InputLabel id="demo-multiple-checkbox-label">
                  None Selected
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<OutlinedInput label="None Selected" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={personName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
              <Box sx={{ textAlign: "right", marginTop: "10px" }}>
                <Button variant="outlined" color="warning">
                  Submit
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions
            sx={{ justifyContent: "center", paddingBottom: "30px" }}
          >
            <Button
              variant="outlined"
              color="primary"
              component={NavLink}
              to="/lostandfound/add-lost-found"
            >
              Lost
            </Button>
            <Button
              variant="contained"
              color="primary"
    
            >
              Submit
            </Button>
          </DialogActions> */}
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;

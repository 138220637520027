import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Grid,
  Checkbox,
  Paper,
  FormControl as MuiFormControl,
  TableSortLabel,
  Toolbar,
  FormControlLabel,
  Switch,
  CircularProgress,
  TextField,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { SendDAREmailList, DARMultiEmail } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Toaster from "../../tasks/Toaster";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    font-size: 14px;
    font-weight: 600;
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#17212f" : "rgb(229,246,253)"};

    color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#fff" : "#646464"};
    position: sticky;
    top: 0;
    z-index: 2;
  }
  &.${tableCellClasses.body} {
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.white};
  }
`;
function createData(name, email) {
  return { name, email };
}

const rows = [
  createData("Nidi sharma", "me@you.com"),
  createData("Nidi sharma 1", "me@you.com"),
  createData("Nidi sharma2", "me@you.com"),
  createData("Nidi sharma3", "me@you.com"),
  createData("Nidi sharma4", "me@you.com"),
  createData("Nidi sharma5", "me@you.com"),
  createData("Nidi sharma6", "me@you.com"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <CustomTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </CustomTableCell>
        {headCells.map((headCell) => (
          <CustomTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected } = props;

  return (
    <Toolbar>
      <div>
        <Typography
          color="inherit"
          variant="subtitle1"
          sx={{ whiteSpace: "noWrap" }}
        >
          {numSelected} selected
        </Typography>
      </div>
      <Spacer />
    </Toolbar>
  );
};

function EnhancedTable(props) {
  const { selected, setSelected } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  const [emailList, setEmailList] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = emailList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  console.log("Selecteds", selected);

  // Method for emails list
  const getUsersEmail = () => {
    setDialogLoader(true);
    SendDAREmailList()
      .then((res) => {
        if (res.statusCode === 200) {
          setEmailList(res.data);
        } else {
          setEmailList([]);
        }
        setDialogLoader(false);
      })
      .catch((err) => {
        setDialogLoader(false);
        setEmailList([]);
        console.log(err);
      });
  };
  useEffect(() => {
    getUsersEmail();
  }, []);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
          clasName="tableContainer"
          sx={{
            maxHeight: "250px",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#F7F9FC",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#f3f3f3",
              borderRadius: 2,
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={emailList.length}
            />
            {dialogLoader ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(emailList, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <CustomTableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </CustomTableCell>
                        <CustomTableCell
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.first_name} {row.last_name}
                        </CustomTableCell>
                        <CustomTableCell align="left">
                          {row.email}
                        </CustomTableCell>
                      </TableRow>
                    );
                  })}
                {emailList.length === 0 && (
                  <TableRow style={{ height: dense ? 6 : 0 }}>
                    <TableCell colSpan={10} align="center">
                      No communication records found! Add in Settings -
                      Communication
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
const SendDar = ({ sendDarOpen, sendDarClose, sendDar, setSendDar }) => {
  const [selected, setSelected] = React.useState([]);
  const [openSnack, setOpenSnack] = useState(false); //state for snackbar
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToSendDar = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "sendMultipleUserMail" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
    // setOpenSnack(false);
  };
  const initialValues = {
    inputEmail: LoggedInUserInfo.Email ? LoggedInUserInfo.Email : "",
  };

  const validationSchema = Yup.object().shape({
    inputEmail: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter email address"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let payload = {
      //  date_time: darDateTime,
      //  activity: darActivity,
      //  location: darLocation,
      //  remarks: darRemark,
      c_id: selected,
      reply_to: values.inputEmail,
      // comments: values.inputComment,
      //  name: userName,
      //  rp_id: "377476411",
      //  reported_by: LoggedInUserInfo.alias_name,
    };
    DARMultiEmail(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackMsg(res.message));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          sendDarClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          // setMsg(`Email sent successfully`);
          // setAlert("success");
          // setOpenSnack(true);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          sendDarClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          // setMsg("Something went wrong, please try again");
          // setAlert("error");
          // setOpenSnack(true);
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        sendDarClose();
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        // setMsg("Something went wrong, please try again");
        // setAlert("error");
        // setOpenSnack(true);
      });
  };
  return (
    <>
      {openSnack && (
        <Toaster
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
          severity={alert}
          message={msg}
        />
      )}
      <Dialog
        aria-labelledby="dar-title"
        aria-describedby="dar-description"
        open={sendDar}
        onClose={sendDarClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Send Daily Activity Report</Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={sendDarClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ paddingLeft: "24px", paddingRight: "24px" }}>
            Please select one or more names from the list below to send
            report(s) to.
          </DialogContentText>
          <Card variant="outlined" sx={{ borderBottom: 0 }}>
            <EnhancedTable selected={selected} setSelected={setSelected} />
          </Card>
          <Paper sx={{ marginTop: "15px", marginBottom: "15px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl sx={{ marginTop: "15px", marginBottom: "15px" }}>
                    <TextField
                      name="inputEmail"
                      value={values.inputEmail}
                      error={Boolean(touched.inputEmail && errors.inputEmail)}
                      fullWidth
                      helperText={touched.inputEmail && errors.inputEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      autoComplete="off"
                      id="reply-to"
                      label="Reply to *"
                      sx={{ input: { color: "red" } }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="me@you.com"
                    />
                  </FormControl>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => setSendDar(false)}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={selected.length < 1 || !hasPermissionToSendDar}
                      sx={{ marginLeft: "10px" }}
                    >
                      Send DAR
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SendDar;

export default [
  {
    id: "CompanyName",
    label: "Name",
  },
  {
    id: "CompanyAddr1",
    label: "Address",
  },
  {
    id: "CompanyCity",
    label: "City",
  },
  {
    id: "CompanyState",
    label: "State",
  },
  {
    id: "CompanyZip",
    label: "Zip",
  },
  {
    id: "CompanyPhone",
    label: "Phone",
  },
  {
    id: "CompanyFax",
    label: "Fax",
  },
  {
    id: "CompanyAdminName",
    label: "Admin",
  },
  {
    id: "CompanyAdminPhone",
    label: "Phone",
  },
  {
    id: "note",
    label: "Note",
  },
  {
    id: "status",
    label: "Active",
  },
  {
    id: "action",
    label: "Action",
  },
];

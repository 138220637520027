export default [
  {
    id: "classificationName",
    numeric: true,
    disablePadding: true,
    label: "Classification Name",
  },
  {
    id: "count",
    numeric: true,
    disablePadding: false,
    label: "Count",
  },
  {
    id: "critical",
    numeric: true,
    disablePadding: false,
    label: "Critical",
  },
  {
    id: "nurseNotify",
    numeric: true,
    disablePadding: false,
    label: "Nurse Notify",
  },
  {
    id: "imgRequired",
    numeric: true,
    disablePadding: false,
    label: "Img Required",
  },
  {
    id: "taskCount",
    numeric: true,
    disablePadding: false,
    label: "Task Count",
  },
  {
    id: "activeStatus",
    numeric: true,
    disablePadding: false,
    label: "Active Status",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

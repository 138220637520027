import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { UpdateKeyRing } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  TextField,
  FormHelperText,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  Paper,
  Select,
  Tooltip,
  InputLabel as MuiInputLabel,
  Grid,
  InputAdornment,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const EditKeyRing = ({
  editKeyRing,
  editKeyRingClose,
  singleData,
  ringId,
  addKeyRingList,
}) => {
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const [securityLevel, setSecurityLevel] = React.useState("");
  const selectSecurityLevel = (event) => {
    setSecurityLevel(event.target.value);
  };

  let securityLvl = [];
  for (let i = 0; i <= 10; i++) {
    securityLvl.push(i);
  }

  const {
    ring_scan_id,
    ring_name,
    ring_securitylvl,
    ring_group,
    ring_expiry,
    ring_code,
  } = singleData;

  /* A validation schema for the formik form. */
  const validationSchema = Yup.object().shape({
    ring_id: Yup.string()
      .required("Please enter ring id")
      .matches(/^[a-z0-9]+$/i, "Ring scan ID is alphanumeric only ")
      .min(2, "Ring scan ID has minimum length of 2 characters"),
    ring_code: Yup.string().required("Please enter ring code"),
    ring_name: Yup.string().required("Please enter ring name"),
    ring_group: Yup.string().required("Please enter ring group"),
    expiry_date: Yup.date().typeError("Please enter a expiry date").required(),
  });

  const initialValues = {
    ring_id: ring_scan_id ? ring_scan_id : "",
    ring_code: ring_code ? ring_code : "",
    ring_name: ring_name ? ring_name : "",
    ring_group: ring_group ? ring_group : "",
    expiry_date: ring_expiry ? ring_expiry : null,
    ring_securitylvl: ring_securitylvl ? ring_securitylvl : "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      editKeyRingClose();
      let formData = {
        id: ringId,
        ring_scan_id: values.ring_id,
        ring_code: values.ring_code,
        ring_name: values.ring_name,
        ring_group: values.ring_group,
        ring_expiry: values.expiry_date,
      };
      UpdateKeyRing(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key ring has been updated successfully"));
            dispatch(getSnackAlert("success"));

            addKeyRingList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={editKeyRing}
        onClose={editKeyRingClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "650px",
            },
          },
        }}
        aria-labelledby="edit-key-ring-title"
        aria-describedby="edit-key-ring-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="edit-key-ring-title"
        >
          <Typography variant="h4">Edit Key Ring</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={editKeyRingClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        label="Ring ID"
                        id="ring-id"
                        name="ring_id"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        placeholder="12345"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top-end"
                                title={
                                  <Typography>
                                    Issued by SOVA, used to add POI records by
                                    email
                                  </Typography>
                                }
                              >
                                <ErrorIcon color="primary" />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        label="Ring name*"
                        id="ring-name"
                        name="ring_name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        placeholder="345"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        label="Ring code*"
                        id="ring-code"
                        name="ring_code"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        placeholder="2345"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        label="Ring group*"
                        id="ring-group"
                        name="ring_group"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        placeholder="456"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel
                        shrink
                        id="ring-security-level"
                        sx={{
                          background: "#fff",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Ring security level*
                      </InputLabel>
                      <Select
                        labelId="key-ring"
                        id="ring-security-level"
                        name="ring_securitylvl"
                        value={values.ring_securitylvl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        displayEmpty
                        disabled
                      >
                        {securityLvl.map((data) => {
                          return (
                            <MenuItem value={data} key={data}>
                              {data}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <DatePicker
                        id="exp-date"
                        label="Expiry date*"
                        name="expiry_date"
                        value={values.expiry_date}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.expiry_date && errors.expiry_date
                        )}
                        helperText={touched.expiry_date && errors.expiry_date}
                        onChange={(newValue) => {
                          setFieldValue("expiry_date", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Click to select",
                            }}
                          />
                        )}
                      />
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.expiry_date && errors.expiry_date}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={editKeyRingClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditKeyRing;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Formik, FieldArray } from "formik";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import {
  Typography,
  Box,
  Button,
  Grid,
  Card,
  TextField,
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  DialogActions,
  DialogTitle,
  Dialog,
  Tooltip,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  RemoveRedEyeRounded as RemoveRedEyeRoundedIcon,
  BorderColorRounded as BorderColorRoundedIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Remove as RemoveIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  ListRules,
  ListItem,
  SaveRules,
  ViewRules,
  DeleteRules,
} from "../../../api";

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const LostAndFoundRule = () => {
  const classes = useStyles();
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;

  const [loading, setLoading] = useState(false);
  const [listing, setListing] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [editList, setEditList] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();

  const { ruleName, notification, data } = editList;
  const initialValues = {
    ruleName: ruleName ? ruleName : "",
    notification: notification ? notification : "",
    data:
      data && data.length > 0
        ? data
        : [
            {
              Category: "",
              Item: "",
              Comparison: "is equal to",
              value: "",
            },
          ],
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteOpen = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };
  const viewRules = () => {
    ViewRules(id)
      .then((response) => {
        setEditList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validationSchema = Yup.object().shape({
    ruleName: Yup.string().required("Please Provide Rule Name"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        id: id ? id : null,
        data: values.data,
        ruleName: values.ruleName,
        notification: values.notification,
      };
      SaveRules(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            listRules();
            navigate("/settings/corporate-user/lost-and-found-rule");
            resetForm();
            if (id) {
              dispatch(getMsg("Data Updated Successfully"));
            } else {
              dispatch(getMsg("Data Added Successfully"));
            }
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something Went Wrong, Please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          dispatch(getSnackClose());
        });
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const listRules = () => {
    setLoading(true);
    ListRules()
      .then((res) => {
        if (res.statusCode == 200) {
          setListing(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          console.log("Something Went Wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const deleteRules = () => {
    DeleteRules(deleteId)
      .then((res) => {
        if (res.statusCode === 200) {
          handleDeleteClose();
          listRules();
          dispatch(getMsg("Data deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const listItem = () => {
    ListItem()
      .then((res) => {
        if (res.statusCode == 200) {
          setItemList(res.data);
        } else {
          setItemList([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    listRules();
    listItem();
    viewRules();
  }, [id]);

  return (
    <>
      <Helmet title="Lost and Found Rules | SOVA" />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete Rules ?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={deleteRules} autoFocus variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h3" gutterBottom>
        Lost and Found Rule Generator
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ textAlign: "justify" }}>
        The form below is used to set rules regarding lost and found. You can
        setup a rule that instructs SOVA to send notifications based on rules.
        For example, perhaps you want to be notified if a high value item like a
        Rolex watch or a controlled item such as medication is taken into
        inventory. Use the plus icon to add more custom rules like: category
        equals 'jewelry' and description contains 'diamond'.
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom mt={4}>
          New Rule
        </Typography>
        {Object.keys(editList).length ? (
          <Button
            component={NavLink}
            to={"/settings/corporate-user/lost-and-found-rule"}
            color="primary"
            variant="contained"
          >
            Add New Rule
          </Button>
        ) : (
          ""
        )}
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldValue,
            values,
            status,
            errors,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} mt={5}>
                  <FormControl>
                    <CustomTextField
                      id="ruleName"
                      name="ruleName"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="Enter a Name for this Rule"
                      autoComplete="off"
                      placeholder="Details About the Rule. Be Specific"
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6}></Grid>
                <FieldArray name="data">
                  {({ push, remove }) => (
                    <>
                      {values.data.map((row, index) => (
                        <Grid container spacing={2} pl={4} mt={3} key={index}>
                          <Grid item lg={2} md={2}>
                            <FormControl mt={3}>
                              <InputLabel id={`category-${index}`}>
                                Category
                              </InputLabel>
                              <Select
                                labelId={`category-${index}`}
                                id={`category-${index}`}
                                name={`data[${index}].Category`}
                                label="Category"
                                value={row.Category}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setFieldValue(
                                    `data[${index}].Category`,
                                    value
                                  );
                                  // Clear the "Item" field when "Category" changes to "Description"
                                  if (value === "Description") {
                                    setFieldValue(`data[${index}].Item`, "");
                                  }
                                }}
                                onBlur={handleBlur}
                                displayEmpty
                              >
                                <MenuItem value="Category">Category</MenuItem>
                                <MenuItem value="Description">
                                  Description
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item lg={3} md={3}>
                            {row.Category !== "Description" && (
                              <FormControl mt={3}>
                                <InputLabel id="demo-simple-select-label">
                                  Item
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select-label"
                                  label="Item"
                                  name={`data[${index}].Item`}
                                  value={row.Item}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    setFieldValue(`data[${index}].Item`, value);
                                  }}
                                  onBlur={handleBlur}
                                  displayEmpty
                                >
                                  {itemList.map((item) => {
                                    return (
                                      <MenuItem value={item.value}>
                                        {" "}
                                        {item.key}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item lg={3} md={3}>
                            <FormControl mt={3}>
                              <InputLabel id="demo-simple-select-label">
                                Comparison
                              </InputLabel>
                              <Select
                                labelId={`comparison-${index}`}
                                id={`comparison-${index}`}
                                name={`data[${index}].Comparison`}
                                label="Comparison"
                                value={row.Comparison}
                                displayEmpty
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setFieldValue(
                                    `data[${index}].Comparison`,
                                    value
                                  );
                                }}
                                onBlur={handleBlur}
                              >
                                <MenuItem value="is equal to">
                                  is equal to
                                </MenuItem>
                                <MenuItem value="is less than">
                                  is less than
                                </MenuItem>
                                <MenuItem value="is greater than">
                                  is greater than
                                </MenuItem>
                                <MenuItem value="contains">contains</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item lg={2} md={2} mt={3}>
                            <FormControl>
                              <TextField
                                label="Value"
                                name={`data[${index}].value`}
                                value={row.value}
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={2} md={2}>
                            <FormControl>
                              <Typography variant="body2" gutterBottom>
                                Action
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  marginTop: "2px",
                                }}
                              >
                                <IconButton>
                                  <AddCircleOutlineIcon
                                    onClick={() =>
                                      push({
                                        Category: "",
                                        Item: "",
                                        Comparison: "is equal to",
                                        value: "",
                                      })
                                    }
                                  />
                                </IconButton>
                                {values.data.length > 1 && (
                                  <IconButton onClick={() => remove(index)}>
                                    <RemoveIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                </FieldArray>
                <Grid item lg={12} md={12} mt={3}>
                  <FormControl>
                    <TextField
                      id="outlined"
                      label="Enter Notification to show"
                      name="notification"
                      placeholder="Enter Notification to show"
                      value={values.notification}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid mt={3}>
                <Button type="submit" color="primary" variant="contained">
                  Submit
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
      <Typography variant="h6" gutterBottom mt={7}>
        Existing Rules
      </Typography>
      <Card
        sx={{
          marginTop: "25px",
        }}
      >
        <Table sx={{ overflow: "scroll" }}>
          <TableHead>
            <TableRow>
              <TableCell>Rule Name</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listing.map((row, index) => (
              <TableRow>
                <TableCell>{row.rule_name}</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>

                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title={"View Rule"}>
                      <IconButton
                        sx={{
                          background: "#b3e5fc",
                          padding: "10px",
                        }}
                        color="primary"
                      >
                        <RemoveRedEyeRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edit Rule"}>
                      <IconButton
                        sx={{
                          background: "#a5d6a7",
                          padding: "10px",
                          mx: "10px",
                        }}
                        color="success"
                        component={NavLink}
                        to={`/settings/corporate-user/lost-and-found-rule/${row.id}`}
                      >
                        <BorderColorRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete Rule"}>
                      <IconButton
                        sx={{
                          color: "#b71c1c",
                          background: "#f18c8378",
                          padding: "10px",
                        }}
                        onClick={() => handleDeleteOpen(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default LostAndFoundRule;

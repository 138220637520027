import React, { useEffect, useState, useRef } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { getCoordsOnCircle } from "../../../utils/utils";

function ProgressValueProvider({
  children,
  initialValue,
  finalValue,
  animationeDelayMS,
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setValue(finalValue);
      clearTimeout(timeoutId);
    }, animationeDelayMS);
  }, [finalValue]);

  return <>{children(value)}</>;
}

const gaugeNumbers = [
  {
    numLabelValue: "0",
    angleDeg: 205,
  },
  {
    numLabelValue: 25,
    angleDeg: 155,
  },
  {
    numLabelValue: "50",
    angleDeg: 90,
  },
  {
    numLabelValue: "75",
    angleDeg: 25,
  },
  {
    numLabelValue: "100",
    angleDeg: 335,
  },
];

export default function CustomGauge({
  progressPercent,
  strokeSize = 9,
  centerLabel,
  centerLabelClasses,
  mainWrapperClasses,
  circularProgressStyles,
  circleRatio = 0.66,
}) {
  const [centerPoint, setCenterPoint] = useState({ x: 0, y: 0 });
  const [gaugeRadiusPx, setGaugeRadiusPx] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      const centerX = width / 2;
      const centerY = height / 2;
      setGaugeRadiusPx(centerX);
      setCenterPoint({ x: centerX, y: centerY });
    }
  }, [ref.current]);

  return (
    <article
      ref={ref}
      className={`relative ${mainWrapperClasses}`}
      style={{
        width: 135,
        height: 135,
        maxWidth: 135,
        maxHeight: 135,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <ProgressValueProvider
        initialValue={0}
        finalValue={progressPercent}
        animationeDelayMS={10}
      >
        {(value) => (
          <CircularProgressbarWithChildren
            value={value}
            circleRatio={circleRatio}
            strokeWidth={strokeSize}
            styles={circularProgressStyles}
          >
            <h6
              className={`text-xl font-black md:text-3xl xl:text-[1.5rem] ${centerLabelClasses}`}
            >
              {centerLabel}
            </h6>
          </CircularProgressbarWithChildren>
        )}
      </ProgressValueProvider>
      {gaugeNumbers.map((data, index) => {
        const { x, y } = getCoordsOnCircle(
          data.angleDeg,
          gaugeRadiusPx,
          centerPoint,
          //Font-size is ~10px, and half of that is a good offset value
          strokeSize + 10 / 2
        );

        return (
          <p
            key={index}
            className={`absolute text-[0.625rem] text-[#959595]`}
            style={{
              top: y,
              left: x,
            }}
          >
            {data.numLabelValue}
          </p>
        );
      })}
    </article>
  );
}

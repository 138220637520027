import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import { SearchVisitor } from "../../api";
import KioskModeSubmitPassword from "./KioskModeSubmitPassword";

import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  FormHelperText,
  CircularProgress,
  Paper,
} from "@mui/material";
const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function ReturningVisitorInfo() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const locationDetail = localStorage.getItem("location");
  const name = JSON.parse(locationDetail);

  const searchParameter = location.state.searchParam;

  const [errorMessage, setErrorMessage] = useState(false);
  const [kioskModeSubmitPasswordModal, setKioskModeSubmitPasswordModal] =
    useState(false);
  const kioskModeSubmitPasswordModalClose = () => {
    setKioskModeSubmitPasswordModal(false);
  };
  const [loading, setLoading] = useState(false); //State for loader
  let initialValues = {
    visitor_name: "",
  };
  const validationSchema = Yup.object().shape({
    visitor_name: Yup.string().required("Please enter your name"),
  });
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setLoading(true);
    let payload = {
      search: searchParameter,
      visitor_name: values.visitor_name,
      partial: 0,
    };
    SearchVisitor(payload).then((response) => {
      setLoading(false);
      if (response.statusCode == 404) {
        setErrorMessage(true);
      }
      if (response.statusCode == 200) {
        response.signOut = 0;
        if (searchParameter == "SignOut") {
          response.signOut = 1;
        }
        navigate("/visitors/returning-visitors", { state: { response } });
      }
    });
  };

  // handle fullscreen
  const launchFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };
  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setKioskModeSubmitPasswordModal(true);
    }
  }

  const updateParentState = () => {
    kioskModeSubmitPasswordModalClose();
  };
  return (
    <>
      <Box sx={{ p: 5, my: "auto" }} className={classes.startOverCard}>
        <Card sx={{ maxWidth: "1140px", mx: "auto" }}>
          <CardHeader
            title={
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    {name.LocationName}
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    Returning Visitor
                  </Typography>
                </Box>
              </>
            }
          />
          <CardContent>
            {errorMessage == false ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, handleSubmit, values, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={10} justifyContent="space-between">
                      <Grid item xs={12} md={12} sx={{ mx: "auto" }}>
                        <TextField
                          fullWidth
                          placeholder="Enter exact name or phone number (9 digits only) then tap search"
                          name="visitor_name"
                          id="visitor-name"
                          value={values.visitor_name}
                          onChange={(e) => {
                            setFieldValue("visitor_name", e.target.value);
                          }}
                        />

                        <FormHelperText error>
                          {errors.visitor_name}
                        </FormHelperText>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="outlined"
                          component={NavLink}
                          to="/visitors/scan-qr-code"
                        >
                          Scan QR code
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Search Name
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontSize: 20,
                }}
              >
                We're sorry. We cannot find a visitor with that name. Please
                stop by the security office for assistance. <br />
                Thank you for using our visitor kiosk.
              </p>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end", mt: "15px" }}>
            <Button component={NavLink} to="/visitors/kiosk-mode">
              Start over
            </Button>
          </CardActions>
        </Card>
      </Box>
      {kioskModeSubmitPasswordModal && (
        <KioskModeSubmitPassword
          kioskModeSubmitPasswordModal={kioskModeSubmitPasswordModal}
          setKioskModeSubmitPasswordModal={setKioskModeSubmitPasswordModal}
          kioskModeSubmitPasswordModalClose={kioskModeSubmitPasswordModalClose}
          launchFullScreen={launchFullScreen}
          onDataFromChild={updateParentState}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

export default ReturningVisitorInfo;

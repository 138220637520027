import React, { useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { NavLink, useNavigate, Link } from "react-router-dom";
import ImageListingModal from "../../components/ImageGallery/ImageListingModal";

import {
  IconButton,
  ListItemText as MuiListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import {
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  RateReview as RateReviewIcon,
  Image as ImageIcon,
  LocalShipping as LocalShippingIcon,
  Label as LabelIcon,
} from "@mui/icons-material";
import WithPermissionHide from "../../../utils/withPermissionHide";

const ListingAction = ({
  data,
  setShowSubItemModal,
  activityLogTable,
  setRecordId,
  setShipmentRequest,
  setShowRevisionsModal,
  setShowComLogModal,
  setShowShippingLabel,
  selectedRecordData,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedImg, setClickedImg] = useState(null);
  const [noShipReason, setNoShipReason] = useState(false);
  const [shippingLabel, setShippingLabel] = useState(false);
  const handleShippingClose = () => {
    setShippingLabel(false);
    setNoShipReason(false);
  };

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setRecordId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const ListItemText = styled(MuiListItemText)(spacing);
  const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  }));
  const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
  `;

  const viewImage = (link) => {
    setClickedImg(link);
  };
  return (
    <React.Fragment>
      <IconButton
        aria-label="Settings"
        size="large"
        aria-owns={anchorEl ? "icon-menu" : undefined}
        aria-haspopup="true"
        onClick={(e) => handleActionClick(e, data.id)}
        style={{ color: "#1374C5" }}
      >
        <SettingsIcon />
      </IconButton>
      <StyledMenu
        id="icon-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <WithPermissionHide controller="LostAndFound" action="editLostFound">
          <MenuItem
            component={NavLink}
            to={"/lostandfound/edit-lost-found/" + data.id}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Item" pl={1} />
          </MenuItem>
        </WithPermissionHide>
        <WithPermissionHide controller="LostAndFound" action="getBagTag">
          <StyledLink target="_blank" to={"/lostandfound/bag-tag/" + data.id}>
            <MenuItem>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="View Bag Tag" pl={1} />
            </MenuItem>
          </StyledLink>
        </WithPermissionHide>
        {data.image && (
          <MenuItem
            onClick={(e) => {
              viewImage(data.image);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText primary="View Image" pl={1} />
          </MenuItem>
        )}
        <WithPermissionHide controller="LostAndFound" action="GetRevisions">
          <MenuItem
            onClick={(e) => {
              setShowRevisionsModal(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <RateReviewIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Revisions (" + data.lostfoundaudit_count + ")"}
              pl={1}
            />
          </MenuItem>
        </WithPermissionHide>
        <WithPermissionHide controller="LostAndFound" action="childList">
          <MenuItem
            onClick={(e) => {
              setShowSubItemModal(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Sub Items (" + data.sub_items_count + ")"}
              pl={1}
            />
          </MenuItem>
        </WithPermissionHide>
        <WithPermissionHide
          controller="LostAndFound"
          action="SendShipmentRequest"
        >
          <MenuItem
            onClick={(e) => {
              if (data.category_description?.no_ship_reason) {
                // If there's a reason why it can't be shipped, show the 'Cannot Ship' dialog
                setNoShipReason(true); // Open the no ship reason dialog
              } else {
                // If no reason, allow shipment request
                setShipmentRequest(true); // Open the shipping request dialog
              }
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary="Send Ship Request" pl={1} />
          </MenuItem>
        </WithPermissionHide>
        {data.shipment_details?.payment_status === "Paid" && (
          <MenuItem
            onClick={(e) => {
              setAnchorEl(null); // Close the menu first
              if (data.locked_item === "Y") {
                setShippingLabel(true); // Show the dialog explaining the item is locked
              } else {
                setShowShippingLabel(true); // Show the existing shipping label dialog
              }
            }}
          >
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <ListItemText primary="Shipping Label" pl={1} />
          </MenuItem>
        )}
        <WithPermissionHide controller="LostAndFound" action="communicationLog">
          <MenuItem
            onClick={(e) => {
              setShowComLogModal(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText primary="View Comm Log" pl={1} />
          </MenuItem>
        </WithPermissionHide>
        <WithPermissionHide controller="LostAndFound" action="activityLog">
          <MenuItem
            onClick={(e) => {
              activityLogTable(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText primary="View Activity Log" pl={1} />
          </MenuItem>
        </WithPermissionHide>
      </StyledMenu>
      {clickedImg && (
        <ImageListingModal
          clickedImg={clickedImg}
          handelRotationRight={false}
          setClickedImg={setClickedImg}
          handelRotationLeft={false}
        />
      )}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        open={noShipReason}
        onClose={handleShippingClose}
      >
        <DialogTitle>Cannot Ship</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            This item cannot be shipped for the following reasons:
          </Typography>
          <Typography variant="body2" mt={2}>
            {data.category_description?.no_ship_reason || "No reason provided."}
          </Typography>
          <Typography variant="body2" color="primary" mt={3}>
            "If this category is incorrect, edit the lost and found item and
            change the category".
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleShippingClose}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        open={shippingLabel}
        onClose={handleShippingClose}
      >
        <DialogContent>
          The record is locked, so the shipping label cannot be printed. To
          print the label, edit the lost and found record and review the reason
          for the lock. If the reason does not recommend prevent shipping the
          item, then remove the lock and print the label.
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleShippingClose}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ListingAction;

import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { DateTimePicker } from "@mui/lab";
import { SketchPicker } from "react-color";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  CorporateList,
  EditNotification,
  GetNotificationView,
} from "../../../api";
import CustomSelect from "./../../../components/form-components/CustomSelect";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";

import {
  Typography,
  Select,
  TextField,
  Grid,
  FormControl as MuiFormControl,
  Button,
  Box,
  FormHelperText,
  CardContent,
  Card,
  Autocomplete,
  Checkbox,
  Alert,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";

const EditNotify = () => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
    tooltipIcon: {
      display: "inline-flex",
      verticalAlign: "middle",
      cursor: "pointer",
      marginLeft: "6px",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  // Initial values
  const [initialValues, setInitialValues] = useState({
    location: "",
    corporate: [],
    description: "",
    start_date: null,
    end_date: null,
    map_pin_color: "#ffffff",
  });

  const validationSchema = Yup.object({
    corporate: Yup.array()
      .min(1, "At least one corporate must be selected")
      .required("This field is required"),
    description: Yup.string().required("This field is required"),
    start_date: Yup.date().required("This field is required"),
    end_date: Yup.date()
      .min(Yup.ref("start_date"), "End date can't be before start date")
      .required("This field is required"),
    map_pin_color: Yup.string().required("This field is required"),
  });

  const QuillWrapper = styled.div`
    .ql-editor {
      min-height: 200px;
    }
  `;
  const [pageLoad, setPageLoad] = useState(false);

  // Icons for the checkboxes
  const icon = <Checkbox />;
  const checkedIcon = <Checkbox checked />;

  //handle form dropdown and edit data
  const [corporateList, setCorporateList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetNotificationView(id);
      const data = response.data;

      setEditData({
        ...data,
        // Split the corporate_id string into an array of numbers
        corporate: data.corporate_id
          ? data.corporate_id.split(",").map(Number)
          : [],
        location: data.location_id
          ? data.location_id.split(",").map(Number)
          : [],
      });

      // Fetch corporate list and set it using setCorporateList
      const corporateResponse = await CorporateList();
      setCorporateList(corporateResponse.data);
    };

    fetchData();
  }, [id]);

  //Add Notify
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setPageLoad(true);
    try {
      let formData = {
        location_id: values.location,
        corporate_id: values.corporate.join(","),
        description: values.description,
        startDate: values.start_date,
        endDate: values.end_date,
        hexCode: values.map_pin_color,
      };
      EditNotification(id, formData)
        .then((res) => {
          if (res.statusCode === 200) {
            navigate({
              pathname: "/settings/corporate-user/past-notify",
            });
            dispatch(getMsg("Notification has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setPageLoad(false);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setPageLoad(false);
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setPageLoad(false);
        });
    } catch (error) {
      setErrors({ submit: error.message });
      setPageLoad(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          description: editData.description || "",
          start_date: editData.startDate || new Date(),
          end_date: editData.endDate || new Date(),
          map_pin_color: editData.hexCode || "#FFFFFF",
          location: editData.location_id || "",
          corporate: editData.corporate || [],
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
        }) => (
          <Card mb={6}>
            <CardContent>
              <form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <Typography variant="h3">Edit Notification Div</Typography>
                <Grid container spacing={4}>
                  <Grid item lg={3} md={3} mt={10}>
                    <FormControl mt={3}>
                      <Autocomplete
                        multiple
                        name="corporate"
                        id="checkboxes-tags-demo"
                        options={corporateList}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.corporate_name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.id}>
                            {" "}
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                            />
                            {option.corporate_name}
                          </li>
                        )}
                        value={corporateList.filter((option) =>
                          values.corporate.includes(option.id)
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Corporate *"
                            placeholder="Select"
                          />
                        )}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "corporate",
                            newValue.map((item) => item.id)
                          );
                        }}
                      />
                      <FormHelperText error>
                        {touched.corporateList && errors.corporateList}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} md={3} sx={{ marginTop: "40px" }}>
                    <FormControl mt={3}>
                      <CustomSelect
                        name="location"
                        fieldLabel="Location"
                        options={locationList}
                        displayEmpty
                      />
                      <FormHelperText error>
                        {touched.locationList && errors.locationList}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={9} md={9}></Grid>
                  <Grid item lg={12} md={12} mt={3}>
                    <FormControl>
                      <QuillWrapper>
                        <ReactQuill
                          theme="snow"
                          name="description"
                          value={values.description}
                          onChange={(newValue) =>
                            setFieldValue("description", newValue)
                          }
                          placeholder="Type something.."
                        />
                      </QuillWrapper>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} md={4} mt={3}>
                    <FormControl>
                      <DateTimePicker
                        id="start_date"
                        label="Start Date *"
                        value={values.start_date}
                        onChange={(newValue) => {
                          setFieldValue("start_date", newValue);
                        }}
                        inputFormat="MM/dd/yyyy"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="start_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy HH:mm",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} md={4} mt={3}>
                    <FormControl>
                      <DateTimePicker
                        id="end_date"
                        label="End Date *"
                        value={values.end_date}
                        onChange={(newValue) => {
                          setFieldValue("end_date", newValue);
                        }}
                        inputFormat="MM/dd/yyyy"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="end_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy HH:mm",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} md={4} mt={3}>
                    <FormControl>
                      <TextField
                        sx={{ width: "100%" }}
                        name="map_pin_color"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.map_pin_color}
                        label="Div Background Color"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="color"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginTop: "10px" }}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default EditNotify;

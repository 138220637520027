import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { DateTimePicker } from "@mui/lab";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { handleApiResonseErrors } from "../../helper/helper";
import { ViewEquipDetial, AddUpdateEquipmentDetail } from "../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/equipmentSlice";
// import classNames from "classnames";
import PropTypes from "prop-types";
import MaintenanceCycle from "./innerform/MaintenanceCycle";

import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Box,
  Menu,
  FormHelperText,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  RadioGroup,
  Radio,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddVisitorForm(props) {
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const [datevalue, setDateValue] = React.useState(null);
  const [datesigned, setDateSigned] = React.useState(null);
  const classes = useStyles();
  const [viewEquipment, setViewEquipment] = useState({});
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const viewEquipmentDetail = () => {
    setLoading(true);
    ViewEquipDetial(id)
      .then((res) => {
        setViewEquipment(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    viewEquipmentDetail();
  }, []);

  const {
    company_address1,
    company_address2,
    company_city,
    company_contact_fname,
    company_contact_lname,
    company_name,
    company_website,
    date_put_in_service,
    make,
    model,
    purchase_date,
    purchase_price,
    serial_no,
    company_email,
    company_state,
    company_zip,
    warranty_expiration_date,
  } = viewEquipment;

  //Add Company Select Control
  let initialValues = {
    company_name: company_name ? company_name : "",
    company_address_1: company_address1 ? company_address1 : "",
    company_address_2: company_address2 ? company_address2 : "",
    city: company_city ? company_city : "",
    state: company_state ? company_state : "",
    zip: company_zip ? company_zip : "",
    company_email: company_email ? company_email : "",
    company_website: company_website ? company_website : "",
    company_last_name: company_contact_lname ? company_contact_lname : "",
    company_first_name: company_contact_fname ? company_contact_fname : "",
    date_put_in_service: date_put_in_service ? date_put_in_service : new Date(),
    // month: 1,
    // day: 1,
    // year: 2022,
    purchase_price: purchase_price ? purchase_price : "",
    purchase_date: purchase_date ? purchase_date : null,
    make: make ? make : "",
    model: model ? model : "",
    serial: serial_no ? serial_no : "",
    warranty_expiration_date: warranty_expiration_date
      ? warranty_expiration_date
      : new Date(),
    // service_month: 1,
    // service_day: 1,
    // service_year: 2022,
    // service_hours: 4,
    // service_minute: 34,
    // meridian: "AM",
  };

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    company_email: Yup.string().nullable().email("Please enter valid email"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        equipment_id: id,
        company_name: values.company_name,
        company_address1: values.company_address_1,
        company_address2: values.company_address_2,
        company_city: values.city,
        company_state: values.state,
        company_zip: values.zip,
        company_email: values.company_email,
        company_website: values.company_website,
        company_contact_fname: values.company_first_name,
        company_contact_lname: values.company_last_name,
        purchase_price: values.purchase_price,
        make: values.make,
        model: values.model,
        serial_no: values.serial,
        date_put_in_service: values.date_put_in_service,
        purchase_date: values.purchase_date,
        warranty_expiration_date: values.warranty_expiration_date,
      };
      AddUpdateEquipmentDetail(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Equipment detail has been updated successfully"));
            dispatch(getSnackAlert("success"));
            setLoading(false);
            navigate({
              pathname: "/equipment/equipment-detail",
            });
          } else {
            dispatch(getSnackClose());
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          // if (error) {
          //   setErrMsg(error);
          // }
          dispatch(getSnackClose());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // Maintenance Cycle Modal
  const [maintenanceCycle, setMaintenanceCycle] = React.useState(false);
  const maintenanceCycleOpen = () => {
    setMaintenanceCycle(true);
  };
  const maintenanceCycleClose = () => {
    setMaintenanceCycle(false);
  };
  return (
    <>
      {" "}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Box className={classes.accordion}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="step1-employee"
                      id="step1-employee"
                    >
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        sx={{ marginBottom: 0 }}
                      >
                        Equipment Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={6} alignItems="end">
                        <Grid item xs={12} md={12}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="company-name"
                              label="Company name"
                              name="company_name"
                              value={values.company_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="company-add1"
                              label="Company address 1"
                              name="company_address_1"
                              value={values.company_address_1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="company-add2"
                              label="Company address 2"
                              name="company_address_2"
                              value={values.company_address_2}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="city"
                              label="City"
                              name="city"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="state"
                              label="State"
                              name="state"
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="zip"
                              label="Zip"
                              name="zip"
                              value={values.zip}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="company-email"
                              label="Company email"
                              name="company_email"
                              value={values.company_email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.company_email && errors.company_email
                              )}
                              helperText={
                                touched.company_email && errors.company_email
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="company-website"
                              label="Company website"
                              name="company_website"
                              value={values.company_website}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="company-last-name"
                              label="Company first name"
                              name="company_first_name"
                              value={values.company_first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="company-last-name"
                              label="Company last name"
                              name="company_last_name"
                              value={values.company_last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <DatePicker
                            id="last-processed"
                            label="Purchase date"
                            name="purchase_date"
                            value={values.purchase_date}
                            onChange={(newValue) => {
                              setFieldValue("purchase_date", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "Click to select",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="purchase-price"
                              label="Purchase price"
                              name="purchase_price"
                              value={values.purchase_price}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion mt={4}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="step2-employee"
                      id="step2-employee"
                    >
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        sx={{ marginBottom: 0 }}
                      >
                        Purchase Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={6} alignItems="end">
                        <Grid item xs={12} md={12} lg={4}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="make"
                              label="Make"
                              name="make"
                              value={values.make}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="model"
                              label="Model"
                              name="model"
                              value={values.model}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="serial"
                              label="Serial"
                              name="serial"
                              value={values.serial}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <DateTimePicker
                            id="date-range"
                            label="Date put in service"
                            name="date_put_in_service"
                            value={values.date_put_in_service}
                            onChange={(newValue) => {
                              setFieldValue("date_put_in_service", newValue);
                            }}
                            ampm={false}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm/dd/yyyy H:i:s",
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion mt={4}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="step3-employee"
                      id="step3-employee"
                    >
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        sx={{ marginBottom: 0 }}
                      >
                        Maintenance Information
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={12}>
                          <FormControl>
                            <DatePicker
                              id="date-range"
                              label="Warranty expiration date"
                              name="warranty_expiration_date"
                              value={values.warranty_expiration_date}
                              onChange={(newValue) => {
                                setFieldValue(
                                  "warranty_expiration_date",
                                  newValue
                                );
                                setDateValue(format(newValue, "yyyy-MM-dd"));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl>
                            <FormLabel>Maintenance Cycle</FormLabel>
                            <FormHelperText sx={{ marginLeft: 0 }}>
                              Click here to open maintenance cycle popup
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Grid item xs={12} md={12}>
                  <WithPermissionHide
                    controller="Equipment"
                    action="updateEquipmentDetail"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      mt={6}
                    >
                      Save
                    </Button>
                  </WithPermissionHide>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function ViewEquipmentDetail() {
  return (
    <React.Fragment>
      <WithPermissionFallback
        controller="Equipment"
        action="viewEquipmentDetail"
      >
        <Helmet title="View Equipment Detail" />
        <Typography variant="h3" gutterBottom display="inline">
          View Equipment Detail
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/equipment/equipment-detail">
            Equipment Detail
          </Link>
          <Typography>View Equipment Detail</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <AddVisitorForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ViewEquipmentDetail;

export default [
  {
    id: "Number",
    label: "Number",
  },
  {
    id: "Classification",
    label: "Classification",
  },
  {
    id: "Datetime",
    label: "Datetime",
  },
  {
    id: "ir_status",
    label: "Disposition",
  },
  {
    id: "IncidentLocation",
    label: "Location",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "UserName",
    label: "Author",
  },
  {
    id: "LastModified",
    label: "Modified",
  },
  {
    id: "Action",
    label: "Action",
  },
];

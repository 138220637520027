import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  incidentList: {
    Number: true,
    Classification: true,
    Datetime: true,
    ir_status: true,
    IncidentLocation: true,
    status: true,
    UserName: true,
    LastModified: true,
    Action: true,
  },
  employeeAccidentList: {
    id: true,
    name: true,
    position: true,
    reportdate: true,
    doctorvisit: true,
    emergencyroom: true,
    enjurytype: true,
    emp: true,
    mgr: true,
    action: true,
  },
};

export const reportTableColSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setIncidentList: (state, action) => {
      state.incidentList = action.payload;
    },
    setEmployeeAccidentList: (state, action) => {
      state.employeeAccidentList = action.payload;
    },
  },
});

export const { setIncidentList, setEmployeeAccidentList } =
  reportTableColSlice.actions;

export default reportTableColSlice.reducer;

export default [
  {
    id: "visitor_name",
    label: "Visitor Name",
  },
  {
    id: "date_added",
    label: "Date Added",
  },
  {
    id: "added_by",
    label: "Added By",
  },
  {
    id: "expiry_date",
    label: "Expiry Date",
  },
  {
    id: "reason",
    label: "Reason",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];

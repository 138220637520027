import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  Chip,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { AddStorageLocation } from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import WithPermissionHide from "../../../utils/withPermissionHide";

// Classes Styles
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const StorageLocationsDialog = ({
  storageLoctaionsDialog,
  storageLocationsDialogClose,
  storageLocation,
  getStorageLocationList,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [fields, setFields] = useState(
    storageLocation.map((item) => {
      return item.value;
    })
  );

  const handleBlur = () => {
    if (fieldValue.trim() !== "") {
      addStorageLocation([...fields, fieldValue]);
    }
    setFieldValue("");
  };

  const handleDelete = (index) => {
    addStorageLocation(fields.filter((item, itemIndex) => itemIndex !== index));
  };

  const addStorageLocation = (data) => {
    setLoading(true);

    const payload = {
      description: data.join(","),
    };
    AddStorageLocation(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setFields(res.data.description.split(","));
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getStorageLocationList();
          setLoading(false);
        } else {
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something Went Wrong!"));
        dispatch(getSnackAlert("Error"));
        dispatch(getSnackOpen());
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="storage-location-title-modal"
        aria-describedby="storage-location-modal"
        open={storageLoctaionsDialog}
        onClose={storageLocationsDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#E8E8E8",
          }}
          id="lift-out-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Storage Description
          </Typography>
          <DialogActions>
            <IconButton
              onClick={storageLocationsDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <Typography sx={{ padding: "20px" }}>
          INSTRUCTIONS: Click into the box below to add your storage locations.
          Type the name, then click the enter key on your keyboard. If you make
          a mistake, remove and enter the name again.
        </Typography>
        <DialogContent>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {fields.map((field, index) => {
              return (
                <Chip
                  key={index}
                  label={field}
                  onDelete={() => handleDelete(index)}
                  color="primary"
                />
              );
            })}
          </Box>
          <WithPermissionHide controller="LostAndFound" action="addStorage">
            <TextField
              sx={{ marginTop: "5px" }}
              fullWidth
              placeholder="Type to add, then press enter key. Click close button when done "
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              onBlur={(e) => handleBlur()}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  handleBlur();
                }
              }}
            />
          </WithPermissionHide>
        </DialogContent>
        <DialogActions sx={{ pr: "28px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={storageLocationsDialogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default StorageLocationsDialog;

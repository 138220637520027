import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  TextField,
  DialogActions,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { TaskLibsView, EditTaskLibs } from "../../../../api";
import { useNavigate } from "react-router-dom";
const taskTag = [
  { key: "Contact", value: "contact" },
  { key: "Photos", value: "photos" },
  { key: "Followup", value: "followup" },
  { key: "Meeting", value: "meeting" },
  { key: "Video", value: "video" },
];
const dueDate = [
  { key: "1 Day", value: "1day" },
  { key: "2 Days", value: "2days" },
  { key: "3 Days", value: "3days" },
];

const EditIrTask = ({
  editIrTask,
  setEditIrTask,
  editIrTaskOpen,
  editIrTaskClose,
  taskLibsList,
  updatedRow,
}) => {
  const [age, setAge] = React.useState("");
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleChange = (event) => {
    const fieldValue = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: fieldValue,
    }));
  };
  console.log("data", formData);
  const taskLibsView = () => {
    TaskLibsView(updatedRow.id)
      .then((res) => {
        if (res.statusCode === 200) {
          setFormData(res.data);
          console.log("successfully", res.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const editTaskLibs = () => {
    EditTaskLibs(formData)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          taskLibsList();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    taskLibsView();
  }, []);
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        divider
        open={editIrTaskOpen}
        onClose={editIrTaskClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4">Edit IR Task</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                marginTop: "30px",
              }}
            >
              <TextField
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                id="outlined-required"
                label="Task Title"
                name="taskTitle"
                onChange={handleChange}
                value={formData.taskTitle}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                marginTop: "30px",
              }}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required
                id="outlined-required"
                label="Task description"
                name="taskDesc"
                onChange={handleChange}
                value={formData.taskDesc}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                marginTop: "20px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Task Tag</InputLabel>
                <Select
                  name="taskTag"
                  value={formData.taskTag}
                  onChange={handleChange}
                  displayEmpty
                >
                  {taskTag.map((item) => {
                    return <MenuItem value={item.value}> {item.key}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                marginTop: "20px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="description">Due Date</InputLabel>
                <Select
                  name="due_date"
                  value={formData.due_date}
                  onChange={handleChange}
                  displayEmpty
                >
                  {dueDate.map((item) => {
                    return <MenuItem value={item.value}> {item.key}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            onClick={() => {
              editTaskLibs();
              editIrTaskClose();
            }}
            variant="outlined"
            color="primary"
          >
            Save
          </Button>
          <Button variant="outlined" onClick={editIrTaskClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditIrTask;

import React, { useState } from "react";
import { Switch } from "@mui/material";
import { ChangeVenueStatus } from "../../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";

const VenueStatusToggle = (props) => {
  const dispatch = useDispatch();
  const { value, id } = props;
  const [active, setActive] = useState(value === 1 ? true : false);
  const handleActive = (status, id) => {
    let payload = {
      status: status === true ? "0" : "1",
      id: id,
    };
    ChangeVenueStatus(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("Venue status updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Switch
        checked={active}
        onClick={() => {
          setActive(!active);
          handleActive(active, id);
        }}
      />
    </>
  );
};

export default VenueStatusToggle;

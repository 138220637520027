import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import { usePlacesWidget } from "react-google-autocomplete";
import { sova } from "../../config";

const GoogleAutoCompleteField = ({ getApiResponse, name, ...otherProps }) => {
  const [field, meta] = useField(name);
  var componentForm = {
    street_number: "short_name",
    route: "short_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name",
  };

  const { ref } = usePlacesWidget({
    apiKey: sova.GoogleApiKey,
    options: {
      types: ["geocode"],
    },
    onPlaceSelected: (place) => {
      console.log("+++++", place);
      let compAddress = {
        street_number: "",
        route: "",
        locality: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
        formatted_address: "",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]];
          compAddress[addressType] = val;
        }
      }
      if (compAddress.street_number && compAddress.route) {
        compAddress.address =
          compAddress.street_number + " " + compAddress.route;
      } else {
        compAddress.address = compAddress.locality;
      }
      compAddress.formatted_address = place.formatted_address;
      getApiResponse(compAddress);
    },
  });

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    inputRef: ref,
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return <TextField {...configTextfield} />;
};

export default GoogleAutoCompleteField;

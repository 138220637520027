import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
  List,
  ListItem,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Tooltip,
  ButtonGroup,
  Avatar,
  TextareaAutosize as MuiTextareaAutosize,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components/macro";
import { DatePicker, TimePicker } from "@mui/lab";
import { format } from "date-fns";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Mail as MailIcon,
  Mail as ErrorIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Cancel as CancelIcon,
  ListAlt as ListAltIcon,
  PictureAsPdf as PictureAsPdfIcon,
  PieChart as PieChartIcon,
  Create as CreateIcon,
  CameraAlt as CameraAltIcon,
  Send as SendIcon,
  SettingsInputComponent as SettingsInputComponentIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import moment from "moment";
import CustomDateRange from "../../../components/form-components/CustomDateRange";
import { UserActivityLog } from "../../../api";

const Paper = styled(MuiPaper)(spacing);
const PerformDialog1 = (props) => {
  const {
    useractivity,
    setUserActivity,
    userActivityClose,
    userIdActivity,
    userNameActivity,
    startDateToFilterA,
    endDateToFilterA,
  } = props;
  const [startDateToFilter, setStartDateToFilter] =
    useState(startDateToFilterA);
  const [endDateToFilter, setEndDateToFilter] = useState(endDateToFilterA);
  const [fadebox, setFadedBox] = React.useState(false);
  const [userActivityList, setUserActivityList] = useState([]);
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader

  const getUserActivityList = () => {
    let formatedsDate = moment(startDateToFilter).format("YYYY-MM-DD");
    let formatedeDate = moment(endDateToFilter).format("YYYY-MM-DD");
    let payload = {
      userId: userIdActivity, //1,
      start_date: formatedsDate, //"2016-08-03",
      end_date: formatedeDate, //"2022-06-01",
    };
    setDialogLoader(true);

    UserActivityLog(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setUserActivityList(res.data);
        } else {
          setUserActivityList([]);
          // setUserActivityList(res.data);
        }
        setDialogLoader(false);
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getUserActivityList();
  }, []);

  return (
    <>
      {/* User Activity Modal */}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        open={useractivity}
        onClose={userActivityClose}
        aria-labelledby="user-activity-title"
        aria-describedby="user-activity-description"
      >
        <DialogTitle
          id="user-activity-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            User Activity Log for: {userNameActivity}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setUserActivity(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Grid container spacing={4} mt={2} sx={{ padding: "0 24px" }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="dateRange"
                label="Date range"
                value={
                  startDateToFilter && endDateToFilter
                    ? moment(startDateToFilter).format("MM-DD-YYYY") +
                      " - " +
                      moment(endDateToFilter).format("MM-DD-YYYY")
                    : ""
                }
                onClick={() => setFadedBox((prev) => !prev)}
                // onChange={handleChange}

                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Select date range"
              />
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                  }}
                  selectedStart={startDateToFilter}
                  selectedEnd={endDateToFilter}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                color="primary"
                variant="contained"
                sx={{ height: "100%" }}
                onClick={getUserActivityList}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Paper mt={3}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Activity Time</TableCell>
                    <TableCell>Activity Type</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogLoader ? (
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <>
                      {userActivityList.map((item) => {
                        return (
                          <TableRow hover key={item.id}>
                            <TableCell>{item.activity_time}</TableCell>
                            <TableCell>{item.activity_type}</TableCell>
                            <TableCell>{item.location}</TableCell>
                            <TableCell>{item.comments}</TableCell>
                          </TableRow>
                        );
                      })}
                      {userActivityList.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            No data for selected date range
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={userActivityClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PerformDialog1;

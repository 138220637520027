import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { UpdateVisitorWatchListStatus } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
const StatusWatchListToggle = (props) => {
  const { id, value } = props;
  const [status, setStatus] = useState(value === 1 ? true : false);
  const dispatch = useDispatch();

  const handleStatus = (status, id) => {
    let payload = {
      id: id,
      status: status === true ? 0 : 1,
    };
    UpdateVisitorWatchListStatus(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("Status updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={status}
            onClick={() => {
              setStatus(!status);
              handleStatus(status, id);
            }}
          />
        }
        label=""
      />
    </>
  );
};

export default StatusWatchListToggle;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  FormLabel,
  Alert as MuiAlert,
  Paper,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import Textfield from "./components/Textfield";
import axios from "axios";
import { AddCorporateUser } from "../../api";
import { makeStyles } from "@mui/styles";
import MuiPhoneNumber from "material-ui-phone-number";

import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../redux/slices/corporateSlice";
//import { getSnackOpen, getSnackClose } from "../../redux/slices/corporateSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const FormControlSpacing = styled(MuiFormControl)(spacing);
// const BigAvatar = styled(Avatar)`
//   width: 120px;
//   height: 120px;
//   margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
// `;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

function ColumnLeft() {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [pageLoad, setPageLoad] = useState(false);
  const classes = useStyles();

  //Initial values Formik
  const initialValues = {
    corporateName: "",
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    phone: "",
    fax: "",
  };

  //Yup validation schema
  const validationSchema = Yup.object().shape({
    corporateName: Yup.string().required("Please enter corporate name"),
    firstName: Yup.string().required("Please enter first"),
    lastName: Yup.string().required("Please enter last name"),
    title: Yup.string().required("Please enter title"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter email address"),
    phone: Yup.string().matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})?[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      "Please enter a valid phone number"
    ),
    fax: Yup.string()
      .matches(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})?[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        "Please enter a valid fax number"
      )
      .min(10, "Please enter a valid fax number"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setPageLoad(true);
    setIsDisabled(true);
    try {
      let formData = {
        corporate_name: values.corporateName,
        first_name: values.firstName,
        last_name: values.lastName,
        title: values.title,
        email: values.email,
        phone: values.phone,
        fax: values.fax,
      };
      await // timeOut(500);
      AddCorporateUser(formData)
        .then((res) => {
          console.log(res.data.success);
          // navigate("/accounts/list-corporate/");
          if (res.statusCode === 200) {
            setIsDisabled(false);
            // dispatch(getSnackOpen());
            navigate({
              pathname: "/accounts/list-corporate",
            });
            dispatch(getSnackMsg("Corporate has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setPageLoad(false);
          } else {
            // dispatch(getSnackClose());
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setPageLoad(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisabled(false);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setPageLoad(false);
          // dispatch(getSnackClose());
          // setStatus({ sent: true });
          // setSubmitting(false);
        });
    } catch (error) {
      // setStatus({ sent: false });
      setErrors({ submit: error.message });
      setPageLoad(false);
      // setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  Your data has been submitted successfully!
                </Alert>
              )}

              {
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                      <FormControl mt={3}>
                        <Textfield
                          name="corporateName"
                          label="Corporate name *"
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <FormLabel component="label">
                          Contact information:
                        </FormLabel>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <Textfield
                          name="firstName"
                          label="First name *"
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormControl>
                          <Textfield
                            name="lastName"
                            label="Last name *"
                            m={2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormControl>
                          <Textfield
                            name="title"
                            label="Title *"
                            m={2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <Textfield
                          name="email"
                          label="Email *"
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        {/* <Textfield
                          name="phone"
                          label="Phone"
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /> */}
                        <MuiPhoneNumber
                          name="phone"
                          id="phone"
                          label="Phone"
                          value={values.phone}
                          onChange={handleChange("phone")}
                          onBlur={handleBlur}
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultCountry={"us"}
                          variant="outlined"
                          disableAreaCodes={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <Textfield
                          name="fax"
                          label="Fax"
                          m={2}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 10 }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        mt={6}
                        sx={{ width: "175px" }}
                        disabled={isDisabled}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              }
            </CardContent>
          </Card>
        )}
      </Formik>
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function AddCorporate() {
  return (
    <React.Fragment>
      <Helmet title="Add Corporate" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Corporate
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/accounts/list-corporate">
          Accounts
        </Link>
        <Typography>Add Corporate</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <ColumnLeft />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddCorporate;

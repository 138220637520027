import React, { useEffect, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Button,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const ImageDialog = ({ imageDialog, imageDialogClose, poiImages }) => {
  //   const [poiImages, setPoiImages] = useState([]);
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
          },
        },
      }}
      open={imageDialog}
      onClose={imageDialogClose}
      aria-labelledby="viewmap-title"
      aria-describedby="viewmap-description"
    >
      <DialogTitle
        id="viewmap-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4">POI Image</Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton sx={{ marginRight: "-10px" }} onClick={imageDialogClose}>
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            border: "1px solid gray",
            borderRadius: "10px",
            background: "white",
            overflow: "hidden",
          }}
        >
          <SimpleImageSlider
            width={552}
            height={262}
            images={poiImages}
            showBullets={false}
            showNavs={true}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "20px 24px" }}>
        <Button variant="outlined" color="primary" onClick={imageDialogClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageDialog;

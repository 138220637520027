import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  GetAddKeyLockNumberList,
  DeleteKeyLockNumber,
  AddNewKeyLockNumber,
  AddEquipmentGroup,
  GetAddKeyGroupList,
  DeleteKeyGroup,
  UpdateEquipmentGroup,
  ViewEquipmentKeyGroup,
} from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormLabel,
  Typography,
  Checkbox,
  FormGroup,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const EditKeyGroup = ({
  editKeyGroup,
  editKeyGroupClose,
  getKeyGroup,
  isCheckedKeyGroupValue,
  getEquipmentKeyGroupTree,
}) => {
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();

  const [viewKeyGroup, setViewKeyGroup] = useState({});
  const viewEquipKeyGroup = () => {
    ViewEquipmentKeyGroup(isCheckedKeyGroupValue)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewKeyGroup(res.data);
        } else {
          setViewKeyGroup({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { name, parent_id } = viewKeyGroup;
  useEffect(() => {
    viewEquipKeyGroup();
  }, []);

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    key_group_name: Yup.string().required("Please enter key group name"),
  });

  /* Setting the initial values for the form. */
  let initialValues = {
    key_group_name: name ? name : "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      editKeyGroupClose();
      let formData = {
        id: isCheckedKeyGroupValue,
        name: values.key_group_name,
        parent_id: parent_id,
      };
      UpdateEquipmentGroup(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Add key group has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            getKeyGroup();
            getEquipmentKeyGroupTree();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog open={editKeyGroup}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Edit Key Group</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={editKeyGroupClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  {/* <Grid item xs={12} md={12}>
                  <FormControl mt={3}>
                    <FormGroup>
                      <FormLabel>Select parent key group</FormLabel>
                      <FormControlLabel
                        control={<Checkbox defaultChecked disableRipple />}
                        label="Executive"
                        name="executive"
                      />
                      <FormControlLabel
                        control={<Checkbox disableRipple />}
                        label="Tes"
                        name="text"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid> */}
                  <Grid item xs={12} md={12}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="key_group_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key group name"
                        autoComplete="off"
                        placeholder="No special characters"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditKeyGroup;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getControllerActionList } from "../../api";

// Async action to fetch permissions from API
export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async () => {
    const response = await getControllerActionList({ controller_name: "All" });
    console.log("response in permission : ", response);
    return response.data;
  }
);

const initialState = {
  permissions: [], // Stores the user's permissions
  loading: false, // Loading state for the API request
  error: null, // Error state for any issues with fetching permissions
};

export const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    // Synchronous action to manually set permissions (optional, if you ever need it)
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    // Synchronous action to set loading status (optional, for manual loading handling)
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    // Synchronous action to handle errors
    setError: (state, action) => {
      state.error = action.payload;
    },
    // Reset the permission state
    resetPermissions: (state) => {
      state.permissions = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload;
        state.loading = false;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export synchronous actions
export const { setPermissions, setLoading, setError, resetPermissions } =
  permissionSlice.actions;

export default permissionSlice.reducer;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import {
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputLabel as MuiInputLabel,
  Box,
  Button,
  Divider as MuiDivider,
  Switch,
  FormControlLabel,
  ListItemText,
  ListItemIcon,
  MenuItem,
  IconButton,
  Checkbox,
  Toolbar,
  FormGroup,
  FormControl,
  TextField,
  Card,
  Select,
  CardContent,
  TableSortLabel,
  Menu,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import {
  Mail as MailIcon,
  Send as SendIcon,
  ListAlt as ListAltIcon,
  Print as PrintIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
  AddBox as AddBoxIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  ExpandMore as ExpandMoreIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  TourSummaryList,
  TourSummaryColumns,
  TourAutocompleteList,
} from "../../api";
import {
  tourSummaryList,
  getTourSummaryChecked,
} from "../../redux/slices/reportSlice";
import moment from "moment";
import { subDays } from "date-fns";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import { useDispatch, useSelector } from "react-redux";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import WithPermissionFallback from "../../utils/withPermissionFallback";

const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const useStyles = makeStyles((theme) => ({
  // searchFilter: {
  //   position: "relative",
  // },
  // clearSearch: {
  //   position: "absolute",
  //   right: "45px",
  // },
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  ErrorImage: {
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",
    marginBottom: "15px",
  },
}));

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(tname, tby, startedat, finishedat, missed) {
  return {
    tname,
    tby,
    startedat,
    finishedat,
    missed,
  };
}
const rows = [
  createData("jhg", "Sandeep Kundu", "22/05/22", "22/05/22", "dndnfbs"),
  createData("xyz", "Sandeep Kundu", "22/05/22", "22/05/22", "dndnfbs"),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.report.tourSummaryChecked);

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  return (
    <TableHead>
      <TableRow>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            //padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={createSortHandler(headCell.key)}
              // hideSortIcon={headCell.key === "tour_name" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    dataToFilter,
    filterRecord,
    startDateToFilter,
    setStartDateToFilter,
    endDateToFilter,
    setEndDateToFilter,
    colArray,
    getColumnList,
    getColumnChecked,
    handleFirstPage,
    selectTourName,
    setSelectTourName,
    generatePdf,
    setCurrentPage,
  } = props;
  const [actions, setActions] = React.useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const classes = useStyles();
  const [isFilter, setIsFilter] = useState(false);
  const checkedValue = useSelector((state) => state.report.tourSummaryChecked);
  const dispatch = useDispatch();
  const [tourSuggestion, setTourSuggestion] = useState([]);

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);
  //Select
  const [position, setPosition] = React.useState("");
  const selectPosition = (event) => {
    setPosition(event.target.value);
  };
  //const [visitortype, setVisitorType] = React.useState("");
  // const selectVisitorType = (event) => {
  //   setVisitorType(event.target.value);
  // };
  // const [visitorstatus, setVisitorStatus] = React.useState("");
  // const selectVisitorStatus = (event) => {
  //   setVisitorStatus(event.target.value);
  // };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  const [tourSelect, setTourSelect] = React.useState("");
  const selectTour = (event) => {
    setIsFilter(false);
    setSelectTourName(event.target.value.TourName);
    setTourSelect(event.target.value);
  };

  const applySearch = () => {
    const payload = {
      // start_date: startDateToFilter
      //   ? moment(startDateToFilter).format("YYYY-MM-DD")
      //   : "",
      // end_date: endDateToFilter
      //   ? moment(endDateToFilter).format("YYYY-MM-DD")
      //   : "",
      tourId: tourSelect.TourID,
    };
    filterRecord(payload);
    console.log(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter(subDays(new Date(), 3));
    setEndDateToFilter(new Date());
    setTourSelect("");
    setSelectTourName("All tours");
    filterRecord({});
  };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getTourSummaryChecked([...checkedValue, value]));
    } else {
      dispatch(getTourSummaryChecked(checkedValue.filter((e) => e !== value)));
    }
  };

  //Api call for  select corporate dropdown
  const getTourList = () => {
    TourAutocompleteList()
      .then((res) => {
        if (res.statusCode == 200) {
          setTourSuggestion(res.data);
        } else {
          setTourSuggestion([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTourList();
  }, []);

  return (
    <Toolbar>
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div style={{ width: "100%" }}>
        <Paper
          mt={3}
          sx={{
            minWidth: { lg: "600px", md: "500px" },

            "& .MuiFormControl-root": {
              width: "100%",
            },
            "& .MuiGrid-item": {
              paddingTop: "0px !important",
            },
          }}
        >
          <Grid container spacing={6} sx={{ marginTop: { md: 0, xs: "20px" } }}>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ mt: { md: 0, xs: "15px" } }}>
                <TextField
                  fullWidth
                  id="dateRange"
                  //label="Date Range"
                  value={
                    startDateToFilter && endDateToFilter
                      ? moment(startDateToFilter).format("MM/DD/YYYY") +
                        " - " +
                        moment(endDateToFilter).format("MM/DD/YYYY")
                      : ""
                  }
                  onClick={() => setFadedBox((prev) => !prev)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      applySearch();
                      setIsFilter(true);
                      // handleFirstPage();
                      setCurrentPage(1);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Select date range"
                />
              </FormControl>
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                    setIsFilter(true);
                  }}
                  selectedStart={startDateToFilter}
                  selectedEnd={endDateToFilter}
                />
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  mt: { md: 0, xs: "15px" },
                }}
              >
                <Item sx={{ flexGrow: 1, width: "100%" }}>
                  <FormControl>
                    <Select
                      labelId="position"
                      id="position"
                      value={tourSelect}
                      onChange={selectTour}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        Select tour
                      </MenuItem>
                      {tourSuggestion.map((t) => (
                        <MenuItem value={t} key={t.TourID}>
                          {t.TourName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <FormControl>
                    <TextField
                      id="search"
                      className={classes.searchFilter}
                      type="text"
                      fullWidth
                      // value={searchTaskname}
                      onChange={(e) => {
                        // setSearchTaskname(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          // applySearch();
                          // setSearchInput(searchTaskname);
                          // setIsFilter(true);
                          // handleFirstPage();
                        }
                      }}
                      placeholder="Search by task name"
                      // inputProps={{
                      //   sx: {
                      //     //minWidth: "280px",
                      //     paddingRight: "25px",
                      //   },
                      // }}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       {searchTaskname !== "" ? (
                      //         <IconButton
                      //           className={classes.clearSearch}
                      //           aria-label="toggle password visibility"
                      //           onClick={() => {
                      //             setSearchTaskname("");
                      //             setSearchInput("");
                      //             setDataToFilter(null);
                      //           }}
                      //         >
                      //           <CancelIcon />
                      //         </IconButton>
                      //       ) : null}
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                  </FormControl> */}
                </Item>
                <Item>
                  {isFilter ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        resetFilter();
                        // setDataToFilter(null);
                        setIsFilter(false);
                        // setSearchInput("");
                      }}
                      sx={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        height: "100%",
                        marginLeft: "-3px",
                      }}
                    >
                      Clear
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        height: "100%",
                        marginLeft: "-3px",
                      }}
                      onClick={(e) => {
                        applySearch();
                        setIsFilter(true);
                        setCurrentPage(1);
                      }}
                    >
                      {/* <SearchIcon /> */}
                      Search
                    </Button>
                  )}
                </Item>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={generatePdf}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Download PDF" pl={0} />
            </MenuItem>

            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [dataToFilter, setDataToFilter] = useState(null);
  const [startDateToFilter, setStartDateToFilter] = useState(
    subDays(new Date(), 3)
  );
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const dispatch = useDispatch();
  const summaryList = useSelector((state) => state.report.tourSummaryData);
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const newRange =
    moment(startDateToFilter).format("MM/DD/YYYY") +
    " - " +
    moment(endDateToFilter).format("MM/DD/YYYY");
  // const newDatePDF = moment(datePDF).format("MM-DD-YYYY");
  const newDatePDF = moment(new Date()).format("MM/DD/YYYY");
  const newTimePDF = moment(new Date()).format("HH:mm:ss");
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const checkedValue = useSelector((state) => state.report.tourSummaryChecked);
  const [selectTourName, setSelectTourName] = useState("All tours");
  const selectedLocation = useSelector((state) => state.login.Location);

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    setCurrentPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelected([]);
  };

  //Handler for pagination and row change
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  //Method for generating PDF
  const generatePdf = () => {
    const doc = new jsPDF();
    var img = new Image();
    img.src = "/static/img/brands/logo.png";
    doc.addImage(img, "png", 10, 10, 45, 20);

    doc.setFontSize(11.7);
    doc.setFont("helvetica", "bold");
    doc.text(100, 45, "Tour Summary", "center");

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(100, 55, `${selectedLocation.LocationName}`, "center");

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Report dates : ", 15, 65);
    // doc.text(`Tour Name :        ${reportData.tour_name}`, 15, 50);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(newRange, 35, 65);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Status : ", 15, 70);
    // doc.text(`Tour By :             ${reportData.user_name}`, 15, 55);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(selectTourName, 28, 70);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Sorted by : ", 15, 75);
    // doc.text(`Tour Status :        ${reportData.report_status}`, 15, 60);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text("Date - Ascending", 32, 75);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Record count : ", 15, 80);
    // doc.text(`Tour Start :          ${reportData.start_time}`, 15, 65);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(totalRecord.toString(), 36, 80);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text(`Created by : `, 145, 65);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(LoggedInUserInfo.alias_name, 165, 65);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text(`Print date : `, 145, 70);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(`${newDatePDF}`, 163, 70);

    const expPdfData = summaryList.map((item) => {
      return [
        item.tour_name,
        item.user_name,
        item.start_time,
        item.finish_time,
        item.missed,
      ];
    });
    autoTable(doc, {
      // styles: { marginTop: "400px" },
      margin: { top: 90 },
      head: [["Tour Name", "Tour By", "Started At", "Finished At", "Missed"]],
      body: expPdfData,
      showFoot: "everyPage",
      // showHead: "firstPage",
      // Footer
      didDrawPage: function (data) {
        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 110, pageHeight - 3);
        doc.setLineWidth(0.1);
        doc.line(10, pageHeight - 8, 200, pageHeight - 8, "F");
      },
    });
    let fileName =
      "SOVA-TOUR-SUMMARY-" + moment(new Date()).format("YYYY-MM-DD") + ".pdf"; // TODOS: location name will be added later
    doc.save(fileName);
  };

  //function for showing the Non-tour-task Listing page
  const showTourSummaryList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      ...dataToFilter,
    };
    TourSummaryList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(tourSummaryList(res.data));
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          dispatch(tourSummaryList(res.data));
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        dispatch(tourSummaryList([]));
      });
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    TourSummaryColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    TourSummaryColumns()
      .then((res) => {
        dispatch(getTourSummaryChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    showTourSummaryList();
    getColumnList();
  }, [
    rowsPerPage,
    currentPage,
    dataToFilter,
    startDateToFilter,
    endDateToFilter,
  ]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
  }, []);
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          filterRecord={(data) => filterRecord(data)}
          startDateToFilter={startDateToFilter}
          setStartDateToFilter={setStartDateToFilter}
          endDateToFilter={endDateToFilter}
          setEndDateToFilter={setEndDateToFilter}
          handleFirstPage={handleFirstPage}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
          selectTourName={selectTourName}
          setSelectTourName={setSelectTourName}
          generatePdf={generatePdf}
          setCurrentPage={setCurrentPage}
        />
        <Box id="mybox" sx={{ padding: "20px 15px" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={12} textAlign="center">
              <Typography variant="h6" component="div" gutterBottom>
                {selectedLocation.LocationName}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box sx={{ display: { lg: "flex" } }}>
                <Paper>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Report dates:</strong> {newRange}
                  </Typography>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Status:</strong> {selectTourName}
                  </Typography>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Sorted by:</strong>{" "}
                    {summaryList.length > 0 ? "Date - Ascending" : null}
                  </Typography>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Record count: </strong> {totalRecord}
                  </Typography>
                </Paper>
                <Paper sx={{ marginLeft: "auto" }}>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Created by:</strong> {LoggedInUserInfo.alias_name}
                  </Typography>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Print date:</strong> {newDatePDF}
                  </Typography>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Print time:</strong> {newTimePDF}
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(summaryList, getComparator(order, orderBy))
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.tname)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {checkedValue.includes("tour_name") && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              // padding="none"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.tour_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("user_name") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.user_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("start_time") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.start_time}
                            </TableCell>
                          )}
                          {checkedValue.includes("finish_time") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.finish_time}
                            </TableCell>
                          )}
                          {checkedValue.includes("missed") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.missed}
                            </TableCell>
                          )}
                          {checkedValue.includes("comments") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.comments}
                            </TableCell>
                          )}
                          {checkedValue.includes("nist_time") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.nist_time}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={10} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
function BlockTourSummary(props) {
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTable />
      </Paper>
    </div>
  );
}

function TourSummary() {
  return (
    <>
      <Helmet title="Tour Summary" />
      <WithPermissionFallback controller="Reports" action="tourSummary">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Typography
              id="tour-summary"
              variant="h3"
              gutterBottom
              display="inline"
            >
              Tour Summary
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6} textAlign="right">
          <Button color="primary" variant="outlined">
            Download Tour Summary
          </Button>
        </Grid> */}
        </Grid>

        {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/reports/tour-patrol">
          Tour/Patrol
        </Link>
        <Typography>Tour Summary</Typography>
      </Breadcrumbs> */}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <BlockTourSummary />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </>
  );
}

export default TourSummary;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Grid,
  FormControl as MuiFormControl,
  TextField,
  Divider as MuiDivider,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { UpdatePackageAudit } from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const EditAudit = ({
  editAudit,
  editAuditClose,
  singleData,
  packagesAuditList,
}) => {
  const [auditDate, setAuditDate] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id, audit_name, package_count, storage_location, date, unaccounted } =
    singleData;
  const initialValues = {
    scan_package: audit_name ? audit_name : "",
    audit_date: new Date(date) ? new Date(date) : null,
    storage_location: storage_location ? storage_location : "",
  };
  const validationSchema = Yup.object().shape({
    // company_name: Yup.string().required("Please enter company name"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      // alert("hi");
      // console.log(values);
      let payload = {
        id: id,
        edit_audit_name: values.scan_package,
        edit_date:
          values.audit_date !== null
            ? format(values.audit_date, "yyyy-MM-dd")
            : format(new Date(date), "yyyy-MM-dd"),
        edit_audit_storage_location: values.storage_location,
      };
      UpdatePackageAudit(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record udpated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            packagesAuditList();
            editAuditClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={editAudit}
        onClose={editAuditClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        aria-labelledby="edit-audit-title"
        aria-describedby="edit-audit-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="edit-audit-title"
        >
          <Typography variant="h4" component="div">
            Edit Audit
          </Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={editAuditClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        placeholder="Derin Redding"
                        label="Author name"
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginTop: "10px" }}
                        name="scan_package"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl mt={3}>
                      <DatePicker
                        name="audit_date"
                        label="Audit date"
                        value={values.audit_date}
                        onChange={(newValue) => {
                          setFieldValue("audit_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="audit_date"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        placeholder="Main Storage"
                        label="Storage location"
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginTop: "10px" }}
                        name="storage_location"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={editAuditClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default EditAudit;

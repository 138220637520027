import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { SingleRecord, TransactionsLabel } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import { useSelector } from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  Typography,
  FormLabel,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import WithPermissionHide from "../../../utils/withPermissionHide";
const PrintShippingLabel = ({ show, handleModal, id, showAlertTost }) => {
  const [loading, setLoading] = useState(true); //State for loader
  const [recordData, setRecordData] = useState(null);
  const [labelRecordData, setLabelRecordData] = useState(null);
  const [showDownloadLabel, setShowDownloadLabel] = useState(false);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  // Check for permission for specific actions
  const hasPermissionForTransactionsLabel = permissions.some(
    (perm) =>
      perm.controller === "LostAndFound" &&
      perm.action_name === "transactionsLabel" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
    borderedBox: {
      border: "1px solid",
      borderBottom: 0,
      borderColor:
        theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
    },
  }));

  const classes = useStyles();

  let initialValues = {
    extraOption: "1",
  };

  useEffect(() => {
    getReord();
  }, []);

  const getReord = () => {
    SingleRecord(id)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          setRecordData(res.data);
        } else {
          showAlertTost(res.message, "error");
        }
      })
      .catch((err) => {
        setLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          showAlertTost(error, "error");
        }
      });
  };
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let formData = {
        id: id,
        extraOption: values.extraOption,
      };
      TransactionsLabel(formData)
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            setLabelRecordData(res.data);
            setShowDownloadLabel(true);
          } else {
            showAlertTost(res.message, "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            showAlertTost(error, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      showAlertTost("Sorry! something wrong please try again", "error");
    }
  };

  return (
    <>
      <Dialog
        open={show}
        aria-labelledby="print-shipping-title"
        aria-describedby="print-shipping-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          id="print-shipping-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="div">
            Printing shipping label for item # {id}
          </Typography>
          <DialogActions onClick={() => handleModal(false)}>
            <IconButton sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        {recordData && (
          <DialogContent id="print-shipping-description">
            <DialogContentText>
              Item description: {recordData.description}
            </DialogContentText>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ handleSubmit, setFieldValue, values }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl sx={{ marginTop: "20px" }}>
                    <FormLabel>Select an option below:</FormLabel>
                    <RadioGroup
                      aria-labelledby=""
                      name="extraOption"
                      value={values.extraOption}
                      onChange={(e) =>
                        setFieldValue("extraOption", e.currentTarget.value)
                      }
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Create shipping label and copy the tracking number to 'Internal '"
                        value="1"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Create shipping label, copy the tracking number to 'Internal Notes' and change the disposition of the item 'Claimed'"
                        value="2"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl mt={3}>
                    <WithPermissionHide
                      controller="LostAndFound"
                      action="transactionPdf"
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <a
                          target="_blank"
                          href={"/lostandfound/transaction-pdf/" + id}
                          style={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          <FormLabel style={{ cursor: "pointer" }}>
                            Download transaction details
                          </FormLabel>
                          <IconButton sx={{ marginLeft: "10px" }}>
                            <OpenInNewIcon />
                          </IconButton>
                        </a>
                      </Box>
                    </WithPermissionHide>
                  </FormControl>
                  <DialogActions>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => handleModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!hasPermissionForTransactionsLabel}
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                    >
                      Okay
                    </Button>
                    {labelRecordData && showDownloadLabel && (
                      <a
                        target="_blank"
                        href={labelRecordData.url}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ marginLeft: "10px" }}
                        >
                          Click to print label
                        </Button>
                      </a>
                    )}
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        )}
        {loading && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </Dialog>
    </>
  );
};

export default PrintShippingLabel;

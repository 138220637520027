import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { AddVisitorNewCompany } from "../../../api";
const AddNewCompany = ({
  addNewCompanyDialog,
  addNewCompanyDialogClose,
  visitorComppanyDropdown,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const handleSave = () => {
    if (companyName !== "") {
      AddVisitorNewCompany({ CompanyName: companyName })
        .then((res) => {
          if (res.statusCode === 200) {
            addNewCompanyDialogClose();
            visitorComppanyDropdown();
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setErrMsg("Please enter company name");
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-new-company"
        aria-describedby="add-new-company-desc"
        onClose={addNewCompanyDialogClose}
        open={addNewCompanyDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-new-company"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add New Company
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addNewCompanyDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="company-name"
            placeholder="Company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <FormHelperText error>{errMsg}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={addNewCompanyDialogClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ ml: "5px" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewCompany;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Switch,
  IconButton,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Create as CreateIcon,
  Archive as ArchiveIcon,
} from "@mui/icons-material";
import { ShippoList, EditCarrierStatus, SetupCSL } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";

const CarrierSettings = () => {
  const dispatch = useDispatch();

  const [carrierList, setCarrierList] = useState([]);

  useEffect(() => {
    ShippoList()
      .then((res) => {
        if (res.statusCode == 200) {
          setCarrierList(res.data);
        } else {
          setCarrierList(res.data);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  }, []);

  //activate or deactivate carrier setting
  const handleSwitchToggle = (objectId, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle the status
    let payload = {
      objectId: objectId,
      status: newStatus,
    };
    EditCarrierStatus(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          // ipRangeList();
          setCarrierList((prevData) =>
            prevData.map((item) =>
              item.object_id === objectId
                ? { ...item, status_flag: newStatus }
                : item
            )
          );
          dispatch(getMsg("Carrier status updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //setup button
  const [buttonText, setButtonText] = useState("Setup"); // State for managing button text

  const handleButtonClick = () => {
    const payload = { mode: buttonText === "Setup" ? "set" : "reset" };
    SetupCSL(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setButtonText(
            buttonText === "Setup" ? "Reset Service Level Settings" : "Setup"
          );

          dispatch(getMsg("Carrier status updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Card
        sx={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          <ArchiveIcon sx={{ marginBottom: "-5px", marginRight: "6px" }} />
          Carrier Settings
        </Typography>

        <Typography variant="body1" color="red">
          TEST Mode
        </Typography>
        <Button variant="contained" onClick={handleButtonClick}>
          {buttonText}
        </Button>
      </Card>
      <Typography variant="body2" gutterBottom>
        Choose the carriers below that you want to allow your guests to select
        from when processing their lost and found shipping. For active carriers,
        click the pencil icon to adjust the notifications that you will receive
        based on service level. Make sure to setup a list of recipients in the
        Communication screen to receive these notifications.
      </Typography>
      <Card
        sx={{
          marginTop: "25px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Carrier</TableCell>
              <TableCell align="center">Account Name</TableCell>
              <TableCell align="center">Carrier Object ID</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Service level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {carrierList.map((row) => (
              <TableRow key={row.object_id}>
                <TableCell>{row.carrier_name}</TableCell>
                <TableCell align="center">{row.account_id}</TableCell>
                <TableCell align="center">{row.object_id}</TableCell>
                <TableCell align="center">
                  <Switch
                    checked={row.status_flag === 1}
                    onChange={() =>
                      handleSwitchToggle(row.object_id, row.status_flag)
                    }
                  />{" "}
                </TableCell>
                <TableCell align="center">
                  {row.status_flag === 1 && (
                    <IconButton>
                      <Link
                        to={`/settings/corporate-user/carrier-service-level/${row.carrier_name}`}
                      >
                        <CreateIcon color="primary" />
                      </Link>
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>{" "}
      </Card>
    </>
  );
};

export default CarrierSettings;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import ShiftReportListCol from "../table-columns-json/logs/ShiftReportList";
import { setShiftReportList } from "../../redux/slices/logs/logTableColSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  Pagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  RadioGroup,
  FormLabel,
  Radio,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  InsertDriveFile as InsertDriveFileIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  Https as HttpsIcon,
  Person as PersonIcon,
  DeviceHub as DeviceHubIcon,
  Circle as CircleIcon,
  CropSquare as CropSquareIcon,
  Send as SendIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  SettingsInputComponent as SettingsInputComponentIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import ShiftRow from "./ShiftRow";
import { ShiftReportLists, DeleteShiftReport, ShiftReportPdf } from "../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux"; //Imports related to redux
import moment from "moment";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
import hasPermission from "../../utils/hasPermission";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 120px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Image = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 15px auto 15px;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "465px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  date,
  supervisor,
  shift,
  training,
  irs,
  groupissues,
  ets,
  sd,
  efs,
  status,
  updatedutc,
  action
) {
  return {
    date,
    supervisor,
    shift,
    training,
    irs,
    groupissues,
    ets,
    sd,
    efs,
    status,
    updatedutc,
    action,
  };
}
const rows = [
  createData(
    "09/10/2022",
    "Dering Redding",
    "0000-2359",
    "N",
    0,
    "None",
    "Y",
    "Y",
    "Y",
    0,
    "10/10/2021 02:09",
    0
  ),
  createData(
    "09/10/2022",
    "Dering Redadmin",
    "0000-2359",
    "N",
    0,
    "None",
    "Y",
    "Y",
    "Y",
    0,
    "10/10/2021 02:09",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "date",
    numeric: true,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "supervisor",
    numeric: true,
    disablePadding: false,
    label: "Supervisor",
  },
  {
    id: "shift",
    numeric: true,
    disablePadding: false,
    label: "Shift",
  },
  {
    id: "training",
    numeric: true,
    disablePadding: false,
    label: "Training",
  },
  {
    id: "irs",
    numeric: true,
    disablePadding: false,
    label: "IRs",
  },
  {
    id: "groupissues",
    numeric: true,
    disablePadding: false,
    label: "Group Issues",
  },
  {
    id: "ets",
    numeric: true,
    disablePadding: false,
    label: "ETS",
  },
  {
    id: "sd",
    numeric: true,
    disablePadding: false,
    label: "SD",
  },
  {
    id: "efs",
    numeric: true,
    disablePadding: false,
    label: "EFS",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "updatedutc",
    numeric: true,
    disablePadding: false,
    label: "Updated (UTC)",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {ShiftReportListCol.map((headCell) => (
          <TableCell
            key={headCell.classificationName}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "action" || headCell.id == "vio"
                  ? null
                  : createSortHandler(headCell.id)
              }
              hideSortIcon={
                headCell.id == "action" || headCell.id == "vio" ? true : false
              }
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setSearchInput,
    handleFirstPage,
    handleDeleteOpen,
    setDataToFilter,
    tableCalRedux,
    defaultTableCalRedux,
    rowsPerPage,
    order,
    orderBy,
    dataToFilter,
  } = props;
  const [actions, setActions] = React.useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [supervisior, setSupervisior] = useState("");
  const [shifTime, setShifTime] = useState("");
  const [training, setTraining] = useState("");
  const [irRecord, setIrRecord] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [dateRange, setDateRange] = React.useState(null);
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
    setAnchorEl(null);
  };
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };
  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  //enter key search
  const enterPress = () => {
    setSearchInput(searchValue);
    handleFirstPage();
  };

  const applySearch = () => {
    const payload = {
      search_value: searchValue,
      supervisor: supervisior,
      shift_number: shifTime,
      training: training,
      incident: irRecord ? 1 : 0,
      group_name: groupName,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
    };
    // console.log(payload);
    setDataToFilter(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setSearchValue("");
    setSupervisior("");
    setShifTime("");
    setTraining("");
    setIrRecord(false);
    setStartDateToFilter("");
    setEndDateToFilter("");
  };

  const onButtonClick = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    ShiftReportPdf(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-SHIFT_REPORT.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("Something Went Wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem
                  disabled={!hasPermission("Logs", "deleteShiftReport")}
                  value={"Delete"}
                  onClick={handleDeleteOpen}
                >
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                placeholder="Search for any remarks written"
                inputProps={{}}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch({});
                    setIsFilter(true);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="supervisior_id"
                                label="Supervisior"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Supervisior"
                                value={supervisior}
                                onChange={(e) => setSupervisior(e.target.value)}
                              />
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="department">
                                Shift Time
                              </InputLabel>
                              <Select
                                labelId="position"
                                id="department"
                                value={shifTime}
                                onChange={(e) => setShifTime(e.target.value)}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">
                                  --Select Shift Time--
                                </MenuItem>

                                <MenuItem value="Shift 1 : 0700-1500">
                                  Shift 1 : 0700-1500
                                </MenuItem>
                                <MenuItem value="Shift 2 : 1500-2300">
                                  Shift 2 : 1500-2300
                                </MenuItem>
                                <MenuItem value="Shift 3 : 2300-0700">
                                  Shift 3 : 2300-0700
                                </MenuItem>
                              </Select>
                            </FormControl>

                            <FormControl>
                              <FormLabel id="demo-controlled-radio-buttons-group">
                                Training
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={training}
                                onChange={(e) => setTraining(e.target.value)}
                                row
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="No"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={irRecord}
                                    onChange={(e) =>
                                      setIrRecord(e.target.checked)
                                    }
                                  />
                                }
                                label="IR recorded during shift"
                              />
                            </FormControl>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="emp_id"
                                label="Group Name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Group Name"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                              />
                            </FormControl>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="dateRange"
                                label="Date range"
                                value={
                                  startDateToFilter && endDateToFilter
                                    ? moment(startDateToFilter).format(
                                        "MM/DD/YYYY"
                                      ) +
                                      " - " +
                                      moment(endDateToFilter).format(
                                        "MM/DD/YYYY"
                                      )
                                    : ""
                                }
                                onClick={() => setFadedBox((prev) => !prev)}
                                // onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Select date range"
                              />
                              {fadebox && (
                                <CustomDateRange
                                  handleShow={fadebox}
                                  conformation={(status) =>
                                    setFadedBox((prev) => !prev)
                                  }
                                  setDates={(start, end) => {
                                    setStartDateToFilter(start);
                                    setEndDateToFilter(end);
                                  }}
                                  selectedStart={startDateToFilter}
                                  selectedEnd={endDateToFilter}
                                />
                              )}
                            </FormControl>
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={6}
                                onClick={() => {
                                  applySearch({});
                                  handleCloseForm();
                                  setIsFilter(true);
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  variant="contained"
                  color="primary"
                  mt={6}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                    setSearchValue("");
                  }}
                >
                  Clear filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    // if (searchValue !== "") {
                    applySearch({});
                    setIsFilter(true);
                    // }
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3} sx={{}}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <WithPermissionHide controller="Logs" action="addShiftReport">
                <MenuItem component={NavLink} to="/logs/add-shift-report">
                  <ListItemIcon>
                    <AddBoxIcon />
                  </ListItemIcon>
                  <ListItemText
                    size="large"
                    primary="Add Shift Report"
                    pl={0}
                  />
                </MenuItem>
              </WithPermissionHide>
              <WithPermissionHide controller="Logs" action="shiftReportPdf">
                <MenuItem
                  onClick={() => {
                    onButtonClick();
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Download PDF" pl={0} />
                </MenuItem>
              </WithPermissionHide>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                  handleClickColumn(e);
                }}
              >
                <ListItemIcon>
                  <SettingsInputComponentIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(tableCalRedux).filter(
                          (k) => tableCalRedux[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(tableCalRedux).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={tableCalRedux}
                      updateCalStatus={(data) => {
                        dispatch(setShiftReportList(data));
                      }}
                      listing={ShiftReportListCol}
                    />
                    <Toolbar
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setShiftReportList(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [length, setLength] = useState(null);
  const [checked, setChecked] = useState(false);
  const [shiftReportList, setShiftReportList] = useState([]);
  const [id, setId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataToFilter, setDataToFilter] = useState(null);

  const dispatch = useDispatch();

  const tableCalRedux = useSelector(
    (state) => state.logTableCol.shiftReportList
  );
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.shiftReportList
  );

  const { startDateToFilterA, endDateToFilterA } = props;
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader

  const [startDateToFilter, setStartDateToFilter] =
    useState(startDateToFilterA);
  const [endDateToFilter, setEndDateToFilter] = useState(endDateToFilterA);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = shiftReportList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleClick = (event, supervisor) => {
    const selectedIndex = selected.indexOf(supervisor);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, supervisor);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setLength(newSelected.length);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setSelected([]);
    setPage(newPage);
  };
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (supervisor) => selected.indexOf(supervisor) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setLength(1);
    console.log("asdfas", id);
    setId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const getShiftReportList = () => {
    setLoading(true);

    let payload = {
      limit: rowsPerPage,
      shift: searchInput,
      ...dataToFilter,
    };
    setDialogLoader(true);

    ShiftReportLists(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          console.log(res);
          setShiftReportList(res.data);
          setLoading(false);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setLoading(false);
          setShiftReportList([]);
          // setUserActivityList(res.data);
          setTotalRecord(0);
        }
        setDialogLoader(false);
      })
      .catch((err) => {
        setDialogLoader(false);
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
      });
  };

  const deleteShiftReport = () => {
    DeleteShiftReport(selected.length > 0 ? selected : id)
      .then((res) => {
        if (res.statusCode === 200) {
          getShiftReportList();
          handleDeleteClose();
          dispatch(getMsg(`Record(s) has been deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShiftReportList();
  }, [rowsPerPage, currentPage, dataToFilter]);
  return (
    <div>
      {selected.length || id > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete shift report?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={deleteShiftReport} autoFocus variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setSearchInput={setSearchInput}
          handleDeleteOpen={handleDeleteOpen}
          setDataToFilter={setDataToFilter}
          tableCalRedux={tableCalRedux}
          defaultTableCalRedux={defaultTableCalRedux}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
          dataToFilter={dataToFilter}
        />

        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={shiftReportList.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(shiftReportList, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <ShiftRow
                        shiftReportList={row}
                        isItemSelected={isItemSelected}
                        anchorEl={anchorEl}
                        handleActionClick={handleActionClick}
                        handleActionClose={handleActionClose}
                        tableCalRedux={tableCalRedux}
                        key={row.id}
                        id={id}
                        handleClick={handleClick}
                        handleDeleteOpen={handleDeleteOpen}
                      />
                    );
                  }
                )}
                {shiftReportList.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * shiftReportList.length,
                    }}
                  >
                    <TableCell colSpan={13} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            // count={totalRecord}
            rowsPerPage={rowsPerPage}
            // page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          // color="primary"
          // boundaryCount={3}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function ShiftReport() {
  return (
    <React.Fragment>
      <Helmet title="Shift Report" />
      <WithPermissionFallback controller="Logs" action="shiftReportList">
        <Typography variant="h3" gutterBottom display="inline">
          Shift Report
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ShiftReport;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import moment from "moment";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { SaveModuleTask } from "../../../api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Select,
  FormLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  TextareaAutosize as MuiTextareaAutosize,
  Grid,
  FormGroup,
  MenuItem,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import * as Yup from "yup";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const AddTask = ({
  addTask,
  addTaskClose,
  module_id,
  module,
  showAlertTost,
  setLoader,
  setReloadTaskList,
}) => {
  const classes = useStyles();
  const [datevalue, setDateValue] = React.useState(null);
  // Tags Select
  const [tags, setTags] = React.useState("");
  const selectTags = (event) => {
    setTags(event.target.value);
  };
  let initialValues = {
    title: "",
    due_date: "",
    status: "",
    description: "",
    comment: "",
    urgent: false,
    problem: false,
    question: false,
    tags: "Contact",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    description: Yup.string().required("Please enter description"),
  });

  //Handler for form submission
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setLoader(true);
    let formData = {
      title: values.title,
      module: module,
      module_id: module_id,
      status: 1,
      description: values.description,
      comment: values.comment,
      urgent: values.urgent,
      problem: values.problem,
      question: values.question,
      tags: tags,
      due_date: datevalue
        ? moment(datevalue).format("YYYY-MM-DD HH:mm:ss")
        : null,
    };
    SaveModuleTask(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          showAlertTost(res.message, "success");
          addTaskClose();
          setReloadTaskList();
        } else {
          showAlertTost("Sorry! something wrong please try agin", "error");
        }
        setLoader(false);
      })
      .catch((err) => {
        showAlertTost("Sorry! something wrong please try agin", "error");
      });
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-task-title"
        aria-describedby="add-task-description"
        open={addTask}
        onClose={addTaskClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
              overflowY: "unset",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-task-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add Task
          </Typography>
          <DialogActions>
            <IconButton onClick={addTaskClose} sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="title"
                        label="Task title *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Call insurance company"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DatePicker
                        id="due-date"
                        label="Due date"
                        name="due_date"
                        value={datevalue}
                        onChange={(newValue) => {
                          setDateValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Click to select",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      name="description"
                      label="Task description *"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      placeholder="Send info to adjuster"
                      multiline={true}
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      name="comment"
                      label="Comments"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      placeholder="Any action so far on this task?"
                      multiline={true}
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Add flag (optional)</FormLabel>
                      <FormGroup row name="add_flag">
                        <FormControlLabel
                          control={
                            <Checkbox
                              disableRipple
                              name="urgent"
                              value={values.urgent}
                              onChange={(e) => {
                                setFieldValue("urgent", !values.urgent);
                              }}
                            />
                          }
                          label="Urgent"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disableRipple
                              name="problem"
                              value={values.problem}
                              onChange={(e) => {
                                setFieldValue("problem", !values.problem);
                              }}
                            />
                          }
                          label="Problem"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disableRipple
                              name="question"
                              value={values.question}
                              onChange={(e) => {
                                setFieldValue("question", !values.question);
                              }}
                            />
                          }
                          label="Question"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="tags"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Tags
                        </InputLabel>
                        <Select
                          labelId="department"
                          id="tags"
                          name="tags"
                          value={tags}
                          label="Tags"
                          onChange={selectTags}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value={"Contact"}>Contact</MenuItem>
                          <MenuItem value={"Photos"}>Photos</MenuItem>
                          <MenuItem value={"Followup"}>Followup</MenuItem>
                          <MenuItem value={"Meeting"}>Meeting</MenuItem>
                          <MenuItem value={"Video"}>Video</MenuItem>
                        </Select>
                      </FormControl>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <DialogActions>
                      <Button color="primary" variant="outlined">
                        Cancel
                      </Button>
                      <Button color="primary" variant="contained" type="submit">
                        Save
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddTask;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { handleApiResonseErrors } from "../../helper/helper";
import {
  DepartmentList,
  ViewVisitorWatch as ViewVisitorWatchs,
} from "../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Box,
  Menu,
  Accordion as MuiAccordion,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
} from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiButtonBase-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "235px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "460px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        padding: "10px",
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const selectSites = [
  { title: "None selected" },
  { title: "One" },
  { title: "Two" },
  { title: "Three" },
  { title: "Fouor" },
  { title: "Five" },
  { title: "Six" },
];

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddVisitorForm(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  //Add Company Select Control

  const [viewWatch, setViewWatch] = useState({});
  const viewVisitorWatch = () => {
    ViewVisitorWatchs(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewWatch(res.data);
          setDepartment(res.data.department_id);
          setLoading(false);
        } else {
          setViewWatch({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //  useEffect(() => {
  //    viewVisitorCompany();
  //    setLoading(true);
  //  }, []);

  const getDepartmentList = () => {
    DepartmentList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  useEffect(() => {
    getDepartmentList();
    viewVisitorWatch();
    setLoading(true);
  }, []);

  const { visitor_name, expiry_date, department_id, added_by, reason } =
    viewWatch;

  // Visitor department
  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = React.useState("");
  const selectDepartment = (event) => {
    setDepartment(event.target.value);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <form>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <FormControl mt={3}>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  id="visitor-name"
                  label="Visitor name"
                  value={visitor_name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Hillari Cliton"
                />
              </FormControl>
              <FormControl mt={6}>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  id="person-requesting"
                  label="Person requesting"
                  value={added_by}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Darin R"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl mt={3}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  value={expiry_date}
                  id="expiry-date"
                  label="Expiry Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="2016-08-31 09:00:00"
                />
              </FormControl>
              <FormControl mt={6}>
                <InputLabel
                  shrink
                  InputProps={{
                    readOnly: true,
                  }}
                  id="department"
                  sx={{
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    marginLeft: "-4px",
                  }}
                >
                  Department
                </InputLabel>
                <Select
                  labelId="department"
                  id="department"
                  value={department}
                  label="Department"
                  onChange={selectDepartment}
                  fullWidth
                  displayEmpty
                  inputProps={{ readOnly: true }}
                >
                  <MenuItem value="">Select department</MenuItem>
                  {departmentList.map((data) => {
                    return (
                      <MenuItem value={data.value} key={data.value}>
                        {data.key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              {/* <TextAreaControl mt={0} className={classes.textAreaControl}> */}
              <FormControl mt={1}>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  id="reason"
                  label="reason"
                  value={reason}
                  multiline
                  rows={4}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="This is a test. Hillery is now added to the watch list. if you create visitor record by this name, a warning will appear with this message."
                />
              </FormControl>
              {/* </TextAreaControl> */}
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

function ViewVisitorWatch() {
  return (
    <React.Fragment>
      <Helmet title="View Visitor Watch" />
      <Typography variant="h3" gutterBottom display="inline">
        View Visitor Watch
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/visitors/visitor-watch-list">
          Watch List
        </Link>
        <Typography>View Visitor Watch</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddVisitorForm />
    </React.Fragment>
  );
}

export default ViewVisitorWatch;

import React from "react";
import { Button, DialogActions, Dialog, DialogTitle } from "@mui/material";

const DialogBox = ({ message, handleShow, conformation }) => {
  return (
    <Dialog
      open={handleShow}
      onClose={() => conformation(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <Button onClick={() => conformation(false)} variant="contained">
          Cancel
        </Button>
        <Button onClick={() => conformation(true)} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;

import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { Grid, Typography, Box, Button } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import BarChartIcon from "@mui/icons-material/BarChart";
import plugin from "dayjs/plugin/isBetween";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TableHead,
} from "@mui/material";

function IrDashboard() {
  useEffect(() => {
    const options = {
      chart: {
        type: "column",
      },
      title: {
        align: "left",
        text: "Incident Class Count",
      },
      subtitle: {
        align: "left",
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ["downloadJPEG", "downloadPDF"],
          },
        },
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Total percent market share",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}%",
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      },
      series: [
        {
          name: "Browsers",
          colorByPoint: true,
          data: [
            {
              name: "Chrome",
              y: 63.06,
              drilldown: "Chrome",
            },
            {
              name: "Safari",
              y: 19.84,
              drilldown: "Safari",
            },
            {
              name: "Firefox",
              y: 4.18,
              drilldown: "Firefox",
            },
            {
              name: "Edge",
              y: 4.12,
              drilldown: "Edge",
            },
            {
              name: "Opera",
              y: 2.33,
              drilldown: "Opera",
            },
            {
              name: "Internet Explorer",
              y: 0.45,
              drilldown: "Internet Explorer",
            },
            {
              name: "Other",
              y: 1.582,
              drilldown: null,
            },
          ],
        },
      ],
      drilldown: {
        breadcrumbs: {
          position: {
            align: "right",
          },
        },
        series: [
          {
            name: "Chrome",
            id: "Chrome",
            data: [
              ["v65.0", 0.1],
              ["v64.0", 1.3],
              ["v63.0", 53.02],
              ["v62.0", 1.4],
              ["v61.0", 0.88],
              ["v60.0", 0.56],
              ["v59.0", 0.45],
              ["v58.0", 0.49],
              ["v57.0", 0.32],
              ["v56.0", 0.29],
              ["v55.0", 0.79],
              ["v54.0", 0.18],
              ["v51.0", 0.13],
              ["v49.0", 2.16],
              ["v48.0", 0.13],
              ["v47.0", 0.11],
              ["v43.0", 0.17],
              ["v29.0", 0.26],
            ],
          },
          {
            name: "Firefox",
            id: "Firefox",
            data: [
              ["v58.0", 1.02],
              ["v57.0", 7.36],
              ["v56.0", 0.35],
              ["v55.0", 0.11],
              ["v54.0", 0.1],
              ["v52.0", 0.95],
              ["v51.0", 0.15],
              ["v50.0", 0.1],
              ["v48.0", 0.31],
              ["v47.0", 0.12],
            ],
          },
          {
            name: "Internet Explorer",
            id: "Internet Explorer",
            data: [
              ["v11.0", 6.2],
              ["v10.0", 0.29],
              ["v9.0", 0.27],
              ["v8.0", 0.47],
            ],
          },
          {
            name: "Safari",
            id: "Safari",
            data: [
              ["v11.0", 3.39],
              ["v10.1", 0.96],
              ["v10.0", 0.36],
              ["v9.1", 0.54],
              ["v9.0", 0.13],
              ["v5.1", 0.2],
            ],
          },
          {
            name: "Edge",
            id: "Edge",
            data: [
              ["v16", 2.6],
              ["v15", 0.92],
              ["v14", 0.4],
              ["v13", 0.1],
            ],
          },
          {
            name: "Opera",
            id: "Opera",
            data: [
              ["v50.0", 0.96],
              ["v49.0", 0.82],
              ["v12.1", 0.14],
            ],
          },
        ],
      },
    };

    Highcharts.chart("ir_dashboard_bar", options);
  }, []);

  const hours = [...Array(24).keys()].map((k) => k.toString().padStart(2, "0"));
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const rotatedTextStyle = {
    transform: "rotate(-90deg)",
    transformOrigin: "left top",
    width: "fit-content",
    position: "absolute",
    top: "74%",
    left: "-27px",
    fontSize: "12px",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    boxShadow: 24,
    border: "none",
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // model from  data

  return (
    <div>
      <Typography variant="h5">
        <BarChartIcon sx={{ marginRight: "5px", marginBottom: "-4px" }} />
        Incident Dashboard
      </Typography>

      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item xs={12} md={4} lg={4}>
          <strong>Days</strong>
          <Button
            variant="outlined"
            sx={{ marginLeft: "10px", marginRight: "10px" }}
          >
            30
          </Button>
          <Button variant="outlined" sx={{ marginRight: "10px" }}>
            60
          </Button>
          <Button variant="outlined">90</Button>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <strong>Months</strong>
          <Button
            variant="outlined"
            sx={{ marginLeft: "10px", marginRight: "10px" }}
          >
            6
          </Button>
          <Button variant="outlined" sx={{ marginRight: "10px" }}>
            12
          </Button>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Typography>
            <strong>Showing</strong> 11/29/2023 to 02/27/2024
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} sx={{ marginTop: "42px" }}>
          <div
            id="ir_dashboard_bar"
            sx={{ maxWidth: "100%", padding: "20px" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <Typography variant="h5" sx={{ marginBottom: "20px" }}>
              Word Cloud
            </Typography>

            <svg width="100" height="100">
              <circle
                cx="50"
                cy="50"
                r="40"
                stroke="green"
                stroke-width="4"
                fill="yellow"
              />
            </svg>
          </Box>
        </Grid>
      </Grid>

      {/*Incident Matrix section */}

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ marginTop: "40px", textAlign: "center" }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Incident Matrix
          </Typography>
        </Grid>
        <Box
          sx={{
            marginTop: "20px",
            background: "white",
            maxWidth: "100%",
            position: "relative",
          }}
        >
          <Typography variant="h6" style={rotatedTextStyle}>
            Incidents by day of week
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="incident matrix">
              <TableBody>
                {days.map((day) => (
                  <TableRow key={day} className="calendar_days">
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      component="th"
                      scope="row"
                    >
                      {day}
                    </TableCell>
                    {hours.map((hour) => (
                      <TableCell key={hour} align="center" onClick={handleOpen}>
                        {hour}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            margin: "auto",
            paddingTop: "10px",
            fontSize: "12px",
          }}
        >
          Incidents by hour of day
        </Typography>
      </Grid>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Dialog
            aria-labelledby="lift-out-title-modal"
            aria-describedby="lift-out-modal"
            open={open}
            onClose={handleClose}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "600px",
                },
              },
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#E8E8E8",
              }}
              id="lift-out-title"
            >
              <Box>
                <Typography variant="h4" component="div" gutterBottom>
                  Incidents by Calendar Day
                </Typography>{" "}
              </Box>

              <DialogActions>
                <IconButton onClick={handleClose} sx={{ marginRight: "-10px" }}>
                  <CancelIcon onClick={handleClose} />
                </IconButton>
              </DialogActions>
            </DialogTitle>

            <DialogContent>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Classification</TableCell>
                      <TableCell> Datetime</TableCell>
                      <TableCell> Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Crime: Arson</TableCell>
                      <TableCell>02/01/2024 10:00</TableCell>
                      <TableCell>
                        <Button variant="outlined">Action</Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions sx={{ pr: "28px" }}>
              <Button color="primary" variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
    </div>
  );
}

export default IrDashboard;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const MessageIconDialog = ({
  emailDialog,
  setEmailDialog,
  emailDialogOpen,
  emailDialogClose,
}) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={emailDialog}
        onClose={emailDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h6" ml={4}>
            Staff SMS Notification
          </Typography>

          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setEmailDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{
              width: "450px",
              marginLeft: 12,
              marginTop: 3,
            }}
            id="outlined-multiline-static"
            label="Add text messgaes here,160 characters max."
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: "20px 24px",
          }}
        >
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              marginRight: "auto",
              marginLeft: "25px",
            }}
          >
            Text messages will be sent to 1 recipients
          </Typography>
          <Button variant="outlined" color="primary" onClick={emailDialogClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={emailDialogClose}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MessageIconDialog;

import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./MergeGroupColumn";

import { Grid } from "@mui/material";

const reorderColumnList = (sourceCol, startIndex, endIndex) => {
  const newTaskIds = Array.from(sourceCol.taskIds);
  const [removed] = newTaskIds.splice(startIndex, 1);
  newTaskIds.splice(endIndex, 0, removed);

  const newColumn = {
    ...sourceCol,
    taskIds: newTaskIds,
  };

  return newColumn;
};

const MergeGroupContainer = ({ setDataToUpdate, recordList, recordListId }) => {
  // export default function MergeGroupContainer() {

  const initialData = {
    tasks: recordList,
    columns: {
      individual_items: {
        id: "individual_items",
        title: "Individual Items",
        taskIds: recordListId,
      },
      merged_group: {
        id: "merged_group",
        title: "Merged Group",
        taskIds: [],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ["individual_items", "merged_group"],
  };

  const [state, setState] = useState(initialData);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    // If user tries to drop in an unknown destination
    if (!destination) return;

    // if the user drags and drops back in the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // If the user drops within the same column but in a different positoin
    const sourceCol = state.columns[source.droppableId];
    const destinationCol = state.columns[destination.droppableId];

    if (sourceCol.id === destinationCol.id) {
      const newColumn = reorderColumnList(
        sourceCol,
        source.index,
        destination.index
      );

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };
      setState(newState);
      return;
    }

    // If the user moves from one column to another
    const startTaskIds = Array.from(sourceCol.taskIds);
    const [removed] = startTaskIds.splice(source.index, 1);
    const newStartCol = {
      ...sourceCol,
      taskIds: startTaskIds,
    };

    const endTaskIds = Array.from(destinationCol.taskIds);
    endTaskIds.splice(destination.index, 0, removed);
    const newEndCol = {
      ...destinationCol,
      taskIds: endTaskIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      },
    };

    setState(newState);
    console.log(newState);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    setDataToUpdate(state);
  }, [state]);

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      //className={classes.DragabbleList}
      sx={{
        "& .MuiListItemButton-root": {
          border: "1px solid #333",
          /*  borderColor:
            theme.palette.mode === "light" ? "#333" : theme.palette.grey[300], */
        },
      }}
    >
      <div>
        <Grid container spacing={6}>
          {state.columnOrder.map((columnId) => {
            const column = state.columns[columnId];
            const tasks = column.taskIds.map((taskId) => {
              return state.tasks.find((task) => task.id === taskId);
            });
            return (
              <Grid item xs={12} lg={6} key={column.id}>
                <Column key={column.id} column={column} tasks={tasks} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </DragDropContext>
  );
};
export default MergeGroupContainer;

import React, { useReducer, useEffect } from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { Checkbox, Chip, Autocomplete } from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultipleAutoComplete = ({
  getChangedData,
  options,
  selOptions,
  name,
  placeholder,
  ...otherProps
}) => {
  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  //useReducer start
  const initialState = { selectedOptions: [] };
  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };

      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.value !== action.payload.value
          ),
        };
      default:
        throw new Error();
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChangeAuto = (event, values) => {
    dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const removeOption = (id) => {
    dispatch({ type: "REMOVE_OPTION", payload: { value: id } });
  };

  useEffect(() => {
    setOptionsToSelected();
  }, []);

  useEffect(() => {
    getChangedData(state.selectedOptions);
  }, [state.selectedOptions]);

  const setOptionsToSelected = () => {
    var setResult = [];
    if (selOptions) {
      selOptions.forEach((obj) => {
        options.forEach((r) => {
          if (r.value == obj) {
            setResult.push(r);
          }
        });
      });
    }
    dispatch({
      type: "SET_SELECTED_OPTIONS",
      payload: {
        options: setResult,
      },
    });
  };

  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.key}
      value={state.selectedOptions}
      renderTags={(values) =>
        values.map((value) => (
          <Chip
            key={value.value}
            label={value.key}
            onDelete={() => {
              removeOption(value.value);
            }}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.value}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.key}
        </li>
      )}
      style={{ width: "100%" }}
      onChange={handleChangeAuto}
      renderInput={(params) => (
        <TextField
          {...params}
          {...configTextfield}
          placeholder={placeholder ?? "Select"}
        />
      )}
    />
  );
};

export default MultipleAutoComplete;

import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components/macro";
import CustomWeekPicker from "../../../components/form-components/CustomWeekPicker";
import { CloneLastWeekEvents } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  Typography,
  Grid,
  Box,
  FormControl as MuiFormControl,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const CloneWeeklySchedule = ({
  cloneWeeklyScheduleDialog,
  cloneWeeklyScheduleDialogClose,
  alertMessage,
  reloadEventsList,
  setLoading,
}) => {
  const [destinationWeekStartDate, setDestinationWeekStartDate] = useState("");
  const [destinationWeekEndDate, setDestinationWeekEndDate] = useState("");

  const [cloneWeekStartDate, setCloneWeekStartDate] = useState("");
  const [cloneWeekEndDate, setCloneWeekEndDate] = useState("");

  const CloneWeeklySchedule = () => {
    setLoading(true);
    let formData = {
      from_start: cloneWeekStartDate,
      from_end: cloneWeekEndDate,
      to_start: destinationWeekStartDate,
      to_end: destinationWeekEndDate,
    };
    CloneLastWeekEvents(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          alertMessage(res.message, "success");
          reloadEventsList();
          cloneWeeklyScheduleDialogClose();
        } else {
          alertMessage("Sorry! something wrong please try again", "error");
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          alertMessage(error, "error");
          setLoading(false);
          reloadEventsList();
          cloneWeeklyScheduleDialogClose();
        }
      });
  };
  return (
    <>
      <Dialog
        aria-labelledby="clone-weekly-schedule-title"
        aria-describedby="clone-weekly-schedule-desc"
        open={cloneWeeklyScheduleDialog}
        onClose={cloneWeeklyScheduleDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="clone-weekly-schedule-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Clone Weekly Schedule
          </Typography>
          <DialogActions>
            <IconButton
              onClick={cloneWeeklyScheduleDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "15px" }}>
            Instructions: Select a week that you want to clone using the date
            picker below. Then select a destination week, where you wish the
            cloned data to be copied. After you click 'Clone', your schedule
            will copied to the destination week.
          </DialogContentText>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <FormControl mt={4}>
                <Box>Cloned Week</Box>
                <CustomWeekPicker
                  setDates={(start, end) => {
                    setCloneWeekStartDate(moment(start).format("YYYY-MM-DD"));
                    setCloneWeekEndDate(moment(end).format("YYYY-MM-DD"));
                  }}
                  selectedStart={cloneWeekStartDate}
                  selectedEnd={cloneWeekEndDate}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl mt={4}>
                <Box>Destination Week</Box>
                <CustomWeekPicker
                  setDates={(start, end) => {
                    setDestinationWeekStartDate(
                      moment(start).format("YYYY-MM-DD")
                    );
                    setDestinationWeekEndDate(moment(end).format("YYYY-MM-DD"));
                  }}
                  selectedStart={destinationWeekStartDate}
                  selectedEnd={destinationWeekEndDate}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={cloneWeeklyScheduleDialogClose}
            sx={{ marginRight: "5px" }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => CloneWeeklySchedule()}
          >
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CloneWeeklySchedule;

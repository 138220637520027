import React from "react";
import { MenuItem, Select, InputLabel as MuiInputLabel } from "@mui/material";
import styled from "styled-components/macro";
import { useField, useFormikContext } from "formik";
import { spacing } from "@mui/system";

const CustomSelect = ({
  hideFirstOption,
  firstOptionTitle,
  fieldLabel,
  customCallbackMethod,
  name,
  options,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
    if (customCallbackMethod) {
      customCallbackMethod(evt);
    }
  };

  const configSelect = {
    ...field,
    ...otherProps,
    variant: "outlined",
    fullWidth: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
  }

  const InputLabel = styled(MuiInputLabel)`
    ${spacing};

    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  `;

  return (
    <React.Fragment>
      <InputLabel
        shrink
        sx={{
          background: "#fff",
          paddingLeft: "6px",
          paddingRight: "6px",
          marginLeft: "-4px",
        }}
      >
        {fieldLabel ? fieldLabel : ""}
      </InputLabel>
      <Select {...configSelect}>
        {!hideFirstOption && (
          <MenuItem key={0} value="">
            <span>{firstOptionTitle ? firstOptionTitle : "Select"}</span>
          </MenuItem>
        )}
        {options.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.key}
            </MenuItem>
          );
        })}
      </Select>
    </React.Fragment>
  );
};

export default CustomSelect;

export default [
  {
    id: "first_name",
    label: "First Name",
  },
  {
    id: "last_name",
    label: "Last Name",
  },
  {
    id: "department",
    label: "Department",
  },
  {
    id: "position",
    label: "Position",
  },
  {
    id: "room_name",
    label: "Room Name",
  },
  {
    id: "locker_num",
    label: "Locker Num",
  },
  {
    id: "inspection",
    label: "Inspection",
  },
  {
    id: "interval",
    label: "Interval",
  },
  {
    id: "action",
    label: "Action",
  },
];

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tourList: {
    TourName: true,
    tour_description: true,
    sched: true,
    buttons: true,
    active: true,
    last_completed: true,
    image: true,
    action: true,
  },
  checkpointList: {
    TagDescription: true,
    location_hint: true,
    image: true,
    critical: true,
    tasks: true,
    task_schedules: true,
    timing: true,
    action: true,
  },
};

export const tourTableColSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setTourList: (state, action) => {
      state.tourList = action.payload;
    },
    setCheckpointList: (state, action) => {
      state.checkpointList = action.payload;
    },
  },
});

export const { setTourList, setCheckpointList } = tourTableColSlice.actions;

export default tourTableColSlice.reducer;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";

const SearchExistingRecords = ({
  searchExistingRecordsDialog,
  searchExistingRecordsDialogClose,
}) => {
  const [visitorId, setVisitorId] = useState("");
  const navigate = useNavigate();

  const searchRecord = () => {
    if (visitorId) {
      navigate({ pathname: "/visitors/list-visitors?id=" + visitorId });
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="search-existing-records"
        aria-describedby="search-existing-records-desc"
        open={searchExistingRecordsDialog}
        onClose={searchExistingRecordsDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="vcomm-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Search Existing Records
          </Typography>
          <DialogActions>
            <IconButton
              onClick={searchExistingRecordsDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            sx={{ marginTop: "15px" }}
            name="visitor_id"
            label="Visitor ID number"
            InputLabelProps={{ shrink: true }}
            placeholder="Scan visitor ID"
            value={visitorId}
            onChange={(e) => setVisitorId(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                searchRecord();
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ paddingRight: "24px" }}>
          <Button
            color="primary"
            variant="contained"
            component={NavLink}
            to="/visitors/add-visitor"
          >
            Add a New Visitor
          </Button>
          <Button
            color="primary"
            variant="contained"
            component={NavLink}
            to="/visitors/list-visitors"
          >
            View Visitor List
          </Button>
          <Button
            sx={{ marginLeft: "8px" }}
            color="primary"
            variant="contained"
            onClick={() => searchRecord()}
          >
            Search
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={searchExistingRecordsDialogClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SearchExistingRecords;

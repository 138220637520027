import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Pagination,
  Select,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Settings as SettingsIcon,
  CameraAlt as CameraAltIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { PassdownItemList, PassdownItemColumns } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  passdownList,
  getPassdownChecked,
} from "../../redux/slices/reportSlice";
import { subDays, subMonths } from "date-fns";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import ImageListingModal from "../components/ImageGallery/ImageListingModal";
import imageData from "../components/ImageGallery/images.json";
import WithPermissionFallback from "../../utils/withPermissionFallback";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.report.passdownChecked);

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);
  return (
    <TableHead>
      <TableRow>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            //padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            <TableSortLabel
              active={
                headCell.key === "task_name" ? orderBy === headCell.key : false
              }
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={
                headCell.key === "task_name"
                  ? createSortHandler(headCell.key)
                  : null
              }
              hideSortIcon={headCell.key === "task_name" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    totalRecord,
    dataToFilter,
    setDataToFilter,
    filterRecord,
    startDateToFilter,
    setStartDateToFilter,
    endDateToFilter,
    setEndDateToFilter,
    handleFirstPage,
    colArray,
    getColumnList,
    getColumnChecked,
    setCurrentPage,
  } = props;
  const [actions, setActions] = React.useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const checkedValue = useSelector((state) => state.report.passdownChecked);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  const [dateRange, setDateRange] = React.useState(null);

  const toggleDatesRange = (event, newAlignment) => {
    setIsFilter(true);
    setDateRange(newAlignment);
    if (newAlignment === "1") {
      setStartDateToFilter(subDays(new Date(), 7));
    } else if (newAlignment === "2") {
      setStartDateToFilter(subDays(new Date(), 14));
    } else if (newAlignment === "3") {
      setStartDateToFilter(subMonths(new Date(), 1));
    } else {
      setStartDateToFilter(subDays(new Date(), 1));
    }
  };

  //Handler for checked unchecked columns
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getPassdownChecked([...checkedValue, value]));
    } else {
      dispatch(getPassdownChecked(checkedValue.filter((e) => e !== value)));
    }
  };

  //Method for search filter
  const applySearch = () => {
    const payload = {
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter(subDays(new Date(), 1));
    setEndDateToFilter(new Date());
    filterRecord({});
    setDateRange(null);
  };

  useEffect(() => {
    applySearch();
  }, [startDateToFilter]);
  return (
    <Toolbar>
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div style={{ width: "100%" }}>
        <Paper
          sx={{
            minWidth: { lg: "600px", md: "500px" },
            marginTop: "50px",
            "& .MuiGrid-item": {
              paddingTop: "0px !important",
            },
          }}
        >
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  mt: { md: 0, xs: "15px" },
                  // gap: "15px",
                  "& .MuiButtonBase-root": {
                    whiteSpace: "nowrap",
                    height: "100%",
                  },
                  "& .MuiToggleButtonGroup-root": {
                    height: "100%",
                  },
                }}
              >
                <Item sx={{ flexGrow: 1, width: "100%" }}>
                  <FormControl>
                    <TextField
                      fullWidth
                      id="dateRange"
                      // label="Date range"
                      value={
                        startDateToFilter && endDateToFilter
                          ? moment(startDateToFilter).format("MM/DD/YYYY") +
                            " - " +
                            moment(endDateToFilter).format("MM/DD/YYYY")
                          : ""
                      }
                      onClick={() => setFadedBox((prev) => !prev)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          applySearch();
                          setIsFilter(true);
                          // handleFirstPage();
                          setCurrentPage(1);
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Select date range"
                    />
                    {fadebox && (
                      <CustomDateRange
                        handleShow={fadebox}
                        conformation={(status) => setFadedBox((prev) => !prev)}
                        setDates={(start, end) => {
                          setStartDateToFilter(start);
                          setEndDateToFilter(end);
                          setIsFilter(true);
                          setDateRange(null);
                        }}
                        selectedStart={startDateToFilter}
                        selectedEnd={endDateToFilter}
                      />
                    )}
                  </FormControl>
                </Item>
                <Item>
                  {isFilter ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        resetFilter();
                        setDataToFilter(null);
                        setIsFilter(false);
                      }}
                      sx={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        height: "100%",
                        marginLeft: "-3px",
                      }}
                    >
                      Clear
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        height: "100%",
                        marginLeft: "-3px",
                      }}
                      onClick={(e) => {
                        applySearch({});
                        setIsFilter(true);
                        // handleFirstPage();
                        setCurrentPage(1);
                      }}
                    >
                      Search
                    </Button>
                  )}
                </Item>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ToggleButtonGroup
                sx={{ mt: { md: 0, xs: "15px" } }}
                color="primary"
                value={dateRange}
                exclusive
                onChange={toggleDatesRange}
              >
                <ToggleButton value="1">1 Week</ToggleButton>
                <ToggleButton value="2">2 Week</ToggleButton>
                <ToggleButton value="3">1 Month</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [startDateToFilter, setStartDateToFilter] = useState(
    subDays(new Date(), 1)
  );
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const [dataToFilter, setDataToFilter] = useState(null);
  const passdownListItems = useSelector((state) => state.report.passdownData);
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const checkedValue = useSelector((state) => state.report.passdownChecked);
  const dispatch = useDispatch();
  const [clickedImg, setClickedImg] = useState(null); //State related to images lightbox
  const [currentIndex, setCurrentIndex] = useState(null); //State related to image lighbox

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = passdownListItems.map((n) => n.recId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, reportedby) => {
    const selectedIndex = selected.indexOf(reportedby);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, reportedby);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSelected([]);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (reportedby) => selected.indexOf(reportedby) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // Methods related to Image lightbox
  const handleClickImage = () => {
    let firstLink = imageData.data[0].link;
    // console.log("+++++++++++++++++++++", firstLink);
    setCurrentIndex(0);
    setClickedImg(firstLink);
  };
  //Handler for image lighbox right roatation
  const handelRotationRight = () => {
    const totalLength = imageData.data.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = imageData.data[0].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = imageData.data.filter((item) => {
      return imageData.data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  //Handler for image lighbox left roatation

  const handelRotationLeft = () => {
    const totalLength = imageData.data.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = imageData.data[totalLength - 1].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = imageData.data.filter((item) => {
      return imageData.data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  //Method for calling the listing API
  const getPassdownList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      ...dataToFilter,
    }; //this will be used for sending filtered data
    PassdownItemList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(passdownList(res.data));
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          dispatch(passdownList(res.data));
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(passdownList([]));
        console.log(err);
        setTotalRecord(0);
      });
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelected([]);
  };

  //handlers for change pagination and change row per page
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    PassdownItemColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    PassdownItemColumns()
      .then((res) => {
        dispatch(getPassdownChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPassdownList();
    getColumnList();
  }, [rowsPerPage, currentPage, dataToFilter]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
  }, []);

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          filterRecord={(data) => filterRecord(data)}
          totalRecord={totalRecord}
          startDateToFilter={startDateToFilter}
          setStartDateToFilter={setStartDateToFilter}
          endDateToFilter={endDateToFilter}
          setEndDateToFilter={setEndDateToFilter}
          handleFirstPage={handleFirstPage}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
          setCurrentPage={setCurrentPage}
        />
        {/* <Typography mt={3} sx={{ padding: "0 12px" }}>
          From: {displayStartDate} To: {displayEndDate}
        </Typography> */}
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={passdownListItems.length}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(passdownListItems, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.recId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.recId}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => handleClick(event, row.recId)}
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell> */}

                          {checkedValue.includes("task_name") && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              // padding="none"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.task_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("task_location") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.task_location}
                            </TableCell>
                          )}
                          {checkedValue.includes("img_url") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              <CameraAltIcon
                                color="primary"
                                key={index}
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleClickImage()}
                              />
                            </TableCell>
                          )}
                          {checkedValue.includes("remark") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.remark}
                            </TableCell>
                          )}
                          {checkedValue.includes("user_name") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.user_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("passdown_time") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.passdown_time}
                            </TableCell>
                          )}
                          {checkedValue.includes("followup") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.followup === "N" ? "No" : "Yes"}
                            </TableCell>
                          )}
                          {checkedValue.includes("scantime") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.scantime}
                            </TableCell>
                          )}
                          {checkedValue.includes("information") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.information}
                            </TableCell>
                          )}
                          {checkedValue.includes("informed_time") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.informed_time}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={8} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* Component for Image Lighbox */}
      {clickedImg && (
        <ImageListingModal
          clickedImg={clickedImg}
          handelRotationRight={handelRotationRight}
          setClickedImg={setClickedImg}
          handelRotationLeft={handelRotationLeft}
        />
      )}
    </div>
  );
}

function Passdown() {
  return (
    <React.Fragment>
      <Helmet title="Passdown Items" />
      <WithPermissionFallback controller="Reports" action="getPassdown">
        <Typography variant="h3" gutterBottom display="inline">
          Passdown Items
        </Typography>

        {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default Passdown;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeeListing: {
    employee_id: true,
    fname: true,
    lname: true,
    department: true,
    position: true,
    action: true,
  },
  vehicleList: {
    fname: true,
    lname: true,
    department: true,
    position: true,
    vehicle_id: true,
    sticker: true,
    color: true,
    make: true,
    model: true,
    year: true,
    l_plate: true,
    v_state: true,
    vio: true,
    action: true,
  },
  violationList: {
    date: true,
    type: true,
    other_type: true,
    issued_type: true,
    image: true,
    action: true,
  },
  overdueKeyReportList: {
    user_name: true,
    department: true,
    item_id: true,
    result: true,
    date_time: true,
    comments: true,
    action: true,
  },
  lockersList: {
    locker_number: true,
    room_name: true,
    status: true,
    assignment: true,
    lock_type: true,
    action: true,
  },
  lockList: {
    brand: true,
    serial: true,
    room_name: true,
    locker_number: true,
    action: true,
  },
  assignmentList: {
    first_name: true,
    last_name: true,
    department: true,
    position: true,
    room_name: true,
    locker_num: true,
    inspection: true,
    interval: true,
    action: true,
  },
  attendanceLogList: {
    name: true,
    department: true,
    type: true,
    comment: true,
    created: true,
    notified: true,
    action: true,
  },
  alarmActivationLogList: {
    date: true,
    time: true,
    code_called: true,
    location: true,
    summary_description: true,
    action: true,
  },
  specialVisitorList: {
    name: true,
    arrival_date: true,
    departure_date: true,
    visitor_type: true,
    room_area: true,
    caretaker: true,
    phone: true,
    special_instructions: true,
    action: true,
  },
  covid19SurveyList: {
    reporteddatetime: true,
    temp: true,
    fname: true,
    lname: true,
    symptoms: true,
    tested: true,
    quarantined: true,
    exposed: true,
    travel: true,
    action: true,
  },
  shiftReportList: {
    date: true,
    supervisor: true,
    shift: true,
    training: true,
    irs: true,
    groupissues: true,
    ets: true,
    sd: true,
    efs: true,
    status: true,
    updatedutc: true,
    action: true,
  },
};

export const logTableColSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setEmployeeListing: (state, action) => {
      state.employeeListing = action.payload;
    },
    setVehicleList: (state, action) => {
      state.vehicleList = action.payload;
    },
    setViolationList: (state, action) => {
      state.violationList = action.payload;
    },
    setOverdueKeyReportList: (state, action) => {
      state.overdueKeyReportList = action.payload;
    },
    setLockersList: (state, action) => {
      state.lockersList = action.payload;
    },
    setLockList: (state, action) => {
      state.lockList = action.payload;
    },
    setAssignmentList: (state, action) => {
      state.assignmentList = action.payload;
    },
    setAttendanceLogList: (state, action) => {
      state.attendanceLogList = action.payload;
    },
    setAlarmActivationLogList: (state, action) => {
      state.alarmActivationLogList = action.payload;
    },
    setSpecialVisitorList: (state, action) => {
      state.specialVisitorList = action.payload;
    },
    setCovid19SurveyList: (state, action) => {
      state.covid19SurveyList = action.payload;
    },
    setShiftReportList: (state, action) => {
      state.shiftReportList = action.payload;
    },
  },
});

export const {
  setEmployeeListing,
  setVehicleList,
  setViolationList,
  setOverdueKeyReportList,
  setLockersList,
  setLockList,
  setAssignmentList,
  setAttendanceLogList,
  setAlarmActivationLogList,
  setSpecialVisitorList,
  setCovid19SurveyList,
  setShiftReportList,
} = logTableColSlice.actions;

export default logTableColSlice.reducer;

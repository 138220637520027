import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import { LocalShipping, MonetizationOn } from "@mui/icons-material";
import IconForStatus from "./icons-for-status";

const LostAndFoundStatus = ({ dispositionStatus }) => {
  // Classes Styling
  const useStyles = makeStyles((theme) => ({
    statusButton: {
      width: "20px",
      height: "20px",
      display: "inline-block",
      lineHeight: "20px",
      textAlign: "center",
      borderRadius: "6px",
      color: "#fff",
      fontSize: "12px",
      fontWeight: "bolder",
    },
  }));

  const classes = useStyles();

  const DispositionStatus = (value) => {
    switch (value) {
      case 0:
        return (
          <React.Fragment>
            <Typography
              component="span"
              gutterBottom
              className={classNames(classes.statusButton)}
              sx={{
                backgroundColor: "#ff0000",
              }}
            >
              L
            </Typography>
            <IconForStatus recordData={dispositionStatus} />
          </React.Fragment>
        );
        break;
      case 1:
        return (
          <React.Fragment>
            <Typography
              component="span"
              gutterBottom
              className={classNames(classes.statusButton)}
              sx={{
                backgroundColor: "#0000ff",
              }}
            >
              S
            </Typography>
            <Box
              sx={{
                display: "block",
                opacity: ".8",
              }}
            >
              <IconForStatus recordData={dispositionStatus} />
            </Box>
          </React.Fragment>
        );
        break;
      case 2:
        return (
          <React.Fragment>
            <Typography
              component="span"
              gutterBottom
              className={classNames(classes.statusButton)}
              sx={{
                backgroundColor: "#00cc00",
              }}
            >
              C
            </Typography>
            <Box
              sx={{
                display: "block",
                opacity: ".8",
              }}
            >
              <IconForStatus recordData={dispositionStatus} />
            </Box>
          </React.Fragment>
        );
        break;
      case 3:
        return (
          <React.Fragment>
            <Typography
              component="span"
              gutterBottom
              className={classNames(classes.statusButton)}
              sx={{
                backgroundColor: "#999999",
              }}
            >
              D
            </Typography>
            <Box
              sx={{
                display: "block",
                opacity: ".8",
              }}
            >
              <IconForStatus recordData={dispositionStatus} />
            </Box>
          </React.Fragment>
        );
        break;
      case 4:
        return (
          <React.Fragment>
            <Typography
              component="span"
              gutterBottom
              className={classNames(classes.statusButton)}
              sx={{
                backgroundColor: "#ff9900",
              }}
            >
              P
            </Typography>
            <Box
              sx={{
                display: "block",
                opacity: ".8",
              }}
            >
              <IconForStatus recordData={dispositionStatus} />
            </Box>
          </React.Fragment>
        );
        break;

      default:
        break;
    }
  };

  return DispositionStatus(dispositionStatus.disposition_status);
};

export default LostAndFoundStatus;

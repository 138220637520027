import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { PrintReceivingRecord, RecipientList } from "../../../api";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton,
  Card,
  CardContent,
  TextField,
  FormHelperText,
  Button,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  Grid,
  Paper,
  Chip,
  Box,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import CustomDateRange from "../../../components/form-components/CustomDateRange";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
  ${spacing};
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const PrintReceive = ({ printReceiving, printReceivingClose }) => {
  const [packageStatus, setPackageStatus] = useState("");
  const [fadebox, setFadedBox] = useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState(new Date());
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleChangeStatus = (event) => {
    setPackageStatus(event.target.value);
  };

  const [recipient, setRecipient] = useState("");
  const handleChangeRecipient = (event) => {
    setRecipient(event.target.value);
  };
  const packageStatusList = ["Received", "Delivered", "Both"];
  const [recipientData, setRecipientData] = useState([]);
  const recipientList = () => {
    RecipientList()
      .then((res) => {
        if (res.statusCode === 200) {
          setRecipientData(res.data);
        } else {
          setRecipientData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * It calls the PrintEmergencyReport() function, which returns a promise. If the promise is resolved,
   * it creates a link element, sets the href attribute to the base64 encoded pdf, sets the download
   * attribute to the name of the file, and clicks the link
   */
  const onButtonClick = () => {
    setLoading(true);
    let payload = {
      package_recepient: recipient,
      package_status: packageStatus,
      start_date: moment(startDateToFilter).format("YYYY-MM-DD"),
      end_date: moment(endDateToFilter).format("YYYY-MM-DD"),
    };
    if (packageStatus != "") {
      PrintReceivingRecord(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            const linkSource = `data:application/pdf;base64,${res.data}`;
            const downloadLink = document.createElement("a");
            const fileName = "SOVA-SPECIAL-VISITORS.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            setLoading(false);
          } else {
            dispatch(getMsg("No packages found"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
    } else {
      dispatch(getMsg("Please select package status and date"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      setLoading(false);
    }
  };
  useEffect(() => {
    recipientList();
  }, []);

  return (
    <>
      <Dialog
        open={printReceiving}
        onClose={printReceivingClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="storage-location-title"
        aria-describedby="storage-location-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="storage-location-title"
        >
          <Typography variant="h4" component="div">
            Print Receiving Record
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={printReceivingClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <FormControl mt={3}>
                <TextField
                  fullWidth
                  id="dateRange"
                  label="Date range"
                  value={
                    startDateToFilter && endDateToFilter
                      ? moment(startDateToFilter).format("MM-DD-YYYY") +
                        " - " +
                        moment(endDateToFilter).format("MM-DD-YYYY")
                      : ""
                  }
                  onClick={() => setFadedBox((prev) => !prev)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Click to select date range"
                />
                {fadebox && (
                  <CustomDateRange
                    handleShow={fadebox}
                    conformation={(status) => setFadedBox((prev) => !prev)}
                    setDates={(start, end) => {
                      setStartDateToFilter(start);
                      setEndDateToFilter(end);
                    }}
                    selectedStart={startDateToFilter}
                    selectedEnd={endDateToFilter}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl mt={3}>
                <InputLabel shrink id="demo-select-small">
                  Package Status
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={packageStatus}
                  label="Package Status"
                  onChange={handleChangeStatus}
                  displayEmpty
                >
                  <MenuItem value="">--select--</MenuItem>
                  {packageStatusList.map((item) => {
                    return (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl mt={3}>
                <InputLabel shrink id="demo-select-small">
                  Recipient
                </InputLabel>
                <Select
                  labelId="department"
                  id="demo-select-small"
                  value={recipient}
                  label="Recipient"
                  onChange={handleChangeRecipient}
                  displayEmpty
                >
                  <MenuItem value="">--select--</MenuItem>
                  {recipientData.map((item) => {
                    return (
                      <MenuItem value={item.value} key={item.value}>
                        {item.key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={printReceivingClose}
          >
            Cancel
          </Button>
          <Button color="primary" variant="outlined" onClick={onButtonClick}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default PrintReceive;

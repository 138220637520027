import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import ClonePackage from "./Inner-components/ClonePackage";
import TrackingSearch from "./Inner-components/TrackingSearch";
import PackagePricing from "./Inner-components/PackagePricing";
import BulkDelivery from "./Inner-components/BulkDelivery";
import {
  PackagesList,
  DeletePackage,
  StorageLocationDropDown,
} from "../../api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import PackagesListCol from "../table-columns-json/packages/PackagesList";
import { setPackagesList } from "../../redux/slices/packages/packagesTableColSlice";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  DialogTitle,
  DialogActions,
  Dialog,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Attractions as AttractionsIcon,
  InsertDriveFile as InsertDriveFileIcon,
  PriceCheck as PriceCheckIcon,
  Approval as ApprovalIcon,
  DeliveryDining as DeliveryDiningIcon,
  FilterNone as FilterNoneIcon,
  Print as PrintIcon,
} from "@mui/icons-material";
import { padding, spacing } from "@mui/system";
import CustomPagination from "../../components/table-components/CustomPagination";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import PrintReceive from "./Inner-components/PrintReceive";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
// const useStyles = makeStyles((theme) => ({
//   alphabets: {
//     marginTop: "15px",
//     "& .MuiList-root": {
//       display: "flex",
//       overflowX: "auto",
//       background: "#7aa8ff0d",
//       padding: "0px",
//       "& .MuiListItemButton-root": {
//         paddingLeft: "13px",
//         paddingRight: "13px",
//         textAlign: "center",
//       },
//     },
//     "& .MuiList-root::-webkit-scrollbar": {
//       width: "5px",
//       height: "5px",
//     },
//     "& .MuiList-root::-webkit-scrollbar-track": {
//       backgroundColor: "#bed2f561",
//     },
//     "& .MuiList-root::-webkit-scrollbar-thumb": {
//       backgroundColor: "#92a6c96e",
//     },
//   },
// }));

// const TableWrapper = styled.div`
//   overflow-y: auto;
//   max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
// `;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex-grow: 1;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    padding: "20px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 70px;
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  id,
  carrier,
  sender,
  recipient,
  group,
  trackingNumber,
  disposition,
  stored,
  received,
  delivered,
  action
) {
  return {
    id,
    carrier,
    sender,
    recipient,
    group,
    trackingNumber,
    disposition,
    stored,
    received,
    delivered,
    action,
  };
}
const rows = [
  createData(
    "20296",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
  createData(
    "20297",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
  createData(
    "20298",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
  createData(
    "20299",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
  createData(
    "20291",
    0,
    "Terra Pharma",
    "Steve Jobs",
    "hwjwh",
    "1234123412341234",
    "Received",
    "Main storage",
    "08/03/2022 06:00",
    "08/03/2022 07:00",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "carrier",
    numeric: true,
    disablePadding: false,
    label: "Carrier",
  },
  {
    id: "sender",
    numeric: true,
    disablePadding: false,
    label: "Sender",
  },
  {
    id: "recipient",
    numeric: true,
    disablePadding: false,
    label: "Recipient",
  },
  {
    id: "group",
    numeric: true,
    disablePadding: false,
    label: "Group",
  },
  {
    id: "trackingNumber",
    numeric: true,
    disablePadding: false,
    label: "Tracking Number",
  },
  {
    id: "disposition",
    numeric: true,
    disablePadding: false,
    label: "Disposition",
  },
  {
    id: "stored",
    numeric: true,
    disablePadding: false,
    label: "Stored",
  },
  {
    id: "received",
    numeric: true,
    disablePadding: false,
    label: "Received",
  },
  {
    id: "delivered",
    numeric: true,
    disablePadding: false,
    label: "Delivered",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {PackagesListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "action" ? null : createSortHandler(headCell.id)
              }
              hideSortIcon={headCell.id == "action" ? true : false}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setDataToFilter,
    tableCalRedux,
    handleDeleteOpen,
    setIsShow,
  } = props;
  const [actions, setActions] = React.useState("");
  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultPackagesTableCol.packagesList
  );
  const [isFilter, setIsFilter] = useState(false);
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [csvData, setCsvData] = useState([]);

  const csv = () => {
    let payload = {
      limit: totalRecord,
    };
    PackagesList(1, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setCsvData(res.data);
        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let headers = [
    { label: "Item", key: "tracking_number" },
    { label: "Guest Name", key: "recipient_first_name" },
    { label: "Sender", key: "sender_name" },
    { label: "Package Type", key: "" },
    { label: "Barcode", key: "barcode" },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    csv();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);
  //Select
  const [storageLocation, setStorageLocation] = React.useState("");
  const selectStorageLocation = (event) => {
    setStorageLocation(event.target.value);
  };
  const [carrier, setCarrier] = React.useState("");
  const selectCarrier = (event) => {
    setCarrier(event.target.value);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  // Tracking Search Modal
  const [trackingSearch, setTrackingSearch] = React.useState(false);
  const trackingSearchOpen = () => {
    setTrackingSearch(true);
  };
  const trackingSearchClose = () => {
    setTrackingSearch(false);
  };

  // Package Pricing Modal
  const [packagePricing, setPackagePricing] = useState(false);
  const packagePricingOpen = () => {
    setPackagePricing(true);
    setAnchorEl(null);
  };
  const packagePricingClose = () => {
    setPackagePricing(false);
  };

  // Bulk Delivery
  const [bulkDelivery, setBulkDelivery] = React.useState(false);
  const bulkDeliveryOpen = () => {
    setBulkDelivery(true);
  };
  const bulkDeliveryClose = () => {
    setBulkDelivery(false);
  };

  //Print Receiving Record
  const [printReceiving, setPrintReceiving] = useState(false);
  const printReceivingOpen = () => {
    setPrintReceiving(true);
  };
  const printReceivingClose = () => {
    setPrintReceiving(false);
  };

  const [checked, setChecked] = React.useState(false);

  const handleChangeDelivered = (event) => {
    setChecked(event.target.checked);
    setIsShow(event.target.checked);
  };

  const [storageData, setStorageData] = useState([]);
  const storageList = () => {
    StorageLocationDropDown()
      .then((res) => {
        if (res.statusCode === 200) {
          setStorageData(res.data);
        } else {
          setStorageData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const carrrier = [
    "DHL",
    "Fed-Ex",
    "UPS",
    "USPS",
    "Amazon",
    "OnTrac",
    "Other",
  ];
  const [fadebox, setFadedBox] = useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");

  const [intialValue, setIntialValue] = useState({
    search_value: "",
    sender_name: "",
    name: "",
    group_name: "",
    tracking_number: "",
    description_note: "",
    handling_instruction: "",
    storage_location: "",
    carrier: "",
    package_status: "",
    recipient: "",
    start_date: null,
    end_date: null,
  });

  const handleInput = (e) => {
    setIntialValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFilter = (val) => {
    setDataToFilter(() => ({
      ...intialValue,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
    }));
  };

  const filterClear = () => {
    setIntialValue({
      search_value: "",
      sender_name: "",
      name: "",
      group_name: "",
      tracking_number: "",
      description_note: "",
      handling_instruction: "",
      storage_location: "",
      carrier: "",
      package_status: "",
      recipient: "",
      start_date: null,
      end_date: null,
      startDateToFilter: "",
      endDateToFilter: "",
    });
  };

  useEffect(() => {
    storageList();
  }, []);

  return (
    <Toolbar sx={{ gap: "10px" }}>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div>
        <Typography
          sx={{
            marginLeft: { xs: 1, md: 0 },
            fontSize: { xs: 12, md: 15 },
          }}
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content" }}
        >
          {totalRecord > 1
            ? `${totalRecord} records found`
            : `${totalRecord} record found`}
        </Typography>
      </div>
      <Spacer />
      <div>
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            {/* <from> */}
            <TextField
              id="search"
              value={intialValue.search_value}
              name="search_value"
              onChange={handleInput}
              placeholder="Search by name, tracking number or click down arrow"
              inputProps={{}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <FormControl mt={3}>
                          <TextField
                            fullWidth
                            id="dateRange"
                            label="Date range"
                            value={
                              startDateToFilter && endDateToFilter
                                ? moment(startDateToFilter).format(
                                    "MM-DD-YYYY"
                                  ) +
                                  " - " +
                                  moment(endDateToFilter).format("MM-DD-YYYY")
                                : ""
                            }
                            onClick={() => setFadedBox((prev) => !prev)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Click to select date range"
                          />
                          {fadebox && (
                            <CustomDateRange
                              handleShow={fadebox}
                              conformation={(status) =>
                                setFadedBox((prev) => !prev)
                              }
                              setDates={(start, end) => {
                                setStartDateToFilter(start);
                                setEndDateToFilter(end);
                              }}
                              selectedStart={startDateToFilter}
                              selectedEnd={endDateToFilter}
                            />
                          )}
                        </FormControl>
                        <FormControl>
                          <TextField
                            fullWidth
                            id="sender-name"
                            label="Sender name"
                            name="sender_name"
                            onChange={handleInput}
                            value={intialValue.sender_name}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="ACME building products, Inc."
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            fullWidth
                            id="recipient-name"
                            label="Recipient name"
                            name="name"
                            onChange={handleInput}
                            value={intialValue.name}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="John Smith"
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            fullWidth
                            id="group-name"
                            label="group name"
                            name="group_name"
                            onChange={handleInput}
                            value={intialValue.group_name}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="XYZ consulting"
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            fullWidth
                            id="tracking-id"
                            label="Tracking ID"
                            name="tracking_number"
                            onChange={handleInput}
                            value={intialValue.tracking_number}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Tracking number of last four"
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            fullWidth
                            id="description"
                            label="Description"
                            name="description_note"
                            value={intialValue.description_note}
                            onChange={handleInput}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Small white box"
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            fullWidth
                            id="handeling-instructions"
                            label="Special handeling instructions"
                            name="handling_instruction"
                            onChange={handleInput}
                            value={intialValue.handling_instruction}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Fragile, store upright, refrigerate, etc."
                          />
                        </FormControl>
                        <FormControl>
                          <InputLabel shrink required id="location">
                            Storage location
                          </InputLabel>
                          <Select
                            labelId="position"
                            id="storage-loc"
                            name="storage_location"
                            onChange={handleInput}
                            value={intialValue.storage_location}
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="">--Select location--</MenuItem>
                            {storageData.map((item) => {
                              return (
                                <MenuItem value={item.key} key={item.value}>
                                  {item.key}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl>
                          <InputLabel shrink required id="carrier">
                            Carrier
                          </InputLabel>
                          <Select
                            labelId="position"
                            id="storage-loc"
                            name="carrier"
                            onChange={handleInput}
                            value={intialValue.carrier}
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="">--Select carrier--</MenuItem>
                            {carrrier.map((item) => {
                              return (
                                <MenuItem value={item} key={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl>
                          <FormLabel component="legend">
                            Package status
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="p-status"
                            name="package_status"
                            onChange={handleInput}
                            value={intialValue.package_status}
                          >
                            <FormControlLabel
                              value="Expected"
                              control={<Radio />}
                              label="Expected"
                            />
                            <FormControlLabel
                              value="Received"
                              control={<Radio />}
                              label="Received"
                            />
                            <FormControlLabel
                              value="Delivered"
                              control={<Radio />}
                              label="Delivered"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl>
                          <FormLabel component="legend">Recipient</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="recipient"
                            defaultValue="guest"
                            name="recipient"
                            onChange={handleInput}
                            value={intialValue.recipient}
                          >
                            <FormControlLabel
                              value="guest"
                              control={<Radio />}
                              label="Guest"
                            />
                            <FormControlLabel
                              value="emp"
                              control={<Radio />}
                              label="Employee"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleFilter();
                              setIsFilter(true);
                              handleCloseForm();
                            }}
                            mt={6}
                          >
                            Filter
                          </Button>
                        </FormControl>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
            {/* </from> */}
          </Item>
          <Item>
            {isFilter ? (
              <Button
                variant="contained"
                color="primary"
                mt={6}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  filterClear();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setStartDateToFilter("");
                  setEndDateToFilter("");
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  if (intialValue.search_value != "") {
                    handleFilter();
                    setIsFilter(true);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <FormControlLabel
          sx={{ whiteSpace: "nowrap" }}
          control={
            <Switch checked={checked} onChange={handleChangeDelivered} />
          }
          label="Show Delivered"
        />
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-package"
              component={NavLink}
              to="/packages/add-package"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Package" pl={0} />
            </MenuItem>
            <MenuItem onClick={trackingSearchOpen}>
              <ListItemIcon>
                <AttractionsIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Tracking Search" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="bulk-receiving"
              component={NavLink}
              to="/packages/bulk-receiving"
            >
              <ListItemIcon>
                <ApprovalIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Bulk Receiving" pl={0} />
            </MenuItem>
            <CSVLink
              data={csvData}
              headers={headers}
              onClick={handleClose}
              filename="SOVA_package_list"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Download CSV" pl={0} />
              </MenuItem>
            </CSVLink>

            <MenuItem onClick={packagePricingOpen}>
              <ListItemIcon>
                <PriceCheckIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Package Pricing" pl={0} />
            </MenuItem>
            <MenuItem onClick={printReceivingOpen}>
              <ListItemIcon>
                <PrintIcon />
              </ListItemIcon>
              <ListItemText
                size="large"
                primary="Print Receiving Record"
                pl={0}
              />
            </MenuItem>
            <MenuItem onClick={bulkDeliveryOpen}>
              <ListItemIcon>
                <DeliveryDiningIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Bulk Delivery" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setPackagesList(data));
                    }}
                    listing={PackagesListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setPackagesList(defaultTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
          {trackingSearch && (
            <TrackingSearch
              trackingSearch={trackingSearch}
              setTrackngSearch={setTrackingSearch}
              trackingSearchOpen={trackingSearchOpen}
              trackingSearchClose={trackingSearchClose}
            />
          )}
          {bulkDelivery && (
            <BulkDelivery
              bulkDelivery={bulkDelivery}
              setBulkDelivery={setBulkDelivery}
              bulkDeliveryOpen={bulkDeliveryOpen}
              bulkDeliveryClose={bulkDeliveryClose}
            />
          )}
          {packagePricing && (
            <PackagePricing
              packagePricing={packagePricing}
              setPackagePricing={setPackagePricing}
              packagePricingOpen={packagePricingOpen}
              packagePricingClose={packagePricingClose}
            />
          )}
          {printReceiving && (
            <PrintReceive
              printReceiving={printReceiving}
              setPrintReceiving={setPrintReceiving}
              printReceivingOpen={printReceivingOpen}
              printReceivingClose={printReceivingClose}
            />
          )}
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [packagesData, setPackagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [isShow, setIsShow] = useState("");
  const dispatch = useDispatch();
  const tableCalRedux = useSelector(
    (state) => state.packagesTableCol.packagesList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = packagesData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // Clone Package Modal
  const [clonePackage, setClonePackage] = React.useState(false);
  const clonePackageOpen = () => {
    setClonePackage(true);
  };
  const clonePackageClose = () => {
    setClonePackage(false);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };

  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const packagesList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      show_delivered: isShow ? "Show" : "Hide",
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    PackagesList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setPackagesData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setPackagesData([]);
          setTotalRecord(0);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const deletePackage = () => {
    let url = selected.length > 0 ? selected : id;
    DeletePackage(url)
      .then((res) => {
        if (res.statusCode === 200) {
          packagesList();
          handleDeleteClose();
          dispatch(getMsg(`Record(s) has been deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setSelected([]);
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again later"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  useEffect(() => {
    packagesList();
  }, [rowsPerPage, currentPage, dataToFilter, order, orderBy, isShow]);
  return (
    <div>
      {selected.length || id > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete the package(s)?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={deletePackage} variant="contained" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setDataToFilter={setDataToFilter}
          tableCalRedux={tableCalRedux}
          handleDeleteOpen={handleDeleteOpen}
          setIsShow={setIsShow}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={packagesData.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(packagesData, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.id === true ? "" : "hide-cell"
                          }
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.carrier === true ? "" : "hide-cell"
                          }
                        >
                          <ThumbnailTableWrapper>
                            {row.carrier_dropping ? (
                              <Image
                                component="img"
                                alt="Guage Chart"
                                src={row.carrier_dropping}
                              />
                            ) : null}
                          </ThumbnailTableWrapper>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.sender_name === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.sender_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.recipient === true ? "" : "hide-cell"
                          }
                        >
                          {row.recipient_first_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.group === true ? "" : "hide-cell"
                          }
                        >
                          {row.recipient_group_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.tracking_number === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.tracking_number}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                          className={
                            tableCalRedux.package_status === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.package_status}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.storage_location === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.current_storage_location}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.package_arrival_date === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.package_arrival_date}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.delivery_datetime === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.delivery_datetime}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              aria-label="view-package"
                              component={NavLink}
                              to={`/packages/view-package/${id}`}
                            >
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText primary="View" pl={1} />
                            </MenuItem>
                            <MenuItem
                              aria-label="edit-package"
                              component={NavLink}
                              to={`/packages/edit-package/${id}`}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleDeleteOpen();
                                handleActionClose();
                              }}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                clonePackageOpen();
                                handleActionClose();
                              }}
                            >
                              <ListItemIcon>
                                <FilterNoneIcon />
                              </ListItemIcon>
                              <ListItemText primary="Clone" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {packagesData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecord}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {clonePackage && (
        <ClonePackage
          clonePackage={clonePackage}
          setClonePackage={setClonePackage}
          clonePackageOpen={clonePackageOpen}
          clonePackageClose={clonePackageClose}
          id={id}
          packagesList={packagesList}
        />
      )}
    </div>
  );
}

function ListPackages() {
  return (
    <React.Fragment>
      <Helmet title="Package Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Package Management
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ListPackages;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { format, subDays } from "date-fns";
import { GetEquipmentListLog } from "../../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  ListItemIcon,
  MenuItem,
  Menu,
  Card,
  Box,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  SettingsInputComposite as SettingsInputCompositeIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const ListItemText = styled(MuiListItemText)(spacing);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const EquipmentLogPopup = (props) => {
  const { open, logModalClose, setOpen, id, equipType, equipName } = props;
  const [dense, setDense] = React.useState(true);
  const [startdate, setStartDate] = React.useState(subDays(new Date(), 15));
  const [enddate, setEndDate] = React.useState(new Date());
  const [equipmentListLog, setEquipmentListLog] = useState([]);
  const [loading, setLoading] = useState(false);
  // Log Action Dropdown
  const [logDropdown, setLogDropdown] = React.useState(null);
  const actionLogOpen = (event) => {
    setLogDropdown(event.currentTarget);
  };
  const actionLogClose = () => {
    setLogDropdown(null);
  };

  const getEquipmentListLog = () => {
    let payload = {
      start_date: format(startdate, "yyyy-MM-dd"),
      end_date: format(enddate, "yyyy-MM-dd"),
    };
    setLoading(true);
    GetEquipmentListLog(id, { params: payload })
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipmentListLog(res.data);
          setLoading(false);
        } else {
          setEquipmentListLog([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getEquipmentListLog();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={open}
        onClose={logModalClose}
        aria-labelledby="equipment-log"
        aria-describedby="equipment-log"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Equipment log for {equipType} {equipName}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText id="equipment-log" sx={{ padding: "30px 20px" }}>
            <Grid container spacing={4} mt={2}>
              <Grid item xs={12} md={4}>
                <DatePicker
                  id="start-date"
                  label="Start date"
                  value={startdate}
                  onChange={(newValueStartDate) => {
                    setStartDate(newValueStartDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Start date",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  id="end-date"
                  label="End date"
                  value={enddate}
                  onChange={(newValueEndDate) => {
                    setEndDate(newValueEndDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "End date",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ height: "100%" }}
                  onClick={getEquipmentListLog}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
          <Paper>
            <TableWrapper>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "15px",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Checkout Date</TableCell>
                    <TableCell>Checkout By</TableCell>
                    <TableCell>Check In Date</TableCell>
                    <TableCell>Check In By</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                ) : (
                  <TableBody>
                    {equipmentListLog.map((data) => {
                      return (
                        <TableRow hover>
                          <TableCell>{data.fname}</TableCell>
                          <TableCell>{data.checked_out_time}</TableCell>
                          <TableCell>{data.check_out_by}</TableCell>
                          <TableCell>{data.checked_in_time}</TableCell>
                          <TableCell>{data.check_in_by}</TableCell>
                        </TableRow>
                      );
                    })}
                    {equipmentListLog.length === 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 7 : 0) * equipmentListLog.length < 0,
                        }}
                      >
                        <TableCell colspan={6} align="center">
                          No records found for selected date range
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableWrapper>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
          <Button onClick={logModalClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EquipmentLogPopup;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import MUIRichTextEditor from "mui-rte";

// Classes Styling
const useStyles = makeStyles((theme) => ({
  textEditor: {
    position: "relative",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    borderRadius: "4px",
    "& #mui-rte-container": {
      marginTop: 0,
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      position: "absolute",
      padding: "15px",
      display: "block",
    },
    "& #mui-rte-editor": {
      height: "180px",
      overflowY: "auto",
    },
    "& #mui-rte-editor-container": {
      height: "170px",
      overflowY: "auto",
      borderTop: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    },
    "& #mui-rte-toolbar": {
      padding: "5px 15px",
    },
    "& .DraftEditor-editorContainer": {
      padding: "15px",
    },
  },
}));
const CustomTextEditor = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.textEditor} sx={{ marginTop: "15px" }}>
        <MUIRichTextEditor
          label={
            <Typography sx={{ padding: "15px" }}>
              Type something here...
            </Typography>
          }
          //   onSave={save}
          inlineToolbar={true}
        />
      </Box>
    </>
  );
};

export default CustomTextEditor;

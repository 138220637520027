export default [
  {
    id: "carrier",
    label: "Carrier",
  },
  {
    id: "service",
    label: "Service",
  },
  {
    id: "amount_paid",
    label: "Rate",
  },
  {
    id: "recipient",
    label: "Recipient",
  },
  {
    id: "created",
    label: "Created",
  },
  {
    id: "lostfound_id",
    label: "ID",
  },
  {
    id: "tracking_status",
    label: "Tracking Status",
  },
  {
    id: "tracking_id",
    label: "Tracking Number",
  },
];

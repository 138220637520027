import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import { StorageLocationDropDown, AddBulkPackage } from "../../api";
import {
  Grid,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  TextField,
  Button,
  FormControl as MuiFormControl,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Select,
  InputLabel as MuiInputLabel,
  MenuItem,
  RadioGroup,
  Box,
  Radio,
  FormControlLabel,
  FormLabel,
  IconButton,
  FormHelperText,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Delete as DeleteIcon,
  CameraAlt as CameraAltIcon,
} from "@mui/icons-material";
import { DatePicker, DateTimePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../helper/helper";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
//Classes Styling
const useStyles = makeStyles((theme) => ({
  BulletList: {
    listStyle: "disc",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "disc",
    },
  },
  decimalList: {
    listStyle: "decimal",
    display: "block",
    padding: "0 20px",
    margin: "15px 0 20px",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item",
      listStyle: "decimal",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function BulkReceivingForm() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // Storage Location
  const [storageLocation, setStorageLocation] = React.useState("");
  const selectStorageLocation = (event) => {
    setStorageLocation(event.target.value);
  };
  let navigate = useNavigate();
  //Add More Fields
  const [moreFields, setMoreFields] = React.useState(0);
  const addMoreFields = () => {
    setMoreFields(moreFields + 1);
    console.log(moreFields);
  };
  //Remove Fields
  const removeFields = () => {
    setMoreFields(moreFields - 1);
    console.log(moreFields);
  };

  const [storageData, setStorageData] = useState([]);
  const storageList = () => {
    StorageLocationDropDown()
      .then((res) => {
        if (res.statusCode === 200) {
          setStorageData(res.data);
        } else {
          setStorageData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    storageList();
  }, []);

  const initialValues = {
    package: [
      {
        tracking_number: "",
        sender_name: "",
        recipient_first_name: "",
        recipient_last_name: "",
        current_storage_location: "",
        receive_package_image: null,
        pallet: "0",
        pallet_id: "",
      },
    ],
  };
  const validationSchema = Yup.object().shape({
    package: Yup.array().when((same, schema) => {
      return schema
        .of(
          Yup.object().shape({
            tracking_number: Yup.string().required(
              "Please enter carrier tracking number"
            ),
            sender_name: Yup.string().required("Please enter sending party"),
            recipient_first_name: Yup.string().required(
              "Please enter sending receiving party first name"
            ),
            recipient_last_name: Yup.string().required(
              "Please enter sending receiving party last name"
            ),
            // pallet_id: Yup.string()
            //   .required()
            //   .when(["pallet"], (same, schema) => {
            //     return same == "1"
            //       ? schema.required("Please enter pallet ID")
            //       : schema;
            //   }),
          })
        )
        .min(1, "Please select one passengers");
    }),
  });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      console.log(values);
      AddBulkPackage(values)
        .then((res) => {
          if (res.statusCode === 200) {
            resetForm();
            dispatch(getMsg("Package saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
            navigate({
              pathname: "/packages/list-packages",
            });
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6" component="div" gutterBottom>
            Instructions:
          </Typography>
          <List className={classes.decimalList}>
            <ListItem>
              <ListItemText primary="Enter tracking number manually or scan when cursor is in field" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Add up to 30 packages by clicking the plus icon" />
            </ListItem>
            <ListItem>
              <ListItemText primary="When finished, click the blue save button at the bottom" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Any changes to these packages or additional information can be added in the package list screen" />
            </ListItem>
          </List>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                {values.package.map((item, index) => {
                  return (
                    <Grid container spacing={6} alignItems="end">
                      <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        order={{ xl: 1, md: 2, xs: 2 }}
                      >
                        <FormControl>
                          <TextField
                            name="tracking_number"
                            label="Carrier Tracking No*"
                            placeholder="Scan or track #"
                            InputLabelProps={{ shrink: true }}
                            value={item.tracking_number}
                            onChange={(e) => {
                              let newp = values.package;
                              newp[index].tracking_number = e.target.value;
                              setFieldValue("package", newp);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.package &&
                                touched.package[index]?.tracking_number &&
                                errors?.package &&
                                errors?.package[index]?.tracking_number
                            )}
                            helperText={
                              touched.package &&
                              touched.package[index]?.tracking_number &&
                              errors?.package &&
                              errors?.package[index]?.tracking_number
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        order={{ xl: 2, md: 3, xs: 3 }}
                      >
                        <FormControl>
                          <TextField
                            name="sender_name"
                            label="Sending Party*"
                            //placeholder="Scan or track #"
                            InputLabelProps={{ shrink: true }}
                            value={item.sender_name}
                            onChange={(e) => {
                              let newp = values.package;
                              newp[index].sender_name = e.target.value;
                              setFieldValue("package", newp);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.package &&
                                touched.package[index]?.sender_name &&
                                errors?.package &&
                                errors?.package[index]?.sender_name
                            )}
                            helperText={
                              touched.package &&
                              touched.package[index]?.sender_name &&
                              errors?.package &&
                              errors?.package[index]?.sender_name
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        order={{ xl: 3, md: 4, xs: 4 }}
                      >
                        <FormControl>
                          <TextField
                            name="recipient_first_name"
                            label="Receiving Party First Name*"
                            //placeholder="Scan or track #"
                            InputLabelProps={{ shrink: true }}
                            value={item.recipient_first_name}
                            onChange={(e) => {
                              let newp = values.package;
                              newp[index].recipient_first_name = e.target.value;
                              setFieldValue("package", newp);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.package &&
                                touched.package[index]?.recipient_first_name &&
                                errors?.package &&
                                errors?.package[index]?.recipient_first_name
                            )}
                            helperText={
                              touched.package &&
                              touched.package[index]?.recipient_first_name &&
                              errors?.package &&
                              errors?.package[index]?.recipient_first_name
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        order={{ xl: 4, md: 5, xs: 5 }}
                      >
                        <FormControl>
                          <TextField
                            name="recipient_last_name"
                            label="Receiving Party Last Name*"
                            InputLabelProps={{ shrink: true }}
                            value={item.recipient_last_name}
                            onChange={(e) => {
                              let newp = values.package;
                              newp[index].recipient_last_name = e.target.value;
                              setFieldValue("package", newp);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.package &&
                                touched.package[index]?.recipient_last_name &&
                                errors?.package &&
                                errors?.package[index]?.recipient_last_name
                            )}
                            helperText={
                              touched.package &&
                              touched.package[index]?.recipient_last_name &&
                              errors?.package &&
                              errors?.package[index]?.recipient_last_name
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        order={{ xl: 5, md: 6, xs: 6 }}
                      >
                        <FormControl>
                          <InputLabel
                            shrink
                            id="department"
                            sx={{
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Storage location
                          </InputLabel>
                          <Select
                            labelId="department"
                            id="storage-location"
                            name="current_storage_location"
                            value={item.current_storage_location}
                            label="Storage location"
                            fullWidth
                            displayEmpty
                            onChange={(e) => {
                              let newp = values.package;
                              newp[index].current_storage_location =
                                e.target.value;
                              setFieldValue("package", newp);
                            }}
                            onBlur={handleBlur}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {storageData.map((item) => {
                              return (
                                <MenuItem
                                  divider={true}
                                  value={item.value}
                                  key={item.value}
                                >
                                  {item.key}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        order={{ xl: 5, md: 6, xs: 6 }}
                      >
                        <FormControl>
                          <InputLabel
                            shrink
                            sx={{
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Image
                          </InputLabel>
                          <label htmlFor="raised-button-file">
                            <TextField
                              id="raised-button-file"
                              type="file"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                  const base64Image = reader.result;
                                  let newp = values.package;
                                  newp[index].receive_package_image =
                                    base64Image;
                                  setFieldValue("package", newp);
                                };
                              }}
                            />
                          </label>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        order={{ xl: 7, md: 8, xs: 8 }}
                      >
                        <FormControl>
                          <FormLabel id="pallet">Pallet?</FormLabel>
                          <RadioGroup
                            aria-labelledby="pallet"
                            name="pallet"
                            row
                            value={item.pallet}
                            onChange={(e) => {
                              let newp = values.package;
                              newp[index].pallet = e.target.value;
                              setFieldValue("package", newp);
                            }}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {item.pallet == "1" && (
                        <Grid
                          item
                          xs={12}
                          md={4}
                          xl={4}
                          order={{ xl: 8, md: 8, xs: 8 }}
                        >
                          <FormControl>
                            <TextField
                              name="pallet_id"
                              label="Pallet ID"
                              InputLabelProps={{ shrink: true }}
                              value={item.pallet_id}
                              onChange={(e) => {
                                let newp = values.package;
                                newp[index].pallet_id = e.target.value;
                                setFieldValue("package", newp);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.package &&
                                  touched.package[index]?.pallet_id &&
                                  errors?.package &&
                                  errors?.package[index]?.pallet_id
                              )}
                              helperText={
                                touched.package &&
                                touched.package[index]?.pallet_id &&
                                errors?.package &&
                                errors?.package[index]?.pallet_id
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                      {index === 0 ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          xl={1}
                          textAlign="right"
                          order={{ xl: 8, md: 1, xs: 1 }}
                        >
                          <IconButton
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              if (values.package.length < 30) {
                                let copy = {
                                  tracking_number: "",
                                  sender_name: "",
                                  recipient_first_name: "",
                                  recipient_last_name: "",
                                  current_storage_location: "",
                                  receive_package_image: null,
                                  pallet: "0",
                                  pallet_id: "",
                                };
                                setFieldValue("package", [
                                  ...values.package,
                                  copy,
                                ]);
                              } else {
                                dispatch(
                                  getMsg("A max of 30 packages are allowed")
                                );
                                dispatch(getSnackAlert("error"));
                                dispatch(getSnackOpen());
                                setTimeout(() => {
                                  dispatch(getSnackClose());
                                }, 3000);
                              }
                            }}
                          >
                            <AddCircleOutlineIcon fontSize="large" />
                          </IconButton>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          xl={1}
                          textAlign="right"
                          order={{ xl: 8, md: 1, xs: 1 }}
                        >
                          <IconButton
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              // removePassengers(index);
                              let newArr = [];
                              for (let i = 0; i < values.package.length; i++) {
                                if (i !== index) {
                                  newArr.push(values.package[i]);
                                }
                              }
                              setFieldValue("package", newArr);
                            }}
                          >
                            <DeleteIcon color="error" fontSize="large" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ margin: "15px 0" }}
                  type="submit"
                >
                  Save
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function BulkReceiving() {
  return (
    <React.Fragment>
      <Helmet title="Bulk Receiving" />
      <Typography variant="h3" gutterBottom display="inline">
        Bulk Receiving
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/packages/list-packages">
          List Packages
        </Link>
        <Typography>Bulk Receiving</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <BulkReceivingForm />
    </React.Fragment>
  );
}
export default BulkReceiving;

export default [
  {
    id: "company_name",
    label: "Company Name",
  },
  {
    id: "admin_name",
    label: "Admin Name",
  },
  {
    id: "admin_email",
    label: "Admin Email",
  },
  {
    id: "tenant_phone",
    label: "Admin Phone",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { SendTestComm } from "../../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  FormControl as MuiFormControl,
  IconButton,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const TestCommunicationDialog = ({ testCommDialog, testCommDialogClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    commValue: Yup.string()
      .required("This field is required")
      .when("commType", (commType, schema) =>
        commType === "email"
          ? schema.email("Invalid email format")
          : schema
              .matches(/^\+?\d+$/, "Only numbers and '+' are allowed for phone")
              .min(10, "Phone number is too short")
              .max(15, "Phone number is too long")
      ),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    let payload =
      values.commType === "email"
        ? { email: values.commValue }
        : { phone: values.commValue };
    SendTestComm(payload)
      .then((res) => {
        if (res.StatusCode === 200) {
          testCommDialogClose();
          dispatch(
            getMsg(
              `${
                values.commType.charAt(0).toUpperCase() +
                values.commType.slice(1)
              } sent successfully`
            )
          );
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
          },
        },
      }}
      aria-labelledby="comment-title"
      aria-describedby="comment-description"
      open={testCommDialog}
      onClose={testCommDialogClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        id="comment-title"
      >
        <Typography variant="h4">Test Communication</Typography>
        <DialogActions>
          <IconButton
            onClick={testCommDialogClose}
            sx={{ marginRight: "-10px" }}
          >
            <CancelRoundedIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ commValue: "", commType: "email" }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <FormControl>
                <FormLabel component="legend">Send by</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="send-by"
                  value={values.commType}
                  onChange={(event) => {
                    const newCommType = event.target.value;
                    setFieldValue("commType", newCommType);
                    setFieldValue("commValue", ""); // Reset commValue when commType changes
                  }}
                  name="commType"
                >
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                  <FormControlLabel
                    value="phone"
                    control={<Radio />}
                    label="Phone"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl mt={3}>
                <Field
                  as={TextField}
                  name="commValue"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={values.commType === "email" ? "Email" : "Phone"}
                  autoComplete="off"
                  placeholder={
                    values.commType === "email"
                      ? "Enter a valid email"
                      : "Enter a valid phone number"
                  }
                />
                <ErrorMessage
                  name="commValue"
                  component="div"
                  style={{ color: "red", fontSize: "0.875em" }}
                />
              </FormControl>
              <DialogActions sx={{ padding: "20px 0" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={testCommDialogClose}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? "Sending..." : "Send"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default TestCommunicationDialog;

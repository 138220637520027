import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  DialogActions,
  Avatar as MuiAvatar,
  TableHead,
  Divider,
  ListItemIcon,
  MenuItem,
  ListItemText,
  Menu,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { PoiEncounterList } from "../../api";
import { NavLink } from "react-router-dom";
const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const BigAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
`;

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const PoiViewEncounter = ({
  poiViewEncounter,
  poiViewEncounterClose,
  singleRecord,
}) => {
  const [encounterList, setEncounterList] = useState([]);
  const [encounter, setEncounter] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleActionClick = (event, encounter) => {
    setAnchorEl(event.currentTarget);
    setEncounter(encounter);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const headCells = [
    {
      id: "encounter_date",
      label: "Date Time",
    },
    {
      id: "location",
      label: "Reporting Unit",
    },
    {
      id: "police_contacted",
      label: "Police Contacted",
    },
    {
      id: "trespass_issued",
      label: "Trespass Issued",
    },
    {
      id: "resolution",
      label: "Resolution",
    },
    {
      id: "image",
      label: "Encounter Image",
    },
    {
      id: "action",
      label: "Action",
    },
  ];

  const getPoiEncounterList = () => {
    setLoading(true);
    PoiEncounterList(singleRecord.id)
      .then((res) => {
        if (res.statusCode === 200) {
          setEncounterList(res?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getPoiEncounterList();
  }, []);

  return (
    <>
      <Dialog
        open={poiViewEncounter}
        onClose={poiViewEncounterClose}
        aria-labelledby="view-encounter"
        aria-describedby="view-encounter"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1200px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">POI Encounters</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={poiViewEncounterClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <TableContainer
            className="tableContainer"
            component={Paper}
            sx={{
              margin: "15px auto auto",
              "&::-webkit-scrollbar": {
                width: 7,
                height: 7,
              },
              "&::-webkit-scrollbar-track": {
                background: "#bed2f5",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#92a6c9",
              },
            }}
          >
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => {
                    return (
                      <TableCell key={headCell.id} align="left">
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {encounterList.map((encounter) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {encounter.encounter_date}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {encounter.Location}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {encounter.police_contacted}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {encounter.trespass_issued}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {encounter.resolution}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            <BigAvatar alt="Image" src={encounter.image} />
                          </TableCell>

                          <TableCell sx={{ borderBottom: "none" }}>
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={anchorEl ? "icon-menu" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleActionClick(e, encounter);
                              }}
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="icon-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                component={NavLink}
                                to={`/poi/view-poi-encounter/${encounter.id}`}
                              >
                                <ListItemIcon>
                                  <VisibilityIcon />
                                </ListItemIcon>
                                <ListItemText primary="View" pl={1} />
                              </MenuItem>
                              <MenuItem
                                component={NavLink}
                                to={`/poi/edit-poi-encounter/${encounter.id}`}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                              <MenuItem
                                component={NavLink}
                                to={`/poi/edit-poi-encounter/${encounter.id}`}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={7} sx={{ fontWeight: "bold" }}>
                            Narrative:
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PoiViewEncounter;

import React, { useState, useEffect } from "react";
import { Button, Tooltip } from "@mui/material";
import ImageListingModal from "../../components/ImageGallery/ImageListingModal";
import ClassificationDoc from "../inner-components/ClassificationDoc";
import { GetIncidentFiles } from "../../../api";

import {
  PictureAsPdf as PictureAsPdfIcon,
  CameraAlt as CameraAltIcon,
} from "@mui/icons-material";

const IncidentMediaStatus = ({ data }) => {
  // Image box
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [fileData, setFileData] = useState([]);
  // Doc modal
  const [docModal, setDocModal] = useState(false);
  const [docFileData, setDocFileData] = useState([]);

  //Handler for image box right roatation
  const handelRotationRight = () => {
    const totalLength = fileData.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = fileData[0].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = fileData.filter((item) => {
      return fileData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  //Handler for image box left roatation
  const handelRotationLeft = () => {
    const totalLength = fileData.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = fileData[totalLength - 1].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = fileData.filter((item) => {
      return fileData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handleClickImage = () => {
    let firstLink = fileData[0].link;
    setCurrentIndex(0);
    setClickedImg(firstLink);
  };

  const handleGetFile = (media_type) => {
    let payload = {
      MediaTypeID: media_type,
      IncidentID: data.IncidentID,
    };
    GetIncidentFiles(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          switch (media_type) {
            case 1:
              setFileData(res.data);
              break;
            case 2:
              // setFileData(res.data);
              break;
            case 3:
              // setFileData(res.data);
              break;
            case 4:
              setDocFileData(res.data);
              break;
            case 5:
              setDocFileData(res.data);
              break;
            default:
              break;
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (fileData.length > 0) {
      handleClickImage();
    }
  }, [fileData]);

  useEffect(() => {
    if (docFileData.length > 0) {
      setDocModal(true);
    }
  }, [docFileData]);

  return (
    <>
      {data.media_image_count > 0 ? (
        <Tooltip title="Show image" followCursor>
          <Button
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              minWidth: "auto",
            }}
            onClick={(e) => {
              handleGetFile(1);
            }}
          >
            <CameraAltIcon /> {data.media_image_count}
          </Button>
        </Tooltip>
      ) : null}
      {data.media_pdf_count > 0 ? (
        <Tooltip title="Download PDF" followCursor>
          <Button
            sx={{
              marginLeft: "5px",
              paddingLeft: 0,
              paddingRight: 0,
              minWidth: "auto",
            }}
            onClick={(e) => {
              handleGetFile(4);
            }}
          >
            <PictureAsPdfIcon /> {data.media_pdf_count}
          </Button>
        </Tooltip>
      ) : null}

      {clickedImg && (
        <ImageListingModal
          clickedImg={clickedImg}
          handelRotationRight={handelRotationRight}
          setClickedImg={setClickedImg}
          handelRotationLeft={handelRotationLeft}
        />
      )}
      {docModal && (
        <ClassificationDoc
          show={docModal}
          handleModal={(status) => setDocModal(status)}
          files={docFileData}
          data={data}
        />
      )}
    </>
  );
};

export default IncidentMediaStatus;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  Chip,
  Box,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { AddElevator, GetElevator } from "../../../api";
import WithPermissionHide from "../../../utils/withPermissionHide";
const LiftOutServies = ({
  liftOutServicesDialog,
  liftOutServicesDialogClose,
  elevatorList,
  getElevator,
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const [fields, setFields] = useState(elevatorList.map((tool) => tool));

  const handleBlur = () => {
    if (fieldValue.trim() !== "") {
      setFields([...fields, fieldValue]);
      addElevator([...fields, fieldValue]);
    }
    setFieldValue("");
  };

  const handleDelete = (index) => {
    setFields(fields.filter((item, itemIndex) => itemIndex !== index));
    addElevator(fields.filter((item, itemIndex) => itemIndex !== index));
  };

  const addElevator = (data) => {
    const payload = {
      description: data.join(","),
    };
    AddElevator(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res);
          getElevator();
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="lift-out-title-modal"
        aria-describedby="lift-out-modal"
        open={liftOutServicesDialog}
        onClose={liftOutServicesDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="lift-out-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Elevator/Lift Out of Service or Failure
          </Typography>
          <DialogActions>
            <IconButton
              onClick={liftOutServicesDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {fields.map((field, index) => {
              return (
                <Chip
                  key={index}
                  label={field}
                  onDelete={() => handleDelete(index)}
                  color="primary"
                />
              );
            })}
          </Box>
          <WithPermissionHide controller="Logs" action="addElevator">
            <TextField
              sx={{ marginTop: "15px" }}
              fullWidth
              placeholder="Type to add, then press enter key. Click close button when done "
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              onBlur={(e) => handleBlur()}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  handleBlur();
                }
              }}
            />
          </WithPermissionHide>
        </DialogContent>
        <DialogActions sx={{ pr: "28px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={liftOutServicesDialogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LiftOutServies;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  Box,
  Grid,
  Divider as MuiDivider,
} from "@mui/material";
import { TrackingSearch as Search } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
`;

const Divider = styled(MuiDivider)(spacing);
const TrackingSearch = ({ trackingSearch, trackingSearchClose }) => {
  const [trackNumber, setTrackNumber] = useState("");
  const [searchData, setSearchData] = useState({});
  const [isFind, setIsFind] = useState(false);
  const dispatch = useDispatch();
  const handleSearch = (value) => {
    Search(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setSearchData(res.data);
          setIsFind(true);
        } else {
          setIsFind(false);
          setSearchData({});
          dispatch(
            getMsg("Nothing found with that tracking number. Try again")
          );
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Dialog
        open={trackingSearch}
        onClose={trackingSearchClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px",
            },
          },
        }}
        aria-labelledby="tracking-search-title"
        aria-describedby="tracking-search-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="tracking-search-title"
        >
          <Typography variant="h4" component="div">
            Find Package Record
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={trackingSearchClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <TextField
              placeholder="Scan barcode or enter tracking number"
              sx={{ marginTop: "10px" }}
              name="tracking_number"
              label="Tracking number"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={trackNumber}
              onChange={(e) => setTrackNumber(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e.target.value);
                }
              }}
            />
            <Button
              color="primary"
              variant="contained"
              sx={{ marginLeft: "10px", height: "52px" }}
              onClick={() => handleSearch(trackNumber)}
            >
              Search
            </Button>
          </Box>
          {isFind && (
            <Grid container spacing={6}>
              <Grid item xs={12} md={12}>
                <Typography sx={{ marginTop: "20px" }}>Match Found!</Typography>
                <Divider my={3} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Image
                  sx={{
                    width: "100%",
                  }}
                  src={
                    searchData.receive_package_image
                      ? searchData.receive_package_image
                      : "/static/img/lost-found/lost_and_found_pic.jpg"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  <strong>Date received:</strong>{" "}
                  {searchData.package_arrival_date}
                </Typography>
                <Typography>
                  <strong>Carrier:</strong> {searchData.carrier_dropping}
                </Typography>
                <Typography>
                  <strong>Sender:</strong> {searchData.sender}
                </Typography>
                <Typography>
                  <strong>Recipient:</strong> {searchData.recipient}
                </Typography>
                <Typography>
                  <strong>Storage Location:</strong>{" "}
                  {searchData.current_storage_location}
                </Typography>
                <Typography>
                  <strong>Disposition:</strong> {searchData.package_status}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider my={3} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={trackingSearchClose}
          >
            Cancel
          </Button>
          {isFind && (
            <Button
              color="primary"
              variant="outlined"
              component={NavLink}
              to={`/packages/edit-package/${searchData.id}`}
              sx={{ marginLeft: "10px" }}
            >
              Edit Package
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TrackingSearch;

import React, { useState, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Link, NavLink } from "react-router-dom";
import {
  Modal,
  Typography,
  MenuList,
  MenuItem,
  Button,
  Grid,
  IconButton,
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  TableContainer,
  Paper,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  BorderColor as BorderColorIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
} from "@mui/icons-material";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";

import { ViewNotification, DeleteNotification } from "../../../api";

const PastNotify = ({ onDelete, itemName }) => {
  const [openModel, setOpenModel] = useState(false);
  const dispatch = useDispatch();

  //Delete Notification
  const handleDelete = (notifyId) => {
    // onDelete();
    DeleteNotification({ notification_id: notifyId })
      .then((res) => {
        pastNotifyList();
        if (res.statusCode == 200) {
          dispatch(getMsg("Notification successfully deleted"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getMsg(err));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
    setOpenModel(false);
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  //notify div list
  const [notifyList, setNotifyList] = useState([]);

  const pastNotifyList = () => {
    ViewNotification()
      .then((res) => {
        if (res.statusCode == 200) {
          setNotifyList(res.data);
        } else {
          setNotifyList(res.data);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  useEffect(() => {
    pastNotifyList();
  }, []);
  return (
    <>
      <Grid container spacing={6}>
        <Grid item lg={6} md={6}>
          <Typography variant="h3" gutterBottom>
            Notification
          </Typography>
        </Grid>
        <Grid item lg={6} md={6}>
          <Paper></Paper>
          <div>
            <IconButton
              sx={{ float: "right", marginBottom: "10px" }}
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <FilterListIcon sx={{ color: "#376fd0" }} />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                        // sx={{ color: "black", textDecoration: "none" }}
                        >
                          <NavLink to="/settings/corporate-user/notify-div">
                            New Notify
                          </NavLink>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Grid>
      </Grid>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Description (Row with light green color is currently active)
                </TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">End Date</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifyList.map((item) => (
                <TableRow
                  key={item.id}
                  style={{ backgroundColor: item.hexCode }}
                >
                  <TableCell
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <TableCell
                    align="center"
                    sx={{ fontSize: "12px", whiteSpace: "pre" }}
                  >
                    {item.startDate}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "12px", whiteSpace: "pre" }}
                  >
                    {item.endDate}
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Link
                        to={`/settings/corporate-user/edit-notify/${item.id}`}
                      >
                        <IconButton>
                          <BorderColorIcon fontSize="small" />
                        </IconButton>
                      </Link>
                      <IconButton onClick={() => setOpenModel(true)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                      {/* Modal for delete confirmation */}
                      <Modal
                        open={openModel}
                        onClose={() => setOpenModel(false)}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "white",
                            padding: "20px",
                            minWidth: "300px",
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            Confirm Delete
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            Are you sure you want to delete?
                          </Typography>
                          <Box sx={{ float: "right", marginTop: "10px" }}>
                            <Button
                              sx={{ marginRight: "10px" }}
                              variant="contained"
                              color="secondary"
                              onClick={() => handleDelete(item.id)}
                            >
                              Delete
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={() => setOpenModel(false)}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </div>
                      </Modal>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default PastNotify;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import PoiPeek from "./inner-component/PoiPeek";
import CommunicationLogModal from "../../components/modals/lostandfound/CommunicationLogModal";
import PoiRequireReview from "./inner-component/PoiRequireReview";
import AddComLogModal from "../../components/modals/lostandfound/AddComLogModal";
import { useDispatch, useSelector } from "react-redux";
import { updateShowAlert } from "../../redux/slices/mainSlice";
import PoiListCol from "../table-columns-json/poi/PoiList";
import { setPoiList } from "../../redux/slices/poi/poiTableColSlice";
import PoiMergeModal from "./PoiMergeModal";
import moment from "moment";
import PoiViewEncounter from "./PoiViewEncounter";
import SimpleImageSlider from "react-simple-image-slider";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Pagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  TrendingDown as TrendingDownIcon,
  PictureAsPdf as PictureAsPdfIcon,
  RateReview as RateReviewIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  PersonOfIntrestList,
  DeletePoi,
  PoiPrintReport,
  UpdateInlinePoiData,
  PoiWatchlist,
} from "../../api";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import { Cancel as CancelIcon, Add as AddIcon } from "@mui/icons-material";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import ImageDialog from "./inner-component/ImageDialog";
import PoiWatch from "./inner-component/PoiWatch";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
// const useStyles = makeStyles((theme) => ({
//   alphabets: {
//     marginTop: "15px",
//     "& .MuiList-root": {
//       display: "flex",
//       overflowX: "auto",
//       background: "#7aa8ff0d",
//       padding: "0px",
//       "& .MuiListItemButton-root": {
//         paddingLeft: "13px",
//         paddingRight: "13px",
//         textAlign: "center",
//       },
//     },
//     "& .MuiList-root::-webkit-scrollbar": {
//       width: "5px",
//       height: "5px",
//     },
//     "& .MuiList-root::-webkit-scrollbar-track": {
//       backgroundColor: "#bed2f561",
//     },
//     "& .MuiList-root::-webkit-scrollbar-thumb": {
//       backgroundColor: "#92a6c96e",
//     },
//   },
// }));

// const TableWrapper = styled.div`
//   overflow-y: auto;
//   max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
// `;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  height: 100px;
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  tl,
  id,
  gender,
  fname,
  lname,
  alias,
  race,
  age,
  height,
  weight,
  image,
  watch,
  enc,
  lastupdated,
  action
) {
  return {
    tl,
    id,
    gender,
    fname,
    lname,
    alias,
    race,
    age,
    height,
    weight,
    image,
    watch,
    enc,
    lastupdated,
    action,
  };
}
const rows = [
  createData(
    "3",
    "141234",
    "Male",
    "John",
    "Smith",
    "John Smith",
    "White",
    "26-30",
    `5'-7" to 6'-0"`,
    "171 - 185 lbs",
    0,
    0,
    "01/21/2022 00:09:08",
    0
  ),
  createData(
    "3",
    "141224",
    "Male",
    "John",
    "Smith",
    "John Smith",
    "White",
    "26-30",
    `5'-7" to 6'-0"`,
    "171 - 185 lbs",
    0,
    0,
    "01/21/2022 00:09:08",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "tl",
    numeric: true,
    disablePadding: true,
    label: "TL",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "gender",
    numeric: true,
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "fname",
    numeric: true,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lname",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "alias",
    numeric: true,
    disablePadding: false,
    label: "Alias",
  },
  {
    id: "race",
    numeric: true,
    disablePadding: false,
    label: "Race",
  },
  {
    id: "age",
    numeric: true,
    disablePadding: false,
    label: "Age",
  },
  {
    id: "height",
    numeric: true,
    disablePadding: false,
    label: "Ht.",
  },
  {
    id: "weight",
    numeric: true,
    disablePadding: false,
    label: "Wt.",
  },
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Image",
  },
  {
    id: "watch",
    numeric: true,
    disablePadding: false,
    label: "Watch",
  },
  {
    id: "end",
    numeric: true,
    disablePadding: false,
    label: "Enc",
  },
  {
    id: "lastUpdated",
    numeric: true,
    disablePadding: false,
    label: "Last Updated",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {PoiListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "image" ||
                headCell.id == "watch" ||
                headCell.id == "enc" ||
                headCell.id == "action"
                  ? null
                  : createSortHandler(headCell.id)
              }
              hideSortIcon={
                headCell.id == "image" ||
                headCell.id == "watch" ||
                headCell.id == "enc" ||
                headCell.id == "action"
                  ? true
                  : false
              }
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    filterRecord,
    dataToFilter,
    setDataToFilter,
    setCurrentPage,
    tableCalRedux,
    selected,
    showAlertTost,
    getPoiList,
    resetSelectedId,
    handleDeleteOpen,
  } = props;
  const [actions, setActions] = React.useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fadebox, setFadedBox] = useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");

  const [isFilter, setIsFilter] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchId, setSearchId] = useState("");
  const [searchThreatLevel, setSearchThreatLevel] = useState("");
  const selectThreatLevel = (event) => {
    setSearchThreatLevel(event.target.value);
  };
  const [searchGender, setSearchGender] = useState("");
  const selectGender = (event) => {
    setSearchGender(event.target.value);
  };
  const [searchRace, setSearchRace] = useState("");
  const selectRace = (event) => {
    setSearchRace(event.target.value);
  };
  const [searchAge, setSearchAge] = useState("");
  const selectAge = (event) => {
    setSearchAge(event.target.value);
  };
  const [searchHairColor, setSearchHairColor] = useState("");
  const selectHairColor = (event) => {
    setSearchHairColor(event.target.value);
  };

  const [searchTatoo, setSearchTatoo] = useState("");
  const selectTatoos = (event) => {
    setSearchTatoo(event.target.value);
  };

  const [searchGlasses, setSearchGlasses] = useState("");
  const selectGlasses = (event) => {
    setSearchGlasses(event.target.value);
  };

  const [searchEncounter, setSearchEncounter] = useState("");
  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultPoiTableCol.poiList
  );
  const threatType = [
    { label: "< 4", value: "1,2,3,4" },
    { label: "5-6", value: "5,6" },
    { label: "7-8", value: "7,8" },
    { label: "> 8", value: "9,10" },
  ];

  const genderType = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
    { label: "Undetermined", value: "U" },
  ];

  const raceType = [
    { label: "White", value: "white" },
    { label: "Black", value: "black" },
    { label: "Asian", value: "asian" },
    { label: "Hispanic", value: "hispanic" },
    { label: "Native American", value: "native_american" },
    { label: "Pacific Islander", value: "pacific_islander" },
    { label: "Unknown", value: "unknown" },
  ];

  const ageType = [
    { label: "18-25", value: "18-25" },
    { label: "26-30", value: "26-30" },
    { label: "31-35", value: "31-35" },
    { label: "36-40", value: "36-40" },
    { label: "41-45", value: "41-45" },
    { label: "46-50", value: "46-50" },
    { label: "51-60", value: "51-60" },
    { label: "Over 60", value: "over 60" },
    { label: "Unknown", value: "Unknown" },
  ];

  const hairType = [
    { label: "Blonde", value: "Blonde" },
    { label: "Brown", value: "Brown" },
    { label: "Red", value: "Red" },
    { label: "Grey", value: "Grey" },
    { label: "Black", value: "Black" },
    { label: "Other", value: "Other" },
  ];

  const tatooType = [
    { label: "None", value: "none" },
    { label: "Any", value: "any" },
  ];

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);

  const [race, setRace] = React.useState("");

  const [age, setAge] = React.useState("");

  const [hairColor, setHairColor] = React.useState("");
  //Poi Merge Modal
  const [poiModal, setPoiModal] = React.useState(false);
  const poiModalOpen = () => {
    setPoiModal(true);
  };
  const poiModalClose = () => {
    setPoiModal(false);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setSearchId("");
    setSearchGender("");
    setSearchRace("");
    setSearchAge("");
    setSearchHairColor("");
    setSearchTatoo("");
    setSearchGlasses("");
    setSearchValue("");
    setSearchEncounter("");
  };

  //Method for search filter
  const applySearch = () => {
    const payload = {
      search_value: searchValue,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      id: searchId,
      threat_level: searchThreatLevel,
      gender: searchGender,
      race: searchRace,
      age: searchAge,
      hair_color: searchHairColor,
      encounters: searchEncounter,
      // alias
      // first_name
      // last_name
      tattoos: searchTatoo,
      glasses: searchGlasses,
    };
    // console.log("Payload console+++++++++++++++", payload);
    setDataToFilter(payload);
  };
  return (
    <Toolbar>
      <>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ whiteSpace: "nowrap" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            mt={3}
            id="tableTitle"
            style={{ whiteSpace: "nowrap" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </>
      <Spacer />
      {numSelected == 2 && (
        <div>
          <Button
            variant="outlined"
            onClick={poiModalOpen}
            sx={{
              marginRight: "50px",
              marginTop: "15px",
            }}
          >
            Merge
          </Button>
        </div>
      )}
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              type="text"
              autoComplete="off"
              placeholder="Search by ID, name or alias or click down arrow"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                  setCurrentPage(1);
                }
              }}
              inputProps={{}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="id"
                              label="ID"
                              autoComplete="off"
                              value={searchId}
                              onChange={(e) => {
                                setSearchId(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Comma separate multiple ID's like 12345, 67894"
                            />
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="threat-level"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Threat level
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="threat-level"
                              value={searchThreatLevel}
                              onChange={selectThreatLevel}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">
                                --Select threat level--
                              </MenuItem>
                              {threatType.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="gender"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Gender
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="gender"
                              value={searchGender}
                              onChange={selectGender}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Select</MenuItem>
                              {genderType.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="race"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Race
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="race"
                              value={searchRace}
                              onChange={selectRace}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Select</MenuItem>
                              {raceType.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="age"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Age
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="age"
                              value={searchAge}
                              onChange={selectAge}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Select</MenuItem>
                              {ageType.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="hair-color"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Hair color
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="hair-color"
                              value={searchHairColor}
                              onChange={selectHairColor}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Select</MenuItem>
                              {hairType.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="tatoos"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Tattoos
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="tatoos"
                              value={searchTatoo}
                              onChange={selectTatoos}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Select</MenuItem>
                              {tatooType.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="glasses"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Glasses
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="glasses"
                              value={searchGlasses}
                              onChange={selectGlasses}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Select</MenuItem>
                              {tatooType.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <FormLabel component="legend">Encounters</FormLabel>
                            <RadioGroup
                              aria-labelledby="encounters"
                              name="encounters"
                              value={searchEncounter}
                              onChange={(e) => {
                                setSearchEncounter(e.target.value);
                              }}
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                value="Yes"
                                label="Yes"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                value="No"
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Encounter Dates"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM-DD-YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM-DD-YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Click to select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>

                          <FormControl>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              sx={{ width: "175px", marginRight: "5px" }}
                              onClick={(e) => {
                                handleCloseForm();
                                applySearch();
                                setIsFilter(true);
                              }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  setIsFilter(false);
                  resetFilter();
                  setDataToFilter(null);
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                  width: "100%",
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchValue !== "") {
                    applySearch();
                    setIsFilter(true);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>

      {poiModal && (
        <PoiMergeModal
          poiModal={poiModal}
          setPoiModal={setPoiModal}
          poiModalOpen={poiModalOpen}
          poiModalClose={poiModalClose}
          selected={selected}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          getPoiList={() => getPoiList()}
          resetSelectedId={() => resetSelectedId()}
        />
      )}
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem component={NavLink} to="/poi/add-poi">
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add POI" pl={0} />
            </MenuItem>
            <MenuItem component={NavLink} to="/poi/poi-encounters">
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="List POI Encounters" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setPoiList(data));
                    }}
                    listing={PoiListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setPoiList(defaultTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  //states realted to listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [poiList, setPoiList] = useState([]);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [preLoading, setPreLoading] = useState(false);
  const [singleRecord, setSingleRecord] = useState({});
  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  // State for editable fields in table
  const [editableCell, setEditableCell] = useState(null);
  const [editableValue, setEditableValue] = useState("");

  const dispatch = useDispatch();
  const classes = useStyles();
  const tableCalRedux = useSelector((state) => state.poiTableCol.poiList);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [imageDialog, setImageDialog] = useState(false);
  const [poiImages, setPoiImages] = useState("");
  const imageDialogOpen = () => {
    setImageDialog(true);
  };
  const imageDialogClose = () => {
    setImageDialog(false);
  };

  const [poiWatch, setPoiWatch] = useState(false);

  const poiWatchOpen = () => {
    setPoiWatch(true);
  };

  const poiWatchClose = () => {
    setPoiWatch(false);
  };

  const handleWatchToggle = (id, prevStatus) => {
    poiWatchOpen();
    // const payload = {
    //   status: !prevStatus ? 1 : 0,
    // };
    // PoiWatchlist(id, payload)
    //   .then((res) => {
    //     if (res.statusCode === 200) {
    //       console.log("Saved Successfully");
    //     }
    //   })
    //   .catch((err) => {
    //     console.err(err);
    //   });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = poiList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // methods for handling editable fields in table
  const handleCellClick = (cellInfo) => {
    setEditableCell(cellInfo);
    setEditableValue(cellInfo.value);
  };

  const handleBlur = async () => {
    const payload = {
      id: editableCell.rowId,
      index: editableCell.field,
      val: editableValue,
    };
    UpdateInlinePoiData(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          getPoiList();
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
        } else {
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setEditableCell(null);
  };

  const handleChange = (event) => {
    setEditableValue(event.target.value);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id, review, data) => {
    setAnchorEl(event.currentTarget);
    setRecordId(id);
    setReviewReq(review);
    setSingleRecord(data);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setRecordId(null);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setRecordId(null);
  };
  //Date Time Picker
  // const [value, setValue] = React.useState(null);
  // const [startdate, setStartDate] = React.useState(null);
  // const [enddate, setEndDate] = React.useState(null);

  // const classes = useStyles();

  // View Communication Log
  const [viewCommunicationLog, setViewCommunicationLog] = React.useState(false);
  const viewCommunicationLogOpen = () => {
    setViewCommunicationLog(true);
  };
  const viewCommunicationLogClose = () => {
    setViewCommunicationLog(false);
  };

  const [showAddComLog, setShowAddComLog] = useState(false);
  const [reviewReq, setReviewReq] = useState("");
  const [reviewDialog, setReviewDialog] = useState(false);
  const reviewDialogOpen = () => {
    setReviewDialog(true);
  };
  const reviewDialogClose = () => {
    setReviewDialog(false);
  };

  // Peek Modal
  const [peek, setPeek] = React.useState(false);
  const peekOpen = () => {
    setPeek(true);
  };
  const peekClose = () => {
    setPeek(false);
  };

  // const filterRecord = (data) => {
  //   setDataToFilter(data);
  // };

  //Method for calling the listing API
  const getPoiList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    }; //this will be used for sending filtered data
    PersonOfIntrestList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setPoiList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setPoiList(res.data);
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setPoiList([]);
        console.log(err);
        setTotalRecord(0);
      });
  };

  const deletePoi = () => {
    let url = selected.length > 0 ? selected : recordId;
    DeletePoi(url)
      .then((res) => {
        if (res.statusCode === 200) {
          setSelected([]);
          getPoiList();
          handleDeleteClose();
          dispatch(getMsg(`Record(s) has been deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again later"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const onButtonClick = () => {
    setPreLoading(true);
    PoiPrintReport(recordId)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-POI.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          handleActionClose();
          setPreLoading(false);
        } else {
          console.log("something went wrong");
          setPreLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPreLoading(false);
      });
  };

  useEffect(() => {
    getPoiList();
  }, [rowsPerPage, currentPage, dataToFilter, order, orderBy]);

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const showAlertTost = (msg, type) => {
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  // View Encounter
  const [poiViewEncounter, setPoiViewEncounter] = useState(false);
  const poiViewEncounterOpen = () => {
    setPoiViewEncounter(true);
  };
  const poiViewEncounterClose = () => {
    setPoiViewEncounter(false);
  };
  return (
    <div>
      {preLoading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {selected.length || recordId > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete POI ?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={deletePoi} autoFocus variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          // filterRecord={(data) => filterRecord(data)}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          setCurrentPage={setCurrentPage}
          tableCalRedux={tableCalRedux}
          selected={selected}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          getPoiList={() => getPoiList()}
          resetSelectedId={() => setSelected([])}
          handleDeleteOpen={handleDeleteOpen}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={poiList.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(poiList, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                          sx={{
                            lg: 'whiteSpace: "nowrap"',
                            fontSize: "34px",
                            fontWeight: "bolder",
                            WebkitTextStrokeWidth: "0.4px",
                            WebkitTextStrokeColor: "#888",
                            color:
                              row.threat_level <= 3
                                ? "#3f9c24"
                                : row.threat_level == 4 || row.threat_level == 5
                                ? "#aed319"
                                : row.threat_level == 6 || row.threat_level == 7
                                ? "#d37f19"
                                : "#d31e19",
                          }}
                          className={
                            tableCalRedux.threat_level === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.threat_level}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.id === true ? "" : "hide-cell"
                          }
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.gender === true ? "" : "hide-cell"
                          }
                        >
                          {row.gender}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.first_name === true ? "" : "hide-cell"
                          }
                          onClick={() =>
                            handleCellClick({
                              rowId: row.id,
                              field: "first_name",
                              value: row.first,
                            })
                          }
                        >
                          {editableCell &&
                          editableCell.rowId === row.id &&
                          editableCell.field === "first_name" ? (
                            <TextField
                              autoFocus
                              value={editableValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          ) : (
                            row.first
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.last_name === true ? "" : "hide-cell"
                          }
                          onClick={() =>
                            handleCellClick({
                              rowId: row.id,
                              field: "last_name",
                              value: row.last,
                            })
                          }
                        >
                          {editableCell &&
                          editableCell.rowId === row.id &&
                          editableCell.field === "last_name" ? (
                            <TextField
                              autoFocus
                              value={editableValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          ) : (
                            row.last
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.alias === true ? "" : "hide-cell"
                          }
                          onClick={() =>
                            handleCellClick({
                              rowId: row.id,
                              field: "alias",
                              value: row.alias,
                            })
                          }
                        >
                          {editableCell &&
                          editableCell.rowId === row.id &&
                          editableCell.field === "alias" ? (
                            <TextField
                              autoFocus
                              value={editableValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              width="full"
                            />
                          ) : (
                            row.alias
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.race === true ? "" : "hide-cell"
                          }
                        >
                          {row.race}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.age === true ? "" : "hide-cell"
                          }
                        >
                          {row.age}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.height === true ? "" : "hide-cell"
                          }
                        >
                          {row.height}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.weight === true ? "" : "hide-cell"
                          }
                        >
                          {row.weight}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.image === true ? "" : "hide-cell"
                          }
                        >
                          <ThumbnailTableWrapper>
                            {row.image != null ? (
                              <Image
                                sx={{ width: "100%" }}
                                component="img"
                                alt="Guage Chart"
                                src={row.image}
                                onClick={() => {
                                  setPoiImages(row.image);
                                  imageDialogOpen();
                                }}
                              />
                            ) : null}
                          </ThumbnailTableWrapper>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.watch === true ? "" : "hide-cell"
                          }
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={row.watchlist_status}
                                onChange={() =>
                                  handleWatchToggle(
                                    row.id,
                                    row.watchlist_status
                                  )
                                }
                              />
                            }
                            label=""
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.enc === true ? "" : "hide-cell"
                          }
                        >
                          {row.enc}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.last_updated === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.modified}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) =>
                              handleActionClick(
                                e,
                                row.id,
                                row.review_request,
                                row
                              )
                            }
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              component={NavLink}
                              to={`/poi/view-poi/${recordId}`}
                            >
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText primary="View" pl={1} />
                            </MenuItem>
                            <MenuItem
                              component={NavLink}
                              to={`/poi/edit-poi/${recordId}`}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={peekOpen}>
                              <ListItemIcon>
                                <TrendingDownIcon />
                              </ListItemIcon>
                              <ListItemText primary="Peek" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={handleDeleteOpen}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                            <MenuItem
                              component={NavLink}
                              to={`/poi/add-poi-encounter/${recordId}`}
                            >
                              <ListItemIcon>
                                <AddBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Add Encounter" pl={1} />
                            </MenuItem>

                            {singleRecord.enc > 0 && (
                              <MenuItem onClick={poiViewEncounterOpen}>
                                <ListItemIcon>
                                  <VisibilityIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="View Encounter(s)"
                                  pl={1}
                                />
                              </MenuItem>
                            )}
                            <MenuItem onClick={onButtonClick}>
                              <ListItemIcon>
                                <PictureAsPdfIcon />
                              </ListItemIcon>
                              <ListItemText primary="Download PDF" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={viewCommunicationLogOpen}>
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText primary="View Comm Log" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                reviewDialogOpen();
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                <RateReviewIcon />
                              </ListItemIcon>
                              <ListItemText primary="Require Review" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {totalRecord === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                    <TableCell colSpan={10} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* {viewCommunicationLog && (
        <ViewCommunicationLog
          viewCommunicationLog={viewCommunicationLog}
          setViewCommunicationLog={setViewCommunicationLog}
          viewCommunicationLogOpen={viewCommunicationLogOpen}
          viewCommunicationLogClose={viewCommunicationLogClose}
        />
      )} */}

      {poiWatch && (
        <PoiWatch poiWatch={poiWatch} poiWatchClose={poiWatchClose} />
      )}

      {imageDialog && (
        <ImageDialog
          imageDialog={imageDialog}
          imageDialogClose={imageDialogClose}
          poiImages={poiImages}
        />
      )}

      {viewCommunicationLog && (
        <CommunicationLogModal
          show={viewCommunicationLog}
          handleModal={(status) => setViewCommunicationLog(status)}
          id={recordId}
          setShowAddComLog={(status) => setShowAddComLog(status)}
          log_type="poi"
        />
      )}

      {showAddComLog && (
        <AddComLogModal
          show={showAddComLog}
          handleModal={(status) => setShowAddComLog(status)}
          id={recordId}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          log_type="poi"
        />
      )}
      {reviewDialog && (
        <PoiRequireReview
          reviewDialog={reviewDialog}
          reviewDialogClose={reviewDialogClose}
          id={recordId}
          reviewReq={reviewReq}
          getPoiList={getPoiList}
        />
      )}

      {peek && (
        <PoiPeek
          peek={peek}
          setPeek={setPeek}
          peekOpen={peekOpen}
          peekClose={peekClose}
          singleRecord={singleRecord}
        />
      )}
      {poiViewEncounter && (
        <PoiViewEncounter
          poiViewEncounter={poiViewEncounter}
          poiViewEncounterClose={poiViewEncounterClose}
          poiViewEncounterOpen={poiViewEncounterOpen}
          setPoiViewEncounter={setPoiViewEncounter}
          singleRecord={singleRecord}
        />
      )}
    </div>
  );
}

function PoiList() {
  return (
    <React.Fragment>
      <Helmet title="Person of Interest" />
      <Typography variant="h3" gutterBottom display="inline">
        Person of Interest
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PoiList;

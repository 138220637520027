import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import format from "date-fns/format";
import {
  NavLink,
  useParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Toaster from "./Toaster";
import { makeStyles } from "@mui/styles";
import { UpdateScheduleTime } from "../../api";
import { useSelector } from "react-redux";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  Switch,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  Tabs,
  Tab,
  Menu,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ChangeHistory as ChangeHistoryIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { event } from "jquery";
import moment from "moment";
const Button = styled(MuiButton)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LavelTable = styled((props) => <TableContainer {...props} />)(
  ({ theme }) => ({
    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiFormLabel-root": {
        whiteSpace: "nowrap",
        paddingRight: "10px",
        minWidth: "60px",
      },
    },
    "& .MuiTableCell-root": {
      padding: "5px 10px 5px 0px",
      border: "0px",
    },
  })
);
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const StyledBox = styled((props) => <Paper {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .Mui-selected": {
      backgroundColor: "#7aa8ff0d",
    },
    "& .MuiList-root::-webkit-scrollbar": {
      /* display: "none", */
    },
  },
}));
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
//Auto Select
const customNotification = [
  { title: "Not selected" },
  { title: "Option one" },
  { title: "Option two" },
  { title: "Option three" },
  { title: "Option four" },
  { title: "Option five" },
];
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(MuiInput)`
  width: 42px;
`;

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const StyledInputSlider = styled((props) => <Paper {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiInput-root::before": {
        borderBottom: "0px",
      },
      "& .MuiInput-root": {
        "& .MuiInput-input": {
          paddingBottom: "0px",
        },
      },
    },
  })
);
const StyledContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#7aa8ff0d",
    padding: "15px",
    marginTop: "10px",
    maxHeight: "542px",
    overflowY: "auto",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      borderBottom: "0px",
      marginBottom: "12px",
      "& .MuiTypography-subtitle2": {
        marginRight: "5px",
      },
      "& .MuiSvgIcon-root": {
        margin: "0px 6px",
        width: "12px",
        height: "12px",
        verticalAlign: "middle",
      },
    },
  },
}));
// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
//For on or off
const CustomSwitchOffOn = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"ON"',
      left: 15,
    },
    "&:after": {
      content: '"OFF"',
      right: "-2px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  })
);
const TourSchedule = (props) => {
  const {
    addschedule,
    addScheduleClose,
    addScheduleModal,
    TimePicker,
    viewSchedule,
    result,
    levelType,
  } = props;
  //   const { event_class, start_time, end_time } = result;
  const [fromTime, setFromTimeValue] = useState(null);
  const [toTime, setToTimeValue] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  // Check for permission for specific actions
  const hasPermissionToUpdateEditSchedule = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "updateEditSchedule" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  if (result !== undefined) {
    var { event_class, start_time, end_time, task_id, id, type } = result;
  }

  let startTime = "2020-01-01" + " " + start_time;
  let endTime = "2020-01-01" + " " + end_time;
  const initialValues = {
    add_schedule: event_class ? event_class : "every_day",
    start_time: start_time ? start_time : new Date().setHours("00", "00", "00"),
    end_time: end_time ? end_time : new Date().setHours("08", "00", "00"),
  };

  const validationSchema = Yup.object().shape({
    start_time: Yup.string().required("Start Time Required"),
    end_time: Yup.string().required("End Time Required"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let payload = {
        start_time: moment(values.start_time).format("hh:mm:ss"),
        end_time: moment(values.endTime).format("hh:mm:ss"),
        event_class: values.add_schedule,
        type: levelType,
        task_id: task_id,
        id: id,
      };
      UpdateScheduleTime(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setOpenSnack(true);
            // scheduleList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message="Add schedule has been successfully updated"
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        open={addschedule}
        onClose={addScheduleClose}
        aria-labelledby="add-schedule"
        aria-describedby="add-schedule"
      >
        <DialogTitle
          id="add-schedule"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Add Schedule
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => addScheduleModal(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText sx={{ padding: "20px 20px 20px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl>
                    <StyledRadio>
                      <RadioGroup
                        aria-labelledby="add-schedules"
                        name="add_schedule"
                        value={values.add_schedule}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <FormControlLabel
                          value="every_day"
                          control={<Radio />}
                          label="Every day"
                        />
                        <FormControlLabel
                          value="weekdays"
                          control={<Radio />}
                          label="Weekdays"
                        />
                        <FormControlLabel
                          value="saturday_sunday"
                          control={<Radio />}
                          label="Saturday & Sunday"
                        />
                      </RadioGroup>
                    </StyledRadio>
                  </FormControl>

                  <Grid container spacing={4}>
                    <Grid item sx={12} lg={6}>
                      <FormControl mt={6}>
                        <TimePicker
                          label="From"
                          name="start_time"
                          value={values.start_time}
                          onChange={(newValue) => {
                            setFieldValue("start_time", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="08:40"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sx={12} lg={6}>
                      <FormControl mt={6}>
                        <TimePicker
                          label="To"
                          name="end_time"
                          value={values.end_time}
                          onChange={(newValue) => {
                            setFieldValue("end_time", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="08:40"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions
                    sx={{
                      paddingBottom: "20px",
                      paddingRight: "0px",
                      paddingTop: "30px",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => addScheduleModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!hasPermissionToUpdateEditSchedule}
                      onClick={() => addScheduleModal(false)}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TourSchedule;

import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "./slices/mainSlice";
import counterReducer from "./slices/counter";
import corporateReducer from "./slices/corporateSlice";
import loginReducer from "./slices/loginSlice";
import taskReducer from "./slices/taskSlice";
import userReducer from "./slices/userSlice";
import propertyReducer from "./slices/propertySlice";
import reportReducer from "./slices/reportSlice";
import tourReducer from "./slices/tourSlice";
import permissionReducer from "./slices/permissionSlice";
import equipmentReducer from "./slices/equipmentSlice";
import lostfoundTableCalReducer from "./slices/lostfound/lostfoundTableCalSlice";
import defaultLostfoundTableCalReducer from "./slices/lostfound/defaultLostfoundTableCalSlice";
import tourTableColReducer from "./slices/tours/tourTabelColSlice";
import defaultTourTableColReducer from "./slices/tours/defaultTourTableColSlice";
import equipmentTableColReducer from "./slices/equipment/equipmentTableColSlice";
import defaultEquipmentTableColReducer from "./slices/equipment/defaultEquipmentTableColSlice";
import reportTableColReducer from "./slices/report/reportTableColSlice";
import defaultReportTableColReducer from "./slices/report/defaultReportTableColSlice";
import logTableColReducer from "./slices/logs/logTableColSlice";
import defaultLogTableColReducer from "./slices/logs/defaultLogTableColSlice";
import visitorListCalReducer from "./slices/visitor/visitorListCalSlice";
import defaultVisitorListCalSliceReducer from "./slices/visitor/defaultVisitorListCalSlice";
import packagesTableColReducer from "./slices/packages/packagesTableColSlice";
import defaultPackagesTableColReducer from "./slices/packages/defaultPackagesTableColSlice";
import poiTableColReducer from "./slices/poi/poiTableColSlice";
import defaultPoiTableColReducer from "./slices/poi/defaultPoiTableColSlice";
import irClassificationTableColReducer from "./slices/irClassification/irClassificationTableColSlice";
import defaultIrClassificationTableColReducer from "./slices/irClassification/defaultIrClassificationTableColSlice";
import floorPlanReducer from "./slices/floorPlanSlice";

export const store = configureStore({
  reducer: {
    main: mainReducer,
    counter: counterReducer,
    corporate: corporateReducer,
    task: taskReducer,
    user: userReducer,
    login: loginReducer,
    property: propertyReducer,
    report: reportReducer,
    tour: tourReducer,
    permissions: permissionReducer,
    equipment: equipmentReducer,
    lostfoundTableCal: lostfoundTableCalReducer,
    defaultLostfoundTableCal: defaultLostfoundTableCalReducer,
    tourTableCol: tourTableColReducer,
    defaultTourTableCol: defaultTourTableColReducer,
    equipmentTableCol: equipmentTableColReducer,
    defaultEquipmentTableCol: defaultEquipmentTableColReducer,
    reportTableCol: reportTableColReducer,
    defaultReportTableCol: defaultReportTableColReducer,
    logTableCol: logTableColReducer,
    defaultLogTableCol: defaultLogTableColReducer,
    visitorListCal: visitorListCalReducer,
    defaultVisitorListCal: defaultVisitorListCalSliceReducer,
    packagesTableCol: packagesTableColReducer,
    defaultPackagesTableCol: defaultPackagesTableColReducer,
    poiTableCol: poiTableColReducer,
    defaultPoiTableCol: defaultPoiTableColReducer,
    irClassificationTableCol: irClassificationTableColReducer,
    defaultIrClassificationTableCol: defaultIrClassificationTableColReducer,
    floorPlan: floorPlanReducer,
  },
});

import React from "react";
import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { usePlacesWidget } from "react-google-autocomplete";

const TextfieldWrapper = ({
  name,
  setLatitude,
  setLongitude,
  handleAddresscomponent,
  ...otherProps
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBZ5R4X8XrNL3bo_Bxbs-VKJE-o7oF_SMc",
    options: {
      types: ["geocode"],
      // types: ["(regions)"],
    },
    onPlaceSelected: (place) => {
      setFieldValue(name, place.formatted_address);
      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());
      let address = handleAddresscomponent(place.address_components);
      setFieldValue("city", address[0]);
      setFieldValue("state", address[1]);
      setFieldValue("country", address[2]);
      setFieldValue("zip", address[3]);
      setFieldValue("street", address[4]);
      setFieldValue("long", place.geometry.location.lng());
    },
  });

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    inputRef: ref,
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return <TextField {...configTextfield} />;
};

export default TextfieldWrapper;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import { makeStyles } from "@mui/styles";
import {
  AddUpdatePropertyInspectionArea,
  PropertyAreaDropdown,
} from "../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import { handleApiResonseErrors } from "../../helper/helper";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  FormControl as MuiFormControl,
  Grid,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const propertyAreaName = [
  { key: "Impaired hearing", value: 1 },
  { key: "Impaired mobility", value: 2 },
  { key: "Impaired vision", value: 3 },
  { key: "VIP", value: 4 },
  { key: "Other", value: 5 },
];
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const EditInspectionArea = ({
  editInspectionAreaDialog,
  editInspectionAreaDialogClose,
  inspectionAreaList,
  singleData,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const [propertyArea, setPropertyArea] = useState([]);
  const propertyAreaDropdown = () => {
    PropertyAreaDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setPropertyArea(res.data);
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    propertyAreaDropdown();
  }, []);
  const {
    id,
    property_area_name,
    area_id,
    area_name,
    description,
    tag_id,
    status,
  } = singleData;
  let initialValues = {
    property_area_name: area_id ? area_id : "",
    area_name: property_area_name ? property_area_name : "",
    tag_id: tag_id ? tag_id : "",
    special_instruction: description ? description : "",
  };
  const validationSchema = Yup.object().shape({
    property_area_name: Yup.string().required(
      "Please select the property area name"
    ),
    area_name: Yup.string().required("Please enter area name"),
    tag_id: Yup.string().required("Please enter tag id"),
    special_instruction: Yup.string().required(
      "Please enter special instruction"
    ),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        id: id,
        name: values.area_name,
        description: values.special_instruction,
        area_id: values.property_area_name,
        tag_uid: values.tag_id,
      };
      AddUpdatePropertyInspectionArea(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setLoading(false);
            editInspectionAreaDialogClose();
            inspectionAreaList();
            dispatch(getMsg("Record saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            setLoading(false);
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-inspection-area-title"
        aria-describedby="add-inspection-area-desc"
        open={editInspectionAreaDialog}
        onClose={editInspectionAreaDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-inspection-area-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add Inspection Area
          </Typography>
          <DialogActions>
            <IconButton
              onClick={editInspectionAreaDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="property_area_name"
                        fieldLabel="Property area name*"
                        options={propertyArea}
                        displayEmpty
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="area_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Area Name*"
                        autoComplete="off"
                        placeholder="Main kitchen, housekeeping, engineering shop, etc."
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="tag_id"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Tag ID*"
                        autoComplete="off"
                        placeholder="The ID of the button scanned to start the inspection"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="special_instruction"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Description*"
                        autoComplete="off"
                        placeholder="Add notes to define the inspection area"
                        rows={5}
                        multiline={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={editInspectionAreaDialogClose}
                    sx={{ marginRight: "5px" }}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default EditInspectionArea;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { RecentScansData } from "../../../api";
import moment from "moment";
const RecentScans = ({
  recentScans,
  setRecentScans,
  recentScanClose,
  tagNameModal,
  tagIdModal,
  startDateToFilter,
  endDateToFilter,
}) => {
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  const [recentScansList, setRecentScansList] = useState([]);
  //handler for (D1) get button missed outer dialog for tourname
  const getRecentScansList = () => {
    let formatedsDate =
      startDateToFilter === ""
        ? ""
        : moment(startDateToFilter).format("YYYY-MM-DD");
    let formatedeDate =
      endDateToFilter === ""
        ? ""
        : moment(endDateToFilter).format("YYYY-MM-DD");
    let payload = {
      tagId: tagIdModal,
      start_date: formatedsDate, //"2018-12-04",
      end_date: formatedeDate, // "2022-06-01",
    };
    setDialogLoader(true);
    RecentScansData(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setRecentScansList(res.data);
        } else {
          setRecentScansList([]);
        }
        setDialogLoader(false);
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getRecentScansList();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={recentScans}
        onClose={recentScanClose}
        aria-labelledby="recent-scans-title"
        aria-describedby="recent-scans-description"
      >
        <DialogTitle
          id="recent-scans-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Recent scans at {tagNameModal}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setRecentScans(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "15px 0" }}>
          <TableContainer ClassName="tableContainer">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Datetime</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogLoader ? (
                  <TableCell colSpan={2} align="center">
                    <CircularProgress />
                  </TableCell>
                ) : (
                  <>
                    {recentScansList.map((item) => {
                      return (
                        <TableRow>
                          <TableCell>{item.user_name}</TableCell>
                          <TableCell>{item.scan_time}</TableCell>
                        </TableRow>
                      );
                    })}
                    {recentScansList.length === 0 ? (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          No records found
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setRecentScans(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecentScans;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { EncounterRequireReview } from "../../../api";
import { useDispatch } from "react-redux";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { handleApiResonseErrors } from "../../../helper/helper";
const RequireReview = ({
  reviewDialog,
  reviewDialogClose,
  id,
  reviewReq,
  poiEncounterList,
}) => {
  const [checked, setChecked] = React.useState(reviewReq == "Y" ? true : false);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setChecked(event.target.checked);
    EncounterRequireReview({
      id: id,
      review_request: event.target.checked ? "Y" : "N",
    })
      .then((res) => {
        if (res.statusCode === 200) {
          poiEncounterList();
          dispatch(
            getMsg(
              checked
                ? "POI unmarked as review required"
                : "POI marked as review required"
            )
          );
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  return (
    <>
      <Dialog
        open={reviewDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="locationDialog-title"
        aria-describedby="locationDialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="locationDialog-title"
        >
          <Typography variant="h4" component="div">
            Require Review
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={reviewDialogClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Checking the box below will require your users to review this POI
            encounter when logging into the website. Unchecking the box will
            remove this requirement.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                checked={checked}
                onChange={handleChange}
              />
            }
            label="Require review"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RequireReview;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import CustomTextField from "../../components/form-components/CustomTextField";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Button,
  Paper as MuiPaper,
  AppBar,
  Toolbar,
  Select,
  MenuItem,
  TextField,
  Grid,
  FormLabel,
  FormControlLabel,
  FormControl as MuiFormControl,
  Box,
  Switch,
  FormGroup,
} from "@mui/material";
import { Covid19ScreeningSurvey, Covid19SurveyLocation } from "../../api";
import { Snackbar } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { Formik } from "formik";
import * as Yup from "yup";
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Image = styled.img``;

// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "15px",
      marginBottom: "20px",
      flexDirection: "column-reverse",
      "& .MuiBox-root": {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  left: "-10px",
  bottom: "-30px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
//For on or off
const CustomSwitchOffOn = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"ON"',
      left: 15,
    },
    "&:after": {
      content: '"OFF"',
      right: "-2px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));

const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);

function SurveyForm() {
  let navigate = useNavigate();
  const [selectLanguage, setSelectLanguage] = useState(1);
  const [locationDetails, setLocationDetails] = useState({});
  const [locationName, setLocationName] = useState("");

  const handleChange = (event) => {
    setSelectLanguage(event.target.value);
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    company: Yup.string().required("Company Name is required"),
    self_quarantined: Yup.string().oneOf(["1", "0"]),
    qurantine_description: Yup.string().when(["self_quarantined"], {
      is: "1",
      then: Yup.string().required("Additional Info is required here"),
    }),
  });
  let initialValues = {
    first_name: "",
    last_name: "",
    company: "",
    cough: "0",
    breath_difficulty: "0",
    fever: "0",
    chills: "0",
    repeated_chills: "0",
    muscle_pain: "0",
    headache: "0",
    sore_throat: "0",
    taste_smell_loss: "0",
    waiting_test_result: "0",
    tested_positive: "0",
    self_quarantined: "0",
    exposed_other: "0",
    exposed_positive_tested: "0",
    exposed_cdc: "0",
    travelled_outside: "0",
    qurantine_description: "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let payload = {
      locationId: locationDetails.LocationID,
      values: values,
    };
    Covid19ScreeningSurvey(payload)
      .then((response) => {
        if (response.statusCode == 200) {
          let responseData = {
            data: response.data,
            locationDetail: locationDetails,
            locationName: locationName,
          };
          navigate("/survey-done", { state: { data: responseData } });
        }
      })
      .catch((error) => {
        console.log("something went wrong");
      });
  };
  useEffect(() => {
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    // Extract the ru_id
    const locationName = parts[parts.length - 1];
    setLocationName(locationName);
    Covid19SurveyLocation(locationName)
      .then((response) => {
        if (response.statusCode != 200) {
          console.log("something went wrong");
        }
        setLocationDetails(response.data);
      })
      .catch((error) => {
        console.log("something went wrong");
      });
  }, []);
  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: "#233044" }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Image
            src={`/static/img/brands/logo.svg`}
            style={{
              height: 40,
              marginLeft: 0,
              marginBottom: 1.75,
              marginTop: 1.75,
            }}
          />
          <Select
            sx={{
              minWidth: 160,
              marginBottom: 1.75,
              marginTop: 1.75,
              color: "#ffffff",
              border: "1px solid #ffffff8a",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiSvgIcon-root": {
                color: "#ffffff",
              },
            }}
            labelId="select-language-label"
            id="select-language-helper"
            value={selectLanguage}
            onChange={handleChange}
          >
            <MenuItem value={1}>English</MenuItem>
            <MenuItem value={2}>Spanish</MenuItem>
          </Select>
        </Toolbar>
      </AppBar>
      <Paper
        sx={{
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 3.75,
          paddingRight: 3.75,
        }}
      >
        <Grid container spacing={3}>
          <Grid item={12} md={10} sx={{ mx: "auto" }}>
            <Box sx={{ textAlign: "center" }}>
              <Image
                src={`/static/img/marigold.tmp`}
                style={{ maxWidth: 200 }}
              />
            </Box>
            <Typography
              component="p"
              variant="body2"
              sx={{
                textAlign: "center",
                fontSize: 19,
                fontWeight: 300,
                marginBottom: 10,
              }}
            >
              All employees and visitors are required to complete this survey
              each day before working on site. Your responses will be used to
              ensure a safe environment for everyone.
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel>
                          First Name
                          <span style={{ color: "#f44336" }}>*</span>
                        </FormLabel>
                        <TextField
                          id="first_name"
                          name="first_name"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="John"
                          value={values.first_name}
                          error={Boolean(
                            touched.first_name && errors.first_name
                          )}
                          helperText={touched.first_name && errors.first_name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel>
                          Last Name
                          <span style={{ color: "#f44336" }}>*</span>
                        </FormLabel>
                        <TextField
                          id="last_name"
                          name="last_name"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Smith"
                          value={values.last_name}
                          error={touched.last_name && Boolean(errors.last_name)}
                          helperText={touched.last_name && errors.last_name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel>
                          Company
                          <span style={{ color: "#f44336" }}>*</span>
                        </FormLabel>
                        <TextField
                          id="company"
                          name="company"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Acme Products"
                          value={values.company}
                          error={touched.company && Boolean(errors.company)}
                          helperText={touched.company && errors.company}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          padding: "15px 20px",
                          backgroundColor: "#f0f7ff",
                          borderRadius: 2,
                          marginTop: 5,
                          marginBottom: 5,
                          fontWeight: 400,
                        }}
                      >
                        Are you experiencing any of the following COVID-19
                        symptoms or combination of symptoms:
                      </Typography>
                      <CustomSwitchControl>
                        <FormGroup>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="cough"
                                    value={values.cough}
                                    checked={values.cough === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "cough",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Cough"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="breath_difficulty"
                                    value={values.breath_difficulty}
                                    checked={values.breath_difficulty === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "breath_difficulty",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Shortness of breath or difficulty breathing"
                              />
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          padding: "15px 20px",
                          backgroundColor: "#f0f7ff",
                          borderRadius: 2,
                          marginTop: 5,
                          marginBottom: 5,
                          fontWeight: 400,
                        }}
                      >
                        Or at least two of the symptoms below:
                      </Typography>
                      <CustomSwitchControl>
                        <FormGroup>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="fever"
                                    value={values.fever}
                                    checked={values.fever === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "fever",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Fever (100.4 degrees or higher)"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="chills"
                                    value={values.chills}
                                    checked={values.chills === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "chills",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Chills"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="repeated_chills"
                                    value={values.repeated_chills}
                                    checked={values.repeated_chills === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "repeated_chills",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Repeated shaking with chills"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="muscle_pain"
                                    value={values.muscle_pain}
                                    checked={values.muscle_pain === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "muscle_pain",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Muscle Pain"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="headache"
                                    value={values.headache}
                                    checked={values.headache === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "headache",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Headache"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="sore_throat"
                                    value={values.sore_throat}
                                    checked={values.sore_throat === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "sore_throat",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Sore Throat"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="taste_smell_loss"
                                    value={values.taste_smell_loss}
                                    checked={values.taste_smell_loss === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "taste_smell_loss",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="New loss of taste or smell"
                              />
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          padding: "15px 20px",
                          backgroundColor: "#f0f7ff",
                          borderRadius: 2,
                          marginTop: 5,
                          marginBottom: 5,
                          fontWeight: 400,
                        }}
                      >
                        Additional questions:
                      </Typography>
                      <CustomSwitchControl>
                        <FormGroup>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="waiting_test_result"
                                    value={values.waiting_test_result}
                                    checked={values.waiting_test_result === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "waiting_test_result",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Are you currently waiting for COVID-19 test results?"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="tested_positive"
                                    value={values.tested_positive}
                                    checked={values.tested_positive === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "tested_positive",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Have you tested positive for COVID-19?"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="self_quarantined"
                                    value={values.self_quarantined}
                                    checked={values.self_quarantined === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "self_quarantined",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Have you self-quarantined? (remaining in your home and outdoor activities without coming closer than 6-feet from others)?"
                              />
                              {values.self_quarantined === "1" && (
                                <TextAreaControl mt={3}>
                                  <FormControl mt={1}>
                                    <FormLabel
                                      sx={{
                                        marginBottom: 3,
                                        color: "rgba(0,0,0,0.87)",
                                      }}
                                    >
                                      How many days and why?*
                                    </FormLabel>
                                    <CustomTextField
                                      fullWidth
                                      name="qurantine_description"
                                      multiline
                                      rows={4}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      placeholder="List days, and whether quarantine was directed by your doctor"
                                      error={Boolean(
                                        touched.qurantine_description &&
                                          errors.qurantine_description
                                      )}
                                      helperText={
                                        touched.qurantine_description &&
                                        errors.qurantine_description
                                      }
                                    />
                                  </FormControl>
                                </TextAreaControl>
                              )}
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="exposed_other"
                                    value={values.exposed_other}
                                    checked={values.exposed_other === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "exposed_other",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Have you been exposed to anyone currently waiting for COVID-19 test results?"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="exposed_positive_tested"
                                    value={values.exposed_positive_tested}
                                    checked={
                                      values.exposed_positive_tested === "1"
                                    }
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "exposed_positive_tested",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Have you been exposed to anyone who has tested positive for COVID-19 within the last 14 days?"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="exposed_cdc"
                                    value={values.exposed_cdc}
                                    checked={values.exposed_cdc === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "exposed_cdc",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Have you been exposed to anyone with any of the CDC specified symptoms (see above)?"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <CustomSwitchYesNo
                                    name="travelled_outside"
                                    value={values.travelled_outside}
                                    checked={values.travelled_outside === "1"}
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        "travelled_outside",
                                        checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                }
                                label="Have you traveled outside your state or regional area?"
                              />
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </CustomSwitchControl>
                    </Grid>
                  </Grid>
                  <div>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        marginTop: 5,
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

function ScreeningSurvey() {
  return (
    <React.Fragment>
      <Helmet title="Screening Survey" />
      <SurveyForm />
    </React.Fragment>
  );
}

export default ScreeningSurvey;

import React, { useState, useEffect } from "react";
import { Sliders, Users } from "react-feather";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
// import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import { ModifyNavMenu } from "../api";
import {
  Business,
  Build,
  DirectionsCar,
  Assignment,
  Person,
  ReportProblem,
  Explore,
  VpnKey,
  Report,
  Streetview,
  AutoAwesomeMosaic,
  Settings as setting,
  Event,
  Apartment,
  Adjust,
} from "@mui/icons-material";
const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: calc(100% - 258px);
  }
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const [navList, setNavList] = useState([]);
  const modifyNavList = () => {
    ModifyNavMenu()
      .then((res) => {
        if (res.statusCode === 200) {
          setNavList(res.data);
        } else {
          setNavList([]);
          console.log("Something went wrong");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    modifyNavList();
  }, []);

  const pagesSection = navList.map((row, index) => {
    if (!(row.visible === 0)) {
      if (row.menu === "Dashboard") {
        return { href: "/", icon: Sliders, title: row.menu };
      }
      if (row.menu === "Live") {
        return {
          href: "/livemodule",
          icon: Adjust,
          title: "Live",
          children: [
            { href: "/livemodule/live-module", title: "Live Module" },
            { href: "/livemodule/live-cast-pics", title: "Cast Pics" },
          ],
        };
      }
      if (row.menu === "Logs") {
        return {
          href: "/logs",
          icon: setting,
          title: "Logs",
          children: [
            { href: "/logs/aed-management", title: "AED Management" },
            {
              href: "/logs/alarm-activation-log",
              title: "Alarm Activation Log",
            },
            { href: "/logs/attendance-log", title: "Attendance Log" },
            {
              href: "/logs/emergency-procedures",
              title: "Emergency Procedures",
            },
            // { href: "/logs/covid19-survey", title: "Covid-19 Survey" },
            { href: "/logs/key-requisition", title: "Key Requisition" },
            { href: "/logs/overdue-key-report", title: "Overdue Key Report" },
            { href: "/logs/locker-log", title: "Locker Log" },
            { href: "/logs/employees", title: "Employees" },
            { href: "/logs/vehicle-management", title: "Vehicle Management" },
            { href: "/logs/vehicle-inspection", title: "Vehicle Inspection" },
            { href: "/logs/shift-report", title: "Shift Report" },
            { href: "/logs/special-visitors", title: "Special Visitors" },
          ],
        };
      }
      if (row.menu === "Accounts") {
        return {
          href: "/accounts",
          icon: Business,
          title: "Accounts",
          children: [
            {
              href: "/accounts/property-list",
              title: "List Property",
            },
            {
              href: "/accounts/list-corporate",
              title: "List Corporate",
            },
            {
              href: "/accounts/add-corporate",
              title: "Add Corporate",
            },
          ],
        };
      }
      if (row.menu === "Users") {
        return {
          href: "/users",
          icon: Users,
          title: "Users",
          children: [
            {
              href: "/users/user",
              title: "List User",
            },
            {
              href: "/users/adduser",
              title: "Add User",
            },
          ],
        };
      }
      if (row.menu === "Equipment") {
        return {
          href: "/equipment",
          icon: Build,
          title: "Equipment",
          children: [
            {
              href: "/equipment/equipment-list",
              title: "List Equipment",
            },
            {
              href: "/equipment/add-equipment",
              title: "Add Equipment",
            },
            {
              href: "/equipment/equipment-user-list",
              title: "List Equipment User",
            },
            {
              href: "/equipment/add-equipment-user",
              title: "Add Equipment User",
            },
            {
              href: "/equipment/process-equipment",
              title: "Process Equipment",
            },
            {
              href: "/equipment/equipment-logs",
              title: "Equipment Logs",
            },
            {
              href: "/equipment/equipment-detail",
              title: "Equipment Detail",
            },
          ],
        };
      }
      if (row.menu === "Tours") {
        return {
          href: "/tours",
          icon: DirectionsCar,
          title: "Tours",
          children: [
            {
              href: "/tours/tour-listing",
              title: "Tour List",
            },
            {
              href: "/tours/geolocate",
              title: "Geolocate",
            },
          ],
        };
      }
      if (row.menu === "Tasks") {
        return {
          href: "/tasks",
          icon: Assignment,
          title: "Tasks",
          children: [
            {
              href: "/tasks/task-list",
              title: "List Tasks",
            },
            {
              href: "/tasks/add-task",
              title: "Add Task",
            },
          ],
        };
      }
      if (row.menu === "Visitor") {
        return {
          href: "/visitors",
          icon: Person,
          title: "Visitors",
          children: [
            {
              href: "/visitors/dashboard",
              title: "Visitor Dashboard",
            },
            {
              href: "/visitors/list-visitors",
              title: "List Visitors",
            },
            {
              href: "/visitors/add-visitor",
              title: "Add Visitor",
            },
            {
              href: "/visitors/visitor-types",
              title: "Visitor Types",
            },
            {
              href: "/visitors/companies",
              title: "Companies",
            },
            {
              href: "/visitors/process-visitor",
              title: "Process Visitor",
            },
            {
              href: "/visitors/visit-log",
              title: "Visit Log",
            },
            {
              href: "/visitors/kiosk-settings",
              title: "Kiosk Settings",
            },
            {
              href: "/visitors/kiosk-mode",
              title: "Kiosk Mode",
            },
            {
              href: "/visitors/visitor-watch-list",
              title: "Visitor Watch List",
            },
          ],
        };
      }
      if (row.menu === "Property Inspection") {
        return {
          href: "/property-inspection",
          title: "Property Inspection",
          icon: Apartment,
        };
      }
      if (row.menu === "Employee Accidents") {
        return {
          href: "/employee-accidents",
          title: "Employee Accidents",
          icon: ReportProblem,
        };
      }
      if (row.menu === "Reports") {
        return {
          href: "/reports",
          icon: Report,
          title: "Reports",
          children: [
            {
              href: "/reports/tour-patrol",
              title: "Tour/Patrol",
            },
            {
              href: "/reports/non-tour-tasks",
              title: "Non Tour Tasks",
            },
            {
              href: "/reports/daily-activity",
              title: "Daily Activity",
            },
            {
              href: "/reports/performance",
              title: "Performance",
            },
            {
              href: "/reports/incidents",
              title: "Incidents",
            },
            {
              href: "/reports/passdown",
              title: "Passdown",
            },
            {
              href: "/reports/button-history",
              title: "Button History",
            },
            {
              href: "/reports/tour-summary",
              title: "Tour Summary",
            },
            {
              href: "/reports/missed-buttons",
              title: "Missed Buttons",
            },
            {
              href: "/reports/sms-reports",
              title: "SMS Reports",
            },
            {
              href: "/reports/device-reports",
              title: "Device Reports",
            },
            {
              href: "/reports/send-ir-logs",
              title: "Send IR Log",
            },
          ],
        };
      }
      if (row.menu === "Persons of Interest") {
        return {
          href: "/poi",
          icon: Streetview,
          title: "Persons of Interest",
          children: [
            {
              href: "/poi/poi-dashboard",
              title: "POI Dashboard",
            },
            {
              href: "/poi/poi-list",
              title: "List POI",
            },
            {
              href: "/poi/add-poi",
              title: "Add Person of Interest",
            },
            {
              href: "/poi/poi-encounters",
              title: "POI Encounters",
            },
            {
              href: "/poi/poi-image-search",
              title: "POI Image Search",
            },
          ],
        };
      }
      if (row.menu === "Lost and Found") {
        return {
          href: "/lostandfound",
          icon: Explore,
          title: "Lost and Found",
          children: [
            {
              href: "/lostandfound/list-lost-found",
              title: "List Lost & Found",
            },
            {
              href: "/lostandfound/add-lost-found",
              title: "Add Lost & Found",
            },
            {
              href: "/lostandfound/lost-found-image-search",
              title: "Image Search",
            },
            {
              href: "/lostandfound/shipments",
              title: "Shipments",
            },
            {
              href: "/lostandfound/shipments-admin",
              title: "Shipments-Admin",
            },
          ],
        };
      }
      if (row.menu === "Packages") {
        return {
          href: "/packages",
          icon: AutoAwesomeMosaic,
          title: "Packages",
          children: [
            {
              href: "/packages/list-packages",
              title: "List Packages",
            },
            {
              href: "/packages/add-package",
              title: "Add Package",
            },
            {
              href: "/packages/package-audit",
              title: "Package Audit",
            },
          ],
        };
      }
      if (row.menu === "Schedule") {
        return {
          href: "/schedules/schedule-calendar",
          icon: Event,
          title: "Calendar",
          // children: [
          //   {
          //     href: "/schedules/schedule-calendar",
          //     title: "Calendar",
          //   },
          // ],
        };
      }
      if (row.menu === "Business Intel") {
        return {
          href: "/businessintelligence",
          icon: AutoAwesomeMosaic,
          title: "Business Intel",
          children: [
            {
              href: "/businessintelligence/business-chart",
              title: "Charts and Graphs",
            },
            {
              href: "/businessintelligence/ir-dashboard",
              title: "IR Dashboard",
            },
          ],
        };
      }
      // if (row.menu === "Privacy") {
      //   return {
      //     href: "/permissions",
      //     icon: VpnKey,
      //     title: "Permissions",
      //     children: [
      //       {
      //         href: "/permissions/edit-group-permissions",
      //         title: "Edit Group Permissions",
      //       },
      //       {
      //         href: "/permissions/user-role",
      //         title: "User Role",
      //       },
      //     ],
      //   };
      // }
    }
  });
  const filteredNavList = pagesSection.filter((item) => item !== undefined);
  const dashboardItems = [
    {
      title: "Pages",
      pages: filteredNavList,
    },
  ];
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
    </Root>
  );
};

export default Dashboard;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  irClassificationList: {
    classificationName: true,
    count: true,
    critical: true,
    nurseNotify: true,
    imgRequired: true,
    taskCount: true,
    activeStatus: true,
    action: true,
  },
};

export const irClassificationTableColSlice = createSlice({
  name: "irClassification",
  initialState,
  reducers: {
    setIrClassificationList: (state, action) => {
      state.irClassificationList = action.payload;
    },
  },
});

export const { setIrClassificationList } =
  irClassificationTableColSlice.actions;

export default irClassificationTableColSlice.reducer;

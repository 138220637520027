import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, FieldArray } from "formik";
import styled from "styled-components/macro";
import { CloneVisitor } from "../../../../api";
import CustomTextField from "../../../../components/form-components/CustomTextField";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Grid,
  FormControl as MuiFormControl,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const CloneVisitors = ({
  cloneVisitorDialog,
  cloneVisitorDialogClose,
  data,
  showAlertModal,
  VisitorList,
  setLoader,
}) => {
  const initialValues = {
    visitors: [{ first_name: "", last_name: "" }],
  };

  const validationSchema = Yup.object().shape({
    visitors: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
      })
    ),
  });

  const [numVisitors, setNumVisitors] = useState(1);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoader(true);
      let formData = {
        id: data.id,
        cloneVisitor: values,
      };
      CloneVisitor(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            showAlertModal("success", "Record has been submitted successfully");
            VisitorList();
            cloneVisitorDialogClose();
          } else {
            showAlertModal("error", "Sorry! something wrong please try again");
          }
          setLoader(false);
        })
        .catch((err) => {
          showAlertModal("error", "Sorry! something wrong please try again");
          setLoader(false);
        });
    } catch (error) {
      showAlertModal("error", "Sorry! something wrong please try again");
    }
  };

  const handleKeyPress = (event, push) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      push({ first_name: "", last_name: "" });
      setNumVisitors(numVisitors + 1);
    }
  };
  const handleRemoveRow = (index, remove) => {
    remove(index);
    setNumVisitors(numVisitors - 1);
  };

  return (
    <>
      <Dialog
        aria-labelledby="clone-visitor"
        aria-describedby="clone-visitor-desc"
        open={cloneVisitorDialog}
        onClose={cloneVisitorDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="clone-visitor-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Clone Visitor
          </Typography>
          <DialogActions>
            <IconButton
              onClick={cloneVisitorDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter first and last name of a visitor then hit the enter key on
            your keyboard to add additional visitors. After saving, the record
            you selected when opening this popup will be cloned, but each cloned
            record will have a different name per the list you add here. Be sure
            the expiry date is set correctly for the cloned record.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FieldArray name="visitors">
                  {({ push, remove }) => (
                    <>
                      {values.visitors.map((_, index) => (
                        <Grid container spacing={6} key={index}>
                          <Grid item xs={12} md={5}>
                            <FormControl mt={6}>
                              <CustomTextField
                                label="First name"
                                // name="first_name"
                                name={`visitors[${index}].first_name`}
                                id="f-name"
                                InputLabelProps={{ shrink: true }}
                                placeholder="First name"
                                onKeyPress={(e) => handleKeyPress(e, push)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <FormControl mt={6}>
                              <CustomTextField
                                label="Last name"
                                // name="last_name"
                                name={`visitors[${index}].last_name`}
                                id="l-name"
                                InputLabelProps={{ shrink: true }}
                                placeholder="Last name"
                                onKeyPress={(e) => handleKeyPress(e, push)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            {index > 0 && (
                              <Button
                                color="primary"
                                variant="outlined"
                                type="button"
                                style={{ marginTop: 33 }}
                                onClick={() => handleRemoveRow(index, remove)}
                              >
                                Remove
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                </FieldArray>
                <DialogActions sx={{ paddingRight: "24px" }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={cloneVisitorDialogClose}
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "5px" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CloneVisitors;

export default [
  {
    id: "employee_id",
    label: "Barcode ID",
  },
  {
    id: "fname",
    label: "First Name",
  },
  {
    id: "lname",
    label: "Last Name",
  },
  {
    id: "department",
    label: "Department",
  },
  {
    id: "position",
    label: "Position",
  },
  {
    id: "action",
    label: "Action",
  },
];

export default [
  {
    id: "name",
    label: "Name",
  },

  {
    id: "department",
    label: "Department",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "comment",
    label: "Comment",
  },
  {
    id: "created",
    label: "Created",
  },
  {
    id: "notified",
    label: "Notified",
  },
  {
    id: "action",
    label: "Action",
  },
];

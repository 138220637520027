import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { UpdateKeyDoor } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import EditKeyRing from "./EditKeyRing";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const EditKeyDoor = ({
  editKeyDoor,
  editKeyDoorClose,
  singleData,
  addKeyDoorList,
  doorId,
}) => {
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();

  const {
    name,
    building,
    floor,
    room,
    room_number,
    notes,
    cylinder_pos,
    cylinder,
    lock_number,
    length,
  } = singleData;
  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    door_name: Yup.string().required("Please enter door name"),
    door_building: Yup.string().required("Please enter door building"),
    door_floor: Yup.string().required("Please enter door floor"),
    door_room: Yup.string().required("Please enter door room"),
    door_room_number: Yup.number()
      .typeError("Door room number must be a number")
      .required("Please enter door room number"),
    door_notes: Yup.string().required("Please enter door notes"),
    door_cylinder_pos: Yup.string().required("Please enter door cylinder pos"),
    door_cylinder: Yup.string().required("Please enter door cylinder"),
    door_lock_number: Yup.number()
      .typeError("Door lock number must be a number")
      .required("Please enter door lock number"),
    door_length: Yup.number()
      .typeError("Door length must be a number")
      .required("Please enter door length"),
  });

  /* Setting the initial values for the form. */
  let initialValues = {
    door_name: name ? name : "",
    door_building: building ? building : "",
    door_floor: floor ? floor : "",
    door_room: room ? room : "",
    door_room_number: room_number ? room_number : "",
    door_notes: notes ? notes : "",
    door_cylinder_pos: cylinder_pos ? cylinder_pos : "",
    door_cylinder: cylinder ? cylinder : "",
    door_lock_number: lock_number ? lock_number : "",
    door_length: length ? length : "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        id: doorId,
        name: values.door_name,
        building: values.door_building,
        floor: values.door_floor,
        room: values.door_room,
        room_number: values.door_room_number,
        notes: values.door_notes,
        cylinder_pos: values.door_cylinder_pos,
        cylinder: values.door_cylinder,
        lock_number: values.door_lock_number,
        length: values.door_length,
      };
      UpdateKeyDoor(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key door has been updated successfully"));
            dispatch(getSnackAlert("success"));
            addKeyDoorList();
            editKeyDoorClose();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={editKeyDoor}
        onClocse={editKeyDoorClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "95%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="add-key-door-title"
        aria-describedby="add-key-door-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-door-title"
        >
          <Typography variant="h4">Edit Key door</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={editKeyDoorClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="door_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door name*"
                        autoComplete="off"
                        placeholder=""
                      />
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errMsg}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="door_building"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door building*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_floor"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door floor*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_room"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door room*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      name="door_room_number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Key door room number*"
                      autoComplete="off"
                      placeholder=""
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_notes"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door notes*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_cylinder_pos"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door cylinder pos*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_cylinder"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door cylinder*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_lock_number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door lock number*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_length"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door length*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="outlined" type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={editKeyDoorClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditKeyDoor;

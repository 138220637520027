import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import MUIRichTextEditor from "mui-rte";
import { AddIr } from "../../../api";

import {
  Button,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const taskTag = [
  { key: "Contact", value: "contact" },
  { key: "Photos", value: "photos" },
  { key: "Followup", value: "followup" },
  { key: "Meeting", value: "meeting" },
  { key: "Video", value: "video" },
];
const dueDate = [
  { key: "Same Day", value: "0" },
  { key: "1 Day", value: "1day" },
  { key: "2 Days", value: "2days" },
  { key: "3 Days", value: "3days" },
];
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textEditor: {
    position: "relative",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    borderRadius: "4px",
    "& #mui-rte-container": {
      marginTop: 0,
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      position: "absolute",
      padding: "15px",
      display: "block",
    },
    "& #mui-rte-editor": {
      height: "180px",
      overflowY: "auto",
    },
    "& #mui-rte-editor-container": {
      height: "170px",
      overflowY: "auto",
      borderTop: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    },
    "& #mui-rte-toolbar": {
      padding: "5px 15px",
    },
    "& .DraftEditor-editorContainer": {
      padding: "15px",
    },
  },
}));

function AddVisitorForm(props) {
  let initialValues = {
    classification_name: "",
    classification_desc: "",
    classification: "",
    contact_corporate_nurse: "",
    call_guest: "",
    guest_description: "",
    task_tag: "",
    due_date: "",
  };

  //Add More Table Rows
  const [formData, setFormData] = useState({
    classification_name: "",
    classification_desc: "",
    classification: "",
    contact_corporate_nurse: "",
    image_required: "no",
    data: [{ taskTitle: "", taskDesc: "", taskTag: "", dueDate: "" }],
  });
  let navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("formdata", formData.data);
  const handleChange = (event, index) => {
    console.log("name", event.target.name.split("_")[1]);
    const fieldName = event.target.name; // extract the field name (call_guest, guest_description, etc.) from the event target name
    const fieldValue = event.target.value;
    setFormData((prevFormData) => {
      const newData = [...prevFormData.data]; // create a new array with a copy of the existing data
      newData[index][fieldName] = fieldValue; // update the value of the field for the row at the specified index
      return { ...prevFormData, data: newData }; // return the updated form data object
    });
  };
  console.log("formData", formData.data[0].taskTitle);
  const handleDelete = (index) => {
    setFormData((prevFormData) => {
      const newData = [...prevFormData.data];
      newData.splice(index, 1);
      return { ...prevFormData, data: newData };
    });
  };
  const addIr = () => {
    let payload = {
      classification_name: formData.classification_name,
      classification_desc: formData.classification_desc,
      classification: formData.classification,
      contact_corporate_nurse: formData.contact_corporate_nurse,
      image_required: formData.image_required,
      narrative: formData.narrative,
      data:
        formData.data.length === 1 &&
        (formData.data[0].taskTitle == "" || formData.data[0].taskDesc == "")
          ? []
          : formData.data,
    };
    AddIr(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          navigate({
            pathname: "/settings/corporate-user/ir-classifications",
          });
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  const classes = useStyles();
  return (
    <Card mb={6}>
      <CardContent>
        <Formik initialValues={initialValues}>
          <form>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FormControl mt={3}>
                  <CustomTextField
                    id="classification-name"
                    name="classification_name"
                    value={formData.classification_name}
                    InputLabelProps={{ shrink: true }}
                    label="IR classification name*"
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        classification_name: event.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl mt={3}>
                  <CustomTextField
                    id="classification-desc"
                    name="classification_desc"
                    editable={true}
                    InputLabelProps={{ shrink: true }}
                    label="IR classification description"
                    value={formData.classification_desc}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        classification_desc: event.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel>Classification*</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="classification"
                    value={formData.classification}
                    name="classification"
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        classification: event.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value="critical"
                      control={<Radio />}
                      label="Critical"
                    />
                    <FormControlLabel
                      value="non-critical"
                      control={<Radio />}
                      label="Non critical"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel>Contact corporate nurse</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="contact-corporate-nurse"
                    value={formData.contact_corporate_nurse}
                    name="contact_corporate_nurse"
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        contact_corporate_nurse: event.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value="Y"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" component="div" gutterBottom>
                  Tasks Assigned to Incident Class
                </Typography>
                <Typography>
                  Add default tasks below that should be automatically added to
                  this incident classification. For example, you may have an
                  incident classification for Guest Injury. Here you can add a
                  default task to followup with the guest within 24 hours, then
                  set a due date for 1 day, giving your team 24 hours to
                  complete the task after the IR is saved. Tasks you add here
                  will be attached to the incident when saved. Manage unfinished
                  tasks for all incidents from the context menu in the incident
                  list, or by selecting Task List from the action menu for open
                  IR's.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant="outlined" sx={{ borderBottom: 0 }}>
                  <TableContainer className="tableContainer">
                    <Table
                      stickyHeader
                      aria-labelledby="tableTitle"
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Task Title</TableCell>
                          <TableCell>Task Description</TableCell>
                          <TableCell>Task Tag</TableCell>
                          <TableCell colSpan={2}>Due Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formData.data.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ minWidth: "200px" }}>
                              <FormControl>
                                <CustomTextField
                                  id={`call-guest-${index}`}
                                  name="taskTitle"
                                  placeholder="Call guest"
                                  onChange={(event) =>
                                    handleChange(event, index)
                                  }
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell sx={{ minWidth: "200px" }}>
                              <FormControl>
                                <CustomTextField
                                  id={`guest-description-${index}`}
                                  name="taskDesc"
                                  placeholder="Make contact with guest within 24 hours"
                                  onChange={(event) =>
                                    handleChange(event, index)
                                  }
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell sx={{ minWidth: "200px" }}>
                              {/* <FormControl>
                                <CustomSelect
                                  name="taskTag"
                                  displayEmpty
                                  options={taskTag}
                                  onChange={(event) =>
                                    handleChange(event, index)
                                  }
                                />
                              </FormControl> */}
                              <FormControl mt={3}>
                                <Select
                                  name="taskTag"
                                  onChange={(event) =>
                                    handleChange(event, index)
                                  }
                                  displayEmpty
                                >
                                  {taskTag.map((item) => {
                                    return (
                                      <MenuItem value={item.value}>
                                        {" "}
                                        {item.key}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell sx={{ minWidth: "200px" }}>
                              {/* <FormControl>
                                <CustomSelect
                                  name="dueDate"
                                  displayEmpty
                                  options={dueDate}
                                  onChange={(event) =>
                                    handleChange(event, index)
                                  }
                                />
                              </FormControl> */}
                              <FormControl mt={3}>
                                <Select
                                  name="dueDate"
                                  onChange={(event) =>
                                    handleChange(event, index)
                                  }
                                  displayEmpty
                                  defaultValue={"0"}
                                >
                                  {dueDate.map((item) => {
                                    return (
                                      <MenuItem value={item.value}>
                                        {" "}
                                        {item.key}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              {index == 0 && (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    setFormData((prevFormData) => {
                                      const newData = [
                                        ...prevFormData.data.slice(
                                          0,
                                          index + 1
                                        ),
                                        {
                                          call_guest: "",
                                          guest_description: "",
                                          task_tag: "",
                                          due_date: "",
                                        },
                                        ...prevFormData.data.slice(index + 1),
                                      ];
                                      return { ...prevFormData, data: newData };
                                    });
                                  }}
                                  sx={{ minWidth: "40px", px: 0 }}
                                >
                                  <AddIcon />
                                </Button>
                              )}
                              {index != 0 && (
                                <Button
                                  color="error"
                                  variant="contained"
                                  onClick={() => handleDelete(index)}
                                  sx={{ minWidth: "40px", px: 0 }}
                                >
                                  <DeleteIcon />
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel>
                    Images Required?{"*"}
                    <Tooltip
                      color="primary"
                      title="If yes, user will be reminded to add images when saving the IR"
                      placement="top"
                      sx={{ mb: "-6px" }}
                    >
                      <ErrorIcon fontSize="small" />
                    </Tooltip>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="contact-corporate-nurse"
                    value={formData.image_required}
                    name="image_required"
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        image_required: event.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                {/* <FormControl>
                  <FormLabel>
                    Boilerplate narrative
                    {
                      <Tooltip
                        color="primary"
                        title="Build consistency in your IR writing by optionally creating a default narrative to be used for each classification. Add {{double curly brackets}} to call attention to words or phrases that officers need to change."
                        placement="bottom-end"
                        sx={{ mb: "-6px" }}
                      >
                        <ErrorIcon fontSize="small" />
                      </Tooltip>
                    }
                  </FormLabel>
                  <Box
                    className={classes.textEditor}
                    sx={{ marginTop: "15px" }}
                  >
                    <MUIRichTextEditor
                      label={
                        <Typography sx={{ padding: "15px" }}>
                          Type something here...
                        </Typography>
                      }
                      //   onSave={save}
                      inlineToolbar={true}
                    />
                  </Box>
                </FormControl> */}
                <FormControl>
                  <FormLabel>
                    Boilerplate Narrative
                    {
                      <Tooltip
                        color="primary"
                        title="Build consistency in your IR writing by optionally creating a default narrative to be used for each classification. Add {{double curly brackets}} to call attention to words or phrases that officers need to change."
                        placement="bottom-end"
                        sx={{ mb: "-6px" }}
                      >
                        <ErrorIcon fontSize="small" />
                      </Tooltip>
                    }
                  </FormLabel>
                  {/* <CustomTextEditor name="desc_of_emergency" /> */}
                  <QuillWrapper>
                    <ReactQuill
                      theme="snow"
                      value={formData.narrative}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          narrative: value,
                        })
                      }
                      placeholder="Type something.."
                    />
                  </QuillWrapper>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button onClick={addIr} color="primary" variant="contained">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Formik>
      </CardContent>
    </Card>
  );
}

function AddIrClassification() {
  return (
    <React.Fragment>
      <Helmet title="Add IR Classification" />
      <Typography variant="h3" gutterBottom display="inline">
        Add IR Classification
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link
          component={NavLink}
          to="/settings/corporate-user/ir-classifications"
        >
          Incident Classifications
        </Link>
        <Typography>Add IR Classification</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddVisitorForm />
    </React.Fragment>
  );
}

export default AddIrClassification;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomSelect from "../../../components/form-components/CustomSelect";
import CustomTextField from "../../../components/form-components/CustomTextField";
import * as Yup from "yup";
import { AddVehicleViolation } from "../../../api";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import hasPermission from "../../../utils/hasPermission";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  FormControl as MuiFormControl,
  Button,
  Grid,
  TextField,
  FormHelperText,
  Paper,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Image = styled.img`
  width: 100%;
  margin-top: 15px;
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const violationType = [
  { key: "Handicap zone", value: "Handicap zone" },
  { key: "Improperly parked", value: "Improperly parked" },
  { key: "No Parking Permit", value: "No Parking Permit" },
  { key: "Payment", value: "Payment" },
  {
    key: "Property damage (oil leaks, etc.)",
    value: "Property damage (oil leaks, etc.)",
  },
  { key: "Taking up 2 spots", value: "Taking up 2 spots" },
  { key: "Towed", value: "Towed" },
  { key: "Unauthorized garage", value: "Unauthorized garage" },
  { key: "Unauthorized space", value: "Unauthorized space" },
  { key: "Unauthorized level", value: "Unauthorized level" },
  { key: "Parked in a disabled space", value: "Parked in a disabled space" },
  {
    key: "Invalid, expired or missing parking permit",
    value: "Invalid, expired or missing parking permit",
  },
  { key: "Parked in a no-parking zone", value: "Parked in a no-parking zone" },
  { key: "Parked in a fire lane", value: "Parked in a fire lane" },
  {
    key: "Unauthorized parking in a reserved parking area",
    value: "Unauthorized parking in a reserved parking area",
  },
  {
    key: "Blocking access to dumpster or trash recepticle",
    value: "Blocking access to dumpster or trash recepticle",
  },
  {
    key: "Blocking entrance to building",
    value: "Blocking entrance to building",
  },
  { key: "Improperly parked", value: "Improperly parked" },
  {
    key: "Vehicle not in an acceptable condition",
    value: "Vehicle not in an acceptable condition",
  },
  { key: "Camping", value: "Camping" },
  { key: "Towed", value: "Towed" },
  { key: "Payment", value: "Payment" },
  { key: "Other", value: "Other" },
];
const AddViolation = ({
  addViolationDialog,
  addViolationDialogClose,
  vehicleId,
  vehicleViolationList,
}) => {
  const [dateTime, setDateTime] = useState(new Date());
  const [postImage, setPostImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPostImage(base64);
  };

  let initialValues = { violation_type: "", image: "", note: "", other: "" };
  const validationSchema = Yup.object().shape({
    violation_type: Yup.string().required("Please select violation type"),
    other: Yup.string().when(["violation_type"], (same, schema) => {
      return same === "Other"
        ? schema.required("Please enter the other")
        : schema;
    }),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setLoading(true);
    try {
      let payload = {
        vehicle_id: vehicleId,
        image: postImage,
        violation_time: format(dateTime, "yyyy-MM-dd HH:mm:ss"),
        type: values.violation_type,
        other_type: values.other,
        note: values.note,
      };
      AddVehicleViolation(vehicleId, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record submitted successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            addViolationDialogClose();
            vehicleViolationList();
            setLoading(false);
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .cathc((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-violation-title"
        aria-describedby="add-violation-desc"
        open={addViolationDialog}
        onClose={addViolationDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "650px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-violation-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add Violation
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addViolationDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <DateTimePicker
                        label="Datetime*"
                        value={dateTime}
                        onChange={(newValue) => {
                          setDateTime(newValue);
                        }}
                        ampm={false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="violation_type"
                        fieldLabel="Violation Type*"
                        options={violationType}
                        displayEmpty
                      />
                    </FormControl>
                    <FormHelperText error>
                      {touched.violation_type && errors.violation_type}
                    </FormHelperText>
                    {values.violation_type == "Other" && (
                      <FormControl mt={4}>
                        <CustomTextField
                          name="other"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Other*"
                          autoComplete="off"
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <TextField
                        name="image"
                        type="file"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Image"
                        autoComplete="off"
                        onChange={(e) => handleFileUpload(e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="note"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Note"
                        autoComplete="off"
                        multiline={true}
                        row={4}
                        placeholder="Document if this is a repeat violation, as well as efforts taken by you to notify the vehicle owner and the owner's response (If any). Also, include contact information for the owner along with their managers name (if they are an employee)."
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{ mr: "5px" }}
                    onClick={addViolationDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!hasPermission("Logs", "vehicleViolationList")}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddViolation;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import { LockerRoomlist, AddLockerLog } from "../../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem,
  FormLabel,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Card,
  Tooltip,
  Box,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  CancelRounded as CancelRoundedIcon,
  Add as AddIcon,
  Error as ErrorIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const AddLocker = ({ addLockerDialog, addLockerDialogClose, lockerList }) => {
  // Room
  const dispatch = useDispatch();
  const [room, setRoom] = React.useState([]);
  const selectRoom = (event) => {
    setRoom(event.target.value);
  };

  const [addMore, setAddMore] = useState([]);
  const handleAddMore = (l, r, c) => {
    if (l != "") {
      let copy = { locker_number: l, locker_room: r, lock_combination: c };
      setAddMore([...addMore, copy]);
    } else {
      dispatch(getSnackOpen());
      dispatch(
        getMsg("Please provide a locker number and select a locker room")
      );
      dispatch(getSnackAlert("error"));
    }
  };

  const handleRemove = (value) => {
    setAddMore(addMore.filter((data, index) => index !== value));
  };

  const roomList = () => {
    LockerRoomlist()
      .then((res) => {
        if (res.statusCode === 200) {
          setRoom(res.data);
        } else {
          setRoom([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    roomList();
  }, []);

  const initialValues = {
    lock_number: "",
    combo: "",
    room: "",
  };
  const validationSchema = Yup.object().shape({
    room: Yup.string().required("Please enter room"),
    // tour_name: Yup.string().required("Please enter tour name"),
    // tour_description: Yup.string().required("Please enter tour description"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let d = {
        locker_number: values.lock_number,
        locker_room: values.room,
        lock_combination: values.combo,
      };
      // setAddMore([...addMore, d]);
      let payload = addMore.length > 0 ? addMore : [d];
      AddLockerLog({ data: payload })
        .then((res) => {
          if (res.statusCode === 200) {
            lockerList();
            addLockerDialogClose();
            dispatch(getSnackOpen());
            dispatch(getMsg("Locker Log submitted successfully"));
            dispatch(getSnackAlert("success"));
          } else {
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="locker-log-title"
        aria-describedby="locker-log-desc"
        open={addLockerDialog}
        onClose={addLockerDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="locker-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add Locker
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addLockerDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add sequential locker number, then select the locker room from the
            dropdown. Next, enter lock combo ONLY if lock is integral to locker.
            If lock is saperate, leave the combo field blank. You can then add
            or assign an existing external lock to this locker form the Locks
            tab instead.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Card variant="outlined" sx={{ borderBottom: 0, my: "15px" }}>
                  <TableContainer>
                    <TableHead>
                      <TableRow>
                        <TableCell>Locker number</TableCell>
                        <TableCell sx={{ minWidth: "180px" }}>Room</TableCell>
                        <TableCell>
                          Combo
                          {/* <Tooltip
                            title="If lock is external, leave field below blank. Then add a lock and assign lock to this locker."
                            placement="top-center"
                            color="primary"
                            sx={{ marginBottom: "-6px" }}
                          >
                            <ErrorIcon />
                          </Tooltip> */}
                        </TableCell>
                        <TableCell>
                          Action
                          {/* <Tooltip
                            title="Click plus to add multiple lockers"
                            placement="top-center"
                            color="primary"
                            sx={{ marginBottom: "-6px" }}
                          >
                            <ErrorIcon />
                          </Tooltip> */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <FormControl>
                            <CustomTextField
                              label="Locker number"
                              id="locker-number"
                              name="lock_number"
                              fullWidth
                              placeholder="001, 002, etc."
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            {/* <Select
                              labelId="department"
                              id="room"
                              value={room}
                              onChange={selectRoom}
                              fullWidth
                            >
                              <MenuItem value="">Select</MenuItem>
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select> */}
                            <CustomSelect
                              name="room"
                              fieldLabel="Room"
                              options={room}
                              displayEmpty
                            />
                            <FormHelperText error>
                              {touched.room && errors.room}
                            </FormHelperText>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            <CustomTextField
                              id="combo"
                              name="combo"
                              fullWidth
                              placeholder="Blank if external"
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <IconButton color="primary">
                            <AddIcon
                              onClick={() => {
                                handleAddMore(
                                  values.lock_number,
                                  values.room,
                                  values.combo
                                );
                                values.lock_number = "";
                                values.room = "";
                                values.combo = "";
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {addMore.map((data, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">
                              <TextField
                                value={data.locker_number}
                                disabled
                                id="lock-number"
                                name="lock_number"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                value={data.locker_room}
                                disabled
                                id="data-combo"
                                name="data_combo"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                value={data.lock_combination}
                                disabled
                                id="data-room"
                                name="data_room"
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton color="primary">
                                <DeleteIcon
                                  onClick={() => handleRemove(index)}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </TableContainer>
                </Card>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={addLockerDialogClose}
                    sx={{ marginRight: "5px" }}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddLocker;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  DialogActions,
  Box,
} from "@mui/material";
import { ViewAssignEquipment } from "../../../api";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const EquipmentCheckIn = ({ equipmentCheckIn, equipmentCheckInClose, id }) => {
  const [assignEquip, setAssignEquip] = useState({});
  const viewAssignEquip = () => {
    ViewAssignEquipment(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setAssignEquip(res.data);
        } else {
          console.log("hello");
        }
      })
      .then((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    viewAssignEquip();
  }, []);

  return (
    <>
      <Dialog
        open={equipmentCheckIn}
        onClose={equipmentCheckInClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        aria-labelledby="equipmentCheckIn-title"
        aria-describedby="equipmentCheckIn-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="equipmentCheckIn-title"
        >
          <Typography variant="h4">Equipment Check In</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={equipmentCheckInClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              <strong>Barcode ID:</strong>{" "}
              {/* {assignEquip
                ? assignEquip.equipment
                  ? assignEquip.equipment.equipment_id
                  : ""
                : ""} */}
              {assignEquip ? assignEquip.barcode_id : ""}
            </Typography>
            <Typography>
              <strong>Equipment Name:</strong>{" "}
              {assignEquip ? assignEquip.name : ""}
            </Typography>
            <Typography>
              <strong>Equipment Type:</strong>{" "}
              {assignEquip ? assignEquip.type : ""}
            </Typography>
            <Typography>
              <strong>Disposition:</strong>{" "}
              {assignEquip ? assignEquip.disposition : ""}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={equipmentCheckInClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EquipmentCheckIn;

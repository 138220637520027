import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
  List,
  ListItem,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Tooltip,
  ButtonGroup,
  Avatar,
  TextareaAutosize as MuiTextareaAutosize,
  CircularProgress,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/lab";
import { format } from "date-fns";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Mail as MailIcon,
  Mail as ErrorIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Cancel as CancelIcon,
  ListAlt as ListAltIcon,
  PictureAsPdf as PictureAsPdfIcon,
  PieChart as PieChartIcon,
  Create as CreateIcon,
  CameraAlt as CameraAltIcon,
  Send as SendIcon,
  SettingsInputComponent as SettingsInputComponentIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DailyActivityRecentData } from "../../../api";
import DetailedLog from "./DetailedLog";
import WithPermissionHide from "../../../utils/withPermissionHide";

const Paper = styled(MuiPaper)(spacing);
// Classes Styling
const useStyles = makeStyles((theme) => ({
  searchFilter: {
    position: "relative",
  },
  clearSearch: {
    position: "absolute",
    right: "45px",
  },
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  FormInquiry: {
    "& .MuiFormControl-root": {
      width: "100%",
      minWidth: "auto",
    },
  },
  ErrorImage: {
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",
    marginBottom: "15px",
  },
  AvatarMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    "& .MuiButtonBase-root": {
      whiteSpace: "nowrap",
    },
  },
  AvatarMediaBody: {
    display: "flex",
    alignItems: "center",
  },
  AvatarImage: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  textAreaControl: {
    position: "relative",
    width: "100%",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
const RecentActivityDialog = (props) => {
  const {
    recentactivities,
    setRecentActivities,
    recentActivitiesClose,
    userIdDialog,
  } = props;
  const [recentActivityList, setRecentActivityList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  // Detailed log modal
  const [modaldetailedlog, setModalDetailedLog] = useState(false);
  const modalDetailedLogOpen = () => {
    setModalDetailedLog(true);
  };
  const modalDetailedLogClose = () => {
    setModalDetailedLog(false);
  };

  const classes = useStyles();

  const getRecentActivity = () => {
    setDialogLoader(true);
    DailyActivityRecentData(userIdDialog)
      .then((res) => {
        setDialogLoader(false);
        setUserInfo(res.data.user);
        setRecentActivityList(res.data.UserActivity);
        // if (res.statusCode == 200) {
        //   setMissedTourButtonList(res.data);
        //   setTotalTourCount(res.tour_run_stats_count);
        // } else {
        //   setMissedTourButtonList(res.data);
        // }
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getRecentActivity();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        open={recentactivities}
        onClose={recentActivitiesClose}
        aria-labelledby="activities-title"
        aria-describedby="activities-description"
      >
        <DialogTitle
          id="activities-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Recent Activities
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setRecentActivities(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Box className={classes.AvatarMedia}>
            <Paper className={classes.AvatarMediaBody}>
              <Avatar
                alt={userInfo.UserName}
                className={classes.AvatarImage}
                src={userInfo.profile_image}
              ></Avatar>
              <Typography variant="span" component="h4">
                {userInfo.UserName}
              </Typography>
            </Paper>
            <WithPermissionHide controller="Reports" action="get_usr_activity">
              <Button
                color="primary"
                variant="contained"
                onClick={modalDetailedLogOpen}
              >
                Detailed Log
              </Button>
            </WithPermissionHide>
          </Box>
          {modaldetailedlog && (
            <DetailedLog
              modalDetailedLogOpen={modalDetailedLogOpen}
              modalDetailedLogClose={modalDetailedLogClose}
              modaldetailedlog={modaldetailedlog}
              setModalDetailedLog={setModalDetailedLog}
              userIdDialog={userIdDialog}
            />
          )}

          <Paper mt={3}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Activity Time</TableCell>
                    <TableCell>Activity Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogLoader ? (
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <>
                      {recentActivityList.map((item, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell>{item.activity_time}</TableCell>
                            <TableCell>{item.activity_type}</TableCell>
                          </TableRow>
                        );
                      })}
                      {recentActivityList.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={5}>
                            No records found
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}

                  {/* <TableRow hover>
                    <TableCell>2022/05/24 11:41:02</TableCell>
                    <TableCell>Non Tour Task Completed Code - 1</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>2022/05/24 11:41:02</TableCell>
                    <TableCell>Non Tour Task Completed Code - 1</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>2022/05/24 11:41:02</TableCell>
                    <TableCell>Non Tour Task Completed Code - 1</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>2022/05/24 11:41:02</TableCell>
                    <TableCell>Non Tour Task Completed Code - 1</TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={recentActivitiesClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecentActivityDialog;

import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const CustomProgressBar = ({ value }) => {
  // Linear Progressbar
  //   const [progress, setProgress] = React.useState(0);
  //   //const [value, setTimeValue] = React.useState(null);
  //   React.useEffect(() => {
  //     const timer = setInterval(() => {
  //       setProgress((oldProgress) => {
  //         if (oldProgress === 100) {
  //           return 0;
  //         }
  //         const diff = Math.random() * 10;
  //         return Math.min(oldProgress + diff, 100);
  //       });
  //     }, 500);

  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }, []);
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1, mb: 4 }}>
          <LinearProgress
            variant="determinate"
            value={value}
            color="success"
            sx={{ height: "20px" }}
          />
          {/* <LinearProgress variant="determinate" {...props} /> */}
        </Box>
        {/* <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box> */}
      </Box>
    </>
  );
};

export default CustomProgressBar;

import React from "react";
import zxcvbn from "zxcvbn";
import FormHelperText from "@mui/material/FormHelperText";

const PasswordStrength = ({ password }) => {
  const testResult = zxcvbn(password);
  const createPassLabel = () => {
    switch (testResult.score) {
      case 0:
        return "0%";
      case 1:
        return "30%";
      case 2:
        return "50%";
      case 3:
        return "75%";
      case 4:
        return "100%";
      default:
        return "";
    }
  };

  // const funcProgressColor = () => {
  //   switch (testResult.score) {
  //     case 0:
  //       return "#828282";
  //     case 1:
  //       return "#EA1111";
  //     case 2:
  //       return "#FFAD00";
  //     case 3:
  //       return "#9bc158";
  //     case 4:
  //       return "#00b500";
  //     default:
  //       return "none";
  //   }
  // };

  // const changePasswordColor = () => ({
  //   // backgroundColor: "red",
  //   backgroundColor: funcProgressColor(),
  //   // height: "7px",
  //   "& .MuiLinearProgress-bar1Determinate": {
  //     background: "#000",
  //   },
  // });

  return (
    <>
      {/* <BorderLinearProgress
        variant="determinate"
        value={num}
        sx={{ marginTop: "15px" }}
      /> */}
      {/* <div
        className="progress"
        style={{ height: "7px", margin: "12px 0px 3px" }}
      >
        <div className="progress-bar" style={changePasswordColor()}></div>
      </div> */}
      {/* <p style={{ fontSize: "small", color: "#758A9B", marginBottom: "0px" }}>
        Key complexity : {createPassLabel()}
      </p> */}
      <FormHelperText>Password complexity:{createPassLabel()}</FormHelperText>
    </>
  );
};

export default PasswordStrength;

//color: funcProgressColor()

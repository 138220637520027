import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checked: [],
  snackStatus: false,
  snackMsg: "",
  snackAlert: "",
};

export const floorPlanSlice = createSlice({
  name: "floorPlan",
  initialState,
  reducers: {
    getChecked: (state, action) => {
      state.checked = action.payload;
    },
    getSnackOpen: (state) => {
      state.snackStatus = true;
    },
    getSnackClose: (state) => {
      state.snackStatus = false;
    },
    getSnackMsg: (state, action) => {
      state.snackMsg = action.payload;
    },
    getSnackAlert: (state, action) => {
      state.snackAlert = action.payload;
    },
  },
});

export const {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} = floorPlanSlice.actions;
export default floorPlanSlice.reducer;

import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
// ImageWrapper
const ImageWrapper = styled.div`
  height: 100px;
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PoiPeek = ({ peek, peekClose, singleRecord }) => {
  return (
    <>
      <Dialog
        open={peek}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
        aria-labelledby="peak-title"
        aria-describedby="peak-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="peak-title"
        >
          <Typography variant="h4" component="div">
            Peek
          </Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={peekClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          {singleRecord.image != null ? (
            <ImageWrapper>
              <Image
                sx={{ width: "100%" }}
                component="img"
                alt="Guage Chart"
                src={singleRecord.image}
              />
            </ImageWrapper>
          ) : null}
          <Typography>
            <strong>Encounters:</strong>
            {singleRecord?.enc_date.map((d, i) => d.encounter_date).join(" ,")}
          </Typography>
          <Typography>
            <strong>POI Notes:</strong>{" "}
            {singleRecord?.notes
              ? singleRecord?.notes.replace(/<[^>]+>/g, "")
              : "No notes have been added for this POI"}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PoiPeek;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

const PeekDialog = (props) => {
  const { peekModal, setPeekModal, peekModalOpen, peekModalClose, recordObj } =
    props;
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={peekModal}
        onClose={peekModalClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "200",
              marginLeft: "auto",
            }}
          >
            Severity scale is not calculated
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setPeekModal(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4" gutterBottom mt={2}>
            Employee
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} ml={5}>
            <strong>Name:</strong> {recordObj?.name}
          </Typography>
          <Typography variant="body2" gutterBottom ml={5}>
            <strong>Department:</strong> {recordObj?.emp_department_name}
          </Typography>
          <Typography variant="body2" gutterBottom ml={5}>
            <strong>Name of supervisor:</strong> {recordObj?.manager}
          </Typography>
          {recordObj?.full_desc_injury && (
            <Typography variant="body2" gutterBottom ml={5}>
              <strong>Details of Incident: </strong>{" "}
              {recordObj?.full_desc_injury}
            </Typography>
          )}
          {recordObj?.doctor_called == "Y" && (
            <Typography variant="body2" gutterBottom ml={5}>
              <strong>Medical Attention received from: </strong>{" "}
              {recordObj?.doctor_name}
            </Typography>
          )}
          <Typography variant="h4" gutterBottom>
            Manager
          </Typography>
          {recordObj?.mgr == "Y" && (
            <Box>
              <Typography variant="body2" gutterBottom mt={3} ml={5}>
                <strong>Written by:</strong> {recordObj?.written_by}
              </Typography>
              <Typography variant="body2" gutterBottom ml={5}>
                <strong>Department:</strong> {recordObj?.mgr_department_name}
              </Typography>
              <Typography variant="body2" gutterBottom ml={5}>
                <strong>Position:</strong> {recordObj?.written_title}
              </Typography>
              <Typography variant="body2" gutterBottom ml={5}>
                <strong>Manager description of incident:</strong>{" "}
                {recordObj?.full_desc}
              </Typography>
              <Typography variant="body2" gutterBottom ml={5}>
                <strong>preventive action plan:</strong>{" "}
                {recordObj?.ear_preventive}
              </Typography>
              <Typography variant="body2" gutterBottom ml={5}>
                <strong>Remedial action completed:</strong> 2
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button variant="outlined" color="primary" onClick={peekModalClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PeekDialog;

import React, { useState, useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import CustomTextField from "../../components/form-components/CustomTextField";
import { ViewCovidSurveyInfo } from "../../api";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  FormGroup,
  FormControlLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Switch,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function ViewCovid19SurveyForm(props) {
  const { id } = useParams(); //Getting the edit user Id
  const [viewData, setViewData] = useState({});
  const [surveyLoader, setSurveyLoader] = useState(false);
  const classes = useStyles();
  const passcode = useSelector((state) => state.main.unmasked);
  let initialValues = {
    first_name: viewData
      ? viewData.first_name
        ? viewData.first_name
        : ""
      : "",
    last_name: viewData ? (viewData.last_name ? viewData.last_name : "") : "",
    company: viewData ? (viewData.company ? viewData.company : "") : "",
    cough: viewData ? (viewData.cough ? viewData.cough : 0) : 0,
    breath_difficulty: viewData
      ? viewData.breath_difficulty
        ? viewData.breath_difficulty
        : 0
      : 0,
    fever: viewData ? (viewData.fever ? viewData.fever : 0) : 0,
    chills: viewData ? (viewData.chills ? viewData.chills : 0) : 0,
    repeated_chills: viewData
      ? viewData.repeated_chills
        ? viewData.repeated_chills
        : 0
      : 0,
    muscle_pain: viewData
      ? viewData.muscle_pain
        ? viewData.muscle_pain
        : 0
      : 0,
    headache: viewData ? (viewData.headache ? viewData.headache : 0) : 0,
    sore_throat: viewData
      ? viewData.sore_throat
        ? viewData.sore_throat
        : 0
      : 0,
    taste_smell_loss: viewData
      ? viewData.taste_smell_loss
        ? viewData.taste_smell_loss
        : 0
      : 0,
    waiting_test_result: viewData
      ? viewData.waiting_test_result
        ? viewData.waiting_test_result
        : 0
      : 0,
    tested_positive: viewData
      ? viewData.tested_positive
        ? viewData.tested_positive
        : 0
      : 0,
    self_quarantined: viewData
      ? viewData.self_quarantined
        ? viewData.self_quarantined
        : 0
      : 0,
    exposed_other: viewData
      ? viewData.exposed_other
        ? viewData.exposed_other
        : 0
      : 0,
    exposed_positive_tested: viewData
      ? viewData.exposed_positive_tested
        ? viewData.exposed_positive_tested
        : 0
      : 0,
    exposed_cdc: viewData
      ? viewData.exposed_cdc
        ? viewData.exposed_cdc
        : 0
      : 0,
    travelled_outside: viewData
      ? viewData.travelled_outside
        ? viewData.travelled_outside
        : 0
      : 0,
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter firstname"),
    last_name: Yup.string().required("Please enter lastname"),
    company: Yup.string().required("Please enter company name"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      // setVehicleLoader(true);
      // let formData = {
      //   id: vehicleData ? (vehicleData.id ? vehicleData.id : null) : null,
      //   incident_id: incidentId,
      //   year: values.year,
      //   make: values.make,
      //   model: values.model,
      //   vin_number: values.vin_number,
      //   color: values.color,
      //   plate: values.plate,
      //   prior_damage: values.prior_damage,
      //   attended: values.attended,
      //   state: values.state,
      //   driver_name: values.driver_name,
      //   deposition: values.deposition,
      //   reason: values.reason,
      //   damaged: values.damaged,
      //   v_image: canvasImg,
      //   citation_ticket: values.citation_ticket,
      //   driver_owner: values.driver_owner,
      //   got_emergency_doctor: values.got_emergency_doctor,
      //   emergency_doctor_detail: values.emergency_doctor_detail,
      //   property_damaged: values.property_damaged,
      //   property_damaged_detail: values.property_damaged_detail,
      //   driven_able: values.driven_able,
      //   driven_able_detail: values.driven_able_detail,
      //   airbag_deploy: values.airbag_deploy,
      //   airbag_deploy_detail: values.airbag_deploy_detail,
      //   car_seats_installed: values.car_seats_installed,
      //   driver_address: values.driver_address,
      //   driver_city: values.driver_city,
      //   driver_state: values.driver_state,
      //   driver_zip: values.driver_zip,
      //   driver_phone: values.driver_phone,
      //   driver_relation: values.driver_relation,
      //   vehicle_claimant: values.vehicle_claimant,
      //   passengers: values.passengers,
      //   passengers_data: values.passengers_data,
      //   insurance_company: values.insurance_company,
      //   policy_number: values.policy_number,
      //   claim_number: values.claim_number,
      //   notes: values.notes,
      // };
      // SaveInvolvedVehicle(formData)
      //   .then((res) => {
      //     setVehicleLoader(false);
      //     if (res.statusCode === 200) {
      //       getInvolvedVehicleListing();
      //       dispatch(getSnackMsg(res.message));
      //       dispatch(getSnackAlert("success"));
      //       dispatch(getSnackOpen());
      //       vehicleInvolvedClose();
      //       setTimeout(() => {
      //         dispatch(getSnackClose());
      //       }, 3000);
      //     } else {
      //       dispatch(getSnackMsg("Something went wrong, please try again"));
      //       dispatch(getSnackAlert("error"));
      //       dispatch(getSnackOpen());
      //       setTimeout(() => {
      //         dispatch(getSnackClose());
      //       }, 3000);
      //     }
      //   })
      // .catch((err) => {
      //   setVehicleLoader(false);
      //   console.log(err);
      //   dispatch(getSnackMsg("Something went wrong, please try again"));
      //   dispatch(getSnackAlert("error"));
      //   dispatch(getSnackOpen());
      //   setTimeout(() => {
      //     dispatch(getSnackClose());
      //   }, 3000);
      // });
      // await
      //   timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      // setVehicleLoader(false);
      console.log(error);
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  // API call for getting the editable data of the user
  const getEditList = () => {
    setSurveyLoader(true);
    ViewCovidSurveyInfo(id)
      .then((res) => {
        if (res.statusCode == 200) {
          setViewData(res.data);
        }
        setSurveyLoader(false);
      })
      .catch((err) => {
        setSurveyLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getEditList();
  }, []);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography>
          <strong>Datetime:</strong> {viewData.date_time}
        </Typography>
        <Typography>
          <strong>Barcode ID:</strong> {viewData.barcode_id}
        </Typography>
        <Typography>
          <strong>Temperature:</strong> {viewData.temp}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setFieldValue,
          }) => (
            <form>
              <Grid
                container
                spacing={6}
                sx={{
                  "& span.MuiFormControlLabel-label.Mui-disabled, label.MuiFormLabel-root":
                    {
                      color: "#000000 !important",
                    },
                }}
              >
                <Grid item xs={12} md={4}>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="first_name"
                      label="First name *"
                      autoComplete="off"
                      id="f-name"
                      disabled
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: true }}
                      type={passcode ? "text" : "password"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="last_name"
                      label="Last name *"
                      autoComplete="off"
                      id="f-name"
                      disabled
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: true }}
                      type={passcode ? "text" : "password"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl mt={6}>
                    <CustomTextField
                      label="Company*"
                      autoComplete="off"
                      name="company"
                      id="company"
                      defaultValue="SOVA systems"
                      disabled
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      type={passcode ? "text" : "password"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" component="div" gutterBottom mt={6}>
                    Are you experiencing any of the following COVID-19 symptoms
                    or combination of symptoms:
                  </Typography>
                  <Divider my={6} />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Cough"
                          control={
                            <CustomSwitchYesNo
                              name="cough"
                              checked={values.cough === 1}
                              onChange={(event, checked) => {
                                setFieldValue("cough", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Shortness of breathe or difficulty breathing"
                          control={
                            <CustomSwitchYesNo
                              name="breath_difficulty"
                              checked={values.breath_difficulty === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "breath_difficulty",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="h6"
                        component="div"
                        gutterBottom
                        mt={6}
                      >
                        Or at least two of these symptoms:
                      </Typography>
                      <Divider my={6} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Fever(100.4 degrees or higher)"
                          control={
                            <CustomSwitchYesNo
                              name="fever"
                              checked={values.fever === 1}
                              onChange={(event, checked) => {
                                setFieldValue("fever", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Chills"
                          control={
                            <CustomSwitchYesNo
                              name="chills"
                              checked={values.chills === 1}
                              onChange={(event, checked) => {
                                setFieldValue("chills", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Repeated shaking with chills"
                          control={
                            <CustomSwitchYesNo
                              name="repeated_chills"
                              checked={values.repeated_chills === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "repeated_chills",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Muscle pain"
                          control={
                            <CustomSwitchYesNo
                              name="muscle_pain"
                              checked={values.muscle_pain === 1}
                              onChange={(event, checked) => {
                                setFieldValue("muscle_pain", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Headache"
                          control={
                            <CustomSwitchYesNo
                              name="headache"
                              checked={values.headache === 1}
                              onChange={(event, checked) => {
                                setFieldValue("headache", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Sore throat"
                          control={
                            <CustomSwitchYesNo
                              name="sore_throat"
                              checked={values.sore_throat === 1}
                              onChange={(event, checked) => {
                                setFieldValue("sore_throat", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="New loss of taste or smell"
                          control={
                            <CustomSwitchYesNo
                              name="taste_smell_loss"
                              checked={values.taste_smell_loss === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "taste_smell_loss",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="h6"
                        component="div"
                        gutterBottom
                        mt={6}
                      >
                        Additional questions:
                      </Typography>
                      <Divider my={6} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Are you currently waiting for COVID-19 test results?"
                          control={
                            <CustomSwitchYesNo
                              name="waiting_test_result"
                              checked={values.waiting_test_result === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "waiting_test_result",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Have you tested positive for COVID-19?"
                          control={
                            <CustomSwitchYesNo
                              name="tested_positive"
                              checked={values.tested_positive === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "tested_positive",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Have you self-quarantined? (remaining in your home and outdoor activities without coming closer than 6-feet from others)?"
                          control={
                            <CustomSwitchYesNo
                              name="self_quarantined"
                              checked={values.self_quarantined === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "self_quarantined",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Have you been exposed to anyone currently waiting for COVID-19 test results?"
                          control={
                            <CustomSwitchYesNo
                              name="exposed_other"
                              checked={values.exposed_other === 1}
                              onChange={(event, checked) => {
                                setFieldValue("exposed_other", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Have you been exposed to anyone who has tested positive for COVID-19 within the past 14 days?"
                          control={
                            <CustomSwitchYesNo
                              name="exposed_positive_tested"
                              checked={values.exposed_positive_tested === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "exposed_positive_tested",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Have you been exposed to anyone with any of the CDC specified symptoms (see above)?"
                          control={
                            <CustomSwitchYesNo
                              name="exposed_cdc"
                              checked={values.exposed_cdc === 1}
                              onChange={(event, checked) => {
                                setFieldValue("exposed_cdc", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CustomSwitchControl>
                        <FormControlLabel
                          disabled
                          label="Have you traveled outside your state or regional area?"
                          control={
                            <CustomSwitchYesNo
                              name="travelled_outside"
                              checked={values.travelled_outside === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "travelled_outside",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <Button color="primary" variant="contained">
                    Save
                  </Button>
                </Grid> */}
              </Grid>
            </form>
          )}
        </Formik>
      </CardContent>
      {surveyLoader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </Card>
  );
}

function ViewCovid19Survey() {
  return (
    <React.Fragment>
      <Helmet title="View Covid-19 Survey" />
      <Typography variant="h3" gutterBottom display="inline">
        View Covid-19 Survey
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/covid19-survey">
          Covid-19 Survey
        </Link>
        <Typography>View Covid-19 Survey</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <ViewCovid19SurveyForm />
    </React.Fragment>
  );
}

export default ViewCovid19Survey;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  Chip,
  Box,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const Editlocked = ({
  editLockedDialog,
  editLockedDialogClose,
  recordData,
}) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <Dialog
        aria-labelledby="lift-out-title-modal"
        aria-describedby="lift-out-modal"
        open={editLockedDialog}
        onClose={editLockedDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#E8E8E8",
          }}
          id="lift-out-title"
        >
          <Box>
            <Typography variant="h4" component="div" gutterBottom>
              Locked Record
            </Typography>{" "}
            <Typography>
              This record has been locked to prevent editing. Details about the
              lock can be found below.
            </Typography>
          </Box>

          <DialogActions>
            <IconButton
              onClick={editLockedDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <Typography sx={{ padding: "5px 20px", marginTop: "20px" }}>
          <strong>Reason For Lock:</strong> {recordData.locked_item_reason}
        </Typography>
        <Typography sx={{ padding: "5px 20px" }}>
          <strong>Locked By:</strong>{" "}
          {`${userData.first_name} ${userData.last_name}`}
        </Typography>
        <Typography sx={{ padding: "5px 20px " }}>
          <strong>Email:</strong> {userData.Email}
        </Typography>
        <DialogContent>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}></Box>
        </DialogContent>
        <DialogActions sx={{ pr: "28px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={editLockedDialogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Editlocked;

import React from "react";
import {
  Typography,
  Button,
  Box,
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

const ModuleColor = () => {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Module Color
      </Typography>
      <Box mt={7}>
        <Button variant="contained">Search</Button>
      </Box>
      <Card
        sx={{
          marginTop: "25px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Module</TableCell>
              <TableCell align="right">Color</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Card>
    </>
  );
};

export default ModuleColor;

export default [
  {
    id: "TagDescription",
    label: "Button Name",
  },
  {
    id: "location_hint",
    label: "Location Hint",
  },
  {
    id: "image",
    label: "Image",
  },
  {
    id: "critical",
    label: "Critical",
  },
  {
    id: "tasks",
    label: "Tasks",
  },
  {
    id: "task_schedules",
    label: "Task Schedules",
  },
  {
    id: "timing",
    label: "Timing",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../components/form-components/CustomTextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  FormControl as MuiFormControl,
  Grid,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../helper/helper";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AddEditPropertyArea } from "../../api";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const AddPropertyArea = ({
  addPropertyAreaDialog,
  addPropertyAreaDialogClose,
  setPageLoad,
  getPropertyAreaList,
  editData,
}) => {
  const dispatch = useDispatch();
  let initialValues = {
    name: editData ? (editData.name ? editData.name : "") : "",
    description: editData
      ? editData.description
        ? editData.description
        : ""
      : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter details"),
    description: Yup.string().required("Please enter details"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formData = {
        id: editData ? (editData.id ? editData.id : null) : null,
        name: values.name,
        description: values.description,
      };
      AddEditPropertyArea(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            getPropertyAreaList();
            dispatch(
              getMsg(
                editData.id
                  ? "Area updated successfully"
                  : "Area added successfully"
              )
            );
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            addPropertyAreaDialogClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } catch (err) {
      setPageLoad(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="add-property-area-title"
        aria-describedby="add-property-area-desc"
        open={addPropertyAreaDialog}
        onClose={addPropertyAreaDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "650px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-property-area-title"
        >
          {editData.id ? (
            <Typography variant="h4" component="div" gutterBottom>
              Edit Property Area
            </Typography>
          ) : (
            <Typography variant="h4" component="div" gutterBottom>
              Add Property Area
            </Typography>
          )}

          <DialogActions>
            <IconButton
              onClick={addPropertyAreaDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Name *"
                        autoComplete="off"
                        placeholder="Major area, department, etc."
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="description"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Description *"
                        autoComplete="off"
                        placeholder="Add notes to define the property area"
                        rows={5}
                        multiline={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={addPropertyAreaDialogClose}
                    sx={{ marginRight: "5px" }}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPropertyArea;

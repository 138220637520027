import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PoiWatch from "./inner-component/PoiWatch";
import { GetPoiEncounterList, PrintEncounterReport } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import PoiEncounterListCol from "../table-columns-json/poi/PoiEncounterList";
import { setPoiEncounterList } from "../../redux/slices/poi/poiTableColSlice";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Cancel as CancelIcon, Add as AddIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  TrendingDown as TrendingDownIcon,
  PictureAsPdf as PictureAsPdfIcon,
  RateReview as RateReviewIcon,
  Map as MapIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import CustomPagination from "../../components/table-components/CustomPagination";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import RequireReview from "./inner-component/RequireReview";
import EncounterMap from "./inner-component/EncounterMap";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
// const useStyles = makeStyles((theme) => ({
//   alphabets: {
//     marginTop: "15px",
//     "& .MuiList-root": {
//       display: "flex",
//       overflowX: "auto",
//       background: "#7aa8ff0d",
//       padding: "0px",
//       "& .MuiListItemButton-root": {
//         paddingLeft: "13px",
//         paddingRight: "13px",
//         textAlign: "center",
//       },
//     },
//     "& .MuiList-root::-webkit-scrollbar": {
//       width: "5px",
//       height: "5px",
//     },
//     "& .MuiList-root::-webkit-scrollbar-track": {
//       backgroundColor: "#bed2f561",
//     },
//     "& .MuiList-root::-webkit-scrollbar-thumb": {
//       backgroundColor: "#92a6c96e",
//     },
//   },
// }));

// const TableWrapper = styled.div`
//   overflow-y: auto;
//   max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
// `;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  height: 100px;
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  tl,
  id,
  reportingLoc,
  gender,
  alias,
  count,
  narrative,
  image,
  datetime,
  action
) {
  return {
    tl,
    id,
    reportingLoc,
    gender,
    alias,
    count,
    narrative,
    image,
    datetime,
    action,
  };
}
const rows = [
  createData(
    "5",
    "141234",
    "",
    "Female",
    "kundu-Tody",
    0,
    "Resolved without issues",
    0,
    "01/21/2022 00:09:08",
    0
  ),
  createData(
    "5",
    "141267",
    "",
    "Female",
    "kundu-Tody",
    0,
    "Resolved without issues",
    0,
    "01/21/2022 00:09:08",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "tl",
    numeric: false,
    disablePadding: true,
    label: "TL",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "POI ID",
  },
  {
    id: "reportingLoc",
    numeric: true,
    disablePadding: false,
    label: "Reporting Location",
  },
  {
    id: "gender",
    numeric: true,
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "alias",
    numeric: true,
    disablePadding: false,
    label: "Alias",
  },
  {
    id: "count",
    numeric: true,
    disablePadding: false,
    label: "Count",
  },
  {
    id: "narrative",
    numeric: true,
    disablePadding: false,
    label: "Narrative",
  },
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Image",
  },
  {
    id: "datetime",
    numeric: true,
    disablePadding: false,
    label: "Datetime",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell> */}
        {PoiEncounterListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "left" : "right"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "reporting_location" ||
                headCell.id == "count" ||
                headCell.id == "image" ||
                headCell.id == "action"
                  ? null
                  : createSortHandler(headCell.id)
              }
              hideSortIcon={
                headCell.id == "reporting_location" ||
                headCell.id == "count" ||
                headCell.id == "image" ||
                headCell.id == "action"
                  ? true
                  : false
              }
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setDataToFilter,
    tableCalRedux,
    rowsPerPage,
    order,
    orderBy,
    dataToFilter,
  } = props;
  const [actions, setActions] = React.useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fadebox, setFadedBox] = useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [alias, setAlias] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultPoiTableCol.poiEncounterList
  );
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);
  //Select
  const [threatLevel, setThreatLevel] = React.useState(null);
  const selectThreatLevel = (event) => {
    setThreatLevel(event.target.value);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //Scan Visitor ID modal
  const [encounterMap, setEncounterMap] = React.useState(false);
  const encounterMapOpen = () => {
    setEncounterMap(true);
  };
  const encounterMapClose = () => {
    setEncounterMap(false);
  };

  let threatValue = [
    { key: "<4", value: [1, 2, 3] },
    { key: "4-5", value: [4, 5] },
    { key: "6-7", value: [6, 7] },
    { key: ">8", value: [8, 9, 10] },
  ];

  /**
   * It takes the values of the form fields and sets them to the dataToFilter state
   */
  const applySearch = () => {
    const payload = {
      search: searchValue,
      start_encounter_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_encounter_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      alias: alias,
      threat_level: threatLevel?.split(","),
    };
    setDataToFilter(payload);
  };

  /**
   * It resets the state of the filter form to its default values
   */
  const resetFilter = () => {
    setSearchValue("");
    setThreatLevel(null);
    setStartDateToFilter(null);
    setEndDateToFilter(null);
    setAlias("");
  };

  const onButtonClick = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    PrintEncounterReport(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-POI_ENCOUNTERS.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Toolbar>
      {/* <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"}>Delete</MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div> */}
      <div className="totalRecord">
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content", marginLeft: "15px" }}
        >
          {totalRecord} records found
        </Typography>
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search by ID, name or alias or click down arrow"
              inputProps={{}}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form>
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Encounter Dates"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM-DD-YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM-DD-YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Click to select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              required
                              id="threat-level"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Threat level
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="threat-level"
                              value={threatLevel}
                              onChange={selectThreatLevel}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">
                                --Select threat level--
                              </MenuItem>
                              {threatValue.map((d) => {
                                return (
                                  <MenuItem
                                    value={d.value.toString()}
                                    key={d.key}
                                  >
                                    {d.key}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="alias"
                              label="Alias"
                              value={alias}
                              onChange={(e) => setAlias(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Nickname given to the POI"
                            />
                          </FormControl>
                          <FormControl>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              sx={{ width: "175px", marginRight: "5px" }}
                              onClick={(e) => {
                                handleCloseForm();
                                applySearch();
                                setIsFilter(true);
                              }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  setIsFilter(false);
                  resetFilter();
                  setDataToFilter(null);
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                  width: "100%",
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchValue !== "") {
                    applySearch();
                    setIsFilter(true);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={encounterMapOpen}>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Encounter Map" pl={0} />
            </MenuItem>
            <MenuItem component={NavLink} to="/poi/poi-list">
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="List POI" pl={0} />
            </MenuItem>
            <MenuItem onClick={onButtonClick}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Download PDF" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setPoiEncounterList(data));
                    }}
                    listing={PoiEncounterListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setPoiEncounterList(defaultTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
          {encounterMap && (
            <EncounterMap
              encounterMap={encounterMap}
              setEncounterMap={setEncounterMap}
              encounterMapOpen={encounterMapOpen}
              encounterMapClose={encounterMapClose}
            />
          )}
          {loading && (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          )}
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("DateTime");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [poiEncounterData, setPoiEncounterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState("");
  const [reviewReq, setReviewReq] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const tableCalRedux = useSelector(
    (state) => state.poiTableCol.poiEncounterList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = poiEncounterData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id, review) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    setReviewReq(review);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  //Date Time Picker
  // const [value, setValue] = React.useState(null);
  // const [startdate, setStartDate] = React.useState(null);
  // const [enddate, setEndDate] = React.useState(null);

  // const classes = useStyles();
  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const [reviewDialog, setReviewDialog] = useState(false);
  const reviewDialogOpen = () => {
    setReviewDialog(true);
  };
  const reviewDialogClose = () => {
    setReviewDialog(false);
  };

  const poiEncounterList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    GetPoiEncounterList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setPoiEncounterData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setPoiEncounterData([]);
          setLoading(false);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  // const deleteVisitorCompany = () => {
  //   DeleteVisitorCompany(id)
  //     .then((res) => {
  //       if (res.statusCode === 200) {
  //         visitorCompanyList();
  //         handleDeleteClose();
  //         dispatch(getMsg(`A total of 1 record(s) have been deleted`));
  //         dispatch(getSnackAlert("success"));
  //         dispatch(getSnackOpen());
  //       } else {
  //         dispatch(getMsg("Something went wrong, please try again later"));
  //         dispatch(getSnackAlert("error"));
  //         dispatch(getSnackOpen());
  //       }
  //     })
  //     .catch((err) => {
  //       dispatch(getMsg("Something went wrong, please try again later"));
  //       dispatch(getSnackAlert("error"));
  //       dispatch(getSnackOpen());
  //     });
  // };
  useEffect(() => {
    poiEncounterList();
  }, [rowsPerPage, currentPage, dataToFilter, order, orderBy]);

  const [encounterImage, setEncounterImage] = useState(false);
  const [encImg, setEncImg] = useState("");
  const encounterImageOpen = () => {
    setEncounterImage(true);
  };
  const encounterImageClose = () => {
    setEncounterImage(false);
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setDataToFilter={setDataToFilter}
          tableCalRedux={tableCalRedux}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
          dataToFilter={dataToFilter}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={poiEncounterData.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(poiEncounterData, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{
                            lg: 'whiteSpace: "nowrap"',
                            fontSize: "34px",
                            fontWeight: "bolder",
                            WebkitTextStrokeWidth: "0.4px",
                            WebkitTextStrokeColor: "#888",
                            color:
                              row.threat_level <= 3
                                ? "#3f9c24"
                                : row.threat_level == 4 || row.threat_level == 5
                                ? "#aed319"
                                : row.threat_level == 6 || row.threat_level == 7
                                ? "#d37f19"
                                : "#d31e19",
                          }}
                          className={
                            tableCalRedux.threat_level === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.threat_level}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.poi_id === true ? "" : "hide-cell"
                          }
                        >
                          {row.poi_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.reporting_location === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.reporting_location}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.gender === true ? "" : "hide-cell"
                          }
                        >
                          {row.gender == "M"
                            ? "Male"
                            : row.gender == "F"
                            ? "Female"
                            : "Undetermined"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.alias === true ? "" : "hide-cell"
                          }
                        >
                          {row.alias}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.count === true ? "" : "hide-cell"
                          }
                        >
                          {row.count}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.narrative === true ? "" : "hide-cell"
                          }
                        >
                          {row.narrative.replace(/<[^>]+>/g, "")}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.image === true ? "" : "hide-cell"
                          }
                        >
                          {row.image ? (
                            <ThumbnailTableWrapper>
                              <Image
                                sx={{ width: "100%" }}
                                component="img"
                                alt=""
                                src={row.image}
                                onClick={() => {
                                  setEncImg(row.image);
                                  encounterImageOpen();
                                }}
                              />
                            </ThumbnailTableWrapper>
                          ) : null}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.date_time === true ? "" : "hide-cell"
                          }
                        >
                          {row.encounter_date}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) =>
                              handleActionClick(e, row.id, row.review_request)
                            }
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              component={NavLink}
                              to={`/poi/view-poi-encounter/${id}`}
                            >
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText primary="View" pl={1} />
                            </MenuItem>
                            <MenuItem
                              component={NavLink}
                              to={`/poi/edit-poi-encounter/${id}`}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                reviewDialogOpen();
                                handleActionClose();
                              }}
                            >
                              <ListItemIcon>
                                <RateReviewIcon />
                              </ListItemIcon>
                              <ListItemText primary="Require Review" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {poiEncounterData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecord}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />

      {reviewDialog && (
        <RequireReview
          reviewDialog={reviewDialog}
          reviewDialogClose={reviewDialogClose}
          id={id}
          reviewReq={reviewReq}
          poiEncounterList={poiEncounterList}
        />
      )}
      {encounterImage && (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",
              },
            },
          }}
          open={encounterImage}
          onClose={encounterImageClose}
          aria-labelledby="viewmap-title"
          aria-describedby="viewmap-description"
        >
          <DialogContent>
            <Box>
              <Image src={encImg} alt={encImg} />
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: "20px 24px" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={encounterImageClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

function PoiEncounters() {
  return (
    <React.Fragment>
      <Helmet title="POI Encounters" />
      <Typography variant="h3" gutterBottom display="inline">
        POI Encounters
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PoiEncounters;

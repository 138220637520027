import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
const TermsAndConditions = ({ termAndConditions, termAndConditionClose }) => {
  return (
    <>
      <Dialog
        open={termAndConditions}
        aria-labelledby="terms-conditions-title"
        aria-describedby="terms-conditions-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600",
            },
          },
        }}
      >
        <DialogTitle
          id="terms-conditions-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Third Party Insurance
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton onClick={termAndConditionClose}>
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Shipping insirance is provided by third party text goes here.....
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={termAndConditionClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermsAndConditions;

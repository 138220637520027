export default [
  {
    id: "auditor",
    label: "Auditor",
  },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "storage_location",
    label: "Storage Location",
  },
  {
    id: "package_count",
    label: "Package Count",
  },
  {
    id: "unaccounted",
    label: "Unaccounted",
  },
  {
    id: "action",
    label: "Action",
  },
];

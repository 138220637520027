import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  IconButton,
  Typography,
  TextField,
  Box,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  CircularProgress,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/lab";
import { format } from "date-fns";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Mail as MailIcon,
  Mail as ErrorIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Cancel as CancelIcon,
  ListAlt as ListAltIcon,
  PictureAsPdf as PictureAsPdfIcon,
  PieChart as PieChartIcon,
  Create as CreateIcon,
  CameraAlt as CameraAltIcon,
  Send as SendIcon,
  SettingsInputComponent as SettingsInputComponentIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DARInquiryDetails, DARInquiryEmail } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
// import CustomAlert from "../../../components/form-components/CustomAlert";
import Toaster from "../../tasks/Toaster";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
// Classes Styling
const useStyles = makeStyles((theme) => ({
  searchFilter: {
    position: "relative",
  },
  clearSearch: {
    position: "absolute",
    right: "45px",
  },
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  FormInquiry: {
    "& .MuiFormControl-root": {
      width: "100%",
      minWidth: "auto",
    },
  },
  ErrorImage: {
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",
    marginBottom: "15px",
  },
  AvatarMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    "& .MuiButtonBase-root": {
      whiteSpace: "nowrap",
    },
  },
  AvatarMediaBody: {
    display: "flex",
    alignItems: "center",
  },
  AvatarImage: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  textAreaControl: {
    position: "relative",
    width: "100%",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));

const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;

const DARInquiryDialog = (props) => {
  const {
    darinquiry,
    setDarInquiry,
    darInquiryClose,
    darDateTime,
    darActivity,
    darLocation,
    darRemark,
    // darUserId,
    userIdDialog,
  } = props;
  const classes = useStyles();
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  const [userName, setUserName] = useState("");
  const [sendTo, setSendTo] = useState("");
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const [openSnack, setOpenSnack] = useState(false); //state for snackbar
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();

  const initialValues = {
    inputComment: "",
    inputEmail: LoggedInUserInfo.Email ? LoggedInUserInfo.Email : "",
  };

  const validationSchema = Yup.object().shape({
    inputEmail: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter email address"),
    inputComment: Yup.string().required("Please enter comment"),
  });

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  // Method for daily activity log dialog listing
  const getDetailDAR = () => {
    setDialogLoader(true);
    DARInquiryDetails(userIdDialog)
      .then((res) => {
        setDialogLoader(false);
        setUserName(res.data.UserName);
        setSendTo(res.data.Email);
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let payload = {
      date_time: darDateTime,
      activity: darActivity,
      location: darLocation,
      remarks: darRemark,
      email_to: sendTo,
      reply_to: values.inputEmail,
      comments: values.inputComment,
      name: userName,
      rp_id: "377476411",
      reported_by: LoggedInUserInfo.alias_name,
    };
    DARInquiryEmail(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackMsg("Email sent successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          darInquiryClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          darInquiryClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        darInquiryClose();
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  useEffect(() => {
    getDetailDAR();
  }, []);

  return (
    <>
      {openSnack && (
        <Toaster
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
          severity={alert}
          message={msg}
        />
      )}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        open={darinquiry}
        onClose={darInquiryClose}
        aria-labelledby="dar-inquiry-title"
        aria-describedby="dar-inquiry-description"
      >
        <DialogTitle
          id="dar-inquiry-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            DAR Inquiry
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setDarInquiry(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Using the form below you are able to message the reporting officer
            regarding this activity. The officer will receive an email with your
            question or comment. The officers reply will go to the Reply-To
            email address shown below in red.
          </DialogContentText>
          <Grid spacing={4} container mt={3}>
            {dialogLoader ? (
              <Grid item xs={12} align="center">
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} lg={6}>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Reported By:</strong> {LoggedInUserInfo.alias_name}
                  </Typography>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Date Time:</strong> {darDateTime}
                  </Typography>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Activity:</strong> {darActivity}
                  </Typography>
                  {/* <Typography component="p" variant="span" gutterBottom>
                    <strong>Tour Start:</strong> 04/05/2022 17:03:05
                  </Typography> */}
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Location:</strong> {darLocation}
                  </Typography>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Remarks:</strong> {darRemark}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography component="p" variant="span" gutterBottom>
                    <strong>Email To:</strong> {sendTo}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Box className={classes.FormInquiry}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl mt={3}>
                    <TextField
                      name="inputComment"
                      label="Comment *"
                      placeholder="Comment relating to the activity"
                      value={values.inputComment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.inputComment && errors.inputComment
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      helperText={touched.inputComment && errors.inputComment}
                      multiline={true}
                      rows={6}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      name="inputEmail"
                      value={values.inputEmail}
                      error={Boolean(touched.inputEmail && errors.inputEmail)}
                      fullWidth
                      helperText={touched.inputEmail && errors.inputEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      autoComplete="off"
                      id="reply-to"
                      label="Reply to *"
                      sx={{ input: { color: "red" } }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="me@you.com"
                    />
                  </FormControl>
                  <DialogActions sx={{ padding: "20px 24px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={darInquiryClose}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained">
                      Send
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DARInquiryDialog;

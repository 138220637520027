import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../components/form-components/CustomAlert";
import CustomSelect from "../../components/form-components/CustomSelect";
import { updateShowAlert } from "../../redux/slices/mainSlice";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomDateTimePicker from "../../components/form-components/CustomDateTimePicker";
import MultipleAutoComplete from "../../components/form-components/MultipleAutoComplete";
import { DepartmentList, ViewOverdueKeyReport } from "../../api";

import {
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Link,
  Grid,
  Card,
  CardContent,
  Paper,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const disposition = [
  { key: "Unknown", value: "Unknown" },
  { key: "Lost", value: "Lost" },
  { key: "Found", value: "Found" },
  { key: "Key Cancelled", value: "Key Cancelled" },
  { key: "Stolen", value: "Stolen" },
];
const investigation = [
  {
    key: "Lockers",
    value: "Lockers",
  },
  {
    key: "Uniforms",
    value: "Uniforms",
  },
  {
    key: "Took Home",
    value: "Took Badge",
  },
  {
    key: "Lost Key",
    value: "Lost Key",
  },
  {
    key: "Other",
    value: "Other",
  },
];

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  borderedBox: {
    border: "1px solid",
    borderBottom: 0,
    borderColor:
      theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
  },
}));

function AddReportForm(props) {
  const params = useParams();

  const [recordData, setRecordData] = useState({});
  const [departmentList, setDepartmentList] = useState([]);
  const [investigationOpt, setInvestigationOpt] = useState([]);

  const [preLoading, setPreLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();
  const uploadedFileRef = useRef();
  const navigate = useNavigate();
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    missing_datetime: Yup.string().nullable(),
    datetime_checked_out: Yup.string().nullable(),
    key_return_datetime: Yup.string().nullable(),
    key_cancelled_datetime: Yup.string().nullable(),
    new_key_datetime: Yup.string().nullable(),
    key_number: Yup.string().required("Please enter key number"),
    checked_to: Yup.string().required("Please enter checked out"),
  });

  let initialValues = {
    key_number: recordData
      ? recordData.key_number
        ? recordData.key_number
        : ""
      : "",
    checked_to: recordData
      ? recordData.checked_to
        ? recordData.checked_to
        : ""
      : "",
    disposition: recordData
      ? recordData.disposition
        ? recordData.disposition
        : ""
      : "",
    missing_datetime: recordData
      ? recordData.missing_datetime
        ? recordData.missing_datetime
        : null
      : null,
    department: recordData
      ? recordData.department
        ? recordData.department
        : ""
      : "",
    datetime_checked_out: recordData
      ? recordData.datetime_checked_out
        ? recordData.datetime_checked_out
        : null
      : null,
    investigation: [],
    manager_notified: recordData
      ? recordData.manager_notified
        ? recordData.manager_notified
        : "No"
      : "No",
    manager_name_comment: recordData
      ? recordData.manager_name_comment
        ? recordData.manager_name_comment
        : ""
      : "",
    key_return_when: recordData
      ? recordData.key_return_when
        ? recordData.key_return_when
        : ""
      : "",
    action_taken: recordData
      ? recordData.action_taken
        ? recordData.action_taken
        : ""
      : "",
    key_returned_by: recordData
      ? recordData.key_returned_by
        ? recordData.key_returned_by
        : ""
      : "",
    key_return_datetime: recordData
      ? recordData.key_return_datetime
        ? recordData.key_return_datetime
        : null
      : null,
    key_return_lp: recordData
      ? recordData.key_return_lp
        ? recordData.key_return_lp
        : ""
      : "",
    key_cancelled: recordData
      ? recordData.key_cancelled
        ? recordData.key_cancelled
        : "No"
      : "No",
    key_cancelled_datetime: recordData
      ? recordData.key_cancelled_datetime
        ? recordData.key_cancelled_datetime
        : null
      : null,
    key_cancelled_lp: recordData
      ? recordData.key_cancelled_lp
        ? recordData.key_cancelled_lp
        : ""
      : "",
    new_key_issued: recordData
      ? recordData.new_key_issued
        ? recordData.new_key_issued
        : "No"
      : "No",
    new_key_datetime: recordData
      ? recordData.new_key_datetime
        ? recordData.new_key_datetime
        : null
      : null,
    new_key_lp: recordData
      ? recordData.new_key_lp
        ? recordData.new_key_lp
        : ""
      : "",
    details: recordData ? (recordData.details ? recordData.details : "") : "",
    image: recordData ? (recordData.image ? recordData.image : "") : "",
  };

  useEffect(() => {
    getDepartmentList();
    GetSingleRecord();
  }, []);

  const GetSingleRecord = () => {
    ViewOverdueKeyReport(params.id)
      .then((res) => {
        if (res.statusCode == 200) {
          setRecordData(res.data);
          setInvestigationOpt(res.data.investigation.split(","));
          setPreLoading(false);
        } else {
          setPreLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPreLoading(false);
      });
  };

  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card mt={6}>
        <CardContent>
          {preLoading ? (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({ errors, values, setFieldValue }) => (
                <form>
                  <Grid container spacing={6}>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="key_number"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Key/Badge number"
                          autoComplete="off"
                          placeholder="Key or badge number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="checked_to"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Checked out to"
                          autoComplete="off"
                          placeholder="Name of person with key or badge"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                      <FormControl mt={3}>
                        <CustomSelect
                          inputProps={{ readOnly: true }}
                          name="disposition"
                          fieldLabel="Disposition"
                          options={disposition}
                          displayEmpty
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <CustomDateTimePicker
                          readOnly={true}
                          name="missing_datetime"
                          label="Datetime missing"
                          inputFormat="yyyy-MM-dd HH:mm"
                          placeholder="yyyy-mm-dd hh:mm"
                          setValue={(value) => {
                            let date = value ? moment(value) : "";
                            let dateValue = date
                              ? date.format("YYYY-MM-DD HH:mm")
                              : "";
                            setFieldValue("missing_datetime", dateValue);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <CustomSelect
                          inputProps={{ readOnly: true }}
                          name="department"
                          fieldLabel="Department/site"
                          options={departmentList}
                          displayEmpty
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <CustomDateTimePicker
                          readOnly={true}
                          name="datetime_checked_out"
                          label="Datetime checked out"
                          inputFormat="yyyy-MM-dd HH:mm"
                          placeholder="yyyy-mm-dd hh:mm"
                          setValue={(value) => {
                            let date = value ? moment(value) : "";
                            let dateValue = date
                              ? date.format("YYYY-MM-DD HH:mm")
                              : "";
                            setFieldValue("datetime_checked_out", dateValue);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl>
                        <MultipleAutoComplete
                          name="investigation"
                          InputLabelProps={{ shrink: true }}
                          label="Investigation: (check all that apply)"
                          options={investigation}
                          selOptions={investigationOpt}
                          getChangedData={(evt) => {
                            setFieldValue("investigation", evt);
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={12} md={12}>
                      <FormControl>
                        <FormLabel>Manager notified?</FormLabel>
                        <RadioGroup
                          aria-labelledby="has-manager_notified"
                          name="manager_notified"
                          value={values.manager_notified}
                          onChange={(e) =>
                            setFieldValue(
                              "manager_notified",
                              e.currentTarget.value
                            )
                          }
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            disabled
                            label="Yes"
                            value="Yes"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            disabled
                            label="No"
                            value="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      sx={{
                        display:
                          values.manager_notified === "Yes" ? "block" : "none",
                      }}
                    >
                      <FormControl>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="manager_name_comment"
                          fullWidth
                          autoComplete="off"
                          placeholder="Manager names and any comments"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="key_return_when"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="When will be the Key/Badge returned?"
                          autoComplete="off"
                          placeholder="Expected return time"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="action_taken"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="What action was taken?"
                          autoComplete="off"
                          placeholder="Include details here"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                      <Typography variant="h4" component="div" gutterBottom>
                        Disposition
                      </Typography>
                      <Divider my={6} />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="key_returned_by"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Key/Badge returned by"
                          autoComplete="off"
                          placeholder="Person name"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <CustomDateTimePicker
                          readOnly={true}
                          name="key_return_datetime"
                          label="Datetime"
                          inputFormat="yyyy-MM-dd HH:mm"
                          placeholder="yyyy-mm-dd hh:mm"
                          setValue={(value) => {
                            let date = value ? moment(value) : "";
                            let dateValue = date
                              ? date.format("YYYY-MM-DD HH:mm")
                              : "";
                            setFieldValue("key_return_datetime", dateValue);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="key_return_lp"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Loss prevention person"
                          autoComplete="off"
                          placeholder="Person key or badge was returned to"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <FormLabel>Key/Badge cancelled?</FormLabel>
                        <RadioGroup
                          aria-labelledby="has-key_cancelled"
                          name="key_cancelled"
                          value={values.key_cancelled}
                          onChange={(e) =>
                            setFieldValue(
                              "key_cancelled",
                              e.currentTarget.value
                            )
                          }
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            disabled
                            label="Yes"
                            value="Yes"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            disabled
                            label="No"
                            value="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <CustomDateTimePicker
                          readOnly={true}
                          name="key_cancelled_datetime"
                          label="Datetime"
                          inputFormat="yyyy-MM-dd HH:mm"
                          placeholder="yyyy-mm-dd hh:mm"
                          setValue={(value) => {
                            let date = value ? moment(value) : "";
                            let dateValue = date
                              ? date.format("YYYY-MM-DD HH:mm")
                              : "";
                            setFieldValue("key_cancelled_datetime", dateValue);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="key_cancelled_lp"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Loss prevention person"
                          autoComplete="off"
                          placeholder="Person who cancelled key or badge"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <FormLabel>New Key/Badge issued?</FormLabel>
                        <RadioGroup
                          aria-labelledby="has-new_key_issued"
                          name="new_key_issued"
                          value={values.new_key_issued}
                          onChange={(e) =>
                            setFieldValue(
                              "new_key_issued",
                              e.currentTarget.value
                            )
                          }
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            disabled
                            label="Yes"
                            value="Yes"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            disabled
                            label="No"
                            value="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <CustomDateTimePicker
                          readOnly={true}
                          name="new_key_datetime"
                          label="Datetime"
                          inputFormat="yyyy-MM-dd HH:mm"
                          placeholder="yyyy-mm-dd hh:mm"
                          setValue={(value) => {
                            let date = value ? moment(value) : "";
                            let dateValue = date
                              ? date.format("YYYY-MM-DD HH:mm")
                              : "";
                            setFieldValue("new_key_datetime", dateValue);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="new_key_lp"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Loss prevention person"
                          autoComplete="off"
                          placeholder="Person who created new key or badge"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                      <FormControl>
                        <CustomTextField
                          InputProps={{
                            readOnly: true,
                          }}
                          name="details"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Narrative"
                          autoComplete="off"
                          placeholder=""
                          multiline={true}
                          rows={4}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                      <FormControl mt={6}>
                        {values.image ? (
                          <Card variant="outlined" sx={{ height: "230px" }}>
                            <img
                              alt="image name"
                              src={values.image}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </Card>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function ViewReport() {
  return (
    <React.Fragment>
      <Helmet title="View Report" />
      <WithPermissionFallback controller="Logs" action="viewOverdueKeyReport">
        <Typography variant="h3" gutterBottom display="inline">
          View Report
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/logs/overdue-key-report">
            Overdue Key Report
          </Link>
          <Typography>View Report</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <AddReportForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ViewReport;

export default [
  {
    id: "reporteddatetime",
    label: "Reported Datetime",
  },
  {
    id: "temp",
    label: "Temp",
  },
  {
    id: "fname",
    label: "First Name",
  },
  {
    id: "lname",
    label: "Last Name",
  },
  {
    id: "symptoms",
    label: "Symptoms",
  },
  {
    id: "tested",
    label: "Tested",
  },
  {
    id: "quarantined",
    label: "Quarantined",
  },
  {
    id: "exposed",
    label: "Exposed",
  },
  {
    id: "travel",
    label: "Travel",
  },
  {
    id: "action",
    label: "Action",
  },
];

import axios from "axios";
import { sova } from "../config";
import { createBrowserHistory } from "history";

const api = axios.create({
  baseURL: sova.SOVA_API,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sova.TOKEN,
    LocationId: sova.LOCATION_ID,
  },
});
api.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      const history = createBrowserHistory({ forceRefresh: true });
      if (checkUnauthRoute(history.location.pathname) === false) {
        history.push("/");
      }
    }
    const generic_error = "Something went wrong.";
    if (error.response && error.response.data) {
      if (typeof error.response.data === "string") {
        const newData = {
          error: generic_error,
          info: error.response.data,
        };
        return Promise.reject(newData);
      }
      return Promise.reject(error.response);
    }
    return Promise.reject(error.message);
  }
);

function checkUnauthRoute(params) {
  if (
    params === "/" ||
    params === "/forgotPassword" ||
    params.includes("changePassword") ||
    params.includes("create-blank-app")
  ) {
    return true;
  } else {
    return false;
  }
}
export default api;

import React from "react";
import { Box, Typography, Paper, Grid, Chip } from "@mui/material";

const MessageStatusDetails = ({ messageData }) => {
  const details = JSON.parse(messageData.details);
  const statusColor =
    messageData.status === "delivered" ? "#4caf50" : "#ffd700";
  const statusBackgroundColor =
    messageData.status === "delivered" ? "#d4edda" : "#fff3cd";
  const statusTextColor =
    messageData.status === "delivered" ? "#155724" : "#856404";

  return (
    <Box
      sx={{
        fontFamily: "Arial, sans-serif",
        padding: 2,
        maxWidth: 900,
        margin: "0 auto",
        overflow: "hidden",
      }}
    >
      <Paper
        elevation={3}
        sx={{ padding: 3, borderRadius: 2, overflow: "hidden" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
          paddingBottom={1}
          borderBottom="2px solid #eee"
        >
          <Typography variant="h5">Message Status Details</Typography>
          {/* <Chip
            label="Status Code: 200"
            sx={{
              backgroundColor: "#e8f5e9",
              color: "#2e7d32",
              fontWeight: "bold",
            }}
          /> */}
        </Box>

        <Paper
          sx={{
            padding: 2,
            backgroundColor: "#f8f9fa",
            borderLeft: `4px solid ${statusColor}`,
            borderRadius: 1,
            overflow: "hidden",
            marginBottom: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={1}
          >
            <Chip
              label={
                messageData.status.charAt(0).toUpperCase() +
                messageData.status.slice(1)
              }
              sx={{
                backgroundColor: statusBackgroundColor,
                color: statusTextColor,
                fontWeight: "bold",
              }}
            />
            <Typography variant="body2" color="textSecondary">
              {new Date(messageData.created_at).toLocaleString()}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2">
              <strong>Recipient:</strong> {messageData.recipient}
            </Typography>
            <Typography variant="body2">
              <strong>Event ID:</strong> {messageData.event_id}
            </Typography>
            <Typography variant="body2">
              <strong>From:</strong> {details.From}
            </Typography>
          </Box>

          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body2" fontWeight="bold">
              Details:
            </Typography>
            <pre
              style={{
                backgroundColor: "#f8f9fa",
                padding: "10px",
                borderRadius: "4px",
                overflowX: "auto",
              }}
            >
              {JSON.stringify(details, null, 2)}
            </pre>
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
};

export default MessageStatusDetails;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import {
  TaskActive,
  UpdateTaskStatus,
  GetTaskAssignment,
  DeleteTaskAssignment,
  UpdateTask,
} from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  FileCopy as FileCopyIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import WithPermissionHide from "../../utils/withPermissionHide";

const Paper = styled(MuiPaper)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    border: "1px solid #9e9e9e",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const TableRows = ({
  task,
  isItemSelected,
  labelId,
  handleClick,
  anchorEl,
  handleActionClick,
  handleActionClose,
  ListItemText,
  criticalToggle,
  taskId,
  id,
  assign,
  sortValue,
  taskName,
}) => {
  const dispatch = useDispatch();
  const checkedValue = useSelector((state) => state.task.checked);
  const [critical, setCritical] = useState(
    task.critical_task === "critical" ? true : false
  );
  const [comment, setComment] = useState(
    task.comment_required == 1 ? true : false
  );
  const [web, setWeb] = useState(task.web_notify == 1 ? true : false);
  const [team, setTeam] = useState(task.notify_email == 1 ? true : false);
  const [shift, setShift] = useState(
    task.send_shift_report == 1 ? true : false
  );
  const [sentiment, setSentiment] = useState(
    task.sentiment_assign == "Y" ? true : false
  );
  const [passdown, setPassdown] = useState(
    task.passdown_req == "Y" ? true : false
  );
  const [active, setActive] = useState(task.status == "A" ? true : false);
  const [taskAssignment, setTaskAssignment] = useState([]);
  const [assignmentId, setAssignmentId] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);

  // for permissions
  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToTaskActive = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "taskActive" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const hasPermissionToUpdateTaskStatus = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "updateTaskStatus" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const hasPermissionToUpdateTask = permissions.some(
    (perm) =>
      perm.controller === "Task" &&
      perm.action_name === "updateTask" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  //Toggle function
  const toggleHandleClick = (name, value, id) => {
    UpdateTaskStatus(id, {
      type: name,
      status: value,
    })
      .then((respone) => {
        console.log(respone);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleActiveHandleClick = (value, id) => {
    TaskActive(id, { status: value == false ? "A" : "D" })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const deleteAssignment = () => {
    setAssignmentDropdown(null);
    DeleteTaskAssignment(assignmentId)
      .then((res) => {
        if (res.statusCode === 200) {
          getTaskAssignment();
          setOpenSnack(true);
        } else {
          getTaskAssignment();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskAssignment = () => {
    GetTaskAssignment(taskId)
      .then((res) => {
        if (res.statusCode === 200) {
          setTaskAssignment(res.data);
        } else {
          setTaskAssignment([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   getTaskAssignment();
  // }, []);
  //Task assignment modal
  const [openassignment, setAssignment] = React.useState(false);
  const assignmentModalOpen = () => {
    setAssignment(true);
  };
  const assignmentModalClose = () => {
    setAssignment(false);
  };
  // Assignment Action Dropdown
  const [assignmentDropdown, setAssignmentDropdown] = React.useState(null);
  const actionAssignmentOpen = (event, id) => {
    setAssignmentDropdown(event.currentTarget);
    setAssignmentId(id);
  };
  const assignmentClose = () => {
    setAssignmentDropdown(null);
  };
  const [dense] = React.useState(true);
  const [rowsPerPage] = React.useState(10);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage);
  if (task.length > 0) {
    setLoading(true);
  }

  // Save edited Task name column data
  const [editedTaskName, setEditedTaskName] = useState(task.TaskDescription);
  const handleTaskNameChange = (event) => {
    setEditedTaskName(event.target.value);
  };
  const saveEditedData = async () => {
    const dataToUpdate = {};

    if (editedTaskName !== task.TaskDescription) {
      dataToUpdate.TaskDescription = editedTaskName;

      UpdateTask(task.id, dataToUpdate)
        .then((res) => {
          if (res.statusCode == 200) {
            dispatch(getMsg("Data updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Somthing went wrong please try again!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {});
    }
  };
  return (
    <>
      <TableRow hover key={task.task_id} selected={isItemSelected}>
        {/* <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, task.task_id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
          />
        </TableCell> */}
        {checkedValue.includes("TaskDescription") && (
          <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            align="left"
            sx={{
              cursor: !hasPermissionToUpdateTask ? "not-allowed" : "pointer",
              color: !hasPermissionToUpdateTask ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToUpdateTask ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            {/* {task.TaskDescription} */}
            <Tooltip title={"Click to edit"}>
              <TextField
                sx={{
                  width: 230,
                  border: 0,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: 0,
                  },
                }}
                value={editedTaskName}
                onChange={handleTaskNameChange}
                onBlur={saveEditedData}
                disabled={!hasPermissionToUpdateTask}
              />
            </Tooltip>
          </TableCell>
        )}
        {checkedValue.includes("active") && (
          <TableCell
            align="left"
            sx={{
              cursor: !hasPermissionToTaskActive ? "not-allowed" : "pointer",
              color: !hasPermissionToTaskActive ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToTaskActive ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="active"
                    checked={active}
                    disabled={!hasPermissionToTaskActive}
                    onClick={() => {
                      setActive(!active);
                      toggleActiveHandleClick(active, task.task_id);
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("critical") && (
          <TableCell
            align="left"
            sx={{
              cursor: !hasPermissionToUpdateTaskStatus
                ? "not-allowed"
                : "pointer",
              color: !hasPermissionToUpdateTaskStatus ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToUpdateTaskStatus ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="critical"
                    checked={critical}
                    disabled={!hasPermissionToUpdateTaskStatus}
                    value={critical === false ? "critical" : "non-critical"}
                    onClick={(checked) => {
                      setCritical(!critical);
                      toggleHandleClick(
                        "critical_task",
                        critical === false ? "critical" : "non-critical",
                        task.task_id
                      );
                    }}
                    sx={{
                      visibility:
                        task.Assignable === "Yes" ? "hidden" : "visible",
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("passdown") && (
          <TableCell
            align="left"
            sx={{
              cursor: !hasPermissionToUpdateTaskStatus
                ? "not-allowed"
                : "pointer",
              color: !hasPermissionToUpdateTaskStatus ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToUpdateTaskStatus ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="passdown"
                    checked={passdown}
                    disabled={!hasPermissionToUpdateTaskStatus}
                    onClick={() => {
                      setPassdown(!passdown);
                      toggleHandleClick(
                        "passdown_req",
                        passdown === false ? "Y" : "N",
                        task.task_id
                      );
                    }}
                    sx={{
                      visibility:
                        task.Assignable === "Yes" ? "hidden" : "visible",
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("comment") && (
          <TableCell
            align="left"
            sx={{
              cursor: !hasPermissionToUpdateTaskStatus
                ? "not-allowed"
                : "pointer",
              color: !hasPermissionToUpdateTaskStatus ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToUpdateTaskStatus ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="comment"
                    checked={comment}
                    disabled={!hasPermissionToUpdateTaskStatus}
                    onClick={() => {
                      setComment(!comment);
                      toggleHandleClick(
                        "comment_required",
                        comment === false ? 1 : 0,
                        task.task_id
                      );
                    }}
                    sx={{
                      visibility:
                        task.Assignable === "Yes" ? "hidden" : "visible",
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("web") && (
          <TableCell
            align="left"
            sx={{
              cursor: !hasPermissionToUpdateTaskStatus
                ? "not-allowed"
                : "pointer",
              color: !hasPermissionToUpdateTaskStatus ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToUpdateTaskStatus ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="web"
                    checked={web}
                    disabled={!hasPermissionToUpdateTaskStatus}
                    onClick={() => {
                      setWeb(!web);
                      toggleHandleClick(
                        "web_notify",
                        web === false ? 1 : 0,
                        task.task_id
                      );
                    }}
                    sx={{
                      visibility:
                        task.Assignable === "Yes" ? "hidden" : "visible",
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("team") && (
          <TableCell
            align="left"
            sx={{
              cursor: !hasPermissionToUpdateTaskStatus
                ? "not-allowed"
                : "pointer",
              color: !hasPermissionToUpdateTaskStatus ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToUpdateTaskStatus ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="team"
                    checked={team}
                    disabled={!hasPermissionToUpdateTaskStatus}
                    onClick={() => {
                      setTeam(!team);
                      toggleHandleClick(
                        "notify_email",
                        team === false ? 1 : 0,
                        task.task_id
                      );
                    }}
                    sx={{
                      visibility:
                        task.Assignable === "Yes" ? "hidden" : "visible",
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("shift") && (
          <TableCell
            align="left"
            sx={{
              cursor: !hasPermissionToUpdateTaskStatus
                ? "not-allowed"
                : "pointer",
              color: !hasPermissionToUpdateTaskStatus ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToUpdateTaskStatus ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="shift"
                    checked={shift}
                    disabled={!hasPermissionToUpdateTaskStatus}
                    onClick={() => {
                      setShift(!shift);
                      toggleHandleClick(
                        "send_shift_report",
                        shift === false ? 1 : 0,
                        task.task_id
                      );
                    }}
                    sx={{
                      visibility:
                        task.Assignable === "Yes" ? "hidden" : "visible",
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("sentiment") && (
          <TableCell
            align="left"
            sx={{
              cursor: !hasPermissionToUpdateTaskStatus
                ? "not-allowed"
                : "pointer",
              color: !hasPermissionToUpdateTaskStatus ? "#B0B0B0" : "#0077B5",
              pointerEvents: !hasPermissionToUpdateTaskStatus ? "none" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="sentiment"
                    checked={sentiment}
                    disabled={!hasPermissionToUpdateTaskStatus}
                    onClick={() => {
                      setSentiment(!sentiment);
                      toggleHandleClick(
                        "sentiment_assign",
                        sentiment === false ? "Y" : "N",
                        task.task_id
                      );
                    }}
                    sx={{
                      visibility:
                        task.Assignable === "Yes" ? "hidden" : "visible",
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </TableCell>
        )}
        {checkedValue.includes("asn") && (
          <TableCell align="left">{task.Assignable}</TableCell>
        )}
        {checkedValue.includes("sch") && (
          <TableCell align="left">{task.sch}</TableCell>
        )}
        {checkedValue.includes("btn") && (
          <TableCell align="left">{task.btn}</TableCell>
        )}
        {checkedValue.includes("lvl1_label") && (
          <TableCell align="left">{task.lvl1_label}</TableCell>
        )}
        {checkedValue.includes("lvl2_label") && (
          <TableCell align="left">{task.lvl2_label}</TableCell>
        )}
        {checkedValue.includes("lvl3_label") && (
          <TableCell align="left">{task.lvl3_label}</TableCell>
        )}
        {checkedValue.includes("Notify_lvl1_email") && (
          <TableCell align="left">{task.Notify_lvl1_email}</TableCell>
        )}
        {checkedValue.includes("Notify_lvl2_email") && (
          <TableCell align="left">{task.Notify_lvl2_email}</TableCell>
        )}
        {checkedValue.includes("Notify_lvl3_email") && (
          <TableCell align="left">{task.Notify_lvl3_email}</TableCell>
        )}
        {checkedValue.includes("task_comment") && (
          <TableCell align="left">{task.task_comment}</TableCell>
        )}
        {checkedValue.includes("activity_point") && (
          <TableCell align="left">{task.activity_point}</TableCell>
        )}
        {checkedValue.includes("last_updated_on") && (
          <TableCell align="left">{task.last_updated_on}</TableCell>
        )}
        <TableCell align="left">
          <IconButton
            aria-label="Settings"
            size="large"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={(event) =>
              handleActionClick(
                event,
                task.task_id,
                task.Assignable,
                task.TaskDescription,
                task.id
              )
            }
            style={{ color: "#1374C5" }}
          >
            <SettingsIcon />
          </IconButton>
          <StyledMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              // onClick={handleClose}
              aria-label="details"
              component={NavLink}
              to={`/tasks/edit-task/${id}`}
              size="large"
            >
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" pl={1} />
            </MenuItem>
            {/* <MenuItem>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" pl={1} />
            </MenuItem> */}
            {/* <MenuItem onClick={assignmentModalOpen}>
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText primary="Assignments" pl={1} />
            </MenuItem> */}
            <WithPermissionHide controller="Task" action="taskAssignmentList">
              {assign === "Yes" ? (
                <MenuItem
                  onClick={() => {
                    assignmentModalOpen();
                    getTaskAssignment();
                  }}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Assignments" pl={1} />
                </MenuItem>
              ) : null}
            </WithPermissionHide>
          </StyledMenu>
        </TableCell>
      </TableRow>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={openassignment}
        onClose={assignmentModalClose}
        aria-labelledby="equipment-log"
        aria-describedby="equipment-log"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Task Assignments
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setAssignment(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText sx={{ padding: "10px 25px 25px" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontSize: "15px", marginBottom: "3px" }}
            >
              {taskName}
            </Typography>
            <Typography variant="p" component="div">
              The list below shows tour checkpoints where this task is currently
              assigned.
            </Typography>
          </DialogContentText>
          <Paper>
            <TableWrapper>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Tour Name</TableCell>
                    <TableCell>Checkpoint</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Snackbar
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={handleCloseSnack}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Alert
                      onClose={handleCloseSnack}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      This is a success message!
                    </Alert>
                  </Snackbar>
                  {taskAssignment.map((data) => {
                    return (
                      <TableRow hover key={data.AssignmentID}>
                        <TableCell>{data.TourName}</TableCell>
                        <TableCell>{data.TagDescription}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="action"
                            size="large"
                            aria-owns={
                              assignmentDropdown ? "icon-menu" : undefined
                            }
                            aria-haspopup="true"
                            onClick={(event) =>
                              actionAssignmentOpen(event, data.AssignmentID)
                            }
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={assignmentDropdown}
                            open={Boolean(assignmentDropdown)}
                            onClose={assignmentClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            {/* <MenuItem>
                              <ListItemIcon>
                                <CreateIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem> */}
                            <WithPermissionHide
                              controller="Task"
                              action="deleteTaskAssignment"
                            >
                              <MenuItem onClick={deleteAssignment}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </WithPermissionHide>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {taskAssignment.length === 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 7 : 0) * taskAssignment.length,
                      }}
                    >
                      <TableCell colSpan={6} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableWrapper>
          </Paper>
        </DialogContent>
        <DialogActions
          sx={{
            paddingTop: "30px",
            paddingBottom: "30px",
            paddingRight: "20px",
          }}
        >
          <Button
            onClick={assignmentModalClose}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableRows;

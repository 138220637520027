import React from "react";

import { RadioGroup, Radio, FormControlLabel } from "@mui/material";

const MergePoiRadioTwo = ({
  secondRecord,
  selectedRecord,
  handleChecked,
  objKey,
  lableText,
}) => {
  return (
    <>
      <FormControlLabel
        checked={secondRecord[objKey] === selectedRecord[objKey]}
        value={secondRecord[objKey]}
        onChange={() => handleChecked(objKey, secondRecord[objKey])}
        control={<Radio required={true} name={objKey} />}
        label={lableText}
      />
    </>
  );
};

export default MergePoiRadioTwo;

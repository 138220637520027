import "react-app-polyfill/stable";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import "./mocks";

import { ThemeProvider } from "./contexts/ThemeContext";
import axios from "axios";
import { sova } from "./config";
import { Provider } from "react-redux";
import { store } from "./redux/store";

axios.defaults.baseURL = sova.SOVA_API + "/";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import CustomTextField from "../../../../components/form-components/CustomTextField";
import CustomSelect from "../../../../components/form-components/CustomSelect";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl as MuiFormControl,
  Grid,
  Typography,
  IconButton,
  Button,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  CancelRounded as CancelRoundedIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import {
  getFileBase64,
  handleApiResonseErrors,
} from "../../../../helper/helper";
import { DepartmentList, AddTrainedresponder } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../../redux/slices/mainSlice";
import moment from "moment";
import CheckboxWrapper from "../../../accounts/components/Checkbox";
import states from "../../../../helper/states.json";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
}));

const trainingInterval = [
  { key: "1 Year", value: "1 Year" },
  { key: "2 Years", value: "2 Years" },
  { key: "3 Years", value: "3 Years" },
];

const type = [
  { key: "Armed", value: "Armed" },
  { key: "Unarmed", value: "Unarmed" },
];
const licenseStatus = [
  { key: "Active", value: "Active" },
  { key: "Inactive", value: "Inactive" },
  { key: "Expired", value: "Expired" },
  { key: "Cancelled", value: "Cancelled" },
  { key: "Suspended", value: "Suspended" },
];

const AddEditTrainedResponder = ({
  addEditTresp,
  addEditTrespClose,
  setPageLoad,
  editData,
  getAedTrainedResponderList,
}) => {
  const classes = useStyles();
  const [departmentList, setDepartmentList] = useState([]);
  const dispatch = useDispatch();
  const [uploadTrainCert, setUploadTrainCert] = useState(null);
  const [uploadGuardCert, setUploadGuardCert] = useState(null);
  const [expanded, setExpanded] = React.useState(false);

  // const handleAccChange = (panel) => (event, isExpanded) => {
  //   console.log(panel);
  //   console.log(isExpanded);
  //   setExpanded(isExpanded ? panel : false);
  // };
  //For Dropdown
  const getDepartmentList = () => {
    DepartmentList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Method for handling certificate image
  const uploadTrainCertificate = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      getFileBase64(file, (result) => {
        setUploadTrainCert(result);
      });
    }
  };
  const uploadGuardCertificate = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      getFileBase64(file, (result) => {
        setUploadGuardCert(result);
      });
    }
  };
  let initialValues = {
    first_name: editData
      ? editData.first_name
        ? editData.first_name
        : ""
      : "",
    last_name: editData ? (editData.last_name ? editData.last_name : "") : "",
    department_id: editData
      ? editData.department_id
        ? editData.department_id
        : ""
      : "",
    enroll_date: editData
      ? editData.enroll_date
        ? editData.enroll_date
        : null
      : null,
    training_date: editData
      ? editData.training_date
        ? editData.training_date
        : null
      : null,
    training_expiry: editData
      ? editData.training_expiry
        ? editData.training_expiry
        : null
      : null,
    training_cert: editData
      ? editData.training_cert
        ? editData.training_cert
        : ""
      : "",
    coordinator: editData ? (editData.coordinator == 1 ? true : false) : false,
    ert: editData ? (editData.ert == 1 ? true : false) : false,
    // certificate_file: "",
    //Security guard state
    initial_training_date: editData
      ? editData.initial_training_date
        ? editData.initial_training_date
        : null
      : null,
    refresher_training_date: editData
      ? editData.refresher_training_date
        ? editData.refresher_training_date
        : null
      : null,
    guard_issue_date: editData
      ? editData.guard_issue_date
        ? editData.guard_issue_date
        : null
      : null,
    guard_training_interval: editData
      ? editData.guard_training_interval
        ? editData.guard_training_interval
        : ""
      : "",
    guard_state: editData
      ? editData.guard_state
        ? editData.guard_state
        : ""
      : "",
    guard_type: editData
      ? editData.guard_type
        ? editData.guard_type
        : ""
      : "",
    guard_license_number: editData
      ? editData.guard_license_number
        ? editData.guard_license_number
        : ""
      : "",
    guard_license_status: editData
      ? editData.guard_license_status
        ? editData.guard_license_status
        : ""
      : "",
    // guard_certificate_image: "",
    //Firearm permit state
    firearm_permit_number: editData
      ? editData.firearm_permit_number
        ? editData.firearm_permit_number
        : ""
      : "",
    firearm_state: editData
      ? editData.firearm_state
        ? editData.firearm_state
        : ""
      : "",
    firearm_issue_date: editData
      ? editData.firearm_issue_date
        ? editData.firearm_issue_date
        : null
      : null,
    firearm_expiry_date: editData
      ? editData.firearm_expiry_date
        ? editData.firearm_expiry_date
        : null
      : null,
    //Baton permit
    baton_permit_number: editData
      ? editData.baton_permit_number
        ? editData.baton_permit_number
        : ""
      : "",
    baton_state: editData
      ? editData.baton_state
        ? editData.baton_state
        : ""
      : "",
    baton_issue_date: editData
      ? editData.baton_issue_date
        ? editData.baton_issue_date
        : null
      : null,
    baton_expiry_date: editData
      ? editData.baton_expiry_date
        ? editData.baton_expiry_date
        : null
      : null,
    //Tear gas permit
    tear_permit_number: editData
      ? editData.tear_permit_number
        ? editData.tear_permit_number
        : ""
      : "",
    tear_state: editData
      ? editData.tear_state
        ? editData.tear_state
        : ""
      : "",
    tear_issue_date: editData
      ? editData.tear_issue_date
        ? editData.tear_issue_date
        : null
      : null,
    tear_expiry_date: editData
      ? editData.tear_expiry_date
        ? editData.tear_expiry_date
        : null
      : null,
    //Other permits
    other_type: editData
      ? editData.other_type
        ? editData.other_type
        : ""
      : "",
    other_permit_number: editData
      ? editData.other_permit_number
        ? editData.other_permit_number
        : ""
      : "",
    other_state: editData
      ? editData.other_state
        ? editData.other_state
        : ""
      : "",
    other_issue_date: editData
      ? editData.other_issue_date
        ? editData.other_issue_date
        : null
      : null,
    other_expiry_date: editData
      ? editData.other_expiry_date
        ? editData.other_expiry_date
        : null
      : null,
    other_description: editData
      ? editData.other_description
        ? editData.other_description
        : ""
      : "",
    expander: null,
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter details"),
    last_name: Yup.string().required("Please enter deatails"),
    department_id: Yup.string().required("Please select department"),
    enroll_date: Yup.date().nullable().required("Please select the date"),
    training_date: Yup.date()
      .nullable()
      .min(
        Yup.ref("enroll_date"),
        "Training date has to be more than enroll date"
      )
      .required("Please select the date"),

    training_expiry: Yup.date()
      .nullable()
      .min(
        Yup.ref("training_date"),
        "Training expiry date has to be more than training date"
      )
      .required("Please select the date"),

    training_cert: Yup.string()
      .required("Please select one of these options")
      .oneOf(["amo", "rc", "other"]),
    initial_training_date: Yup.string()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "security"
          ? schema.required("Please select date")
          : schema;
      }),

    refresher_training_date: Yup.string()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "security"
          ? schema.required("Please select date")
          : schema;
      }),
    guard_issue_date: Yup.string()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "security"
          ? schema.required("Please select date")
          : schema;
      }),
    guard_training_interval: Yup.string().when(["expander"], (same, schema) => {
      return same === "security"
        ? schema.required("Please select training interval")
        : schema;
    }),
    guard_state: Yup.string().when(["expander"], (same, schema) => {
      return same === "security"
        ? schema.required("Please select state")
        : schema;
    }),
    guard_type: Yup.string().when(["expander"], (same, schema) => {
      return same === "security"
        ? schema.required("Please select type")
        : schema;
    }),
    guard_license_number: Yup.string().when(["expander"], (same, schema) => {
      return same === "security"
        ? schema.required("Please enter license number")
        : schema;
    }),
    guard_license_status: Yup.string().when(["expander"], (same, schema) => {
      return same === "security"
        ? schema.required("Please select status")
        : schema;
    }),
    firearm_permit_number: Yup.string().when(["expander"], (same, schema) => {
      return same === "fire"
        ? schema.required("Please enter permit number")
        : schema;
    }),
    firearm_state: Yup.string().when(["expander"], (same, schema) => {
      return same === "fire" ? schema.required("Please select state") : schema;
    }),
    firearm_issue_date: Yup.date()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "fire" ? schema.required("Please select date") : schema;
      }),
    firearm_expiry_date: Yup.date()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "fire"
          ? schema
              .min(
                Yup.ref("firearm_issue_date"),
                "Expiry date has to be more than issue date"
              )
              .required("Please select date")
          : schema;
      }),
    baton_permit_number: Yup.string().when(["expander"], (same, schema) => {
      return same === "baton"
        ? schema.required("Please enter permit number")
        : schema;
    }),
    baton_state: Yup.string().when(["expander"], (same, schema) => {
      return same === "baton" ? schema.required("Please select state") : schema;
    }),
    baton_issue_date: Yup.date()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "baton"
          ? schema.required("Please select date")
          : schema;
      }),
    baton_expiry_date: Yup.date()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "baton"
          ? schema
              .min(
                Yup.ref("baton_issue_date"),
                "Expiry date has to be more than issue date"
              )
              .required("Please select date")
          : schema;
      }),
    tear_permit_number: Yup.string().when(["expander"], (same, schema) => {
      return same === "tear"
        ? schema.required("Please enter permit number")
        : schema;
    }),
    tear_state: Yup.string().when(["expander"], (same, schema) => {
      return same === "tear" ? schema.required("Please select state") : schema;
    }),
    tear_issue_date: Yup.date()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "tear" ? schema.required("Please select date") : schema;
      }),
    tear_expiry_date: Yup.date()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "tear"
          ? schema
              .min(
                Yup.ref("tear_issue_date"),
                "Expiry date has to be more than issue date"
              )
              .required("Please select date")
          : schema;
      }),
    other_type: Yup.string().when(["expander"], (same, schema) => {
      return same === "other"
        ? schema.required("Please enter other type")
        : schema;
    }),
    other_permit_number: Yup.string().when(["expander"], (same, schema) => {
      return same === "other"
        ? schema.required("Please enter permit number")
        : schema;
    }),
    other_state: Yup.string().when(["expander"], (same, schema) => {
      return same === "other" ? schema.required("Please select state") : schema;
    }),
    other_issue_date: Yup.date()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "other"
          ? schema.required("Please select date")
          : schema;
      }),
    other_expiry_date: Yup.date()
      .nullable()
      .when(["expander"], (same, schema) => {
        return same === "other"
          ? schema
              .min(
                Yup.ref("other_issue_date"),
                "Expiry date has to be more than issue date"
              )
              .required("Please select date")
          : schema;
      }),
    other_description: Yup.string().when(["expander"], (same, schema) => {
      return same === "other"
        ? schema.required("Please enter description")
        : schema;
    }),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formattedEnrollDate = values.enroll_date
        ? moment(values.enroll_date).format("YYYY-MM-DD")
        : "";
      let formattedTrainDate = values.training_date
        ? moment(values.training_date).format("YYYY-MM-DD")
        : "";
      let formattedTrainExpDate = values.training_expiry
        ? moment(values.training_expiry).format("YYYY-MM-DD")
        : "";
      let formattedInitTrainDate = values.initial_training_date
        ? moment(values.initial_training_date).format("YYYY-MM-DD")
        : "";
      let formattedRefTrainDate = values.refresher_training_date
        ? moment(values.refresher_training_date).format("YYYY-MM-DD")
        : "";
      let formattedGuardIssueDate = values.guard_issue_date
        ? moment(values.guard_issue_date).format("YYYY-MM-DD")
        : "";
      let formattedFireArmIssueDate = values.firearm_issue_date
        ? moment(values.firearm_issue_date).format("YYYY-MM-DD")
        : "";
      let formattedFireArmExpDate = values.firearm_expiry_date
        ? moment(values.firearm_expiry_date).format("YYYY-MM-DD")
        : "";
      let formattedBatonIssueDate = values.baton_issue_date
        ? moment(values.baton_issue_date).format("YYYY-MM-DD")
        : "";
      let formattedBatonExpDate = values.baton_expiry_date
        ? moment(values.baton_expiry_date).format("YYYY-MM-DD")
        : "";
      let formattedTearIssueDate = values.tear_issue_date
        ? moment(values.tear_issue_date).format("YYYY-MM-DD")
        : "";
      let formattedTearExpDate = values.tear_expiry_date
        ? moment(values.tear_expiry_date).format("YYYY-MM-DD")
        : "";
      let formattedOtherIssueDate = values.other_issue_date
        ? moment(values.other_issue_date).format("YYYY-MM-DD")
        : "";
      let formattedOtherExpDate = values.other_expiry_date
        ? moment(values.other_expiry_date).format("YYYY-MM-DD")
        : "";
      let formData = {
        id: editData ? (editData.id ? editData.id : null) : null,
        first_name: values.first_name,
        last_name: values.last_name,
        department_id: values.department_id,
        enroll_date: formattedEnrollDate,
        training_date: formattedTrainDate,
        training_expiry: formattedTrainExpDate,
        training_cert: values.training_cert,
        coordinator: values.coordinator ? "1" : "0",
        ert: values.ert ? "1" : "0",
        certificate_file: uploadTrainCert,
        initial_training_date: formattedInitTrainDate,
        refresher_training_date: formattedRefTrainDate,
        guard_issue_date: formattedGuardIssueDate,
        guard_training_interval: values.guard_training_interval,
        guard_state: values.guard_state,
        guard_type: values.guard_type,
        guard_license_number: values.guard_license_number,
        guard_license_status: values.guard_license_status,
        guard_certificate_image: uploadGuardCert,
        firearm_permit_number: values.firearm_permit_number,
        firearm_state: values.firearm_state,
        firearm_issue_date: formattedFireArmIssueDate,
        firearm_expiry_date: formattedFireArmExpDate,
        baton_permit_number: values.baton_permit_number,
        baton_state: values.baton_state,
        baton_issue_date: formattedBatonIssueDate,
        baton_expiry_date: formattedBatonExpDate,
        tear_permit_number: values.tear_permit_number,
        tear_state: values.tear_state,
        tear_issue_date: formattedTearIssueDate,
        tear_expiry_date: formattedTearExpDate,
        other_type: values.other_type,
        other_permit_number: values.other_permit_number,
        other_state: values.other_state,
        other_issue_date: formattedOtherIssueDate,
        other_expiry_date: formattedOtherExpDate,
        other_description: values.other_description,
      };
      AddTrainedresponder(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            // getAedsInventoryList();
            getAedTrainedResponderList();
            dispatch(getMsg("Trained responder saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            addEditTrespClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            if (res.message == "The name has already been taken ") {
              dispatch(getMsg("Something went wrong: " + res.message));
            } else {
              dispatch(getMsg("Something went wrong, please try again"));
            }
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } catch (err) {
      setPageLoad(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    }
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  const [openEnroll, setOpenEnroll] = useState(false);
  const [openTraining, setOpenTraining] = useState(false);
  const [openTrainingExpiry, setOpenTrainingExpiry] = useState(false);

  return (
    <>
      <Dialog
        aria-labelledby="addEditTresp-modal"
        aria-describedby="addEditTresp-modal"
        open={addEditTresp}
        onClose={addEditTrespClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="addedit-aed-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit Trained Responder
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addEditTrespClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => {
              if (isSubmitting) {
                const firstErrorKey = Object.keys(errors)[0];
                if (firstErrorKey) {
                  const firstErrorField = document.querySelector(
                    `[name="${firstErrorKey}"]`
                  );
                  if (firstErrorField) {
                    firstErrorField.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                  firstErrorField.focus();
                }
              }
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="first_name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="First name *"
                          autoComplete="off"
                          placeholder="John"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="last_name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Last name *"
                          autoComplete="off"
                          placeholder="Smith"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomSelect
                          name="department_id"
                          fieldLabel="Department*"
                          firstOptionTitle="--Click to select--"
                          options={departmentList}
                          displayEmpty
                        />
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.department_id && errors.department_id}
                        </FormHelperText>
                      </FormControl>
                      <FormControl mt={6}>
                        <DatePicker
                          open={openEnroll}
                          onOpen={() => setOpenEnroll(true)}
                          onClose={() => setOpenEnroll(false)}
                          disableOpenPicker="true"
                          label="Enroll date *"
                          value={values.enroll_date}
                          onChange={(newValue) => {
                            setFieldValue("enroll_date", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="enroll_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              maxDate={new Date()}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: moment(new Date()).format(
                                  "MM/DD/YYYY"
                                ),
                              }}
                              onClick={() => setOpenEnroll(true)} // Handle text field click to open date picker
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <DatePicker
                          open={openTraining}
                          onOpen={() => setOpenTraining(true)}
                          onClose={() => setOpenTraining(false)}
                          disableOpenPicker="true"
                          label="Training date *"
                          value={values.training_date}
                          onChange={(newValue) => {
                            setFieldValue("training_date", newValue);
                          }}
                          minDate={values.enroll_date}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="training_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: moment(new Date()).format(
                                  "MM/DD/YYYY"
                                ),
                              }}
                              onClick={() => setOpenTraining(true)}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <DatePicker
                          open={openTrainingExpiry}
                          onOpen={() => setOpenTrainingExpiry(true)}
                          onClose={() => setOpenTrainingExpiry(false)}
                          disableOpenPicker="true"
                          label="Training expiry *"
                          value={values.training_expiry}
                          onChange={(newValue) => {
                            setFieldValue("training_expiry", newValue);
                          }}
                          minDate={values.training_date}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="training_expiry"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: moment(new Date()).format(
                                  "MM/DD/YYYY"
                                ),
                              }}
                              onClick={() => setOpenTrainingExpiry(true)}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>Training certificate*</FormLabel>
                        <RadioGroup
                          name="training_cert"
                          aria-labelledby="training-certificate"
                          value={values.training_cert.toString()}
                          onChange={(e) =>
                            setFieldValue(
                              "training_cert",
                              e.currentTarget.value
                            )
                          }
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="American medical association"
                            value="amo"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Red cross"
                            value="rc"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Other"
                            value="other"
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.training_cert && errors.training_cert}
                        </FormHelperText>
                      </FormControl>
                      <FormControl mt={3}>
                        <CheckboxWrapper
                          name="coordinator"
                          checked={values.coordinator}
                          disableRipple
                          label="Is coordinator ?"
                        />
                      </FormControl>
                      <FormControl mt={3}>
                        <CheckboxWrapper
                          name="ert"
                          checked={values.ert}
                          disableRipple
                          label="Is ERT ?"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="training_certificate"
                          label="Upload training certificate"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          placeholder=""
                          sx={{
                            "& .MuiInputBase-input": { textAlign: "right" },
                          }}
                        />
                        <input
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            paddingTop: "15px",
                            paddingLeft: "15px",
                          }}
                          accept="image/*"
                          id="raised-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            uploadTrainCertificate(e);
                          }}
                        />
                        <FormHelperText>
                          Image files only (jpg, png)
                        </FormHelperText>
                        {uploadTrainCert && (
                          <div style={{ marginTop: "20px" }}>
                            <img
                              src={uploadTrainCert}
                              alt="Selected"
                              style={{ maxWidth: "80px", height: "80px" }}
                            />
                          </div>
                        )}
                        {editData ? (
                          editData.certificate_file !== null ? (
                            <FormHelperText error>
                              Selecting new image will delete existing image.
                            </FormHelperText>
                          ) : null
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h4" component="div" gutterBottom>
                        Permits and Licensing
                      </Typography>
                      <Box className={classes.accordion}>
                        <Accordion
                          expanded={values.expander === "security"}
                          onChange={() =>
                            setFieldValue(
                              "expander",
                              values.expander !== null ? null : "security"
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="security-guard"
                            id="security-guard"
                          >
                            <Typography>Security Guard</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormControl mt={3}>
                              <DatePicker
                                label="Initial training date *"
                                value={values.initial_training_date}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    "initial_training_date",
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="initial_training_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Refresher training date *"
                                value={values.refresher_training_date}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    "refresher_training_date",
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="refresher_training_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Issue date *"
                                value={values.guard_issue_date}
                                onChange={(newValue) => {
                                  setFieldValue("guard_issue_date", newValue);
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="guard_issue_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="guard_training_interval"
                                fieldLabel="Training interval *"
                                firstOptionTitle="--Click to select--"
                                options={trainingInterval}
                                displayEmpty
                              />
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.guard_training_interval &&
                                  errors.guard_training_interval}
                              </FormHelperText>
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="guard_state"
                                fieldLabel="State *"
                                options={states}
                                firstOptionTitle="--Click to select--"
                                displayEmpty
                              />
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.guard_state && errors.guard_state}
                              </FormHelperText>
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="guard_type"
                                fieldLabel="Type *"
                                options={type}
                                firstOptionTitle="--Click to select--"
                                displayEmpty
                              />
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.guard_type && errors.guard_type}
                              </FormHelperText>
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                name="guard_license_number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 25 }}
                                label="License number *"
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="guard_license_status"
                                fieldLabel="License status *"
                                options={licenseStatus}
                                firstOptionTitle="--Click to select--"
                                displayEmpty
                              />
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.guard_license_status &&
                                  errors.guard_license_status}
                              </FormHelperText>
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                name="guard_certificate_image"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Certificate image"
                                autoComplete="off"
                                placeholder=""
                                sx={{
                                  "& .MuiInputBase-input": {
                                    textAlign: "right",
                                  },
                                }}
                              />
                              <input
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  paddingTop: "15px",
                                  paddingLeft: "15px",
                                }}
                                accept="image/*"
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={(e) => {
                                  uploadGuardCertificate(e);
                                }}
                              />
                              <FormHelperText sx={{ textAlign: "right" }}>
                                Image files only (jpg, png)
                              </FormHelperText>
                              {editData ? (
                                editData.guard_certificate_image ==
                                null ? null : (
                                  <FormHelperText
                                    error
                                    sx={{ textAlign: "right" }}
                                  >
                                    Selecting new image will delete existing
                                    image.
                                  </FormHelperText>
                                )
                              ) : (
                                <FormHelperText
                                  error
                                  sx={{ textAlign: "right" }}
                                >
                                  Selecting new image will delete existing
                                  image.
                                </FormHelperText>
                              )}
                            </FormControl>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          mt={5}
                          expanded={values.expander === "fire"}
                          onChange={() =>
                            setFieldValue(
                              "expander",
                              values.expander !== null ? null : "fire"
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="firearm-permit"
                            id="firearm-permit"
                          >
                            <Typography>Firearm Permit</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormControl mt={6}>
                              <CustomTextField
                                name="firearm_permit_number"
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Permit number *"
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="firearm_state"
                                fieldLabel="State *"
                                options={states}
                                firstOptionTitle="--Click to select--"
                                displayEmpty
                              />
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.firearm_state && errors.firearm_state}
                              </FormHelperText>
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Issue date *"
                                value={values.firearm_issue_date}
                                onChange={(newValue) => {
                                  setFieldValue("firearm_issue_date", newValue);
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="firearm_issue_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Expiry date *"
                                value={values.firearm_expiry_date}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    "firearm_expiry_date",
                                    newValue
                                  );
                                }}
                                minDate={values.firearm_issue_date}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="firearm_expiry_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          mt={5}
                          expanded={values.expander === "baton"}
                          onChange={() =>
                            setFieldValue(
                              "expander",
                              values.expander !== null ? null : "baton"
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="baton-permit"
                            id="baton-permit"
                          >
                            <Typography>Baton Permit</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormControl mt={6}>
                              <CustomTextField
                                name="baton_permit_number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 50 }}
                                label="Permit number *"
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="baton_state"
                                fieldLabel="State *"
                                options={states}
                                firstOptionTitle="--Click to select--"
                                displayEmpty
                              />
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.baton_state && errors.baton_state}
                              </FormHelperText>
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Issue date *"
                                value={values.baton_issue_date}
                                onChange={(newValue) => {
                                  setFieldValue("baton_issue_date", newValue);
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="baton_issue_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Expiry date *"
                                value={values.baton_expiry_date}
                                onChange={(newValue) => {
                                  setFieldValue("baton_expiry_date", newValue);
                                }}
                                minDate={values.baton_issue_date}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="baton_expiry_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          mt={5}
                          expanded={values.expander === "tear"}
                          onChange={() =>
                            setFieldValue(
                              "expander",
                              values.expander !== null ? null : "tear"
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="tear-gas-permit"
                            id="tear-gas-permit"
                          >
                            <Typography>Tear Gas Permit</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormControl mt={6}>
                              <CustomTextField
                                name="tear_permit_number"
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Permit number *"
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="tear_state"
                                fieldLabel="State *"
                                options={states}
                                firstOptionTitle="--Click to select--"
                                displayEmpty
                              />
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.tear_state && errors.tear_state}
                              </FormHelperText>
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Issue date *"
                                value={values.tear_issue_date}
                                onChange={(newValue) => {
                                  setFieldValue("tear_issue_date", newValue);
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="tear_issue_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Expiry date *"
                                value={values.tear_expiry_date}
                                onChange={(newValue) => {
                                  setFieldValue("tear_expiry_date", newValue);
                                }}
                                minDate={values.tear_issue_date}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="tear_expiry_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          mt={5}
                          expanded={values.expander === "other"}
                          onChange={() =>
                            setFieldValue(
                              "expander",
                              values.expander !== null ? null : "other"
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="other-permit"
                            id="other-permit"
                          >
                            Other Permit
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormControl mt={3}>
                              <CustomTextField
                                name="other_type"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 50 }}
                                label="Type*"
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                name="other_permit_number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 50 }}
                                label="Permit number *"
                                autoComplete="off"
                                placeholder=""
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="other_state"
                                fieldLabel="State *"
                                options={states}
                                firstOptionTitle="--Click to select--"
                                displayEmpty
                              />
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.other_state && errors.other_state}
                              </FormHelperText>
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Issue date *"
                                value={values.other_issue_date}
                                onChange={(newValue) => {
                                  setFieldValue("other_issue_date", newValue);
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="other_issue_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <DatePicker
                                label="Expiry date *"
                                value={values.other_expiry_date}
                                onChange={(newValue) => {
                                  setFieldValue("other_expiry_date", newValue);
                                }}
                                minDate={values.other_issue_date}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="other_expiry_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: moment(new Date()).format(
                                        "MM/DD/YYYY"
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                name="other_description"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Description *"
                                autoComplete="off"
                                placeholder=""
                                rows={4}
                                multiline={true}
                              />
                            </FormControl>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </Grid>
                  </Grid>
                  <DialogActions sx={{ pr: "28px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClic={addEditTrespClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ ml: "15px" }}
                      type="submit"
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEditTrainedResponder;

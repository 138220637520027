export default [
  {
    id: "fname",
    label: "First Name",
  },
  {
    id: "lname",
    label: "Last Name",
  },
  {
    id: "department",
    label: "Department",
  },
  {
    id: "position",
    label: "Position",
  },
  {
    id: "vehicle_id",
    label: "ID",
  },
  {
    id: "sticker",
    label: "Sticker",
  },
  {
    id: "color",
    label: "Color",
  },
  {
    id: "make",
    label: "Make",
  },
  {
    id: "model",
    label: "Model",
  },
  {
    id: "year",
    label: "Year",
  },
  {
    id: "l_plate",
    label: "V Plate",
    title: "Vehicle license plate",
  },
  {
    id: "v_state",
    label: "V State",
    title: "Vehicle license state",
  },
  {
    id: "vio",
    label: "Vio",
    title: "Count of vehicle violations",
  },
  {
    id: "action",
    label: "Action",
  },
];

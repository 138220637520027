import React from "react";
import moment from "moment";
import { CommunicationLog } from "../../../api";

import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  IconButton,
  DialogContent,
  Typography,
  CircularProgress,
  Rating,
} from "@mui/material";
import { Cancel as CancelIcon, Add as AddIcon } from "@mui/icons-material";

const CommunicationLogModal = ({
  show,
  handleModal,
  id,
  setShowAddComLog,
  log_type,
}) => {
  const [list, setList] = React.useState([]);
  const [loading, setLoading] = React.useState(true); //State for loader

  React.useEffect(() => {
    getListing();
    return () => {
      setList([]);
    };
  }, []);

  const getListing = () => {
    let payload = {
      id: id,
      log_type: log_type,
    };
    CommunicationLog(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setList(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog
      open={show}
      aria-labelledby="activity-log-title"
      aria-describedby="activity-log-description"
      fullWidth={true}
      maxWidth={"xl"}
    >
      <DialogTitle
        id="activity-log-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Communication Log: ID # {id}
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <Button
            onClick={() => {
              setShowAddComLog(true);
              handleModal(false);
            }}
            color="primary"
            variant="contained"
          >
            {<AddIcon />}Comm Log
          </Button>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => handleModal(false)}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 0" }}>
        <TableContainer className="tableContainer">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Datetime</TableCell>
                <TableCell>Origin</TableCell>
                <TableCell>Caller Name</TableCell>
                <TableCell>Caller Number</TableCell>
                <TableCell>Call Length</TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Follow Up</TableCell>
                <TableCell>Caller Sentiment</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Staff</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={10} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {list.map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        {moment(row.created).format("YYYY/MM/DD HH:mm:ss")}
                      </TableCell>
                      <TableCell>{row.origin ? row.origin : "-"}</TableCell>
                      <TableCell>
                        {row.caller_name ? row.caller_name : "-"}
                      </TableCell>
                      <TableCell>
                        {row.caller_number ? row.caller_number : "-"}
                      </TableCell>
                      <TableCell>
                        {row.length_of_call ? row.length_of_call : "-"}
                      </TableCell>
                      <TableCell>
                        {row.purpose_of_call ? row.purpose_of_call : "-"}
                      </TableCell>
                      <TableCell>
                        {row.follow_up ? row.follow_up : "-"}
                      </TableCell>
                      <TableCell>
                        <Rating
                          name="read-only"
                          value={
                            row.caller_sentiment
                              ? parseInt(row.caller_sentiment)
                              : ""
                          }
                          readOnly
                        />
                      </TableCell>
                      <TableCell>{row.status ? row.status : "-"}</TableCell>
                      <TableCell>
                        {row.user ? row.user.UserName : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {list.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          onClick={() => handleModal(false)}
          color="primary"
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommunicationLogModal;

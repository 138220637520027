import React from "react";
import { Button, DialogActions, Dialog } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const CustomDateRange = ({
  handleShow,
  conformation,
  setDates,
  selectedStart,
  selectedEnd,
}) => {
  const [startDate, setStartDate] = React.useState(
    selectedStart ? selectedStart : new Date()
  );
  const [endDate, setEndDate] = React.useState(
    selectedEnd ? selectedEnd : new Date()
  );
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleDateRangeSelect = (ranges) => {
    let start = ranges.selection.startDate;
    let end = ranges.selection.endDate;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Dialog
      open={handleShow}
      onClose={() => conformation(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={(e) => handleDateRangeSelect(e)}
      />
      <DialogActions>
        <Button onClick={() => conformation(true)} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setDates(startDate, endDate);
            conformation(true);
          }}
          variant="contained"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDateRange;

import React from "react";
import { Box, Typography, Paper, Grid, Chip } from "@mui/material";

const EmailStatusDetails = ({ emailData }) => {
  const details = JSON.parse(emailData.details);
  const headers = details.message?.headers || {};
  const deliveryStatus = details["delivery-status"];
  const envelope = details.envelope || {};
  const recipientProvider = details["recipient-provider"];

  return (
    <Box
      sx={{
        fontFamily: "Arial, sans-serif",
        padding: 2,
        maxWidth: 900,
        margin: "0 auto",
        overflow: "hidden",
      }}
    >
      <Paper
        elevation={3}
        sx={{ padding: 3, borderRadius: 2, overflow: "hidden" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
          paddingBottom={1}
          borderBottom="2px solid #eee"
        >
          <Typography variant="h5">Email Delivery Status</Typography>
          {/* <Chip
            label={`Status Code: ${deliveryStatus?.code || "N/A"}`}
            sx={{
              backgroundColor: "#e8f5e9",
              color: "#2e7d32",
              fontWeight: "bold",
            }}
          /> */}
        </Box>

        <Paper
          sx={{
            padding: 2,
            backgroundColor: "#f8f9fa",
            borderLeft: "4px solid #4caf50",
            borderRadius: 1,
            overflow: "hidden",
          }}
        >
          <Chip
            label={
              emailData.status.charAt(0).toUpperCase() +
              emailData.status.slice(1)
            }
            sx={{
              backgroundColor: "#d4edda",
              color: "#155724",
              fontWeight: "bold",
              marginBottom: 2,
            }}
          />

          <Box
            marginTop={2}
            padding={2}
            backgroundColor="white"
            borderRadius={1}
          >
            <Typography variant="h6" color="textPrimary" marginBottom={1}>
              Email Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  From
                </Typography>
                <Typography variant="body1">{headers.from}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  To
                </Typography>
                <Typography variant="body1">{emailData.recipient}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Subject
                </Typography>
                <Typography variant="body1">{headers.subject}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Message ID
                </Typography>
                <Typography variant="body1">{emailData.message_id}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              backgroundColor: "#e8f5e9",
              padding: 2,
              borderRadius: 1,
              marginTop: 2,
            }}
          >
            <Typography variant="h6" color="textPrimary" marginBottom={1}>
              Delivery Status
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Status
                </Typography>
                <Typography variant="body1" sx={{ color: "#2e7d32" }}>{`${
                  emailData.status.charAt(0).toUpperCase() +
                  emailData.status.slice(1)
                } (Code: ${deliveryStatus?.code || "N/A"})`}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Server Response
                </Typography>
                <Typography variant="body1">
                  {deliveryStatus?.message || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  MX Host
                </Typography>
                <Typography variant="body1">
                  {deliveryStatus?.["mx-host"] || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Security
                </Typography>
                <Typography variant="body1">
                  {deliveryStatus?.tls ? "TLS Enabled, " : ""} Certificate
                  Verified:{" "}
                  {deliveryStatus?.["certificate-verified"] ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            marginTop={2}
            padding={2}
            backgroundColor="white"
            borderRadius={1}
          >
            <Typography variant="h6" color="textPrimary" marginBottom={1}>
              Technical Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Event ID
                </Typography>
                <Typography variant="body1">{emailData.event_id}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Sending IP
                </Typography>
                <Typography variant="body1">
                  {envelope["sending-ip"]}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Provider
                </Typography>
                <Typography variant="body1">{recipientProvider}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Delivery Attempt
                </Typography>
                <Typography variant="body1">
                  {deliveryStatus?.["attempt-no"] || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Typography variant="body2" color="textSecondary" marginTop={2}>
            Created at: {new Date(emailData.created_at).toLocaleString()}
          </Typography>
        </Paper>
      </Paper>
    </Box>
  );
};

export default EmailStatusDetails;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  FormHelperText,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { GetLfByControlId } from "../../../../api";
import { changeLocation } from "../../../../redux/slices/loginSlice";
import { useDispatch, useSelector } from "react-redux";
const ControlIdPopup = ({
  conrolIdPopup,
  setConrolIdPopup,
  conrolIdPopupOpen,
  conrolIdPopupClose,
}) => {
  const [getData, setGetData] = useState({});
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(false);
  const [error, seterror] = useState("");
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  // Check for permission for specific actions
  const hasPermissionToGetLfByControllId = permissions.some(
    (perm) =>
      perm.controller === "LostAndFound" &&
      perm.action_name === "getLfByControlId" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const handleChange = (event) => {
    setPayload(event.target.value);
  };
  const handleClickChange = () => {
    conrolIdPopupClose();
    let loc = getData.map(({ id, description, ...rest }) => rest);
    dispatch(changeLocation(loc));
  };
  const getLfByControlId = () => {
    GetLfByControlId(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setGetData(res.data);
          setLoading(true);
        } else {
          seterror("No item found");
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={conrolIdPopup}
        onClose={conrolIdPopupClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h5">
            Search by Lost and Found Control ID
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setConrolIdPopup(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4} mt={2}>
            <Grid item lg={6} mt={3}>
              <TextField
                id="outlined"
                label="Search Control Id "
                placeholder="Eter Control Id"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormHelperText error>{error}</FormHelperText>
            </Grid>
            <Grid item lg={6} mt={5}>
              <Button
                disabled={!hasPermissionToGetLfByControllId}
                onClick={getLfByControlId}
                variant="contained"
              >
                Search
              </Button>
            </Grid>
          </Grid>
          {loading && (
            <TableContainer
              sx={{
                marginTop: "15px",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Control Id </TableCell>
                    <TableCell align="right">Location Name</TableCell>
                    <TableCell align="right">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{getData?.id ? getData.id : ""}</TableCell>
                    <TableCell align="right">
                      {getData?.LocationName ? getData.LocationName : ""}
                    </TableCell>
                    <TableCell align="right">
                      {getData?.description ? getData.description : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        {loading && (
          <DialogActions sx={{ padding: "20px 24px" }}>
            <Button
              component={NavLink}
              to={`/lostandfound/edit-lost-found/${getData.id}`}
              onClick={handleClickChange}
              variant="contained"
              color="primary"
            >
              Go to Record
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ControlIdPopup;

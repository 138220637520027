import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import CustomTextField from "../../components/form-components/CustomTextField";
import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  FormControlLabel,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  startOverButton: {
    width: "100%",
    fontSize: "28px",
    borderRadius: "40px",
    padding: "12px 30px",
  },
  startOverCard: {
    "& .MuiTypography-root": {
      fontSize: "22px",
    },
  },
}));
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;

const KioskModeCheckIn = () => {
  const classes = useStyles();
  let initialValues = {
    recipient_first_name: "",
    recipient_last_name: "",
    sender_name: "",
    current_storage_location: "",
    carrier_dropping: "",
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div>
      <Box
        sx={{
          p: 5,
          my: "auto",
          marginTop: 10,
        }}
        className={classes.startOverCard}
      >
        <Card sx={{ maxWidth: "1140px", mx: "auto" }}>
          <CardHeader
            title={
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    Marigold Hotel
                  </Typography>
                </Box>
              </>
            }
          />
          <CardContent>
            <Formik initialValues={initialValues}>
              <form>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="recipient_first_name"
                        id="recipient-first-name"
                        placeholder="Mark"
                        label="Recipient first name"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="recipient_last_name"
                        id="recipient-last-name"
                        placeholder="Smith"
                        label="Recipient last name"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <FormControl>
                      <CustomTextField
                        name="sender_name"
                        id="sender-name"
                        placeholder="xxx-xxx-xxxx"
                        label="Mobile Number"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl>
                      <CustomTextField
                        name="sender_name"
                        id="sender-name"
                        placeholder="xxx-xxx-xxxx"
                        label="Length of stay"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                        shrink
                      >
                        Company
                      </InputLabel>
                      <Select
                        labelId="department"
                        id="demo-select-small"
                        value={age}
                        onChange={handleChange}
                        placeholder="Select template(optional)"
                      >
                        <MenuItem value="disables value">
                          Select template(optional)
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                        shrink
                      >
                        Visitor Type
                      </InputLabel>
                      <Select
                        labelId="department"
                        id="demo-select-small"
                        value={age}
                        onChange={handleChange}
                        placeholder="Select template(optional)"
                      >
                        <MenuItem value="disables value">
                          Select template(optional)
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        label="Host"
                        placeholder="Name of Person you are Visting"
                        name="other_pack_drop"
                        id="other-pack-name"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </Formik>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end", mt: "15px" }}>
            <Button component={NavLink} to="/visitors/kiosk-mode">
              Start over
            </Button>
          </CardActions>
        </Card>
      </Box>
    </div>
  );
};

export default KioskModeCheckIn;

import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";

import {
  IconButton,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  MenuList,
  MenuItem,
  Typography,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 250px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
    max-height: 350px;
    border: 0;
    box-shadow: 0px 4px 13px 3px #0000001c;
    border-radius: 12px;
  }
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  QuickLinksMenu: {
    "& .MuiList-root": {
      padding: 0,
      "& .MuiMenuItem-root": {
        borderBottom: "1px solid #0000001a",
      },
    },
    "& .MuiPaper-root::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },
    "& .MuiPaper-root::-webkit-scrollbar-track": {
      background: "#bed2f5",
    },
    "& .MuiPaper-root::-webkit-scrollbar-thumb": {
      background: "#92a6c9",
    },
  },
}));

function QuickLinks() {
  const quickLinksRef = useRef(null);
  const [quickLinks, quickLinksOpen] = useState(false);
  const quickLinksMenuOpen = () => {
    quickLinksOpen(true);
  };
  const quickLinksMenuClose = () => {
    quickLinksOpen(false);
  };
  const classes = useStyles();

  const locationArray = [
    {
      locationId: 1,
      locationName: "A Seattle collection",
    },
    {
      locationId: 2,
      locationName: "Ambridge hospitality las vegas south",
    },
    {
      locationId: 3,
      locationName: "Andaz Sen Deigo",
    },
    {
      locationId: 4,
      locationName: "A Seattle collection2",
    },
    {
      locationId: 5,
      locationName: "Ambridge hospitality las vegas south",
    },
    {
      locationId: 6,
      locationName: "Andaz Sen Deigo2",
    },
    {
      locationId: 7,
      locationName: "Big sky resort",
    },
    {
      locationId: 8,
      locationName: "Marigold Hotel",
    },
    {
      locationId: 9,
      locationName: "New Bay",
    },
    {
      locationId: 10,
      locationName: "Startup found",
    },
    {
      locationId: 11,
      locationName: "AMA group",
    },
    {
      locationId: 12,
      locationName: "Norwang Hotel",
    },
    {
      locationId: 13,
      locationName: "Yak collection",
    },
    {
      locationId: 14,
      locationName: "New Micheal",
    },
  ];

  return (
    <React.Fragment>
      {/* <Typography>ID</Typography> */}
      <Tooltip title="Quick Links">
        <IconButton
          color="inherit"
          ref={quickLinksRef}
          onClick={quickLinksMenuOpen}
          size="large"
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={quickLinksRef.current}
        onClose={quickLinksMenuClose}
        open={quickLinks}
        className={classes.QuickLinksMenu}
      >
        <MenuList>
          {locationArray.map((l) => (
            <MenuItem value={l.locationId} key={l.locationId}>
              <ListItemText primary={l.locationName} />
            </MenuItem>
          ))}

          {/* <MenuItem>
            <ListItemText primary="Ambridge hospitality las vegas south" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Andaz Sen Deigo" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Big sky resort" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="A Seattle collection" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Ambridge hospitality las vegas south" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Andaz Sen Deigo" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Big sky resort" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="A Seattle collection" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Ambridge hospitality las vegas south" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Andaz Sen Deigo" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Big sky resort" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="A Seattle collection" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Ambridge hospitality las vegas south" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Andaz Sen Deigo" />
          </MenuItem>
          <MenuItem>
            <ListItemText primary="Big sky resort" />
          </MenuItem> */}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
}

export default QuickLinks;

import React from "react";
import { ViewEquipmentUsers, UpdatePositionUsers } from "../../../api";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";

const EquipmentUserDialog = ({
  equipUserModal,
  equipUserModalClose,
  equipUserData,
  id,
  loadList,
  key_ring_id,
  getEditEquipmentUser,
  viewEquipDepartmentList,
}) => {
  //   const { id, user_id } = equipUserData;
  console.log({ loadList });
  const handleEquipmentUsers = () => {
    loadList == "users"
      ? ViewEquipmentUsers({
          user_id: equipUserData.id,
          check: "Y",
          equipment_id: id,
        })
          .then((res) => {
            if (res.statusCode === 200) {
              console.log(res.data);
              getEditEquipmentUser();
              equipUserModalClose();
            } else {
              console.log("error");
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : UpdatePositionUsers({
          pos_id: equipUserData.pos_id,
          check: "Y",
          equip_key: key_ring_id,
        })
          .then((res) => {
            if (res.statusCode === 200) {
              console.log(res.data);
              viewEquipDepartmentList();
              equipUserModalClose();
            } else {
              console.log("error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
  };
  return (
    <Dialog open={equipUserModal} onClose={equipUserModalClose}>
      <DialogContent>
        <DialogContentText>
          The keys shown in the list below are part of this key ring. Would you
          like to assign these keys to this user also?
        </DialogContentText>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={equipUserModalClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleEquipmentUsers}
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EquipmentUserDialog;

import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { updateShowAlert, updateText } from "../../redux/slices/mainSlice";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import GoogleAutoCompleteField from "../../components/form-components/GoogleAutoCompleteField";
import CameraFeeding from "../../helper/camera-feeding";
import CustomSelect from "../../components/form-components/CustomSelect";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomAlert from "../../components/form-components/CustomAlert";
import {
  AddLost,
  CategoryList,
  EstimatedList,
  StorageLocationList,
} from "../../api";

import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormHelperText,
  Box,
  Menu,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  RadioGroup,
  Paper,
  CircularProgress,
  IconButton,
  MenuItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  Error as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
  Menu as MenuIcon,
  Cancel as CancelIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DateTimePicker } from "@mui/lab";
import StorageLocationsDialog from "./inner-component/StorageLocationsDialog";
import ItemCategories from "./inner-component/ItemCategories";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

function AddLostAndFoundForm({
  storageLocation,
  getStorageLocationList,
  itemcatglist,
  getCategoryList,
}) {
  const [lostdate, setLostDate] = React.useState(null);
  const [arrivaldate, setArrivalDate] = React.useState(null);
  const [departuredate, setDepartureDate] = React.useState(null);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [takePhoto, setTakePhoto] = React.useState(false);
  const [followup, setFollowup] = React.useState(false);
  const [showWebCam, setShowWebCam] = React.useState(false);
  // Alert Message
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  // Estimated Value
  const [itemestlist, setItemEstList] = React.useState([]);
  const classes = useStyles();
  const navigate = useNavigate();
  // State for loader
  const [loading, setLoading] = React.useState(false);

  // Redux
  const CurrentLocationInfo = useSelector((state) => state.login.Location);
  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();

  const uploadedFileRef = useRef();

  React.useEffect(() => {
    getCategoryList();
    getEstimatedList();
    getStorageLocationList();
  }, []);
  // Sacn Upc Button Modal
  const [scanUpc, setScanUpc] = useState(false);

  const handleClickOpen = () => {
    setScanUpc(true);
  };

  const handleClose = () => {
    setScanUpc(false);
  };

  // Item Information Expansion
  const [iteminfoexpand, setItemInfo] = React.useState("expand-item-info");
  const handleItemExpansion = (panel) => (event, newExpanded) => {
    setItemInfo(newExpanded ? panel : false);
  };
  // Image Expansion
  const [imageexpand, setImageExpand] = React.useState("expand-image");
  const handleImgExpansion = (panel) => (event, newExpanded) => {
    setImageExpand(newExpanded ? panel : false);
  };
  // Lost Expansion
  const [lostexpand, setLostExpand] = React.useState("expand-lost");
  const handleLostExpansion = (panel) => (event, newExpanded) => {
    setLostExpand(newExpanded ? panel : false);
  };

  let initialValues = {
    parent_id: "",
    category: "",
    estimated_value: "",
    followup_required: "",
    description: "",
    internal_notes: "",
    lost_first_name: "",
    lost_last_name: "",
    lost_room_number: "",
    lost_date: "",
    lost_arrival_date: "",
    lost_departure_date: "",
    lost_street1: "",
    lost_street2: "",
    lost_city: "",
    lost_state: "",
    lost_zip: "",
    lost_country: "",
    lost_phone: "",
    lost_email: "",
    lost_location: "",
    lost_guest_notes: "",
    location_id: CurrentLocationInfo.LocationID,
    image: "",
  };

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Please select category."),
    estimated_value: Yup.string().required("Please select estimated value."),
    lost_first_name: Yup.string().required("Please enter first name."),
    lost_last_name: Yup.string().required("Please enter last name."),
    lost_location: Yup.string().required("Please enter lost location."),
    description: Yup.string().required(
      "Lost and found description should not be empty."
    ),
    lost_room_number: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    lost_zip: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    lost_phone: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    lost_email: Yup.string().nullable().email("Please enter a valid email."),
  });

  const getEstimatedList = () => {
    EstimatedList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemEstList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const uploadImage = async (file) => {
    getFileBase64(file, (result) => {
      setUploadedImage(result);
      setShowWebCam(false);
    });
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };

  const takeImageToWebCam = () => {
    let changeTakePhoto = !takePhoto;
    setTakePhoto(changeTakePhoto);
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };

  const handleChecked = (e) => {
    let updateFollowup = !followup;
    setFollowup(updateFollowup);
  };

  const someThingWrongAlert = (msg) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType("error");
    dispatch(updateShowAlert(true));
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let lost_date = lostdate ? moment(lostdate) : "";
      let lost_arrival_date = arrivaldate ? moment(arrivaldate) : "";
      let lost_departure_date = departuredate ? moment(departuredate) : "";

      lost_date = lost_date ? lost_date.format("YYYY-MM-DD HH:mm:ss") : "";
      lost_arrival_date = lost_arrival_date
        ? lost_arrival_date.format("YYYY-MM-DD HH:mm:ss")
        : "";
      lost_departure_date = lost_departure_date
        ? lost_departure_date.format("YYYY-MM-DD HH:mm:ss")
        : "";

      let formData = {
        parent_id: "",
        category: values.category,
        estimated_value: values.estimated_value,
        followup_required: followup === true ? "Y" : "N",
        description: values.description,
        internal_notes: values.internal_notes,
        lost_first_name: values.lost_first_name,
        lost_last_name: values.lost_last_name,
        lost_room_number: values.lost_room_number,
        lost_date: lost_date,
        lost_arrival_date: lost_arrival_date,
        lost_departure_date: lost_departure_date,
        lost_street1: values.lost_street1,
        lost_street2: values.lost_street2,
        lost_city: values.lost_city,
        lost_state: values.lost_state,
        lost_zip: values.lost_zip,
        lost_country: values.lost_country,
        lost_phone: values.lost_phone,
        lost_email: values.lost_email,
        lost_location: values.lost_location,
        lost_guest_notes: values.lost_guest_notes,
        location_id: values.location_id,
        image: uploadedImage,
      };
      AddLost(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(updateShowAlert(true));
            dispatch(updateText("Record has been submitted successfully"));
            navigate({ pathname: "/lostandfound/list-lost-found" });
          } else {
            someThingWrongAlert("Sorry! something wrong please try again");
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            someThingWrongAlert(error);
          }
        });
    } catch (error) {
      someThingWrongAlert("Sorry! something wrong please try again");
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Box className={classes.accordion}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} lg={6}>
                      <Accordion
                        mt={0}
                        expanded={iteminfoexpand === "expand-item-info"}
                        onChange={handleItemExpansion("expand-item-info")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="item-info"
                          id="item-infor"
                        >
                          <Typography>Item Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={8}>
                              <FormControl mt={3}>
                                <TextField
                                  fullWidth
                                  id="controlid"
                                  label="Control ID"
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="System generated"
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              lg={4}
                              alignSelf="self-end"
                              align="right"
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpen}
                              >
                                Scan UPC
                              </Button>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="category"
                                  fieldLabel="Item category *"
                                  options={itemcatglist}
                                  displayEmpty
                                />
                                <FormHelperText>
                                  {errors.category}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="estimated_value"
                                  fieldLabel="Estimated value *"
                                  options={itemestlist}
                                  displayEmpty
                                />
                                <FormHelperText>
                                  {errors.estimated_value}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormHelperText>
                                Choose 'Laptop' above for all Tablets, iPads,
                                Kindles, Macbooks, etc.
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "15px",
                                }}
                              >
                                <FormLabel>Followup required</FormLabel>
                                <Tooltip title="Highlights row in list, also copies record to followup tab">
                                  <ErrorIcon color="primary" />
                                </Tooltip>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableRipple
                                      name="followup_required"
                                      value={values.followup_required}
                                      onChange={(e) => handleChecked(e)}
                                    />
                                  }
                                  label=""
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="description"
                                  label="Description *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Make model, color, material, size, etc."
                                  multiline={true}
                                  rows={4}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="internal_notes"
                                  label="Internal Notes "
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Notes about the item, owner, a phone call you had with them, etc."
                                  multiline={true}
                                  rows={4}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        mt={6}
                        expanded={imageexpand === "expand-image"}
                        onChange={handleImgExpansion("expand-image")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="image-from-group"
                          id="image-from-group"
                        >
                          <Typography>Image</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                {uploadedImage ? (
                                  <Image alt="image name" src={uploadedImage} />
                                ) : (
                                  <Image
                                    alt="image name"
                                    src="/static/img/lost-found/lost_and_found_pic.jpg"
                                  />
                                )}

                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    JustifyContent: "space-between",
                                    margin: "10px 0 15px",
                                    gap: "15px",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    component="span"
                                    onClick={(e) => clearSelectedImage(e)}
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="warning"
                                    component="span"
                                    onClick={() => handleShowWebCam()}
                                  >
                                    {showWebCam ? "Close WebCam" : "WebCam"}
                                  </Button>
                                  {/* {showWebCam && (
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      component="span"
                                      onClick={() => takeImageToWebCam()}
                                    >
                                      Capture
                                    </Button>
                                  )} */}
                                </Box>
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id="raised-button-file"
                                  multiple
                                  type="file"
                                  ref={uploadedFileRef}
                                  onChange={(e) => handleFileChange(e)}
                                />
                                <label htmlFor="raised-button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                  >
                                    Choose image
                                  </Button>
                                </label>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Accordion
                        mt={0}
                        expanded={lostexpand === "expand-lost"}
                        onChange={handleLostExpansion("expand-lost")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="lost-form-group"
                          id="lost-form-group"
                        >
                          <Typography>Lost</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_first_name"
                                  label="First name *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Mike"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_last_name"
                                  label="Last name *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Smith"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_room_number"
                                  label="Room number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="1256"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <DateTimePicker
                                  id="lost_date"
                                  name="lost_date"
                                  label="Lost date"
                                  value={lostdate}
                                  onChange={(event) => {
                                    setLostDate(event);
                                  }}
                                  inputFormat="yyyy-MM-dd HH:mm:ss"
                                  defaultDate={values.lost_date}
                                  // maxDate={
                                  //   departuredate ? departuredate : new Date()
                                  // }
                                  // minDate={
                                  //   arrivaldate ? arrivaldate : new Date()
                                  // }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "yyyy-mm-dd hh:mm:ss",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <DateTimePicker
                                  id="lost_arrival_date"
                                  label="Arrival date"
                                  value={arrivaldate}
                                  onChange={(newValue) => {
                                    setArrivalDate(newValue);
                                  }}
                                  inputFormat="yyyy-MM-dd HH:mm:ss"
                                  defaultDate={values.lost_arrival_date}
                                  // maxDate={new Date()}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "yyyy-mm-dd hh:mm:ss",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <DateTimePicker
                                  id="departure-date"
                                  label="Departure date"
                                  value={departuredate}
                                  onChange={(newValue) => {
                                    setDepartureDate(newValue);
                                  }}
                                  inputFormat="yyyy-MM-dd HH:mm:ss"
                                  defaultDate={values.lost_departure_date}
                                  // minDate={
                                  //   arrivaldate ? arrivaldate : new Date()
                                  // }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "yyyy-mm-dd hh:mm:ss",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormLabel>Address</FormLabel>
                              <FormControl mt={3}>
                                <GoogleAutoCompleteField
                                  name="lost_street1"
                                  label="Street line1"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="3214 N University Ave"
                                  getApiResponse={(res) => {
                                    setFieldValue("lost_street1", res.address);
                                    setFieldValue("lost_city", res.locality);
                                    setFieldValue(
                                      "lost_state",
                                      res.administrative_area_level_1
                                    );
                                    setFieldValue("lost_zip", res.postal_code);
                                    setFieldValue("lost_country", res.country);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_street2"
                                  label="Street line2"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Enter suite and apt numbers in field above"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_city"
                                  label="City"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Provo"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_state"
                                  label="State"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Utah"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_zip"
                                  label="Zip code"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="84604"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_country"
                                  label="Country"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="USA"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_phone"
                                  label="Phone"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="XXX-XXX-XXXX"
                                  inputProps={{ maxLength: 20 }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_email"
                                  label="Email"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="me@you.com"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_location"
                                  label="Lost location *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Include specifics"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CustomTextField
                                  name="lost_guest_notes"
                                  label="Notes on guest"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Optional data here about the guest"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Button color="primary" variant="contained" type="submit">
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              {showAlert && (
                <CustomAlert
                  message={alertMessage}
                  severity={alertType}
                  closeAlert={(show) => dispatch(updateShowAlert(show))}
                  showAlert={showAlert}
                />
              )}
            </Card>
          </form>
        )}
      </Formik>
      {showWebCam && (
        // <CameraFeed
        //   sendFile={uploadImage}
        //   takePhoto={takePhoto}
        // />

        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      <Dialog open={scanUpc} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle>Scan UPC</DialogTitle>
          <CancelIcon
            onClick={handleClose}
            sx={{ cursor: "pointer", marginRight: "6px" }}
          />
        </Box>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              Scan or enter a barcode UPC in the box below, then click search.
              If a match is found, a portion of the product data will be
              automatically added to the record, saving you time.
            </Typography>
          </DialogContentText>
          <Grid container spacing={6}>
            <Grid item xs={4} lg={4} mt={4}>
              <Typography variant="body2">UPC</Typography>
              <TextField
                id="outlined"
                label="Enter upc code"
                variant="outlined"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={4} lg={4} mt={4}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                sx={{ marginTop: "47px" }}
              >
                <SearchIcon sx={{ marginRight: "5px" }} />
                Search
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function AddLostAndFound() {
  const navigate = useNavigate();
  //state for lost and found option dialog
  const [lostFoundDialog, setLostFoundDialog] = useState(true);
  // State for anchorEl
  const [anchorEl, setAnchorEl] = useState(null);
  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // storage locations
  const [storageLocation, setStorageLocation] = useState([]);
  const getStorageLocationList = () => {
    StorageLocationList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setStorageLocation(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [storageLocationsDialog, setStorageLocationsDialog] = useState(false);
  const storageLocationsDialogOpen = () => {
    setStorageLocationsDialog(true);
  };

  const storageLocationsDialogClose = () => {
    setStorageLocationsDialog(false);
  };

  // item categories
  const [itemcatglist, setItemCatgList] = useState([]);
  const getCategoryList = () => {
    CategoryList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemCatgList(
            res.data.map((item) => ({ key: item.key, value: item.key }))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [itemCategoriesDialog, setItemCategoriesDialog] = useState(false);
  const itemCategoriesDialogOpen = () => {
    setItemCategoriesDialog(true);
  };

  const itemCategoriesDialogClose = () => {
    setItemCategoriesDialog(false);
  };

  return (
    <React.Fragment>
      <WithPermissionFallback controller="LostAndFound" action="addLost">
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Helmet title="Add Lost and Found" />
            <Typography variant="h3" gutterBottom display="inline">
              Add Lost and Found
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/lostandfound/list-lost-found">
                List Lost and Found
              </Link>
              <Typography>Add Lost and Found</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} md={6} textAlign="right">
            <IconButton
              aria-label="Menu"
              size="large"
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={(e) => handleActionClick(e)}
              style={{ color: "#1374C5" }}
            >
              <MenuIcon />
            </IconButton>
            <StyledMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleActionClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  storageLocationsDialogOpen();
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary={"Storage Locations"} pl={1} />
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  itemCategoriesDialogOpen();
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary={"Item Categories"} pl={1} />
              </MenuItem>
            </StyledMenu>
          </Grid>
        </Grid>
        <Divider my={6} />
        <AddLostAndFoundForm
          storageLocation={storageLocation}
          getStorageLocationList={getStorageLocationList}
          itemcatglist={itemcatglist}
          getCategoryList={getCategoryList}
        />
        {storageLocationsDialog && (
          <StorageLocationsDialog
            storageLoctaionsDialog={storageLocationsDialog}
            storageLocationsDialogClose={storageLocationsDialogClose}
            storageLocation={storageLocation}
            getStorageLocationList={getStorageLocationList}
          />
        )}
        {itemCategoriesDialog && (
          <ItemCategories
            itemCategoriesDialog={itemCategoriesDialog}
            itemCategoriesDialogClose={itemCategoriesDialogClose}
            getCategoryList={getCategoryList}
          />
        )}
      </WithPermissionFallback>
      {lostFoundDialog && (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
              },
            },
          }}
          open={lostFoundDialog}
          // onClose={() => navigate(-1)}
          aria-labelledby="visitor-modal"
          aria-describedby="visitor-modal"
        >
          <DialogTitle
            id="visitor-modal"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: "0px",
            }}
          >
            <DialogActions sx={{ padding: "0px" }}>
              <IconButton
                sx={{ marginRight: "-10px" }}
                onClick={() => navigate(-1)}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
          <DialogContent sx={{ padding: "0px" }}>
            <DialogContentText sx={{ padding: "0px 20px 20px" }}>
              <Box align="center">
                <Typography variant="h4" component="div" mt={3} align="center">
                  Add Lost and Found Item
                </Typography>
                <Typography variant="p" component="div" mt={3} align="center">
                  Are you adding a Lost or a Found item?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "center", paddingBottom: "30px" }}
          >
            <Button
              variant="outlined"
              color="primary"
              // component={NavLink}
              // to="/lostandfound/add-lost-found"
              onClick={() => setLostFoundDialog(false)}
            >
              Lost
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: "10px" }}
              component={NavLink}
              to="/lostandfound/add-found"
            >
              Found
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default AddLostAndFound;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Radio,
  RadioGroup,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Box,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { ViewSwitchDummyData, UpdateSwitchDummyData } from "../../../api";

const SwitchDummyData = () => {
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [switchStatus, setSwitchStatus] = useState("");
  const [adminSuId, setAdminSuId] = useState("");

  useEffect(() => {
    ViewSwitchDummyData()
      .then((res) => {
        if (res.statusCode == 200) {
          const dummy_text = res.data.dummy_text == 1 ? "inactive" : "active";
          setId(res.data.id);
          setSwitchStatus(dummy_text);
          setAdminSuId(res.data.su_id);
          localStorage.setItem("switch_dummy_text", dummy_text);
          localStorage.setItem("su_id", res.data.su_id);
        } else {
          setId(res.data.id);
          setSwitchStatus(res.data.dummy_text);
          setAdminSuId(res.data.su_id);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  }, []);

  const handleChange = async (event) => {
    const value = event.target.value;
    setSwitchStatus(value);
  };

  //handle save api
  const handleSubmit = () => {
    try {
      let formData = {
        dummy_text: switchStatus,
        su_id: adminSuId,
      };
      UpdateSwitchDummyData(id, formData)
        .then((res) => {
          if (res.statusCode === 200) {
            // set switch dummy data values in local storage
            localStorage.setItem("switch_dummy_text", switchStatus);
            localStorage.setItem("su_id", adminSuId);

            dispatch(getMsg("Settings has been updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Show or Hide Client Data
      </Typography>
      <FormControl
        sx={{
          marginTop: "25px",
        }}
      >
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={switchStatus}
          onChange={handleChange}
        >
          <FormControlLabel
            value="active"
            control={<Radio />}
            label="Show Real Data"
          />
          <FormControlLabel
            value="inactive"
            control={<Radio />}
            label="Show Dummy Data"
          />
        </RadioGroup>
      </FormControl>
      <Grid container spacing={4}>
        <Grid item lg={3} md={3} mt={5}>
          <TextField
            id="admin-id"
            label="SU or AdminID"
            value={adminSuId}
            placeholder="1_1"
            onChange={(e) => setAdminSuId(e.target.value)}
            InputLabelProps={{
              shrink: "true",
            }}
          />
        </Grid>
      </Grid>
      <Box mt={7}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </>
  );
};

export default SwitchDummyData;

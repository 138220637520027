import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { sova } from "../../config";

import { Helmet } from "react-helmet-async";
import {
  Typography,
  Button,
  Paper as MuiPaper,
  AppBar,
  Toolbar,
  Select,
  MenuItem,
  Grid,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
const Paper = styled(MuiPaper)(spacing);
const Image = styled.img``;

function SurveyDoneMessage() {
  const location = useLocation();
  const [selectLanguage, setSelectLanguage] = useState(1);
  const [covidSurveyData, setCovidSurveyData] = useState({});
  const data = location.state.data.data;
  const locationName = location.state.data.locationName;
  const handleChange = (event) => {
    setSelectLanguage(event.target.value);
  };

  const path = sova.SOVA_API.split("/api")[0];
  useEffect(() => {
    setCovidSurveyData(data);
  }, [data]);
  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: "#233044" }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Image
            src={`/static/img/brands/logo.svg`}
            style={{
              height: 40,
              marginLeft: 0,
              marginBottom: 1.75,
              marginTop: 1.75,
            }}
          />
          <Select
            sx={{
              minWidth: 160,
              marginBottom: 1.75,
              marginTop: 1.75,
              color: "#ffffff",
              border: "1px solid #ffffff8a",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiSvgIcon-root": {
                color: "#ffffff",
              },
            }}
            labelId="select-language-label"
            id="select-language-helper"
            value={selectLanguage}
            onChange={handleChange}
          >
            <MenuItem value={1}>English</MenuItem>
            <MenuItem value={2}>Spanish</MenuItem>
          </Select>
        </Toolbar>
      </AppBar>
      <Paper
        sx={{
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 3.75,
          paddingRight: 3.75,
        }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            lg={9}
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Image
                src={`/static/img/marigold.tmp`}
                style={{ maxWidth: 200 }}
              />
            </Box>
            <Typography
              component="p"
              variant="body2"
              sx={{
                textAlign: "center",
                fontSize: 19,
                fontWeight: 300,
                mb: 15,
                mt: 5,
              }}
            >
              Your Covid-19 screening survey has been successfully submitted for
              your upcoming visit and a barcode has been generated below. An
              employee will scan this barcode when you arrive so please take a
              screenshot or keep this browser window on top to speed up
              processing. Follow all health and safety guidelines posted at the
              entrance to this business. If you experience any symptoms during
              your stay that would cause your survey responses to change, inform
              an employee immediately. A new survey must be completed each day
              before you arrive. Thank you for your help, and stay safe!
            </Typography>

            <Box sx={{ textAlign: "center" }}>
              <Image
                src={path + covidSurveyData.fileName}
                style={{ maxWidth: 200 }}
              />
              <Button
                fullWidth
                color="primary"
                variant="contained"
                sx={{ mt: 10 }}
                component={NavLink}
                to={`/screening-survey/${locationName}`}
              >
                RETURN TO SURVEY
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

function SurveyDone() {
  return (
    <React.Fragment>
      <Helmet title="Screening Survey" />
      <SurveyDoneMessage />
    </React.Fragment>
  );
}

export default SurveyDone;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import EditCommunicationLog from "./EditCommunicationLog";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Paper,
  Select,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  RadioGroup,
  Radio,
  DialogActions,
  FormLabel,
  FormControlLabel,
  Grid,
  ToggleButtonGroup,
  TextareaAutosize as MuiTextareaAutosize,
  ToggleButton,
  Rating,
  FormHelperText,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  MenuItem,
  Card,
  Menu,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import {
  CancelRounded as CancelRoundedIcon,
  Restore as RestoreIcon,
  Fullscreen as FullscreenExitIcon,
  Redo as RedoIcon,
  FormatBold as FormatBoldIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  LocalPrintshop as LocalPrintshopIcon,
  Code as CodeIcon,
  Undo as UndoIcon,
  Settings as SettingsIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { DateTimePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import { makeStyles } from "@mui/styles";
import MUIRichTextEditor from "mui-rte";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import CustomSelection from "../../../components/form-components/CustomSelection";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  accordion: {
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  textEditor: {
    position: "relative",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    borderRadius: "4px",
    "& #mui-rte-container": {
      marginTop: 0,
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      position: "absolute",
      padding: "15px",
      display: "block",
    },
    "& #mui-rte-editor": {
      height: "180px",
      overflowY: "auto",
    },
    "& #mui-rte-editor-container": {
      height: "170px",
      overflowY: "auto",
      borderTop: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    },
    "& #mui-rte-toolbar": {
      padding: "5px 15px",
    },
    "& .DraftEditor-editorContainer": {
      padding: "15px",
    },
  },
}));
function CommunicationLogTable() {
  const [ratings, setRatings] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  // Edit communicattion log Modal
  const [editCommunicationLog, setEditCommunicationLog] = useState(false);
  const editCommunicationLogOpen = () => {
    setEditCommunicationLog(true);
  };
  const editCommunicationLogClose = () => {
    setEditCommunicationLog(false);
  };
  return (
    <>
      <Card variant="outlined" sx={{ borderBottom: 0 }}>
        <TableContainer className="tableContainer">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>DateTime</TableCell>
                <TableCell>Origin</TableCell>
                <TableCell>Caller Name</TableCell>
                <TableCell>Caller Number</TableCell>
                <TableCell>Length Of Call</TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Follow Up</TableCell>
                <TableCell>Caller Sentiment</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Recipient</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell>02/08/2022</TableCell>
                <TableCell>Notes Only</TableCell>
                <TableCell>Testing</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>testing</TableCell>
                <TableCell>
                  <Rating
                    name="ratings-controlled"
                    value={ratings}
                    onChange={(event, newValue) => {
                      setRatings(newValue);
                    }}
                  />
                </TableCell>
                <TableCell>Closed</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <IconButton
                    aria-owns={anchorEl ? "icon-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleActionClick}
                    variant="contained"
                    color="primary"
                  >
                    <SettingsIcon />
                  </IconButton>
                  <StyledMenu
                    id="icon-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleActionClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem onClick={editCommunicationLogOpen}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText size="large" primary="Edit" pl={0} />
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText size="large" primary="Delete" pl={0} />
                    </MenuItem>
                  </StyledMenu>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {editCommunicationLog && (
          <EditCommunicationLog
            editCommunicationLog={editCommunicationLog}
            setEditCommunicationLog={setEditCommunicationLog}
            editCommunicationLogOpen={editCommunicationLogOpen}
            editCommunicationLogClose={editCommunicationLogClose}
          />
        )}
      </Card>
    </>
  );
}
const CommunicationLog = ({ communicationLog, communicationLogClose }) => {
  const [dateTime, setDateTime] = React.useState(null);
  const classes = useStyles();

  // Select Length Of Call
  const [lengthOfCall, setLengthOfCall] = React.useState("");
  const selectLengthOfCall = (event) => {
    setLengthOfCall(event.target.value);
  };
  const [purposeBtn, setPurposeBtn] = React.useState("undobtn");
  const handlePurposeBtn = (event, newPurposeBtn) => {
    setPurposeBtn(newPurposeBtn);
  };
  const [followUpBtn, setFollowUpBtn] = React.useState("undobtn");
  const handleFollowUpBtn = (event, newPurposeBtn) => {
    setFollowUpBtn(newPurposeBtn);
  };
  const [ratings, setRatings] = React.useState(1);

  const callLength = [
    { key: "Unanswered", value: "Unanswered" },
    { key: "< 2 Minutes", value: "<2Minutes" },
    { key: "2-5 Minutes", value: "2-5 Minutes" },
    { key: "5-10 Minutes", value: "5-10 Minutes" },
    { key: "10-20 Minutes", value: "10-20 Minutes" },
    { key: "> 20 Minutes", value: ">20Minutes" },
  ];

  let initialValues = {
    com_datetime: null,
    origin: "Incoming",
    type: "Phone",
    caller_name: "",
    caller_number: "",
    length_of_call: "",
    purpose_of_call: "",
    follow_up: "",
    caller_sentiment: 1,
    status: "Open",
  };

  const validationSchema = Yup.object().shape({
    com_datetime: Yup.string().nullable().required("Please select date/time"),
    origin: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Incoming", "Outgoing", "Notes Only"]),
    type: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Phone", "Email", "SMS"]),
    caller_name: Yup.string().required("Please enter value"),
    caller_number: Yup.number()
      .nullable()
      .when(["origin"], (originValue, schema) => {
        return originValue === "Incoming" || originValue === "Outgoing"
          ? schema
              .positive("Please enter a valid number")
              .typeError("Please enter a valid number")
              .required("Please enter a value")
          : schema;
      }),
    length_of_call: Yup.string().when(
      ["origin", "type"],
      (originValue, typeValue, schema) => {
        return originValue === "Notes Only" || typeValue === "SMS"
          ? schema
          : schema.required("Please select call length");
      }
    ),
    caller_sentiment: Yup.number()
      .required("Please select caller sentiment")
      .positive("Please select caller sentiment")
      .typeError("Please select caller sentiment"),
    // .when(["type"], (originValue, schema) => {
    //   return originValue === "SMS"
    //     ? schema
    //     : schema.required("Please select call length");
    // }),
    // com_datetime: Yup.string().required("Please select date."),
    // caller_name: Yup.string().required("Please enter value."),
    // follow_up: Yup.string().required("Please enter value."),
    // caller_sentiment: Yup.number()
    //   .required("Please select caller sentiment.")
    //   .positive("Please select caller sentiment.")
    //   .typeError("Please select caller sentiment."),
    // caller_number: Yup.number()
    //   .nullable()
    //   .when(["origin"], (originValue, schema) => {
    //     return originValue === "Incoming" || originValue === "Outgoing"
    //       ? schema
    //           .positive("Please enter a valid number.")
    //           .typeError("Please enter a valid number.")
    //           .required("Please enter a value.")
    //       : schema;
    //   }),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      // setLoading(true);
      let formData = {
        lost_found_id: values.lost_found_id,
        log_type: values.log_type,
        com_datetime: values.com_datetime,
        origin: values.origin,
        type: values.type,
        caller_sentiment: values.caller_sentiment,
        caller_name: values.caller_name,
        follow_up: values.follow_up,
        status: values.status,
        caller_number:
          values.origin === "Notes Only" ? "" : values.caller_number,
        length_of_call:
          values.origin === "Notes Only" ? "" : values.length_of_call,
        purpose_of_call:
          values.origin === "Notes Only" ? "" : values.purpose_of_call,
      };

      // SaveCommunicationLog(formData)
      //   .then((res) => {
      //     setLoading(false);
      //     if (res.statusCode === 200) {
      //       showAlertTost(res.message, "success");
      //       handleModal(false);
      //     } else {
      //       showAlertTost("Sorry! something wrong please try again", "error");
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     let errors = err.data.errors;
      //     let error = handleApiResonseErrors(errors);
      //     if (error) {
      //       showAlertTost(error, "error");
      //     }
      //   });
    } catch (error) {
      // setLoading(false);
      // showAlertTost("Sorry! something wrong please try again", "error");
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="comm-log-title"
        aria-describedby="comm-log-description"
        open={communicationLog}
        onClose={communicationLogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1200px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="comm-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Communication Log
          </Typography>
          <DialogActions>
            <IconButton
              onClick={communicationLogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>ID: 121539018</DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <FormControl mt={6}>
                      <DateTimePicker
                        id="date-range"
                        label="Datetime *"
                        value={values.com_datetime}
                        onChange={(newValue) => {
                          setFieldValue("com_datetime", newValue);
                        }}
                        inputFormat="MM/dd/yyyy HH:mm"
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="com_datetime"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <FormLabel>Origin</FormLabel>
                    <RadioGroup
                      aria-labelledby="origin"
                      name="origin"
                      value={values.origin.toString()}
                      onChange={(e) => {
                        setFieldValue("origin", e.currentTarget.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Incoming"
                        value="Incoming"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Outgoing"
                        value="Outgoing"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Notes Only"
                        value="Notes Only"
                      />
                    </RadioGroup>
                    <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                      {touched.origin && errors.origin}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <FormLabel>Type *</FormLabel>
                    <RadioGroup
                      aria-labelledby="type"
                      name="type"
                      value={values.type.toString()}
                      onChange={(e) => {
                        setFieldValue("type", e.currentTarget.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Phone"
                        value="Phone"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Email"
                        value="Email"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="SMS"
                        value="SMS"
                      />
                    </RadioGroup>
                    <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                      {touched.type && errors.type}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <FormControl>
                      <CustomTextField
                        name="caller_name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={
                          values.origin === "Incoming"
                            ? "Person receiving message *"
                            : values.origin === "Outgoing"
                            ? "Person Called *"
                            : "Note Added By *"
                        }
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  {(values.origin === "Outgoing" ||
                    values.origin === "Incoming") && (
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                      <FormControl>
                        <CustomTextField
                          name="caller_number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Phone/SMS *"
                          autoComplete="off"
                          placeholder=""
                          inputProps={{ maxLength: 20 }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {(values.origin === "Outgoing" ||
                    values.origin === "Incoming") &&
                    values.type !== "SMS" && (
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <FormControl>
                          <CustomSelection
                            name="length_of_call"
                            label="Call Length *"
                            options={callLength}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  {(values.origin === "Outgoing" ||
                    values.origin === "Incoming") && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <FormControl>
                        <FormLabel>Purpose</FormLabel>
                        <Box
                          className={classes.textEditor}
                          sx={{ marginTop: "15px" }}
                        >
                          <MUIRichTextEditor
                            label={
                              <Typography sx={{ padding: "15px" }}>
                                Type something here...
                              </Typography>
                            }
                            // defaultValue={values.narrative}
                            inlineToolbar={true}
                            onChange={(e) => {
                              setFieldValue(
                                "purpose_of_call",
                                e.getCurrentContent().getPlainText()
                              );
                            }}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl>
                      <FormLabel>Followup</FormLabel>
                      <Box
                        className={classes.textEditor}
                        sx={{ marginTop: "15px" }}
                      >
                        <MUIRichTextEditor
                          label={
                            <Typography sx={{ padding: "15px" }}>
                              Type something here...
                            </Typography>
                          }
                          // defaultValue={values.narrative}
                          inlineToolbar={true}
                          onChange={(e) => {
                            setFieldValue(
                              "follow_up",
                              e.getCurrentContent().getPlainText()
                            );
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <FormControl>
                      <FormLabel>Caller sentiment</FormLabel>
                      <FormHelperText sx={{ marginLeft: 0 }}>
                        Select how the call interaction was. Higher stars
                        represent a more positive experience.
                      </FormHelperText>
                      <Rating
                        name="caller_sentiment"
                        value={
                          values.caller_sentiment
                            ? parseInt(values.caller_sentiment)
                            : 0
                        }
                        onChange={(event, newValue) => {
                          setFieldValue("caller_sentiment", newValue);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <FormLabel>Status</FormLabel>
                    <RadioGroup
                      aria-labelledby="status"
                      name="status"
                      value={values.status.toString()}
                      onChange={(e) =>
                        setFieldValue("status", e.currentTarget.value)
                      }
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Open"
                        value="Open"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Closed"
                        value="Closed"
                      />
                    </RadioGroup>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <FormLabel>Paste screenshot</FormLabel>
                  </Grid> */}
                </Grid>
                <DialogActions sx={{ padding: "20px 0" }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={communicationLogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    sx={{ marginLeft: "10px" }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
          <Paper mt={6}>
            <CommunicationLogTable />
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CommunicationLog;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import moment from "moment";
import { format } from "date-fns";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RecentScans from "./inner-components/RecentScans";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonHistoryList,
  TourAutocompleteList,
  GetButtonName,
  ButtonHistoryColumns,
} from "../../api";
import {
  buttonHistoryList,
  getButtonHistoryChecked,
} from "../../redux/slices/reportSlice";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import { CSVLink } from "react-csv";
import WithPermissionFallback from "../../utils/withPermissionFallback";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      "& a": {
        color:
          theme.palette.mode === "light" ? "#374151" : theme.palette.grey[300],
        textDecoration: "none !important",
      },
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      // "& .MuiMenuItem-root:hover": {
      //   background: "transparent",
      // },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(uname, datetime, tourname, buttonname) {
  return {
    uname,
    datetime,
    tourname,
    buttonname,
    // action,
  };
}
const rows = [
  createData("Sandeep Kundu", 0, "tour name one", "lorem Ipsum"),
  createData("Sandeep Singh", 0, "tour name one", "lorem Ipsum"),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "user_name",
    numeric: true,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "datetime",
    numeric: true,
    disablePadding: false,
    label: "Datetime",
  },
  {
    id: "tourname",
    numeric: true,
    disablePadding: false,
    label: "Tour Name",
  },
  {
    id: "buttonname",
    numeric: true,
    disablePadding: false,
    label: "Button Name",
  },
  //{ id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector(
    (state) => state.report.buttonHistoryChecked
  );

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell> */}
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.uname ? order : false}
          >
            <TableSortLabel
              active={
                headCell.key === "user_name" ? orderBy === headCell.key : false
              }
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={
                headCell.key === "user_name"
                  ? createSortHandler(headCell.key)
                  : null
              }
              hideSortIcon={headCell.key === "user_name" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    filterRecord,
    dataToFilter,
    setDataToFilter,
    startDateToFilter,
    setStartDateToFilter,
    endDateToFilter,
    setEndDateToFilter,
    handleFirstPage,
    colArray,
    getColumnList,
    getColumnChecked,
    setCurrentPage,
  } = props;
  const [actions, setActions] = React.useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [tourSuggestion, setTourSuggestion] = useState([]);
  const [buttonSuggestion, setButtonSuggestion] = useState([]);
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [csvDataList, setCsvDataList] = useState([]);

  const checkedValue = useSelector(
    (state) => state.report.buttonHistoryChecked
  );

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setCheckboxStatus(event.target.checked);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    getButtonHistoryCSV();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };

  //Header for CSV
  let exportCSVheaders = [
    { label: "User Name", key: "user_name" },
    { label: "Datetime", key: "scan_time" },
    { label: "Tour Name", key: "location" },
    { label: "Button Name", key: "tag_description" },
  ];
  // Tour Select dropdown
  const [tourSelect, setTourSelect] = React.useState("");
  const selectTour = (event) => {
    setTourSelect(event.target.value);
    GetButtonName(event.target.value)
      .then((res) => {
        if (res.statusCode === 200) {
          setButtonSuggestion(res.data);
        } else {
          setButtonSuggestion([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Button Select
  const [buttonSelect, setButtonSelect] = React.useState("");
  const selectButton = (event) => {
    setButtonSelect(event.target.value);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  const applySearch = () => {
    const payload = {
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      tourId: tourSelect,
      tagId: buttonSelect,
      tour_comment: checkboxStatus,
      user_name: searchInput,
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    setTourSelect("");
    setButtonSelect("");
    setCheckboxStatus(false);
    setButtonSuggestion([]);
    filterRecord({});
  };

  //Api call for  select corporate dropdown
  const getTourList = () => {
    TourAutocompleteList()
      .then((res) => {
        if (res.statusCode == 200) {
          setTourSuggestion(res.data);
        } else {
          setTourSuggestion([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Methods for calling the APIs
  const getButtonHistoryCSV = () => {
    let payload = {
      limit: totalRecord,
    }; //this will be used for sending filtered data
    if (totalRecord !== 0) {
      ButtonHistoryList(1, payload)
        .then((res) => {
          if (res.statusCode == 200) {
            setCsvDataList(res.data);
          } else {
            setCsvDataList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getButtonHistoryChecked([...checkedValue, value]));
    } else {
      dispatch(
        getButtonHistoryChecked(checkedValue.filter((e) => e !== value))
      );
    }
  };

  useEffect(() => {
    getTourList();
  }, []);

  return (
    <Toolbar>
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              type="text"
              placeholder="Search by username, or click down arrow"
              autoComplete="off"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                  // handleFirstPage();
                  setCurrentPage(1);
                }
              }}
              inputProps={{}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form>
                          <FormControl mt={3}>
                            <InputLabel
                              shrink
                              id="tour"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Tour
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="tour"
                              value={tourSelect}
                              onChange={selectTour}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                Click to select
                              </MenuItem>
                              {tourSuggestion.map((t) => (
                                <MenuItem value={t.TourID} key={t.TourID}>
                                  {t.TourName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="button"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Button
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="button"
                              value={buttonSelect}
                              onChange={selectButton}
                              fullWidth
                              displayEmpty
                            >
                              {tourSelect === "" ? (
                                <MenuItem value="">No tour selected</MenuItem>
                              ) : (
                                <MenuItem value="">Click to select</MenuItem>
                              )}
                              {/* <MenuItem value="">--No tour selected--</MenuItem> */}
                              {buttonSuggestion.map((t) => (
                                <MenuItem value={t.TagID} key={t.TagID}>
                                  {t.TagDescription}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Date range"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM/DD/YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM/DD/YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              // onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checkboxStatus}
                                    disableRipple
                                    onChange={handleChange}
                                  />
                                }
                                label="Show no scan"
                              />
                            </FormGroup>
                          </FormControl>

                          <FormControl>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              onClick={(e) => {
                                applySearch({});
                                setIsFilter(true);
                                handleCloseForm();
                                setCurrentPage(1);
                              }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setSearchInput("");
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchInput !== "") {
                    applySearch({});
                    setIsFilter(true);
                    // handleFirstPage();
                    setCurrentPage(1);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CSVLink
              data={csvDataList}
              headers={exportCSVheaders}
              filename={`SOVA_button_history-${format(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <MenuItem>
                <ListItemIcon>
                  <SummarizeIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Download CSV" pl={0} />
              </MenuItem>
            </CSVLink>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [dataToFilter, setDataToFilter] = useState(null);
  const historyList = useSelector((state) => state.report.buttonHistoryData);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [tagNameModal, setTagNameModal] = useState("");
  const [tagIdModal, setTagIdModal] = useState("");
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const checkedValue = useSelector(
    (state) => state.report.buttonHistoryChecked
  );
  const dispatch = useDispatch();

  // for permissions
  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToGetTagDescription = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "getTagDescription" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = historyList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, uname) => {
    const selectedIndex = selected.indexOf(uname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (uname) => selected.indexOf(uname) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  // Methods for calling the APIs
  const getButtonHistoryList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      ...dataToFilter,
    }; //this will be used for sending filtered data
    ButtonHistoryList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(buttonHistoryList(res.data));
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          dispatch(buttonHistoryList(res.data));
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        dispatch(buttonHistoryList([]));
      });
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelected([]);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  //Recent scan modal
  const [recentScans, setRecentScans] = React.useState(false);
  const recentScanOpen = (tagId, tagName) => {
    setRecentScans(true);
    setTagNameModal(tagName);
    setTagIdModal(tagId);
  };
  const recentScanClose = () => {
    setRecentScans(false);
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    ButtonHistoryColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    ButtonHistoryColumns()
      .then((res) => {
        dispatch(getButtonHistoryChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Side-effects
  useEffect(() => {
    getButtonHistoryList();
    getColumnList();
  }, [rowsPerPage, currentPage, dataToFilter]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
  }, []);

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          filterRecord={(data) => filterRecord(data)}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          startDateToFilter={startDateToFilter}
          setStartDateToFilter={setStartDateToFilter}
          endDateToFilter={endDateToFilter}
          setEndDateToFilter={setEndDateToFilter}
          handleFirstPage={handleFirstPage}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
          setCurrentPage={setCurrentPage}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={historyList.length}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(historyList, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.uname)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}
                          {checkedValue.includes("user_name") && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.user_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("scan_time") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.scan_time}
                            </TableCell>
                          )}
                          {checkedValue.includes("tour_id") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.tour_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("tag_description") && (
                            <TableCell
                              align="left"
                              sx={{
                                cursor: !hasPermissionToGetTagDescription
                                  ? "not-allowed"
                                  : "pointer",
                                color: !hasPermissionToGetTagDescription
                                  ? "#B0B0B0"
                                  : "#0077B5",
                                pointerEvents: !hasPermissionToGetTagDescription
                                  ? "none"
                                  : "auto",
                                lg: 'whiteSpace: "nowrap"',
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                              onClick={() => {
                                if (hasPermissionToGetTagDescription)
                                  recentScanOpen(
                                    row.tag_id,
                                    row.tag_description
                                  );
                              }}
                            >
                              {row.tag_description}
                            </TableCell>
                          )}
                          {checkedValue.includes("tour_comment") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.tour_comment}
                            </TableCell>
                          )}
                          {checkedValue.includes("location") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.location}
                            </TableCell>
                          )}
                          {checkedValue.includes("start_time") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.start_time}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={7} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {recentScans && (
        <RecentScans
          recentScans={recentScans}
          setRecentScans={setRecentScans}
          recentScanClose={recentScanClose}
          tagNameModal={tagNameModal}
          tagIdModal={tagIdModal}
          startDateToFilter={startDateToFilter}
          endDateToFilter={endDateToFilter}
        />
      )}
    </div>
  );
}

function ButtonHistory() {
  return (
    <React.Fragment>
      <Helmet title="Button History" />
      <WithPermissionFallback controller="Reports" action="buttonHistory">
        <Typography variant="h3" gutterBottom display="inline">
          Button History
        </Typography>

        {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ButtonHistory;

import React from "react";
import { Snackbar, Alert } from "@mui/material";

const SnackToolbar = ({
  snackToolbarOpen,
  handleSnackToolbarClose,
  recordLength,
}) => {
  return (
    <>
      <Snackbar
        open={snackToolbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackToolbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleSnackToolbarClose}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          A total of {recordLength} record(s) have been deleted.
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackToolbar;

import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Typography,
  FormLabel,
  Grid,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
  Menu,
  FormGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Questionnaire = ({
  interviewQuestionList,
  currentQues,
  childToParent,
}) => {
  const [personName, setPersonName] = React.useState("");
  const selectPersonName = (event) => {
    setPersonName(event.target.value);
  };

  const [inputVal, setinputVal] = useState("");

  return (
    <>
      <form>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={12}>
            <FormControl>
              <FormLabel>
                Question: {currentQues?.id} - {currentQues?.question}{" "}
                {currentQues.ques_type}
              </FormLabel>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            {currentQues.ques_type == "radio" ? (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="select-radio"
                  value={inputVal}
                  onChange={(e) => setinputVal(e.target.value)}
                >
                  {currentQues?.ir_question_option?.map((item) => (
                    <FormControlLabel
                      value={item.options}
                      control={<Radio />}
                      label={item.options}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : currentQues.ques_type == "text" ? (
              <FormControl>
                <TextField name="" value="dffd" />
              </FormControl>
            ) : currentQues.ques_type == "list" ? (
              <FormControl>
                <Select
                  labelId="department"
                  id="department"
                  value={personName}
                  onChange={selectPersonName}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            ) : currentQues.ques_type == "multitext" ? (
              <Grid container spacing={6} sx={{ marginTop: "8px" }}>
                <Grid item xs={12} lg={11}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl>
                        <TextField
                          value=""
                          placeholder=""
                          label="Injury Type"
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl>
                        <TextField
                          value=""
                          placeholder=""
                          label="Name of person injured"
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl>
                        <TextField
                          value=""
                          placeholder=""
                          label="Hospital or treatment facility"
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl>
                        <TextField
                          value=""
                          placeholder=""
                          label="Physicians name"
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={1}>
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Questionnaire;

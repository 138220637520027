import React, { useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  DialogActions,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
  Paper,
  FormControl as MuiFormControl,
} from "@mui/material";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/lab";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import CustomCheckbox from "../../../components/form-components/CustomCheckbox";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { SendIncidnetOfficier } from "../../../api";
import { useDispatch, useSelector } from "react-redux";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const SendIrToRepondingPoliceOfficer = ({
  sendResponseToPoliceOfficer,
  sendResponseToPoliceOfficerClose,
  singleData,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToSendIncidentToOfficer = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "sendIncidentToOfficer" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const initialValues = {
    schedule_send: false,
    release_officer_email: "",
    schedule_date: null,
  };
  const validationSchema = Yup.object().shape({
    release_officer_email: Yup.string()
      .email("Email must be valid email")
      .required("Please enter email address"),
    schedule_date: Yup.string()
      .nullable()
      .when(["schedule_send"], (same, schema) => {
        return same === false
          ? schema
          : schema.required("Please select the date");
      }),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        incident_id: singleData.IncidentID,
        release_officer_email: values.release_officer_email,
      };
      SendIncidnetOfficier(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setLoading(false);

            sendResponseToPoliceOfficerClose();
            dispatch(getSnackMsg("Email sent successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            setLoading(false);
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      <Dialog
        aria-labelledby="sendResponseToPoliceOfficer-title"
        aria-describedby="sendResponseToPoliceOfficer-description"
        open={sendResponseToPoliceOfficer}
        onClose={sendResponseToPoliceOfficerClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="sendResponseToPoliceOfficer-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Send IR to Responding Police Officer
          </Typography>
          <DialogActions>
            <IconButton
              onClick={sendResponseToPoliceOfficerClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If a police officer was involved in this incident and needs a copy
            of the incident report, add their email below and click Send. To
            send the report later, tick the Schedule Send box and select a
            different date.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl mt={6}>
                  <CustomTextField
                    name="release_officer_email"
                    label="Room number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                    placeholder="officer@localpd.com"
                  />
                </FormControl>
                <FormControl>
                  <CustomCheckbox
                    name="schedule_send"
                    checked={values.schedule_send}
                    label="Schedule send"
                    // returnOnChange={(evt) => {
                    //   if (evt.target.checked) {
                    //     setFieldValue("schedule_send", evt.target.value);
                    //   }
                    // }}
                  />
                </FormControl>
                {values.schedule_send && (
                  <FormControl mt={2}>
                    <DatePicker
                      id="date-range"
                      label="Approx date of accident"
                      value={values.schedule_date}
                      onChange={(newValue) => {
                        setFieldValue("schedule_date", newValue);
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          name="schedule_date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Select range",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}

                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={sendResponseToPoliceOfficerClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!hasPermissionToSendIncidentToOfficer}
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Send
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SendIrToRepondingPoliceOfficer;

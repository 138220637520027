import React from "react";
import styled from "styled-components/macro";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Checkbox,
  TextField,
  Grid,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { Box } from "react-feather";
const LiveIconDilaog = ({
  liveIconDilaog,
  setLiveIconDilaog,
  liveIconDilaogOpen,
  liveIconDilaogClose,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const top100Films = [
    { title: "A settle collection" },
    { title: "Alia Marea Beach Resort" },
    { title: "Andaz San Diego" },
    { title: "The Dark Knight" },
    { title: "12 Angry Men" },
    { title: "Schindler's List" },
    { title: "Pulp Fiction" },
    { title: "The Lord of the Rings: The Return of the King" },
    { title: "The Good, the Bad and the Ugly" },
    { title: "Fight Club" },
    {
      title: "The Lord of the Rings: The Fellowship of the Ring",
    },
    {
      title: "Star Wars: Episode V - The Empire Strikes Back",
    },
    { title: "Forrest Gump" },
    { title: "Inception" },
    {
      title: "The Lord of the Rings: The Two Towers",
    },
    { title: "One Flew Over the Cuckoo's Nest" },
    { title: "A settle collection" },
    { title: "Alia Marea Beach Resort" },
    { title: "Andaz San Diego" },
    { title: "The Dark Knight" },
    { title: "12 Angry Men" },
    { title: "Schindler's List" },
    { title: "Pulp Fiction" },
    { title: "The Lord of the Rings: The Return of the King" },
    { title: "The Good, the Bad and the Ugly" },
    { title: "Fight Club" },
    {
      title: "The Lord of the Rings: The Fellowship of the Ring",
    },
    {
      title: "Star Wars: Episode V - The Empire Strikes Back",
    },
    { title: "Forrest Gump" },
    { title: "Inception" },
  ];

  return (
    <div>
      {" "}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={liveIconDilaog}
        onClose={liveIconDilaogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h5">Location Name</Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setLiveIconDilaog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            gap: 5,
          }}
        >
          <Typography variant="body2" gutterBottom mt={3}>
            Sites
          </Typography>

          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={top100Films}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            style={{ width: 400 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="All Selected(113)"
                sx={{
                  padding: 0,
                  height: "15px",
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LiveIconDilaog;

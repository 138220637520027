import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const NoneHeader = ({ children }) => {
  return (
    <Root>
      {children}
      <Outlet />
    </Root>
  );
};

export default NoneHeader;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  AlarmActivationLogList,
  DeleteAlarmActivationLog,
  PrintEmergencyReport,
} from "../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/mainSlice";
import Toaster from "../tasks/Toaster";
import AlarmActivationLogListCol from "../table-columns-json/logs/AlarmActivationLogList";
import { setAlarmActivationLogList } from "../../redux/slices/logs/logTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  Link,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Pagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  PictureAsPdf as PictureAsPdfIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 12px auto 0;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "465px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "200px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  date,
  time,
  codeCalled,
  location,
  summaryDescription,
  action
) {
  return {
    date,
    time,
    codeCalled,
    location,
    summaryDescription,
    action,
  };
}
const rows = [
  createData("09-22-2022", "05:25", "423423", "sdfdrgdg", "dggh", 0),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "date",
    numeric: true,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "time",
    numeric: true,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "codeCalled",
    numeric: true,
    disablePadding: false,
    label: "Code Called",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "summaryDescription",
    numeric: true,
    disablePadding: false,
    label: "Summary Description",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {AlarmActivationLogListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setDataToFilter,
    tableCalRedux,
    handleDeleteOpen,
  } = props;
  const [actions, setActions] = useState("");
  const [emergencyCodeNumber, setEmergencyCodeNumber] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLogTableCol.alarmActivationLogList
  );
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };
  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  const applySearch = () => {
    const payload = {
      search_value: search,
      location: location,
      code_number: emergencyCodeNumber,
      description: description,
      start_date: dateFrom ? format(dateFrom, "yyyy-MM-dd") : null,
      end_date: dateTo ? format(dateTo, "yyyy-MM-dd") : null,
    };
    // console.log(payload);
    setDataToFilter(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setSearch("");
    setEmergencyCodeNumber("");
    setLocation("");
    setDescription("");
    setDateFrom(null);
    setDateTo(null);
  };

  /**
   * It calls the PrintEmergencyReport() function, which returns a promise. If the promise is resolved,
   * it creates a link element, sets the href attribute to the base64 encoded pdf, sets the download
   * attribute to the name of the file, and clicks the link
   */
  const onButtonClick = () => {
    setLoading(true);
    PrintEmergencyReport({
      search_value: search,
      location: location,
      code_number: emergencyCodeNumber,
      description: description,
      start_date: dateFrom,
      end_date: dateTo,
    })
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-EMERGENCY-CALL-LOG.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content", marginLeft: "15px" }}
        >
          {totalRecord <= 1
            ? `${totalRecord} record found`
            : `${totalRecord} records found`}
        </Typography>
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              type="search"
              placeholder="Search by location, code or click down arrow"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch({});
                  setIsFilter(true);
                }
              }}
              inputProps={{}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form>
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="emergency-code-number"
                              label="Emergency code number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Emergency code number"
                              value={emergencyCodeNumber}
                              onChange={(e) =>
                                setEmergencyCodeNumber(e.target.value)
                              }
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="location"
                              label="Location"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Location"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="description"
                              label="Description"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </FormControl>
                          <FormControl>
                            <DatePicker
                              id="date-from"
                              label="Date from"
                              value={dateFrom}
                              onChange={(newValue) => {
                                setDateFrom(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Date from",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl>
                            <DatePicker
                              id="date-to"
                              label="Date to"
                              value={dateTo}
                              onChange={(newValue) => {
                                setDateTo(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Date to",
                                  }}
                                />
                              )}
                            />
                          </FormControl>

                          <FormControl>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              onClick={() => {
                                applySearch({});
                                handleCloseForm();
                                setIsFilter(true);
                              }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                variant="contained"
                color="primary"
                mt={6}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setSearch("");
                }}
              >
                Clear filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  if (search !== "") {
                    applySearch({});
                    setIsFilter(true);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-alarm-activation-log"
              component={NavLink}
              to="/logs/add-alarm-activation-log"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Alarm Log" pl={0} />
            </MenuItem>
            <MenuItem onClick={onButtonClick}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Download PDF" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setAlarmActivationLogList(data));
                    }}
                    listing={AlarmActivationLogListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          setAlarmActivationLogList(defaultTableCalRedux)
                        );
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [alarmActivationLogData, setAlarmActivationLogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const snackbarStatus = useSelector((state) => state.main.snackStatus);
  const snackbarMsg = useSelector((state) => state.main.msg);
  const snackbarAlert = useSelector((state) => state.main.snackAlert);

  const dispatch = useDispatch();

  const tableCalRedux = useSelector(
    (state) => state.logTableCol.alarmActivationLogList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = alarmActivationLogData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, codeCalled) => {
    const selectedIndex = selected.indexOf(codeCalled);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, codeCalled);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (codeCalled) => selected.indexOf(codeCalled) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const alarmActivationLog = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      // sorting: {
      //   order: order,
      //   by: orderBy,
      // },
      ...dataToFilter,
    };
    AlarmActivationLogList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setAlarmActivationLogData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setAlarmActivationLogData([]);
          setLoading(false);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteAlarmActivationLog = () => {
    DeleteAlarmActivationLog(selected.length > 0 ? selected : id)
      .then((res) => {
        if (res.statusCode === 200) {
          alarmActivationLog();
          handleDeleteClose();
          dispatch(getMsg(`Recored(s) has been deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    alarmActivationLog();
  }, [rowsPerPage, currentPage, dataToFilter]);

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  return (
    <div>
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {selected.length || id > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete alarm activation log?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={deleteAlarmActivationLog}
              autoFocus
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setDataToFilter={setDataToFilter}
          tableCalRedux={tableCalRedux}
          handleDeleteOpen={handleDeleteOpen}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={alarmActivationLogData.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(
                  alarmActivationLogData,
                  getComparator(order, orderBy)
                )
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.date === true ? "" : "hide-cell"
                          }
                        >
                          {row.date}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.time === true ? "" : "hide-cell"
                          }
                        >
                          {row.alert_time}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.code_called === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.code_number}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.location === true ? "" : "hide-cell"
                          }
                        >
                          {row.location_emergency}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap", minWidth: "220px" }}
                          className={
                            tableCalRedux.summary_description === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.summary_description}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              aria-label="edit-alarm-activation-log"
                              component={NavLink}
                              to={`/logs/edit-alarm-activation-log/${id}`}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={handleDeleteOpen}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {alarmActivationLogData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function AlarmActivationLog() {
  return (
    <React.Fragment>
      <Helmet title="Alarm Activation Log" />
      <Typography variant="h3" gutterBottom display="inline">
        Alarm Activation Log
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AlarmActivationLog;

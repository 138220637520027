import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import axios from "axios";
import Toaster from "../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { getTourRow } from "../../redux/slices/tourSlice"; //import related to redux store.
import { DownloadShiftPdf, UpdateTour, UpdateTourStatus } from "../../api";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import { RemoveTourTime } from "../../api";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WithPermissionHide from "../../utils/withPermissionHide";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  Tooltip,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  InsertDriveFile as InsertDriveFileIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  Https as HttpsIcon,
  Person as PersonIcon,
  DeviceHub as DeviceHubIcon,
  Circle as CircleIcon,
  CropSquare as CropSquareIcon,
  Send as SendIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import { yellow } from "@mui/material/colors";
import SendShiftReport from "./inner-component/SendShiftReport";
import { makeStyles } from "@mui/styles";

/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
const Image = styled.img`
  height: 30px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const ShiftRow = (props) => {
  const {
    shiftReportList,
    isItemSelected,
    labelId,
    handleClick,
    anchorEl,
    handleActionClick,
    handleActionClose,
    tableCalRedux,
    getShiftReportList,
    id,
    handleDeleteOpen,
  } = props;
  //   const [active, setActive] = useState(tour.status === "A" ? true : false);
  const dispatch = useDispatch();
  function getIconForModifiedTime(modifiedTime, shiftDateTimeEnd) {
    const hourBef2 = new Date(shiftDateTimeEnd);
    hourBef2.setHours(hourBef2.getHours() - 2);
    const hourBef = new Date(shiftDateTimeEnd);
    hourBef.setHours(hourBef.getHours() - 1);

    if (modifiedTime < hourBef2) {
      return (
        <CropSquareIcon
          style={{ color: "red" }}
          title="Modified more than 2 hours before shift end time"
        />
      );
    } else if (modifiedTime >= hourBef2 && modifiedTime <= hourBef) {
      return (
        <CircleIcon
          style={{ color: "yellow" }}
          title="Modified within 1-2 hours of shift end time"
        />
      );
    } else if (modifiedTime > hourBef) {
      return (
        <CircleIcon
          style={{ color: "green" }}
          title="Modified within 1 hour of shift end time"
        />
      );
    }
  }
  const classes = useStyles();
  const shiftDateTimeEnd =
    shiftReportList.end_date + " " + shiftReportList.end_time;

  const [sendSr, setSendSr] = useState(false);
  const sendSrOpen = () => {
    setSendSr(true);
  };

  const sendSrClose = () => {
    setSendSr(false);
  };

  const [loading, setLoading] = useState(false);
  const downloadPdf = () => {
    DownloadShiftPdf(id)
      .then((res) => {
        if (res.statusCode == 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-SHIFT-REPORT.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <TableRow hover selected={isItemSelected} key={shiftReportList.id}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, shiftReportList.id)}
            selected={isItemSelected}
            aria-checked={isItemSelected}
            tabIndex={-1}
            role="checkbox"
          />
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.date === true ? "" : "hide-cell"}
        >
          {shiftReportList.date}
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.supervisor === true ? "" : "hide-cell"}
        >
          {shiftReportList.supervisor}
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.shift === true ? "" : "hide-cell"}
        >
          {shiftReportList.shift}
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.training === true ? "" : "hide-cell"}
        >
          {shiftReportList.training}
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.irs === true ? "" : "hide-cell"}
        >
          {shiftReportList.irs}
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.groupissues === true ? "" : "hide-cell"}
        >
          {shiftReportList.group_issue}
        </TableCell>
        <TableCell
          align="left"
          style={{ color: shiftReportList.ets.includes("N") ? "red" : "black" }}
          className={tableCalRedux.ets === true ? "" : "hide-cell"}
        >
          {shiftReportList.ets}
        </TableCell>
        <TableCell
          align="left"
          style={{ color: shiftReportList.sd.includes("N") ? "red" : "black" }}
          className={tableCalRedux.sd === true ? "" : "hide-cell"}
        >
          {shiftReportList.sd}
        </TableCell>
        <TableCell
          align="left"
          style={{ color: shiftReportList.efs.includes("N") ? "red" : "black" }}
          className={tableCalRedux.efs === true ? "" : "hide-cell"}
        >
          {shiftReportList.efs}
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.status === true ? "" : "hide-cell"}
        >
          {shiftReportList.modifiedTime < shiftReportList.hourBef2 ? (
            <Tooltip
              title="Modified more than 2 hours before shift end time"
              placement="right"
            >
              <CropSquareIcon color="warning" />
            </Tooltip>
          ) : shiftReportList.modifiedTime >= shiftReportList.hourBef2 &&
            shiftReportList.modifiedTime <= shiftReportList.hourBef ? (
            <Tooltip
              title="Modified within 1-2 hours of shift end time"
              placement="right"
            >
              <ChangeHistoryIcon sx={{ color: yellow[500] }} />
            </Tooltip>
          ) : (
            <Tooltip
              title="Modified within 1 hour of shift end time"
              placement="right"
            >
              <CircleIcon color="success" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell
          align="left"
          sx={{ whiteSpace: "nowrap" }}
          className={tableCalRedux.updatedutc === true ? "" : "hide-cell"}
        >
          {shiftReportList.updated}
        </TableCell>
        <TableCell
          align="left"
          className={tableCalRedux.action === true ? "" : "hide-cell"}
        >
          <IconButton
            aria-label="Settings"
            size="large"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            // onClick={handleActionClick}
            onClick={(event) => handleActionClick(event, shiftReportList.id)}
            style={{ color: "#1374C5" }}
          >
            <SettingsIcon />
          </IconButton>
          <StyledMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <WithPermissionHide controller="Logs" action="editShiftReport">
              <MenuItem
                component={NavLink}
                to={`/logs/edit-shift-report/${id}`}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit" pl={1} />
              </MenuItem>
            </WithPermissionHide>
            <WithPermissionHide controller="Logs" action="viewShiftReport">
              <MenuItem
                component={NavLink}
                to={`/logs/view-shift-report/${id}`}
              >
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText primary="View" pl={1} />
              </MenuItem>
            </WithPermissionHide>
            <WithPermissionHide controller="Logs" action="deleteShiftReport">
              <MenuItem onClick={handleDeleteOpen}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary="Delete" pl={1} />
              </MenuItem>
            </WithPermissionHide>
            <WithPermissionHide controller="Logs" action="sendSrPdf">
              <MenuItem>
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Send Report"
                  pl={1}
                  onClick={(e) => {
                    sendSrOpen();
                    handleActionClose();
                  }}
                />
              </MenuItem>
            </WithPermissionHide>
            <WithPermissionHide controller="Logs" action="downloadShiftPdf">
              <MenuItem onClick={downloadPdf}>
                <ListItemIcon>
                  <DeviceHubIcon />
                </ListItemIcon>
                <ListItemText primary="Download PDF" pl={1} />
              </MenuItem>
            </WithPermissionHide>
          </StyledMenu>
        </TableCell>
      </TableRow>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {sendSr && (
        <SendShiftReport
          sendSr={sendSr}
          sendSrOpen={sendSrOpen}
          sendSrClose={sendSrClose}
          setSendSr={setSendSr}
          srId={id}
        />
      )}
    </>
  );
};

export default ShiftRow;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Image,
} from "@mui/material";

// Classes Styles
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const UserImage = ({ userImageDialog, userImageDialogClose, poiImage }) => {
  return (
    <Dialog
      aria-labelledby="lift-out-title-modal"
      aria-describedby="lift-out-modal"
      open={userImageDialog}
      onClose={userImageDialogClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
          },
        },
      }}
    >
      <DialogContent>
        <Box>
          <img src={poiImage} alt={"POI Image"} />
        </Box>
      </DialogContent>

      <DialogActions sx={{ pr: "28px" }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={userImageDialogClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserImage;

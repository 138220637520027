import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../components/form-components/CustomTextField";
import { useDispatch } from "react-redux";
import { AddUpdateVisitorCompany, ViewVisitorCompany } from "../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  Box,
  Menu,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  ListItemButton,
  ListItem,
  List,
  ListItemText,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  Paper,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Search as SearchIcon,
  SettingsOverscan as SettingsOverscanIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiButtonBase-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "235px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "460px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        padding: "10px",
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const selectSites = [
  { title: "None selected" },
  { title: "One" },
  { title: "Two" },
  { title: "Three" },
  { title: "Fouor" },
  { title: "Five" },
  { title: "Six" },
];

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddVisitorForm(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Visitor status
  const [visitorstatus, setVisitorStatus] = React.useState("");
  const selectVisitorStatus = (event) => {
    setVisitorStatus(event.target.value);
  };
  // Vehicle Year
  const [vehicleyear, setVehicleYear] = React.useState("");
  const selectVehicleYear = (event) => {
    setVehicleYear(event.target.value);
  };
  // Vehicle Make
  const [vehiclemake, setVehicleMake] = React.useState("");
  const selectVehicleMake = (event) => {
    setVehicleMake(event.target.value);
  };
  // Vehicle Model
  const [vehiclemodel, setVehicleModel] = React.useState("");
  const selectVehicleModel = (event) => {
    setVehicleModel(event.target.value);
  };
  // Visitor Type
  const [visitortype, setVisitorType] = React.useState("");
  const selectVisitorType = (event) => {
    setVisitorType(event.target.value);
  };
  // Visitor Company
  const [visitorcompany, setVisitorCompany] = React.useState("");
  const selectVisitorCompany = (event) => {
    setVisitorCompany(event.target.value);
  };
  // Visitor security level
  const [vsecuritylevel, setVSecurityLevel] = React.useState("");
  const selectVSecurityLevel = (event) => {
    setVSecurityLevel(event.target.value);
  };
  // Visitor escort
  const [escort, setEscort] = React.useState("");
  const selectEscort = (event) => {
    setEscort(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const [otherinfo, setOtherInfo] = React.useState("");
  const selectOtherInfo = (event) => {
    setOtherInfo(event.target.value);
  };
  // Site Dropdown
  const [siteDropdown, setSiteDropdown] = React.useState(null);
  const siteDropdownOpen = (event) => {
    setSiteDropdown(event.currentTarget);
  };
  const siteDropdownClose = () => {
    setSiteDropdown(null);
  };
  const classes = useStyles();
  // DatePicker
  const [issuedate, setIssueDate] = React.useState(null);
  const [expirydate, setExpiryDate] = React.useState(null);
  //Add Company Select Control
  const [control, setControl] = React.useState(0);
  const addMoreVisitorCompany = () => {
    setControl(control + 1);
    console.log(control);
  };
  //Remove Company Select Control
  const removeVisitorCompany = () => {
    setControl(control - 1);
    console.log(control);
  };
  //Equipment check in Modal
  const [scanbarcode, setScanBarcode] = React.useState(false);
  const scanBarcodeOpen = () => {
    setScanBarcode(true);
  };
  const scanBarcodeClose = () => {
    setScanBarcode(false);
  };
  const [viewCompany, setViewCompany] = useState({});
  const viewVisitorCompany = () => {
    ViewVisitorCompany(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewCompany(res.data);
          setLoading(false);
        } else {
          setViewCompany({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    viewVisitorCompany();
    setLoading(true);
  }, []);

  const {
    CompanyName,
    CompanyAddr1,
    CompanyAddr2,
    CompanyCity,
    CompanyState,
    CompanyZip,
    CompanyPhone,
    CompanyFax,
    CompanyAdminName,
    CompanyAdminPhone,
    note,
  } = viewCompany;
  const initialValues = {
    company_name: CompanyName ? CompanyName : "",
    company_address1: CompanyAddr1 ? CompanyAddr1 : "",
    company_address2: CompanyAddr2 ? CompanyAddr2 : "",
    city: CompanyCity ? CompanyCity : "",
    state: CompanyState ? CompanyState : "",
    zip: CompanyZip ? CompanyZip : "",
    phone: CompanyPhone ? CompanyPhone : "",
    fax: CompanyFax ? CompanyFax : "",
    company_admin_name: CompanyAdminName ? CompanyAdminName : "",
    company_admin_phone: CompanyAdminPhone ? CompanyAdminPhone : "",
    notes: note ? note : "",
  };
  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Please enter company name"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        id: id,
        CompanyName: values.company_name,
        CompanyAddr1: values.company_address1,
        CompanyAddr2: values.company_address2,
        CompanyCity: values.city,
        CompanyState: values.state,
        CompanyZip: values.zip,
        CompanyPhone: values.phone,
        CompanyFax: values.fax,
        CompanyAdminName: values.company_admin_name,
        CompanyAdminPhone: values.company_admin_phone,
        note: values.notes,
      };
      AddUpdateVisitorCompany(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record udpated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
            navigate({
              pathname: "/visitors/companies",
            });
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        fullWidth
                        id="company-name"
                        label="Company name"
                        name="company_name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>

                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="company-address-1"
                        label="Company address1"
                        name="company_address1"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="company-address-2"
                        label="Company address2"
                        name="company_address2"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="city"
                        label="City"
                        name="city"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="state"
                        label="State"
                        name="state"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="zip"
                        label="Zip"
                        name="zip"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        fullWidth
                        id="phone"
                        label="Phone"
                        name="phone"
                        type="tel"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="fax"
                        label="Fax"
                        name="fax"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="company-ad-name"
                        label="Company admin name"
                        name="company_admin_name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="company-ad-phone"
                        label="Company admin phone no."
                        name="company_admin_phone"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    {/* <TextAreaControl mt={3} className={classes.textAreaControl}>
                      <FormControl mt={1}>
                        <InputLabel id="v-status">Notes</InputLabel>
                        <TextareaAutosize placeholder="" />
                      </FormControl>
                    </TextAreaControl> */}
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        id="notes"
                        label="Notes"
                        name="notes"
                        multiline
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      mt={6}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function EditCompany() {
  return (
    <React.Fragment>
      <Helmet title="Edit Company" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Company
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/visitors/companies">
          Company List
        </Link>
        <Typography>Edit Company</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddVisitorForm />
    </React.Fragment>
  );
}

export default EditCompany;

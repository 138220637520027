import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ChoosePoiImage from "../poi/inner-component/ChoosePoiImage";

import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Switch,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  FormHelperText,
  Alert,
  TextField,
  Snackbar,
  Paper,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import Textfield from "./components/Textfield";
import SelectedCurrency from "./components/SelectCurrency";
import currency from "./Data/currency.json";
import axios from "axios";
import { spacing } from "@mui/system";
import SelectCorporate from "./components/SelectCorporate";
import StrengthMeter from "./components/StrengthMeter";
import AddressTextfield from "./components/AddressTextfield";
import MuiPhoneNumber from "material-ui-phone-number";
import useAppDispatch from "../../hooks/useAppDispatch";
import { hideLoader, showLoader } from "../../redux/slices/counter";
import { getMsg } from "../../redux/slices/taskSlice";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../redux/slices/propertySlice";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"; //for strength meter
import {
  AddProperties,
  CorporateList,
  TimeZoneList,
  Modulelist,
  CopyTask,
  UserPropertyList,
  CopyIRTypes,
} from "../../api";
import ImageCropper from "../../components/form-components/ImageCropper";
import { Error as ErrorIcon } from "@mui/icons-material";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  tooltipIcon: {
    display: "inline-flex",
    verticalAlign: "middle",
    cursor: "pointer",
    marginLeft: "6px",
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const StyledSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiSwitch-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
      },
    },
  })
);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

//Styled component for stength meter.
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#1976D2", //funcProgressColor(),
  },
}));

function AddPropertyForm() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [corporateList, setCorporateList] = useState([]); //state for storing the corporate list api response
  const [timerZone, setTimerZone] = useState([]); //state for storing timezone data
  const [modules, setModules] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState(""); //state for latitude
  const [longitude, setLongitude] = useState(""); //State for longitude
  const [errorSnack, setErrorSnack] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false); // Modal state
  const [propertyList, setPropertyList] = useState([]);
  const [listLocation, setListLocation] = useState([]);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [taskLocation, setTaskLocation] = useState(""); // Separate state for Copy Task location
  const [irLocation, setIrLocation] = useState("");
  const [currentLocationID, setCurrentLocationID] = useState("");
  // State for image cropper modal
  const [imageCropperDialog, setImageCropperDialog] = useState(false);
  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };
  const [imageData, setImageData] = useState(null);
  const childToParent = (childData) => {
    setImageData(childData);
  };

  const imageCropperClear = () => {
    setImageData(null);
  };

  const handleTaskLocationChange = (event) => {
    setTaskLocation(event.target.value);
  };

  const handleIrLocationChange = (event) => {
    setIrLocation(event.target.value);
  };

  // const handleErrorSnackClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;e
  //   }
  //   setErrorSnack(false);
  // };

  const initialValues = {
    corporateName: "",
    businessName: "",
    mobileActivationID: "",
    address: "",
    long: "",
    phoneNumber: "",
    fax: "",
    accountNumber: "",
    publicLostFound: "",
    lostFoundReporting: "",
    timeZone: "",
    mapOrientation: 1,
    shipmentHandlingFee: "",
    passwordResetIntervalDays: "",
    lockerPin: "",
    subscriptionLevel: 1,
    mixedTech: "N",
    adminName: "",
    adminEmail: "",
    adminSMS: "",
    switchone: "N",
    switchtwo: "N",
    switchthree: 0,
    switchfour: "TEST",
    switchfive: 0,
    switchsix: 0,
    switchseven: 0,
    switcheight: "ses",
    switchnine: 0,
    switchten: 0,
    switcheleven: 0,
    switchtwelve: 0,
    allow_adding_device: 0,
    can_add_sites: 0,
    shift_equipment_report: 0,
    switch13: 0,
    switch14: 0,
    incognitoPropertyName: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    selectCurrency: "",
    module_list: [],
  };

  const validationSchema = Yup.object().shape({
    corporateName: Yup.string().required("Please select corporate name"),
    businessName: Yup.string().required("Please enter business name"),
    mobileActivationID: Yup.string()
      .required("Please enter mobile activation ID")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must contain 8 characters, one uppercase and one number"
      ),
    address: Yup.string().required("Please select address"),
    long: Yup.string().required("Please select address"),
    phoneNumber: Yup.string().required("Please enter phone number"),
    fax: Yup.string().matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})?[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      "Please enter a valid fax number"
    ),
    accountNumber: Yup.string().required("Please enter Account number"),
    publicLostFound: Yup.string().required(
      "Please enter public lost and found ID"
    ),
    timeZone: Yup.string().required("Please select a timezone"),
    shipmentHandlingFee: Yup.string()
      .matches(
        /(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/,
        "Please enter a valid number"
      )
      .required("Please enter shipment handling fees"),
    passwordResetIntervalDays: Yup.number()
      .typeError("Please enter a valid number.")
      .min(30, "Please enter days in the range of 30-180")
      .max(180, "Please enter days in the range of 30-180"),
    adminName: Yup.string().required("Please enter admin name"),
    adminEmail: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter email address"),
    adminSMS: Yup.number()
      .integer()
      .positive("Please enter a valid number without dashes")
      .typeError("Please enter a valid number without dashes")
      .required("Please enter SMS"),
    module_list: Yup.array().min(1, "Please select module"),
  });

  //Handler for image upload and converting it into base 64
  // const onChange = (e) => {
  //   const files = e.target.files;
  //   const file = files[0];
  //   getBase64(file);
  // };

  // const onLoad = (fileString) => {
  //   initialValues.logoImage = fileString;
  //   setImage(fileString);
  // };

  // const getBase64 = (file) => {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     onLoad(reader.result);
  //   };
  // };
  useEffect(() => {
    UserPropertyList()
      .then((res) => {
        if (res.statusCode === 200) {
          setListLocation(res.data);
          setPropertyList(res.data);
        } else {
          setListLocation([]);
          setPropertyList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const copyIRTypes = () => {
    const newLocationID = parseInt(localStorage.getItem("currentLocationID")); // Convert the saved location ID to a number

    let payload = {
      source_location: irLocation, // Source location chosen by the user
      target_location: newLocationID, // Ensure it's a number
    };

    CopyIRTypes(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const locationName = listLocation.find(
            (item) => item.LocationID === irLocation
          );
          dispatch(
            getMsg(
              `IR Types successfully copied to ${locationName.LocationName}`
            )
          );
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopyTask = () => {
    const newLocationID = parseInt(localStorage.getItem("currentLocationID")); // Convert the saved location ID to a number

    let payload = {
      source_location: taskLocation, // Source location chosen by the user
      target_location: newLocationID, // Ensure it's a number
    };

    CopyTask(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const locationName = propertyList.find(
            (item) => item.LocationID === taskLocation
          );
          dispatch(
            getMsg(`Tasks successfully copied to ${locationName.LocationName}`)
          );
          handleModalClose();
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getSnackClose());
      });
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setPageLoad(true);
    setIsDisabled(true);
    try {
      let formData = {
        corporate_id: values.corporateName,
        location_name: values.businessName,
        mobile_activation_id: values.mobileActivationID,
        address: values.address,
        phone: values.phoneNumber,
        fax: values.fax,
        account_number: values.accountNumber,
        lat: latitude,
        long: longitude,
        reg_date: new Date(),
        public_lost_id_found: values.publicLostFound,
        lostfound_account_id: values.lostFoundReporting,
        time_zone: values.timeZone,
        map_direction: values.mapOrientation,
        shipment_handling_fee: values.shipmentHandlingFee,
        pwd_reset_days: values.passwordResetIntervalDays,
        locker_combo_unmask_pin: values.lockerPin,
        subscription_id: values.subscriptionLevel,
        enable_mixed_tech: values.mixedTech,
        logo_image: imageData,
        admin_name: values.adminName,
        admin_email: values.adminEmail,
        admin_sms: values.adminSMS,
        publish_dispatch: values.switchone,
        publish_dispatch_online: values.switchtwo,
        show_location_dar: values.switchthree,
        shipment_status: values.switchfour,
        ablyOnOff: values.switchfive,
        dynamo_db: values.switchsix,
        temp_check: values.switchseven,
        email_client: values.switcheight,
        fcm_enable: values.switchnine,
        mobile_ir_logout_reminder: values.switchten,
        site_feature: values.switcheleven,
        is_hotel: values.switchtwelve,
        allow_adding_device: values.allow_adding_device,
        can_add_sites: values.can_add_sites,
        shift_equipment_report: values.shift_equipment_report,
        module_list: values.module_list,
        dummy_location_name: values.incognitoPropertyName,
        street: values.street,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
        currency: values.selectCurrency,
      };
      const res = await AddProperties(formData);

      if (res.statusCode === 200) {
        const newLocationID = res.data.LocationID; // Get the new LocationID after property is added
        setIsDisabled(false);
        setSuccessModalOpen(true);
        dispatch(getSnackMsg("Property has been submitted successfully"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        setCurrentLocationID(newLocationID); // Set the new location ID for copying tasks and IR

        // Set this location ID in the local storage so you can use it later for Copy Task and Copy IR
        localStorage.setItem("currentLocationID", newLocationID);
      } else {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      }
      setPageLoad(false);
    } catch (error) {
      setErrors({ submit: error.message });
      setIsDisabled(false);
      setPageLoad(false);
    }
  };

  const [showTaskSelect, setShowTaskSelect] = useState(false);
  const [showIrClassificationSelect, setShowIrClassificationSelect] =
    useState(false);

  const handleTaskCheckboxChange = () => {
    setShowTaskSelect(!showTaskSelect);
  };

  const handleIrClassificationCheckboxChange = () => {
    setShowIrClassificationSelect(!showIrClassificationSelect);
  };
  const [location, setLocation] = useState("");
  const selectLocation = (event) => {
    setLocation(event.target.value);
  };

  //Api call for  select corporate dropdown
  const corporate = () => {
    CorporateList()
      // axios
      //   .get("corporate-list")
      .then((response) => {
        // console.log(response.data.data);
        setCorporateList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //API call for Timezone
  const timeZoneList = () => {
    TimeZoneList()
      // axios
      //   .get("timezone-list")
      .then((response) => {
        // console.log(response.data.data);
        setTimerZone(response.data);
        // setCorporateList(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //api call for module list
  const moduleList = () => {
    Modulelist()
      // axios
      //   .get("module-list")
      .then((response) => {
        console.log(response.data);
        setModules(response.data);
        // setCorporateList(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleModalClose = () => {
    setSuccessModalOpen(false);
    navigate("/accounts/property-list"); // Navigate after closing the modal
  };

  const handleAddresscomponent = (components) => {
    let address_street1 = "";
    let address_street2 = "";
    let address_street3 = "";
    let address_city;
    let address_state;
    let address_country;
    let address_zip;
    for (let i = 0; i < components.length; i++) {
      if (components[i].types[0] === "sublocality_level_1") {
        address_street1 = components[i]?.short_name;
      }
      if (components[i].types[0] === "sublocality_level_2") {
        address_street2 = components[i]?.short_name;
      }
      if (components[i].types[0] === "sublocality_level_3") {
        address_street3 = components[i]?.short_name
          ? components[i]?.short_name
          : "";
      }
      if (components[i].types[0] === "locality") {
        address_city = components[i]?.short_name;
      }
      if (components[i].types[0] === "administrative_area_level_2") {
        address_state = components[i]?.short_name;
      }
      if (components[i].types[0] === "administrative_area_level_1") {
        address_state = components[i]?.short_name;
      }
      if (components[i].types[0] === "country") {
        address_country = components[i]?.short_name;
      }
      if (components[i].types[0] === "postal_code") {
        address_zip = components[i]?.short_name;
      }
    }
    let fullstreet =
      address_street3 + " " + address_street2 + " " + address_street1;
    return [
      address_city,
      address_state,
      address_country,
      address_zip,
      fullstreet,
    ];
  };

  // const mixedTechName = "mixedTech";

  useEffect(() => {
    corporate();
    timeZoneList();
    moduleList();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          values,
        }) => (
          <Card mb={6}>
            {/* <Snackbar
              open={errorSnack}
              autoHideDuration={3000}
              onClose={handleErrorSnackClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert
                onClose={handleErrorSnackClose}
                severity="error"
                variant="filled"
                sx={{ maxWidth: 600 }}
              >
                Something went wrong, Please try again.
              </Alert>
            </Snackbar> */}
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <SelectCorporate
                        name="corporateName"
                        label="Corporate name *"
                        options={corporateList}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="businessName"
                        label="Business name *"
                        m={2}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Business name"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="mobileActivationID"
                        label="Mobile Activation ID *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Key complexity 30% or greater"
                      />
                      <StrengthMeter
                        mobileActivationID={values.mobileActivationID}
                        BorderLinearProgress={BorderLinearProgress}
                      />
                      <FormHelperText style={{ marginLeft: "0px" }}>
                        8 character minimum with one upper case letter and one
                        number
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <AddressTextfield
                        name="address"
                        label="Address *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Enter your address"
                        setLatitude={setLatitude}
                        setLongitude={setLongitude}
                        handleAddresscomponent={handleAddresscomponent}
                      />
                      {!errors.address ? (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {errors.long}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <FormControl mt={6}>
                      <MuiPhoneNumber
                        name="phoneNumber"
                        id="contactPhoneNumber"
                        label="Phone *"
                        value={values.phoneNumber}
                        onChange={handleChange("phoneNumber")}
                        onBlur={handleBlur}
                        defaultCountry={"us"}
                        style={{ width: "100%" }}
                        variant="outlined"
                        disableAreaCodes={true}
                        // margin="normal"
                        error={Boolean(
                          touched.phoneNumber && errors.phoneNumber
                        )}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="fax"
                        label="Fax"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ maxLength: 20 }}
                        autoComplete="off"
                        placeholder="Please enter fax"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="accountNumber"
                        label="Account number *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Generally a 2-3 digit number"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="publicLostFound"
                        label="Public lost and found ID *"
                        inputProps={{ maxLength: 20 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Public enters this when reporting a lost item"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="lostFoundReporting"
                        label="Lost and found ID for reporting"
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Usually the same as above ID"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        id="timezone"
                        label="Timezone *"
                        name="timeZone"
                        select
                        value={values.timeZone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(touched.timeZone && errors.timeZone)}
                        helperText={touched.timeZone && errors.timeZone}
                      >
                        {timerZone.map((t) => (
                          <MenuItem value={t.name} key={t.id}>
                            {t.timezone}({t.name})
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="map-orientation"
                        sx={{
                          background: "#fff",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Map orientation
                      </InputLabel>
                      <Select
                        labelId="map-orientation"
                        id="map-orientation"
                        name="mapOrientation"
                        value={values.mapOrientation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value={1}>North</MenuItem>
                        <MenuItem value={90}>West</MenuItem>
                        <MenuItem value={180}>South</MenuItem>
                        <MenuItem value={270}>East</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="shipmentHandlingFee"
                        label="Shipment handling fee *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Processing fee to SOVA in USD. Standard is 7.50"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="passwordResetIntervalDays"
                        label="Password reset interval days"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Range 30-180 days"
                        autoComplete="off"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="lockerPin"
                        label="Locker combo unmask pin"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="For HR to view locker combo"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="subs-level"
                        sx={{
                          background: "#fff",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Subscription level
                      </InputLabel>
                      <Select
                        labelId="subs-level"
                        id="subs-level"
                        name="subscriptionLevel"
                        value={values.subscriptionLevel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value={1}>No IR</MenuItem>
                        <MenuItem value={2}>IR</MenuItem>
                        <MenuItem value={3}>BLE</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <FormControl mt={6}>
                      <FormLabel component="legend">
                        Enable mixed tech?
                      </FormLabel>
                      <RadioGroup
                        name="mixedTech"
                        row={true}
                        value={values.mixedTech.toString()}
                        onChange={(e) =>
                          setFieldValue("mixedTech", e.currentTarget.value)
                        }
                        aria-labelledby="enable-mixed-tech"
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Y"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="N"
                        />
                      </RadioGroup>
                    </FormControl> */}
                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Add/edit property logo
                      </FormLabel>
                      {/* <BigAvatar alt="Remy Sharp" src={imageData} /> */}
                    </FormControl>
                    <ChoosePoiImage
                      uploadedImage={imageData}
                      setUploadedImage={(file) => setImageData(file)}
                    />
                    {/* <Button
                      onClick={imageCropperOpen}
                      variant="contained"
                      color="primary"
                      component="span"
                      mt={3}
                    >
                      Choose Image
                    </Button> */}
                    {imageData && (
                      <Button
                        sx={{ marginLeft: "10px" }}
                        onClick={imageCropperClear}
                        variant="contained"
                        color="primary"
                        component="span"
                        mt={3}
                      >
                        Clear
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <Textfield
                        name="adminName"
                        label="Admin name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ maxLength: 20 }}
                        autoComplete="off"
                        placeholder="John Smith"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="adminEmail"
                        label="Admin email *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="me@you.com"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="adminSMS"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Admin SMS *"
                        placeholder="Number only, no dashes"
                        autoComplete="off"
                      />
                    </FormControl>
                    <StyledSwitchControl sx={{ marginTop: "12px" }}>
                      <FormGroup>
                        <FormControlLabel
                          label={
                            <Typography>
                              Show dispatch for this property
                              <Tooltip
                                title={
                                  "Leave off unless you have a dedicated dispatcher"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchone"
                              value="N"
                              checked={values.switchone === "Y"}
                              onChange={(event, checked) => {
                                setFieldValue("switchone", checked ? "Y" : "N");
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Show only logged in users in officer dispatched
                              dropdown?
                              <Tooltip
                                title={
                                  "If yes, will only list officers that are currently logged in. Only applies to property users when logged into Live Dispatch"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchtwo"
                              value="N"
                              checked={values.switchtwo === "Y"}
                              onChange={(event, checked) => {
                                setFieldValue("switchtwo", checked ? "Y" : "N");
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Show location in DAR Report?
                              <Tooltip
                                title={
                                  "If yes, shows location column in DAR report"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchthree"
                              checked={values.switchthree === 1}
                              value={0}
                              onChange={(event, checked) => {
                                setFieldValue("switchthree", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Lost and found shipping setting
                              <Tooltip
                                title={
                                  "Only set to yes if using the shipping system integrated into lost and found"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchfour"
                              checked={values.switchfour === "LIVE"}
                              value="TEST"
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "switchfour",
                                  checked ? "LIVE" : "TEST"
                                );
                              }}
                            />
                          }
                        />
                        {/* <FormControlLabel
                          label="Run ably for this property?"
                          control={
                            <Switch
                              name="switchfive"
                              checked={values.switchfive === 1}
                              value={0}
                              onChange={(event, checked) => {
                                setFieldValue("switchfive", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Enable DynamoDB?"
                          control={
                            <Switch
                              name="switchsix"
                              value={0}
                              checked={values.switchsix === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchsix", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Perform Covid-19 tech checks?"
                          control={
                            <Switch
                              name="switchseven"
                              value={0}
                              checked={values.switchseven === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchseven", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Enable Amazon SES for email?"
                          control={
                            <Switch
                              name="switcheight"
                              value="ses"
                              checked={values.switcheight === "sendgrid"}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "switcheight",
                                  checked ? "sendgrid" : "ses"
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Enable FCM for mobile?"
                          control={
                            <Switch
                              name="switchnine"
                              value={0}
                              checked={values.switchnine === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchnine", checked ? 1 : 0);
                              }}
                            />
                          }
                        /> */}
                        <FormControlLabel
                          label={
                            <Typography>
                              Show mobile reminder if logging out with
                              unsubmitted IR?
                              <Tooltip
                                title={
                                  "If yes, prevents mobile user from logging out of their device if they have an IR that is not yet submitted"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchten"
                              value={0}
                              checked={values.switchten === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchten", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Enable site feature?
                              <Tooltip
                                title={
                                  "Set to yes if you as a client of SOVA manage multiple sites"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switcheleven"
                              value={0}
                              checked={values.switcheleven === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switcheleven", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Is this site a hotel?
                              <Tooltip
                                title={
                                  "If yes, adds various hotel-specific features in some reports and screens"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchtwelve"
                              value={0}
                              checked={values.switchtwelve === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchtwelve", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography> Allow adding devices?</Typography>
                          }
                          control={
                            <Switch
                              name="allow_adding_device"
                              value={0}
                              checked={values.allow_adding_device === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "allow_adding_device",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={<Typography>Can add sites?</Typography>}
                          control={
                            <Switch
                              name="can_add_sites"
                              value={0}
                              checked={values.can_add_sites === 1}
                              onChange={(event, checked) => {
                                setFieldValue("can_add_sites", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>Shift Equipment Report?</Typography>
                          }
                          control={
                            <Switch
                              name="shift_equipment_report"
                              value={0}
                              checked={values.shift_equipment_report === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "shift_equipment_report",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </StyledSwitchControl>
                    <StyledFormControl sx={{ marginTop: "15px" }}>
                      <FormLabel
                        component="legend"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Select modules
                        {
                          <>
                            <sup
                              style={{
                                verticalAlign: "top",
                              }}
                            >
                              *
                            </sup>
                            <Tooltip
                              title={
                                "Choose the modules you will use at this site"
                              }
                              arrow
                              placement="top"
                            >
                              <ErrorIcon
                                className={classes.tooltipIcon}
                                fontSize="small"
                                color="primary"
                              />
                            </Tooltip>
                          </>
                        }
                      </FormLabel>
                      <FormGroup>
                        {modules.map((mod, index) => {
                          return (
                            <FormControlLabel
                              key={mod.id}
                              control={
                                <Checkbox
                                  disableRipple
                                  name={mod.module_name}
                                  value={mod.module_value}
                                  // id={mod.id}
                                  onChange={(e) => {
                                    const { value, checked } = e.target;
                                    if (checked) {
                                      let filter = [
                                        ...values.module_list,
                                        value,
                                      ];
                                      setFieldValue("module_list", filter);
                                    } else {
                                      let filter = values.module_list.filter(
                                        (e) => e !== value
                                      );
                                      setFieldValue("module_list", filter);
                                    }
                                  }}
                                />
                              }
                              label={mod.module_name}
                              // checked={
                              //   data.shift3_time_notif.includes(mod.id)
                              //     ? true
                              //     : false
                              // }
                            />
                          );
                        })}
                      </FormGroup>
                      <FormHelperText
                        style={{ marginLeft: "0px", color: "#d32f2f" }}
                      >
                        {errors.module_list}
                      </FormHelperText>
                    </StyledFormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="incognitoPropertyName"
                        label="Incognito property name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="False name visible during demos"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="street"
                        label="Street"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="city"
                        label="City"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="state"
                        label="State"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="country"
                        label="Country"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="zip"
                        label="Zip"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <SelectedCurrency
                        name="selectCurrency"
                        label="Currency"
                        options={currency}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      mt={6}
                      sx={{ width: "175px" }}
                      disabled={isDisabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {imageCropperDialog && (
        <ImageCropper
          imageCropperDialog={imageCropperDialog}
          setImageCropperDialog={setImageCropperDialog}
          imageCropperOpen={imageCropperOpen}
          imageCropperClose={imageCropperClose}
          childToParent={childToParent}
          imageData={imageData}
          dialogHead="Choose a property logo"
        />
      )}
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}

      <Dialog
        open={successModalOpen}
        onClose={handleModalClose}
        maxWidth={"md"}
        PaperProps={{
          sx: { width: "500px" }, // Set the desired width here
        }}
      >
        <DialogTitle>
          <Typography variant="h5">Select Source Location</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body1">
              OPTIONAL: Select source location for copying Non-Tour Tasks and/or
              Incident Classifications. You can always add these later by
              cloning data from another location.
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}
            >
              <Checkbox
                checked={showTaskSelect}
                onChange={handleTaskCheckboxChange}
              />
              <Typography variant="h5" sx={{ paddingLeft: "6px" }}>
                Copy Task
              </Typography>
            </Box>
            {showTaskSelect && (
              <FormControl mt={3} sx={{ width: "100%", marginLeft: "11px" }}>
                <InputLabel id="copy-task">Select Source Location</InputLabel>
                <Select
                  fullWidth
                  id="task-location"
                  name="taskLocation"
                  label="Select location"
                  labelId="copy-task"
                  onChange={handleTaskLocationChange}
                  value={taskLocation}
                  variant="outlined"
                  displayEmpty
                >
                  {propertyList.map((data) => (
                    <MenuItem value={data.LocationID} key={data.LocationID}>
                      {data.LocationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={showIrClassificationSelect}
                onChange={handleIrClassificationCheckboxChange}
              />
              <Typography variant="h5" sx={{ paddingLeft: "6px" }}>
                Copy IR Classification
              </Typography>
            </Box>
            {showIrClassificationSelect && (
              <FormControl mt={3} sx={{ width: "100%", marginLeft: "11px" }}>
                <InputLabel id="ir-classification">
                  Select Source Location
                </InputLabel>
                <Select
                  fullWidth
                  id="ir-location"
                  name="irLocation"
                  label="Select location"
                  labelId="ir-classification"
                  onChange={handleIrLocationChange}
                  value={irLocation}
                  variant="outlined"
                  displayEmpty
                >
                  {listLocation.map((item) => (
                    <MenuItem value={item.LocationID} key={item.LocationID}>
                      {item.LocationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModalClose}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCopyTask();
              copyIRTypes();
              handleModalClose();
            }}
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
function AddProperty() {
  return (
    <React.Fragment>
      <Helmet title="Add Property" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Property
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/accounts/property-list">
          Accounts
        </Link>
        <Typography>Add Property</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddPropertyForm />
    </React.Fragment>
  );
}

export default AddProperty;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import MUIRichTextEditor from "mui-rte";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import EditIcon from "@mui/icons-material/Edit";
import EditIrTask from "./inner-component/EditIrTask";

import {
  Button,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  IconButton,
  TableRow,
  TableCell,
  Tooltip,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  CircularProgress,
  DialogContent,
  Checkbox,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  ViewIr,
  EditIr,
  TaskLibsList,
  DeleteTaskLibs,
  IrNameChange,
} from "../../../api";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;

const taskTag = [
  { key: "Contact", value: "contact" },
  { key: "Photos", value: "photos" },
  { key: "Followup", value: "followup" },
  { key: "Meeting", value: "meeting" },
  { key: "Video", value: "video" },
];
const dueDate = [
  { key: "Same Day", value: "0" },
  { key: "1 Day", value: "1day" },
  { key: "2 Days", value: "2days" },
  { key: "3 Days", value: "3days" },
];
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textEditor: {
    position: "relative",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    borderRadius: "4px",
    "& #mui-rte-container": {
      marginTop: 0,
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      position: "absolute",
      padding: "15px",
      display: "block",
    },
    "& #mui-rte-editor": {
      height: "180px",
      overflowY: "auto",
    },
    "& #mui-rte-editor-container": {
      height: "170px",
      overflowY: "auto",
      borderTop: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    },
    "& #mui-rte-toolbar": {
      padding: "5px 15px",
    },
    "& .DraftEditor-editorContainer": {
      padding: "15px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function AddVisitorForm(props) {
  let initialValues = {
    classification_name: "",
    classification_desc: "",
    classification: "",
    contact_corporate_nurse: "",
    call_guest: "",
    guest_description: "",
    task_tag: "",
    due_date: "",
  };
  const { id } = useParams();
  const handleActionClick = (row) => {
    setUpdatedRow(row);
  };
  const [editIrTask, setEditIrTask] = useState(false);
  const editIrTaskOpen = () => {
    setEditIrTask(true);
  };
  const editIrTaskClose = () => {
    setEditIrTask(false);
  };
  const [updatedRow, setUpdatedRow] = useState(null);
  const [libListData, setLibListData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [pageLoad, setPageLoad] = useState(false);
  const [formData, setFormData] = useState({
    classification_name: "",
    classification_desc: "",
    classification: "",
    contact_corporate_nurse: "yes",
    image_required: "no",
    narrative: "",
    data: [{ taskTitle: "", taskDesc: "", taskTag: "", dueDate: "0" }],
  });
  const [saveConfirmationModal, setSaveConfirmationModal] = useState(false);
  const [initialClassificationName, setInitialClassificationName] =
    useState("");
  const [materiallyNameChanges, setMateriallyNameChanges] = useState(false);
  const [confirmNameChangesModal, setConfirmNameChangesModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleChange = (event, index) => {
    console.log("name", event.target.name.split("_")[1]);
    const fieldName = event.target.name; // extract the field name (call_guest, guest_description, etc.) from the event target name
    const fieldValue = event.target.value;
    setFormData((prevFormData) => {
      const newData = [...prevFormData.data]; // create a new array with a copy of the existing data
      newData[index][fieldName] = fieldValue; // update the value of the field for the row at the specified index
      return { ...prevFormData, data: newData }; // return the updated form data object
    });
  };
  console.log("name", updatedRow);
  //handler for opening the delete dialog box
  const handleDeleteOpen = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = (index) => {
    setFormData((prevFormData) => {
      const newData = [...prevFormData.data];
      newData.splice(index, 1);
      return { ...prevFormData, data: newData };
    });
  };
  const taskLibsList = () => {
    TaskLibsList(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setLibListData(res.data);
          console.log("successfully", res.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("form", editIrTask);
  const viewIr = () => {
    ViewIr(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setFormData((prev) => ({
            ...prev,
            classification_name: res.data.IncidentTypeName,
            classification_desc: res.data.IncidentDescription,
            classification: res.data.classification,
            contact_corporate_nurse: res.data.ohm,
            image_required: res.data.image_required,
            narrative: res.data.narrative,
          }));
          setInitialClassificationName(res.data.IncidentTypeName);
          console.log("successfully", res.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const classes = useStyles();
  const deleteTaskLibs = () => {
    DeleteTaskLibs(deleteId)
      .then((res) => {
        if (res.statusCode === 200) {
          taskLibsList();
          dispatch(getMsg("Data deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = () => {
    if (formData.classification_name !== initialClassificationName) {
      setSaveConfirmationModal(true);
    } else {
      editIr();
    }
  };

  const editIr = () => {
    setPageLoad(true);
    let payload = {
      id: id,
      classification_name: formData.classification_name,
      materiallyChangesClassification: materiallyNameChanges,
      classification_desc: formData.classification_desc,
      classification: formData.classification,
      contact_corporate_nurse: formData.contact_corporate_nurse,
      image_required: formData.image_required,
      narrative: formData.narrative,
      data:
        formData.data.length === 1 &&
        (formData.data[0].taskTitle == "" || formData.data[0].taskDesc == "")
          ? []
          : formData.data,
    };
    EditIr(payload)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode == 200) {
          if (payload.materiallyChangesClassification) {
            setResponseData(res);
            setConfirmNameChangesModal(true);
            setSaveConfirmationModal(false);
          } else {
            dispatch(getMsg("Data updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            navigate({
              pathname: "/settings/corporate-user/ir-classifications",
            });
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
      });
  };

  const irNameChange = async () => {
    const payload = {
      id: id,
      newName: formData.classification_name,
    };
    setPageLoad(true);
    try {
      const response = await IrNameChange(payload);
      setConfirmNameChangesModal(false);
      dispatch(getMsg("Data updated successfully"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      navigate({
        pathname: "/settings/corporate-user/ir-classifications",
      });
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } catch (error) {
      console.error("Error while saving IR name: ", error);
      dispatch(getMsg("Something went wrong, please try again"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } finally {
      setPageLoad(false);
    }
  };

  useEffect(() => {
    taskLibsList();
    viewIr();
  }, []);
  return (
    <>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"are you sure you want to delete this task?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteTaskLibs();
              handleDeleteClose();
            }}
            autoFocus
            color="primary"
            sx={{ ml: "10px" }}
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog box which appears while saving only if IR classification  name changes*/}
      <Dialog
        open={saveConfirmationModal}
        onClose={() => setSaveConfirmationModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "Does the name change you are making here materially change the IR classification? An example of a material change would be changing the name of an incident classification from ‘Guest Injury’ to ‘Auto Accident'. If the change you are making materially changes the Incident Classification, tick the box below. If the name change does not materially change the classification, do not tick the box."
          }
        </DialogTitle>
        <DialogContent>
          <FormControlLabel
            label="Name change materially changes the classification"
            control={
              <Checkbox
                checked={materiallyNameChanges}
                onChange={() =>
                  setMateriallyNameChanges(!materiallyNameChanges)
                }
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSaveConfirmationModal(false)}
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={() => editIr()} autoFocus variant="contained">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      {/* Adtional Confirmartion Dialog box if materiallyName changes box is ticked */}
      <Dialog
        open={confirmNameChangesModal}
        onClose={() => setConfirmNameChangesModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Name Change</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" style={{ fontSize: "inherit" }}>
            There are{" "}
            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: "bold", fontSize: "inherit" }}
            >
              {responseData?.irCount}
            </Typography>{" "}
            incident reports written for your property that are classified as{" "}
            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: "bold", fontSize: "inherit" }}
            >
              {responseData?.oldName}
            </Typography>{" "}
            . Checking this box will update these incident reports to be
            classified as{" "}
            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: "bold", fontSize: "inherit" }}
            >
              {formData.classification_name}
            </Typography>
            . Proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmNameChangesModal(false)}
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={() => irNameChange()} autoFocus variant="contained">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Card mb={6}>
        <CardContent>
          <Formik initialValues={initialValues}>
            <form>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <CustomTextField
                      id="classification-name"
                      value={formData.classification_name}
                      name="IncidentTypeName"
                      InputLabelProps={{ shrink: true }}
                      label="IR classification name*"
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          classification_name: event.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <CustomTextField
                      id="classification-desc"
                      name="classification_desc"
                      value={formData.classification_desc}
                      InputLabelProps={{ shrink: true }}
                      label="IR classification description"
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          classification_desc: event.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel>Classification*</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="classification"
                      name="classification"
                      value={formData.classification}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          classification: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="critical"
                        control={<Radio />}
                        label="Critical"
                      />
                      <FormControlLabel
                        value="noncritical"
                        control={<Radio />}
                        label="Non critical"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel>Contact corporate nurse</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="contact-corporate-nurse"
                      name="contact_corporate_nurse"
                      value={formData.contact_corporate_nurse}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          contact_corporate_nurse: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="Y"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" component="div" gutterBottom>
                    Tasks Assigned to Incident Class
                  </Typography>
                  <Typography>
                    Add default tasks below that should be automatically added
                    to this incident classification. For example, you may have
                    an incident classification for Guest Injury. Here you can
                    add a default task to followup with the guest within 24
                    hours, then set a due date for 1 day, giving your team 24
                    hours to complete the task after the IR is saved. Tasks you
                    add here will be attached to the incident when saved. Manage
                    unfinished tasks for all incidents from the context menu in
                    the incident list, or by selecting Task List from the action
                    menu for open IR's.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card variant="outlined" sx={{ borderBottom: 0 }}>
                    <TableContainer className="tableContainer">
                      <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Task Title</TableCell>
                            <TableCell>Task Description</TableCell>
                            <TableCell>Task Tag</TableCell>
                            <TableCell colSpan={2}>Due Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {libListData.map((item, index) => {
                            return (
                              <TableRow>
                                <TableCell sx={{ minWidth: "200px" }}>
                                  {item.taskTitle}
                                </TableCell>
                                <TableCell sx={{ minWidth: "200px" }}>
                                  {item.taskDesc}
                                </TableCell>
                                <TableCell sx={{ minWidth: "200px" }}>
                                  {item.taskTag}
                                </TableCell>
                                <TableCell sx={{ minWidth: "200px" }}>
                                  {item.due_date}
                                </TableCell>
                                <TableCell sx={{ minWidth: "200px" }}>
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      editIrTaskOpen();
                                      handleActionClick(item);
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                  <Button
                                    color="error"
                                    onClick={() => handleDeleteOpen(item.id)}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}

                          {formData.data.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ minWidth: "200px" }}>
                                <FormControl>
                                  <CustomTextField
                                    id={`call-guest-${index}`}
                                    name="taskTitle"
                                    placeholder="Call guest"
                                    onChange={(event) =>
                                      handleChange(event, index)
                                    }
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell sx={{ minWidth: "200px" }}>
                                <FormControl>
                                  <CustomTextField
                                    id={`guest-description-${index}`}
                                    name="taskDesc"
                                    placeholder="Make contact with guest within 24 hours"
                                    onChange={(event) =>
                                      handleChange(event, index)
                                    }
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell sx={{ minWidth: "200px" }}>
                                {/* <FormControl>
                                  <CustomSelect
                                    name="taskTag"
                                    displayEmpty
                                    options={taskTag}
                                    onChange={(event) =>
                                      handleChange(event, index)
                                    }
                                  />
                                </FormControl> */}
                                <FormControl mt={3}>
                                  <Select
                                    name="taskTag"
                                    onChange={(event) =>
                                      handleChange(event, index)
                                    }
                                    displayEmpty
                                  >
                                    {taskTag.map((item) => {
                                      return (
                                        <MenuItem value={item.value}>
                                          {" "}
                                          {item.key}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell sx={{ minWidth: "200px" }}>
                                {/* <FormControl>
                                  <CustomSelect
                                    name="dueDate"
                                    displayEmpty
                                    options={dueDate}
                                    onChange={(event) =>
                                      handleChange(event, index)
                                    }
                                  />
                                </FormControl> */}
                                <FormControl mt={3}>
                                  <Select
                                    name="dueDate"
                                    onChange={(event) =>
                                      handleChange(event, index)
                                    }
                                    displayEmpty
                                    defaultValue={"0"}
                                  >
                                    {dueDate.map((item) => {
                                      return (
                                        <MenuItem value={item.value}>
                                          {" "}
                                          {item.key}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                {index == 0 && (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                      setFormData((prevFormData) => {
                                        const newData = [
                                          ...prevFormData.data.slice(
                                            0,
                                            index + 1
                                          ),
                                          {
                                            taskTitle: "",
                                            taskDesc: "",
                                            taskTag: "",
                                            dueDate: "",
                                          },
                                          ...prevFormData.data.slice(index + 1),
                                        ];
                                        return {
                                          ...prevFormData,
                                          data: newData,
                                        };
                                      });
                                    }}
                                    sx={{ minWidth: "40px", px: 0 }}
                                  >
                                    <AddIcon />
                                  </Button>
                                )}
                                {index != 0 && (
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => handleDelete(index)}
                                    sx={{ minWidth: "40px", px: 0 }}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      Images Required?{"*"}
                      <Tooltip
                        color="primary"
                        title="If yes, user will be reminded to add images when saving the IR"
                        placement="top"
                        sx={{ mb: "-6px" }}
                      >
                        <ErrorIcon fontSize="small" />
                      </Tooltip>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="contact-corporate-nurse"
                      name="image_required"
                      value={formData.image_required}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          image_required: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  {/* <FormControl>
                  <FormLabel>
                    Boilerplate narrative
                    {
                      <Tooltip
                        color="primary"
                        title="Build consistency in your IR writing by optionally creating a default narrative to be used for each classification. Add {{double curly brackets}} to call attention to words or phrases that officers need to change."
                        placement="bottom-end"
                        sx={{ mb: "-6px" }}
                      >
                        <ErrorIcon fontSize="small" />
                      </Tooltip>
                    }
                  </FormLabel>
                  <Box
                    className={classes.textEditor}
                    sx={{ marginTop: "15px" }}
                  >
                    <MUIRichTextEditor
                      label={
                        <Typography sx={{ padding: "15px" }}>
                          Type something here...
                        </Typography>
                      }
                      //   onSave={save}
                      inlineToolbar={true}
                    />
                  </Box>
                </FormControl> */}
                  <FormControl>
                    <FormLabel>
                      Boilerplate Narrative
                      {
                        <Tooltip
                          color="primary"
                          title="Build consistency in your IR writing by optionally creating a default narrative to be used for each classification. Add {{double curly brackets}} to call attention to words or phrases that officers need to change."
                          placement="bottom-end"
                          sx={{ mb: "-6px" }}
                        >
                          <ErrorIcon fontSize="small" />
                        </Tooltip>
                      }
                    </FormLabel>
                    {/* <CustomTextEditor name="desc_of_emergency" /> */}
                    <QuillWrapper>
                      <ReactQuill
                        theme="snow"
                        value={formData.narrative}
                        onChange={(value) =>
                          setFormData({
                            ...formData,
                            narrative: value,
                          })
                        }
                        placeholder="Type something.."
                      />
                    </QuillWrapper>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Formik>
        </CardContent>
      </Card>
      {editIrTask && (
        <EditIrTask
          editIrTask={editIrTask}
          setEditIrTask={setEditIrTask}
          editIrTaskOpen={editIrTaskOpen}
          editIrTaskClose={editIrTaskClose}
          taskLibsList={taskLibsList}
          updatedRow={updatedRow}
        />
      )}
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function EditIrClassification() {
  return (
    <React.Fragment>
      <Helmet title="Edit IR Classification" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit IR Classification
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link
          component={NavLink}
          to="/settings/corporate-user/ir-classifications"
        >
          Incident Classifications
        </Link>
        <Typography>Edit IR Classification</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddVisitorForm />
    </React.Fragment>
  );
}

export default EditIrClassification;

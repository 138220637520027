import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import PurchaseSmsCredits from "./inner-components/PurchaseSmsCredits";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import moment from "moment";
import {
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  SmsReportList,
  SmsReportColumns,
  GetMessageSubcriptionDetail,
} from "../../api";
import {
  smsUsageList,
  getSmsReportChecked,
  getSnackClose,
} from "../../redux/slices/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import Toaster from "../tasks/Toaster";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
// const useStyles = makeStyles((theme) => ({
//   alphabets: {
//     marginTop: "15px",
//     "& .MuiList-root": {
//       display: "flex",
//       overflowX: "auto",
//       background: "#7aa8ff0d",
//       padding: "0px",
//       "& .MuiListItemButton-root": {
//         paddingLeft: "13px",
//         paddingRight: "13px",
//         textAlign: "center",
//       },
//     },
//     "& .MuiList-root::-webkit-scrollbar": {
//       width: "5px",
//       height: "5px",
//     },
//     "& .MuiList-root::-webkit-scrollbar-track": {
//       backgroundColor: "#bed2f561",
//     },
//     "& .MuiList-root::-webkit-scrollbar-thumb": {
//       backgroundColor: "#92a6c96e",
//     },
//   },
// }));

// const TableWrapper = styled.div`
//   overflow-y: auto;
//   max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
// `;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      // "& .MuiMenuItem-root:hover": {
      //   background: "transparent",
      // },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "200px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.report.smsReportChecked);

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  return (
    <TableHead>
      <TableRow>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            // padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.datetime ? order : false}
          >
            <TableSortLabel
              active={
                headCell.key === "comm_tab" ? orderBy === headCell.key : false
              }
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={
                headCell.key === "comm_tab"
                  ? createSortHandler(headCell.key)
                  : null
              }
              hideSortIcon={headCell.key === "comm_tab" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    filterRecord,
    dataToFilter,
    setDataToFilter,
    handleFirstPage,
    colArray,
    getColumnList,
    getColumnChecked,
    setCurrentPage,
  } = props;
  const [isFilter, setIsFilter] = useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [searchInput, setSearchInput] = useState("");
  const checkedValue = useSelector((state) => state.report.smsReportChecked);
  const [actions, setActions] = React.useState("");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);
  // Tour Select
  const [tourSelect, setTourSelect] = React.useState("");
  const selectTour = (event) => {
    setTourSelect(event.target.value);
  };
  // Button Select
  const [userSelect, setUserSelect] = React.useState("");
  const selectUser = (event) => {
    setUserSelect(event.target.value);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //Scan Visitor ID modal
  const [open, visitorModal] = React.useState(false);
  // const scanVisitorModalOpen = () => {
  //   visitorModal(true);
  // };
  // const scanVisitorModalClose = () => {
  //   visitorModal(false);
  // };

  const applySearch = () => {
    const payload = {
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      phone_no: searchInput,
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    filterRecord({});
  };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getSmsReportChecked([...checkedValue, value]));
    } else {
      dispatch(getSmsReportChecked(checkedValue.filter((e) => e !== value)));
    }
  };

  return (
    <Toolbar>
      {/* <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"}>Delete</MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div> */}
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              type="text"
              autoComplete="off"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                  // handleFirstPage();
                  setCurrentPage(1);
                }
              }}
              placeholder="Search by phone number, or click down arrow"
              inputProps={{}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form>
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Date range"
                              autoComplete="off"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM/DD/YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM/DD/YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              // onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>
                          <FormControl>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              onClick={(e) => {
                                applySearch();
                                setIsFilter(true);
                                handleCloseForm();
                                setCurrentPage(1);
                              }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setSearchInput("");
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100px",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                }}
              >
                Clear Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100px",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchInput !== "") {
                    applySearch();
                    setIsFilter(true);
                    // handleFirstPage();
                    setCurrentPage(1);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const {
    sovaCreditSystem,
    setSovaCreditSystem,
    sovaCreditSystemOpen,
    sovaCreditSystemClose,
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const dispatch = useDispatch();
  const smsReportList = useSelector((state) => state.report.smsUsageData);
  const checkedValue = useSelector((state) => state.report.smsReportChecked);
  const Loggedlocation = useSelector((state) => state.login.Location);
  const [totalAvaiable, setTotalAvaiable] = useState("");
  const [totalSent, setTotalSent] = useState("");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = smsReportList.map((n) => n.phone_no);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, datetime) => {
    const selectedIndex = selected.indexOf(datetime);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, datetime);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setSelected([]);
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (datetime) => selected.indexOf(datetime) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelected([]);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };
  // Methods for calling the APIs
  const getSmsUsageList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      ...dataToFilter,
    }; //this will be used for sending filtered data
    SmsReportList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(smsUsageList(res.data));
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          dispatch(smsUsageList(res.data));
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        dispatch(smsUsageList([]));
      });
  };

  //API call for getting SMS subscribed Plan details
  const getSubcriptionDetail = () => {
    GetMessageSubcriptionDetail(Loggedlocation.LocationID)
      .then((res) => {
        if (res.statusCode == 200) {
          setTotalAvaiable(res.data.available_sms);
          setTotalSent(res.data.total_sent);
        } else {
          setTotalAvaiable("");
          setTotalSent("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    SmsReportColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    SmsReportColumns()
      .then((res) => {
        dispatch(getSmsReportChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Side-effects
  useEffect(() => {
    getSmsUsageList();
    getColumnList();
    getSubcriptionDetail();
  }, [rowsPerPage, currentPage, dataToFilter]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
  }, []);

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          filterRecord={(data) => filterRecord(data)}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          handleFirstPage={handleFirstPage}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
          setCurrentPage={setCurrentPage}
        />
        <Paper sx={{ padding: "15px 15px 0" }}>
          <Typography variant="p" component="div" gutterBottom>
            Total Available: {totalAvaiable}
          </Typography>
          <Typography variant="p" component="div" gutterBottom>
            Total Sent: {totalSent}
          </Typography>
        </Paper>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={smsReportList.length}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(smsReportList, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.phone_no);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.datetime)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}
                          {checkedValue.includes("comm_tab") && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              // padding="none"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.comm_tab}
                            </TableCell>
                          )}
                          {checkedValue.includes("phone_no") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.phone_no}
                            </TableCell>
                          )}
                          {checkedValue.includes("send_date") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.send_date}
                            </TableCell>
                          )}
                          {checkedValue.includes("location") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.location}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={6} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {sovaCreditSystem && (
        <PurchaseSmsCredits
          sovaCreditSystem={sovaCreditSystem}
          setSovaCreditSystem={setSovaCreditSystem}
          sovaCreditSystemOpen={sovaCreditSystemOpen}
          sovaCreditSystemClose={sovaCreditSystemClose}
          getSubcriptionDetail={getSubcriptionDetail}
        />
      )}
    </div>
  );
}

function SmsReports() {
  // Purchase SMS Credits
  const [sovaCreditSystem, setSovaCreditSystem] = React.useState(false);
  const dispatch = useDispatch();
  const sovaCreditSystemOpen = () => {
    setSovaCreditSystem(true);
  };
  const sovaCreditSystemClose = () => {
    setSovaCreditSystem(false);
  };

  const snackbarStatus = useSelector((state) => state.report.snackStatus);
  const snackbarMsg = useSelector((state) => state.report.snackMsg);
  const snackbarAlert = useSelector((state) => state.report.snackAlert);
  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  return (
    <React.Fragment>
      <Helmet title="SMS Usage Reports" />
      <WithPermissionFallback controller="Reports" action={"smsLog"}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            <Typography variant="h3" gutterBottom display="inline">
              SMS Usage Reports
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} align="right">
            <WithPermissionHide controller="Reports" action="getSmsCreditPopup">
              <Button
                color="primary"
                variant="outlined"
                onClick={sovaCreditSystemOpen}
              >
                Purchase SMS Credits
              </Button>
            </WithPermissionHide>
          </Grid>
        </Grid>

        {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable
              sovaCreditSystem={sovaCreditSystem}
              setSovaCreditSystem={setSovaCreditSystem}
              sovaCreditSystemOpen={sovaCreditSystemOpen}
              sovaCreditSystemClose={sovaCreditSystemClose}
            />
          </Grid>
        </Grid>

        {/* snackbar after successfull payment */}
        {snackbarStatus && (
          <Toaster
            openSnack={snackbarStatus}
            handleCloseSnack={handleCloseSnack}
            severity={snackbarAlert}
            message={snackbarMsg}
          />
        )}
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default SmsReports;

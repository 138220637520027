import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  Typography,
  Card,
  CardContent,
  Box,
  CardMedia,
  CircularProgress,
  Paper,
} from "@mui/material";
import Barcode from "react-barcode";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const BadgePrint = ({
  badgePrintDialog,
  badgePrintDialogClose,
  barCodeId,
  viewData,
  addVisitorKey,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const authEndDate = new Date(
    viewData?.visitor_auth_end_date
  ).toLocaleDateString();
  const status =
    authEndDate && authEndDate < new Date().toLocaleDateString()
      ? "EXPIRED"
      : viewData?.visitor_auth_end_date;
  const redirectUrl = "/visitors/list-visitors";

  const [hideButton, setHideButtons] = useState(false);
  const [loading, setLoading] = useState(false); //State for loader

  const checkButton = () => {
    setLoading(true);
    setHideButtons(true);
    setTimeout(() => {
      setLoading(false);
      window.print();
      setHideButtons(false);
    }, 2000);
  };
  return (
    <>
      <Dialog
        aria-labelledby="print-badge"
        aria-describedby="print-badge-desc"
        open={badgePrintDialog}
        onClose={badgePrintDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="print-badge"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Print Badge
          </Typography>
          {hideButton == false && (
            <DialogActions>
              <IconButton
                onClick={() => {
                  if (addVisitorKey) {
                    navigate(redirectUrl);
                  } else {
                    badgePrintDialogClose();
                  }
                }}
                sx={{ marginRight: "-10px" }}
              >
                <CancelRoundedIcon />
              </IconButton>
            </DialogActions>
          )}
        </DialogTitle>
        <DialogContent>
          <Card sx={{ display: "flex" }}>
            <Box>
              {viewData.image ? (
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={viewData.image}
                  alt="Meri derin"
                />
              ) : (
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={`/static/img/avatars/user.webp`}
                  alt="Meri derin"
                />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="h4"
                >
                  {viewData?.visitor_type}
                </Typography>
                <Typography variant="h3" component="div" gutterBottom>
                  {viewData?.name}
                </Typography>
                <Typography>{viewData?.company_name}</Typography>
                <Typography>Host: {viewData?.host_name}</Typography>
                <Typography>
                  <strong>Issued:{viewData?.visitor_auth_start_date}</strong>
                </Typography>
                <Typography>
                  <strong>Expires: </strong>
                  {status}
                </Typography>
              </CardContent>
              <Box
                sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
              ></Box>
            </Box>
          </Card>
          <Box
            sx={{
              width: 80,
              mx: "auto",
              "& svg": {
                width: "80px",
                height: "auto",
              },
            }}
          >
            <Barcode
              value={barCodeId}
              // lineColor="#FF0000"
              background="#FFFFFF"
            />
          </Box>
          <DialogContentText sx={{ textAlign: "center", py: 3 }}>
            {viewData.visitor_escort == "Yes" ? "Escort Required" : null}
          </DialogContentText>
        </DialogContent>
        {hideButton == false && (
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              component={NavLink}
              to="/visitors/list-visitors"
            >
              Back to List
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                if (addVisitorKey) {
                  navigate({
                    pathname: "/visitors/edit-visitor/" + viewData.id,
                  });
                } else {
                  badgePrintDialogClose();
                }
              }}
              sx={{ mx: 3 }}
            >
              Edit Visitor
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => checkButton()}
            >
              Send to Printer
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default BadgePrint;

import React, { useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { format } from "date-fns";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  FormControl as MuiFormControl,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormLabel,
  ToggleButtonGroup,
  ToggleButton,
  TextareaAutosize as MuiTextareaAutosize,
  Tooltip,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import { saveIncidentInvestigation } from "../../../api";
import { DatePicker } from "@mui/lab";
import {
  CancelRounded as CancelRoundedIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  FormatBold as FormatBoldIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  LocalPrintshop as LocalPrintshopIcon,
  Restore as RestoreIcon,
  FullscreenExit as FullscreenExitIcon,
  Code as CodeIcon,
  AddBox as AddBoxIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const InvestigativeReport = ({
  investigativeReport,
  investigativeReportClose,
  editableData,
}) => {
  console.log(editableData);
  const [reviewed, setReviewed] = useState("");
  const dispatch = useDispatch();
  const [investigate, setInvestigate] = useState([
    { id: 1, date: null, comments: "", image: null },
  ]);

  const handleAddField = () => {
    const lastField = investigate[investigate.length - 1];
    const newField = {
      id: lastField.id + 1,
      date: null,
      comments: "",
      image: null,
    };
    setInvestigate([...investigate, newField]);
  };

  const handleDeleteField = (id) => {
    setInvestigate(investigate.filter((field) => field.id !== id));
  };

  const handleFieldChange = (id, field, value) => {
    setInvestigate(
      investigate.map((f) => (f.id === id ? { ...f, [field]: value } : f))
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("Form submitted:", investigate);
    // console.log(
    //   "hellasdfa",
    //   investigate.map(({ id, ...rest }) => rest)
    // );
    let payload = {
      data: investigate.map(({ id, ...rest }) => rest),
      incident_id: editableData ? editableData.IncidentID : "",
      reviewed_by: reviewed,
    };
    if (investigate.comments != "" && investigate.date != null) {
      saveIncidentInvestigation(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            investigativeReportClose();
            console.log("sucess");
          } else {
            dispatch(getMsg("Something went wrong"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getMsg("Something went wrong"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } else {
      dispatch(getMsg("Pease enter date and comments"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }

    // Do something with the form data, e.g. send it to a server
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1100px",
            },
          },
        }}
        aria-labelledby="investigative-title"
        aria-describedby="investigative-description"
        open={investigativeReport}
        onClose={investigativeReportClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="investigative-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Investigative Report:{" "}
            {editableData
              ? editableData.IncidentTypeID == -1
                ? editableData.IncidentTypeOther
                : editableData.IncidentTypeID
              : ""}{" "}
            -{" "}
            {editableData
              ? editableData.start_time
                ? editableData.start_time
                : ""
              : ""}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={investigativeReportClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="investigative-description">
            Create a timeline of the incident using the form fields below.
            Consider actions or activities on the part of employees or the
            involved persons (IP) that may have contributed to the incident.
            Adding timestamped entries documenting the events leading up to the
            incident is helpful. Be as detailed as you need to ensure you
            capture all relevant details that could be helpful in this
            investigation. Add specific timelines for CCTV footage, names and
            timestamps of employee or witnesses who encountered an IP prior to
            the incident, etc. Add additional rows to the timeline by clicking
            the plus icon.
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6} sx={{ marginTop: "10px" }}>
              <Grid item xs={12} md={12} lg={12} textAlign="right">
                <Tooltip title="Add" placement="top">
                  <IconButton onClick={handleAddField} color="primary">
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              {investigate.map((it, index) => {
                return (
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container spacing={6} sx={{ marginTop: "10px" }}>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            lg={3}
                            order={{ lg: 1, xs: 2 }}
                          >
                            <FormControl mt={3}>
                              <DatePicker
                                id="date-range"
                                label="Date"
                                value={it.date}
                                onChange={(newValue) =>
                                  handleFieldChange(
                                    it.id,
                                    "date",
                                    format(newValue, "yyyy-MM-dd")
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "mm/dd/yyyy",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={5}
                            order={{ lg: 2, xs: 3 }}
                          >
                            <FormControl>
                              <FormLabel>Comments</FormLabel>
                              <QuillWrapper>
                                <ReactQuill
                                  theme="snow"
                                  value={it.comments}
                                  onChange={(value) =>
                                    handleFieldChange(it.id, "comments", value)
                                  }
                                  placeholder="Type something.."
                                />
                              </QuillWrapper>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            lg={3}
                            order={{ lg: 3, xs: 4 }}
                          >
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                type="file"
                                id="file"
                                label="Image"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                // value={it.image}
                                onChange={(event) => {
                                  const reader = new FileReader();
                                  const file = event.target.files[0];
                                  reader.onload = () => {
                                    const base64Image = reader.result;
                                    handleFieldChange(
                                      it.id,
                                      "image",
                                      base64Image
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }}
                                placeholder=""
                              />
                            </FormControl>
                          </Grid>
                          {investigate.length > 1 && (
                            <Grid item xs={12} lg={1} order={{ lg: 4, xs: 4 }}>
                              <Tooltip
                                color="error"
                                title="Delete"
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => handleDeleteField(it.id)}
                                  color="error"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={6} lg={6} order={{ lg: 6, xs: 6 }}>
                <FormControl>
                  <TextField
                    fullWidth
                    id="reviewed-by"
                    label="Reviewed by"
                    value={reviewed}
                    onChange={(e) => setReviewed(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12} order={{ lg: 7, xs: 7 }}>
                <Button color="primary" type="submit" variant="contained">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>

          {/* <Formik initialValue={initialValue}>
            <form>
              <Grid container spacing={6} sx={{ marginTop: "10px" }}>
                <Grid item xs={12} md={3} lg={3} order={{ lg: 1, xs: 2 }}>
                  <FormControl mt={3}>
                    <DatePicker
                      id="date-range"
                      label="Date"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "mm/dd/yyyy",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={5} order={{ lg: 2, xs: 3 }}>
                  <FormControl>
                    <FormLabel>Comments</FormLabel>
                    <ToggleButtonGroup
                      color="primary"
                      sx={{ marginTop: "10px" }}
                      value={commentsBtn}
                      exclusive
                      onChange={handleCommentsBtn}
                    >
                      <ToggleButton value="undobtn">
                        <UndoIcon />
                      </ToggleButton>
                      <ToggleButton value="redobtn">
                        <RedoIcon />
                      </ToggleButton>
                      <ToggleButton value="boldbtn">
                        <FormatBoldIcon />
                      </ToggleButton>
                      <ToggleButton value="underlinebtn">
                        <FormatUnderlinedIcon />
                      </ToggleButton>
                      <ToggleButton value="printbtn">
                        <LocalPrintshopIcon />
                      </ToggleButton>
                      <ToggleButton value="clockbtn">
                        <RestoreIcon />
                      </ToggleButton>
                      <ToggleButton value="resizebtn">
                        <FullscreenExitIcon />
                      </ToggleButton>
                      <ToggleButton value="codebtn">
                        <CodeIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <TextareaAutosize placeholder="" />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} order={{ lg: 3, xs: 4 }}>
                  <FormControl mt={3}>
                    <TextField
                      fullWidth
                      type="file"
                      id="file"
                      label="Image"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={1}
                  textAlign="right"
                  order={{ lg: 4, xs: 1 }}
                >
                  <Tooltip title="Add" placement="top">
                    <IconButton onClick={addMoreControls} color="primary">
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={12} lg={12} order={{ lg: 5, xs: 5 }}>
                  {Array.from(Array(moreControls)).map((c, index) => {
                    return (
                      <Grid
                        container
                        spacing={6}
                        sx={{ marginTop: "0", md: { marginTop: "20px" } }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={3}
                          lg={3}
                          order={{ lg: 1, xs: 2 }}
                        >
                          <FormControl mt={3}>
                            <DatePicker
                              id="date-range"
                              label="Date"
                              value={date}
                              onChange={(newValue) => {
                                setDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "mm/dd/yyyy",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={5}
                          order={{ lg: 2, xs: 3 }}
                        >
                          <FormControl>
                            <FormLabel>Comments</FormLabel>
                            <ToggleButtonGroup
                              color="primary"
                              sx={{ marginTop: "10px" }}
                              value={commentsBtn}
                              exclusive
                              onChange={handleCommentsBtn}
                            >
                              <ToggleButton value="undobtn">
                                <UndoIcon />
                              </ToggleButton>
                              <ToggleButton value="redobtn">
                                <RedoIcon />
                              </ToggleButton>
                              <ToggleButton value="boldbtn">
                                <FormatBoldIcon />
                              </ToggleButton>
                              <ToggleButton value="underlinebtn">
                                <FormatUnderlinedIcon />
                              </ToggleButton>
                              <ToggleButton value="printbtn">
                                <LocalPrintshopIcon />
                              </ToggleButton>
                              <ToggleButton value="clockbtn">
                                <RestoreIcon />
                              </ToggleButton>
                              <ToggleButton value="resizebtn">
                                <FullscreenExitIcon />
                              </ToggleButton>
                              <ToggleButton value="codebtn">
                                <CodeIcon />
                              </ToggleButton>
                            </ToggleButtonGroup>
                            <TextareaAutosize placeholder="" />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={3}
                          lg={3}
                          order={{ lg: 3, xs: 4 }}
                        >
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              type="file"
                              id="file"
                              label="Image"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={1}
                          textAlign="right"
                          order={{ lg: 4, xs: 1 }}
                        >
                          <Tooltip title="Delete" placement="top">
                            <IconButton onClick={removeControls} color="error">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={6} lg={6} order={{ lg: 6, xs: 6 }}>
                  <FormControl>
                    <TextField
                      fullWidth
                      id="reviewed-by"
                      label="Reviewed by"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12} order={{ lg: 7, xs: 7 }}>
                  <Button color="primary" variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Formik> */}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={investigativeReportClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvestigativeReport;

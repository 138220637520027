import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { GetLockerLogHistory } from "../../../api";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TableContainer,
  Card,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const LockerLogDialog = ({ lockerLogModal, lockerLogModalClose, id }) => {
  const [lockerLogHistoryData, setLockerLogHistoryData] = useState([]);
  const getLockerLogHistory = () => {
    let payload = {
      locker_id: id,
    };
    GetLockerLogHistory(1, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLockerLogHistoryData(res.data);
        } else {
          setLockerLogHistoryData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getLockerLogHistory();
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="locker-log-title"
        aria-describedby="locker-log-desc"
        open={lockerLogModal}
        onClose={lockerLogModalClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="locker-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Locker Log
          </Typography>
          <DialogActions>
            <IconButton
              onClick={lockerLogModalClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Card variant="outlined" sx={{ borderBottom: 0, mt: "20px" }}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Activity</TableCell>
                    <TableCell>Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lockerLogHistoryData.map((data) => {
                    return (
                      <TableRow hover>
                        <TableCell>{data.date}</TableCell>
                        <TableCell>{data.activity}</TableCell>
                        <TableCell sx={{ minWidth: "220px" }}>
                          {data.comments}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {lockerLogHistoryData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No record found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={lockerLogModalClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LockerLogDialog;

import React, { useState } from "react";
import AddTask from "./AddTask";
import TaskColumns from "./Kanban";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Add as AddIcon,
} from "@mui/icons-material";

const TaskList = ({
  taskList,
  taskListClose,
  showAlertTost,
  module_id,
  moduleType,
  taskTitle,
  setLoader,
}) => {
  const [addTask, setAddTask] = useState(false);
  const [reloadTaskList, setReloadTaskList] = useState(false);

  const addTaskOpen = () => {
    setAddTask(true);
  };
  const addTaskClose = () => {
    setAddTask(false);
  };
  return (
    <>
      <Dialog
        aria-labelledby="tasklist-title"
        aria-describedby="tasklist-description"
        open={taskList}
        onClose={taskListClose}
        sx={{
          "& .MuiDialog-container": {
            paddingLeft: "15px",
            paddingRight: "15px",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1100px",
              overflowY: "unset",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="tasklist-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Task: {taskTitle}
          </Typography>
          <DialogActions>
            <IconButton onClick={taskListClose} sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <TaskColumns
            module_id={module_id}
            showAlertTost={(msg, type) => showAlertTost(msg, type)}
            setLoader={(status) => setLoader(status)}
            module={moduleType}
            reloadTaskList={reloadTaskList}
          />
        </DialogContent>
        <IconButton
          onClick={addTaskOpen}
          sx={{
            position: "absolute",
            left: "-15px",
            bottom: "-15px",
            background: "#2f65cb !important",
            color: "#ffffff",
          }}
        >
          <AddIcon />
        </IconButton>
      </Dialog>
      {addTask && (
        <AddTask
          addTask={addTask}
          setAddTask={setAddTask}
          addTaskOpen={addTaskOpen}
          addTaskClose={addTaskClose}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          setLoader={(status) => setLoader(status)}
          module_id={module_id}
          module={moduleType}
          setReloadTaskList={(status) => setReloadTaskList((prev) => !prev)}
        />
      )}
    </>
  );
};

export default TaskList;

import React from "react";
import LiveCastPicsModal from "./inner-components/LiveCastPicsModal";
import { Typography, Box, Grid, Card, IconButton, Avatar } from "@mui/material";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";

import { spacing } from "@mui/system";

const LiveCastPics = () => {
  const [castPicsModal, setCastPicsModal] = React.useState(false);
  const castPicsModalOpen = () => {
    setCastPicsModal(true);
  };
  const castPicsModalClose = () => {
    setCastPicsModal(false);
  };
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Cast Pics
      </Typography>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="h6" gutterBottom mt={2}>
          Dispatch
        </Typography>
        <IconButton>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
        <Typography variant="h6" gutterBottom mt={2}>
          Cast pics
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginTop: "10px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card
              onClick={castPicsModalOpen}
              sx={{
                display: "flex",
                padding: "5px",
              }}
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Typography variant="body2" gutterBottom mt={3} ml={2}>
                Earn trust invent and simplify
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {castPicsModal && (
        <LiveCastPicsModal
          castPicsModal={castPicsModal}
          setCastPicsModal={setCastPicsModal}
          castPicsModalOpen={castPicsModalOpen}
          castPicsModalClose={castPicsModalClose}
        />
      )}
    </>
  );
};

export default LiveCastPics;

import React, { useState } from "react";
import styled from "styled-components/macro";
import MUIRichTextEditor from "mui-rte";
import { makeStyles } from "@mui/styles";
import { AppendIrDeatails } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  DialogActions,
  Button,
  FormControl as MuiFormControl,
  Grid,
  TextField,
  Box,
  ToggleButton,
  FormLabel,
  ToggleButtonGroup,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  CircularProgress,
  Paper,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Restore as RestoreIcon,
  Fullscreen as FullscreenExitIcon,
  Redo as RedoIcon,
  FormatBold as FormatBoldIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  LocalPrintshop as LocalPrintshopIcon,
  Code as CodeIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import { DateTimePicker } from "@mui/lab";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);

// Classes Styling
const useStyles = makeStyles((theme) => ({
  textEditor: {
    position: "relative",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    borderRadius: "4px",
    "& #mui-rte-container": {
      marginTop: 0,
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      position: "absolute",
      padding: "15px",
      display: "block",
    },
    "& #mui-rte-editor": {
      height: "180px",
      overflowY: "auto",
    },
    "& #mui-rte-editor-container": {
      height: "170px",
      overflowY: "auto",
      borderTop: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    },
    "& #mui-rte-toolbar": {
      padding: "5px 15px",
    },
    "& .DraftEditor-editorContainer": {
      padding: "15px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const AddAppendedNarrative = ({
  addAppendedNarrative,
  addAppendedNarrativeClose,
  singleData,
}) => {
  const [narrativeDateValue, setNarrativeValue] = React.useState(new Date());
  const [narrativebtn, setNarrativeBtn] = React.useState("undobtn");
  const [editorText, setEditorText] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToAppendIrDetails = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "appendIrDetails" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  const handleNarrativeBtn = (event, newNarrativeBtn) => {
    setNarrativeBtn(newNarrativeBtn);
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const onEditorChange = (e) => {
    setEditorText(e.getCurrentContent().getPlainText());
  };
  // console.log(singleData.Datetime);
  const handleSave = () => {
    setLoading(true);
    let payload = {
      incident_id: singleData?.IncidentID,
      description: editorText,
      date: format(narrativeDateValue, "yyyy-MM-dd HH:mm:ss")
        ? format(narrativeDateValue, "yyyy-MM-dd HH:mm:ss")
        : null,
    };
    if (editorText != "") {
      AppendIrDeatails(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            addAppendedNarrativeClose();
            setLoading(false);
            dispatch(getSnackMsg("Saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setLoading(false);
          }
        })
        .catch((err) => {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
    } else {
      setErrMsg("Description should not be empty");
      setLoading(false);
    }
  };
  return (
    <>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      <Dialog
        aria-labelledby="appended-narrative-title"
        aria-describedby="appended-narrative-description"
        open={addAppendedNarrative}
        // onClose={addAppendedNarrativeClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="change-submit-status-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add Appended Narrative
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addAppendedNarrativeClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="p" component="div" gutterBottom>
              <strong>IR Classification:</strong> {singleData?.Classification}
            </Typography>
            <Typography variant="p" component="div" gutterBottom>
              <strong>Incident Location:</strong> {singleData?.IncidentLocation}
            </Typography>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <Typography variant="p" component="div" gutterBottom>
                  <strong>Incident Date:</strong>{" "}
                  {singleData
                    ? singleData.Datetime
                      ? singleData.Datetime.split(" ")[0]
                      : ""
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="p" component="div" gutterBottom>
                  <strong>Incident Time:</strong>
                  {singleData
                    ? singleData.Datetime
                      ? singleData.Datetime.split(" ")[1]
                      : ""
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <DateTimePicker
                  id="date-range"
                  label="Append datetime"
                  value={narrativeDateValue}
                  onChange={(newValue) => {
                    setNarrativeValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "mm/dd/yyyy H:i:s",
                      }}
                    />
                  )}
                />
              </Grid>
              <Box
                className={classes.textEditor}
                sx={{ margin: "15px 0 0 24px" }}
              >
                <MUIRichTextEditor
                  label={
                    <Typography sx={{ padding: "15px" }}>
                      Type something here...
                    </Typography>
                  }
                  // defaultValue={editorText}
                  onChange={onEditorChange}
                  inlineToolbar={true}
                />
              </Box>
              <FormHelperText error>{errMsg}</FormHelperText>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={addAppendedNarrativeClose}
          >
            Close
          </Button>
          <Button
            disabled={!hasPermissionToAppendIrDetails}
            color="primary"
            variant="contained"
            sx={{ marginLeft: "10px" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAppendedNarrative;

export default [
  {
    id: "date",
    label: "Date",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "other_type",
    label: "Other Type",
  },
  {
    id: "issued_type",
    label: "Issued By",
  },
  {
    id: "image",
    label: "Image",
  },
  {
    id: "action",
    label: "Action",
  },
];

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { EarTaskList } from "../api";
const EarDialog = ({
  earDialog,
  setEarDialog,
  earDialogOpen,
  earDialogClose,
}) => {
  const [earTask, setEarTask] = useState([]);
  const earTaskList = () => {
    EarTaskList()
      .then((res) => {
        if (res.statusCode) {
          setEarTask(res.data);
        } else {
          setEarTask([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    earTaskList();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        open={earDialog}
        onClose={earDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4"> EAR with unfinished task</Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setEarDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Employee</TableCell>
                <TableCell align="right">Position</TableCell>
                <TableCell align="right">Date Of Report</TableCell>
                <TableCell align="right">Not Started</TableCell>
                <TableCell align="right">Started</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {earTask.map((it) => {
                return (
                  <TableRow>
                    <TableCell>{it.name}</TableCell>
                    <TableCell align="right">{it.emp_position}</TableCell>
                    <TableCell align="right">{it.injury_datetime}</TableCell>
                    <TableCell
                      align="right"
                      sx={
                        it.hasDueNst
                          ? {
                              color: "white",
                              backgroundColor: "#ff8c8c",
                            }
                          : null
                      }
                    >
                      {it.notStarted}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={
                        it.hasDueSt
                          ? {
                              color: "white",
                              backgroundColor: "#ff8c8c",
                            }
                          : null
                      }
                    >
                      {it.started}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="outlined">Open</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button variant="outlined" color="primary" onClick={earDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EarDialog;

import React from "react";
import { makeStyles } from "@mui/styles";
import BuildIcon from "@mui/icons-material/Build";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CakeIcon from "@mui/icons-material/Cake";
import ShieldIcon from "@mui/icons-material/Shield";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Types = ({ data }) => {
  // Classes Styling
  const useStyles = makeStyles((theme) => ({
    statusButton: {
      width: "20px",
      height: "20px",
      display: "block",
      lineHeight: "20px",
      textAlign: "center",
      borderRadius: "6px",
      color: "#fff",
      fontSize: "12px",
      fontWeight: "bolder",
    },
  }));

  const classes = useStyles();

  const TypeStatus = (value) => {
    switch (value) {
      case 2:
        return (
          <React.Fragment>
            <BuildIcon color="warning" title="Contractor" />
          </React.Fragment>
        );
        break;
      case 3:
        return (
          <React.Fragment>
            <BuildIcon title="Candidate for employment" />
          </React.Fragment>
        );
        break;
      case 4:
        return (
          <React.Fragment>
            <ApartmentIcon title="Corporate Visitor" color="success" />
          </React.Fragment>
        );
        break;
      case 5:
        return (
          <React.Fragment>
            <PeopleIcon title="Employee from another Site" />
          </React.Fragment>
        );
        break;
      case 7:
        return (
          <React.Fragment>
            <PersonIcon title="Employee from another Site" color="primary" />
          </React.Fragment>
        );
        break;
      case 8:
        return (
          <React.Fragment>
            <WhatshotIcon title="Fire" color="warning" />
          </React.Fragment>
        );
        break;
      case 9:
        return (
          <React.Fragment>
            <MedicalServicesIcon title="Medical" color="error" />
          </React.Fragment>
        );
        break;
      case 10:
        return (
          <React.Fragment>
            <CakeIcon title="Event Setup" />
          </React.Fragment>
        );
        break;
      case 12:
        return (
          <React.Fragment>
            <PersonIcon title="Employee" color="" />
          </React.Fragment>
        );
        break;
      case 13:
        return (
          <React.Fragment>
            <ShieldIcon title="Third Party Security" color="primary" />
          </React.Fragment>
        );
        break;
      case 14:
        return (
          <React.Fragment>
            <TouchAppIcon title="Vendor" color="info" />
          </React.Fragment>
        );
        break;
      case 15:
        return (
          <React.Fragment>
            <NotificationsIcon title="Union Rep" />
          </React.Fragment>
        );
        break;

      default:
        return <React.Fragment>Other - {value}</React.Fragment>;
        break;
    }
  };

  return TypeStatus(data);
};

export default Types;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl as MuiFormControl,
  Box,
  FormControlLabel,
  Checkbox,
  Card,
  TextField,
  Grid,
  TextareaAutosize as MuiTextareaAutosize,
  formLabelClasses,
} from "@mui/material";
import {
  TaskActivityList,
  TaskUserList,
  AddNewTask,
  GetNonTourDataList,
} from "../../../api";
import { spacing } from "@mui/system";
import { Close as CloseIcon } from "@mui/icons-material";
import { Check } from "react-feather";

const IconPopup = ({
  iconPopup,
  setIconPopup,
  iconPopupOpen,
  iconPopupClose,
}) => {
  const TextareaAutosize = styled(MuiTextareaAutosize)`
    ${spacing};
    border: 1px solid
      ${(props) =>
        props.theme.palette.mode === "dark" ? "#c4c4c4" : "#c4c4c4"};
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 120px !important;
    background: transparent;
    padding: 20px 12px 15px;
    font-size: 13px;
    opacity: 0.8;
    font-weight: 200;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    letter-spacing: normal;
    width: 100%;
  `;
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const [loading, setLoading] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedOfficer, setSelectedOfficer] = useState("");
  const [location, setLocation] = useState("");
  const [informant, setInformant] = useState("");
  const [comment, setComment] = useState("");
  useEffect(() => {
    if (iconPopup) {
      TaskActivityList()
        .then((response) => {
          console.log("TaskActivityList response:", response.data); // Debug log
          setActivityList(response.data || []); // Ensure it's an array
        })
        .catch((error) => {
          console.error("Error fetching TaskActivityList:", error);
          setActivityList([]); // Set as empty array if there's an error
        });

      TaskUserList()
        .then((response) => {
          console.log("TaskUserList response:", response.data); // Debug log
          setUserList(response.data || []); // Ensure it's an array
        })
        .catch((error) => {
          console.error("Error fetching TaskUserList:", error);
          setUserList([]); // Set as empty array if there's an error
        });
    }
  }, [iconPopup]);
  const [activityList, setActivityList] = useState([]); // Initialize as empty array
  const [userList, setUserList] = useState([]);
  const handleActivityChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  const handleUserChange = (event) => {
    setSelectedOfficer(event.target.value);
  };
  const handleSave = () => {
    if (
      !selectedActivity ||
      !selectedOfficer ||
      !location ||
      !informant ||
      !comment
    ) {
      alert("All fields are required.");
      return;
    }

    const payload = {
      Nontourdata: {
        TaskID: selectedActivity,
        UserID: selectedOfficer,
        location,
        informant,
        Comment: comment,
        start_time: "1",
        ScanTime: "",
      },
    };

    AddNewTask(payload)
      .then((response) => {
        console.log("Task added successfully:", response.data);
        GetNonTourDataList();
        iconPopupClose();
      })
      .catch((error) => {
        console.error("Error adding new task:", error);
      });
  };

  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
        open={iconPopup}
        onClose={iconPopupClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px !important",
          }}
        >
          <Typography variant="h5">Add Activity</Typography>
          <IconButton onClick={iconPopupClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={6} mt={2}>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Activity</InputLabel>
                <Select
                  labelId="activity-select-label"
                  value={selectedActivity}
                  onChange={handleActivityChange}
                >
                  {Array.isArray(activityList) && activityList.length > 0 ? (
                    activityList.map((activity) => (
                      <MenuItem key={activity.id} value={activity.id}>
                        {activity.text} {/* Displaying only the text */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Loading</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Officer</InputLabel>
                <Select
                  labelId="officer-select-label"
                  value={selectedOfficer}
                  onChange={handleUserChange}
                >
                  {Array.isArray(userList) && userList.length > 0 ? (
                    userList.map((officer) => (
                      <MenuItem key={officer.id} value={officer.id}>
                        {officer.text} {/* Displaying only the text */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Loading</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                label="Location"
                fullWidth
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                label="Informant"
                fullWidth
                value={informant}
                onChange={(e) => setInformant(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextareaAutosize
                placeholder="Comment about the task"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "20px 24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "2px",
              marginRight: "auto",
            }}
          >
            <Typography variant="body">Start task upon saving</Typography>
            <Checkbox />
          </Box>
          <Button variant="outlined" color="primary" onClick={iconPopupClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IconPopup;

import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  FormControl as MuiFormControl,
  TextField,
  InputLabel as MuiInputLabel,
  Grid,
  DialogActions,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import { AedBulkEditDate } from "../../../api";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import CustomTextField from "../../../components/form-components/CustomTextField";
import moment from "moment";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/mainSlice";
import { handleApiResonseErrors } from "../../../helper/helper";
import { useDispatch, useSelector } from "react-redux";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const UpdatePadsBatteryExpired = ({
  updatePadsBatteryExpiredDialog,
  updatePadsBatteryExpiredDialogClose,
  selected,
  setPageLoad,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    pad_expiry: null,
    battery_expiry: null,
  };

  const validationSchema = Yup.object().shape({
    pad_expiry: Yup.string().nullable().required("Pad expiry date is required"),
    battery_expiry: Yup.string()
      .nullable()
      .required("Battery expiry date is required"),
  });
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setPageLoad(true);
    let formatPadExpDate = values.pad_expiry
      ? moment(values.pad_expiry).format("YYYY-MM-DD")
      : "";
    let formatBatteryExpDate = values.battery_expiry
      ? moment(values.battery_expiry).format("YYYY-MM-DD")
      : "";
    let payload = {
      id: selected.toString(),
      pad_expiry: formatPadExpDate,
      battery_expiry: formatBatteryExpDate,
    };
    AedBulkEditDate(payload)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          dispatch(getMsg("Email sent successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          updatePadsBatteryExpiredDialogClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          // darInquiryClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };
  return (
    <>
      <Dialog
        aria-labelledby="reissueid-title"
        aria-describedby="reissueid-desc"
        open={updatePadsBatteryExpiredDialog}
        onClose={updatePadsBatteryExpiredDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="reissueid-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Update Pads And Battery Expiry Date
          </Typography>
          <DialogActions>
            <IconButton
              onClick={updatePadsBatteryExpiredDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DatePicker
                        // name="pad_expiry"
                        label="Pad Expiry Date"
                        value={values.pad_expiry}
                        onChange={(newValue) => {
                          setFieldValue("pad_expiry", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="pad_expiry"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              // placeholder: "09/13/2022",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <DatePicker
                        // name="pad_expiry"
                        label="Battery Expiry Date"
                        value={values.battery_expiry}
                        onChange={(newValue) => {
                          setFieldValue("battery_expiry", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="battery_expiry"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              // placeholder: "09/13/2022",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions sx={{ padding: "20px 24px" }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    // onClick={darInquiryClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" variant="contained">
                    Send
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdatePadsBatteryExpired;

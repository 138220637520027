import React, { useState } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelection from "../../../components/form-components/CustomSelection";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  IconButton,
  FormControl as MuiFormControl,
  InputAdornment,
  Grid,
  Box,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { display, spacing } from "@mui/system";
import * as Yup from "yup";
import { SaveFinancialInfo, DeleteInvolvedFinanceImage } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const ImageWrapper = styled.div``;
const Image = styled.img`
  width: 150px;
  height: 100px;
`;
const AddEditFinancialReport = ({
  financialReportDialog,
  financialReportDialogClose,
  incidentId,
  getInvolvedFinanceListing,
  setPageLoad,
  editFIData,
  getFinanceInvolved,
  financeActionstatus,
  financeViewActionstatus,
}) => {
  const dispatch = useDispatch();
  const [uploadMedia, setUploadMedia] = useState(null);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToDeleteFinancialLossImage = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "deleteFinancialLossImage" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  console.log(financeViewActionstatus);
  //Initial values Formik
  const initialValues = {
    category_of_loss: editFIData
      ? editFIData.category_of_loss
        ? editFIData.category_of_loss
        : ""
      : "",
    description: editFIData
      ? editFIData.description
        ? editFIData.description
        : ""
      : "",
    cost: editFIData ? (editFIData.cost ? editFIData.cost : "") : "",
    image: editFIData ? (editFIData.image ? editFIData.image : "") : "",
  };
  const validationSchema = Yup.object().shape({
    category_of_loss: Yup.string().required("Please select category"),
    description: Yup.string().required("Please enter details"),
    cost: Yup.string().required("Please enter cost"),
  });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log({ values });
      setPageLoad(true);
      let formData = {
        id: editFIData ? (editFIData.id ? editFIData.id : null) : null,
        incident_id: incidentId,
        category_of_loss: values.category_of_loss,
        description: values.description,
        cost: values.cost,
        finance_file: uploadMedia,
      };
      SaveFinancialInfo(formData)
        .then((res) => {
          console.log("++", res);
          setPageLoad(false);
          if (res.statusCode === 200) {
            getInvolvedFinanceListing();
            dispatch(
              getSnackMsg(
                editFIData
                  ? editFIData.id
                    ? "Record has been updated successfully"
                    : "Record has been added successfully"
                  : "Record has been added successfully"
              )
            );
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            financialReportDialogClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          console.log(err);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
      // await timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      setPageLoad(false);
      console.log(error);
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };
  const deleteFinanceInvolvedImage = () => {
    setPageLoad(true);
    DeleteInvolvedFinanceImage(editFIData.image_id)
      .then((res) => {
        if (res.statusCode == 200) {
          setPageLoad(false);
          getFinanceInvolved();
          dispatch(getSnackMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getInvolvedFinanceListing();
          // handleDeleteClose();
        } else {
          setPageLoad(false);
          // handleDeleteClose();
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };
  const category = [
    { key: "Guest Property Loss", value: "Guest Property Loss" },
    { key: "Guest Property Damage", value: "Guest Property Damage" },
    { key: "Business Property Loss", value: "Business Property Loss" },
    { key: "Business Property Damage", value: "Business Property Damage" },
    { key: "Revenue Loss", value: "Revenue Loss" },
    { key: "Loyalty Points", value: "Loyalty Points" },
    { key: "Property Recovery", value: "Property Recovery" },
  ];

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      //   onLoad(reader.result);
      setUploadMedia(reader.result);
    };
  };

  const uploadMediaFiles = (e) => {
    if (e.target.files) {
      console.log(e.target.files);
      const files = e.target.files;
      const file = files[0];
      getBase64(file);
      // setUploadMedia(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "650px",
            },
          },
        }}
        aria-labelledby="financial-report-title"
        aria-describedby="financial-report-description"
        open={financialReportDialog}
        onClose={financialReportDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="financial-report-title"
        >
          <Typography variant="h4">
            Add/Edit Financial Loss Or Recovery
          </Typography>
          <DialogActions>
            <IconButton
              onClick={financialReportDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomSelection
                        name="category_of_loss"
                        label="Category *"
                        fullWidth
                        options={category}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={financeActionstatus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomTextField
                        label="Supportive Documents"
                        fullWidth
                        type="file"
                        name="finance_file"
                        id="supportive-docs"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={uploadMediaFiles}
                        disabled={financeActionstatus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        label="Description*"
                        fullWidth
                        name="description"
                        id="description"
                        multiline={true}
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={financeActionstatus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        label="Cost*"
                        fullWidth
                        name="cost"
                        id="cost"
                        type="number"
                        min="0"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={financeActionstatus}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              USD
                            </InputAdornment>
                          ),
                          inputProps: { min: 0 },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {editFIData.image && (
                    <Grid item xs={12} md={4}>
                      <ImageWrapper>
                        <Paper
                          sx={{
                            "& .MuiBox-root:hover .MuiIconButton-root": {
                              display: "block",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                            }}
                          >
                            <IconButton
                              onClick={deleteFinanceInvolvedImage}
                              color="primary"
                              disabled={
                                financeActionstatus ||
                                !hasPermissionToDeleteFinancialLossImage
                              }
                              sx={{
                                position: "absolute",
                                right: -2,
                                top: -1,
                                zIndex: 1,
                                display: "none",
                              }}
                            >
                              <CancelRoundedIcon />
                            </IconButton>
                            <Image
                              sx={{ width: "150px" }}
                              component="img"
                              alt="Guage Chart"
                              src={
                                editFIData
                                  ? editFIData.image
                                    ? editFIData.image
                                    : ""
                                  : ""
                              }
                            />
                          </Box>
                        </Paper>
                      </ImageWrapper>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={financialReportDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={financeActionstatus}
                      sx={{ marginLeft: "10px" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEditFinancialReport;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Box,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  RadioGroup,
  Radio,
  FormHelperText,
  Paper,
  CircularProgress,
  CardActions,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DateTimePicker } from "@mui/lab";
import { handleApiResonseErrors } from "../helper/helper";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import CustomTextField from "../components/form-components/CustomTextField";
import {
  DepartmentList,
  PositionByDepartment,
  AddEARReport,
  ViewEmployeeAccidentReport,
  EditEmpAccident,
} from "../api";
import CustomSelect from "../components/form-components/CustomSelect";
import { DatePicker } from "@mui/lab";
import moment from "moment";
import CanvasDraw from "react-canvas-draw";
import SignatureCanvas from "react-signature-canvas";
import CustomProgressBar from "../components/form-components/CustomProgressBar";
// import EditEarForm from "./inner-components-EAR/EditEarForm";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);

const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
    marginTop: "5px",
  })
);

const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

function EditEarForm(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [editData, setEditData] = useState({});
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [loader, setLoader] = useState(false); //For page loader
  const canvasRef = useRef(null);
  const [updateImgStatus, setUpdateImgStatus] = useState(false);
  const [buttonChange, setButtonChange] = useState(false);
  const [progressCount, setProgressCount] = useState(0);

  //Signature padsetUpdateImgStatus
  //Ref for signature pad
  const sigCanvas = useRef({});
  const [signImage, setSignImage] = useState(null);
  const clearPad = () => {
    sigCanvas.current.clear();
    setSignImage(null);
  };
  const savePad = () => {
    setSignImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };
  //Body canvas draw section
  const [canvasImg, setCanvasImg] = useState(null);
  const saveDamagedImage = () => {
    const width = canvasRef.current.props.canvasWidth;
    const height = canvasRef.current.props.canvasHeight;
    const background = canvasRef.current.canvasContainer.children[0];
    const drawing = canvasRef.current.canvasContainer.children[1];

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    // composite now
    canvas.getContext("2d").drawImage(background, 0, 0);
    canvas.getContext("2d").globalAlpha = 1.0;
    canvas.getContext("2d").drawImage(drawing, 0, 0);

    const dataUri = canvas.toDataURL("image/jpeg", 1.0);
    const data = dataUri.split(",")[1];
    const mimeType = dataUri.split(";")[0].slice(5);

    const bytes = window.atob(data);
    const buf = new ArrayBuffer(bytes.length);
    const arr = new Uint8Array(buf);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: mimeType });
    console.log(dataUri);
    setCanvasImg(dataUri);
    dispatch(getMsg("Image saved"));
    dispatch(getSnackAlert("success"));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
    // return { blob: blob, dataUri: dataUri };
  };

  const initialValues = {
    report_type: editData
      ? editData.report_type
        ? editData.report_type
        : ""
      : "",
    name: editData ? (editData.name ? editData.name : "") : "",
    supervisor_dept: editData
      ? editData.supervisor_dept
        ? editData.supervisor_dept
        : ""
      : "",
    manager: editData ? (editData.manager ? editData.manager : "") : "",
    witness_name: editData
      ? editData.witness_name
        ? editData.witness_name
        : ""
      : "",
    supervisor_informed: editData
      ? editData.supervisor_informed
        ? editData.supervisor_informed
        : ""
      : "",
    medicare_status: editData
      ? editData.medicare_status
        ? editData.medicare_status
        : ""
      : "",
    injury_datetime: editData
      ? editData.injury_datetime
        ? editData.injury_datetime
        : null
      : null,
    emp_position: editData
      ? editData.emp_position
        ? editData.emp_position
        : ""
      : "",
    supervisor_title: editData
      ? editData.supervisor_title
        ? editData.supervisor_title
        : ""
      : "",
    incident_location: editData
      ? editData.incident_location
        ? editData.incident_location
        : ""
      : "",
    emp_desc_notes: editData
      ? editData.emp_desc_notes
        ? editData.emp_desc_notes
        : ""
      : "",
    full_desc_injury: editData
      ? editData.full_desc_injury
        ? editData.full_desc_injury
        : ""
      : "",
    crew_shoes_status: editData
      ? editData.crew_shoes_status
        ? editData.crew_shoes_status
        : ""
      : "",
    emp_desc_prevent: editData
      ? editData.emp_desc_prevent
        ? editData.emp_desc_prevent
        : ""
      : "",
    doctor_called: editData
      ? editData.doctor_called
        ? editData.doctor_called
        : ""
      : "",
    injured_before: editData
      ? editData.injured_before
        ? editData.injured_before
        : ""
      : "",
    date_signed: editData
      ? editData.date_signed
        ? editData.date_signed
        : null
      : null,
    translating_called: editData
      ? editData.translating_called
        ? editData.translating_called
        : ""
      : "",
    b_image: editData ? (editData.b_image ? editData.b_image : null) : null,
    signature_image: editData
      ? editData.signature_image
        ? editData.signature_image
        : null
      : null,
  };

  const validationSchema = Yup.object().shape({
    report_type: Yup.string()
      .required("Please select one of these options")
      .oneOf(["injury", "illness", "near_miss"]),
    name: Yup.string().required("Please enter Employee full name"),
    injury_datetime: Yup.string()
      .nullable()
      .required("Please select date/time of injury"),
    manager: Yup.string().required("Please enter supervisor name"),
  });

  const ProgressCounter = (val) => {
    console.log(val);
    let emptyField = {};
    let nonEmptyField = {};
    // const keys = Object.keys(val);
    for (const key in val) {
      if (val[key] === null || val[key] === "") {
        emptyField = {
          ...emptyField,
          [key]: val[key],
        };
      } else {
        nonEmptyField = {
          ...nonEmptyField,
          [key]: val[key],
        };
      }
    }
    let empFieldLength = Object.keys(emptyField).length;
    let nonEmpFieldLength = Object.keys(nonEmptyField).length;
    console.log(emptyField);
    console.log(nonEmptyField);
    // console.log(empFieldLength);
    // console.log(nonEmpFieldLength);

    function percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    }
    let perVal = percentage(nonEmpFieldLength, 21);
    console.log(perVal);
    console.log("nonEmpFieldLength", nonEmpFieldLength);
    setProgressCount(perVal);
  };

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setLoader(true);
      let formatedInjuryDate = values.injury_datetime
        ? moment(values.injury_datetime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedSignDate = values.date_signed
        ? moment(values.date_signed).format("YYYY-MM-DD")
        : "";
      let formData = {
        id: id,
        report_type: values.report_type,
        name: values.name,
        supervisor_dept: values.supervisor_dept,
        manager: values.manager,
        witness_name: values.witness_name,
        supervisor_informed: values.supervisor_informed,
        medicare_status: values.medicare_status,
        injury_datetime: formatedInjuryDate,
        emp_position: values.emp_position,
        supervisor_title: values.supervisor_title,
        incident_location: values.incident_location,
        emp_desc_notes: values.emp_desc_notes,
        full_desc_injury: values.full_desc_injury,
        crew_shoes_status: values.crew_shoes_status,
        emp_desc_prevent: values.emp_desc_prevent,
        doctor_called: values.doctor_called,
        injured_before: values.injured_before,
        date_signed: formatedSignDate,
        translating_called: values.translating_called,
        b_image: updateImgStatus ? values.b_image : null, //canvasImg,
        signature_image: null, //values.signature_image, //signImage,
      };
      EditEmpAccident(formData)
        .then((res) => {
          setLoader(false);
          if (res.statusCode === 200) {
            navigate({ pathname: "/employee-accidents" });
            dispatch(getMsg("EAR report successfully saved"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          // setVehicleLoader(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setLoader(false);
        });
    } catch (err) {
      // setVehicleLoader(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      // setPageLoad(false);
    }
  };

  //For Dropdown
  const getDepartmentList = () => {
    setLoader(true);
    DepartmentList({})
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const getPositionList = (value) => {
    setLoader(true);
    PositionByDepartment(value)
      .then((res) => {
        setLoader(false);
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //API call for view employee accident
  const getAccidentViewData = () => {
    setLoader(true);
    ViewEmployeeAccidentReport(id)
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setEditData(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  useEffect(() => {
    getDepartmentList();
    getAccidentViewData();
  }, []);

  useEffect(() => {
    if (editData && editData.supervisor_dept) {
      getPositionList(editData.supervisor_dept);
    }
    ProgressCounter(initialValues);
    console.log(initialValues);
  }, [editData]);
  const [showFields, setShowFields] = useState(false);

  const handleRadioChange = (event) => {
    setShowFields(event.target.value === "Y");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className={classes.accordion}>
                <Typography variant="h4" component="div" mb={4} gutterBottom>
                  Employee
                </Typography>
                <CustomProgressBar value={progressCount} />
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step1-content"
                    id="step1-header"
                  >
                    <Typography>
                      Step 1: Details of Employee Involved
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <FormLabel component="legend">
                            I am reporting a work related *
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby="workreport"
                              name="report_type"
                              value={values.report_type.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "report_type",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Injury"
                                value="injury"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Illness"
                                value="illness"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Near miss"
                                value="near_miss"
                              />
                            </RadioGroup>
                            <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                              {touched.report_type && errors.report_type}
                            </FormHelperText>
                          </StyledRadio>
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            fullWidth
                            name="name"
                            id="emp-name"
                            label="Employee full name *"
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="First name last name"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomSelect
                            name="supervisor_dept"
                            fieldLabel="Department"
                            options={departmentList}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            displayEmpty
                            customCallbackMethod={(evt) => {
                              if (evt.target.value) {
                                getPositionList(evt.target.value);
                              }
                            }}
                          />
                          <FormHelperText error>
                            {touched.supervisor_dept && errors.supervisor_dept}
                          </FormHelperText>
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="manager"
                            id="supervisor-name"
                            label="Name of supervisor at time of injury *"
                            placeholder="Direct manager on day of accident"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="witness_name"
                            id="witness-name"
                            label="Name of Witnesses (seperate by commas):"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="John Smith, Marry Johnson, Alex Jones"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel component="legend">
                            Have you notified your supervisor about this
                            injury/near miss?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby="notify-supervisor"
                              name="supervisor_informed"
                              value={values.supervisor_informed.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "supervisor_informed",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel component="legend">
                            Currently on medicare?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby="medicarestatus"
                              name="medicare_status"
                              value={values.medicare_status.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "medicare_status",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl mt={3}>
                          <DateTimePicker
                            id="date-range"
                            label="* Date & time of injury : "
                            value={values.injury_datetime}
                            onChange={(newValue) => {
                              setFieldValue("injury_datetime", newValue);
                            }}
                            inputFormat="MM/dd/yyyy HH:mm"
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="injury_datetime"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm/dd/yyyy H:i:s",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomSelect
                            name="emp_position"
                            fieldLabel="Employee position "
                            options={positionList}
                            displayEmpty
                            firstOptionTitle="Select"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomSelect
                            name="supervisor_title"
                            fieldLabel="Supervisor position "
                            options={positionList}
                            displayEmpty
                            firstOptionTitle="Select"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step2-content"
                    id="step2-header"
                  >
                    <Typography>Step 2: Details of the Incident</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <CustomTextField
                            name="incident_location"
                            id="incident-location"
                            label="Where exactly did  the injury/near miss out?"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ maxLength: 100 }}
                            placeholder="Be specific: name the department, floor, room or hallway"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="emp_desc_notes"
                            label="If you were injurded what were you doing at the moment?"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Describe the activity you were doing"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="full_desc_injury"
                            label="Describe step by step how the injury/near miss
                                occured:"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder="Describe surroundings and your activities leading up too the accident"
                            multiline={true}
                            rows={6}
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel component="legend">
                            Where you were wearing Shoes or Crews? (or similar)
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby=""
                              name="crew_shoes_status"
                              value={values.crew_shoes_status.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "crew_shoes_status",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="emp_desc_prevent"
                            label="What could have been done to prevent this
                                injury/near miss?"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder="Better training, improve work training, better equipment etc."
                            multiline={true}
                            rows={6}
                          />
                        </FormControl>
                      </Grid>
                      {editData.b_image != null ? (
                        <Grid item xs={12} md={6}>
                          {updateImgStatus === false ? (
                            <Image
                              sx={{
                                width: "100%",
                              }}
                              src={editData.b_image}
                            />
                          ) : (
                            <>
                              <Button
                                color="primary"
                                variant="contained"
                                mb={3}
                                onClick={() => {
                                  const width =
                                    canvasRef.current.props.canvasWidth;
                                  const height =
                                    canvasRef.current.props.canvasHeight;
                                  const background =
                                    canvasRef.current.canvasContainer
                                      .children[0];
                                  const drawing =
                                    canvasRef.current.canvasContainer
                                      .children[1];

                                  const canvas =
                                    document.createElement("canvas");
                                  canvas.width = width;
                                  canvas.height = height;

                                  // composite now
                                  canvas
                                    .getContext("2d")
                                    .drawImage(background, 0, 0);
                                  canvas.getContext("2d").globalAlpha = 1.0;
                                  canvas
                                    .getContext("2d")
                                    .drawImage(drawing, 0, 0);

                                  const dataUri = canvas.toDataURL(
                                    "image/jpeg",
                                    1.0
                                  );
                                  const data = dataUri.split(",")[1];
                                  const mimeType = dataUri
                                    .split(";")[0]
                                    .slice(5);

                                  const bytes = window.atob(data);
                                  const buf = new ArrayBuffer(bytes.length);
                                  const arr = new Uint8Array(buf);

                                  for (let i = 0; i < bytes.length; i++) {
                                    arr[i] = bytes.charCodeAt(i);
                                  }

                                  const blob = new Blob([arr], {
                                    type: mimeType,
                                  });
                                  console.log(dataUri);
                                  values.b_image = dataUri;
                                  setFieldValue("b_image", dataUri);
                                  // setCanvasImg(dataUri);
                                  dispatch(getMsg("Image saved"));
                                  dispatch(getSnackAlert("success"));
                                  dispatch(getSnackOpen());
                                  setTimeout(() => {
                                    dispatch(getSnackClose());
                                  }, 3000);
                                  ProgressCounter(values);
                                }}
                                // onClick={saveDamagedImage}
                              >
                                Save
                              </Button>
                              <Button
                                color="primary"
                                variant="contained"
                                mb={3}
                                sx={{ marginLeft: 5, marginRight: 5 }}
                                onClick={() => {
                                  canvasRef.current.undo();
                                  setFieldValue("b_image", null);
                                  ProgressCounter(values);
                                  // dispatch(getMsg("Undo changes"));
                                  // dispatch(getSnackAlert("success"));
                                  // dispatch(getSnackOpen());
                                  // setTimeout(() => {
                                  //   dispatch(getSnackClose());
                                  // }, 3000);
                                }}
                              >
                                Undo
                              </Button>
                              <Button
                                color="primary"
                                variant="contained"
                                mb={3}
                                onClick={() => {
                                  canvasRef.current.clear();
                                  dispatch(getMsg("Image cleared"));
                                  dispatch(getSnackAlert("success"));
                                  dispatch(getSnackOpen());
                                  setTimeout(() => {
                                    dispatch(getSnackClose());
                                  }, 3000);
                                  setFieldValue("b_image", null);
                                  ProgressCounter(values);
                                }}
                              >
                                Clear
                              </Button>
                              <CanvasDraw
                                imgSrc="/static/img/unsplash/body-part.jpg"
                                // imgSrc="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
                                brushColor="rgba(255, 0, 0)"
                                brushRadius={1}
                                canvasWidth={450}
                                canvasHeight={500}
                                hideGrid={true}
                                // disabled={vehicleActionStatus}
                                // getSaveData={()=>console.log()}
                                ref={canvasRef}
                              />
                            </>
                          )}
                          {buttonChange == false ? (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                setUpdateImgStatus(true);
                                setButtonChange(true);
                              }}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="outlined"
                              sx={{ marginTop: "5px" }}
                              onClick={() => {
                                setUpdateImgStatus(false);
                                setButtonChange(false);
                                setFieldValue("b_image", null);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={6}>
                          <Button
                            color="primary"
                            variant="contained"
                            mb={3}
                            onClick={() => {
                              const width = canvasRef.current.props.canvasWidth;
                              const height =
                                canvasRef.current.props.canvasHeight;
                              const background =
                                canvasRef.current.canvasContainer.children[0];
                              const drawing =
                                canvasRef.current.canvasContainer.children[1];

                              const canvas = document.createElement("canvas");
                              canvas.width = width;
                              canvas.height = height;

                              // composite now
                              canvas
                                .getContext("2d")
                                .drawImage(background, 0, 0);
                              canvas.getContext("2d").globalAlpha = 1.0;
                              canvas.getContext("2d").drawImage(drawing, 0, 0);

                              const dataUri = canvas.toDataURL(
                                "image/jpeg",
                                1.0
                              );
                              const data = dataUri.split(",")[1];
                              const mimeType = dataUri.split(";")[0].slice(5);

                              const bytes = window.atob(data);
                              const buf = new ArrayBuffer(bytes.length);
                              const arr = new Uint8Array(buf);

                              for (let i = 0; i < bytes.length; i++) {
                                arr[i] = bytes.charCodeAt(i);
                              }

                              const blob = new Blob([arr], { type: mimeType });
                              console.log(dataUri);
                              setFieldValue("b_image", dataUri);
                              // setCanvasImg(dataUri);
                              dispatch(getMsg("Image saved"));
                              dispatch(getSnackAlert("success"));
                              dispatch(getSnackOpen());
                              setTimeout(() => {
                                dispatch(getSnackClose());
                              }, 3000);
                              ProgressCounter(values);
                            }}
                            // onClick={saveDamagedImage}
                          >
                            Save
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            mb={3}
                            sx={{ marginLeft: 5, marginRight: 5 }}
                            onClick={() => {
                              canvasRef.current.undo();
                              setFieldValue("b_image", null);
                            }}
                          >
                            Undo
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            mb={3}
                            onClick={() => {
                              canvasRef.current.clear();
                              setFieldValue("b_image", null);
                              dispatch(getMsg("Image cleared"));
                              dispatch(getSnackAlert("success"));
                              dispatch(getSnackOpen());
                              setTimeout(() => {
                                dispatch(getSnackClose());
                              }, 3000);
                            }}
                          >
                            Clear
                          </Button>
                          {/* <Image src={`/static/img/unsplash/body-part.jpg`} /> */}
                          <CanvasDraw
                            imgSrc="/static/img/unsplash/body-part.jpg"
                            // imgSrc="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
                            brushColor="rgba(255, 0, 0)"
                            brushRadius={1}
                            canvasWidth={450}
                            canvasHeight={500}
                            hideGrid={true}
                            // disabled={vehicleActionStatus}
                            // getSaveData={()=>console.log()}
                            ref={canvasRef}
                          />
                        </Grid>
                      )}
                      {/* {editData.b_image && updateImgStatus === false ? (
                        <Grid item xs={12} md={6}>
                          <Image
                            sx={{
                              width: "100%",
                            }}
                            src={editData.b_image}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={6}>
                          <Button
                            color="primary"
                            variant="contained"
                            mb={3}
                            onClick={saveDamagedImage}
                          >
                            Save
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            mb={3}
                            sx={{ marginLeft: 5, marginRight: 5 }}
                            onClick={() => {
                              canvasRef.current.undo();
                            }}
                          >
                            Undo
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            mb={3}
                            onClick={() => {
                              canvasRef.current.clear();
                            }}
                          >
                            Clear
                          </Button>
                          <CanvasDraw
                            imgSrc="/static/img/unsplash/body-part.jpg"
                            // imgSrc="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
                            brushColor="rgba(255, 0, 0)"
                            brushRadius={1}
                            canvasWidth={450}
                            canvasHeight={500}
                            hideGrid={true}
                            // disabled={vehicleActionStatus}
                            // getSaveData={()=>console.log()}
                            ref={canvasRef}
                          />
                        </Grid>
                      )} */}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step3-content"
                    id="step3-header"
                  >
                    <Typography>Step 3: Treatment for Injury</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={6} md={6}>
                        <FormControl>
                          <FormLabel component="legend">
                            Did you see a doctor about this Injury/Illness?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby=""
                              name="doctor_called"
                              value={values.doctor_called.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "doctor_called",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                onChange={handleRadioChange}
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                        {showFields && (
                          <FormControl
                            sx={{
                              marginTop: "30px",
                            }}
                          >
                            <FormLabel>Doctor's phone number:</FormLabel>

                            <Select
                              fullWidth
                              sx={{
                                height: "40px",
                                marginTop: "5px",
                              }}
                            >
                              <MenuItem value="UnitedStates">
                                United States
                              </MenuItem>
                              <MenuItem value="India">India</MenuItem>
                            </Select>
                            <CustomTextField
                              sx={{
                                marginTop: "15px",
                              }}
                              id="number"
                              name="phone-number"
                              type="number"
                            />
                          </FormControl>
                        )}

                        <FormControl mt={6}>
                          <FormLabel component="legend">
                            Has this part of your body been injured before?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby=""
                              name="injured_before"
                              value={values.injured_before.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "injured_before",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        {showFields && (
                          <FormControl>
                            <FormLabel>If yes, whom did you see?</FormLabel>
                            <CustomTextField
                              sx={{
                                marginTop: "5px",
                              }}
                              id="text"
                              name="dr-name"
                              type="text"
                              placeholder="Provide doctor name"
                            />
                            <FormLabel
                              sx={{
                                marginTop: "15px",
                              }}
                            >
                              Date and time of your doctor visit:
                            </FormLabel>

                            <DateTimePicker
                              id="start_datetime"
                              value={new Date()}
                              inputFormat="MM/dd/yyyy HH:mm"
                              renderInput={(params) => (
                                <CustomTextField
                                  sx={{
                                    marginTop: "5px",
                                  }}
                                  {...params}
                                  name="start_datetime"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step4-content"
                    id="step4-header"
                  >
                    <Typography>Step 4: Signature</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl mt={3}>
                          <DatePicker
                            id="date-signed"
                            label="Date signed"
                            value={values.date_signed}
                            onChange={(newValue) => {
                              setFieldValue("date_signed", newValue);
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="date_signed"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <>
                            {editData.signature_image !== null ? (
                              <Card
                                variant="outlined"
                                className="signatureCanvas"
                              >
                                <Image
                                  sx={{ height: "150px" }}
                                  src={editData.signature_image}
                                />
                              </Card>
                            ) : (
                              <>
                                <FormLabel component="legend">
                                  Sign here
                                </FormLabel>
                                <Card
                                  variant="outlined"
                                  className="signatureCanvas"
                                >
                                  <SignatureCanvas ref={sigCanvas} />
                                </Card>
                                <Box mt={3}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={savePad}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={clearPad}
                                    ml={3}
                                  >
                                    Clear
                                  </Button>
                                </Box>
                              </>
                            )}
                          </>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl mt={3}>
                          <FormLabel component="legend">
                            Did you have help translating this form?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby=""
                              name="translating_called"
                              value={values.translating_called.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "translating_called",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  mt={3}
                >
                  Save
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        {loader && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </CardContent>
    </Card>
  );
}

function EditEmployeeAccident() {
  return (
    <React.Fragment>
      <Helmet title="Edit EAR Report" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit EAR Reports
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/employee-accidents">
          EAR Reports
        </Link>
        <Typography>Edit EAR Reports</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <EditEarForm />
    </React.Fragment>
  );
}

export default EditEmployeeAccident;

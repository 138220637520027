import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import axios from "axios";
import Toaster from "../tasks/Toaster";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import format from "date-fns/format";
import { Formik } from "formik";
import * as Yup from "yup";
import { UpdateTourSchedule } from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import { TimePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  Cached as CachedIcon,
  CheckBox as CheckBoxIcon,
  LocationOn as LocationOnIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  GpsFixed as GpsFixedIcon,
  CalendarToday as CalendarTodayIcon,
  FileCopy as FileCopyIcon,
  Public as PublicIcon,
  Schedule,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import TourRow from "./TourRow";
import CopyTour from "./CopyTour";
import { GetTourShiftTime, UpdateTourShiftTime } from "../../api";
import ShiftTimeDialog from "../tours/ShiftTimeDailog";
import { useDispatch, useSelector } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const ScheduleTour = (props) => {
  const [dayNames, setDayNames] = useState([]);
  const [fromTime, setFromTime] = useState();
  const [toTime, setToTime] = useState();
  const {
    schedulemodal,
    scheduleModalClose,
    setschedulemodal,
    tourId,
    tourView,
    singleTour,
    modulearray,
    getTourList,
    setmodulearray,
  } = props;
  if (tourView !== undefined) {
    var {
      schedule_time_end,
      schedule_time_start,
      schedule_type,
      schedule_type_value,
      shift_selected,
      tour_name,
      occurence,
      weekly_value,
    } = tourView;
  }
  //start time
  let sTime = "" + schedule_time_start;
  let sHour = sTime.slice(0, 2);
  let sMinute = sTime.slice(2, 4);
  let sT = "2020-01-01" + " " + sHour + ":" + sMinute;

  //end time
  let eTime = "" + schedule_time_end;
  let eHour = eTime.slice(0, 2);
  let eMinute = eTime.slice(2, 4);
  let eT = "2020-01-01" + " " + eHour + ":" + eMinute;

  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const handleChecked = (e, name) => {
    const { value, checked } = e.target;
    console.log(name);
    if (checked) {
      setmodulearray([...modulearray, value]);
      setDayNames([...dayNames, name]);
    } else {
      setmodulearray(modulearray.filter((e) => e !== value));
      setDayNames(dayNames.filter((e) => e !== name));
    }
  };

  let months = [];
  for (let index = 1; index <= 31; index++) {
    months.push(index);
  }

  const week = [
    {
      id: 1,
      name: "Sunday",
      data: "1",
    },
    {
      id: 2,
      name: "Monday",
      data: "2",
    },
    {
      id: 3,
      name: "Tuesday",
      data: "3",
    },
    {
      id: 4,
      name: "Wednesday",
      data: "4",
    },
    {
      id: 5,
      name: "Thursday",
      data: "5",
    },
    {
      id: 6,
      name: "Friday",
      data: "6",
    },
    {
      id: 7,
      name: "Saturday",
      data: "7",
    },
  ];

  var resultDay = [];
  week.forEach((obj) => {
    modulearray.forEach((res) => {
      if (res == obj.data) {
        resultDay.push(obj);
      }
    });
  });
  resultDay.sort((a, b) => {
    return a.data - b.data;
  });
  //Toaster state and method
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const initialValues = {
    month: schedule_type_value ? schedule_type_value : "1",
    recurring: schedule_type ? schedule_type : "Daily",
    occurence: occurence ? occurence : "per_shift",
    shift_selected: shift_selected ? shift_selected : "",
    start_time: sT ? sT : "",
    end_time: eT ? eT : "",
  };

  const validationSchema = Yup.object().shape({});

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let payload = {
        occurence: values.occurence,
        schedule_time_start: fromTime ? fromTime : schedule_time_start,
        schedule_time_end: toTime ? toTime : schedule_time_end,
        shift_selected: values.shift_selected,
        schedule_type: values.recurring,
        monthly_day: values.month,
        weekly_day: modulearray,
      };
      UpdateTourSchedule(tourId, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            singleTour();
            // scheduleList();
            getTourList();
            setOpenSnack(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [apiData, setApiData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDialogOpen) {
      GetTourShiftTime()
        .then((data) => {
          setApiData(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [isDialogOpen]);
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const handleSave = (updatedData) => {
    UpdateTourShiftTime(updatedData)
      .then((res) => {
        if (res.statusCode == 200) {
          console.log(res);
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Somthing went wrong please try again!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message="Tour schedule has been updated successfully "
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={schedulemodal}
        onClose={scheduleModalClose}
        aria-labelledby="schedule-tour"
        aria-describedby="schedule-tour"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            {tour_name}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setschedulemodal(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText id="equipment-log" sx={{ padding: "20px 25px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <Typography variant="p" component="div">
                        Choosing options below will restrict user from seeing
                        the tour in their mobile devices except for the day and
                        time selected. For example, setting a tour schedule of
                        "Monday, between 0800 and 1200 hours" would result in
                        this tour only being available during this day and only
                        within these times. If user begin a tour outside of
                        these times, they would not see the tour.
                      </Typography>
                      <FormControl mt={6}>
                        <FormLabel>Recurring</FormLabel>
                        <StyledRadio>
                          <RadioGroup
                            aria-labelledby="recurring"
                            name="recurring"
                            value={values.recurring}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              value="Daily"
                              control={<Radio />}
                              label="Daily"
                            />
                            <FormControlLabel
                              value="Weekly"
                              control={<Radio />}
                              label="Weekly"
                            />
                            <FormControlLabel
                              value="Monthly"
                              control={<Radio />}
                              label="Monthly"
                            />
                          </RadioGroup>
                        </StyledRadio>
                      </FormControl>
                      <FormControl
                        mt={3}
                        style={{
                          display:
                            values.recurring === "Weekly" ? "block" : "none",
                        }}
                      >
                        <FormGroup>
                          {week.map((mod, index) => {
                            return (
                              <FormControlLabel
                                key={mod.id}
                                control={
                                  <Checkbox
                                    disableRipple
                                    name={mod.name}
                                    value={mod.data}
                                    checked={modulearray.includes(mod.data)}
                                    onChange={(e) => {
                                      handleChecked(e, mod.name);
                                    }}
                                  />
                                }
                                label={mod.name}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                      <FormControl
                        mt={3}
                        style={{
                          display:
                            values.recurring === "Monthly" ? "block" : "none",
                        }}
                      >
                        <Select
                          labelId="month"
                          id="month"
                          name="month"
                          value={values.month}
                          onChange={handleChange}
                          displayEmpty
                        >
                          {months.map((day) => {
                            return <MenuItem value={day}>{day}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <FormLabel>Interval</FormLabel>
                        <StyledRadio>
                          <RadioGroup
                            aria-labelledby="interval"
                            name="radio-buttons-group"
                            name="occurence"
                            value={values.occurence}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              value="per_shift"
                              control={<Radio />}
                              label="Per shift"
                            />
                            <FormControlLabel
                              value="specific_time"
                              control={<Radio />}
                              label="Specific time"
                            />
                          </RadioGroup>
                        </StyledRadio>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display:
                          values.occurence === "specific_time"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                          <FormControl mt={6}>
                            <TimePicker
                              label="Start time"
                              name="start_time"
                              value={startTime ? startTime : values.start_time}
                              onChange={(newValue) => {
                                setStartTime(newValue);
                                setFromTime(format(newValue, "HHmm"));
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl mt={6}>
                            <TimePicker
                              label="End time"
                              name="end_time"
                              value={endTime ? endTime : values.end_time}
                              onChange={(newValue) => {
                                setEndTime(newValue);
                                setToTime(format(newValue, "HHmm"));
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display:
                          values.occurence === "per_shift" ? "block" : "none",
                      }}
                    >
                      <FormControl>
                        <div>
                          {isDialogOpen ? (
                            <ShiftTimeDialog
                              isOpen={isDialogOpen}
                              onClose={closeDialog}
                              onDataUpdate={handleSave}
                              apiData={apiData}
                            />
                          ) : (
                            <span style={{ display: "inline-block" }}>
                              Select a shift:{" "}
                              <FormLabel
                                onClick={openDialog}
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                View/Edit shift times
                              </FormLabel>
                            </span>
                          )}
                        </div>
                        <StyledRadio>
                          <RadioGroup
                            aria-labelledby="shift-select"
                            name="shift_selected"
                            value={values.shift_selected}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              value="Shift-1"
                              control={<Radio />}
                              label="Shift 1"
                            />
                            <FormControlLabel
                              value="Shift-2"
                              control={<Radio />}
                              label="Shift 2"
                            />
                            <FormControlLabel
                              value="Shift-3"
                              control={<Radio />}
                              label="Shift 3"
                            />
                          </RadioGroup>
                        </StyledRadio>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormHelperText
                        style={{
                          display:
                            values.recurring === "Daily" &&
                            values.occurence === "per_shift"
                              ? "block"
                              : "none",
                        }}
                      >
                        Everyday,during{" "}
                        {values.shift_selected === "0"
                          ? null
                          : values.shift_selected}
                      </FormHelperText>
                      <FormHelperText
                        style={{
                          display:
                            values.recurring === "Monthly" &&
                            values.occurence === "per_shift"
                              ? "block"
                              : "none",
                        }}
                      >
                        {`Every ${values.month}${
                          values.month === 1
                            ? "st"
                            : values.month === 2
                            ? "nd"
                            : values.month === 3
                            ? "rd"
                            : "th"
                        } day of month, during ${" "}
                        ${
                          values.shift_selected === "0"
                            ? null
                            : values.shift_selected
                        }`}
                      </FormHelperText>
                      <FormHelperText
                        style={{
                          display:
                            values.recurring === "Weekly" &&
                            values.occurence === "per_shift"
                              ? "block"
                              : "none",
                        }}
                      >
                        Every {resultDay.map((data) => data.name).join(",")} at{" "}
                        {values.shift_selected === "0"
                          ? null
                          : values.shift_selected}
                      </FormHelperText>
                      <FormHelperText
                        style={{
                          display:
                            values.recurring === "Daily" &&
                            values.occurence === "specific_time"
                              ? "block"
                              : "none",
                        }}
                      >
                        Everyday , between {fromTime ? fromTime : "XX"} hours
                        and {toTime ? toTime : "XX"} hours
                      </FormHelperText>
                      <FormHelperText
                        style={{
                          display:
                            values.recurring === "Weekly" &&
                            values.occurence === "specific_time"
                              ? "block"
                              : "none",
                        }}
                      >
                        Every {resultDay.map((data) => data.name).join(", ")}{" "}
                        between {fromTime ? fromTime : "XX"} hours and{" "}
                        {toTime ? toTime : "XX"} hours
                      </FormHelperText>
                      <FormHelperText
                        style={{
                          display:
                            values.recurring === "Monthly" &&
                            values.occurence === "specific_time"
                              ? "block"
                              : "none",
                        }}
                      >
                        {`Every ${values.month}${
                          values.month === 1
                            ? "st"
                            : values.month === 2
                            ? "nd"
                            : values.month === 3
                            ? "rd"
                            : "th"
                        } day of month, during ${" "}
                       between ${fromTime ? fromTime : "XX"} hours and
                        ${toTime ? toTime : "XX"} hours`}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <DialogActions
                    sx={{ paddingBottom: "30px", paddingRight: "20px" }}
                  >
                    <Button
                      onClick={scheduleModalClose}
                      color="primary"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={scheduleModalClose}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Save changes
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScheduleTour;

export default [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "carrier",
    label: "Carrier",
  },
  {
    id: "sender_name",
    label: "Sender",
  },
  {
    id: "recipient",
    label: "Recipient",
  },
  {
    id: "group",
    label: "Group",
  },
  {
    id: "tracking_number",
    label: "Tracking Number",
  },
  {
    id: "package_status",
    label: "Disposition",
  },
  {
    id: "storage_location",
    label: "Stored",
  },
  {
    id: "package_arrival_date",
    label: "Received",
  },
  {
    id: "delivery_datetime",
    label: "Delivered",
  },
  {
    id: "action",
    label: "Action",
  },
];

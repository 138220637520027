import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem,
  Box,
  Radio,
  FormLabel,
  Avatar as MuiAvatar,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import { Cancel as CancelIcon } from "@mui/icons-material";
const PoiEncounterDialog = ({
  poiEncounterDialog,
  setPoiEncounterDialog,
  poiEncounterDialogOpen,
  poiEncounterDialogClose,
}) => {
  const Avatar = styled(MuiAvatar)`
    display: inline-block;
    height: 200px;
    width: 200px;
  `;
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const InputLabel = styled(MuiInputLabel)`
    ${spacing};

    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
    padding-left: 6px;
    padding-right: 6px;
    margin-left: -4px;
  `;

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        open={poiEncounterDialog}
        onClose={poiEncounterDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h5">POI#1536463</Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setPoiEncounterDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} mt={5}>
              <Typography variant="body2" gutterBottom>
                <strong>Date</strong>
              </Typography>
              <Typography variant="body2" gutterBottom>
                06-02-2020 15:52:34
              </Typography>
              <FormControl>
                <TextField
                  sx={{
                    marginTop: "10px",
                  }}
                  id="outlined"
                  label="Property"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl mt={5}>
                <InputLabel shrink>Location</InputLabel>
                <Select
                  labelId="escort"
                  id="department"
                  label="Location"
                  fullWidth
                >
                  <MenuItem>Please select</MenuItem>
                  <MenuItem>Yes</MenuItem>
                  <MenuItem>No</MenuItem>
                </Select>
              </FormControl>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  marginTop: "10px",
                }}
              >
                Add POI Encounter increation on map
              </Typography>
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                <Button variant="contained" fontSize="small">
                  View Map
                </Button>
                <FormControl mt={5}>
                  <InputLabel shrink>Alert Level</InputLabel>
                  <Select
                    labelId="escort"
                    id="department"
                    label="Location"
                    fullWidth
                  >
                    <MenuItem>Please select</MenuItem>
                    <MenuItem>Yes</MenuItem>
                    <MenuItem>No</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  marginTop: "10px",
                }}
              >
                <strong>Behavioural Factors</strong>
              </Typography>
              <FormControl mt={2}>
                <FormLabel>Police Contacted</FormLabel>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    value="Yes"
                  />
                  <FormControlLabel control={<Radio />} label="No" value="No" />
                </RadioGroup>
              </FormControl>
              <FormControl mt={2}>
                <FormLabel>Trespass warning issued</FormLabel>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    value="Yes"
                  />
                  <FormControlLabel control={<Radio />} label="No" value="No" />
                </RadioGroup>
              </FormControl>
              <FormControl mt={5}>
                <InputLabel shrink>Resolution</InputLabel>
                <Select
                  labelId="escort"
                  id="department"
                  label="Location"
                  fullWidth
                >
                  <MenuItem>Please select</MenuItem>
                  <MenuItem>Yes</MenuItem>
                  <MenuItem>No</MenuItem>
                </Select>
              </FormControl>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  marginTop: "8px",
                }}
              >
                <strong>Narrative</strong>
              </Typography>
              <Typography variant="body2">test test</Typography>
            </Grid>
            <Grid item lg={6} md={6} mt={5}>
              <Avatar
                alt="Lucy Lavender"
                src="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={poiEncounterDialogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PoiEncounterDialog;

import React from "react";
import moment from "moment";
import { SingleLostfoundActivityLog } from "../../../api";

import {
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  Paper,
  CircularProgress,
} from "@mui/material";

const ActivityLog = ({ id }) => {
  const [itemLogsList, setItemLogsList] = React.useState([]);
  const [loading, setLoading] = React.useState(true); //State for loader

  React.useEffect(() => {
    ActivityLogList();
  }, []);

  const ActivityLogList = () => {
    SingleLostfoundActivityLog(id)
      .then((res) => {
        if (res.statusCode == 200) {
          setItemLogsList(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Paper>
        <TableContainer
          className="tableContainer"
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Activity</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Datetime</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={3} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {itemLogsList.map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.activity}</TableCell>
                      <TableCell>
                        {row.user ? row.user.UserName : "-"}
                      </TableCell>
                      <TableCell>
                        {moment(row.created).format("YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {itemLogsList.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ActivityLog;

import React from "react";
import { Box } from "@mui/material";
import { LocalShipping, MonetizationOn } from "@mui/icons-material";

const IconForStatus = ({ recordData }) => {
  return (
    <React.Fragment>
      <Box>
        {recordData.shipment_details &&
          recordData.shipment_details.transaction_status === "SUCCESS" && (
            <LocalShipping fontSize="small" />
          )}
        {recordData.shipment_paid_status && (
          <MonetizationOn fontSize="small" color="error" />
        )}
      </Box>
    </React.Fragment>
  );
};

export default IconForStatus;

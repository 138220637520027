import React, { useState } from "react";
import MarkDelivered from "./MarkDelivered";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
  TextField,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
} from "@mui/material";
import { PackageDetail } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
const BulkDelivery = ({ bulkDelivery, bulkDeliveryClose }) => {
  const [scanId, setScanId] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [isTrue, setIsTrue] = useState(false);
  const [markDeliveredModal, setMarkDeliveredModal] = useState(false);
  const markDeliveredModalOpen = () => {
    setMarkDeliveredModal(true);
    // bulkDeliveryClose();
  };
  const dispatch = useDispatch();
  const packageDetail = (value) => {
    PackageDetail({ packageId: value })
      .then((res) => {
        if (res.statusCode === 200) {
          setPackageData([...packageData, res.data]);
          setScanId("");
          setIsTrue(true);
        } else {
          setPackageData([]);
          setScanId("");
          dispatch(getMsg("Package is already delivered"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setScanId("");
        dispatch(getMsg("Package not found!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };
  return (
    <>
      <Dialog
        open={bulkDelivery}
        onClose={bulkDeliveryClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px",
            },
          },
        }}
        aria-labelledby="bulk-delivery-title"
        aria-describedby="bulk-delivery-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="bulk-delivery-title"
        >
          <Typography variant="h4" component="div">
            Package Delivery
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={bulkDeliveryClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Scan multiple package manually then hit enter key to add"
            label="Scan package *"
            InputLabelProps={{ shrink: true }}
            sx={{ marginTop: "10px" }}
            name="scan_package"
            fullWidth
            value={scanId}
            onChange={(e) => setScanId(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                packageDetail(e.target.value);
              }
            }}
          />
          {isTrue && (
            <Card
              variant="outlined"
              sx={{ borderBottom: 0, marginTop: "15px" }}
            >
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Recipient</TableCell>
                      <TableCell>Sender</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {packageData.map((row, index) => (
                      <TableRow key={index} sx={{ border: "1px" }}>
                        <TableCell component="th" scope="row">
                          {row.recipient}
                        </TableCell>
                        <TableCell>{row.sender_name}</TableCell>
                        <TableCell>{row.package_status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={bulkDeliveryClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: "10px" }}
            onClick={markDeliveredModalOpen}
            disabled={isTrue ? false : true}
          >
            Deliver all
          </Button>
          {markDeliveredModal && (
            <MarkDelivered
              markDeliveredModal={markDeliveredModal}
              setMarkDeliveredModal={setMarkDeliveredModal}
              packageData={packageData}
              bulkDeliveryClose={bulkDeliveryClose}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkDelivery;

export default [
  {
    id: "locker_number",
    label: "Lock Number",
  },
  {
    id: "room_name",
    label: "Room Name",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "assignment",
    label: "Assignment",
  },
  {
    id: "lock_type",
    label: "Lock Type",
  },
  {
    id: "action",
    label: "Action",
  },
];

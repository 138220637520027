import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import ViewMatchingPair from "../../../components/modals/lostandfound/ViewMatchingPair";
import DialogBox from "../../../components/form-components/DialogBox";
import { handleApiResonseErrors } from "../../../helper/helper";
import { NoMatch, MergeDuplicates } from "../../../api";

import {
  IconButton,
  ListItemText as MuiListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  ErrorOutline as ErrorOutlineIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  MergeType as MergeTypeIcon,
} from "@mui/icons-material";
import WithPermissionHide from "../../../utils/withPermissionHide";

const MatchLostfoundAction = ({
  data,
  reloadList,
  showAlertPopup,
  setShowLoader,
  setMergedRecordId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showViewMatchingPair, setShowViewMatchingPair] = useState(false);
  const [showNoMatch, setShowNoMatch] = useState(false);
  const [showMerge, setShowMerge] = useState(false);
  const CurrentLocationInfo = useSelector((state) => state.login.Location);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const ListItemText = styled(MuiListItemText)(spacing);
  const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  }));

  const handelMergeRecord = (status) => {
    if (status === true) {
      setShowLoader(true);
      let payload = {
        parent_id: data.lost_id,
        child_id: data.found_id,
      };
      MergeDuplicates(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            setMergedRecordId(res.data.id);
            showAlertPopup(res.message, "success");
            setShowNoMatch(false);
            reloadList();
          } else {
            showAlertPopup(res.message, "error");
          }
          setShowLoader(false);
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            showAlertPopup(error, "error");
            setShowLoader(false);
          }
        });
    } else {
      setShowMerge(false);
    }
  };

  const handelNoMatch = (status) => {
    if (status === true) {
      setShowLoader(true);
      let payload = {
        lost_id: data.lost_id,
        found_id: data.found_id,
        location_id: CurrentLocationInfo.LocationID,
      };
      NoMatch(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            showAlertPopup(res.message, "success");
            setShowNoMatch(false);
            reloadList();
          } else {
            showAlertPopup(res.message, "error");
          }
          setShowLoader(false);
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            showAlertPopup(error, "error");
            setShowLoader(false);
          }
        });
    } else {
      setShowNoMatch(false);
    }
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="Settings"
        size="large"
        aria-owns={anchorEl ? "icon-menu" : undefined}
        aria-haspopup="true"
        onClick={(e) => handleActionClick(e)}
        style={{ color: "#1374C5" }}
      >
        <SettingsIcon />
      </IconButton>
      <StyledMenu
        id="icon-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            setShowViewMatchingPair(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary="View Pair" pl={1} />
        </MenuItem>
        <WithPermissionHide controller="LostAndFound" action="mergeDuplicates">
          <MenuItem
            onClick={() => {
              setShowMerge(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <MergeTypeIcon />
            </ListItemIcon>
            <ListItemText primary="Merge" pl={1} />
          </MenuItem>
        </WithPermissionHide>
        <MenuItem
          onClick={() => {
            setShowNoMatch(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ErrorOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="No Match" pl={1} />
        </MenuItem>
      </StyledMenu>
      {showViewMatchingPair && (
        <ViewMatchingPair
          show={showViewMatchingPair}
          handleModal={(status) => setShowViewMatchingPair(status)}
          data={data}
          handelNoMatch={(status) => setShowNoMatch(status)}
        />
      )}
      {showMerge && (
        <DialogBox
          handleShow={showMerge}
          message={
            "Are you sure you want to merge lost item " +
            data.lost_id +
            " with found item " +
            data.found_id +
            "?"
          }
          conformation={(status) => handelMergeRecord(status)}
        />
      )}
      {showNoMatch && (
        <DialogBox
          handleShow={showNoMatch}
          message={"Hide possible matched items? This cannot be undone!"}
          conformation={(status) => handelNoMatch(status)}
        />
      )}
    </React.Fragment>
  );
};

export default MatchLostfoundAction;

import React, { useState, useRef } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import * as Yup from "yup";
import { BulkDelivery } from "../../../api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  TextField,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  FormGroup,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  Box,
  Tooltip,
  CircularProgress,
  Paper,
  FormHelperText,
  CardActions,
  Avatar,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DatePicker, DateTimePicker } from "@mui/lab";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { getFileBase64, handleApiResonseErrors } from "../../../helper/helper";
import CameraFeeding from "../../../helper/camera-feeding";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Image = styled.img`
  max-width: fit-content;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  object-fit: contain;
`;
const ImageWrapper = styled.div`
  height: 200px;
  overflow: hidden;
  &:before {
  }
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const MarkDelivered = ({
  markDeliveredModal,
  setMarkDeliveredModal,
  packageData,
  bulkDeliveryClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [showWebCam, setShowWebCam] = React.useState(false);
  const uploadedFileRef = useRef();
  const [uploadedImage, setUploadedImage] = useState(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const classes = useStyles();
  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };
  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };
  const initialValues = {
    delivery_to_location: "",
    other_guestroom_number: "",
    ballroom_name: "",
    other_delivery_location: "",
    fname_of_person_receiving_package: "",
    lname_of_person_receiving_package: "",
    gender: "",
    identification_verified: "",
    delivery_datetime: null,
    delivery_notes: "",
  };
  const validationSchema = Yup.object().shape({
    // company_name: Yup.string().required("Please enter company name"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        id: packageData.map((d) => d.id).toString(),
        delivery_to_location: values.delivery_to_location,
        other_delivery_location: values.other_delivery_location,
        other_guestroom_number: values.other_guestroom_number,
        ballroom_name: values.ballroom_name,
        receiver_first_name: values.fname_of_person_receiving_package,
        receiver_last_name: values.lname_of_person_receiving_package,
        receiver_gender: values.gender,
        receiver_id: values.identification_verified,
        delivery_datetime:
          values.delivery_datetime !== null
            ? format(values.delivery_datetime, "yyyy-MM-dd HH:mm:ss")
            : null,
        delivery_notes: values.delivery_notes,
        webcam_mage: uploadedImage ? uploadedImage : null,
      };
      BulkDelivery(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record udpated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setMarkDeliveredModal(false);
            bulkDeliveryClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
            // navigate({
            //   pathname: "/visitors/companies",
            // });
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        open={markDeliveredModal}
        // onClose={() => setMarkDeliveredModal(false)}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="mark-delivered"
        >
          <Typography variant="h4" component="div">
            Mark Delivered
          </Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setMarkDeliveredModal(false)}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={8}>
                    <FormControl>
                      <FormLabel>Delivery to location</FormLabel>
                      <RadioGroup
                        name="delivery_to_location"
                        aria-labelledby="delivery-to-location"
                        row
                        value={values.delivery_to_location}
                        onChange={(e) =>
                          setFieldValue("delivery_to_location", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Guest"
                          value="Guest"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Ballroom"
                          value="Ballroom"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Returned to sender"
                          value="Returned to sender"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Other"
                          value="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                    {values.delivery_to_location == "Guest" && (
                      <FormControl mt={6}>
                        <CustomTextField
                          fullWidth
                          name="other_guestroom_number"
                          autoComplete="off"
                          placeholder="Guestroom number, front dest etc "
                        />
                      </FormControl>
                    )}
                    {values.delivery_to_location == "Ballroom" && (
                      <FormControl mt={6}>
                        <CustomTextField
                          fullWidth
                          name="ballroom_name"
                          autoComplete="off"
                          placeholder="Ballroom name"
                        />
                      </FormControl>
                    )}
                    {values.delivery_to_location == "Other" && (
                      <FormControl mt={3}>
                        <CustomTextField
                          fullWidth
                          name="other_delivery_location"
                          autoComplete="off"
                          placeholder="Delivery location other than guestroom"
                        />
                      </FormControl>
                    )}
                    <FormControl mt={3}>
                      <CustomTextField
                        fullWidth
                        name="fname_of_person_receiving_package"
                        label="First name of person receiving package"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Mark"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        name="lname_of_person_receiving_package"
                        label="Last name of person receiving package"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Johnson"
                      />
                    </FormControl>
                    <FormControl mt={3}>
                      <FormLabel>Gender of person receiving package:</FormLabel>
                      <RadioGroup
                        name="gender"
                        aria-labelledby="identification-verified"
                        row
                        value={values.gender}
                        onChange={(e) =>
                          setFieldValue("gender", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Male"
                          value="Male"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Female"
                          value="Female"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Unknown"
                          value="Unknown"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl mt={3}>
                      <FormLabel>Identification verified</FormLabel>
                      <RadioGroup
                        name="identification_verified"
                        aria-labelledby="identification-verified"
                        row
                        value={values.identification_verified}
                        onChange={(e) =>
                          setFieldValue(
                            "identification_verified",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Driver license"
                          value="Driver License"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Passport"
                          value="Passport"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Military ID"
                          value="Military ID"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl mt={3}>
                      <DateTimePicker
                        name="delivery_datetime"
                        label="Delivery datetime"
                        value={values.delivery_datetime}
                        onChange={(newValue) => {
                          setFieldValue("delivery_datetime", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            fullWidth
                            name="delivery_datetime"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                      <FormControl mt={6}>
                        <CustomTextField
                          fullWidth
                          name="delivery_notes"
                          label="Delivery Notes"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          placeholder="Given to (name), placed in vacant guestroom, etc."
                        />
                      </FormControl>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      {uploadedImage ? (
                        <BigAvatar alt="image name" src={uploadedImage} />
                      ) : (
                        <BigAvatar
                          alt="image name"
                          src="/static/img/avatars/profile-upload.png"
                        />
                      )}

                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          JustifyContent: "space-between",
                          margin: "10px 0 15px",
                          gap: "15px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                          onClick={(e) => clearSelectedImage(e)}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="outlined"
                          color="warning"
                          component="span"
                          onClick={() => handleShowWebCam()}
                        >
                          {showWebCam ? "Close WebCam" : "WebCam"}
                        </Button>
                      </Box>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        ref={uploadedFileRef}
                        onChange={(e) => handleFileChange(e)}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Choose image
                        </Button>
                      </label>
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setMarkDeliveredModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {showWebCam && (
        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
    </>
  );
};

export default MarkDelivered;
